import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import {
  KnownFeatureFlags,
  MainTabGroup,
} from '../../context/GlobalPropsContext';

import { GlobalPropertiesContext } from '../../context';
import { PageContentCard } from '../PageContentCard/PageContentCard';
import HeadsUpDisplay from './HeadsUpDisplay';

export interface TabType {
  id: string;
  label: string;
  content: JSX.Element;
  group: MainTabGroup;
  href: string;
  ff?: KnownFeatureFlags; // when specified, tab will only be visible if the feature flag is enabled for the user
  hide?: boolean; // when true tab will never render
  disabled?: boolean; // when true tab will be visible but not clickable
}

/**
 * Component that provides the base tab layout for the main page content
 * and a right drawer for contextual elements.
 */
export default function TabLayout({
  breadcrumbs,
  tabs,
  resourceType,
}: {
  breadcrumbs?: any;
  tabs: TabType[];
  resourceType?: string;
}) {
  const paramMap = useParams();
  const activeTab = paramMap['*']?.split('/')[0] || '';
  const navigate = useNavigate();
  const { ff, mainTabGroup } = useContext(GlobalPropertiesContext);
  const visibleTabs = tabs.filter((tab) => {
    const featureFlagVisible = tab.ff ? ff(tab.ff) : true;
    const tabInGroup = mainTabGroup === undefined || mainTabGroup === tab.group;

    return tabInGroup && featureFlagVisible && !tab.hide;
  });

  const findActiveTabIndex = () =>
    visibleTabs.findIndex(
      (tab: any) => tab.href === activeTab || activeTab === '',
    );
  const [tabIndex, setTabIndex] = useState(Math.max(findActiveTabIndex(), 0));

  const handleTabChange = (event: any, newValue: any) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    //handle breadcrumb clicks
    const activeIndex = findActiveTabIndex();
    if (activeIndex !== tabIndex || visibleTabs[tabIndex]?.href !== activeTab) {
      //handleTabChange(null, activeIndex);
      if ('*' in paramMap && activeIndex > -1) {
        navigate(visibleTabs[tabIndex].href, { replace: activeTab === '' }); //replacing the history if activetab is empty since this is a default redirection and not a user action
      }
    }
  }, [activeTab, tabIndex]);

  useEffect(() => {
    setTabIndex(Math.max(findActiveTabIndex(), 0));
  }, [visibleTabs]);

  return (
    <Box>
      <HeadsUpDisplay
        breadcrumbs={breadcrumbs}
        tabs={visibleTabs}
        onTabChange={handleTabChange}
        tabIndex={tabIndex}
      />
      <PageContentCard activeTab={activeTab}>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: {
              '& .status-board-root': {
                marginX: -3,
                mt: -3.5,
                pt: 2,
                px: 3,
              },
            },
            [theme.breakpoints.down('xl')]: {},
            [theme.breakpoints.down('md')]: {
              '& .status-board-root': {
                marginX: -3,
                mt: -2.5,
                pt: 3.5,
              },
            },
            [theme.breakpoints.down('sm')]: {
              mt: '16px !important',
              '& .status-board-root': {
                marginX: -3,
                mt: -2.5,
                pt: 3.5,
              },
            },
          })}
        >
          <Routes>
            {visibleTabs.map(
              (tab: any, idx: any) =>
                tabIndex === idx && (
                  <Route
                    key={'route-' + tab.id}
                    path=":activetab/*"
                    element={<div key={tab.id}>{tab.content}</div>}
                  />
                ),
            )}
          </Routes>
        </Box>
      </PageContentCard>
    </Box>
  );
}
