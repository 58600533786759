import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Table } from '../../graphql/gen/graphql';
import { TableEventsView } from '../EventsViewer/TableEventsView';
// @ts-ignore
import SnapshotView from '../SnapshotViewer/SnapshotView';

export default function TableActivityView({
  table,
  userCanEdit,
}: {
  table: Table;
  userCanEdit: boolean;
}) {
  return (
    <>
      <Routes>
        <Route index element={<TableEventsView table={table} />} />

        <Route
          path="snapshots"
          element={<SnapshotView table={table} userCanEdit={userCanEdit} />}
        />
      </Routes>
    </>
  );
}
