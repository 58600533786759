import React, { useCallback, useMemo, useRef } from 'react';

import { Box, useTheme } from '@mui/material';

import {
  DynamicBreakpoint,
  useDynamicBreakpoints,
} from '../../utils/ElementVisibility';
import StatusPanelControlsView from './StatusPanelControlsView';
import StatusPanelSectionView from './StatusPanelSectionView';
import { StatusPanelContextProvider } from './context/StatusPanelContextProvider';
import { useStatusPanelProps } from './useStatusPanelProps';

import { StatusPanelProps } from './index';

export const STATUS_PANEL_CLASS_PREFIX = 'status-panel';
export const STATUS_PANEL_CLASS_SIZE_DESCRIPTORS = ['normal', 'small', 'tiny'];

const MAX_NUM_BREAKPOINTS = 2;
function makeAutoBreakClasses(breakpoints: DynamicBreakpoint[], type: string) {
  return breakpoints.length
    ? breakpoints
        .filter((eachBreakpoint) => !eachBreakpoint.isVisible)
        .slice(0, MAX_NUM_BREAKPOINTS)
        .map(
          (eachBreakpoint, idx) =>
            `${STATUS_PANEL_CLASS_PREFIX}-${type}-${
              STATUS_PANEL_CLASS_SIZE_DESCRIPTORS[idx + 1]
            }`,
        )
    : [
        `${STATUS_PANEL_CLASS_PREFIX}-${type}-${STATUS_PANEL_CLASS_SIZE_DESCRIPTORS[0]}`,
      ];
}

const DYN_CLASS_TYPE_HIDE = 'hide';
const DYN_CLASS_TYPE_MOBILE = 'mobile';
export default function StatusPanelView(inProps: StatusPanelProps) {
  const props = useStatusPanelProps(inProps);
  const { statusPanel } = props;
  const theme = useTheme();
  const testBoxRef = useRef<HTMLDivElement>();

  const autoBreakpoints = useDynamicBreakpoints(testBoxRef);

  const autoBreakHideClasses = makeAutoBreakClasses(
    autoBreakpoints.breakpoints,
    DYN_CLASS_TYPE_HIDE,
  );

  const autoBreakClasses = [...autoBreakHideClasses];

  const autoBreakHideStyles = Object.assign(
    {
      [theme.breakpoints.down('md')]: {
        [`.${STATUS_PANEL_CLASS_PREFIX}-${DYN_CLASS_TYPE_HIDE}-${
          STATUS_PANEL_CLASS_SIZE_DESCRIPTORS[
            STATUS_PANEL_CLASS_SIZE_DESCRIPTORS.length - 2
          ]
        }`]: { display: 'none !important' },
      },
      [theme.breakpoints.down('sm')]: {
        height: 'inherit',
        [`.${STATUS_PANEL_CLASS_PREFIX}-${DYN_CLASS_TYPE_HIDE}-${
          STATUS_PANEL_CLASS_SIZE_DESCRIPTORS[
            STATUS_PANEL_CLASS_SIZE_DESCRIPTORS.length - 2
          ]
        }`]: { display: 'none !important' },
        [`.${STATUS_PANEL_CLASS_PREFIX}-${DYN_CLASS_TYPE_HIDE}-${
          STATUS_PANEL_CLASS_SIZE_DESCRIPTORS[
            STATUS_PANEL_CLASS_SIZE_DESCRIPTORS.length - 1
          ]
        }`]: { display: 'none !important' },
        [`.${STATUS_PANEL_CLASS_PREFIX}-${DYN_CLASS_TYPE_MOBILE}`]: {
          flexWrap: 'wrap !important',
          gap: 1,
        },
        [`.${STATUS_PANEL_CLASS_PREFIX}-${DYN_CLASS_TYPE_MOBILE} .item`]: {
          flexBasis: `100%`,
          paddingX: 0,
        },
        [`.${STATUS_PANEL_CLASS_PREFIX}-${DYN_CLASS_TYPE_MOBILE} .section`]: {},
      },
    },
    ...autoBreakHideClasses.map((eachHideClass) => ({
      [`& .${eachHideClass}`]: { display: 'none !important' },
    })),
  );

  return (
    <StatusPanelContextProvider
      props={{
        ...props,
        autoBreakClasses,
        isSizeConstrained: autoBreakpoints?.breakpoints?.length,
      }}
    >
      <Box
        {...props}
        sx={[
          {
            ...autoBreakHideStyles,

            flexGrow: 1,
            display: 'flex',
            height: 72,
            borderRadius: 2,
            overflow: 'hidden',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'midnight.two',
            boxShadow: theme.shadows[2],
            backgroundColor: 'controlBackground.main',
          },
          ...(Array.isArray(props?.sx) ? props?.sx ?? [] : [props?.sx]),
        ]}
        flexGrow={1}
      >
        <Box
          sx={{
            display: 'flex',
          }}
          flexGrow={1}
        >
          <>
            {statusPanel.sections.map((section, sectionIndex) => (
              <StatusPanelSectionView
                {...section}
                flexGrow={sectionIndex === 0 ? 1 : 0}
                key={`status-panel-section-header-${sectionIndex}`}
              />
            ))}

            {(statusPanel.actions || statusPanel.dashboardDropdownContent) && (
              <StatusPanelControlsView
                actions={statusPanel.actions}
                dashboardDropdownContent={statusPanel.dashboardDropdownContent}
              />
            )}
          </>
        </Box>
        <Box ref={testBoxRef}></Box>
      </Box>
    </StatusPanelContextProvider>
  );
}
