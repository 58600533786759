export interface Logger {
  error: (msg: string, ...args: any) => void;
  info: (msg: string, ...args: any) => void;
  warn: (msg: string, ...args: any) => void;
  log: (msg: string, ...args: any) => void;
  debug: (msg: string, ...args: any) => void;
}
function formatLogMessage(source: string, message: string): string {
  const now = new Date();

  return `${now.getFullYear()}-${('0' + (now.getMonth() + 1)).slice(-2)}-${(
    '0' + now.getDate()
  ).slice(-2)}T${('0' + now.getHours()).slice(-2)}:${(
    '0' + now.getMinutes()
  ).slice(-2)}:${('0' + now.getSeconds()).slice(-2)}.${(
    '0' + now.getMilliseconds()
  ).slice(-2)}\t${source}\t: ${message}`;
}
export function getLogger(source: string): Logger {
  if ('prod' === process.env.REACT_APP_ENV) {
    return {
      error: (msg: string) => {},
      info: (msg: string) => {},
      warn: (msg: string) => {},
      log: (msg: string) => {},
      debug: (msg: string) => {},
    };
  } else {
    return {
      error: (msg: string, ...rest) => {
        console.error(formatLogMessage(source, msg), rest);
      },
      info: (msg: string, ...rest) => {
        console.info(formatLogMessage(source, msg), rest);
      },
      warn: (msg: string, ...rest) => {
        console.warn(formatLogMessage(source, msg), rest);
      },
      log: (msg: string, ...rest) => {
        console.log(formatLogMessage(source, msg), rest);
      },
      debug: (msg: string, ...rest) => {
        console.debug(formatLogMessage(source, msg), rest);
      },
    };
  }
}
