import React, { useEffect, useMemo, useState } from 'react';

import { useMutation, useLazyQuery } from '@apollo/client';
import { useModal, create, show } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { isEmpty } from 'lodash';

import { MirrorTarget, Warehouse } from '../../../graphql/gen/graphql';
import {
  getMirrorStatus,
  mirrorWarehouseMutation,
} from '../../../graphql/warehouse';
import { Talert } from '../../Alert/Talert';
import { CustomIcon } from '../../Icons/CustomIcon';
import { Tabulink } from '../../Link/Tabulink';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../WizardContainer';
import { GlueSetup } from '../glue/GlueSetup';

const RedshiftWizardDialog = create<{
  organizationName: string;
  warehouse: Warehouse;
  refetch: any;
}>(({ warehouse, organizationName, refetch }) => {
  const modal = useModal();
  const [errorMessage, setErrorMessage] = useState('');
  const [taskId, setTaskId] = useState('');
  const [status, setStatus] = useState();

  const [mirrorWarehouse] = useMutation(mirrorWarehouseMutation);
  const [getStatus, { data, error, startPolling, stopPolling }] =
    useLazyQuery(getMirrorStatus);

  const glueMirroringEnabled = useMemo(
    () =>
      warehouse.properties?.find((item) => item?.key === 'mirror.glue.enabled')
        ?.value == 'true' || false,
    [warehouse],
  );

  const closeAndRefetch = async () => {
    await refetch();
  };

  const handleGlueMirrorUpdate = () => {
    return mirrorWarehouse({
      variables: {
        warehouseId: warehouse.id,
        target: MirrorTarget.Glue,
      },
      onCompleted: async (data) => {
        setTaskId(data.mirrorWarehouse.taskId);
      },

      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(taskId)) {
      getStatus({
        variables: {
          warehouseId: warehouse.id,
          taskId: taskId,
          target: MirrorTarget.Glue.toLowerCase(),
        },
      });
      startPolling(1000);
    }

    if (data?.getMirrorStatus?.status === 'COMPLETE') {
      setStatus(data?.getMirrorStatus?.status);
      stopPolling();
      closeAndRefetch();
    }

    if (data?.getMirrorStatus?.status === 'ERROR') {
      setErrorMessage('An error has occurred please try again.');
      stopPolling();
    }
    if (error) {
      setErrorMessage(
        'Error syncing data to Glue, there may be an existing data set.',
      );
      stopPolling();
    }
  }, [taskId, data, error]);

  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: 'Redshift setup',
        body: (
          <>
            <Typography paddingTop={0} mb={2} variant={'subtitle1'}>
              In order to use Redshift you must sync your warehouse with Amazon
              Glue:
            </Typography>
            <GlueSetup
              region={warehouse?.region}
              roleArn={warehouse?.roleArn || ''}
            />
          </>
        ),
        nextButtonEffect: () => handleGlueMirrorUpdate(),
        buttonText: 'Sync Glue',
        disallowContinue: glueMirroringEnabled,
      },
      {
        title: 'Test connection',
        body: (
          <>
            <Box sx={{ width: 0.8 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 'fit-content',
                }}
              >
                <Typography variant={`subtitle1`} sx={{ mb: 1 }}>
                  Syncing {warehouse.name} ({warehouse.region}) to Glue.
                </Typography>
                <Typography variant={`subtitle1`} sx={{ mb: 1 }}>
                  Once Glue is successfully connected you will see your
                  databases in Redshift Serverless: default-workgroup:
                </Typography>
                <Tabulink
                  external
                  href={`https://${warehouse.region}.console.aws.amazon.com/sqlworkbench/home?region=${warehouse.region}#/client`}
                  variant="body1"
                  rel="noopener"
                  aria-label="Link to Amazon Glue"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Check connection in Redshift
                  <OpenInNewIcon
                    fontSize="small"
                    sx={{ ml: '2px', mr: '3px' }}
                  />
                </Tabulink>
                <Box sx={{ width: '100%' }}>
                  {status !== 'COMPLETE' && !error && (
                    <Talert
                      color={'neutral'}
                      sx={{ my: 2, width: 'fit-content' }}
                    >
                      Watching for Glue sync status
                    </Talert>
                  )}
                  {status === 'COMPLETE' && (
                    <Talert
                      severity="success"
                      sx={{ my: 2, width: 'fit-content' }}
                    >
                      Successful connection detected!
                    </Talert>
                  )}
                  {error && (
                    <Talert
                      severity={'error'}
                      sx={{ my: 2, width: 'fit-content' }}
                    >
                      {errorMessage}
                    </Talert>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        ),
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <WizardContainer
        title={'Redshift'}
        stepsTitle={warehouse.name}
        stepsSubtitle={`Connect Redshift`}
        stepsIcon={
          <CustomIcon
            src={'/assets/img/logos/amazon-redshift.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        backButtonClick={() => {}}
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        sx={{ minHeight: '550px', width: 1, height: '98vh' }}
      />
    </Dialog>
  );
});

export function showRedshiftWizardDialog({
  warehouse,
  organizationName,
  refetch,
}: {
  warehouse: Warehouse;
  organizationName: string;
  refetch: any;
}) {
  return show(RedshiftWizardDialog, {
    warehouse,
    organizationName,
    refetch,
  });
}
