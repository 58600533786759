import React from 'react';

import { Box, BoxProps, IconButton, Tooltip, useTheme } from '@mui/material';
import { SystemProps } from '@mui/system';
import { stat } from 'fs';

import { CustomIcon } from '../Icons/CustomIcon';

import { StatusAction } from './index';

interface StatusActionViewProps extends BoxProps {
  statusAction: StatusAction;
}

export default function StatusActionView({
  statusAction,
  ...props
}: StatusActionViewProps) {
  return (
    <Box {...props}>
      <Tooltip title={statusAction.title} placement={'top'} arrow>
        <IconButton
          size={'small'}
          sx={{
            backgroundColor: 'controlBackground.main',
            display: 'flex',
            p: '4px',
            alignItems: 'flex-start',
            gap: 1,
            borderRadius: '16px',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'midnight.two',
          }}
          {...statusAction.iconButtonProps}
        >
          <CustomIcon
            {...statusAction.icon}
            sx={[
              { height: 16, width: 16 },
              ...(statusAction?.icon?.sx
                ? [statusAction?.icon?.sx as SystemProps]
                : []),
            ]}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
