import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import { Box, Typography } from '@mui/material';

import { toDocsRoot, toProfile } from '../../RouteTable';
import { Talert } from '../../components/Alert/Talert';
import { Tacard } from '../../components/Card/Tacard';
import { CreateIAMRoleMappingDialog } from '../../components/Credentials/Dialogs/CreateIAMRoleMappingDialog';
import { CreateIdentityProviderDialog } from '../../components/Credentials/Dialogs/CreateIdentityProviderDialog';
import { CreateOrgCredentialDialog } from '../../components/Credentials/Dialogs/CreateOrgCredentialDialog';
import { CreateServiceMappingDialog } from '../../components/Credentials/Dialogs/CreateServiceMappingDialog';
import AWSIdentityProviders from '../../components/Credentials/Grids/AWSIdentityProviders';
import CredentialsGrid from '../../components/Credentials/Grids/CredentialGrid';
import IAMRoleMapping from '../../components/Credentials/Grids/IAMRoleMapping';
import ServiceMapping from '../../components/Credentials/Grids/ServiceMapping';
import { DropdownMenuContent } from '../../components/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenuItem';
import StatusBars from '../../components/Feedback/StatusBars';
import { ConfirmationDialog } from '../../components/Modals/ConfirmationDialog';
import { useAuth } from '../../context/auth-context';
import {
  CredentialTypes,
  OrganizationCredential,
  OrgAuth,
} from '../../graphql/gen/graphql';
import {
  deleteOrgCredential,
  getOrganizationAuths,
  organizationCredentials,
  removeOrganizationAuth,
  updateOrgCredential,
} from '../../graphql/organization';
import { getRolesByOrgIdQuery } from '../../graphql/role';

export default function Security({
  orgName,
  organizationId,
}: {
  orgName: string;
  organizationId: string;
}) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { organization: organizationName } = useParams();
  const isSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(organizationName),
    [user, organizationName],
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbar, setSnackbar] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [AWSIdentityConfirmModal, setAWSIdentityConfirmModal] = useState(false);
  const [credToDelete, setCredToDelete] = useState(
    {} as OrganizationCredential,
  );
  const [AWSIdentityToDelete, setAWSIdentityToDelete] = useState({} as OrgAuth);
  const { data: roleData, loading: rolesLoading } = useQuery(
    getRolesByOrgIdQuery,
    {
      variables: { organizationId },
      skip: !isSecurityAdmin,
    },
  );
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [createIAMDialogVisible, setCreateIAMDialogVisible] = useState(false);
  const [createIdentityProviderDialog, setCreateIdentityProviderDialog] =
    useState(false);
  const [createServiceDialogVisible, setCreateServiceDialogVisible] =
    useState(false);

  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash && !scrolledRef.current) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'auto' });
          window.scroll(0, window.scrollY - 100);
          scrolledRef.current = true;
        }, 500);
      }
    }
  });

  const {
    data: orgCredentials,
    refetch: refetchCredentials,
    loading: credentialsLoading,
  } = useQuery(organizationCredentials, {
    variables: {
      organizationId,
    },
    skip: !isSecurityAdmin,
  });

  const [deleteCredential, { loading: deleteLoading }] = useMutation(
    deleteOrgCredential,
    {
      variables: {
        organizationId,
        credentialKey: credToDelete.key,
      },
      onCompleted: async () => {
        await refetchCredentials();
        setConfirmModal(false);
        setCredToDelete({} as OrganizationCredential);
        setSnackbar('Successfully deleted');
        setOpenSnackbar(true);
      },
    },
  );
  const {
    data: authData,
    refetch: refetchAuth,
    loading: authLoading,
  } = useQuery(getOrganizationAuths, {
    variables: { organizationId },
  });

  const [removeAuth, { loading: deleteAWSIdentityLoading }] = useMutation(
    removeOrganizationAuth,
    {
      variables: {
        organizationId,
        authId: AWSIdentityToDelete.id,
      },
      onCompleted: async () => {
        await refetchAuth();
        setAWSIdentityConfirmModal(false);
        setAWSIdentityToDelete({} as OrgAuth);
        setSnackbar('Successfully deleted');
        setOpenSnackbar(true);
      },
    },
  );

  const [updateCredential] = useMutation(updateOrgCredential);
  const handleCredentialUpdate = async (
    credentialKey: string,
    updates: any,
  ) => {
    await updateCredential({
      variables: {
        organizationId,
        credentialKey,
        updates: updates,
      },
    });

    await refetchCredentials();
  };

  const confirmCredentialDelete = (credential: any) => {
    setCredToDelete(credential);
    setConfirmModal(true);
  };
  const confirmAWSIdentityDelete = (auth: any) => {
    setAWSIdentityToDelete(auth);
    setAWSIdentityConfirmModal(true);
  };
  const customIdentityCreds = useMemo(
    () =>
      orgCredentials?.organizationCredentials.filter(
        (cred: OrganizationCredential) =>
          cred.type === CredentialTypes.Federated,
      ),
    [orgCredentials],
  );
  const serviceAccountCreds = useMemo(
    () =>
      orgCredentials?.organizationCredentials.filter(
        (cred: OrganizationCredential) => cred.type === CredentialTypes.Service,
      ),
    [orgCredentials],
  );
  const userCreds = useMemo(
    () =>
      orgCredentials?.organizationCredentials.filter(
        (cred: OrganizationCredential) => cred.type === CredentialTypes.Member,
      ),
    [orgCredentials],
  );
  const iamCreds = useMemo(
    () =>
      orgCredentials?.organizationCredentials.filter(
        (cred: OrganizationCredential) => cred.type === CredentialTypes.Iam,
      ),
    [orgCredentials],
  );

  const currentUserEmail = useMemo(
    () => user?.loginSession?.membership?.email || user?.email,
    [user],
  );

  return (
    <>
      <Talert
        severity={'info'}
        alertTitle={'Understanding credentials'}
        sx={{ width: 'fit-content', mb: 4 }}
        TabuttonProps={{
          children: (
            <Box display={'flex'} alignItems={'center'}>
              Learn more
              <OpenInNewIcon fontSize={'small'} sx={{ ml: 1 }} />
            </Box>
          ),
          size: 'small',
          onClick: () =>
            window.open(
              toDocsRoot() + '/understanding-credentials.html',
              '_blank',
            ),
          sx: {
            m: 1,
            borderRadius: '16px',
          },
        }}
      >
        {' '}
        Follow link to learn more about credential types.
      </Talert>
      {isSecurityAdmin ? (
        <>
          <Tacard>
            <CredentialsGrid
              credentialsData={userCreds as [OrganizationCredential]}
              onCredentialDelete={confirmCredentialDelete}
              onCredentialUpdate={handleCredentialUpdate}
              headerName={'Member credentials'}
              icon={<AccountCircleOutlinedIcon />}
              showCreatorColumn
              currentUserEmail={currentUserEmail}
              refreshResourceContent={{
                title: 'Refresh member credentials list',
                action: refetchCredentials,
              }}
              dashboardMenuContent={
                <DropdownMenuContent
                  menuItems={[
                    <DropdownMenuItem
                      key={'createMemberCred'}
                      titleText={'Create member credential'}
                      description={`Personal credentials are created and managed in My Profile.`}
                      Icon={ArrowForwardIcon}
                      onClick={() => {
                        navigate(toProfile(user.loginSession.loggedInOrg.name));
                      }}
                    />,
                  ]}
                />
              }
              loading={credentialsLoading}
            />
          </Tacard>
          <Tacard>
            <ServiceMapping
              credentialsData={serviceAccountCreds as [OrganizationCredential]}
              onCredentialDelete={confirmCredentialDelete}
              onCredentialUpdate={handleCredentialUpdate}
              currentUserEmail={currentUserEmail}
              roles={roleData?.organization?.roles}
              rolesLoading={rolesLoading}
              loading={credentialsLoading}
              refreshResourceContent={{
                title: 'Refresh service accounts list',
                action: refetchCredentials,
              }}
              addResourceContent={{
                title: 'Create service account',
                action: () => setCreateServiceDialogVisible(true),
              }}
            />
          </Tacard>
          <Tacard>
            <CredentialsGrid
              credentialsData={customIdentityCreds as [OrganizationCredential]}
              onCredentialDelete={confirmCredentialDelete}
              onCredentialUpdate={handleCredentialUpdate}
              headerName={'Custom identity provider'}
              icon={<LocalPoliceOutlinedIcon />}
              showCreatorColumn
              currentUserEmail={currentUserEmail}
              loading={credentialsLoading}
              refreshResourceContent={{
                title: 'Refresh custom service providers list',
                action: refetchCredentials,
              }}
              addResourceContent={{
                title: 'Create custom service provider',
                action: () => setCreateDialogVisible(true),
              }}
            />
          </Tacard>
          <Tacard>
            <AWSIdentityProviders
              orgAuthData={authData?.organization?.auths || []}
              onIdentityProviderDelete={confirmAWSIdentityDelete}
              currentUserEmail={currentUserEmail}
              refreshResourceContent={{
                title: 'Refresh AWS IAM identity provider list',
                action: refetchAuth,
              }}
              addResourceContent={{
                title: 'Create AWS IAM identity provider',
                action: () => setCreateIdentityProviderDialog(true),
              }}
              loading={authLoading}
            />
          </Tacard>
          <Tacard>
            <IAMRoleMapping
              credentialsData={iamCreds as [OrganizationCredential]}
              onCredentialDelete={confirmCredentialDelete}
              onCredentialUpdate={handleCredentialUpdate}
              currentUserEmail={currentUserEmail}
              refreshResourceContent={{
                title: 'Refresh AWS IAM role mapping list',
                action: refetchCredentials,
              }}
              addResourceContent={{
                title: 'Create AWS IAM role mapping',
                action: () => setCreateIAMDialogVisible(true),
              }}
              orgName={orgName}
              roles={roleData?.organization?.roles}
              rolesLoading={rolesLoading}
              loading={credentialsLoading}
            />
          </Tacard>
          <CreateOrgCredentialDialog
            isOpen={createDialogVisible}
            onDismiss={() => setCreateDialogVisible(false)}
            organizationId={organizationId}
            onCredentialCreated={async () => {
              setCreateDialogVisible(false);
              setSnackbar('Service credential created');
              setOpenSnackbar(true);
              await refetchCredentials();
            }}
            isExternalOption={isSecurityAdmin}
          />
          <ConfirmationDialog
            open={confirmModal}
            loading={deleteLoading}
            title={`Delete ${credToDelete?.name}?`}
            acceptText="Delete"
            onDismiss={() => {
              setConfirmModal(false);
              setCredToDelete({} as OrganizationCredential);
            }}
            onAccept={deleteCredential}
          />
          <ConfirmationDialog
            open={AWSIdentityConfirmModal}
            loading={deleteAWSIdentityLoading}
            title={`Delete ${AWSIdentityToDelete?.clientId}?`}
            acceptText="Delete"
            onDismiss={() => {
              setAWSIdentityConfirmModal(false);
              setAWSIdentityToDelete({} as OrgAuth);
            }}
            onAccept={removeAuth}
          />
          <CreateIAMRoleMappingDialog
            organizationId={organizationId}
            isOpen={createIAMDialogVisible}
            onDismiss={() => setCreateIAMDialogVisible(false)}
            onCredentialCreated={async () => {
              setCreateIAMDialogVisible(false);
              setSnackbar('IAM role mapping created');
              setOpenSnackbar(true);
              await refetchCredentials();
            }}
            isExternalOption={false}
          />
          <CreateIdentityProviderDialog
            organizationId={organizationId}
            isOpen={createIdentityProviderDialog}
            onDismiss={() => setCreateIdentityProviderDialog(false)}
            onIdentityProviderCreated={async () => {
              setCreateIdentityProviderDialog(false);
              setSnackbar('AWS IAM identity successfully created');
              setOpenSnackbar(true);
              await refetchAuth();
            }}
          />
          <CreateServiceMappingDialog
            organizationId={organizationId}
            isOpen={createServiceDialogVisible}
            onDismiss={() => setCreateServiceDialogVisible(false)}
            onCredentialCreated={async () => {
              setCreateServiceDialogVisible(false);
              setSnackbar('Service account credential created');
              setOpenSnackbar(true);
              await refetchCredentials();
            }}
          />
          <StatusBars
            successDisplay={openSnackbar}
            handleSuccessClose={() => setOpenSnackbar(false)}
            successMessage={snackbar}
          />
        </>
      ) : (
        <Talert
          severity={'info'}
          sx={{
            maxWidth: 'fit-content',
            marginBottom: 2,
            marginX: 2,
          }}
          alertTitle={'Secured area'}
          customIcon={<PrivacyTipOutlinedIcon fontSize={'small'} />}
        >
          <Typography variant={'helperText'} sx={{ color: 'midnight.seven' }}>
            This area is restricted to Security Admins.
          </Typography>
        </Talert>
      )}
    </>
  );
}
