import React from 'react';

import { Close, EventAvailableOutlined } from '@mui/icons-material';
import { Box, BoxProps, IconButton, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

interface TagDisplayProps extends BoxProps {
  name: string;
  hasIcon: boolean;
  remove?: () => void;
  maxWidth?: number;
  disabled?: boolean;
}

export function TagDisplay({
  name,
  hasIcon,
  remove,
  maxWidth,
  disabled,
  ...props
}: TagDisplayProps) {
  return (
    <Box
      {...props}
      sx={(theme) => ({
        ...(props.sx ? (props.sx as SystemStyleObject) : {}),
        backgroundColor: 'white',
        display: 'flex',
        height: '24px',
        paddingRight: remove ? 0.5 : '12px',
        borderRadius: '4px',
        maxWidth: maxWidth || '650px',
        border: `1px solid ${theme.palette.midnight.seven}`,
        alignItems: 'center',
      })}
    >
      {hasIcon && (
        <Box
          sx={{
            height: '100%',
            minWidth: '24px',
            borderTopLeftRadius: '2px',
            borderBottomLeftRadius: '2px',
            backgroundColor: 'midnight.seven',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EventAvailableOutlined fontSize={`small`} sx={{ color: 'white' }} />
        </Box>
      )}
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          overflow: 'hidden',
          pl: 1,
        }}
      >
        <Typography
          variant={`subtitle2`}
          sx={{
            color: 'midnight.seven',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {name}
        </Typography>
      </Box>
      {remove && (
        <IconButton
          sx={{ height: '12px', width: '12px', ml: '4px' }}
          onClick={remove}
          disabled={disabled}
        >
          <Close
            fontSize={'small'}
            sx={{
              color: disabled ? 'midnight.two' : 'midnight.seven',
              pointer: 'cursor',
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}
