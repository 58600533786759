import React, { memo } from 'react';

import { Stack, LinearProgress, Box, BoxProps } from '@mui/material';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';

import { SnapshotRefProp } from '../SnapshotViewer/SnapshotView';
import DataPreviewToolbar from './DataPreviewToolbar';

interface DataPreviewGridProps extends BoxProps {
  rows: any;
  columns: any;
  loading?: boolean;
  refreshResourceContent?: { title: string; action: () => void };
  breadcrumbs?: React.ReactElement[];
  branchRefs?: SnapshotRefProp[];
  selectedBranch?: string;
  handleChangeBranch?: (branch: string) => void;
  showBranchSelector?: boolean;
  tableName: string;
}
export default memo(function DataPreviewGrid({
  rows,
  columns,
  loading,
  refreshResourceContent,
  breadcrumbs,
  branchRefs,
  selectedBranch,
  handleChangeBranch,
  showBranchSelector,
  tableName,
  ...props
}: DataPreviewGridProps) {
  return (
    <Box {...props}>
      <DataGridPro
        rows={rows || []}
        columns={(columns || []) as GridColumns}
        headerHeight={34}
        autoHeight
        getRowId={(rowData) => {
          return rowData.__row_id;
        }}
        disableColumnMenu
        density="comfortable"
        isRowSelectable={() => false}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No Data
            </Stack>
          ),
          Toolbar: refreshResourceContent && DataPreviewToolbar,
        }}
        componentsProps={{
          toolbar: {
            refreshResourceContent: refreshResourceContent,
            breadcrumbs: breadcrumbs,
            branchRefs: branchRefs,
            selectedBranch: selectedBranch,
            handleChangeBranch: handleChangeBranch,
            showBranchSelector: showBranchSelector,
            displayName: showBranchSelector ? tableName : '',
          },
        }}
        loading={loading}
        sx={{
          border: 'none',
          '.MuiDataGrid-columnHeaders': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
          '.MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: 'dusk.half',
          },
          '.MuiDataGrid-rowIdColumn': {
            backgroundColor: 'dusk.half',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        }}
      />
    </Box>
  );
});
