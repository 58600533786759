import React from 'react';

import { StatusBoardContext } from './StatusBoardContext';

type StatusBoardContextProviderProps = {
  props: {};
  children: React.ReactNode;
};

export function StatusBoardContextProvider({
  props,
  children,
}: StatusBoardContextProviderProps) {
  return (
    <StatusBoardContext.Provider value={props}>
      {children}
    </StatusBoardContext.Provider>
  );
}
