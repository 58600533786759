import React, { useState } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';

import TextField from '../../Forms/TextField';
import { GcsWorkloadIdentityLink } from '../storage';
import { ExternalBreadcrumbs, createPoolbreadcrumbs } from './helpers';

export const WorkloadInstructions = ({
  textField = false,
}: {
  textField?: boolean;
}) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  return (
    <List sx={{ listStyle: 'decimal', pl: 3 }}>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Create a new pool. Enter <b>Pool ID</b> and click continue.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Select provider type <b>AWS</b> and choose a name for <b>Provider</b>.
        </Typography>
      </ListItem>
      <ListItem
        sx={{
          display: 'list-item',
          p: 0,
          mb: 0,
        }}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'body2'}>
            {`Copy Tabular AWS accountId for provider:`}
          </Typography>
          <Typography sx={{ pl: 1, color: 'midnight.seven' }}>
            {process.env.REACT_APP_AWS_ACCOUNT_ID}
          </Typography>
          <Tooltip
            title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
            placement={`top`}
            arrow
          >
            <ContentCopyOutlinedIcon
              onClick={() => {
                copyToClipBoard(process.env.REACT_APP_AWS_ACCOUNT_ID || '');
              }}
              fontSize={'small'}
              sx={{
                cursor: 'pointer',
                marginLeft: 1,
                color: 'brandBlue.main',
                display: 'flex',
                justifySelf: 'flex-end',
              }}
            />
          </Tooltip>
        </Box>
      </ListItem>
      {textField && (
        <ListItem sx={{ display: 'list-item', p: 0, m: 0 }}>
          <Typography variant={'body2'} paddingRight={1}>
            Enter <b>Pool ID</b> and <b>Provider ID</b> below.
          </Typography>
        </ListItem>
      )}
    </List>
  );
};

export default function GoogleWorkloadStep() {
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} sx={{ px: 5, pb: 3 }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant="body1">
            {`Create a Workload Identity Federation pool`}
          </Typography>
        </Box>
        <Card variant={'outlined'} sx={{ borderRadius: 2, mt: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
            <CardHeader
              title={
                <>
                  <Typography variant={'subtitle1'} sx={{ mb: 1 }}>
                    Workload Identity Federation
                  </Typography>
                  <ExternalBreadcrumbs
                    breadcrumbs={createPoolbreadcrumbs}
                    link={GcsWorkloadIdentityLink}
                  />
                </>
              }
              sx={(theme) => ({
                pt: 2,
                borderBottom: `1px solid ${theme.palette.midnight.two}`,
              })}
            />
            <CardContent>
              <WorkloadInstructions textField />
              <Box display={'flex'} flexDirection={'row'}>
                <TextField
                  name="poolId"
                  type="text"
                  label="Pool ID"
                  id="poolId"
                  variant="standard"
                  margin="normal"
                  autoComplete="off"
                  helperText="The Pool ID from the Workload Identity Pool connected to bucket"
                  sx={{ mr: 2 }}
                />
                <TextField
                  name="providerId"
                  type="text"
                  label="Provider ID"
                  id="providerId"
                  variant="standard"
                  margin="normal"
                  autoComplete="off"
                  helperText="Provider ID from the Workload Identity Pool connected to bucket"
                  sx={{ mr: 2 }}
                />
              </Box>
            </CardContent>
          </Box>
        </Card>
      </Box>
    </>
  );
}
