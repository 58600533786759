import React, {
  useEffect,
  MouseEvent,
  useCallback,
  useRef,
  useMemo,
  MutableRefObject,
} from 'react';

// @ts-ignore

import { useMutation, useQuery } from '@apollo/client';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  BoxProps,
  Divider,
  Grid,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GridRowId, GridRowModel } from '@mui/x-data-grid';
import { Form, Formik, FormikValues } from 'formik';
import { groupBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { TableEditContext } from './TableEditContext';

import { useAuth } from '../../context/auth-context';
import { getAuthorizationDecision } from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  AuthDecisionSubjectType,
  Privilege,
  Table,
  TableMetadata,
  User,
} from '../../graphql/gen/graphql';
import { createTable, patchTable, registerTable } from '../../graphql/table';
import { getApolloErrorMessage } from '../../utils/ApolloUtils';
import { getLogger } from '../../utils/logging';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import { MenuContentType } from '../DataGridToolbar/DataGridToolbar';
import TextField from '../Forms/TextField';
import PartitionEdit, { PartitionFieldRow } from './PartitionEdit';
import PropertiesEdit, { PropertiesRow } from './PropertiesEdit';
import SchemaEdit, { SchemaFieldRow } from './SchemaEdit';
import SortOrderEdit, { SortOrderFieldRow } from './SortOrderEdit';
import { TableEditBody } from './TableEditBody';
import { TableEditChangelogDetails } from './TableEditChangelogDetails';
import { TableEditCreateHeader } from './TableEditCreateHeader';
import {
  generateCreateTableRequest,
  getIdGenerator,
  getPartitionFieldModelFromMetadata,
  getSchemaModelFromMetadata,
  getSortOrderFieldModelFromMetadata,
  generateEditTableRequest,
  createCDCTargetSchemaModel,
  createCDCChangelogSchemaModel,
  createCDCTargetPropertiesModel,
  createCDCChangelogSortModel,
} from './TableEditHelpers';

const logger = getLogger(
  'components.TableEdit.TableEdit' /*FUTURE import.meta.url ?*/,
);

export interface TableEditTab {
  validationRef: MutableRefObject<number>;
  label: string;
  body: JSX.Element;
  key: string;
}
export enum EditTableMode {
  CREATE,
  CREATE_LIKE,
  CREATE_LOAD,
  EDIT,
  VIEW,
  CREATE_CDC_PIPELINE,
  REGISTER,
}

export const TABLE_GRID_NO_ROWS_HEIGHT = 264;
export const CHANGELOG_SORT_NO_ROWS_HEIGHT = 100;

export const GridDivider = (props: any) => {
  return (
    <Divider
      orientation="vertical"
      sx={{ ...props.sx, height: 0.5, ml: 2, mr: 2 }}
    ></Divider>
  );
};

const getStandardGridSx = (
  hideTableHeader: boolean,
  mode: EditTableMode,
  theme: Theme,
) => {
  return {
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
      {
        outline: 'none !important',
      },

    ...(hideTableHeader
      ? {
          '& .grid-toolbar': {
            //height: 28,
          },
          '& .MuiDataGrid-columnHeaders': {
            display: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            mt: '0!important' as any,
          },
          '& .MuiDataGrid-root .MuiDataGrid-cell, .MuiInputBase-root .MuiInput-root':
            {
              p: 0,
            },
          '& .MuiTextField-root': {
            m: 0,
            p: 0,
          },
          '& .MuiDataGrid-cell--editing': {
            p: 0,
          },
        }
      : {
          '& .MuiDataGrid-overlay': { pt: '56px' },
          '& .MuiDataGrid-cell--editing': {
            pl: '10px!important',
            pr: '10px!important',
          },
          '& .MuiFormControl-root .MuiTextField-root': {
            p: 0,
          },
        }),
    boxShadow: theme.shadows[4],
    mb: '24px',

    ...(mode === EditTableMode.VIEW
      ? {
          '& .MuiDataGrid-detailPanel': {},
          '& .MuiDataGrid-root': {
            minHeight: 60,
          },
        }
      : {}),

    ...(mode !== EditTableMode.VIEW
      ? {
          '& .MuiDataGrid-root': {
            minHeight: 60,
          },
        }
      : {}),
  };
};

export interface TableEditedContext {
  table: Table;
}
export interface CDCPipelineCreatedContext extends TableEditedContext {
  targetTable: Table;
}

export interface TableEditProps extends BoxProps {
  warehouseId: string;
  warehouseName?: string;
  database: string;
  onCancel?: () => void;
  onBack?: () => void;
  onTableEdited?: (
    context: TableEditedContext | CDCPipelineCreatedContext,
  ) => void;
  mode: EditTableMode;
  newTableName?: string;
  table?: Table;
  creationMetadata?: TableMetadata;
  stagedTableId?: string;
  refreshResourceContent?: MenuContentType;
  editResourceContent?: MenuContentType;
  dashboardMenuContent?: JSX.Element;
  metadataLocation?: string;
}

const DIALOG_BOX_ID = 'createTableDialogBackground';
export default function TableEdit({
  warehouseId,
  warehouseName,
  database,
  onCancel,
  onBack,
  onTableEdited,
  mode,
  table,
  newTableName,
  creationMetadata,
  stagedTableId,
  refreshResourceContent,
  editResourceContent,
  dashboardMenuContent,
  metadataLocation,
  ...props
}: TableEditProps) {
  const {
    user,
  }: {
    user: any;
  } = useAuth();
  const organization = user.loginSession.loggedInOrg;

  const shouldShowBackButton =
    mode === EditTableMode.CREATE_LOAD ||
    mode === EditTableMode.CREATE_LIKE ||
    mode === EditTableMode.CREATE_CDC_PIPELINE ||
    mode === EditTableMode.REGISTER;

  const [allDone, setAllDone] = React.useState<boolean>(false);
  const [createTableMutation, { error, reset }] = useMutation(createTable);
  const [patchTableMutation, { error: patchError, reset: patchReset }] =
    useMutation(patchTable);
  const [
    registerTableMutation,
    { error: registerError, reset: registerReset },
  ] = useMutation(registerTable);
  const theme = useTheme();
  const { data: updateDecision } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        privileges: [Privilege.Update],
        requirement: 'ALL',
        resource: {
          type: AuthDecisionResourceType.Table,
          identifier: table?.id,
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const userCanEdit =
    updateDecision?.authDecision === AuthDecisionResponseType.Allow;
  const editTableMetadata = table?.metadata
    ? table?.metadata
    : creationMetadata
    ? creationMetadata
    : undefined;
  const tableName = table?.name;
  const tableId = table?.id;

  const fieldLabels = groupBy(table?.fieldLabels, (fieldLabel) => {
    return fieldLabel?.fieldId;
  });

  const {
    deserializedSchemaModel,
    schemaFieldToToRowIdMap,
    initialValidityModel,
  } = useMemo(() => {
    return getSchemaModelFromMetadata(editTableMetadata, fieldLabels);
  }, [editTableMetadata, fieldLabels]);

  const deserializedPartitionFieldModel = useMemo(() => {
    return getPartitionFieldModelFromMetadata(
      editTableMetadata,
      schemaFieldToToRowIdMap,
      deserializedSchemaModel,
    );
  }, [editTableMetadata, fieldLabels]);

  const deserializedSortOrderFieldModel = useMemo(() => {
    return getSortOrderFieldModelFromMetadata(
      editTableMetadata,
      schemaFieldToToRowIdMap,
      deserializedSchemaModel,
    );
  }, [editTableMetadata, fieldLabels]);

  const [schemaModel, setSchemaModel] = React.useState<
    Record<string, GridRowModel<SchemaFieldRow>[]>
  >(deserializedSchemaModel);

  const noOpColumnFound =
    mode === EditTableMode.CREATE_CDC_PIPELINE &&
    schemaModel['top'] &&
    !schemaModel['top'].find(
      (eachField) => eachField?.name?.toLowerCase() == 'op',
    );

  const [partitionFieldModel, setPartitionFieldModel] = React.useState<
    GridRowModel<PartitionFieldRow>[]
  >(deserializedPartitionFieldModel);

  const [sortOrderFieldModel, setSortOrderFieldModel] = React.useState<
    GridRowModel<SortOrderFieldRow>[]
  >(deserializedSortOrderFieldModel);

  const [changelogSortOrderFieldModel, setChangelogSortOrderFieldModel] =
    React.useState<GridRowModel<SortOrderFieldRow>[]>([]);

  const [propertiesModel, setPropertiesModel] = React.useState<
    GridRowModel<PropertiesRow>[]
  >([]);

  const [submitNextLoop, setSubmitNextLoop] = React.useState<boolean>(false);

  const [validityModel, setValidityModel] =
    React.useState<Record<GridRowId, number>>(initialValidityModel);
  const [descriptionRows, setDescriptionRows] = React.useState<number>(1);
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [wizardStepIndex, setWizardStepIndex] = React.useState<number>(0);

  //trigger used to tell all edit controls to validate and report back
  const [triggerVersion, setTriggerVersion] = React.useState<number>(-1);
  const triggerVersionRef = useRef(triggerVersion);

  //ability for any edit control to cancel submission
  const [isCancelled, setIsCancelled] = React.useState<boolean>(false);
  const isCancelledRef = useRef(isCancelled);

  //schemaEdit validation state
  const [lastValidSchemaVersion, setLastValidSchemaVersion] =
    React.useState<number>(-1);
  const lastValidSchemaVersionRef = useRef(lastValidSchemaVersion);

  //partitionEdit validation state
  const [lastValidPartitionVersion, setLastValidPartitionVersion] =
    React.useState<number>(-1);
  const lastValidPartitionVersionRef = useRef(lastValidPartitionVersion);

  //sortOrderEdit validation state
  const [lastValidSortOrderVersion, setLastValidSortOrderVersion] =
    React.useState<number>(-1);
  const lastValidSortOrderVersionRef = useRef(lastValidSortOrderVersion);

  //propertiesEdit validation state
  const [lastValidPropertiesVersion, setLastValidPropertiesVersion] =
    React.useState<number>(-1);
  const lastValidPropertiesVersionRef = useRef(lastValidPropertiesVersion);

  //changelogSortOrderEdit validation state
  const [
    lastValidChangelogSortOrderVersion,
    setLastValidChangelogSortOrderVersion,
  ] = React.useState<number>(-1);
  const lastValidChangelogSortOrderVersionRef = useRef(
    lastValidChangelogSortOrderVersion,
  );

  const tabRef = useRef<HTMLButtonElement | null>(null);
  const scrollRef = useRef<HTMLElement | null>(null);

  const [showError, setShowError] = React.useState<boolean>(false);

  const schemaIsReady = useMemo(() => {
    return triggerVersion == lastValidSchemaVersion;
  }, [triggerVersion, lastValidSchemaVersion]);

  const noIdColumnFound = useMemo(() => {
    if (schemaIsReady) {
      return (
        mode === EditTableMode.CREATE_CDC_PIPELINE &&
        schemaModel['top'] &&
        Object.values(schemaModel)
          .flatMap((eachField) => eachField)
          .filter((eachField) => eachField?.identity).length == 0
      );
    }
    return true;
  }, [schemaModel, schemaIsReady]);

  useEffect(() => {
    triggerVersionRef.current = triggerVersion;
    isCancelledRef.current = isCancelled;
    lastValidSchemaVersionRef.current = lastValidSchemaVersion;
    lastValidPartitionVersionRef.current = lastValidPartitionVersion;
    lastValidSortOrderVersionRef.current = lastValidSortOrderVersion;
    lastValidPropertiesVersionRef.current = lastValidPropertiesVersion;
    lastValidChangelogSortOrderVersionRef.current =
      lastValidChangelogSortOrderVersion;
  }, [
    triggerVersion,
    isCancelled,
    lastValidSchemaVersion,
    lastValidPartitionVersion,
    lastValidSortOrderVersion,
    lastValidPropertiesVersion,
    lastValidChangelogSortOrderVersion,
  ]);

  interface FormValues {
    name: string;
    description: string;
    changelogDatabaseName: string;
    cdcTargetLagMinutes: number;
  }

  const existingTableDescriptionProperty = table
    ? table?.properties?.find((prop) => prop != null && prop.key === 'comment')
    : undefined;

  const mbrDefRoleId =
    (user as User).loginSession?.membership?.defaultRoleId || '';

  const initialValues: FormValues = {
    name: tableName ? tableName : newTableName ? newTableName : '',
    description:
      existingTableDescriptionProperty && existingTableDescriptionProperty.value
        ? existingTableDescriptionProperty.value
        : '',
    changelogDatabaseName: database,
    cdcTargetLagMinutes: 5,
  };

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikValues,
  ) => {
    logger.debug('About to build table req!', schemaModel);

    if (
      mode === EditTableMode.CREATE ||
      mode === EditTableMode.CREATE_LIKE ||
      mode === EditTableMode.CREATE_LOAD
    ) {
      const schemaColumns = schemaModel['top'];
      const generator = getIdGenerator();
      const partitionFieldGenerator = getIdGenerator();
      let schemaFieldNameMap = new Map<string, number>();
      const request = generateCreateTableRequest({
        tableName: values.name,
        tableDescription: values.description,
        schemaColumns,
        schemaModel,
        partitionFieldModel,
        sortOrderFieldModel,
        propertiesModel,
        schemaFieldNameMap,
        generator,
        partitionFieldGenerator,
        stagedTableId,
      });

      logger.debug('About to request table creation', request);
      const result = await createTableMutation({
        variables: {
          warehouseId,
          database,
          request: request,
        },
      });
      if (result.data && result.data.createTable) {
        // reset the state of the mutation
        reset();
        if (onTableEdited) {
          setAllDone(true);
          onTableEdited({ table: result.data.createTable });
        }
      }
    } else if (mode === EditTableMode.REGISTER) {
      await registerTableMutation({
        variables: {
          warehouseId,
          database,
          name: values.name,
          metadataLocation,
        },
      })
        .then((result) => {
          if (result.data && result.data.registerTable) {
            // reset the state of the mutation
            registerReset();
            if (onTableEdited) {
              setAllDone(true);
              onTableEdited({ table: result.data.registerTable });
            }
          }
        })
        .catch((error) => {
          logger.debug(getApolloErrorMessage(error));
        });
    } else if (mode === EditTableMode.EDIT) {
      const schemaColumns = schemaModel['top'];
      const generator = getIdGenerator();
      const partitionFieldGenerator = getIdGenerator();
      let schemaFieldNameMap = new Map<string, number>();
      const request = generateEditTableRequest({
        schemaColumns,
        schemaModel,
        partitionFieldModel,
        sortOrderFieldModel,
        propertiesModel,
        schemaFieldNameMap,
        generator,
        partitionFieldGenerator,
      });

      logger.debug('About to request table patch', request);
      const result = await patchTableMutation({
        variables: {
          warehouseId,
          database,
          tableName: values.name,
          request: request,
          dryRun: false,
        },
      });
      if (result.data && result.data.patchTable) {
        // reset the state of the mutation
        patchReset();
        if (onTableEdited) {
          onTableEdited({ table: result.data.patchTable });
        }

        logger.debug('Changes: ', result.data.patchTable.schemaChanges);
        logger.debug(
          'TableUpdateRequest: ',
          result.data.patchTable.updateTableRequest,
        );
      }
    } else if (mode === EditTableMode.CREATE_CDC_PIPELINE) {
      const changelogGenerator = getIdGenerator();
      const changelogPartitionFieldGenerator = getIdGenerator();
      const targetGenerator = getIdGenerator();
      const targetPartitionFieldGenerator = getIdGenerator();
      let changelogSchemaFieldNameMap = new Map<string, number>();
      let targetSchemaFieldNameMap = new Map<string, number>();

      //schemaModel will represent the changelog schema, it's op field has been hidden from the user so far
      //create a target schema model sans the op field
      const targetSchemaModel = createCDCTargetSchemaModel(schemaModel);
      const changelogSchemaModel = createCDCChangelogSchemaModel(schemaModel);

      const targetPropertiesModel = createCDCTargetPropertiesModel(
        propertiesModel,
        changelogSortOrderFieldModel,
        values.cdcTargetLagMinutes,
      );

      const changelogSortModel = createCDCChangelogSortModel(
        changelogSchemaModel,
        changelogSortOrderFieldModel,
      );

      //we are creating two tables; the changelog table with OG schema and the target table sans op field

      const targetSchemaColumns = targetSchemaModel['top'];
      const targetCreateTableRequest = generateCreateTableRequest({
        tableName: values.name,
        tableDescription: values.description,
        schemaColumns: targetSchemaColumns,
        schemaModel: targetSchemaModel,
        partitionFieldModel: partitionFieldModel,
        sortOrderFieldModel: sortOrderFieldModel,
        propertiesModel: targetPropertiesModel,
        schemaFieldNameMap: targetSchemaFieldNameMap,
        generator: targetGenerator,
        partitionFieldGenerator: targetPartitionFieldGenerator,
        stagedTableId: undefined,
      });

      const changelogSchemaColumns = changelogSchemaModel['top'];
      const changeLogCreateTableRequest = generateCreateTableRequest({
        tableName: `${values.name}_changelog`,
        tableDescription: `CDC changelog for target table ${database}.${values.name}`,
        schemaColumns: changelogSchemaColumns,
        schemaModel: changelogSchemaModel,
        partitionFieldModel: [],
        sortOrderFieldModel: changelogSortModel,
        //the rest of the bootstrap fileloader properties are currently done in the workflow init
        propertiesModel: [
          {
            rowId: uuidv4(),
            isNew: false,
            name: 'fileloader.transforms',
            value: 'dms',
          },
        ],
        schemaFieldNameMap: changelogSchemaFieldNameMap,
        generator: changelogGenerator,
        partitionFieldGenerator: changelogPartitionFieldGenerator,
        stagedTableId: undefined,
      });

      logger.debug(
        'About to request target table creation',
        targetCreateTableRequest,
      );
      const targetTableResult = await createTableMutation({
        variables: {
          warehouseId,
          database,
          request: targetCreateTableRequest,
        },
      });
      if (targetTableResult.data && targetTableResult.data.createTable) {
        // reset the state of the mutation
        reset();
      } else {
        logger.error(
          'Could not create target table, aborting full creation of pipeline.',
        );
        return;
      }

      logger.debug(
        'About to request changelog table creation',
        changeLogCreateTableRequest,
      );
      const changelogTableResult = await createTableMutation({
        variables: {
          warehouseId,
          database: values.changelogDatabaseName,
          request: changeLogCreateTableRequest,
        },
      });
      if (
        changelogTableResult.data &&
        changelogTableResult.data.createTable &&
        targetTableResult.data &&
        targetTableResult.data.createTable
      ) {
        // reset the state of the mutation
        reset();
        if (onTableEdited) {
          setAllDone(true);
          onTableEdited({
            table: changelogTableResult.data.createTable,
            targetTable: targetTableResult.data.createTable,
          });
        }
      } else {
        logger.error(
          'Could not create changelog table, aborting full creation of pipeline.',
        );
        return;
      }
    }

    setSubmitting(false);
  };
  const handleCancelSubmission = (
    level: number,
    reason: string,
    shouldTryAgainNextLoop: boolean,
  ) => {
    logger.debug(
      `TableEdit: Submission cancelled from level ${level} because ${reason} and shouldTryAgainNextLoop is ${shouldTryAgainNextLoop}`,
    );
    setSubmitNextLoop(false);
    if (!shouldTryAgainNextLoop) {
      setIsCancelled(true);
    }
  };

  interface ValidationProp {
    wizardStep: number;
    ref: React.MutableRefObject<number>;
  }

  const pullTrigger = useCallback(
    async (validationRef: React.MutableRefObject<number>, label: string) => {
      let newTriggerVersion: number = triggerVersionRef.current + 1;

      setIsCancelled(false);
      isCancelledRef.current = false;
      setTriggerVersion(newTriggerVersion);
      logger.debug(
        `Trigger attempt v.${newTriggerVersion} started from tableEdit !`,
      );
      const triggerPromise = new Promise(function (resolve, reject) {
        let numTries = 0;
        const checkStatus = () => {
          numTries++;
          logger.debug(
            `checking trigger result: Looking for ${newTriggerVersion} for wizard step "${label}" lastValidVersion is ${validationRef.current}`,
          );
          if (validationRef.current === newTriggerVersion) {
            logger.debug(
              `Validation trigger looks good for "${label}". resolving...`,
            );
            resolve(validationRef.current);
            return;
          } else if (isCancelledRef.current) {
            logger.debug(`Someone cancelled, rejecting...`);
            reject();
            return;
          }
          if (numTries < 10) {
            setTimeout(checkStatus, 50 + numTries * 50);
          } else {
            reject();
          }
        };
        checkStatus();
      });

      return triggerPromise;
    },
    [],
  );

  const tableEditTabs: TableEditTab[] = [
    {
      key: 'tableSchema',
      label: 'Schema',
      body: (
        <Box sx={{ m: allDone ? 2 : 0 }}>
          <SchemaEdit
            baseType={'struct'}
            currentModelKey={'top'}
            validityChanged={(isValid: number) => {
              logger.debug(
                `Schema validity changed! SchemaEdit reports v.${isValid} is valid.  ${
                  isValid == triggerVersion
                    ? 'Ready for Submit! 🏄‍♀️'
                    : 'NOT READY 😢'
                }`,
              );
              setLastValidSchemaVersion(isValid);
            }}
            level={0}
            title={''}
            refreshResourceContent={refreshResourceContent}
            editResourceContent={editResourceContent}
            dashboardMenuContent={dashboardMenuContent}
            sx={getStandardGridSx(true, mode, theme)}
            existingTable={table}
          />
        </Box>
      ),
      validationRef: lastValidSchemaVersionRef,
    },

    {
      key: 'partitionSpec',
      label: 'Partitioning',
      body: (
        <PartitionEdit
          validityChanged={(isValid: number) => {
            logger.debug(
              `PartitionSpec validity changed! PartitionEdit reports v.${isValid} is valid.  ${
                isValid == triggerVersion
                  ? 'Ready for Submit! 🏄‍♀️️'
                  : 'NOT READY 😢'
              }`,
            );
            setLastValidPartitionVersion(isValid);
          }}
          sx={getStandardGridSx(false, mode, theme)}
        />
      ),
      validationRef: lastValidPartitionVersionRef,
    },
    {
      key: 'sortOrderEdit',
      label: 'Ordering',
      body: (
        <SortOrderEdit
          validityChanged={(isValid: number) => {
            logger.debug(
              `SortOrder validity changed! SortOrder reports v.${isValid} is valid.  ${
                isValid == triggerVersion
                  ? 'Ready for Submit! 🏄‍♀️'
                  : 'NOT READY 😢'
              }`,
            );
            setLastValidSortOrderVersion(isValid);
          }}
          sx={getStandardGridSx(false, mode, theme)}
        />
      ),
      validationRef: lastValidSortOrderVersionRef,
    },

    ...(mode === EditTableMode.CREATE ||
    mode === EditTableMode.CREATE_LIKE ||
    mode === EditTableMode.CREATE_LOAD ||
    mode === EditTableMode.CREATE_CDC_PIPELINE
      ? [
          {
            key: 'propertiesEdit',
            label: 'Custom properties',
            body: (
              <PropertiesEdit
                validityChanged={(isValid: number) => {
                  logger.debug(
                    `Properties validity changed! Properties reports v.${isValid} is valid.  ${
                      isValid == triggerVersion
                        ? 'Ready for Submit! 🏄‍♀️'
                        : 'NOT READY 😢'
                    }`,
                  );
                  setLastValidPropertiesVersion(isValid);
                }}
                sx={getStandardGridSx(true, mode, theme)}
              />
            ),
            validationRef: lastValidPropertiesVersionRef,
          },
        ]
      : []),
  ];

  const _handleTabChange = useCallback(
    (index: number) => {
      if (mode === EditTableMode.VIEW || mode === EditTableMode.REGISTER) {
        setTabIndex(index);
      } else {
        pullTrigger(
          tableEditTabs[tabIndex].validationRef,
          tableEditTabs[tabIndex].label,
        )
          .then((validVersion) => {
            logger.debug(
              `Promise resolved with validation ${validVersion},  going next.`,
            );
            setTabIndex(index);
          })
          .catch(() => {
            logger.debug('Promise rejected, not going next.');
          });
      }
    },
    [tabIndex, tableEditTabs],
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    _handleTabChange(newValue);
  };

  const wizardSteps = [
    ...(mode !== EditTableMode.CREATE_CDC_PIPELINE
      ? []
      : [
          {
            back: {
              buttonText: 'Back',
              onBack: () => {
                reset();
                if (onBack) {
                  onBack();
                }
              },
            },
            next: {
              isDisabled: () => {
                return !(changelogSortOrderFieldModel.length > 0);
              },
              buttonText: 'Review Target Schema',
              onNext: () => {
                pullTrigger(
                  lastValidChangelogSortOrderVersionRef,
                  'ChangelogSortOrder',
                )
                  .then(() => {
                    setWizardStepIndex(1);
                  })
                  .catch((error) => {
                    logger.debug('Failed to step navigate to cdc wiz page 2.');
                  });
              },
            },
            body: (
              <>
                {noOpColumnFound && (
                  <Talert severity={'warning'}>
                    The files you have chosen do not have an op column. Change
                    files must contain an op column, yet initial DB dump files
                    may have it omitted.
                  </Talert>
                )}
                {
                  <>
                    <Typography
                      variant={'subtitle1'}
                      sx={{ mb: 1 }}
                    >{`Changelog settings`}</Typography>
                    <TableEditChangelogDetails
                      sx={{ mb: 2 }}
                      organizationId={organization.id}
                      warehouseId={warehouseId}
                      validityChanged={(isValid: number) => {
                        logger.debug(
                          `ChangelogSortOrder validity changed! ChangelogSortOrder reports v.${isValid} is valid.  ${
                            isValid == triggerVersion
                              ? 'Ready for Submit! 🏄‍♀️'
                              : 'NOT READY 😢'
                          }`,
                        );
                        setLastValidChangelogSortOrderVersion(isValid);
                      }}
                    ></TableEditChangelogDetails>
                    <Typography
                      variant={'subtitle1'}
                      sx={{ mb: 1 }}
                    >{`Pipeline settings`}</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={8} lg={4}>
                        <TextField
                          fullWidth
                          name={'cdcTargetLagMinutes'}
                          label="Merge Frequency (Min)"
                          helperText={
                            'The number of minutes to wait between merge operations (0=ASAP)'
                          }
                          variant="outlined"
                          margin="normal"
                          autoComplete="off"
                          required
                        ></TextField>
                      </Grid>
                    </Grid>
                  </>
                }
              </>
            ),
          },
        ]),
    {
      back: {
        buttonText: 'Back',
        onBack: () => {
          if (mode === EditTableMode.CREATE_CDC_PIPELINE) {
            setWizardStepIndex(0);
          } else {
            reset();
            if (onBack) {
              onBack();
            }
          }
        },
      },
      next: {
        isDisabled: () => {
          //return mode === EditTableMode.CREATE_CDC_PIPELINE && noIdColumnFound;

          return false;
        },
        buttonText:
          mode === EditTableMode.CREATE_LOAD
            ? `Create and Load`
            : mode === EditTableMode.CREATE ||
              mode === EditTableMode.CREATE_LIKE
            ? 'Create'
            : mode === EditTableMode.CREATE_CDC_PIPELINE
            ? 'Create Pipeline'
            : mode === EditTableMode.REGISTER
            ? 'Register'
            : 'Alter',
        onNext: () => {
          setIsCancelled(false);
          pullTrigger(
            tableEditTabs[tabIndex].validationRef,
            tableEditTabs[tabIndex].label,
          )
            .then(() => {
              setSubmitNextLoop(true);
            })
            .catch((error) => {
              logger.debug('Failed to start submit.');
            });
          //}
        },
      },
      body: (
        <>
          {mode === EditTableMode.CREATE_CDC_PIPELINE && (
            <>
              {noIdColumnFound && (
                <Typography sx={{ mt: 1, mb: 1 }}>
                  Specify the primary key for your data using the ID checkboxes
                  within your target schema. (ID columns must be required)
                </Typography>
              )}
              <Typography
                variant={'subtitle1'}
              >{`Target table schema`}</Typography>
            </>
          )}

          {mode === EditTableMode.EDIT && (
            <Typography variant={'subtitle1'}>{`${tableName}`}</Typography>
          )}
          {(mode === EditTableMode.CREATE ||
            mode === EditTableMode.CREATE_LIKE ||
            mode === EditTableMode.CREATE_LOAD ||
            mode === EditTableMode.CREATE_CDC_PIPELINE ||
            mode === EditTableMode.REGISTER) && (
            <TableEditCreateHeader
              descriptionRows={descriptionRows}
              showDescription={mode !== EditTableMode.REGISTER}
            />
          )}
          <TableEditBody
            mode={mode}
            scrollRef={scrollRef}
            tabRef={tabRef}
            tabIndex={tabIndex}
            editTabs={tableEditTabs}
            handleTabChange={handleTabChange}
          />
        </>
      ),
    },
  ];

  const _handleWizardNext = useCallback(() => {
    wizardSteps[wizardStepIndex].next.onNext();
  }, [wizardStepIndex, wizardSteps]);
  const handleWizardNext = (event: React.SyntheticEvent) => {
    _handleWizardNext();
  };

  const isFieldInUse = (
    fieldId: number,
  ): { inUse: boolean; locationString: string } => {
    let retVal = { inUse: false, locationString: '' };
    for (let idx in partitionFieldModel) {
      if (partitionFieldModel[idx].sourceId === fieldId) {
        retVal.inUse = true;
        retVal.locationString += 'P';
        break;
      }
    }
    for (let idx in sortOrderFieldModel) {
      if (sortOrderFieldModel[idx].sourceId === fieldId) {
        retVal.inUse = true;
        retVal.locationString += 'W';
        break;
      }
    }

    return retVal;
  };

  return (
    <TableEditContext.Provider
      value={{
        mode,
        schemaModel:
          mode === EditTableMode.VIEW ? deserializedSchemaModel : schemaModel,
        setSchemaModel,
        schemaValidityModel: validityModel,
        setSchemaValidityModel: setValidityModel,
        triggerValidation: triggerVersion,
        isCancelled,
        cancelSubmission: handleCancelSubmission,
        partitionFieldModel:
          mode === EditTableMode.VIEW
            ? deserializedPartitionFieldModel
            : partitionFieldModel,
        setPartitionFieldModel,
        sortOrderFieldModel:
          mode === EditTableMode.VIEW
            ? deserializedSortOrderFieldModel
            : sortOrderFieldModel,
        setSortOrderFieldModel,
        changelogSortOrderFieldModel,
        setChangelogSortOrderFieldModel,
        propertiesModel,
        setPropertiesModel,
        isFieldInUse,
        organization: organization,
        warehouseId,
        warehouseName: warehouseName || '',
        database,
        tableName,
        tableId,
        editTableEnabled: userCanEdit,
      }}
    >
      <Box
        id={DIALOG_BOX_ID}
        {...props}
        onClick={(event: MouseEvent<HTMLDivElement>) => {
          logger.debug(
            // @ts-ignore
            `box clicked. id: ->${event.target.id}<- !`,
            event,
          );
          // @ts-ignore
          if (event.target.id === DIALOG_BOX_ID) {
            pullTrigger(
              tableEditTabs[tabIndex].validationRef,
              tableEditTabs[tabIndex].label,
            )
              .then(() => {
                logger.debug('resolved after box click.');
              })
              .catch((reason) => {
                logger.debug(`error after box click. ${reason}`);
              });
          }
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            name: Yup.string()
              .required('Required')
              .matches(
                /^[a-zA-Z0-9_-]*$/,
                'Table name may only contain alpha-numeric characters and ' +
                  " ('_' or '-') and may not contain any spaces.",
              ),
            ...(mode === EditTableMode.CREATE_CDC_PIPELINE
              ? {
                  changelogDatabaseName: Yup.string()
                    .required('Required')
                    .matches(
                      /^[a-zA-Z0-9_-]*$/,
                      'Table name may only contain alpha-numeric characters and ' +
                        " ('_' or '-') and may not contain any spaces.",
                    ),
                  cdcTargetLagMinutes: Yup.number()
                    .required('Required')
                    .moreThan(-1, 'Must be zero or more minutes (zero=ASAP)'),
                }
              : {}),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, status, submitForm, values }) => {
            useEffect(() => {
              if (submitNextLoop) {
                setSubmitNextLoop(false);
                if (
                  mode === EditTableMode.CREATE_CDC_PIPELINE &&
                  schemaIsReady &&
                  noIdColumnFound
                ) {
                  setShowError(true);
                  return;
                }
                submitForm();
              }
            }, [submitNextLoop, setSubmitNextLoop, lastValidSchemaVersion]);

            useEffect(() => {
              if (values.description) {
                const rows = values.description.split(/\r\n|\r|\n/).length || 1;

                setDescriptionRows(rows >= 4 ? 4 : rows);
              }
            }, [values]);

            return (
              <Form>
                {error ? (
                  <Talert severity="error" onClose={() => reset()}>
                    Error:
                    {getApolloErrorMessage(error)}
                  </Talert>
                ) : null}
                {patchError ? (
                  <Talert severity="error" onClose={() => patchReset()}>
                    Error:
                    {getApolloErrorMessage(patchError)}
                  </Talert>
                ) : null}
                {registerError ? (
                  <Talert severity="error" onClose={() => registerReset()}>
                    Error:
                    {getApolloErrorMessage(registerError)}
                  </Talert>
                ) : null}

                <DialogContent
                  sx={{
                    pt: 0,
                    ...(mode === EditTableMode.VIEW ? { m: 0, p: 0 } : {}),
                  }}
                >
                  {wizardSteps[wizardStepIndex]?.body}
                </DialogContent>

                {mode !== EditTableMode.VIEW && !allDone && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      left: 0,
                      pr: 2,
                      pb: 2,
                      pl: 2,

                      backgroundColor: 'controlBackground.main',
                      zIndex: 20000,
                    }}
                  >
                    <DialogActions
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: shouldShowBackButton
                          ? 'space-between'
                          : '',
                      }}
                    >
                      {showError &&
                        mode === EditTableMode.CREATE_CDC_PIPELINE &&
                        noIdColumnFound && (
                          <>
                            <Box
                              sx={{
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Talert
                                severity={'error'}
                                onClose={() => setShowError(false)}
                              >
                                Primary key is required, select the Id columns
                                in your schema.
                              </Talert>
                            </Box>

                            <Box sx={{ flexBasis: '100%' }}></Box>
                          </>
                        )}
                      {shouldShowBackButton && (
                        <Box sx={{ mr: 4 }}>
                          <Tabutton
                            onClick={wizardSteps[wizardStepIndex].back.onBack}
                          >
                            <ArrowBackIcon />
                            {wizardSteps[wizardStepIndex].back.buttonText}
                          </Tabutton>
                        </Box>
                      )}

                      <Box>
                        <Button
                          onClick={() => {
                            reset();
                            if (onCancel) {
                              onCancel();
                            }
                          }}
                          sx={{ mr: 4 }}
                        >
                          Cancel
                        </Button>

                        <Button
                          type="button"
                          variant="contained"
                          sx={{ mr: 2, ml: 2 }}
                          disabled={
                            isSubmitting ||
                            submitNextLoop ||
                            wizardSteps[wizardStepIndex]?.next?.isDisabled()
                          }
                          onClick={handleWizardNext}
                        >
                          {wizardSteps[wizardStepIndex]?.next?.buttonText}
                        </Button>
                      </Box>
                    </DialogActions>
                  </Box>
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
    </TableEditContext.Provider>
  );
}
