import React from 'react';

import { Box, BoxProps, Theme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { SxProps } from '@mui/system/styleFunctionSx';

export interface CustomIconProps {
  src?: string;
  icon?: typeof SvgIcon;
  alt?: string;
  sx?: SxProps<Theme>;
}

export const CustomIcon = ({ src, icon, sx, alt }: CustomIconProps) => {
  const Icon = icon;
  if (Icon) {
    return <Icon sx={sx} titleAccess={alt} />;
  }
  return <Box component={'img'} src={src} sx={sx} alt={alt} />;
};
