import React from 'react';
import { Outlet } from 'react-router-dom';

import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';

import AppBar from '../AppBar/AppBar';
import BaseLayout from './BaseLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '112px',
  },
}));

export default function AppBarLayout() {
  const toolbarSt = useStyles();

  return (
    <BaseLayout>
      {/* Logo, top-level nav, and user profile menu */}
      <AppBar />
      {/* Empty toolbar to push down the rest of the content */}
      <Toolbar classes={{ root: toolbarSt.root }} />
      {/* Main page content */}
      <Outlet />
    </BaseLayout>
  );
}
