import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { SystemProps } from '@mui/system';

import { toTableRoot } from '../../RouteTable';
import { useAuth } from '../../context/auth-context';
import { Database, Table, Warehouse } from '../../graphql/gen/graphql';
import { warehouseById } from '../../graphql/warehouse';
import { STATISTICS_DAYS_BACK } from '../../pages/helpers';
import { Tabutton } from '../Button/Tabutton';
import { Tacard } from '../Card/Tacard';
import { RoleSelector } from '../Forms/RoleSelector';
import TextField from '../Forms/TextField';
import { Tabulink } from '../Link/Tabulink';
import ChangelogOrderEdit from './ChangelogOrderEdit';

export interface CDCPipelineCreationSummaryProps extends BoxProps {
  warehouse: Warehouse;
  changelogTable?: Table;
  targetTable?: Table;
  onClose: () => void;
}

export const CDCPipelineCreationSummary = ({
  warehouse,
  changelogTable,
  targetTable,
  onClose,
  ...props
}: CDCPipelineCreationSummaryProps) => {
  const { user } = useAuth();
  const organizationName = user.loginSession.loggedInOrg.name;
  return (
    <Box
      {...props}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
        props.sx as SystemProps,
      ]}
    >
      {changelogTable && targetTable && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant={'subtitle1'} sx={{ mb: 4 }}>
              Successfully began creation of the CDC pipeline!
            </Typography>
            <Typography>
              Your tables have been created and the changelog table load has
              been initiated. Once the load is complete and auto file loader
              activity has been detected, your pipeline will start
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Tacard cardGrid>
              <CardHeader
                sx={(theme) => ({
                  paddingY: 1,
                  paddingX: 2,
                  margin: 'none',
                  backgroundColor: 'midnight.one',
                  borderBottom: `1px solid ${theme.palette.midnight.two}`,
                })}
                title={
                  <Typography variant={'subtitle1'} display={'flex'}>
                    {`Changelog`}
                  </Typography>
                }
              />
              <CardContent>
                <Tabulink
                  external
                  href={toTableRoot(
                    organizationName,
                    warehouse.name,
                    changelogTable.databaseName,
                    changelogTable.name,
                  )}
                  variant="body1"
                  rel="noopener"
                  aria-label={`${changelogTable.databaseName}.${changelogTable.name}`}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '350px',
                    mb: 1,
                  }}
                >
                  {`${changelogTable.databaseName}.${changelogTable.name}`}
                  <OpenInNewIcon fontSize="small" sx={{ marginLeft: '2px' }} />
                </Tabulink>
                <Typography>
                  The changelog is populated with CDC files from your storage
                  profile.
                </Typography>
              </CardContent>
            </Tacard>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Tacard cardGrid>
              <CardHeader
                sx={(theme) => ({
                  paddingY: 1,
                  paddingX: 2,
                  margin: 'none',
                  backgroundColor: 'midnight.one',
                  borderBottom: `1px solid ${theme.palette.midnight.two}`,
                })}
                title={
                  <Typography variant={'subtitle1'} sx={{ display: 'flex' }}>
                    {`Target table`}
                  </Typography>
                }
              />
              <CardContent>
                <Typography>
                  <Tabulink
                    external
                    href={toTableRoot(
                      organizationName,
                      warehouse.name,
                      targetTable.databaseName,
                      targetTable.name,
                    )}
                    variant="body1"
                    rel="noopener"
                    aria-label={`${targetTable.databaseName}.${targetTable.name}`}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      maxWidth: '350px',
                      mb: 1,
                    }}
                  >
                    {`${targetTable.databaseName}.${targetTable.name}`}
                    <OpenInNewIcon
                      fontSize="small"
                      sx={{ marginLeft: '2px' }}
                    />
                  </Tabulink>
                  The target table is updated from changes in the changelog.
                </Typography>
              </CardContent>
            </Tacard>
          </Grid>
        </Grid>
      )}
      <DialogActions sx={{ mt: 2 }}>
        <Tabutton
          variant="contained"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Tabutton>
      </DialogActions>
    </Box>
  );
};
