import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import {
  Box,
  CardContent,
  CardHeader,
  Link,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { enqueueSnackbar } from 'notistack';

import { toWarehouseRoot } from '../../RouteTable';
import { MirrorTarget, Warehouse } from '../../graphql/gen/graphql';
import {
  detachWarehouseMutation,
  getMirrorStatus,
  mirrorWarehouseMutation,
} from '../../graphql/warehouse';
import { calculateTextWidth } from '../../pages/helpers';
import { Tacard } from '../Card/Tacard';
import { ChipColors, Tachip } from '../Chip/Tachip';
import { StyledSwitch } from '../Forms/Switch';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { NamePopover } from '../Popovers/NamePopover';
import { showSnowflakeDialog } from '../Wizards/snowflake/SnowflakeWizardDialog';
import {
  getTarget,
  ConnectionsType,
  mirrorEnabledProperties,
  snowflakeProperties,
  databricksProperties,
} from './helpers';

export default function ComputeConnectionsGrid({
  warehouses,
  orgName,
  refetch,
  isSecurityAdmin,
}: {
  warehouses: Warehouse[];
  orgName: string;
  refetch: () => void;
  isSecurityAdmin: boolean;
}) {
  const [taskId, setTaskId] = useState('');
  const [target, setTarget] = useState('');
  const [warehouseId, setWarehouseId] = useState('');
  const [mirrorPending, setMirrorPending] = useState({
    id: '',
    pending: false,
  });
  const [mirrorWarehouse] = useMutation(mirrorWarehouseMutation);
  const [detachWarehouse] = useMutation(detachWarehouseMutation);
  const [getStatus, { data, error, startPolling, stopPolling }] =
    useLazyQuery(getMirrorStatus);

  useEffect(() => {
    if (!isEmpty(taskId) && !isEmpty(warehouseId)) {
      getStatus({
        variables: {
          warehouseId: warehouseId,
          taskId: taskId,
          target: target.toLowerCase(),
        },
      });
      startPolling(1000);
    }
    if (data?.getMirrorStatus?.status === 'IN_PROGRESS') {
      setMirrorPending({ id: warehouseId, pending: true });
    }
    if (data?.getMirrorStatus?.status === 'COMPLETE') {
      stopPolling();
      setMirrorPending({ id: '', pending: false });
      refetch();
      enqueueSnackbar('Successfully connected', {
        variant: 'success',
        preventDuplicate: true,
        onClose: () => setWarehouseId(''),
      });
    }
    if (data?.getMirrorStatus?.status === 'ERROR') {
      enqueueSnackbar('An error has occurred please try again.', {
        variant: 'error',
      });
      setMirrorPending({ id: '', pending: false });
      stopPolling();
    }
    if (error) {
      enqueueSnackbar(
        'Error syncing data, there may be an existing data set.',
        { variant: 'error' },
      );
      setMirrorPending({ id: '', pending: false });
      stopPolling();
    }
  }, [taskId, data, error, target]);
  const handleMirrorUpdate = (
    mirrorEnabled: boolean,
    warehouseId: string,
    mirrorTarget: MirrorTarget,
  ) => {
    if (mirrorEnabled) {
      return mirrorWarehouse({
        variables: {
          warehouseId: warehouseId,
          target: mirrorTarget,
        },
        onCompleted: async (data) => {
          setTaskId(data.mirrorWarehouse.taskId);
          setTarget(mirrorTarget);
          setWarehouseId(warehouseId);
        },
        onError: (error) => {
          error?.message?.startsWith('401:');
          enqueueSnackbar(
            error?.message?.startsWith('401:')
              ? error.message +
                  '. Please ensure your Snowflake URL, user, and network policies (if applicable) have been configured correctly.'
              : error.message,
            {
              variant: 'error',
              autoHideDuration: 5000,
              onClose: () => setMirrorPending({ pending: false, id: '' }),
            },
          );
        },
      });
    } else {
      return detachWarehouse({
        variables: {
          warehouseId: warehouseId,
          target: mirrorTarget,
        },
        onCompleted: async () => {
          enqueueSnackbar(`Successfully deactivated ${mirrorTarget}`, {
            variant: 'success',
          });
          setMirrorPending({ pending: false, id: '' });
          await refetch();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        },
      });
    }
  };

  let warehouseConnections = [] as ConnectionsType[];

  useMemo(() => {
    return warehouses.map((wh) => {
      const properties = wh?.properties || [];

      //Get warehouses with Snowflake related properties
      const snowflakeFilter = properties.filter((properties) =>
        properties?.key.includes('snowflake'),
      );

      //Hide them if there is no account configuration (They will be in the warehouse select drop down)
      const hideSnowflake =
        snowflakeFilter?.find((i) => i?.key === 'mirror.snowflake.configured')
          ?.value == 'false';

      const isSnowflake =
        (snowflakeFilter && snowflakeFilter?.length > 0) || false;

      //Get warehouses with Databricks related properties
      const databricksFilter = properties.filter((properties) =>
        properties?.key.includes('databricks'),
      );

      //Hide them if there is no account configuration (They will be in the warehouse select drop down)
      const hideDatabricks =
        databricksFilter?.find((i) => i?.key === 'mirror.databricks.configured')
          ?.value == 'false';

      const isDatabricks =
        (databricksFilter && databricksFilter?.length > 0) || false;

      // Get all mirrors for a warehouse (could have 2)
      const mirrors =
        properties &&
        properties.filter((properties) =>
          mirrorEnabledProperties.some((key) => properties?.key.includes(key)),
        );

      // Special treatment for multiple Snowflake properties
      const multipleSnowflakeProperties =
        mirrors &&
        mirrors.filter((prop: any) =>
          snowflakeProperties.some(() => prop?.key.includes('snowflake')),
        ).length > 1;

      // Special treatment for multiple Databricks properties
      const multipleDatabricksProperties =
        mirrors &&
        mirrors.filter((prop: any) =>
          databricksProperties.some(() => prop?.key.includes('databricks')),
        ).length > 1;

      const snowflakeEnabledAndConfigured =
        mirrors &&
        isSnowflake &&
        mirrors?.length > 1 &&
        multipleSnowflakeProperties;

      const databricksEnabledAndConfigured =
        mirrors &&
        isDatabricks &&
        mirrors?.length > 1 &&
        multipleDatabricksProperties;

      // Only show one warehouse per set of Snowflake properties
      if (snowflakeEnabledAndConfigured) {
        const findIndex = mirrors.findIndex(
          (property: any) => property.key === 'mirror.snowflake.configured',
        );
        mirrors.splice(findIndex, 1);
      }

      // Only show one warehouse per set of Databricks properties
      if (databricksEnabledAndConfigured) {
        const findIndex = mirrors.findIndex(
          (property: any) => property.key === 'mirror.databricks.configured',
        );
        mirrors.splice(findIndex, 1);
      }

      return (
        mirrors &&
        mirrors.map((mirror: any) => {
          const target =
            mirror && getTarget(mirror.key, wh?.storageProfile, wh?.roleArn);
          const connections = {
            id: wh.id,
            computeName: target?.name || '',
            warehouse: wh,
            warehouseName: wh?.name,
            mirror: mirror || undefined,
            permissions: target?.permission,
            enabled:
              (mirror?.key.includes('enabled') && mirror?.value == 'true') ||
              false,
            mirrorTarget: target?.target || null,
            hideSnowflake: hideSnowflake,
            hideDatabricks: hideDatabricks,
          };
          return warehouseConnections.push(connections);
        })
      );
    });
  }, [warehouses]);

  const rows = useMemo(() => {
    return warehouseConnections?.map((warehouse: ConnectionsType, index) => {
      const hideSnowflake =
        warehouse.mirrorTarget === MirrorTarget.Snowflake &&
        !warehouse.enabled &&
        warehouse.hideSnowflake;

      const hideDatabricks =
        warehouse.mirrorTarget === MirrorTarget.Databricks &&
        !warehouse.enabled &&
        warehouse.hideDatabricks;

      return {
        id: index,
        warehouseId: warehouse.id,
        computeName: warehouse.computeName,
        accountDetails: warehouse.computeName,
        warehouse: warehouse.warehouse,
        warehouseName: warehouse.warehouseName,
        permissions: warehouse.permissions,
        enabled: warehouse.enabled,
        mirrorTarget: warehouse.mirrorTarget,
        hide: hideSnowflake || hideDatabricks,
      };
    });
  }, [warehouses]);

  const filteredRows = rows.filter((row) => !row.hide);
  const columns = [
    {
      field: 'warehouseName',
      headerName: 'Warehouse',
      flex: 1,
      minWidth: 120,
      editable: false,
      sortable: true,
      renderCell: (params: any) => {
        const textWidth = calculateTextWidth(
          params.value,
          '16px Source Sans Pro',
          true,
        );
        return (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flex: '1 1 100%',
                    alignItems: 'center',
                    height: '100%',
                    minWidth: 0,
                    cursor: 'pointer',
                  }}
                >
                  <Link
                    href={toWarehouseRoot(orgName, params.value)}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    variant={`body1`}
                    {...bindHover(popupState)}
                  >
                    {params.value}
                  </Link>
                </Box>
                {textWidth >= params.colDef.computedWidth && (
                  <Popper
                    {...bindPopper(popupState)}
                    placement={`right`}
                    modifiers={[
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['bottom'],
                        },
                      },
                    ]}
                  >
                    <NamePopover name={params.value} />
                  </Popper>
                )}
              </>
            )}
          </PopupState>
        );
      },
    },
    {
      field: 'computeName',
      headerName: 'Compute connections',
      flex: 1,
      minWidth: 120,
      editable: false,
      renderCell: (params: any) => <Typography>{params.value}</Typography>,
    },
    {
      field: 'accountDetails',
      headerName: 'Account details',
      flex: 1,
      minWidth: 120,
      editable: false,
      renderCell: (params: any) => {
        const snowflake =
          params.row.mirrorTarget === MirrorTarget.Snowflake && isSecurityAdmin;
        const showSnowflake = () =>
          showSnowflakeDialog({
            warehouse: params.row.warehouse,
            refetch: refetch,
          });
        return (
          snowflake && (
            <Tooltip
              title={'Review connection details and steps'}
              placement={`top`}
              arrow
            >
              <Tachip
                type={ChipColors.Info}
                size={'small'}
                onClick={() => {
                  showSnowflake();
                }}
                icon={<AdsClickIcon />}
                label={
                  <Typography
                    variant={'subtitle2'}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Snowflake details
                  </Typography>
                }
              />
            </Tooltip>
          )
        );
      },
    },
    {
      field: 'permissions',
      headerName: 'Associated permission',
      flex: 2,
      minWidth: 120,
      editable: false,
      renderCell: (params: any) => {
        const textWidth = calculateTextWidth(
          params.value,
          '16px Source Sans Pro',
          true,
        );
        return (
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 100%',
              alignItems: 'center',
              justifyContent: 'flex-start',
              height: '100%',
              minWidth: 0,
            }}
          >
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      cursor: 'default',
                    }}
                    variant={`inputText`}
                    {...bindHover(popupState)}
                  >
                    {params.value}
                  </Typography>
                  {textWidth >= params.colDef.computedWidth && (
                    <Popper
                      style={{ zIndex: 1300 }}
                      {...bindPopper(popupState)}
                      placement={`right`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                        {
                          name: 'offset',
                          options: {
                            offset: [-5, 5],
                          },
                        },
                      ]}
                    >
                      <NamePopover name={params.value} />
                    </Popper>
                  )}
                </>
              )}
            </PopupState>
          </Box>
        );
      },
    },
    {
      field: 'enabled',
      headerName: 'Active',
      minWidth: 80,
      renderCell: (params: any) => {
        const pending = mirrorPending.id.includes(params.row.id);
        return (
          <Tooltip
            title={
              !isSecurityAdmin
                ? 'Mirroring is restricted to SECURITY_ADMIN'
                : ''
            }
            arrow
          >
            <Box>
              <StyledSwitch
                checked={params.value}
                disabled={!isSecurityAdmin || pending}
                onChange={async () => {
                  setMirrorPending({
                    pending: true,
                    id: params.row.warehouseId,
                  });
                  await handleMirrorUpdate(
                    !params.value,
                    params.row.warehouseId,
                    params.row.mirrorTarget,
                  );
                }}
              />
            </Box>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Tacard>
        <CardHeader
          title={`Connections`}
          sx={{
            '.MuiCardHeader-subheader': {
              marginTop: 1,
              color: 'midnight.nine',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '21px',
            },
          }}
        />
        <CardContent sx={{ p: 0, m: 0, borderRadius: 0, border: 'none' }}>
          <DataGridPro
            autoHeight
            hideFooter
            disableColumnMenu
            rows={filteredRows || []}
            columns={columns as GridColumns}
            headerHeight={48}
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: OhNoesRows,
            }}
            componentsProps={{
              noRowsOverlay: {
                buttonControl: (
                  <Typography variant={`h1`} color={`brandBlue.main`}>
                    No BigQuery or Glue connections
                  </Typography>
                ),
              },
            }}
            isRowSelectable={() => false}
            sx={{
              ...(rows?.length === 0 ? { minHeight: 500 } : {}),
              borderRadius: 0,
              borderLeft: 'none',
              borderRight: 'none',
              color: 'midnight.nine',
              '.MuiDataGrid-columnHeaders': {
                borderRadius: 0,
              },
              '.MuiDataGrid-cell--textLeft': {
                paddingLeft: 2,
              },
              '.MuiDataGrid-columnHeader': {
                paddingLeft: 2,
              },
            }}
          />
        </CardContent>
      </Tacard>
    </>
  );
}
