import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useField } from 'formik';

import { StyledSwitch } from '../Forms/Switch';
import TextField from '../Forms/TextField';

type FLFFPropTypes = {
  name: string;
  label: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  helperText?: string;
  setEditingPropGroup: (value: boolean) => void;
  ignoreHeadersProp: boolean;
};
export default function FileLoaderFileFormat({
  name,
  label,
  setFieldValue,
  helperText,
  setEditingPropGroup,
  ignoreHeadersProp,
  ...props
}: FLFFPropTypes) {
  const [field] = useField('fileFormat');
  const [ignoreHeaders, setIgnoreHeaders] = useState(ignoreHeadersProp);

  useEffect(() => {
    if (field.value === 'csv' || field.value === 'tsv') {
      setFieldValue('fileLoaderIgnoreHeaders', ignoreHeaders);
    }
  }, [field.value]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField label={label} name={name} helperText={helperText} {...props} />
      {(field.value === 'csv' || field.value === 'tsv') && (
        <Box sx={{ pb: 2, ml: 4 }}>
          <StyledSwitch
            switchlabel={`Ignore headers`}
            checked={ignoreHeaders}
            onChange={() => {
              setFieldValue('fileLoaderIgnoreHeaders', !ignoreHeaders);
              setIgnoreHeaders(!ignoreHeaders);
              setEditingPropGroup(true);
            }}
          />
        </Box>
      )}
    </Box>
  );
}
