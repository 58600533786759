import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikErrors, FormikValues } from 'formik';
import { uniq } from 'lodash';

import { useAuth } from '../../context/auth-context';
import {
  BucketValidationResponse,
  Maybe,
  User,
} from '../../graphql/gen/graphql';
import { createStorageProfile } from '../../graphql/organization';
import { bucketValidator } from '../../graphql/utils';
import { getDetailedApolloError } from '../../utils/ApolloUtils';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import BucketValidation from './BucketValidation';
import { getBucketName } from './helpers';
import { BucketCreationType, PolicyBucketConfigs } from './storage';

export enum ConnectionStatus {
  WarehouseEligible,
  ReadOnlyEligible,
  Failed,
  BucketError,
  AssumeRoleError,
  PermissionFailed,
}

export interface ConnectionValues {
  region?: string;
  bucketName?: string;
  stackName?: string;
  warehouseName?: string;
  iamRole?: string;
  externalId?: string;
  readOnly?: boolean;
  creatorRoleId?: string;
  projectNum?: string;
  projectId?: string;
  providerId?: string;
  poolId?: string;
}

export type StepType = {
  component: JSX.Element;
  primaryButton: string;
  secondaryButton: string;
  bucketValidationSchema?: any;
  onClick?: ({
    values,
    setFieldValue,
  }: {
    values: ConnectionValues;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<ConnectionValues>>;
  }) => void;
};

export default function ConnectionAssistant({
  orgName,
  warehouseName,
  steps,
  type,
  onCancel,
  onStorageBucketCreated,
  setStepType,
}: {
  orgName: string;
  warehouseName?: string;
  steps: StepType[];
  type: BucketCreationType;
  onCancel: () => void;
  onStorageBucketCreated: (bucket: any) => void;
  setStepType: (step: BucketCreationType | undefined) => void;
}) {
  const { user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);

  const [status, setStatus] = useState<ConnectionStatus | undefined>(undefined);
  const [lastError, setLastError] = useState<Maybe<string> | undefined>(null);
  const [validationResponse, setValidationResponse] = useState<
    Maybe<BucketValidationResponse> | undefined
  >(null);
  const [displayValidateModal, setDisplayValidateModal] = useState(false);
  const [finalSubmit, setFinalSubmit] = useState(false);

  const orgId = user.getOrganization(orgName).id;
  const [validateBucket, { loading: bucketLoading, error: bucketError, data }] =
    useMutation(bucketValidator);

  const [createStorage, { loading: storageLoading, error: storageError }] =
    useMutation(createStorageProfile);

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handlePrev = () => {
    if (activeStep === 0) {
      setStepType(undefined);
    }
    setActiveStep(Math.max(activeStep - 1, 0));
  };

  //@ts-ignore
  function doError(
    status: ConnectionStatus,
    errorMessage?: Maybe<string>,
    bucketValidationResponse?: Maybe<BucketValidationResponse>,
  ) {
    setStatus(status);
    setLastError(errorMessage);
    setValidationResponse(bucketValidationResponse);
  }
  function reset() {
    setStatus(undefined);
    setValidationResponse(null);
    setLastError(null);
  }
  function getRoleArn(values: FormikValues) {
    return type === BucketCreationType.Google
      ? `arn:gcp:gcs::${values.projectNum.trim()}:/${values.poolId.trim()}/${values.providerId.trim()}`
      : type === BucketCreationType.Azure
      ? `arn:azure:adls::${values.storageAccountName.trim()}.dfs.core.windows.net:/${values.applicationId.trim()}/${values.tenantId.trim()}`
      : values.iamRole.trim();
  }

  const onValidate = async (values: FormikValues) => {
    reset();

    try {
      setDisplayValidateModal(true);
      const roleArn = getRoleArn(values);

      const validateBucketResult = await validateBucket({
        variables: {
          region: values.region,
          bucket: values.bucketName.trim(),
          roleARN: roleArn,
          externalId: values.externalId.trim(),
        },
      });

      if (bucketError || !validateBucketResult) {
        doError(
          ConnectionStatus.Failed,
          'Error connecting to bucket validator. Please try again.',
          null,
        );
        return;
      }
      const validationResponse: BucketValidationResponse =
        validateBucketResult?.data.bucketValidator;

      const permissionErrorList = Object.keys(validationResponse).filter(
        (eachConfigType) =>
          eachConfigType !== '__typename' &&
          !validateBucketResult?.data.bucketValidator[eachConfigType]
            .checkPassed,
      );
      let s3ErroredPermissions: string[] = [];

      permissionErrorList.forEach((errorKey: string) => {
        s3ErroredPermissions.push(PolicyBucketConfigs[errorKey]);
      });
      const perms: Map<string, boolean> = new Map<string, boolean>(
        Object.keys(validationResponse)
          .filter((eachConfigType) => eachConfigType !== '__typename')
          .map((checkKey) => [
            (validationResponse as any)[checkKey]?.type,
            !!(validationResponse as any)[checkKey]?.checkPassed,
          ]),
      );

      // unable to assume role error
      if (!validationResponse.assumeRoleStatus?.checkPassed) {
        doError(
          ConnectionStatus.AssumeRoleError,
          validationResponse.assumeRoleStatus?.message,
          validationResponse,
        );
        return;
      }

      // bucket does not exist error
      if (!validationResponse.bucketExistsStatus?.checkPassed) {
        doError(
          ConnectionStatus.BucketError,
          validationResponse.bucketExistsStatus?.message,
          validationResponse,
        );
        return;
      }

      //we can only look for list since we dont want to read random files from a client (maybe we could ask for a check.txt)
      //so to test for getObject, we kinda need write...
      const readOnlyEligible = perms.get('ListObjects');

      const warehouseEligible =
        perms.get('ListObjects') &&
        perms.get('PutObject') &&
        perms.get('GetObject') &&
        perms.get('GetObjectMetadata') &&
        perms.get('DeleteObject') &&
        perms.get('MultipartUpload');

      // permissions error
      if (!readOnlyEligible && !warehouseEligible) {
        type === BucketCreationType.Manual ||
        type === BucketCreationType.CloudFormation
          ? doError(
              ConnectionStatus.PermissionFailed,
              `Policy is not configured correctly checks failed on the following: ${uniq(
                s3ErroredPermissions.map((config: string) => ' ' + config),
              )}`,
              validationResponse,
            )
          : doError(
              ConnectionStatus.PermissionFailed,
              `Something is not configured correctly, check inputs including bucket name and permissions`,
              validationResponse,
            );
        return;
      }

      if (warehouseEligible) {
        setStatus(ConnectionStatus.WarehouseEligible);
      } else if (readOnlyEligible) {
        setStatus(ConnectionStatus.ReadOnlyEligible);
      }

      setValidationResponse(validationResponse);
    } catch (error) {
      const errorMessage = getDetailedApolloError(error);
      if (errorMessage.type === 'InvalidRequestException') {
        doError(ConnectionStatus.Failed, errorMessage.message);
      } else {
        doError(
          ConnectionStatus.Failed,
          'Something went wrong. Please try again.',
        );
      }
    }
  };

  const onSubmit = async (
    values: FormikValues,
    {
      setSubmitting,
      setFieldValue,
    }: {
      setSubmitting?: (value: boolean) => void;
      setFieldValue?: any;
    },
  ) => {
    // if (activeStep === 0 && type === BucketCreationType.CloudFormation) {
    //   const formattedLink = getCloudFormationLink(values, setFieldValue);
    //   window.open(formattedLink, '_blank');
    // }

    reset();

    try {
      const roleArn = getRoleArn(values);

      const createStorageProfileResult = await createStorage({
        variables: {
          organizationId: orgId,
          request: {
            bucket: values.bucketName.trim(),
            region: values.region,
            roleArn,
            externalId: values.externalId.trim(),
            projectId: values.projectId.trim(),
          },
          creatorRoleId: values.creatorRoleId,
        },
      });

      if (storageError) {
        doError(
          ConnectionStatus.Failed,
          'Error creating storage bucket. Please try again.',
        );
        return;
      }
      if (createStorageProfileResult.data.createStorageProfile) {
        setDisplayValidateModal(false);
        onStorageBucketCreated(
          createStorageProfileResult.data.createStorageProfile,
        );
      }
    } catch (error) {
      const errorMessage = getDetailedApolloError(error);
      if (errorMessage.type === 'InvalidRequestException') {
        doError(ConnectionStatus.Failed, errorMessage.message);
      } else {
        doError(
          ConnectionStatus.PermissionFailed,
          'Something went wrong!. Please try again.',
        );
      }
    }

    if (!bucketLoading)
      setTimeout(() => {
        setSubmitting && setSubmitting(false);
      }, 1000);
  };

  const validationSchema = steps[activeStep].bucketValidationSchema;
  const names = getBucketName(orgName);
  const manualStep =
    (activeStep === 1 && type === BucketCreationType.Manual) ||
    ([1, 2, 3].includes(activeStep) && type === BucketCreationType.Google);

  const mbrDefRoleId =
    (user as User).loginSession?.membership?.defaultRoleId || '';

  const initialValues: ConnectionValues = {
    region: '',
    bucketName:
      type === BucketCreationType.CloudFormation ? names.bucketName : '',
    stackName: names.stackName,
    warehouseName: warehouseName,
    iamRole: '',
    externalId: type === BucketCreationType.Google ? '' : orgId,
    readOnly: false,
    creatorRoleId: '',
    projectNum: '',
    projectId: '',
    providerId: '',
    poolId: '',
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          values,
          submitForm,
          validateForm,
          touched,
          setTouched,
          setFieldTouched,
          errors,
          setErrors,
          setFieldValue,
        }) => (
          <Form>
            <Box
              display={'flex'}
              justifyContent={manualStep ? 'space-between' : 'flex-end'}
              sx={{ px: 2, py: 1 }}
            >
              {manualStep && (
                <DialogTitle
                  sx={{ mt: 2, pb: 0, maxWidth: '540px' }}
                  variant={'h1'}
                >
                  {values.bucketName} bucket setup
                </DialogTitle>
              )}
              <IconButton onClick={onCancel} sx={{ alignSelf: 'flex-start' }}>
                <CloseIcon
                  fontSize={'small'}
                  sx={{ color: 'midnight.six', pointer: 'cursor' }}
                />
              </IconButton>
            </Box>

            {status === ConnectionStatus.Failed && lastError && (
              <Talert
                sx={{ mx: 5, my: 2 }}
                severity={'error'}
                alertTitle={'Error validating '}
              >
                <Typography variant={'helperText'}>- {lastError}</Typography>
              </Talert>
            )}

            <DialogContent dividers={false} sx={{ padding: 0 }}>
              {!displayValidateModal && <> {steps[activeStep].component}</>}
            </DialogContent>
            {!displayValidateModal && (
              <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  pr: 6,
                  pb: manualStep ? 2 : 4,
                  pt: 2,
                }}
              >
                <Tabutton
                  disabled={isSubmitting}
                  onClick={handlePrev}
                  sx={{ mr: 3 }}
                >
                  {steps[activeStep].secondaryButton}
                </Tabutton>
                <Tabutton
                  disabled={isSubmitting}
                  onClick={() => {
                    const step = steps[activeStep];
                    if (step !== undefined && step.onClick !== undefined) {
                      step.onClick({ values, setFieldValue });
                    }
                    if (isLastStep()) {
                      onValidate(values);
                    } else {
                      if (validationSchema) {
                        validationSchema?._nodes.forEach(
                          (eachField: string) => {
                            setFieldTouched(eachField, true, true);
                          },
                        );
                      }

                      validateForm().then((validationErrors) => {
                        if (
                          !validationErrors ||
                          Object.keys(validationErrors).length == 0
                        ) {
                          setActiveStep(
                            Math.min(activeStep + 1, steps.length - 1),
                          );
                        }
                      });
                    }
                  }}
                  variant="contained"
                  endIcon={
                    type === BucketCreationType.CloudFormation &&
                    activeStep === 0 ? (
                      <OpenInNewIcon />
                    ) : (
                      <></>
                    )
                  }
                >
                  {steps[activeStep].primaryButton}
                </Tabutton>
              </DialogActions>
            )}

            {displayValidateModal && (
              <BucketValidation
                verifyLoading={bucketLoading}
                status={status}
                serverError={lastError}
                onBack={() => {
                  reset();
                  setDisplayValidateModal(false);
                  setFinalSubmit(false);
                  setActiveStep(steps.length - 1);
                }}
                onRevalidate={() => {
                  onValidate(values);
                }}
                finalSubmit={setFinalSubmit}
                createLoading={storageLoading}
                isWarehouse={Boolean(warehouseName)}
                validationResponse={validationResponse}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
