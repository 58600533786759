import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import {
  Box,
  MenuItem,
  Skeleton,
  Card,
  CardHeader,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikErrors, FormikValues } from 'formik';
import { sortBy } from 'lodash';

import { toWarehouseRoot } from '../../RouteTable';
import { useAuth } from '../../context/auth-context';
import {
  StorageProfile,
  StorageType,
  User,
  Warehouse,
} from '../../graphql/gen/graphql';
import { getOrgStorageProfilesList } from '../../graphql/organization';
import { useCreateWarehouse } from '../../graphql/warehouse';
import { getLogger } from '../../utils/logging';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import { selfHostedWarehouseSchema } from '../CreateStorageBucket/helpers';
import TextField from '../Forms/TextField';
import { showCreateStorageBucketDialog } from '../Modals/CreateStorageBucketDialog';
import { RoleSelector } from './RoleSelector';

export default function CreateWarehouse({
  orgName,
  onCancel,
  onWarehouseCreated,
  preSelectedBucket,
}: {
  orgName: string;
  onCancel: () => void;
  onWarehouseCreated: (warehouse: Warehouse) => void;
  preSelectedBucket?: StorageProfile;
}) {
  const logger = getLogger(
    'components.Forms.CreateWarehouse' /*FUTURE import.meta.url ?*/,
  );
  const { user } = useAuth();

  const mbrDefRoleId =
    (user as User).loginSession?.membership?.defaultRoleId || '';

  const organizationId = user.getOrganization(orgName).id;
  const [isAddingBucket, setAddingBucket] = useState(false);
  const navigate = useNavigate();
  const { loading, data, refetch } = useQuery(getOrgStorageProfilesList, {
    variables: { organizationId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [warehouseMutation, createWarehouseState] =
    useCreateWarehouse(organizationId);

  const [warehouseName, setWarehouseName] = useState('');

  const buckets = useMemo(
    () =>
      sortBy(
        data?.organization?.storageProfiles.filter(
          (bucket: StorageProfile) =>
            bucket.properties.validationStatus === 'PASS',
        ),
        'bucket',
      ),
    [data],
  );

  const onSubmit = async (
    values: FormikValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void },
  ) => {
    const selectedBucket = buckets.find(
      (eachBucket) => eachBucket.bucket == values.bucket,
    );
    if (!selectedBucket) {
      logger.error(
        `Bucket was null while submitting create warehouse. looking for ${values.bucket}`,
      );
      return;
    }
    const createWarehouseResult = await warehouseMutation({
      variables: {
        organization_name: orgName,
        name: warehouseName.trim(),
        region: selectedBucket.region,
        location: selectedBucket.bucket,
        roleArn: selectedBucket.roleArn,
        externalId: selectedBucket.externalId,
        managed: false,
        creatorRoleId: values.creatorRoleId,
        createStorage: false,
        orgId: organizationId,
      },
    });
    if (createWarehouseResult.data) {
      const newWarehouseName = createWarehouseResult.data.createWarehouse.name;
      onWarehouseCreated(createWarehouseResult.data.createWarehouse);
      if (!preSelectedBucket) {
        navigate(toWarehouseRoot(orgName, newWarehouseName), {
          state: {
            snackbar: true,
            snackbarMessage: `${newWarehouseName} successfully created.`,
          },
        });
      }
    }
    onCancel();
    setSubmitting(false);
  };

  const addBucketClicked =
    (
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ) => Promise<void | FormikErrors<{
        name: string;
        bucket: string;
        creatorRoleId: string;
      }>>,
    ) =>
    () => {
      setAddingBucket(true);
      setFieldValue('bucket', '');
      showCreateStorageBucketDialog(orgName, warehouseName)
        .then((storageProfile) => {
          setAddingBucket(false);
          refetch().then((data) => {
            setFieldValue('bucket', storageProfile.bucket);
          });
        })
        .catch((error) => {
          onCancel();
        })
        .finally(() => setAddingBucket(false));
    };

  const everyoneRole = useMemo(
    () =>
      user?.loginSession?.roles?.find((role: any) => role.name === 'EVERYONE'),
    [user],
  );

  return (
    <>
      <Card sx={{ p: 4 }}>
        <CardHeader title={`Create a warehouse`} sx={{ p: 1 }} />
        <Formik
          initialValues={{
            name: warehouseName, //TODO: auto-generate a default name
            bucket: preSelectedBucket ? preSelectedBucket?.bucket : '',
            creatorRoleId: mbrDefRoleId,
          }}
          validationSchema={selfHostedWarehouseSchema}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, errors, validateField, values }) => (
            <>
              {isAddingBucket && (
                <Typography variant={'body1'}>
                  Adding storage profile.
                </Typography>
              )}
              {!isAddingBucket && (
                <Form>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mt: 2,
                      maxWidth: 'sm',
                    }}
                  >
                    <TextField
                      name="name"
                      id="name"
                      type="text"
                      label="Warehouse name*"
                      variant="outlined"
                      margin="normal"
                      autoComplete="off"
                      fullWidth
                      autoFocus
                      value={warehouseName}
                      onChange={(e) => {
                        setWarehouseName(e.target.value);
                        setFieldValue('name', e.target.value);
                        validateField('name');
                      }}
                    />
                    {loading && <Skeleton />}
                    {!loading && (
                      <>
                        {!preSelectedBucket && (
                          <TextField
                            name="bucket"
                            id="bucket"
                            type="text"
                            label="Storage bucket*"
                            helperText={`Enter warehouse name in order to select bucket.`}
                            select={true}
                            variant="outlined"
                            margin="normal"
                            autoComplete="off"
                            fullWidth
                            disabled={!warehouseName || Boolean(errors.name)}
                            error={
                              Boolean(warehouseName) && Boolean(errors.bucket)
                            }
                            sx={{
                              '.MuiOutlinedInput-input:hover': {
                                cursor: `${
                                  !warehouseName ? 'not-allowed' : 'pointer'
                                }`,
                              },
                            }}
                          >
                            {buckets?.map((bucket: any, index: number) => {
                              return (
                                <MenuItem
                                  key={bucket.id}
                                  value={bucket.bucket}
                                  disabled={!bucket.requesterHasUsage}
                                >
                                  {`${bucket.bucket} (${
                                    bucket.storageType === StorageType.Gcs
                                      ? bucket.properties['gcp.bucket.region']
                                      : bucket.region
                                  })`}
                                </MenuItem>
                              );
                            })}
                            <MenuItem
                              key="newManualBucket"
                              value="newManualBucket"
                              onClick={addBucketClicked(setFieldValue)}
                            >
                              + Add new bucket
                            </MenuItem>
                          </TextField>
                        )}
                        <RoleSelector
                          name="creatorRoleId"
                          textFieldProps={{
                            label: 'Initial role*',
                            helperText:
                              'Select role to initially grant full access to.',
                            value: {
                              roleId: everyoneRole.id,
                              roleName: everyoneRole.name,
                            },
                          }}
                          creatorRoleStep
                        />
                      </>
                    )}
                    {createWarehouseState.error && (
                      <Talert
                        severity="error"
                        onClose={createWarehouseState.reset}
                      >
                        {createWarehouseState.error.message}
                      </Talert>
                    )}

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        pt: 1,
                        mt: 2,
                      }}
                    >
                      <Tabutton disabled={isSubmitting} onClick={onCancel}>
                        Cancel
                      </Tabutton>
                      <Tabutton
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        Create Warehouse
                      </Tabutton>
                    </Box>
                  </Box>
                </Form>
              )}
            </>
          )}
        </Formik>
      </Card>
    </>
  );
}
