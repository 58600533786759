import React from 'react';

import { Box } from '@mui/material';

import { Talert } from '../Alert/Talert';

export default function PasswordValidationHelpers({
  formErrors,
}: {
  formErrors: any;
}) {
  return (
    <Box sx={{ my: 2 }}>
      <Talert severity={`${formErrors?.charLength ? 'info' : 'success'}`}>
        8-character minimum
      </Talert>
    </Box>
  );
}
