import React from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoginIcon from '@mui/icons-material/Login';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { Tabutton } from '../Button/Tabutton';
import TextField from '../Forms/TextField';

export default function PasswordButtonGroup({
  showPassword,
  setShowPassword,
  buttonDisabled = false,
  buttonContent,
  formAutoComplete,
  inputError,
  helperText,
}: {
  showPassword: boolean;
  setShowPassword: (showPassword: boolean) => void;
  buttonDisabled?: boolean;
  buttonContent: any;
  formAutoComplete: string;
  inputError?: boolean;
  helperText?: string;
}) {
  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        autoFocus
        name="password"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        id="password"
        autoComplete={formAutoComplete}
        error={inputError}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tabutton
          type="submit"
          variant="contained"
          disabled={buttonDisabled}
          endIcon={<LoginIcon />}
        >
          {buttonContent}
        </Tabutton>
      </Box>
    </>
  );
}
