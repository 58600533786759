import React, { useEffect } from 'react';
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import { useUserAgent } from 'react-ua';

// @ts-ignore
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box, Grid, Paper, Stack, StackProps, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
// @ts-ignore
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// @ts-ignore
import { Organization, Warehouse } from '../../../graphql/gen/graphql';
import { Tabutton } from '../../Button/Tabutton';

interface TrinoDownloadImageCmdProps extends StackProps {
  trinoImageName: string;
  onCommandFormed?: (cmd: string) => void;
}
export const TrinoDownloadImageCmdTemplate = ({
  trinoImageName,
  onCommandFormed,
  ...props
}: TrinoDownloadImageCmdProps): JSX.Element => {
  const ua = useUserAgent();

  const cmdTemplate = `docker pull ${trinoImageName}`;

  useEffect(() => {
    if (onCommandFormed) {
      onCommandFormed(cmdTemplate);
    }
  }, [cmdTemplate]);

  return (
    <Stack direction="column" {...props}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            pre: {
              m: 0,
              height: 1,
            },
          }}
        >
          {' '}
          <SyntaxHighlighter
            language="bash"
            style={{ ...dracula }}
            showLineNumbers={false}
          >
            {cmdTemplate}
          </SyntaxHighlighter>
        </Grid>
      </Grid>
    </Stack>
  );
};
