import React from 'react';

import Error from '../../pages/Error';
import { PageAccessDeniedMessage } from './ErrorMessaging';

type Props = {
  resourceType?: string;
  breadcrumbs?: any;
};
export default function PageAccessDenied({ resourceType, breadcrumbs }: Props) {
  return (
    <Error
      message={PageAccessDeniedMessage}
      resourceType={resourceType}
      breadcrumbs={breadcrumbs}
    />
  );
}
