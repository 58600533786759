import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import GroupIcon from '@mui/icons-material/Group';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SecurityIcon from '@mui/icons-material/Security';
import {
  Box,
  Typography,
  CardHeader,
  Popper,
  Stack,
  List,
  ListItem,
  Checkbox,
  useMediaQuery,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { toSingleRole } from '../../RouteTable';
import { AuthDecisionResourceType, Role } from '../../graphql/gen/graphql';
import { formatTimestamp } from '../../utils/time';
import { Tavatar } from '../Avatar/Tavatar';
import { Tabutton } from '../Button/Tabutton';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import { ConfirmationDialog } from '../Modals/ConfirmationDialog';
import UserInfo from '../ProfileMenu/UserInfo';
import { getRoleDescription, getCascadeRevokeDescription } from './RoleHelpers';

const useCardHeaderStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiCardHeader-content': {
        minWidth: 0,
      },

      '& .MuiCardHeader-title': {
        fontSize: '20px',
        fontWeight: 500,
        fontFamily: 'Merriweather Sans',
      },
    },
  }),
);

type RoleDetailsProps = {
  organizationName: string;
  roleDetails: Role;
  showRevokeAccess: boolean;
  resourceName: string;
  onRevokeAccess: (cascadeRevoke?: boolean) => void;
  isResourceAdmin: boolean;
  currentUserEmail: string;
  accessType: string;
};

export default function RoleDetails({
  organizationName,
  roleDetails,
  showRevokeAccess = false,
  resourceName,
  onRevokeAccess = () => {},
  isResourceAdmin,
  currentUserEmail,
  accessType,
}: RoleDetailsProps) {
  const [revokeModal, setRevokeModal] = useState(false);
  const [cascadeRevoke, setCascadeRevoke] = useState(false);
  const createdAtDate = formatTimestamp(roleDetails.createdAt || '');
  const lastModifiedDate = formatTimestamp(roleDetails.lastModified || '');
  const navigate = useNavigate();
  const smallScreenView = useMediaQuery('(max-width: 600px)');

  const currentUser = useMemo(() => {
    return roleDetails?.users?.find(
      (user: any) => user.email === currentUserEmail,
    );
  }, [roleDetails]);

  const ManageMembers = (
    <Box
      display={`flex`}
      flexDirection={`column`}
      alignItems={'flex-start'}
      sx={(theme) => ({
        p: 2,
        borderBottom: `1px solid ${theme.palette.midnight.two}`,
      })}
    >
      <Tabutton
        size={'small'}
        onClick={() =>
          navigate(toSingleRole(organizationName || '', roleDetails.name))
        }
      >
        MANAGE MEMBERS
      </Tabutton>
    </Box>
  );

  const AvatarRows = (
    <>
      <Box
        display={'flex'}
        sx={(theme) => ({
          p: 2,
          borderBottom: `1px solid ${theme.palette.midnight.one}`,
        })}
      >
        <Tavatar
          profilePic={roleDetails?.createdByMember?.properties?.[`picture.url`]}
          tabularService={roleDetails.createdByMember?.properties === null}
          displayName={roleDetails?.createdByMember?.displayName || ''}
        />
        <Box sx={{ px: 2 }}>
          <Typography variant="subtitle2">Created</Typography>
          <Typography variant="helperText">{createdAtDate}</Typography>
        </Box>
      </Box>
      <Box
        display={'flex'}
        sx={{
          p: 2,
        }}
      >
        <Tavatar
          profilePic={
            roleDetails?.lastModifiedByMember?.properties?.[`picture.url`]
          }
          tabularService={roleDetails.createdByMember?.properties === null}
          displayName={roleDetails?.lastModifiedByMember?.displayName || ''}
        />
        <Box sx={{ px: 2 }}>
          <Typography variant="subtitle2">Modified</Typography>
          <Typography variant="helperText">{lastModifiedDate}</Typography>
        </Box>
      </Box>
    </>
  );

  const dashboardDropdownContent = (
    <Box>
      <Stack direction={`column`}>
        {ManageMembers}
        {AvatarRows}
        {showRevokeAccess && isResourceAdmin && (
          <Box
            display={`flex`}
            flexDirection={`column`}
            alignItems={'flex-start'}
            sx={(theme) => ({
              p: 2,
              borderTop: `1px solid ${theme.palette.midnight.two}`,
            })}
          >
            <Tabutton
              size={'small'}
              onClick={() => setRevokeModal(true)}
              sx={{ color: 'secondary.main' }}
            >
              REVOKE ACCESS
            </Tabutton>
          </Box>
        )}
      </Stack>
    </Box>
  );

  const cardHeaderClasses = useCardHeaderStyles();
  const peoples = roleDetails?.users?.length === 1 ? 'person' : 'people';
  const kiddos = roleDetails?.children?.length === 1 ? 'role' : 'roles';
  const cascadeEnabled =
    accessType === AuthDecisionResourceType.Database ||
    accessType === AuthDecisionResourceType.Warehouse;
  return (
    <>
      <CardHeader
        sx={(theme) => ({
          px: 2,
          py: 1,
          mb: 1,
          display: 'flex',
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
        className={cardHeaderClasses.root}
        title={
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: smallScreenView ? 'left' : 'center',
              wordWrap: 'break-word',
              minWidth: '0px',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                justifyContent: 'flex-start',
              },
            })}
          >
            <Box
              display={'flex'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  pb: 1,
                  pt: 1,
                },
              })}
            >
              <Typography variant="subtitle1" sx={{ minWidth: '0px' }}>
                {roleDetails.displayName}
              </Typography>
              {roleDetails.displayName === 'Security Admin' && (
                <LocalPoliceIcon sx={{ ml: '4px' }} />
              )}
              {roleDetails.displayName === 'Organization Admin' && (
                <SecurityIcon sx={{ ml: '4px' }} />
              )}
              {roleDetails.name === 'EVERYONE' && (
                <GroupIcon sx={{ ml: '4px' }} />
              )}
            </Box>
            <Box
              display={'flex'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              {roleDetails?.name !== 'EVERYONE' && currentUser && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ml: smallScreenView ? 0 : 1,
                    mr: smallScreenView ? 1 : 0,
                  }}
                >
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <PopupState variant="popper">
                      {(popupState) => (
                        <>
                          <Box {...bindHover(popupState)}>
                            <Tavatar
                              avatarProps={{ sx: { height: 32, width: 32 } }}
                              profilePic={
                                currentUser?.properties?.['picture.url']
                              }
                              displayName={currentUser?.displayName || ''}
                              currentUser={true}
                              roleAdmin={currentUser?.isAdmin || false}
                            />
                          </Box>
                          <Popper {...bindPopper(popupState)} placement={'top'}>
                            <Box
                              sx={(theme) => ({
                                backgroundColor: 'sky.one',
                                borderRadius: '4px',
                                boxShadow: theme.shadows[3],
                              })}
                            >
                              <UserInfo
                                showAdmin={currentUser?.isAdmin || false}
                                user={currentUser}
                              />
                            </Box>
                          </Popper>
                        </>
                      )}
                    </PopupState>
                  </Box>
                </Box>
              )}
              <>
                <Box
                  onClick={() => {
                    navigate(
                      toSingleRole(organizationName || '', roleDetails.name),
                    );
                  }}
                  sx={{
                    cursor: 'pointer',
                    marginX: smallScreenView ? 0 : 2,
                  }}
                >
                  <Typography variant={'subtitle2'} color={'sky.seven'}>
                    {roleDetails.name === 'EVERYONE'
                      ? `All people | All roles`
                      : `${roleDetails?.users?.length} ${peoples} | ${roleDetails?.children?.length} ${kiddos}`}
                  </Typography>
                </Box>

                {!smallScreenView && (
                  <Typography variant="body2" color={'midnight.nine'}>
                    {getRoleDescription(roleDetails.name)}
                  </Typography>
                )}
              </>
            </Box>
          </Box>
        }
        action={
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                pb: 1,
                pt: 1,
              },
            })}
          >
            <DashboardMenu
              dashboardDropdownContent={dashboardDropdownContent}
            />
          </Box>
        }
      />

      <ConfirmationDialog
        open={revokeModal}
        title={roleDetails.name !== 'EVERYONE' ? roleDetails.name : `Default`}
        acceptText="Revoke Access"
        onDismiss={() => {
          setRevokeModal(false);
        }}
        onAccept={() => {
          onRevokeAccess(cascadeRevoke);
          setRevokeModal(false);
        }}
      >
        <List>
          <ListItem>
            <Typography variant={`body1`}>
              Access to{' '}
              <Box component={`span`} sx={{ fontWeight: 'bold' }}>
                {resourceName}
              </Box>{' '}
              will be revoked
            </Typography>
          </ListItem>
          {cascadeEnabled && (
            <ListItem>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={cascadeRevoke}
                  onChange={() => setCascadeRevoke(!cascadeRevoke)}
                  sx={{
                    pl: 0,
                    '&.Mui-checked': { color: 'aurora.five' },
                  }}
                />
                <Typography variant={`body1`}>
                  {getCascadeRevokeDescription(accessType)}
                </Typography>
              </Box>
            </ListItem>
          )}
        </List>
      </ConfirmationDialog>
    </>
  );
}
