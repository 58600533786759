import React, { useEffect } from 'react';

import { Box, BoxProps, Grid } from '@mui/material';

import DashboardMenu from '../DashboardMenu/DashboardMenu';
import DashboardCard, { DashboardItem } from './DashboardCard';

export interface DashboardPanel {
  items: DashboardItem[];
}

interface DataGridProps extends BoxProps {
  dashboardCards: DashboardPanel[];
  dashboardDropdownContent?: JSX.Element | undefined;
  useMarginTop?: boolean;
}

export default function DataGridDashboard({
  dashboardCards,
  dashboardDropdownContent,
  useMarginTop = true,
}: DataGridProps) {
  return (
    <Box sx={{ display: 'flex', mb: 2, mt: useMarginTop ? 2 : 0 }}>
      <Grid
        container
        spacing={2}
        justifyContent={`space-between`}
        alignItems={`stretch`}
      >
        {dashboardCards.map((dashboardItem, index: number) => (
          <Grid item key={`dashboard-item-${index}`} flexGrow={1}>
            <DashboardCard items={dashboardItem.items} />
          </Grid>
        ))}
        {dashboardDropdownContent && (
          <Grid item>
            <DashboardMenu
              dashboardDropdownContent={dashboardDropdownContent}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
