import React from 'react';

import { useQuery } from '@apollo/client';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Unstable_Grid2 as Grid,
  useTheme,
} from '@mui/material';
import { ResponsiveLine, Serie } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { groupBy } from 'lodash';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { getUsageDaily } from '../../graphql/usage';
import { warehousePeersQuery } from '../../graphql/warehouse';

type UsageQueryResponse = {
  usageByDayAndService: {
    data: Array<{
      bytesRead: number;
      day: string;
      service: string;
    }>;
  };
};

type ByDayAndServiceChartDatum = {
  day: string;
  ChangeDataCapture: number;
  Compaction: number;
  FileLoad: number;
};

type ByServiceChartDatum = {
  id: string;
  background: string;
  label: string;
  value: number;
};

type ChartColors = Record<string, { line: string; dot: string }>;

type OrgWithWarehouses = {
  displayName: string;
  id: string;
  name: string;
  warehouses: Array<{
    id: string;
    name: string;
  }>;
};

export default function Usage({ organizationId }: { organizationId: string }) {
  const theme = useTheme();

  const COLORS: ChartColors = {
    Total: {
      line: theme.palette.sky.four ?? '#70B8DB', // sky 400
      dot: theme.palette.sky.seven ?? '#246B8F', // sky 700
    },
    FileLoad: {
      line: theme.palette.sunset.four ?? '#FF714C', // sunset 400
      dot: theme.palette.sunset.seven ?? '#B22400', // sunset 700
    },
    ChangeDataCapture: {
      line: theme.palette.dusk.four ?? '#A094B8', // dusk 400
      dot: theme.palette.dusk.seven ?? '#53476B', // dusk 700
    },
    Compaction: {
      line: theme.palette.sunrise.four ?? '#FFC34C', // sunrise 400
      dot: theme.palette.sunrise.seven ?? '#B27700', // sunrise 700
    },
  };

  const [timeframe, setTimeframe] = React.useState('7days');
  const [warehouseId, setWarehouseId] = React.useState('all');

  const { data, loading } = useQuery<UsageQueryResponse>(getUsageDaily, {
    variables: {
      organizationId,
      timeframe,
      warehouseId: warehouseId === 'all' ? undefined : warehouseId,
    },
    context: {
      headers: {
        'Cache-Control': 'no-cache', //tell our graphql code not to ever cache
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const warehousesQuery = useQuery<{ organization: OrgWithWarehouses }>(
    warehousePeersQuery,
    {
      variables: {
        organizationId,
      },
    },
  );

  // NOTE: this produces a time series for each service, plus one for the total
  const bytesByDayData = React.useMemo(() => {
    if (data && data.usageByDayAndService && data.usageByDayAndService.data) {
      const byService = groupBy(data.usageByDayAndService.data, 'service');

      // combine daily data into a single object with attributes for each service
      // { day: '2021-10-01', ChangeDataCapture: 123, Compaction: 456, FileLoad: 789 }
      const compactedByDay = Object.values(
        data.usageByDayAndService.data.reduce((items, datum) => {
          const item = items[datum.day] ?? { day: datum.day };

          return {
            ...items,
            [datum.day]: {
              ...item,
              [datum.service]: datum.bytesRead / 1073741824,
            },
          };
        }, {} as Record<string, ByDayAndServiceChartDatum>),
      );

      return [
        // our series for the total
        ...(compactedByDay.length > 0 // only show if we have data
          ? [
              {
                id: 'Total',
                color: COLORS.Total.line,
                dotColor: COLORS.Total.dot,
                data: compactedByDay.map(({ day, ...rest }) => ({
                  x: day,
                  y: Object.values(rest).reduce((sum, val) => sum + val, 0),
                })),
              },
            ]
          : []),
        // our series for each service
        ...Object.keys(byService).map((service) => ({
          id: service,
          color: COLORS[service]?.line,
          dotColor: COLORS[service]?.dot,
          data: byService[service].map((d) => ({
            x: d.day,
            y: d.bytesRead / 1073741824,
          })),
        })),
      ];
    } else {
      return [];
    }
  }, [data]);

  const bytesByServiceData = React.useMemo(() => {
    if (data && data.usageByDayAndService && data.usageByDayAndService.data) {
      const byService = groupBy(data.usageByDayAndService.data, 'service');

      return Object.keys(byService).map((service) => ({
        id: service,
        background: COLORS[service]?.line,
        label: service,
        value:
          byService[service].reduce((tot, d) => tot + d.bytesRead, 0) /
          1073741824,
      }));
    }
  }, [data]);

  const filteredWarehouseName = React.useMemo(() => {
    if (warehouseId === 'all') {
      return undefined;
    } else {
      return warehousesQuery.data?.organization.warehouses.find(
        (w) => w.id === warehouseId,
      )?.name;
    }
  }, [warehousesQuery.data, warehouseId]);

  const handleChangeTimeframe = (event: SelectChangeEvent) => {
    setTimeframe(event.target.value);
  };

  const handleWarehouseFilterChange = (event: SelectChangeEvent) => {
    if (event.target.value !== 'all') {
      setWarehouseId(event.target.value);
    } else {
      setWarehouseId('all');
    }
  };

  return (
    <Box sx={{ minHeight: 600 }}>
      <Grid
        container
        columns={12}
        spacing={2}
        sx={{
          mb: 1,
        }}
      >
        <Grid xs={12} md={4}>
          <PageHeader
            resourceName="Usage Statistics"
            pageTitle="Bytes read by services"
            marginB={0}
          />
        </Grid>

        <Grid
          xs={12}
          md={8}
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
              justifyContent: 'end',
            },
          })}
        >
          <FilterAltIcon sx={{ marginRight: 1 }} />
          <FormControl variant="outlined" size="small" sx={{ minWidth: 160 }}>
            <InputLabel id="timeframe-select-label">Date Range</InputLabel>
            <Select
              disableUnderline
              labelId="timeframe-select-label"
              id="timeframe-select"
              value={timeframe}
              onChange={handleChangeTimeframe}
              label={`Timeframe`}
            >
              <MenuItem value="7days">Last 7 days</MenuItem>
              <MenuItem value="30days">Last 30 days</MenuItem>
              <MenuItem value="90days">Last 90 days</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            size="small"
            sx={{ marginLeft: 2, minWidth: 300 }}
          >
            <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
            <Select
              disableUnderline
              labelId="warehouse-select-label"
              id="warehouse-select"
              value={warehouseId}
              onChange={handleWarehouseFilterChange}
              label={`Warehouse`}
            >
              <MenuItem value="all">All Warehouses</MenuItem>
              {warehousesQuery.data?.organization.warehouses.map((w) => (
                <MenuItem key={w.id} value={w.id}>
                  {w.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container columns={12} spacing={2}>
        <Grid xs={12} lg={8}>
          <UsageByDayChart
            colors={COLORS}
            isLoading={loading}
            data={bytesByDayData}
            warehouse={filteredWarehouseName}
          />
        </Grid>
        <Grid xs={12} lg={4}>
          <UsageByServiceChart
            colors={COLORS}
            data={bytesByServiceData}
            isLoading={loading}
            warehouse={filteredWarehouseName}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function UsageByDayChart({
  colors,
  data,
  isLoading,
  warehouse,
}: {
  colors: ChartColors;
  data: Serie[];
  isLoading: boolean;
  warehouse?: string;
}) {
  return (
    <Chart
      colors={colors}
      isEmptyData={data.length === 0}
      isLoading={isLoading}
      legendItems={data.map((d) => d.id as string)}
      subtitle={warehouse ?? 'All warehouses'}
      title="Daily usage (GiB)"
      width="99%" // workaround for responsive chart
    >
      <ResponsiveLine
        animate={true}
        axisBottom={{
          format: '%b %d',
          tickValues:
            data.length > 0 && data[0].data.length < 10
              ? 'every day'
              : 'every 5 days',
        }}
        axisLeft={{
          tickValues: 4,
        }}
        colors={(d) => d.color ?? '#000'}
        curve="monotoneX"
        data={data}
        enablePoints={true}
        enableSlices="x"
        margin={{ top: 20, right: 25, bottom: 40, left: 60 }}
        pointBorderColor="#fff"
        pointBorderWidth={1}
        pointColor={(d: Serie) => d.dotColor ?? '#000'}
        pointSize={6}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
        }}
        yFormat=" >-.2f"
      />
    </Chart>
  );
}

function UsageByServiceChart({
  colors,
  data,
  isLoading,
  warehouse,
}: {
  colors: ChartColors;
  data: ByServiceChartDatum[] | undefined;
  isLoading: boolean;
  warehouse?: string;
}) {
  const emptyData: ByServiceChartDatum[] = [];

  return (
    <Chart
      colors={colors}
      isEmptyData={!data || data.length === 0}
      isLoading={isLoading}
      legendItems={data?.map((d) => d.id as string)}
      subtitle={warehouse ?? 'All warehouses'}
      title="Service usage (GiB)"
      width="99%" // workaround for responsive chart
    >
      <ResponsivePie
        activeOuterRadiusOffset={8}
        animate={true}
        arcLabelsSkipAngle={10} // avoid labels on small slices
        colors={(d) => colors[d.id]?.line ?? '#000'}
        cornerRadius={3} // rounded corners
        data={data ?? emptyData}
        enableArcLabels={true}
        enableArcLinkLabels={false}
        innerRadius={0.5}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        padAngle={2.0} // spacing between slices in degrees
        valueFormat=" >-.2f"
      />
    </Chart>
  );
}

function Chart({
  children,
  colors,
  height,
  isEmptyData,
  isLoading,
  legendItems,
  subtitle,
  title,
  width,
}: {
  children: React.ReactNode;
  colors: ChartColors;
  height?: number;
  isEmptyData?: boolean;
  isLoading?: boolean;
  legendItems?: string[];
  subtitle?: string;
  title?: string;
  width?: number | string;
}) {
  const chartTitle = title ? (
    <>
      <Typography variant="h6">{title}</Typography>
      {subtitle && (
        <Typography variant="subtitle2" color="grey" my={1}>
          {subtitle}
        </Typography>
      )}
    </>
  ) : null;

  const content = isLoading ? (
    <CircularProgress />
  ) : isEmptyData ? (
    <div>No data available</div>
  ) : (
    children
  );

  return (
    <Card variant="outlined" sx={{ p: 2, boxShadow: 4 }}>
      {chartTitle}
      <Legend colors={colors} items={legendItems ?? []} />
      <div
        style={{
          display: 'flex',
          height: height ?? 300,
          // width: '99%',
          width: width ?? 600,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {content}
      </div>
    </Card>
  );
}

function Legend({ colors, items }: { colors: ChartColors; items: string[] }) {
  return (
    <Box sx={{ display: 'flex', mb: 1 }}>
      {items.map((item) => (
        <Box key={item} sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <label
            style={{
              position: 'relative',
              backgroundColor: colors[item].line,
              width: 24,
              height: 6,
              borderRadius: '4px',
              marginRight: 4,
            }}
          >
            <span
              style={{
                position: 'absolute',
                backgroundColor: colors[item].dot,
                borderRadius: '50%',
                border: '2px solid white',
                width: 12,
                height: 12,
                right: 0,
                bottom: -3,
              }}
            />
          </label>

          <Typography variant="body2">{item}</Typography>
        </Box>
      ))}
    </Box>
  );
}
