import React from 'react';
import { FallbackProps } from 'react-error-boundary';

import Error from '../../pages/Error';
import { getLogger } from '../../utils/logging';
import { SomethingWentWrongMessage } from './ErrorMessaging';

export default function SomethingWentWrong({ ...props }) {
  return <Error message={SomethingWentWrongMessage} />;
}
export function SomethingWentWrong2({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  const logger = getLogger(
    'Errors.SomethingWentWrong' /*FUTURE import.meta.url ?*/,
  );
  logger.error(`Unhandled exception! ${error}`);
  return <Error message={SomethingWentWrongMessage} />;
}
