import React, { useEffect, useMemo, useRef, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { useLazyQuery, useMutation } from '@apollo/client';
import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { defaultStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useAuth } from '../../../context/auth-context';
import { Secret, Warehouse } from '../../../graphql/gen/graphql';
import {
  createOrgCredential,
  orgCredentialUsage,
} from '../../../graphql/organization';
import { Talert } from '../../Alert/Talert';
import { ExternalBreadcrumbs } from '../../CreateStorageBucket/BucketSteps/helpers';
import { CustomIcon } from '../../Icons/CustomIcon';
import { Tabulink } from '../../Link/Tabulink';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../WizardContainer';
import { CreateCredentialStep } from '../components/CreateCredentialStep';
import { IAMRoleMappingStep } from '../components/IAMRoleMappingStep';
import { SecurityMethod } from '../helpers';
import { SparkSQLTemplate, SqlCmds } from '../spark/SparkSQLTemplate';
import { EMRCreateCluster } from './EMRCreateCluster';

const EMRBreadcrumbs = ['Amazon EMR', 'EMR on EC2:Clusters', 'Create cluster'];

const instructions = {
  step1: (
    <Typography variant={'body1'}>
      Scroll to <b>Identity and Access Management roles</b>, select an IAM role
      for the <b>EC2 instance profile for Amazon EMR</b>. Navigate to that role,
      copy the ARN and map it to a role in Tabular.
    </Typography>
  ),
  example: `arn:aws:iam::<aws_acct_id>:role/EMR_EC2_DefaultRole`,
  step2: (
    <Typography variant={'body1'}>
      {' '}
      Under <b>Permissions</b> click <b>Add permissions</b> then{' '}
      <b>Attach policies</b> and search for{' '}
      <b>AmazonAPIGatewayInvokeFullAccess</b>, select and add permission.
    </Typography>
  ),
};

const EMRWizardDialog = create<{
  organizationName: string;
  warehouse: Warehouse;
  organizationId: string;
}>(({ warehouse, organizationName, organizationId }) => {
  const modal = useModal();
  const [createOrgCredentialMutation] = useMutation(createOrgCredential);
  const [getOrgCredentialUsage, { data, stopPolling }] =
    useLazyQuery(orgCredentialUsage);
  const [credential, setCredential] = useState<Secret>();
  const [errorStatus, setErrorStatus] = useState();
  const [credentialUsed, setCredentialUsed] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const isSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(organizationName),
    [user, organizationName],
  );
  const [securityType, setSecurityType] = useState(
    isSecurityAdmin
      ? SecurityMethod.IamMapping
      : SecurityMethod.MemberCredential,
  );

  const createCredential = async () => {
    if (!credential && !errorStatus) {
      return createOrgCredentialMutation({
        variables: {
          organizationId: organizationId,
          name: 'Amazon EMR Spark Connection Credential',
          description: 'Built with the Amazon EMR Spark Connection Flow',
        },
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {
          if (data) {
            setCredential(data.data.createOrgCredential);
          }
        });
    }
  };

  const pollCredUsage = async () => {
    if (credential) {
      getOrgCredentialUsage({
        variables: {
          organizationId: organizationId,
          credentialKey: credential.credential.split(':')[0],
        },
        pollInterval: 2000,
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {});
    }
  };

  useEffect(() => {
    if (data?.organizationCredential?.lastSession) {
      setCredentialUsed(true);
      stopPolling();
    }
  }, [data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    setSecurityType((event.target as HTMLInputElement).value);
  };
  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: 'Amazon EMR Spark setup',
        body: (
          <Box>
            <Box sx={{ mt: 0 }}>
              <Box mb={3}>
                <Grid
                  container
                  sx={{
                    width: 1,
                    border: 1,
                    borderColor: 'midnight.two',
                    borderRadius: '8px',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1 / 2,
                      borderRadius: '8px',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottom: 1,
                      borderColor: 'midnight.two',
                      backgroundColor: 'dusk.half',
                    }}
                  >
                    <Typography variant={'body1'}>
                      {`Follow link below to create a cluster`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1,
                      borderBottom: 0,
                      borderColor: 'midnight.two',
                    }}
                  >
                    <Typography sx={{ display: 'inline-flex', mr: 1 }}>
                      <Tabulink
                        external
                        href={`https://${warehouse.region}.console.aws.amazon.com/emr/home?region=${warehouse.region}#/createCluster`}
                        variant="body1"
                        rel="noopener"
                        aria-label="Amazon EMR create cluster"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {'Amazon EMR cluster creation'}
                        <OpenInNewIcon
                          fontSize="small"
                          sx={{ ml: '2px', mr: '3px' }}
                        />
                      </Tabulink>
                      (instructions detailed below)
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              In Amazon EMR Create cluster:
              <ExternalBreadcrumbs
                breadcrumbs={EMRBreadcrumbs}
                link={`https://${warehouse.region}.console.aws.amazon.com/emr/home?region=${warehouse.region}#/createCluster`}
              />
              <List sx={{ listStyle: 'decimal', pl: 5, pt: 2, pb: 3 }}>
                <ListItem sx={{ display: 'list-item', p: 0 }}>
                  <Typography variant={'body1'}>
                    Start by naming <b>cluster</b>.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', p: 0 }}>
                  <Typography variant={'body1'}>
                    For the <b>Application bundle</b> select <b>Spark</b>.
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', p: 0 }}>
                  <Typography variant={'body1'}>
                    Under <b>Networking</b> select VPC and subnet to launch
                    cluster in.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant={'body1'}>
                <b>
                  Choose a security method for this connection and continue:
                </b>
              </Typography>
              <RadioGroup
                sx={{ ml: 2, width: 'fit-content' }}
                defaultValue={SecurityMethod.IamMapping}
                value={securityType}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={SecurityMethod.IamMapping}
                  control={<Radio />}
                  label={
                    isSecurityAdmin
                      ? 'AWS IAM role mapping'
                      : 'AWS IAM role mapping (Security admin is required to add a new IAM role mapping)'
                  }
                />

                <Typography variant={'helperText'}>
                  An AWS IAM identity can be mapped to a Tabular role for
                  authentication without the need for a credential
                </Typography>
                <FormControlLabel
                  value={SecurityMethod.MemberCredential}
                  control={<Radio />}
                  label="Member credential"
                />
                <Typography variant={'helperText'}>
                  A Member credential will provide authentication for a specific
                  user
                </Typography>
              </RadioGroup>
            </Box>
          </Box>
        ),
      },
      {
        title: 'Security setup',
        effect:
          securityType == SecurityMethod.MemberCredential
            ? createCredential
            : () => {},
        body:
          securityType == SecurityMethod.MemberCredential ? (
            <CreateCredentialStep
              organizationName={organizationName}
              connectionName={'EMR Spark'}
              credential={credential?.credential}
              errorStatus={errorStatus}
            />
          ) : (
            <>
              {isSecurityAdmin ? (
                <>
                  <Talert
                    severity={'info'}
                    alertTitle={'Note'}
                    sx={{ mb: 2, width: 'fit-content' }}
                  >
                    {`You can skip this step. However, for your EMR cluster to authenticate to Tabular, you must map the IAM role specified in the `}
                    <b>EC2 instance profile for Amazon EMR</b> to a Tabular
                    role. To set up later, go to{' '}
                    <b>Connections {`>`} Security</b>.
                  </Talert>
                  In Amazon EMR Create cluster:
                  <ExternalBreadcrumbs
                    breadcrumbs={EMRBreadcrumbs}
                    link={`https://${warehouse.region}.console.aws.amazon.com/emr/home?region=${warehouse.region}#/createCluster`}
                  />
                  <IAMRoleMappingStep
                    organizationId={organizationId}
                    organizationName={organizationName}
                    instructions={instructions}
                  />
                </>
              ) : (
                <>
                  <Talert
                    severity={'info'}
                    alertTitle={'Note'}
                    sx={{ width: 'fit-content', mb: 2 }}
                  >
                    {`You can skip this step. However, for your EMR cluster to authenticate to Tabular, you must map the IAM role specified in the `}
                    <b>EC2 instance profile for Amazon EMR</b> to a Tabular
                    role. To set up later, go to{' '}
                    <b>Connections {`>`} Security</b>.
                  </Talert>
                  In Amazon EMR Create cluster:
                  <ExternalBreadcrumbs
                    breadcrumbs={EMRBreadcrumbs}
                    link={`https://${warehouse.region}.console.aws.amazon.com/emr/home?region=${warehouse.region}#/createCluster`}
                  />
                  <Typography variant={'body1'}>
                    Scroll to <b>Identity and Access Management roles</b>,
                    select an IAM for for the{' '}
                    <b>EC2 instance profile for Amazon EMR</b>. You will need
                    the full ARN for that role mapped to a role in Tabular.
                  </Typography>
                  <Typography variant={'body1'}>
                    Example:{' '}
                    <SyntaxHighlighter
                      language={'text'}
                      style={defaultStyle}
                      customStyle={{
                        display: 'inline-flex',
                        fontSize: '13px',
                        lineHeight: '1em',
                        margin: 0,
                        borderRadius: '4px',
                        paddingTop: '.3em',
                        paddingBottom: '.3em',
                      }}
                    >
                      {`arn:aws:iam::<aws_acct_id>:role/EMR_EC2_DefaultRole`}
                    </SyntaxHighlighter>
                  </Typography>
                </>
              )}
            </>
          ),
      },
      {
        title: 'Create cluster',
        effect: () => {
          modalRef.current?.scrollIntoView();
        },
        body: (
          <EMRCreateCluster
            name={warehouse.name}
            region={warehouse.region}
            securityType={securityType}
            breadcrumbs={EMRBreadcrumbs}
            credential={credential}
            modalRef={modalRef}
          />
        ),
      },
      {
        title: 'Test connection',
        effect: () => {
          pollCredUsage();
          modalRef.current?.scrollIntoView();
        },
        body: (
          <>
            <Box sx={{ width: 0.5 }} ref={modalRef}>
              {credential && !credentialUsed && (
                <Talert color="neutral">
                  Watching for connection, please perform the steps below...
                </Talert>
              )}
              {credential && credentialUsed && (
                <Talert severity="success">
                  Successful connection detected!
                </Talert>
              )}
            </Box>
            <Box
              sx={{
                mt: credential ? 3 : 1,
                display: 'flex',
                flexDirection: 'column',
                width: 0.98,
              }}
            >
              <SparkSQLTemplate sx={{ width: 1 }} cmds={SqlCmds} />
            </Box>
          </>
        ),
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      {' '}
      <WizardContainer
        title={'Amazon EMR Spark'}
        stepsTitle={warehouse.name}
        stepsSubtitle={`Connect Amazon EMR Spark`}
        stepsIcon={
          <CustomIcon
            src={'/assets/img/logos/amazon-emr.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        backButtonClick={() => {}}
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        sx={{ minHeight: '550px', width: 1, height: '98vh' }}
      />
    </Dialog>
  );
});

export function showEMRWizardDialog({
  warehouse,
  organizationName,
  organizationId,
}: {
  warehouse: Warehouse;
  organizationName: string;
  organizationId: any;
}) {
  return show(EMRWizardDialog, {
    warehouse,
    organizationName,
    organizationId,
  });
}
