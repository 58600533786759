import React, { ReactNode, useMemo, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  useGridApiContext,
} from '@mui/x-data-grid-pro';

import { useTableEditContext } from './TableEditContext';

import { getLogger } from '../../utils/logging';
import { hashCode } from '../../utils/webutils';
import { GridTextField } from './SchemaEditGridComponents';
import { EditTableMode } from './TableEdit';
import { shouldBlockPropagation, simulateMouseClick } from './TableEditHelpers';

export interface SimpleSelectionViewProps extends GridRenderCellParams {
  label: string;
}

const logger = getLogger(
  'components.TableEdit.SimpleSelector' /*FUTURE import.meta.url ?*/,
);

export function SimpleSelectionView({
  id,
  value,
  field,
  tabIndex,
  label,
}: SimpleSelectionViewProps) {
  if (value === undefined || value === '') {
    return (
      <GridTextField label={`Select ${label}`} required error></GridTextField>
    );
  }
  const { mode } = useTableEditContext();
  let isError = !value;

  return (
    <FormControl>
      <GridTextField
        sx={(theme) => ({
          pl: 2,
          pr: 2,
          width: 1,
          border: `1px solid ${theme.palette.midnight.two}`,
          borderRadius: '4px',
        })}
        InputProps={{
          ...(mode !== EditTableMode.VIEW
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                ),
              }
            : {}),
          sx: { pl: 2, pr: 0, pt: 0.5, pb: 0.5 },
        }}
        required
        error={isError}
        // tabIndex={tabIndex}
        value={value}
      />
    </FormControl>
  );
}

export interface SimpleSelectionEditProps extends GridRenderEditCellParams {
  label: string;
  options: { label?: string; value: string; description?: string }[];
  defaultValue?: string;
}

const SCHEMA_TYPE_VAL_NONE = 'none';

export function SimpleSelectionEdit({
  id,
  value,
  field,
  row,
  level,
  hasFocus,
  tabIndex,
  label,
  options,
  defaultValue,
  ...rest
}: SimpleSelectionEditProps) {
  const apiRef = useGridApiContext();

  const previousValue = apiRef.current.getCellValue(id, field);

  const initialValue =
    value === undefined || value === ''
      ? defaultValue !== undefined
        ? defaultValue
        : SCHEMA_TYPE_VAL_NONE
      : value;
  const [currentVal, setCurrentVal] = useState<string>(initialValue);
  if (value != initialValue) {
    apiRef.current.setEditCellValue({ id, field, value: initialValue });
  }
  const setValue = (newValue: string) => {
    setCurrentVal(newValue);
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectOpen, setSelectOpen] = React.useState(hasFocus);
  const [lastSelectCloseTs, setLastSelectCloseTs] = React.useState(0);

  const handlePopoverOpen = (event: React.SyntheticEvent<HTMLElement>) => {
    if (selectOpen) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleValueChange = (
    event: SelectChangeEvent<string>,
    child?: ReactNode,
  ) => {
    setValue(event.target.value);
    logger.debug('select changed, closing');
    setSelectOpen(false);
  };

  const menuItems = useMemo(
    () =>
      options.map((eachValue) => [
        [
          // @ts-ignore
          <MenuItem
            id={'simpleValue_' + id + '_' + hashCode(eachValue.value)}
            key={'simpleValue_' + id + '_' + hashCode(eachValue.value)}
            value={eachValue.value}
            aria-owns={open ? 'simpleValue_' + id + '_popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onFocus={(event) => {
              handlePopoverOpen(event);
            }}
            onBlur={(event) => {
              handlePopoverClose();
            }}
            onKeyDown={(event) => {
              logger.debug(
                `menuitem ${event.currentTarget.id} got keydown ${event.key}`,
              );
              //simulate a click if they hit space bar on the menu item.
              if (event.key === ' ') {
                simulateMouseClick(event.currentTarget);
              }
            }}
          >
            <Box>
              <ListItemText>
                {eachValue.label ? eachValue.label : eachValue.value}
              </ListItemText>
            </Box>
          </MenuItem>,
          <Popover
            tabIndex={-1}
            key={
              'simpleValue_' + id + '_' + hashCode(eachValue.value) + '_popover'
            }
            id={
              'simpleValue_' + id + '_' + hashCode(eachValue.value) + '_popover'
            }
            sx={{
              pointerEvents: 'none',
            }}
            open={
              open &&
              anchorEl?.id ==
                'simpleValue_' + id + '_' + hashCode(eachValue.value)
            }
            anchorEl={anchorEl?.style.display != 'none' ? anchorEl : undefined}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableAutoFocus //🙏 this stops the loss of focus and headaches
            disableEnforceFocus //🙏 this stops the loss of focus and headaches
          >
            <Box sx={{ maxWidth: 500 }}>
              <Typography sx={{ p: 1 }}>
                {`${
                  eachValue.description
                    ? eachValue.description
                    : eachValue.label
                    ? eachValue.label
                    : eachValue.value
                }`}
              </Typography>
            </Box>
          </Popover>,
        ],
      ]),
    [id, handlePopoverOpen, handlePopoverClose, anchorEl],
  );

  // @ts-ignore
  return (
    <Box tabIndex={-1} sx={{ display: 'flex', width: 1 }}>
      {/*There is a weird quirk / bug in the mui select here.  If I get rid of the flexGrow:1 or width:1, I can tab
      once into the select... otherwise it is twice!  maybe is the react strict mode issue local?*/}
      <FormControl
        variant="standard"
        sx={{
          flexGrow: 1,
          '& .MuiInputBase-formControl': {
            borderRadius: '5px',
            border: 1,
            borderColor: 'midnight.two',
          },
        }}
        onKeyDown={(event) => {
          logger.debug(`in form control onkeydown '${event.key}' !`);
          if (shouldBlockPropagation(event)) {
            event.stopPropagation();
          }
        }}
      >
        <Select
          sx={{
            ...(!currentVal || currentVal === SCHEMA_TYPE_VAL_NONE
              ? { color: 'midnight.four' }
              : {}),
          }}
          autoFocus={hasFocus}
          disableUnderline
          key={field + '_simple_select'}
          onBlur={() => {
            setSelectOpen(false);
          }}
          onFocus={(e) => {
            if (e.currentTarget === e.target) {
              logger.debug('focused self');
            } else {
              logger.debug('focused child', e.target);
            }
            if (!e.currentTarget.contains(e.relatedTarget)) {
              // Not triggered when swapping focus between children
              logger.debug('focus entered self');
              if (e.timeStamp - lastSelectCloseTs > 1000) {
                if (e.relatedTarget) {
                  setSelectOpen(true);
                }
              }
            }
          }}
          inputProps={{
            sx: { pt: 1 / 2, pb: 1 / 2, pl: 2 },
          }}
          onChange={handleValueChange}
          onPointerDown={() => {
            logger.debug(`pointer down`);
            apiRef.current.setCellFocus(id, field);
            setSelectOpen(!selectOpen);
          }}
          open={selectOpen}
          onOpen={() => {
            logger.debug('On open!');
          }}
          onClose={(event) => {
            logger.debug('On close!');
            setSelectOpen(false);
            setLastSelectCloseTs(event.timeStamp);
            setAnchorEl(null);
          }}
          value={currentVal ? currentVal : SCHEMA_TYPE_VAL_NONE}
          placeholder={`${label}`}
        >
          <MenuItem disabled value={SCHEMA_TYPE_VAL_NONE} key={'Menu_0' + id}>
            {`${label}`}
          </MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    </Box>
  );
}
