import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import MUILink, { LinkProps as MUILinkProps } from '@mui/material/Link';

interface TaLinkProps extends MUILinkProps {
  external?: boolean;
  color?: string;
}

export const Tabulink = React.forwardRef<HTMLAnchorElement, TaLinkProps>(
  ({ href, external, ...props }, ref) => {
    // If we are attempting to link to an external url, then just use an <a> tag
    if (external) {
      return (
        <MUILink
          ref={ref}
          href={href}
          {...props}
          component="a"
          target="_blank"
        />
      );
    }
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href || ''} {...props} />;
  },
);
