import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import LoadingBackdrop from '../../components/Feedback/LoadingBackdrop';
import { useAuth } from '../../context/auth-context';

export function Logout() {
  const client = useApolloClient();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  // if we have a logged in user, than call logout
  // intentionally passing an empty array to prevent logout from being called multiple times
  useEffect(() => {
    if (user) {
      logout();
    } else {
      client.clearStore();
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  // else we have a user and we are waiting for the logout call to be made
  return <LoadingBackdrop />;
}
