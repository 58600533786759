import React, { MouseEvent } from 'react';

import { Tab, Tabs } from '@mui/material';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

interface StyledTabProps {
  label: JSX.Element | string;
  disabled?: boolean;
  icon?: JSX.Element;
  iconPosition?: 'bottom' | 'top' | 'end' | 'start' | undefined;
  popoverEnter?: (
    event: React.SyntheticEvent<HTMLDivElement>,
    position: number,
  ) => void;
  popoverLeave?: () => void;
  position?: number;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  onClick: (event: MouseEvent<HTMLButtonElement>, currentIndex: number) => void;
}

export const MainNavTabs = styled((props: StyledTabsProps) => (
  <Tabs
    sx={{ width: '100%', minHeight: 28, maxHeight: 28 }}
    {...props}
    /* @ts-ignore */
    onClick={(event) => {
      /* @ts-ignore */
      props.onClick(event, props.value);
    }}
  />
))(() => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  ' & button': {
    minHeight: '28px',
    maxHeight: '28px',
    height: '28px',
    borderRadius: '22px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    flex: 'auto',
  },
  '& .MuiTabs-flexContainer': {
    overflow: 'hidden',
  },
}));

export const MainNavTab = styled((props: StyledTabProps) => (
  <Tab
    disableRipple
    {...props}
    onMouseEnter={(event) =>
      props.popoverEnter ? props.popoverEnter(event, props.position || 0) : null
    }
    onMouseLeave={() => (props.popoverLeave ? props.popoverLeave() : null)}
  />
))(({ theme }) => ({
  textTransform: 'none',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 700,
  marginRight: theme.spacing(1),
  color: theme.palette.sky.two,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '.75px',
  fontFamily: 'Source Sans Pro',
  '&:hover': {
    color: 'white',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: theme.palette.sky.eight,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

export const MobileMenu = styled(Menu)(({ theme }) => ({
  '& ul': {
    listStyle: 'none',
    padding: 0,
    border: `1px solid ${theme.palette.midnight.two}`,
    borderRadius: '4px',
  },
}));

export const MobileMainNavTabs = styled((props: StyledTabsProps) => (
  <Tabs
    orientation={'vertical'}
    {...props}
    /* @ts-ignore */
    onClick={(event) => {
      /* @ts-ignore */
      props.onClick(event, props.value);
    }}
    TabIndicatorProps={{
      style: { display: 'none' },
    }}
  />
))(() => ({}));

export const MobileMainNavTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: '700',
  minWidth: '297px',
  color: '#1C708D',
  fontSize: theme.typography.pxToRem(12),
  fontFamily: [theme.typography.fontFamily].join(','),
  height: '54px',
  borderTop: '1px solid #d5dadd',
  '&:hover': {
    backgroundColor: theme.palette.sky.one,
    opacity: 1,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.dusk.one,
  },
  '&:last-of-type .MuiButtonBase-root': {
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
}));
