import React, { ReactElement } from 'react';

import {
  AccessTimeOutlined,
  AutoAwesome,
  CancelOutlined,
  Checklist,
  DirectionsRunOutlined,
  ForwardToInboxOutlined,
  HourglassDisabled,
  HourglassEmpty,
  Pause,
  SettingsOutlined,
} from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { BoxProps, IconButtonProps } from '@mui/material';

import { Member, Membership } from '../../graphql/gen/graphql';
import { Tavatar } from '../Avatar/Tavatar';
import { ChipColors, Tachip, TaChipProps } from '../Chip/Tachip';
import { CustomIcon, CustomIconProps } from '../Icons/CustomIcon';

export interface StatusAction {
  icon: CustomIconProps;
  iconButtonProps?: IconButtonProps;
  title: string;
}

/**
 * Status Board Section
 */

export interface StatusBoardSlotsComponent {}
export interface StatusBoardSlotsProps {}
export interface StatusBoardPropsBeforeProcessing {
  slots?: Partial<StatusBoardSlotsComponent>;
}
export interface StatusBoardPropsAfterProcessing {
  slots: StatusBoardSlotsComponent;
}
export interface StatusBoardPropsWithoutDefaultValue {
  slotProps?: StatusBoardSlotsProps;
}
export interface StatusBoardPropsWithDefaultValue extends BoxProps {}

export interface StatusBoardProcessedProps
  extends StatusBoardPropsWithDefaultValue,
    StatusBoardPropsAfterProcessing,
    StatusBoardPropsWithoutDefaultValue {}

export type StatusBoardProps = Partial<StatusBoardPropsWithDefaultValue> &
  StatusBoardPropsBeforeProcessing &
  StatusBoardPropsWithoutDefaultValue & {
    statusPanels: StatusPanel[];
  };

/**
 * Status Panel Section
 */

export interface StatusPanelSlotsComponent {}
export interface StatusPanelSlotsProps {}
export interface StatusPanelPropsBeforeProcessing {
  slots?: Partial<StatusPanelSlotsComponent>;
}
export interface StatusPanelPropsAfterProcessing {
  slots: StatusPanelSlotsComponent;
  autoBreakClasses: string[];
  isSizeConstrained: boolean;
}
export interface StatusPanelPropsWithoutDefaultValue {
  slotProps?: StatusPanelSlotsProps;
}
export interface StatusPanelPropsWithDefaultValue extends BoxProps {}

export interface StatusPanelProcessedProps
  extends StatusPanelPropsWithDefaultValue,
    StatusPanelPropsAfterProcessing,
    StatusPanelPropsWithoutDefaultValue {}

export type StatusPanelProps = Partial<StatusPanelPropsWithDefaultValue> &
  StatusPanelPropsBeforeProcessing &
  StatusPanelPropsWithoutDefaultValue & {
    statusPanel: StatusPanel;
  };

/**
 * Slots Section
 */

export interface StateProps {
  iconProps: CustomIconProps;
  chipProps: TaChipProps;
  displayName?: string;
}
/**
 * Status Panel Section
 */
export interface StatusPanelSectionSlotsComponent {
  /**
   * Icon displayed as the main icon for the section
   * @default CustomIcon
   */
  stateProps: { [key: string]: StateProps };

  StateIconComponent: React.JSXElementConstructor<any>;
  /**
   * Icon displayed as the main icon for the section
   * @default CustomIcon
   */
  headerIcon: CustomIconProps;

  HeaderIconComponent: React.JSXElementConstructor<any>;
}
export interface StatusPanelSectionSlotsProps {
  stateIcons: { [key: string]: any };
  stateIconComponent: any;
  headerIcon: any;
  headerIconComponent: any;
}
export interface StatusPanelSectionPropsBeforeProcessing {
  slots?: Partial<StatusPanelSectionSlotsComponent>;
}
export interface StatusPanelSectionPropsAfterProcessing {
  slots: StatusPanelSectionSlotsComponent;
}
export interface StatusPanelSectionPropsWithoutDefaultValue {
  slotProps?: Partial<StatusPanelSectionSlotsProps>;
}

export type StatusBoardVariant =
  | 'pipeline-header'
  | 'pipeline-source'
  | 'pipeline-service'
  | 'pipeline-target'
  | 'task';

export interface StatusPanelSectionPropsWithDefaultValue extends BoxProps {
  variant: StatusBoardVariant;
}

export interface StatusPanelSectionProcessedProps
  extends StatusPanelSectionPropsWithDefaultValue,
    StatusPanelSectionPropsAfterProcessing,
    StatusPanelSectionPropsWithoutDefaultValue {}

export type StatusPanelSectionProps =
  Partial<StatusPanelSectionPropsWithDefaultValue> &
    StatusPanelSectionPropsBeforeProcessing &
    StatusPanelSectionPropsWithoutDefaultValue & {
      associatedMember?: Membership & Member;
      headerLabel?: string;
      headerName?: string;
      headerProps?: BoxProps;
      state?: string;
      progress?: number;
      progressType?: 'indeterminate' | 'determinate';
      statusMessages?: (string | ReactElement)[];
      details?: ReactElement;
      hideForwardArrow?: boolean;
    };

export interface StatusPanel {
  sections: StatusPanelSectionProps[];
  actions?: StatusAction[];
  dashboardDropdownContent?: JSX.Element | undefined;
  id?: string;
}

export const STATUS_BOARD_DEFAULT_SLOTS_COMPONENTS: StatusBoardSlotsComponent =
  {};

export const STATUS_PANEL_DEFAULT_SLOTS_COMPONENTS: StatusPanelSlotsComponent =
  {};

export const STATUS_PANEL_SECTION_DEFAULT_STATE_PROPS: {
  [key: string]: StateProps;
} = {
  ready: {
    iconProps: { icon: CheckIcon },
    chipProps: { type: ChipColors.Success },
    displayName: 'Ready',
  },
  running: {
    iconProps: { icon: DirectionsRunIcon },
    chipProps: { type: ChipColors.Info },
    displayName: 'Running',
  },
  in_progress: {
    iconProps: { icon: DirectionsRunIcon },
    chipProps: { type: ChipColors.Info },
    displayName: 'In Progress',
  },
  paused: {
    iconProps: { icon: Pause },
    chipProps: { type: ChipColors.Warning },
    displayName: 'Paused',
  },
  errored: {
    iconProps: { icon: ErrorOutlineIcon },
    chipProps: { type: ChipColors.Error },
    displayName: 'Errored',
  },
  cancelling: {
    iconProps: { icon: HourglassEmpty },
    chipProps: { type: ChipColors.Neutral },
    displayName: 'Cancelling',
  },
  cancelled: {
    iconProps: { icon: WarningAmberIcon },
    chipProps: { type: ChipColors.Warning },
    displayName: 'Cancelled',
  },
  complete: {
    iconProps: { icon: CheckIcon },
    chipProps: { type: ChipColors.Success },
    displayName: 'Complete',
  },
  created: {
    iconProps: { icon: Checklist },
    chipProps: { type: ChipColors.Success },
    displayName: 'Created',
  },
  failed: {
    iconProps: { icon: ErrorOutlineIcon },
    chipProps: { type: ChipColors.Error },
    displayName: 'Failed',
  },
  submitted: {
    iconProps: { icon: HourglassEmpty },
    chipProps: { type: ChipColors.Neutral },
    displayName: 'Submitted',
  },
  'timed out': {
    iconProps: { icon: HourglassDisabled },
    chipProps: { type: ChipColors.Error },
    displayName: 'Timed Out',
  },
  default: {
    iconProps: { icon: AutoAwesome },
    chipProps: { type: ChipColors.Neutral },
  },
};

export const STATUS_PANEL_SECTION_DEFAULT_STATE_COMPONENT = Tachip;
export const getDefaultStatusPanelSectionSlots = (
  inProps: StatusPanelSectionProps,
) => ({
  StateIconComponent: STATUS_PANEL_SECTION_DEFAULT_STATE_COMPONENT,
  stateProps: STATUS_PANEL_SECTION_DEFAULT_STATE_PROPS,
  HeaderIconComponent:
    inProps.variant === 'pipeline-header' ? Tavatar : CustomIcon,
  headerIcon: { icon: SettingsOutlined },
});
