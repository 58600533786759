import React, { useMemo, useState } from 'react';

import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, ListItem, Typography, List } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { toSecurity } from '../../../RouteTable';
import { useAuth } from '../../../context/auth-context';
import { Warehouse } from '../../../graphql/gen/graphql';
import { Talert } from '../../Alert/Talert';
import { CustomIcon } from '../../Icons/CustomIcon';
import { Tabulink } from '../../Link/Tabulink';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../WizardContainer';
import { AthenaDataSourceSetup } from './AthenaDataSourceSetup';
import { AthenaErrorsNonSA } from './AthenaErrorsNonSA';
import { AthenaErrorsSecurityAdmin } from './AthenaErrorsSecurityAdmin';

const AthenaSQLConnectionWizardDialog = create<{
  organizationId: string;
  organizationName: string;
  warehouse: Warehouse;
}>(({ warehouse, organizationName, organizationId }) => {
  const modal = useModal();
  const { user } = useAuth();
  const [errors, setErrors] = useState(false);
  const isSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(organizationName),
    [user, organizationName],
  );
  const displayName = user?.displayName;
  const region = warehouse?.region;

  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: 'Prerequisites',
        body: (
          <>
            <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
              <ListItem sx={{ display: 'list-item', p: 0 }}>
                <Typography variant={'body1'}>
                  A workgroup setup with <b>Athena SQL engine version 3</b> is
                  required, link to instructions:
                </Typography>
                <Typography sx={{ display: 'inline-flex', mr: 1, gap: 1 }}>
                  <Tabulink
                    external
                    href={` https://docs.aws.amazon.com/athena/latest/ug/workgroups-create-update-delete.html#creating-workgroups`}
                    variant="body1"
                    rel="noopener"
                    aria-label="Amazon Athena workgroup creation"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {'Amazon Athena workgroup creation'}
                    <OpenInNewIcon
                      fontSize="small"
                      sx={{ ml: '2px', mr: '3px' }}
                    />
                  </Tabulink>
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item', p: 0, my: 1 }}>
                <Typography variant={'body1'}>
                  An <b>AWS IAM identity provider</b> setup in Tabular:
                </Typography>
                <Typography sx={{ display: 'inline-flex', mr: 1, gap: 1 }}>
                  {isSecurityAdmin ? (
                    <Tabulink
                      external
                      href={`${toSecurity(organizationName)}#aws-identity`}
                      variant="body1"
                      rel="noopener"
                      aria-label="Tabular security"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {
                        'As security admin you can manage identities in Connections > Security'
                      }
                      <OpenInNewIcon
                        fontSize="small"
                        sx={{ ml: '2px', mr: '3px' }}
                      />
                    </Tabulink>
                  ) : (
                    <>
                      {
                        'A security admin is needed to add this identity in Connections > Security'
                      }
                    </>
                  )}
                </Typography>
              </ListItem>
            </List>
            <Box pt={1}>
              <Typography variant={'body1'}>
                If you already have a workgroup, please click <b>continue</b>
              </Typography>
            </Box>
          </>
        ),
      },
      {
        title: 'Create datasource',
        body: (
          <AthenaDataSourceSetup
            region={region}
            warehouseName={warehouse.name}
          />
        ),
      },
      {
        title: 'Confirm connection',
        body: (
          <>
            <Box sx={{ width: 0.95 }}>
              <Box>
                <Typography variant={'body1'}>
                  If you are successfully connected, click <b>finish</b>.
                </Typography>
              </Box>
              {!errors && (
                <Talert
                  severity={'info'}
                  sx={{ width: 'fit-content', mt: 2 }}
                  alertTitle={'Running into errors?'}
                  TabuttonProps={{
                    children: <Box>Troubleshoot</Box>,
                    size: 'small',
                    sx: {
                      m: 1,
                      borderRadius: '16px',
                    },
                    onClick: () => setErrors(true),
                  }}
                >
                  Ensure authentication is setup correctly
                </Talert>
              )}

              {errors && isSecurityAdmin && (
                <AthenaErrorsSecurityAdmin
                  organizationId={organizationId}
                  organizationName={organizationName}
                />
              )}
              {errors && !isSecurityAdmin && (
                <AthenaErrorsNonSA
                  organizationId={organizationId}
                  displayName={displayName}
                />
              )}
            </Box>
          </>
        ),
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <WizardContainer
        title={'Amazon Athena'}
        stepsTitle={warehouse?.name}
        stepsSubtitle={`Connect Amazon Athena`}
        stepsIcon={
          <CustomIcon
            src={'/assets/img/logos/athena-sql.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        backButtonClick={() => {}}
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        sx={{ minHeight: '550px', width: 1, height: '98vh' }}
      />
    </Dialog>
  );
});

export function showAthenaSQLConnectionWizardDialog({
  warehouse,
  organizationName,
  organizationId,
}: {
  warehouse: Warehouse;
  organizationName: string;
  organizationId: string;
}) {
  return show(AthenaSQLConnectionWizardDialog, {
    warehouse,
    organizationName,
    organizationId,
  });
}
