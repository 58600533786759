import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApolloError, useMutation } from '@apollo/client';
import { Box, DialogActions } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { includes } from 'lodash';
import * as Yup from 'yup';

import { useAuth } from '../../context/auth-context';
import { Role } from '../../graphql/gen/graphql';
import { organizationRolesQuery } from '../../graphql/organization';
import { createRole } from '../../graphql/role';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';

export default function CreateRole({
  organizationId,
  onCancel,
  onRoleCreated,
  roleOnTheFly = false,
}: {
  organizationId: string;
  onCancel: () => void;
  onRoleCreated: (role: Role) => void;
  roleOnTheFly?: boolean;
}) {
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [mutationError, setMutationError] = useState(false);

  const [createRoleMutation, { reset }] = useMutation(createRole);

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    const roleMembers = values.members;

    const result = await createRoleMutation({
      variables: {
        role: {
          organizationId,
          roleName: values.name,
          members: roleMembers,
        },
      },
      refetchQueries: [
        {
          query: organizationRolesQuery,
          variables: {
            organizationId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onError: (e: ApolloError) => {
        if (includes(e.message, '409: Conflict')) {
          setErrorMessage('A role with this name already exists.');
        } else {
          setErrorMessage(
            'An unexpected error occurred. Please refresh the page and try again. ',
          );
        }
        setMutationError(true);
      },
    });

    await setSubmitting(false);
    result?.data && onRoleCreated(result?.data?.createRole);
  };

  const getInitialValues = () => {
    const membersArray = [];

    if (
      roleOnTheFly ||
      (!roleOnTheFly &&
        !user.isSecurityAdmin(user.loginSession.loggedInOrg.name))
    ) {
      membersArray.push({
        subject: { type: 'USER', identifier: user.id },
        admin: true,
      });
    }

    return {
      name: '',
      members: membersArray,
    };
  };

  const initialValues = getInitialValues();
  const validationSchema = Yup.object({
    name: Yup.string().required('Role name is required.'),
    members: Yup.array().of(Yup.object().nullable()),
  });

  return (
    <Box>
      <DialogTitle>Create role</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, touched, errors }) => (
            <Form>
              {mutationError && (
                <Talert
                  severity="error"
                  onClose={() => {
                    reset();
                    setMutationError(false);
                    setErrorMessage('');
                  }}
                >
                  {errorMessage}
                </Talert>
              )}

              <Field
                component={TextField}
                id="name"
                name="name"
                type="text"
                label="Role name"
                variant="outlined"
                margin="normal"
                autoComplete="off"
                fullWidth
                error={touched['name'] && errors['name']}
              />

              <DialogActions sx={{ mt: 2 }}>
                <Tabutton
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Cancel
                </Tabutton>
                <Tabutton
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Create Role
                </Tabutton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Box>
  );
}
