const protocol = process.env.REACT_APP_SERVICE_PROTOCOL;
export function downloadBlob(blob: Blob, filename: string) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);

  // Create a new anchor element
  const a = document.createElement('a');

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || 'download';

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      // @ts-ignore
      this.removeEventListener('click', clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener('click', clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
}

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hashCode(str: string): number {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

const getBaseUrl = () => {
  if (window.location.hostname === 'localhost') {
    return 'app.dev.tabular.io';
  }
  const baseDomainParts = window.location.hostname.split('.').slice(1);
  baseDomainParts.unshift('app');
  return baseDomainParts.join('.');
};

export const isPreviewBranchMode = () =>
  !(
    window.location.hostname === 'localhost' ||
    window.location.hostname.startsWith('app.')
  );

export const getGooglePreviewLoginUrl = () => {
  return `${protocol}://${getBaseUrl()}${
    process.env.REACT_APP_PREVIEW_GOOGLE_LOGIN_SUFFIX
  }`;
};
