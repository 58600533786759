import React from 'react';

import { Box, Grid, Popper, Typography } from '@mui/material';

import { toTableAccessControls } from '../../RouteTable';
import { AsyncState, Table } from '../../graphql/gen/graphql';
import { Talert } from '../Alert/Talert';
import DashboardCard, {
  DashboardItem,
} from '../DataGridDashboard/DashboardCard';
import DataGridDashboard from '../DataGridDashboard/DataGridDashboard';
import { MenuContentType } from '../DataGridToolbar/DataGridToolbar';
import { Tabulink } from '../Link/Tabulink';
import TableEdit, { EditTableMode } from '../TableEdit/TableEdit';

type DashboardItemsType = {
  items: DashboardItem[];
};

export const TableOverview = ({
  dashboardItems,
  showPermissionsError,
  organizationName,
  warehouseName,
  warehouseId,
  databaseName,
  tableName,
  table,
  loading,
  refreshResourceContent,
  editResourceContent,
  dashboardMenuContent,
  isView,
}: {
  dashboardItems: DashboardItemsType[];
  showPermissionsError: boolean;
  organizationName: string;
  warehouseName: string;
  warehouseId: string;
  databaseName: string;
  tableName: string;
  table: Table;
  loading: boolean;
  refreshResourceContent: MenuContentType;
  editResourceContent?: MenuContentType;
  dashboardMenuContent: JSX.Element;
  isView: boolean;
}) => {
  return (
    <>
      {!isView && (
        <>
          <DataGridDashboard dashboardCards={dashboardItems} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              mt: -1,
              mb: 2,
            }}
          >
            <Typography variant={`helperText`} sx={{ color: 'midnight.six' }}>
              Dashboard is representative of the main branch
            </Typography>
          </Box>
        </>
      )}

      {showPermissionsError ? (
        <Box>
          <Talert severity={`error`} sx={{ width: 'fit-content' }}>
            You do not have permissions to access this table. As a Security
            Admin, you can modify permissions on the{' '}
            <Tabulink
              href={toTableAccessControls(
                organizationName,
                warehouseName,
                databaseName,
                tableName,
              )}
            >
              Access Control
            </Tabulink>{' '}
            tab.
          </Talert>
        </Box>
      ) : (
        <Box>
          {table && (
            <TableEdit
              warehouseId={warehouseId}
              warehouseName={warehouseName}
              database={databaseName}
              mode={EditTableMode.VIEW}
              table={table}
              refreshResourceContent={refreshResourceContent}
              editResourceContent={editResourceContent}
              dashboardMenuContent={dashboardMenuContent}
            />
          )}
        </Box>
      )}
    </>
  );
};
