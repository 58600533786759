import * as React from 'react';
import { useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import {
  CredentialTypes,
  //@ts-ignore
  LinkedCredentialType,
  OrganizationCredential,
  Role,
} from '../../graphql/gen/graphql';
import {
  deleteOrgCredential,
  updateOrgCredential,
} from '../../graphql/organization';
import { Tacard } from '../Card/Tacard';
import { CreateIAMRoleMappingDialog } from '../Credentials/Dialogs/CreateIAMRoleMappingDialog';
import { CreateServiceMappingDialog } from '../Credentials/Dialogs/CreateServiceMappingDialog';
import IAMRoleMapping from '../Credentials/Grids/IAMRoleMapping';
import ServiceMapping from '../Credentials/Grids/ServiceMapping';
import { CustomIcon } from '../Icons/CustomIcon';
import { ConfirmationDialog } from '../Modals/ConfirmationDialog';

export default function RoleSecurity({
  role,
  loading,
  currentUserEmail,
  refetchRole,
  orgName,
  orgId,
  canModifyRole,
}: {
  role: Role;
  loading: boolean;
  currentUserEmail: string;
  refetchRole: () => void;
  orgName: string;
  orgId: string;
  canModifyRole: boolean;
}) {
  const [credToDelete, setCredToDelete] = useState({} as LinkedCredentialType);
  const [confirmModal, setConfirmModal] = useState(false);
  const [createServiceDialogVisible, setCreateServiceDialogVisible] =
    useState(false);
  const [createIAMDialogVisible, setCreateIAMDialogVisible] = useState(false);
  const [updateCredential] = useMutation(updateOrgCredential);
  const [deleteCredential, { loading: deleteLoading }] = useMutation(
    deleteOrgCredential,
    {
      variables: {
        organizationId: orgId,
        credentialKey: credToDelete.key,
      },
      onCompleted: async () => {
        await refetchRole();
        setConfirmModal(false);
        setCredToDelete({} as LinkedCredentialType);
        enqueueSnackbar('Successfully deleted', { variant: 'success' });
      },
    },
  );
  const serviceAccountCreds = useMemo(
    () =>
      //@ts-ignore
      role?.linkedCredentials?.filter(
        //@ts-ignore
        (cred: LinkedCredentialType) => cred.type === CredentialTypes.Service,
      ),
    [role],
  );
  const iamCreds = useMemo(
    () =>
      //@ts-ignore
      role?.linkedCredentials?.filter(
        //@ts-ignore
        (cred: LinkedCredentialType) => cred.type === CredentialTypes.Iam,
      ),
    [role],
  );
  const confirmCredentialDelete = (credential: any) => {
    setCredToDelete(credential);
    setConfirmModal(true);
  };

  const handleCredentialUpdate = async (
    credentialKey: string,
    updates: any,
  ) => {
    await updateCredential({
      variables: {
        organizationId: orgId,
        credentialKey,
        updates: updates,
      },
    });

    await refetchRole();
  };

  return (
    <>
      <Stack direction={`column`} spacing={2}>
        <Tacard>
          <ServiceMapping
            credentialsData={serviceAccountCreds as [OrganizationCredential]}
            onCredentialDelete={confirmCredentialDelete}
            onCredentialUpdate={handleCredentialUpdate}
            currentUserEmail={currentUserEmail}
            roles={[role]}
            loading={loading}
            refreshResourceContent={{
              title: 'Refresh service accounts list',
              action: refetchRole,
            }}
            addResourceContent={{
              title: 'Create service account',
              action: () => setCreateServiceDialogVisible(true),
              disabled: !canModifyRole,
            }}
            showTabularRole={false}
            disabled={!canModifyRole}
          />
        </Tacard>
        <Tacard>
          <IAMRoleMapping
            credentialsData={iamCreds as [OrganizationCredential]}
            onCredentialDelete={confirmCredentialDelete}
            onCredentialUpdate={handleCredentialUpdate}
            currentUserEmail={currentUserEmail}
            refreshResourceContent={{
              title: 'Refresh AWS IAM role mapping list',
              action: refetchRole,
            }}
            addResourceContent={{
              title: 'Create AWS IAM role mapping',
              action: () => setCreateIAMDialogVisible(true),
              disabled: !canModifyRole,
            }}
            orgName={orgName}
            roles={[role]}
            loading={loading}
            showTabularRole={false}
            disabled={!canModifyRole}
          />
        </Tacard>
      </Stack>
      <CreateServiceMappingDialog
        organizationId={orgId}
        preSelectedRoleId={role?.id}
        modalTitle={`Connect a credential to ${role?.displayName} role`}
        isOpen={createServiceDialogVisible}
        onDismiss={() => setCreateServiceDialogVisible(false)}
        onCredentialCreated={async () => {
          setCreateServiceDialogVisible(false);
          refetchRole();
          enqueueSnackbar('Service account credential created', {
            variant: 'success',
          });
        }}
      />
      <CreateIAMRoleMappingDialog
        organizationId={orgId}
        preSelectedRoleId={role?.id}
        modalTitle={`Connect an AWS IAM role to ${role?.displayName} role`}
        isOpen={createIAMDialogVisible}
        onDismiss={() => setCreateIAMDialogVisible(false)}
        onCredentialCreated={async () => {
          setCreateIAMDialogVisible(false);
          await refetchRole();
          enqueueSnackbar('IAM role mapping created', {
            variant: 'success',
          });
        }}
        isExternalOption={false}
      />
      <ConfirmationDialog
        open={confirmModal}
        loading={deleteLoading}
        title={`Delete ${credToDelete?.name}?`}
        acceptText="Delete"
        onDismiss={() => {
          setConfirmModal(false);
          setCredToDelete({} as LinkedCredentialType);
        }}
        onAccept={deleteCredential}
      />
    </>
  );
}
