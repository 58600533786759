import { gql } from '@apollo/client';

import { FRAGMENTS } from './fragments';

export enum BuiltInRoleNames {
  EVERYONE = 'EVERYONE',
  SECURITY_ADMIN = 'SECURITY_ADMIN',
  ORG_ADMIN = 'ORG_ADMIN',
}

export const builtInRoles: BuiltInRoleNames[] = [
  BuiltInRoleNames.EVERYONE,
  BuiltInRoleNames.SECURITY_ADMIN,
  BuiltInRoleNames.ORG_ADMIN,
];

//queries
export const getRolesByOrgIdQuery = gql`
  query rolesByOrganizationId(
    $organizationId: String!
    $noCache: Boolean
    $roleNameFilter: [String]
  ) {
    organization(id: $organizationId) {
      id
      name
      roles(noCache: $noCache, roleNameFilter: $roleNameFilter) {
        ...SubjectFields
        users {
          ...RoleMemberFields
        }
        children {
          id
          name
          displayName
          users {
            ...RoleMemberFields
          }
        }
        parents {
          id
          name
          displayName
        }
      }
    }
  }
  ${FRAGMENTS.roleMember}
  ${FRAGMENTS.subject}
`;

export const getRoleById = gql`
  query roleById(
    $organizationId: String!
    $organizationName: String!
    $organizationDisplayName: String!
    $roleId: String!
    $noCache: Boolean
  ) {
    role(
      id: $roleId
      organizationId: $organizationId
      organizationName: $organizationName
      organizationDisplayName: $organizationDisplayName
    ) {
      id
      name
      displayName
      organizationName
      users {
        ...RoleMemberFields
      }
      authorizations(noCache: $noCache) {
        ...RoleAuthFields
      }
      children {
        id
        name
        displayName
        users {
          ...RoleMemberFields
        }
      }
      parents {
        id
        name
        displayName
      }
    }
  }
  ${FRAGMENTS.roleMember}
  ${FRAGMENTS.roleAuths}
`;

export const getRoleByName = gql`
  query fetchRoleByName(
    $organizationId: String!
    $roleName: String!
    $organizationName: String!
    $organizationDisplayName: String!
  ) {
    fetchRoleByName(
      organizationId: $organizationId
      roleName: $roleName
      organizationName: $organizationName
      organizationDisplayName: $organizationDisplayName
    ) {
      ...SubjectFields
      users {
        ...RoleMemberFields
      }
      authorizations(noCache: true) {
        ...RoleAuthFields
      }
      linkedCredentials {
        ...LinkedCredentialFields
      }
      parents {
        id
        name
        displayName
        users {
          id
          roleId
          email
        }
        children {
          id
          name
        }
      }
      children {
        id
        name
        displayName
        users {
          id
          roleId
          email
        }
      }
    }
  }
  ${FRAGMENTS.subject}
  ${FRAGMENTS.roleMember}
  ${FRAGMENTS.roleAuths}
  ${FRAGMENTS.linkedCredential}
`;

export const searchRoleAuthorizationsQuery = gql`
  query SearchRoleAuths(
    $roleId: String!
    $query: String!
    $maxResults: Int
    $searchAfter: [String]
    $sortBy: [SortByInput]
  ) {
    searchRoleAuthorizations(
      roleId: $roleId
      query: $query
      maxResults: $maxResults
      searchAfter: $searchAfter
      sortBy: $sortBy
    ) {
      resultSize
      totalHits
      searchAfterKeys
      results {
        id
        subjectId
        resource
        resourceType
        resourceName
        description
        parents {
          namespace
          warehouse
        }
        privileges {
          privilege
          withGrant
        }
      }
    }
  }
`;

export const getRoleAuthAggregatesQuery = gql`
  query getRoleAuthAggregates($roleId: String!) {
    roleAuthAggregates(roleId: $roleId) {
      count
      resourceType
    }
  }
`;

//mutations
export const createRole = gql`
  mutation CreateRole($role: CreateRoleInput!) {
    createRole(role: $role) {
      id
      name
      users {
        ...RoleMemberFields
      }
      children {
        id
        name
        displayName
        users {
          id
          roleId
          email
        }
      }
    }
  }
  ${FRAGMENTS.roleMember}
`;

export const updateRole = gql`
  mutation UpdateRole($role: UpdateRoleInput!) {
    updateRole(role: $role) {
      id
      name
      users {
        ...RoleMemberFields
      }
      children {
        id
        name
        displayName
        users {
          id
          roleId
          email
        }
      }
    }
  }
  ${FRAGMENTS.roleMember}
`;

export const deleteRole = gql`
  mutation DeleteRole($roleId: String!, $force: Boolean) {
    deleteRole(role_id: $roleId, force: $force)
  }
`;

export const createRoleRelationship = gql`
  mutation RoleRelationship($parentRoleId: String!, $childRoleId: String!) {
    createRoleRelationship(
      parentRoleId: $parentRoleId
      childRoleId: $childRoleId
    )
  }
`;
