import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Box, BoxProps, Grid, MenuItem, Typography } from '@mui/material';

import { Database } from '../../graphql/gen/graphql';
import { warehouseById } from '../../graphql/warehouse';
import { STATISTICS_DAYS_BACK } from '../../pages/helpers';
import { RoleSelector } from '../Forms/RoleSelector';
import TextField from '../Forms/TextField';
import ChangelogOrderEdit from './ChangelogOrderEdit';

export interface TableEditChangelogDetailsProps extends BoxProps {
  organizationId: string;
  warehouseId: string;
  validityChanged: (isValid: number) => void;
}

export const TableEditChangelogDetails = ({
  organizationId,
  warehouseId,
  validityChanged,
  ...props
}: TableEditChangelogDetailsProps) => {
  const { data, loading, error } = useQuery(warehouseById, {
    variables: {
      organizationId,
      warehouseId,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });
  const databases: Database[] = useMemo(
    () =>
      [...(data?.organization?.warehouse?.databases ?? [])].sort(
        (a: Database, b: Database) =>
          a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1,
      ) ?? [],
    [data],
  );

  return (
    <Box {...props}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8} lg={4}>
          <TextField
            fullWidth
            select
            name={'changelogDatabaseName'}
            label="Changelog database name"
            helperText
            variant="outlined"
            margin="normal"
            autoComplete="off"
            required
          >
            {databases.map((database) => (
              <MenuItem key={database.name} value={database.name}>
                {database.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Typography>
            Indicate how to order your changelog by specifying columns below.
          </Typography>
          <ChangelogOrderEdit
            validityChanged={validityChanged}
          ></ChangelogOrderEdit>
        </Grid>
      </Grid>
    </Box>
  );
};
