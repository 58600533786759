import React, { useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { useAuth } from '../../../context/auth-context';
import { RoleSelector } from '../../Forms/RoleSelector';
import { Content } from './helpers';

export default function BucketBasics({ content }: { content: Content }) {
  const { user } = useAuth();
  const everyoneRole = useMemo(
    () =>
      user?.loginSession?.roles?.find((role: any) => role.name === 'EVERYONE'),
    [user],
  );
  return (
    <Box display={'flex'} flexDirection={'column'} sx={{ px: 5, pb: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          mb: 4,
        }}
      >
        <Typography variant={'h1'} sx={{ p: 0, pb: 1 }}>
          {content.header}
        </Typography>

        <Typography variant={'subtitle1'}>{content.subHeader}</Typography>
        <Box sx={{ pt: 4 }}>
          {content.description}
          {content.inputs}
          <Typography variant={'h6'} sx={{ mt: 3 }}>
            Tabular storage profile
          </Typography>
          <Typography variant={'body1'} sx={{ mt: 1 }}>
            With your cloud account information, we will create a Tabular
            storage profile. Please choose a role to which you want to grant
            full access to this storage profile.
          </Typography>
          <RoleSelector
            name="creatorRoleId"
            textFieldProps={{
              variant: 'standard',
              label: 'Initial Tabular role*',
              helperText:
                'Select a Tabular role you wish to have full access to this storage profile.',
              value: {
                roleId: everyoneRole.id,
                roleName: everyoneRole.name,
              },
            }}
            creatorRoleStep={true}
          />
        </Box>
      </Box>
    </Box>
  );
}
