import React from 'react';

import { SkipNextRounded } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Link } from '@mui/material';

import {
  AsyncState,
  TableLoadState,
  TableLoadStatus,
} from '../../graphql/gen/graphql';
import { Tabutton } from '../Button/Tabutton';
import StatusActionView from '../StatusBoard/StatusActionView';

export function getTableLoadState(status: TableLoadStatus) {
  return status.state === AsyncState.Cancelled &&
    status.loadTableState === TableLoadState.Cancelling
    ? 'Cancelling'
    : status.state;
}
export function getTableLoadStatusMessage(
  tableName?: string,
  status?: TableLoadStatus,
  onClick?: any,
): string | JSX.Element {
  if (!status) {
    return `Preparing table load`;
  }
  switch (status.state) {
    case AsyncState.TimedOut:
      return `Table load timed out: ${status.statusMessage}`;

    case AsyncState.Cancelled:
      switch (status.loadTableState) {
        case TableLoadState.Cancelling:
          return `Table load attempting cancel`;
        default:
          return `Table load cancelled for`;
      }

    case AsyncState.Created:
    case AsyncState.Submitted:
      return `Preparing table load`;
    case AsyncState.InProgress:
      switch (status.loadTableState) {
        case TableLoadState.Init:
          return `Initializing workflow`;
        case TableLoadState.LoadingBootStrap:
          return `Loading existing files`;

        case TableLoadState.LoadingBackFill:
          return `Backfill in progress`;
        case TableLoadState.PendingBackFill:
          return (
            <Box sx={{ display: 'flex' }}>
              {`Waiting for auto file loader events `}
              <Link onClick={onClick} sx={{ textDecoration: 'none', ml: 1 }}>
                skip file load detection
              </Link>
            </Box>
          );

        case TableLoadState.Loading:
        default:
          return `Table load in progress`;
      }

    case AsyncState.Failed:
      return `Table load failed: ${status.statusMessage}`;
    case AsyncState.Complete:
      if (status.loadTableState == TableLoadState.Completed) {
        if (
          status.tableLoadMode === 'APPEND_AUTO_LOAD' ||
          status.tableLoadMode === 'REPLACE_AUTO_LOAD'
        ) {
          if (status.notificationConfigIssueDetected) {
            return `Warning: We found new files without receiving notifications. Please check bucket auto file loader notification config.`;
          }
          if (!status.autoLoaderDetected && status?.backFillFileCount === 0) {
            return `Completed table load successfully, no newly landed files detected`;
          }
          if (
            !status.autoLoaderDetected &&
            (status?.backFillFileCount ?? 0) > 0
          ) {
            return `Completed table load successfully, new files back-filled`;
          }
          if (status.autoLoaderDetected) {
            return `Completed table load successfully, auto file loader running successfully`;
          }
        }

        return `Completed table load successfully`;
      }

      return `Table load complete`;
  }
}
