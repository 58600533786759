import { Role } from '../graphql/gen/graphql';
import { BuiltInRoleNames } from '../graphql/role';

export function getFilteredRoles(user: any) {
  return user?.loginSession?.roles
    ?.filter(
      (role: Role) =>
        role.name !== BuiltInRoleNames.ORG_ADMIN ||
        BuiltInRoleNames.SECURITY_ADMIN,
    )
    .map((role: Role) => ({
      roleId: role.id,
      roleName: role.name,
    }));
}
