import React, { useCallback, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { useMutation } from '@apollo/client';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  CircularProgress,
  Typography,
  Box,
  Chip,
  Avatar,
  Stack,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';

import { LOGIN_DEST_ORG_ID } from '../../context/GlobalPropsContext';

import { ROUTES } from '../../RouteTable';
import { Talert } from '../../components/Alert/Talert';
import { Tabutton } from '../../components/Button/Tabutton';
import AuthFormContainer from '../../components/Forms/AuthFormContainer';
import BaseLayout from '../../components/Layouts/BaseLayout';
import EmailButtonGroup from '../../components/Login/EmailButtonGroup';
import GoogleAuthButton from '../../components/Login/GoogleAuthButton';
import MagicLogin from '../../components/Login/MagicLogin';
import OktaSignInWidget, {
  OktaWidgetMode,
} from '../../components/Login/OktaSignInWidget';
import PasswordButtonGroup from '../../components/Login/PasswordButtonGroup';
import {
  loginSchema,
  RESET_EMAIL_TEXT,
  tabularWelcomeContent,
} from '../../components/Login/helpers';
import { useAuth } from '../../context/auth-context';
import { usePosthogClient } from '../../context/posthog';
import useLoginListener from '../../hooks/UseLoginListener';
import { getLogger } from '../../utils/logging';
import {
  getGooglePreviewLoginUrl,
  isPreviewBranchMode,
} from '../../utils/webutils';

const logger = getLogger('Login' /*FUTURE import.meta.url ?*/);

export default function Login() {
  //clear invalid link state when refreshing page without re-rendering
  window.history.replaceState({}, document.title);
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePosthogClient();
  const from = location.state?.from || '/';
  const { login, loginWithToken, requestMagicLink, lastError, clearLastError } =
    useAuth();

  const isPreview = isPreviewBranchMode();
  const previewUrl = getGooglePreviewLoginUrl();

  const [titleText, setTitleText] = useState(
    location.pathname.endsWith('okta')
      ? 'Log in with Okta'
      : location.pathname.endsWith('magic')
      ? 'Check your inbox'
      : 'Welcome Back',
  );
  const [subtitleText, setSubtitleText] = useState(
    location.pathname.endsWith('okta') || location.pathname.endsWith('magic')
      ? ''
      : 'Nice to see you again.',
  );
  const [submitButtonContent, setSubmitButtonContent] = useState<
    string | JSX.Element
  >('Log In');

  const [params, setParams] = useSearchParams();
  let emailSearchParam = params.get('email');
  const [emailValue, setEmailValue] = useState(emailSearchParam || '');
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showMagicForm, setShowMagicForm] = useState(false);
  const [showOktaEmailForm, setShowOktaEmailForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showResetInfo, setShowResetInfo] = useState(false);
  const [helpText, setHelpText] = useState(`Need an account?`);
  const [actionText, setActionText] = useState(`Sign Up`);
  const [codeCount, setCodeCount] = useState(0);
  const showWelcomeForm =
    !showEmailForm &&
    !showOktaEmailForm &&
    !showPasswordForm &&
    !showResetInfo &&
    !showMagicForm;
  const showBackButton =
    showEmailForm ||
    showOktaEmailForm ||
    showPasswordForm ||
    showMagicForm ||
    location.pathname.endsWith('okta');
  const [leftGridContent, setLeftGridContent] = useState(tabularWelcomeContent);

  const handleSubmit = (values: any, actions: any) => {
    setSubmitButtonContent(<CircularProgress color="inherit" size={15} />);
    login(values)
      .then(() => posthog?.capture('login', { loginType: 'credentials' }))
      .then(() => navigate(from, { replace: true }))
      .catch((e: any) => {
        logger.error('Problem during submit.', e);
        setSubmitButtonContent('Log In');
        actions.setSubmitting(false); // Change Formix isSubmitting back to false
      });
  };

  const handleGoogleSignIn = (idToken: string) => {
    const destinationOrgId = sessionStorage.getItem(LOGIN_DEST_ORG_ID);
    loginWithToken(idToken, destinationOrgId)
      .then(() =>
        posthog?.capture('login', {
          loginType: 'token',
          loginProvider: 'google',
        }),
      )
      .then(() => navigate(from, { replace: true }))
      .catch((e: any) => {
        logger.error('Problem during google login.', e);
      });
  };

  useLoginListener((token) => handleGoogleSignIn(token));

  const handleMagicLinkRequest = useCallback((email: string) => {
    requestMagicLink(email)
      .then(() => {})
      .catch((e: any) => {
        logger.error('Problem during magic login request.', e);
      });
  }, []);

  const handleOktaSignIn = async (idToken: string, orgId: string) => {
    return loginWithToken(idToken, orgId)
      .then(() =>
        posthog?.capture('login', {
          loginType: 'token',
          loginProvider: 'okta',
        }),
      )
      .then(() => navigate(from, { replace: true }))
      .catch((e: any) => {
        logger.error('Unhandled ex during okta login.', e);
      });
  };

  const handleOktaConfigError = () => {};

  const handleBackButton = () => {
    if (location.pathname.endsWith('okta')) {
      navigate('/');
    }
    if (showEmailForm || showOktaEmailForm || showMagicForm) {
      setShowEmailForm(false);
      setShowOktaEmailForm(false);
      setShowMagicForm(false);
      setTitleText(`Welcome Back`);
      setSubtitleText(`Nice to see you again.`);
      setHelpText(`Don't have an account?`);
    }

    if (showPasswordForm) {
      setShowPasswordForm(false);
      setShowEmailForm(true);
      setTitleText(`Log in with email`);
      setSubtitleText('');
    }
  };

  const handleSignUp = async () => {
    return navigate(ROUTES.signup);
  };

  return (
    <BaseLayout>
      <AuthFormContainer
        title={titleText}
        subtitle={subtitleText}
        backButton={showBackButton}
        backButtonClick={() => handleBackButton()}
        leftSideContent={leftGridContent}
        ghostMode={location.pathname.endsWith('mlcb') && !lastError}
      >
        {lastError && (
          <Talert severity="error" sx={{ mb: 2 }}>
            {lastError}
          </Talert>
        )}
        <Routes>
          <Route
            index
            path={'/'}
            element={
              <>
                <Formik
                  initialValues={{ email: emailValue, password: '' }}
                  validationSchema={loginSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, setFieldValue, errors, isValid }) => (
                    <Form>
                      {showWelcomeForm && (
                        <Box
                          sx={{
                            mb: 2,
                            mt: 4,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Stack
                            spacing={2}
                            marginTop={2}
                            alignItems={'center'}
                          >
                            {!isPreview && (
                              <GoogleAuthButton
                                onGoogleSignIn={handleGoogleSignIn}
                                sx={{ p: 1, width: 235 }}
                              />
                            )}
                            {isPreview && (
                              <iframe
                                title={'GoogleLogin'}
                                id="googleLogin"
                                src={previewUrl}
                                style={{
                                  height: '52px',
                                  width: '220px',
                                  border: 'none',
                                  overflow: 'hidden',
                                  margin: '0px',
                                  padding: '0px',
                                }}
                              ></iframe>
                            )}

                            {process.env.REACT_APP_OKTA_ENABLED && (
                              <Tabutton
                                onClick={() => {
                                  setShowOktaEmailForm(true);
                                  setTitleText(`Look up Okta account`);
                                  setSubtitleText('');
                                  clearLastError();
                                }}
                                startIcon={
                                  <Avatar
                                    src={'/assets/img/logos/okta-20px.png'}
                                    sx={{ width: 20, height: 20 }}
                                  />
                                }
                                size={'small'}
                                variant={`outlined`}
                                sx={{
                                  borderRadius: '4px',
                                  width: 235,
                                  p: 0.75,
                                }}
                              >
                                Log in with Okta
                              </Tabutton>
                            )}

                            {process.env.REACT_APP_MAGIC_LINKS_ENABLED && (
                              <Tabutton
                                onClick={() => {
                                  setShowMagicForm(true);
                                  setTitleText(`Log in with email`);
                                  setSubtitleText('');
                                  clearLastError();
                                }}
                                startIcon={<MailOutlineIcon />}
                                size={'small'}
                                variant={`outlined`}
                                sx={{
                                  borderRadius: '4px',
                                  width: 235,
                                  p: 0.75,
                                }}
                              >
                                Log in with Email
                              </Tabutton>
                            )}
                          </Stack>
                        </Box>
                      )}
                      {showEmailForm && (
                        <EmailButtonGroup
                          buttonText={`Next`}
                          formAutoComplete={`username`}
                          emailValue={emailValue}
                          setEmailValue={setEmailValue}
                          setFieldValue={setFieldValue}
                          buttonDisabled={!emailValue || !isEmpty(errors.email)}
                          buttonCallback={() => {
                            setShowPasswordForm(true);
                            setTitleText(`Enter your password`);
                            setSubtitleText('');
                            setHelpText(`Forget your password?`);
                            setActionText(`Yes`);
                            setShowEmailForm(false);
                          }}
                        />
                      )}
                      {showOktaEmailForm && (
                        <EmailButtonGroup
                          buttonText={`Next`}
                          formAutoComplete={`username`}
                          emailValue={emailValue}
                          setEmailValue={setEmailValue}
                          setFieldValue={setFieldValue}
                          buttonDisabled={!emailValue || !isEmpty(errors.email)}
                          buttonCallback={() => {
                            //setShowOktaForm(true);
                            navigate('okta?email=' + emailValue);
                            setTitleText(`Log in with Okta`);
                            setSubtitleText('');
                            setShowOktaEmailForm(false);
                            clearLastError();
                          }}
                        />
                      )}
                      {showMagicForm && (
                        <EmailButtonGroup
                          buttonText={`Next`}
                          formAutoComplete={`username`}
                          emailValue={emailValue}
                          setEmailValue={setEmailValue}
                          setFieldValue={setFieldValue}
                          buttonDisabled={!emailValue || !isEmpty(errors.email)}
                          buttonCallback={() => {
                            handleMagicLinkRequest(emailValue);
                            navigate('magic?email=' + emailValue);
                          }}
                        />
                      )}

                      {showPasswordForm && (
                        <Box>
                          <Box
                            sx={{
                              mt: 2,
                              mb: 2,
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Chip
                              icon={<MailOutlineIcon />}
                              label={emailValue}
                            />
                          </Box>
                          <PasswordButtonGroup
                            showPassword={showPassword}
                            setShowPassword={setShowPassword}
                            buttonDisabled={isSubmitting || !isValid}
                            buttonContent={submitButtonContent}
                            formAutoComplete={`current-password`}
                          />
                        </Box>
                      )}
                      {showResetInfo && (
                        <Box>
                          <Box
                            sx={{
                              mt: 2,
                              mb: 4,
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Chip
                              icon={<MailOutlineIcon />}
                              label={emailValue}
                            />
                          </Box>
                          {RESET_EMAIL_TEXT}
                        </Box>
                      )}
                      {!showEmailForm && !showOktaEmailForm && (
                        <Box
                          display={`flex`}
                          flexDirection={`column`}
                          alignItems={`center`}
                        >
                          <Talert
                            severity={showResetInfo ? 'warning' : 'info'}
                            sx={{ mt: 16, maxWidth: 400 }}
                            TabuttonProps={{
                              children: <Box>{actionText}</Box>,
                              size: 'small',
                              disabled: codeCount >= 3,
                              onClick: () => handleSignUp(),
                            }}
                          >
                            <Typography variant="body2">{helpText}</Typography>
                          </Talert>
                        </Box>
                      )}
                    </Form>
                  )}
                </Formik>
              </>
            }
          />
          {/*
          This is us launching the user over to Okta.  lookup via email.  after user logs in, we will receive the redirect back at /callback
          */}
          <Route
            path={'okta/*'}
            element={
              <OktaSignInWidget
                mode={OktaWidgetMode.Redirect}
                emailValue={emailValue}
                onOktaSignIn={handleOktaSignIn}
                onOktaConfigError={handleOktaConfigError}
              />
            }
          />
          {/*
          idp is called by identity providers initating login from their dashboard.
          we first lookup the authconfig via the iss param, then redirect to the idp provider based on the lookup.
          */}
          <Route
            path={'idp/*'}
            element={
              <OktaSignInWidget
                mode={OktaWidgetMode.Redirect}
                emailValue={emailValue}
                onOktaSignIn={handleOktaSignIn}
                onOktaConfigError={handleOktaConfigError}
              />
            }
          />

          {/*
          callback is used to receive the redirect from an idp after the user logs in.  We get the token here
          */}
          <Route
            path={'callback'}
            element={
              <OktaSignInWidget
                mode={OktaWidgetMode.Callback}
                emailValue={emailValue}
                onOktaSignIn={handleOktaSignIn}
                onOktaConfigError={handleOktaConfigError}
              />
            }
          />
          {/*
          This is the callback for magic links
          */}
          <Route
            path={'mlcb'}
            element={
              <>
                <MagicLogin />
              </>
            }
          />
          {/*
          This is the magic link request
          */}
          <Route
            path={'magic'}
            element={
              <>
                {!lastError && (
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography>
                      {`We just sent a link to ${emailValue}.  Click the link in the email to log in to your account.  The link expires in 15 minutes.`}
                    </Typography>
                    <Box
                      sx={{
                        alignItems: 'flex-end',
                        flexGrow: 1,
                        mt: 4,
                        mb: 4,
                        mr: 4,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <img
                        src="/assets/img/penguin-with-email.svg"
                        alt="Penguin /w Email"
                        height="104px"
                      />
                    </Box>
                  </Box>
                )}
              </>
            }
          />
        </Routes>
      </AuthFormContainer>
    </BaseLayout>
  );
}
