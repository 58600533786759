import * as React from 'react';

import { Typography, Box, Stack, Divider } from '@mui/material';

type Props = {
  invitedBy: string;
  invitedDate: string;
};
export const InvitePopover = React.forwardRef(
  ({ invitedBy, invitedDate, ...props }: Props, ref) => {
    return (
      <Box
        ref={ref}
        {...props}
        sx={(theme) => ({
          backgroundColor: 'popper.main',
          color: 'popper.contrastText',
          borderRadius: '4px',
          position: 'relative',
          boxShadow: theme.shadows[3],
          ml: '10px',
          m: 2,
        })}
      >
        <Stack direction={`column`}>
          <Box
            display={`flex`}
            alignItems={`center`}
            sx={{
              py: 1,
              px: 2,
              backgroundColor: 'popper.one',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
            }}
          >
            <Typography sx={{ mr: 1 }} variant={`caption`}>
              {'Invited By:'}
            </Typography>
            <Typography variant={`body2`}>
              {invitedBy ? invitedBy : '--'}
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: 'popper.one' }} />
          <Box display={`flex`} alignItems={`center`} sx={{ py: 1, px: 2 }}>
            <Typography variant={`body2`}>
              {invitedDate ? new Date(invitedDate).toUTCString() : '--'}
            </Typography>
          </Box>
        </Stack>
      </Box>
    );
  },
);
