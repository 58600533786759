import React from 'react';

import {
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { Table } from '../../../graphql/gen/graphql';
import { Event } from '../../../schema/events/event';
import { SucceededRewriteManifests } from '../../../schema/events/succeeded.rewrite-manifests';
import { TableEventTypes, Verbosity } from '../TableEventsView';

type Props = {
  event: Event;
  table: Table;
  verbosity?: Verbosity;
};
export default function RewriteManifestsView({
  event,
  table,
  verbosity = Verbosity.Summary,
}: Props) {
  let rewriteManifestsMessage = (function () {
    switch (event.type) {
      case TableEventTypes.RUN_REWRITE_MANIFESTS:
        return 'Scheduled';
      case TableEventTypes.STARTED_REWRITE_MANIFESTS:
        return 'Started';
      case TableEventTypes.SUCCEEDED_REWRITE_MANIFESTS:
        return 'Succeeded';
      default:
        return 'Failed';
    }
  })();

  switch (verbosity) {
    case Verbosity.Summary:
      return <div>Table Rewrite Manifests {rewriteManifestsMessage}</div>;
    case Verbosity.Detailed:
      const succeededRewriteManifests = event as SucceededRewriteManifests;

      return (
        <>
          <Grid container margin={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TableContainer component={Paper}>
                <MuiTable aria-label="Listing">
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">Rewritten Manifests</TableCell>
                      <TableCell>
                        {succeededRewriteManifests.rewritten_manifests_count}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Added Manifests</TableCell>
                      <TableCell>
                        {succeededRewriteManifests.added_manifests_count}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
}
