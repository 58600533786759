import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';

export const NavTab = styled(Tab)(({ theme }) => ({
  fontFamily: 'Source Sans Pro',
  color: theme.palette.midnight.six,
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '18px',
  letterSpacing: '0.75px',
  height: '26px',
  minHeight: '26px',
  minWidth: 0,
  padding: '8px 0 12px 0',
  marginLeft: 16,
  marginRight: 16,
  textTransform: 'none',
  '&.Mui-selected': {
    color: theme.palette.midnight.nine,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '11px',
  },
  [theme.breakpoints.down('sm')]: {
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
}));
export const TabWrapper = styled(Tabs)(({ theme }) => ({
  minHeight: '26px',
  height: '26px',
  '& .MuiTabs-indicator': {
    height: '4px',
  },
}));
