import React from 'react';

import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import SecurityIcon from '@mui/icons-material/Security';
import ShieldIcon from '@mui/icons-material/Shield';
import { Avatar, AvatarProps, Badge, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SystemProps } from '@mui/system';

const SmallAvatar = styled(Box)(({ theme }) => ({
  width: 14,
  height: 14,
  backgroundColor: theme.palette.background.paper,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export interface TavatarProps {
  profilePic?: string;
  tabularService?: boolean;
  displayName?: string;
  currentUser?: boolean;
  securityAdmin?: boolean;
  orgAdmin?: boolean;
  roleAdmin?: boolean;
  avatarProps?: AvatarProps;
}
export const Tavatar = React.forwardRef(
  (
    {
      profilePic,
      tabularService,
      displayName,
      currentUser,
      securityAdmin,
      orgAdmin,
      roleAdmin,
      avatarProps,
      ...props
    }: TavatarProps,
    ref: any,
  ): JSX.Element => {
    const initials = displayName
      ?.split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), '');
    const getIconName = () => {
      switch (true) {
        case orgAdmin:
          return 'SecurityIcon';
        case securityAdmin:
          return 'LocalPoliceIcon';
        case roleAdmin:
          return 'ShieldIcon';
        default:
          return '';
      }
    };
    const IconMapping = { ShieldIcon, LocalPoliceIcon, SecurityIcon };
    // @ts-ignore
    const Component = IconMapping[getIconName()];

    return (
      <>
        {securityAdmin || orgAdmin || roleAdmin ? (
          <Badge
            ref={ref}
            {...props}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            badgeContent={
              Component && (
                <SmallAvatar>
                  <Component
                    // @ts-ignore
                    sx={(theme) => ({
                      color: currentUser
                        ? theme.palette.brandOrange.main
                        : theme.palette.midnight.six,
                      fontSize: '10px',
                    })}
                  />
                </SmallAvatar>
              )
            }
          >
            <Avatar
              src={profilePic || ''}
              {...props}
              {...avatarProps}
              sx={[
                (theme) => ({
                  border: `${
                    currentUser
                      ? `2px solid ${theme.palette.brandOrange.main} !important`
                      : `2px solid ${theme.palette.midnight.two} !important`
                  }`,
                  backgroundColor: currentUser
                    ? theme.palette.sky.one
                    : theme.palette.midnight.one,
                  color: currentUser
                    ? theme.palette.sky.main
                    : theme.palette.midnight.six,
                }),
                ...(avatarProps?.sx ? [avatarProps?.sx as SystemProps] : []),
              ]}
            >
              {tabularService ? (
                <MiscellaneousServicesIcon />
              ) : (
                <Typography variant="avatarLetter">
                  {initials?.slice(0, 2).toUpperCase()}
                </Typography>
              )}
            </Avatar>
          </Badge>
        ) : (
          <Avatar
            src={profilePic || ''}
            ref={ref}
            {...props}
            {...avatarProps}
            sx={[
              (theme) => ({
                border: `${
                  currentUser
                    ? `2px solid ${theme.palette.brandOrange.main} !important`
                    : `2px solid ${theme.palette.midnight.two} !important`
                }`,
                backgroundColor: currentUser
                  ? theme.palette.sky.one
                  : theme.palette.midnight.one,
                color: currentUser
                  ? theme.palette.sky.main
                  : theme.palette.midnight.six,
              }),
              ...(avatarProps?.sx ? [avatarProps?.sx as SystemProps] : []),
            ]}
          >
            {tabularService ? (
              <MiscellaneousServicesIcon />
            ) : (
              <Typography variant="avatarLetter">
                {initials?.slice(0, 2).toUpperCase()}
              </Typography>
            )}
          </Avatar>
        )}
      </>
    );
  },
);
