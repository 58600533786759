import React, { useEffect, useMemo, useState } from 'react';
// @ts-ignore
import { useUserAgent } from 'react-ua';

import { useMutation, useLazyQuery } from '@apollo/client';
import { useModal, create, show } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { isEmpty } from 'lodash';

import { MirrorTarget, Warehouse } from '../../../graphql/gen/graphql';
import {
  getMirrorStatus,
  mirrorWarehouseMutation,
} from '../../../graphql/warehouse';
import { Talert } from '../../Alert/Talert';
import { CustomIcon } from '../../Icons/CustomIcon';
import { Tabulink } from '../../Link/Tabulink';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../WizardContainer';
import { BigQuerySetup } from './BigQuerySetup';

const BigQueryWizardDialog = create<{
  organizationName: string;
  warehouse: Warehouse;
  refetch: any;
}>(({ warehouse, organizationName, refetch }) => {
  const modal = useModal();
  const [errorMessage, setErrorMessage] = useState('');
  const [taskId, setTaskId] = useState('');
  const [status, setStatus] = useState();

  const [mirrorWarehouse] = useMutation(mirrorWarehouseMutation);
  const [getStatus, { data, error, startPolling, stopPolling }] =
    useLazyQuery(getMirrorStatus);

  const bigQueryMirroringEnabled = useMemo(
    () =>
      warehouse.properties?.find(
        (item) => item?.key === 'mirror.bigquery.enabled',
      )?.value == 'true' || false,
    [warehouse],
  );

  const closeAndRefetch = async () => {
    await refetch();
  };

  const handleBigQueryMirrorUpdate = () => {
    return mirrorWarehouse({
      variables: {
        warehouseId: warehouse.id,
        target: MirrorTarget.Bigquery,
      },
      onCompleted: async (data) => {
        setTaskId(data.mirrorWarehouse.taskId);
      },

      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(taskId)) {
      getStatus({
        variables: {
          warehouseId: warehouse.id,
          taskId: taskId,
          target: MirrorTarget.Bigquery.toLowerCase(),
        },
      });
      startPolling(1000);
    }

    if (data?.getMirrorStatus?.status === 'COMPLETE') {
      setStatus(data?.getMirrorStatus?.status);
      stopPolling();
      closeAndRefetch();
    }

    if (data?.getMirrorStatus?.status === 'ERROR') {
      setErrorMessage('An error has occurred please try again.');
      stopPolling();
    }
    if (error) {
      setErrorMessage(
        'Error syncing data to Big Query, there may be an existing data set.',
      );
      stopPolling();
    }
  }, [taskId, data, error]);

  const serviceAccount =
    warehouse?.storageProfile?.properties['gcp.service-account.email'];

  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: 'Big Query setup',
        body: <BigQuerySetup serviceAccount={serviceAccount} />,
        nextButtonEffect: () => handleBigQueryMirrorUpdate(),
        buttonText: 'Sync Big Query',
        disallowContinue: bigQueryMirroringEnabled,
      },
      {
        title: 'Test connection',
        body: (
          <>
            <Box sx={{ width: 0.8 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Typography variant={`subtitle1`} sx={{ mb: 1 }}>
                  Syncing {warehouse.name} to Big Query
                </Typography>
                <Tabulink
                  external
                  href={`https://console.cloud.google.com/bigquery?hl=en&`}
                  variant="body1"
                  rel="noopener"
                  aria-label="Link to Big Query"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Check connection in Big Query
                  <OpenInNewIcon
                    fontSize="small"
                    sx={{ ml: '2px', mr: '3px' }}
                  />
                </Tabulink>
                <Box sx={{ width: '100%' }}>
                  {status !== 'COMPLETE' && !error && (
                    <Talert
                      color={'neutral'}
                      sx={{ my: 2, width: 'fit-content' }}
                    >
                      Watching for Biq Query sync status
                    </Talert>
                  )}
                  {status === 'COMPLETE' && (
                    <Talert
                      severity="success"
                      sx={{ my: 2, width: 'fit-content' }}
                    >
                      Successful connection detected!
                    </Talert>
                  )}
                  {error && (
                    <Talert
                      severity={'error'}
                      sx={{ my: 2, width: 'fit-content' }}
                    >
                      {errorMessage}
                    </Talert>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        ),
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <WizardContainer
        title={'Big Query'}
        stepsTitle={warehouse.name}
        stepsSubtitle={`Connect Big Query`}
        stepsIcon={
          <CustomIcon
            src={'/assets/img/logos/big-query.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        backButtonClick={() => {}}
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        sx={{ minHeight: '550px', width: 1, height: '98vh' }}
      />
    </Dialog>
  );
});

export function showBigQueryWizardDialog({
  warehouse,
  organizationName,
  refetch,
}: {
  warehouse: Warehouse;
  organizationName: string;
  refetch: any;
}) {
  return show(BigQueryWizardDialog, {
    warehouse,
    organizationName,
    refetch,
  });
}
