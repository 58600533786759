import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  BoxProps,
  FormControlLabel,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Field, Form, Formik, FormikValues, useFormikContext } from 'formik';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { FileLoadMode, TableLoadMode } from '../../graphql/gen/graphql';
import { getByteSize } from '../../pages/helpers';
import { getApolloErrorMessage } from '../../utils/ApolloUtils';
import { getLogger } from '../../utils/logging';
import { fileFormatOptions } from '../../utils/properties';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import { getSchemeForStorageType } from '../CreateStorageBucket/ValidationStatusHelpers';
import { FileUpload, FileUploadMode } from '../FileUpload/FileUpload';
import { StyledSwitch, Switch } from '../Forms/Switch';
import TextField from '../Forms/TextField';
import StorageBrowser, {
  StorageBrowserSelectionMode,
} from '../StorageBrowser/StorageBrowser';

const logger = getLogger(
  'components.TableEdit.ChooseCreateTableMetadata' /*FUTURE import.meta.url ?*/,
);

export enum UserTableIntent {
  CREATE,
  LOAD,
  CREATE_CDC,
  REGISTER,
}

interface FormValues {
  fileFormat: string;
  tableLoadMode: TableLoadMode;
  delimiter: string;
  escape: string;
  dateFormat: string;
  timestampFormat: string;
  timestampNTZFormat: string;
  fileExcludeGlobFilter: string | null;
  hasHeader: boolean;
  useMaps: boolean;
  inferPartitionsFromPath: boolean;
}

interface DataSourceSelection {
  scheme: string;
  storageProfileId: string;
  region: string;
  bucket: string;
  keyPaths: string[];
  stagedTableId?: string;
}

export interface SourceSelection extends DataSourceSelection {
  fileFormat: string;
  tableLoadMode: TableLoadMode;
  delimiter: string;
  escape: string | null;
  dateFormat: string | null;
  timestampFormat: string | null;
  timestampNTZFormat: string | null;
  hasHeader: boolean;
  useMaps: boolean;
  fileExcludeGlobFilter?: string | null;
  inferPartitionsFromPath?: boolean | null;
}
interface SourceProps extends BoxProps {
  organizationId: string;
  warehouseId: string;
  databaseName: string;
  tableName: string;
  selectionMode: StorageBrowserSelectionMode;
  regionFilter: string[];
  storageTypeFilter: string[];
  onCancel: () => void;
  onSelection: (event: DataSourceSelection) => void;
  sourceProps?: Record<string, any>;
}
function StorageProfileSource({
  organizationId,
  selectionMode,
  regionFilter,
  storageTypeFilter,
  onCancel,
  onSelection,
  tableName,
  sourceProps,
  ...props
}: SourceProps) {
  return (
    <Box {...props} sx={[props.sx as SystemStyleObject, { display: 'flex' }]}>
      <StorageBrowser
        sx={{ flexGrow: 1, ml: 2, mr: 2 }}
        organizationId={organizationId}
        selectionMode={selectionMode}
        storageTypeFilter={storageTypeFilter}
        regionFilter={regionFilter}
        onFinalSelection={(selectionModel) => {
          onSelection({
            scheme: getSchemeForStorageType(
              selectionModel.profile.storageType!,
            ),
            bucket: selectionModel.profile.bucket,
            region: selectionModel.profile.region,
            storageProfileId: selectionModel.profile.id,
            keyPaths: selectionModel.selectionModel as string[],
            stagedTableId: selectionModel.stagedTableId,
          });
        }}
        onCancel={onCancel}
        showBack
      />
    </Box>
  );
}
function FileUploadSource({
  organizationId,
  warehouseId,
  databaseName,
  selectionMode,
  regionFilter,
  onCancel,
  onSelection,
  tableName,
  sourceProps,
  ...props
}: SourceProps) {
  return (
    <Box {...props} sx={[props.sx as SystemStyleObject, { display: 'flex' }]}>
      <FileUpload
        mode={
          selectionMode == StorageBrowserSelectionMode.FILES_FOLDERS
            ? FileUploadMode.MULTIPLE
            : FileUploadMode.SINGLE
        }
        sx={{ flexGrow: 1, ml: 2, mr: 2 }}
        url={sourceProps?.uploadUrl}
        onFinalSelection={(result) => {
          onSelection({
            scheme: getSchemeForStorageType(result.profile.storageType!),
            bucket: result.profile.bucket,
            region: result.profile.region,
            storageProfileId: result.profile.id,
            keyPaths: result.selectionModel as string[],
            stagedTableId: result.stagedTableId,
          });
        }}
      />
    </Box>
  );
}

interface TableLoadBodyProps extends BoxProps {
  selectionRows: { path: string; type: string }[];
  tableName: string;

  onClearSelection: () => void;
  onSubmit: () => void;
  hasFolderInSelection: boolean;
  setInitialValues: React.Dispatch<React.SetStateAction<FormValues>>;
  disableSubForm: boolean;
  isComplete: boolean;
  isFoShizSubmitting: boolean;
  intent: UserTableIntent;
}
function TableLoadBody({
  selectionRows,
  tableName,
  onClearSelection,
  onSubmit,
  hasFolderInSelection,
  setInitialValues,
  disableSubForm,
  isComplete,
  isFoShizSubmitting,
  intent,
}: TableLoadBodyProps) {
  const { values, isSubmitting } = useFormikContext<FormValues>();
  return (
    <>
      <Box
        sx={{
          width: 1,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {intent !== UserTableIntent.REGISTER && (
          <TableLoadSubForm
            setInitialValues={setInitialValues}
            disabled={disableSubForm}
            hasFolderInSelection={hasFolderInSelection}
            intent={intent}
          />
        )}
        <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
          <MUITable
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>Selection</TableCell>
                <TableCell align="right">Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectionRows.map((row) => (
                <TableRow
                  key={row.path}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.path}
                  </TableCell>
                  <TableCell align="right">{row.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          pr: 2,
          pb: 2,
          backgroundColor: 'controlBackground.main',
        }}
      >
        {!isComplete && (
          <DialogActions>
            <Tabutton
              onClick={() => {
                onClearSelection();
              }}
            >
              Clear selection
            </Tabutton>
            <Tabutton
              disabled={
                (intent !== UserTableIntent.REGISTER &&
                  values?.fileFormat === undefined) ||
                values?.fileFormat === '' ||
                isFoShizSubmitting
              }
              onClick={() => {
                onSubmit();
              }}
              type={'submit'}
              variant="contained"
            >
              {intent === UserTableIntent.LOAD
                ? `Load files`
                : intent === UserTableIntent.CREATE_CDC
                ? `Configure Pipeline Settings`
                : `Review Schema`}
            </Tabutton>
          </DialogActions>
        )}
      </Box>
    </>
  );
}

interface TableLoadSubFormProps extends BoxProps {
  disabled: boolean;
  hasFolderInSelection: boolean;
  setInitialValues: React.Dispatch<React.SetStateAction<FormValues>>;
  intent: UserTableIntent;
}

function TableLoadSubForm({
  disabled,
  hasFolderInSelection,
  setInitialValues,
  intent,
}: TableLoadSubFormProps) {
  const { values } = useFormikContext<FormikValues>();

  const settingsSummary = (
    <TableContainer
      component={Box}
      sx={(theme) => ({
        flexGrow: 1,
        maxHeight: '80%',
        width: 1,
        borderWidth: 1,
        borderColor: 'midnight.two',
        borderStyle: 'solid',
        backgroundColor: 'midnight.half',
        borderRadius: '8px',
        boxShadow: theme.shadows[3],
      })}
    >
      <MUITable aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Setting</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Header
            </TableCell>
            <TableCell align="right">
              {values.hasHeader ? 'File has header' : 'File has no header'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Delimiter
            </TableCell>
            <TableCell align="right">{values.delimiter}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Quote Escape Character
            </TableCell>
            <TableCell align="right">{values.escape}</TableCell>
          </TableRow>
        </TableBody>
      </MUITable>
    </TableContainer>
  );
  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', width: 1 }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {!(
          intent === UserTableIntent.CREATE ||
          intent === UserTableIntent.CREATE_CDC ||
          intent === UserTableIntent.REGISTER
        ) && (
          <TextField
            disabled={disabled}
            select
            name={'tableLoadMode'}
            label={'Table load mode'}
            helperText={'Add to your table or replace your table.'}
          >
            <MenuItem value={TableLoadMode.Append}>Append</MenuItem>
            <MenuItem value={TableLoadMode.Replace}>Replace</MenuItem>
          </TextField>
        )}

        <TextField
          disabled={disabled}
          select
          name={'fileFormat'}
          label={'File format'}
          helperText={'Expected format for all files being loaded'}
          onChange={(e) => {
            setInitialValues((previous) => {
              return {
                ...previous,
                fileExcludeGlobFilter: values.fileExcludeGlobFilter,
                tableLoadMode: values.tableLoadMode,
                fileFormat: e.target.value,
                delimiter:
                  e.target.value == 'csv'
                    ? ','
                    : e.target.value == 'tsv'
                    ? '\\t'
                    : '',
                escape: values.escape,
                dateFormat: values.dateFormat,
                timestampFormat: values.timestampFormat,
                timestampNTZFormat: values.timestampNTZFormat,
                inferPartitionsFromPath: values.inferPartitionsFromPath,
              };
            });
          }}
        >
          {fileFormatOptions.map((eachFormat) => (
            <MenuItem key={eachFormat.value} value={eachFormat.value}>
              {eachFormat.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {(values?.fileFormat === 'csv' ||
        values?.fileFormat === 'tsv' ||
        values?.fileFormat === 'json') && (
        <TableContainer
          component={Box}
          sx={(theme) => ({
            flexGrow: 1,
            maxHeight: '80%',
            width: 1,
            borderWidth: 1,
            borderColor: 'midnight.two',
            borderStyle: 'solid',
            backgroundColor: 'midnight.half',
            borderRadius: '8px',
            boxShadow: theme.shadows[3],
          })}
        >
          <MUITable aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Additional settings</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {(values?.fileFormat === 'csv' ||
                    values?.fileFormat === 'tsv') && (
                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                      <FormControlLabel
                        disabled={disabled}
                        sx={{ ml: 1, minWidth: 100 }}
                        control={
                          <Field
                            disabled={disabled}
                            type="checkbox"
                            name="hasHeader"
                          />
                        }
                        label="Has header"
                      />
                      <TextField
                        disabled={disabled}
                        name={'delimiter'}
                        label={'Delimiter'}
                      />
                      <TextField
                        disabled={disabled}
                        name={'escape'}
                        label={'Escape'}
                        helperText={'Used for escaping quotes'}
                      />
                      <TextField
                        disabled={disabled}
                        name={'dateFormat'}
                        label={'DateFormat'}
                      />
                      <TextField
                        disabled={disabled}
                        name={'timestampFormat'}
                        label={'TimestampFormat'}
                      />
                      <TextField
                        disabled={disabled}
                        name={'timestampNTZFormat'}
                        label={'TimestampNTZFormat'}
                      />
                    </Box>
                  )}
                  {values?.fileFormat === 'json' && (
                    <>
                      <FormControlLabel
                        disabled={disabled}
                        sx={{ ml: 1 }}
                        control={
                          <Field
                            disabled={disabled}
                            type="checkbox"
                            name="useMaps"
                          />
                        }
                        label="Use maps instead of structs"
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </MUITable>
        </TableContainer>
      )}

      {hasFolderInSelection && (
        <TextField
          disabled={disabled}
          name={'fileExcludeGlobFilter'}
          label={'Optional glob file exclusion filter'}
          helperText={
            'eg. **/*.excludeme (multiple filters can be pipe delimited)'
          }
        />
      )}
      <Switch
        name={'inferPartitionsFromPath'}
        switchlabel={'Infer Partitions from Path'}
      />
    </Box>
  );
}

const componentsMap: {
  [P in string]: React.JSXElementConstructor<SourceProps>;
} = {
  StorageProfileSource,
  FileUploadSource,
};

export interface ChooseTableSourceProps extends BoxProps {
  organizationId: string;
  warehouseId: string;
  database: string;
  tableName: string;
  source: string;
  sourceProps?: Record<string, any>;
  intent: UserTableIntent;
  regionFilter: string[];

  storageTypeFilter: string[];
  onSelection: (event: SourceSelection) => Promise<boolean>;
  subTitles: string[];
  parentIsLoading: boolean;
  parentIsError: boolean;
  onCancel?: () => void;
}
export default function ChooseTableSource({
  organizationId,
  warehouseId,
  database,
  tableName,
  regionFilter,
  storageTypeFilter,
  source,
  sourceProps,
  intent,
  onSelection,
  subTitles,
  parentIsLoading,
  parentIsError,
  onCancel,
  ...props
}: ChooseTableSourceProps) {
  const [selection, setSelection] = useState<DataSourceSelection>();
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isFoShizSubmitting, setIsFoShizSubmitting] = useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [showErrorDetails, setShowErrorDetails] =
    React.useState<boolean>(false);
  const Source = componentsMap[source];
  const defaults = {
    fileFormat: '',
    tableLoadMode:
      intent === UserTableIntent.CREATE_CDC
        ? TableLoadMode.AppendAutoLoad
        : TableLoadMode.Append,
    delimiter: ',',
    escape: '\\',
    dateFormat: 'yyyy-MM-dd',
    timestampFormat: "yyyy-MM-dd'T'HH:mm:ss[.SSS][XXX]",
    timestampNTZFormat: "yyyy-MM-dd'T'HH:mm:ss[.SSS]",
    fileExcludeGlobFilter: null,
    hasHeader: true,
    useMaps: false,
    inferPartitionsFromPath: true,
  };
  const [initialValues, setInitialValues] = useState<FormValues>(defaults);

  const currentStep = selection === undefined ? 0 : 1;

  useEffect(() => {
    if (parentIsError && isComplete) {
      setIsComplete(false);
    }
  }, [parentIsError, isComplete]);

  const hasFolderInSelection = useMemo(() => {
    if (selection != undefined) {
      return selection.keyPaths.some((eachKeyPath) =>
        (eachKeyPath as string).endsWith('/'),
      );
    } else {
      return false;
    }
  }, [selection]);

  const selectionRows = useMemo(() => {
    if (selection != undefined) {
      return selection.keyPaths.map((eachKeyPath) => {
        return {
          path: `${selection.scheme}://${selection.bucket}/${eachKeyPath}`,
          type: (eachKeyPath as string).endsWith('/') ? 'Folder' : 'File',
        };
      });
    }
    return [];
  }, [selection]);

  const handleLoadTableData = useCallback(
    async (values: FormValues, { setSubmitting }: FormikValues) => {
      if (!selection) return;
      setIsFoShizSubmitting(true);
      const completeSourceSelection: SourceSelection = {
        scheme: selection.scheme,
        bucket: selection.bucket,
        stagedTableId: selection?.stagedTableId,
        storageProfileId: selection?.storageProfileId,
        keyPaths: selection?.keyPaths,
        region: selection?.region,
        fileFormat: values.fileFormat,
        tableLoadMode: values.tableLoadMode,
        delimiter: values.delimiter === '\\t' ? '\t' : values.delimiter,
        escape: values.escape === defaults.escape ? null : values.escape,
        dateFormat:
          values.dateFormat === defaults.dateFormat ? null : values.dateFormat,
        timestampFormat:
          values.timestampFormat === defaults.timestampFormat
            ? null
            : values.timestampFormat,
        timestampNTZFormat:
          values.timestampNTZFormat === defaults.timestampNTZFormat
            ? null
            : values.timestampNTZFormat,
        hasHeader: values.hasHeader,
        useMaps: values.useMaps,
        fileExcludeGlobFilter: values.fileExcludeGlobFilter,
        inferPartitionsFromPath: values.inferPartitionsFromPath,
      };
      onSelection(completeSourceSelection)
        .then((success) => {
          setIsComplete(true);
          setIsFoShizSubmitting(false);
        })
        .catch((error) => {
          logger.error('error whilst selecting files.', error);
          setError(getApolloErrorMessage(error));
          setIsFoShizSubmitting(false);
        });
    },
    [selection, isComplete],
  );

  const steps = useMemo(() => {
    return [
      {
        body: (
          <>
            {Source && (
              <Source
                sx={{ flexGrow: 1 }}
                organizationId={organizationId}
                warehouseId={warehouseId}
                databaseName={database}
                tableName={tableName}
                selectionMode={
                  intent === UserTableIntent.CREATE_CDC
                    ? StorageBrowserSelectionMode.PATH
                    : intent === UserTableIntent.REGISTER
                    ? StorageBrowserSelectionMode.SINGLE_FILE
                    : StorageBrowserSelectionMode.FILES_FOLDERS
                }
                regionFilter={regionFilter}
                storageTypeFilter={storageTypeFilter}
                onSelection={(event) => {
                  setSelection(event);
                  //check for known file types
                  if (event?.keyPaths?.length > 0) {
                    const keyPathDotParts = event.keyPaths[0].split('.');
                    const matchedFileFormat = fileFormatOptions.find(
                      (eachVal) =>
                        keyPathDotParts[keyPathDotParts.length - 1].indexOf(
                          eachVal.value,
                        ) > -1,
                    );
                    if (matchedFileFormat) {
                      setInitialValues((prevState) => {
                        return {
                          ...prevState,
                          fileFormat: matchedFileFormat?.value,
                        };
                      });
                    }
                  }
                }}
                onCancel={() => {
                  if (onCancel) {
                    onCancel();
                  }
                }}
                sourceProps={sourceProps}
              />
            )}
          </>
        ),
      },
      {
        body: (
          <TableLoadBody
            intent={intent}
            setInitialValues={setInitialValues}
            onClearSelection={() => {
              setSelection(undefined);
              setError(undefined);
              setInitialValues((prevState) => {
                return {
                  ...prevState,
                  fileFormat: '',
                };
              });
            }}
            onSubmit={() => {
              setError(undefined);
            }}
            tableName={tableName}
            disableSubForm={false}
            selectionRows={selectionRows}
            hasFolderInSelection={hasFolderInSelection}
            isComplete={isComplete && !parentIsError}
            isFoShizSubmitting={isFoShizSubmitting}
          />
        ),
      },
    ];
  }, [
    selection,
    selectionRows,
    source,
    isComplete,
    isFoShizSubmitting,
    parentIsError,
  ]);

  return (
    <Box
      {...props}
      sx={[
        props.sx as SystemStyleObject,
        { display: 'flex', flexDirection: 'column' },
      ]}
    >
      {!Source && <Talert severity={'error'} title={'No source selected'} />}
      {error ? (
        <>
          <Talert severity="error" sx={{ mb: 1, width: 'fit-content' }}>
            {intent === UserTableIntent.REGISTER
              ? `There was an error reading the metadata. `
              : `There was an error inferring the schema. `}
            <Link
              variant="body2"
              onClick={() => {
                setShowErrorDetails((prev) => !prev);
              }}
            >
              {showErrorDetails ? `Hide error details` : `Show error details`}
            </Link>
          </Talert>
          {showErrorDetails && (
            <SyntaxHighlighter
              language="bash"
              style={{ ...dracula, margin: 1 as React.CSSProperties }}
              showLineNumbers={false}
            >
              {error}
            </SyntaxHighlighter>
          )}
        </>
      ) : null}
      <Typography variant={'subtitle1'} sx={{ mb: 2 }}>
        {!parentIsLoading && `${subTitles[currentStep]}`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
        }}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleLoadTableData}
        >
          {({ isSubmitting }) => (
            <Form
              style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
            >
              {steps[currentStep].body}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
