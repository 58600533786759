import React from 'react';

import {
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { Table } from '../../../graphql/gen/graphql';
import { Compaction } from '../../../schema/events/compaction';
import { Event } from '../../../schema/events/event';
import { Failed } from '../../../schema/events/failed';
import { SucceededCompaction } from '../../../schema/events/succeeded.compaction';
import { TableEventTypes, Verbosity } from '../TableEventsView';
import FailedServiceDetails from './FailedServiceDetails';
import PartitionDetailList from './PartitionDetailList';

type Props = {
  event: Event;
  table: Table;
  verbosity?: Verbosity;
};
export default function CompactionView({
  event,
  table,
  verbosity = Verbosity.Summary,
}: Props) {
  const compaction = event.context.find(
    (ctx) => ctx.type === 'Compaction',
  ) as Compaction;

  let compactionMessage = (function () {
    switch (event.type) {
      case TableEventTypes.CANCELLED_COMPACTION:
        return 'Cancelled';
      case TableEventTypes.RUN_COMPACTION:
        return 'Scheduled';
      case TableEventTypes.STARTED_COMPACTION:
        return 'Started';
      case TableEventTypes.SUCCEEDED_COMPACTION:
        return 'Succeeded';
      default:
        return 'Failed';
    }
  })();

  switch (verbosity) {
    case Verbosity.Summary:
      if (compaction.partitions) {
        const numPartitions = compaction.partitions.length;
        return (
          <div>
            Table Compaction {compactionMessage} for {numPartitions} partition
            {numPartitions > 1 && 's'}
          </div>
        );
      } else {
        return <div>Table Compaction {compactionMessage}</div>;
      }
    case Verbosity.Detailed:
      if (event.type == TableEventTypes.SUCCEEDED_COMPACTION) {
        const succeededCompaction = event as SucceededCompaction;

        return (
          <>
            <Grid container margin={2}>
              <Grid item xs={12} md={6} lg={4}>
                {event.type === TableEventTypes.SUCCEEDED_COMPACTION && (
                  <TableContainer component={Paper}>
                    <MuiTable aria-label="Listing">
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head">
                            Rewritten data files
                          </TableCell>
                          <TableCell>
                            {succeededCompaction.rewritten_data_files_count}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Added data files</TableCell>
                          <TableCell>
                            {succeededCompaction.added_data_files_count}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </MuiTable>
                  </TableContainer>
                )}
                <PartitionDetailList partitions={compaction.partitions} />
              </Grid>
            </Grid>
          </>
        );
      } else if (event.type == TableEventTypes.FAILED_COMPACTION) {
        return <FailedServiceDetails event={event as Failed} table={table} />;
      } else {
        return (
          <>
            <Grid container margin={2}>
              <Grid item xs={12} md={6} lg={4}>
                <PartitionDetailList partitions={compaction.partitions} />
              </Grid>
            </Grid>
          </>
        );
      }

    default:
      return (
        <>
          <Grid container margin={2}>
            <Grid item xs={12} md={6} lg={4}>
              <PartitionDetailList partitions={compaction.partitions} />
            </Grid>
          </Grid>
        </>
      );
  }
}
