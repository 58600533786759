import * as React from 'react';

import { Typography, Box, BoxProps, TypographyTypeMap } from '@mui/material';

interface TaBadgeProps extends BoxProps {
  text: string;
  bgColor?: string;
  showBorder?: boolean;
  textVariant?: TypographyTypeMap['props']['variant'];
  fullWidth?: boolean;
}
export const TaBadge = React.forwardRef<HTMLElement, TaBadgeProps>(
  ({ text, bgColor, showBorder, textVariant, fullWidth, ...props }, ref) => (
    <Box
      ref={ref}
      {...props}
      sx={(theme) => ({
        width: fullWidth ? '100%' : 'fit-content',
        px: 2,
        py: '4px',
        backgroundColor: bgColor ?? 'sky.one',
        color: 'midnight.nine',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(showBorder
          ? {
              border: `1px solid ${theme.palette.midnight.two}`,
            }
          : {}),
      })}
    >
      <Typography
        variant={textVariant ? textVariant : 'semanticH4styledOverline'}
        whiteSpace={`nowrap`}
      >
        {text}
      </Typography>
    </Box>
  ),
);
