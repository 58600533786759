import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { AddOutlined } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Skeleton } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { MainTabGroup } from '../../context/GlobalPropsContext';

import { toStorage, toStorageProfile, toDocsRoot } from '../../RouteTable';
import { getUserFriendlyErrorMessage } from '../../components/AccessControl/common';
import { DropdownMenuContent } from '../../components/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenuItem';
import NotFound from '../../components/Errors/NotFound';
import TabLayout from '../../components/Layouts/TabLayout';
import { showCreateWarehouseDialog } from '../../components/Modals/CreateWarehouseDialog';
import { showTypeDeleteDialog } from '../../components/Modals/TypeDeleteDialog';
import ConnectedWarehouses from '../../components/StorageProfileSingle/ConnectedWarehouses';
import ProfileOverview from '../../components/StorageProfileSingle/ProfileOverview';
import StorageProfileAccessControls from '../../components/StorageProfileSingle/StorageProfileAccessControls';
import { useAuth } from '../../context/auth-context';
import { AuthDecisionResourceType } from '../../graphql/gen/graphql';
import {
  deleteStorageProfile,
  getStorageProfileQuery,
  getStorageProfileValidation,
} from '../../graphql/organization';

export default function StorageProfile() {
  const { bucketName } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const orgName = useMemo(
    () => user?.loginSession?.loggedInOrg?.name || '',
    [user],
  );
  const orgDisplayName = useMemo(
    () => user?.loginSession?.loggedInOrg?.displayName,
    [user],
  );
  const orgId = useMemo(() => user?.loginSession?.loggedInOrg?.id, [user]);
  const isSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(orgName),
    [user, orgName],
  );
  const { data, loading, refetch } = useQuery(getStorageProfileQuery, {
    variables: {
      bucketName,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const [validateStorage] = useLazyQuery(getStorageProfileValidation, {
    variables: {
      storageProfileId: data?.getStorageProfile?.id,
    },
    //network-only to force a re-validation
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      enqueueSnackbar(
        'Revalidated. Changes made in your AWS account may take a few minutes to be available here.',
        {
          variant: 'success',
        },
      );
    },
  });

  const [deleteStorage] = useMutation(deleteStorageProfile, {
    variables: {
      id: data?.getStorageProfile?.id,
    },
    onCompleted: async () => {
      navigate(toStorage(orgName));
      enqueueSnackbar('Storage profile successfully deleted', {
        variant: 'success',
      });
    },
    onError: (e) => {
      enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
        variant: 'error',
      });
    },
  });

  const handleCreateWarehouse = useCallback(
    (orgName: string) => {
      return showCreateWarehouseDialog(
        orgName,
        (warehouse) => warehouse?.name && refetch(),
        data?.getStorageProfile,
      );
    },
    [data],
  );

  const storageProfileType = useMemo(
    () => data?.getStorageProfile?.storageType,
    [data],
  );

  const breadcrumbs = [
    { href: toStorage(orgName), text: 'Storage' },
    {
      text: bucketName,
      href: toStorageProfile(orgName, bucketName || ''),
      dropdown: {
        type: AuthDecisionResourceType.StorageProfile,
        variables: {
          organizationId: orgId,
        },
        current: bucketName,
      },
    },
  ];

  const dashboardDropdownContent = useMemo(
    () => (
      <DropdownMenuContent
        menuItems={[
          <DropdownMenuItem
            key={`refreshProfile`}
            showAsMenuButton
            titleText={`Revalidate`}
            Icon={RefreshIcon}
            onClick={() => validateStorage()}
          />,
          <DropdownMenuItem
            key={`linkToDocs`}
            showAsMenuButton
            titleText={`More about Storage Profiles`}
            Icon={OpenInNewIcon}
            onClick={() =>
              window.open(
                toDocsRoot() + '/understanding-storage-profiles.html',
                '_blank',
              )
            }
          />,
          <DropdownMenuItem
            key={'dropStorageProfile'}
            showAsMenuButton
            titleText={'Drop storage profile'}
            disabled={
              data?.getStorageProfile?.warehouses?.length > 0 ||
              !isSecurityAdmin
            }
            onClick={() => {
              showTypeDeleteDialog({
                title: `Drop ${data?.getStorageProfile?.bucket} storage profile`,
                confirmText: `Drop storage profile`,
                recoveryWarningText: `This will drop the storage profile and metadata from Tabular, but will not alter data or metadata files in your object store.`,
              }).then(() => deleteStorage());
            }}
            dangerZone
          />,
        ]}
      />
    ),
    [data],
  );

  const warehousesDropdownContent = useMemo(
    () => (
      <DropdownMenuContent
        menuItems={[
          <DropdownMenuItem
            key={`refreshWarehouses`}
            showAsMenuButton
            titleText={`Refresh warehouse list`}
            Icon={RefreshIcon}
            onClick={() => refetch()}
          />,
          <DropdownMenuItem
            key={'createWarehouse'}
            showAsMenuButton
            titleText={'Create warehouse'}
            onClick={() => {
              handleCreateWarehouse(orgName);
            }}
            Icon={AddOutlined}
            disabled={
              data?.getStorageProfile?.properties?.validationStatus !== 'PASS'
            }
          />,
        ]}
      />
    ),
    [data, orgName],
  );

  const refreshResourceContent = {
    title: 'Revalidate storage profile',
    action: () => validateStorage(),
  };
  const refreshWarehousesContent = {
    title: 'Refresh warehouse list',
    action: () => refetch(),
  };
  const addResourceContent = {
    title: 'Create warehouse',
    action: () => handleCreateWarehouse(orgName),
    disabled: data?.getStorageProfile?.properties?.validationStatus !== 'PASS',
  };

  const tabs = useMemo(
    () => [
      {
        id: 'profile_overview',
        label: 'Storage Profile Overview',
        group: MainTabGroup.CONNECTIONS,
        content: (
          <ProfileOverview
            profile={data?.getStorageProfile}
            orgName={orgName}
            isSecurityAdmin={isSecurityAdmin}
            dashboardMenuContent={dashboardDropdownContent}
            refreshResourceContent={refreshResourceContent}
            user={user}
            loading={loading}
          />
        ),
        hide: false,
        href: 'profile-overview',
      },
      {
        id: 'connected_warehouses',
        label: 'Warehouses',
        group: MainTabGroup.CONNECTIONS,
        content: (
          <ConnectedWarehouses
            profile={data?.getStorageProfile}
            storageProfileType={storageProfileType}
            orgName={orgName}
            loading={loading}
            addResourceContent={addResourceContent}
            refreshResourceContent={refreshWarehousesContent}
            dashboardMenuContent={warehousesDropdownContent}
            isSecurityAdmin={isSecurityAdmin}
            user={user}
          />
        ),
        hide: false,
        href: 'connected-warehouses',
      },
      {
        id: 'profile_access',
        label: 'Access Control',
        group: MainTabGroup.CONNECTIONS,
        content: (
          <StorageProfileAccessControls
            profile={data?.getStorageProfile}
            orgId={orgId}
            orgName={orgName}
            orgDisplayName={orgDisplayName}
            loading={loading}
            user={user}
          />
        ),
        hide: false,
        href: 'profile-access',
      },
    ],
    [data, user],
  );

  if (!loading && !data) {
    return <NotFound />;
  }

  return (
    <>
      {tabs ? (
        <TabLayout
          tabs={tabs}
          breadcrumbs={breadcrumbs}
          resourceType={`Storage Profile`}
        />
      ) : (
        <Skeleton />
      )}
    </>
  );
}
