import React, { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FieldHookConfig, useField } from 'formik';
import { isEmpty } from 'lodash';

import TextField from '../Forms/TextField';

export default function ReadOnlyWithCopy({
  name,
  label,
  ...props
}: {
  name: string;
  label: string;
}) {
  const [copySuccess, setCopySuccess] = useState('');
  const [field] = useField(props as FieldHookConfig<any>);

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        '> div': {
          width: '100%',
        },
      }}
    >
      <TextField
        name={name}
        id={name}
        label={label}
        {...props}
        fullWidth
        //@ts-ignore
        InputProps={{ ...props.InputProps, readOnly: true }}
      />
      <IconButton
        sx={{ ml: 2 }}
        onClick={() => copyToClipBoard(field.value[name])}
      >
        <Tooltip
          title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
          placement={`top`}
        >
          <ContentCopyIcon sx={{ color: 'sky.seven' }} />
        </Tooltip>
      </IconButton>
    </Box>
  );
}
