import React from 'react';

import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
} from '@mui/material';

import TextField from '../../Forms/TextField';
import { GcsDashboardLink } from '../storage';
import { ExternalBreadcrumbs } from './helpers';

const dashboardBreadcrumbs = ['Cloud Overview', 'Dashboard', 'Project info'];

export default function GoogleProjectStep() {
  return (
    <Box display={'flex'} flexDirection={'column'} sx={{ px: 5, pb: 3 }}>
      <Box sx={{ pb: 2 }}>
        <Typography variant="body1">
          {`In Google cloud services find your project number and project ID`}
        </Typography>
      </Box>
      <Card variant={'outlined'} sx={{ borderRadius: 2, mt: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
          <CardHeader
            title={
              <>
                <Typography variant={'subtitle1'} sx={{ mb: 1 }}>
                  {'Google cloud project'}
                </Typography>
                <ExternalBreadcrumbs
                  breadcrumbs={dashboardBreadcrumbs}
                  link={`${GcsDashboardLink}`}
                />
              </>
            }
            sx={(theme) => ({
              pt: 2,
              borderBottom: `1px solid ${theme.palette.midnight.two}`,
            })}
          />
          <CardContent>
            <List sx={{ listStyle: 'decimal', pl: 5 }}>
              <ListItem sx={{ display: 'list-item', p: 0 }}>
                <Typography variant={'body2'}>
                  Enter <b>Project number</b> and <b>Project ID</b> below then
                  continue.
                </Typography>
              </ListItem>
            </List>
            <Box display={'flex'} flexDirection={'row'}>
              <TextField
                name="projectNum"
                type="text"
                label={'Project number'}
                id="projectNum"
                variant="standard"
                margin="normal"
                autoComplete="off"
                helperText="The project number found under Project info on the Dashboard page in GCP"
                sx={{ mr: 2, width: '100%' }}
              />
              <TextField
                name="projectId"
                type="text"
                label="Project ID"
                id="projectId"
                variant="standard"
                margin="normal"
                autoComplete="off"
                helperText="Project ID found under Project info on the Dashboard page in GCP"
                sx={{ mr: 2 }}
              />
            </Box>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}
