import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { useMutation } from '@apollo/client';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Formik, FormikValues, Form } from 'formik';
import { includes } from 'lodash';
import { defaultStyle } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import { OrgAuthProvider } from '../../../graphql/gen/graphql';
import { createOrganizationAuth } from '../../../graphql/organization';
import { getApolloErrorMessage } from '../../../utils/ApolloUtils';
import { getLogger } from '../../../utils/logging';
import { Talert } from '../../Alert/Talert';
import { Tabutton } from '../../Button/Tabutton';
import TextField from '../../Forms/TextField';
import { awsAuthValidationSchema } from '../helpers';

export function AWSIdentityProviderForm({
  organizationId,
  onIdentityProviderCreated,
  onDismiss,
  modal = true,
  setFormVisible,
}: {
  organizationId: string;
  onIdentityProviderCreated: (data: boolean) => void;
  onDismiss?: any;
  modal?: boolean;
  setFormVisible?: () => void;
}) {
  const [createSSO, { error, loading }] = useMutation(createOrganizationAuth);
  const [errorMessage, setErrorMessage] = useState('');
  const logger = getLogger(
    'components.Credentials.CreateIdentityProviderDialog',
  );

  const handleCancel = () => {
    setErrorMessage('');
    modal && onDismiss();
    setFormVisible && setFormVisible();
  };

  const onSubmit = async (values: FormikValues) => {
    const trimmedClientId = values.clientId.trim();
    const accountId = trimmedClientId.split(':')[4];
    const old = values.clientId.split(':')[4];
    await createSSO({
      variables: {
        organizationId: organizationId,
        request: {
          clientId: trimmedClientId,
          issuer: accountId,
          authType: OrgAuthProvider.IamSso,
        },
      },
    })
      .then((data) => {
        if (data) {
          onIdentityProviderCreated(data.data.createOrganizationAuth);
          modal && onDismiss();
        }
      })

      .catch((error) => {
        logger.debug(getApolloErrorMessage(error));
        if (includes(error, '409: Conflict')) {
          setErrorMessage('ARN is already in use as a Tabular identity');
        } else {
          setErrorMessage('Something went wrong. Please try again.');
        }
      });
  };

  const examples = [
    `arn:aws:iam::<aws_acct_id>:user/<user>`,
    `arn:aws:sts::<aws_acct_id>:assumed-role/<role>`,
  ];

  const exampleArns = examples.map((arn, index) => (
    <SyntaxHighlighter
      key={index}
      language={'text'}
      style={defaultStyle}
      customStyle={{
        display: 'inline-flex',
        fontSize: '13px',
        lineHeight: '1em',
        margin: 0,
        marginTop: 4,
        borderRadius: '4px',
        paddingTop: '.3em',
        paddingBottom: '.3em',
        wordBreak: 'break-word',
      }}
    >
      {arn}
    </SyntaxHighlighter>
  ));

  return (
    <Formik
      initialValues={{
        clientId: '',
      }}
      validationSchema={awsAuthValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <DialogTitle sx={{ p: 2, pb: 1 }}>
            {'Add AWS IAM as identity provider'}
            <Typography
              variant={'helperText'}
              display={'inline-block'}
              sx={{ pt: 2, pb: 0, pr: 2 }}
            >
              The full ARN of an IAM role providing user identity in your AWS
              account, examples: {exampleArns}
            </Typography>
          </DialogTitle>
          <DialogContent>
            {error && errorMessage && (
              <Talert severity="error" sx={{ mb: 1 }}>
                <Typography>{errorMessage}</Typography>
              </Talert>
            )}
            <TextField
              name="clientId"
              type="text"
              label="IAM Role ARN"
              id="clientId"
              fullWidth
              required
              autoComplete="off"
              sx={{ my: 2 }}
            />
          </DialogContent>

          <DialogActions sx={{ p: 2 }}>
            <Tabutton onClick={handleCancel} disabled={isSubmitting || loading}>
              Cancel
            </Tabutton>
            <Tabutton
              type="submit"
              disabled={isSubmitting || loading}
              variant="contained"
            >
              Create
            </Tabutton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
