import React, { useCallback, useEffect, useMemo } from 'react';

import { AddOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, BoxProps, Divider } from '@mui/material';
import {
  DataGridPro,
  GRID_REORDER_COL_DEF,
  GridActionsCellItem,
  GridCallbackDetails,
  GridCellParams,
  GridColumns,
  GridColumnVisibilityModel,
  GridEventListener,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowOrderChangeParams,
  GridRowParams,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-pro/hooks/features/detailPanel/gridDetailPanelToggleColDef';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { useTableEditContext } from './TableEditContext';

import { SortOrderFieldInput } from '../../graphql/gen/graphql';
import { getLogger } from '../../utils/logging';
import { Tabutton } from '../Button/Tabutton';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { EditToolbar } from './EditToolbar';
import { SchemaEditToolbar } from './SchemaEditToolbar';
import { SimpleSelectionEdit, SimpleSelectionView } from './SimpleSelector';
import { SortOrderEditProps, SortOrderFieldRow } from './SortOrderEdit';
import {
  SourceFieldSelectionEdit,
  SourceFieldSelectionView,
} from './SourceFieldSelector';
import {
  CHANGELOG_SORT_NO_ROWS_HEIGHT,
  EditTableMode,
  TABLE_GRID_NO_ROWS_HEIGHT,
} from './TableEdit';
import {
  createCDCChangelogSchemaModel,
  handleRowModesModelChangeStripCellFocus,
  nukeAllEmpties,
  safeJSONParse,
  updateRowPosition,
  validateSortOrderRow,
} from './TableEditHelpers';
import {
  TransformSelectionEdit,
  TransformSelectionView,
} from './TransformSelector';

const logger = getLogger(
  'components.TableEdit.SortOrderEdit' /*FUTURE import.meta.url ?*/,
);

export default function ChangelogOrderEdit({
  validityChanged,
  ...props
}: SortOrderEditProps) {
  const gridApiRef = useGridApiRef();

  const {
    schemaModel,
    mode,
    triggerValidation,
    isCancelled,
    cancelSubmission,
    changelogSortOrderFieldModel: rows,
    setChangelogSortOrderFieldModel: setRows,
  } = useTableEditContext();

  const changeLogModel = useMemo(() => {
    return createCDCChangelogSchemaModel(schemaModel);
  }, [schemaModel]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );
  const [rowsBeingEdited, setRowsBeingEdited] = React.useState<
    Record<string, boolean>
  >({});

  const [validityModel, setValidityModel] = React.useState<
    Record<GridRowId, number>
  >({});

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      ...(mode === EditTableMode.VIEW
        ? { __reorder__: false, actions: false }
        : {}),
    });

  const [lastValidation, setLastValidation] = React.useState<number>(-1);

  const [shouldAddNewRowOnNextLoop, setShouldAddNewRowOnNextLoop] =
    React.useState(false);

  const stopAllEditing = (): boolean => {
    let model: GridRowModesModel = {};
    logger.debug(`Stop all editing for ${rows.length} rows`, model);
    let foundAtLeastOneInEditMode = false;
    rows.forEach((eachRow, idx) => {
      if (
        gridApiRef.current.getRowMode(eachRow.rowId as GridRowId) ==
        GridRowModes.Edit
      ) {
        foundAtLeastOneInEditMode = true;
      }
      model[eachRow.rowId as GridRowId] = { mode: GridRowModes.View };
    });

    setRowModesModel(model);
    return foundAtLeastOneInEditMode;
  };

  const startEditingRow = (rowId: GridRowId) => {
    if (gridApiRef.current.getRowMode(rowId) != GridRowModes.Edit) {
      gridApiRef.current.startRowEditMode({
        id: rowId,
        fieldToFocus: 'schemaSourceField',
      });
      //stupidly, onRowEditStart does not fire when we put this row into edit mode. (above) so we have to count here too
      setRowsBeingEdited((prevState) => {
        return { ...prevState, [rowId]: true };
      });
    }
  };

  useEffect(() => {
    if (shouldAddNewRowOnNextLoop) {
      setShouldAddNewRowOnNextLoop(false);
      handleAddColumnClick();
    }
  }, [shouldAddNewRowOnNextLoop]);

  useEffect(() => {
    if (!isCancelled && triggerValidation > lastValidation) {
      logger.debug(
        `⚡️SortOrder Edit: validation trigger is ${triggerValidation} and last validation is ${lastValidation}!`,
      );

      //1. stop all editing my rows
      const foundAtLeastOneInEditMode = stopAllEditing();
      //2. if no rows being edited, then we are good, report back!
      if (
        !foundAtLeastOneInEditMode &&
        Object.keys(rowsBeingEdited).length == 0
      ) {
        logger.debug(`SortOrder Triggering schema valid ALL GOOD`);
        let allRowsGood = true;
        if (rows.length == 0) {
          allRowsGood = true;
        }
        rows.forEach((eachRow) => {
          if (!validateSortOrderRow(eachRow)) {
            allRowsGood = false;
          }
        });
        if (allRowsGood) {
          logger.debug(`All SortOrder rows valid!`);
          if (triggerValidation > lastValidation) {
            validityChanged(triggerValidation);
            setLastValidation(triggerValidation);
          }
        } else {
          logger.debug(`CANCELLING: SortOrder rows are not all valid!`);
          const nukedAtLeastOne = nukeAllEmpties(
            rows,
            setRows,
            'schemaSourceField',
          );
          cancelSubmission(
            0,
            'SortOrder Rows are not all valid!',
            foundAtLeastOneInEditMode || nukedAtLeastOne,
          );
        }
      } else {
        logger.debug(
          `Sort Order edit cant trigger valid, we have rows being edited `,
          rowsBeingEdited,
        );
      }
    }
  }, [
    rows,
    rowsBeingEdited,
    validityModel,
    triggerValidation,
    lastValidation,
    isCancelled,
    rowModesModel,
  ]);

  const handleCellFocusOut = (
    params: GridCellParams,
    event: MuiEvent<MuiBaseEvent>,
    details: GridCallbackDetails,
  ) => {};

  const handleCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails,
  ) => {
    logger.debug('click on cell...');

    if (
      (mode === EditTableMode.EDIT ||
        mode == EditTableMode.CREATE ||
        mode == EditTableMode.CREATE_LIKE ||
        mode == EditTableMode.CREATE_LOAD ||
        mode == EditTableMode.CREATE_CDC_PIPELINE) &&
      params.field !== '__reorder__' &&
      params.field !== 'actions' &&
      params.field !== GRID_DETAIL_PANEL_TOGGLE_FIELD &&
      gridApiRef.current.getRowMode(params.id) === 'view'
    ) {
      gridApiRef.current.startRowEditMode({ id: params.id });
    }
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    logger.debug(
      `: On row EditStart with id ${params.id} current rows editing is `,
      rowsBeingEdited,
    );
    setRowsBeingEdited((prevState) => {
      return { ...prevState, [params.id]: true };
    });
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    logger.debug(`: rowEditStop for id ${params.id} NOOP`);

    if (
      params.reason === 'tabKeyDown' &&
      params.id === rows[rows.length - 1].rowId
    ) {
      setShouldAddNewRowOnNextLoop(true);
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.rowId !== id));
    setRowsBeingEdited(({ [id]: me, ...prevState }) => {
      return { ...prevState };
    });
  };

  const handleRowModesModelChange = (
    rowModesModel: GridRowModesModel,
    details: GridCallbackDetails,
  ) => {
    logger.debug(`Handle handleRowModesModelChange!`, rowModesModel, details);
    handleRowModesModelChangeStripCellFocus(
      rowModesModel,
      details,
      setRowModesModel,
    );
  };

  const processRowUpdate = async (newRow: GridRowModel<SortOrderFieldRow>) => {
    logger.debug(
      `🚧 Handle processRowUpdate for row ${newRow.rowId}, removing from editing!`,
      newRow,
    );
    //const subRows = subRowsModel[newRow.id as GridRowId];
    const updatedRow = {
      ...newRow,
      isNew: false,
      __reorder__: newRow.schemaSourceField,
    };

    setRows((prevState) => {
      return prevState.map((eachRow) =>
        eachRow.rowId === updatedRow.rowId ? updatedRow : eachRow,
      );
    });

    //handling this here because handleRowEditStop is flaky, not being triggered when save all triggered?
    let updatedRowsBeingEdited: Record<string, any> | undefined = undefined;
    setRowsBeingEdited((prevState) => {
      const { [newRow.rowId as any]: newGuy, ...rows } = prevState;
      updatedRowsBeingEdited = rows;
      return { ...updatedRowsBeingEdited };
    });

    return updatedRow;
  };
  const getUsedTransforms = useCallback(
    (sourceColumn: string, myRowId: string) => {
      const usedTransforms = rows
        .filter(
          (eachField) =>
            eachField.schemaSourceField === sourceColumn &&
            eachField.rowId !== myRowId,
        )
        .map((eachUsedField) =>
          eachUsedField.transform && eachUsedField.transform !== ''
            ? safeJSONParse(eachUsedField.transform, { name: '' }).name
            : '',
        );
      return usedTransforms;
    },
    [rows],
  );

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );

    setRows(newRows);
  };

  const handleAddColumnClick = () => {
    if (mode === EditTableMode.VIEW) return;

    const newName = uuidv4();
    //HARD CODING transform, direction, nullorder because it doesnt matter, we just want the name from the UI today.
    setRows((oldRows) => [
      ...oldRows,
      {
        rowId: newName,
        isNew: true,
        schemaSourceField: '',
        sourceId: -1,
        transform:
          '{"name":"none","value":"identity","description":"","sort":10}',
        direction: 'asc',
        nullOrder: 'nulls-first',
      },
    ]);
    startEditingRow(newName);
  };

  const columns: GridColumns = [
    {
      field: 'schemaSourceField',
      headerName: 'Changelog Ordering',
      width: 450,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <SourceFieldSelectionEdit
            schemaModel={changeLogModel}
            hideTypes
            excludedFieldNames={[
              'op',
              ...rows
                .filter((row) => row.schemaSourceField)
                .map((row) =>
                  safeJSONParse(row.schemaSourceField, {
                    name: '',
                  }).name.toLowerCase(),
                ),
            ]}
            {...params}
          />
        );
      },
      renderCell: (params: GridRenderCellParams) => (
        <>
          <SourceFieldSelectionView hideTypes {...params} />
        </>
      ),
    },

    {
      ...GRID_REORDER_COL_DEF, // Already contains the right field
      editable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <>
            <Box title={'Click and drag to re-order'}>
              <Divider
                orientation="vertical"
                sx={{ height: 0.5, mr: 1 }}
              ></Divider>
              {GRID_REORDER_COL_DEF &&
                GRID_REORDER_COL_DEF.renderCell &&
                GRID_REORDER_COL_DEF.renderCell({ ...params, tabIndex: -1 })}
              <Divider
                orientation="vertical"
                sx={{ ml: 1, height: 0.5 }}
              ></Divider>
            </Box>
          </>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 20,
      cellClassName: 'actions',
      align: 'right',
      flex: 1,
      getActions: ({ id, row, columns }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        return [
          <GridActionsCellItem
            placeholder
            key="delete"
            icon={<CloseIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            sx={{ color: 'sunset.seven', mr: 2 }}
          />,
        ];
      },
    },
  ];

  return (
    <Box {...props}>
      <DataGridPro
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(args) => {
          logger.debug('ERROR while saving row!');
        }}
        onCellClick={handleCellClick}
        onCellFocusOut={handleCellFocusOut}
        components={{
          Footer: EditToolbar,
          Toolbar: null,
          /*@ts-ignore*/
          ColumnHeaders: () => {
            return <></>;
          },
          NoRowsOverlay: OhNoesRows,
        }}
        componentsProps={{
          toolbar: null,
          footer: {
            mode,
            setRowsBeingEdited,
            level: 0,
            stopAllEditing,
            startEditingRow,
            buttonText: 'Specify Additional Order Column',
            onAddColumnClick: handleAddColumnClick,
            sx: {
              display: rows.length === 0 ? 'none' : '',
              minHeight: 56,
            },
          },
          noRowsOverlay: {
            GridOverlayProps: {
              top: -56,
              height: TABLE_GRID_NO_ROWS_HEIGHT - 44,
            },
            buttonControl:
              mode !== EditTableMode.VIEW ? (
                <Tabutton
                  variant={'outlined'}
                  sx={{ mt: 1, alignContent: 'center' }}
                  endIcon={<AddOutlined />}
                  onClick={handleAddColumnClick}
                >
                  {`Specify changelog order columns`}
                </Tabutton>
              ) : (
                <Box>This table has no sort order.</Box>
              ),
          },
        }}
        disableSelectionOnClick={true}
        experimentalFeatures={{ newEditingApi: true }}
        autoHeight={rows.length > 0}
        hideFooterPagination
        hideFooterSelectedRowCount
        disableColumnMenu
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        getDetailPanelHeight={({ row }) => 'auto'}
        getRowId={(row) => row?.rowId}
        initialState={{}}
        sx={{
          ...(rows.length == 0
            ? { height: CHANGELOG_SORT_NO_ROWS_HEIGHT }
            : {}),
        }}
      />
    </Box>
  );
}
