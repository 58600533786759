import React, { useContext } from 'react';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';

import { KnownFeatureFlags } from '../../context/GlobalPropsContext';

import { GlobalPropertiesContext } from '../../context';
import Copyright from '../Login/Copyright';

export default function Footer() {
  const { ff, systemProps, paletteControl } = useContext(
    GlobalPropertiesContext,
  );
  const theme = useTheme();

  return (
    <>
      <Box component="footer" flexShrink={0} p={4}>
        <Container maxWidth="lg">
          <Copyright />
          {systemProps && ff(KnownFeatureFlags.SHOW_VERSION) && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body2">
                {systemProps?.['version']}
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
      {ff(KnownFeatureFlags.DARK_MODE_ENABLED) && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            borderRadius: 1,
            p: 1,
          }}
        >
          {theme.palette.mode} mode
          <IconButton
            sx={{ ml: 1 }}
            onClick={paletteControl.togglePaletteMode}
            color="inherit"
          >
            {theme.palette.mode === 'dark' ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
        </Box>
      )}
    </>
  );
}
