import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Typography, Button } from '@mui/material';

import { ROUTES } from '../../RouteTable';

interface Props {
  children?: React.ReactNode;
  resourceName: string;
  pageTitle?: string;
  marginB?: number;
}
export const PageHeader = ({
  children,
  resourceName,
  pageTitle,
  marginB = 5,
}: Props) => {
  return (
    <Box
      sx={{
        mb: marginB,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down(450)]: {
            paddingBottom: 1,
          },
        })}
      >
        <Typography variant="h4" sx={{ wordBreak: 'break-word' }}>
          {resourceName}
        </Typography>
        {pageTitle && (
          <Typography variant="subtitle1" color={'dusk.six'} paddingTop={1}>
            {pageTitle}
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
};
