import * as React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import BadgeIcon from '@mui/icons-material/Badge';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { compact, concat, includes } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { enqueueSnackbar } from 'notistack';

import { toSingleRole } from '../../RouteTable';
import {
  Member,
  Role,
  RoleMember,
  SearchRoleResult,
} from '../../graphql/gen/graphql';
import { getRoleByName, updateRole } from '../../graphql/role';
import { calculateTextWidth } from '../../pages/helpers';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import { Tavatar } from '../Avatar/Tavatar';
import { Tacard } from '../Card/Tacard';
import { ChipColors, Tachip } from '../Chip/Tachip';
import {
  CustomPaging,
  DataGridToolBar,
} from '../DataGridToolbar/DataGridToolbar';
import QuickAddPerson from '../Forms/QuickAddPerson';
import QuickAddRole from '../Forms/QuickAddRole';
import { StyledSwitch } from '../Forms/Switch';
import { ConfirmationDialog } from '../Modals/ConfirmationDialog';
import { NamePopover } from '../Popovers/NamePopover';
import QuickAddMenu from '../QuickAddMenu/QuickAddMenu';
import { RoleMemberType } from './helpers';

export default function RoleMemberGrid({
  role,
  currentUserEmail,
  loading,
  refreshResourceContent,
  orgName,
  orgId,
  orgDisplayName,
  onRoleMembershipUpdate,
  canModifyRole,
}: {
  role: Role;
  currentUserEmail: string;
  loading: boolean;
  refreshResourceContent: () => void;
  orgName: string;
  orgId: string;
  orgDisplayName: string;
  onRoleMembershipUpdate?: (result: any) => void;
  canModifyRole: boolean;
}) {
  const apiRef = useGridApiRef();
  const [pageSize, setPageSize] = useState(25);
  const [confirmModal, setConfirmModal] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState<any>();
  const navigate = useNavigate();
  const [updateRoleMutation] = useMutation(updateRole);

  const members = useMemo(
    () =>
      //@ts-ignore
      compact(concat(role?.users || [], role?.children || []))?.map(
        (item: any) => ({
          ...item,
          type: item?.__typename,
        }),
      ),
    [role?.children, role?.users],
  );
  const handleRemoveMember = async () => {
    const updatedMemberList = members
      ?.filter((member) => member.id !== memberToRemove.id)
      ?.map((member: RoleMemberType) => ({
        subject: {
          type: member.__typename === 'Role' ? 'ROLE' : 'USER',
          identifier: member.id,
        },
        admin: member.__typename === 'Role' ? false : member.isAdmin,
      }));

    await updateRoleMutation({
      variables: {
        role: {
          id: role.id,
          roleName: role.name,
          members: updatedMemberList,
        },
      },
      refetchQueries: [
        {
          query: getRoleByName,
          variables: {
            organizationId: orgId,
            roleName: role.name,
            organizationName: orgName,
            organizationDisplayName: orgDisplayName,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(async (result) => {
        await enqueueSnackbar(
          `${memberToRemove?.displayName} successfully removed as member`,
          { variant: 'success' },
        );
        setMemberToRemove(undefined);
        setConfirmModal(false);
      })
      .catch((error) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
          variant: 'error',
        });
      });
  };

  const handleAdminChange = async (user: RoleMember, newValue: boolean) => {
    const roleMembers = members.map((member: RoleMemberType) => ({
      subject: {
        type: member.__typename === 'Role' ? 'ROLE' : 'USER',
        identifier: member.id,
      },
      admin:
        member.__typename === 'Role'
          ? false
          : member.id === user.id
          ? newValue
          : member.isAdmin,
    }));

    await updateRoleMutation({
      variables: {
        role: {
          id: role.id,
          roleName: role.name,
          members: roleMembers,
        },
      },
    })
      .then((result) => {
        enqueueSnackbar(`${user.displayName} admin privileges updated.`, {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
          variant: 'error',
        });
      });
  };

  const onSubmitPerson = async (selectedPerson: Member) => {
    const roleMembers = members.map((member: RoleMemberType) => ({
      subject: {
        type: member.__typename === 'Role' ? 'ROLE' : 'USER',
        identifier: member.id,
      },
      admin: member.__typename === 'Role' ? false : member.isAdmin,
    }));
    roleMembers.push({
      subject: {
        type: 'USER',
        identifier: selectedPerson?.user?.id,
      },
      admin: false,
    });

    await updateRoleMutation({
      variables: {
        role: {
          id: role.id,
          roleName: role.name,
          members: roleMembers,
        },
      },
      refetchQueries: [
        {
          query: getRoleByName,
          variables: {
            organizationId: orgId,
            roleName: role.name,
            organizationName: orgName,
            organizationDisplayName: orgDisplayName,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then((result) => {
        if (onRoleMembershipUpdate) {
          onRoleMembershipUpdate(result?.data?.updateRole);
        }
        enqueueSnackbar(
          `${selectedPerson.displayName} successfully added as member`,
          { variant: 'success' },
        );
      })
      .catch((error) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
          variant: 'error',
        });
      });
  };

  const onSubmitRole = async (selectedRole: SearchRoleResult) => {
    const roleMembers = members.map((member: RoleMemberType) => ({
      subject: {
        type: member.__typename === 'Role' ? 'ROLE' : 'USER',
        identifier: member.id,
      },
      admin: member.__typename === 'Role' ? false : member.isAdmin,
    }));
    roleMembers.push({
      subject: {
        type: 'ROLE',
        identifier: selectedRole?.roleId,
      },
      admin: false,
    });

    await updateRoleMutation({
      variables: {
        role: {
          id: role.id,
          roleName: role.name,
          members: roleMembers,
        },
      },
      refetchQueries: [
        {
          query: getRoleByName,
          variables: {
            organizationId: orgId,
            roleName: role.name,
            organizationName: orgName,
            organizationDisplayName: orgDisplayName,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then((result) => {
        if (onRoleMembershipUpdate) {
          onRoleMembershipUpdate(result?.data?.updateRole);
        }
        enqueueSnackbar(
          `${selectedRole.roleName} successfully added as member`,
          { variant: 'success' },
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          includes(error.message, 409)
            ? 'This role cannot be added as it causes a relationship cycle.'
            : getUserFriendlyErrorMessage(error.message),
          {
            variant: 'error',
          },
        );
      });
  };

  const columns = useMemo(
    () => [
      {
        field: 'avatar',
        headerName: '',
        width: 75,
        sortable: false,
        align: 'center',
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.type === 'Role' ? (
            <BadgeIcon sx={{ color: 'midnight.six' }} />
          ) : (
            <Tavatar
              profilePic={params.row?.properties?.['picture.url']}
              displayName={params.row.displayName}
              currentUser={currentUserEmail === params.row.email}
            />
          );
        },
      },
      {
        field: 'displayName',
        headerName: 'Name',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
            true,
          );
          return params.row.type === 'Role' ? (
            <Box
              sx={{
                display: 'flex',
                flex: '1 1 100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
                minWidth: 0,
              }}
            >
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Tachip
                      type={ChipColors.Neutral}
                      size={'small'}
                      onClick={() =>
                        navigate(toSingleRole(orgName, params.row.name))
                      }
                      label={
                        <Typography
                          variant={'subtitle2'}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {params.value}
                        </Typography>
                      }
                      {...bindHover(popupState)}
                    />

                    {textWidth >= params.colDef.computedWidth && (
                      <Popper {...bindPopper(popupState)} placement={`right`}>
                        <NamePopover name={params.value} />
                      </Popper>
                    )}
                  </>
                )}
              </PopupState>
            </Box>
          ) : (
            <Typography variant={'inputText'}>{params.value}</Typography>
          );
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        minWidth: 225,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return params.row?.email ? params.row?.email : '--';
        },
      },
      {
        field: 'memberCount',
        headerName: 'People',
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params?.row?.users && params.row?.users?.length !== 0
            ? params.row?.users?.length
            : '--';
        },
      },
      {
        field: 'isAdmin',
        headerName: 'Admin',
        minWidth: 80,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.type === 'Role' ? (
            '--'
          ) : (
            <Tooltip
              title={
                role?.name === 'SECURITY_ADMIN'
                  ? `Security Admin membership has implicit Role Admin privileges.`
                  : ''
              }
              placement={`top`}
              arrow
            >
              <Box>
                <StyledSwitch
                  checked={
                    role?.name === 'SECURITY_ADMIN' ? true : params.value
                  }
                  disabled={!canModifyRole || role?.name === 'SECURITY_ADMIN'}
                  onChange={(e: any) =>
                    handleAdminChange(params.row, e.target.checked)
                  }
                />
              </Box>
            </Tooltip>
          );
        },
      },
      {
        field: 'remove',
        headerName: '',
        width: 60,
        editable: false,
        renderCell: (params: any) => (
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Tooltip title={'Remove role member'} placement={`top`} arrow>
              <span>
                <IconButton
                  disabled={!canModifyRole}
                  onClick={() => {
                    setMemberToRemove(params.row);
                    setConfirmModal(true);
                  }}
                >
                  <CloseIcon
                    sx={{
                      color: canModifyRole ? 'error.main' : 'midnight.three',
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [members, canModifyRole],
  );

  const AddPersonComponent = (
    <Box paddingLeft={2}>
      <QuickAddMenu
        buttonSize={`medium`}
        disabled={!canModifyRole || role?.name === 'EVERYONE'}
        menuContent={
          <QuickAddPerson
            organizationId={orgId}
            onPersonAdded={onSubmitPerson}
            existingPeople={role?.users || []}
            currentUserEmail={currentUserEmail}
          />
        }
        tooltipText={`Add person`}
      />
    </Box>
  );

  const AddRoleComponent = (
    <Box paddingLeft={2}>
      <QuickAddMenu
        buttonSize={`medium`}
        disabled={!canModifyRole || role?.name === 'EVERYONE'}
        menuContent={
          <QuickAddRole
            organizationId={orgId}
            onRoleAdded={onSubmitRole}
            existingRoles={role?.children || []}
          />
        }
        tooltipText={`Add role`}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        '& .MuiDataGrid-row, .MuiDataGrid-root .MuiDataGrid-cell, .rendering-zone':
          { maxheight: 'none !important', minHeight: '67px !important' },
        '& .MuiDataGrid-root .MuiDataGrid-window': {
          position: 'relative !important',
        },
        '& .MuiDataGrid-root .MuiDataGrid-viewport': {
          maxheight: 'none !important',
        },
        '& .MuiDataGrid-root': { height: 'auto !important' },
        '& .roles-cell': {
          display: 'flex',
          flexWrap: 'wrap',
          flex: '1 1 auto',
          maxHeight: '100%',
        },
      }}
    >
      <Tacard grid>
        <DataGridPro
          rows={members}
          columns={columns as GridColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          headerHeight={34}
          autoHeight
          apiRef={apiRef}
          pagination={members?.length >= 25}
          hideFooter={members?.length < 25}
          disableColumnMenu
          density="comfortable"
          getRowHeight={() => 'auto'}
          initialState={{
            sorting: {
              sortModel: [{ field: 'displayName', sort: 'asc' }],
            },
            pagination: { page: 0, pageSize: 25 },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [''],
              },
            },
          }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {`${
                  role?.name === 'EVERYONE'
                    ? 'All people and all roles'
                    : 'No members'
                }`}
              </Stack>
            ),
            Toolbar: DataGridToolBar,
            Pagination: CustomPaging,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              apiRef: apiRef,
              pageSize: pageSize,
              setPageSize: setPageSize,
              headerName: (
                <Box display={'flex'} paddingLeft={2} alignItems={'center'}>
                  {'Role members'}
                </Box>
              ),
              refreshResourceContent: {
                title: 'Refresh members',
                action: refreshResourceContent,
              },
              customButton1: AddPersonComponent,
              customButton2: AddRoleComponent,
              rowCount: members?.length,
              showRange: members?.length >= 25,
              pageAtNumber: 25,
            },
            pagination: {
              apiRef: apiRef,
              pageSize: pageSize,
              setPageSize: setPageSize,
              rowCount: members?.length,
            },
          }}
          loading={loading}
          sx={{
            border: 'none',
            '.MuiDataGrid-columnHeaders': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
            '.MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: 'dusk.half',
            },
          }}
        />
      </Tacard>
      <ConfirmationDialog
        open={confirmModal}
        title="Remove member?"
        acceptText="Remove"
        onDismiss={() => {
          setConfirmModal(false);
        }}
        onAccept={handleRemoveMember}
      >
        {`Remove ${memberToRemove?.displayName} as member of ${role?.name}?`}
      </ConfirmationDialog>
    </Box>
  );
}
