import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { Talert } from '../../components/Alert/Talert';
import MemberGrid from '../../components/MemberGrid/MemberGrid';
//@ts-ignore
import { showInviteUsersDialog } from '../../components/Modals/InviteUsersDialog';
import { useAuth } from '../../context/auth-context';
import { getAuthorizationDecision } from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  AuthDecisionSubjectType,
  Authorization,
  Privilege,
} from '../../graphql/gen/graphql';
import {
  organizationMemberAndInvitesQuery,
  organizationMembersQuery,
} from '../../graphql/organization';

export default function Members({ orgName }: { orgName: string }) {
  const { user } = useAuth();

  const { data: grantDecision } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        privileges: [Privilege.ManageUsers],
        requirement: 'ALL',
        resource: {
          type: AuthDecisionResourceType.Organization,
          identifier: user.getOrganization(orgName)?.id,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const hasManageUser =
    grantDecision?.authDecision === AuthDecisionResponseType.Allow;

  const membershipQuery = hasManageUser
    ? organizationMemberAndInvitesQuery
    : organizationMembersQuery;

  const { data, loading, error, refetch } = useQuery(membershipQuery, {
    variables: {
      organizationId: user.getOrganization(orgName)?.id,
    },
    context: {
      headers: {
        'Cache-Control': 'no-cache', //tell our graphql code not to ever cache
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    return <Talert severity="error">Something went wrong.</Talert>;
  }

  const addResourceContent = {
    title: 'Invite members',
    action: () =>
      showInviteUsersDialog(data?.organization).then((userInvited: any) => {
        if (userInvited) {
          refetch();
        }
      }),
    disabled: !hasManageUser,
  };
  const refreshResourceContent = {
    title: 'Refresh member list',
    action: () => refetch(),
  };

  return (
    <>
      <MemberGrid
        organization={data?.organization}
        enableRemoveMember={hasManageUser}
        currentUserEmail={user.loginSession?.membership?.email || user.email}
        addResourceContent={addResourceContent}
        refreshResourceContent={refreshResourceContent}
        loading={loading}
      />
    </>
  );
}
