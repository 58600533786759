const protocol = process.env.REACT_APP_SERVICE_PROTOCOL;
const subscriptionProtocol =
  process.env.REACT_APP_SERVICE_SUBSCRIPTIONS_PROTOCOL;

const getBaseUrl = () => {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'remote'
  ) {
    return window.location.hostname;
  }
  const baseDomainParts = window.location.hostname.split('.').slice(1);
  baseDomainParts.unshift('api');
  return baseDomainParts.join('.');
};

export const getApiUrl = () => {
  return `${protocol}://${getBaseUrl()}${process.env.REACT_APP_API_URL_SUFFIX}`;
};

export const getUploadBaseUrl = () => {
  return `${protocol}://${getBaseUrl()}${
    process.env.REACT_APP_UPLOAD_URL_SUFFIX
  }`;
};

export const getSubscriptionsUrl = () => {
  return `${subscriptionProtocol}://${getBaseUrl()}${
    process.env.REACT_APP_SUBSCRIPTIONS_URL_SUFFIX
  }`;
};

export const getApiWafUrl = () => {
  return `${protocol}://${getBaseUrl()}${process.env.REACT_APP_API_WAF_SUFFIX}`;
};

export const getCatalogUrl = () => {
  return `${protocol}://${getBaseUrl()}${
    process.env.REACT_APP_CATALOG_SERVICE_URL_SUFFIX
  }`;
};

export const getSignerUrl = () => {
  return `${protocol}://${getBaseUrl()}${
    process.env.REACT_APP_SIGNER_SERVICE_URL_SUFFIX
  }`;
};
