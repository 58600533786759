import React from 'react';

import { Box } from '@mui/material';

export const ProgressBar = React.memo(function ProgressBar(props: {
  value: number;
  display: any;
}) {
  const { value, display } = props;

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.midnight.two}`,
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 24,
        borderRadius: '2px',
      })}
    >
      <Box
        sx={{
          position: 'absolute',
          lineHeight: '24px',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '4px',
        }}
      >
        {display}
      </Box>
      <Box
        sx={{
          height: '100%',
          backgroundColor: 'sky.two',
          maxWidth: `${value}%`,
        }}
      />
    </Box>
  );
});
