import React, { useMemo } from 'react';

import { Box, CardHeader, Skeleton, Typography } from '@mui/material';

import { StorageProfile } from '../../graphql/gen/graphql';
import { Refresh } from '../Button/Refresh';
import { Tacard } from '../Card/Tacard';
import {
  getMainValidationStatus,
  getValidationDisplayDetails,
  getValidationStatuses,
} from '../CreateStorageBucket/ValidationStatusHelpers';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import DataSourceCard from '../DataSources/DataSourceCard';
import {
  getStorageProfileDetailsTemplate,
  getThemValidationDeets,
} from './helpers';

export default function ProfileOverview({
  profile,
  orgName,
  isSecurityAdmin,
  dashboardMenuContent,
  refreshResourceContent,
  user,
  loading,
}: {
  profile: StorageProfile;
  orgName: string;
  isSecurityAdmin: boolean;
  dashboardMenuContent: any;
  refreshResourceContent: any;
  user: any;
  loading: boolean;
}) {
  const warehouseCount = useMemo(() => profile?.warehouses?.length, [profile]);
  const individualChecks = useMemo(
    () =>
      profile?.properties.validationDetails &&
      getValidationStatuses(
        profile?.properties?.validationDetails,
        warehouseCount,
      ),
    [profile?.properties?.validationDetails, warehouseCount],
  );
  const mainStatus = useMemo(
    () => getMainValidationStatus(profile?.properties?.validationStatus),
    [profile?.properties?.validationStatus],
  );
  const {
    status: warehouseStatus,
    label: warehouseLabel,
    icon: warehouseIcon,
  } = getValidationDisplayDetails(
    !profile?.properties?.validationDetails
      ? mainStatus
      : individualChecks.warehouse,
  );
  const {
    status: importStatus,
    label: importLabel,
    icon: importIcon,
  } = getValidationDisplayDetails(
    !profile?.properties?.validationDetails
      ? mainStatus
      : individualChecks.dataImportList,
  );

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <Tacard>
          <CardHeader
            title={
              <Box
                display={`flex`}
                justifyContent={`space-between`}
                alignItems={`center`}
              >
                <Typography variant={`h6`}>{profile?.bucket}</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Refresh
                    refreshList={refreshResourceContent.action}
                    title={refreshResourceContent.title}
                  />
                  <DashboardMenu
                    dashboardDropdownContent={dashboardMenuContent}
                  />
                </Box>
              </Box>
            }
          />
          <DataSourceCard
            profile={profile}
            orgName={orgName}
            isSecurityAdmin={isSecurityAdmin}
            showActions={false}
            showStatus={false}
            currentUserEmail={user?.email}
          />
          {getThemValidationDeets({
            validationStatusDetails:
              profile?.properties?.validationStatusDetails ||
              profile?.properties?.validationDetails,
            warehouseCount,
            warehouseStatus,
            warehouseLabel,
            warehouseIcon,
            importStatus,
            importLabel,
            importIcon,
          })}
          {getStorageProfileDetailsTemplate(profile)}
        </Tacard>
      )}
    </>
  );
}
