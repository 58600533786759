import React from 'react';

import { Box, Typography } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';

import TextField from '../../Forms/TextField';
import {
  GcsDashboardLink,
  GcsServiceAccountLink,
  GcsWorkloadIdentityLink,
} from '../storage';
import { ExternalBreadcrumbs } from './helpers';

const dashboardBreadcrumbs = ['Cloud Overview', 'Dashboard', 'Project info'];
const serviceAccountBreadcrumbs = [
  'IAM & Admin',
  'Service Accounts',
  'Created service account',
];
const poolBreadcrumbs = [
  'IAM & Admin',
  'Workload Identity Federation',
  'Created pool',
];
export default function GoogleBucketForm({
  messaging,
}: {
  messaging: React.ReactNode;
}) {
  const { values } = useFormikContext() as FormikValues;
  return (
    <Box sx={{ px: 5, pb: 6, boxShadow: 'none' }}>
      <Typography variant={'h1'} sx={{ pb: 1 }}>
        {values.bucketName} inputs
      </Typography>
      {messaging}
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
        <Typography variant={'subtitle1'}>Cloud Overview</Typography>
        <ExternalBreadcrumbs
          breadcrumbs={dashboardBreadcrumbs}
          link={GcsDashboardLink}
        />
        <Box display={'flex'} flexDirection={'row'}>
          <TextField
            name="projectNum"
            type="text"
            label={'Project number'}
            id="projectNum"
            variant="standard"
            margin="normal"
            autoComplete="off"
            helperText="The project number found under Project info on the Dashboard page in GCP"
            sx={{ mr: 2, width: '100%' }}
          />
          <TextField
            name="projectId"
            type="text"
            label="Project ID"
            id="projectId"
            variant="standard"
            margin="normal"
            autoComplete="off"
            helperText="Project ID found under Project info on the Dashboard page in GCP"
            sx={{ mr: 2 }}
          />
        </Box>
        <Typography variant={'subtitle1'} sx={{ pt: 1 }}>
          Workload Identity Provider
        </Typography>
        <ExternalBreadcrumbs
          breadcrumbs={poolBreadcrumbs}
          link={GcsWorkloadIdentityLink}
        />
        <Box display={'flex'} flexDirection={'row'}>
          <TextField
            name="poolId"
            type="text"
            label="Pool ID"
            id="poolId"
            variant="standard"
            margin="normal"
            autoComplete="off"
            helperText="The Pool ID from the Workload Identity Pool connected to bucket"
            sx={{ mr: 2 }}
          />
          <TextField
            name="providerId"
            type="text"
            label="Provider ID"
            id="providerId"
            variant="standard"
            margin="normal"
            autoComplete="off"
            helperText="Provider ID from the Workload Identity Pool connected to bucket"
            sx={{ mr: 2 }}
          />
        </Box>
        <Typography variant={'subtitle1'} sx={{ pt: 1 }}>
          Service Account:
        </Typography>
        <ExternalBreadcrumbs
          breadcrumbs={serviceAccountBreadcrumbs}
          link={GcsServiceAccountLink}
        />
        <Box>
          <TextField
            name="externalId"
            type="text"
            label="EX: <service-account-name>@<project-id>.iam.gserviceaccount.com"
            id="externalId"
            variant="standard"
            margin="normal"
            autoComplete="off"
            helperText="Service account email found under IAM & Admin -> Service Accounts"
            sx={{ mr: 2, width: '100%' }}
          />
        </Box>
      </Box>
    </Box>
  );
}
