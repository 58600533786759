import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Grid,
  Typography,
  Box,
  Tooltip,
  Stack,
  ListItem,
  List,
  IconButton,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Tabutton } from '../../Button/Tabutton';
import {
  athenaPySparkNotebook,
  ExternalBreadcrumbs,
} from '../../CreateStorageBucket/BucketSteps/helpers';
import { SecurityMethod } from '../helpers';

export const AthenaNotebookSetup = ({
  warehouseName,
  credential,
  region,
  securityType,
}: {
  warehouseName: string;
  credential: string;
  region: string;
  securityType: any;
}) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const iamConfig = `{
      "spark.sql.catalog.${warehouseName}": "org.apache.iceberg.spark.SparkCatalog",
      "spark.sql.catalog.${warehouseName}.catalog-impl": "org.apache.iceberg.rest.RESTCatalog",
      "spark.sql.catalog.${warehouseName}.rest.sigv4-enabled": "true",
      "spark.sql.catalog.${warehouseName}.uri":  "https://iam-gw.${region}${process.env.REACT_APP_REST_SIGV4_PARTIAL}",
      "spark.sql.catalog.${warehouseName}.warehouse": "${warehouseName}",
      "spark.sql.defaultCatalog": "${warehouseName}",
      "spark.sql.extensions": "org.apache.iceberg.spark.extensions.IcebergSparkSessionExtensions"
  }`;

  const credentialConfig = `{
      "spark.sql.catalog.${warehouseName}": "org.apache.iceberg.spark.SparkCatalog",
      "spark.sql.catalog.${warehouseName}.catalog-impl": "org.apache.iceberg.rest.RESTCatalog",
      "spark.sql.catalog.${warehouseName}.credential": "${credential}",
      "spark.sql.catalog.${warehouseName}.uri": "${process.env.REACT_APP_REST_CATALOG_URL}",
      "spark.sql.catalog.${warehouseName}.rest.signing-region": "${region}",
      "spark.sql.catalog.${warehouseName}.warehouse": "${warehouseName}",
      "spark.sql.defaultCatalog": "${warehouseName}",
      "spark.sql.extensions": "org.apache.iceberg.spark.extensions.IcebergSparkSessionExtensions"
  }`;

  const cmdTemplate =
    securityType == SecurityMethod.MemberCredential
      ? credentialConfig
      : iamConfig;
  return (
    <Stack sx={{ width: 0.95 }}>
      <Box pt={0}>
        <Typography variant={'body1'}>
          Steps to create notebook:
          <ExternalBreadcrumbs breadcrumbs={athenaPySparkNotebook} />
          <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
            {securityType == SecurityMethod.MemberCredential && (
              <ListItem sx={{ display: 'list-item', p: 0 }}>
                <Typography variant={'body1'}>
                  Select your newly created <b>workgroup</b>
                </Typography>
              </ListItem>
            )}
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Click on <b>Create notebook</b>
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Enter a <b>Name</b> for notebook
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Open <b>Apache Spark properties</b> and select <b>Custom</b>
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Toggle Edit in Table to <b>Edit in JSON</b>
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Copy and paste generated configuration below
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Click <b>Create notebook</b>
              </Typography>
            </ListItem>
          </List>
        </Typography>
        <Box
          sx={{
            mt: 0,
            display: 'flex',
            flexDirection: 'column',
            width: 1,
          }}
        >
          <Grid
            container
            sx={{
              width: 0.95,
              mt: 1,
              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingRight: 2,
              }}
            >
              <Typography
                variant={'body1'}
                color={'midnight.nine'}
                alignSelf={'center'}
              >
                Tabular catalog configuration
              </Typography>
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <IconButton
                  onClick={() => copyToClipBoard(cmdTemplate)}
                  title="Copy to clipboard"
                  size={'small'}
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              <SyntaxHighlighter
                language="bash"
                style={{ ...dracula }}
                customStyle={{ margin: 0 }}
                showLineNumbers={false}
              >
                {cmdTemplate}
              </SyntaxHighlighter>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                padding: 1 / 2,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <Tabutton
                  onClick={() => copyToClipBoard(cmdTemplate)}
                  title="Copy to clipboard"
                  size={'small'}
                  sx={{ alignSelf: 'right' }}
                  endIcon={<FileCopyIcon />}
                >
                  Copy Tabular catalog configuration
                </Tabutton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Stack>
  );
};
