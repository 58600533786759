import * as React from 'react';

import { Typography, Box, Stack, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  divider: {
    '&:last-of-type .commit-divider': {
      display: 'none',
    },
  },
}));

type Commit = {
  label: string;
  count: number;
};

type Props = {
  commitsData: Commit[];
};

export const CommitsPopover = React.forwardRef(
  ({ commitsData, ...props }: Props, ref) => {
    const dividerStyles = useStyles();
    return (
      <Box
        ref={ref}
        {...props}
        sx={(theme) => ({
          backgroundColor: 'popper.main',
          color: 'popper.contrastText',
          minWidth: 168,
          borderRadius: '4px',
          position: 'relative',
          boxShadow: theme.shadows[3],
          ml: '10px',
        })}
      >
        <Stack direction={`column`}>
          {commitsData.map((commit) => (
            <Box key={commit.count} className={dividerStyles.divider}>
              <Box display={`flex`} alignItems={`center`} sx={{ py: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexBasis: '45%',
                    justifyContent: 'flex-end',
                    mr: 1,
                  }}
                >
                  <Typography variant={`caption`}>
                    {commit.count.toLocaleString('en-US')}
                  </Typography>
                </Box>
                <Typography variant={`body2`}>{commit.label}</Typography>
              </Box>
              <Divider
                sx={{ backgroundColor: 'popper.one' }}
                className={`commit-divider`}
              />
            </Box>
          ))}
        </Stack>
      </Box>
    );
  },
);
