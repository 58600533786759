import React from 'react';
import ReactMarkdown from 'react-markdown';

import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarContainerProps,
} from '@mui/x-data-grid-pro';

import { Tabutton } from '../Button/Tabutton';
import { EditTableMode } from './TableEdit';

export interface EditToolbarProps extends GridToolbarContainerProps {
  onAddColumnClick: () => void;
  buttonText: string;
  mode: EditTableMode;
}

export function EditToolbar({
  onAddColumnClick,
  buttonText,
  mode,
  children,
  ...props
}: EditToolbarProps) {
  return (
    <GridToolbarContainer {...props}>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          justifyContent:
            mode === EditTableMode.VIEW ? 'flex-end' : 'space-between',
        }}
      >
        <Tabutton
          size={'small'}
          color="primary"
          startIcon={<AddIcon />}
          onClick={onAddColumnClick}
          sx={{ display: mode === EditTableMode.VIEW ? 'none' : '' }}
        >
          {buttonText}
        </Tabutton>
        <Box>{children}</Box>
      </Box>
    </GridToolbarContainer>
  );
}
