import React, { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { orderBy } from 'lodash';

import { ROUTES, toOrgRoot, toOrgSettings, toProfile } from '../../RouteTable';
import { useAuth } from '../../context/auth-context';
import { getAuthorizationDecision } from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  AuthDecisionSubjectType,
  Privilege,
  Organization,
  OrgAuthProvider,
} from '../../graphql/gen/graphql';
import { Tavatar } from '../Avatar/Tavatar';
import { Tabutton } from '../Button/Tabutton';
import { Tabulink } from '../Link/Tabulink';
import { showCreateOrganizationDialog } from '../Modals/CreateOrganizationDialog';
//@ts-ignore
import { showInviteUsersDialog } from '../Modals/InviteUsersDialog';
import UserInfo from './UserInfo';

function OrganizationListItem({
  organization,
  onOrganizationSelected,
  user,
  currentOrg,
  isOrgAdmin,
  hasManageUser,
}: {
  organization: Organization;
  onOrganizationSelected: any;
  user: any;
  currentOrg: Organization;
  isOrgAdmin: boolean;
  hasManageUser: boolean;
}) {
  const orgName = organization.displayName || organization.name;
  const isFastSwitchable =
    user?.loginSession?.loggedInOrg?.id === organization.id ||
    user?.loginSession?.fastSwitchOrgs?.includes(organization.id);
  const isCurrentOrg = currentOrg.name === organization.name;
  const navigate = useNavigate();

  return (
    <Box sx={{ m: 0, p: 0 }} key={organization.id}>
      {isCurrentOrg ? (
        <MenuItem
          sx={(theme) => ({
            width: '100%',
            backgroundColor: theme.palette.dusk.one,
            borderTop: `1px solid ${theme.palette.midnight.two}`,
            borderBottom: `1px solid ${theme.palette.midnight.two}`,

            '&:hover': {
              backgroundColor: theme.palette.dusk.half,
            },
          })}
        >
          <Box
            sx={{
              py: 1,
              display: 'flex',
              flex: '1 0 100%',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={`body2`} sx={{ color: 'midnight.nine' }}>
              {orgName}
            </Typography>
            <Box>
              {hasManageUser && (
                <IconButton
                  size={`small`}
                  sx={{
                    backgroundColor: 'sky.seven',
                    '&:hover': {
                      backgroundColor: 'sky.nine',
                    },
                  }}
                  onClick={() => showInviteUsersDialog(organization)}
                >
                  <PersonAddAlt1Icon
                    sx={{ color: 'white', fontSize: '16px' }}
                  />
                </IconButton>
              )}
              {isOrgAdmin && (
                <IconButton
                  size={`small`}
                  sx={{
                    marginLeft: 1,
                    backgroundColor: 'sky.seven',
                    '&:hover': {
                      backgroundColor: 'sky.nine',
                    },
                  }}
                  onClick={() =>
                    navigate(toOrgSettings(organization.name || ''))
                  }
                >
                  <SettingsIcon sx={{ color: 'white', fontSize: '16px' }} />
                </IconButton>
              )}
            </Box>
          </Box>
        </MenuItem>
      ) : (
        <MenuItem
          sx={(theme) => ({
            width: '100%',
            backgroundColor: 'transparent',
            borderTop: '1px solid transparent',
            borderBottom: '1px solid transparent',
            '&:hover': {
              backgroundColor: theme.palette.dusk.half,
            },
          })}
          onClick={() => {
            onOrganizationSelected();
            navigate(
              isFastSwitchable
                ? toOrgRoot(organization.name || '')
                : ROUTES.logout,
            );
          }}
        >
          <Box
            sx={{
              py: 1,
              width: '100%',
              height: '100%',
              a: { textDecoration: 'none', width: '100%', height: '100%' },
            }}
          >
            <Tabulink
              href={
                isFastSwitchable
                  ? toOrgRoot(organization.name || '')
                  : ROUTES.logout
              }
              title={
                isFastSwitchable
                  ? 'Switch to ' + organization.displayName
                  : organization.displayName +
                    ' requires a different login provider. Click here login.'
              }
            >
              <Typography variant={`body2`} sx={{ color: 'midnight.nine' }}>
                {orgName}
              </Typography>
            </Tabulink>
          </Box>
        </MenuItem>
      )}
    </Box>
  );
}

export default function ProfileMenu() {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const orgName = user?.loginSession?.loggedInOrg?.name;
  const isSecurityAdmin = useMemo(() => user.isSecurityAdmin(orgName), [user]);
  const isOrgAdmin = useMemo(() => user.isOrganizationAdmin(orgName), [user]);
  const canLoginWithPassOrGoogle =
    user?.loginSession?.loggedInOrg?.authProviders?.includes(
      OrgAuthProvider.Password,
    ) ||
    user?.loginSession?.loggedInOrg?.authProviders?.includes(
      OrgAuthProvider.Google,
    );
  const currentOrg = useMemo(
    () =>
      user?.organizations.find(
        (organization: Organization) => organization.name === orgName,
      ),
    [user],
  );
  const profilePicUrl = useMemo(
    () => user?.loginSession?.membership?.properties?.[`picture.url`],
    [user],
  );
  const orderedOrganizations = useMemo(
    () =>
      orderBy(
        user.organizations,
        [(org) => org.displayName.toLowerCase()],
        ['asc'],
      ),
    [user],
  );

  const openMenu = (event: any) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const [getDecision, { data: grantDecision }] = useLazyQuery(
    getAuthorizationDecision,
    {
      variables: {
        request: {
          subject: {
            type: AuthDecisionSubjectType.User,
            identifier: user.id,
          },
          privileges: [Privilege.ManageUsers],
          requirement: 'ALL',
          resource: {
            type: AuthDecisionResourceType.Organization,
            identifier: user.getOrganization(orgName)?.id,
          },
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    },
  );

  const hasManageUser =
    grantDecision?.authDecision === AuthDecisionResponseType.Allow;

  useEffect(() => {
    if (anchorEl) {
      getDecision();
    }
  }, [anchorEl]);

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit"
        sx={{ backgroundColor: 'transparent' }}
      >
        <Tavatar
          profilePic={profilePicUrl}
          displayName={user.loginSession.membership.displayName}
          currentUser={true}
          securityAdmin={isSecurityAdmin}
          orgAdmin={isOrgAdmin}
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem
          sx={{
            '&:hover': { backgroundColor: 'transparent' },
            a: { textDecoration: 'none', width: '100%' },
            minWidth: 320,
          }}
          disableRipple
        >
          <Tabulink href={toProfile(orgName)} onClick={closeMenu}>
            <UserInfo
              user={user}
              currentUser
              securityAdmin={isSecurityAdmin}
              orgAdmin={isOrgAdmin}
              showBadge={true}
              showBorder={false}
            >
              <IconButton
                aria-label="settings"
                size={`small`}
                sx={{
                  backgroundColor: 'sky.seven',
                  '&:hover': { backgroundColor: 'sky.nine' },
                }}
              >
                <SettingsIcon sx={{ color: 'white' }} />
              </IconButton>
            </UserInfo>
          </Tabulink>
        </MenuItem>
        <Divider sx={{ marginY: 2 }} />
        {canLoginWithPassOrGoogle && (
          <Box>
            <MenuItem onClick={() => showCreateOrganizationDialog(user)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  variant={`subtitle2`}
                  sx={{ color: 'midnight.nine' }}
                >
                  Organizations
                </Typography>
                <IconButton size={`small`}>
                  <AddIcon sx={{ color: 'sky.seven' }} />
                </IconButton>
              </Box>
            </MenuItem>
            <Divider
              sx={{ margin: 'auto', color: 'midnight.one', width: '80%' }}
            />
          </Box>
        )}

        {orderedOrganizations.map((organization) =>
          OrganizationListItem({
            organization,
            onOrganizationSelected: closeMenu,
            user,
            currentOrg,
            isOrgAdmin,
            hasManageUser,
          }),
        )}

        <Divider />
        <MenuItem
          component={Tabulink}
          href={ROUTES.logout}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 2,
          }}
        >
          <Tabutton size={`small`} color={`error`} endIcon={<LogoutIcon />}>
            Sign Out
          </Tabutton>
        </MenuItem>
      </Menu>
    </>
  );
}
