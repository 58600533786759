import React, { useState } from 'react';

import { Table } from '../../../graphql/gen/graphql';
import { TableUpdate } from '../../../schema/events/table.update';
import { Verbosity } from '../TableEventsView';
import TableUpdateSchemaView from './TableUpdateSchemaView';
import UpdateChanges from './UpdateChanges';

type Props = {
  event: TableUpdate;
  table: Table;
  verbosity?: Verbosity;
};
export default function TableUpdateView({
  event,
  table,
  verbosity = Verbosity.Summary,
}: Props) {
  if (event.changes.includes('SetCurrentSchema')) {
    const metadataContext = event.context.find(
      (ctx) => ctx.type === 'TABLE_METADATA',
    );
    const oldMetadataContext = event.old_metadata;
    return (
      <TableUpdateSchemaView
        event={event}
        currentSchemaId={metadataContext?.schema_id as number}
        previousSchemaId={oldMetadataContext.schema_id as number}
        table={table}
        verbosity={verbosity}
      />
    );
  }
  return (
    <UpdateChanges key={event.id} operations={event.changes}></UpdateChanges>
  );
}
