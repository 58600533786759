import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Stack,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridCallbackDetails,
  GridColumns,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { includes, startsWith } from 'lodash';
import { useSnackbar } from 'notistack';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { InProperty, Property } from '../../graphql/gen/graphql';
import {
  deleteTableProperties,
  fetchFullTableQuery,
  updateTableProperties,
} from '../../graphql/table';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import { Tabutton } from '../Button/Tabutton';
import { Tacard } from '../Card/Tacard';
import SectionHeader from '../SectionHeader/SectionHeader';
import {
  GridEditTextField,
  GridTextField,
} from '../TableEdit/SchemaEditGridComponents';
import {
  handleRowModesModelChangeStripCellFocus,
  validatePropertiesRow,
} from '../TableEdit/TableEditHelpers';
import { UI_HANDLED_PROPERTIES } from './helpers';

type TablePropertiesPropTypes = {
  tableProperties: Property[];
  warehouseId: string;
  organizationId: string;
  orgName: string;
  orgDisplayName: string;
  userCanEdit: boolean;
  resourceType: string;
};
export interface PropertiesRow extends InProperty {
  rowId: string;
  isNew: boolean;
  key: string;
}

const TableProperties = ({
  tableProperties,
  warehouseId,
  organizationId,
  orgName,
  orgDisplayName,
  userCanEdit,
  resourceType,
}: TablePropertiesPropTypes) => {
  const gridApiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const { database, table } = useParams();
  const [editing, setEditing] = useState(false);
  const [editable, setEditable] = useState(false);
  const [deleted, setDeleted] = React.useState<string[]>([]);
  const [rows, setRows] = React.useState<GridRowModel<PropertiesRow>[]>([]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );
  const [rowsBeingEdited, setRowsBeingEdited] = React.useState<
    Record<string, boolean>
  >({});

  const [setTableProperties, { loading }] = useMutation(updateTableProperties, {
    variables: {
      warehouseId,
      database,
      table,
      contentType: resourceType?.toUpperCase(),
    },
    refetchQueries: [
      {
        query: fetchFullTableQuery,
        variables: {
          orgId: organizationId,
          orgName,
          orgDisplayName,
          warehouseId,
          database,
          tableName: table,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [deleteProperties] = useMutation(deleteTableProperties, {
    variables: {
      warehouseId,
      database,
      table,
      contentType: resourceType?.toUpperCase(),
    },
    refetchQueries: [
      {
        query: fetchFullTableQuery,
        variables: {
          orgId: organizationId,
          orgName,
          orgDisplayName,
          warehouseId,
          database,
          tableName: table,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const propList = useMemo(
    () =>
      tableProperties
        .filter(
          (prop: any) =>
            !includes(UI_HANDLED_PROPERTIES, prop.key) &&
            !startsWith(prop.key, 'lifecycle.column.'),
        )
        .map((item: any, idx: number) => ({
          ...item,
          name: item.key,
          rowId: idx + uuidv4(),
        })),
    [tableProperties],
  );

  useEffect(() => {
    setRows(propList);
  }, [tableProperties]);

  const startEditingRow = (rowId: GridRowId) => {
    if (gridApiRef.current.getRowMode(rowId) != GridRowModes.Edit) {
      gridApiRef.current.startRowEditMode({
        id: rowId,
        fieldToFocus: 'key',
      });
    }
  };

  const handleCellFocusOut = () => {};

  const handleRowClick = (params: GridRowParams) => {
    startEditingRow(params.id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setEditing(true);
    setRows(rows.filter((row) => row.rowId !== id));
    setRowsBeingEdited(({ [id]: me, ...prevState }) => {
      return { ...prevState };
    });
    const keyToDelete = rows.find((row) => row.rowId === id);
    //@ts-ignore
    setDeleted([...deleted, keyToDelete?.name]);
  };

  const handleRowModesModelChange = (
    rowModesModel: GridRowModesModel,
    details: GridCallbackDetails,
  ) => {
    handleRowModesModelChangeStripCellFocus(
      rowModesModel,
      details,
      setRowModesModel,
    );
  };

  const processRowUpdate = async (newRow: GridRowModel<PropertiesRow>) => {
    const updatedRow = {
      ...newRow,
      isNew: false,
    };

    setRows((prevState) => {
      return prevState.map((eachRow) =>
        eachRow.rowId === updatedRow.rowId ? updatedRow : eachRow,
      );
    });
    let updatedRowsBeingEdited: Record<string, any> | undefined = undefined;
    setRowsBeingEdited((prevState) => {
      const { [newRow.rowId as any]: newGuy, ...rows } = prevState;
      updatedRowsBeingEdited = rows;
      return { ...updatedRowsBeingEdited };
    });

    return updatedRow;
  };

  const handleAddColumnClick = async () => {
    setEditing(true);
    setEditable(true);
    const id = uuidv4();

    setRows((propList: any) => [
      ...propList,
      {
        __typename: 'Property',
        rowId: id,
        isNew: true,
        name: '',
        value: '',
      },
    ]);

    startEditingRow(id);
  };

  const validateProperties = async () => {
    let valid = false;
    let notValid: string[] = [];
    await rows.forEach((eachRow) => {
      if (!validatePropertiesRow(eachRow)) {
        valid = false;
        notValid.push(eachRow.rowId);
        return;
      } else {
        valid = true;
      }
    });
    return { valid, notValid };
  };

  const updateProperties = async () => {
    const validation = await validateProperties();

    if (validation.notValid.length > 0) {
      enqueueSnackbar('Table properties not valid', {
        variant: 'error',
        onEntered: () => setRows([...propList]),
      });
      return;
    }
    if (validation.notValid.length === 0 && validation.valid) {
      const propertiesToDelete = deleted.length > 0;
      if (propertiesToDelete) {
        await deleteProperties({
          variables: {
            //@ts-ignore
            properties: [...deleted],
          },
        }).catch((error) => {
          enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
            variant: 'error',
          });
        });
      }

      const newTableProperties = rows.map(
        ({ name, value }: { name: string; value: string }) => ({
          key: name,
          value,
        }),
      );

      await setTableProperties({
        variables: {
          //@ts-ignore
          properties: newTableProperties,
        },
      })
        .then((data) => {
          if (data) {
            enqueueSnackbar(
              `${
                resourceType[0]?.toUpperCase() + resourceType?.slice(1)
              } properties updated`,
              {
                variant: 'success',
              },
            );
          }
        })
        .catch((error) => {
          enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
            variant: 'error',
          });
        });
    }
  };

  const columns: GridColumns = [
    {
      field: 'name',
      flex: 1,
      editable: editable,
      renderCell: (params) => {
        const isValid = params.value !== undefined && params.value !== '';
        return (
          <>
            <Divider orientation="vertical" sx={{ height: 0.5, mr: 2 }} />
            <GridTextField
              label={!isValid ? 'Name' : null}
              tabIndex={-1}
              placeholder={'Name'}
              value={params.value}
              required
              error={!isValid}
            />
          </>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <GridEditTextField
            tabIndex={params.tabIndex}
            placeholder={'Name'}
            value={params.value}
            onChange={(event) => {
              gridApiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: event.target.value,
              });
            }}
            shouldAutoFocus={params.hasFocus}
            sx={{ p: 1, ml: 2.25, mr: 2.25, width: '100%' }}
          />
        );
      },
    },
    {
      field: 'value',
      flex: 1,
      editable: userCanEdit,
      renderCell: (params) => {
        const isValid = params.value !== undefined && params.value !== '';
        return (
          <>
            <Divider orientation="vertical" sx={{ height: 0.5, mr: 2 }} />
            <GridTextField
              label={!isValid ? 'Value' : null}
              tabIndex={-1}
              placeholder={'Value'}
              value={params.value}
              required
              error={!isValid}
            />
          </>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <FormControl
            tabIndex={-1}
            onKeyUp={(event) => {
              processRowUpdate(params.row);
            }}
            sx={{ width: '100%' }}
          >
            <GridEditTextField
              tabIndex={params.tabIndex}
              placeholder={'Value'}
              value={params.value}
              onChange={(event) => {
                setEditing(true);
                gridApiRef.current.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: event.target.value,
                });
              }}
              fullWidth
              shouldAutoFocus={params.hasFocus}
              sx={{ p: 1, ml: 2.25, mr: 2.25 }}
            />
          </FormControl>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 20,
      cellClassName: 'actions',
      align: 'left',
      editable: userCanEdit,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            placeholder
            key="delete"
            icon={<CloseIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            sx={{ color: userCanEdit ? 'sunset.seven' : 'midnight.two' }}
            disabled={!userCanEdit}
          />,
        ];
      },
    },
  ];

  function CustomFooter() {
    // Keep track of propList separately to display cancelled changes correctly
    const tablePropertyList = useMemo(
      () =>
        tableProperties
          .filter(
            (prop: any) =>
              !includes(UI_HANDLED_PROPERTIES, prop.key) &&
              !startsWith(prop.key, 'lifecycle.column.'),
          )
          .map((item: any, idx: number) => ({
            ...item,
            name: item.key,
            rowId: idx + uuidv4(),
          })),
      [tableProperties],
    );

    return (
      <CardActions
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.midnight.two}`,
          display: 'flex',
          justifyContent: 'space-between',
        })}
      >
        <Box display={'flex'}>
          <Tabutton
            onClick={() => handleAddColumnClick()}
            disabled={!userCanEdit}
            endIcon={<AddIcon />}
          >
            {`Add a ${resourceType} property`}
          </Tabutton>
        </Box>
        <Box>
          <Tabutton
            onClick={() => {
              setRows([...tablePropertyList]);
              setDeleted([]);
              setEditing(false);
            }}
            disabled={!editing}
          >
            Cancel
          </Tabutton>
          <Tabutton
            loadingBtn
            onClick={async () => {
              setEditing(false);
              await updateProperties();
            }}
            variant={`contained`}
            disabled={!editing}
            loading={loading}
          >
            Apply Changes
          </Tabutton>
        </Box>
      </CardActions>
    );
  }

  return (
    <Tacard>
      <Box>
        <Typography sx={{ p: 2 }} variant={`h5`}>
          User managed
        </Typography>
        <SectionHeader>
          <Typography
            variant={`h6`}
            display={'inline-flex'}
            alignItems={'center'}
          >
            {`Misc. ${resourceType} properties`}
          </Typography>
        </SectionHeader>
      </Box>
      <CardContent
        sx={{
          p: 0,
          m: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <DataGridPro
          autoHeight
          apiRef={gridApiRef}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          headerHeight={0}
          rowHeight={64}
          onRowClick={handleRowClick}
          onCellFocusOut={handleCellFocusOut}
          components={{
            Footer: CustomFooter,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {`No misc ${resourceType} properties`}
              </Stack>
            ),
          }}
          sx={{
            border: 'none',
            '& .MuiDataGrid-columnHeaders': {
              border: 'none',
              backgroundColor: 'white !important',
            },
            '& .MuiDataGrid-cellContent': {
              backgroundColor: 'controlBackground.half',
              width: '100%',
              px: 1,
              py: 1,
              fontFamily: 'Source Sans Pro',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '18px',
              letterSpacing: '0.25px',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
            },
          }}
          disableSelectionOnClick={true}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(row) => row?.rowId}
          initialState={{}}
        />
      </CardContent>
    </Tacard>
  );
};

export { TableProperties };
