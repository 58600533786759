import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  LinearProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';

import {
  AuthDecisionResourceType,
  Authorization,
  InFlightCascadeTaskResult,
  Privilege,
} from '../../graphql/gen/graphql';
import { AccessButton } from '../Button/AccessButtonGroup';
import { Tabutton } from '../Button/Tabutton';
import { configureButtonGroups } from './ButtonConfiguration';
import PrivilegeSelector from './PrivilegeSelector';
import { getCascadeDescription } from './RoleHelpers';
import { AuthWithGrant } from './common';

type RolePrivilegesProps = {
  privileges: Authorization[];
  accessType: string;
  roleName: string;
  selectedRoleId?: string;
  grantingNewAccess: boolean;
  onCancelNewAccess?: () => void;
  handleApplyChanges?: any;
  isResourceAdmin: boolean;
  currentGrants: AuthWithGrant[];
  currentlyInFlight?: InFlightCascadeTaskResult;
  isSecurityAdmin: boolean;
  securityAdminCard?: boolean;
};

export type PrivilegeSelected = {
  authorizationId?: string;
  roleId?: string;
  resourceId?: string;
  resourceType?: string;
  privilege: Privilege;
  groupingName: string;
  selected?: boolean;
  withGrant: boolean;
  group?: number;
  level?: number;
};
export default function RolePrivileges({
  privileges,
  accessType,
  roleName,
  selectedRoleId,
  grantingNewAccess,
  onCancelNewAccess = () => {},
  handleApplyChanges,
  isResourceAdmin,
  currentGrants,
  currentlyInFlight,
  isSecurityAdmin,
  securityAdminCard,
}: RolePrivilegesProps) {
  const [cascade, setCascade] = useState(false);
  const [privsUpdated, setPrivsUpdated] = useState(false);
  const [createPrivilege, setCreatePrivilege] = useState<PrivilegeSelected[]>(
    [],
  );

  const [updatePrivilege, setUpdatePrivilege] = useState<PrivilegeSelected[]>(
    [],
  );
  const [deletePrivilege, setDeletePrivilege] = useState<string[]>([]);
  const smallScreenView = useMediaQuery('(max-width: 600px)');

  const initialPrivileges = !grantingNewAccess
    ? privileges?.map((priv: Authorization) => ({
        authorizationId: priv.id,
        privilege: priv.privilege,
        resourceType: priv.resourceType,
        resourceId: priv.resource,
        roleId: securityAdminCard ? selectedRoleId : priv.roleId,
        withGrant: priv.withGrant,
      }))
    : ([] as any);

  const [currentPrivileges, setCurrentPrivileges] =
    useState<PrivilegeSelected[]>(initialPrivileges);

  const configuredButtonGroup = configureButtonGroups(
    currentPrivileges,
    accessType,
    '',
    true,
  );

  const handlePrivChange = (
    event: PrivilegeSelected,
    withGrant?: boolean,
    multiple?: boolean,
  ) => {
    const privForUpdateOrDelete = currentPrivileges.map(
      (priv: PrivilegeSelected) => priv.privilege === event.privilege,
    );

    const sliderEffectPrivs = multiple && !privForUpdateOrDelete.includes(true);
    const createNewPriv = !multiple && !privForUpdateOrDelete.includes(true);
    const removePriv =
      !multiple && privForUpdateOrDelete.includes(true) && !withGrant;
    const updatePriv =
      !multiple && privForUpdateOrDelete.includes(true) && withGrant;

    if (sliderEffectPrivs) {
      const includeAllLowers = configuredButtonGroup[event.group].filter(
        //@ts-ignore
        (priv: PrivilegeSelected) => priv.level <= event.level,
      );

      const newPrivsIncluded = [...currentPrivileges, ...includeAllLowers];
      //Keeping track of UI state
      setCurrentPrivileges(newPrivsIncluded);

      //Keeping track for API
      const privsToCreate =
        createPrivilege.length > 0
          ? [...createPrivilege, ...includeAllLowers]
          : [...includeAllLowers];
      setCreatePrivilege(privsToCreate);
      setPrivsUpdated(true);
    }

    if (createNewPriv) {
      const newPrivIncluded = [...currentPrivileges, event];
      //Keeping track of UI state
      setCurrentPrivileges(newPrivIncluded);
      setPrivsUpdated(true);

      const privExistsCurrently = privileges.find(
        (priv: any) => priv.privilege === event.privilege,
      );

      if (privExistsCurrently === undefined) {
        const privsToCreate =
          createPrivilege.length > 0 ? [...createPrivilege, event] : [event];
        //Keeping track for API
        setCreatePrivilege(privsToCreate);
        setPrivsUpdated(true);
      }

      if (deletePrivilege.includes(event.privilege)) {
        const indexOfDeleted = deletePrivilege.indexOf(event.privilege);
        deletePrivilege.splice(indexOfDeleted, 1);
        setDeletePrivilege([...deletePrivilege]);
      }
    }

    if (removePriv) {
      const indexOfUpdate = currentPrivileges.findIndex(
        (priv: PrivilegeSelected) => priv.privilege === event.privilege,
      );
      currentPrivileges.splice(indexOfUpdate, 1);
      const privUpdated = [...currentPrivileges];
      //Keeping track of UI state
      setCurrentPrivileges(privUpdated);
      setPrivsUpdated(true);

      const privExistsCurrently = privileges.find(
        (priv: any) => priv.privilege === event.privilege,
      );

      if (privExistsCurrently === undefined) {
        const indexOfCreated = createPrivilege.findIndex(
          (priv: PrivilegeSelected) => priv.privilege === event.privilege,
        );
        createPrivilege.splice(indexOfCreated, 1);
        //Keeping track for API
        setCreatePrivilege([...createPrivilege]);
        setPrivsUpdated(true);
      } else {
        const privsToDelete =
          deletePrivilege.length > 0
            ? [...deletePrivilege, event.privilege]
            : [event.privilege];
        //Keep track for API
        setDeletePrivilege(privsToDelete);
        setPrivsUpdated(true);
      }
    }

    if (updatePriv) {
      const createHasBeenUpdated = createPrivilege.map(
        (priv: PrivilegeSelected) => priv.privilege === event.privilege,
      );

      if (createHasBeenUpdated.includes(true)) {
        const indexOfCurrentCreated = currentPrivileges.findIndex(
          (priv: PrivilegeSelected) => priv.privilege === event.privilege,
        );
        const indexOfNewlyCreated = createHasBeenUpdated.indexOf(true);
        currentPrivileges.splice(indexOfCurrentCreated, 1);
        createPrivilege.splice(indexOfNewlyCreated, 1);

        const privUpdated = [...currentPrivileges, event];
        const createUpdated = [...createPrivilege, event];
        //Keeping track of both
        setCurrentPrivileges(privUpdated);
        setCreatePrivilege(createUpdated);
        setPrivsUpdated(true);
      } else {
        const indexOfUpdate = currentPrivileges.findIndex(
          (priv: PrivilegeSelected) => priv.privilege === event.privilege,
        );
        currentPrivileges.splice(indexOfUpdate, 1);
        const privUpdated = [...currentPrivileges, event];
        const privsToUpdate =
          updatePrivilege.length > 0 ? [...updatePrivilege, event] : [event];
        //Keeping track of both
        setCurrentPrivileges(privUpdated);
        setUpdatePrivilege(privsToUpdate);
        setPrivsUpdated(true);
      }
    }
  };
  const grantNewApply = () => {
    handleApplyChanges(currentPrivileges, selectedRoleId, roleName, cascade);
  };
  const apply = () => {
    handleApplyChanges(
      currentPrivileges,
      privileges,
      createPrivilege,
      updatePrivilege,
      deletePrivilege,
      cascade,
      selectedRoleId,
      roleName,
      grantingNewAccess,
    );
  };
  const handleCascade = () => {
    setCascade(!cascade);
    setPrivsUpdated(true);
  };

  const hasManageGrants =
    currentGrants?.some((priv) => priv.privilege === Privilege.ManageGrants) ||
    isSecurityAdmin;

  const cascadeEnabled =
    accessType === AuthDecisionResourceType.Database ||
    accessType === AuthDecisionResourceType.Warehouse;

  const hasCascadeCheckbox = cascadeEnabled && hasManageGrants;

  const minimumPrivileges = securityAdminCard
    ? false
    : currentPrivileges?.length < 1;
  return (
    <>
      {configuredButtonGroup.map(
        (buttonGroup: AccessButton[], index: number) => {
          buttonGroup.forEach((privilege) => {
            const matching = currentGrants?.map(
              (priv) => priv.privilege === privilege.privilege,
            );
            const indexOfPrivilege = matching?.indexOf(true) || 0;
            const existingPrivilege =
              currentGrants && currentGrants[indexOfPrivilege];

            privilege['hasAbilityToGrantPriv'] =
              existingPrivilege?.withGrant ||
              hasManageGrants ||
              isSecurityAdmin;
          });
          const groupName =
            accessType === AuthDecisionResourceType.View
              ? 'View'
              : buttonGroup[0].groupingName || '';
          return (
            <PrivilegeSelector
              key={`${selectedRoleId} ${index}`}
              accessButtons={buttonGroup}
              groupName={groupName}
              handlePrivChange={handlePrivChange}
              grantingNewAccess={grantingNewAccess}
              securityAdminCard={securityAdminCard}
              roleId={selectedRoleId}
            />
          );
        },
      )}
      <Box
        height={'48px'}
        paddingLeft={smallScreenView ? 0 : 1}
        display="flex"
        alignItems={'center'}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.midnight.two}`,
          marginTop: 1,
          [theme.breakpoints.down('sm')]: {
            ...(hasCascadeCheckbox && {
              height: '84px',
              justifyContent: 'flex-start',
            }),
          },
        })}
      >
        {isResourceAdmin && (
          <Box
            sx={(theme) => ({
              display: 'flex',
              width: '100%',
              justifyContent: hasCascadeCheckbox ? 'space-between' : 'flex-end',

              [theme.breakpoints.down('sm')]: {
                ...(hasCascadeCheckbox && {
                  flexDirection: 'column',
                  justifyItems: 'center',
                }),
              },
            })}
          >
            {cascadeEnabled && currentlyInFlight && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography variant={`helperText`} sx={{ mb: 1 }}>
                  {`Privileges currently being applied to ${
                    currentlyInFlight?.tablesTotalCount
                  } tables: ${Math.round(
                    currentlyInFlight?.progress
                      ? currentlyInFlight.progress * 100
                      : 0,
                  )}% complete.`}
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={Math.round(
                      currentlyInFlight?.progress
                        ? currentlyInFlight?.progress * 100
                        : 0,
                    )}
                  />
                </Box>
              </Box>
            )}
            {hasCascadeCheckbox && !currentlyInFlight && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: 0,
                }}
              >
                <Checkbox
                  checked={cascade}
                  onChange={handleCascade}
                  sx={{
                    '&.Mui-checked': { color: 'aurora.five' },
                  }}
                />
                <Typography variant={`inputLabel`} sx={{ fontWeight: 600 }}>
                  {getCascadeDescription(accessType)}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mx: 2,
              }}
            >
              <Tabutton
                size={smallScreenView ? 'small' : 'medium'}
                onClick={() => {
                  if (grantingNewAccess) {
                    onCancelNewAccess();
                  }
                  //@ts-ignore
                  setCurrentPrivileges([...privileges]);
                  setPrivsUpdated(false);
                }}
                disabled={!privsUpdated && !grantingNewAccess}
                sx={{ maxHeight: 35, mr: 1 }}
              >
                CANCEL
              </Tabutton>

              <Tabutton
                onClick={() => {
                  grantingNewAccess ? grantNewApply() : apply();
                  setPrivsUpdated(false);
                }}
                size={smallScreenView ? 'small' : 'medium'}
                variant={'contained'}
                disabled={!privsUpdated || minimumPrivileges}
                sx={{ whiteSpace: 'nowrap', maxHeight: 35 }}
              >
                APPLY CHANGES
              </Tabutton>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
