import React, { useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { FormikValues } from 'formik';
import { isEmpty } from 'lodash';

import {
  MirrorTarget,
  StorageType,
  Warehouse,
} from '../../../graphql/gen/graphql';
import {
  createSnowflakeConnectionMutation,
  deleteSnowflakeConnectionMutation,
  getMirrorStatus,
  getSnowflakeConnectionQuery,
  mirrorWarehouseMutation,
} from '../../../graphql/warehouse';
import { getLogger } from '../../../utils/logging';
import { Talert } from '../../Alert/Talert';
import { getImgSrcForStorageType } from '../../CreateStorageBucket/ValidationStatusHelpers';
import LoadingBackdrop from '../../Feedback/LoadingBackdrop';
// @ts-ignore
import { StyledSwitch } from '../../Forms/Switch';
import { CustomIcon } from '../../Icons/CustomIcon';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../WizardContainer';
import { SnowflakeCmdTemplate } from './SnowflakeCmdTemplate';
import { SnowflakeDetails } from './SnowflakeDetails';
import { SnowflakeInitialize } from './SnowflakeInitialize';
import { SnowflakePermissionSetup } from './SnowflakePermissionSetup';

const SnowflakeDialog = create<{
  warehouse: Warehouse;
  refetch: any;
}>(({ warehouse, refetch }) => {
  const modal = useModal();
  const [errorMessage, setErrorMessage] = useState('');
  const [taskId, setTaskId] = useState('');
  const [status, setStatus] = useState();
  const [mutationError, setMutationError] = useState(false);
  const logger = getLogger(
    'components.Wizards.Snowflake.SnowflakeWizardDialog',
  );
  const [mirrorWarehouse, { error: mirrorError }] = useMutation(
    mirrorWarehouseMutation,
  );
  const [getStatus, { data, error, startPolling, stopPolling }] =
    useLazyQuery(getMirrorStatus);
  const [createSnowflakeConnection] = useMutation(
    createSnowflakeConnectionMutation,
  );
  const [deleteSnowflakeConnection] = useMutation(
    deleteSnowflakeConnectionMutation,
  );

  const gcsWarehouse = useMemo(
    () => warehouse?.storageProfile?.storageType === StorageType.Gcs,
    [warehouse],
  );

  const {
    data: connectionData,
    loading: snowflakeLoading,
    refetch: snowflakeRefetch,
  } = useQuery(getSnowflakeConnectionQuery, {
    variables: {
      warehouseId: warehouse?.id,
    },
    errorPolicy: 'all',
  });

  const snowflakeEnabled = useMemo(
    () =>
      warehouse.properties?.find(
        (prop) => prop?.key === 'mirror.snowflake.enabled',
      )?.value == 'true' || false,
    [warehouse],
  );

  const closeAndRefetch = async () => {
    await refetch();
  };
  const handleSnowflakeUpdate = () => {
    return mirrorWarehouse({
      variables: {
        warehouseId: warehouse.id,
        target: MirrorTarget.Snowflake,
      },
      onCompleted: async (data) => {
        setTaskId(data.mirrorWarehouse.taskId);
      },

      onError: (error) => {
        setErrorMessage(
          error?.message?.startsWith('401:')
            ? error.message +
                '. Please ensure your Snowflake URL, user, and network policies (if applicable) have been configured correctly.'
            : error.message,
        );
      },
    });
  };

  const handleSnowflakeDelete = () => {
    return deleteSnowflakeConnection({
      variables: {
        warehouseId: warehouse.id,
      },
      onCompleted: async () => {
        await snowflakeRefetch();
        await closeAndRefetch();
      },
      onError: (error) => {
        setErrorMessage(error.message);
        setMutationError(true);
        logger.debug('error deleting snowflake connection.', error);
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(taskId)) {
      getStatus({
        variables: {
          warehouseId: warehouse.id,
          taskId: taskId,
          target: MirrorTarget.Snowflake.toLowerCase(),
        },
      });
      startPolling(1000);
    }

    if (data?.getMirrorStatus?.status === 'COMPLETE') {
      setStatus(data?.getMirrorStatus?.status);
      stopPolling();
      closeAndRefetch();
    }

    if (data?.getMirrorStatus?.status === 'ERROR') {
      setErrorMessage('An error has occurred please try again.');
      stopPolling();
    }

    if (error) {
      setErrorMessage(
        'Error syncing data to Snowflake, there may be an existing data set.',
      );
      stopPolling();
    }
  }, [taskId, data, error]);

  const handleSnowflakeConnection = async (values: FormikValues) => {
    return await createSnowflakeConnection({
      variables: {
        warehouseId: warehouse.id,
        accountUrl: values.createAccountUrl.trim(),
        user: values.createSnowflakeUser.trim(),
        role: values.createSnowflakeRole.trim(),
        database: values.createSnowflakeDatabase?.trim(),
      },
      onCompleted: () => {
        snowflakeRefetch();
        closeAndRefetch();
      },
      onError: (error) => {
        setErrorMessage(error.message);
        setMutationError(true);
        logger.debug('error creating snowflake connection.', error);
      },
    });
  };

  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: !connectionData?.getSnowflakeConnection?.accountUrl
          ? 'Snowflake setup'
          : 'Connection Details',
        body: snowflakeLoading ? (
          <LoadingBackdrop />
        ) : !connectionData?.getSnowflakeConnection?.accountUrl ? (
          <SnowflakeInitialize
            handleSnowflakeConnection={handleSnowflakeConnection}
            connectionData={connectionData}
            warehouseId={warehouse?.id}
            error={mutationError}
          />
        ) : (
          <SnowflakeDetails
            connectionData={connectionData?.getSnowflakeConnection}
            handleSnowflakeDelete={handleSnowflakeDelete}
            error={mutationError}
            snowflakeEnabled={snowflakeEnabled}
          />
        ),
        buttonText: 'Continue',
        disallowContinue:
          mutationError || !connectionData?.getSnowflakeConnection?.accountUrl,
      },

      {
        title: 'Snowflake Configuration',
        body: (
          <Stack>
            <SnowflakeCmdTemplate
              warehouse={warehouse}
              snowflakeUser={connectionData?.getSnowflakeConnection?.user}
              snowflakeRole={connectionData?.getSnowflakeConnection?.role}
              externalVolumeId={
                connectionData?.getSnowflakeConnection?.externalVolumeId
              }
              catalogIntegrationId={
                connectionData?.getSnowflakeConnection?.catalogIntegrationId
              }
              publicKey={connectionData?.getSnowflakeConnection?.publicKey}
            />
          </Stack>
        ),

        buttonText: 'Continue',
        disallowContinue: mutationError,
      },

      {
        title: 'Setup permissions',
        titleIcon: getImgSrcForStorageType(
          warehouse?.storageProfile?.storageType || StorageType.S3,
        ),
        body: (
          <SnowflakePermissionSetup
            warehouse={warehouse}
            gcsWarehouse={gcsWarehouse}
            connectionData={connectionData}
          />
        ),
        nextButtonEffect: () => handleSnowflakeUpdate(),
        buttonText: 'Sync Snowflake',
        disallowContinue: snowflakeEnabled,
      },
      {
        title: 'Test connection',
        body: (
          <>
            <Box sx={{ width: 0.8 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Typography variant={`subtitle1`} sx={{ mb: 1 }}>
                  Mirroring {warehouse.name} to Snowflake
                </Typography>
                <Box sx={{ width: '100%' }}>
                  {status !== 'COMPLETE' && !error && (
                    <Talert
                      color={'neutral'}
                      sx={{ my: 2, width: 'fit-content' }}
                    >
                      Watching for Snowflake sync status
                    </Talert>
                  )}
                  {status === 'COMPLETE' && (
                    <Talert
                      severity="success"
                      sx={{ my: 2, width: 'fit-content' }}
                    >
                      Successful connection detected!
                    </Talert>
                  )}
                  {(error || mirrorError) && (
                    <Talert
                      severity={'error'}
                      sx={{ my: 2, width: 'fit-content' }}
                    >
                      {errorMessage}
                    </Talert>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        ),
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      sx={{ minHeight: '550px', width: 1, maxHeight: '98vh' }}
    >
      {' '}
      <WizardContainer
        title={'Snowflake'}
        stepsTitle={'Snowflake setup'}
        backButtonClick={() => {}}
        stepsIcon={
          <CustomIcon
            src={'/assets/img/logos/snowflake.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        stepsSubtitle={warehouse?.name}
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        sx={{ minHeight: '550px', width: 1, height: '98vh' }}
      />
    </Dialog>
  );
});

export function showSnowflakeDialog({
  warehouse,
  refetch,
}: {
  warehouse: Warehouse;
  refetch: any;
}) {
  return show(SnowflakeDialog, {
    warehouse,
    refetch,
  });
}
