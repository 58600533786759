import { gql } from '@apollo/client';

export const searchLabelsQuery = gql`
  query SearchLabels(
    $organizationId: String!
    $query: String!
    $maxResults: Int
    $searchAfter: [String]
    $sortBy: [SortByInput]
    $privilegeFilter: [String]
    $resourceTypeFilter: [LabelResourceType]
  ) {
    searchLabels(
      organizationId: $organizationId
      query: $query
      maxResults: $maxResults
      searchAfter: $searchAfter
      sortBy: $sortBy
      privilegeFilter: $privilegeFilter
      resourceTypeFilter: $resourceTypeFilter
    ) {
      resultSize
      totalHits
      searchAfterKeys
      results {
        labelId
        labelName
        description
        properties
        allowedResourceTypes
        rolesByPrivilege
        createdAt
        lastModified
        createdBy {
          id
          displayName
          userId
          email
          properties
          admin
        }
        lastModifiedBy {
          id
          displayName
          userId
          email
          properties
          admin
        }
      }
    }
  }
`;

export const createLabel = gql`
  mutation CreateLabel(
    $organizationId: String!
    $request: CreateLabelRequest!
  ) {
    createLabel(organizationId: $organizationId, request: $request) {
      id
      name
    }
  }
`;

export const updateLabel = gql`
  mutation UpdateLabel(
    $organizationId: String!
    $labelId: String!
    $request: UpdateLabelRequest!
  ) {
    updateLabel(
      organizationId: $organizationId
      labelId: $labelId
      request: $request
    ) {
      id
      name
    }
  }
`;

export const labelFieldMasking = gql`
  mutation LabelFieldMasking(
    $organizationId: String!
    $labelId: String!
    $request: UpdateLabelMaskingRequest!
  ) {
    updateLabelFieldMasking(
      organizationId: $organizationId
      labelId: $labelId
      request: $request
    ) {
      id
      name
    }
  }
`;

export const deleteLabel = gql`
  mutation DeleteLabel($organizationId: String!, $labelId: String!) {
    deleteLabel(organizationId: $organizationId, labelId: $labelId)
  }
`;

export const labelAuthorizations = gql`
  mutation LabelAuthorizations(
    $authsToCreate: [CreateAuthInput]
    $authsToDelete: [CreateAuthInput]
    $authsToUpdate: [UpdateAuthInput]
  ) {
    handleLabelAuthMutations(
      authsToCreate: $authsToCreate
      authsToDelete: $authsToDelete
      authsToUpdate: $authsToUpdate
    )
  }
`;

export const searchApplyLabelsQuery = gql`
  query SearchApplyLabels(
    $organizationId: String!
    $query: String!
    $searchAfter: [String]
    $sortBy: [SortByInput]
    $maxResults: Int
    $privilegeFilter: [String]
    $resourceTypeFilter: [LabelResourceType]
  ) {
    searchLabels(
      organizationId: $organizationId
      query: $query
      searchAfter: $searchAfter
      sortBy: $sortBy
      maxResults: $maxResults
      privilegeFilter: $privilegeFilter
      resourceTypeFilter: $resourceTypeFilter
    ) {
      resultSize
      totalHits
      searchAfterKeys
      results {
        labelId
        labelName
        description
        properties
      }
    }
  }
`;

export const getLabelById = gql`
  query label($organizationId: String!, $labelId: String!) {
    label(organizationId: $organizationId, labelId: $labelId) {
      id
      organizationId
      name
      description
      allowedResourceTypes
      properties
      authorizations {
        id
        privilege
        resource
        resourceType
        subjectId
        withGrant
      }
    }
  }
`;
