import React from 'react';

import { Table } from '../../../graphql/gen/graphql';
import { Event } from '../../../schema/events/event';
import { SucceededFilesCleanup } from '../../../schema/events/succeeded.files-cleanup';
import { TableEventTypes, Verbosity } from '../TableEventsView';

type Props = {
  event: Event;
  table: Table;
  verbosity?: Verbosity;
};
export default function FilesCleanupView({
  event,
  table,
  verbosity = Verbosity.Summary,
}: Props) {
  let filesCleanupMessage = (function () {
    switch (event.type) {
      case TableEventTypes.RUN_FILES_CLEANUP:
        return 'Scheduled';
      case TableEventTypes.STARTED_FILES_CLEANUP:
        return 'Started';
      case TableEventTypes.SUCCEEDED_FILES_CLEANUP:
        return 'Succeeded';
      default:
        return 'Failed';
    }
  })();

  switch (verbosity) {
    case Verbosity.Summary:
      let succeededFilesCleanup;
      if (event.type == TableEventTypes.SUCCEEDED_FILES_CLEANUP) {
        succeededFilesCleanup = event as SucceededFilesCleanup;
      }

      return (
        <div>
          Table Orphan Files Cleanup {filesCleanupMessage}.
          {succeededFilesCleanup && (
            <span>
              {' '}
              Files removed: {succeededFilesCleanup.orphan_file_count}
            </span>
          )}
        </div>
      );
    default:
      return null;
  }
}
