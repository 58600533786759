import { differenceInDays, differenceInHours } from 'date-fns';
import { format } from 'date-fns-tz';

const relativeTimeFormatter = new Intl.RelativeTimeFormat();

export const relativeTimeAutoFormat = (timestamp: string | number | Date) => {
  const dt = new Date(timestamp) as any;
  const now = new Date() as any;

  let delta = (dt - now) / 1000;
  let unit = 'seconds';

  const units = [
    ['minutes', 60],
    ['hours', 60],
    ['days', 24],
  ];

  for (const [intervalName, duration] of units) {
    if (Math.abs(delta) > duration) {
      //@ts-ignore
      unit = intervalName;
      //@ts-ignore
      delta /= duration;
    } else {
      break;
    }
  }
  //@ts-ignore
  return relativeTimeFormatter.format(Math.round(delta), unit);
};

export const getDifferenceInDays = (date1: Date, date2: Date) => {
  return differenceInDays(date1, date2);
};

export const getDifferenceInHours = (date1: Date, date2: Date) => {
  return differenceInHours(date1, date2);
};

export const formatTimestamp = (time: string | number | Date) => {
  return format(new Date(time), 'EEE, MMM d, yyyy (z) h:mm a');
};

export const formatDateToMonDayYear = (date: string | number | Date) => {
  return format(new Date(date), 'MMM-d-yyyy');
};
