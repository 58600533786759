import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';

import {
  StorageProfile,
  StorageType,
  Warehouse,
} from '../../graphql/gen/graphql';
import { TileProps } from '../TileSelector/Tile';
import TileSelector from '../TileSelector/TileSelector';
import BucketConfiguration from './BucketConfiguration';
import { getImgSrcForStorageType } from './ValidationStatusHelpers';

export default function ChooseBucketType({
  onCancel,
  orgName,
  onStorageProfileCreated,
  warehouseName,
  preSelectedBucketType,
}: {
  onCancel: () => void;
  orgName: string;
  onStorageProfileCreated: (storageProfile: StorageProfile) => void;
  warehouseName?: string;
  preSelectedBucketType?: string;
}) {
  const [bucketType, setBucketType] = useState(
    preSelectedBucketType ? preSelectedBucketType : '',
  );
  const tiles: TileProps[] = [
    {
      id: 's3',
      logoImgUrl: getImgSrcForStorageType(StorageType.S3),
      logoAlt: 'S3',
      handleButtonClick: () => {
        setBucketType(StorageType.S3);
      },
      body: (
        <>
          <Box>
            <Typography variant={'subtitle1'}>Amazon S3</Typography>
          </Box>
        </>
      ),
    },
    {
      id: 'gcs',
      logoImgUrl: getImgSrcForStorageType(StorageType.Gcs),
      logoAlt: 'GCS',
      handleButtonClick: () => {
        setBucketType(StorageType.Gcs);
      },
      body: (
        <>
          <Box>
            <Typography variant={'subtitle1'}>Google cloud storage</Typography>
          </Box>
        </>
      ),
    },
    {
      id: 'adls',
      logoImgUrl: getImgSrcForStorageType(StorageType.Adls),
      logoAlt: 'Microsoft Azure',
      handleButtonClick: () => {
        setBucketType(StorageType.Adls);
      },
      body: (
        <>
          <Box>
            <Typography variant={'subtitle1'}>
              Microsoft Azure cloud storage
            </Typography>
          </Box>
        </>
      ),
    },
  ];

  return (
    <>
      {!bucketType && (
        <TileSelector
          tiles={tiles}
          onCancel={onCancel}
          title={'Select object store location'}
        />
      )}
      {bucketType && (
        <BucketConfiguration
          onCancel={onCancel}
          orgName={orgName}
          onStorageProfileCreated={(storage) => {
            onStorageProfileCreated(storage);
          }}
          warehouseName={warehouseName}
          type={bucketType}
        />
      )}
    </>
  );
}
