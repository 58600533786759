import React, { useEffect, useRef, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  GridProps,
} from '@mui/material';

import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import { CustomIcon } from '../Icons/CustomIcon';

export interface WizardStep {
  id?: string; //used for more complicated wizards
  nextId?: string; //used for more complicated wizards
  title: string;
  titleIcon?: string;
  subTitle?: string;
  description?: string;
  body: JSX.Element;
  disallowContinue?: boolean;
  effect?: () => void;
  nextButtonEffect?: () => void;
  buttonText?: string;
  footerContent?: JSX.Element;
  subHeader?: JSX.Element;
}
interface WizardProps extends GridProps {
  title: string;
  steps: Map<string, WizardStep[]>;
  closeAction: JSX.Element;
  stepsTitle: string;
  stepsSubtitle?: string;
  stepsIcon?: JSX.Element;
  backButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose?: () => void;
  customStartStep?: number;
}

export const WIZARD_START_KEY = 'start';

export default function WizardContainer({
  title,
  backButtonClick,
  closeAction,
  handleClose,
  stepsTitle,
  stepsSubtitle,
  stepsIcon,
  steps,
  customStartStep,
  ...props
}: WizardProps) {
  const [currentStep, setCurrentStep] = useState(
    customStartStep ? customStartStep : 0,
  );
  const [currentBranchKey, setCurrentBranchKey] =
    useState<string>(WIZARD_START_KEY);
  const [effectError, setEffectError] = useState<string>();
  const wizardHeader = useRef();
  const currentBranch: WizardStep[] = steps.get(currentBranchKey) || [];
  const currentWizardStep: WizardStep = currentBranch[currentStep];

  useEffect(() => {
    if (
      currentWizardStep &&
      currentWizardStep.effect != undefined &&
      !effectError
    ) {
      try {
        currentWizardStep.effect();
      } catch (e: any) {
        setEffectError(e.message);
      }
    }
  }, [currentStep, currentBranchKey]);

  const handleNext = () => {
    currentWizardStep.nextButtonEffect && currentWizardStep.nextButtonEffect();
    setEffectError(undefined);
    if (currentStep < currentBranch.length - 1) {
      setCurrentStep(() => currentStep + 1);
    } else {
      if (handleClose) {
        handleClose();
      }
    }
  };

  const handleBack = () => {
    setEffectError(undefined);
    setCurrentStep(() => currentStep - 1);
  };

  return (
    <Grid
      container
      sx={(theme) => ({
        boxShadow: theme.shadows[6],
        height: '98vh',
      })}
      style={{ overflow: 'hidden' }}
      spacing={0}
      {...props}
    >
      <Grid
        item
        xs={12}
        md={2}
        sx={{
          pt: 7,
          pb: 5,
          backgroundColor: 'dusk.six',
          color: 'primary.contrastText',
          display: {
            xs: 'none',
            md: 'flex',
            lg: 'flex',
            justifyContent: 'center',
          },
        }}
      >
        <Box sx={{ width: 1, ml: 3, mr: 3, pl: 2 }}>
          {stepsIcon && (
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: '16px',
                width: 80,
                height: 80,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 2,
              }}
            >
              {stepsIcon}
            </Box>
          )}
          <Box display={`flex`} flexDirection={`column`} marginBottom={3}>
            <Typography variant={'h6'} sx={{ wordBreak: 'break-word' }}>
              {stepsTitle}
            </Typography>
            {stepsSubtitle && (
              <Typography variant={`subtitle2`} color={`dusk.three`}>
                {stepsSubtitle}
              </Typography>
            )}
          </Box>
          <Stepper
            activeStep={currentStep}
            orientation="vertical"
            sx={(theme) => ({
              '& .MuiStepLabel-root .Mui-completed': {
                color: 'dusk.three',
              },
              '& .MuiStepLabel-labelContainer': {
                color: 'dusk.three',
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'dusk.three',
                },
              '& .MuiStepLabel-root .Mui-active': {
                color: 'primary.contrastText',
                fill: theme.palette.common.white,
              },
              '& .MuiStepIcon-root': {
                fill: theme.palette.dusk.three,
              },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                  color: 'common.white',
                },
              '& .MuiStepLabel-root .MuiStepIcon-text': {
                fill: theme.palette.dusk.six,
                fontSize: '14px',
                fontWeight: 700,
              },
            })}
          >
            {currentBranch.map((step, index) => (
              <Step key={step.title}>
                <StepLabel>
                  <Typography>{step.title}</Typography>
                </StepLabel>
                <StepContent>
                  {step.description && (
                    <Typography>{step.description}</Typography>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={10}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.midnight.two}`,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        })}
      >
        <Box
          sx={{
            minHeight: '400px',
            width: 1,
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 auto',
            '& ::-webkit-scrollbar': {
              width: '8px',
            },
            '& ::-webkit-scrollbar-track': {
              backgroundColor: 'midnight.one',
            },
            '& ::-webkit-scrollbar-thumb': {
              borderRadius: '10px',
              backgroundColor: 'midnight.two',
            },
          }}
        >
          <Box
            ref={wizardHeader}
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: `1px solid ${theme.palette.midnight.two}`,
            })}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'column', pl: 4, py: 3 }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: 'midnight.nine',
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                }}
              >
                {currentWizardStep.title}
                {currentWizardStep.titleIcon && (
                  <CustomIcon
                    src={currentWizardStep.titleIcon}
                    sx={{
                      ml: 2,
                      height: 32,
                      width: 32,
                      padding: 0,
                    }}
                  />
                )}
              </Typography>
              {currentWizardStep.subTitle && (
                <Typography
                  variant="subtitle1"
                  sx={{ color: 'dusk.six', mt: 1 }}
                >
                  {currentWizardStep.subTitle}
                </Typography>
              )}
            </Box>
            {closeAction}
          </Box>

          {currentWizardStep?.subHeader && currentWizardStep.subHeader}

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {effectError && <Talert severity="error">{effectError}</Talert>}
          </Box>

          <Box
            sx={{
              width: 0.95,
              flexDirection: 'column',
              display: 'flex',
              mx: 4,
              mb: 0,
              mt: 2,
              height: '100%',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: 'auto',
              }}
            >
              {currentWizardStep.body}
            </Box>
          </Box>

          {/*  Footer */}
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-between',
              p: 2,
              flex: '0 0 auto',
              borderTop: `1px solid ${theme.palette.midnight.two}`,
              backgroundColor: 'white',
            })}
          >
            <Box>
              {currentStep !== 0 && (
                <Tabutton
                  disabled={currentStep === 0}
                  onClick={handleBack}
                  sx={{
                    minWidth: 135,
                    display: currentStep === 0 ? 'none' : '',
                  }}
                >
                  <ArrowBackIcon />
                  Back
                </Tabutton>
              )}
            </Box>
            <Box display={`flex`} alignItems={`center`}>
              {currentWizardStep?.footerContent &&
                currentWizardStep.footerContent}
              <Tabutton onClick={handleClose} sx={{ mr: 1, minWidth: 135 }}>
                Cancel
              </Tabutton>
              <Tabutton
                variant="contained"
                onClick={handleNext}
                disabled={currentWizardStep.disallowContinue}
                sx={{ mr: 1, minWidth: 135 }}
              >
                {currentStep === currentBranch.length - 1
                  ? 'Finish'
                  : currentWizardStep.buttonText || 'Continue'}
              </Tabutton>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
