import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import { ClickAwayListener, Popper } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import {
  KnownFeatureFlags,
  MainTabGroup,
} from '../../context/GlobalPropsContext';

import {
  toMembers,
  toServices,
  toStorage,
  toWarehouses,
} from '../../RouteTable';
import { GlobalPropertiesContext } from '../../context';
import { useAuth } from '../../context/auth-context';
import { User } from '../../graphql/gen/graphql';
import {
  MainNavTabs,
  MobileMainNavTabs,
  MobileMenu,
} from '../NavTabs/MainNavTabs';
import { getNavTabs, getNavTabsDropdownContent } from '../NavTabs/helpers';

export interface MainNavTabItem {
  tab: JSX.Element;
  tabGroup: MainTabGroup;
  href: string;
  dropdown: JSX.Element;
}
type Props = {
  mobileNav?: boolean;
};

interface UserProps extends User {
  isSecurityAdmin: (orgName: string) => boolean;
  isOrganizationAdmin: (orgName: string) => boolean;
}

export function TabLayoutGroupHeader({ mobileNav = false }: Props) {
  const { user }: { user: UserProps } = useAuth();
  const navigate = useNavigate();
  const { mainNavIndex, ff, registerMainNavTabItems } = useContext(
    GlobalPropertiesContext,
  );

  const [navigateToUrl, setNavigateToUrl] = useState('');
  /*
    using a separate state rn because of the difficulty with the onclick event.
    currently I can only get the "current" state of the onclick, not the tab
    we are going towards. (without digging into places I shouldn't be.  So for now
    we will hold a nav url for the change event, and one for the click event (change has priority)
    this is all to support clicking on the top nav and going to the root of that nav.
   */
  const [navigateClickUrl, setNavigateClickUrl] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverEl, setPopoverEl] = useState<null | HTMLElement>(null);
  const [popoverIndex, setPopoverIndex] = useState(0);
  const pipelinesEnabled = ff(KnownFeatureFlags.PIPELINES_ENABLED);
  const orgName = user.loginSession?.loggedInOrg?.name || 'unknown';
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const popoverEnter = (
    event: React.SyntheticEvent<HTMLDivElement>,
    position: number,
  ) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setPopoverIndex(position);
    setPopoverEl(event.currentTarget);
  };
  const popoverLeave = () => {
    timerRef.current = setTimeout(() => {
      setPopoverEl(null);
    }, 1000);
  };
  const popoverLeaveImmediate = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setPopoverEl(null);
  };
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const mainNavTabs = getNavTabs(
    mobileNav,
    user.isSecurityAdmin(orgName),
    popoverEnter,
    popoverLeave,
    user.loginSession?.loggedInOrg?.displayName || 'Organization',
    pipelinesEnabled,
  );
  const dropdownContent = getNavTabsDropdownContent(
    orgName,
    user.isSecurityAdmin(orgName),
    user.isOrganizationAdmin(orgName),
  );

  const navTabs: MainNavTabItem[] = useMemo(() => {
    return pipelinesEnabled
      ? [
          {
            tab: mainNavTabs.dataTab,
            tabGroup: MainTabGroup.DATA,
            href: toWarehouses(orgName),
            dropdown: dropdownContent.dataDropdown,
          },

          {
            tab: mainNavTabs.connectTab,
            tabGroup: MainTabGroup.CONNECTIONS,
            href: toStorage(orgName),
            dropdown: dropdownContent.connectionsDropdown,
          },

          {
            tab: mainNavTabs.pipelineTab,
            tabGroup: MainTabGroup.PIPELINES,
            href: toServices(orgName),
            dropdown: dropdownContent.pipelinesDropdown,
          },

          {
            tab: mainNavTabs.orgTab,
            tabGroup: MainTabGroup.ORG,
            href: toMembers(orgName),
            dropdown: dropdownContent.orgDropdown,
          },
        ]
      : [
          {
            tab: mainNavTabs.dataTab,
            tabGroup: MainTabGroup.DATA,
            href: toWarehouses(orgName),
            dropdown: dropdownContent.dataDropdown,
          },

          {
            tab: mainNavTabs.connectTab,
            tabGroup: MainTabGroup.CONNECTIONS,
            href: toStorage(orgName),
            dropdown: dropdownContent.connectionsDropdown,
          },

          {
            tab: mainNavTabs.orgTab,
            tabGroup: MainTabGroup.ORG,
            href: toMembers(orgName),
            dropdown: dropdownContent.orgDropdown,
          },
        ];
  }, [orgName, pipelinesEnabled]);

  useEffect(() => {
    registerMainNavTabItems(navTabs);
  }, [navTabs]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setNavigateToUrl(navTabs[newValue].href);
    },
    [navTabs, setNavigateToUrl],
  );

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>, currentIndex: number) => {
      setNavigateClickUrl(navTabs[currentIndex].href);
    },
    [setNavigateClickUrl],
  );

  useEffect(() => {
    if (navigateToUrl !== '') {
      navigate(navigateToUrl);
      setNavigateToUrl('');
      setNavigateClickUrl('');
    } else if (navigateClickUrl !== '') {
      navigate(navigateClickUrl);
      setNavigateClickUrl('');
    }
  }, [navigateToUrl, navigateClickUrl]);

  return (
    <>
      {mobileNav ? (
        <>
          <IconButton
            onClick={handleMenuClick}
            sx={{
              backgroundColor: 'transparent',
              height: '40px',
              width: '40px',
              alignSelf: 'center',
            }}
          >
            <MenuIcon sx={{ color: 'white' }} />
          </IconButton>
          <MobileMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Box
              height={'72px'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <img
                src="/assets/img/tabular-logo.svg"
                alt="tabular"
                height="40px"
              />
            </Box>
            {mainNavIndex > -1 && (
              <MobileMainNavTabs
                value={mainNavIndex}
                onChange={handleChange}
                /* @ts-ignore */
                onClick={handleClick}
                aria-label="Main Navigation"
              >
                {navTabs.map((tabObj) => tabObj.tab)}
              </MobileMainNavTabs>
            )}
          </MobileMenu>
        </>
      ) : (
        mainNavIndex > -1 && (
          <Box sx={{ position: 'relative' }}>
            <MainNavTabs
              value={mainNavIndex}
              onChange={handleChange}
              /* @ts-ignore */
              onClick={handleClick}
              aria-label="Main Navigation"
            >
              {navTabs.map((tabObj) => tabObj.tab)}
            </MainNavTabs>
            <ClickAwayListener onClickAway={popoverLeaveImmediate}>
              <Popper
                open={Boolean(popoverEl)}
                anchorEl={popoverEl}
                disablePortal
                placement={`bottom-start`}
                modifiers={[
                  {
                    name: 'preventOverflow',
                    enabled: false,
                  },
                ]}
                onMouseEnter={() =>
                  timerRef.current ? clearTimeout(timerRef.current) : null
                }
                onMouseLeave={popoverLeaveImmediate}
              >
                {navTabs[popoverIndex]?.dropdown}
              </Popper>
            </ClickAwayListener>
          </Box>
        )
      )}
    </>
  );
}
