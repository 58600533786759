import React, { useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import { useUserAgent } from 'react-ua';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useModal, create, show } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Formik } from 'formik';

import { toProfile } from '../../../RouteTable';
import { useAuth } from '../../../context/auth-context';
import { Secret, Warehouse } from '../../../graphql/gen/graphql';
import {
  createOrgCredential,
  orgCredentialUsage,
} from '../../../graphql/organization';
import { getApolloErrorMessage } from '../../../utils/ApolloUtils';
import { Talert } from '../../Alert/Talert';
import { Tabutton } from '../../Button/Tabutton';
import { CustomIcon } from '../../Icons/CustomIcon';
import { Tabulink } from '../../Link/Tabulink';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../WizardContainer';

const StarburstConnectionWizardDialog = create<{
  organizationId: string;
  organizationName: string;
  warehouse: Warehouse;
}>(({ warehouse, organizationName, organizationId }) => {
  const modal = useModal();
  const { user } = useAuth();
  const ua = useUserAgent();
  const [createOrgCredentialMutation] = useMutation(createOrgCredential);
  const [getOrgCredentialUsage, { data, loading, error, stopPolling }] =
    useLazyQuery(orgCredentialUsage);
  const [credential, setCredential] = useState<Secret>();
  const [errorStatus, setErrorStatus] = useState();
  const [credentialUsed, setCredentialUsed] = useState<boolean>(false);

  const isSecurityAdmin = user.isSecurityAdmin(organizationName);

  const createCredential = async () => {
    if (!credential && !errorStatus) {
      return createOrgCredentialMutation({
        variables: {
          organizationId: organizationId,
          name: 'Starburst Galaxy Connection Credential',
          description: 'Built with the Starburst Galaxy Connection Flow',
          external: isSecurityAdmin,
        },
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {
          if (data) {
            setCredential(data.data.createOrgCredential);
          }
        });
    }
  };

  const pollCredUsage = async () => {
    if (credential) {
      getOrgCredentialUsage({
        variables: {
          organizationId: organizationId,
          credentialKey: credential.credential.split(':')[0],
        },
        pollInterval: 2000,
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {});
    }
  };

  useEffect(() => {
    if (data?.organizationCredential?.lastSession) {
      setCredentialUsed(true);
      stopPolling();
    }
  }, [data]);

  const terminalName =
    ua.os.name === 'Windows'
      ? 'power shell'
      : ua.os.name === 'Mac OS'
      ? 'terminal'
      : 'shell';

  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: 'Starburst setup',
        body: (
          <Box>
            <Box sx={{}}>
              <Box sx={{ width: 0.95 }}>
                <Grid
                  container
                  sx={{
                    width: 1,
                    border: 1,
                    borderColor: 'midnight.two',
                    borderRadius: '8px',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1 / 2,
                      borderRadius: '8px',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottom: 1,
                      borderColor: 'midnight.two',
                      backgroundColor: 'dusk.half',
                    }}
                  >
                    <Typography variant={'body1'}>
                      {`Requires a Starburst account`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1,
                      borderBottom: 1,
                      borderColor: 'midnight.two',
                    }}
                  >
                    <Typography sx={{ display: 'inline-flex', mr: 1 }}>
                      <Tabulink
                        external
                        href={process.env.REACT_APP_STARBURST_LOG_IN_URL}
                        variant="body1"
                        rel="noopener"
                        aria-label="Login to your Starburst account"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {'Log into your Starburst account '}
                        <OpenInNewIcon
                          fontSize="small"
                          sx={{ ml: '2px', mr: '3px' }}
                        />
                      </Tabulink>
                      (instructions detailed below)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1 / 2,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography variant={'body1'}>
                      {/* @ts-ignore */}
                      <Link
                        external
                        href={process.env.REACT_APP_STARBURST_SIGN_UP_URL}
                        aria-label="Sign up with Starburst Galaxy"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',

                          textDecoration: 'none',
                        }}
                      >
                        {' '}
                        <Tabutton
                          size={`small`}
                          endIcon={<OpenInNewIcon />}
                          sx={{ mt: 2 }}
                        >
                          Sign up with Starburst Galaxy
                        </Tabutton>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mt: 4 }}>
                <Typography variant={'body1'}>
                  In Starburst Galaxy,
                  <ol>
                    <li>
                      In the Catalogs section, click <b>Create catalog</b>
                    </li>
                    <li>
                      In the Select a data source step, select the{' '}
                      <b>Tabular</b> tile
                    </li>
                    <li>
                      Come back here and click <b>CONTINUE</b>
                    </li>
                  </ol>
                </Typography>
              </Box>
            </Box>
          </Box>
        ),
      },
      {
        title: 'Create credential',

        disallowContinue: !credential,
        effect: createCredential,
        body: (
          <Formik initialValues={{}} onSubmit={() => {}}>
            <Stack sx={{ width: 0.95 }}>
              {errorStatus && (
                <Talert severity="error">
                  {getApolloErrorMessage(errorStatus)}
                </Talert>
              )}

              {credential && (
                <>
                  <Box sx={{}}>
                    <Typography variant={'body1'} sx={{ display: 'flex' }}>
                      In Starburst Galaxy, while creating your catalog, fill out
                      the Tabular configuration with the following details:
                    </Typography>
                  </Box>

                  <Grid
                    container
                    sx={{
                      width: 0.95,
                      mt: 4,
                      border: 1,
                      borderColor: 'midnight.two',
                      borderRadius: '8px',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        padding: 1,
                        borderRadius: '8px',
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottom: 1,
                        borderColor: 'midnight.two',
                        backgroundColor: 'dusk.half',
                      }}
                    >
                      <Typography
                        variant={'body1'}
                        sx={{}}
                      >{`API URL`}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        padding: 1,

                        borderBottom: 1,
                        borderColor: 'midnight.two',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pre: {
                            m: 0,
                            height: 1,
                          },
                        }}
                      >
                        <SyntaxHighlighter
                          className="ph-no-capture"
                          language="bash"
                          style={{ margin: 0 as React.CSSProperties }}
                          showLineNumbers={false}
                        >
                          {/*@ts-ignore*/}
                          {process.env.REACT_APP_REST_CATALOG_URL}
                        </SyntaxHighlighter>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        padding: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Typography variant={'body1'}>
                        {/* @ts-ignore */}
                        <Tabutton
                          onClick={() =>
                            navigator.clipboard.writeText(
                              process.env.REACT_APP_REST_CATALOG_URL!,
                            )
                          }
                          title="Copy to clipboard"
                          size={'small'}
                          sx={{ alignSelf: 'center' }}
                          endIcon={<FileCopyIcon />}
                        >
                          Copy URL
                        </Tabutton>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      width: 0.95,
                      mt: 4,
                      border: 1,
                      borderColor: 'midnight.two',
                      borderRadius: '8px',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        padding: 1,
                        borderRadius: '8px',
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottom: 1,
                        borderColor: 'midnight.two',
                        backgroundColor: 'dusk.half',
                      }}
                    >
                      <Typography
                        variant={'body1'}
                        sx={{}}
                      >{`Warehouse name`}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        padding: 1,

                        borderBottom: 1,
                        borderColor: 'midnight.two',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pre: {
                            m: 0,
                            height: 1,
                          },
                        }}
                      >
                        <SyntaxHighlighter
                          language="bash"
                          style={{ margin: 0 as React.CSSProperties }}
                          showLineNumbers={false}
                        >
                          {warehouse.name}
                        </SyntaxHighlighter>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        padding: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Typography variant={'body1'}>
                        {/* @ts-ignore */}
                        <Tabutton
                          onClick={() =>
                            navigator.clipboard.writeText(warehouse.name)
                          }
                          title="Copy to clipboard"
                          size={'small'}
                          sx={{ alignSelf: 'center' }}
                          endIcon={<FileCopyIcon />}
                        >
                          Copy Warehouse name
                        </Tabutton>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      width: 0.95,
                      mt: 4,
                      border: 1,
                      borderColor: 'midnight.two',
                      borderRadius: '8px',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        padding: 1,
                        borderRadius: '8px',
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottom: 1,
                        borderColor: 'midnight.two',
                        backgroundColor: 'dusk.half',
                      }}
                    >
                      <Typography variant={'body1'} sx={{}}>{`${
                        isSecurityAdmin ? 'Service' : 'Personal'
                      } Credential`}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        padding: 1,

                        borderBottom: 1,
                        borderColor: 'midnight.two',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          pre: {
                            m: 0,
                            height: 1,
                          },
                        }}
                      >
                        <SyntaxHighlighter
                          className="ph-no-capture"
                          language="bash"
                          style={{ margin: 0 as React.CSSProperties }}
                          showLineNumbers={false}
                        >
                          {credential.credential}
                        </SyntaxHighlighter>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        padding: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Typography variant={'body1'}>
                        {/* @ts-ignore */}
                        <Tabutton
                          onClick={() =>
                            navigator.clipboard.writeText(credential.credential)
                          }
                          title="Copy to clipboard"
                          size={'small'}
                          sx={{ alignSelf: 'center' }}
                          endIcon={<FileCopyIcon />}
                        >
                          Copy Credential
                        </Tabutton>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 1 }}>
                    <Talert
                      severity={'info'}
                      sx={{ mt: 2, width: 0.95 }}
                      alertTitle={'Please note'}
                    >
                      <Typography variant={'body1'} display={'inline-flex'}>
                        Your credentials can be managed in{' '}
                        <Tabulink
                          external
                          href={toProfile(organizationName)}
                          variant="body1"
                          rel="noopener"
                          aria-label="Tabular profile settings"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            ml: '3px',
                          }}
                        >
                          {` your profile settings.`}
                          <OpenInNewIcon
                            fontSize="small"
                            sx={{ ml: '2px', mr: '3px' }}
                          />
                        </Tabulink>
                      </Typography>
                    </Talert>
                  </Box>
                </>
              )}
            </Stack>
          </Formik>
        ),
      },

      {
        title: 'Test connection',
        effect: pollCredUsage,
        body: (
          <>
            <Box sx={{ width: 0.8 }}>
              {!credentialUsed && (
                <Talert color="neutral">
                  Watching for connection, please configure and test your new
                  catalog in Starburst Galaxy.
                </Talert>
              )}
              {credentialUsed && (
                <Talert severity="success">
                  Successful connection detected!
                </Talert>
              )}
            </Box>
          </>
        ),
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <WizardContainer
        title={'Starburst Galaxy'}
        stepsTitle={warehouse.name}
        stepsSubtitle={`Connect Starburst Galaxy`}
        stepsIcon={
          <CustomIcon
            src={'/assets/img/logos/starburst-icon-light.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        backButtonClick={() => {}}
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        sx={{ minHeight: '550px', width: 1, height: '98vh' }}
      />
    </Dialog>
  );
});

export function showStarburstConnectionWizardDialog({
  warehouse,
  organizationName,
  organizationId,
}: {
  warehouse: Warehouse;
  organizationName: string;
  organizationId: string;
}) {
  return show(StarburstConnectionWizardDialog, {
    warehouse,
    organizationName,
    organizationId,
  });
}
