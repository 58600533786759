import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { ButtonProps } from '@mui/material/Button';

import { Tabutton } from '../Button/Tabutton';

export const enum GoogleButtonText {
  signin_with = 'signin_with',
  continue_with = 'continue_with',
  signup_with = 'signup_with',
}

interface GoogleButtonProps extends ButtonProps {
  onGoogleSignIn: (credential: string) => void;
  googleButtonText?: GoogleButtonText;
  hidden?: boolean;
}

export default function GoogleAuthButton({
  onGoogleSignIn,
  googleButtonText = GoogleButtonText.signin_with,
  hidden = false,
  ...props
}: GoogleButtonProps) {
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);
  const clientId = useMemo(() => process.env.REACT_APP_GOOGLE_CLIENT_ID, []);

  const handleGoogleSignIn = useCallback(
    (res: any) => {
      if (!res.clientId || !res.credential) return;

      // Implement your login mutations and logic here.
      // Set cookies, call your backend, etc.
      onGoogleSignIn(res.credential);
    },
    [onGoogleSignIn],
  );

  useEffect(() => {
    if (gsiScriptLoaded) return;

    const initializeGsi = () => {
      // Typescript will complain about window.google
      // Add types to your `react-app-env.d.ts` or //@ts-ignore it.
      if (!(window as any).google || gsiScriptLoaded) return;

      setGsiScriptLoaded(true);
      (window as any).google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleSignIn,
      });

      (window as any).google.accounts.id.renderButton(
        document.getElementById('google-client-button'),
        {
          text: googleButtonText,
          // @ts-ignore
          ...(props?.sx?.width > 0 ? { width: props?.sx.width } : {}),
          theme: 'outline',
        }, // customization attributes
      );
    };

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = initializeGsi;
    script.defer = true;
    script.async = true;
    script.id = 'google-client-script';
    document.querySelector('body')?.appendChild(script);

    return () => {
      // Cleanup function that runs when component unmounts
      (window as any).google?.accounts.id.cancel();
      document.getElementById('google-client-script')?.remove();
    };
  }, [handleGoogleSignIn, gsiScriptLoaded]);

  return <>{clientId && <Tabutton {...props} id="google-client-button" />}</>;
}
