import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Skeleton } from '@mui/material';
import { isEmpty, concat, orderBy, compact, forEach, remove } from 'lodash';
import { useSnackbar } from 'notistack';

import { toDocsRoot } from '../../RouteTable';
import { useAuth } from '../../context/auth-context';
import {
  cascadeWarehouseAuthorizationsMutation,
  createAuthorizations,
  getAuthorizationGrants,
  handleAuthorizationMutations,
  inFlightWarehouseCascadeTasks,
  warehouseResourceAuthorizationsQuery,
} from '../../graphql/authorization';
import {
  AsyncState,
  AuthDecisionResourceType,
  AuthDecisionSubjectType,
  Authorization,
  CreateAuthInput,
  InFlightCascadeTaskResult,
  Privilege,
  UpdateAuthInput,
} from '../../graphql/gen/graphql';
import { getRoleByName } from '../../graphql/role';
import { Tabulink } from '../Link/Tabulink';
import { PageHeader } from '../PageHeader/PageHeader';
import GrantNewAccess from './GrantNewAccess';
import RoleCard from './RoleCard';
import { getCascadeFeedback } from './RoleHelpers';
import { PrivilegeSelected } from './RolePrivileges';
import {
  getRoleIds,
  getAuthsToUpdate,
  getAuthsToCreate,
  getUserFriendlyErrorMessage,
  getCurrentPrivilegesWithGrant,
  getSecurityAdminDefault,
} from './common';

const WarehouseAccessControl = ({
  orgName,
  organizationId,
  warehouseId,
}: {
  orgName: string; //TODO: fix this so we dont need to pass this in
  organizationId: string;
  warehouseId: string;
}) => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const orgDisplayName = user.getOrganization(orgName).displayName;

  //states
  const [roleBeingEdited, setRoleBeingEdited] = useState('');
  const [inProgressTasks, setInProgressTasks] = useState<
    InFlightCascadeTaskResult[]
  >([]);
  const [roleIdBeingEdited, setRoleIdBeingEdited] = useState('');
  //queries
  const {
    data: resourceData,
    loading,
    error,
    refetch,
  } = useQuery(warehouseResourceAuthorizationsQuery, {
    variables: {
      organizationId,
      warehouseId,
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: inFlightTasks,
    startPolling,
    stopPolling,
    refetch: refetchInFlightTasks,
  } = useQuery(inFlightWarehouseCascadeTasks, {
    variables: { warehouseId: warehouseId },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
  const securityAdminAuth = useMemo(
    () =>
      resourceData?.organization?.warehouse?.authorizations.filter(
        (auth: any) => auth?.role?.displayName === 'Security Admin',
      )[0],
    [resourceData],
  );

  const { data: securityAdminRole, loading: securityAdminLoading } = useQuery(
    getRoleByName,
    {
      skip: loading || securityAdminAuth,
      variables: {
        organizationId,
        roleName: 'SECURITY_ADMIN',
        organizationName: orgName,
        organizationDisplayName: orgDisplayName,
      },
    },
  );

  const { data: currentGrants } = useQuery(getAuthorizationGrants, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        resource: {
          type: AuthDecisionResourceType.Warehouse,
          identifier: warehouseId,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  //Cascade Handler
  useEffect(() => {
    if (!isEmpty(inFlightTasks?.inFlightWarehouseCascadeTasks)) {
      const inProgress = inFlightTasks.inFlightWarehouseCascadeTasks.filter(
        (task: InFlightCascadeTaskResult) =>
          task.status === AsyncState.InProgress ||
          task.status === AsyncState.Submitted,
      );
      if (!isEmpty(inProgress)) {
        startPolling(1000);
        setInProgressTasks(inProgress);
      }
      const failed = inFlightTasks.inFlightWarehouseCascadeTasks.filter(
        (task: InFlightCascadeTaskResult) =>
          task.status === AsyncState.Failed ||
          task.status === AsyncState.TimedOut,
      );
      if (!isEmpty(failed)) {
        forEach(failed, (failGuy) => {
          if (failGuy.createdBy === user.email) {
            const failedRoleName = resourceAuthorizations?.find(
              (auth: any) => auth.role.id === failGuy.subjectIds[0],
            )?.role?.name;
            const feedback = getCascadeFeedback(failGuy, failedRoleName, false);
            enqueueSnackbar(feedback, {
              variant: 'error',
              preventDuplicate: true,
            });
          }
        });
      }
      const complete = inFlightTasks.inFlightWarehouseCascadeTasks.filter(
        (task: InFlightCascadeTaskResult) =>
          task.status === AsyncState.Complete,
      );
      if (!isEmpty(complete)) {
        forEach(complete, (completeGuy) => {
          if (completeGuy.createdBy === user.email) {
            const completedRoleName = resourceAuthorizations?.find(
              (auth: any) => auth.role.id === completeGuy.subjectIds[0],
            )?.role?.name;
            const feedback = getCascadeFeedback(
              completeGuy,
              completedRoleName || roleBeingEdited || '',
              true,
            );
            closeAndRefetch(feedback);
          }
        });
        const removeCompletedTasks = remove(
          inProgressTasks,
          (task) => task.status === AsyncState.Complete,
        );
        setInProgressTasks(removeCompletedTasks);
        if (
          isEmpty(inProgress) &&
          (isEmpty(inProgressTasks) || isEmpty(removeCompletedTasks))
        ) {
          stopPolling();
        }
      }
    }
  }, [inFlightTasks]);

  //mutations
  const [createAuths] = useMutation(createAuthorizations);
  const [handleAuthMutations] = useMutation(handleAuthorizationMutations);
  const [cascadeAuths] = useMutation(cascadeWarehouseAuthorizationsMutation, {
    variables: {
      warehouseId: warehouseId,
      roleId: roleIdBeingEdited,
    },
    onError: (e) => {
      enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
        variant: 'error',
        preventDuplicate: true,
      });
    },
  });
  //variables
  const resourceAuthorizations = useMemo(
    () => resourceData?.organization?.warehouse?.authorizations,
    [resourceData],
  );

  const saRole = securityAdminRole && securityAdminRole?.fetchRoleByName;

  const securityAdminDefault =
    saRole &&
    getSecurityAdminDefault(saRole, AuthDecisionResourceType.Warehouse);

  const resourceAuthorizationsWithDefault = !securityAdminAuth
    ? compact(concat(resourceAuthorizations, securityAdminDefault))
    : resourceAuthorizations;

  const sortedAuths = orderBy(
    resourceAuthorizationsWithDefault,
    [(auth) => auth?.role?.displayName?.toLowerCase()],
    ['asc'],
  );

  const securityAdminIncluded = !securityAdminAuth
    ? !securityAdminLoading && securityAdminRole
    : true;

  const roleIds = useMemo(
    () => getRoleIds(resourceAuthorizations),
    [resourceAuthorizations],
  );
  const isSecurityAdmin = useMemo(() => user.isSecurityAdmin(orgName), [user]);

  const privilegesWithGrant = getCurrentPrivilegesWithGrant(
    currentGrants?.authGrants,
  );
  const isResourceAdmin = privilegesWithGrant?.length > 0 || isSecurityAdmin;

  const handleCascade = async () => {
    const cascadeResult = await cascadeAuths();
    if (cascadeResult?.data?.cascadeWarehouseAuthorizations?.taskId) {
      refetchInFlightTasks();
    }
  };

  const handleMutations = async (
    authsToUpdate: UpdateAuthInput[],
    authsToCreate: CreateAuthInput[],
    authsToDelete: string[],
    cascade: boolean,
    selectedRoleName: string,
  ) => {
    return await handleAuthMutations({
      variables: {
        authsToUpdate,
        authsToCreate,
        authsToDelete,
      },
      onCompleted: () => {
        if (cascade) {
          handleCascade();
        } else {
          closeAndRefetch(`${selectedRoleName} privileges have been updated`);
        }
      },
      onError: (e) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
          variant: 'error',
          preventDuplicate: true,
        });
      },
    });
  };

  const closeAndRefetch = (message: string) => {
    setTimeout(() => {
      refetch().then(() =>
        enqueueSnackbar(message, {
          variant: 'success',
          preventDuplicate: true,
        }),
      );
    }, 1000);
  };

  const handleGrantNewAccess = useCallback(
    async (
      currentPrivileges: PrivilegeSelected[],
      selectedRoleId: string,
      selectedRoleName: string,
      cascade: boolean,
    ) => {
      const authsToCreate = getAuthsToCreate(
        currentPrivileges,
        warehouseId,
        AuthDecisionResourceType.Warehouse,
        selectedRoleId,
      );
      return await createAuths({
        variables: {
          authorizations: authsToCreate,
        },
        onCompleted: () => {
          if (cascade) {
            handleCascade();
          } else {
            closeAndRefetch(`${selectedRoleName} privileges have been updated`);
          }
        },
        onError: (e) => {
          enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
            variant: 'error',
            preventDuplicate: true,
          });
        },
      });
    },
    [],
  );

  const handleApplyChanges = useCallback(
    async (
      currentPrivileges: PrivilegeSelected[],
      originalPrivileges: Authorization[],
      toCreate: PrivilegeSelected[],
      toUpdate: PrivilegeSelected[],
      toDelete: Privilege[],
      cascade: boolean,
      selectedRoleId: string,
      selectedRoleName: string,
    ) => {
      const privilegeChanges =
        toCreate.length > 0 || toUpdate.length > 0 || toDelete.length > 0;
      if (privilegeChanges) {
        const roleId = originalPrivileges[0].roleId || selectedRoleId;
        const authsToCreate = getAuthsToCreate(
          toCreate,
          warehouseId,
          AuthDecisionResourceType.Warehouse,
          roleId,
        );

        const authsToUpdate = getAuthsToUpdate(toUpdate, originalPrivileges);

        const authsToDelete = toDelete.map((priv: Privilege) => {
          return originalPrivileges.find((p) => p.privilege === priv)?.id;
        });

        return handleMutations(
          //@ts-ignore
          authsToUpdate,
          authsToCreate,
          authsToDelete,
          cascade,
          selectedRoleName,
        );
      } else if (cascade) {
        const cascadeResult = await cascadeAuths();
        if (cascadeResult?.data?.cascadeWarehouseAuthorizations?.taskId) {
          refetchInFlightTasks();
        }
      }
    },

    [],
  );

  const revokePrivilege = async (
    privileges: Authorization[],
    cascade: boolean,
    roleName: string,
  ) => {
    const toDelete = privileges.map((priv: any) => {
      return priv.id;
    });

    return handleMutations([], [], toDelete, cascade, roleName);
  };

  if (error && !resourceData) return <div>Error loading access controls</div>;

  return (
    <>
      {loading && <Skeleton variant="rectangular" height={350} />}
      {!loading && isResourceAdmin && (
        <>
          <PageHeader
            resourceName={resourceData?.organization?.warehouse?.name}
            pageTitle={'Warehouse access controls'}
            marginB={0}
          >
            <GrantNewAccess
              user={user}
              organizationId={organizationId}
              existingRoleIds={roleIds}
              handleGrantNewApply={async (
                currentPrivileges: PrivilegeSelected[],
                selectedRoleId: string,
                selectedRoleName: string,
                cascade: boolean,
              ) => {
                setRoleIdBeingEdited(selectedRoleId);
                await setRoleBeingEdited(selectedRoleName);
                return handleGrantNewAccess(
                  currentPrivileges,
                  selectedRoleId,
                  selectedRoleName,
                  cascade,
                );
              }}
              resourceType={AuthDecisionResourceType.Warehouse}
              isSecurityAdmin={isSecurityAdmin}
              currentGrants={privilegesWithGrant || []}
            />
          </PageHeader>
          <Tabulink
            external
            href={
              toDocsRoot() +
              '/understanding-tabular-role-based-access-controls--rbac-.html'
            }
            variant="body1"
            rel="noopener"
            aria-label="Tabular Documentation"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2,
              maxWidth: '350px',
            }}
          >
            Learn about access controls and role privileges
            <OpenInNewIcon fontSize="small" sx={{ marginLeft: '2px' }} />
          </Tabulink>
        </>
      )}
      {!loading &&
        resourceData?.organization &&
        !isEmpty(sortedAuths) &&
        securityAdminIncluded &&
        sortedAuths?.map((roleGroup: any, key: number) => {
          const securityAdminCard = roleGroup.role.name === 'SECURITY_ADMIN';
          return (
            <RoleCard
              key={`role-card-${key}-${roleGroup.role.id}`}
              organizationName={resourceData?.organization?.name}
              authorizations={roleGroup.authorizations}
              resourceName={resourceData?.organization?.warehouse?.name}
              roleDetails={roleGroup.role}
              accessType={AuthDecisionResourceType.Warehouse}
              handleApplyChanges={async (
                currentPrivileges: PrivilegeSelected[],
                originalPrivileges: Authorization[],
                toCreate: PrivilegeSelected[],
                toUpdate: PrivilegeSelected[],
                toDelete: Privilege[],
                cascade: boolean,
                selectedRoleId: string,
                selectedRoleName: string,
              ) => {
                await setRoleIdBeingEdited(selectedRoleId);
                await setRoleBeingEdited(roleGroup.role.displayName);
                return handleApplyChanges(
                  currentPrivileges,
                  originalPrivileges,
                  toCreate,
                  toUpdate,
                  toDelete,
                  cascade,
                  selectedRoleId,
                  selectedRoleName,
                );
              }}
              showRevokeAccess={!securityAdminCard}
              onRevokeAccess={async (cascadeRevoke: boolean) => {
                await setRoleIdBeingEdited(roleGroup.role.id);
                await revokePrivilege(
                  roleGroup.authorizations,
                  cascadeRevoke,
                  roleGroup.role.displayName,
                );
              }}
              isResourceAdmin={isResourceAdmin}
              currentGrants={privilegesWithGrant || []}
              user={user}
              securityAdminCard={securityAdminCard}
              inProgressTasks={inProgressTasks}
            />
          );
        })}
    </>
  );
};

export { WarehouseAccessControl };
