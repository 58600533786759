import React, { useCallback, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

import { ROUTES } from '../../RouteTable';
import HelpMenu from '../Help/HelpMenu';
import { TabLayoutGroupHeader } from '../Layouts/TabLayoutGroupHeader';
import { showNavSearchDialog } from '../Modals/NavSearchDialog';
import { useNavSearchKeyboardEvents } from '../Modals/UseNavSearchKeyboardEvents';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import { SearchButton } from '../SearchButton/SearchButton';
import { SearchLabel } from '../SearchButton/SearchLabel';
import { Shortcut } from '../SearchButton/Shortcut';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '72px',
  },
}));

export default function AppBar() {
  const toolbarSt = useStyles();
  const mobileNav = useMediaQuery('(max-width:910px)');
  const searchBreakpoint = useMediaQuery('(max-width: 370px)');
  const [isOpen, setIsOpen] = React.useState(false);

  const onOpen = useCallback(() => {
    setIsOpen(true);
    showNavSearchDialog();
  }, [setIsOpen, isOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    return useNavSearchKeyboardEvents({ isOpen, onOpen, onClose });
  }, [isOpen, onOpen, onClose]);

  return (
    <MuiAppBar
      sx={(theme) => ({
        backgroundImage: theme.palette.controlBackground.gradient,
        backgroundSize: '100% 100vh',
      })}
      position="fixed"
    >
      <Toolbar classes={{ root: toolbarSt.root }}>
        <Box
          display={'flex'}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {mobileNav ? (
            <TabLayoutGroupHeader mobileNav />
          ) : (
            <Box display={'flex'} alignItems={'center'}>
              <Link href={ROUTES.home} sx={{ marginRight: 3 }}>
                <img
                  src="/assets/img/tabular-logo-blue.svg"
                  alt="tabular"
                  height="40px"
                />
              </Link>
              <Box display={`flex`} justifyContent={`center`}>
                <TabLayoutGroupHeader />
              </Box>
            </Box>
          )}
          <Box display={'flex'} justifyContent={'end'} alignItems={'center'}>
            {!searchBreakpoint && (
              <SearchButton
                onClick={() => {
                  onOpen();
                }}
                sx={{ height: '24px' }}
              >
                <SearchIcon
                  sx={(theme) => ({
                    color: theme.palette.sky.one,
                    fontSize: '20px',
                  })}
                />
                <SearchLabel>{'Search...'}</SearchLabel>
                <Shortcut>{'/'}</Shortcut>
              </SearchButton>
            )}
            <HelpMenu />
            <ProfileMenu />
          </Box>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
}
