import React from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Grid, Typography, Box, Link, List, ListItem } from '@mui/material';

import { Tabutton } from '../../Button/Tabutton';
import { Tabulink } from '../../Link/Tabulink';

export const CelerDataSetup = () => {
  return (
    <Box>
      <Box sx={{}}>
        <Box sx={{ width: 0.95 }}>
          <Grid
            container
            sx={{
              width: 1,
              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
              }}
            >
              <Typography variant={'body1'}>
                {`Requires a CelerData account`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              <Typography sx={{ display: 'inline-flex', mr: 1 }}>
                <Tabulink
                  external
                  href={'https://cloud.celerdata.com/login?step=account'}
                  variant="body1"
                  rel="noopener"
                  aria-label="Login to your CelerData account"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {'Log into your CelerData account '}
                  <OpenInNewIcon
                    fontSize="small"
                    sx={{ ml: '2px', mr: '3px' }}
                  />
                </Tabulink>
                (instructions detailed below)
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant={'body1'}>
                {/* @ts-ignore */}
                <Link
                  external
                  href={'https://cloud.celerdata.com/login'}
                  aria-label="Sign up with CelerData"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                  }}
                >
                  {' '}
                  <Tabutton size={`small`} endIcon={<OpenInNewIcon />}>
                    Sign up with CelerData
                  </Tabutton>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant={'body1'}>In CelerData,</Typography>
          <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Click <b>Create cluster</b>
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Choose your desired <b>cluster model</b> and select <b>AWS</b>{' '}
                for cloud provider, <b>click next</b>
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Configure cluster with <b>name</b>,<b> region</b>, and{' '}
                <b>node options</b>
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Select <b>Quick deployment</b> and make sure you are logged into
                your AWS account then click <b>Start</b>
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Next fill out cloud formation template with <b>S3</b> bucket
                names and <b>admin credentials</b>
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Click <b>Create stack</b> and keep CelerData open, once cloud
                formation is successful click <b>Finish</b> and continue here
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};
