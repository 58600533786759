import React, { SyntheticEvent, useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  AutocompleteRenderInputParams,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Field } from 'formik';
import { Autocomplete } from 'formik-mui';
import { isEmpty, remove, some } from 'lodash';

import { toDocsRoot } from '../../RouteTable';
import {
  AuthDecisionResourceType,
  Privilege,
  SearchRoleResult,
} from '../../graphql/gen/graphql';
import { configureButtonGroups } from '../AccessControl/ButtonConfiguration';
import PrivilegeSelector from '../AccessControl/PrivilegeSelector';
import { PrivilegeSelected } from '../AccessControl/RolePrivileges';
import { TaBadge } from '../Badge/TaBadge';
import { Tacard } from '../Card/Tacard';
import { Tabulink } from '../Link/Tabulink';
import SectionHeader from '../SectionHeader/SectionHeader';
import { LabelAccessRoleType } from './helpers';

type LabelAccessControlProps = {
  values: any;
  initialValues?: any;
  setFieldValue: any;
  loading: boolean;
  labelAccessRoleOptions: LabelAccessRoleType[];
  highlighted: any;
  setHighlighted: any;
  handleScroll: any;
  showMemberCount: boolean;
  userCanEditAccess: boolean;
  debouncedSearchChange: any;
  currentGrants?: any;
};

export function LabelAccessControls({
  values,
  initialValues,
  setFieldValue,
  loading,
  labelAccessRoleOptions,
  highlighted,
  setHighlighted,
  handleScroll,
  showMemberCount,
  userCanEditAccess,
  debouncedSearchChange,
  currentGrants,
}: LabelAccessControlProps) {
  const handlePrivChange = (
    event: PrivilegeSelected,
    withGrant?: boolean,
    multiple?: boolean,
  ) => {
    setFieldValue(
      'labelAccess',
      values.labelAccess?.map((priv: any) => {
        if (priv.roleId === event.roleId) {
          // Map to correct role
          if (priv.privileges) {
            const currentRolePrivs = initialValues?.find(
              (role: any) => role.roleId === event.roleId,
            )?.privileges;

            const currentPriv = currentRolePrivs?.find(
              (priv: any) => priv.privilege === event.privilege,
            );
            const allPrivsForRole = Object.keys(priv.privileges)?.map(
              (key: any) => priv.privileges[key],
            );

            const privilegeToUpdate = allPrivsForRole?.find(
              (p: any) => p.privilege === event.privilege,
            );

            const updateThisOne = allPrivsForRole.indexOf(privilegeToUpdate);
            const privForUpdateOrDelete =
              allPrivsForRole[updateThisOne]?.selected;

            const createsForRole = values.createPrivs?.filter(
              (priv: any) => priv.roleId === event.roleId,
            );
            const deletesForRole = values.deletePrivs?.filter(
              (priv: any) => priv.roleId === event.roleId,
            );
            const updatesForRole = values.updatePrivs?.filter(
              (priv: any) => priv.roleId === event.roleId,
            );

            const sliderEffectPrivs = multiple && !privForUpdateOrDelete;
            const createPriv = !multiple && !privForUpdateOrDelete;
            const removePriv = !multiple && privForUpdateOrDelete && !withGrant;
            const updatePriv = !multiple && privForUpdateOrDelete && withGrant;

            if (sliderEffectPrivs) {
              const lowerPrivs = [
                {
                  privilege: Privilege.ApplyLabel,
                  selected: true,
                  withGrant: false,
                  roleId: event.roleId,
                  roleName: event.groupingName,
                },
                {
                  privilege: Privilege.ModifyLabel,
                  selected: true,
                  withGrant: false,
                  roleId: event.roleId,
                  roleName: event.groupingName,
                },
              ];

              const privsToCreate =
                values.createPrivs.length > 0
                  ? [...values.createPrivs, ...lowerPrivs]
                  : [...lowerPrivs];
              priv.privileges.push(...lowerPrivs);
              setFieldValue('createPrivs', privsToCreate);
            }

            //DELETING
            if (removePriv) {
              const isOnUpdateList = updatesForRole.find(
                (i: any) => i.privilege === event.privilege,
              );
              const isOnCreateList = createsForRole.find(
                (i: any) => i.privilege === event.privilege,
              );

              if (isOnUpdateList || isOnCreateList) {
                if (isOnUpdateList) {
                  const indexOfUpdated =
                    values.updatePrivs.indexOf(isOnUpdateList);
                  values.updatePrivs.splice(indexOfUpdated, 1);
                }
                if (isOnCreateList) {
                  const indexOfUpdated =
                    values.createPrivs.indexOf(isOnCreateList);
                  values.createPrivs.splice(indexOfUpdated, 1);
                }
              } else {
                setFieldValue('deletePrivs', [...values.deletePrivs, event]);
              }
              priv.privileges.splice(updateThisOne, 1);
            }

            //CREATING
            if (createPriv) {
              const isOnDeleteList = deletesForRole?.find(
                (priv: any) => priv.privilege === event.privilege,
              );

              if (currentPriv) {
                const checkSelected = currentPriv.selected === event.selected;
                const checkWithGrant =
                  currentPriv.withGrant === event.withGrant;
                if (checkSelected && checkWithGrant) {
                } else {
                  setFieldValue('updatePrivs', [...values.updatePrivs, event]);
                }
              }

              if (isOnDeleteList) {
                const indexOfDeleted =
                  values.deletePrivs.indexOf(isOnDeleteList);
                values.deletePrivs.splice(indexOfDeleted, 1);
                priv.privileges.push(event);
              }

              if (!isOnDeleteList) {
                setFieldValue('createPrivs', [...values.createPrivs, event]);
                priv.privileges.push(event);
              }
            }
            // UPDATING
            if (updatePriv) {
              const onUpdateList = updatesForRole.find(
                (priv: any) => priv.privilege === event.privilege,
              );

              const isOnCreateList = createsForRole.find(
                (priv: any) => priv.privilege === event.privilege,
              );

              if (onUpdateList) {
                const indexOfUpdated = values.updatePrivs.indexOf(onUpdateList);
                values.updatePrivs.splice(indexOfUpdated, 1);
              }

              if (isOnCreateList) {
                const indexOfUpdated =
                  values.createPrivs.indexOf(isOnCreateList);
                values.createPrivs.splice(indexOfUpdated, 1);
                setFieldValue('createPrivs', [...values.createPrivs, event]);
              }

              privilegeToUpdate.withGrant = event.withGrant;
              if (!isOnCreateList) {
                setFieldValue('updatePrivs', [...values.updatePrivs, event]);
              }
            }
          }
        }

        return priv;
      }),
    );
  };
  const handleRemove = (roleId: string) => {
    const accessWithRoleRemoved = remove(
      values.labelAccess,
      (item: LabelAccessRoleType) => item.roleId !== roleId,
    );

    const removedPrivsFilteredOut = values.createPrivs?.filter(
      (priv: PrivilegeSelected) => priv.roleId !== roleId,
    );
    setFieldValue('createPrivs', [...removedPrivsFilteredOut]);

    const privsToDelete = initialValues?.filter(
      (priv: PrivilegeSelected) => priv.roleId === roleId,
    )[0].privileges;

    if (privsToDelete) {
      privsToDelete.map((priv: PrivilegeSelected) =>
        values.deletePrivs.push(priv),
      );
      remove(values.updatePrivs, (i: any) => i.roleId === roleId);
    }
    setFieldValue('labelAccess', accessWithRoleRemoved);
  };

  return (
    <>
      <Stack direction={`column`} spacing={2}>
        <Box>
          <Typography variant="subtitle1" color={'dusk.six'}>
            {`Label access controls`}
          </Typography>
          <Tabulink
            external
            href={toDocsRoot() + '/introducing-labels.html'}
            variant="body1"
            rel="noopener"
            aria-label="Tabular Documentation"
            sx={{
              display: 'flex',
              alignItems: 'center',
              maxWidth: '350px',
            }}
          >
            Learn about label access controls and privileges
            <OpenInNewIcon fontSize="small" sx={{ marginLeft: '2px' }} />
          </Tabulink>
        </Box>
        <Box display={`flex`} sx={{ mt: '24px !important' }}>
          <TaBadge text={`APPLY`} />
          <Typography variant={`body1`} sx={{ ml: 1 }}>
            allows role to apply this label to resources.
          </Typography>
        </Box>
        <Box display={`flex`}>
          <TaBadge text={`MODIFY`} />
          <Typography variant={`body1`} sx={{ ml: 1 }}>
            allows role to edit this label’s name and description.
          </Typography>
        </Box>
        <Box display={`flex`} sx={{ mb: '24px !important' }}>
          <TaBadge text={`ADMIN`} />
          <Typography variant={`body1`} sx={{ ml: 1 }}>
            allows role to edit access controls and label policy.
          </Typography>
        </Box>
        <Tacard sx={{ mt: 4 }}>
          <SectionHeader borderTop={'none'}>
            <Typography variant={`subtitle1`}>Roles</Typography>
          </SectionHeader>
          {isEmpty(values.labelAccess) ? (
            <Box
              sx={(theme) => ({
                background: theme.palette.controlBackground.lakeWaterGradient,
                height: 80,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '600px',
              })}
            >
              <Typography variant={`helperText`}>
                To add roles, click the roles dropdown below.
              </Typography>
            </Box>
          ) : (
            <List
              sx={{
                px: 1,
                maxHeight: 300,
                minWidth: 600,
                overflowY: 'auto',
              }}
            >
              {values.labelAccess?.map((role: any) => {
                const configuredButton = configureButtonGroups(
                  role.privileges,
                  AuthDecisionResourceType.Label,
                  role.roleName,
                  true,
                );
                const securityAdminCard = role.roleName === 'SECURITY_ADMIN';

                // Removing Select from list
                configuredButton[0].shift();
                return (
                  <ListItem
                    key={role.roleId}
                    disablePadding
                    disableGutters
                    sx={{ marginRight: 0 }}
                  >
                    <Box display={`flex`} alignItems={`center`}>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        width={'100%'}
                      >
                        <Box display={`flex`}>
                          {configuredButton.map((buttonGroup: any) => {
                            buttonGroup.forEach((privilege: any) => {
                              const existingPrivs = currentGrants?.map(
                                (priv: any) =>
                                  priv.privilege === privilege.privilege,
                              );

                              const indexOfPrivilege =
                                existingPrivs?.indexOf(true) || 0;
                              const existingPrivilege =
                                currentGrants &&
                                currentGrants[indexOfPrivilege];

                              privilege['hasAbilityToGrantPriv'] =
                                existingPrivilege?.withGrant ||
                                userCanEditAccess;
                            });

                            return (
                              <PrivilegeSelector
                                key={role.roleId}
                                accessButtons={buttonGroup}
                                groupName={role.roleName}
                                handlePrivChange={handlePrivChange}
                                grantingNewAccess={false}
                                securityAdminCard={securityAdminCard}
                                roleId={role.roleId}
                              />
                            );
                          })}
                        </Box>
                        <Box
                          display={'flex'}
                          sx={(theme) => ({
                            [theme.breakpoints.down('md')]: { marginTop: 4 },
                          })}
                        >
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            disabled={!userCanEditAccess || securityAdminCard}
                            onClick={() => handleRemove(role.roleId)}
                          >
                            <CloseIcon
                              sx={{
                                color:
                                  !userCanEditAccess || securityAdminCard
                                    ? 'rgba(0, 0, 0, 0.38)'
                                    : 'sunset.seven',
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          )}
        </Tacard>
      </Stack>
      <Box marginY={4}>
        <Field
          component={Autocomplete}
          multiple
          name={`labelAccess`}
          blurOnSelect
          disableClearable
          disabled={!userCanEditAccess}
          getOptionLabel={(option: SearchRoleResult) => option.roleName}
          isOptionEqualToValue={(option: any, value: any) =>
            option.roleId === value.roleId
          }
          loading={loading}
          options={
            labelAccessRoleOptions
              ? labelAccessRoleOptions.filter(
                  (option: SearchRoleResult) =>
                    !some(
                      values.labelAccess,
                      (role: LabelAccessRoleType) =>
                        role.roleId === option?.roleId,
                    ),
                )
              : []
          }
          noOptionsText={`No roles`}
          renderTags={() => null}
          onInputChange={(event: SyntheticEvent) => {
            //@ts-ignore
            debouncedSearchChange(event);
          }}
          onHighlightChange={(
            event: SyntheticEvent,
            option: LabelAccessRoleType,
            reason: any,
          ) => {
            if (reason === 'keyboard') {
              setHighlighted(option?.roleId || '');
            }
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <MuiTextField
              {...params}
              label="Add roles"
              fullWidth
              onKeyDown={(event: any) => {
                if (event.key === 'Backspace') {
                  event.stopPropagation();
                }
              }}
            />
          )}
          ListboxProps={{
            onScroll: handleScroll,
            role: 'list-box',
          }}
          renderOption={(props: any, option: SearchRoleResult) => {
            return (
              <li {...props}>
                <ListItemButton
                  sx={{
                    backgroundColor:
                      highlighted === option.roleId ? 'sky.two' : 'inherit',
                  }}
                >
                  <ListItemText sx={{ wordBreak: 'break-word' }}>
                    {option?.roleName}
                  </ListItemText>
                </ListItemButton>
              </li>
            );
          }}
        />
      </Box>
    </>
  );
}
