import React from 'react';

import { StatusPanelSectionContext } from './StatusPanelSectionContext';

type StatusPanelSectionContextProviderProps = {
  props: {};
  children: React.ReactNode;
};

export function StatusPanelSectionContextProvider({
  props,
  children,
}: StatusPanelSectionContextProviderProps) {
  return (
    <StatusPanelSectionContext.Provider value={props}>
      {children}
    </StatusPanelSectionContext.Provider>
  );
}
