import React, { useState } from 'react';

import {
  AvatarGroup,
  Box,
  Card,
  Chip,
  Paper,
  Popover,
  Typography,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { IPopoverState } from '../../Popovers/Popover';

type Props = {
  operations: string[];
  key: React.Key;
};
export default function UpdateChanges({ operations, key, ...props }: Props) {
  const [popover, setPopover] = useState<IPopoverState>({} as IPopoverState);

  const handlePopoverClose = () => {
    setPopover({} as IPopoverState);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 30,
    lineHeight: '30px',
    marginRight: '10px',
    paddingLeft: '5px',
    paddingRight: '5px',
  }));
  return (
    <>
      {[...new Set(operations)].map((change) => (
        <Item key={key + '_changes_' + change}>{change}</Item>
      ))}
    </>
  );
}
