import React from 'react';

import {
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { Table } from '../../../graphql/gen/graphql';
import { Failed } from '../../../schema/events/failed';

type Props = {
  event: Failed;
  table: Table;
};
export default function FailedServiceDetails({ event, table }: Props) {
  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <TableContainer component={Paper}>
            <MuiTable aria-label="Listing">
              <TableBody>
                <TableRow>
                  <TableCell variant="head">Error</TableCell>
                  <TableCell>{event.error}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Error Message</TableCell>
                  <TableCell>{event.error_message}</TableCell>
                </TableRow>
                {event.cause != null && (
                  <>
                    <TableRow>
                      <TableCell variant="head">Cause</TableCell>
                      <TableCell>{event.cause}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Cause Message</TableCell>
                      <TableCell>{event.cause_message}</TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
