import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLazyQuery, useQuery } from '@apollo/client';
// @ts-ignore
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { OktaAuth } from '@okta/okta-auth-js';
// @ts-ignore
import internal from 'stream';

// @ts-ignore
import { ROUTES, toOrgRoot } from '../../RouteTable';
// @ts-ignore
import { useAuth, noAccountErrorMessage } from '../../context/auth-context';
// @ts-ignore
import { authConfigByIssuer } from '../../graphql/organization';
// @ts-ignore
import { oktaConfigByEmail } from '../../graphql/user';
import { getLogger } from '../../utils/logging';
import { Talert } from '../Alert/Talert';
// @ts-ignore
import { PleaseWait } from '../PleaseWait/PleaseWait';

const logger = getLogger('OktaLogin' /*FUTURE import.meta.url ?*/);

const LoginRedirect = ({ authClient }: { authClient: OktaAuth }) => {
  const [isSigningIn, setIsSigningIn] = useState(false);

  if (!isSigningIn) {
    setIsSigningIn(true);
    authClient.signInWithRedirect({
      originalUri: window.location.toString(),
    });
  }

  return null;
};

const ParseToken = ({
  authClient,
  orgId,
  onOktaSignIn,
  setStatusBar,
}: {
  authClient: OktaAuth;
  orgId: string;
  onOktaSignIn: (oktaToken: string, orgId: string) => Promise<any>;
  setStatusBar: Dispatch<SetStateAction<any>>;
}) => {
  const [params, setParams] = useSearchParams();

  useMemo(() => {
    if (params.get('code')) {
      authClient.token
        .parseFromUrl()
        .then((res) => {
          const { idToken } = res.tokens;
          // console.log('Got token!', idToken);
          if (idToken && idToken?.idToken) {
            onOktaSignIn(idToken?.idToken, orgId).catch((error) => {
              console.log('Error logging in:', error);
              setStatusBar(<Talert severity="error">{error.message}</Talert>);
            });
          } else {
            console.log('No token found during okta login');
          }
        })
        .catch((error) => {
          console.log('Error while getting okta token', error);
          setStatusBar(<Talert severity="error">{error.message}</Talert>);
        });
    }
  }, [authClient]);

  return null;
};

export const enum OktaWidgetMode {
  Redirect,
  Callback,
}

const OktaSignInWidget = ({
  emailValue,
  onOktaSignIn,
  onOktaConfigError,
  mode,
}: {
  emailValue: string;
  onOktaSignIn: (oktaToken: string, orgId: string) => Promise<any>;
  onOktaConfigError: (error: any) => void;
  mode: OktaWidgetMode;
}) => {
  const initialStatus: any = null;

  const windowLocation = window.location.toString();

  const hasCodeValueInURL = windowLocation.indexOf('code=') >= 0;

  function getStoredConfig() {
    try {
      //we dont have the iss to query for config, we dont have the email to query for config, lets see if it is in storage!
      const authConfigString = localStorage.getItem('authConfig');

      if (authConfigString) {
        const authConfig = JSON.parse(authConfigString);
        return {
          data: { oktaConfig: authConfig },
          loading: false,
          error: null,
        };
      }
    } catch (e) {
      logger.error(
        'unhandled exception getting authConfig from local storage.',
        e,
      );
    }

    return undefined;
  }

  const storedConfig = getStoredConfig();
  const [statusBar, setStatusBar] = useState(initialStatus);
  const [oktaScriptLoaded, setOktaScriptLoaded] = useState(false);
  const [authClient, setAuthClient] = useState({} as OktaAuth);
  const [params, setParams] = useSearchParams();
  const issSearchValue = params.get('iss');

  const [queryByIssuer, queryByIssuerResult] = useLazyQuery(
    authConfigByIssuer,
    {
      variables: {
        issuer: issSearchValue,
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    },
  );

  const [queryByEmail, queryByEmailResult] = useLazyQuery(oktaConfigByEmail, {
    variables: {
      email: emailValue,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    //we are a callback from an okta initiated session
    if (issSearchValue) {
      logger.info(`Logging into okta with issuer ${issSearchValue}`);
      queryByIssuer();
    } else if (emailValue) {
      logger.info(`Logging into okta with email ${emailValue}`);
      //we are either initially querying for the user or we are ping ponging back from that session
      queryByEmail();
    }
  }, [params, emailValue]);

  const { data, loading, error } = issSearchValue
    ? queryByIssuerResult
    : emailValue
    ? queryByEmailResult
    : storedConfig?.data
    ? storedConfig
    : {
        data: { oktaConfig: undefined },
        loading: false,
        error: null,
      };

  if (error) {
    logger.error(`got api error ${error}`);
  }

  let config = data?.oktaConfig
    ? data?.oktaConfig
    : data?.authConfigForIssuer
    ? data?.authConfigForIssuer
    : null;

  if (config) {
    localStorage.setItem('authConfig', JSON.stringify(config));
  }

  useEffect(() => {
    if (oktaScriptLoaded || !config) return;

    const initialize = () => {
      const issuerUrl = new URL(config.issuer);
      const redirectUri = `${window.location.protocol}//${window.location.host}/login/callback`;
      var authClient = new OktaAuth({
        issuer: `${issuerUrl.protocol}//${issuerUrl.host}${issuerUrl.pathname}`,
        clientId: config.clientId,
        redirectUri: redirectUri,
      });
      setAuthClient(authClient);
      setOktaScriptLoaded(true);
    };

    const script = document.createElement('script');
    script.src =
      'https://global.oktacdn.com/okta-auth-js/7.1.1/okta-auth-js.min.js';
    script.onload = initialize;
    script.defer = true;
    script.async = true;
    script.id = 'okta-client-script';
    document.querySelector('body')?.appendChild(script);

    return () => {
      // Cleanup function that runs when component unmounts
      // (window as any).google?.accounts.id.cancel();
      document.getElementById('okta-client-script')?.remove();
    };
  }, [oktaScriptLoaded, config]);

  if (loading) {
    return <PleaseWait />;
  }

  const apiError = error ? (
    <Talert severity="error">
      {error.message == '404: Not Found'
        ? noAccountErrorMessage
        : error.message}
    </Talert>
  ) : undefined;

  if (!oktaScriptLoaded)
    return (
      <>
        {apiError && apiError}
        {!apiError && !config && (
          <Talert severity="error">
            No Okta config found, unable to login.
          </Talert>
        )}
        {statusBar == null && config && <PleaseWait />}
        {statusBar != null && (
          <Grid container sx={{ mt: 4 }}>
            <Grid item>
              {statusBar}
              <Link href={ROUTES.login} variant="body2">
                {'Please try logging in again.'}
              </Link>
            </Grid>
          </Grid>
        )}
      </>
    );

  return (
    <>
      {statusBar}
      {mode == OktaWidgetMode.Redirect && (
        <LoginRedirect authClient={authClient} />
      )}
      {mode === OktaWidgetMode.Callback && hasCodeValueInURL && (
        <ParseToken
          authClient={authClient}
          orgId={config.organizationId}
          onOktaSignIn={onOktaSignIn}
          setStatusBar={setStatusBar}
        />
      )}
      {statusBar == null && <PleaseWait />}
      {statusBar != null && (
        <Grid container sx={{ mt: 4 }}>
          <Grid item>
            <Link href={ROUTES.login} variant="body2">
              {'Please try logging in again.'}
            </Link>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OktaSignInWidget;
