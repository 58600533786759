import * as React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  IconButton,
  Typography,
  Tooltip,
  BoxProps,
} from '@mui/material';

import { Privilege } from '../../graphql/gen/graphql';

export type AccessButton = {
  privilege: Privilege;
  selected: boolean;
  buttonText: string;
  groupingName: string;
  withGrant: boolean;
  hasAbilityToGrantPriv: boolean;
  description: string;
  level: number;
  group: number;
};

export const StyledButtonGroup = (props: ButtonGroupProps) => (
  <ButtonGroup
    fullWidth
    size={`small`}
    {...props}
    sx={(theme) => ({
      alignItems: 'center',
      '& .MuiButtonGroup-middleButton': {
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
      },
      '& .MuiButtonGroup-firstButton': {
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px',
        borderBottomLeftRadius: '12px',
      },
      '& .MuiButtonGroup-lastButton': {
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        borderTopRightRadius: '12px',
      },
      height: '30px',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
    })}
  >
    {props.children}
  </ButtonGroup>
);

export const GrantButton = ({
  popover,
  grantSelected,
  disable,
  handleClick,
}: {
  popover: boolean;
  grantSelected: boolean;
  disable?: boolean;
  handleClick?: () => void;
}): JSX.Element => (
  <Tooltip
    title={!handleClick ? '' : 'Ability to grant access to this privilege'}
    PopperProps={{
      sx: [
        {
          lineHeight: '16px',
        },
      ],
    }}
    arrow
    placement={'top'}
  >
    <Box
      sx={{
        backgroundColor: popover ? 'black' : 'white',
        borderRadius: '50%',
        position: 'absolute',
        alignSelf: 'center',
        height: '30px',
        width: '30px',
        marginLeft: '-10px',
        display: 'flex',
        right: -4,
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          right: 3,
          alignSelf: 'center',
          height: '24px',
          width: '24px',
          color: `${grantSelected ? 'midnight.nine' : 'midnight.seven'}`,
          backgroundColor: `${grantSelected ? 'sunrise.four' : 'midnight.two'}`,
          '&:hover': {
            color: 'midnight.nine',
            backgroundColor:
              disable && !grantSelected
                ? 'midnight.two'
                : disable && grantSelected
                ? 'sunrise.four'
                : 'sunrise.two',
          },
          '&:disabled': {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
            color: `${grantSelected ? 'midnight.nine' : 'midnight.seven'}`,
            backgroundColor: `${
              grantSelected ? 'sunrise.four' : 'midnight.two'
            }`,
          },
        }}
        onClick={handleClick}
        disabled={disable}
      >
        {grantSelected ? (
          <GppGoodOutlinedIcon sx={{ height: '16px', width: '16px' }} />
        ) : (
          <ShieldOutlinedIcon sx={{ height: '16px', width: '16px' }} />
        )}
      </IconButton>
    </Box>
  </Tooltip>
);
export function AccessButtonGroup({
  accessButtons,
  securityAdminRole,
  popover = false,
}: {
  accessButtons: AccessButton[];
  securityAdminRole: boolean;
  popover?: boolean;
}) {
  const hasManageGrants = accessButtons?.find((priv: AccessButton) =>
    priv.privilege?.includes('GRANTS'),
  );

  return (
    <StyledButtonGroup>
      {accessButtons.map((button, index) => {
        const myVariant = button.selected
          ? 'filledAccessMain'
          : 'blankAccessMain';

        const withGrant = button.withGrant;
        const manageGrantsButton = button?.privilege?.includes('GRANTS');

        return (
          !manageGrantsButton && (
            <Box
              key={`access-button-${button.privilege}-${index}`}
              sx={(theme) => ({
                marginLeft: '10px',
                color: 'black !important',
                position: 'relative',
                [theme.breakpoints.down('md')]: {
                  '&:first-of-type': {
                    marginLeft: 0,
                  },
                },
              })}
              display={'flex'}
              flex={1}
            >
              <Button
                size={`small`}
                variant={myVariant}
                sx={{
                  boxShadow: 'none !important',
                  p: 0,
                  m: 0,
                  height: '24px',
                  fontSize: '12px',
                }}
                disabled
              >
                <Box
                  sx={{
                    ml: button.buttonText && !popover ? 4 : 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {!button.buttonText ? (
                    <CheckIcon
                      fontSize={`small`}
                      sx={{
                        color: `${
                          button.selected ? 'midnight.nine' : 'midnight.two'
                        }`,
                      }}
                    />
                  ) : (
                    <>
                      {button.selected && (
                        <CheckIcon fontSize={`small`} sx={{ color: 'black' }} />
                      )}
                      <Typography
                        sx={{
                          color: `${
                            button.selected ? 'black' : 'midnight.seven'
                          }`,
                          ml: '4px',
                        }}
                        variant={'smallButton'}
                      >
                        {button.buttonText}
                      </Typography>
                    </>
                  )}
                </Box>
              </Button>
              <GrantButton
                popover={popover}
                grantSelected={
                  withGrant ||
                  hasManageGrants?.selected ||
                  securityAdminRole ||
                  false
                }
              />
            </Box>
          )
        );
      })}
    </StyledButtonGroup>
  );
}
