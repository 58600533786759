import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { ROUTES } from '../../RouteTable';
import { Tabutton } from '../Button/Tabutton';

export default function IdleTimer({ timeout }: { timeout: number }) {
  const [remaining, setRemaining] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const onIdle = () => {
    handleLogOut();
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const handleStillHere = () => {
    activate();
    setOpen(false);
  };

  const handleLogOut = () => {
    navigate(ROUTES.logout);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout: timeout ?? 7 * 24 * 60 * 60 * 1000,
    promptBeforeIdle: timeout > 60000 ? 60000 : timeout / 2,
    throttle: 1000,
    crossTab: true,
    syncTimers: 1000,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Dialog open={open}>
      <DialogTitle>Are you still there?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Your session will expire in ${remaining}
            seconds due to inactivity.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Tabutton onClick={handleLogOut}>Log out</Tabutton>
        <Tabutton variant="contained" onClick={handleStillHere}>
          Stay logged in
        </Tabutton>
      </DialogActions>
    </Dialog>
  );
}
