import * as React from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Divider, Stack, Typography } from '@mui/material';

type Props = {
  type: string;
  numberOfDays: number;
  starting: string | number;
  rewritten: string | number;
  total: string | number;
  downward: boolean;
  percent: string;
};
export const CompactionResultsPopover = React.forwardRef(
  (
    {
      type,
      numberOfDays,
      starting,
      rewritten,
      total,
      downward,
      percent,
      ...props
    }: Props,
    ref,
  ) => {
    return (
      <Box
        ref={ref}
        {...props}
        sx={(theme) => ({
          backgroundColor: 'controlBackground.main',
          border: `1px solid ${theme.palette.midnight.two}`,
          borderRadius: '4px',
          boxShadow: theme.shadows[3],
          minWidth: 170,
          p: 2,
          mt: 1,
        })}
      >
        <Stack direction={`column`}>
          <Box>
            <Typography
              variant={`overline`}
            >{`Last ${numberOfDays} Days`}</Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography
              marginBottom={1}
              variant={`subtitle2`}
            >{`Starting ${type}`}</Typography>
            <Typography variant={`body2`}>{starting}</Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography
              marginBottom={1}
              variant={`subtitle2`}
            >{`Rewritten ${type}`}</Typography>
            <Typography variant={`body2`}>{rewritten}</Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography
              marginBottom={1}
              variant={`subtitle2`}
            >{`Total ${type} change`}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant={`body2`}>{total}</Typography>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.sky.half,
                  border: `1px solid ${theme.palette.sky.two}`,
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  px: 1,
                  ml: 1,
                  minWidth: 90,
                })}
              >
                {downward ? (
                  <ArrowDownwardIcon sx={{ fontSize: '16px', mr: 1 }} />
                ) : (
                  <ArrowUpwardIcon sx={{ fontSize: '16px', mr: 1 }} />
                )}
                <Typography variant={`subtitle2`}>{percent}</Typography>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
    );
  },
);
