import React from 'react';

import { useModal, create, show } from '@ebay/nice-modal-react';

import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from './ConfirmationDialog';

export interface ConfirmationDialogModalProps
  extends Partial<
    Omit<ConfirmationDialogProps, 'open' | 'onAccept' | 'onDismiss' | 'loading'>
  > {}
const ConfirmationDialogModal = create(
  ({
    title,
    acceptText = 'ok',
    children,
    disabled = false,
  }: ConfirmationDialogModalProps) => {
    const modal = useModal();

    return (
      <ConfirmationDialog
        open={modal.visible}
        title={title ?? 'Do you accept?'}
        acceptText={acceptText}
        onAccept={() => {
          modal.resolve();
          modal.hide();
        }}
        onDismiss={() => modal.hide()}
      >
        {children}
      </ConfirmationDialog>
    );
  },
);

export function showConfirmationDialog(props: ConfirmationDialogModalProps) {
  return show<boolean, any, ConfirmationDialogModalProps>(
    ConfirmationDialogModal,
    props,
  );
}
