import React from 'react';

import { Popper, Typography, Box } from '@mui/material';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { Table } from '../../graphql/gen/graphql';
import { calculateTextWidth } from '../../pages/helpers';
import { Event } from '../../schema/events/event';
import { TableCreate } from '../../schema/events/table.create';
import { TableSnapshot } from '../../schema/events/table.snapshot';
import { TableUpdate } from '../../schema/events/table.update';
import { DescriptionPopover } from '../Popovers/DescriptionPopover';
import ChangeDataCaptureView from './EventViews/ChangeDataCaptureView';
import CompactionView from './EventViews/CompactionView';
import DataLifecycleView from './EventViews/DataLifecycleView';
import ExpireSnapshotsView from './EventViews/ExpireSnapshotsView';
import FileLoadView from './EventViews/FileLoadView';
import FilesCleanupView from './EventViews/FilesCleanupView';
import RewriteManifestsView from './EventViews/RewriteManifestsView';
import TableCreateView from './EventViews/TableCreateView';
import TableSnapshotView from './EventViews/TableSnapshotView';
import TableUpdateView from './EventViews/TableUpdateView';
import { TableEventTypes, Verbosity } from './TableEventsView';

type Props = {
  event: Event;
  table: Table;
  verbosity?: Verbosity;
  columnSize: number;
  detailPanelView?: boolean;
};
export default function TableEventView({
  event,
  table,
  verbosity = Verbosity.Summary,
  columnSize,
  detailPanelView,
}: Props) {
  switch (event.type) {
    case TableEventTypes.TABLE_UPDATE:
      return (
        <TableUpdateView
          event={event as TableUpdate}
          table={table}
          verbosity={verbosity}
        />
      );
    case TableEventTypes.FAILED_FILES_CLEANUP:
    case TableEventTypes.RUN_FILES_CLEANUP:
    case TableEventTypes.STARTED_FILES_CLEANUP:
    case TableEventTypes.SUCCEEDED_FILES_CLEANUP:
      return (
        <FilesCleanupView
          event={event as Event}
          table={table}
          verbosity={verbosity}
        />
      );
    case TableEventTypes.TABLE_SNAPSHOT:
      return (
        <TableSnapshotView
          event={event as TableSnapshot}
          table={table}
          verbosity={verbosity}
          columnSize={columnSize}
        />
      );
    case TableEventTypes.TABLE_CREATE:
      return (
        <TableCreateView
          event={event as TableCreate}
          table={table}
          verbosity={verbosity}
        />
      );
    case TableEventTypes.CANCELLED_COMPACTION:
    case TableEventTypes.FAILED_COMPACTION:
    case TableEventTypes.RUN_COMPACTION:
    case TableEventTypes.STARTED_COMPACTION:
    case TableEventTypes.SUCCEEDED_COMPACTION:
      return (
        <CompactionView
          event={event as Event}
          table={table}
          verbosity={verbosity}
        />
      );
    case TableEventTypes.FAILED_EXPIRE_SNAPSHOTS:
    case TableEventTypes.RUN_EXPIRE_SNAPSHOTS:
    case TableEventTypes.STARTED_EXPIRE_SNAPSHOTS:
    case TableEventTypes.SUCCEEDED_EXPIRE_SNAPSHOTS:
      return (
        <ExpireSnapshotsView
          event={event as Event}
          table={table}
          verbosity={verbosity}
        />
      );
    case TableEventTypes.TABLE_ANALYZE:
      return <div>Table was analyzed</div>;
    case TableEventTypes.FAILED_DATA_LIFECYCLE:
    case TableEventTypes.RUN_DATA_LIFECYCLE:
    case TableEventTypes.STARTED_DATA_LIFECYCLE:
    case TableEventTypes.SUCCEEDED_DATA_LIFECYCLE:
      return (
        <DataLifecycleView
          event={event as Event}
          table={table}
          verbosity={verbosity}
        />
      );
    case TableEventTypes.FAILED_REWRITE_MANIFESTS:
    case TableEventTypes.RUN_REWRITE_MANIFESTS:
    case TableEventTypes.STARTED_REWRITE_MANIFESTS:
    case TableEventTypes.SUCCEEDED_REWRITE_MANIFESTS:
      return (
        <RewriteManifestsView
          event={event as Event}
          table={table}
          verbosity={verbosity}
        />
      );
    case TableEventTypes.TABLE_DELETE:
      return <div>Table was deleted</div>;
    case TableEventTypes.TABLE_DROP:
      return <div>Table was dropped</div>;
    case TableEventTypes.TABLE_RENAME:
      return <div>Table was renamed</div>;
    case TableEventTypes.TABLE_SCAN_REPORT:
      return <div>Table scan report delivered</div>;
    case TableEventTypes.TABLE_COMMIT_REPORT:
      return <div>Table commit report delivered</div>;
    case TableEventTypes.FAILED_CHANGE_DATA_CAPTURE:
    case TableEventTypes.RUN_CHANGE_DATA_CAPTURE:
    case TableEventTypes.STARTED_CHANGE_DATA_CAPTURE:
    case TableEventTypes.SUCCEEDED_CHANGE_DATA_CAPTURE:
      return (
        <ChangeDataCaptureView
          event={event as Event}
          table={table}
          verbosity={verbosity}
        />
      );
    case TableEventTypes.FAILED_FILE_LOAD:
    case TableEventTypes.RUN_FILE_LOAD:
    case TableEventTypes.STARTED_FILE_LOAD:
    case TableEventTypes.SUCCEEDED_FILE_LOAD:
      return (
        <FileLoadView
          event={event as Event}
          table={table}
          verbosity={verbosity}
        />
      );
    default:
      const textWidth = calculateTextWidth(
        JSON.stringify(event),
        '18px Source Sans Pro',
        true,
      );
      const textStyle =
        columnSize === undefined
          ? {}
          : {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            };
      const informativeTooltip = textWidth >= 8000;
      const fullTextTooltip = textWidth >= columnSize;
      return (
        <PopupState variant="popper">
          {(popupState) => (
            <>
              {detailPanelView ? (
                <>
                  <Box
                    padding={2}
                    sx={{ fontSize: '14px', backgroundColor: 'midnight.half' }}
                  >
                    <pre>{JSON.stringify(event, null, 2)}</pre>
                  </Box>
                </>
              ) : (
                <Typography sx={textStyle} {...bindHover(popupState)}>
                  {JSON.stringify(event)}
                </Typography>
              )}

              {fullTextTooltip && !informativeTooltip && (
                <Popper {...bindPopper(popupState)} placement={`top-start`}>
                  <DescriptionPopover description={JSON.stringify(event)} />
                </Popper>
              )}
              {informativeTooltip && fullTextTooltip && (
                <Popper {...bindPopper(popupState)} placement={`top-start`}>
                  <DescriptionPopover
                    description={'Expand to see full details'}
                  />
                </Popper>
              )}
            </>
          )}
        </PopupState>
      );
  }
}
