import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoginIcon from '@mui/icons-material/Login';
import { Box, Typography } from '@mui/material';

import { ROUTES } from '../../RouteTable';
import { useAuth } from '../../context/auth-context';
import { getLogger } from '../../utils/logging';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';

const logger = getLogger('Login.MagicLogin' /*FUTURE import.meta.url ?*/);

export default function MagicLogin({
  buttonContent,
  buttonDisabled = false,
  ...props
}: {
  buttonContent?: any;
  buttonDisabled?: boolean;
}) {
  const { loginWithToken, lastError, clearLastError } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const magicToken = searchParams.get('mt');

  const handleMagicSignIn = async (magicToken: string) => {
    await loginWithToken(magicToken)
      .then(() => {})
      .catch((e: any) => {
        logger.error('Problem during magic login.', e);
      });
  };

  useEffect(() => {
    if (magicToken && magicToken != '' && !lastError) {
      //run the magic login only one time and 1 second after the last rendering using the final component state
      const main = window as any;
      if (main.debouncedLogin) {
        main.clearTimeout(main.debouncedLogin);
      }
      main.debouncedLogin = setTimeout(() => {
        handleMagicSignIn(magicToken).catch((e) => {
          logger.debug('Unhandled ex during magic login.');
        });
      }, 250);
    }
  }, [magicToken, lastError]);

  return (
    <>
      {lastError && (
        <>
          <Talert
            severity={`warning`}
            TabuttonProps={{
              children: <Box>REQUEST LOG IN</Box>,
              size: 'small',
              onClick: () => navigate(ROUTES.login),
            }}
            sx={{ maxWidth: 400 }}
          >
            <Typography variant={`helperText`} sx={{ color: 'midnight.seven' }}>
              Request another login email?
            </Typography>
          </Talert>
        </>
      )}
      {buttonContent && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tabutton
            type="submit"
            variant="contained"
            disabled={buttonDisabled}
            endIcon={<LoginIcon />}
          >
            {buttonContent}
          </Tabutton>
        </Box>
      )}
    </>
  );
}
