import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Tooltip } from '@mui/material';

export const EditResource = ({
  editResource,
  title,
  disabled,
  ...props
}: {
  editResource: any;
  title: string;
  disabled?: boolean;
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        paddingLeft: 2,
      }}
    >
      <Tooltip title={title} placement={'top'} arrow>
        <IconButton
          sx={(theme) => ({
            padding: '4px',
            border: `1px solid ${theme.palette.midnight.two}`,
            '&:hover': {
              backgroundColor: theme.palette.sky.one,
            },
            '&:active': {
              backgroundColor: theme.palette.sky.two,
            },
            '&:focus': {
              border: `1px solid ${theme.palette.sky.seven}`,
            },
          })}
          onClick={() => editResource()}
          disabled={disabled}
          {...props}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
