import * as React from 'react';

import { Typography, Box, Stack, Divider } from '@mui/material';

type Props = {
  title: string;
  listItems: string[];
  overflowItem?: string;
};
export const ListPopover = React.forwardRef(
  ({ title, listItems, overflowItem, ...props }: Props, ref) => {
    return (
      <Box
        ref={ref}
        {...props}
        sx={(theme) => ({
          backgroundColor: 'popper.main',
          color: 'popper.contrastText',
          borderRadius: '4px',
          position: 'relative',
          boxShadow: theme.shadows[3],
          ml: '10px',
        })}
      >
        <Stack direction={`column`}>
          <Box
            display={`flex`}
            alignItems={`center`}
            sx={{
              py: 1,
              px: 2,
              backgroundColor: 'popper.one',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
            }}
          >
            <Typography sx={{ mr: 1 }} variant={`caption`} color={''}>
              {title}
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: 'popper.one' }} />
          {listItems.map((item, index) => {
            const lastItem = listItems.length - 1;
            return (
              <Box key={item + index}>
                <Box
                  display={`flex`}
                  alignItems={`center`}
                  sx={{ py: 1, px: 2 }}
                >
                  <Typography variant={`body2`}>{item}</Typography>
                </Box>
                {lastItem != index && (
                  <Divider
                    sx={{
                      backgroundColor: 'popper.one',
                    }}
                  />
                )}
              </Box>
            );
          })}
          {overflowItem && (
            <Box>
              <Divider
                sx={{
                  backgroundColor: 'popper.one',
                }}
              />
              <Box display={`flex`} alignItems={`center`} sx={{ py: 1, px: 2 }}>
                <Typography variant={`subtitle2`}>{overflowItem}</Typography>
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
    );
  },
);
