import * as Sentry from '@sentry/react';
import { useState, useEffect } from 'react';

import {
  split,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { SentryLink } from 'apollo-link-sentry';
import { makeBreadcrumb } from 'apollo-link-sentry/lib-cjs/breadcrumb';
import { createClient } from 'graphql-ws';

import { getApiUrl, getSubscriptionsUrl } from '../utils/api';

const SEVERITY_ERROR = 'error';
const SEVERITY_INFO = 'info';

export default function useApolloClient() {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true';

  useEffect(() => {
    async function init() {
      const apiUrl = getApiUrl();
      const subscriptionsUrl = getSubscriptionsUrl();

      const httpLink = createHttpLink({
        uri: apiUrl,
        credentials: process.env.REACT_APP_API_CREDENTIALS,
      });

      const wsLink = new GraphQLWsLink(
        createClient({
          url: subscriptionsUrl,
          connectionParams: {
            authToken: 'my_token',
          },
        }),
      );

      // The split function takes three parameters:
      //
      // * A function that's called for each operation to execute
      // * The Link to use for an operation if the function returns a "truthy" value
      // * The Link to use for an operation if the function returns a "falsy" value
      const splitLink = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink,
      );

      const sentryLinkOptions = {
        uri: apiUrl,
        setTransaction: true,
        setFingerprint: true,
        attachBreadcrumbs: {
          includeError: true,
          includeVariables: true,
          transform: (breadcrumb: any) => {
            if (breadcrumb.data.variables?.email) {
              breadcrumb.data.variables.email = 'MASKED_EMAIL';
            }
            if (breadcrumb.data.variables?.password) {
              breadcrumb.data.variables.password = 'MASKED_PASSWORD';
            }
            return breadcrumb;
          },
        },
      };
      let links = [];
      if (sentryEnabled) {
        const sentryLink = new SentryLink(sentryLinkOptions);

        const errorLink = onError(
          ({ graphQLErrors, networkError, response, operation }) => {
            // Hack to ensure that the current graphql request is added as a breadcrumb (this may cause duplicates in future sentry events)
            // https://github.com/DiederikvandenB/apollo-link-sentry/issues/420
            Sentry.addBreadcrumb(
              makeBreadcrumb(operation, sentryLinkOptions as any),
            );
            let isAuthorizationError = false;

            graphQLErrors?.forEach((error) => {
              const { message, extensions } = error;

              if (extensions && extensions.code === 'UNAUTHENTICATED') {
                isAuthorizationError = true;
                Sentry.captureMessage(message, {
                  level: SEVERITY_INFO,
                  contexts: { graphQLError: { extensions: extensions } },
                });
              } else {
                Sentry.captureMessage(message, {
                  level: SEVERITY_ERROR,
                  contexts: { graphQLError: { extensions: extensions } },
                });
              }
            });

            if (networkError) {
              Sentry.captureMessage(networkError.message, {
                level: SEVERITY_ERROR,
                contexts: {
                  apolloNetworkError: {
                    error: networkError,
                  },
                },
              });
            }

            // We expect to get 401 errors for CurrentUserQuery when we do not have a valid cookie / token
            // https://www.apollographql.com/docs/react/api/link/apollo-link-error/#ignoring-errors
            if (
              operation.operationName === 'CurrentUserQuery' &&
              isAuthorizationError &&
              response
            ) {
              response.errors = undefined;
            }
          },
        );
        links.push(errorLink);
        links.push(sentryLink);
      } else {
        const errorLink = onError(
          ({ graphQLErrors, networkError, response, operation }) => {
            // We expect to get 401 errors for CurrentUserQuery when we do not have a valid cookie / token
            // https://www.apollographql.com/docs/react/api/link/apollo-link-error/#ignoring-errors

            let isAuthorizationError = false;

            graphQLErrors?.forEach((error) => {
              const { message, extensions } = error;

              if (extensions && extensions.code === 'UNAUTHENTICATED') {
                isAuthorizationError = true;
              }
            });

            if (
              operation.operationName === 'CurrentUserQuery' &&
              isAuthorizationError &&
              response
            ) {
              response.errors = undefined;
            }
          },
        );
        links.push(errorLink);
      }

      links.push(splitLink);

      // Setup cache and persistence to local storage for managing app-local state (e.g. current org context)
      const cache = new InMemoryCache({
        typePolicies: {
          Role: {
            fields: {
              isBuiltIn: {
                read(_, { readField }) {
                  return ['EVERYONE', 'SECURITY_ADMIN', 'ORG_ADMIN'].includes(
                    readField('name') as string,
                  );
                },
              },
            },
          },
          RoleMember: {
            keyFields: ['roleId', 'id'],
          },
          Database: {
            keyFields: ['warehouseId', 'name'],
          },
          Table: {
            keyFields: ['warehouseId', 'databaseName', 'name'],
          },
          TableExt: {
            keyFields: ['id'],
          },
          TableMetadata: {
            keyFields: ['table_uuid'],
          },
          SchemaField: {
            keyFields: false, // prevent client-side caching as SchemaField id is not unique across tables
          },
        },
      });

      setClient(
        new ApolloClient({
          connectToDevTools: process.env.REACT_APP_ENV !== 'prod',
          link: from(links),
          cache,
        }),
      );
    }

    init().catch(Sentry.captureException);
  }, []);

  return client;
}
