import React from 'react';

import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';

import { User } from '../../graphql/gen/graphql';
import SendFeedback from '../Forms/SendFeedback';

interface SendFeedbackDialogProps {
  user: User;
}
const SendFeedbackDialog = create(({ user }: SendFeedbackDialogProps) => {
  const modal = useModal();

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <SendFeedback user={user} onCancel={modal.hide} />
    </Dialog>
  );
});

export default SendFeedbackDialog;

export function showSendFeedbackDialog(user: User) {
  return show<boolean, any, SendFeedbackDialogProps>(SendFeedbackDialog, {
    user,
  });
}
