import React from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Organization } from '../../graphql/gen/graphql';
import { organizationById } from '../../graphql/organization';
import { Tabutton } from '../Button/Tabutton';
import TextField from './TextField';

const INVITE_USER = gql`
  mutation InviteUser(
    $email: String!
    $displayName: String
    $organizationId: String
  ) {
    inviteUser(
      email: $email
      displayName: $displayName
      organizationId: $organizationId
    ) {
      id
      email
      organizationId
      status
    }
  }
`;

interface FormUser {
  email: string;
  name?: string;
}
interface FormValues {
  users: FormUser[];
}

interface InviteUsersProps {
  organization: Organization;
  onCancel: () => void;
  onUsersInvited: () => void;
}
export default function InviteUsers({
  organization,
  onCancel,
  onUsersInvited,
}: InviteUsersProps) {
  const [loadOrg, _] = useLazyQuery(organizationById, {
    variables: {
      organizationId: organization.id,
    },
  });
  const [inviteUsersMutation, { error, reset }] = useMutation(INVITE_USER);

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    const invitations = await Promise.allSettled(
      values.users.map((user: { email: string }) =>
        inviteUsersMutation({
          variables: {
            email: user.email,
            displayName: user.email,
            organizationId: organization.id,
          },
        }),
      ),
    );
    await loadOrg();
    reset();
    onUsersInvited();
    setSubmitting(false);
  };

  const initialValues: FormValues = {
    users: [
      {
        email: '',
      },
    ],
  };

  const validationSchema = Yup.object({
    users: Yup.array().of(
      Yup.object({
        email: Yup.string()
          .email('Email is invalid')
          .required('Email is required'),
      }),
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          {/*{ error ? <Alert severity="error" onClose={() => reset()}>Error: {error.message}</Alert> : null }*/}
          <DialogTitle>
            Invite team members to {organization.displayName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              An invite is specific the email address(es) provided below.
            </DialogContentText>
            <FieldArray name="users">
              {({ remove, push }) => (
                <Grid container spacing={1}>
                  {values.users.map((friend, index) => (
                    <Grid
                      key={`${friend.name}-${index}`}
                      container
                      item
                      spacing={1}
                      sx={{ alignItems: 'center' }}
                    >
                      <Grid item xs={10}>
                        <TextField
                          name={`users.${index}.email`}
                          type="email"
                          label="Email"
                          id={`users.${index}.email`}
                          variant="outlined"
                          margin="normal"
                          autoComplete="off"
                          required
                          fullWidth
                          autoFocus
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      {values.users.length > 1 && (
                        <Grid item xs={2}>
                          <IconButton
                            aria-label="delete"
                            onClick={() => remove(index)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Tabutton onClick={() => push({ email: '' })}>
                      Add another team member
                    </Tabutton>
                  </Grid>
                </Grid>
              )}
            </FieldArray>
          </DialogContent>
          <DialogActions>
            <Tabutton onClick={onCancel}>Cancel</Tabutton>
            <Tabutton type="submit" variant="contained" disabled={isSubmitting}>
              Invite
            </Tabutton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
