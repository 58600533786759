import React from 'react';

import { Box, BoxProps, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { compact, concat, groupBy, isEqual, join, some, uniqBy } from 'lodash';
import * as Yup from 'yup';

import {
  AuthDecisionResourceType,
  Authorization,
  GrantRequest,
  Label,
  LabelMaskMode,
  LabelResourceType,
  Privilege,
  SearchRoleResult,
} from '../../graphql/gen/graphql';
import { PrivilegeSelected } from '../AccessControl/RolePrivileges';

export interface BlendedLabelType extends Label {
  rolesByPrivilege: JSON;
}

export type RoleByPrivilegeType = {
  id: string;
  name: string;
};

export interface TabPanelProps extends BoxProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface RoleSearchParams {
  organizationId: string;
  query: string;
  maxResults: number;
  searchAfter: string[] | undefined;
  sortBy: any[];
  excludeSystemRoles: boolean;
}

export type LabelAccessRoleType = {
  roleId: string;
  roleName: string;
  privileges: PrivilegeSelected[];
};

export type InitialValueModeType = {
  enabled: boolean;
  value: LabelMaskMode;
};

export type LabelFormValuesType = {
  name: string;
  description: string;
  scope: string | LabelResourceType;
  mode: InitialValueModeType;
  exemptRoles: [SearchRoleResult];
  labelAccess?: [LabelAccessRoleType] | any[];
  createPrivs?: PrivilegeSelected[];
  updatePrivs?: PrivilegeSelected[];
  deletePrivs?: PrivilegeSelected[];
};

export const LabelPropertyOptions = {
  FIELD_MASKING: 'FIELD_MASKING',
};

export const LabelScopeDisplay = {
  FIELD: 'Columns',
  DATABASE: 'Databases',
  ROLE: 'Roles',
  STORAGE_PROFILE: 'Storage profiles',
  TABLE: 'Tables',
  WAREHOUSE: 'Warehouses',
};

export const getLabelScopeDisplay = (scopeArray: string[]) => {
  const values = scopeArray.map((scopeValue: string) => {
    //@ts-ignore
    return LabelScopeDisplay[scopeValue];
  });
  return join(values, ', ');
};

export const createLabelValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required('Label name is required.')
    .matches(
      /^[a-zA-Z0-9_]*$/,
      'Label name may only contain alpha-numeric characters and ' +
        "underscores ('_') and may not contain any spaces.",
    )
    .max(127, 'Label names have a maximum length of 127 characters.'),
  description: Yup.string()
    .trim()
    .max(280, 'Label descriptions have a maximum length of 280 characters.'),
  scope: Yup.string().required('Scope is required.'),
  exemptRoles: Yup.array().of(Yup.object().nullable()),
  labelAccess: Yup.array().of(Yup.object().nullable()),
  mode: Yup.object({
    enabled: Yup.boolean(),
    value: Yup.string().when('enabled', {
      is: true,
      then: Yup.string()
        .required('Mask mode is required when column masking is enabled.')
        .oneOf(['NULL', 'HIDE']),
      otherwise: Yup.string(),
    }),
  }),
});

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, sx, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={[{ pt: 3 }, sx as SystemStyleObject]} {...other}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const compileGrantsList = (createPrivs: PrivilegeSelected[]) => {
  let grantsList: GrantRequest[] = [];
  createPrivs.forEach((role) => {
    grantsList.push({
      privilege: role.privilege,
      roleId: role.roleId,
      withGrant: role.withGrant,
    });
  });

  return grantsList;
};

export const compileAccessControls = (
  labelId: string,
  rolesByPrivilege: JSON,
  labelAuths: Authorization[],
  securityAdminDefault: any,
) => {
  //@ts-ignore
  const grantGuys = rolesByPrivilege?.[Privilege.ManageGrants]?.map(
    (role: RoleByPrivilegeType) => {
      return {
        roleId: role.id,
        roleName: role.name,
      };
    },
  );
  //@ts-ignore
  const modifyGuys = rolesByPrivilege?.[Privilege.ModifyLabel]?.map(
    (role: RoleByPrivilegeType) => {
      return {
        roleId: role.id,
        roleName: role.name,
      };
    },
  );
  //@ts-ignore
  const applyGuys = rolesByPrivilege?.[Privilege.ApplyLabel]?.map(
    (role: RoleByPrivilegeType) => {
      return {
        roleId: role.id,
        roleName: role.name,
      };
    },
  );

  const privilegesWithRoleName = uniqBy(
    compact(concat(grantGuys, modifyGuys, applyGuys)),
    'roleId',
  );

  const formattedLabelAuths = labelAuths?.map((auth) => {
    return {
      id: auth?.id,
      privilege: auth?.privilege,
      resourceType: auth?.resourceType,
      roleId: auth?.subjectId,
      withGrant: auth?.withGrant,
      selected: true,
    };
  });

  const securityDefaultIncluded = compact(
    concat(formattedLabelAuths, securityAdminDefault?.authorizations),
  );

  const groupByRole = groupBy(securityDefaultIncluded, 'roleId');
  return Object.keys(groupByRole).map(function (key) {
    const roleName =
      privilegesWithRoleName.find((role) => role.roleId === key)?.roleName ||
      groupByRole[key][0]?.roleName;
    roleName &&
      groupByRole[key].forEach((group: any) => {
        group['roleName'] = roleName;
        group['roleId'] = key;
      });
    return {
      roleName: roleName,
      roleId: key,
      privileges: [...groupByRole[key]],
    };
  });
};

export const labelOverviewChanges = (
  values: LabelFormValuesType,
  initialValues: LabelFormValuesType,
) => {
  return (
    !isEqual(values.name, initialValues.name) ||
    !isEqual(values.description, initialValues.description) ||
    !isEqual(values.scope, initialValues.scope)
  );
};

export const columnMaskingChanges = (
  values: LabelFormValuesType,
  initialValues: LabelFormValuesType,
) => {
  const changed = !isEqual(values.mode, initialValues.mode);
  const newValue = !values.mode.enabled
    ? LabelMaskMode.None
    : values.mode.value;

  return { changed, newValue };
};

export const authorizationChanges = (
  values: LabelFormValuesType,
  initialValuesExemptRoles: [SearchRoleResult],
  initialValuesLabelAccess: [LabelAccessRoleType] | any[],
  labelId: string,
) => {
  const exemptRolesChanged = !isEqual(
    values.exemptRoles,
    initialValuesExemptRoles,
  );
  const accessChanged = !isEqual(values.labelAccess, initialValuesLabelAccess);
  let authsToCreate: any[] = [];
  let authsToDelete: any[] = [];
  let authsToUpdate: any[] = [];

  if (exemptRolesChanged) {
    const newAuths = values.exemptRoles
      ?.filter(
        (role) =>
          !some(
            initialValuesExemptRoles,
            (ogRole) => ogRole.roleId === role.roleId,
          ),
      )
      ?.map((newGuy) => {
        return {
          privilege: Privilege.Select,
          roleId: newGuy.roleId,
          resource: labelId,
          resourceType: AuthDecisionResourceType.Label,
          withGrant: false,
        };
      });
    authsToCreate = concat(authsToCreate, compact(newAuths));

    const oldGuys = initialValuesExemptRoles
      ?.filter(
        (ogRole) =>
          !some(values.exemptRoles, (role) => ogRole.roleId === role.roleId),
      )
      ?.map((oldGuy) => {
        return {
          privilege: Privilege.Select,
          roleId: oldGuy.roleId,
          resource: labelId,
          resourceType: AuthDecisionResourceType.Label,
          withGrant: false,
        };
      });
    authsToDelete = concat(authsToDelete, compact(oldGuys));
  }

  if (accessChanged) {
    const createAuths =
      values.createPrivs &&
      values.createPrivs?.map((priv) => {
        return {
          privilege: priv.privilege,
          resource: labelId,
          resourceType: AuthDecisionResourceType.Label,
          roleId: priv.roleId,
          withGrant: priv.withGrant,
        };
      });
    authsToCreate = concat(authsToCreate, compact(createAuths));

    const deleteAuths =
      values.deletePrivs &&
      values.deletePrivs?.map((priv) => {
        return {
          privilege: priv.privilege,
          resource: labelId,
          resourceType: AuthDecisionResourceType.Label,
          roleId: priv.roleId,
          withGrant: priv.withGrant,
        };
      });
    authsToDelete = concat(authsToDelete, compact(deleteAuths));
    const updateAuths =
      values.updatePrivs &&
      values.updatePrivs?.map((updatedPriv) => {
        const auth = initialValuesLabelAccess
          .find((ogPriv) => ogPriv.roleId === updatedPriv.roleId)
          .privileges.find(
            (priv: any) => priv.privilege === updatedPriv.privilege,
          )?.id;
        if (auth) {
          return {
            authorizationId: auth,
            privilege: updatedPriv.privilege,
            resource: labelId,
            resourceType: AuthDecisionResourceType.Label,
            roleId: updatedPriv.roleId,
            withGrant: updatedPriv.withGrant,
          };
        }
        return {};
      });
    authsToUpdate = concat(authsToUpdate, compact(updateAuths));
  }

  return {
    changed: exemptRolesChanged || accessChanged,
    auths: [authsToCreate, authsToDelete, authsToUpdate],
  };
};
