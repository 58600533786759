import React from 'react';

import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CardHeader, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { Warehouse } from '../../graphql/gen/graphql';
import ComputeGrid from '../Compute/ComputeGrid';

interface SelectWizardDialogProps {
  warehouses?: Warehouse[];
  refetch?: any;
}
const SelectWizardDialog = create(
  ({ warehouses, refetch }: SelectWizardDialogProps) => {
    const modal = useModal();

    return (
      <Dialog
        open={modal.visible}
        scroll={'paper'}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '500px',
            backgroundColor: 'controlBackground.main',
          }}
        >
          <Box display={'flex'} justifyContent={'space-between'} p={1}>
            <CardHeader
              sx={{ p: 0, ml: 1, mt: 1 }}
              title={'Select compute engine'}
            />
            <IconButton onClick={() => modal.hide()}>
              <CloseIcon
                fontSize={'small'}
                sx={{ color: 'midnight.six', pointer: 'cursor' }}
              />
            </IconButton>
          </Box>
          <ComputeGrid
            warehouses={warehouses || []}
            modalView
            handleClose={() => modal.hide()}
            refetch={refetch}
          />
        </Box>
      </Dialog>
    );
  },
);

export default SelectWizardDialog;

export function showSelectWizardDialog(
  warehouses?: Warehouse[],
  refetch?: any,
) {
  return show<boolean, any, SelectWizardDialogProps>(SelectWizardDialog, {
    warehouses,
    refetch,
  });
}
