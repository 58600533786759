import * as React from 'react';

import { Typography, Box, Stack } from '@mui/material';

type Props = {
  description: string;
  name: string;
  leftPlacement?: boolean;
};
export const LabelPopover = React.forwardRef(
  ({ description, name, leftPlacement, ...props }: Props, ref) => {
    const arrowPositionProps: any = {};
    if (leftPlacement) {
      arrowPositionProps.right = -6;
      arrowPositionProps.top = 'calc(50% - 6px)';
      arrowPositionProps.backgroundColor = 'popper.main';
    } else {
      arrowPositionProps.bottom = -6;
      arrowPositionProps.right = '50%';
      arrowPositionProps.backgroundColor = 'popper.main';
    }

    return (
      <Box
        ref={ref}
        {...props}
        sx={(theme) => ({
          backgroundColor: 'popper.main',
          color: 'popper.contrastText',
          borderRadius: '4px',
          maxWidth: '400px',
          position: 'relative',
          boxShadow: theme.shadows[3],
          mb: leftPlacement ? '0' : '10px',
          mr: leftPlacement ? '12px' : '0',
        })}
      >
        <Stack direction={`column`}>
          <>
            <Box display={'flex'} flexDirection={'row'}>
              <Box
                sx={{
                  width: '100px',
                  borderTopLeftRadius: '4px',
                  backgroundColor: 'popper.one',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{ mr: 1, wordBreak: 'break-word' }}
                  variant={`caption`}
                >
                  Name:
                </Typography>
              </Box>
              <Box
                sx={{
                  py: 1,
                  px: 1,
                  backgroundColor: 'popper.main',
                  width: '240px',
                  borderTopRightRadius: '4px',
                  wordBreak: 'break-word',
                }}
              >
                <Typography variant={`body2`}>{name}</Typography>
              </Box>
            </Box>
            <Box display={'flex'} flexDirection={'row'}>
              <Box
                sx={{
                  width: '100px',
                  borderBottomLeftRadius: '4px',
                  backgroundColor: 'popper.one',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ mr: 1 }} variant={`caption`}>
                  Description:
                </Typography>
              </Box>
              <Box
                sx={{
                  px: 1,
                  py: 1,
                  backgroundColor: 'popper.main',
                  width: '240px',
                  borderBottomRightRadius: '4px',
                  display: 'flex',
                }}
              >
                <Typography variant={`body2`}>{description || '--'}</Typography>
              </Box>
            </Box>
          </>
        </Stack>
      </Box>
    );
  },
);
