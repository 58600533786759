import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import { AddOutlined } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { enqueueSnackbar } from 'notistack';

import { getUserFriendlyErrorMessage } from '../../components/AccessControl/common';
import StorageProfilesGrid from '../../components/CreateStorageBucket/StorageProfilesGrid';
import { DropdownMenuContent } from '../../components/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenuItem';
import { showCreateStorageBucketDialog } from '../../components/Modals/CreateStorageBucketDialog';
import { showTypeDeleteDialog } from '../../components/Modals/TypeDeleteDialog';
import { getAuthorizationDecision } from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  AuthDecisionSubjectType,
  Privilege,
} from '../../graphql/gen/graphql';
import {
  deleteStorageProfile,
  getOrgStorageProfiles,
} from '../../graphql/organization';

export default function Storage({
  orgName,
  organizationId,
  isSecurityAdmin,
  user,
}: {
  orgName: string;
  organizationId: string;
  isSecurityAdmin: boolean;
  user: any;
}) {
  const { organization: organizationName } = useParams();
  const [storageToDelete, setStorageToDelete] = useState({} as any);

  const {
    data,
    loading,
    refetch: refetchStorage,
  } = useQuery(getOrgStorageProfiles, {
    variables: {
      organizationId,
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const { data: grantDecision } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        privileges: [Privilege.CreateWarehouse],
        requirement: 'ALL',
        resource: {
          type: AuthDecisionResourceType.Organization,
          identifier: organizationId,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const hasCreateWarehouse =
    grantDecision?.authDecision === AuthDecisionResponseType.Allow;

  const handleRefresh = async () => {
    await refetchStorage();
  };

  const [deleteStorage] = useMutation(deleteStorageProfile, {
    variables: {
      id: storageToDelete.id,
    },
    onCompleted: async () => {
      await handleRefresh();
      setStorageToDelete({} as any);
      enqueueSnackbar('Storage profile successfully deleted', {
        variant: 'success',
      });
    },
    onError: (e) => {
      enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
        variant: 'error',
      });
    },
  });

  const handleStorageDelete = (profile: any) => {
    setStorageToDelete(profile);
    showTypeDeleteDialog({
      title: `Drop ${profile?.bucket} storage profile`,
      confirmText: `Drop storage profile`,
      recoveryWarningText: `This will drop the storage profile and metadata from Tabular, but will not alter data or metadata files in your object store.`,
    }).then(() => deleteStorage());
  };

  const handleCreateStorageBucket = async () => {
    showCreateStorageBucketDialog(organizationName || '')
      .then((storageProfile) => {
        handleRefresh();
      })
      .catch((error) => {
        if (error) {
          enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
            variant: 'error',
          });
        }
      });
  };

  const dashboardDropdownContent = useMemo(
    () => (
      <DropdownMenuContent
        menuItems={[
          <DropdownMenuItem
            key={'refresh'}
            showAsMenuButton
            onClick={() => {
              handleRefresh();
            }}
            Icon={RefreshIcon}
            titleText={'Refresh'}
          />,
          <DropdownMenuItem
            key={'createStorageBucket'}
            showAsMenuButton
            onClick={() => handleCreateStorageBucket()}
            Icon={AddOutlined}
            titleText={'Create storage profile'}
            disabled={!hasCreateWarehouse}
          />,
        ]}
      />
    ),
    [data, organizationId, hasCreateWarehouse],
  );

  const addResourceContent = {
    title: 'Create storage profile',
    action: () => handleCreateStorageBucket(),
    disabled: !hasCreateWarehouse,
  };

  const refreshResourceContent = {
    title: 'Refresh storage profile list',
    action: () => handleRefresh(),
  };

  return (
    <>
      <StorageProfilesGrid
        storageProfiles={data?.organization?.storageProfiles}
        onStorageDelete={handleStorageDelete}
        loading={loading}
        refreshResourceContent={refreshResourceContent}
        addResourceContent={addResourceContent}
        isSecurityAdmin={isSecurityAdmin}
        dashboardMenuContent={dashboardDropdownContent}
        orgName={organizationName || ''}
        user={user}
      />
    </>
  );
}
