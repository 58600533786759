import { CustomIconProps } from '../components/Icons/CustomIcon';

export function computeSlots<SlotComponents extends object>({
  defaultSlots,
  slots,
}: {
  defaultSlots: SlotComponents;
  slots?: Partial<SlotComponents>;
}): SlotComponents {
  const overrides = slots;

  if (!overrides || Object.keys(overrides).length === 0) {
    return defaultSlots;
  }

  const result = { ...defaultSlots };
  Object.keys(overrides).forEach((key) => {
    const k = key as keyof typeof overrides;

    if (overrides[k] !== undefined) {
      if (k === 'stateIcons') {
        const stateIcons: { [key: string]: CustomIconProps } = overrides[k] as {
          [key: string]: CustomIconProps;
        };
        const lowered = Object.assign(
          {},
          ...Object.keys(stateIcons).map((eachStateKey) => ({
            [eachStateKey.toLowerCase()]: stateIcons[eachStateKey],
          })),
        );
        result[k] = lowered as any;
      } else {
        result[k] = overrides[k] as any;
      }
    }
  });

  return result;
}
