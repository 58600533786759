import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useLazyQuery, useQuery } from '@apollo/client';
import { AddOutlined } from '@mui/icons-material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import RefreshIcon from '@mui/icons-material/Refresh';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {
  Box,
  ClickAwayListener,
  Popper,
  Skeleton,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import {
  KnownFeatureFlags,
  MainTabGroup,
} from '../../context/GlobalPropsContext';

import { toWarehouseRoot } from '../../RouteTable';
import { Talert } from '../../components/Alert/Talert';
import ComputeGrid from '../../components/Compute/ComputeGrid';
import { CreateOrgCredentialDialog } from '../../components/Credentials/Dialogs/CreateOrgCredentialDialog';
import { DropdownMenuContent } from '../../components/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenuItem';
import TabLayout from '../../components/Layouts/TabLayout';
import ListWarehouses from '../../components/ListWarehouses/ListWarehouses';
import { showCreateWarehouseDialog } from '../../components/Modals/CreateWarehouseDialog';
import { showSelectWizardDialog } from '../../components/Modals/SelectWizardDialog';
import OrgSettings from '../../components/OrgSettings/OrgSettings';
import { CompactionResultsPopover } from '../../components/Popovers/CompactionResultsPopover';
import SpecialTrash from '../../components/SpecialTrash/SpecialTrash';
import {
  SHOWED_INIT_EXPERIENCE_NAME,
  useAuth,
} from '../../context/auth-context';
import { getAuthorizationDecision } from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  Privilege,
} from '../../graphql/gen/graphql';
import {
  myOrganizationCredentials,
  organizationPageQuery,
  OrgDashboardCardsQuery,
} from '../../graphql/organization';
import { getLogger } from '../../utils/logging';
import { compactNumberFormatter } from '../../utils/numbers';
import Security from '../Connections/Security';
import Storage from '../Connections/Storage';
import { OrganizationAccessControls } from '../Organization/AccessControls';
import Labels from '../Organization/Labels';
import Members from '../Organization/Members';
import ProfilePage from '../Organization/ProfilePage';
import Roles from '../Organization/Roles';
import UsagePage from '../Organization/Usage';
import Pipelines from '../Pipelines/Pipelines';
import Services from '../Pipelines/Services';
import {
  getByteSize,
  getFileSize,
  getSizeDifferencePercentage,
  STATISTICS_DAYS_BACK,
} from '../helpers';

const logger = getLogger('pages.Organization' /*FUTURE import.meta.url ?*/);

export default function Organization() {
  const { user } = useAuth();
  const { organization: organizationPath } = useParams();
  const organizationName = organizationPath || 'unknown';
  const organizationId = user.getOrganization(organizationName)?.id;
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(
    location?.state ? location.state.snackbar : false,
  );
  const [openInitialExperienceToolTip, setOpenInitialExperienceToolTip] =
    React.useState(false);
  const [openInitialExperienceMenu, setOpenInitialExperienceMenu] =
    React.useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  const initalExperienceRawValue = window.sessionStorage.getItem(
    SHOWED_INIT_EXPERIENCE_NAME,
  );
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const navigate = useNavigate();
  const isSecurityAdmin = user.isSecurityAdmin(organizationName);
  const [getOrgPageData, { data, loading, error, refetch }] = useLazyQuery(
    organizationPageQuery,
    {
      variables: {
        organizationId,
        daysBack: STATISTICS_DAYS_BACK,
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  );

  const { data: myCredsData } = useQuery(myOrganizationCredentials, {
    variables: {
      organizationId,
    },
    errorPolicy: 'all',
  });

  const { data: dashboardData, refetch: refetchStats } = useQuery(
    OrgDashboardCardsQuery,
    {
      variables: {
        organizationId,
        daysBack: STATISTICS_DAYS_BACK,
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const { data: grantDecision } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: 'USER',
          identifier: user.id,
        },
        privileges: [Privilege.CreateWarehouse],
        requirement: 'ALL',
        resource: {
          type: AuthDecisionResourceType.Organization,
          identifier: organizationId,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const hasCreateWarehouse =
    grantDecision?.authDecision === AuthDecisionResponseType.Allow;
  const handleRefetch = () => {
    getOrgPageData().then(() => refetchStats());
  };

  useEffect(() => {
    if (initalExperienceRawValue === null) {
      return;
    }
    const initalExperienceCount = Number(initalExperienceRawValue);
    if (
      initalExperienceCount < 3 &&
      !openInitialExperienceMenu &&
      myCredsData?.myOrganizationCredentials?.length == 0
    ) {
      setOpenInitialExperienceMenu(true);
      window.setTimeout(() => {
        setOpenInitialExperienceToolTip(true);
      }, 850);
      window.sessionStorage.setItem(
        SHOWED_INIT_EXPERIENCE_NAME,
        String(initalExperienceCount + 1),
      );
    }
  }, [
    myCredsData,
    openInitialExperienceMenu,
    setOpenInitialExperienceMenu,
    setOpenInitialExperienceToolTip,
    initalExperienceRawValue,
  ]);

  useEffect(() => {
    if (organizationName !== user.loginSession.loggedInOrg.name) {
      logger.debug(
        `Delaying query for org data due to org login transition.current path org (${organizationName}) is not my logged in org(${user.loginSession.loggedInOrg.name}`,
      );
    } else {
      setInitialized(true);
      if (!data && !loading) {
        logger.debug('Querying for org data.');
        getOrgPageData();
      }
    }
  }, [organizationName, user.loginSession.loggedInOrg.name]);

  const orgDisplayName = useMemo(
    () => data?.organization?.displayName || organizationName,
    [user, data],
  );
  const userCanDrop = user.isOrganizationAdmin(organizationName);

  const dashboardStatistics = useMemo(
    () => dashboardData?.organization?.statistics,
    [dashboardData],
  );
  const dashboardCompaction = useMemo(
    () => dashboardData?.organization?.compactionSummary,
    [dashboardData],
  );
  const numberOfWarehouses = useMemo(
    () => data?.organization?.warehouses?.length,
    [data?.organization?.warehouses],
  );

  const handleTooltipClose = () => {
    setOpenInitialExperienceToolTip(false);
  };

  const handleCreateWarehouse = (orgName: string) => {
    return showCreateWarehouseDialog(
      orgName,
      (warehouse) =>
        warehouse?.name &&
        navigate(toWarehouseRoot(organizationName, warehouse.name)),
    );
  };

  const dashboardDropdownContent = useMemo(
    () => (
      <DropdownMenuContent
        menuItems={[
          <DropdownMenuItem
            key={'refresh'}
            showAsMenuButton
            titleText={'Refresh'}
            Icon={RefreshIcon}
            onClick={() => handleRefetch()}
          />,
          <DropdownMenuItem
            key={'createWarehouse'}
            showAsMenuButton
            titleText={'Create warehouse'}
            Icon={AddOutlined}
            onClick={() => handleCreateWarehouse(organizationName)}
            disabled={!hasCreateWarehouse}
          />,

          <DropdownMenuItem
            key={'manuallyCreate'}
            titleText={'Manually create a credential'}
            description={
              'Once you have a valid credential, you can configure various data compute engines.'
            }
            Icon={VpnKeyIcon}
            onClick={() => {
              setCreateDialogVisible(true);
            }}
          />,
          <ClickAwayListener onClickAway={handleTooltipClose} key={'tryItOut'}>
            <Box sx={{ mt: '0 !important' }}>
              <Tooltip
                onClose={handleTooltipClose}
                open={openInitialExperienceToolTip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                PopperProps={{
                  sx: {
                    zIndex: 10,
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'aurora.seven',
                    },
                  },
                }}
                title={
                  <Box>
                    <Typography variant={'button'}> Try it Out</Typography>
                  </Box>
                }
                arrow
                placement={'left'}
              >
                <span>
                  <DropdownMenuItem
                    titleText={'Connect to compute'}
                    description={
                      'Connect to Spark, Trino, Starburst, Athena, and more!'
                    }
                    Icon={AutoFixHighIcon}
                    onClick={() => {
                      showSelectWizardDialog(
                        data?.organization?.warehouses || [],
                        refetch,
                      ).then((res) => {});
                    }}
                  />
                </span>
              </Tooltip>
            </Box>
          </ClickAwayListener>,
        ]}
      />
    ),
    [
      data,
      openInitialExperienceToolTip,
      setOpenInitialExperienceToolTip,
      openInitialExperienceMenu,
      setOpenInitialExperienceMenu,
      copySuccess,
      organizationId,
    ],
  );

  const dashboardItems = useMemo(
    () => [
      {
        items: [
          {
            title: 'Warehouses',
            data: (
              <Typography variant={`subtitle1`}>
                {numberOfWarehouses
                  ? compactNumberFormatter.format(numberOfWarehouses)
                  : '--'}
              </Typography>
            ),
          },
          {
            title: 'Databases',
            data: (
              <Typography variant={`subtitle1`}>
                {dashboardStatistics?.totalNamespaces
                  ? compactNumberFormatter.format(
                      dashboardStatistics.totalNamespaces,
                    )
                  : '--'}
              </Typography>
            ),
          },
          {
            title: 'Tables',
            data: (
              <Typography variant={`subtitle1`}>
                {dashboardStatistics?.totalTables
                  ? compactNumberFormatter.format(
                      dashboardStatistics.totalTables,
                    )
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: 'Total Size',
            data: (
              <Typography variant={`subtitle1`}>
                {getByteSize(dashboardStatistics?.totalBytes) || '--'}
              </Typography>
            ),
          },
          {
            title: `Last ${STATISTICS_DAYS_BACK} Days`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {dashboardStatistics?.totalBytes
                    ? getSizeDifferencePercentage(
                        dashboardStatistics?.bytesAdded,
                        dashboardStatistics?.bytesRemoved,
                        dashboardStatistics.totalBytes,
                      ).icon
                    : ''}
                  {dashboardStatistics?.totalBytes ? (
                    <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                      {
                        getSizeDifferencePercentage(
                          dashboardStatistics?.bytesAdded,
                          dashboardStatistics?.bytesRemoved,
                          dashboardStatistics.totalBytes,
                        ).value
                      }
                    </Typography>
                  ) : (
                    '--'
                  )}
                </Box>
              </Box>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: `File Compaction`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {dashboardCompaction?.filePercent ? (
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          {...bindHover(popupState)}
                        >
                          <SouthEastIcon fontSize={`small`} />
                          <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                            {`${dashboardCompaction.filePercent}%`}
                          </Typography>
                        </Box>
                        <Popper
                          {...bindPopper(popupState)}
                          placement={`bottom`}
                        >
                          <CompactionResultsPopover
                            type={`files`}
                            numberOfDays={STATISTICS_DAYS_BACK}
                            starting={getFileSize(
                              dashboardCompaction?.startingFiles,
                            )}
                            // @ts-ignore
                            rewritten={getFileSize(
                              dashboardCompaction?.rewrittenFiles,
                            )}
                            // @ts-ignore
                            total={getFileSize(
                              dashboardCompaction?.startingFiles -
                                dashboardCompaction?.rewrittenFiles,
                            )}
                            downward={true}
                            percent={`${dashboardCompaction?.filePercent}%`}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                ) : (
                  '--'
                )}
              </Box>
            ),
          },
          {
            title: `Storage Change`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {dashboardCompaction?.sizePercent ? (
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          {...bindHover(popupState)}
                        >
                          {dashboardCompaction.sizeChange < 0 ? (
                            <NorthEastIcon fontSize={`small`} />
                          ) : (
                            <SouthEastIcon fontSize={`small`} />
                          )}
                          <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                            {`${dashboardCompaction.sizePercent}%`}
                          </Typography>
                        </Box>
                        <Popper
                          {...bindPopper(popupState)}
                          placement={`bottom`}
                        >
                          <CompactionResultsPopover
                            type={`size`}
                            numberOfDays={STATISTICS_DAYS_BACK}
                            starting={getByteSize(
                              dashboardCompaction?.startingSize,
                            )}
                            rewritten={getByteSize(
                              dashboardCompaction?.rewrittenSize,
                            )}
                            total={
                              getByteSize(
                                dashboardCompaction?.startingSize -
                                  dashboardCompaction?.rewrittenSize,
                              ) || '--'
                            }
                            downward={dashboardCompaction.sizeChange > 0}
                            percent={`${dashboardCompaction.sizePercent}%`}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                ) : (
                  '--'
                )}
              </Box>
            ),
          },
        ],
      },
    ],
    [dashboardCompaction, dashboardStatistics, numberOfWarehouses],
  );

  const tabs = useMemo(() => {
    return [
      {
        id: 'warehouses',
        label: 'Warehouses',
        hide: false,
        href: 'warehouses',
        group: MainTabGroup.DATA,
        content: loading ? (
          <Skeleton />
        ) : (
          <ListWarehouses
            dashboardItems={dashboardItems}
            dashboardDropdownContent={dashboardDropdownContent}
            warehouseData={data}
            organizationName={organizationName}
            orgDisplayName={orgDisplayName}
            onHandleCreateWarehouse={handleCreateWarehouse}
            onHandleRefetch={handleRefetch}
            loading={loading}
            organizationId={organizationId}
            hasCreateWarehouse={hasCreateWarehouse}
          />
        ),
      },
      {
        id: 'dropped',
        label: 'Dropped Tables',
        href: 'dropped-tables',
        group: MainTabGroup.DATA,
        content: <SpecialTrash organizationId={organizationId} />,
        hide: false,
      },
      {
        id: 'members',
        label: 'Members',
        href: 'members',
        group: MainTabGroup.ORG,
        content: loading ? (
          <Skeleton />
        ) : (
          <Members orgName={organizationName} />
        ),
        hide: false,
      },
      {
        id: 'roles',
        label: 'Roles',
        href: 'roles',
        group: MainTabGroup.ORG,
        content: loading ? (
          <Skeleton />
        ) : (
          <Roles user={user} orgName={organizationName} />
        ),
        hide: false,
      },
      {
        id: 'labels',
        label: 'Labels',
        href: 'labels',
        group: MainTabGroup.ORG,
        content: loading ? (
          <Skeleton />
        ) : (
          <Labels user={user} orgName={organizationName} />
        ),
        hide: false,
      },
      {
        id: 'access-controls',
        label: 'Access Control',
        href: 'org-access-controls',
        group: MainTabGroup.ORG,
        content: loading ? (
          <Skeleton />
        ) : (
          <>
            <OrganizationAccessControls
              orgId={organizationId}
              orgName={organizationName}
              orgDisplayName={orgDisplayName}
              user={user}
            />
          </>
        ),
        disabled: !isSecurityAdmin,
      },
      {
        id: 'settings',
        label: 'Settings',
        href: 'settings',
        group: MainTabGroup.ORG,
        content: loading ? (
          <Skeleton />
        ) : (
          <OrgSettings
            userCanDrop={userCanDrop}
            organization={data?.organization}
          />
        ),
        hide: false,
        disabled: !userCanDrop,
      },
      {
        id: 'profile',
        label: 'My Profile',
        href: 'profile',
        group: MainTabGroup.ORG,
        content: <ProfilePage />,
        hide: false,
        disabled: false,
      },
      {
        id: 'usage',
        label: 'Usage Stats',
        href: 'usage',
        group: MainTabGroup.ORG,
        content: loading ? (
          <Skeleton />
        ) : (
          <UsagePage organizationId={organizationId as string} />
        ),
        hide: !isSecurityAdmin,
        disabled: false,
        ff: KnownFeatureFlags.USAGE_DATA_PAGE,
      },
      {
        id: 'storage',
        label: 'Storage',
        hide: false,
        href: 'storage',
        group: MainTabGroup.CONNECTIONS,
        content: (
          <Storage
            orgName={orgDisplayName}
            organizationId={organizationId}
            isSecurityAdmin={isSecurityAdmin}
            user={user}
          />
        ),
        disabled: false,
      },
      {
        id: 'compute',
        label: 'Compute',
        hide: false,
        href: 'compute',
        group: MainTabGroup.CONNECTIONS,
        content: (
          <ComputeGrid
            warehouses={data?.organization?.warehouses}
            refetch={refetch}
          />
        ),
        disabled: false,
      },
      {
        id: 'security',
        label: 'Security',
        hide: false,
        href: 'security',
        group: MainTabGroup.CONNECTIONS,
        content: (
          <Security orgName={orgDisplayName} organizationId={organizationId} />
        ),
        disabled: !isSecurityAdmin,
      },
      {
        id: 'services',
        label: 'Services',
        hide: false,
        href: 'services',
        group: MainTabGroup.PIPELINES,
        content: <Services organizationId={organizationId} />,
        disabled: false,
      },
      {
        id: 'pipelines',
        label: 'Pipelines',
        hide: false,
        href: 'pipelines',
        group: MainTabGroup.PIPELINES,
        content: (
          <Pipelines
            organizationId={organizationId}
            user={user}
            orgName={organizationName}
          />
        ),
        disabled: false,
      },
    ];
  }, [
    data,
    openInitialExperienceToolTip,
    setOpenInitialExperienceToolTip,
    openInitialExperienceMenu,
    setOpenInitialExperienceMenu,
    loading,
    dashboardDropdownContent,
    dashboardItems,
    organizationName,
    orgDisplayName,
    organizationId,
  ]);

  if (!initialized) {
    return <Skeleton />;
  }
  if (error && !data) {
    return <Navigate to="/error" replace />;
  }

  return (
    <>
      <TabLayout tabs={tabs} />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
          window.history.replaceState({}, document.title);
        }}
      >
        <Talert
          onClose={() => {
            setOpenSnackbar(false);
            window.history.replaceState({}, document.title);
          }}
          color="darkSuccess"
          sx={{ width: '100%' }}
        >
          {location?.state?.snackbarMessage ??
            'Warehouse successfully dropped.'}
        </Talert>
      </Snackbar>
      <CreateOrgCredentialDialog
        isOpen={createDialogVisible}
        onDismiss={() => setCreateDialogVisible(false)}
        organizationId={user.loginSession.loggedInOrg.id}
        onCredentialCreated={async () => {
          setCreateDialogVisible(false);
        }}
        isExternalOption={false}
      />
    </>
  );
}
