import React from 'react';

import { Typography } from '@mui/material';

import { SearchEntityType } from '../../../../graphql/gen/graphql';
import SearchAndNavigateResources, {
  SearchAndNavigateItem,
} from '../../../Forms/SearchAndNavigateResources';

export const ChooseTarget = ({
  onTargetSelected,
  selectedId,
}: {
  onTargetSelected: (selected: SearchAndNavigateItem) => void;
  selectedId?: string;
}): JSX.Element => {
  const handleSelect = (selected: SearchAndNavigateItem) => {
    onTargetSelected(selected);
  };

  return (
    <>
      <Typography variant={`body1`} sx={{ mb: 2 }}>
        Choose the database where you would like the auto-replicate pipeline to
        create the replica table.
      </Typography>
      <Typography fontWeight={`bold`} variant={`body1`} sx={{ mb: 1 }}>
        Select your target database.
      </Typography>
      <SearchAndNavigateResources
        onResourceSelected={(selected: SearchAndNavigateItem) =>
          handleSelect(selected)
        }
        resourceType={SearchEntityType.Database}
        selectedId={selectedId}
      />
    </>
  );
};
