import React, { useEffect, useState } from 'react';

import { Box, TextField, Typography } from '@mui/material';

import { Talert } from '../../../Alert/Talert';

export default function Configure({
  pipelineName,
  setPipelineName,
  targetTableName,
  setTargetTableName,
  description,
  setDescription,
  onTargetNameError,
}: {
  pipelineName: string;
  setPipelineName: (value: string) => void;
  targetTableName: string;
  setTargetTableName: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  onTargetNameError: (value: boolean) => void;
}) {
  const [targetNameError, setTargetNameError] = useState(false);
  const evaluateTableName = (value: string) => {
    setTargetTableName(value);
    const passingValidation = /^[a-zA-Z0-9_-]*$/.test(value);
    setTargetNameError(!passingValidation);
  };

  useEffect(() => {
    onTargetNameError(targetNameError);
  }, [targetNameError]);

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant={`body1`} fontWeight={600} sx={{ mb: 1 }}>
          Target table
        </Typography>
        <Typography variant={`body1`} sx={{ mb: 1 }}>
          Provide a name for the target table. To drop a target table, you must
          first delete the pipeline. Maintenance will be disabled on the target
          table.
        </Typography>
        <Talert severity={`warning`}>
          Users are encouraged to never modify the target table manually as it
          can interfere with the auto-replication pipeline.
        </Talert>
        <TextField
          name="target_name"
          id="target_name"
          type="text"
          label="Target table name*"
          variant="outlined"
          margin="normal"
          autoComplete="off"
          fullWidth
          value={targetTableName}
          onChange={(e) => evaluateTableName(e.target.value)}
          error={targetNameError}
          helperText={
            targetNameError
              ? `Table name may only contain alpha-numeric characters and (_ or -) and may not contain any spaces.`
              : null
          }
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant={`body1`} fontWeight={600} sx={{ mb: 1 }}>
          Pipeline
        </Typography>
        <Typography variant={`body1`}>
          {`Provide a name for this pipeline. Example: Auto replicate <SourceTableName> to <TargetTableName>`}
        </Typography>
        <TextField
          name="pipeline_name"
          id="pipeline_name"
          type="text"
          label="Pipeline name*"
          variant="outlined"
          margin="normal"
          autoComplete="off"
          fullWidth
          value={pipelineName}
          onChange={(e) => setPipelineName(e.target.value)}
        />
      </Box>
      <TextField
        name="pipeline_description"
        id="pipeline_description"
        type="text"
        label="Pipeline description"
        variant="outlined"
        margin="normal"
        autoComplete="off"
        fullWidth
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </Box>
  );
}
