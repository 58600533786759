import { withSentryReactRouterV6Routing } from '@sentry/react';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { LicenseInfo } from '@mui/x-license-pro';

import { ROUTES, DASH_PATH } from './RouteTable';
import NotFound from './components/Errors/NotFound';
import OrgAccessDenied from './components/Errors/OrgAccessDenied';
import AppBarLayout from './components/Layouts/AppBarLayout';
import MainLayout from './components/Layouts/MainLayout';
import { useAuth } from './context/auth-context';
import { usePosthogClient } from './context/posthog';
import { User } from './graphql/gen/graphql';
import StorageProfile from './pages/Connections/StorageProfile';
import Database from './pages/Data/Database';
import Organization from './pages/Data/Organization';
import Table from './pages/Data/Table';
import Warehouse from './pages/Data/Warehouse';
import Login from './pages/External/Login';
import { Logout } from './pages/External/Logout';
import ResetPassword from './pages/External/ResetPassword';
import Signup from './pages/External/Signup';
import Home from './pages/Home';
import Role from './pages/Organization/Role';

//set the @mui/x-license-pro lic once for the project.
LicenseInfo.setLicenseKey(
  '2771251d71a23e13c00da781127b0bd9Tz05OTk5MSxFPTE3NjA1NjE1MjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
);

function App() {
  const { user } = useAuth();
  const location = useLocation();
  const posthog = usePosthogClient();

  React.useEffect(() => {
    if (posthog) {
      // on route changes manually trigger pageview event
      posthog.capture('$pageview');
    }
  }, [location]);

  return user && user.id ? (
    <AuthenticatedApp user={user} />
  ) : (
    <UnauthenticatedApp />
  );
}

function AuthenticatedApp({ user }: { user: User }) {
  const SentryRoutes = withSentryReactRouterV6Routing(Routes);

  /*
  A lot of fiddling to get the redirection to the dashboard path working at each level
  Do not use index for those redirects, used empty path instead as the matcher, otherwise child paths accidentally matched
  parents.  for instance, navigating to /[org]/[warehouse]/[database]  after redirect to /[org]/[warehouse]/[database]/-/tables
  would accidentally match  /[org]/[warehouse]/[database]/-/tables down on the <Table> route with params
  {org:"orgname", warehouse:"warehousename",database:"-", table:"tables"}...  changing the subchildren from having the index flag
  to just path="" fixes this as they are specific matches
   */
  return (
    <SentryRoutes>
      <Route path={ROUTES.login + '/*'} element={<Login />} />
      <Route path={ROUTES.logout} element={<Logout />} />
      <Route path={ROUTES.signup + '/*'} element={<Signup />} />
      <Route path={ROUTES.reset} element={<ResetPassword />} />
      <Route path={'error'} element={<NotFound />} />
      <Route path={'denied'} element={<OrgAccessDenied />} />
      <Route path="/" element={<AppBarLayout />}>
        <Route index element={<Home />} />
        <Route path={ROUTES.error} element={<NotFound />} />
        <Route path=":organization" element={<MainLayout />}>
          <Route path={'roles'}>
            <Route
              path=""
              element={<Navigate to={'../' + DASH_PATH + '/roles'} replace />}
            />
            <Route path=":role">
              <Route path={DASH_PATH + '/*'} element={<Role />} />
              <Route path="" element={<Navigate to={DASH_PATH} replace />} />
            </Route>
          </Route>
          <Route path={'storage'}>
            <Route
              path=""
              element={<Navigate to={'../' + DASH_PATH + '/storage'} replace />}
            />
            <Route path=":bucketName">
              <Route path={DASH_PATH + '/*'} element={<StorageProfile />} />
              <Route path="" element={<Navigate to={DASH_PATH} replace />} />
            </Route>
          </Route>
          <Route path={DASH_PATH + '/*'} element={<Organization />} />
          <Route
            path="" //see comment above
            element={<Navigate to={DASH_PATH} replace />}
          />
          <Route path="warehouses">
            <Route
              path=""
              element={
                <Navigate to={'../' + DASH_PATH + '/warehouses'} replace />
              }
            />
            <Route path=":warehouse">
              <Route path={DASH_PATH + '/*'} element={<Warehouse />} />
              <Route path="" element={<Navigate to={DASH_PATH} replace />} />
              <Route path=":database">
                <Route path={DASH_PATH + '/*'} element={<Database />} />
                <Route
                  path="" //see comment above
                  element={<Navigate to={DASH_PATH} replace />}
                />
                <Route path=":table">
                  <Route path={DASH_PATH + '/*'} element={<Table />} />
                  <Route
                    path="" //see comment above
                    element={<Navigate to={DASH_PATH} replace />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route
            path="*" //catch all folks coming to an unknown or old link and redirect them to the org root
            element={<Navigate to={'../'} replace />}
          />
        </Route>
      </Route>
    </SentryRoutes>
  );
}

function UnauthenticatedApp() {
  const location = useLocation();
  const SentryRoutes = withSentryReactRouterV6Routing(Routes);
  return (
    <SentryRoutes>
      <Route path={ROUTES.login + '/*'} element={<Login />}></Route>
      <Route path={ROUTES.reset} element={<ResetPassword />} />
      <Route path={ROUTES.signup + '/*'} element={<Signup />} />
      <Route path={ROUTES.logout} element={<Logout />} />
      <Route
        path={ROUTES.home}
        element={<Navigate to={ROUTES.login} replace />}
      />
      <Route
        path="*"
        element={
          <Navigate to={ROUTES.login} state={{ from: location }} replace />
        }
      />
    </SentryRoutes>
  );
}

export default App;
