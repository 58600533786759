import React from 'react';

import { GridCellParams } from '@mui/x-data-grid-pro';
import { includes } from 'lodash';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  roleId: Yup.string().required('Pick a Tabular role to pair with IAM role'),
  roleArn: Yup.string()
    .trim()
    .required('IAM role ARN is required.')
    .matches(
      /^arn:aws:(iam|sts)::\d{12}:(role|assumed-role|federated-user|user|group|instance-profile)\/\S+$/,
      `Invalid IAM role ARN, should match 
        arn:aws:[aws_service]::aws_account_id:[aws_resource]/resource_name 
        where [aws_service] can be iam or sts and [aws_resource] can be role, 
        assumed-role, federated-user, user, group, or instance-profile`,
    ),
});

export const awsAuthValidationSchema = Yup.object().shape({
  clientId: Yup.string()
    .trim()
    .required('AWS ARN is required.')
    .matches(
      /^arn:aws:(iam|sts)::\d{12}:(user|assumed-role|federated-user)\/\S+$/,
      `Invalid IAM role ARN, should match 
        arn:aws:[aws_service]::aws_account_id:[aws_resource]/resource_name 
        where [aws_service] can be iam or sts and [aws_resource] can be user, 
        assumed-role, or federated-user`,
    )
    .test(
      'no-session-name',
      'Role session name for assumed-role not allowed in identity provider',
      (value) => {
        if (value?.match(/^arn:aws:sts::\d{12}:assumed-role\/\S+$/)) {
          const checkForRoleSession =
            value && value.match(/^arn:aws:sts::\d{12}:assumed-role\/[^/]*$/);
          return checkForRoleSession !== null;
        }
        return true;
      },
    ),
});

export const serviceMappingSchema = Yup.object().shape({
  name: Yup.string().required('Name credential'),
  roleId: Yup.string().required('Pick a Tabular role to pair with credential'),
});

export const getApplyFilterFnCreatorName = (value: string) => {
  return ({ value: columnValue }: GridCellParams): boolean => {
    return columnValue != null && columnValue.displayName != null
      ? includes(columnValue?.displayName.toLowerCase(), value.toLowerCase())
      : false;
  };
};

type Created = {
  displayName: string;
  profilePic: string;
  currentUser: string;
  email: string;
  createdAt: string;
};

export const dateComparator = (v1: Created, v2: Created) =>
  v1.createdAt.localeCompare(v2.createdAt);
