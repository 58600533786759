import * as React from 'react';

import { Typography, Box, Stack, Divider } from '@mui/material';

import { AuthDecisionResourceType } from '../../graphql/gen/graphql';
import { configureButtonGroups } from '../AccessControl/ButtonConfiguration';
import { AccessButton, AccessButtonGroup } from '../Button/AccessButtonGroup';
import { ResourceTypeDisplay } from '../RoleSingle/helpers';

type Props = {
  resource: any;
  resourceType: string;
  securityAdminRole: boolean;
  leftPlacement?: boolean;
};
export const RoleAuthDetailsPopover = React.forwardRef(
  (
    {
      resource,
      resourceType,
      securityAdminRole,
      leftPlacement,
      ...props
    }: Props,
    ref,
  ) => {
    const buttonGroups = configureButtonGroups(
      resource.privileges,
      resource.resourceType,
      '',
      true,
    );

    const arrowPositionProps: any = {};
    if (leftPlacement) {
      arrowPositionProps.right = -6;
      arrowPositionProps.top = 'calc(50% - 6px)';
      arrowPositionProps.backgroundColor = 'popper.main';
    } else {
      arrowPositionProps.bottom = -6;
      arrowPositionProps.right = '50%';
      arrowPositionProps.backgroundColor = 'popper.main';
    }

    return (
      <Box
        ref={ref}
        {...props}
        sx={(theme) => ({
          backgroundColor: 'black',
          color: 'midnight.half',
          borderRadius: '4px',
          position: 'relative',
          boxShadow: theme.shadows[3],
          mb: leftPlacement ? '0' : '10px',
          mr: leftPlacement ? '12px' : '0',
        })}
      >
        <Stack direction={`column`}>
          <Box padding={2}>
            <Typography variant={'h6'}>{`${
              //@ts-ignore
              ResourceTypeDisplay[resource.resourceType]
            } privileges`}</Typography>
            <Typography variant={`subtitle1`} marginTop={1}>
              {resource.resourceName}
            </Typography>
          </Box>
          <Divider sx={{ my: 1, borderColor: 'midnight.two' }} />
          <Box padding={2} paddingBottom={4}>
            <Stack spacing={1}>
              {buttonGroups.map(
                (buttonGroup: AccessButton[], index: number) => {
                  const groupName =
                    resourceType === AuthDecisionResourceType.View
                      ? 'View'
                      : buttonGroup[0].groupingName || '';

                  return (
                    <Box
                      key={`${resource.resourceName}-popover-privs-${index}`}
                      display={`flex`}
                    >
                      <Box
                        display={`flex`}
                        flexGrow={1}
                        flexBasis={`max-content`}
                        marginRight={1}
                      >
                        <Box
                          sx={{
                            border: '1px solid white',
                            px: 1,
                            py: 1,
                            display: 'flex',
                          }}
                        >
                          <Typography
                            variant={`overline`}
                            sx={{
                              color: 'white',
                              p: 0,
                              m: 0,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {groupName}
                          </Typography>
                        </Box>
                      </Box>
                      <Box width={400}>
                        <AccessButtonGroup
                          accessButtons={buttonGroup}
                          securityAdminRole={securityAdminRole}
                          popover={true}
                        />
                      </Box>
                    </Box>
                  );
                },
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  },
);
