import React from 'react';

import { StatusPanelContext } from './StatusPanelContext';

type StatusPanelContextProviderProps = {
  props: {};
  children: React.ReactNode;
};

export function StatusPanelContextProvider({
  props,
  children,
}: StatusPanelContextProviderProps) {
  return (
    <StatusPanelContext.Provider value={props}>
      {children}
    </StatusPanelContext.Provider>
  );
}
