import React from 'react';

import { StatusPanelSectionContext } from './StatusPanelSectionContext';

import { StatusPanelSectionProcessedProps } from '../index';

export const useStatusPanelSectionRootProps = () => {
  const contextValue = React.useContext(StatusPanelSectionContext);

  if (!contextValue) {
    throw new Error(
      'useStatusBoardRootProps should only be used inside the status panel.',
    );
  }

  return contextValue as StatusPanelSectionProcessedProps;
};
