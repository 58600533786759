import React, { useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SecurityIcon from '@mui/icons-material/Security';
import {
  Typography,
  CardHeader,
  CardContent,
  Box,
  CardActions,
  Link,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid';
import { Form, Formik, useFormikContext } from 'formik';
import { includes, isEqual, orderBy } from 'lodash';
import * as Yup from 'yup';

import { toSingleRole } from '../../RouteTable';
import { Tavatar } from '../../components/Avatar/Tavatar';
import { Refresh } from '../../components/Button/Refresh';
import { Tabutton } from '../../components/Button/Tabutton';
import { Tacard } from '../../components/Card/Tacard';
import { CreateOrgCredentialDialog } from '../../components/Credentials/Dialogs/CreateOrgCredentialDialog';
import CredentialsGrid from '../../components/Credentials/Grids/CredentialGrid';
import StatusBars from '../../components/Feedback/StatusBars';
import TextField from '../../components/Forms/TextField';
import { ConfirmationDialog } from '../../components/Modals/ConfirmationDialog';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { OrganizationCredential, Role } from '../../graphql/gen/graphql';
import {
  deleteOrgCredential,
  myOrganizationCredentials,
  updateOrgCredential,
} from '../../graphql/organization';
import { ProfilePageQuery, updateMembershipMutation } from '../../graphql/user';
import { SERVICE_CREDENTIAL_TYPES, teeUp } from '../helpers';

interface RoleProps extends Role {
  systemRole: boolean;
}

export default function ProfilePage() {
  const [formChange, setFormChange] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [credKeyToUpdate, setCredKeyToUpdate] = useState('');
  const [credUpdates, setCredUpdates] = useState();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [credToDelete, setCredToDelete] = useState(
    {} as OrganizationCredential,
  );
  const {
    data: userData,
    loading: userDataLoading,
    refetch: refetchUserData,
  } = useQuery(ProfilePageQuery, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const member = useMemo(
    () => userData?.currentUser?.loginSession?.membership,
    [userData],
  );
  const organizationId = useMemo(
    () => userData?.currentUser?.loginSession?.loggedInOrg?.id,
    [userData],
  );
  const orgName = useMemo(
    () => userData?.currentUser?.loginSession?.loggedInOrg?.name,
    [userData],
  );

  const {
    data: myCredentials,
    loading: credentialsLoading,
    refetch: refetchCredentials,
  } = useQuery(myOrganizationCredentials, {
    skip: !organizationId,
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [updateMembership] = useMutation(updateMembershipMutation);
  const [updateCredential] = useMutation(updateOrgCredential, {
    variables: {
      organizationId,
      credentialKey: credKeyToUpdate,
      updates: credUpdates,
    },
  });
  const [deleteCredential, { loading: deleteLoading }] = useMutation(
    deleteOrgCredential,
    {
      variables: {
        organizationId,
        credentialKey: credToDelete.key,
      },
      onCompleted: async () => {
        await refetchCredentials();
        setConfirmModal(false);
        setCredToDelete({} as OrganizationCredential);
      },
    },
  );

  const MEMBER_INFO_INITIAL_VALUES = useMemo(() => {
    return {
      email: userData?.currentUser?.email || '',
      displayName:
        member?.displayName || userData?.currentUser?.displayName || '',
      imageUrl: member?.properties?.[`picture.url`] ?? '',
    };
  }, [userData, member]);

  const profilePageCredentials = useMemo(
    () =>
      myCredentials?.myOrganizationCredentials?.filter(
        (cred: OrganizationCredential) =>
          !includes(SERVICE_CREDENTIAL_TYPES, cred.type) &&
          !includes('IAM', cred.type),
      ),
    [myCredentials],
  );

  const handleMembershipDetails = async (values: any) => {
    try {
      await updateMembership({
        variables: {
          memberId: member.id,
          membershipUpdates: { displayName: values.displayName.trim() },
        },
      });
      setOpenSnackbar(true);
    } catch (error) {
      setApiError(true);
    }
  };

  const handleCredentialUpdate = async (
    credentialKey: string,
    updates: any,
  ) => {
    await setCredKeyToUpdate(credentialKey);
    await setCredUpdates(updates);
    await updateCredential();
    await refetchCredentials();
  };

  const confirmCredentialDelete = (credential: any) => {
    setCredToDelete(credential);
    setConfirmModal(true);
  };

  const rows = useMemo(
    () =>
      teeUp(
        orderBy(
          userData?.currentUser?.loginSession?.roles,
          [(role) => role.displayName.toLowerCase()],
          ['asc'],
        ),
        (role: RoleProps) => role.systemRole,
      ).map((role: RoleProps) => ({
        ...role,
        displayName: role.displayName,
        id: role.id,
        roleAdmin: role.currentUserRoleAdmin,
      })),
    [userData],
  );

  const columns = useMemo(
    () => [
      {
        field: 'displayName',
        headerName: 'Role',
        flex: 1,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link
                href={toSingleRole(orgName, params.row.name)}
                sx={{ textDecoration: 'none' }}
              >
                <Typography
                  variant={`inputText`}
                  sx={{ mr: 1, color: 'sky.seven' }}
                >
                  {params.value}
                </Typography>
                {params.value === 'Security Admin' && (
                  <LocalPoliceIcon sx={{ color: 'sky.seven' }} />
                )}
                {params.value === 'Organization Admin' && (
                  <SecurityIcon sx={{ color: 'sky.seven' }} />
                )}
              </Link>
            </Box>
          );
        },
      },
      {
        field: 'roleAdmin',
        headerName: 'Admin',
        flex: 1,
        editable: false,
        renderCell: (params: any) => {
          return <>{params.value ? 'Yes' : 'No'}</>;
        },
      },
    ],
    [orgName, userData],
  );

  const FormObserver = () => {
    const { values } = useFormikContext();

    useEffect(() => {
      setFormChange(!isEqual(MEMBER_INFO_INITIAL_VALUES, values));
    }, [values]);

    return null;
  };

  return (
    <>
      <PageHeader
        resourceName="My profile"
        pageTitle={`${userData?.currentUser?.loginSession?.loggedInOrg?.displayName} organization`}
        marginB={3}
      />
      <Tacard>
        <CardHeader
          title={member?.displayName}
          sx={{ backgroundColor: 'sky.one' }}
        />
        <Formik
          initialValues={MEMBER_INFO_INITIAL_VALUES}
          validationSchema={Yup.object({
            email: Yup.string().required(),
            displayName: Yup.string().trim().required(),
            imageUrl: Yup.string(),
          })}
          enableReinitialize
          onSubmit={handleMembershipDetails}
        >
          {({ resetForm }) => (
            <Form>
              <CardContent sx={{ p: 0, m: 0 }}>
                <SectionHeader>
                  <Typography variant={`subtitle1`}>My email</Typography>
                </SectionHeader>
                <Box padding={2}>
                  <Typography variant={`body2`} color={'midnight.six'}>
                    You cannot change the email address associated with this
                    account.
                  </Typography>
                  <TextField
                    name={`email`}
                    id={`email`}
                    label={`Email`}
                    disabled
                    InputProps={{ readOnly: true }}
                    sx={(theme) => ({
                      minWidth: '420px',
                      [theme.breakpoints.down(530)]: {
                        minWidth: '275px',
                      },
                    })}
                  />
                </Box>
                <SectionHeader>
                  <Typography variant={`subtitle1`}>My display name</Typography>
                </SectionHeader>
                <Box padding={2}>
                  <Typography variant={`body2`} color={'midnight.six'}>
                    How your name appears inside of this organization.
                  </Typography>

                  <TextField
                    name={`displayName`}
                    id={`displayName`}
                    label={`Display Name`}
                  />
                </Box>
                <SectionHeader>
                  <Typography variant={`subtitle1`}>My avatar</Typography>
                </SectionHeader>
                <Box padding={2}>
                  <Typography variant={`body2`} color={'midnight.six'}>
                    How your avatar appears inside of this organization.
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', py: 2 }}>
                    <Tavatar
                      profilePic={member?.properties?.[`picture.url`] ?? ''}
                      displayName={
                        member?.displayName ||
                        userData?.currentUser?.displayName
                      }
                      currentUser={true}
                      //@ts-ignore
                      height={64}
                      width={64}
                    />
                  </Box>
                </Box>
              </CardContent>
              <CardActions
                sx={(theme) => ({
                  borderTop: `1px solid ${theme.palette.midnight.two}`,
                  display: 'flex',
                  justifyContent: 'end',
                  p: 2,
                })}
              >
                <Tabutton onClick={() => resetForm()} disabled={!formChange}>
                  Cancel
                </Tabutton>
                <Tabutton
                  type={`submit`}
                  disabled={!formChange}
                  variant={`contained`}
                >
                  Save Changes
                </Tabutton>
              </CardActions>
              <FormObserver />
            </Form>
          )}
        </Formik>
      </Tacard>
      <Tacard>
        <CardHeader
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.midnight.two}`,
          })}
          title={
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant={`h4`}
              >{`My roles in ${userData?.currentUser?.loginSession?.loggedInOrg?.displayName}`}</Typography>
              <Box
                sx={{
                  pr: 2,
                  display: 'flex',
                }}
              >
                <Refresh
                  refreshList={() => refetchUserData()}
                  title={'Refresh roles list'}
                />
              </Box>
            </Box>
          }
        />
        <CardContent sx={{ p: 0, m: 0 }}>
          <DataGrid
            autoHeight
            hideFooter
            disableColumnMenu
            rows={rows}
            columns={columns}
            headerHeight={48}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            loading={userDataLoading || !rows}
            sx={{
              border: 'none',
              '.MuiDataGrid-columnHeaders': {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              },
              '.MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
              },
            }}
          />
        </CardContent>
      </Tacard>
      <Tacard>
        <CredentialsGrid
          credentialsData={profilePageCredentials}
          onCredentialDelete={confirmCredentialDelete}
          onCredentialUpdate={handleCredentialUpdate}
          headerName={`My credentials in ${userData?.currentUser?.loginSession?.loggedInOrg?.displayName}`}
          currentUserEmail={member?.email || ''}
          refreshResourceContent={{
            title: 'Refresh member credentials list',
            action: refetchCredentials,
          }}
          addResourceContent={{
            title: 'Create member credential',
            action: () => setCreateDialogVisible(true),
          }}
          showCreatorColumn={false}
          loading={credentialsLoading}
        />
      </Tacard>
      <ConfirmationDialog
        open={confirmModal}
        loading={deleteLoading}
        title={`Delete ${credToDelete?.name} credential?`}
        acceptText="Delete"
        onDismiss={() => {
          setConfirmModal(false);
          setCredToDelete({} as OrganizationCredential);
        }}
        onAccept={deleteCredential}
      />
      <CreateOrgCredentialDialog
        isOpen={createDialogVisible}
        onDismiss={() => setCreateDialogVisible(false)}
        organizationId={organizationId}
        onCredentialCreated={async () => {
          setCreateDialogVisible(false);
          await refetchCredentials();
        }}
        isExternalOption={false}
      />
      <StatusBars
        successDisplay={openSnackbar}
        handleSuccessClose={() => setOpenSnackbar(false)}
        successMessage={`Profile settings have been updated.`}
        errorDisplay={apiError}
        setErrorDisplay={setApiError}
        errorMessage={`Something went wrong. Please refresh the page and try again.`}
      />
    </>
  );
}
