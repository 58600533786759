import {
  MirrorTarget,
  Property,
  StorageProfile,
  Warehouse,
} from '../../graphql/gen/graphql';

export const getTarget = (
  mirror: string,
  storageProfile?: StorageProfile | null,
  role?: string | null,
) => {
  switch (mirror) {
    case 'mirror.glue.enabled':
      return { target: MirrorTarget.Glue, name: 'Glue', permission: role };
    case 'mirror.bigquery.enabled':
      return {
        target: MirrorTarget.Bigquery,
        name: 'BigQuery',
        permission: storageProfile?.properties['gcp.service-account.email'],
      };
    case 'mirror.snowflake.enabled':
      return {
        target: MirrorTarget.Snowflake,
        name: 'Snowflake',
        permission: role,
      };
    case 'mirror.snowflake.configured':
      return {
        target: MirrorTarget.Snowflake,
        name: 'Snowflake',
        permission: role,
      };
    case 'mirror.databricks.enabled':
      return {
        target: MirrorTarget.Databricks,
        name: 'Databricks',
        permission: role,
      };
    case 'mirror.databricks.configured':
      return {
        target: MirrorTarget.Databricks,
        name: 'Databricks',
        permission: role,
      };
    default:
      return null;
  }
};

export type ConnectionsType = {
  id: string;
  computeName: string;
  warehouse: Warehouse;
  warehouseName: string;
  mirror?: Property;
  permissions: string;
  enabled: boolean;
  mirrorTarget: MirrorTarget | null;
  hideSnowflake: boolean;
  hideDatabricks: boolean;
};

export const mirrorEnabledProperties = [
  'mirror.glue.enabled',
  'mirror.bigquery.enabled',
  'mirror.snowflake.configured',
  'mirror.snowflake.enabled',
  'mirror.databricks.configured',
  'mirror.databricks.enabled',
];

export const snowflakeProperties = [
  'mirror.snowflake.configured',
  'mirror.snowflake.enabled',
];

export const databricksProperties = [
  'mirror.databricks.configured',
  'mirror.databricks.enabled',
];
