import React, { useCallback, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  BoxProps,
  Chip,
  Divider,
  FormControl,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  GridActionsCellItem,
  GridCellParams,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridToolbarContainer,
  MuiBaseEvent,
  DataGridPro,
  GridCallbackDetails,
  GridColumns,
  GridRenderCellParams,
  MuiEvent,
  useGridApiRef,
  GRID_REORDER_COL_DEF,
} from '@mui/x-data-grid-pro';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { useTableEditContext } from './TableEditContext';

import { InProperty } from '../../graphql/gen/graphql';
import { getLogger } from '../../utils/logging';
import { Tabutton } from '../Button/Tabutton';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { EditToolbar } from './EditToolbar';
import { RowType } from './SchemaEdit';
import { GridEditTextField, GridTextField } from './SchemaEditGridComponents';
import { SchemaEditToolbar } from './SchemaEditToolbar';
import { TABLE_GRID_NO_ROWS_HEIGHT } from './TableEdit';
import {
  handleRowModesModelChangeStripCellFocus,
  nukeAllEmpties,
  shouldBlockPropagation,
  validatePropertiesRow,
} from './TableEditHelpers';

const logger = getLogger(
  'components.TableEdit.PropertiesEdit' /*FUTURE import.meta.url ?*/,
);
export interface PropertiesRow extends InProperty {
  rowId: string;
  isNew: boolean;
}

export interface PropertiesEditProps extends BoxProps {
  validityChanged: (isValid: number) => void;
}
export default function PropertiesEdit({
  validityChanged,
  ...props
}: PropertiesEditProps) {
  const gridApiRef = useGridApiRef();

  const {
    propertiesModel: rows,
    setPropertiesModel: setRows,
    triggerValidation,
    isCancelled,
    cancelSubmission,
  } = useTableEditContext();

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );
  const [rowsBeingEdited, setRowsBeingEdited] = React.useState<
    Record<string, boolean>
  >({});

  const [validityModel, setValidityModel] = React.useState<
    Record<GridRowId, number>
  >({});

  const [lastValidation, setLastValidation] = React.useState<number>(-1);

  const [shouldAddNewRowOnNextLoop, setShouldAddNewRowOnNextLoop] =
    React.useState(false);

  const stopAllEditing = (): boolean => {
    let model: GridRowModesModel = {};
    logger.debug(`Stop all editing for ${rows.length} rows`, model);
    let foundAtLeastOneInEditMode = false;
    rows.forEach((eachRow, idx) => {
      if (
        gridApiRef.current.getRowMode(eachRow.rowId as GridRowId) ==
        GridRowModes.Edit
      ) {
        foundAtLeastOneInEditMode = true;
      }
      model[eachRow.rowId as GridRowId] = { mode: GridRowModes.View };
    });

    setRowModesModel(model);
    return foundAtLeastOneInEditMode;
  };

  const startEditingRow = (rowId: GridRowId) => {
    if (gridApiRef.current.getRowMode(rowId) != GridRowModes.Edit) {
      // stopAllEditing();
      gridApiRef.current.startRowEditMode({
        id: rowId,
        fieldToFocus: 'name',
      });
      //stupidly, onRowEditStart does not fire when we put this row into edit mode. (above) so we have to count here too
      setRowsBeingEdited((prevState) => {
        return { ...prevState, [rowId]: true };
      });
    }
  };

  useEffect(() => {
    if (shouldAddNewRowOnNextLoop) {
      setShouldAddNewRowOnNextLoop(false);

      handleAddColumnClick();
    }
  }, [shouldAddNewRowOnNextLoop]);

  useEffect(() => {
    if (!isCancelled && triggerValidation > lastValidation) {
      logger.debug(
        `⚡️PropertiesEdit: validation trigger is ${triggerValidation} and last validation is ${lastValidation}!`,
      );

      //1. stop all editing my rows
      const foundAtLeastOneInEditMode = stopAllEditing();
      //2. if no rows being edited, then we are good, report back!
      if (
        !foundAtLeastOneInEditMode &&
        Object.keys(rowsBeingEdited).length == 0
      ) {
        logger.debug(`PropertiesEdit Triggering schema valid ALL GOOD`);
        let allRowsGood = true;
        if (rows.length == 0) {
          allRowsGood = true;
        }
        rows.forEach((eachRow) => {
          if (!validatePropertiesRow(eachRow)) {
            allRowsGood = false;
          }
        });
        if (allRowsGood) {
          logger.debug(`All Properties rows valid!`);
          if (triggerValidation > lastValidation) {
            validityChanged(triggerValidation);
            setLastValidation(triggerValidation);
          }
        } else {
          logger.debug(`CANCELLING: Properties rows are not all valid!`);
          const nukedAtLeastOne = nukeAllEmpties(rows, setRows, 'name');
          cancelSubmission(
            0,
            'Properties Rows are not all valid!',
            foundAtLeastOneInEditMode || nukedAtLeastOne,
          );
        }
      } else {
        logger.debug(
          `Properties edit cant trigger valid, we have rows being edited `,
          rowsBeingEdited,
        );
      }
    }
  }, [
    rows,
    rowsBeingEdited,
    validityModel,
    triggerValidation,
    lastValidation,
    isCancelled,
    rowModesModel,
  ]);

  const handleCellFocusOut = (
    params: GridCellParams,
    event: MuiEvent<MuiBaseEvent>,
    details: GridCallbackDetails,
  ) => {};

  const handleRowClick = (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails,
  ) => {
    logger.debug('click on row...');
    gridApiRef.current.startRowEditMode({ id: params.id });
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    logger.debug(
      `: On row EditStart with id ${params.id} current rows editing is `,
      rowsBeingEdited,
    );
    setRowsBeingEdited((prevState) => {
      return { ...prevState, [params.id]: true };
    });
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    logger.debug(`: rowEditStop for id ${params.id} NOOP`);

    if (
      params.reason === 'tabKeyDown' &&
      params.id === rows[rows.length - 1].rowId
    ) {
      setShouldAddNewRowOnNextLoop(true);
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.rowId !== id));
    setRowsBeingEdited(({ [id]: me, ...prevState }) => {
      return { ...prevState };
    });
  };

  const handleRowModesModelChange = (
    rowModesModel: GridRowModesModel,
    details: GridCallbackDetails,
  ) => {
    logger.debug(`Handle handleRowModesModelChange!`, rowModesModel, details);
    handleRowModesModelChangeStripCellFocus(
      rowModesModel,
      details,
      setRowModesModel,
    );
  };

  const processRowUpdate = async (newRow: GridRowModel<PropertiesRow>) => {
    logger.debug(
      `🚧 Handle processRowUpdate for row ${newRow.rowId}, removing from editing!`,
      newRow,
    );
    //const subRows = subRowsModel[newRow.id as GridRowId];
    const updatedRow = {
      ...newRow,
      isNew: false,
    };

    setRows((prevState) => {
      return prevState.map((eachRow) =>
        eachRow.rowId === updatedRow.rowId ? updatedRow : eachRow,
      );
    });

    //handling this here because handleRowEditStop is flaky, not being triggered when save all triggered?
    let updatedRowsBeingEdited: Record<string, any> | undefined = undefined;
    setRowsBeingEdited((prevState) => {
      const { [newRow.rowId as any]: newGuy, ...rows } = prevState;
      updatedRowsBeingEdited = rows;
      return { ...updatedRowsBeingEdited };
    });

    return updatedRow;
  };

  const handleAddColumnClick = async () => {
    const newName = uuidv4();
    setRows((oldRows) => [
      ...oldRows,
      {
        rowId: newName,
        isNew: true,
        name: '',
        value: '',
      },
    ]);

    startEditingRow(newName);
  };

  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      editable: true,
      renderCell: (params) => {
        const isValid = params.value !== undefined && params.value !== '';
        return (
          <>
            <Divider
              orientation="vertical"
              sx={{ height: 0.5, mr: 2 }}
            ></Divider>
            <GridTextField
              label={!isValid ? 'Name' : null}
              tabIndex={-1}
              placeholder={'Name'}
              value={params.value}
              required
              error={!isValid}
            />
          </>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <GridEditTextField
            onKeyDown={(event) => {
              logger.debug(`in form control ${event.key} !`);
              //if we are a complex control with a param, we need to block tab so the datagrid doesnt skip it if this colum is on the end
              if (shouldBlockPropagation(event)) {
                event.stopPropagation();
              }
            }}
            tabIndex={params.tabIndex}
            placeholder={'Name'}
            value={params.value}
            onChange={(event) =>
              gridApiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: event.target.value,
              })
            }
            shouldAutoFocus={params.hasFocus}
            sx={{ p: 1, ml: 2.25, mr: 2.25 }}
          ></GridEditTextField>
        );
      },
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 250,
      editable: true,
      renderCell: (params) => {
        const isValid = params.value !== undefined && params.value !== '';
        return (
          <>
            <Divider
              orientation="vertical"
              sx={{ height: 0.5, mr: 2 }}
            ></Divider>
            <GridTextField
              label={!isValid ? 'Value' : null}
              tabIndex={-1}
              placeholder={'Value'}
              value={params.value}
              required
              error={!isValid}
            />
          </>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <FormControl
            tabIndex={-1}
            onKeyDown={(event) => {
              logger.debug(`in form control onkeydown '${event.key}' !`);
              if (shouldBlockPropagation(event)) {
                event.stopPropagation();
              }
            }}
          >
            <GridEditTextField
              tabIndex={params.tabIndex}
              placeholder={'Value'}
              value={params.value}
              onChange={(event) =>
                gridApiRef.current.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: event.target.value,
                })
              }
              shouldAutoFocus={params.hasFocus}
              sx={{ p: 1, ml: 2.25, mr: 2.25 }}
            ></GridEditTextField>
          </FormControl>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 20,
      cellClassName: 'actions',
      align: 'left',
      getActions: ({ id, row, columns }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        return [
          <GridActionsCellItem
            placeholder
            key="delete"
            icon={<CloseIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            sx={{ color: 'sunset.seven' }}
          />,
        ];
      },
    },
  ];

  return (
    <Box {...props}>
      <DataGridPro
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(args) => {
          logger.debug('ERROR while saving row!');
        }}
        onRowClick={handleRowClick}
        onCellFocusOut={handleCellFocusOut}
        components={{
          Footer: EditToolbar,
          Toolbar: SchemaEditToolbar,
          /*@ts-ignore*/
          ColumnHeaders: () => {
            return <></>;
          },
          NoRowsOverlay: OhNoesRows,
        }}
        componentsProps={{
          toolbar: {
            className: 'grid-toolbar',
            sx: {},
            title: ``,
            apiRef: gridApiRef,
          },
          footer: {
            setRowsBeingEdited,
            level: 0,
            stopAllEditing,
            startEditingRow,
            buttonText: 'Add custom property',
            onAddColumnClick: handleAddColumnClick,
            sx: {
              display: rows.length === 0 ? 'none' : '',
              minHeight: 56,
            },
          },
          noRowsOverlay: {
            GridOverlayProps: {
              top: -56,
              height: TABLE_GRID_NO_ROWS_HEIGHT - 44,
            },
            buttonControl: (
              <Tabutton
                variant={'outlined'}
                sx={{ mt: 1, alignContent: 'center' }}
                endIcon={<AddIcon />}
                onClick={handleAddColumnClick}
              >
                {`Add properties`}
              </Tabutton>
            ),
          },
        }}
        disableSelectionOnClick={true}
        experimentalFeatures={{ newEditingApi: true }}
        autoHeight={rows.length > 0}
        hideFooterPagination
        hideFooterSelectedRowCount
        disableColumnMenu
        getDetailPanelHeight={({ row }) => 'auto'}
        getRowId={(row) => row?.rowId}
        initialState={{}}
        sx={{
          ...(rows.length == 0 ? { height: TABLE_GRID_NO_ROWS_HEIGHT } : {}),
        }}
      />
    </Box>
  );
}
