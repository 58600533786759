import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  ListItem,
  ListItemText,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { debounce, some } from 'lodash';

import {
  SortByDirection,
  SearchRoleResult,
  Role,
  Maybe,
} from '../../graphql/gen/graphql';
import { basicSearchRolesQuery } from '../../graphql/organization';
import { getLogger } from '../../utils/logging';

const logger = getLogger(
  'components.Forms.QuickAddRole' /*FUTURE import.meta.url ?*/,
);
interface RoleSearchParams {
  organizationId: string;
  query: string;
  maxResults: number;
  searchAfter: string[] | undefined;
  sortBy: any[];
  excludeSystemRoles: boolean;
}

export default function QuickAddRole({
  organizationId,
  onRoleAdded,
  existingRoles,
}: {
  organizationId: string;
  onRoleAdded: (role: SearchRoleResult) => void;
  existingRoles: Maybe<Role>[];
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [roleOptions, setRoleOptions] = useState<SearchRoleResult[]>([]);

  const { data, loading, refetch } = useQuery<any, RoleSearchParams>(
    basicSearchRolesQuery,
    {
      variables: {
        organizationId: organizationId,
        query: searchQuery !== '' ? searchQuery : '*',
        maxResults: 100,
        searchAfter: undefined,
        sortBy: [
          {
            fieldName: searchQuery ? '_score' : 'roleName.sort',
            sortDirection: searchQuery
              ? SortByDirection.Desc
              : SortByDirection.Asc,
          },
        ],
        excludeSystemRoles: false,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  );

  const nextKeys: string[] = useMemo(
    () =>
      data?.searchRoles?.searchAfterKeys
        ? data?.searchRoles?.searchAfterKeys
        : [],
    [data],
  );
  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      //@ts-ignore
      const searchyMcSearch = e?.target?.value || '';
      setSearchQuery(searchyMcSearch);
    },
    [],
  );
  const debouncedSearchChange = debounce(onSearchChange, 500);

  const handleScroll = useCallback(
    (event: React.SyntheticEvent) => {
      const listboxNode = event.currentTarget;
      const position = listboxNode.scrollTop + listboxNode.clientHeight;

      if (
        listboxNode.scrollHeight - position <= 1 &&
        roleOptions?.length < data?.searchRoles?.totalHits
      ) {
        refetch({
          searchAfter: nextKeys.length === 0 ? undefined : nextKeys,
        }).then((refetchGuys) => {
          setRoleOptions((prevRoleOptions) => [
            ...prevRoleOptions,
            ...refetchGuys?.data?.searchRoles?.results,
          ]);
        });
      }
    },
    [nextKeys, data, roleOptions],
  );

  useEffect(() => {
    if (searchQuery !== undefined) {
      refetch({
        query: searchQuery !== '' ? searchQuery : '*',
        searchAfter: undefined,
      })
        .then((refetchGuys) =>
          setRoleOptions(refetchGuys?.data?.searchRoles?.results),
        )
        .catch((error) => {
          logger.error('problem querying roles ', error);
        });
    }
  }, [searchQuery]);
  const CustomPopper = useCallback((props: any) => {
    const { className, anchorEl, style, ...rest } = props;
    return (
      <Box
        {...rest}
        sx={(theme) => ({
          '& .MuiPaper-root': {
            boxShadow: 'unset',
          },
          '& ::-webkit-scrollbar': {
            width: '4px',
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: 'midnight.half',
          },
          '& ::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: 'midnight.two',
          },
          borderTop: `1px solid ${theme.palette.midnight.two}`,
          zIndex: 9999,
          width: '250px',
          display: 'flex',
          justifyItems: 'flex-start',
        })}
      />
    );
  }, []);

  return (
    <Stack direction={'column'} alignItems={'center'} height={'300px'}>
      <Autocomplete
        id={`quick-add-role-combo-box`}
        open
        disableCloseOnSelect
        loading={loading}
        noOptionsText={`No roles`}
        popupIcon={''}
        options={roleOptions ? roleOptions : []}
        isOptionEqualToValue={(option: any, value: any) =>
          option.roleId === value.roleId
        }
        PopperComponent={CustomPopper}
        getOptionLabel={(option) => option.roleName}
        getOptionDisabled={(option) =>
          some(existingRoles, (existing) => existing?.id === option?.roleId)
        }
        renderOption={(props: any, option: any) => {
          return (
            <ListItem
              {...props}
              style={{
                padding: '8px',
              }}
            >
              <ListItemText sx={{ wordBreak: 'break-word', pl: 2 }}>
                <Typography variant={`subtitle2`}>
                  {option?.roleName}
                </Typography>
                {option?.roleName === 'EVERYONE' ? (
                  <Typography
                    variant={`helperText`}
                  >{`All people | All roles`}</Typography>
                ) : (
                  <Typography variant={`helperText`}>{`${option?.memberCount} ${
                    option?.memberCount === 1 ? 'person' : 'people'
                  } | ${option?.childRoleCount} ${
                    option?.childRoleCount === 1 ? 'role' : 'roles'
                  }`}</Typography>
                )}
              </ListItemText>
            </ListItem>
          );
        }}
        onChange={(event, newValue, reason) => {
          if (reason !== 'clear' && newValue !== null) {
            //@ts-ignore
            onRoleAdded(newValue);
          }
        }}
        filterOptions={(x) => x}
        renderTags={() => null}
        onInputChange={(event: SyntheticEvent) => {
          //@ts-ignore
          debouncedSearchChange(event);
        }}
        ListboxProps={{
          style: {
            maxHeight: '250px',
            width: '245px',
          },
          onScroll: handleScroll,
          role: 'list-box',
        }}
        renderInput={(params: any) => (
          <MuiTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              style: {
                width: '225px',
                borderRadius: 24,
                height: '30px',
                alignContent: 'center',
              },
              startAdornment: (
                <SearchIcon
                  fontSize={'small'}
                  sx={{ color: 'brandBlue.main' }}
                />
              ),
            }}
            placeholder="Search"
            margin="normal"
            onKeyDown={(event: any) => {
              if (event.key === 'Backspace') {
                event.stopPropagation();
              }
            }}
          />
        )}
      />
    </Stack>
  );
}
