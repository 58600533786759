import React from 'react';

import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SecurityIcon from '@mui/icons-material/Security';
import {
  Avatar,
  Box,
  BoxProps,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  StandardTextFieldProps,
} from '@mui/material';
import { FormikValues, useField, useFormikContext } from 'formik';
import { sortBy } from 'lodash';

import { Database, Role, Warehouse } from '../../graphql/gen/graphql';
import { BuiltInRoleNames, builtInRoles } from '../../graphql/role';
import TextField, { TextFieldProps } from './TextField';

interface SelectorProps extends StandardTextFieldProps {
  databases: Database[];
  name: string;
  helperText?: string;
}
export const DatabaseSelector = ({
  databases,
  name,
  helperText,
  ...props
}: SelectorProps) => {
  return (
    <>
      {databases && databases.length > 0 && (
        <TextField
          variant={'outlined'}
          fullWidth
          select
          name={name}
          label="Database"
          helperText
          {...props}
        >
          {databases.map((database) => (
            <MenuItem key={database.id} value={database.name}>
              {database.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};
