import React from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, BoxProps, IconButton, Tooltip } from '@mui/material';

export interface RefreshProps extends BoxProps {
  refreshList: any;
  title?: string;
  iconSize?: 'small' | 'medium' | 'large' | 'inherit' | undefined;
}
export const Refresh = ({
  refreshList,
  title,
  iconSize,
  ...props
}: RefreshProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
      {...props}
    >
      <Tooltip title={title} placement={'top'} arrow>
        <IconButton
          sx={(theme) => ({
            padding: '4px',
            border: `1px solid ${theme.palette.midnight.two}`,
            '&:hover': {
              backgroundColor: theme.palette.sky.one,
            },
            '&:active': {
              backgroundColor: theme.palette.sky.two,
            },
            '&:focus': {
              border: `1px solid ${theme.palette.sky.seven}`,
            },
          })}
          onClick={() => refreshList()}
        >
          <RefreshIcon fontSize={iconSize ? iconSize : 'medium'} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
