import React, { useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { KnownFeatureFlags } from '../../context/GlobalPropsContext';

import { toCompute } from '../../RouteTable';
import { GlobalPropertiesContext } from '../../context';
import {
  Organization,
  StorageType,
  Warehouse,
} from '../../graphql/gen/graphql';
import { Talert } from '../Alert/Talert';
import StatusBars from '../Feedback/StatusBars';
import DangerZone from '../TableSettings/DangerZone';
import { getDangerZoneItems } from './helpers';

export const WarehouseSettings = ({
  warehouse,
  organization,
  refetchQuery,
  userCanDrop,
  errorMessage,
  setErrorMessage,
  mutationError,
  setMutationError,
  handleDangerZoneMutation,
}: {
  warehouse: Warehouse;
  organization: Organization;
  refetchQuery: () => void;
  userCanDrop: boolean;
  errorMessage: any;
  setErrorMessage: any;
  mutationError: any;
  setMutationError: any;
  handleDangerZoneMutation: any;
}): JSX.Element => {
  //states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  const { ff } = useContext(GlobalPropertiesContext);
  const gcsTestingEnabled = ff(KnownFeatureFlags.GCS_TESTING_ENABLED);
  const adlsTestingEnabled = ff(KnownFeatureFlags.ADLS_TESTING_ENABLED);

  const dangerZoneItems = getDangerZoneItems(warehouse.name, warehouse.id);
  const orgName = organization?.name || '';

  const hideDueToStorageType = useMemo(
    () =>
      warehouse.storageProfile?.storageType === StorageType.Gcs
        ? !gcsTestingEnabled
        : warehouse.storageProfile?.storageType === StorageType.Adls
        ? !adlsTestingEnabled
        : false,
    [warehouse],
  );
  return (
    <>
      {!hideDueToStorageType && (
        <Talert
          severity="info"
          sx={{ width: 'fit-content', mb: 5 }}
          TabuttonProps={{
            children: <Box>Go to Compute</Box>,
            onClick: () => {
              navigate(toCompute(orgName));
            },
            size: 'small',
          }}
        >
          <Typography variant={'subtitle2'}>
            Snowflake settings have moved
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography variant={'helperText'} sx={{ flexGrow: 1 }}>
              Setup and configuration information can now be found on the
              Compute page.
            </Typography>
          </Box>
        </Talert>
      )}

      {userCanDrop && (
        <DangerZone
          dangerZoneItems={dangerZoneItems}
          onConfirm={handleDangerZoneMutation}
        />
      )}
      <StatusBars
        successDisplay={openSnackbar}
        handleSuccessClose={() => setOpenSnackbar(false)}
        successMessage={`${warehouse.name} settings have been updated.`}
        errorDisplay={mutationError}
        setErrorDisplay={setMutationError}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        refetch={() => refetchQuery()}
      />
    </>
  );
};
