import React, { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';

import { usePosthogClient } from '../../context/posthog';
import { FeedbackType, User } from '../../graphql/gen/graphql';
import { sendFeedback } from '../../graphql/organization';
import { Tabutton } from '../Button/Tabutton';
import TextField from './TextField';

interface FeedbackProps {
  user: User;
  onCancel: () => void;
}
export default function SendFeedback({ user, onCancel }: FeedbackProps) {
  const [receipt, setReceipt] = useState(false);
  const posthog = usePosthogClient();
  const [sendFeedbackMutation, { error }] = useMutation(sendFeedback);

  interface FormValues {
    feedback: string;
  }
  const initialValues: FormValues = {
    feedback: '',
  };
  const handleSubmitForm = useCallback(
    async (
      values: FormValues,
      { setSubmitting }: FormikHelpers<FormValues>,
    ) => {
      const feedbackProps = [{ name: 'Page URL', value: window.location.href }];

      if (posthog && posthog.sessionRecordingStarted()) {
        feedbackProps.push({
          name: 'Posthog Session Replay',
          value: posthog.get_session_replay_url(),
        });
      }
      const result = await sendFeedbackMutation({
        variables: {
          description: values.feedback,
          type: FeedbackType.Other,
          props: feedbackProps,
        },
        errorPolicy: 'all',
      });

      if (result?.data?.submitFeedback?.receipt) {
        setReceipt(true);
      }

      setSubmitting(false);
    },
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmitForm}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <DialogTitle>
            <Typography variant={`h6`} sx={{ color: 'dusk.six' }}>
              Contact us
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'midnight.nine',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />

          <DialogContent>
            {receipt ? (
              <Box sx={{ py: 1 }}>
                <Stack direction={`column`} spacing={1} alignItems={`center`}>
                  <MarkEmailReadIcon
                    sx={{ color: 'dusk.two', fontSize: '64px' }}
                  />
                  <Typography
                    variant={`h1`}
                    sx={{ color: 'brandBlue.main' }}
                  >{`Thanks ${user?.loginSession?.membership?.displayName}`}</Typography>
                  <Typography
                    variant={`subtitle1`}
                    sx={{ color: 'midnight.six' }}
                  >
                    We got it from here.
                  </Typography>
                </Stack>
              </Box>
            ) : (
              <>
                {error && (
                  <Box sx={{ py: 1 }}>
                    <Stack
                      direction={`column`}
                      spacing={1}
                      alignItems={`center`}
                    >
                      <UnsubscribeIcon
                        sx={{ color: 'dusk.two', fontSize: '64px' }}
                      />
                      <Typography
                        variant={`h1`}
                        sx={{ color: 'brandBlue.main' }}
                      >
                        Oh bother
                      </Typography>
                      <Typography
                        variant={`subtitle1`}
                        sx={{ color: 'midnight.six' }}
                      >
                        Your message didn’t make it to us.
                      </Typography>
                    </Stack>
                  </Box>
                )}
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  type="text"
                  name={`feedback`}
                  autoComplete="off"
                  placeholder={`How can we help you?`}
                />
              </>
            )}
          </DialogContent>
          <Divider />

          <DialogActions sx={{ p: 2 }}>
            {receipt ? (
              <Tabutton
                variant={`contained`}
                onClick={() => {
                  onCancel();
                }}
              >
                Close window
              </Tabutton>
            ) : (
              <>
                <Tabutton
                  onClick={() => {
                    onCancel();
                  }}
                >
                  {error ? `Nevermind` : `Cancel`}
                </Tabutton>
                <Tabutton
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting || isEmpty(values.feedback)}
                >
                  {error ? `Try again` : `Send`}
                </Tabutton>
              </>
            )}
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
