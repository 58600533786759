import React, { useMemo, useState, useRef } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Card,
  CardActions,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { defaultStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useAuth } from '../../../context/auth-context';
import {
  CredentialTypes,
  OrganizationCredential,
} from '../../../graphql/gen/graphql';
import { organizationCredentials } from '../../../graphql/organization';
import { getRolesByOrgIdQuery } from '../../../graphql/role';
import { Tabutton } from '../../Button/Tabutton';
import { IAMRoleMappingForm } from '../../Credentials/Forms/IAMRoleMappingForm';
import IAMRoleMapping from '../../Credentials/Grids/IAMRoleMapping';
import { CustomIcon } from '../../Icons/CustomIcon';

type InstructionsType = {
  step1: JSX.Element;
  example: string;
  step2: JSX.Element;
};

export const IAMRoleMappingStep = ({
  organizationId,
  organizationName,
  instructions,
}: {
  organizationId: string;
  organizationName: string;
  instructions: InstructionsType;
}) => {
  const { user } = useAuth();
  const [formVisible, setFormVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(organizationName),
    [user, organizationName],
  );

  const {
    data: orgCredentials,
    refetch: refetchCredentials,
    loading,
  } = useQuery(organizationCredentials, {
    variables: {
      organizationId,
    },
    skip: !isSecurityAdmin,
  });

  const iamCreds = useMemo(
    () =>
      orgCredentials?.organizationCredentials.filter(
        (cred: OrganizationCredential) => cred.type === CredentialTypes.Iam,
      ),
    [orgCredentials],
  );
  const { data: roleData } = useQuery(getRolesByOrgIdQuery, {
    variables: { organizationId },
    skip: !isSecurityAdmin,
  });

  const onCredentialCreated = async () => {
    await refetchCredentials();
    setFormVisible(false);
  };
  const handleAdd = () => {
    setFormVisible(true);
    setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth' }), 0);
  };

  return (
    <Stack sx={{ width: 0.95 }}>
      <Box sx={{ mt: 1 }}>
        {' '}
        <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            {instructions.step1}
            <Typography variant={'body1'}>
              Example:{' '}
              <SyntaxHighlighter
                language={'text'}
                style={defaultStyle}
                customStyle={{
                  display: 'inline-flex',
                  fontSize: '13px',
                  lineHeight: '1em',
                  margin: 0,
                  borderRadius: '4px',
                  paddingTop: '.3em',
                  paddingBottom: '.3em',
                }}
              >
                {instructions.example}
              </SyntaxHighlighter>
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            {instructions.step2}
          </ListItem>
        </List>
      </Box>
      <Card
        sx={(theme) => ({
          border: `1px solid ${theme.palette.midnight.two}`,
          borderRadius: 2,
          mt: 2,
        })}
      >
        <IAMRoleMapping
          credentialsData={iamCreds}
          orgName={organizationName}
          roles={roleData?.organization?.roles}
          hideDelete={true}
          loading={loading}
        />
        <CardActions
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.midnight.two}`,
            display: 'flex',
            justifyContent: 'end',
            padding: 0,
          })}
        >
          <Tabutton
            onClick={handleAdd}
            endIcon={<AddIcon />}
            size={'small'}
            sx={{ m: 1 }}
          >
            Add role mapping
          </Tabutton>
        </CardActions>
      </Card>
      {formVisible && (
        <div ref={ref}>
          <IAMRoleMappingForm
            organizationId={organizationId}
            onCredentialCreated={onCredentialCreated}
            modal={false}
            setFormVisible={() => setFormVisible(false)}
          />
        </div>
      )}
    </Stack>
  );
};
