import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton, InputAdornment, Tooltip } from '@mui/material';
import { useField } from 'formik';
import { isEmpty } from 'lodash';

import { DEFAULT_TTL_IN_MS } from '../../utils/properties';
import { Tabutton } from '../Button/Tabutton';
import TextField from '../Forms/TextField';
import { convertMillisecondsToDays } from './helpers';

type RowTTLPropTypes = {
  name: string;
  label: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  helperText?: string;
  setEditingPropGroup: (value: boolean) => void;
  stagePropertiesForDelete: (value: string[]) => void;
};

export default function LifecycleRowTTL({
  name,
  label,
  setFieldValue,
  helperText,
  setEditingPropGroup,
  stagePropertiesForDelete,
  ...props
}: RowTTLPropTypes) {
  const [field, meta, helpers] = useField('lifecycleMgmt.rowRecordAge');
  const [showRow, setShowRow] = useState(!isEmpty(field.value));

  const handleRowTTL = () => {
    setShowRow(true);
    helpers.setValue(convertMillisecondsToDays(DEFAULT_TTL_IN_MS));
    setEditingPropGroup(true);
  };

  return (
    <Box width={`100%`}>
      {showRow && (
        <Box display={`flex`} alignItems={`center`}>
          <TextField
            name={name}
            label={'Maximum record age'}
            sx={{
              mt: 1,
              mb: 2,
              justifyContent: 'flex-start',
              input: {
                textAlign: 'right',
              },
            }}
            onClick={() => setEditingPropGroup(true)}
            InputProps={{
              endAdornment: (
                <InputAdornment position={`end`}>{`days`}</InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '10%',
              pb: 2,
            }}
          >
            <IconButton
              onClick={() => {
                setShowRow(false);
                helpers.setValue('');
                setEditingPropGroup(true);
                stagePropertiesForDelete(['lifecycle.table.max-data-age-ms']);
              }}
            >
              <Tooltip title={`Remove row TTL`} placement={`top`}>
                <ClearIcon
                  sx={{
                    color: 'sunset.seven',
                  }}
                />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
      )}
      {!showRow && (
        <Tabutton
          size={`small`}
          endIcon={<AddIcon />}
          onClick={handleRowTTL}
          sx={{ mt: 2 }}
        >
          Add Row TTL
        </Tabutton>
      )}
    </Box>
  );
}
