import React, { useContext } from 'react';

import { MenuItem, Box, BoxProps } from '@mui/material';

import { KnownFeatureFlags } from '../../context/GlobalPropsContext';

import { GlobalPropertiesContext } from '../../context';
import { StorageType } from '../../graphql/gen/graphql';
import TextField, { TextFieldProps } from './TextField';

const alwaysRegions = [
  ...(process.env.REACT_APP_SUPPORTED_REGIONS_AWS?.split(',') || []),
];

export const SUPPORTED_BUCKET_REGIONS = [
  { storageType: StorageType.S3, code: 'us-east-1', name: 'N. Virginia' },

  { storageType: StorageType.S3, code: 'us-east-2', name: 'Ohio' },

  { storageType: StorageType.S3, code: 'us-west-2', name: 'Oregon' },

  {
    storageType: StorageType.S3,
    code: 'ca-central-1',
    name: 'Canada (Central)',
  },

  {
    storageType: StorageType.S3,
    code: 'eu-west-1',
    name: 'Europe (Ireland)',
  },

  {
    storageType: StorageType.S3,
    code: 'eu-central-1',

    name: 'Europe (Frankfurt)',
  },

  {
    storageType: StorageType.S3,
    code: 'ap-southeast-2',
    name: 'Asia Pacific (Sydney)',
  },

  {
    storageType: StorageType.S3,
    code: 'ap-southeast-1',
    name: 'Asia Pacific (Singapore)',
  },

  {
    storageType: StorageType.S3,
    code: 'af-south-1',
    name: 'Africa (Cape Town)',
  },

  {
    storageType: StorageType.S3,
    code: 'eu-north-1',
    name: 'Europe (Stockholm)',
  },
];
export interface RegionSelectorProps extends BoxProps {
  storageType: StorageType;
  textFieldProps?: TextFieldProps;
}
export default function RegionSelector({
  storageType,
  textFieldProps,
  ...props
}: RegionSelectorProps) {
  const { ff } = useContext(GlobalPropertiesContext);
  const showPreviewRegions = ff(KnownFeatureFlags.SHOW_PREVIEW_REGIONS);
  const regions = SUPPORTED_BUCKET_REGIONS.filter(
    (region) =>
      region.storageType === storageType &&
      (showPreviewRegions || alwaysRegions.includes(region.code)),
  );

  return (
    <Box {...props}>
      <TextField
        variant={'standard'}
        select
        name="region"
        label="Region*"
        {...textFieldProps}
      >
        {regions.map((region) => (
          <MenuItem key={region.code} value={region.code}>
            {region.name} ({region.code})
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}
