import React from 'react';

import { Stack, Box, CardHeader, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro';

import { BranchSelector } from '../BranchSelector/BranchSelector';
import { Refresh } from '../Button/Refresh';
import { MenuContentType } from '../DataGridToolbar/DataGridToolbar';
import { SnapshotRefProp } from '../SnapshotViewer/SnapshotView';

export default function DataPreviewToolbar({
  refreshResourceContent,
  breadcrumbs,
  branchRefs,
  selectedBranch,
  handleChangeBranch,
  showBranchSelector,
  displayName,
}: {
  refreshResourceContent: MenuContentType;
  breadcrumbs: any;
  branchRefs: SnapshotRefProp[];
  selectedBranch: string;
  handleChangeBranch: (branch: string) => void;
  showBranchSelector: boolean;
  displayName: string;
}) {
  const mobileToolBar = useMediaQuery('(max-width:500px)');

  return (
    <>
      <CardHeader
        sx={(theme) => ({
          paddingY: 1,
          paddingX: 2,
          margin: 'none',
          backgroundColor: 'midnight.one',
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
        title={
          <Box
            display="flex"
            flexDirection={mobileToolBar ? 'column' : 'row'}
            justifyContent={mobileToolBar ? 'flex-start' : 'space-between'}
            padding={0}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              sx={{ wordBreak: 'break-word' }}
            >
              <Typography variant={'h6'} pr={1}>
                {`${displayName}`}
              </Typography>
              <Stack direction="row" spacing={2} display={'block'}>
                {breadcrumbs}
              </Stack>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: mobileToolBar ? 'flex-start' : 'flex-end',
              }}
            >
              <Box
                pr={2}
                display={'flex'}
                alignContent={'center'}
                paddingTop={mobileToolBar ? 1 : 0}
              >
                <GridToolbarQuickFilter
                  variant={'outlined'}
                  sx={(theme) => ({
                    width: '100%',
                    maxWidth: '300px',
                    justifyContent: 'center',
                    py: 0,
                    minWidth: '130px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '24px',
                      backgroundColor: 'white',
                      height: '28px',
                      fontSize: theme.typography.pxToRem(14),
                      fontStyle: 'italic',
                      cursor: 'pointer',
                    },
                  })}
                  quickFilterParser={(searchInput: string) =>
                    searchInput
                      .split(',')
                      .map((value) => value.trim())
                      .filter((value) => value !== '')
                  }
                />
              </Box>
              {refreshResourceContent && (
                <Refresh
                  refreshList={refreshResourceContent.action}
                  title={refreshResourceContent.title}
                />
              )}
            </Box>
          </Box>
        }
      />
      {showBranchSelector && (
        <Box
          display="flex"
          flexDirection={mobileToolBar ? 'column' : 'row'}
          justifyContent={mobileToolBar ? 'flex-start' : 'space-between'}
          padding={0}
          sx={{
            paddingY: 1,
            paddingX: 2,
            backgroundColor: 'midnight.one',
          }}
        >
          <BranchSelector
            branchRefs={branchRefs || []}
            selectedBranch={selectedBranch}
            handleChangeBranch={handleChangeBranch}
            sx={{ height: 28 }}
          ></BranchSelector>
        </Box>
      )}
    </>
  );
}
