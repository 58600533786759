import React from 'react';
import { Routes, useNavigate } from 'react-router-dom';

import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';

import { Organization, User } from '../../graphql/gen/graphql';
import CreateOrganization from '../Forms/CreateOrganization';
import { showLogoutDialog } from './LogoutDialog';

interface CreateOrganizationDialogProps {
  user: User;
}
const CreateOrganizationDialog = create(
  ({ user }: CreateOrganizationDialogProps) => {
    const modal = useModal();

    const onOrganizationCreated = (organization: Organization) => {
      modal.hide();
      showLogoutDialog(
        organization,
        user,
        `Would you like to logout and login to ${organization.displayName} now?`,
      );
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <CreateOrganization
          user={user}
          onCancel={modal.hide}
          onOrganizationCreated={onOrganizationCreated}
        />
      </Dialog>
    );
  },
);

export default CreateOrganizationDialog;

export function showCreateOrganizationDialog(user: User) {
  return show<boolean, any, CreateOrganizationDialogProps>(
    CreateOrganizationDialog,
    { user },
  );
}
