import React, { useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, Box, Menu, Skeleton, BreadcrumbsProps } from '@mui/material';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

import { databasePeersQuery } from '../../graphql/database';
import { geStorageProfilePeers } from '../../graphql/organization';
import { tablePeersQuery } from '../../graphql/table';
import { warehousePeersQuery } from '../../graphql/warehouse';
import { CustomIcon } from '../Icons/CustomIcon';
import SneezShield from '../SneezeShield/SneezShield';
import { formatDropdownContent } from './helpers';

export const MenuSkeleton = () => (
  <>
    <Skeleton variant="text" height={25} />
    <Skeleton variant="text" height={25} />
    <Skeleton variant="text" height={25} />
  </>
);

export interface TBreadcrumbsProps extends BreadcrumbsProps {
  links: any;
  readonly?: boolean;
}

export default function Breadcrumbs({
  links,
  readonly,
  ...props
}: TBreadcrumbsProps) {
  const [anchorEl, setAnchorEl] = useState<Element | undefined | null>(
    undefined,
  );
  const dropdownOpen = Boolean(anchorEl);
  const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
  const [dropdownContent, setDropdownContent] = useState<any>(null);
  const [warehouseDropdown] = useLazyQuery(warehousePeersQuery);
  const [databaseDropdown] = useLazyQuery(databasePeersQuery);
  const [tableDropdown] = useLazyQuery(tablePeersQuery);
  const [storageProfileDropdown] = useLazyQuery(geStorageProfilePeers);

  const handleWarehouseDropdown = async (link: any) => {
    await warehouseDropdown({
      variables: link.dropdown.variables,
    }).then(({ data }) => {
      const warehouse = data?.organization?.warehouses;
      const sortableArray = [...warehouse];
      const alphabetizedWarehouses = sortableArray.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1,
      );
      const whContent = formatDropdownContent(
        'Warehouses',
        alphabetizedWarehouses,
        link,
        'WAREHOUSE',
        [data.organization.name],
      );
      return setDropdownContent(whContent);
    });
  };

  const handleDatabaseDropdown = async (link: any) => {
    await databaseDropdown({
      variables: link.dropdown.variables,
    }).then(({ data }) => {
      const dbContent = formatDropdownContent(
        'Databases',
        data?.organization?.warehouse?.databases,
        link,
        'DATABASE',
        [data.organization.name, data.organization.warehouse.name],
      );
      return setDropdownContent(dbContent);
    });
  };

  const handleTableDropdown = async (link: any) => {
    await tableDropdown({
      variables: link.dropdown.variables,
    }).then(({ data }) => {
      const tblContent = formatDropdownContent(
        'Tables',
        data?.organization?.warehouse?.database?.tables,
        link,
        'TABLE',
        [
          data.organization.name,
          data.organization.warehouse.name,
          data.organization.warehouse.database.name,
        ],
      );
      return setDropdownContent(tblContent);
    });
  };

  const handleStorageProfileDropdown = async (link: any) => {
    await storageProfileDropdown({
      variables: link.dropdown.variables,
    }).then(({ data }) => {
      const profiles = data?.organization?.storageProfiles;
      const sortableArray = [...profiles];
      const alphabetized = sortableArray
        .sort((a, b) =>
          a.bucket.toUpperCase() > b.bucket.toUpperCase() ? 1 : -1,
        )
        .map((profile) => {
          return { ...profile, name: profile.bucket };
        });
      const spContent = formatDropdownContent(
        'Storage profiles',
        alphabetized,
        link,
        'STORAGE_PROFILE',
        [data.organization.name],
      );
      return setDropdownContent(spContent);
    });
  };

  const QueryTypesEnum: any = Object.freeze({
    WAREHOUSE: { handler: handleWarehouseDropdown },
    DATABASE: { handler: handleDatabaseDropdown },
    TABLE: { handler: handleTableDropdown },
    STORAGE_PROFILE: { handler: handleStorageProfileDropdown },
  });

  const handleDropdownClick = (
    event: React.MouseEvent<Element>,
    link: any,
    index: any,
  ) => {
    setDropdownContent(null);
    setAnchorEl(event.currentTarget?.parentNode?.parentNode as Element);
    setActiveDropdownId(`dropdown-${link.text}-${index}`);
    return QueryTypesEnum[link.dropdown.type].handler(link);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    setActiveDropdownId(null);
  };
  const lastLink = links?.length - 1;

  return (
    <SneezShield enabled={readonly === undefined ? false : readonly}>
      <MUIBreadcrumbs
        aria-label="breadcrumb"
        separator={''}
        {...props}
        sx={(theme) => ({
          whiteSpace: 'nowrap',
          overflowX: 'auto',
          scrollbarWidth: 'none',
          '& .breadcrumb-wrapper a': {
            color: theme.palette.sky.seven,
          },
          '> ol': {
            flexWrap: 'nowrap',
            overflowX: 'auto',
            scrollbarWidth: 'none',
          },
          ...(props.sx ? (props.sx as SystemStyleObject) : {}),
        })}
      >
        {links &&
          links?.map((link: any, index: number) => (
            <Box
              sx={{
                margin: '2px 2px 2px 0',
                display: 'flex',
                height: '16px',
              }}
              className={`breadcrumb-wrapper`}
              key={link.href}
              id={`menu_box_${link.href}`}
            >
              <Link
                {...(readonly ? { tabIndex: -1 } : {})}
                underline="hover"
                href={link.href}
                variant={`subtitle2`}
                sx={{
                  color: 'midnight.six',
                  height: '16px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {link.text}
              </Link>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {link.dropdown && !readonly && (
                  <ArrowDropDownIcon
                    sx={(theme) => ({
                      transition: theme.transitions.create(['transform'], {
                        duration: '.5s',
                      }),
                      transform:
                        dropdownOpen && anchorEl?.id === `menu_box_${link.href}`
                          ? 'rotate(-90deg)'
                          : 'rotate(0deg)',
                      cursor: 'pointer',
                      color: 'sky.seven',
                      marginLeft: '8px',
                    })}
                    onClick={(event) => handleDropdownClick(event, link, index)}
                    aria-controls={
                      dropdownOpen && anchorEl?.id === `menu_box_${link.href}`
                        ? `dropdown-${link.text}-${index}`
                        : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={
                      dropdownOpen && anchorEl?.id === `menu_box_${link.href}`
                        ? 'true'
                        : undefined
                    }
                    fontSize={`small`}
                  />
                )}
                {lastLink !== index && (
                  <CustomIcon
                    src={'/assets/icon/divider.svg'}
                    sx={{ mr: 2, ml: 2 }}
                  />
                )}
              </Box>
              <Menu
                id={`dropdown-${link.text}-${index}`}
                anchorEl={anchorEl}
                open={dropdownOpen && anchorEl?.id === `menu_box_${link.href}`}
                onClose={handleDropdownClose}
                transitionDuration={500}
                sx={(theme) => ({
                  '& .MuiPaper-root': {
                    border: `1px solid ${theme.palette.midnight.two}`,
                    borderRadius: '4px',
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    minWidth: '130px',
                    maxWidth: '320px',
                    '& .breadcrumb-dropdown-link:hover': {
                      backgroundColor: theme.palette.sky.one,
                    },
                  })}
                >
                  {dropdownContent ?? <MenuSkeleton />}
                </Box>
              </Menu>
            </Box>
          ))}
      </MUIBreadcrumbs>
    </SneezShield>
  );
}
