import React, { useMemo } from 'react';

import { includes, map } from 'lodash';

import {
  AuthDecisionResourceType,
  Authorization,
  Privilege,
  Role,
} from '../../graphql/gen/graphql';
import { PrivilegeSelected } from './RolePrivileges';

export type AuthWithGrant = {
  privilege: Privilege;
  withGrant: boolean;
};

export const getRoleIds = (authorizations: any) => {
  return map(authorizations, (authGroup) => authGroup.role.id);
};

export const getAuthsToCreate = (
  authsToCreate: PrivilegeSelected[],
  resource: string,
  resourceType: AuthDecisionResourceType,
  roleId: string,
) => {
  return authsToCreate.map((priv: any) => {
    return {
      privilege: priv.privilege,
      resource: resource,
      resourceType: resourceType,
      roleId: roleId,
      withGrant: priv.withGrant,
    };
  });
};

export const getAuthsToUpdate = (
  authsToUpdate: PrivilegeSelected[],
  originalPrivileges: Authorization[],
) => {
  return authsToUpdate.map((priv) => {
    const auth = originalPrivileges.find(
      (ogPriv) => ogPriv.privilege === priv.privilege,
    );

    if (auth) {
      return {
        authorizationId: auth.id,
        privilege: priv.privilege,
        resource: auth.resource,
        resourceType: auth.resourceType,
        roleId: auth.roleId,
        withGrant: priv.withGrant,
      };
    } else return [];
  });
};
export const getCurrentPrivilegesWithGrant = (
  currentGrants: AuthWithGrant[],
) => {
  return useMemo(
    () =>
      currentGrants?.filter(
        (priv: AuthWithGrant) =>
          (priv.withGrant && !priv.privilege.includes('FUTURE')) ||
          priv.privilege === Privilege.ManageGrants,
      ),
    [currentGrants],
  );
};

export const getUserFriendlyErrorMessage = (message: any) => {
  switch (true) {
    case includes(message, 403):
      return 'You do not have applicable permissions to make this change. Please contact your Security Admin.';
    case includes(message, 409):
      return 'A role with this name already exists.';
    default:
      return 'Something went wrong. Please refresh the page and try again.';
  }
};

const warehousePrivs = [
  { privilege: Privilege.ManageGrants, group: 'WAREHOUSE' },
  {
    privilege: Privilege.FutureManageGrantsDatabase,
    group: 'FUTURE DATABASES',
  },
  { privilege: Privilege.FutureManageGrantsTable, group: 'FUTURE TABLES' },
];

const databasePrivs = [
  {
    privilege: Privilege.ManageGrants,
    group: 'DATABASE',
  },
  { privilege: Privilege.FutureManageGrantsTable, group: 'FUTURE TABLES' },
];
const tablePrivs = [{ privilege: Privilege.ManageGrants, group: 'TABLE' }];
const storageProfilePrivs = [
  { privilege: Privilege.ManageGrants, group: 'STORAGE PROFILE' },
];
const labelPrivs = [{ privilege: Privilege.ManageGrants, group: 'LABEL' }];

const getResourcePrivs = (resourceType: AuthDecisionResourceType) => {
  switch (resourceType) {
    case AuthDecisionResourceType.Warehouse:
      return warehousePrivs;
    case AuthDecisionResourceType.Database:
      return databasePrivs;
    case AuthDecisionResourceType.Table:
      return tablePrivs;
    case AuthDecisionResourceType.StorageProfile:
      return storageProfilePrivs;
    case AuthDecisionResourceType.Label:
      return labelPrivs;
    default:
      return [{ privilege: Privilege.ManageGrants, group: resourceType }];
  }
};
export const getSecurityAdminDefault = (
  role: Role | any,
  resourceType: AuthDecisionResourceType,
) => {
  const privs = getResourcePrivs(resourceType);
  return {
    authorizations: privs.map((priv) => {
      return {
        privilege: priv.privilege,
        resourceType: resourceType,
        id: '',
        withGrant: true,
        resource: '',
        roleId: role?.id,
        groupingName: priv.group,
        ...(resourceType === AuthDecisionResourceType.Label && {
          selected: true,
          roleName: role?.name,
        }),
      };
    }),
    role: role,
  };
};
