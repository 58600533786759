import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import SendIcon from '@mui/icons-material/Send';
import { Stack, Snackbar, Divider, Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useAuth } from '../context/auth-context';
import {
  triggerMaintenanceAction,
  triggerAnalyzeAction,
} from '../graphql/table';
import { MoreColor, Talert } from './Alert/Talert';
import { Tabutton } from './Button/Tabutton';

const MaintenanceActions = () => {
  const { user } = useAuth();

  const {
    organization: organizationName,
    warehouse: warehouseName,
    database,
    table,
  } = useParams();

  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] =
    useState<MoreColor>('info');

  const [doTriggerMaintenanceAction, { loading }] = useMutation(
    triggerMaintenanceAction,
    {
      variables: {
        warehouseId: user.getWarehouse(organizationName, warehouseName).id,
        database,
        table,
      },
    },
  );

  const [doTriggerAnalyzeAction, { loading: analyzeLoading }] = useMutation(
    triggerAnalyzeAction,
    {
      variables: {
        warehouseId: user.getWarehouse(organizationName, warehouseName).id,
        database,
        table,
      },
    },
  );

  const onMaintenanceButtonClick = (operation: string) => {
    doTriggerMaintenanceAction({
      variables: { operation: operation },
      onCompleted: () => {
        setNotificationVisible(true);
        setNotificationMessage(
          `${operation} action queued.  This may take up to an hour to successfully complete.`,
        );
        setNotificationSeverity('success');
      },
      onError: () => {
        setNotificationVisible(true);
        setNotificationMessage(
          `${operation} action failed to submit, please try again later.`,
        );
        setNotificationSeverity('error');
      },
    });
  };

  const onAnalyzeButtonClick = () => {
    doTriggerAnalyzeAction({
      onCompleted: () => {
        setNotificationVisible(true);
        setNotificationMessage(
          `Analyze action queued.  This may take a few minutes to successfully complete.`,
        );
        setNotificationSeverity('darkSuccess' as MoreColor);
      },
      onError: () => {
        setNotificationVisible(true);
        setNotificationMessage(
          `Analyze action failed to submit, please try again later.`,
        );
        setNotificationSeverity('darkError' as MoreColor);
      },
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setNotificationVisible(false)}
        open={notificationVisible}
      >
        <Talert severity={notificationSeverity}>{notificationMessage}</Talert>
      </Snackbar>

      <Box>
        <Typography variant="body1">
          Manually trigger table maintenance actions:
        </Typography>
        <Stack
          direction="column"
          alignItems="flex-start"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
          mt={2}
        >
          <Tabutton
            onClick={() => onMaintenanceButtonClick('EXPIRE_SNAPSHOTS')}
            loading={loading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            variant="outlined"
            loadingBtn
          >
            EXPIRE_SNAPSHOTS
          </Tabutton>
          <Tabutton
            onClick={() => onMaintenanceButtonClick('REMOVE_ORPHAN_FILES')}
            loading={loading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            variant="outlined"
            loadingBtn
          >
            REMOVE_ORPHAN_FILES
          </Tabutton>
          <Tabutton
            onClick={() => onMaintenanceButtonClick('REWRITE_DATA_FILES')}
            loading={loading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            variant="outlined"
            loadingBtn
          >
            REWRITE_DATA_FILES
          </Tabutton>
          <Tabutton
            onClick={() => onMaintenanceButtonClick('DATA_LIFECYCLE')}
            loading={loading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            variant="outlined"
            loadingBtn
          >
            DATA_LIFECYCLE
          </Tabutton>
          <Tabutton
            onClick={() => onMaintenanceButtonClick('REWRITE_MANIFESTS')}
            loading={loading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            variant="outlined"
            loadingBtn
          >
            REWRITE_MANIFESTS
          </Tabutton>
          <Tabutton
            onClick={() => onAnalyzeButtonClick()}
            loading={analyzeLoading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            variant="outlined"
            loadingBtn
          >
            OPTIMIZE
          </Tabutton>
        </Stack>
      </Box>
    </>
  );
};

export { MaintenanceActions };
