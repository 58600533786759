import React, { useCallback, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useModal, create, show } from '@ebay/nice-modal-react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Form, Formik } from 'formik';

// @ts-ignore
import { SHOWED_INIT_EXPERIENCE_NAME } from '../../context/auth-context';
import { Database, IcebergContentType } from '../../graphql/gen/graphql';
import { renameTable } from '../../graphql/table';
import { warehouseById } from '../../graphql/warehouse';
import { STATISTICS_DAYS_BACK } from '../../pages/helpers';
import { Tabutton } from '../Button/Tabutton';
// @ts-ignore
import StatusBars from '../Feedback/StatusBars';
import { DatabaseSelector } from '../Forms/DatabaseSelector';
import TextField from '../Forms/TextField';

export interface RenameResponse {
  newTableName: string;
  newDatabaseName: string;
}

interface RenameTableDialogProps {
  organizationId: string;
  warehouseId: string;
  database: string;
  table: string;
  contentType: IcebergContentType;
}
const RenameTableDialog = create(
  ({
    organizationId,
    warehouseId,
    database,
    table,
    contentType,
  }: RenameTableDialogProps) => {
    const modal = useModal();

    const [mutationError, setMutationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [renameTableMutation, { error }] = useMutation(renameTable);

    const {
      data,
      loading,
      error: databaseError,
      refetch: refetchWarehouse,
    } = useQuery(warehouseById, {
      variables: {
        organizationId,
        warehouseId,
        daysBack: STATISTICS_DAYS_BACK,
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    });

    const initialValues: RenameResponse = {
      newTableName: table,
      newDatabaseName: database,
    };

    const databases: Database[] = useMemo(
      () => data?.organization?.warehouse?.databases,
      [data],
    );

    const handleSubmitForm = useCallback(
      async ({ newTableName, newDatabaseName }: RenameResponse) => {
        const { errors } = await renameTableMutation({
          variables: {
            warehouseId,
            database,
            table,
            newTableName,
            newDatabaseName,
            contentType,
          },
          errorPolicy: 'all',
        });
        if (errors && error) {
          setErrorMessage(error?.message);
          setMutationError(true);
        }
        if (errors && errors.length) {
          const errormessage =
            errors['0']?.extensions?.response?.body?.error?.message ||
            errors[0].message;

          setErrorMessage(errormessage);
          setMutationError(true);
        } else {
          modal.resolve({ newTableName, newDatabaseName });
          modal.hide();
        }
      },
      [renameTableMutation],
    );

    return (
      <Dialog
        open={modal.visible}
        fullWidth
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
          {({ submitForm, isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogTitle variant={'h1'} sx={{ mt: 4, ml: 2 }}>
                Rename table
              </DialogTitle>

              <DialogContent sx={{ ml: 2, mt: 1 }}>
                <Typography>
                  {`Choose a destination database and ${contentType} name below.`}
                </Typography>
                <Grid
                  container
                  sx={{ mt: 1, mb: 2 }}
                  spacing={2}
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={6}>
                    <DatabaseSelector
                      databases={databases}
                      name={'newDatabaseName'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name={`newTableName`}
                      label={`New ${contentType} Name`}
                    />
                  </Grid>
                </Grid>
                <StatusBars
                  errorDisplay={mutationError}
                  setErrorDisplay={setMutationError}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                />

                <DialogActions>
                  <Tabutton onClick={() => modal.hide()}>Cancel</Tabutton>
                  <Tabutton
                    type={`submit`}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    loadingBtn
                  >
                    Rename
                  </Tabutton>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  },
);

export function showRenameTableDialog(params: RenameTableDialogProps) {
  return show<RenameResponse, any, RenameTableDialogProps>(
    RenameTableDialog,
    params,
  );
}
