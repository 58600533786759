import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BoxProps } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { toTableRoot } from '../../RouteTable';
import { getAuthorizationDecision } from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  AuthDecisionSubjectType,
  Pipeline,
  PipelineReference,
  PipelineState,
  PipelineType,
  Privilege,
  Table,
} from '../../graphql/gen/graphql';
import { deletePipeline, getPipelineById } from '../../graphql/pipeline';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import { DropdownMenuContent } from '../DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../DropdownMenu/DropdownMenuItem';
import { showConfirmationDialog } from '../Modals/ConfirmationDialogModal';
import { showTypeDeleteDialog } from '../Modals/TypeDeleteDialog';
import { StatusBoardVariant, StatusPanel } from '../StatusBoard';
import StatusBoard from '../StatusBoard/StatusBoard';

export interface TablePipelineProps extends BoxProps {
  organizationId: string;
  basicPipelineReference: PipelineReference;
  onPipelineDeleted: () => void;
  user: any;
  tableId: string;
  orgName: string;
}

export const TablePipeline = ({
  organizationId,
  basicPipelineReference,
  onPipelineDeleted,
  user,
  tableId,
  orgName,
  ...props
}: TablePipelineProps) => {
  const navigate = useNavigate();
  const {
    data: pipelineData,
    startPolling,
    stopPolling,
  } = useQuery(getPipelineById, {
    variables: {
      organizationId,
      pipelineId: basicPipelineReference?.pipelineId,
    },
  });

  const isTargetTable = useMemo(() => {
    return (
      pipelineData?.pipeline?.target?.context?.tableReferenceId === tableId
    );
  }, [pipelineData, tableId]);

  const [canUserDeletePipeline] = useLazyQuery(getAuthorizationDecision);
  const [deleteTheDangPipeline] = useMutation(deletePipeline, {
    onCompleted: async () => {
      onPipelineDeleted();
      enqueueSnackbar('Pipeline successfully deleted', {
        variant: 'success',
      });
    },
    onError: (e) => {
      enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
        variant: 'error',
      });
    },
  });

  const handleDeletePipeline = async (pipeline: Pipeline) => {
    const decision = await canUserDeletePipeline({
      variables: {
        request: {
          subject: {
            type: AuthDecisionSubjectType.User,
            identifier: user.id,
          },
          privileges: [Privilege.Update],
          requirement: 'ALL',
          resource: {
            type: AuthDecisionResourceType.Table,
            identifier: pipeline?.target?.context?.tableReferenceId,
          },
        },
      },
    });

    if (decision?.data?.authDecision === AuthDecisionResponseType.Deny) {
      showConfirmationDialog({
        title: 'Permission denied',
        children:
          'You need UPDATE privileges on the target table in order to delete this pipeline ',
      });
    }

    if (decision?.data?.authDecision === AuthDecisionResponseType.Allow) {
      showTypeDeleteDialog({
        title: `Delete ${pipeline.name} pipeline?`,
        confirmText: `Delete pipeline`,
        recoveryWarningText: `A deleted pipeline cannot be resumed. You can recreate it, but must bootstrap and initialize again. You will not lose data.`,
      }).then(() =>
        deleteTheDangPipeline({
          variables: {
            organizationId,
            pipelineId: pipeline.id,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (
      pipelineData?.pipeline?.state === PipelineState.Initializing ||
      pipelineData?.pipeline?.state === PipelineState.Running ||
      pipelineData?.pipeline?.state === PipelineState.Paused
    ) {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [pipelineData]);

  const statusPanels: StatusPanel[] | undefined = useMemo(() => {
    if (pipelineData) {
      return [
        {
          id: pipelineData?.pipeline?.id,
          sections: [
            {
              variant: 'pipeline-header' as StatusBoardVariant,
              state: pipelineData?.pipeline?.state,
              headerName: 'Pipeline',
              statusMessages: [
                pipelineData?.pipeline?.name,
                pipelineData?.pipeline?.description,
              ],
              details: undefined,
              associatedMember: pipelineData?.pipeline?.createdByMember,
            },
            {
              variant: 'pipeline-source' as StatusBoardVariant,
              statusMessages: [
                'Table',
                //@ts-ignore
                pipelineData?.pipeline?.sources?.[0]?._table
                  ? //@ts-ignore
                    `${pipelineData?.pipeline?.sources?.[0]?._table?.tableName}`
                  : `Unavailable`,
              ],
              details: undefined,
              headerLabel: 'Source',
              slots: {
                headerIcon: {
                  src: '/assets/icon/table.svg',
                },
              },
            },
            {
              variant: 'pipeline-service' as StatusBoardVariant,
              state: pipelineData?.pipeline?.state,
              details: undefined,
              headerLabel: 'Service',
              headerName: basicPipelineReference?.pipelineType || '',
              slots: {
                headerIcon: {
                  src:
                    basicPipelineReference?.pipelineType ===
                    PipelineType.Replication
                      ? '/assets/icon/auto_replication.svg'
                      : '/assets/icon/dedupe.svg',
                },
              },
            },
            {
              variant: 'pipeline-target' as StatusBoardVariant,
              details: undefined,
              statusMessages: [
                'Table',
                //@ts-ignore
                `${
                  pipelineData?.pipeline?.target?._table?.tableName ||
                  'Unavailable'
                }`,
              ],
              headerLabel: 'Target',
              slots: {
                headerIcon: {
                  src: '/assets/icon/table.svg',
                },
              },
            },
          ],
          actions: [
            {
              title: 'Delete',
              icon: {
                icon: Close,
              },
              iconButtonProps: {
                onClick: () => {
                  handleDeletePipeline(pipelineData?.pipeline);
                },
              },
            },
          ],
          dashboardDropdownContent: (
            <DropdownMenuContent
              menuItems={[
                <DropdownMenuItem
                  key={'goToTable'}
                  showAsMenuButton
                  titleText={
                    isTargetTable ? 'Go to source table' : 'Go to target table'
                  }
                  Icon={ArrowForwardIcon}
                  onClick={() => {
                    navigate(
                      isTargetTable
                        ? toTableRoot(
                            orgName,
                            pipelineData?.pipeline?.sources?.[0]?._table
                              ?.warehouseName || '',
                            pipelineData?.pipeline?.sources?.[0]?._table
                              ?.namespaceName || '',
                            pipelineData?.pipeline?.sources?.[0]?._table
                              ?.tableName || '',
                          )
                        : toTableRoot(
                            orgName,
                            pipelineData?.pipeline?.target?._table
                              ?.warehouseName || '',
                            pipelineData?.pipeline?.target?._table
                              ?.namespaceName || '',
                            pipelineData?.pipeline?.target?._table?.tableName ||
                              '',
                          ),
                    );
                  }}
                />,
                <DropdownMenuItem
                  key={'deletePipeline'}
                  showAsMenuButton
                  titleText={'Delete pipeline'}
                  onClick={() => {
                    handleDeletePipeline(pipelineData?.pipeline);
                  }}
                  dangerZone
                />,
              ]}
            />
          ),
        },
      ];
    } else {
      return undefined;
    }
  }, [pipelineData]);

  if (!statusPanels) {
    return null;
  }

  return <StatusBoard statusPanels={statusPanels} {...props} />;
};
