import React from 'react';

import { Table } from '../../../graphql/gen/graphql';
import { TableUpdate } from '../../../schema/events/table.update';
import { Verbosity } from '../TableEventsView';
import TableUpdateSchemaDetailedView from './TableUpdateSchemaDetailedView';
import TableUpdateSchemaSummaryView from './TableUpdateSchemaSummaryView';

type Props = {
  event: TableUpdate;
  currentSchemaId: number;
  previousSchemaId: number;
  table: Table;
  verbosity?: Verbosity;
};
export default function TableUpdateSchemaView({
  event,
  currentSchemaId,
  previousSchemaId,
  table,
  verbosity = Verbosity.Summary,
}: Props) {
  const currentSchema = table?.metadata?.schemas?.find(
    (schema) => schema?.schema_id == currentSchemaId,
  );
  const previousSchema = table?.metadata?.schemas?.find(
    (schema) => schema?.schema_id == previousSchemaId,
  );
  if (!currentSchema || !previousSchema) {
    return <>Current or previous schema is null.</>;
  }
  switch (verbosity) {
    case Verbosity.Summary:
      return (
        <TableUpdateSchemaSummaryView
          event={event}
          previousSchema={previousSchema}
          currentSchema={currentSchema}
          table={table}
        ></TableUpdateSchemaSummaryView>
      );
    case Verbosity.Detailed:
      return (
        <TableUpdateSchemaDetailedView
          event={event}
          previousSchema={previousSchema}
          currentSchema={currentSchema}
          table={table}
        ></TableUpdateSchemaDetailedView>
      );
    default:
      return <>Unknown Verbosity Mode</>;
  }
}
