import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { Form, Formik, FormikProps } from 'formik';
// @ts-ignore
import { bool } from 'yup';

import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import { Tacard } from '../Card/Tacard';
import SectionHeader from '../SectionHeader/SectionHeader';
import { Setting, SettingGroup } from './Settings';

export const StyledAccordion = styled(Accordion)(({ theme }) => {
  return {
    backgroundColor: theme.palette.controlBackground.main,
    paddingX: 0,
    '& .MuiStack-root': {
      justifyContent: 'flex-start',
    },
    '&.Mui-expanded': {
      margin: 0,
      backgroundColor: theme.palette.controlBackground.main,
      marginBottom: 0,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.controlBackground.main,
      marginTop: 0,
      '& .MuiAccordionSummary-expandIconWrapper': {
        display: 'none',
      },
      '&:last-of-type': {
        bottomBorder: 'none',
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
    '&:last-of-type': { mb: 0 },
  };
});

const SettingGroupAccordionVariant = ({
  eachSettingGroup,
  index,
  isSubmitting,
  editingSetting,
  setEditingSetting,
  formikProps,
  pendingConnection,
  userCanEdit,
  disabled,
  loading,
}: {
  eachSettingGroup: SettingGroup<any>;
  index: number;
  isSubmitting: boolean;
  editingSetting: boolean;
  setEditingSetting: React.Dispatch<React.SetStateAction<boolean>>;
  formikProps: FormikProps<any>;
  pendingConnection: boolean;
  userCanEdit: boolean;
  disabled: boolean;
  loading: boolean;
}): JSX.Element => {
  const deleteSnowflake = index === 1;
  const showButtons = [0, 1, 4].includes(index);
  return (
    <StyledAccordion
      key={'setting_group_field_' + index}
      disabled={eachSettingGroup?.disabled === true}
      onChange={(event, newExpanded) => {
        if (eachSettingGroup.onAccordionChange) {
          eachSettingGroup.onAccordionChange(newExpanded).then((result) => {
            if (result && result.shouldSetEditing) {
              setEditingSetting(true);
            }
          });
        }
      }}
      style={{
        display:
          eachSettingGroup?.visible === undefined || eachSettingGroup.visible
            ? 'block'
            : 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={(theme) => ({
          height: '56px',
          borderTop: `1px solid ${theme.palette.midnight.two}`,
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
          backgroundColor: theme.palette.controlBackground.main,
        })}
      >
        <Typography variant={`subtitle1`}>{eachSettingGroup.label}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 2 }}>
        <Typography variant={`body2`} component="span" sx={{ m: 2 }}>
          {eachSettingGroup.description}
        </Typography>
        <Box onClick={() => userCanEdit && setEditingSetting(true)}>
          {eachSettingGroup.fields.map((eachField, index) =>
            //only pass dynField, editingSetting, and isSubmitting if the component is a DynSettingField
            React.createElement(eachField.component, {
              ...(eachField?.component?.name === 'DynSettingField'
                ? {
                    dynField: eachField,
                  }
                : {}),
              name: eachField.name,
              label: eachField.label,
              helperText: eachField.description,
              key: 'setting_group_field_' + index,
              disabled: isSubmitting || !userCanEdit || disabled,
              ...eachField.componentProps,
              formikProps,
              editingSetting: editingSetting,
              isSubmitting: isSubmitting,
              style: { alignSelf: 'flex-start' },
            }),
          )}
        </Box>
      </AccordionDetails>
      {showButtons && (
        <CardActions
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.midnight.two}`,
            display: 'flex',
            justifyContent: 'end',
            p: 2,
          })}
        >
          <Tabutton
            loadingBtn
            onClick={() => {
              formikProps.resetForm();
              setEditingSetting(false);
            }}
            disabled={
              disabled || !userCanEdit || deleteSnowflake
                ? !pendingConnection
                : !editingSetting
            }
            loading={loading}
          >
            Cancel
          </Tabutton>
          <Tabutton
            loadingBtn
            variant={'contained'}
            onClick={async () => {
              await formikProps.submitForm();
            }}
            disabled={
              disabled || !userCanEdit || deleteSnowflake
                ? !pendingConnection
                : !editingSetting
            }
            loading={loading}
          >
            Apply Changes
          </Tabutton>
        </CardActions>
      )}
    </StyledAccordion>
  );
};

const SettingGroupBoxVariant = ({
  eachSettingGroup,
  index,
  editingSetting,
  setEditingSetting,
  isSubmitting,
  formikProps,
  userCanEdit,
  disabled,
  loading,
}: {
  eachSettingGroup: SettingGroup<any>;
  index: number;
  editingSetting: boolean;
  setEditingSetting: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting: boolean;
  formikProps: FormikProps<any>;
  userCanEdit: boolean;
  disabled: boolean;
  loading: boolean;
}): JSX.Element => {
  const settingVisible =
    eachSettingGroup.visible === undefined || eachSettingGroup.visible;
  return (
    <>
      <Box
        key={'setting_group_field_' + index}
        style={{
          display: settingVisible ? 'flex' : 'none',
        }}
        sx={{
          backgroundColor: 'controlBackground.main',
          '&:last-of-type': { mb: 0 },
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'start',
        }}
      >
        {eachSettingGroup.label && (
          <SectionHeader>
            <Typography
              variant={`subtitle1`}
              display={'inline-flex'}
              alignItems={'center'}
            >
              {eachSettingGroup.label}
            </Typography>
          </SectionHeader>
        )}
        <Box
          onClick={() => userCanEdit && !disabled && setEditingSetting(true)}
          sx={{ pl: 2 }}
        >
          {eachSettingGroup.fields.map(
            (eachField, index) =>
              //only pass dynField, editingSetting, and isSubmitting if the component is a DynSettingField
              (eachField.dependsOn == null ||
                formikProps.values[eachField.dependsOn]) &&
              React.createElement(eachField.component, {
                ...(eachField?.component?.name === 'DynSettingField'
                  ? {
                      dynField: eachField,
                    }
                  : {}),
                name: eachField.name,
                label: eachField.label,
                helperText: eachField.description,
                key: 'setting_group_field_' + index,
                disabled: !userCanEdit || isSubmitting || disabled,
                ...eachField.componentProps,
                formikProps,
                editingSetting: editingSetting,
                isSubmitting: isSubmitting,
              }),
          )}
        </Box>
      </Box>
      {settingVisible && (
        <CardActions
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.midnight.two}`,
            display: 'flex',
            justifyContent: 'end',
            p: 2,
            paddingBottom: 1,
          })}
        >
          <Tabutton
            loadingBtn
            onClick={() => {
              formikProps.resetForm();
              setEditingSetting(false);
            }}
            disabled={!editingSetting || loading}
            loading={loading}
          >
            Cancel
          </Tabutton>
          <Tabutton
            loadingBtn
            onClick={async () => {
              await formikProps.submitForm();
            }}
            disabled={!editingSetting || loading}
            loading={loading}
            variant={'contained'}
          >
            Apply Changes
          </Tabutton>
        </CardActions>
      )}
    </>
  );
};

export const SettingGroupCard = ({
  onUpdateSettings,
  userCanEdit,
  setting,
  pendingConnection,
}: {
  userCanEdit: boolean;
  onUpdateSettings: (data: any) => Promise<any> | undefined;
  setting: Setting;
  pendingConnection?: boolean;
}): JSX.Element => {
  const [editingSetting, setEditingSetting] = useState(false);
  const [loading, setLoading] = useState(false);

  const applyChanges = async (
    data: any,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: () => void,
  ) => {
    setLoading(true);
    const updatePromise = onUpdateSettings(data);
    if (updatePromise !== undefined) {
      updatePromise.then((mutationResult) => {
        if (mutationResult.errors) {
        } else {
          setEditingSetting(false);
        }
        setLoading(false);
        setSubmitting(false);
      });
    } else {
      setLoading(false);
      setSubmitting(false);
      setEditingSetting(false);
    }
  };
  const disabled = setting.disabled || false;
  return (
    <>
      <Formik
        initialValues={setting.initialValues}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await applyChanges(values, setSubmitting, resetForm);
        }}
        validationSchema={setting.validationSchema}
      >
        {(formikProps: FormikProps<any>) => (
          <Form>
            <Tacard>
              <CardHeader
                title={<Typography variant={`h6`}>{setting.name}</Typography>}
              />
              <CardContent
                sx={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}
              >
                {(setting.disabled && setting.disabledMessage) ||
                !userCanEdit ? (
                  <Talert
                    severity={'info'}
                    sx={{
                      maxWidth: 'fit-content',
                      marginBottom: 2,
                      marginX: 2,
                    }}
                    alertTitle={!userCanEdit ? 'Secured area' : undefined}
                    customIcon={
                      !userCanEdit ? (
                        <PrivacyTipOutlinedIcon fontSize={'small'} />
                      ) : undefined
                    }
                  >
                    <Typography
                      variant={'helperText'}
                      sx={{ color: 'midnight.seven' }}
                    >
                      {setting.disabledMessage}
                    </Typography>
                  </Talert>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        flexGrow: 1,
                        paddingX: 2,
                        paddingBottom: 2,
                      }}
                    >
                      <Typography variant={`body2`} component={'span'}>
                        {setting.description}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        flexGrow: 1,
                      }}
                    >
                      {setting.settingGroups.map((eachSettingGroup, index) =>
                        setting.variant === 'accordion' ? (
                          <SettingGroupAccordionVariant
                            key={'setting-group-accordion-' + index}
                            eachSettingGroup={eachSettingGroup}
                            index={index}
                            isSubmitting={formikProps.isSubmitting}
                            editingSetting={editingSetting}
                            setEditingSetting={setEditingSetting}
                            formikProps={formikProps}
                            pendingConnection={pendingConnection || false}
                            userCanEdit={userCanEdit}
                            disabled={disabled}
                            loading={loading}
                          />
                        ) : (
                          <SettingGroupBoxVariant
                            key={'setting-group-box-' + index}
                            eachSettingGroup={eachSettingGroup}
                            index={index}
                            isSubmitting={formikProps.isSubmitting}
                            editingSetting={editingSetting}
                            setEditingSetting={setEditingSetting}
                            formikProps={formikProps}
                            userCanEdit={userCanEdit}
                            disabled={disabled}
                            loading={loading}
                          />
                        ),
                      )}
                    </Box>
                  </>
                )}
              </CardContent>
            </Tacard>
          </Form>
        )}
      </Formik>
    </>
  );
};
