import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useTableEditContext } from './TableEditContext';

import { addTableFieldLabels, fetchFullTableQuery } from '../../graphql/table';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import { Tabutton } from '../Button/Tabutton';
import ApplyLabel from '../Forms/ApplyLabel';
import { showCreateLabelDialog } from '../Modals/CreateLabelDialog';
import QuickAddMenu from '../QuickAddMenu/QuickAddMenu';

export function LabelMenu({
  row,
  labelsNotAllowed,
  onLabelApplied,
}: {
  row: any;
  labelsNotAllowed: boolean;
  onLabelApplied: () => void;
}) {
  const { organization, warehouseId, database, tableName } =
    useTableEditContext();
  const { enqueueSnackbar } = useSnackbar();

  const [applyTableFieldLabel] = useMutation(addTableFieldLabels);
  const fieldId = row.id;
  const fieldLabels = row.fieldLabels;
  const orgId = organization.id;
  const orgName = organization.name;
  const orgDisplayName = organization.displayName;

  const onLabelCreated = useCallback((label: any) => {
    if (label) {
      applyTableFieldLabel({
        variables: {
          warehouseId: warehouseId,
          database: database,
          table: tableName,
          fieldLabels: { fieldId: fieldId, labelId: label.id },
        },
        refetchQueries: [
          {
            query: fetchFullTableQuery,
            variables: {
              orgId,
              orgName,
              orgDisplayName,
              warehouseId,
              database,
              tableName,
            },
          },
        ],
        awaitRefetchQueries: true,
      }).catch((error) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
          variant: 'error',
        });
      });
    }
  }, []);
  const onPartialFail = () => {};
  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'flex-start'}
        paddingRight={2}
        sx={{
          display: labelsNotAllowed ? 'none' : '',
        }}
      >
        <QuickAddMenu
          tooltipText={`Add label`}
          menuContent={
            <Stack direction={`column`} spacing={1}>
              <ApplyLabel
                organizationId={orgId}
                orgName={orgName}
                orgDisplayName={orgDisplayName}
                warehouseId={warehouseId}
                database={database}
                tableName={tableName || ''}
                fieldId={fieldId}
                fieldLabels={fieldLabels}
                onLabelApplied={onLabelApplied}
              />
              <Divider sx={{ borderColor: 'midnight.two' }} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Tabutton
                  size={'small'}
                  onClick={() =>
                    showCreateLabelDialog(orgId, onLabelCreated, onPartialFail)
                  }
                  endIcon={<AddIcon />}
                >
                  New Label
                </Tabutton>
              </Box>
            </Stack>
          }
        />
      </Box>
    </>
  );
}
