import { gql } from '@apollo/client';

import { FRAGMENTS } from './fragments';

//queries
// currentOrganizationId is a local field with a custom read function defined in the client InMemoryCache
export const currentUser = gql`
  query CurrentUserQuery {
    currentUser {
      id
      displayName
      email
      properties
      loginSession {
        id
        loggedInOrg {
          id
          name
          displayName
          authProviders
          properties
        }
        roles {
          id
          name
          displayName
          currentUserRoleAdmin
          systemRole
        }
        fastSwitchOrgs
        membership {
          id
          displayName
          properties
          defaultRoleId
          createdAt
          createdBy
          lastModified
          lastModifiedBy
        }
      }
      organizations {
        ...OrgFields
        systemRoles {
          id
          name
          displayName
        }
        warehouses {
          id
          name
          region
          storageProfile {
            id
            region
            storageType
          }
        }
      }
    }
  }
  ${FRAGMENTS.org}
`;

export const oktaConfigByEmail = gql`
  query OktaConfigQuery($email: String!) {
    oktaConfig(email: $email) {
      ...AuthConfigFields
    }
  }
  ${FRAGMENTS.authConfig}
`;

export const ProfilePageQuery = gql`
  query ProfilePageQuery {
    currentUser {
      id
      displayName
      email
      properties
      loginSession {
        id
        loggedInOrg {
          id
          displayName
          name
        }
        roles {
          id
          name
          displayName
          currentUserRoleAdmin
          systemRole
        }
        membership {
          id
          displayName
          properties
          defaultRoleId
          createdAt
          createdBy
          lastModified
          lastModifiedBy
        }
      }
    }
  }
`;

//mutations
export const updateMembershipMutation = gql`
  mutation UpdateMembership(
    $memberId: String!
    $membershipUpdates: MembershipUpdate!
  ) {
    updateMembership(
      memberId: $memberId
      membershipUpdates: $membershipUpdates
    ) {
      ...MembershipFields
    }
  }
  ${FRAGMENTS.membership}
`;

export const loginUser = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...UserFields
    }
  }
  ${FRAGMENTS.user}
`;

export const loginUserOrg = gql`
  mutation loginOrg($orgId: String!) {
    loginOrg(orgId: $orgId) {
      ...UserFields
    }
  }
  ${FRAGMENTS.user}
`;

export const loginUserWithToken = gql`
  mutation loginWithToken($idToken: String!, $orgId: String) {
    loginWithToken(idToken: $idToken, orgId: $orgId) {
      ...UserFields
    }
  }
  ${FRAGMENTS.user}
`;

export const RequestMagicLink = gql`
  mutation requestMagicLink($email: String!) {
    requestMagicLink(email: $email)
  }
`;

export const logoutUser = gql`
  mutation logout {
    logout
  }
`;

export const signupUserWithInvite = gql`
  mutation signupUserWithInvite($request: SignupWithInviteRequest!) {
    signupWithInvite(request: $request) {
      userId
      organizationId
      orgAuthProviders
      name
      displayName
      email
    }
  }
`;

export const signupUser = gql`
  mutation signupUser($idToken: String!) {
    signup(idToken: $idToken) {
      userId
      organizationId
      orgAuthProviders
      name
      displayName
      email
    }
  }
`;

export const SelfInvite = gql`
  mutation selfInvite($email: String!, $displayName: String) {
    selfInvite(email: $email, displayName: $displayName) {
      status
      email
    }
  }
`;

export const generateResetPasswordFlow = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const changeUserPassword = gql`
  mutation changePassword($resetCode: String!, $newPassword: String!) {
    changePassword(resetCode: $resetCode, newPassword: $newPassword)
  }
`;

export const updateUserProperties = gql`
  mutation updateUserPropertiesMutation(
    $userId: String!
    $userProperties: UserPropertiesUpdate!
  ) {
    updateUserProperties(userId: $userId, userProperties: $userProperties) {
      ...UserFields
    }
  }
  ${FRAGMENTS.user}
`;

export enum WellKnownUserProperties {
  PALETTE_MODE = 'paletteMode',
}
