import React from 'react';

import { Box, BoxProps, Grid, Typography, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

import { RoleSelector } from '../Forms/RoleSelector';
import TextField from '../Forms/TextField';
import { NavTab, TabWrapper } from '../NavTabs/SubNavTabs';
import { EditTableMode, TableEditTab } from './TableEdit';
import { checkOverflow } from './TableEditHelpers';

interface TabPanelProps extends BoxProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, sx, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={[{ pt: 3 }, sx as SystemStyleObject]} {...other}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export interface TableEditBodyProps extends BoxProps {
  mode: EditTableMode;
  scrollRef: React.MutableRefObject<HTMLElement | null>;
  tabRef: React.MutableRefObject<HTMLButtonElement | null>;
  tabIndex: number;
  editTabs: TableEditTab[];
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const TableEditBody = ({
  mode,
  scrollRef,
  tabRef,
  tabIndex,
  editTabs,
  handleTabChange,

  ...props
}: TableEditBodyProps) => {
  const theme = useTheme();

  const breakPointStyles = {
    overflowX: 'auto',
    overflowY: 'hidden',
    backgroundColor: 'controlBackground.half',
    px: 1,
    borderBottom: `1px solid ${theme.palette.midnight.two}`,
    width: '100%',
    height: '48px',
    ...(checkOverflow(tabRef.current, scrollRef.current) && {
      '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        background: `linear-gradient(270deg, ${theme.palette.midnight.two} 0%, rgba(255, 255, 255, 0) 100%)`,
        height: 48,
        width: 32,
      },
    }),
  };
  return (
    <Box {...props}>
      {mode !== EditTableMode.VIEW && mode !== EditTableMode.REGISTER && (
        <Box
          ref={scrollRef}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
              ...breakPointStyles,
            },
          }}
        >
          <TabWrapper
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Table Edit"
            TabIndicatorProps={{ style: { display: 'none' } }}
            variant={'standard'}
            sx={{
              [theme.breakpoints.down('sm')]: {
                border: 'none',
                marginRight: '0px',
                paddingRight: '0px',
              },
            }}
          >
            {editTabs.map((eachStep, index) => (
              <NavTab
                key={eachStep.key + 'tab_header'}
                label={eachStep.label}
              />
            ))}
          </TabWrapper>
        </Box>
      )}

      {editTabs.map((eachStep, idx) => (
        <TabPanel
          key={eachStep.key + '_tab_body'}
          value={tabIndex}
          index={idx}
          sx={{
            ...(mode === EditTableMode.VIEW ? { m: 0, p: 0 } : {}),
          }}
        >
          {eachStep.body}
        </TabPanel>
      ))}
    </Box>
  );
};
