import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Popper,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { compact, concat } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';

import { toRoles, toRoleSettings } from '../../RouteTable';
import { Role } from '../../graphql/gen/graphql';
import { deleteRole as DeleteRole, updateRole } from '../../graphql/role';
import { relativeTimeAutoFormat } from '../../utils/time';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import { Tavatar } from '../Avatar/Tavatar';
import { Tabutton } from '../Button/Tabutton';
import { Tacard } from '../Card/Tacard';
import DatagridCards from '../DatagridCards/DatagridCards';
import TextField from '../Forms/TextField';
import UserInfo from '../ProfileMenu/UserInfo';
import DangerZone from '../TableSettings/DangerZone';

export default function RoleSettings({
  role,
  orgName,
  orgId,
  orgDisplayName,
  currentUserEmail,
  canModifyRole,
}: {
  role: Role;
  orgName: string;
  orgId: string;
  orgDisplayName: string;
  currentUserEmail: string;
  canModifyRole: boolean;
}) {
  const [formChange, setFormChange] = useState(false);
  const navigate = useNavigate();
  const [updateRoleMutation, { loading }] = useMutation(updateRole);
  const [deleteRole] = useMutation(DeleteRole);

  const cardContent = [
    {
      item: (
        <Box
          display={`flex`}
          flexDirection={`row`}
          p={2}
          height={'100%'}
          width={'100%'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} mr={1}>
            <Typography variant="subtitle1" pb={1} color={'midnight.nine'}>
              Updated{' '}
              {role?.lastModified
                ? relativeTimeAutoFormat(role.lastModified)
                : '--'}
            </Typography>
            {role?.lastModified && (
              <Typography variant="inputText" color={'midnight.seven'}>
                {new Date(role.lastModified).toUTCString()}
              </Typography>
            )}
          </Box>
          <Box display={'flex'}>
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box {...bindHover(popupState)}>
                    <Tavatar
                      profilePic={
                        role?.lastModifiedByMember?.properties?.[`picture.url`]
                      }
                      tabularService={
                        role?.lastModifiedByMember?.properties === null
                      }
                      displayName={
                        role?.lastModifiedByMember?.displayName || ''
                      }
                    />
                  </Box>
                  <Popper {...bindPopper(popupState)} placement={`top`}>
                    <Box
                      sx={(theme) => ({
                        backgroundColor: 'controlBackground.main',
                        borderRadius: '4px',
                        boxShadow: theme.shadows[3],
                      })}
                    >
                      <UserInfo
                        user={{
                          displayName:
                            role?.lastModifiedByMember?.displayName || '',
                          profilePic:
                            role?.lastModifiedByMember?.properties?.[
                              `picture.url`
                            ],
                          email: role?.lastModifiedBy,
                        }}
                        currentUser={currentUserEmail === role?.lastModifiedBy}
                      />
                    </Box>
                  </Popper>
                </>
              )}
            </PopupState>
          </Box>
        </Box>
      ),
    },
    {
      item: (
        <Box
          display={`flex`}
          flexDirection={`row`}
          p={2}
          height={'100%'}
          width={'100%'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} flexDirection={'column'} mr={1}>
            <Typography variant="subtitle1" pb={1} color={'midnight.nine'}>
              Created{' '}
              {role?.createdAt ? relativeTimeAutoFormat(role.createdAt) : '--'}
            </Typography>
            {role?.createdAt && (
              <Typography variant="inputText" color={'midnight.seven'}>
                {new Date(role.createdAt).toUTCString()}
              </Typography>
            )}
          </Box>
          <Box display={'flex'}>
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box {...bindHover(popupState)}>
                    <Tavatar
                      profilePic={
                        role?.createdByMember?.properties?.[`picture.url`]
                      }
                      tabularService={
                        role?.createdByMember?.properties === null
                      }
                      displayName={role?.createdByMember?.displayName || ''}
                    />
                  </Box>
                  <Popper {...bindPopper(popupState)} placement={`top`}>
                    <Box
                      sx={(theme) => ({
                        backgroundColor: 'controlBackground.main',
                        borderRadius: '4px',
                        boxShadow: theme.shadows[3],
                      })}
                    >
                      <UserInfo
                        user={{
                          displayName: role?.createdByMember?.displayName || '',
                          profilePic:
                            role?.createdByMember?.properties?.[`picture.url`],
                          email: role?.createdBy,
                        }}
                        currentUser={currentUserEmail === role?.createdBy}
                      />
                    </Box>
                  </Popper>
                </>
              )}
            </PopupState>
          </Box>
        </Box>
      ),
    },
  ];

  const handleNameChange = async (values: any) => {
    const roleMembers = compact(
      //@ts-ignore
      concat(role?.users || [], role?.children || []),
    )?.map((member: any) => ({
      subject: {
        type: member.__typename === 'Role' ? 'ROLE' : 'USER',
        identifier: member.id,
      },
      admin: member.__typename === 'Role' ? false : member.isAdmin,
    }));

    await updateRoleMutation({
      variables: {
        role: {
          id: role.id,
          roleName: values.name,
          members: roleMembers,
        },
      },
    })
      .then((result) => {
        //update URL with new name
        navigate(toRoleSettings(orgName, values.name));
        enqueueSnackbar(`Role name successfully updated`, {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
          variant: 'error',
        });
      });
  };

  const dangerZoneItems = [
    {
      title: 'Delete Role',
      description:
        'This action will delete the role and all authorizations associated with it.',
      buttonText: 'Delete this role',
      confirmModal: 'TypeDeleteDialog',
      modalContent: {
        title: 'Delete Role',
        confirmText: 'Delete Role',
        recoveryWarningText:
          'This action will delete the role and all authorizations associated with it.',
      },
      mutationName: 'deleteRole',
      disabled: !canModifyRole,
    },
  ];

  const handleDangerZone = async () => {
    await deleteRole({ variables: { roleId: role?.id, force: true } })
      .then(() => {
        navigate(toRoles(orgName));
        enqueueSnackbar(`Role successfully deleted.`, {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
          variant: 'error',
        });
      });
  };

  return (
    <>
      <Typography variant={`h6`}>{'Role settings'}</Typography>
      <DatagridCards cards={cardContent} />
      <Tacard>
        <CardHeader title={`Role name`} />
        <Formik
          initialValues={{ name: role?.name || '' }}
          validationSchema={Yup.object({
            name: Yup.string().required('Role name is required'),
          })}
          enableReinitialize
          onSubmit={async (values) => {
            handleNameChange(values);
            setFormChange(false);
          }}
        >
          {({ resetForm }) => (
            <Form>
              <CardContent
                sx={(theme) => ({
                  borderTop: `1px solid ${theme.palette.midnight.two}`,
                })}
              >
                <TextField
                  name={'name'}
                  id={'name-input'}
                  //@ts-ignore
                  readOnly={!canModifyRole}
                  label={'Role name'}
                  disabled={role?.isBuiltIn || !canModifyRole}
                  onClick={() => setFormChange(true)}
                  sx={{
                    width: '100%',
                    '& .MuiInputLabel-root': {
                      marginTop: '3px',
                      '&.Mui-focused': {
                        marginTop: '0px',
                        marginRight: '10px',
                      },
                      '&.MuiFormLabel-filled': {
                        marginTop: '0px',
                      },
                    },
                  }}
                />
              </CardContent>
              <CardActions
                sx={(theme) => ({
                  borderTop: `1px solid ${theme.palette.midnight.two}`,
                  display: 'flex',
                  justifyContent: 'end',
                  p: 2,
                })}
              >
                <Tabutton
                  onClick={() => {
                    //reset value
                    resetForm();
                    setFormChange(false);
                  }}
                  disabled={!formChange || loading}
                >
                  Cancel
                </Tabutton>
                <Tabutton
                  loadingBtn
                  loading={loading}
                  type={'submit'}
                  variant={`contained`}
                  disabled={!formChange || loading}
                >
                  Save Role Name
                </Tabutton>
              </CardActions>
            </Form>
          )}
        </Formik>
      </Tacard>
      <DangerZone
        dangerZoneItems={dangerZoneItems}
        onConfirm={handleDangerZone}
      />
    </>
  );
}
