import React, { useState } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  Box,
  CardContent,
  CardHeader,
  TextField,
  Tooltip,
} from '@mui/material';
import { isEmpty } from 'lodash';

import { StorageProfile } from '../../graphql/gen/graphql';
import { Tacard } from '../Card/Tacard';

export default function ADLSDetailsTemplate({
  profile,
}: {
  profile: StorageProfile;
}) {
  const [copySuccess, setCopySuccess] = useState('');
  const storageAcctName = profile?.accountId?.substring(
    0,
    profile?.accountId?.indexOf('.'),
  );
  const appClientId = profile?.roleArn?.substring(
    profile?.roleArn?.indexOf('/') + 1,
    profile?.roleArn?.lastIndexOf('/'),
  );
  const directoryTenantId = profile?.roleArn?.substring(
    profile?.roleArn?.lastIndexOf('/') + 1,
  );

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  return (
    <Tacard sx={{ mx: 2 }}>
      <CardHeader
        title={`${profile?.storageType} storage account`}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
      />
      <CardContent>
        <Box display={`flex`} marginTop={2}>
          <Box display={`flex`} flexBasis={`50%`}>
            <TextField
              variant={`outlined`}
              label={`Region`}
              defaultValue={profile?.region}
              inputProps={{
                size: profile?.region?.length + 4,
                readOnly: true,
              }}
              sx={{ mr: 2 }}
            />
            <Box
              display={`flex`}
              alignItems={`center`}
              marginRight={2}
              width={`100%`}
            >
              <TextField
                fullWidth
                variant={`outlined`}
                label={`Storage acct name`}
                defaultValue={storageAcctName}
                inputProps={{
                  readOnly: true,
                }}
                sx={{ mr: 1 }}
              />
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <ContentCopyOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    color: 'brandBlue.main',
                  }}
                  onClick={() => {
                    copyToClipBoard(storageAcctName).then(() =>
                      setTimeout(() => {
                        setCopySuccess('');
                      }, 2000),
                    );
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          <Box display={`flex`} alignItems={`center`} flexBasis={`50%`}>
            <TextField
              variant={`outlined`}
              label={`Container name`}
              defaultValue={profile?.bucket}
              inputProps={{
                readOnly: true,
              }}
              fullWidth
              sx={{ mr: 1 }}
            />
            <Tooltip
              title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
              placement={`top`}
              arrow
            >
              <ContentCopyOutlinedIcon
                sx={{
                  cursor: 'pointer',
                  color: 'brandBlue.main',
                }}
                onClick={() => {
                  copyToClipBoard(profile?.bucket).then(() =>
                    setTimeout(() => {
                      setCopySuccess('');
                    }, 2000),
                  );
                }}
              />
            </Tooltip>
          </Box>
        </Box>
        <Box display={`flex`} marginTop={4}>
          <Box display={`flex`} flexBasis={`50%`}>
            <Box
              display={`flex`}
              alignItems={`center`}
              marginRight={2}
              width={`100%`}
            >
              <TextField
                variant={`outlined`}
                label={`App (client) ID`}
                defaultValue={appClientId}
                inputProps={{
                  readOnly: true,
                }}
                fullWidth
                sx={{ mr: 1 }}
              />
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <ContentCopyOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    color: 'brandBlue.main',
                  }}
                  onClick={() => {
                    copyToClipBoard(appClientId).then(() =>
                      setTimeout(() => {
                        setCopySuccess('');
                      }, 2000),
                    );
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          <Box display={`flex`} alignItems={`center`} flexBasis={`50%`}>
            <TextField
              variant={`outlined`}
              label={`Directory (tenant) ID`}
              defaultValue={directoryTenantId}
              fullWidth
              inputProps={{
                readOnly: true,
              }}
              sx={{ mr: 1 }}
            />
            <Tooltip
              title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
              placement={`top`}
              arrow
            >
              <ContentCopyOutlinedIcon
                sx={{
                  cursor: 'pointer',
                  color: 'brandBlue.main',
                }}
                onClick={() => {
                  copyToClipBoard(directoryTenantId).then(() =>
                    setTimeout(() => {
                      setCopySuccess('');
                    }, 2000),
                  );
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </CardContent>
    </Tacard>
  );
}
