import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { debounce, isEmpty } from 'lodash';

import {
  SEARCH_ENTITY_TYPE_DATABASE,
  SEARCH_ENTITY_TYPE_TABLE,
  SEARCH_ENTITY_TYPE_WAREHOUSE,
} from '../../RouteTable';
import { useAuth } from '../../context/auth-context';
import {
  SearchEntityType,
  SearchResult,
  SortByDirection,
  TableSearchResult,
  User,
} from '../../graphql/gen/graphql';
import { navSearchQuery } from '../../graphql/organization';
import { Talert } from '../Alert/Talert';
import SearchBox from './SearchBox';

export type SearchAndNavigateItem = {
  id: string;
  type: SearchEntityType;
  whName: string;
  whId: string;
  dbName: string;
  dbId: string;
  tblName: string;
};

export type FriendlySearchResults = {
  id: string;
  type: SearchEntityType;
  warehouseName: string;
  warehouseId: string;
  databaseName: string;
  databaseId: string;
  tableName: string;
};

function getDisplayForResult(result: SearchAndNavigateItem): JSX.Element {
  let contents = null;
  const dot = '.';
  switch (result.type) {
    case SearchEntityType.Warehouse:
      contents = (
        <Grid item sx={{ color: 'midnight.nine', pl: '4px !important' }}>
          {result.whName}
        </Grid>
      );
      break;
    case SearchEntityType.Database:
      contents = (
        <>
          <Grid item sx={{ color: 'midnight.four', pl: '4px !important' }}>
            {result.whName}
          </Grid>
          <Grid item sx={{ pl: '4px !important' }}>
            {dot}
          </Grid>
          <Grid item sx={{ color: 'midnight.nine', pl: '4px !important' }}>
            {result.dbName}
          </Grid>
        </>
      );
      break;

    case SearchEntityType.Table:
      contents = (
        <>
          <Grid item sx={{ color: 'midnight.four', pl: '4px !important' }}>
            {result.whName}
          </Grid>
          <Grid item sx={{ pl: '4px !important' }}>
            {dot}
          </Grid>
          <Grid item sx={{ color: 'midnight.four', pl: '4px !important' }}>
            {result.dbName}
          </Grid>
          <Grid item sx={{ pl: '4px !important' }}>
            {dot}
          </Grid>
          <Grid item sx={{ color: 'midnight.nine', pl: '4px !important' }}>
            {result.tblName}
          </Grid>
        </>
      );
      break;
  }
  return (
    <Grid container spacing={1} justifyContent={'flex-start'}>
      {contents}
    </Grid>
  );
}
export default function SearchAndNavigateResources({
  onResourceSelected,
  resourceType,
  selectedId,
}: {
  onResourceSelected: (result: SearchAndNavigateItem) => void;
  resourceType: SearchEntityType;
  selectedId?: string;
}) {
  const { user }: { user: User } = useAuth();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [lastSearchQuery, setLastSearchQuery] = useState<string>('');
  const [results, setResults] = useState<any[]>([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState<
    SearchAndNavigateItem[]
  >([]);
  const [selectedDatabases, setSelectedDatabases] = useState<
    SearchAndNavigateItem[]
  >([]);
  const [nextKeys, setNextKeys] = useState<string[]>([]);
  const [whChips, setWhChips] = useState<JSX.Element[]>([]);
  const [dbChips, setDbChips] = useState<JSX.Element[]>([]);
  const orgId = useMemo(() => user.loginSession?.loggedInOrg?.id, [user]);

  const mapThemResults = (resources: SearchResult[]) => {
    return (
      resources?.map((result) => {
        return {
          id: result.eid,
          type:
            result.et === SEARCH_ENTITY_TYPE_WAREHOUSE
              ? SearchEntityType.Warehouse
              : result.et === SEARCH_ENTITY_TYPE_DATABASE
              ? SearchEntityType.Database
              : result.et === SEARCH_ENTITY_TYPE_TABLE
              ? SearchEntityType.Table
              : SearchEntityType.View,
          warehouseName:
            result.et === SEARCH_ENTITY_TYPE_WAREHOUSE ? result.en : result.wn,
          warehouseId:
            result.et === SEARCH_ENTITY_TYPE_WAREHOUSE
              ? result.eid
              : result.wid,
          databaseName:
            result.et === SEARCH_ENTITY_TYPE_DATABASE
              ? result.en
              : (result as TableSearchResult).nn,
          databaseId:
            result.et === SEARCH_ENTITY_TYPE_DATABASE
              ? result.eid
              : (result as TableSearchResult).nid,
          tableName:
            result.et === SEARCH_ENTITY_TYPE_TABLE
              ? result.en
              : (result as TableSearchResult).tn,
        };
      }) || []
    );
  };

  const getEntityFilters = (resourceType: SearchEntityType) => {
    const types = [];
    if (isEmpty(searchQuery)) {
      if (isEmpty(selectedWarehouses) && isEmpty(selectedDatabases)) {
        types.push(SearchEntityType.Warehouse);
      } else if (!isEmpty(selectedWarehouses) && isEmpty(selectedDatabases)) {
        types.push(SearchEntityType.Database);
      } else if (!isEmpty(selectedWarehouses) && !isEmpty(selectedDatabases)) {
        types.push(SearchEntityType.Table);
      } else {
        types.push(SearchEntityType.Warehouse, SearchEntityType.Table);
      }
    } else if (resourceType === SearchEntityType.Table) {
      if (!isEmpty(selectedWarehouses) && !isEmpty(selectedDatabases)) {
        types.push(SearchEntityType.Table);
      } else if (!isEmpty(selectedWarehouses)) {
        types.push(SearchEntityType.Database, SearchEntityType.Table);
      } else if (!isEmpty(selectedDatabases)) {
        types.push(SearchEntityType.Warehouse, SearchEntityType.Table);
      } else {
        types.push(
          SearchEntityType.Warehouse,
          SearchEntityType.Database,
          SearchEntityType.Table,
        );
      }
    } else {
      if (!isEmpty(selectedWarehouses)) {
        types.push(SearchEntityType.Database);
      } else {
        types.push(SearchEntityType.Warehouse, SearchEntityType.Database);
      }
    }

    return types;
  };

  const {
    data: searchData,
    loading,
    error,
    refetch,
  } = useQuery(navSearchQuery, {
    variables: {
      organizationId: orgId,
      query: searchQuery !== '' ? `*${searchQuery}*` : '*',
      entityTypesFilter: getEntityFilters(resourceType),
      warehouseIdFilter: selectedWarehouses?.map((wh) => wh.whId),
      databaseIdFilter: selectedDatabases?.map((db) => db.dbId),
      sortBy: isEmpty(searchQuery)
        ? null
        : [
            {
              fieldName: '_score',
              sortDirection: SortByDirection.Asc,
            },
          ],
      searchAfter: undefined,
    },
  });

  const [lazySearch] = useLazyQuery(navSearchQuery);

  const handleScroll = useCallback(
    (event: React.SyntheticEvent) => {
      const listboxNode = event.currentTarget;
      const position = listboxNode.scrollTop + listboxNode.clientHeight;

      if (
        listboxNode.scrollHeight - position <= 1 &&
        results?.length < searchData?.search?.totalHits
      ) {
        lazySearch({
          variables: {
            organizationId: orgId,
            query: searchQuery !== '' ? `*${searchQuery}*` : '*',
            entityTypesFilter: getEntityFilters(resourceType),
            warehouseIdFilter: selectedWarehouses?.map((wh) => wh.whId),
            databaseIdFilter: selectedDatabases?.map((db) => db.dbId),
            sortBy: isEmpty(searchQuery)
              ? null
              : [
                  {
                    fieldName: '_score',
                    sortDirection: SortByDirection.Asc,
                  },
                ],
            searchAfter: nextKeys.length === 0 ? undefined : nextKeys,
          },
          onCompleted: (data) => {
            setNextKeys(data?.search?.searchAfterKeys || []);
            setResults((prevResults) => [
              ...prevResults,
              ...mapThemResults(data?.search?.results),
            ]);
          },
        });
      }
    },
    [nextKeys, searchData, results],
  );

  const handleRemoveWhChip = (whName: string) => {
    setSelectedWarehouses(
      selectedWarehouses.filter((wh) => wh.whName !== whName),
    );
  };

  const handleRemoveDbChip = (dbName: string) => {
    setSelectedDatabases(
      selectedDatabases.filter((db) => db.dbName !== dbName),
    );
  };

  const areWeGoodToGo = async (selected: SearchAndNavigateItem) => {
    if (selected.type === resourceType) {
      return onResourceSelected(selected);
    }
    if (selected.type === SearchEntityType.Warehouse) {
      await setSelectedWarehouses([...selectedWarehouses, selected]);
      await refetch();
    }
    if (selected.type === SearchEntityType.Database) {
      await setSelectedDatabases([...selectedDatabases, selected]);
      await refetch();
    }
  };

  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      // @ts-ignore
      const currentSearchValue = e.target.value;
      if (currentSearchValue !== searchQuery) {
        setSearchQuery(currentSearchValue);
      }
    },
    [],
  );
  const debouncedSearchChange = debounce(onSearchChange, 500);
  const handleRowClick = (selected: SearchAndNavigateItem) => {
    areWeGoodToGo(selected);
  };

  const rows = useMemo(() => {
    return (
      results?.map((result: FriendlySearchResults) => {
        return {
          id: result.id,
          type: result.type,
          whName: result.warehouseName,
          whId: result.warehouseId,
          dbName: result.databaseName,
          dbId: result.databaseId,
          tblName: result.tableName,
        };
      }) || []
    );
  }, [results]);

  useEffect(() => {
    const chipArray = selectedWarehouses?.map((wh) => (
      <Chip
        key={wh.whId}
        onDelete={() => handleRemoveWhChip(wh.whName)}
        label={`Warehouse=${wh.whName}`}
      />
    ));
    setWhChips(chipArray);
  }, [selectedWarehouses]);

  useEffect(() => {
    const chipArray = selectedDatabases?.map((db) => (
      <Chip
        key={db.dbName}
        onDelete={() => handleRemoveDbChip(db.dbName)}
        label={`Database=${db.dbName}`}
      />
    ));
    setDbChips(chipArray);
  }, [selectedDatabases]);

  useEffect(() => {
    if (searchQuery !== '' && searchQuery !== lastSearchQuery) {
      setLastSearchQuery(searchQuery);
      refetch();
    }
  }, [searchQuery, lastSearchQuery]);

  useEffect(() => {
    if (searchData?.search) {
      setNextKeys(searchData?.search?.searchAfterKeys || []);
      setResults(mapThemResults(searchData.search.results));
    }
  }, [searchData]);

  return (
    <>
      {error ? (
        <Talert sx={{ mb: 2 }} severity="error">
          Error: {error.message}
        </Talert>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 100px)',
          backgroundColor: searchData ? 'controlBackground.main' : '',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            px: 1,
            py: 2,
            backgroundColor: 'midnight.one',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
          }}
        >
          <SearchBox
            name="query"
            onKeyUp={debouncedSearchChange}
            onKeyDown={() => {}}
            placeholder={`Search`}
            onClearSearchField={async () => {
              await setSearchQuery('');
              await setSelectedWarehouses([]);
              await setSelectedDatabases([]);
              await refetch();
            }}
            whChips={whChips}
            dbChips={dbChips}
          />
        </Box>

        <List
          sx={(theme) => ({
            position: 'absolute',
            top: 68,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'auto',
            borderLeft: `1px solid ${theme.palette.midnight.one}`,
            borderRight: `1px solid ${theme.palette.midnight.one}`,
          })}
          onScroll={handleScroll}
        >
          {!loading && rows?.length === 0 && (
            <ListItem key={'search_list_item_no_results'} disablePadding>
              <ListItemButton>
                <ListItemText primary={'No results'} />
              </ListItemButton>
            </ListItem>
          )}

          {!loading &&
            rows?.length > 0 &&
            rows?.map((result: SearchAndNavigateItem, i: number) => (
              <>
                <ListItem key={'search_list_item_' + result.id} disablePadding>
                  <ListItemButton
                    selected={selectedId === result.id}
                    onClick={() => {
                      handleRowClick(result);
                    }}
                    sx={(theme) => ({
                      '&.Mui-selected': {
                        backgroundColor: 'sky.one',
                        border: `4px solid ${theme.palette.sky.seven}`,
                      },
                      '&:hover': {
                        backgroundColor: `${theme.palette.sky.one} !important`,
                      },
                    })}
                  >
                    <ListItemIcon>
                      <Box
                        sx={(theme) => ({
                          minWidth: '100px',
                          minHeight: '24px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: `1px solid ${theme.palette.midnight.two}`,
                          borderRadius: '4px',
                          mr: 6,
                          backgroundColor: 'white',
                        })}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ color: 'midnight.seven', fontWeight: 600 }}
                        >
                          {result.type}
                        </Typography>
                      </Box>
                    </ListItemIcon>
                    <ListItemText sx={{ wordBreak: 'break-word' }}>
                      {getDisplayForResult(result)}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            ))}
        </List>
      </Box>
    </>
  );
}
