import React, { useMemo, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Grid,
  ListItem,
  List,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { isEmpty } from 'lodash';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { toDocsRoot } from '../../../RouteTable';
import { StorageType, Warehouse } from '../../../graphql/gen/graphql';
import { Tabutton } from '../../Button/Tabutton';
import { Tabulink } from '../../Link/Tabulink';
import { transformWarehouseNameForCFT } from './helpers';

export const SnowflakeCmdTemplate = ({
  warehouse,
  snowflakeUser,
  snowflakeRole,
  externalVolumeId,
  catalogIntegrationId,
  publicKey,
}: {
  warehouse: Warehouse;
  snowflakeUser: string;
  snowflakeRole: string;
  externalVolumeId: string;
  catalogIntegrationId: string;
  publicKey: string;
}) => {
  const [copySuccess, setCopySuccess] = useState('');
  const gcsWarehouse = useMemo(
    () => warehouse?.storageProfile?.storageType === StorageType.Gcs,
    [warehouse],
  );
  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  let awsAccountNumber: string = '<PLACE_YOUR_AWS_12_DIGIT_ACCOUNT_NUM_HERE>';

  if (warehouse != undefined && warehouse.roleArn != undefined) {
    const idxAccountNumber: number = warehouse?.roleArn!.indexOf('iam::') + 5;
    awsAccountNumber = warehouse.roleArn!.substring(
      idxAccountNumber,
      idxAccountNumber + 12,
    );
  }
  const getStorageInfo = () => {
    if (gcsWarehouse) {
      return {
        storageProvider: 'GCS',
        baseUrl: `gcs${warehouse?.location?.slice(2)}`,
      };
    }
    return {
      storageProvider: 'S3',
      baseUrl: warehouse?.location,
    };
  };

  const cmdTemplate = `

-- ⬇️ ENTER YOUR SNOWFLAKE WAREHOUSE ⬇️
                  set snowflake_warehouse_name = '🧊YOUR_WAREHOUSE_HERE_PLEASE🧊'; 
-- ⬆️ ENTER YOUR SNOWFLAKE WAREHOUSE ⬆️


-- -----------------------------------------------
-- create security admin assets
-- -----------------------------------------------
use role securityadmin;
create role ${snowflakeRole};
grant role ${snowflakeRole} to role sysadmin; 
-- best practice, always do this: https://docs.snowflake.com/en/user-guide/security-access-control-considerations#managing-custom-roles

create user ${snowflakeUser} 
  rsa_public_key    = '${publicKey}',
  default_role      = '${snowflakeRole}',
  default_warehouse = $snowflake_warehouse_name;
-- -----------------------------------------------


-- -----------------------------------------------
-- create account admin assets 
-- -----------------------------------------------
use role accountadmin;
create external volume ${externalVolumeId} 
  allow_writes=false
  storage_locations = ((
      name = '${externalVolumeId}'
      storage_provider = '${getStorageInfo().storageProvider}'
      storage_base_url = '${getStorageInfo().baseUrl}'
       ${
         gcsWarehouse
           ? ''
           : `storage_aws_role_arn = 'arn:aws:iam::${awsAccountNumber}:role/snowflake-tabular-${transformWarehouseNameForCFT(
               warehouse.name,
             )}'`
       }
  ));

create catalog integration ${catalogIntegrationId}
  catalog_source = object_store
  table_format = iceberg
  enabled = true
  comment = 'Tabular.io Catalog Integration';
-- -----------------------------------------------


-- -----------------------------------------------
-- update permissions on these new objects
-- -----------------------------------------------
use role accountadmin;
grant create database on account to role ${snowflakeRole};
grant usage on integration ${catalogIntegrationId} to role ${snowflakeRole};
grant usage on volume ${externalVolumeId} to role ${snowflakeRole};

use role securityadmin;
grant usage on warehouse IDENTIFIER($snowflake_warehouse_name) to role ${snowflakeRole};
grant role ${snowflakeRole} to user ${snowflakeUser};
-- -----------------------------------------------


-- -----------------------------------------------
-- Get details from newly-created integration 
-- objects. 
-- -----------------------------------------------
use role ${snowflakeRole};
use warehouse IDENTIFIER($snowflake_warehouse_name);
describe external volume ${externalVolumeId};

${
  gcsWarehouse
    ? `select 
  parse_json("property_value"::string):"STORAGE_GCP_SERVICE_ACCOUNT"::string as storage_gcp_service_account,
  'https://' || lower(current_account()) || '.snowflakecomputing.com' as snowflake_account_url`
    : `
  select 
  parse_json("property_value"::string):"STORAGE_AWS_IAM_USER_ARN"::string as storage_aws_iam_user_arn,
  parse_json("property_value"::string):"STORAGE_AWS_EXTERNAL_ID"::string as storage_aws_external_id,
  'https://' || lower(current_account()) || '.snowflakecomputing.com' as snowflake_account_url`
}
  
from table(result_scan(last_query_id()))

where "parent_property"='STORAGE_LOCATIONS' and "property"='STORAGE_LOCATION_1';
`;

  const networkPolicyCmds = `-- Check if your account uses network policies to limit access
show network policies;

-- Create a network policy for Tabular access
-- (see link above for regional ip lists)
create network policy TABULAR_NETWORK_POLICY allowed_ip_list = ('<ip1>', '<ip2>');

-- Set the Tabular user's network policy - ensure this is placed below the 'use role securityadmin' statement, as the user won't exist until later
alter user <username> set network_policy = 'TABULAR_NETWORK_POLICY';`;

  return (
    <Stack direction="column" sx={{ width: 1, pb: 5 }}>
      <List sx={{ listStyle: 'decimal', pl: 5 }}>
        <ListItem sx={{ display: 'list-item', p: 1 }}>
          <Typography variant={'body1'}>
            In Snowflake, open a new <b>SQL worksheet</b>.
          </Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item', p: 1 }}>
          <Typography variant={'body1'}>
            Provide your <b>Snowflake warehouse name</b> on <b>line 4</b> before
            running commands.
          </Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item', p: 1 }}>
          <Typography variant={'body1'}>
            This step only applies to those with <b>Network Policies</b> in
            place. This policy needs to be applied to new users.
          </Typography>
          <Tabulink
            href={toDocsRoot() + '/tabular-egress-ip-addresses.html'}
            external
            variant="body2"
            rel="noopener"
            aria-label="Tabular IP address list"
            sx={{
              whiteSpace: 'nowrap',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              py: 1,
            }}
          >
            Tabular regional IP list
            <OpenInNewIcon fontSize="small" />
          </Tabulink>
          <Tabulink
            href={'https://docs.snowflake.com/en/user-guide/network-policies'}
            external
            variant="body2"
            rel="noopener"
            aria-label="Snowflake network policies"
            sx={{
              whiteSpace: 'nowrap',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Snowflake network policy documentation
            <OpenInNewIcon fontSize="small" />
          </Tabulink>
          <Grid
            container
            sx={{
              width: 0.95,
              mt: 1,
              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingRight: 2,
              }}
            >
              <Typography
                variant={'body1'}
                color={'midnight.nine'}
                alignSelf={'center'}
              >
                SQL commands for Network policy configuration
              </Typography>
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <IconButton
                  onClick={() => copyToClipBoard(cmdTemplate)}
                  title="Copy to clipboard"
                  size={'small'}
                  sx={{
                    color: 'brandBlue.main',
                    height: 12,
                    width: 12,
                    alignSelf: 'center',
                  }}
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              <SyntaxHighlighter
                language="sql"
                style={{
                  ...dracula,
                  padding: 0 as React.CSSProperties,
                }}
                customStyle={{ margin: 0 }}
                showLineNumbers={false}
              >
                {networkPolicyCmds}
              </SyntaxHighlighter>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 1 / 2,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <Tabutton
                  onClick={() => copyToClipBoard(networkPolicyCmds)}
                  title="Copy to clipboard"
                  size={'small'}
                  sx={{ alignSelf: 'right' }}
                  endIcon={<FileCopyIcon />}
                >
                  Copy SQL commands for Network configuration.
                </Tabutton>
              </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem sx={{ display: 'list-item', p: 1 }}>
          <Typography variant={'body1'}>
            Run commands and continue to setup permissions.
          </Typography>
        </ListItem>
        <Grid
          container
          sx={{
            width: 0.95,
            mt: 1,
            border: 1,
            borderColor: 'midnight.two',
            borderRadius: '8px',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              padding: 1,
              borderRadius: '8px',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottom: 1,
              borderColor: 'midnight.two',
              backgroundColor: 'dusk.half',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: 2,
            }}
          >
            <Typography
              variant={'body1'}
              color={'midnight.nine'}
              alignSelf={'center'}
            >
              SQL commands for Snowflake configuration
            </Typography>
            <Tooltip
              title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
              placement={`top`}
              arrow
            >
              <IconButton
                onClick={() => copyToClipBoard(cmdTemplate)}
                title="Copy to clipboard"
                size={'small'}
                sx={{
                  color: 'brandBlue.main',
                  height: 12,
                  width: 12,
                  alignSelf: 'center',
                }}
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              borderBottom: 1,
              borderColor: 'midnight.two',
            }}
          >
            <SyntaxHighlighter
              language="sql"
              style={{
                ...dracula,
                padding: 0 as React.CSSProperties,
              }}
              customStyle={{ margin: 0 }}
              showLineNumbers={true}
            >
              {cmdTemplate}
            </SyntaxHighlighter>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              padding: 1 / 2,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Tooltip
              title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
              placement={`top`}
              arrow
            >
              <Tabutton
                onClick={() => copyToClipBoard(cmdTemplate)}
                title="Copy to clipboard"
                size={'small'}
                sx={{ alignSelf: 'right' }}
                endIcon={<FileCopyIcon />}
              >
                Copy SQL commands for Snowflake configuration
              </Tabutton>
            </Tooltip>
          </Grid>
        </Grid>
      </List>
    </Stack>
  );
};
