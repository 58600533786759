import { gql, useMutation } from '@apollo/client';

import { FRAGMENTS } from './fragments';
import { organizationPageQuery } from './organization';

//queries
export const warehouseById = gql`
  query WarehouseById(
    $organizationId: String!
    $warehouseId: String!
    $daysBack: Int
  ) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouse(id: $warehouseId) {
        ...WarehouseFields
        databases {
          id
          name
          warehouseId
          lastModified
          lastModifiedBy
          createdAt
          statistics(daysBack: $daysBack) {
            ...ResourceStatsFields
          }
          privileges
        }
        privileges
      }
    }
  }
  ${FRAGMENTS.warehouse}
  ${FRAGMENTS.resourceStats}
  ${FRAGMENTS.org}
`;
export const getSnowflakeConnectionQuery = gql`
  query GetSnowflakeConnection($warehouseId: String!) {
    getSnowflakeConnection(warehouse_id: $warehouseId) {
      publicKey
      catalogIntegrationId
      externalVolumeId
      storageIntegrationId
      stageUrl
      accountUrl
      user
      role
      database
      enabled
    }
  }
`;

export const warehousePeersQuery = gql`
  query WarehousePeersQuery($organizationId: String!) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouses {
        ...WarehouseFields
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.warehouse}
`;

export const WarehouseDashboardCardsQuery = gql`
  query WarehouseDashboardQuery(
    $organizationId: String!
    $warehouseId: String!
    $daysBack: Int
  ) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouse(id: $warehouseId) {
        id
        name
        statistics(daysBack: $daysBack) {
          ...StatisticsFields
          totalTables
          totalNamespaces
        }
        compactionSummary(daysBack: $daysBack) {
          ...CompactionFields
        }
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.stats}
  ${FRAGMENTS.compaction}
`;

export const WarehouseDatabases = gql`
  query WarehouseDatabases($organizationId: String!, $warehouseId: String!) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouse(id: $warehouseId) {
        id
        name
        databases {
          id
          name
          warehouseId
          privileges
        }
        privileges
      }
    }
  }
  ${FRAGMENTS.org}
`;

export const getMirrorStatus = gql`
  query getMirrorStatus(
    $warehouseId: String!
    $taskId: String!
    $target: String!
  ) {
    getMirrorStatus(
      warehouseId: $warehouseId
      taskId: $taskId
      target: $target
    ) {
      status
    }
  }
`;

//mutations
export const createWarehouseMutation = gql`
  mutation CreateWarehouseMutation(
    $organization_name: String!
    $name: String!
    $region: String!
    $location: String
    $roleArn: String
    $externalId: String
    $managed: Boolean
    $creatorRoleId: String!
    $projectId: String
    $orgId: String!
    $createStorage: Boolean!
  ) {
    createWarehouse(
      organization_name: $organization_name
      name: $name
      region: $region
      location: $location
      roleArn: $roleArn
      externalId: $externalId
      managed: $managed
      creatorRoleId: $creatorRoleId
      projectId: $projectId
      orgId: $orgId
      createStorage: $createStorage
    ) {
      id
      name
      roleArn
    }
  }
`;

export const useCreateWarehouse = (organizationId: string) =>
  useMutation(createWarehouseMutation, {
    refetchQueries: [
      {
        query: organizationPageQuery,
        variables: { organizationId },
      },
    ],
    awaitRefetchQueries: true,
  });

export const mirrorWarehouseMutation = gql`
  mutation MirrorWarehouseMutation(
    $warehouseId: String!
    $target: MirrorTarget!
  ) {
    mirrorWarehouse(warehouse_id: $warehouseId, target: $target) {
      message
      status
      taskId
      tableCollisions {
        namespace
        name
      }
      failures
    }
  }
`;

export const detachWarehouseMutation = gql`
  mutation DetachWarehouseMutation(
    $warehouseId: String!
    $target: MirrorTarget!
  ) {
    detachWarehouse(warehouse_id: $warehouseId, target: $target)
  }
`;

export const createSnowflakeConnectionMutation = gql`
  mutation CreateSnowflakeConnection(
    $warehouseId: String!
    $accountUrl: String!
    $user: String!
    $role: String!
    $database: String
  ) {
    createSnowflakeConnection(
      warehouse_id: $warehouseId
      account_url: $accountUrl
      user: $user
      role: $role
      database: $database
    ) {
      publicKey
      storageIntegrationId
      stageUrl
      user
      role
      database
      enabled
    }
  }
`;

export const deleteSnowflakeConnectionMutation = gql`
  mutation deleteSnowflakeConnection($warehouseId: String!) {
    deleteSnowflakeConnection(warehouse_id: $warehouseId)
  }
`;

export const deleteWarehouse = gql`
  mutation DeleteWarehouseMutation($warehouseId: String!) {
    deleteWarehouse(warehouseId: $warehouseId)
  }
`;

export const UpdateWarehouseMutation = gql`
  mutation updateWarehouse($warehouseId: String!, $name: String!) {
    updateWarehouse(warehouseId: $warehouseId, name: $name)
  }
`;
