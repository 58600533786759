import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, LinearProgress, Popper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { debounce, isEmpty } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';

import { Tavatar } from '../../components/Avatar/Tavatar';
import { Tabutton } from '../../components/Button/Tabutton';
import { Tacard } from '../../components/Card/Tacard';
import DashboardMenu from '../../components/DashboardMenu/DashboardMenu';
import { LabelPropertyOptions } from '../../components/Labels/helpers';
import { ConfirmationDialog } from '../../components/Modals/ConfirmationDialog';
import { showUpdateLabelDialog } from '../../components/Modals/UpdateLabelDialog';
import { OhNoesRows } from '../../components/OhNosRows/OhNoesRows';
import { NamePopover } from '../../components/Popovers/NamePopover';
import UserInfo from '../../components/ProfileMenu/UserInfo';
import {
  getAuthorizationDecision,
  getAuthorizationGrants,
} from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  AuthDecisionSubjectType,
  Authorization,
  LabelMaskMode,
  LabelSearchResult,
  Privilege,
  SortByDirection,
} from '../../graphql/gen/graphql';
import { deleteLabel, searchLabelsQuery } from '../../graphql/label';
import { getDetailedApolloError } from '../../utils/ApolloUtils';
import { getLogger } from '../../utils/logging';
import { formatTimestamp, relativeTimeAutoFormat } from '../../utils/time';
import {
  calculateTextWidth,
  ESCustomPagination,
  LabelsPageCustomToolbar,
} from '../helpers';

const logger = getLogger(
  'pages.Organization.Labels' /*FUTURE import.meta.url ?*/,
);
export default function Labels({
  user,
  orgName,
}: {
  user: any;
  orgName: string;
}) {
  const [totalHitCount, setTotalHitCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [backStack, setBackStack] = useState<string[][]>([]);
  const [currentKeys, setCurrentKeys] = useState<string[]>([]);
  const [labelToDelete, setLabelToDelete] = useState<any>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [userCanDelete, setUserCanDelete] = useState(true);
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const orgId = useMemo(() => user.getOrganization(orgName)?.id, [user]);

  interface LabelSearchParams {
    organizationId: string;
    query: string;
    maxResults: number;
    searchAfter: string[] | undefined;
    sortBy: any[];
  }
  const [deleteLabelMutation] = useMutation(deleteLabel);
  const { data, loading, refetch } = useQuery<any, LabelSearchParams>(
    searchLabelsQuery,
    {
      variables: {
        //@ts-ignore
        organizationId: orgId,
        query: searchQuery !== '' ? searchQuery : '*',
        maxResults: pageSize,
        searchAfter: undefined,
        sortBy: [
          {
            fieldName: 'labelName.sort',
            sortDirection: SortByDirection.Asc,
          },
        ],
      },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  );
  const [getModifyLabelDecision] = useLazyQuery(getAuthorizationDecision);
  const { data: grantDecision } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        privileges: [Privilege.CreateLabel],
        requirement: 'ALL',
        resource: {
          type: AuthDecisionResourceType.Organization,
          identifier: user.getOrganization(orgName)?.id,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const hasCreateLabel =
    grantDecision?.authDecision === AuthDecisionResponseType.Allow;

  const totalLabelsCount = useMemo(() => data?.searchLabels?.totalHits, [data]);
  const nextKeys: string[] = useMemo(
    () =>
      data?.searchLabels?.searchAfterKeys
        ? data?.searchLabels?.searchAfterKeys
        : [],
    [data],
  );
  const page = backStack.length + 1;
  const displayName = user.loginSession?.loggedInOrg?.displayName;
  const currentUserEmail = user?.loginSession?.membership?.email || user?.email;

  const resetAndReFetch = useCallback(() => {
    setBackStack([]);
    setCurrentKeys([]);
    return refetch({
      searchAfter: undefined,
    });
  }, []);

  const handleLabelCreated = useCallback((label: any) => {
    if (label) {
      setTimeout(() => {
        resetAndReFetch().then(() =>
          enqueueSnackbar(`New label created: ${label.name}`, {
            variant: 'success',
          }),
        );
      }, 2000);
    }
  }, []);

  const handleLabelUpdated = useCallback((labelName: string) => {
    if (labelName) {
      setTimeout(() => {
        resetAndReFetch().then(() =>
          enqueueSnackbar(`${labelName} label has been updated`, {
            variant: 'success',
          }),
        );
      }, 2000);
    }
  }, []);

  const handlePartialFail = useCallback(() => {
    setTimeout(() => {
      resetAndReFetch().then(() => {});
    }, 2000);
  }, []);

  const onKeyDownHandler = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code == 'Enter') {
        e.preventDefault();
        //search again?
      }
    },
    [],
  );

  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      //@ts-ignore
      const searchyMcSearch = e.target.value;
      setSearchQuery(searchyMcSearch);
      setBackStack([]);
      setCurrentKeys([]);
    },
    [],
  );
  const debouncedSearchChange = debounce(onSearchChange, 500);

  const pushBackStack = useCallback((keys: string[]) => {
    setBackStack((prevState) => [...prevState, keys]);
  }, []);

  const popBackStack = useCallback((): string[] => {
    if (backStack.length > 0) {
      const retVal = backStack[backStack.length - 1];
      setBackStack((prevState) => prevState.slice(0, backStack.length - 1));
      return retVal;
    } else {
      return [];
    }
  }, [backStack]);

  const handleBackButtonPagination = useCallback(() => {
    const backKeys = popBackStack();
    refetch({
      searchAfter: backKeys.length == 0 ? undefined : backKeys,
    }).then(() => setCurrentKeys(backKeys));
  }, [backStack]);

  const handleNextButtonPagination = useCallback(() => {
    if (currentKeys) {
      pushBackStack(currentKeys);
    }

    refetch({ searchAfter: nextKeys.length == 0 ? undefined : nextKeys }).then(
      () => setCurrentKeys(nextKeys),
    );
  }, [nextKeys, currentKeys]);

  const handleSortChange = useCallback((sortModel: GridSortModel) => {
    setBackStack([]);
    setCurrentKeys([]);
    const sortColumn = sortModel[0].field;
    const sortDirection = sortModel[0].sort;
    if (sortColumn === 'lastModified') {
      refetch({
        sortBy: [
          {
            fieldName: sortColumn,
            sortDirection:
              sortDirection === SortByDirection.Desc
                ? SortByDirection.Desc
                : SortByDirection.Asc,
          },
          {
            fieldName: 'labelId.keyword',
            sortDirection: SortByDirection.Asc,
          },
        ],
        searchAfter: undefined,
      });
    } else {
      refetch({
        sortBy: [
          {
            fieldName: 'labelName.sort',
            sortDirection:
              sortDirection === SortByDirection.Desc
                ? SortByDirection.Desc
                : SortByDirection.Asc,
          },
        ],
        searchAfter: undefined,
      });
    }
  }, []);

  const closeAndRefetch = async (message: string) => {
    setTimeout(() => {
      refetch().then(() =>
        enqueueSnackbar(message, {
          variant: 'success',
          preventDuplicate: true,
        }),
      );
    }, 1000);
  };

  const handleLabelDelete = async (labelName: string) => {
    const deleteResult = await deleteLabelMutation({
      variables: {
        organizationId: orgId,
        labelId: labelToDelete?.id,
      },
      onCompleted: () => closeAndRefetch(`${labelName} successfully deleted`),
      onError: (error) => {
        const errorMessage = getDetailedApolloError(error);
        enqueueSnackbar(
          errorMessage.type === 'LabelInUseException'
            ? 'This label is currently in use. You must remove this label from all resources before deleting it.'
            : 'Something went wrong. Please try again.',
          { variant: 'error' },
        );
      },
    });

    setConfirmModal(false);
    setLabelToDelete(undefined);
    if (deleteResult?.data?.deleteLabel) {
      setTimeout(() => {
        resetAndReFetch().then(() =>
          enqueueSnackbar(`Label deleted`, { variant: 'success' }),
        );
      }, 2000);
    }
  };

  const checkIfUserHasDeletePrivileges = useCallback(
    async (labelId: string) => {
      const decision = await getModifyLabelDecision({
        variables: {
          request: {
            subject: {
              type: 'USER',
              identifier: user.id,
            },
            privileges: ['MODIFY_LABEL'],
            requirement: 'ALL',
            resource: {
              type: 'LABEL',
              identifier: labelId,
            },
          },
        },
      });

      setUserCanDelete(
        decision?.data?.authDecision === AuthDecisionResponseType.Allow,
      );
    },
    [user],
  );

  const rows = useMemo(() => {
    return data?.searchLabels?.results?.map((label: LabelSearchResult) => {
      return {
        id: label.labelId,
        name: label.labelName,
        description: label.description,
        allowedResourceTypes: label.allowedResourceTypes,
        properties: label.properties,
        userHasModifyPrivs: true, //temp bandaid (will fail on BE)
        currentUserEmail: currentUserEmail,
        rolesByPrivilege: label.rolesByPrivilege,
        lastModified: {
          profilePic: label?.lastModifiedBy?.properties?.[`picture.url`],
          displayName: label?.lastModifiedBy?.displayName || '',
          modifiedTimestamp: label?.lastModified,
          modifiedDate: formatTimestamp(label?.lastModified || ''),
          tabularService: label?.lastModifiedBy?.properties === null,
          email: label?.lastModifiedBy?.email,
        },
        dropdown: {
          createdBy: {
            profilePic: label?.createdBy?.properties?.[`picture.url`],
            displayName: label?.createdBy?.displayName || '',
            createdDate: formatTimestamp(label?.createdAt || ''),
            tabularService: label?.createdBy?.properties === null,
          },
        },
      };
    });
  }, [user, data]);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 240,
        flex: 0.5,
        editable: false,
        sortingOrder: ['desc', 'asc'],
        renderCell: (params: GridRenderCellParams) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
          );
          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: '1 1 100%',
                      alignItems: 'center',
                      height: '100%',
                      minWidth: 0,
                      cursor:
                        textWidth >= params.colDef.computedWidth ||
                        params.row?.comment?.value
                          ? 'default'
                          : 'auto',
                    }}
                  >
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      variant={`inputText`}
                      {...bindHover(popupState)}
                    >
                      {params.value}
                    </Typography>
                  </Box>
                  {textWidth >= params.colDef.computedWidth && (
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <NamePopover name={params.value} />
                    </Popper>
                  )}
                </>
              )}
            </PopupState>
          );
        },
      },
      {
        field: 'properties',
        headerName: 'Policy',
        width: 160,
        editable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const maskMode =
            params.value?.[LabelPropertyOptions.FIELD_MASKING]?.payload
              ?.maskMode;

          if (isEmpty(params.value) || maskMode === LabelMaskMode.None) {
            return <Typography variant={`inputText`}>--</Typography>;
          }

          return (
            <Stack spacing={1} direction={`row`} alignItems={`center`}>
              {maskMode === LabelMaskMode.Hide ? (
                <VisibilityOffIcon fontSize={`small`} />
              ) : (
                <PasswordIcon fontSize={`small`} />
              )}
              <Typography variant={`helperText`} fontWeight={600}>
                {`${
                  maskMode === LabelMaskMode.Hide
                    ? 'Hide column'
                    : 'NULL values'
                }`}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: 'description',
        headerName: 'Description',
        minWidth: 260,
        flex: 0.5,
        editable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
          );
          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      cursor: 'default',
                    }}
                    {...bindHover(popupState)}
                  >
                    {params.value}
                  </Typography>
                  {textWidth >= params.colDef.computedWidth && (
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <NamePopover name={params.value} />
                    </Popper>
                  )}
                </>
              )}
            </PopupState>
          );
        },
      },
      {
        field: 'lastModified',
        headerName: 'Last modified',
        width: 200,
        editable: false,
        sortingOrder: ['desc', 'asc'],
        renderCell: (params: GridRenderCellParams) => (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  {...bindHover(popupState)}
                >
                  <Tavatar
                    displayName={params.value?.displayName}
                    profilePic={params.value?.profilePic}
                    tabularService={params.value?.tabularService}
                    currentUser={
                      params.row.currentUserEmail === params.value?.email
                    }
                  />
                  <Typography
                    variant={`inputText`}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      ml: 1,
                    }}
                  >
                    {relativeTimeAutoFormat(params.value?.modifiedTimestamp)}
                  </Typography>
                </Box>
                <Popper {...bindPopper(popupState)} placement={`top`}>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: 'controlBackground.main',
                      borderRadius: '4px',
                      boxShadow: theme.shadows[3],
                    })}
                  >
                    <UserInfo
                      user={{
                        displayName: params.value?.displayName,
                        profilePic: params.value?.profilePic,
                        email: params.value?.email,
                      }}
                      currentUser={
                        params.row.currentUserEmail === params.value?.email
                      }
                    >
                      <Typography>{params.value?.modifiedDate}</Typography>
                    </UserInfo>
                  </Box>
                </Popper>
              </>
            )}
          </PopupState>
        ),
      },
      {
        field: 'dropdown',
        headerName: '',
        width: 160,
        editable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const dashboardDropdownContent = (
            <Box>
              <Stack direction={`column`}>
                <>
                  {params.row.userHasModifyPrivs && (
                    <Box
                      display={`flex`}
                      flexDirection={`column`}
                      alignItems={'flex-start'}
                      sx={(theme) => ({
                        p: 2,
                        borderBottom: `1px solid ${theme.palette.midnight.two}`,
                      })}
                    >
                      <Tabutton
                        size={'small'}
                        onClick={() =>
                          showUpdateLabelDialog(
                            orgId,
                            params.row,
                            handleLabelUpdated,
                          )
                        }
                      >
                        EDIT LABEL
                      </Tabutton>
                    </Box>
                  )}

                  <Box
                    display={'flex'}
                    sx={(theme) => ({
                      p: 2,
                      borderBottom: `1px solid ${theme.palette.midnight.one}`,
                    })}
                  >
                    <Tavatar
                      profilePic={params.value.createdBy.profilePic}
                      tabularService={params.value.createdBy.tabularService}
                      displayName={params.value.createdBy.displayName}
                    />
                    <Box sx={{ px: 2 }}>
                      <Typography variant="subtitle2">Created</Typography>
                      <Typography variant="helperText">
                        {params.value.createdBy.createdDate}
                      </Typography>
                    </Box>
                  </Box>

                  {params.row.userHasModifyPrivs && (
                    <Box
                      display={`flex`}
                      flexDirection={`column`}
                      alignItems={'flex-start'}
                      sx={(theme) => ({
                        p: 2,
                        borderTop: `1px solid ${theme.palette.midnight.two}`,
                      })}
                    >
                      <Tabutton
                        onClick={() => {
                          checkIfUserHasDeletePrivileges(params.row.id).then(
                            () => {
                              setLabelToDelete(params.row);
                              setConfirmModal(true);
                            },
                          );
                        }}
                        size={'small'}
                        sx={{ color: 'secondary.main' }}
                      >
                        DELETE LABEL
                      </Tabutton>
                    </Box>
                  )}
                </>
              </Stack>
            </Box>
          );

          return (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                sx={(theme) => ({
                  padding: '4px',
                  '&:hover': {
                    backgroundColor: theme.palette.sky.one,
                  },
                  '&:active': {
                    backgroundColor: theme.palette.sky.two,
                  },
                  '&:focus': {
                    border: `1px solid ${theme.palette.sky.seven}`,
                  },
                  border: `1px solid ${theme.palette.midnight.two}`,
                })}
                onClick={() =>
                  showUpdateLabelDialog(orgId, params.row, handleLabelUpdated)
                }
                disabled={!params.row.userHasModifyPrivs}
              >
                <EditIcon />
              </IconButton>
              <DashboardMenu
                dashboardDropdownContent={dashboardDropdownContent}
              />
            </Box>
          );
        },
      },
    ],
    [data, orgName, orgId],
  );

  useEffect(() => {
    setTotalHitCount(totalLabelsCount);
  }, [totalLabelsCount]);

  useEffect(() => {
    if (searchQuery === '' || searchQuery === '*') {
      refetch().catch((error) =>
        logger.error('Unhandled error while searching labels', error),
      );
    } else {
      refetch({
        sortBy: [
          {
            fieldName: '_score',
            sortDirection: SortByDirection.Desc,
          },
          {
            fieldName: 'labelId.keyword',
            sortDirection: SortByDirection.Asc,
          },
        ],
      }).catch((error) =>
        logger.error('Unhandled error while searching labels', error),
      );
    }
  }, [searchQuery]);

  return (
    <>
      <Tacard>
        <DataGridPro
          apiRef={apiRef}
          autoHeight
          pagination
          rows={rows || []}
          columns={columns as GridColumns}
          headerHeight={48}
          rowHeight={64}
          disableColumnMenu
          isRowSelectable={() => false}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
            pinnedColumns: { right: ['dropdown'] },
          }}
          sortingMode={`server`}
          onSortModelChange={handleSortChange}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            setBackStack([]);
            setCurrentKeys([]);
          }}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: OhNoesRows,
            NoResultsOverlay: OhNoesRows,
            Toolbar: LabelsPageCustomToolbar,
            Pagination: ESCustomPagination,
          }}
          componentsProps={{
            noRowsOverlay: {
              buttonControl: (
                <Typography variant={`h1`} color={`brandBlue.main`}>
                  No labels
                </Typography>
              ),
            },
            toolbar: {
              apiRef: apiRef,
              totalCount: totalHitCount,
              pageSize: pageSize,
              onRefetch: refetch,
              organizationId: orgId,
              onLabelCreated: handleLabelCreated,
              onPartialFail: handlePartialFail,
              displayName: displayName,
              handleKeyUp: debouncedSearchChange,
              handleKeyDown: onKeyDownHandler,
              onBackButton: handleBackButtonPagination,
              onNextButton: handleNextButtonPagination,
              page: page,
              hasCreateLabel,
            },
            pagination: {
              apiRef: apiRef,
              pageSize: pageSize,
              onBackButton: handleBackButtonPagination,
              onNextButton: handleNextButtonPagination,
              page: page,
              paginationType: 'Labels',
            },
          }}
          loading={loading || !data}
          sx={{
            ...(rows?.length === 0 || totalHitCount === 0
              ? { minHeight: 600 }
              : {}),
            border: 'none',
            '.MuiDataGrid-columnHeaders': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
            '.MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: 'dusk.half',
            },
          }}
        />
      </Tacard>
      <ConfirmationDialog
        open={confirmModal}
        title="Delete label?"
        acceptText="Delete"
        onDismiss={() => {
          setLabelToDelete(undefined);
          setConfirmModal(false);
        }}
        onAccept={() => handleLabelDelete(labelToDelete?.name)}
        disabled={!userCanDelete}
      >
        {`${
          userCanDelete
            ? `Permanently delete the ${labelToDelete?.name} label?`
            : `You do not have privileges to delete this label. Please contact an administrator.`
        } `}
      </ConfirmationDialog>
    </>
  );
}
