import { gql } from '@apollo/client';

//queries
export const getUsageDaily = gql`
  query usageQuery(
    $organizationId: String!
    $timeframe: String!
    $warehouseId: String
  ) {
    usageByDayAndService(
      organizationId: $organizationId
      timeframe: $timeframe
      warehouseId: $warehouseId
    ) {
      data {
        bytesRead
        day
        service
      }
    }
  }
`;
