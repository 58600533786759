import { useParams } from 'react-router-dom';

import {
  keyBy,
  mapValues,
  floor,
  round,
  isEmpty,
  startsWith,
  endsWith,
  some,
} from 'lodash';

import { Property } from '../../graphql/gen/graphql';
import {
  CompactionOverrideProperties,
  lifecycleManagementProperties,
} from '../../utils/properties';

export const UI_HANDLED_PROPERTIES = [
  'comment',
  'history.expire.max-snapshot-age-ms',
  'history.expire.min-snapshots-to-keep',
  'history.expire.max-ref-age-ms',
  'compaction.enabled',
  'compaction.strategy',
  'compaction.options.partial-progress.enabled',
  'compaction.options.partial-progress.max-commits',
  'compaction.options.max-file-group-size-bytes',
  'compaction.options.max-concurrent-file-group-rewrites',
  'compaction.options.rewrite-job-order',
  'fileloader.csv.column-delimiter',
  'fileloader.enabled',
  'fileloader.file-format',
  'fileloader.path',
  'fileloader.write-mode',
  'fileloader.csv.header',
  'optimizer.enabled',
  'write.parquet.compression-codec',
  'write.summary.partition-limit',
  'write.metadata.compression-codec',
  'write.delete.parquet.compression-codec',
  'write.object-storage.enabled',
  'lifecycle.enabled',
  'lifecycle.data-age-column',
  'lifecycle.table.max-data-age-ms',
  'lifecycle.data-age-column-units',
  'spark.query-column-names',
];

export const getInitialValues = (
  groupProperties: any,
  mappedProps: Property[],
  bucketName: string,
  scheme: string,
) => {
  const { warehouse: warehouseName, database, table } = useParams();
  return mapValues(keyBy(groupProperties, 'name'), (property) => {
    if (property.key === 'overrides') {
      const existingValues: any[] = [];

      CompactionOverrideProperties.forEach((compactionOverride) => {
        const existing = mappedProps.find(
          (existing) => existing.key === compactionOverride.form.key,
        );
        if (existing && existing.value !== compactionOverride.default) {
          const formattedOverride = {
            [compactionOverride.form.name]: compactionOverride.form
              ?.displayFormatter
              ? compactionOverride.form.displayFormatter(existing.value)
              : existing.value,
            existing: true,
          };

          if (compactionOverride.form?.helperComponentName) {
            formattedOverride[compactionOverride.form.helperComponentName] =
              compactionOverride.form.helperComponentDefault;
          }

          existingValues.push(formattedOverride);
        }
      });

      return !isEmpty(existingValues) ? existingValues : property.default;
    }

    if (property.key === 'lifecycleMgmt') {
      const lifecycleValues = {
        timestampColumn: '',
        timeUnit: '',
        timestampType: '',
        rowRecordAge: '',
        columnMasking: [],
      };
      const existingColumnProps = mappedProps.filter(
        (existingProp) =>
          //we don't need the transform property (for now) since we only nullify (for now)
          startsWith(existingProp.key, 'lifecycle.column.') &&
          endsWith(existingProp.key, '.max-data-age-ms'),
      );
      const existingTimeUnitProp = mappedProps.find(
        (existingProp) =>
          existingProp.key === 'lifecycle.data-age-column-units',
      );
      if (existingTimeUnitProp) {
        //@ts-ignore
        lifecycleValues.timeUnit = existingTimeUnitProp.value;
      }

      lifecycleManagementProperties.forEach((lifecycleProp: any) => {
        const existing = mappedProps.find(
          (existingProp) => existingProp.key === lifecycleProp.key,
        );

        switch (true) {
          //timestamp and row record age properties
          case existing && existing.value !== lifecycleProp.default:
            //@ts-ignore
            lifecycleValues[lifecycleProp.name] =
              lifecycleProp?.displayFormatter
                ? //@ts-ignore
                  lifecycleProp.displayFormatter(existing.value)
                : //@ts-ignore
                  existing.value;
            break;

          case lifecycleProp.name === 'columnMasking' &&
            !isEmpty(existingColumnProps):
            existingColumnProps.forEach((prop) => {
              //@ts-ignore
              lifecycleValues.columnMasking.push({
                identifier: getIdentifierNameFromKey(prop.key),
                ttl: lifecycleProp.displayFormatter(prop.value),
              });
            });
            break;

          default:
            //@ts-ignore
            lifecycleValues[lifecycleProp.name] =
              lifecycleProp?.displayFormatter && !isEmpty(lifecycleProp.default)
                ? lifecycleProp.displayFormatter(lifecycleProp.default)
                : lifecycleProp.default;
        }
      });

      return lifecycleValues;
    }

    const existing = mappedProps.find(
      (existing) => existing.key === property.key,
    );
    const helperProperty = mappedProps.find(
      (prop) => prop.key === property.helperProperty,
    );

    if (existing) {
      return property?.displayFormatter && helperProperty
        ? property.displayFormatter(existing.value, helperProperty.value)
        : property?.displayFormatter && !helperProperty
        ? property.displayFormatter(existing.value)
        : existing.value;
    } else {
      return property?.displayFormatter
        ? property.displayFormatter(property.default)
        : property?.dynamicDefault
        ? property.dynamicDefault(
            bucketName,
            warehouseName,
            database,
            table,
            scheme,
          )
        : property.default;
    }
  });
};

export const getDangerZoneItems = (tableName: string, resourceType: string) => {
  return [
    {
      title: `Drop ${resourceType}`,
      description: `This will mark this ${resourceType}'s ${
        resourceType === 'table' ? 'data and' : ''
      } metadata files for deletion.`,
      buttonText: `Drop this ${resourceType}`,
      confirmModal: 'TypeDeleteDialog',
      modalContent: {
        title: `Drop ${tableName} ${resourceType}`,
        confirmText: `Drop ${resourceType}`,
        recoveryWarningText: `This will mark ${tableName} ${resourceType}'s ${
          resourceType === 'table' ? 'data and' : ''
        } metadata files for deletion.`,
      },
      mutationName: 'deleteTable',
    },
  ];
};

export const convertMillisecondsToDays = (milliseconds: string) => {
  if (isEmpty(milliseconds)) {
    return '';
  }
  const total_seconds = floor(parseInt(milliseconds) / 1000);
  const total_minutes = floor(total_seconds / 60);
  const total_hours = floor(total_minutes / 60);
  const days = floor(total_hours / 24);
  return days.toString();
};

export const convertDaysToMilliseconds = (days: string) => {
  if (isEmpty(days)) {
    return '';
  }
  return round(parseInt(days) * 24 * 60 * 60 * 1000).toString();
};

export const convertBooleanToString = (bool: boolean) => {
  return bool.toString();
};

export const convertStringToBoolean = (string: string) => {
  return string === 'true';
};

export const convertToBytes = (size: number, type: any) => {
  const types = ['B', 'KB', 'MB', 'GB', 'TB'];
  const key = types.indexOf(type.toUpperCase());
  if (typeof key !== 'boolean') {
    return (size * 1024 ** key).toString();
  }
  return 'invalid type: type must be GB/KB/MB etc.';
};

export const convertBytesToMB = (bytes: number) => {
  return bytes / Math.pow(1024, 2);
};

export const convertBytesToGB = (bytes: number) => {
  return bytes / Math.pow(1024, 3);
};

export const convertGBtoBytes = (size: any) => {
  return convertToBytes(size, 'GB');
};

export const formatFileLoaderPath = (
  bucket: string,
  wh: string,
  db: string,
  table: string,
  scheme: string,
) => {
  return `${scheme}://${bucket}/tabular/staged/${wh}/${db}/${table}`;
};

export const configureDelimiter = (fileFormat: string) => {
  if (fileFormat === 'csv') {
    return { key: 'fileloader.csv.column-delimiter', value: ',' };
  }

  if (fileFormat === 'tsv') {
    return { key: 'fileloader.csv.column-delimiter', value: '\t' };
  }
};

export const convertTsvToCsv = (value: string) => {
  return value === 'tsv' ? 'csv' : value;
};

export const convertCsvToTsv = (fileFormat: string, delimiter: string) => {
  return delimiter === '\t' ? 'tsv' : fileFormat;
};

export const SWITCH_COMPONENT_PROPERTIES = [
  'compaction.enabled',
  'fileloader.enabled',
  'optimizer.enabled',
  'lifecycle.enabled',
];

export const TIMESTAMP_COLUMN_TYPES = [
  'timestamp',
  'timestamptz',
  'long',
  'date',
];

export const getIdentifierNameFromKey = (fullKey: string) => {
  //17 is index number after lifecycle.column.
  return fullKey.substring(17, fullKey.indexOf('.max-data-age-ms'));
};

export const KID_GLOVE_PROPERTIES = [
  'overrides',
  'lifecycleMgmt',
  'fileLoaderIgnoreHeaders',
];
