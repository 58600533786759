import React, { useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  Box,
  IconButton,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, GridColumns, useGridApiRef } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { OrgAuth, OrgAuthProvider } from '../../../graphql/gen/graphql';
import { calculateTextWidth } from '../../../pages/helpers';
import { relativeTimeAutoFormat } from '../../../utils/time';
import { Tavatar } from '../../Avatar/Tavatar';
import {
  DataGridToolBar,
  CustomPaging,
  MenuContentType,
} from '../../DataGridToolbar/DataGridToolbar';
import { CustomIcon } from '../../Icons/CustomIcon';
import { NamePopover } from '../../Popovers/NamePopover';
import UserInfo from '../../ProfileMenu/UserInfo';
import { dateComparator, getApplyFilterFnCreatorName } from '../helpers';

export default function AWSIdentityProviders({
  orgAuthData,
  onIdentityProviderDelete,
  currentUserEmail,
  showDelete = true,
  refreshResourceContent,
  addResourceContent,
  loading,
}: {
  orgAuthData: [OrgAuth];
  onIdentityProviderDelete: any;
  currentUserEmail: string;
  showDelete?: boolean;
  refreshResourceContent?: MenuContentType;
  addResourceContent?: MenuContentType;
  loading?: boolean;
}) {
  const [copySuccess, setCopySuccess] = useState('');
  const [pageSize, setPageSize] = useState(10);

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const apiRef = useGridApiRef();
  const awsIdentity = useMemo(
    () =>
      orgAuthData?.filter(
        (auth: OrgAuth) => auth?.authType === OrgAuthProvider.IamSso,
      ),
    [orgAuthData],
  );

  const rows = useMemo(() => {
    return awsIdentity?.map((auth: OrgAuth) => {
      return {
        id: auth.id,
        clientId: auth.clientId,
        created: {
          displayName: auth?.createdByMember?.displayName,
          profilePic: auth?.createdByMember?.properties['picture.url'],
          currentUser: currentUserEmail === auth?.createdByMember?.user?.email,
          email: auth?.createdByMember?.user?.email,
          createdAt: auth.createdAt,
        },
        lastSession: auth?.lastSession,
      };
    });
  }, [awsIdentity]);

  const columns = useMemo(
    () => [
      {
        field: 'clientId',
        headerName: 'AWS IAM identity provider ARN',
        minWidth: 200,
        editable: false,
        flex: 2,
        renderCell: (params: any) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
            true,
          );
          return (
            <Box
              sx={{
                display: 'flex',
                flex: '1 1 100%',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '100%',
                minWidth: 0,
              }}
            >
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      variant={`inputText`}
                      {...bindHover(popupState)}
                    >
                      {params.value}
                    </Typography>
                    {textWidth >= params.colDef.computedWidth && (
                      <Popper
                        style={{ zIndex: 1300 }}
                        {...bindPopper(popupState)}
                        placement={`right`}
                        modifiers={[
                          {
                            name: 'flip',
                            options: {
                              fallbackPlacements: ['bottom'],
                            },
                          },
                        ]}
                      >
                        <NamePopover name={params.value} />
                      </Popper>
                    )}
                  </>
                )}
              </PopupState>
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <ContentCopyOutlinedIcon
                  fontSize={'small'}
                  sx={{
                    cursor: 'pointer',
                    color: 'brandBlue.main',
                    display: 'flex',

                    marginLeft: 1,
                  }}
                  onClick={() => {
                    copyToClipBoard(params.value);
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
      {
        field: 'created',
        headerName: 'Created',
        minWidth: 175,
        flex: 0.5,
        editable: false,
        sortComparator: dateComparator,
        getApplyQuickFilterFn: getApplyFilterFnCreatorName,
        sortingOrder: ['desc', 'asc', null],
        renderCell: (params: any) => {
          const createdBy = params.value;
          const createdAt = params.value?.createdAt;

          return (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PopupState variant="popper">
                  {(popupState) => (
                    <>
                      <Box {...bindHover(popupState)}>
                        <Tavatar
                          displayName={createdBy?.displayName}
                          profilePic={createdBy?.profilePic}
                          currentUser={createdBy?.currentUser}
                        />
                      </Box>
                      <Popper {...bindPopper(popupState)} placement={`top`}>
                        <Box
                          sx={(theme) => ({
                            backgroundColor: 'controlBackground.main',
                            borderRadius: '4px',
                            boxShadow: theme.shadows[3],
                          })}
                        >
                          <UserInfo
                            user={{
                              displayName: createdBy?.displayName,
                              profilePic: createdBy?.profilePic,
                              email: createdBy?.email,
                            }}
                            currentUser={createdBy?.currentUser}
                          />
                        </Box>
                      </Popper>
                    </>
                  )}
                </PopupState>
              </Box>
              <Typography display={'flex'} alignItems={'center'} sx={{ ml: 1 }}>
                {createdAt ? relativeTimeAutoFormat(createdAt) : '--'}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'lastSession',
        headerName: 'Last session',
        minWidth: 150,
        flex: 0.5,
        editable: false,
        type: 'dateTime',
        getApplyQuickFilterFn: undefined,
        sortingOrder: ['desc', 'asc', null],
        valueFormatter: (params: any) => {
          return params.value ? relativeTimeAutoFormat(params.value) : '--';
        },
      },
      {
        field: 'delete',
        headerName: '',
        width: showDelete ? 60 : 0,
        editable: false,
        renderCell: (params: any) => (
          <>
            {showDelete && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={'Delete'} placement={`top`} arrow>
                  <span>
                    <IconButton
                      onClick={() => {
                        onIdentityProviderDelete(params.row);
                      }}
                      disabled={params.row.active}
                    >
                      <CloseIcon
                        sx={{
                          color: params.row.active
                            ? 'midnight.three'
                            : 'error.main',
                        }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
          </>
        ),
      },
    ],
    [orgAuthData],
  );

  return (
    <>
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        pagination={rows?.length >= 10}
        hideFooter={rows?.length < 10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        rows={rows || []}
        columns={columns as GridColumns}
        headerHeight={34}
        disableColumnMenu
        isRowSelectable={() => false}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created', sort: 'desc' }],
          },
          pinnedColumns: { right: ['active', 'delete'] },
          pagination: { page: 0, pageSize: 10 },
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [''],
            },
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No AWS IAM identity providers
            </Stack>
          ),
          Toolbar: DataGridToolBar,
          Pagination: CustomPaging,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            apiRef: apiRef,
            pageSize: pageSize,
            setPageSize: setPageSize,
            headerName: (
              <Box display={'flex'} paddingLeft={2} alignItems={'center'}>
                {`AWS IAM identity provider`}
              </Box>
            ),
            icon: (
              <CustomIcon
                src={'/assets/img/logos/aws.svg'}
                sx={{ height: '24px', width: '40px' }}
              />
            ),
            refreshResourceContent: refreshResourceContent,
            addResourceContent: addResourceContent,
            rowCount: awsIdentity?.length,
            pageAtNumber: 10,
          },
          pagination: {
            apiRef: apiRef,
            pageSize: pageSize,
            setPageSize: setPageSize,
            rowCount: awsIdentity?.length,
          },
        }}
        loading={loading}
        sx={{
          border: 'none',
          '.MuiDataGrid-columnHeaders': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
          '.MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: 'dusk.half',
          },
        }}
      />
    </>
  );
}
