import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../RouteTable';

const tabular = 'Tabular';

const titleList = [
  { path: ROUTES.login, title: '' },
  { path: ROUTES.signup, title: '' },
  { path: ROUTES.reset, title: '' },
  { path: ROUTES.logout, title: '' },
  { path: '/warehouses', title: 'Warehouses' },
  { path: '/members', title: 'Members' },
  { path: '/roles', title: 'Roles' },
  { path: '/profile', title: 'Profile' },
  { path: '/settings', title: 'Settings' },
  { path: '/databases', title: 'Databases' },
  { path: '/security', title: 'Security' },
  { path: '/compute', title: 'Compute' },
  { path: '/storage', title: 'Storage' },
  { path: '/access', title: 'Access' },
  { path: '/activity', title: 'Activity' },
  { path: '/tables', title: 'Tables' },
  { path: '/overview', title: 'Table Overview' },
  { path: '/config', title: 'Table Maintenance' },
  { path: '/dropped-tables', title: 'Dropped Tables' },
];

const TitleContext = React.createContext(tabular);
function DocumentTitleProvider({ children }: { children: any }) {
  const [title, setTitle] = useState(tabular);

  let curLoc = useLocation();
  const cleanPath = curLoc.pathname
    .slice(1)
    .replace('/-', '')
    .replace(/%20/g, ' ');

  const resource = cleanPath.split('/').slice(-2)[0];

  useEffect(() => {
    const curTitle = titleList.find((item) =>
      curLoc.pathname.includes(item.path),
    );

    if (curTitle && curTitle.title) {
      setTitle(curTitle.title);
      document.title = `${resource} • ${curTitle.title} • ${cleanPath}`;
    } else if (curTitle && !curTitle.title) {
      setTitle(curTitle.title);
      document.title = `${resource}`;
    }
  }, [curLoc]);

  return (
    <TitleContext.Provider value={title}>{children}</TitleContext.Provider>
  );
}

export { DocumentTitleProvider };
