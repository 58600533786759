import React, { useMemo, useReducer, useRef, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { useQuery } from '@apollo/client';
import {
  Box,
  Popper,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { SchemaField, SnapshotRefs } from '../../graphql/gen/graphql';
import { TableDataPreviewQuery } from '../../graphql/table';
import { Tacard } from '../Card/Tacard';
import { SnapshotRefProp } from '../SnapshotViewer/SnapshotView';
import DataPreviewGrid from './DataPreviewGrid';

interface DataPreviewParams {
  organizationId: string;
  warehouseId: string;
  databaseName: string;
  tableName: string;
}

interface PathSegment {
  pathField: string;
  currentData: any;
  currentSchema: any;
}

enum PathActions {
  SELECT,
  DRILLDOWN,
}

enum VIEW_OPTION {
  DETAILED = 'Detailed',
  RAW = 'Raw',
}

const findSchema = (fieldName: string, schema: any) => {
  if (schema.type === 'struct' || schema.hasOwnProperty('schema_id')) {
    schema = schema.fields.find((schema: any) => {
      return schema.name === fieldName;
    })?.type;
  } else if (schema.type === 'map') {
    schema = schema[fieldName];
  } else if (schema.type === 'list') {
    schema = schema.element;
  }
  return schema;
};

export default function TableDataPreview({
  organizationId,
  warehouseId,
  databaseName,
  tableName,
}: DataPreviewParams) {
  const [branch, setBranch] = useState('main');
  const { data, loading, refetch } = useQuery(TableDataPreviewQuery, {
    variables: {
      warehouseId: warehouseId,
      database: databaseName,
      table: tableName,
      branchName: branch,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const tableData = data?.fetchTableDataPreview?.dataPreview?.records;
  const tableSchema = data?.fetchTableDataPreview?.dataPreview?.schema;

  const handlePathAction = (
    path: PathSegment[],
    action: { type: PathActions; pathSegment?: PathSegment; index?: number },
  ) => {
    switch (action.type) {
      case PathActions.SELECT:
        return !action.index
          ? [
              {
                pathField: '',
                currentData: tableData,
                currentSchema: tableSchema,
              },
            ]
          : path.slice(0, action.index + 1);
      case PathActions.DRILLDOWN:
        if (path.length === 1 && action.pathSegment != null) {
          return [
            {
              pathField: `row ${action.index}`,
              currentData: tableData,
              currentSchema: tableSchema,
            },
            action.pathSegment,
          ];
        } else if (action.pathSegment != null)
          return [...path, action.pathSegment];
    }
    return path;
  };

  const [path, dispatchPath] = useReducer(handlePathAction, [
    { pathField: '', currentData: tableData, currentSchema: tableSchema },
  ]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [hoverData, setHoverData] = useState({
    rows: {},
    columns: {},
    raw: {},
  });
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>();
  const [viewOption, setViewOption] = useState(VIEW_OPTION.DETAILED);

  const handleChangeDetailData = (
    args: GridRenderCellParams,
    fieldName: string,
    currentSchema: any,
  ) => {
    if (typeof args?.value === 'object' && args?.value != null) {
      dispatchPath({
        type: PathActions.DRILLDOWN,
        pathSegment: {
          pathField: fieldName,
          currentData: args.value,
          currentSchema: findSchema(fieldName, currentSchema),
        },
        index: args.row.__row_id,
      });
      setAnchorEl(null);
    }
  };

  const clearTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleChipMouseEnter = (
    event: React.MouseEvent<HTMLElement>,
    params: GridRenderCellParams,
    fieldName: string,
    currentSchema: any,
  ) => {
    clearTimeoutRef();
    const schema = findSchema(fieldName, currentSchema);
    setHoverData({
      rows: getDetailRows(schema, params.value),
      columns: getDetailColumns(schema, true),
      raw: params.value,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleChipMouseLeave = () => {
    clearTimeoutRef();
    timeoutRef.current = setTimeout(() => setAnchorEl(null), 100);
  };

  const renderChip = (
    params: GridRenderCellParams,
    fieldName: string,
    currentSchema: any,
    isHover: boolean,
    linkText: string,
  ) => {
    if (isHover) {
      return linkText;
    }
    return (
      <Link
        underline="hover"
        onMouseEnter={(event) =>
          handleChipMouseEnter(event, params, fieldName, currentSchema)
        }
        onMouseLeave={handleChipMouseLeave}
        onClick={(event) => {
          event.preventDefault();
          handleChangeDetailData(params, fieldName, currentSchema);
        }}
      >
        {linkText}
      </Link>
    );
  };

  const renderCell = (
    params: GridRenderCellParams,
    type: string,
    fieldName: string,
    currentSchema: any,
    isHover = false,
  ) => {
    if (params.value != null) {
      switch (type) {
        case 'list':
          const childType = findSchema(fieldName, currentSchema);
          return renderChip(
            params,
            fieldName,
            currentSchema,
            isHover,
            `[${Object.keys(params.value).length} ${
              childType?.element?.type || childType?.element
            }]`,
          );
        case 'map':
          return renderChip(
            params,
            fieldName,
            currentSchema,
            isHover,
            `<${Object.keys(params.value).length} keys>`,
          );
        case 'struct':
          return renderChip(
            params,
            fieldName,
            currentSchema,
            isHover,
            `{${Object.keys(params.value).length} fields}`,
          );
      }
    }
    return (
      <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {String(params.value)}
      </Box>
    );
  };

  const getDetailColumns = (currentSchema: any, isHover = false) => {
    const type = currentSchema?.type;
    switch (type) {
      case 'list':
        return [
          {
            field: 'index',
            headerName: 'index',
            minWidth: 150,
            flex: 1,
          },
          {
            field: 'value',
            headerName: 'value',
            minWidth: 150,
            flex: 1,
            renderCell: (params: GridRenderCellParams) =>
              renderCell(
                params,
                currentSchema?.element?.type || currentSchema?.element,
                `row ${params.row.__row_id}`,
                currentSchema,
                isHover,
              ),
          },
        ];
      case 'map':
        return [
          {
            field: 'key',
            headerName: 'key',
            minWidth: 150,
            flex: 1,
            renderCell: (params: GridRenderCellParams) =>
              renderCell(
                params,
                currentSchema?.key?.type || currentSchema?.key,
                'key',
                currentSchema,
                isHover,
              ),
          },
          {
            field: 'value',
            headerName: 'value',
            minWidth: 150,
            flex: 1,
            renderCell: (params: GridRenderCellParams) =>
              renderCell(
                params,
                currentSchema?.value?.type || currentSchema?.value,
                'value',
                currentSchema,
                isHover,
              ),
          },
        ];
      case 'struct':
        return [
          {
            field: 'field',
            headerName: 'field',
            minWidth: 150,
            flex: 1,
          },
          {
            field: 'value',
            headerName: 'value',
            minWidth: 150,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
              const childType = findSchema(params.row.field, currentSchema);
              return renderCell(
                params,
                childType?.type || childType,
                params.row.field,
                currentSchema,
                isHover,
              );
            },
          },
        ];
    }
    return [];
  };

  const columns = useMemo(() => {
    if (path.length === 1) {
      const columns = tableSchema?.fields?.map((schemaField: SchemaField) => ({
        field: schemaField.name,
        headerName: schemaField.name,
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) =>
          renderCell(
            params,
            schemaField.type?.type || schemaField.type,
            schemaField.name || '',
            tableSchema,
          ),
      }));
      return [
        {
          field: '__row_id',
          headerName: '',
          maxWidth: 50,
          flex: 0,
          cellClassName: 'MuiDataGrid-rowIdColumn',
          sortable: false,
        },
        ...(columns ? columns : []),
      ];
    }
    return getDetailColumns(path[path.length - 1].currentSchema);
  }, [data, path]);

  const getDetailRows = (currentSchema: any, currentData: any) => {
    const type = currentSchema?.type;
    switch (type) {
      case 'list':
        return currentData.map((value: any, index: number) => ({
          __row_id: index + 1,
          index: index + 1,
          value,
        }));
      case 'map':
        return Object.entries(currentData).map(([key, value], index) => ({
          __row_id: index + 1,
          key,
          value,
        }));
      case 'struct':
        return Object.entries(currentData).map(([field, value], index) => ({
          __row_id: index + 1,
          field,
          value,
        }));
    }
  };

  const rows = useMemo(() => {
    if (path.length === 1) {
      return tableData?.map((row: any, index: number) => ({
        ...row,
        __row_id: index + 1,
      }));
    }
    const current = path[path.length - 1];
    return getDetailRows(current.currentSchema, current.currentData);
  }, [data, path]);

  const breadcrumbs = useMemo(
    () =>
      path.map((pathSegment, index) => (
        <span key={index} style={{ margin: 0 }}>
          <Link
            underline="hover"
            variant="subtitle1"
            onClick={(e) => {
              e.preventDefault();
              dispatchPath({ type: PathActions.SELECT, index: index });
            }}
          >
            {pathSegment.pathField}
          </Link>
          {index < path.length - 1 && ' / '}
        </span>
      )),
    [data, path],
  );

  const refreshResourceContent = useMemo(
    () => ({
      title: 'Refresh preview data',
      action: () => {
        refetch();
        dispatchPath({ type: PathActions.SELECT, index: 0 });
      },
    }),
    [],
  );

  const metadata = useMemo(() => data?.fetchTableDataPreview?.metadata, [data]);

  const refs: SnapshotRefProp[] = useMemo(() => {
    return metadata?.refs?.map(({ key, value }: SnapshotRefs) => ({
      ...value,
      name: key,
    }));
  }, [data]);

  const branchRefs = useMemo(
    () => refs?.filter((ref) => ref.type === 'branch'),
    [data],
  );

  return (
    <>
      <Tacard grid>
        <DataPreviewGrid
          className="ph-no-capture"
          rows={rows}
          columns={columns}
          loading={loading}
          refreshResourceContent={refreshResourceContent}
          breadcrumbs={path.length > 1 ? breadcrumbs : []}
          branchRefs={branchRefs}
          selectedBranch={branch}
          handleChangeBranch={setBranch}
          showBranchSelector={path.length < 2}
          tableName={tableName}
        />
      </Tacard>

      <Popper
        sx={(theme) => ({
          border: `1px solid ${theme.palette.midnight.two}`,
          borderRadius: '8px',
          boxShadow: theme.shadows[3],
          overflow: 'auto',
          zIndex: 1500,
        })}
        modifiers={[
          {
            name: 'flip',
            options: {
              altBoundary: false,
            },
          },
        ]}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={'right'}
        onMouseEnter={clearTimeoutRef}
        onMouseLeave={handleChipMouseLeave}
        className="ph-no-capture"
      >
        <RadioGroup
          row
          value={viewOption}
          onChange={(event, value) => setViewOption(value as VIEW_OPTION)}
          sx={(theme) => ({
            pl: 1,
            backgroundColor: theme.palette.popper.main,
            color: theme.palette.popper.contrastText,
          })}
        >
          <FormControlLabel
            value={VIEW_OPTION.DETAILED}
            control={<Radio />}
            label="Detailed"
          />
          <FormControlLabel
            value={VIEW_OPTION.RAW}
            control={<Radio />}
            label="Raw"
          />
        </RadioGroup>
        {viewOption === VIEW_OPTION.DETAILED && (
          <DataPreviewGrid
            sx={{
              width: '35vw',
              height: '50vh',
              backgroundColor: 'popper.main',
              '.MuiDataGrid-root': {
                backgroundColor: 'popper.main',
                color: 'popper.contrastText',
                '.MuiDataGrid-columnHeaders': {
                  backgroundColor: 'dusk.seven',
                },
              },
            }}
            rows={hoverData.rows}
            columns={hoverData.columns}
            tableName={''}
          />
        )}
        {viewOption === VIEW_OPTION.RAW && (
          <SyntaxHighlighter
            language="json"
            style={dracula}
            customStyle={{
              fontSize: '16px',
              lineHeight: '1em',
              margin: 0,
              width: '35vw',
              height: '50vh',
            }}
          >
            {JSON.stringify(hoverData.raw, null, 2)}
          </SyntaxHighlighter>
        )}
      </Popper>
    </>
  );
}
