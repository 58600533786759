import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useModal, create, show } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, List, ListItem, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { Secret, Warehouse } from '../../../graphql/gen/graphql';
import {
  createOrgCredential,
  orgCredentialUsage,
} from '../../../graphql/organization';
import { Talert } from '../../Alert/Talert';
import { CustomIcon } from '../../Icons/CustomIcon';
import { Tabulink } from '../../Link/Tabulink';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../WizardContainer';
import { CreateCredentialStep } from '../components/CreateCredentialStep';
import { SparkSQLTemplate } from '../spark/SparkSQLTemplate';
import { DatabricksCreateCluster } from './DatabrickCreateCluster';
import { DatabricksClusterLibraries } from './DatabricksClusterLibraries';

const DatabricksConnectionWizardDialog = create<{
  organizationId: string;
  organizationName: string;
  warehouse: Warehouse;
}>(({ warehouse, organizationName, organizationId }) => {
  const modal = useModal();
  const [createOrgCredentialMutation] = useMutation(createOrgCredential);
  const [getOrgCredentialUsage, { data, loading, error, stopPolling }] =
    useLazyQuery(orgCredentialUsage);
  const [credential, setCredential] = useState<Secret>();
  const [errorStatus, setErrorStatus] = useState();
  const [credentialUsed, setCredentialUsed] = useState<boolean>(false);

  const createCredential = async () => {
    if (!credential && !errorStatus) {
      return createOrgCredentialMutation({
        variables: {
          organizationId: organizationId,
          name: 'Databricks Connection Credential',
          description: 'Built with the Databricks Connection Flow',
        },
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {
          if (data) {
            setCredential(data.data.createOrgCredential);
          }
        });
    }
  };
  const pollCredUsage = async () => {
    if (credential) {
      getOrgCredentialUsage({
        variables: {
          organizationId: organizationId,
          credentialKey: credential.credential.split(':')[0],
        },
        pollInterval: 2000,
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {});
    }
  };

  useEffect(() => {
    if (data?.organizationCredential?.lastSession) {
      setCredentialUsed(true);
      stopPolling();
    }
  }, [data]);

  const DatabricksSparkSqlCmds = [
    {
      title: `List your Warehouse's databases.`,
      cmd: `%sql
use catalog ${warehouse.name};`,
    },
    {
      title: `Use the example database.`,
      cmd: `%sql
show databases;`,
    },
    {
      title: `Use the example database.`,
      cmd: `%sql
use examples;`,
    },
    {
      title: `Describe example table`,
      cmd: `%sql
describe table examples.nyc_taxi_yellow;
`,
    },
    {
      title: `Create table`,
      cmd: `%sql
create table if not exists default.taxi_tip_rate_per_passenger_spark as
select 
  passenger_count,
  count(*) as trip_count,
  avg(tip_amount / fare_amount) as tip_rate
from examples.nyc_taxi_yellow
where 
    pickup_time between '2020-03-01' and '2020-03-31'
    and passenger_count > 0
    and fare_amount > 0
group by passenger_count;`,
    },
    {
      title: 'Analyze data',
      cmd: `%sql
select 
  passenger_count,  
  trip_count,
  format_number(tip_rate * 100, 2) as tip_percentage
from default.taxi_tip_rate_per_passenger_spark 
order by passenger_count;`,
    },
  ];

  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: 'Prerequisites',
        body: (
          <Box>
            <Box sx={{ width: 0.95 }}>
              <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
                <ListItem sx={{ display: 'list-item', p: 0 }}>
                  <Typography variant={'body1'}>
                    A workspace setup in region <b>{warehouse.region}</b>, link
                    to instructions:
                  </Typography>
                  <Typography sx={{ display: 'inline-flex', mr: 1, gap: 1 }}>
                    <Tabulink
                      external
                      href={`https://docs.databricks.com/en/administration-guide/workspace/quick-start.html`}
                      variant="body1"
                      rel="noopener"
                      aria-label="Databricks workspace creation"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {'Databricks workspace creation'}
                      <OpenInNewIcon
                        fontSize="small"
                        sx={{ ml: '2px', mr: '3px' }}
                      />
                    </Tabulink>
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', p: 0 }}>
                  <Typography variant={'body1'}>
                    Ensure your workspace does not have Unity catalog enabled as
                    Databricks prevents directly configuring spark catalogs in
                    Unity workspaces.
                  </Typography>
                </ListItem>
              </List>
              <Box pt={1}>
                <Typography variant={'body1'}>
                  If you already have a workspace setup without Unity catalog
                  enabled, please click <b>continue</b>
                </Typography>
              </Box>
            </Box>
          </Box>
        ),
      },
      {
        title: 'Create credential',
        disallowContinue: !credential,
        effect: createCredential,
        body: (
          <CreateCredentialStep
            organizationName={organizationName}
            connectionName={'Databricks'}
            credential={credential?.credential}
            errorStatus={errorStatus}
          />
        ),
      },
      {
        title: 'Create Databricks cluster',
        body: (
          <DatabricksCreateCluster
            warehouse={warehouse}
            credential={credential}
          />
        ),
      },
      {
        title: 'Add libraries',
        body: (
          <DatabricksClusterLibraries
            storageType={warehouse?.storageProfile?.storageType!}
          />
        ),
      },
      {
        title: 'Query example data',
        effect: pollCredUsage,
        body: (
          <>
            <Box sx={{ width: 0.5 }}>
              {!credentialUsed && (
                <Talert color="neutral">
                  Watching for connection, please perform the steps below...
                </Talert>
              )}
              {credentialUsed && (
                <Talert severity="success">
                  Successful connection detected!
                </Talert>
              )}
            </Box>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column',
                width: 0.98,
              }}
            >
              <SparkSQLTemplate
                sx={{ width: 1 }}
                cmds={DatabricksSparkSqlCmds}
              />
            </Box>
          </>
        ),
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <WizardContainer
        title={'Databricks'}
        stepsTitle={warehouse.name}
        stepsSubtitle={'Connect Databricks'}
        stepsIcon={
          <CustomIcon
            src={'/assets/img/logos/databricks.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        backButtonClick={() => {}}
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        sx={{ minHeight: '550px', width: 1, height: '98vh' }}
      />
    </Dialog>
  );
});

export function showDatabricksConnectionWizardDialog({
  warehouse,
  organizationName,
  organizationId,
}: {
  warehouse: Warehouse;
  organizationName: string;
  organizationId: string;
}) {
  return show(DatabricksConnectionWizardDialog, {
    warehouse,
    organizationName,
    organizationId,
  });
}
