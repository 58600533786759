import React, { useState } from 'react';

import { Schema, Table as GqlTable } from '../../../graphql/gen/graphql';
import { TableUpdate } from '../../../schema/events/table.update';
import UpdateChanges from './UpdateChanges';

type Props = {
  event: TableUpdate;
  currentSchema: Schema;
  previousSchema: Schema;
  table: GqlTable;
};
export default function TableUpdateSchemaSummaryView({
  event,
  currentSchema,
  previousSchema,
  table,
}: Props) {
  const sizeDelta =
    currentSchema!.fields!.length - previousSchema!.fields!.length;
  const multiple = Math.abs(sizeDelta) > 1;
  let execSummary =
    sizeDelta == 0
      ? 'Schema has been updated'
      : `${Math.abs(sizeDelta)} field${multiple ? 's' : ''} ${
          multiple ? 'have' : 'has'
        } been ${sizeDelta > 0 ? 'added' : 'removed'}`;

  return (
    <>
      <UpdateChanges key={event.id} operations={event.changes}></UpdateChanges>
      {execSummary}
    </>
  );
}
