export enum BucketCreationType {
  CloudFormation = 'cloudFormation',
  Manual = 'manual',
  Google = 'google',
  Azure = 'azure',
}

export const PolicyBucketConfigs: { [key: string]: string } = {
  ListObject: 's3:ListBucket',
  PutObject: 's3:PutObject',
  DeleteObject: 's3:DeleteObject',
  MultipartUpload: 's3:AbortMultipartUpload',
  GetObjectMetadata: 's3:GetObject',
  GetObject: 's3:GetObject',
};

export const FullBucketConfigs = {
  BucketExists: 'Bucket does not exist',
  AssumeRole: 'Cannot assume role: check "sts:AssumeRole"',
  ...PolicyBucketConfigs,
};

export type InitialBucket = {
  bucketId: string;
  bucketName: string;
  externalId: string;
  missingConfig: any;
};

export const GcsDashboardLink =
  'https://console.cloud.google.com/home/dashboard';
export const GcsServiceAccountLink =
  'https://console.cloud.google.com/iam-admin/serviceaccounts';
export const GcsWorkloadIdentityLink =
  'https://console.cloud.google.com/iam-admin/workload-identity-pools';

export const AzureAppRegistrationLink =
  'https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade';

export const AwsCreatePolicyLink = (region: string) => {
  return `https://${region}.console.aws.amazon.com/iamv2/home?region=${region}#/policies/create?step=addPermissions`;
};
export const AwsCreateRoleLink = (region: string) => {
  return `https://${region}.console.aws.amazon.com/iamv2/home?region=${region}#/roles/create`;
};
