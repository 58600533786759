import React from 'react';

import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import { Tacard } from '../../components/Card/Tacard';
import Tile, { TileProps } from '../../components/TileSelector/Tile';
import { showAutoReplicateWizard } from '../../components/Wizards/Pipelines/AutoReplicate/AutoReplicateWizardDialog';
import { showDedupeWizard } from '../../components/Wizards/Pipelines/Dedupe/DedupeWizardDialog';

export default function Services({
  organizationId,
}: {
  organizationId: string;
}) {
  const processingTiles: TileProps[] = [
    {
      id: 'auto_replicate',
      logoImgUrl: '/assets/icon/auto_replication.svg',
      logoAlt: 'Auto replication icon',
      handleButtonClick: () => {
        showAutoReplicateWizard({ organizationId }).then(() => {});
      },
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Auto Replication</Typography>
        </Box>
      ),
    },
    {
      id: 'auto_dedupe',
      logoImgUrl: '/assets/icon/dedupe.svg',
      logoAlt: 'Auto dedupe icon',
      handleButtonClick: () => {
        showDedupeWizard({ organizationId }).then(() => {});
      },
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Auto Deduplication</Typography>
        </Box>
      ),
    },
  ];

  return (
    <Tacard>
      <CardHeader title={`Services`} />
      <Box
        sx={(theme) => ({
          backgroundColor: 'midnight.one',
          borderTop: `1px solid ${theme.palette.midnight.two}`,
          paddingY: 1,
          paddingX: 2,
        })}
      >
        <Typography variant={`helperText`}>All Services</Typography>
      </Box>
      <CardContent>
        <Box>
          <Typography sx={{ marginY: 3 }} variant={`h6`}>
            Ingestion
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Typography sx={{ marginY: 3 }} variant={`h6`}>
            Processing
          </Typography>
          <Grid
            container
            justifyItems={'center'}
            spacing={5}
            columnSpacing={3}
            marginBottom={4}
          >
            {processingTiles.map((eachTileProps) => (
              <Grid key={eachTileProps.id} item xs={12} md={4}>
                <Tile key={`${eachTileProps.id}_tile`} {...eachTileProps} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Tacard>
  );
}
