import React from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  OutlinedTextFieldProps,
  Stack,
  StandardTextFieldProps,
  TextField as MuiTextField,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FieldHookConfig, useField } from 'formik';

export type TextFieldProps =
  | OutlinedTextFieldPropsExt
  | StandardTextFieldPropsExt;

interface OutlinedTextFieldPropsExt extends OutlinedTextFieldProps {
  showCopyClipboard?: boolean;
  visible?: boolean;
}
interface StandardTextFieldPropsExt extends StandardTextFieldProps {
  showCopyClipboard?: boolean;
  visible?: boolean;
}
export default function TextField({
  helperText,
  showCopyClipboard,
  visible,
  variant,
  ...props
}: TextFieldProps) {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta, helpers] = useField(props.name!);
  const error = meta.error && meta.touched;
  if (error) {
    helperText = `${meta.error} ${helperText ? `: ${helperText}` : ''}`;
  }
  const multiline = 'multiline' in props;

  return (
    <Stack
      direction="row"
      sx={{
        ...(!variant || variant === 'outlined'
          ? {
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
                ...(!multiline && { height: '48px' }),
                minHeight: '48px',
                minWidth: '176px',
              },
              '& .MuiInputLabel-root': {
                left: 10,
                fontWeight: 400,
                fontSize: '13px',
                lineHeight: '18px',
                letterSpacing: '0.25px',
              },
              '& .MuiOutlinedInput-notchedOutline legend': {
                marginLeft: 1.5,
              },
            }
          : {}),
      }}
    >
      <MuiTextField
        variant={!variant ? 'outlined' : variant}
        margin="normal"
        error={!!error}
        helperText={helperText}
        {...field}
        {...props}
        style={{
          display: visible === false ? 'none' : '',
        }}
      />
      {showCopyClipboard && (
        <IconButton
          onClick={() => navigator.clipboard.writeText(field.value)}
          title="Copy to clipboard"
          style={{
            backgroundColor: 'transparent',
            display: visible === false ? 'none' : '',
          }}
          sx={{ mb: 2 }}
        >
          <FileCopyIcon />
        </IconButton>
      )}
    </Stack>
  );
}
