import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BadgeIcon from '@mui/icons-material/Badge';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalculateIcon from '@mui/icons-material/Calculate';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DnsIcon from '@mui/icons-material/Dns';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import SettingsIcon from '@mui/icons-material/Settings';
import ShieldIcon from '@mui/icons-material/Shield';
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded';
import TagIcon from '@mui/icons-material/Tag';
import { Badge, Box, Divider, Stack } from '@mui/material';

import { KnownFeatureFlags } from '../../context/GlobalPropsContext';

import {
  toCompute,
  toSecurity,
  toDroppedTables,
  toMembers,
  toOrgSettings,
  toProfile,
  toRoles,
  toStorage,
  toWarehouses,
  toLabels,
  toUsage,
  toOrgAccess,
  toServices,
  toPipelines,
} from '../../RouteTable';
import { GlobalPropertiesContext } from '../../context';
import { DropdownMenuContent } from '../DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../DropdownMenu/DropdownMenuItem';
import { MainNavTab, MobileMainNavTab } from './MainNavTabs';

function a11yProps(index: number) {
  return {
    key: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const getNavTabs = (
  mobileNavBar: boolean,
  isSecurityAdmin: boolean,
  popoverEnter: (
    event: React.SyntheticEvent<HTMLDivElement>,
    position: number,
  ) => void,
  popoverLeave: () => void,
  orgDisplayName: string,
  pipelinesEnabled: boolean,
) => {
  if (mobileNavBar) {
    return {
      dataTab: <MobileMainNavTab label="Data" {...a11yProps(0)} />,
      connectTab: (
        <MobileMainNavTab
          label={
            <Badge
              style={{
                paddingRight: '10px',
                paddingLeft: '10px',
              }}
            >
              {'Connections'}
            </Badge>
          }
          {...a11yProps(1)}
        />
      ),

      pipelineTab: (
        <MobileMainNavTab
          {...a11yProps(2)}
          label={
            <Badge
              style={{
                paddingRight: '10px',
                paddingLeft: '10px',
              }}
            >
              {'Pipelines'}
            </Badge>
          }
        />
      ),

      orgTab: (
        <MobileMainNavTab
          label={orgDisplayName}
          {...a11yProps(pipelinesEnabled ? 3 : 2)}
        />
      ),
    };
  } else {
    return {
      dataTab: (
        <MainNavTab
          iconPosition={`start`}
          label="Data"
          {...a11yProps(0)}
          position={0}
          popoverEnter={popoverEnter}
          popoverLeave={popoverLeave}
        />
      ),
      connectTab: (
        <MainNavTab
          iconPosition={`start`}
          label={`Connections`}
          {...a11yProps(1)}
          position={1}
          popoverEnter={popoverEnter}
          popoverLeave={popoverLeave}
        />
      ),

      pipelineTab: (
        <MainNavTab
          label={`Pipelines`}
          position={2}
          popoverEnter={popoverEnter}
          popoverLeave={popoverLeave}
          {...a11yProps(2)}
        />
      ),

      orgTab: (
        <MainNavTab
          iconPosition={`start`}
          label={orgDisplayName}
          {...a11yProps(pipelinesEnabled ? 3 : 2)}
          position={pipelinesEnabled ? 3 : 2}
          popoverEnter={popoverEnter}
          popoverLeave={popoverLeave}
        />
      ),
    };
  }
};

export const getNavTabsDropdownContent = (
  orgName: string,
  isSecurityAdmin: boolean,
  isOrgAdmin: boolean,
) => {
  const { ff } = useContext(GlobalPropertiesContext);

  const navigate = useNavigate();
  return {
    dataDropdown: (
      <Box
        sx={(theme) => ({
          borderRadius: '4px',
          border: `1px solid ${theme.palette.midnight.two}`,
          minWidth: '320px',
          boxShadow: theme.shadows[6],
          mt: 1,
        })}
      >
        <Stack direction={`column`} spacing={1}>
          <DropdownMenuItem
            titleText={'Warehouses'}
            description={'Explore your data warehouses.'}
            Icon={TableViewRoundedIcon}
            highlightBackground
            onClick={() => {
              navigate(toWarehouses(orgName));
            }}
          />
          <Divider sx={{ mt: '0 !important' }} />
          <DropdownMenuItem
            titleText={'Dropped tables'}
            description={'Find and restore dropped tables.'}
            Icon={DeleteOutlineIcon}
            highlightBackground
            onClick={() => {
              navigate(toDroppedTables(orgName));
            }}
          />
        </Stack>
      </Box>
    ),
    connectionsDropdown: (
      <Box
        sx={(theme) => ({
          backgroundColor: 'controlBackground.main',
          borderRadius: '4px',
          border: `1px solid ${theme.palette.midnight.two}`,
          minWidth: '320px',
          boxShadow: theme.shadows[6],
          mt: 1,
        })}
      >
        <DropdownMenuContent
          menuItems={[
            <DropdownMenuItem
              key={'storage'}
              titleText={'Storage'}
              description={'Manage storage and bucket usage.'}
              Icon={DnsIcon}
              highlightBackground
              onClick={() => {
                navigate(toStorage(orgName));
              }}
            />,

            <DropdownMenuItem
              key={'compute'}
              titleText={'Compute'}
              description={'Configure data compute engines.'}
              Icon={CalculateIcon}
              highlightBackground
              onClick={() => {
                navigate(toCompute(orgName));
              }}
            />,
            <DropdownMenuItem
              key={'security'}
              titleText={'Security'}
              description={
                'Administrate service authentications, identity mappings, and member credentials. '
              }
              Icon={AdminPanelSettingsIcon}
              highlightBackground
              onClick={() => {
                navigate(toSecurity(orgName));
              }}
              disabled={!isSecurityAdmin}
            />,
          ]}
        ></DropdownMenuContent>
      </Box>
    ),
    orgDropdown: (
      <Box
        sx={(theme) => ({
          backgroundColor: 'controlBackground.main',
          borderRadius: '4px',
          border: `1px solid ${theme.palette.midnight.two}`,
          minWidth: '320px',
          boxShadow: theme.shadows[6],
          mt: 1,
        })}
      >
        <DropdownMenuContent
          menuItems={[
            <DropdownMenuItem
              key={'members'}
              titleText={'Members'}
              description={'Invite and manage users.'}
              Icon={PeopleAltIcon}
              highlightBackground
              onClick={() => {
                navigate(toMembers(orgName));
              }}
            />,

            <DropdownMenuItem
              key={'roles'}
              titleText={'Roles'}
              description={
                'Create and manage the roles which can be assigned access controls.'
              }
              Icon={BadgeIcon}
              highlightBackground
              onClick={() => {
                navigate(toRoles(orgName));
              }}
            />,
            <DropdownMenuItem
              key={'labels'}
              titleText={'Labels'}
              description={'Create and manage labels.'}
              Icon={TagIcon}
              highlightBackground
              onClick={() => {
                navigate(toLabels(orgName));
              }}
            />,
            <DropdownMenuItem
              key={'access-controls'}
              titleText={'Access Control'}
              description={'Create and manage organization privileges.'}
              Icon={ShieldIcon}
              highlightBackground
              onClick={() => {
                navigate(toOrgAccess(orgName));
              }}
              disabled={!isSecurityAdmin}
            />,
            <DropdownMenuItem
              key={'settings'}
              titleText={'Settings'}
              description={'Administrate organization-wide settings.'}
              Icon={SettingsIcon}
              highlightBackground
              onClick={() => {
                navigate(toOrgSettings(orgName));
              }}
              disabled={!isOrgAdmin}
            />,
            <DropdownMenuItem
              key={'myProfiles'}
              titleText={'My profile'}
              description={'Account info, roles, and credentials.'}
              Icon={PersonIcon}
              highlightBackground
              onClick={() => {
                navigate(toProfile(orgName));
              }}
            />,
            ...(ff(KnownFeatureFlags.USAGE_DATA_PAGE) && isSecurityAdmin
              ? [
                  <DropdownMenuItem
                    key={'usageStats'}
                    titleText={'Usage Stats'}
                    description={'Recent platform usage information.'}
                    Icon={BarChartIcon}
                    highlightBackground
                    onClick={() => {
                      navigate(toUsage(orgName));
                    }}
                  />,
                ]
              : []),
          ]}
        />
      </Box>
    ),
    pipelinesDropdown: (
      <Box
        sx={(theme) => ({
          borderRadius: '4px',
          border: `1px solid ${theme.palette.midnight.two}`,
          minWidth: '320px',
          boxShadow: theme.shadows[6],
          mt: 1,
        })}
      >
        <Stack direction={`column`} spacing={1}>
          <DropdownMenuItem
            titleText={'Services'}
            description={'Explore ingestion and processing services.'}
            Icon={RoomServiceIcon}
            highlightBackground
            onClick={() => {
              navigate(toServices(orgName));
            }}
          />
          <Divider sx={{ mt: '0 !important' }} />
          <DropdownMenuItem
            titleText={'Pipelines'}
            description={'Create and manage data pipelines.'}
            Icon={LinearScaleIcon}
            highlightBackground
            onClick={() => {
              navigate(toPipelines(orgName));
            }}
          />
        </Stack>
      </Box>
    ),
  };
};
