import React from 'react';

import { AltRoute } from '@mui/icons-material';
import { Box, BoxProps, Typography } from '@mui/material';

interface TagDisplayProps extends BoxProps {
  name: string;
  maxWidth?: number;
}

export function BranchDisplay({ name, maxWidth, ...props }: TagDisplayProps) {
  return (
    <Box
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: name === 'main' ? 'brandBlue.main' : 'midnight.six',
        display: 'flex',
        height: '24px',
        paddingRight: '12px',
        borderRadius: '4px',
        maxWidth: maxWidth || '650px',
      }}
    >
      <Box
        sx={{
          height: '100%',
          minWidth: '24px',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          backgroundColor: name === 'main' ? 'brandBlue.main' : 'midnight.six',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AltRoute fontSize={`small`} sx={{ color: 'white' }} />
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          overflow: 'hidden',
        }}
      >
        <Typography
          variant={`subtitle2`}
          sx={{
            color: 'white',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
}
