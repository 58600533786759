import * as React from 'react';

import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  gridPaginationRowRangeSelector,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import {
  GridCellParams,
  gridPageCountSelector,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { includes } from 'lodash';

import { Talert } from '../Alert/Talert';

export interface propertyType {
  key: string;
  value: any;
}

export interface droppedByMemberType {
  id: string;
  email: string;
  displayName: string;
  properties?: [propertyType];
}
export interface droppedByType {
  droppedByEmail: string;
  droppedByMember: droppedByMemberType;
}

export interface formerLocationType {
  warehouseId: string;
  warehouseName: string;
  databaseName: string;
  databaseId?: string;
}

export interface DatabaseOptionType {
  id: string;
  name: string;
  privileges: [string];
}
export const droppedByComparator = (v1: droppedByType, v2: droppedByType) =>
  v1.droppedByMember.displayName
    .toLowerCase()
    .localeCompare(v2.droppedByMember.displayName.toLowerCase());

export const formerLocationComparator = (
  v1: formerLocationType,
  v2: formerLocationType,
) =>
  v1.warehouseName.toLowerCase().localeCompare(v2.warehouseName.toLowerCase());

export const getApplyFilterFnFormerLocation = (value: any) => {
  return ({ value: columnValue }: GridCellParams): boolean => {
    return columnValue != null
      ? includes(columnValue.warehouseName, value) ||
          includes(columnValue.databaseName, value)
      : false;
  };
};

export const getApplyFilterFnDroppedBy = (value: any) => {
  return ({ value: columnValue }: GridCellParams): boolean => {
    return columnValue != null && columnValue.droppedByMember != null
      ? includes(
          columnValue?.droppedByMember?.displayName.toLowerCase(),
          value.toLowerCase(),
        )
      : false;
  };
};

export const CustomPagination = ({
  apiRef,
  pageSize,
}: {
  apiRef: any;
  pageSize: number;
}) => {
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const handleChange = (event: SelectChangeEvent<any>) => {
    apiRef.current.setPageSize(event.target.value);
  };

  return (
    <Box display={`flex`} alignItems={`center`} sx={{ pr: 2 }}>
      <FormControl
        sx={{
          m: 1,
          p: 0,
          mr: 4,
          minWidth: 80,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography variant={`inputLabel`} sx={{ mr: 1 }}>
          Tables per page:
        </Typography>
        <Select size="small" value={pageSize} onChange={handleChange}>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
        </Select>
      </FormControl>
      <Pagination
        count={pageCount}
        showFirstButton
        showLastButton
        variant="outlined"
        color="primary"
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{
              display: `${item.type === 'page' ? `none` : `inline-flex`}`,
            }}
          />
        )}
      />
    </Box>
  );
};

export const QuickSearchToolbar = ({
  visibleRowCount,
  apiRef,
  pageSize,
}: {
  visibleRowCount: number;
  apiRef: any;
  pageSize: number;
}) => {
  //@ts-ignore
  const range = useGridSelector(apiRef, gridPaginationRowRangeSelector);
  return (
    <>
      <Box
        sx={(theme) => ({
          mb: 2,
          position: 'absolute',
          bottom: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        })}
      >
        <GridToolbarQuickFilter
          sx={(theme) => ({
            width: 340,
            height: 48,
            p: 1,
            backgroundColor: 'dusk.half',
            border: `1px solid ${theme.palette.midnight.two}`,
            borderRadius: '4px',
            '& .MuiInput-root::after, & .MuiInput-root::before, & .MuiInput-root:hover:not(.Mui-disabled):before':
              {
                borderBottom: 'none',
              },
          })}
          placeholder={`Search dropped tables`}
        />
        <Talert
          severity={`info`}
          sx={(theme: any) => ({
            [theme.breakpoints.down(790)]: { ml: 2, padding: '8px 8px' },
            [theme.breakpoints.down('sm')]: {
              mt: 2,
              ml: 0,
              padding: '10px 14px',
            },
          })}
        >
          <Typography variant={`helperText`}>
            Dropped tables can be restored for up to 7 days
          </Typography>
        </Talert>
      </Box>
      <Box
        display={`flex`}
        alignItems={`center`}
        justifyContent={`space-between`}
        sx={{ pl: 2 }}
      >
        <Typography variant={`inputLabel`}>{`Showing ${
          range ? range.firstRowIndex + 1 : 0
        } - ${
          range ? range.lastRowIndex + 1 : 0
        } Tables of ${visibleRowCount}`}</Typography>
        <CustomPagination apiRef={apiRef} pageSize={pageSize} />
      </Box>
    </>
  );
};
