export const getDangerZoneItems = (databaseName: string) => {
  return [
    {
      title: 'Drop database',
      description:
        "This will delete this database from Tabular and mark all of it's table data and metadata files for deletion.",
      buttonText: 'Drop this database',
      confirmModal: 'TypeDeleteDialog',
      modalContent: {
        title: `Drop ${databaseName} database`,
        confirmText: 'Drop database',
        recoveryWarningText: `This will delete ${databaseName} database from Tabular and mark all of it's table data and metadata files for deletion.`,
        privilegeCheck: true,
      },
      mutationName: 'dropDatabase',
      privilegeCheck: true,
    },
  ];
};
