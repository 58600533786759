import React from 'react';

import { ChipProps, Chip } from '@mui/material';

export enum ChipColors {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Neutral = 'neutral',
  Info = 'info',
}

export interface TaChipProps extends ChipProps {
  type: ChipColors;
  showBorder?: boolean;
}

const successStyles = (showBorder: boolean) => ({
  backgroundColor: 'aurora.one',
  color: 'aurora.seven',
  '&:hover': {
    backgroundColor: 'aurora.two',
  },
  '& .MuiChip-icon': {
    marginRight: '4px',
    marginLeft: 0,
    color: 'aurora.seven',
  },
  ...(showBorder
    ? { borderWidth: 1, borderStyle: 'solid', borderColor: 'aurora.two' }
    : {}),
});
const errorStyles = (showBorder: boolean) => ({
  backgroundColor: 'sunset.one',
  color: 'sunset.seven',
  '&:hover': {
    backgroundColor: 'sunset.two',
  },
  '& .MuiChip-icon': {
    marginRight: '4px',
    marginLeft: 0,
    color: 'sunset.seven',
  },
  ...(showBorder
    ? { borderWidth: 1, borderStyle: 'solid', borderColor: 'sunset.two' }
    : {}),
});
const warningStyles = (showBorder: boolean) => ({
  backgroundColor: 'sunrise.one',
  color: 'sunrise.seven',
  '&:hover': {
    backgroundColor: 'sunrise.two',
  },
  '& .MuiChip-icon': {
    marginRight: '4px',
    marginLeft: 0,
    color: 'sunrise.seven',
  },
  ...(showBorder
    ? { borderWidth: 1, borderStyle: 'solid', borderColor: 'sunrise.two' }
    : {}),
});
const neutralStyles = (showBorder: boolean) => ({
  backgroundColor: 'midnight.one',
  color: 'midnight.seven',
  '&:active': {
    backgroundColor: 'midnight.three',
  },
  '&:hover': {
    backgroundColor: 'midnight.two',
  },
  '& .MuiChip-icon': {
    marginRight: '4px',
    marginLeft: 0,
    color: 'midnight.seven',
  },
  ...(showBorder
    ? { borderWidth: 1, borderStyle: 'solid', borderColor: 'midnight.two' }
    : {}),
});

const infoStyles = (showBorder: boolean) => ({
  backgroundColor: 'sky.one',
  color: 'midnight.seven',
  '&:active': {
    backgroundColor: 'sky.three',
  },
  '&:hover': {
    backgroundColor: 'sky.two',
  },
  '& .MuiChip-icon': {
    marginRight: '4px',
    marginLeft: 0,
    color: 'midnight.seven',
  },
  ...(showBorder
    ? { borderWidth: 1, borderStyle: 'solid', borderColor: 'sky.two' }
    : {}),
});
const getTypeStyles = (type: ChipColors, showBorder: boolean) => {
  switch (type) {
    case 'success':
      return successStyles(showBorder);
    case 'error':
      return errorStyles(showBorder);
    case 'warning':
      return warningStyles(showBorder);
    case 'neutral':
      return neutralStyles(showBorder);
    case 'info':
      return infoStyles(showBorder);
    default:
      return neutralStyles(showBorder);
  }
};

export const Tachip = ({
  type,
  showBorder,
  ...props
}: TaChipProps): JSX.Element => {
  const styles = getTypeStyles(type, showBorder ?? false);
  return (
    <>
      <Chip
        {...props}
        sx={{
          ...styles,
          ...props.sx,
          fontSize: '13px',
          lineHeight: '16px',
          fontWeight: 600,
          letterSpacing: '0.5px',
          borderRadius: 1,
          padding: '8px 12px',
        }}
      />
    </>
  );
};
