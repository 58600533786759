import React from 'react';

import { AddOutlined } from '@mui/icons-material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import {
  Box,
  CardHeader,
  IconButton,
  Stack,
  TablePagination,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  gridPaginationSelector,
  GridToolbarQuickFilter,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import { AddNewResource } from '../Button/AddNewResource';
import { EditResource } from '../Button/EditResource';
import { Refresh } from '../Button/Refresh';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import { CustomIcon } from '../Icons/CustomIcon';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

export function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page + 1);
  };
  const nextDisabled = page >= Math.ceil(count / rowsPerPage) - 1;
  const backDisabled = page === 0;
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={backDisabled}
        aria-label="previous page"
      >
        <ArrowBackOutlinedIcon
          sx={{
            color: backDisabled ? 'midnight.three' : 'midnight.nine',
            fontSize: '16px',
          }}
        />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={nextDisabled}
        aria-label="next page"
      >
        <ArrowForwardOutlinedIcon
          sx={{
            color: nextDisabled ? 'midnight.three' : 'midnight.nine',
            fontSize: '16px',
          }}
        />
      </IconButton>
    </Box>
  );
}
export const CustomPaging = ({
  apiRef,
  pageSize,
  rowsPerPageOptions,
  setPageSize,
  rowCount,
  setPage,
}: {
  apiRef: any;
  pageSize: number;
  rowsPerPageOptions: any;
  setPageSize: (page: number) => void;
  rowCount: number;
  setPage?: any;
}) => {
  const page = apiRef?.current?.state?.pagination?.page || 0;
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage && setPage(newPage);
    apiRef.current.setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage && setPage(0);
    apiRef.current.setPage(0);
  };

  return (
    <Box display={`flex`} alignItems={`center`} sx={{ pr: 2 }}>
      <TablePagination
        labelRowsPerPage={'Show per page:'}
        component="div"
        ActionsComponent={TablePaginationActions}
        count={rowCount}
        page={rowCount && page}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={(theme) => ({
          '& .MuiTablePagination-displayedRows': {
            display: 'none',
          },
          '& .MuiTablePagination-root': {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
          },
          '& .MuiSelect-standard .MuiSelect': {
            display: 'none',
          },
          '& .MuiTablePagination-selectLabel': {
            color: 'midnight.seven',
          },
          '& .MuiTablePagination-select': {
            color: 'midnight.nine',
          },
          '& .MuiSelect-iconStandard': {
            color: 'midnight.nine',
          },
          '& .MuiInputBase-root': {
            marginRight: 0,
          },
        })}
      />
    </Box>
  );
};

export type MenuContentType = {
  title: string;
  action: () => void;
  disabled?: boolean;
};

export type DashboardContentType = {
  dashboardMenuContent: JSX.Element;
  title: string;
  icon: JSX.Element;
};
type DataGridToolBarType = {
  apiRef: any;
  pageSize: number;
  setPageSize: (page: number) => void;
  rowsPerPageOptions?: number[];
  headerName: JSX.Element;
  cloudProviderIcon?: string;
  icon?: JSX.Element;
  refreshResourceContent: MenuContentType;
  addResourceContent?: MenuContentType;
  addResourceDashboardContent?: DashboardContentType;
  editResourceContent?: MenuContentType;
  secondRowContent?: JSX.Element;
  rowCount: number;
  dashboardMenuContent?: JSX.Element;
  pageAtNumber: number;
  setPage?: () => void;
  showRange?: boolean;
  customButton1?: JSX.Element;
  customButton2?: JSX.Element;
  showQuickFilter?: boolean;
};
export const DataGridToolBar = ({
  apiRef,
  pageSize,
  setPageSize,
  rowsPerPageOptions,
  headerName,
  cloudProviderIcon,
  icon,
  refreshResourceContent,
  addResourceContent,
  addResourceDashboardContent,
  editResourceContent,
  secondRowContent,
  dashboardMenuContent,
  rowCount,
  pageAtNumber,
  showRange = true,
  setPage,
  customButton1,
  customButton2,
  showQuickFilter,
}: DataGridToolBarType) => {
  const paginationInfo = useGridSelector(apiRef, gridPaginationSelector);

  const startingIndex =
    rowCount < 1 ? 0 : paginationInfo.page * paginationInfo.pageSize + 1;
  const endingIndex =
    startingIndex + paginationInfo.pageSize - 1 > rowCount
      ? rowCount
      : startingIndex + paginationInfo.pageSize - 1;

  const mobileToolBar = useMediaQuery('(max-width:500px)');

  return (
    <>
      <CardHeader
        sx={(theme) => ({
          padding: 0,
          margin: 'none',
          backgroundColor: 'midnight.one',
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
        title={
          <Box
            display={'flex'}
            flexDirection={mobileToolBar ? 'column' : 'row'}
            justifyContent={mobileToolBar ? 'flex-start' : 'space-between'}
            paddingTop={mobileToolBar ? 1 : 0}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {cloudProviderIcon && !mobileToolBar && (
                <Box
                  sx={{
                    width: '74px',
                    height: '50px',
                    backgroundColor: 'white',
                    padding: 2,
                    borderTopLeftRadius: '2px',
                  }}
                >
                  <CustomIcon
                    src={cloudProviderIcon}
                    sx={{
                      height: '24px',
                      width: '40px',
                    }}
                  />
                </Box>
              )}
              {headerName && headerName}
              <Box sx={{ mx: 1, display: 'flex', alignItems: 'center' }}>
                {icon}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: mobileToolBar ? 'flex-start' : 'flex-end',
                px: 2,
                py: 1,
              }}
            >
              {showQuickFilter && (
                <Box
                  pr={2}
                  display={'flex'}
                  alignContent={'center'}
                  paddingTop={mobileToolBar ? 1 : 0}
                >
                  <GridToolbarQuickFilter
                    variant={'outlined'}
                    sx={(theme) => ({
                      width: '100%',
                      maxWidth: '300px',
                      justifyContent: 'center',
                      py: 0,
                      minWidth: '130px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '24px',
                        backgroundColor: 'white',
                        height: '28px',
                        fontSize: theme.typography.pxToRem(14),
                        fontStyle: 'italic',
                        cursor: 'pointer',
                      },
                    })}
                    quickFilterParser={(searchInput: string) =>
                      searchInput
                        .split(',')
                        .map((value) => value.trim())
                        .filter((value) => value !== '')
                    }
                  />
                </Box>
              )}

              {refreshResourceContent && (
                <Refresh
                  refreshList={refreshResourceContent.action}
                  title={refreshResourceContent.title}
                />
              )}
              {addResourceContent && (
                <AddNewResource
                  createResource={addResourceContent.action}
                  title={addResourceContent.title}
                  disabled={addResourceContent?.disabled}
                />
              )}
              {customButton1 && <>{customButton1}</>}
              {customButton2 && <>{customButton2}</>}
              {editResourceContent && (
                <EditResource
                  editResource={editResourceContent.action}
                  title={editResourceContent.title}
                  disabled={editResourceContent.disabled}
                />
              )}
              {addResourceDashboardContent && (
                <DashboardMenu
                  dashboardDropdownContent={
                    addResourceDashboardContent.dashboardMenuContent
                  }
                  title={addResourceDashboardContent.title}
                  Icon={AddOutlined}
                />
              )}
              {dashboardMenuContent && (
                <DashboardMenu
                  dashboardDropdownContent={dashboardMenuContent}
                />
              )}
            </Box>
          </Box>
        }
      />
      {secondRowContent && (
        <Box
          display="flex"
          flexDirection={mobileToolBar ? 'column' : 'row'}
          justifyContent={mobileToolBar ? 'flex-start' : 'space-between'}
          padding={0}
          sx={{
            paddingY: 1,
            paddingX: 2,
            backgroundColor: 'midnight.one',
          }}
        >
          {secondRowContent}
        </Box>
      )}

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        sx={(theme) => ({
          ...(showRange
            ? {
                borderBottom: `1px solid ${theme.palette.midnight.two}`,
              }
            : { borderBottom: 'none' }),
        })}
      >
        {showRange && (
          <Box display={'flex'} alignItems={'center'}>
            <Typography
              sx={{ p: 2 }}
              variant={`inputLabel`}
            >{`Showing ${startingIndex} - ${endingIndex} of ${
              rowCount || 0
            }`}</Typography>
          </Box>
        )}
        {rowCount > pageAtNumber && (
          <CustomPaging
            apiRef={apiRef}
            pageSize={pageSize}
            setPageSize={setPageSize}
            rowCount={rowCount}
            rowsPerPageOptions={rowsPerPageOptions || [10, 25, 50, 100]}
            setPage={setPage}
          />
        )}
      </Box>
    </>
  );
};
