import React from 'react';

import { Table } from '../../../graphql/gen/graphql';
import { TableCreate } from '../../../schema/events/table.create';
import { Verbosity } from '../TableEventsView';
import TableCreateDetailedView from './TableCreateDetailedView';

type Props = {
  event: TableCreate;
  table: Table;
  verbosity?: Verbosity;
};
export default function TableCreateView({
  event,
  table,
  verbosity = Verbosity.Summary,
}: Props) {
  const currentSchema = table?.metadata?.schemas?.find(
    (schema) => schema?.schema_id == 0,
  );
  if (!currentSchema) {
    return <>Current schema is null.</>;
  }
  switch (verbosity) {
    case Verbosity.Summary:
      return <div>Table Created</div>;
    case Verbosity.Detailed:
      return (
        <TableCreateDetailedView
          table={table}
          event={event}
          currentSchema={currentSchema}
        ></TableCreateDetailedView>
      );
    default:
      return null;
  }
}
