import React, { SyntheticEvent } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  AutocompleteRenderInputParams,
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Field } from 'formik';
import { Autocomplete, RadioGroup } from 'formik-mui';
import { isEmpty, remove, some } from 'lodash';

import { SearchRoleResult } from '../../graphql/gen/graphql';
import { Talert } from '../Alert/Talert';
import { Tacard } from '../Card/Tacard';
import { StyledSwitch as Switch } from '../Forms/Switch';
import SectionHeader from '../SectionHeader/SectionHeader';

type ColumnMaskingPolicyProps = {
  setFieldValue: any;
  values: any;
  maskingEnabled: boolean;
  setMaskingEnabled: any;
  isSubmitting: boolean;
  loading: boolean;
  roleOptions: SearchRoleResult[];
  highlighted: any;
  setHighlighted: any;
  handleScroll: any;
  debouncedSearchChange: any;
  showMemberCount: boolean;
  userCanEditPolicy: boolean;
};

export function ColumnMaskingPolicy({
  setFieldValue,
  values,
  maskingEnabled,
  setMaskingEnabled,
  isSubmitting,
  loading,
  roleOptions,
  highlighted,
  setHighlighted,
  handleScroll,
  debouncedSearchChange,
  showMemberCount,
  userCanEditPolicy,
}: ColumnMaskingPolicyProps) {
  const maskingBehaviorDisabled =
    !userCanEditPolicy || isSubmitting || !values.mode.enabled;
  return (
    <Stack direction="column" spacing={2}>
      <Box>
        <Talert severity={`info`}>
          ADMIN privilege required to set policy.
        </Talert>
      </Box>
      <Box>
        <Switch
          edge="end"
          onChange={() => {
            setFieldValue('mode.enabled', !values.mode.enabled);
            setMaskingEnabled(!maskingEnabled);
          }}
          checked={values.mode.enabled}
          disabled={!userCanEditPolicy}
          switchlabel={
            <Stack direction={'column'} marginLeft={1}>
              <Typography variant={`inputLabel`} fontWeight={600}>
                Column masking (Preview)
              </Typography>
              <Typography variant={`helperText`}>
                NULL or hide field values
              </Typography>
            </Stack>
          }
        />
      </Box>

      <Box sx={{ backgroundColor: 'midnight.half', p: 2 }}>
        <Stack direction={`column`} spacing={2}>
          <Typography
            variant={`body1`}
            sx={{
              color: maskingBehaviorDisabled
                ? 'rgba(0, 0, 0, 0.38)'
                : 'midnight.nine',
            }}
          >
            Which masking behavior should be used on columns with this label
            applied?
          </Typography>
          <Field component={RadioGroup} name="mode.value">
            <FormControlLabel
              value="NULL"
              control={<Radio disabled={maskingBehaviorDisabled} />}
              label={
                <Stack direction={`column`}>
                  <Typography variant={`inputLabel`} fontWeight={600}>
                    NULL values
                  </Typography>
                  <Typography variant={`helperText`}>
                    Show the column in query results and allow in query filters
                    but make the value NULL for all references.
                  </Typography>
                </Stack>
              }
              disabled={maskingBehaviorDisabled}
            />
            <FormControlLabel
              value="HIDE"
              control={<Radio disabled={maskingBehaviorDisabled} />}
              label={
                <Stack direction={`column`}>
                  <Typography variant={`inputLabel`} fontWeight={600}>
                    Hide column
                  </Typography>
                  <Typography variant={`helperText`}>
                    The column is not queryable and the value is completely
                    hidden from the table schema.
                  </Typography>
                </Stack>
              }
              disabled={maskingBehaviorDisabled}
            />
          </Field>
        </Stack>
        <Tacard sx={{ mt: 4 }}>
          <SectionHeader>
            <Stack direction={`column`}>
              <Typography
                variant={`subtitle1`}
                sx={{
                  color: maskingBehaviorDisabled
                    ? 'rgba(0, 0, 0, 0.38)'
                    : 'midnight.nine',
                }}
              >
                Roles exempt from this policy
              </Typography>
              <Typography
                variant={`helperText`}
                sx={{
                  color: maskingBehaviorDisabled
                    ? 'rgba(0, 0, 0, 0.38)'
                    : 'midnight.nine',
                }}
              >
                Roles with UPDATE on the table are also exempt from this
                behavior.
              </Typography>
            </Stack>
          </SectionHeader>
          {isEmpty(values.exemptRoles) ? (
            <Box
              sx={(theme) => ({
                background: theme.palette.controlBackground.lakeWaterGradient,
                height: 80,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '600px',
              })}
            >
              <Typography
                variant={`helperText`}
                sx={{
                  color: maskingBehaviorDisabled
                    ? 'rgba(0, 0, 0, 0.38)'
                    : 'midnight.nine',
                }}
              >
                To add roles, click the roles dropdown below.
              </Typography>
            </Box>
          ) : (
            <List
              sx={{
                px: 1,
                maxHeight: 300,
                minWidth: 600,
                overflowY: 'auto',
              }}
            >
              {values.exemptRoles?.map((role: SearchRoleResult) => (
                <ListItem key={role.roleId}>
                  <Box flexGrow={1} px={2}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: maskingBehaviorDisabled
                            ? 'rgba(0, 0, 0, 0.38)'
                            : 'midnight.nine',
                        }}
                      >
                        {role.roleName}
                      </Typography>
                      {showMemberCount && (
                        <Typography
                          variant="helperText"
                          sx={{
                            color: maskingBehaviorDisabled
                              ? 'rgba(0, 0, 0, 0.38)'
                              : 'midnight.nine',
                          }}
                        >
                          {role.roleName === 'EVERYONE'
                            ? 'All members'
                            : `${role.memberCount} ${
                                role.memberCount === 1 ? 'Member' : 'Members'
                              }`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={maskingBehaviorDisabled}
                      onClick={() =>
                        setFieldValue(
                          'exemptRoles',
                          remove(
                            values.exemptRoles,
                            (item: SearchRoleResult) =>
                              item.roleId !== role.roleId,
                          ),
                        )
                      }
                    >
                      <CloseIcon
                        sx={{
                          color: maskingBehaviorDisabled
                            ? 'rgba(0, 0, 0, 0.38)'
                            : 'sunset.seven',
                        }}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </Tacard>
        <Box marginY={2}>
          <Field
            component={Autocomplete}
            multiple
            name={`exemptRoles`}
            disableClearable
            disabled={maskingBehaviorDisabled}
            getOptionLabel={(option: SearchRoleResult) => option.roleName}
            loading={loading}
            options={
              roleOptions
                ? roleOptions.filter(
                    (option) =>
                      !some(
                        values.exemptRoles,
                        (role: SearchRoleResult) =>
                          role.roleId === option?.roleId,
                      ),
                  )
                : []
            }
            noOptionsText={`No roles`}
            renderTags={() => null}
            onInputChange={(event: SyntheticEvent) => {
              //@ts-ignore
              debouncedSearchChange(event);
            }}
            onHighlightChange={(
              event: SyntheticEvent,
              option: SearchRoleResult,
              reason: any,
            ) => {
              if (reason === 'keyboard') {
                setHighlighted(option?.roleId || '');
              }
            }}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MuiTextField
                {...params}
                label="Add roles"
                fullWidth
                disabled={maskingBehaviorDisabled}
                onKeyDown={(event: any) => {
                  if (event.key === 'Backspace') {
                    event.stopPropagation();
                  }
                }}
              />
            )}
            ListboxProps={{
              onScroll: handleScroll,
              role: 'list-box',
            }}
            renderOption={(props: any, option: SearchRoleResult) => {
              return (
                <li {...props}>
                  <ListItemButton
                    sx={{
                      backgroundColor:
                        highlighted === option.roleId ? 'sky.two' : 'inherit',
                    }}
                  >
                    <ListItemText sx={{ wordBreak: 'break-word' }}>
                      {option?.roleName}
                    </ListItemText>
                  </ListItemButton>
                </li>
              );
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
}
