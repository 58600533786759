import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

// @ts-ignore
const isOverflowHidden = (el) => getComputedStyle(el, 'overflow') === 'hidden';
const getComputedStyle = (el: Element, property: any) => {
  // @ts-ignore
  const computedStyle = window.getComputedStyle
    ? // @ts-ignore
      document.defaultView.getComputedStyle(el, null)
    : // @ts-ignore
      el.currentStyle;

  return property ? computedStyle[property] : computedStyle;
};
// @ts-ignore
const isOnDocument: boolean = (el) => {
  while ((el = el.parentNode)) {
    if (el == document) return true;
  }
  return false;
};
// @ts-ignore
export const isVisibleDueToOverflow = (el, parentEl) => {
  if (!el) return false;
  const elPositioning = getPositioning(el.getBoundingClientRect());

  while ((el = el.parentNode)) {
    if (el.nodeType !== 9 && isOverflowHidden(el)) {
      const parentElPositioning = getPositioning(el.getBoundingClientRect());

      const isElInsideParentRectX =
        elPositioning.startX >= parentElPositioning.startX &&
        elPositioning.endX <= parentElPositioning.endX;
      const isElInsideParentRectY =
        elPositioning.startY >= parentElPositioning.startY &&
        elPositioning.endY <= parentElPositioning.endY;

      if (!isElInsideParentRectX || !isElInsideParentRectY) return false;
    }
  }

  return true;
};
// @ts-ignore
const getPositioning = ({ x, width, y, height }) => ({
  startX: parseInt(x),
  endX: parseInt(x) + parseInt(width),
  startY: parseInt(y),
  endY: parseInt(y) + parseInt(height),
});

// @ts-ignore
export function isVisible(el) {
  // @ts-ignore
  if (!isOnDocument(el)) return false;

  const isHiddenDueToOpacity = getComputedStyle(el, 'opacity') === '0';
  const isHiddenDueToDisplay = getComputedStyle(el, 'display') === 'none';
  const isHiddenDueToVisibility =
    getComputedStyle(el, 'visibility') === 'hidden';
  if (isHiddenDueToOpacity || isHiddenDueToDisplay || isHiddenDueToVisibility)
    return false;
  // @ts-ignore
  return isVisibleDueToOverflow(el);
}
// @ts-ignore
Element.prototype.isVisible = function () {
  return isVisible(this);
};

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export interface DynamicBreakpoint {
  x: number;
  isVisible: boolean;
}

export interface DynamicBreakpoints {
  currentWindowSize: number[];
  breakpoints: DynamicBreakpoint[];
}
export function useDynamicBreakpoints(
  targetElement: React.MutableRefObject<HTMLDivElement | undefined>,
) {
  const breakpointRef = useRef<DynamicBreakpoint[]>([]);
  const [autoBreakpoints, setAutoBreakpoints] = useState<DynamicBreakpoints>({
    currentWindowSize: [0, 0],
    breakpoints: breakpointRef.current,
  });
  const updateSize = useCallback(() => {
    const winX = window.innerWidth;
    const winY = window.innerHeight;
    const isVisible = isVisibleDueToOverflow(targetElement.current, null);
    const lastBreakpoint =
      breakpointRef.current.length > 0 ? breakpointRef.current[0] : undefined;

    if (winX === 0) return;

    if (isVisible) {
      if (lastBreakpoint !== undefined) {
        if (winX < lastBreakpoint.x && !lastBreakpoint.isVisible) {
          breakpointRef.current = [
            { x: winX, isVisible },
            ...breakpointRef.current,
          ];
          setAutoBreakpoints({
            currentWindowSize: [winX, winY],
            breakpoints: breakpointRef.current,
          });
        } else if (winX > lastBreakpoint.x) {
          breakpointRef.current = [...breakpointRef.current.slice(1)];
          setAutoBreakpoints({
            currentWindowSize: [winX, winY],
            breakpoints: breakpointRef.current,
          });
        }
      }
    } else {
      //hidden

      if (lastBreakpoint !== undefined) {
        if (winX < lastBreakpoint.x && lastBreakpoint.isVisible) {
          breakpointRef.current = [
            { x: winX, isVisible },
            ...breakpointRef.current,
          ];
          setAutoBreakpoints({
            currentWindowSize: [winX, winY],
            breakpoints: breakpointRef.current,
          });
        }
      } else {
        breakpointRef.current = [
          { x: winX, isVisible: isVisible },
          ...breakpointRef.current,
        ];
        setAutoBreakpoints({
          currentWindowSize: [winX, winY],
          breakpoints: breakpointRef.current,
        });
      }
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [autoBreakpoints]);
  return autoBreakpoints;
}
