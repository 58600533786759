import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import { AddOutlined, RefreshOutlined } from '@mui/icons-material';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import RestoreIcon from '@mui/icons-material/Restore';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import {
  Box,
  Link,
  Popper,
  Skeleton,
  Snackbar,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGridPro,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { isEmpty, sumBy } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import {
  KnownFeatureFlags,
  MainTabGroup,
} from '../../context/GlobalPropsContext';

import {
  toDatabaseRoot,
  toDroppedTables,
  toTableRoot,
  toWarehouseRoot,
} from '../../RouteTable';
import { DatabaseAccessControl } from '../../components/AccessControl/DatabaseAccessControl';
import { Talert } from '../../components/Alert/Talert';
import { Tacard } from '../../components/Card/Tacard';
import { getImgSrcForStorageType } from '../../components/CreateStorageBucket/ValidationStatusHelpers';
import DataGridDashboard from '../../components/DataGridDashboard/DataGridDashboard';
import {
  CustomPaging,
  DataGridToolBar,
} from '../../components/DataGridToolbar/DataGridToolbar';
import DatabaseSettings from '../../components/DatabaseSettings/DatabaseSettings';
import { getDangerZoneItems } from '../../components/DatabaseSettings/helpers';
import { DropdownMenuContent } from '../../components/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenuItem';
import NotFound from '../../components/Errors/NotFound';
import OrgAccessDenied from '../../components/Errors/OrgAccessDenied';
import PageAccessDenied from '../../components/Errors/PageAccessDenied';
import { CustomIcon } from '../../components/Icons/CustomIcon';
import TabLayout from '../../components/Layouts/TabLayout';
import { showChooseTableCreationSourceDialog } from '../../components/Modals/ChooseTableSourceDialog';
import {
  CreateTableOption,
  showCreateTableOptionsDialog,
} from '../../components/Modals/CreateTableOptionsDialog';
import {
  showCreateCDCPipelineDialog,
  showCreateTableDialog,
  showCreateTableLikeDialog,
  showCreateTableLoadDialog,
} from '../../components/Modals/EditTableDialog';
import showRegisterTableDialog from '../../components/Modals/RegisterTableDialog';
import { showTypeDeleteDialog } from '../../components/Modals/TypeDeleteDialog';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { CommitsPopover } from '../../components/Popovers/CommitsPopover';
import { CompactionResultsPopover } from '../../components/Popovers/CompactionResultsPopover';
import { LastUpdateModifiedByPopover } from '../../components/Popovers/LastUpdateModifiedByPopover';
import { NamePopover } from '../../components/Popovers/NamePopover';
import { TrendPopover } from '../../components/Popovers/TrendPopover';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import { UserTableIntent } from '../../components/TableEdit/ChooseTableSource';
import { GlobalPropertiesContext } from '../../context';
import { useAuth } from '../../context/auth-context';
import {
  DatabaseDashboardCardsQuery,
  databaseQuery,
  deleteDatabase,
  listTablesQuery,
} from '../../graphql/database';
import {
  IcebergContentType,
  StorageType,
  Table,
} from '../../graphql/gen/graphql';
import { compactNumberFormatter } from '../../utils/numbers';
import { relativeTimeAutoFormat } from '../../utils/time';
import {
  calculateTextWidth,
  commitComparator,
  getByteSize,
  getCommitFrequency,
  getFileSize,
  getLastModifiedInfo,
  getSizeDifference,
  getSizeDifferencePercentage,
  getTotalSizePercentage,
  getTotalTables,
  HeaderDropdown,
  lastUpdateComparator,
  rowCountComparator,
  sizeComparator,
  STATISTICS_DAYS_BACK,
  tableSizePercentComparator,
} from '../helpers';

export default function Database() {
  const { user } = useAuth();
  const {
    organization: organizationNameParam,
    warehouse: warehouseNameParam,
    database: databaseParam,
  } = useParams();
  const organizationName = organizationNameParam || '';
  const warehouseName = warehouseNameParam || '';
  const database = databaseParam || '';
  const { ff } = useContext(GlobalPropertiesContext);
  const gcsTestingEnabled = ff(KnownFeatureFlags.GCS_TESTING_ENABLED);
  const adlsTestingEnabled = ff(KnownFeatureFlags.ADLS_TESTING_ENABLED);
  const registerTableEnabled = ff(KnownFeatureFlags.REGISTER_TABLE_ENABLED);

  const location = useLocation();
  const [openSnackbar, setOpenSnackbar] = useState(
    location?.state ? location.state.snackbar : false,
  );
  const apiRef = useGridApiRef();
  const [pageSize, setPageSize] = useState(100);
  const [apiError, setApiError] = useState(false);
  const [undroppedTables, setUndroppedTables] = useState([]);

  const navigateTo = useNavigate();
  const warehouseId = user.getWarehouse(organizationName, warehouseName)?.id;
  const organizationId = user.getOrganization(organizationName)?.id;
  const {
    data,
    loading,
    error,
    refetch: refetchDatabase,
  } = useQuery(databaseQuery, {
    variables: {
      organizationId,
      warehouseId,
      database,
      daysBack: STATISTICS_DAYS_BACK,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });
  const { data: dashboardData, refetch: refetchStats } = useQuery(
    DatabaseDashboardCardsQuery,
    {
      variables: {
        organizationId,
        warehouseId,
        database,
        daysBack: STATISTICS_DAYS_BACK,
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );
  const isSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(organizationName),
    [user],
  );

  const { data: tableListing } = useQuery(listTablesQuery, {
    skip: !data && !isSecurityAdmin,
    variables: {
      warehouseId,
      database,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const dropDatabasePrivilegeCheck = async () => {
    if (!userCanDrop) {
      return { database: false, tables: false, tablesWithoutPrivs: [] };
    }
    const tablesWithoutPrivs: any[] = [];
    tables.map((table: any) => {
      if (!user.hasPrivilege('DROP', table)) {
        tablesWithoutPrivs.push({
          tableName: table.name,
          tableLink: toTableRoot(
            organizationName,
            warehouseName,
            database,
            table.name,
          ),
        });
      }

      return true;
    });

    return {
      database: true,
      tables: isEmpty(tablesWithoutPrivs),
      tablesWithoutPrivs,
    };
  };

  const [dropDatabase] = useMutation(deleteDatabase, {
    variables: {
      warehouseId,
      database,
    },
    onError: () => {
      setApiError(true);
    },
    onCompleted: (result) => {
      if (result?.deleteDatabase?.databaseDropped) {
        navigateTo(toWarehouseRoot(organizationName, warehouseName), {
          state: {
            snackbar: true,
            snackbarMessage: `${database} successfully dropped.`,
          },
        });
      } else {
        setUndroppedTables(result.deleteDatabase.undroppedTableNames);
      }
    },
  });

  const organizationDisplayName = useMemo(
    () =>
      user.getOrganization(organizationName)?.displayName || organizationName,
    [user, organizationName],
  );
  const warehouseDisplayName = useMemo(
    () =>
      user.getWarehouse(organizationName, warehouseName)?.name || warehouseName,
    [user, organizationName, warehouseName],
  );
  const databaseData = useMemo(
    () => data?.organization?.warehouse?.database,
    [data],
  );
  const databaseName = useMemo(
    () => data?.organization?.warehouse?.database?.name || database,
    [data],
  );
  const tables: Table[] = useMemo(
    () =>
      data?.organization?.warehouse?.database?.tables
        ? data?.organization?.warehouse?.database?.tables
        : tableListing?.listTablesByName,
    [data, tableListing],
  );

  const databaseId = useMemo(
    () =>
      databaseData?.id
        ? databaseData?.id
        : tableListing?.listTablesByName &&
          tableListing?.listTablesByName[0]?.namespaceId,
    [databaseData, tableListing],
  );

  const userCanDrop = useMemo(
    () =>
      user.hasPrivilege(
        'MODIFY_DATABASE',
        data?.organization?.warehouse?.database,
      ),
    [data, user],
  );
  const canCreateTable = useMemo(
    () =>
      user.hasPrivilege(
        'CREATE_TABLE',
        data?.organization?.warehouse?.database,
      ),
    [user, data],
  );
  const dashboardStatistics = useMemo(
    () => dashboardData?.organization?.warehouse?.database?.statistics,
    [dashboardData],
  );
  const dashboardCompaction = useMemo(
    () => dashboardData?.organization?.warehouse?.database?.compactionSummary,
    [dashboardData],
  );
  const dbPath = useMemo(
    () => `${warehouseName}.${databaseName}`,
    [warehouseName, databaseName],
  );
  const hideDueToStorageType = useMemo(
    () =>
      data?.organization?.warehouse?.storageProfile?.storageType ===
      StorageType.Gcs
        ? !gcsTestingEnabled
        : data?.organization?.warehouse?.storageProfile?.storageType ===
          StorageType.Adls
        ? !adlsTestingEnabled
        : false,
    [data],
  );
  const handleRefetch = () => {
    refetchDatabase({
      //@ts-ignore
      noCache: true,
    }).then(() => refetchStats());
  };

  const handleCreateTableOptions = useCallback(
    () =>
      showCreateTableOptionsDialog(hideDueToStorageType).then((option) => {
        if (option === CreateTableOption.CREATE) {
          handleCreateTable();
        } else if (option === CreateTableOption.CREATE_LIKE) {
          handleCreateTableLike();
        } else if (option === CreateTableOption.CREATE_LOAD) {
          handleCreateTableLoad();
        } else if (option === CreateTableOption.CDC) {
          handleCreateCDCPipeline();
        }
      }),
    [user, organizationName, warehouseName, databaseName],
  );

  const handleCreateTable = useCallback(
    () =>
      showCreateTableDialog(
        organizationId,
        user.getWarehouse(organizationName, warehouseName),
        database,
        breadcrumbs,
      ).then(
        (table) =>
          table?.name &&
          navigateTo(
            toTableRoot(
              organizationName,
              warehouseName,
              databaseName,
              table.name,
            ),
          ),
      ),
    [user, organizationName, warehouseName, databaseName],
  );

  const handleCreateTableLike = useCallback(
    () =>
      showChooseTableCreationSourceDialog(UserTableIntent.CREATE).then(
        (source) => {
          showCreateTableLikeDialog(
            organizationId,
            user.getWarehouse(organizationName, warehouseName),
            database,
            breadcrumbs,
            source,
          ).then(
            (table) =>
              table?.name &&
              navigateTo(
                toTableRoot(
                  organizationName,
                  warehouseName,
                  databaseName,
                  table.name,
                ),
              ),
          );
        },
      ),
    [user, organizationName, warehouseName, databaseName],
  );

  const handleCreateCDCPipeline = useCallback(() => {
    showChooseTableCreationSourceDialog(UserTableIntent.CREATE_CDC).then(
      (source) => {
        showCreateCDCPipelineDialog(
          organizationId,
          user.getWarehouse(organizationName, warehouseName),
          database,
          breadcrumbs,
          source,
        ).then(
          (table) =>
            table?.name &&
            navigateTo(
              toTableRoot(
                organizationName,
                warehouseName,
                databaseName,
                table.name,
              ),
            ),
        );
      },
    );
  }, [user, organizationName, warehouseName, databaseName]);

  const handleRegisterTable = useCallback(() => {
    showRegisterTableDialog(
      organizationId,
      user.getWarehouse(organizationName, warehouseName),
      database,
      breadcrumbs,
    ).then(
      (table) =>
        table?.name &&
        navigateTo(
          toTableRoot(
            organizationName,
            warehouseName,
            databaseName,
            table.name,
          ),
          {
            state: {
              snackbar: true,
              snackbarMessage: `${table?.name} successfully registered.`,
            },
          },
        ),
    );
  }, [user, organizationName, warehouseName, databaseName]);

  const handleCreateTableLoad = useCallback(() => {
    showChooseTableCreationSourceDialog(UserTableIntent.CREATE).then(
      (source) => {
        showCreateTableLoadDialog(
          organizationId,
          user.getWarehouse(organizationName, warehouseName),
          database,
          breadcrumbs,
          source,
        ).then(
          (table) =>
            table?.name &&
            navigateTo(
              toTableRoot(
                organizationName,
                warehouseName,
                databaseName,
                table.name,
              ),
            ),
        );
      },
    );
  }, [user, organizationName, warehouseName, databaseName]);

  const doDropDatabase = async () => {
    await dropDatabase();
  };

  const handleDropDatabase = useCallback(() => {
    if (userCanDrop) {
      dropDatabasePrivilegeCheck().then((privilegeCheckResults) => {
        showTypeDeleteDialog({
          privilegeCheckResults,
          ...getDangerZoneItems(databaseName)[0].modalContent,
        }).then((doit) => {
          doDropDatabase();
        });
      });
    }
  }, [userCanDrop, databaseName, doDropDatabase]);

  const breadcrumbs = useMemo(
    () => [
      {
        href: toWarehouseRoot(organizationName, warehouseName),
        text: warehouseDisplayName,
        dropdown: {
          type: 'WAREHOUSE',
          variables: {
            organizationId: user.getOrganization(organizationName)?.id,
          },
          current: warehouseName,
        },
      },
      {
        href: toDatabaseRoot(organizationName, warehouseName, database),
        text: database,
        dropdown: {
          type: 'DATABASE',
          variables: {
            organizationId: user.getOrganization(organizationName)?.id,
            warehouseId: user.getWarehouse(organizationName, warehouseName)?.id,
          },
          current: database,
        },
      },
    ],
    [
      database,
      organizationName,
      organizationDisplayName,
      warehouseName,
      warehouseDisplayName,
    ],
  );

  const rows = useMemo(() => {
    const allTablesSize = sumBy(
      tables,
      (table) => table.statistics?.totalBytes,
    );

    return tables?.map((table) => {
      return {
        id: table.name,
        name: table.name,
        hasAccess:
          user.isSecurityAdmin(organizationName) ||
          user.hasPrivilege('SELECT', table),
        comment: table.properties?.find((p) => p?.key === 'comment'),
        lastModified: getLastModifiedInfo(table.statistics, table)
          .lastModifiedDate,
        lastModifiedBy: getLastModifiedInfo(table.statistics, table)
          .lastModifiedBy,
        // the below object params will not work with filtering,
        // to enable filtering the params will need to be converted to single variables
        size: {
          bytesAdded: table.statistics?.bytesAdded,
          bytesRemoved: table.statistics?.bytesRemoved,
          totalBytes: table.statistics?.totalBytes,
        },
        rows: {
          rowsAdded: table.statistics?.rowsAdded,
          rowsRemoved: table.statistics?.rowsDeleted,
          totalRows: table.statistics?.totalRows,
        },
        commits: {
          commitCnt: table.statistics?.commitCnt,
          opAppendCnt: table.statistics?.opAppendCnt,
          opOverwriteCnt: table.statistics?.opOverwriteCnt,
          opDeleteCnt: table.statistics?.opDeleteCnt,
          opReplaceCnt: table.statistics?.opReplaceCnt,
          createdAt: table.createdAt,
        },
        totalSize: {
          allTablesSize: allTablesSize,
          totalBytes: table.statistics?.totalBytes,
        },
        isView: table.contentType === IcebergContentType.View,
      };
    });
  }, [tables]);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Table',
        flex: 2,
        minWidth: 200,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
          );
          const isView = params?.row?.isView;
          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: '1 1 100%',
                      alignItems: 'center',
                      height: '100%',
                      minWidth: 0,
                      cursor:
                        textWidth >= params.colDef.computedWidth ||
                        params.row?.comment?.value
                          ? 'pointer'
                          : 'auto',
                    }}
                  >
                    {params.row.hasAccess ? (
                      <Link
                        href={toTableRoot(
                          organizationName,
                          warehouseName,
                          databaseName,
                          params.value,
                        )}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {params.value}
                      </Link>
                    ) : (
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {params.value}
                      </Typography>
                    )}
                  </Box>
                  {isView && (
                    <CustomIcon
                      src="/assets/icon/view-indicator.svg"
                      sx={(theme) => ({ ml: theme.spacing(1) })}
                    />
                  )}
                  {(textWidth >= params.colDef.computedWidth ||
                    params.row?.comment?.value) && (
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <NamePopover
                        name={params.value}
                        comment={
                          params.row.comment ? params.row.comment.value : null
                        }
                      />
                    </Popper>
                  )}
                </>
              )}
            </PopupState>
          );
        },
      },
      {
        field: 'lastModified',
        headerName: 'Last update',
        flex: 1,
        minWidth: 150,
        editable: false,
        type: 'dateTime',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: lastUpdateComparator,
        renderCell: (params: GridRenderCellParams) => (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flex: '1 1 100%',
                    alignItems: 'center',
                    height: '100%',
                    cursor: 'default',
                  }}
                >
                  <Typography variant={`inputText`} {...bindHover(popupState)}>
                    {params.value ? relativeTimeAutoFormat(params.value) : '--'}
                  </Typography>
                </Box>
                <Popper
                  {...bindPopper(popupState)}
                  placement={`right-start`}
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom'],
                      },
                    },
                  ]}
                >
                  <LastUpdateModifiedByPopover
                    modifiedBy={params.row.lastModifiedBy}
                    modifiedDate={params.value}
                  />
                </Popper>
              </>
            )}
          </PopupState>
        ),
      },
      {
        field: 'commits',
        headerName: 'Commit frequency',
        flex: 1,
        minWidth: 150,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: commitComparator,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value.commitCnt ? (
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '1 1 100%',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-end',
                        cursor: 'default',
                      }}
                    >
                      <Typography
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {getCommitFrequency(
                          params.value.commitCnt,
                          params.value.createdAt,
                        )}
                      </Typography>
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <CommitsPopover
                        commitsData={[
                          { label: 'Append', count: params.value.opAppendCnt },
                          {
                            label: 'Replace',
                            count: params.value.opReplaceCnt,
                          },
                          {
                            label: 'Overwrite',
                            count: params.value.opOverwriteCnt,
                          },
                          { label: 'Delete', count: params.value.opDeleteCnt },
                        ]}
                      />
                    </Popper>
                  </>
                )}
              </PopupState>
            ) : (
              '--'
            )}
          </>
        ),
      },
      {
        field: 'rows',
        headerName: 'Row Count',
        minWidth: 80,
        flex: 1,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: rowCountComparator,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value?.totalRows ? (
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '1 1 100%',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        height: '100%',
                        cursor: 'default',
                      }}
                    >
                      <Typography
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {compactNumberFormatter.format(params.value.totalRows)}
                      </Typography>
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <TrendPopover
                        added={compactNumberFormatter.format(
                          params.value?.rowsAdded,
                        )}
                        removed={compactNumberFormatter.format(
                          params.value?.rowsRemoved,
                        )}
                        total={getSizeDifference(
                          params.value?.rowsAdded,
                          params.value?.rowsRemoved,
                          'compact',
                        )}
                        icon={
                          getSizeDifferencePercentage(
                            params.value?.rowsAdded,
                            params.value?.rowsRemoved,
                            params.value.totalRows,
                          ).icon
                        }
                        diff={
                          getSizeDifferencePercentage(
                            params.value?.rowsAdded,
                            params.value?.rowsRemoved,
                            params.value.totalRows,
                          ).value
                        }
                        exactRows={getTotalTables(params.value.totalRows)}
                      />
                    </Popper>
                  </>
                )}
              </PopupState>
            ) : (
              '--'
            )}
          </>
        ),
      },
      {
        field: 'size',
        headerName: 'Size',
        minWidth: 100,
        flex: 1,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: sizeComparator,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value?.totalBytes ? (
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '1 1 100%',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        height: '100%',
                        cursor: 'default',
                      }}
                    >
                      <Typography
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {getByteSize(params.value.totalBytes)}
                      </Typography>
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <TrendPopover
                        added={getByteSize(params.value?.bytesAdded)}
                        removed={getByteSize(params.value?.bytesRemoved)}
                        total={getSizeDifference(
                          params.value?.bytesAdded,
                          params.value?.bytesRemoved,
                          'bytes',
                        )}
                        icon={
                          getSizeDifferencePercentage(
                            params.value?.bytesAdded,
                            params.value?.bytesRemoved,
                            params.value.totalBytes,
                          ).icon
                        }
                        diff={
                          getSizeDifferencePercentage(
                            params.value?.bytesAdded,
                            params.value?.bytesRemoved,
                            params.value.totalBytes,
                          ).value
                        }
                      />
                    </Popper>
                  </>
                )}
              </PopupState>
            ) : (
              '--'
            )}
          </>
        ),
      },
      {
        field: 'totalSize',
        headerName: 'Total size %',
        minWidth: 200,
        flex: 2,
        editable: false,
        type: 'number',
        align: 'center',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: tableSizePercentComparator,
        renderCell: (params: GridRenderCellParams) =>
          params.value.totalBytes ? (
            <ProgressBar
              value={
                getTotalSizePercentage(
                  params.value.totalBytes,
                  params.value.allTablesSize,
                ).value
              }
              display={
                getTotalSizePercentage(
                  params.value.totalBytes,
                  params.value.allTablesSize,
                ).display
              }
            />
          ) : (
            '--'
          ),
      },
    ],
    [organizationName, warehouseName, databaseName],
  );

  const dashboardItems = useMemo(
    () => [
      {
        items: [
          {
            title: 'Tables',
            data: (
              <Typography variant={`subtitle1`}>
                {dashboardStatistics?.totalTables
                  ? compactNumberFormatter.format(
                      dashboardStatistics.totalTables,
                    )
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: 'Database Size',
            data: (
              <Typography variant={`subtitle1`}>
                {getByteSize(dashboardStatistics?.totalBytes) || '--'}
              </Typography>
            ),
          },
          {
            title: `Last ${STATISTICS_DAYS_BACK} Days`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {dashboardStatistics?.totalBytes
                    ? getSizeDifferencePercentage(
                        dashboardStatistics?.bytesAdded,
                        dashboardStatistics?.bytesRemoved,
                        dashboardStatistics.totalBytes,
                      ).icon
                    : ''}
                  {dashboardStatistics?.totalBytes ? (
                    <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                      {
                        getSizeDifferencePercentage(
                          dashboardStatistics?.bytesAdded,
                          dashboardStatistics?.bytesRemoved,
                          dashboardStatistics.totalBytes,
                        ).value
                      }
                    </Typography>
                  ) : (
                    '--'
                  )}
                </Box>
              </Box>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: `File Compaction`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {dashboardCompaction?.filePercent ? (
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          {...bindHover(popupState)}
                        >
                          <SouthEastIcon fontSize={`small`} />
                          <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                            {`${dashboardCompaction.filePercent}%`}
                          </Typography>
                        </Box>
                        <Popper
                          {...bindPopper(popupState)}
                          placement={`bottom`}
                        >
                          <CompactionResultsPopover
                            type={`files`}
                            numberOfDays={STATISTICS_DAYS_BACK}
                            starting={getFileSize(
                              dashboardCompaction?.startingFiles,
                            )}
                            // @ts-ignore
                            rewritten={getFileSize(
                              dashboardCompaction?.rewrittenFiles,
                            )}
                            // @ts-ignore
                            total={getFileSize(
                              dashboardCompaction?.startingFiles -
                                dashboardCompaction?.rewrittenFiles,
                            )}
                            downward={true}
                            percent={`${dashboardCompaction?.filePercent}%`}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                ) : (
                  '--'
                )}
              </Box>
            ),
          },
          {
            title: `Storage Change`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {dashboardCompaction?.sizePercent ? (
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          {...bindHover(popupState)}
                        >
                          {dashboardCompaction.sizeChange < 0 ? (
                            <NorthEastIcon fontSize={`small`} />
                          ) : (
                            <SouthEastIcon fontSize={`small`} />
                          )}
                          <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                            {`${dashboardCompaction.sizePercent}%`}
                          </Typography>
                        </Box>
                        <Popper
                          {...bindPopper(popupState)}
                          placement={`bottom`}
                        >
                          <CompactionResultsPopover
                            type={`size`}
                            numberOfDays={STATISTICS_DAYS_BACK}
                            starting={getByteSize(
                              dashboardCompaction?.startingSize,
                            )}
                            rewritten={getByteSize(
                              dashboardCompaction?.rewrittenSize,
                            )}
                            total={
                              getByteSize(
                                dashboardCompaction?.startingSize -
                                  dashboardCompaction?.rewrittenSize,
                              ) || '--'
                            }
                            downward={dashboardCompaction.sizeChange > 0}
                            percent={`${dashboardCompaction.sizePercent}%`}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                ) : (
                  '--'
                )}
              </Box>
            ),
          },
        ],
      },
    ],
    [dashboardCompaction, dashboardStatistics],
  );

  const headerReferences = [
    {
      title: 'Warehouse ID',
      refText: warehouseId,
      clipBoardText: warehouseId,
    },
    {
      title: 'Fully qualified name',
      refText: dbPath,
      clipBoardText: dbPath,
    },
    {
      title: 'Database ID',
      refText: databaseId,
      clipBoardText: databaseId,
    },
  ];
  const storageType =
    data?.organization?.warehouse?.storageProfile?.storageType;

  const cloudProviderIcon =
    storageType &&
    getImgSrcForStorageType(
      data?.organization?.warehouse?.storageProfile?.storageType,
    );

  const headerName = HeaderDropdown(
    databaseName,
    data?.organization?.warehouse?.storageProfile?.bucket,
    organizationName,
    headerReferences,
  );

  const dashboardDropdownContent = (
    <DropdownMenuContent
      menuItems={[
        <DropdownMenuItem
          key={'refresh'}
          showAsMenuButton
          titleText={'Refresh'}
          Icon={RefreshOutlined}
          onClick={() => {
            handleRefetch();
          }}
        />,

        <DropdownMenuItem
          key={'restoreTables'}
          showAsMenuButton
          titleText={'Restore Tables'}
          Icon={RestoreIcon}
          onClick={() => {
            navigateTo(toDroppedTables(organizationName), {
              state: { searchTerm: databaseName },
            });
          }}
        />,

        <DropdownMenuItem
          key={'createTable'}
          titleText={'Create table'}
          description={'Select a table creation method'}
          Icon={AddOutlined}
          onClick={handleCreateTableOptions}
          disabled={!canCreateTable}
        />,

        ...(!hideDueToStorageType && registerTableEnabled
          ? [
              <DropdownMenuItem
                key={'registerTable'}
                titleText={'Register table'}
                description={'Register a table from an existing definition'}
                Icon={AddOutlined}
                onClick={handleRegisterTable}
                disabled={!canCreateTable}
              />,
            ]
          : []),

        <DropdownMenuItem
          key={'dropDatabase'}
          showAsMenuButton
          titleText={'Drop database'}
          disabled={!userCanDrop}
          onClick={handleDropDatabase}
          dangerZone
        />,
      ]}
    />
  );

  const addResourceContent = {
    title: 'Create Table',

    action: () => {
      handleCreateTableOptions();
    },
    disabled: !canCreateTable,
  };

  const refreshResourceContent = {
    title: 'Refresh table list',
    action: () => handleRefetch(),
  };

  const tabs = useMemo(
    () => [
      {
        id: 'tables',
        label: 'Database Overview',
        group: MainTabGroup.DATA,
        content: (
          <>
            <DataGridDashboard dashboardCards={dashboardItems} />
            <Tacard grid>
              <DataGridPro
                autoHeight
                apiRef={apiRef}
                pagination={rows?.length >= 100}
                hideFooter={rows?.length <= 100}
                rowsPerPageOptions={[10, 25, 50, 100]}
                rows={rows || []}
                //@ts-ignore
                columns={columns || []}
                headerHeight={34}
                disableColumnMenu
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                  },
                  pagination: { page: 0, pageSize: 10 },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [''],
                    },
                  },
                }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: DataGridToolBar,
                  Pagination: CustomPaging,
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                    apiRef: apiRef,
                    pageSize: pageSize,
                    setPageSize: setPageSize,
                    refreshResourceContent: refreshResourceContent,
                    addResourceContent: addResourceContent,
                    dashboardMenuContent: dashboardDropdownContent,
                    rowCount: rows?.length,
                    showRange: rows?.length >= 100,
                    pageAtNumber: 100,
                    headerName: headerName,
                    cloudProviderIcon: cloudProviderIcon,
                  },
                  pagination: {
                    apiRef: apiRef,
                    pageSize: pageSize,
                    setPageSize: setPageSize,
                    rowCount: rows?.length,
                  },
                }}
                loading={loading}
                sx={{
                  border: 'none',
                  '.MuiDataGrid-columnHeaders': {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  },
                  '.MuiDataGrid-pinnedColumnHeaders': {
                    backgroundColor: 'dusk.half',
                  },
                }}
              />
            </Tacard>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={openSnackbar}
              onClose={() => {
                setOpenSnackbar(false);
                window.history.replaceState({}, document.title);
              }}
            >
              <Talert
                onClose={() => {
                  setOpenSnackbar(false);
                  window.history.replaceState({}, document.title);
                }}
                color="darkSuccess"
                sx={{ width: '100%' }}
              >
                {location?.state?.snackbarMessage ??
                  'Table successfully dropped.'}
              </Talert>
            </Snackbar>
          </>
        ),
        hide: false,
        href: 'tables',
      },
      {
        id: 'access',
        label: 'Access Control',
        group: MainTabGroup.DATA,
        content: (
          <>
            {data?.organization?.warehouse && (
              <DatabaseAccessControl
                organizationId={organizationId}
                organizationName={organizationName}
                warehouseId={warehouseId}
                databaseName={databaseName}
                databaseId={databaseId}
              />
            )}
          </>
        ),
        hide: false,
        href: 'access',
      },
      {
        id: 'settings',
        label: 'Settings',
        group: MainTabGroup.DATA,
        href: 'settings',
        content: loading ? (
          <Skeleton />
        ) : (
          <>
            <PageHeader
              resourceName={databaseName}
              pageTitle={'Database settings'}
            />
            <DatabaseSettings
              userCanDrop={userCanDrop}
              organizationName={data?.organization?.name}
              warehouseId={warehouseId}
              warehouseName={warehouseName}
              database={database}
              tables={tables}
              doDropDatabase={doDropDatabase}
              apiError={apiError}
              setApiError={setApiError}
              undroppedTables={undroppedTables}
              dropDatabasePrivilegeCheck={dropDatabasePrivilegeCheck}
            />
          </>
        ),
        hide: false,
        disabled: !userCanDrop,
      },
    ],
    [
      data,
      loading,
      rows,
      columns,
      openSnackbar,
      setOpenSnackbar,
      dashboardItems,
      dashboardDropdownContent,
      userCanDrop,
    ],
  );

  if (error && !user.isSecurityAdmin(organizationName)) {
    if (
      error?.message?.startsWith('404:') &&
      !user.isSecurityAdmin(organizationName)
    ) {
      return <NotFound />;
    } else if (
      error?.message?.startsWith('403:') &&
      !user.isSecurityAdmin(organizationName)
    ) {
      return (
        <PageAccessDenied breadcrumbs={breadcrumbs} resourceType={'Database'} />
      );
    } else if (error?.message?.startsWith('401:')) {
      return <OrgAccessDenied />;
    }
  }

  return (
    <TabLayout resourceType="Database" breadcrumbs={breadcrumbs} tabs={tabs} />
  );
}
