import React, { MouseEvent, ReactElement } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  BoxProps,
  Fab,
  FabProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SvgIconProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { SystemProps } from '@mui/system';
import { isEmpty } from 'lodash';

import { Tabutton } from '../Button/Tabutton';
import { CustomIcon } from '../Icons/CustomIcon';

interface DropdownMenuItemProps extends BoxProps {
  Icon?: React.ElementType;
  customIconPath?: string;
  iconProps?: SvgIconProps;
  titleText: string;
  titleColor?: string;
  description?: string;
  onClick: (event: MouseEvent) => void;
  clickToCopy?: boolean;
  copySuccess?: string;
  highlightBackground?: boolean;

  dangerZone?: boolean;
  linkCallout?: JSX.Element;
  disabled?: boolean;
  showAsMenuButton?: boolean;
  toolTipText?: string;
}

export const DropdownMenuItem = ({
  Icon,
  customIconPath,
  iconProps,
  titleText,
  titleColor,
  description,
  onClick,
  clickToCopy,
  copySuccess,
  highlightBackground,
  dangerZone,
  linkCallout,
  disabled,
  showAsMenuButton,
  toolTipText,
  ...props
}: DropdownMenuItemProps): ReactElement => {
  if (dangerZone && !Icon) {
    if (!Icon) {
      Icon = DeleteOutlineIcon;
      iconProps = {
        sx: {
          color: disabled ? 'midnight.two' : 'sunset.seven',
          height: 16,
          width: 16,
        },
      };
    }
    if (!titleColor) {
      titleColor = 'sunset.seven';
    }
  }

  if (clickToCopy && !Icon) {
    Icon = ContentCopyOutlinedIcon;
    iconProps = {
      sx: {
        color: 'brandBlue.main',
        height: 16,
        width: 16,
      },
    };
    if (!toolTipText) {
      toolTipText = 'Click to copy';
    }
  }

  const iconComponent = (
    <>
      {Icon && (
        <Icon
          fontSize={`medium`}
          sx={[
            {
              height: 16,
              width: 16,
              color: disabled ? 'midnight.two' : 'primary.main',
            },
            iconProps?.sx as SystemProps,
          ]}
        />
      )}
      {customIconPath && (
        <CustomIcon
          src={customIconPath}
          sx={{
            height: 16,
            width: 16,
          }}
        />
      )}
    </>
  );

  const finalIconComponent = showAsMenuButton ? (
    iconComponent
  ) : (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: disabled ? 'midnight.two' : 'brandBlue',
        borderRadius: 24,
        height: 24,
        width: 24,
        boxShadow: theme.shadows[0],
        ...(showAsMenuButton
          ? {}
          : { border: `1px solid ${theme.palette.midnight.two}` }),
        cursor: disabled ? 'not-allowed' : 'pointer',
      })}
    >
      {iconComponent}
    </Box>
  );

  const listItemiconComponent = (Icon || customIconPath) && (
    <ListItemIcon sx={{ alignSelf: 'center', mt: 0.2 }}>
      {finalIconComponent}
    </ListItemIcon>
  );
  const finalIcon =
    toolTipText && listItemiconComponent ? (
      <Tooltip title={toolTipText} placement={`top`} arrow>
        {listItemiconComponent}
      </Tooltip>
    ) : (
      listItemiconComponent
    );
  const menuItem = (
    <MenuItem
      onClick={(e: MouseEvent) => (disabled ? e.stopPropagation() : onClick(e))}
      sx={{
        '& .MuiStack-root': {
          alignItems: 'flex-start',
        },
        '&:hover': { backgroundColor: 'transparent' },
        cursor: disabled ? 'not-allowed' : 'pointer',
        maxWidth: '320px',
        ...(showAsMenuButton
          ? { textTransform: 'uppercase', color: 'brandBlue.main' }
          : {}),
      }}
    >
      <ListItemText
        sx={{
          flexGrow: 0,

          mr: 1,
          ...(description ? { minWidth: 250 } : {}),
        }}
      >
        <Box
          sx={{
            flexDirection: 'column',
            paddingY: showAsMenuButton ? 0.5 : 0.16,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: disabled
                ? 'midnight.three'
                : titleColor
                ? titleColor
                : showAsMenuButton
                ? 'brandBlue'
                : 'midnight.nine',
              display: 'inline-flex',
            }}
          >
            {titleText}
          </Typography>
          {description && (
            <Typography
              variant={clickToCopy ? 'smallPreformat' : 'helperText'}
              sx={{
                color: disabled
                  ? 'midnight.three'
                  : clickToCopy
                  ? 'midnight.nine'
                  : 'midnight.seven',
                lineHeight: 'normal',
                whiteSpace: 'normal',
                display: 'block',
              }}
            >
              {description}
            </Typography>
          )}
          {linkCallout && <Box marginTop={1}>{linkCallout}</Box>}
        </Box>
      </ListItemText>
      {finalIcon}
    </MenuItem>
  );

  return (
    <>
      <Box
        {...props}
        sx={{
          '&:hover': {
            backgroundColor: dangerZone ? 'sunset.one' : 'sky.one',
          },
          width: 1,
          backgroundColor: highlightBackground ? 'midnight.one' : 'inherit',
          marginY: '0 !important',
        }}
      >
        {menuItem}
      </Box>
    </>
  );
};
