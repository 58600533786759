import * as React from 'react';

import { Typography, Box, Stack, Divider } from '@mui/material';
import PropTypes from 'prop-types';

export enum ArrowPosition {
  Left,
  Right,
}

type Props = {
  modifiedDate: string;
};

export const DatePopover = React.forwardRef(({ modifiedDate }: Props, ref) => {
  return (
    <Box
      ref={ref}
      sx={(theme) => ({
        backgroundColor: 'popper.main',
        color: 'popper.contrastText',
        borderRadius: '4px',
        position: 'relative',
        boxShadow: theme.shadows[3],
        ml: '10px',
      })}
    >
      <Stack direction={`column`}>
        <Box display={`flex`} alignItems={`center`} sx={{ py: 1, px: 2 }}>
          <Typography variant={`body2`}>
            {modifiedDate ? new Date(modifiedDate).toUTCString() : '--'}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
});
