import React, { useCallback, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import InputAdornment from '@mui/material/InputAdornment';
import { millisecondsToMinutes, minutesToMilliseconds } from 'date-fns';
import _ from 'lodash';
import * as Yup from 'yup';

import { useAuth } from '../../context/auth-context';
import {
  Organization,
  OrganizationUpdate,
  PropertyUpdateRequest,
} from '../../graphql/gen/graphql';
import {
  updateOrg,
  updateOrganizationProperties,
} from '../../graphql/organization';
import StatusBars from '../Feedback/StatusBars';
import { Switch } from '../Forms/Switch';
import TextField from '../Forms/TextField';
import { PageHeader } from '../PageHeader/PageHeader';
import { SettingGroupCard } from '../Settings/SettingGroupCard';
import { Setting } from '../Settings/Settings';
import { OrgProperties } from './OrgProperties';

export const OrgGeneralSettings = ({
  organization,
}: {
  organization: Organization;
}): JSX.Element => {
  const [updateOrgMutation] = useMutation(updateOrg);
  const [updateOrgPropertiesMutation] = useMutation(
    updateOrganizationProperties,
  );
  const { user } = useAuth();
  const isOrganizationAdmin = useMemo(
    () => user.isOrganizationAdmin(organization.name),
    [user, organization],
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [mutationError, setMutationError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    `${organization.name} settings have been updated.`,
  );
  const [errorMessage, setErrorMessage] = useState('');

  const handleUpdateSettings = useCallback(
    (data: any) => {
      let orgUpdates: OrganizationUpdate = {};
      let requiresRedirect = false;
      if (data.orgName && data.orgName !== organization.name) {
        orgUpdates.name = data.orgName;
        requiresRedirect = true;
      }
      if (
        data.orgDisplayName &&
        data.orgDisplayName !== organization.displayName
      ) {
        orgUpdates.displayName = data.orgDisplayName;
      }
      if (Object.keys(orgUpdates).length > 0) {
        return updateOrgMutation({
          variables: {
            organizationId: organization?.id,
            orgUpdates: orgUpdates,
          },
          onCompleted: async () => {
            await closeAndRefetch();
          },

          onError: (error) => {
            setErrorMessage(error.message);
            setMutationError(true);
            console.log('Error setting the updates.', error);
          },
        });
      }
    },
    [organization],
  );

  const handleDataPreviewUpdate = (data: any) => {
    let propertyUpdateRequest: PropertyUpdateRequest = {
      removals: [],
      updates: {},
    };
    if (
      data.dataPreviewEnabled !=
      organization.properties[OrgProperties.ALLOW_DATA_PREVIEW]
    ) {
      propertyUpdateRequest.updates[OrgProperties.ALLOW_DATA_PREVIEW] =
        data.dataPreviewEnabled;
    }
    if (
      !_.isEmpty(propertyUpdateRequest.updates) ||
      !_.isEmpty(propertyUpdateRequest.removals)
    ) {
      return updateOrgPropertiesMutation({
        variables: {
          organizationId: organization?.id,
          propertyUpdateRequest: propertyUpdateRequest,
        },
        onCompleted: async () => {
          await closeAndRefetch();
        },

        onError: (error) => {
          setErrorMessage(error.message);
          setMutationError(true);
        },
      });
    }
  };

  const handleUserIdleTimeoutUpdate = (data: any) => {
    let propertyUpdateRequest: PropertyUpdateRequest = {
      removals: [],
      updates: {},
    };
    if (
      !data.userIdleTimeoutEnabled &&
      organization.properties[OrgProperties.USER_IDLE_TIMEOUT]
    ) {
      propertyUpdateRequest.removals = [
        ...propertyUpdateRequest.removals,
        OrgProperties.USER_IDLE_TIMEOUT,
      ];
    } else if (
      data.userIdleTimeoutEnabled &&
      minutesToMilliseconds(data.userIdleTimeout) !=
        organization.properties[OrgProperties.USER_IDLE_TIMEOUT]
    ) {
      propertyUpdateRequest.updates[OrgProperties.USER_IDLE_TIMEOUT] =
        minutesToMilliseconds(data.userIdleTimeout);
    }
    if (
      !_.isEmpty(propertyUpdateRequest.updates) ||
      !_.isEmpty(propertyUpdateRequest.removals)
    ) {
      return updateOrgPropertiesMutation({
        variables: {
          organizationId: organization?.id,
          propertyUpdateRequest: propertyUpdateRequest,
        },
        onCompleted: async () => {
          await closeAndRefetch();
        },

        onError: (error) => {
          setErrorMessage(error.message);
          setMutationError(true);
        },
      });
    }
  };

  const settings: Setting[] = [
    {
      name: 'General settings',
      description: 'Update your Organizations settings.',
      settingGroups: [
        {
          label: 'Organization name',
          fields: [
            {
              label: 'Display name',
              component: TextField,
              name: 'orgDisplayName',
            },
            {
              description:
                'Used in your vanity URL: app.tabular.io/<your_org_name>',
              label: 'URL name',
              component: TextField,
              name: 'orgName',
            },
          ],
        },
      ],
      initialValues: {
        orgName: organization.name,
        orgDisplayName: organization.displayName,
      },
      validationSchema: Yup.object({
        orgName: Yup.string()
          .required('Required')
          .matches(
            /^[a-zA-Z0-9_-]*$/,
            'Name may only contain alpha-numeric characters, ' +
              "underscores ('_'), or hyphens ('-') and may not contain any spaces.",
          ),
      }),
      updateSettings: handleUpdateSettings,
    },
    {
      name: 'Data preview',
      description: 'Preview up to 100 rows of data in a table.',
      settingGroups: [
        {
          label: '',
          fields: [
            {
              component: Switch,
              name: 'dataPreviewEnabled',
            },
          ],
        },
      ],
      initialValues: {
        dataPreviewEnabled:
          organization.properties &&
          organization.properties[OrgProperties.ALLOW_DATA_PREVIEW],
      },
      validationSchema: Yup.object({
        dataPreviewEnabled: Yup.boolean().required('Required'),
      }),
      updateSettings: handleDataPreviewUpdate,
    },
    {
      name: 'Inactive user policy',
      description:
        'Log out users when their web sessions have become inactive.',
      settingGroups: [
        {
          label: '',
          fields: [
            {
              component: Switch,
              name: 'userIdleTimeoutEnabled',
            },
            {
              component: TextField,
              name: 'userIdleTimeout',
              componentProps: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">minutes</InputAdornment>
                  ),
                },
              },
              label: 'Period of inactivity',
              dependsOn: 'userIdleTimeoutEnabled',
            },
          ],
        },
      ],
      initialValues: {
        userIdleTimeoutEnabled:
          organization.properties &&
          organization.properties[OrgProperties.USER_IDLE_TIMEOUT] != null,
        userIdleTimeout:
          organization.properties &&
          organization.properties[OrgProperties.USER_IDLE_TIMEOUT] != null
            ? millisecondsToMinutes(
                organization.properties[OrgProperties.USER_IDLE_TIMEOUT],
              )
            : 15,
      },
      validationSchema: Yup.object({
        userIdleTimeoutEnabled: Yup.boolean().required('Required'),
        userIdleTimeout: Yup.number().when('userIdleTimeoutEnabled', {
          is: true,
          then: Yup.number()
            .required('Required')
            .min(1, 'Value must be at least 1.'),
        }),
      }),
      updateSettings: handleUserIdleTimeoutUpdate,
    },
  ];

  const closeAndRefetch = async () => {
    setOpenSnackbar(true);

    //await refetchQuery();
  };

  return (
    <>
      <PageHeader
        resourceName={organization.displayName || ''}
        pageTitle={'Organization settings'}
      />
      {settings.map((eachSetting, index) => (
        <SettingGroupCard
          key={'org_setting-card_' + index}
          userCanEdit={isOrganizationAdmin}
          onUpdateSettings={eachSetting.updateSettings}
          setting={eachSetting}
        />
      ))}
      <StatusBars
        successDisplay={openSnackbar}
        handleSuccessClose={() => {
          setOpenSnackbar(false);
        }}
        successMessage={successMessage}
        errorDisplay={mutationError}
        setErrorDisplay={setMutationError}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        // refetch={() => refetchQuery()}
      />
    </>
  );
};
