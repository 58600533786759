import * as React from 'react';

import { Typography, Box, Stack } from '@mui/material';

type Props = {
  description: string;
};

export const DescriptionPopover = React.forwardRef(
  ({ description, ...props }: Props, ref) => {
    return (
      <Box
        ref={ref}
        {...props}
        sx={(theme) => ({
          backgroundColor: 'popper.main',
          color: 'popper.contrastText',
          borderRadius: '4px',
          maxWidth: '480px',
          position: 'relative',
          boxShadow: theme.shadows[3],
          ml: '10px',
        })}
      >
        <Stack direction={`column`}>
          <Box
            display={`flex`}
            alignItems={`center`}
            sx={{
              py: 1,
              px: 2,
              overflowWrap: 'anywhere',
            }}
          >
            <Typography variant={`body2`}>{description}</Typography>
          </Box>
        </Stack>
      </Box>
    );
  },
);
