import React, { useCallback } from 'react';

import { Box, CardContent, CardHeader, Typography } from '@mui/material';

import { Tabutton } from '../Button/Tabutton';
import { Tacard } from '../Card/Tacard';
import { showRenameWarehouseDialog } from '../Modals/RenameWarehouseDialog';
import { showTypeDeleteDialog } from '../Modals/TypeDeleteDialog';
import { DangerZoneItemsType } from './types';

type DangerZonePropTypes = {
  dangerZoneItems: DangerZoneItemsType[];
  onConfirm: (mutationName: string, newName?: string) => void;
  onPrivilegeCheck?: () => Promise<any>;
  undroppedTables?: string[];
  numOfTables?: number;
};

export default function DangerZone({
  dangerZoneItems,
  onConfirm,
  onPrivilegeCheck,
  undroppedTables,
  numOfTables,
}: DangerZonePropTypes) {
  const handleAction = useCallback(
    (item: DangerZoneItemsType, privilegeCheckResults?: any) => {
      if (item.confirmModal === 'TypeDeleteDialog') {
        showTypeDeleteDialog({
          privilegeCheckResults,
          undroppedTables,
          numOfTables,
          ...item.modalContent,
        }).then((doit) => {
          onConfirm(item.mutationName);
        });
      } else {
        showRenameWarehouseDialog({
          ...item.modalContent,
        }).then((response) => onConfirm(item.mutationName, response.newName));
      }
    },
    [undroppedTables, numOfTables],
  );

  return (
    <Tacard>
      <CardHeader
        title={'Danger zone'}
        sx={{ backgroundColor: 'sunset.seven', color: 'white' }}
      />
      <CardContent
        sx={{ '&.MuiCardContent-root:last-child': { paddingBottom: 2 } }}
      >
        {dangerZoneItems.map((item, index) => (
          <Box
            key={`danger-zone-${index}`}
            display={`flex`}
            flexDirection={`row`}
            justifyContent={`space-between`}
            alignItems={`center`}
            sx={(theme) => ({
              py: 3,
              borderBottom: `1px solid ${theme.palette.midnight.two}`,
              '&:last-of-type': {
                borderBottom: 'none',
              },
            })}
          >
            <Box display={`flex`} flexDirection={`column`}>
              <Typography variant={`subtitle1`}>{item.title}</Typography>
              <Typography variant={`body2`}>{item.description}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <Tabutton
                disabled={item?.disabled}
                onClick={() => {
                  if (item.privilegeCheck) {
                    onPrivilegeCheck &&
                      onPrivilegeCheck().then((privilegeCheckResults) => {
                        handleAction(item, privilegeCheckResults);
                      });
                  } else {
                    handleAction(item);
                  }
                }}
                color={`error`}
                variant={`text`}
                sx={{ whiteSpace: 'nowrap', ml: 2 }}
              >
                {item.buttonText}
              </Tabutton>
            </Box>
          </Box>
        ))}
      </CardContent>
    </Tacard>
  );
}
