import * as React from 'react';

import { computeSlots } from '../../utils/computeSlots';

import {
  STATUS_PANEL_DEFAULT_SLOTS_COMPONENTS,
  StatusPanelProps,
  StatusPanelPropsWithDefaultValue,
  StatusPanelSlotsComponent,
} from './index';

export const STATUS_PANEL_PROPS_DEFAULT_VALUES: StatusPanelPropsWithDefaultValue =
  {};

const defaultSlots = STATUS_PANEL_DEFAULT_SLOTS_COMPONENTS;

export const useStatusPanelProps = (inProps: StatusPanelProps) => {
  const slots = React.useMemo<StatusPanelSlotsComponent>(
    () =>
      computeSlots<StatusPanelSlotsComponent>({
        defaultSlots,
        slots: inProps.slots,
      }),
    [inProps.slots],
  );

  return React.useMemo<StatusPanelProps>(
    () => ({
      ...STATUS_PANEL_PROPS_DEFAULT_VALUES,
      ...inProps,
      slots,
    }),
    [inProps, slots],
  );
};
