import React from 'react';

import { Box, BoxProps, useTheme } from '@mui/material';

import DashboardMenu from '../DashboardMenu/DashboardMenu';
import { CustomIcon } from '../Icons/CustomIcon';
import StatusActionView from './StatusActionView';

import { StatusAction } from './index';

interface StatusPanelControlsViewProps extends BoxProps {
  actions?: StatusAction[];
  dashboardDropdownContent?: JSX.Element | undefined;
}

export default function StatusPanelControlsView({
  actions,
  dashboardDropdownContent,
  ...props
}: StatusPanelControlsViewProps) {
  return (
    <Box
      {...props}
      sx={{
        backgroundColor: 'midnight.one',
        display: 'flex',
        alignItems: 'center',
        p: 2,
      }}
    >
      {actions && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          {actions.map((action, actionIndex) => (
            <StatusActionView
              className={'status-panel-hide-small'}
              statusAction={action}
              key={`status-panel-action-${actionIndex}`}
            />
          ))}
          {dashboardDropdownContent && (
            <Box>
              <DashboardMenu
                buttonSize={'small'}
                sx={{ paddingLeft: 0 }}
                dashboardDropdownContent={dashboardDropdownContent}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
