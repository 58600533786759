import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { AlertProps, Box, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

import { Tabutton, TabuttonProps } from '../Button/Tabutton';

type SimpleSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;
export type MoreColor = 'success' | 'info' | 'warning' | 'error' | 'neutral';

export enum AlertButtonPosition {
  RIGHT,
  BOTTOM,
}

export interface TalertProps {
  severity?: MoreColor;
  alertTitle?: string;
  customIcon?: React.ReactNode;
  TabuttonProps?: TabuttonProps;
  buttonPosition?: AlertButtonPosition;
}
interface Props extends SimpleSpread<AlertProps, TalertProps> {}
export const Talert = React.forwardRef(
  (
    {
      severity,
      alertTitle,
      customIcon,
      children,
      TabuttonProps,
      buttonPosition,
      ...props
    }: Props,
    ref,
  ) => {
    if (props.color === 'neutral') {
      props['icon'] = (
        <NotesOutlinedIcon
          fontSize={'small'}
          sx={{ color: 'midnight.seven' }}
        />
      );
    }
    if (props.color === 'darkSuccess') {
      props['icon'] = (
        <CheckCircleOutlineIcon
          fontSize={'small'}
          sx={{ color: 'aurora.four' }}
        />
      );
    }
    if (props.color === 'darkError') {
      props['icon'] = (
        <InfoOutlined fontSize={'small'} sx={{ color: 'sunset.four' }} />
      );
    }
    if (props.color === 'darkWarning') {
      props['icon'] = (
        <WarningAmberIcon fontSize={'small'} sx={{ color: 'sunrise.four' }} />
      );
    }
    if (props.color === 'darkInfo') {
      props['icon'] = (
        <InfoOutlined fontSize={'small'} sx={{ color: 'sky.four' }} />
      );
    }
    if (props.color === 'darkNeutral') {
      props['icon'] = (
        <NotesOutlinedIcon fontSize={'small'} sx={{ color: 'midnight.four' }} />
      );
    }
    if (customIcon) {
      props['icon'] = customIcon;
    }
    const customVariants =
      'neutral' ||
      'darkError' ||
      'darkSuccess' ||
      'darkWarning' ||
      'darkInfo' ||
      'darkNeutral';
    return (
      <Box ref={ref}>
        <Alert
          {...(severity === customVariants ? {} : { severity: severity })}
          {...props}
          iconMapping={{ success: <CheckCircleOutlineIcon fontSize="small" /> }}
          style={{ ...(!alertTitle ? { alignItems: 'center' } : {}) }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection:
                buttonPosition === AlertButtonPosition.BOTTOM
                  ? 'column'
                  : 'row',
            }}
          >
            <Box marginRight={2}>
              {alertTitle && (
                <Typography variant={'subtitle2'} sx={{ pb: 0.5 }}>
                  {alertTitle}
                </Typography>
              )}
              {children}
            </Box>

            {TabuttonProps && <Tabutton {...TabuttonProps} />}
          </Box>
        </Alert>
      </Box>
    );
  },
);
