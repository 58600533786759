import React from 'react';

import { ContentCopy } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  ChipProps,
  Chip,
  Typography,
  Box,
  BoxProps,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { TypographyOwnProps } from '@mui/material/Typography/Typography';
import { SystemProps, TypographyProps } from '@mui/system';

import { Tabutton } from '../Button/Tabutton';

export interface TaClipboardProps extends BoxProps {
  clipboardText?: string;
  typographyProps?: TypographyOwnProps;
  tooltipText?: string;
}

export const TaClipboardText = ({
  clipboardText,
  typographyProps,
  children,
  tooltipText,
  sx,
  ...props
}: TaClipboardProps): JSX.Element => {
  const content = children ? (
    children
  ) : (
    <Typography {...typographyProps}>{clipboardText}</Typography>
  );
  return (
    <Box
      sx={[{ display: 'flex', alignItems: 'center', pr: 2 }, sx as SystemProps]}
      {...props}
    >
      {content}
      {clipboardText && (
        <Tooltip
          title={tooltipText ? tooltipText : 'Copy to clipboard'}
          placement={'top'}
          arrow
        >
          <IconButton
            size={'small'}
            onClick={() => navigator.clipboard.writeText(clipboardText)}
            style={{
              backgroundColor: 'transparent',
            }}
            sx={(theme) => ({
              color: 'brandBlue.main',
              '&:hover': {
                backgroundColor: theme.palette.sky.one,
                outline: 'none',
              },
              '&:active': {
                backgroundColor: theme.palette.sky.two,
                outline: 'none',
              },
              '&:focus': {
                outline: 'none',
              },
            })}
          >
            <ContentCopyOutlinedIcon sx={{ height: 16, width: 16 }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
