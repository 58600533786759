import React from 'react';

import { ArrowForward } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { SystemProps } from '@mui/system';
import { validate } from 'uuid';

import { useAuth } from '../../context/auth-context';
import { CustomIcon } from '../Icons/CustomIcon';
import { useStatusPanelSectionRootProps } from './context/useStatusPanelSectionRootProps';

import {
  STATUS_PANEL_SECTION_DEFAULT_STATE_COMPONENT,
  StatusPanelSectionProps,
} from './index';

export default function StatusPanelSectionLayoutPipeline({
  headerName,
  headerLabel,
  headerProps,
  state,
  statusMessages,
  progress,
  progressType,
  associatedMember,
  hideForwardArrow,
  ...props
}: StatusPanelSectionProps) {
  const theme = useTheme();
  const { slots, slotProps } = useStatusPanelSectionRootProps();
  const currentAuth = useAuth();

  const stateProps = slots.stateProps[state?.toLocaleLowerCase() ?? 'default']
    ? slots.stateProps[state?.toLocaleLowerCase() ?? 'default']
    : slots.stateProps['default'];
  return (
    <>
      {headerLabel && (
        <>
          <Box
            {...headerProps}
            sx={[
              {
                display: 'flex',
                py: 2,
                px: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                alignSelf: 'stretch',
                color: 'dusk.one',
                backgroundColor: 'dusk.six',
                height: '100%',
                position: 'relative',
              },
              ...(Array.isArray(headerProps?.sx)
                ? headerProps?.sx ?? []
                : [headerProps?.sx]),
            ]}
          >
            <Box sx={{ display: 'flex' }}>
              {!(hideForwardArrow ?? false) && (
                <Box
                  sx={{
                    display: 'flex',
                    padding: '2px',
                    alignItems: 'flex-start',
                    gap: '8px',
                    position: 'absolute',
                    left: -10,
                    top: 72 / 2 - 16 / 2,
                    borderRadius: '12px',
                    backgroundColor: 'dusk.six',
                  }}
                >
                  <ArrowForward
                    sx={{ height: 16, width: 16, color: 'dusk.one' }}
                  />
                </Box>
              )}

              <Typography
                variant={'buttonsmall'}
                sx={{
                  textAlign: 'center',
                  textOrientation: 'sideways',
                  textDecoration: 'none',
                  writingMode: 'vertical-lr',
                  transform: 'rotate(180deg)',
                  borderLeftColor: theme.palette.midnight.one,
                }}
              >
                {headerLabel}
              </Typography>
            </Box>
          </Box>
        </>
      )}

      <Box
        {...props}
        sx={[
          {
            display: 'flex',
            backgroundColor: 'controlBackground.main',
            ...(headerLabel
              ? {
                  borderLeftWidth: 1,
                  borderLeftColor: 'midnight.two',
                  borderLeftStyle: 'solid',
                }
              : {}),
          },
          props.sx as SystemProps,
        ]}
      >
        <Box
          className={'status-panel-hide-small'}
          sx={{
            display: 'flex',

            paddingX: 2,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            alignSelf: 'stretch',
            borderLeftWidth: 1,
            borderLeftColor: 'midnight.one',
            borderLeftStyle: 'solid',
          }}
        >
          {slots.HeaderIconComponent && (
            <slots.HeaderIconComponent
              sx={[
                {
                  height: '32px',
                  width: '32px',
                  margin: '0 auto',
                  alignSelf: 'center',
                  color: 'brandBlue.main',
                },
                ...(Array.isArray(slots.headerIcon?.sx)
                  ? slots.headerIcon?.sx
                  : [slots.headerIcon?.sx]),
              ]}
              {...(associatedMember
                ? {
                    displayName: associatedMember?.displayName,
                    profilePic: associatedMember?.properties?.['picture.url'],
                    tabularService: validate(associatedMember.id),
                    currentUser:
                      currentAuth.user.email === associatedMember?.user?.email,
                  }
                : {})}
              {...slots.headerIcon}
              {...slotProps?.headerIconComponent}
            />
          )}
        </Box>
        {headerName &&
          slots.StateIconComponent &&
          (slots.StateIconComponent !=
            STATUS_PANEL_SECTION_DEFAULT_STATE_COMPONENT ||
            state != undefined) && (
            <Box
              className={'status-panel-hide-tiny'}
              sx={{
                flexGrow: 1,
                display: 'flex',
                paddingX: 2,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: 1,
                alignSelf: 'stretch',
                borderLeftWidth: 1,
                borderLeftColor: 'midnight.one',
                borderLeftStyle: 'solid',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingY: 2,
                  justifyContent: 'center',
                  justifyItems: 'stretch',
                  alignItems: 'center',
                  gap: 1,
                  alignSelf: 'stretch',
                  minWidth: 130,
                }}
              >
                {headerName && (
                  <Typography
                    variant={'overline'}
                    sx={{
                      display: '-webkit-box',
                      '-webkit-box-orient': 'vertical',
                      alignSelf: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordWrap: 'break-word',
                      '-webkit-line-clamp': '1',
                    }}
                  >
                    {headerName}
                  </Typography>
                )}
                {slots.StateIconComponent &&
                  (slots.StateIconComponent !=
                    STATUS_PANEL_SECTION_DEFAULT_STATE_COMPONENT ||
                    state != undefined) && (
                    <slots.StateIconComponent
                      sx={{ p: 0, paddingX: 1 }}
                      {...stateProps?.chipProps}
                      label={stateProps?.displayName ?? state}
                      icon={
                        <CustomIcon
                          sx={{ height: 16, width: 16 }}
                          {...stateProps?.iconProps}
                        />
                      }
                      size={`small`}
                      showBorder
                      {...slotProps?.stateIconComponent}
                    />
                  )}
              </Box>
            </Box>
          )}
        {statusMessages && statusMessages.length > 0 && (
          <Box
            sx={{
              width: 1,
              borderLeftWidth: statusMessages?.length ? 1 : 0,
              borderLeftColor: 'midnight.one',
              borderLeftStyle: 'solid',
              display: 'flex',
              flexDirection: 'column',
              paddingX: 2,
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: 2,
              alignSelf: 'stretch',
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: 1,
                flexGrow: 1,
              }}
            >
              {statusMessages.map((eachStatusMessage, idx) => (
                <Box
                  key={`status-message-${idx}`}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    wordWrap: 'break-word',
                    '-webkit-line-clamp': '1',
                    minWidth: 200,
                  }}
                >
                  <Typography
                    variant={idx == 0 ? 'h6' : 'helperText'}
                    sx={{
                      display: '-webkit-box',
                      '-webkit-box-orient': 'vertical',
                      alignSelf: 'stretch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordWrap: 'break-word',
                      '-webkit-line-clamp': '1',
                      wordBreak: 'break-all',
                    }}
                  >
                    {eachStatusMessage}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
