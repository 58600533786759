import React, { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useModal, create, show } from '@ebay/nice-modal-react';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Form, Formik } from 'formik';

import { currentUser } from '../../graphql/user';
import { UpdateWarehouseMutation } from '../../graphql/warehouse';
import { Tabutton } from '../Button/Tabutton';
// @ts-ignore
import StatusBars from '../Feedback/StatusBars';
import TextField from '../Forms/TextField';
import { ModalContentType } from '../TableSettings/types';

export interface RenameResponse {
  newName: string;
}

interface RenameWarehouseDialogProps extends ModalContentType {
  warehouseId?: string;
  warehouseName?: string;
}
const RenameWarehouseDialog = create(
  ({
    warehouseId,
    warehouseName,
    recoveryWarningText,
  }: RenameWarehouseDialogProps) => {
    const modal = useModal();

    const [mutationError, setMutationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [renameWarehouse, { error }] = useMutation(UpdateWarehouseMutation);

    const initialValues: RenameResponse = {
      newName: warehouseName || '',
    };

    const handleSubmitForm = useCallback(
      async ({ newName }: RenameResponse) => {
        const { errors } = await renameWarehouse({
          variables: {
            warehouseId,
            name: newName,
          },
          errorPolicy: 'all',
          refetchQueries: [
            {
              query: currentUser,
            },
          ],
          awaitRefetchQueries: true,
        });
        if (errors && error) {
          setErrorMessage(error?.message);
          setMutationError(true);
        }
        if (errors && errors.length) {
          const errormessage =
            errors['0']?.extensions?.response?.body?.error?.message ||
            errors[0].message;

          setErrorMessage(errormessage);
          setMutationError(true);
        } else {
          modal.resolve({ newName });
          modal.hide();
        }
      },
      [renameWarehouse],
    );

    return (
      <Dialog
        open={modal.visible}
        fullWidth
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
          {({ isSubmitting }) => (
            <Form>
              <DialogTitle>Rename warehouse</DialogTitle>

              <DialogContent sx={{ ml: 2, mt: 1 }}>
                <Box
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.sunrise.two}`,
                    borderRadius: '4px',
                    backgroundColor: theme.palette.sunrise.half,
                    p: 2,
                    display: 'flex',
                    mb: 2,
                  })}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      pr: 2,
                    }}
                  >
                    <WarningIcon sx={{ color: 'sunrise.seven' }} />
                  </Box>
                  <Box sx={{ color: 'midnight.seven' }}>
                    <Typography variant={`subtitle2`} mb={`4px`}>
                      Warning
                    </Typography>
                    <Typography variant={`body2`}>
                      {recoveryWarningText}
                    </Typography>
                  </Box>
                </Box>
                <TextField
                  fullWidth
                  name={`newName`}
                  label={`New warehouse name`}
                />

                <StatusBars
                  errorDisplay={mutationError}
                  setErrorDisplay={setMutationError}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                />

                <DialogActions>
                  <Tabutton onClick={() => modal.hide()}>Cancel</Tabutton>
                  <Tabutton
                    type={`submit`}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    loadingBtn
                  >
                    Rename
                  </Tabutton>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  },
);

export function showRenameWarehouseDialog(params: RenameWarehouseDialogProps) {
  return show<RenameResponse, any, RenameWarehouseDialogProps>(
    RenameWarehouseDialog,
    params,
  );
}
