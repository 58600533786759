import { ApolloError } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';

export function getApolloErrorMessage(error: any) {
  const errorObj =
    error.graphQLErrors && error.graphQLErrors.length > 0
      ? error?.graphQLErrors['0']
      : Array.isArray(error) && error.length > 0
      ? error[0]
      : error;

  return (
    errorObj?.extensions?.response?.body?.error?.message || errorObj.message
  );
}

export function getDetailedApolloError(error: any) {
  const errorObj =
    error.graphQLErrors && error.graphQLErrors.length > 0
      ? error?.graphQLErrors['0']
      : Array.isArray(error) && error.length > 0
      ? error[0]
      : error;

  return {
    message:
      errorObj?.extensions?.response?.body?.error?.message || errorObj.message,
    type: errorObj.extensions.response.body.error.type,
    code: errorObj.extensions.response.body.error.code,
  };
}
