import React from 'react';

import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';

import { StorageProfile, Warehouse } from '../../graphql/gen/graphql';
import CreateWarehouse from '../Forms/CreateWarehouse';

interface CreateWarehouseDialogProps {
  orgName: string;
  onWarehouseCreated: (warehouse: Warehouse) => void;
  preSelectedBucket?: StorageProfile;
}
const CreateWarehouseDialog = create(
  ({
    orgName,
    onWarehouseCreated,
    preSelectedBucket,
  }: CreateWarehouseDialogProps) => {
    const modal = useModal();

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <CreateWarehouse
          orgName={orgName}
          onCancel={modal.hide}
          onWarehouseCreated={(warehouse: Warehouse) => {
            onWarehouseCreated(warehouse);
            modal.hide();
          }}
          preSelectedBucket={preSelectedBucket}
        />
      </Dialog>
    );
  },
);

export default CreateWarehouseDialog;

export function showCreateWarehouseDialog(
  orgName: string,
  onWarehouseCreated: (warehouse: Warehouse) => void,
  preSelectedBucket?: StorageProfile,
) {
  return show<boolean, any, CreateWarehouseDialogProps>(CreateWarehouseDialog, {
    orgName,
    onWarehouseCreated,
    preSelectedBucket,
  });
}
