import React, { useEffect } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import { useUserAgent } from 'react-ua';

import { Grid, Stack, StackProps } from '@mui/material';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

interface SparkDownloadCmdProps extends StackProps {
  directSparkDownloadUrl: string;
  onCommandFormed?: (cmd: string) => void;
}
export const SparkDownloadCmdTemplate = ({
  directSparkDownloadUrl,
  onCommandFormed,
  ...props
}: SparkDownloadCmdProps): JSX.Element => {
  const ua = useUserAgent();

  const lineContinuationChar =
    ua.os.name === 'Windows' ? '`' : ua.os.name === 'Mac OS' ? '\\' : '\\';

  const fileNameParts = directSparkDownloadUrl.split('/');
  const fileName = fileNameParts[fileNameParts.length - 1];
  const cmdTemplate = `curl -OL ${directSparkDownloadUrl}
tar zxvf ${fileName}
cd ${fileName.substring(0, 11)}*
`;

  useEffect(() => {
    if (onCommandFormed) {
      onCommandFormed(cmdTemplate);
    }
  }, [cmdTemplate]);

  return (
    <Stack direction="column" {...props}>
      <Grid container width={1} alignItems={'center'}>
        <Grid
          item
          xs={12}
          sx={{
            pre: {
              m: 0,
              height: 1,
            },
          }}
        >
          {' '}
          <SyntaxHighlighter
            language="bash"
            style={{ ...dracula, margin: 0 as React.CSSProperties }}
            showLineNumbers={false}
          >
            {cmdTemplate}
          </SyntaxHighlighter>
        </Grid>
      </Grid>
    </Stack>
  );
};
