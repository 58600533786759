import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import {
  AddOutlined,
  AltRoute,
  ContentCopy,
  ExpandLess,
  ExpandMore,
  History as HistoryIcon,
} from '@mui/icons-material';
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  LinearProgress,
  Popper,
} from '@mui/material';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { includes } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { enqueueSnackbar } from 'notistack';

import { useAuth } from '../../context/auth-context';
import { Table } from '../../graphql/gen/graphql';
import {
  deleteTag,
  rollbackSnapshot,
  TableSnapshotQuery,
} from '../../graphql/table';
import { formatBytes } from '../../utils/numbers';
import { getDifferenceInHours, relativeTimeAutoFormat } from '../../utils/time';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import { BranchSelector } from '../BranchSelector/BranchSelector';
import { Tacard } from '../Card/Tacard';
import {
  CustomPaging,
  DataGridToolBar,
} from '../DataGridToolbar/DataGridToolbar';
import { ConfirmationDialog } from '../Modals/ConfirmationDialog';
import CreateBranchDialog from '../Modals/CreateBranchDialog';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { TagPopover } from '../Popovers/TagPopover';
import { BranchDisplay } from './BranchDisplay';
import { SnapshotProp, SnapshotRefProp } from './SnapshotView';
import { TagDisplay } from './TagDisplay';

interface SnapshotTableProps {
  table: Table;
  userCanEdit: boolean;
  numSnapshots: number;
  snapshots: SnapshotProp[];
  loadingSnapshots: boolean;
  refreshResourceContent: () => void;
  page: number;
  rowsPerPage: number;
  handleChangePage: (page: number) => void;
  handleChangeRowsPerPage: (pageSize: number) => void;
  detailPanelExpandedRowIds: GridRowId[];
  setDetailPanelExpandedRowIds: React.Dispatch<
    React.SetStateAction<GridRowId[]>
  >;
  branchRefs: SnapshotRefProp[];
  selectedBranch: string;
  handleChangeBranch: (branch: string) => void;
  handleBranchCreated: () => void;
  isPipelineTarget?: boolean;
}

export default function SnapshotTable({
  table,
  userCanEdit,
  numSnapshots,
  snapshots,
  loadingSnapshots,
  refreshResourceContent,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  detailPanelExpandedRowIds,
  setDetailPanelExpandedRowIds,
  branchRefs,
  selectedBranch,
  handleChangeBranch,
  handleBranchCreated,
  isPipelineTarget = false,
}: SnapshotTableProps) {
  const { user } = useAuth();
  const {
    organization: organizationName,
    warehouse: warehouseName,
    database,
    table: tableName,
  } = useParams();

  const [snapshotTarget, setSnapshotTarget] = useState<number | null>(null);
  const [snapshotRollbackTarget, setSnapshotRollbackTarget] = useState<
    number | null
  >(null);
  const [tagToRemove, setTagToRemove] = useState({
    snapshotId: null,
    tagName: '',
  });
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<'Branch' | 'Tag'>('Branch');

  const [rollbackTo, { loading }] = useMutation(rollbackSnapshot, {
    refetchQueries: [
      {
        query: TableSnapshotQuery,
        variables: {
          warehouseId: user.getWarehouse(organizationName, warehouseName).id,
          database,
          table: tableName,
          page: page + 1,
          pageSize: rowsPerPage,
          branch: selectedBranch,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(`Successfully rolled back to ${snapshotRollbackTarget}`, {
        variant: 'success',
      });
      setSnapshotRollbackTarget(null);
    },
    onError: (error) => {
      enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
        variant: 'error',
      });
    },
  });

  const [deleteTagMutation] = useMutation(deleteTag, {
    refetchQueries: [
      {
        query: TableSnapshotQuery,
        variables: {
          warehouseId: user.getWarehouse(organizationName, warehouseName).id,
          database,
          table: tableName,
          page: page + 1,
          pageSize: rowsPerPage,
          branch: selectedBranch,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => setTagToRemove({ snapshotId: null, tagName: '' }),
    onError: (error) => {
      enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
        variant: 'error',
      });
    },
  });

  const columns = useMemo(() => {
    return [
      {
        field: 'timestamp_ms',
        headerName: 'Timestamp',
        minWidth: 250,
        flex: 0.5,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title={new Date(params.value).toUTCString()}
            placement={'top'}
          >
            <Typography variant={`inputText`} whiteSpace={`nowrap`}>
              {getDifferenceInHours(new Date(), new Date(params.value)) < 24
                ? relativeTimeAutoFormat(params.value)
                : new Date(params.value).toUTCString()}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'operation',
        headerName: 'Operation',
        minWidth: 100,
        flex: 0.5,
        sortable: false,
      },
      {
        field: 'total_records',
        headerName: 'Total Records',
        minWidth: 100,
        flex: 0.5,
        sortable: false,
      },
      {
        field: 'snapshot_id',
        headerName: 'Snapshot ID',
        minWidth: 200,
        flex: 0.5,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography minWidth={160}>{params.value}</Typography>
              <Tooltip title="Copy to clipboard" placement={'top'} arrow>
                <IconButton
                  sx={(theme) => ({
                    padding: '4px',
                    border: `1px solid ${theme.palette.midnight.two}`,
                    '&:hover': {
                      backgroundColor: theme.palette.sky.one,
                    },
                    '&:active': {
                      backgroundColor: theme.palette.sky.two,
                    },
                    '&:focus': {
                      border: `1px solid ${theme.palette.sky.seven}`,
                    },
                    height: '24px',
                    width: '24px',
                  })}
                  onClick={() => {
                    navigator.clipboard.writeText(params.value);
                  }}
                >
                  <ContentCopy sx={{ height: 16, width: 16 }} />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        ),
      },
      {
        field: 'tag',
        headerName: 'References',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              {params.row.branches &&
                [...params.row.branches]
                  ?.sort((a: string, b: string) => {
                    if (a === 'main') return -1;
                    else if (b === 'main') return 1;
                    return a.localeCompare(b);
                  })
                  .map((branch: string) => (
                    <Box
                      key={branch}
                      maxWidth={params.colDef.computedWidth}
                      overflow={'hidden'}
                    >
                      <BranchDisplay
                        name={branch}
                        sx={{
                          mr: 1,
                          my: 0.25,
                          cursor: 'default',
                        }}
                        maxWidth={params.colDef.computedWidth - 80}
                      />
                    </Box>
                  ))}
              {params.value &&
                params.value.map((ref: SnapshotRefProp) => (
                  <Box
                    key={ref.name}
                    maxWidth={params.colDef.computedWidth}
                    overflow={'hidden'}
                  >
                    <PopupState variant="popper">
                      {(popupState) => (
                        <>
                          <TagDisplay
                            name={ref.name}
                            hasIcon={ref.max_ref_age_ms != null}
                            {...bindHover(popupState)}
                            sx={{
                              mr: 1,
                              my: 0.25,
                              cursor: 'default',
                            }}
                            remove={() => {
                              setTagToRemove({
                                snapshotId: params.row.snapshot_id,
                                tagName: ref.name,
                              });
                            }}
                            maxWidth={params.colDef.computedWidth - 80}
                            disabled={isPipelineTarget}
                          />
                          <Popper {...bindPopper(popupState)} placement={`top`}>
                            <TagPopover
                              timestamp={params.row.timestamp_ms}
                              maxRefAgeMs={ref.max_ref_age_ms}
                              name={ref.name}
                            />
                          </Popper>
                        </>
                      )}
                    </PopupState>
                  </Box>
                ))}
            </Box>
          </>
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 120,
        flex: 0,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip
              title="Create branch from this snapshot"
              placement={'top'}
              arrow
            >
              <IconButton
                sx={(theme) => ({
                  padding: '4px',
                  border: `1px solid ${theme.palette.midnight.two}`,
                  '&:hover': {
                    backgroundColor: theme.palette.sky.one,
                  },
                  '&:active': {
                    backgroundColor: theme.palette.sky.two,
                  },
                  '&:focus': {
                    border: `1px solid ${theme.palette.sky.seven}`,
                  },
                  height: '24px',
                  width: '24px',
                })}
                onClick={() => {
                  setType('Branch');
                  setOpen(true);
                  setSnapshotTarget(params.row.snapshot_id);
                }}
              >
                <AltRoute sx={{ height: 16, width: 16 }} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                isPipelineTarget
                  ? 'This is a target table in an active pipeline. Tags are disabled.'
                  : 'Create tag for this snapshot'
              }
              placement={'top'}
              arrow
            >
              <span>
                <IconButton
                  sx={(theme) => ({
                    ml: 1,
                    padding: '4px',
                    border: `1px solid ${theme.palette.midnight.two}`,
                    '&:hover': {
                      backgroundColor: theme.palette.sky.one,
                    },
                    '&:active': {
                      backgroundColor: theme.palette.sky.two,
                    },
                    '&:focus': {
                      border: `1px solid ${theme.palette.sky.seven}`,
                    },
                    height: '24px',
                    width: '24px',
                  })}
                  onClick={() => {
                    setType('Tag');
                    setOpen(true);
                    setSnapshotTarget(params.row.snapshot_id);
                  }}
                  disabled={isPipelineTarget}
                >
                  <AddOutlined sx={{ height: 16, width: 16 }} />
                </IconButton>
              </span>
            </Tooltip>
            {RollBackButton(
              params.row as SnapshotProp,
              setSnapshotRollbackTarget,
              userCanEdit,
              isPipelineTarget,
              branchRefs,
              selectedBranch,
            )}
          </Box>
        ),
      },
    ];
  }, [snapshots, userCanEdit, isPipelineTarget]);

  const rows = useMemo(
    () =>
      snapshots.map((s) => ({
        ...s,
        operation: s.summary.operation,
        total_records: s.summary['total-records']
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      })),
    [snapshots],
  );

  const apiRef = useGridApiRef();

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(newIds);
    },
    [],
  );

  const branchFilter = useMemo(
    () => (
      <BranchSelector
        branchRefs={branchRefs || []}
        selectedBranch={selectedBranch}
        handleChangeBranch={handleChangeBranch}
        sx={{ height: 28 }}
      />
    ),
    [branchRefs, handleChangeBranch],
  );

  return (
    <>
      <Tacard grid>
        <DataGridPro
          apiRef={apiRef}
          pagination
          getRowHeight={() => 'auto'}
          autoHeight
          rows={rows || []}
          columns={columns as GridColumns}
          headerHeight={48}
          disableColumnMenu
          isRowSelectable={() => false}
          getRowId={(r) => r.snapshot_id}
          initialState={{
            pagination: { page: 0, pageSize: 100 },
          }}
          paginationMode="server"
          rowCount={numSnapshots || 0}
          page={page}
          pageSize={rowsPerPage}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: OhNoesRows,
            NoResultsOverlay: OhNoesRows,
            Toolbar: DataGridToolBar,
            Pagination: CustomPaging,
            DetailPanelExpandIcon: () => <ExpandMore className="icon" />,
            DetailPanelCollapseIcon: () => <ExpandLess className="icon" />,
          }}
          componentsProps={{
            noRowsOverlay: {
              buttonControl: (
                <Typography variant={`h1`} color={`brandBlue.main`}>
                  No matching snapshot
                </Typography>
              ),
            },
            noResultsOverlay: {
              buttonControl: (
                <Typography variant={`h1`} color={`brandBlue.main`}>
                  No matching snapshot
                </Typography>
              ),
            },
            toolbar: {
              headerName: (
                <Box display={'flex'} paddingLeft={2} alignItems={'center'}>
                  {tableName}
                </Box>
              ),
              apiRef: apiRef,
              setPage: handleChangePage,
              pageSize: rowsPerPage,
              setPageSize: handleChangeRowsPerPage,
              rowCount: numSnapshots || 0,
              pageAtNumber: 0,
              refreshResourceContent: {
                title: 'Refresh list',
                action: refreshResourceContent,
              },
              secondRowContent: branchFilter,
              rowsPerPageOptions: [100, 200, 500],
            },
            pagination: {
              apiRef: apiRef,
              setPage: handleChangePage,
              pageSize: rowsPerPage,
              setPageSize: handleChangeRowsPerPage,
              rowCount: numSnapshots || 0,
              rowsPerPageOptions: [100, 200, 500],
            },
          }}
          sx={{
            border: 'none',
            '.MuiDataGrid-columnHeaders': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
            '.MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: 'dusk.half',
            },
          }}
          loading={loadingSnapshots || !snapshots}
          getDetailPanelHeight={({ row }) => 'auto'}
          getDetailPanelContent={({ row }) => (
            <SummaryTableInset snapshot={row} />
          )}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          onDetailPanelExpandedRowIdsChange={
            handleDetailPanelExpandedRowIdsChange
          }
        />
      </Tacard>

      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>
      <ConfirmationDialog
        open={snapshotRollbackTarget != null}
        title="Rollback to snapshot?"
        acceptText="Rollback"
        onDismiss={() => setSnapshotRollbackTarget(null)}
        onAccept={() =>
          rollbackTo({
            variables: {
              warehouseId: user.getWarehouse(organizationName, warehouseName)
                .id,
              database,
              table: tableName,
              snapshotId: snapshotRollbackTarget,
              ref: selectedBranch,
            },
          })
        }
      >
        Rollback {selectedBranch} to snapshot {snapshotRollbackTarget}?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={tagToRemove.snapshotId != null}
        title={`Remove ${tagToRemove.tagName}?`}
        acceptText="Remove"
        onDismiss={() => {
          setTagToRemove({ snapshotId: null, tagName: '' });
        }}
        onAccept={() => {
          deleteTagMutation({
            variables: {
              warehouseId: user.getWarehouse(organizationName, warehouseName)
                .id,
              database,
              table: tableName,
              name: tagToRemove.tagName,
              snapshotId: tagToRemove.snapshotId,
            },
          });
          setTagToRemove({ snapshotId: null, tagName: '' });
        }}
      />

      <CreateBranchDialog
        snapshotId={snapshotTarget}
        onBranchCreated={handleBranchCreated}
        open={open}
        setOpen={setOpen}
        type={type}
        table={table}
      />
    </>
  );
}

const RollBackButton = (
  snapshot: SnapshotProp,
  setSnapshotRollbackTarget: React.Dispatch<
    React.SetStateAction<number | null>
  >,
  userCanEdit: boolean,
  isPipelineTarget: boolean,
  branchRefs: SnapshotRefProp[],
  selectedBranch: string,
) => {
  const currentSnapshot = 'Current snapshot cannot rollback';
  const userPermission = 'Update permissions are required to rollback';
  const pipelineTarget =
    'This is a target table in an active pipeline. Rollback is disabled.';
  const isCurrent =
    snapshot.snapshot_id ===
    branchRefs.find((ref) => ref.name === selectedBranch)?.snapshot_id;
  const disabled = isCurrent || !userCanEdit || isPipelineTarget;

  const getTitle = () => {
    if (isCurrent) {
      return currentSnapshot;
    } else if (!userCanEdit) {
      return userPermission;
    } else if (isPipelineTarget) {
      return pipelineTarget;
    }
    return 'Rollback';
  };
  return (
    <Tooltip placement={'top'} arrow title={getTitle()}>
      <span>
        <IconButton
          disabled={disabled}
          sx={(theme) => ({
            ml: 1,
            padding: '4px',
            border: `1px solid ${theme.palette.midnight.two}`,
            '&:hover': {
              backgroundColor: theme.palette.sky.one,
            },
            '&:active': {
              backgroundColor: theme.palette.sky.two,
            },
            '&:focus': {
              border: `1px solid ${theme.palette.sky.seven}`,
            },
            height: '24px',
            width: '24px',
          })}
          onClick={() => setSnapshotRollbackTarget(snapshot.snapshot_id)}
        >
          <HistoryIcon
            sx={{
              height: 16,
              width: 16,
            }}
          />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const commaGuys = [
  'total-records',
  'total-equality-deletes',
  'total-position-deletes',
  'total-delete-files',
  'total-data-files',
  'changed-partition-count',
  'deleted-records',
  'added-records',
  'deleted-data-files',
  'added-data-files',
];

function SummaryTableInset({ snapshot }: { snapshot: SnapshotProp }) {
  return (
    <Box
      sx={{ margin: 1 }}
      id={`snapshot-table-summary-${snapshot.snapshot_id}`}
    >
      <Typography variant="h6" gutterBottom component="div">
        Summary
      </Typography>
      <MuiTable
        size="small"
        aria-label="snapshot summary"
        style={{ display: 'inline-block' }}
      >
        <TableBody>
          {Object.entries(snapshot.summary).map(
            ([key, value]: [key: string, value: any]) => (
              <TableRow key={snapshot.snapshot_id + key}>
                <TableCell style={{ fontWeight: 600 }} align="right">
                  {key}
                </TableCell>
                <TableCell>
                  {key.endsWith('size')
                    ? formatBytes(value)
                    : includes(commaGuys, key)
                    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : value}
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </MuiTable>
    </Box>
  );
}
