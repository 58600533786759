import React from 'react';

import { Typography, Box, List, ListItem } from '@mui/material';

import { StorageType } from '../../../graphql/gen/graphql';
import { ExternalBreadcrumbs } from '../../CreateStorageBucket/BucketSteps/helpers';
import { getBundle } from '../helpers';

export const DatabricksClusterLibraries = ({
  storageType,
}: {
  storageType: StorageType;
}) => {
  const addLibraryBreadcrumbs = ['Compute', 'Preview'];
  const sparkBundle =
    process.env.REACT_APP_SPARK_WIZ_ICEBERG_RUNTIME?.split(':')[1];
  const bundle = getBundle(storageType);
  const bundleSplit = bundle?.split(':')[1];
  return (
    <>
      <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', width: 1 }}>
        <ExternalBreadcrumbs breadcrumbs={addLibraryBreadcrumbs} />
        <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              Open newly created cluster to add the following libraries:{' '}
              <b>{process.env.REACT_APP_SPARK_WIZ_ICEBERG_RUNTIME}</b> and{' '}
              <b>{bundle}</b>.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0.5 }}>
            <Typography variant={'body1'}>
              Select <b>Libraries</b> {'>'} <b>Install new</b> {'>'}{' '}
              <b>Maven</b> then click <b>Search Packages</b>.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0.5 }}>
            <Typography variant={'body1'}>
              Click dropdown and select <b>Maven central</b>.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0.5 }}>
            <Typography variant={'body1'}>
              In Query box search for <b>apache iceberg spark</b>, find the same
              runtime set in cluster: <b>{sparkBundle}</b> release{' '}
              <b>{process.env.REACT_APP_ICEBERG_VERSION}</b> select and install.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0.5 }}>
            <Typography variant={'body1'}>
              Repeat <b>steps 2 and 3</b>, this time query for{' '}
              <b>{bundleSplit}</b> release{' '}
              <b>{process.env.REACT_APP_ICEBERG_VERSION}</b> select and install.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0.5 }}>
            <Typography variant={'body1'}>
              Once your cluster is built, open a new notebook to test the
              connection, click <b>continue</b>.
            </Typography>
          </ListItem>
        </List>
      </Box>
    </>
  );
};
