import React, { useEffect, useState } from 'react';

import { Box, BoxProps, IconButton, Tooltip, useTheme } from '@mui/material';

import {
  formatTimestamp,
  getDifferenceInDays,
  relativeTimeAutoFormat,
} from '../../utils/time';

interface RelativeTimeDisplayProps extends BoxProps {
  dateString: string;
  liveUpdate?: boolean;
  prefix?: string;
  suffix?: string;
}

export default function RelativeTimeDisplay({
  dateString,
  liveUpdate,
  prefix = '',
  suffix = '',
  ...props
}: RelativeTimeDisplayProps) {
  const dateFromString = new Date(dateString);
  const [now, setNow] = useState(new Date());
  const isRecent = Math.abs(getDifferenceInDays(now, dateFromString)) <= 3;

  useEffect(() => {
    if (isRecent && liveUpdate) {
      setTimeout(() => setNow(new Date()), 1000);
    }
  }, [now, isRecent]);
  if (dateString === undefined || dateString === null) {
    return <></>;
  }
  return (
    <Tooltip title={formatTimestamp(dateString)} arrow>
      <Box {...props}>
        {isRecent
          ? `${prefix}${relativeTimeAutoFormat(dateString)}${suffix}`
          : `${prefix}${formatTimestamp(dateString)}${suffix}`}
      </Box>
    </Tooltip>
  );
}
