import { gql } from '@apollo/client';

import { FRAGMENTS } from './fragments';

//queries
export const databaseQuery = gql`
  query Database(
    $organizationId: String!
    $warehouseId: String!
    $database: String!
    $daysBack: Int
  ) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouse(id: $warehouseId) {
        id
        name
        database(name: $database) {
          id
          warehouseId
          name
          privileges
          createdAt
          createdBy
          lastModified
          lastModifiedBy
          tables {
            ...TableFields
            statistics(daysBack: $daysBack) {
              ...StatisticsFields
            }
            privileges
          }
        }
        storageProfile {
          id
          storageType
          bucket
        }
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.stats}
  ${FRAGMENTS.extTable}
`;
export const listTablesQuery = gql`
  query ListTablesQuery($warehouseId: String!, $database: String!) {
    listTablesByName(warehouseId: $warehouseId, database: $database) {
      ...TableRefFields
    }
  }
  ${FRAGMENTS.tableRef}
`;
export const databasePeersQuery = gql`
  query DatabasePeersQuery($organizationId: String!, $warehouseId: String!) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouse(id: $warehouseId) {
        id
        name
        databases {
          id
          name
          warehouseId
        }
      }
    }
  }
  ${FRAGMENTS.org}
`;

export const DatabaseDashboardCardsQuery = gql`
  query DatabaseDashboardQuery(
    $organizationId: String!
    $warehouseId: String!
    $database: String!
    $daysBack: Int
  ) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouse(id: $warehouseId) {
        id
        name
        database(name: $database) {
          warehouseId
          name
          statistics(daysBack: $daysBack) {
            ...StatisticsFields
            totalTables
          }
          compactionSummary(daysBack: $daysBack) {
            ...CompactionFields
          }
        }
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.stats}
  ${FRAGMENTS.compaction}
`;

//mutations
export const createDatabase = gql`
  mutation CreateDatabase(
    $organizationId: String!
    $warehouseId: String!
    $name: String!
  ) {
    createDatabase(
      organizationId: $organizationId
      warehouseId: $warehouseId
      name: $name
    ) {
      id
      name
      warehouseId
    }
  }
`;

export const deleteDatabase = gql`
  mutation DeleteDatabaseMutation($warehouseId: String!, $database: String!) {
    deleteDatabase(warehouseId: $warehouseId, database: $database) {
      databaseDropped
      undroppedTableNames {
        name
      }
    }
  }
`;
