import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Formik, FormikValues, Form } from 'formik';

import { Role, CredentialTypes } from '../../../graphql/gen/graphql';
import { createOrgCredential } from '../../../graphql/organization';
import { getApolloErrorMessage } from '../../../utils/ApolloUtils';
import { getLogger } from '../../../utils/logging';
import { Talert } from '../../Alert/Talert';
import { Tabutton } from '../../Button/Tabutton';
import { RoleSelector } from '../../Forms/RoleSelector';
import TextField from '../../Forms/TextField';
import { serviceMappingSchema } from '../helpers';
import { CreateOrgCredentialDialogResults } from './CreateOrgCredentialDialog';

export function CreateServiceMappingDialog({
  organizationId,
  isOpen,
  onDismiss,
  onCredentialCreated,
  preSelectedRoleId,
  modalTitle,
}: {
  organizationId: string;
  isOpen: boolean;
  onDismiss: () => void;
  onCredentialCreated: (credential: Credential) => void;
  preSelectedRoleId?: string;
  modalTitle?: string;
}) {
  const [createCredentialMutation, { error }] =
    useMutation(createOrgCredential);
  const [errorMessage, setErrorMessage] = useState('');
  const logger = getLogger('components.Credentials.CreateIAMRoleMappingDialog');
  const [result, setResult] = useState('');

  const onSubmit = async (values: FormikValues) => {
    await createCredentialMutation({
      variables: {
        organizationId,
        name: values.name.trim(),
        type: CredentialTypes.Service,
        roleId: values.roleId,
      },
    })
      .then((data) => {
        if (data) {
          setResult(data.data.createOrgCredential.credential);
        }
      })
      .catch((error) => {
        logger.debug(getApolloErrorMessage(error));
        setErrorMessage('Something went wrong. Please try again.');
      });
  };

  const handleCancel = () => {
    setErrorMessage('');
    onDismiss();
  };

  return result ? (
    <CreateOrgCredentialDialogResults
      credential={result}
      onDismiss={() => {
        setResult('');
        // @ts-ignore
        onCredentialCreated(result);
      }}
    />
  ) : (
    <Dialog open={isOpen}>
      <Formik
        initialValues={{
          name: '',
          roleId: preSelectedRoleId || '',
        }}
        validationSchema={serviceMappingSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogTitle sx={{ p: 2 }}>
              {modalTitle
                ? modalTitle
                : 'Connect a credential to a Tabular role'}
              <Typography
                variant={'helperText'}
                display={'block'}
                sx={{ pt: 2, pb: 0, pr: 2 }}
              >
                A service account enables accessing Tabular with the privileges
                of a specified role without requiring an authenticated user.
              </Typography>
            </DialogTitle>
            <DialogContent>
              {error && errorMessage && (
                <Talert severity="error" sx={{ mb: 1 }}>
                  <Typography>{errorMessage}</Typography>
                </Talert>
              )}
              <TextField
                autoFocus
                label="Credential name"
                name="name"
                id="name"
                type="text"
                fullWidth
                required
                sx={{ mt: 2 }}
              />
              {!preSelectedRoleId && <RoleSelector name="roleId" />}
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Tabutton onClick={handleCancel} disabled={isSubmitting}>
                Cancel
              </Tabutton>
              <Tabutton
                type="submit"
                disabled={isSubmitting}
                variant="contained"
              >
                Create
              </Tabutton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
