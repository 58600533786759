import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { includes } from 'lodash';

import {
  CheckResponse,
  Maybe,
  StorageProfile,
  StorageType,
} from '../../graphql/gen/graphql';
import { Tacard } from '../Card/Tacard';
import { ChipColors, Tachip } from '../Chip/Tachip';
import ADLSDetailsTemplate from './ADLSDetailsTemplate';
import GCPDetailsTemplate from './GCPDetailsTemplate';
import S3DetailsTemplate from './S3DetailsTemplate';

export const getStorageProfileDetailsTemplate = (profile: StorageProfile) => {
  switch (true) {
    case profile?.storageType === StorageType.S3:
      return <S3DetailsTemplate profile={profile} />;
    case profile?.storageType === StorageType.Gcs:
      return <GCPDetailsTemplate profile={profile} />;
    case profile?.storageType === StorageType.Adls:
      return <ADLSDetailsTemplate profile={profile} />;
  }
};

export const getValidationStatusIconForWH = (pass: boolean) => {
  if (pass) {
    return <CheckIcon fontSize={'medium'} sx={{ color: 'aurora.seven' }} />;
  } else {
    return <ErrorOutlineIcon sx={{ color: 'sunset.seven' }} />;
  }
};

const WarehouseOnlyCheckTypes = [
  'DeleteObject',
  'PutObject',
  'MultipartUpload',
  'DeleteObjectVersion',
];

export const getValidationStatusIconForImport = (
  type: string,
  pass: boolean,
) => {
  if (includes(WarehouseOnlyCheckTypes, type)) {
    return (
      <Typography variant={`body2`} sx={{ color: 'midnight.four' }}>
        NA
      </Typography>
    );
  }

  if (pass) {
    return <CheckIcon fontSize={'medium'} sx={{ color: 'aurora.seven' }} />;
  } else {
    return <ErrorOutlineIcon sx={{ color: 'sunset.seven' }} />;
  }
};

export interface ValidationDisplayProps {
  validationStatusDetails: any;
  warehouseCount: number | undefined;
  warehouseStatus: ChipColors;
  warehouseLabel: string;
  warehouseIcon: React.ReactElement;
  importStatus: ChipColors;
  importLabel: string;
  importIcon: React.ReactElement;
}

export const getThemValidationDeets = ({
  validationStatusDetails,
  warehouseCount,
  warehouseStatus,
  warehouseLabel,
  warehouseIcon,
  importStatus,
  importLabel,
  importIcon,
}: ValidationDisplayProps) => {
  return (
    <Tacard sx={{ mx: 2 }}>
      <CardHeader
        title={
          <Box
            display={`flex`}
            justifyContent={`space-between`}
            alignItems={`center`}
          >
            <Typography variant={`h6`}>Validation</Typography>
            <Box
              sx={{
                width: 360,
                display: 'flex',
              }}
            >
              <Box
                display={`flex`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignItems={`center`}
                flexBasis={'50%'}
              >
                <Typography
                  variant={`overline`}
                  sx={{ color: 'midnight.six', mb: 1 }}
                >
                  WAREHOUSES
                </Typography>
                <Box display={`flex`} alignItems={`center`} flexWrap={`nowrap`}>
                  {warehouseCount ? (
                    <Tachip
                      type={warehouseStatus}
                      label={`${warehouseCount} ${
                        warehouseCount === 1 ? 'Warehouse' : 'Warehouses'
                      }`}
                      icon={warehouseIcon}
                      size={`small`}
                    />
                  ) : (
                    <Tachip
                      type={warehouseStatus}
                      label={warehouseLabel}
                      icon={warehouseIcon}
                      size={`small`}
                    />
                  )}
                </Box>
              </Box>
              <Box
                display={`flex`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignItems={`center`}
                flexBasis={'50%'}
              >
                <Typography
                  variant={`overline`}
                  sx={{ color: 'midnight.six', mb: 1 }}
                >
                  DATA IMPORT
                </Typography>
                <Box display={`flex`} alignItems={`center`} flexWrap={`nowrap`}>
                  <Tachip
                    type={importStatus}
                    label={importLabel}
                    icon={importIcon}
                    size={`small`}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        }
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
      />
      <CardContent sx={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Grid
          container
          spacing={1}
          sx={{
            padding: 2,
          }}
        >
          {validationStatusDetails &&
            Object.keys(validationStatusDetails)
              .filter(
                (eachCheck) =>
                  eachCheck !== '__typename' &&
                  // @ts-ignore
                  validationStatusDetails[eachCheck].status !==
                    'NOT_APPLICABLE' &&
                  // @ts-ignore
                  validationStatusDetails[eachCheck].status !== 'UNCHECKED',
              )
              .map((eachCheck) => {
                const eachValidation: Maybe<CheckResponse> =
                  // @ts-ignore
                  validationStatusDetails[eachCheck];
                return eachValidation;
              })
              .sort((a, b) =>
                a?.checkPassed == b?.checkPassed ? 0 : a?.checkPassed ? -1 : 1,
              )
              .map((eachValidation, idx) => {
                if (!eachValidation) return <></>;
                return (
                  <>
                    {idx !== 0 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}

                    <Grid item xs={8}>
                      <Typography
                        variant={'body2'}
                        sx={{ color: 'midnight.nine' }}
                      >
                        {eachValidation.type}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      display={`flex`}
                      justifyContent={`center`}
                      alignItems={`center`}
                      padding={1}
                    >
                      {getValidationStatusIconForWH(
                        eachValidation.checkPassed!,
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      display={`flex`}
                      justifyContent={`center`}
                      alignItems={`center`}
                      padding={1}
                    >
                      {getValidationStatusIconForImport(
                        eachValidation.type || '',
                        eachValidation.checkPassed!,
                      )}
                    </Grid>
                    {eachValidation.message && (
                      <Grid
                        item
                        xs={12}
                        sx={(theme) => ({
                          backgroundColor: 'sunset.half',
                          border: `1px solid ${theme.palette.sunset.two}`,
                          borderRadius: '8px',
                          p: 1,
                        })}
                      >
                        <Typography
                          variant={'body2'}
                          sx={{ color: 'midnight.seven' }}
                        >
                          {eachValidation.message}
                        </Typography>
                      </Grid>
                    )}
                  </>
                );
              })}
        </Grid>
      </CardContent>
    </Tacard>
  );
};
