import React from 'react';

import { Box, Popper } from '@mui/material';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { LabelMaskMode } from '../../graphql/gen/graphql';
import { LabelPopover } from '../Popovers/LabelPopover';
import { LabelDisplay } from './LabelDisplay';
import { LabelPropertyOptions } from './helpers';

export default function LabelSelect({
  label,
}: {
  label: any;
  showDelete?: boolean;
}) {
  const maskMode =
    label?.properties?.[LabelPropertyOptions.FIELD_MASKING]?.payload?.maskMode;

  const modeEnabled =
    maskMode === LabelMaskMode.Null || maskMode === LabelMaskMode.Hide;

  return (
    <Box
      key={label.name}
      display="flex"
      p={0}
      alignItems={'center'}
      justifyContent={'flex-start'}
    >
      <Box display={'flex'}>
        <PopupState variant="popper">
          {(popupState) => (
            <>
              <Box pl={1.5} maxWidth={220}>
                <LabelDisplay
                  hasPolicy={modeEnabled}
                  labelName={label.name}
                  maskMode={maskMode}
                  {...bindHover(popupState)}
                />
              </Box>
              <Popper
                {...bindPopper(popupState)}
                placement={`left`}
                sx={{ zIndex: 9999999 }}
              >
                <LabelPopover
                  description={label.description || ''}
                  name={label.name}
                  leftPlacement={true}
                />
              </Popper>
            </>
          )}
        </PopupState>
      </Box>
    </Box>
  );
}
