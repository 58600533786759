import React, { MouseEvent } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, BoxProps, Tooltip } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

import { Tabutton } from '../Button/Tabutton';
import { Tacard } from '../Card/Tacard';
import { CustomIcon } from '../Icons/CustomIcon';

export interface TileContext {
  id: string;
  ctx?: any;
}
export interface TileProps extends BoxProps {
  id: string;
  logoImgUrl?: string;

  logoIcon?: typeof SvgIcon;
  logoAlt: string;
  handleButtonClick: (
    event: MouseEvent,
    context: TileContext,
  ) => void | undefined;
  body: JSX.Element;
  disable?: boolean;
  toolTipTitle?: string;
  ctx?: any;
}

export default function Tile({
  id,
  logoImgUrl,
  logoAlt,
  handleButtonClick,
  body,
  disable = false,
  toolTipTitle,
  logoIcon,
  ctx,
  ...props
}: TileProps) {
  const LogoIcon = logoIcon;
  return (
    <Tacard
      cardGrid
      flexDirectionRow
      {...(disable && { sx: { opacity: '0.4' } })}
    >
      <Tooltip title={disable ? toolTipTitle : ''} arrow>
        <Box
          sx={[
            (theme) => ({
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              p: 0,
              margin: '0 auto',
              overflow: 'hidden',
              width: '100%',
            }),
            (props.sx as SystemStyleObject) ?? {
              cursor: !disable ? 'pointer' : 'cursor',
            },
          ]}
          onClick={
            !disable
              ? (event) => {
                  handleButtonClick(event, { id, ctx });
                }
              : () => {}
          }
        >
          <Box display={'flex'} minHeight={'104px'} minWidth={'104px'}>
            <CustomIcon
              alt={logoAlt}
              src={logoImgUrl}
              icon={logoIcon}
              sx={{
                height: '72px',
                width: '72px',
                margin: '0 auto',
                alignSelf: 'center',
                color: 'brandBlue.main',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              py: 1.5,
              px: 1,
              backgroundColor: 'midnight.one',
              width: '100%',
              height: '100%',
              flexGrow: 3,
            }}
          >
            <Box width={'100%'}>{body}</Box>
            <Tabutton
              size={'small'}
              variant={'outlined'}
              sx={{
                mx: 2,
                p: 0,
                pr: 1,
                mt: 0,
                mb: 0,
                ml: 0,
                mr: 1.5,
                width: 24,
                height: 24,
                minWidth: 24,
                backgroundColor: 'controlBackground.main',
                '& .MuiButton-endIcon': {
                  mr: 0,
                },
              }}
              onClick={
                !disable
                  ? (event) => {
                      handleButtonClick(event, { id, ctx });
                    }
                  : () => {}
              }
              endIcon={<AddIcon />}
            />
          </Box>
        </Box>
      </Tooltip>
    </Tacard>
  );
}
