import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CardContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';

import {
  StorageProfile,
  StorageType,
  Warehouse,
} from '../../graphql/gen/graphql';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import {
  azureManualBucketSteps,
  cloudFormationSteps,
  googleManualBucketSteps,
  manualBucketSteps,
} from './BucketSteps/Steps';
import ConnectionAssistant from './ConnectionAssistant';
import { BucketCreationType } from './storage';

export default function BucketConfiguration({
  onCancel,
  orgName,
  onStorageProfileCreated,
  warehouseName,
  type,
}: {
  onCancel: () => void;
  orgName: string;
  onStorageProfileCreated: (storageProfile: StorageProfile) => void;
  warehouseName?: string;
  type: string;
}) {
  const [stepType, setStepType] = useState<BucketCreationType | undefined>(
    undefined,
  );

  return (
    <>
      {!stepType && (
        <>
          <Box display={'flex'} justifyContent={'flex-end'} sx={{ p: 1 }}>
            <IconButton onClick={onCancel}>
              <CloseIcon
                fontSize={'small'}
                sx={{ color: 'midnight.six', pointer: 'cursor' }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DialogTitle variant={'h1'} sx={{ color: 'brandBlue.main', pb: 1 }}>
              Bucket configuration
            </DialogTitle>
            <Typography variant={'subtitle1'}>
              Connect to a new bucket
            </Typography>
            <CardContent sx={{ boxShadow: 'none' }}>
              <Talert severity={'info'} sx={{ maxWidth: '325px' }}>
                <Typography variant={'helperText'}>
                  New bucket configuration requires the ability to create and
                  modify {type} resources.
                </Typography>
              </Talert>
            </CardContent>
            {type === StorageType.S3 && (
              <>
                <Tabutton
                  sx={{ mb: 3 }}
                  variant="contained"
                  onClick={() => {
                    setStepType(BucketCreationType.CloudFormation);
                  }}
                >
                  Use Connection Assistant
                </Tabutton>
                <Divider
                  sx={{ width: '70%', '& span': { color: 'midnight.seven' } }}
                >
                  Or set up manually
                </Divider>
              </>
            )}
            <Tabutton
              variant="outlined"
              sx={{ m: 3 }}
              onClick={() => {
                type === StorageType.S3
                  ? setStepType(BucketCreationType.Manual)
                  : type === StorageType.Adls
                  ? setStepType(BucketCreationType.Azure)
                  : setStepType(BucketCreationType.Google);
              }}
            >
              Manually set up bucket
            </Tabutton>
          </Box>
        </>
      )}
      {stepType && (
        <ConnectionAssistant
          orgName={orgName}
          warehouseName={warehouseName}
          steps={
            stepType === BucketCreationType.CloudFormation
              ? cloudFormationSteps
              : stepType === BucketCreationType.Google
              ? googleManualBucketSteps
              : stepType === BucketCreationType.Azure
              ? azureManualBucketSteps
              : stepType === BucketCreationType.Manual
              ? manualBucketSteps
              : manualBucketSteps
          }
          type={stepType}
          onCancel={onCancel}
          onStorageBucketCreated={onStorageProfileCreated}
          setStepType={setStepType}
        />
      )}
    </>
  );
}
