import React from 'react';

import {
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { Table } from '../../../graphql/gen/graphql';
import { DataLifecycle } from '../../../schema/events/data-lifecycle';
import { Event } from '../../../schema/events/event';
import { TableEventTypes, Verbosity } from '../TableEventsView';

type Props = {
  event: Event;
  table: Table;
  verbosity?: Verbosity;
};
export default function DataLifecycleView({
  event,
  table,
  verbosity = Verbosity.Summary,
}: Props) {
  const dataLifecycle = event.context.find(
    (ctx) => ctx.type === 'DataLifecycle',
  ) as DataLifecycle;

  let dataLifecycleMessage = (function () {
    switch (event.type) {
      case TableEventTypes.RUN_DATA_LIFECYCLE:
        return 'Scheduled';
      case TableEventTypes.STARTED_DATA_LIFECYCLE:
        return 'Started';
      case TableEventTypes.SUCCEEDED_DATA_LIFECYCLE:
        return 'Succeeded';
      default:
        return 'Failed';
    }
  })();

  switch (verbosity) {
    case Verbosity.Summary:
      return <div>Table Data Lifecycle Cleanup {dataLifecycleMessage}</div>;
    case Verbosity.Detailed:
      return (
        <>
          <Grid container margin={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TableContainer component={Paper}>
                <MuiTable aria-label="Listing">
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">
                        Deleted rows based on column:
                      </TableCell>
                      <TableCell>{dataLifecycle.max_age_column}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Older than:</TableCell>
                      <TableCell>{dataLifecycle.before_ts_ms}</TableCell>
                    </TableRow>
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
}
