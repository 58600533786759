import React from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  CardContent,
  CardHeader,
  Stack,
  Tooltip,
  Typography,
  Card,
} from '@mui/material';

import { SnowflakeConnectionInfo } from '../../../graphql/gen/graphql';
import { Talert } from '../../Alert/Talert';
import { Tabutton } from '../../Button/Tabutton';
import { Tabulink } from '../../Link/Tabulink';

export const SnowflakeDetails = ({
  connectionData,
  snowflakeEnabled,
  handleSnowflakeDelete,
  error,
}: {
  connectionData: SnowflakeConnectionInfo;
  snowflakeEnabled: boolean;
  handleSnowflakeDelete?: any;
  error?: any;
}) => {
  return (
    <Stack height={'100%'}>
      <Box sx={{ py: 2, pr: 2 }}>
        <Typography variant={`subtitle2`} sx={{ mb: 1 }}>
          Snowflake account URL
        </Typography>
        <Tabulink
          external
          href={connectionData?.accountUrl || ''}
          sx={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            variant={`inputTextLink`}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {connectionData?.accountUrl}
          </Typography>
          <OpenInNewIcon
            fontSize={`small`}
            sx={{
              color: 'brandBlue.main',
              ml: 1,
            }}
          />
        </Tabulink>

        <Box display={`flex`} flexDirection={`column`}>
          <Typography variant={`subtitle2`} sx={{ mb: 1 }}>
            Catalog integration ID
          </Typography>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Typography variant={`inputText`}>
              {connectionData?.catalogIntegrationId}
            </Typography>
            <Tooltip title={'Click to Copy'} placement={`top`} arrow>
              <ContentCopyOutlinedIcon
                fontSize={`small`}
                sx={{
                  color: 'brandBlue.main',
                  display: 'flex',
                  justifySelf: 'flex-end',
                  ml: 1,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigator.clipboard.writeText(
                    connectionData?.catalogIntegrationId || '',
                  )
                }
              />
            </Tooltip>
          </Box>
        </Box>

        <Box display={`flex`} flexDirection={`column`}>
          <Typography variant={`subtitle2`} sx={{ mb: 1 }}>
            External Volume ID
          </Typography>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Typography variant={`inputText`}>
              {connectionData?.externalVolumeId}
            </Typography>
            <Tooltip title={'Click to Copy'} placement={`top`} arrow>
              <ContentCopyOutlinedIcon
                fontSize={`small`}
                sx={{
                  color: 'brandBlue.main',
                  display: 'flex',
                  justifySelf: 'flex-end',
                  ml: 1,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigator.clipboard.writeText(
                    connectionData?.externalVolumeId || '',
                  )
                }
              />
            </Tooltip>
          </Box>
        </Box>

        <Box display={`flex`} flexDirection={`column`}>
          <Typography variant={`subtitle2`} sx={{ mb: 1 }}>
            Username
          </Typography>
          <Typography variant={`inputText`} sx={{ mb: 2 }}>
            {connectionData?.user}
          </Typography>
        </Box>

        <Box display={`flex`} flexDirection={`column`}>
          <Typography variant={`subtitle2`} sx={{ mb: 1 }}>
            Role name
          </Typography>
          <Typography variant={`inputText`} sx={{ mb: 2 }}>
            {connectionData?.role}
          </Typography>
        </Box>

        <Box display={`flex`} flexDirection={`column`}>
          <Typography variant={`subtitle2`} sx={{ mb: 1 }}>
            Database
          </Typography>
          <Typography variant={`inputText`} sx={{ mb: 2 }}>
            {connectionData?.database}
          </Typography>
        </Box>

        <Box display={`flex`} flexDirection={`column`}>
          <Typography variant={`subtitle2`} sx={{ mb: 1 }}>
            Public key
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography
              variant={`inputText`}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                mb: 2,
              }}
            >
              {connectionData?.publicKey}
            </Typography>
            <Tooltip title={'Click to Copy'} placement={`top`} arrow>
              <ContentCopyOutlinedIcon
                fontSize={`small`}
                sx={{
                  color: 'brandBlue.main',
                  display: 'flex',
                  justifySelf: 'flex-end',
                  ml: 1,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigator.clipboard.writeText(connectionData?.publicKey || '')
                }
              />
            </Tooltip>
          </Box>
        </Box>
        {handleSnowflakeDelete && (
          <Card
            sx={(theme) => ({
              borderRadius: 2,
              boxShadow: 'none',
              border: `1px solid ${theme.palette.midnight.two}`,
            })}
          >
            <CardHeader
              title={'Danger zone'}
              sx={{
                backgroundColor: 'sunset.seven',
                color: 'white',
              }}
            />
            <CardContent>
              <Box
                display={`flex`}
                flexDirection={`row`}
                justifyContent={`space-between`}
                alignItems={`center`}
                sx={(theme) => ({
                  py: 3,
                  borderBottom: `1px solid ${theme.palette.midnight.two}`,
                  '&:last-of-type': {
                    borderBottom: 'none',
                  },
                })}
              >
                <Box display={`flex`} flexDirection={`column`}>
                  <Typography variant={`subtitle1`}>
                    {'Delete configuration'}
                  </Typography>
                  <Typography variant={`body2`}>
                    {
                      'This will delete current Snowflake account configurations. These configurations are used in the SQL commands on the next step. Once deleted you will need to start over by re-adding a Snowflake URL.'
                    }
                  </Typography>
                  {snowflakeEnabled && (
                    <Talert
                      severity={'warning'}
                      sx={{ width: 'fit-content', mt: 2 }}
                    >
                      You cannot delete configuration until you deactivate the
                      connection.
                    </Talert>
                  )}
                </Box>
                <Tooltip
                  title={
                    snowflakeEnabled
                      ? 'Connection must be deactivated before deleting details'
                      : 'Delete current connection details'
                  }
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    <Tabutton
                      onClick={() => {
                        handleSnowflakeDelete();
                      }}
                      color={`error`}
                      variant={`text`}
                      sx={{ whiteSpace: 'nowrap', ml: 2 }}
                      disabled={snowflakeEnabled}
                    >
                      {'Delete'}
                    </Tabutton>
                  </Box>
                </Tooltip>
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
      {error && (
        <Talert severity={'error'} alertTitle={'Error'}>
          {error}
        </Talert>
      )}
    </Stack>
  );
};
