import React from 'react';

import { Chip, List, ListItem } from '@mui/material';

type Props = {
  partitions: { [k: string]: string }[] | undefined;
};
export default function PartitionDetailList({ partitions }: Props) {
  if (!partitions || partitions.length < 1) return null;
  return (
    <>
      <h3>Partitions:</h3>
      <List>
        {partitions.map((partition) => (
          <ListItem key={JSON.stringify(partition)}>
            {Object.entries(partition).map(([k, v]) => {
              return (
                <Chip
                  key={k + v}
                  sx={{ fontWeight: 'bold' }}
                  label={`${k}: ${v}`}
                />
              );
            })}
          </ListItem>
        ))}
      </List>
    </>
  );
}
