import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, CardHeader, Grid, IconButton } from '@mui/material';

import Tile, { TileProps } from './Tile';

export default function TileSelector({
  tiles,
  title,
  onCancel,
}: {
  tiles: TileProps[];
  title: string;
  onCancel?: () => void;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '500px',
        backgroundColor: 'controlBackground.main',
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'} p={1}>
        <CardHeader sx={{ p: 0, ml: 1, mt: 1 }} title={title || 'Select'} />
        {onCancel && (
          <IconButton onClick={onCancel}>
            <CloseIcon
              fontSize={'small'}
              sx={{ color: 'midnight.six', pointer: 'cursor' }}
            />
          </IconButton>
        )}
      </Box>
      <Grid
        container
        alignSelf={'center'}
        marginY={2}
        width={'80%'}
        paddingBottom={3}
        rowSpacing={2}
      >
        {tiles.map((eachTileProps) => (
          <Grid key={eachTileProps.id} item xs={12} alignItems={'center'}>
            <Tile key={`${eachTileProps.id}_tile`} {...eachTileProps} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
