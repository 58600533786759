import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Grid,
  LinearProgress,
  Popover,
  Popper,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColumns,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

// @ts-ignore
import { KnownFeatureFlags } from '../../context/GlobalPropsContext';

import { GlobalPropertiesContext } from '../../context';
// @ts-ignore
import { useAuth } from '../../context/auth-context';
import {
  SnapshotRefs,
  SortByDirection,
  Table,
} from '../../graphql/gen/graphql';
import { TableEventsQuery } from '../../graphql/table';
import { ESCustomPagination } from '../../pages/helpers';
import { Event } from '../../schema/events/event';
import { getLogger } from '../../utils/logging';
import { getDifferenceInHours, relativeTimeAutoFormat } from '../../utils/time';
import { Talert } from '../Alert/Talert';
import { Tavatar } from '../Avatar/Tavatar';
import { Tabutton } from '../Button/Tabutton';
import { Tacard } from '../Card/Tacard';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { DatePopover } from '../Popovers/DatePopover';
import { IPopoverState } from '../Popovers/Popover';
import UserInfo from '../ProfileMenu/UserInfo';
import { Filters } from '../SearchBar/ESCustomSearchBar';
import { SnapshotRefProp } from '../SnapshotViewer/SnapshotView';
import TableEventChip from './EventViews/TableEventChip';
import TableEventToolbar from './TableEventToolbar';
import TableEventView from './TableEventView';

const logger = getLogger(
  'components.EventsViewer.TableEventsView' /*FUTURE import.meta.url ?*/,
);

export enum TableEventTypes {
  FAILED_CHANGE_DATA_CAPTURE = 'FailedChangeDataCapture',
  RUN_CHANGE_DATA_CAPTURE = 'RunChangeDataCapture',
  STARTED_CHANGE_DATA_CAPTURE = 'StartedChangeDataCapture',
  SUCCEEDED_CHANGE_DATA_CAPTURE = 'SucceededChangeDataCapture',
  FAILED_FILE_LOAD = 'FailedFileLoad',
  RUN_FILE_LOAD = 'RunFileLoad',
  STARTED_FILE_LOAD = 'StartedFileLoad',
  SUCCEEDED_FILE_LOAD = 'SucceededFileLoad',
  TABLE_ANALYZE = 'TABLE_ANALYZE',
  CANCELLED_COMPACTION = 'CancelledCompaction',
  FAILED_COMPACTION = 'FailedCompaction',
  RUN_COMPACTION = 'RunCompaction',
  STARTED_COMPACTION = 'StartedCompaction',
  SUCCEEDED_COMPACTION = 'SucceededCompaction',
  FAILED_EXPIRE_SNAPSHOTS = 'FailedExpireSnapshots',
  RUN_EXPIRE_SNAPSHOTS = 'RunExpireSnapshots',
  STARTED_EXPIRE_SNAPSHOTS = 'StartedExpireSnapshots',
  SUCCEEDED_EXPIRE_SNAPSHOTS = 'SucceededExpireSnapshots',
  TABLE_COMPACTION_REPORT = 'TABLE_COMPACTION_REPORT',
  TABLE_CREATE = 'TABLE_CREATE',
  TABLE_DELETE = 'TABLE_DELETE',
  TABLE_DROP = 'TABLE_DROP',
  FAILED_DATA_LIFECYCLE = 'FailedDataLifecycle',
  RUN_DATA_LIFECYCLE = 'RunDataLifecycle',
  STARTED_DATA_LIFECYCLE = 'StartedDataLifecycle',
  SUCCEEDED_DATA_LIFECYCLE = 'SucceededDataLifecycle',
  FAILED_FILES_CLEANUP = 'FailedFilesCleanup',
  RUN_FILES_CLEANUP = 'RunFilesCleanup',
  STARTED_FILES_CLEANUP = 'StartedFilesCleanup',
  SUCCEEDED_FILES_CLEANUP = 'SucceededFilesCleanup',
  FAILED_REWRITE_MANIFESTS = 'FailedRewriteManifests',
  RUN_REWRITE_MANIFESTS = 'RunRewriteManifests',
  STARTED_REWRITE_MANIFESTS = 'StartedRewriteManifests',
  SUCCEEDED_REWRITE_MANIFESTS = 'SucceededRewriteManifests',
  TABLE_RENAME = 'TABLE_RENAME',
  TABLE_SCAN_REPORT = 'TABLE_SCAN_REPORT',
  TABLE_COMMIT_REPORT = 'TABLE_COMMIT_REPORT',
  TABLE_SNAPSHOT = 'TABLE_SNAPSHOT',
  TABLE_UPDATE = 'TABLE_UPDATE',
}

const search_options = {
  event_type: Object.values(TableEventTypes),
};

export class TableEventTypesUtil {
  private readonly UNKNOWN_PREFIX = 'Unk:';

  displayName(type: TableEventTypes): string {
    switch (type) {
      case TableEventTypes.TABLE_ANALYZE:
        return 'Analyze';
      case TableEventTypes.CANCELLED_COMPACTION:
      case TableEventTypes.FAILED_COMPACTION:
      case TableEventTypes.RUN_COMPACTION:
      case TableEventTypes.STARTED_COMPACTION:
      case TableEventTypes.SUCCEEDED_COMPACTION:
        return 'Compaction';
      case TableEventTypes.TABLE_CREATE:
        return 'Create';
      case TableEventTypes.FAILED_DATA_LIFECYCLE:
      case TableEventTypes.RUN_DATA_LIFECYCLE:
      case TableEventTypes.STARTED_DATA_LIFECYCLE:
      case TableEventTypes.SUCCEEDED_DATA_LIFECYCLE:
        return 'DataLifeCycle';
      case TableEventTypes.TABLE_DELETE:
        return 'Delete';
      case TableEventTypes.TABLE_DROP:
        return 'Drop';
      case TableEventTypes.FAILED_EXPIRE_SNAPSHOTS:
      case TableEventTypes.RUN_EXPIRE_SNAPSHOTS:
      case TableEventTypes.STARTED_EXPIRE_SNAPSHOTS:
      case TableEventTypes.SUCCEEDED_EXPIRE_SNAPSHOTS:
        return 'ExpireSnapshots';
      case TableEventTypes.FAILED_FILES_CLEANUP:
      case TableEventTypes.RUN_FILES_CLEANUP:
      case TableEventTypes.STARTED_FILES_CLEANUP:
      case TableEventTypes.SUCCEEDED_FILES_CLEANUP:
        return 'OrphanFilesCleanup';
      case TableEventTypes.TABLE_RENAME:
        return 'Rename';
      case TableEventTypes.TABLE_SCAN_REPORT:
        return 'ScanReport';
      case TableEventTypes.TABLE_COMMIT_REPORT:
        return 'CommitReport';
      case TableEventTypes.TABLE_SNAPSHOT:
        return 'Snapshot';
      case TableEventTypes.TABLE_UPDATE:
        return 'Update';
      case TableEventTypes.FAILED_CHANGE_DATA_CAPTURE:
      case TableEventTypes.RUN_CHANGE_DATA_CAPTURE:
      case TableEventTypes.STARTED_CHANGE_DATA_CAPTURE:
      case TableEventTypes.SUCCEEDED_CHANGE_DATA_CAPTURE:
        return 'CDC';
      case TableEventTypes.FAILED_FILE_LOAD:
      case TableEventTypes.RUN_FILE_LOAD:
      case TableEventTypes.STARTED_FILE_LOAD:
      case TableEventTypes.SUCCEEDED_FILE_LOAD:
        return 'FileLoad';
      case TableEventTypes.FAILED_REWRITE_MANIFESTS:
      case TableEventTypes.RUN_REWRITE_MANIFESTS:
      case TableEventTypes.STARTED_REWRITE_MANIFESTS:
      case TableEventTypes.SUCCEEDED_REWRITE_MANIFESTS:
        return 'RewriteManifests';
      default:
        return this.UNKNOWN_PREFIX + ' ' + type;
    }
  }
  hasDetailedView(type: TableEventTypes, event: Event): boolean {
    switch (event.type) {
      case TableEventTypes.TABLE_CREATE:
        return false;
      case TableEventTypes.TABLE_UPDATE:
        return false;
      case TableEventTypes.CANCELLED_COMPACTION:
      case TableEventTypes.RUN_COMPACTION:
      case TableEventTypes.FAILED_COMPACTION:
      case TableEventTypes.STARTED_COMPACTION:
        return false;
      case TableEventTypes.RUN_EXPIRE_SNAPSHOTS:
      case TableEventTypes.FAILED_EXPIRE_SNAPSHOTS:
      case TableEventTypes.STARTED_EXPIRE_SNAPSHOTS:
        return false;
      case TableEventTypes.RUN_DATA_LIFECYCLE:
      case TableEventTypes.FAILED_DATA_LIFECYCLE:
      case TableEventTypes.STARTED_DATA_LIFECYCLE:
        return false;
      case TableEventTypes.RUN_FILES_CLEANUP:
      case TableEventTypes.FAILED_FILES_CLEANUP:
      case TableEventTypes.STARTED_FILES_CLEANUP:
      case TableEventTypes.SUCCEEDED_FILES_CLEANUP:
        return false;
      case TableEventTypes.RUN_REWRITE_MANIFESTS:
      case TableEventTypes.FAILED_REWRITE_MANIFESTS:
      case TableEventTypes.STARTED_REWRITE_MANIFESTS:
        return false;
      case TableEventTypes.TABLE_SNAPSHOT:
        return false;
      default:
        return true;
    }
  }
}
const tableEventTypesUtil = new TableEventTypesUtil();
export enum Verbosity {
  Summary,
  Detailed,
}

export function TableEventsView({ table }: { table: Table }) {
  const { user } = useAuth();
  const { ff } = useContext(GlobalPropertiesContext);
  const showUnknown: boolean = ff(KnownFeatureFlags.SHOW_UNKNOWN_EVENTS);
  const { organization: organizationName } = useParams();

  const apiRef = useGridApiRef();
  const [pageSize, setPageSize] = useState(25);
  const [searchQuery, setSearchQuery] = useState<string[]>([]);
  const [eventType, setEventType] = useState<string[]>(
    showUnknown ? [] : search_options.event_type,
  );
  const [branch, setBranch] = useState('');
  const [backStack, setBackStack] = useState<string[][]>([]);
  const [currentKeys, setCurrentKeys] = useState<string[]>([]);
  const [popover, setPopover] = useState<IPopoverState>({} as IPopoverState);

  const [eventQuery, { data, loading, refetch }] = useLazyQuery(
    TableEventsQuery,
    {
      variables: {
        organizationId: user.getOrganization(organizationName).id,
        tableRefId: table?.id,
        query: searchQuery,
        eventType: eventType,
        branchName: branch,
        sortBy: [
          {
            fieldName: 'event_ts',
            sortDirection: SortByDirection.Desc,
          },
        ],
        searchAfter: undefined,
        maxResults: pageSize,
        verbosity: 1,
      },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (table?.primary) eventQuery();
  }, [table, eventQuery]);

  useEffect(() => {
    if (table?.primary)
      refetch().catch((error) =>
        logger.error('Unhandled error while searching events', error),
      );
  }, [eventType, searchQuery]);

  const totalHitCount = useMemo(() => data?.searchEvents?.totalHits, [data]);
  const nextKeys: string[] = useMemo(
    () =>
      data?.searchEvents?.searchAfterKeys
        ? data?.searchEvents?.searchAfterKeys
        : [],
    [data],
  );
  const page = backStack.length + 1;

  const onSearchChange = useCallback((filters: Filters) => {
    setSearchQuery(filters.searchFilter);
    const eventType = filters.categoryFilter['event_type'];
    if (eventType == null || eventType.length == 0) {
      setEventType(showUnknown ? [] : search_options.event_type);
    } else {
      setEventType(eventType);
    }
    setBackStack([]);
    setCurrentKeys([]);
  }, []);

  const pushBackStack = useCallback((keys: string[]) => {
    setBackStack((prevState) => [...prevState, keys]);
  }, []);

  const popBackStack = useCallback((): string[] => {
    if (backStack.length > 0) {
      const retVal = backStack[backStack.length - 1];
      setBackStack((prevState) => prevState.slice(0, backStack.length - 1));
      return retVal;
    } else {
      return [];
    }
  }, [backStack]);

  const handleBackButtonPagination = useCallback(() => {
    const backKeys = popBackStack();
    refetch({
      searchAfter: backKeys.length == 0 ? undefined : backKeys,
    }).then(() => setCurrentKeys(backKeys));
  }, [backStack]);

  const handleNextButtonPagination = useCallback(() => {
    if (currentKeys) {
      pushBackStack(currentKeys);
    }

    refetch({ searchAfter: nextKeys.length == 0 ? undefined : nextKeys }).then(
      () => setCurrentKeys(nextKeys),
    );
  }, [nextKeys, currentKeys]);

  const resetAndReFetch = useCallback(() => {
    setBackStack([]);
    setCurrentKeys([]);
    return refetch({
      searchAfter: undefined,
    });
  }, []);

  const events = useMemo(() => data?.searchEvents?.results, [data]);

  const handlePopoverOpen = (event: any, popoverId: any) => {
    setPopover({
      anchorEl: event.currentTarget,
      popoverId,
    });
  };

  const handlePopoverClose = () => {
    setPopover({} as IPopoverState);
  };

  function CustomDetailPanelToggle(
    props: Pick<GridRenderCellParams, 'id' | 'value' | 'row'>,
  ) {
    const { value: isExpanded, row } = props;

    const typeHasDetail = tableEventTypesUtil.hasDetailedView(
      row.operation,
      row.event,
    );
    return (
      <IconButton
        size="small"
        tabIndex={-1}
        style={{ display: typeHasDetail ? '' : 'none' }}
        disabled={!typeHasDetail}
        aria-label={isExpanded ? 'Close' : 'Open'}
      >
        <ExpandMoreIcon
          sx={{
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
    );
  }

  // @ts-ignore
  const columns = [
    {
      field: 'operation',
      headerName: 'Event',
      maxWidth: 150,
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <TableEventChip
            event={params.row}
            columnSize={params.colDef.computedWidth}
          />
        );
      },
    },
    {
      field: 'avatar',
      headerName: '',
      maxWidth: 50,
      flex: 0.5,
      sortable: false,
      align: 'center',
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <>
          <Box
            aria-controls={`event-user-info-${params.row.eventId}`}
            aria-haspopup="true"
            // @ts-ignore
            onMouseEnter={(e: any) => handlePopoverOpen(e, params.row.eventId)}
            onMouseLeave={handlePopoverClose}
          >
            <Tavatar
              displayName={params.row.member.displayName}
              tabularService={params.row.member?.user?.email === ''}
              currentUser={
                user?.loginSession?.membership?.email ||
                user?.email === params.row.member?.user?.email
              }
              profilePic={params.row.member?.properties?.['picture.url']}
              securityAdmin={false}
              orgAdmin={false}
              roleAdmin={false}
            />
          </Box>
          <Popover
            id={`event-user-info-${params.row.eventId}`}
            sx={{
              pointerEvents: 'none',
            }}
            open={popover.popoverId === params.row.eventId}
            anchorEl={popover.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <UserInfo
              showAdmin={params.row.member.isAdmin}
              user={params.row.member}
              securityAdmin={false}
              orgAdmin={false}
              tabularService={params.row.member.user.email === ''}
            />
          </Popover>
        </>
      ),
    },
    {
      field: 'eventId',
      headerName: 'Summary',
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <TableEventView
            event={params.row.event}
            table={table}
            columnSize={params.colDef.computedWidth}
          />
        );
      },
    },
    {
      field: 'eventTs',
      headerName: 'Event Time',
      minWidth: 250,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flex: '1 1 100%',
                    alignItems: 'center',
                    height: '100%',
                    cursor: 'default',
                  }}
                >
                  <Typography {...bindHover(popupState)}>
                    {getDifferenceInHours(
                      new Date(),
                      new Date(params.row.eventTs),
                    ) < 24
                      ? relativeTimeAutoFormat(params.row.eventTs)
                      : new Date(params.row.eventTs).toUTCString()}
                  </Typography>
                </Box>
                <Popper
                  {...bindPopper(popupState)}
                  placement={'right'}
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom'],
                      },
                    },
                  ]}
                >
                  <DatePopover modifiedDate={params.row.eventTs} />
                </Popper>
              </>
            )}
          </PopupState>
        );
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params: any) => (
        <CustomDetailPanelToggle
          id={params.id}
          value={params.value}
          row={params.row}
        />
      ),
    },
  ];

  const metadata = useMemo(() => table?.metadata, [table]);

  const refs: SnapshotRefProp[] = useMemo(() => {
    const refs = metadata?.refs as SnapshotRefs[];
    return refs?.map(({ key, value }: SnapshotRefs) => ({
      ...value,
      name: key,
    }));
  }, [metadata]);

  const branchRefs = useMemo(
    () => refs?.filter((ref) => ref.type === 'branch'),
    [table],
  );

  return (
    <>
      {table !== undefined && !table.primary ? (
        <Grid container>
          <Grid item>
            <Talert severity="info" sx={{ mt: 2 }}>
              The table owner has not shared table activity with you{' '}
              <Link href="./snapshots" component={Tabutton} underline="none">
                VIEW SNAPSHOTS
              </Link>
            </Talert>
          </Grid>
        </Grid>
      ) : (
        <Tacard grid>
          <DataGridPro
            apiRef={apiRef}
            pagination
            autoHeight
            rows={events || []}
            columns={columns as GridColumns}
            headerHeight={48}
            disableColumnMenu
            isRowSelectable={() => false}
            getRowId={(r) => r.eventId}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
              resetAndReFetch();
            }}
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: OhNoesRows,
              NoResultsOverlay: OhNoesRows,
              Toolbar: TableEventToolbar,
              Pagination: ESCustomPagination,
            }}
            componentsProps={{
              noRowsOverlay: {
                buttonControl: (
                  <Typography variant={`h1`} color={`brandBlue.main`}>
                    No matching event
                  </Typography>
                ),
              },
              noResultsOverlay: {
                buttonControl: (
                  <Typography variant={`h1`} color={`brandBlue.main`}>
                    No matching event
                  </Typography>
                ),
              },
              toolbar: {
                apiRef: apiRef,
                totalCount: totalHitCount,
                pageSize: pageSize,
                onRefetch: resetAndReFetch,
                onBackButton: handleBackButtonPagination,
                onNextButton: handleNextButtonPagination,
                page: page,
                onSearchChange: onSearchChange,
                options: search_options,
                branchRefs: branchRefs,
                selectedBranch: branch,
                handleChangeBranch: setBranch,
              },
              pagination: {
                apiRef: apiRef,
                pageSize: pageSize,
                onBackButton: handleBackButtonPagination,
                onNextButton: handleNextButtonPagination,
                page: page,
                paginationType: 'Events',
              },
            }}
            sx={{
              ...(events?.length === 0 || totalHitCount === 0
                ? { minHeight: 600 }
                : {}),
              border: 'none',
              '.MuiDataGrid-columnHeaders': {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              },
              '.MuiDataGrid-pinnedColumnHeaders': {
                backgroundColor: 'dusk.half',
              },
            }}
            loading={loading || !data}
            getDetailPanelHeight={({ row }) => 'auto'}
            getDetailPanelContent={({ row }) => (
              <TableEventView
                event={row.event}
                table={table}
                verbosity={Verbosity.Detailed}
                columnSize={row.columnSize}
                detailPanelView={true}
              />
            )}
          />
        </Tacard>
      )}
    </>
  );
}
