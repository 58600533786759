import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress, Link, Popper } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
  GridValueFormatterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';

import { toSingleRole } from '../../RouteTable';
import { Tavatar } from '../../components/Avatar/Tavatar';
import { Tacard } from '../../components/Card/Tacard';
import { ConfirmationDialog } from '../../components/Modals/ConfirmationDialog';
import { OhNoesRows } from '../../components/OhNosRows/OhNoesRows';
import { NamePopover } from '../../components/Popovers/NamePopover';
import UserInfo from '../../components/ProfileMenu/UserInfo';
import SystemRoleCards from '../../components/SystemRoles/SystemRoleCards';
import { getAuthorizationDecision } from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  AuthDecisionSubjectType,
  Privilege,
  Role,
  SearchRoleResult,
  SortByDirection,
} from '../../graphql/gen/graphql';
import { searchRolesQuery } from '../../graphql/organization';
import { deleteRole as DeleteRole } from '../../graphql/role';
import { getDetailedApolloError } from '../../utils/ApolloUtils';
import { getLogger } from '../../utils/logging';
import { formatTimestamp, relativeTimeAutoFormat } from '../../utils/time';
import {
  calculateTextWidth,
  ESCustomPagination,
  RolesPageCustomToolbar,
} from '../helpers';

const logger = getLogger(
  'pages.Organization.Roles' /*FUTURE import.meta.url ?*/,
);
export default function Roles({
  user,
  orgName,
}: {
  user: any;
  orgName: string;
}) {
  const [roleToDelete, setRoleToDelete] = useState<Role>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [totalHitCount, setTotalHitCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [backStack, setBackStack] = useState<string[][]>([]);
  const [currentKeys, setCurrentKeys] = useState<string[]>([]);
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteRole] = useMutation(DeleteRole);
  const organization = useMemo(() => user.getOrganization(orgName), [user]);
  const orgId = organization?.id;

  interface RoleSearchParams {
    organizationId: string;
    query: string;
    maxResults: number;
    searchAfter: string[] | undefined;
    sortBy: any[];
    excludeSystemRoles: boolean;
  }

  const { data, loading, refetch } = useQuery<any, RoleSearchParams>(
    searchRolesQuery,
    {
      variables: {
        //@ts-ignore
        organizationId: orgId,
        query: searchQuery !== '' ? searchQuery : '*',
        maxResults: pageSize,
        searchAfter: undefined,
        sortBy: [
          {
            fieldName: 'roleName.sort',
            sortDirection: SortByDirection.Asc,
          },
        ],
        excludeSystemRoles: true,
      },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  );
  const { data: grantDecision } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        privileges: [Privilege.CreateRole],
        requirement: 'ALL',
        resource: {
          type: AuthDecisionResourceType.Organization,
          identifier: user.getOrganization(orgName)?.id,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const hasCreateRole =
    grantDecision?.authDecision === AuthDecisionResponseType.Allow;

  const totalRolesCount = useMemo(() => data?.searchRoles?.totalHits, [data]);
  const nextKeys: string[] = useMemo(
    () =>
      data?.searchRoles?.searchAfterKeys
        ? data?.searchRoles?.searchAfterKeys
        : [],
    [data],
  );
  const page = backStack.length + 1;
  const displayName = user.loginSession?.loggedInOrg?.displayName;
  const currentUserEmail = user?.loginSession?.membership?.email || user?.email;

  const resetAndReFetch = useCallback(() => {
    setBackStack([]);
    setCurrentKeys([]);
    return refetch({
      searchAfter: undefined,
    });
  }, []);
  const onRoleCreated = useCallback((role: Role) => {
    if (role && role.name) {
      navigate(toSingleRole(orgName, role.name));
      enqueueSnackbar(`New role created: ${role.name}`, {
        variant: 'success',
      });
    }
  }, []);
  const handleRoleDelete = async () => {
    const deleteResult = await deleteRole({
      variables: {
        roleId: roleToDelete?.id,
        force: true,
      },
      onError: (error) => {
        const errorMessage = getDetailedApolloError(error);
        enqueueSnackbar(
          errorMessage.type === 'RoleInUseException'
            ? errorMessage.message
            : 'Something went wrong. Please try again.',
          { variant: 'error' },
        );
      },
    });

    setConfirmModal(false);
    setRoleToDelete(undefined);
    if (deleteResult?.data?.deleteRole) {
      setTimeout(() => {
        resetAndReFetch().then(() =>
          enqueueSnackbar(`Role deleted`, { variant: 'success' }),
        );
      }, 1000);
    }
  };

  const onKeyDownHandler = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code == 'Enter') {
        e.preventDefault();
        //search again?
      }
    },
    [],
  );

  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      //@ts-ignore
      const searchyMcSearch = e.target.value;
      setSearchQuery(searchyMcSearch);
      setBackStack([]);
      setCurrentKeys([]);
    },
    [],
  );
  const debouncedSearchChange = debounce(onSearchChange, 500);

  const pushBackStack = useCallback((keys: string[]) => {
    setBackStack((prevState) => [...prevState, keys]);
  }, []);

  const popBackStack = useCallback((): string[] => {
    if (backStack.length > 0) {
      const retVal = backStack[backStack.length - 1];
      setBackStack((prevState) => prevState.slice(0, backStack.length - 1));
      return retVal;
    } else {
      return [];
    }
  }, [backStack]);

  const handleBackButtonPagination = useCallback(() => {
    const backKeys = popBackStack();
    refetch({
      searchAfter: backKeys.length == 0 ? undefined : backKeys,
    }).then(() => setCurrentKeys(backKeys));
  }, [backStack]);

  const handleNextButtonPagination = useCallback(() => {
    if (currentKeys) {
      pushBackStack(currentKeys);
    }

    refetch({ searchAfter: nextKeys.length == 0 ? undefined : nextKeys }).then(
      () => setCurrentKeys(nextKeys),
    );
  }, [nextKeys, currentKeys]);

  const handleSortChange = useCallback((sortModel: GridSortModel) => {
    setBackStack([]);
    setCurrentKeys([]);
    const sortColumn = sortModel[0].field;
    const sortDirection = sortModel[0].sort;
    if (sortColumn === 'lastModified') {
      refetch({
        sortBy: [
          {
            fieldName: sortColumn,
            sortDirection:
              sortDirection === SortByDirection.Desc
                ? SortByDirection.Desc
                : SortByDirection.Asc,
          },
          {
            fieldName: 'roleId.keyword',
            sortDirection: SortByDirection.Asc,
          },
        ],
        searchAfter: undefined,
      });
    } else {
      refetch({
        sortBy: [
          {
            fieldName: 'roleName.sort',
            sortDirection:
              sortDirection === SortByDirection.Desc
                ? SortByDirection.Desc
                : SortByDirection.Asc,
          },
        ],
        searchAfter: undefined,
      });
    }
  }, []);

  const rows = useMemo(() => {
    return data?.searchRoles?.results?.map((role: SearchRoleResult) => {
      return {
        id: role.roleId,
        name: role.roleName,
        memberCount: role?.memberCount,
        childrenCount: role?.childRoleCount,
        userHasModifyPrivs: true, //handled in the update role modal now
        currentUserEmail: currentUserEmail,
        lastModified: {
          profilePic: role?.lastModifiedBy?.properties?.[`picture.url`],
          displayName: role?.lastModifiedBy?.displayName || '',
          modifiedTimestamp: role?.lastModified,
          modifiedDate: formatTimestamp(role?.lastModified || ''),
          tabularService: role?.lastModifiedBy?.properties === null,
          email: role?.lastModifiedBy?.email,
        },
        adminMembers: role?.adminMemberCount,
        adminRoles: role?.adminRoleCount,
      };
    });
  }, [user, data]);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Role',
        minWidth: 200,
        flex: 2,
        editable: false,
        sortingOrder: ['desc', 'asc'],
        renderCell: (params: GridRenderCellParams) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
          );
          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: '1 1 100%',
                      alignItems: 'center',
                      height: '100%',
                      minWidth: 0,
                      cursor:
                        textWidth >= params.colDef.computedWidth ||
                        params.row?.comment?.value
                          ? 'pointer'
                          : 'auto',
                    }}
                    {...bindHover(popupState)}
                  >
                    <Link
                      href={toSingleRole(orgName, params.row.name)}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      variant={`inputText`}
                    >
                      {params.value}
                    </Link>
                  </Box>
                  {textWidth >= params.colDef.computedWidth && (
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <NamePopover name={params.value} />
                    </Popper>
                  )}
                </>
              )}
            </PopupState>
          );
        },
      },
      {
        field: 'lastModified',
        headerName: 'Last modified',
        minWidth: 160,
        flex: 0.5,
        editable: false,
        sortingOrder: ['desc', 'asc'],
        renderCell: (params: GridRenderCellParams) => (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  {...bindHover(popupState)}
                >
                  <Tavatar
                    displayName={params.value?.displayName}
                    profilePic={params.value?.profilePic}
                    tabularService={params.value?.tabularService}
                    currentUser={
                      params.row.currentUserEmail === params.value?.email
                    }
                  />
                  <Typography
                    variant={`inputText`}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      ml: 1,
                    }}
                  >
                    {relativeTimeAutoFormat(params.value?.modifiedTimestamp)}
                  </Typography>
                </Box>
                <Popper {...bindPopper(popupState)} placement={`top`}>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: 'controlBackground.main',
                      borderRadius: '4px',
                      boxShadow: theme.shadows[3],
                    })}
                  >
                    <UserInfo
                      user={{
                        displayName: params.value?.displayName,
                        profilePic: params.value?.profilePic,
                        email: params.value?.email,
                      }}
                      currentUser={
                        params.row.currentUserEmail === params.value?.email
                      }
                    >
                      <Typography>{params.value?.modifiedDate}</Typography>
                    </UserInfo>
                  </Box>
                </Popper>
              </>
            )}
          </PopupState>
        ),
      },
      {
        field: 'memberCount',
        headerName: 'Members',
        width: 120,
        editable: false,
        type: 'number',
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value != 0 ? params.value : '--';
        },
      },
      {
        field: 'childrenCount',
        headerName: 'Nested roles',
        width: 140,
        editable: false,
        type: 'number',
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value != 0 ? params.value : '--';
        },
      },
      {
        field: 'adminMembers',
        headerName: 'Admins',
        width: 120,
        editable: false,
        sortable: false,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value != 0 ? params.value : '--';
        },
      },
      {
        field: 'adminRoles',
        headerName: 'Admin roles',
        width: 140,
        editable: false,
        sortable: false,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value != 0 ? params.value : '--';
        },
      },
    ],
    [data, orgName, orgId],
  );

  useEffect(() => {
    setTotalHitCount(totalRolesCount);
  }, [totalRolesCount]);

  useEffect(() => {
    if (searchQuery === '' || searchQuery === '*') {
      refetch().catch((error) =>
        logger.error('Unhandled error while searching roles', error),
      );
    } else {
      refetch({
        sortBy: [
          {
            fieldName: '_score',
            sortDirection: SortByDirection.Desc,
          },
          {
            fieldName: 'roleId.keyword',
            sortDirection: SortByDirection.Asc,
          },
        ],
      }).catch((error) =>
        logger.error('Unhandled error while searching roles', error),
      );
    }
  }, [searchQuery]);

  return (
    <>
      <SystemRoleCards organization={organization} />
      <Tacard>
        <DataGridPro
          apiRef={apiRef}
          autoHeight
          pagination
          rows={rows || []}
          columns={columns as GridColumns}
          headerHeight={48}
          rowHeight={64}
          disableColumnMenu
          isRowSelectable={() => false}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
          sortingMode={`server`}
          onSortModelChange={handleSortChange}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            setBackStack([]);
            setCurrentKeys([]);
          }}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: OhNoesRows,
            NoResultsOverlay: OhNoesRows,
            Toolbar: RolesPageCustomToolbar,
            Pagination: ESCustomPagination,
          }}
          componentsProps={{
            noRowsOverlay: {
              buttonControl: (
                <Typography variant={`h1`} color={`brandBlue.main`}>
                  No matching roles
                </Typography>
              ),
            },
            toolbar: {
              apiRef: apiRef,
              totalCount: totalHitCount,
              pageSize: pageSize,
              onRefetch: refetch,
              organization: { id: orgId, name: orgName },
              onRoleCreated: onRoleCreated,
              displayName: displayName,
              handleKeyUp: debouncedSearchChange,
              handleKeyDown: onKeyDownHandler,
              onBackButton: handleBackButtonPagination,
              onNextButton: handleNextButtonPagination,
              page: page,
              currentUserEmail: currentUserEmail,
              hasCreateRole,
            },
            pagination: {
              apiRef: apiRef,
              pageSize: pageSize,
              onBackButton: handleBackButtonPagination,
              onNextButton: handleNextButtonPagination,
              page: page,
              paginationType: 'Roles',
            },
          }}
          loading={loading || !data}
          sx={{
            ...(rows?.length === 0 || totalHitCount === 0
              ? { minHeight: 600 }
              : {}),
            border: 'none',
            '.MuiDataGrid-columnHeaders': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
            '.MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: 'dusk.half',
            },
          }}
        />
      </Tacard>
      <ConfirmationDialog
        open={confirmModal}
        title="Delete role?"
        acceptText="Delete"
        onDismiss={() => {
          setRoleToDelete(undefined);
          setConfirmModal(false);
        }}
        onAccept={handleRoleDelete}
      >
        Permanently delete the {roleToDelete?.name} role and associated
        privileges?
      </ConfirmationDialog>
    </>
  );
}
