import React from 'react';

import { createSvgIcon } from '@mui/material';

export const AddRoleIcon = createSvgIcon(
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66667 3.33333H12C12.7333 3.33333 13.3333 3.93333 13.3333 4.66667V6.69775H12V4.66667H8.66667C8.66667 5.4 8.06667 6 7.33333 6H6C5.26667 6 4.66667 5.4 4.66667 4.66667H1.33333V12H7.99805V13.3333H1.33333C0.6 13.3333 0 12.7333 0 12V4.66667C0 3.93333 0.6 3.33333 1.33333 3.33333H4.66667V1.33333C4.66667 0.6 5.26667 0 6 0H7.33333C8.06667 0 8.66667 0.6 8.66667 1.33333V3.33333ZM7.33333 1.33333H6V4.66667H7.33333V1.33333ZM10.6667 6.66667H8V7.66667H10.6667V6.66667ZM10.6667 8.66667H8V9.66667H10.6667V8.66667ZM4.66667 8.66667C5.21895 8.66667 5.66667 8.21895 5.66667 7.66667C5.66667 7.11438 5.21895 6.66667 4.66667 6.66667C4.11438 6.66667 3.66667 7.11438 3.66667 7.66667C3.66667 8.21895 4.11438 8.66667 4.66667 8.66667ZM4.66667 9.16667C5.16 9.16667 5.62667 9.26667 6.05333 9.45333C6.42667 9.61333 6.66667 9.97333 6.66667 10.38V10.6667H2.66667V10.38C2.66667 9.97333 2.90667 9.61333 3.28 9.45333C3.70667 9.26667 4.17333 9.16667 4.66667 9.16667Z"
      fill="#246B8F"
    />
    <rect
      x="12.0156"
      y="8.35352"
      width="1.32129"
      height="6.64648"
      fill="#246B8F"
    />
    <rect
      x="16"
      y="11.0161"
      width="1.32129"
      height="6.64648"
      transform="rotate(90 16 11.0161)"
      fill="#246B8F"
    />
  </svg>,
  'AddRole',
);
