import React, { ReactNode, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  BoxProps,
  InputAdornment,
  Grid,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import {
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';

import { useTableEditContext } from './TableEditContext';

import { getLogger } from '../../utils/logging';
import { SchemaFieldRow } from './SchemaEdit';
import {
  getColorForComplexType,
  GridTextField,
} from './SchemaEditGridComponents';
import { EditTableMode } from './TableEdit';
import {
  cleanSourceFieldValue,
  cleanTransformValue,
  cleanTypeValue,
  findPartitionFieldCandidates,
  safeJSONParse,
  shouldBlockPropagation,
  simulateMouseClick,
} from './TableEditHelpers';

const logger = getLogger(
  'components.TableEdit.SourceFieldSelector' /*FUTURE import.meta.url ?*/,
);

export interface SourceFieldSelectionProps extends GridRenderCellParams {
  BoxProps?: BoxProps;
  hideTypes?: boolean;
}
export function SourceFieldSelectionView({
  id,
  value,
  field,
  tabIndex,
  BoxProps,
  hideTypes,
  ...props
}: SourceFieldSelectionProps) {
  if (value === undefined || value === '') {
    return (
      <Box {...BoxProps} sx={{ ...(BoxProps ? BoxProps.sx : {}) }}>
        <GridTextField label={'Select source'} required error></GridTextField>
      </Box>
    );
  }
  let jsonValueObject: any;
  const { mode } = useTableEditContext();

  jsonValueObject = safeJSONParse(value, {});

  let isError = !jsonValueObject.name;

  return (
    <Box
      {...BoxProps}
      sx={[(BoxProps?.sx as SystemStyleObject) ?? {}, { width: 1 }]}
    >
      <FormControl fullWidth>
        <Box
          className="icebergTypeLabel"
          sx={(theme) => ({
            width: 1,
            height: 42,
            pl: 2,
            pr: 0,
            border: `1px solid ${theme.palette.midnight.two}`,
            borderRadius: '4px',
            backgroundColor: getColorForComplexType(
              jsonValueObject?.type,
              mode,
            ),
            display: 'flex',
            alignItems: 'center',
          })}
        >
          <Grid container alignItems={'center'} sx={{ width: 400 }}>
            <Grid
              item
              xs={9}
              sx={{ height: 20 }}
            >{`${jsonValueObject?.name}`}</Grid>
            {!hideTypes && (
              <Grid
                item
                xs={3}
                sx={{ height: 20 }}
              >{`${jsonValueObject.type}`}</Grid>
            )}
          </Grid>
          {mode !== EditTableMode.VIEW && (
            <ArrowDropDownIcon sx={{ ml: '1px' }} />
          )}
        </Box>
      </FormControl>
    </Box>
  );
}

export interface SourceFieldValue {
  rowId: string;
  name: string;
  type: string;
}

export interface SourceFieldCandidate extends SourceFieldValue {
  description?: string;
}

export interface SourceFieldSelectionEditProps
  extends GridRenderEditCellParams {
  schemaModel: Record<string, SchemaFieldRow[]>;
  BoxProps?: BoxProps;
  excludedFieldNames?: string[];
  hideTypes?: boolean;
}

const SCHEMA_TYPE_VAL_NONE = 'none';

export function SourceFieldSelectionEdit({
  id,
  value,
  field,
  row,
  level,
  hasFocus,
  tabIndex,
  BoxProps,
  excludedFieldNames,
  hideTypes,
  schemaModel,
  ...rest
}: SourceFieldSelectionEditProps) {
  const apiRef = useGridApiContext();

  const previousValue = apiRef.current.getCellValue(id, field);

  const inVal = cleanSourceFieldValue(value, previousValue, schemaModel);

  const [currentVal, setCurrentVal] = useState<string>(inVal.value);
  const [currentValObj, setCurrentValObj] = useState<any>(inVal.valueObj);
  const [selectOpen, setSelectOpen] = React.useState(hasFocus);
  const [lastSelectCloseTs, setLastSelectCloseTs] = React.useState(0);

  const setValue = (newValue: string) => {
    let valueObj;

    valueObj = newValue === undefined ? undefined : safeJSONParse(newValue, {});

    setCurrentVal(newValue);
    setCurrentValObj(valueObj);

    apiRef.current.setEditCellValue({ id, field, value: newValue });
    return valueObj;
  };

  const getCandidateValue = (
    candidate: SourceFieldCandidate,
  ): SourceFieldValue => {
    const { description: _, ...rest } = candidate;
    return rest;
  };

  const setParamValue = (paramName: string, newValue: string) => {
    const newValueObjString = JSON.stringify({
      ...currentValObj,
      [paramName]: newValue,
    });
    setValue(newValueObjString);
  };

  const handleValueChange = (
    event: SelectChangeEvent<string>,
    child?: ReactNode,
  ) => {
    const valueObj = setValue(event.target.value);

    logger.debug('select changed, closing');
    setSelectOpen(false);
  };

  const menuItems = findPartitionFieldCandidates(
    schemaModel,
    schemaModel['top'],
    null,
  )
    .filter((eachFieldCandidate) =>
      excludedFieldNames
        ? !excludedFieldNames.includes(eachFieldCandidate.name.toLowerCase())
        : true,
    )
    .map((fieldCandidate) => [
      // @ts-ignore
      <MenuItem
        id={'candidate_' + id + '_' + fieldCandidate.rowId}
        key={'candidateMenu_' + id + '_' + fieldCandidate.rowId}
        value={`${JSON.stringify(getCandidateValue(fieldCandidate))}`}
        aria-haspopup="true"
        onKeyDown={(event) => {
          logger.debug(
            `menuitem ${event.currentTarget.id} got keydown ${event.key}`,
          );
          //simulate a click if they hit space bar on the menu item.
          if (event.key === ' ') {
            simulateMouseClick(event.currentTarget);
          }
        }}
      >
        <Grid container sx={{ height: 32, width: 400 }} alignItems={'center'}>
          <Grid
            item
            xs={9}
            sx={{ height: 23 }}
          >{`${fieldCandidate.name}`}</Grid>
          {!hideTypes && (
            <Grid
              item
              xs={3}
              sx={{ height: 23 }}
            >{`${fieldCandidate.type}`}</Grid>
          )}
        </Grid>
      </MenuItem>,
    ]);

  // @ts-ignore
  return (
    <Box
      {...BoxProps}
      sx={[
        (BoxProps?.sx as SystemStyleObject) ?? {},
        { display: 'flex', width: 1 },
      ]}
      tabIndex={-1}
      onKeyDown={(event) => {
        logger.debug(`in box control ${event.key} !`);
        // if (event.key !== 'Tab') {
        //   event.stopPropagation();
        // }
      }}
    >
      {/*There is a weird quirk / bug in the mui select here.  If I get rid of the flexGrow:1 or width:1, I can tab
      once into the select... otherwise it is twice!  maybe is the react strict mode issue local?*/}
      <FormControl
        variant="standard"
        sx={{
          flexGrow: 1,
          '& .MuiInputBase-formControl': {
            borderRadius: '5px',
            border: 1,
            borderColor: 'midnight.two',
          },
        }}
        onKeyDown={(event) => {
          logger.debug(`in form control onkeydown '${event.key}' !`);
          if (shouldBlockPropagation(event)) {
            event.stopPropagation();
          }
        }}
      >
        <Select
          sx={{
            ...(!currentVal || currentVal === SCHEMA_TYPE_VAL_NONE
              ? { color: 'midnight.four' }
              : {}),
          }}
          autoFocus={hasFocus}
          disableUnderline
          onBlur={() => {
            setSelectOpen(false);
          }}
          onFocus={(e) => {
            if (e.currentTarget === e.target) {
              logger.debug('focused self');
            } else {
              logger.debug('focused child', e.target);
            }
            if (!e.currentTarget.contains(e.relatedTarget)) {
              // Not triggered when swapping focus between children
              logger.debug('focus entered self');
              if (e.timeStamp - lastSelectCloseTs > 1000) {
                if (e.relatedTarget) {
                  setSelectOpen(true);
                }
              }
            }
          }}
          inputProps={{
            sx: { pt: 1 / 2, pb: 1 / 2, pl: 2 },
          }}
          onChange={handleValueChange}
          onPointerDown={() => {
            logger.debug(`pointer down`);
            apiRef.current.setCellFocus(id, field);
            if (!selectOpen) {
              setSelectOpen(!selectOpen);
            }
          }}
          open={selectOpen}
          onOpen={() => {
            logger.debug('On open!');
          }}
          onClose={(event) => {
            logger.debug('On close!');
            setSelectOpen(false);
            setLastSelectCloseTs(event.timeStamp);
          }}
          value={currentVal ? currentVal : SCHEMA_TYPE_VAL_NONE}
          placeholder={'Select source'}
        >
          <MenuItem
            disabled
            value={SCHEMA_TYPE_VAL_NONE}
            key={'partitionEditMenu_0' + id}
          >
            Select source
          </MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    </Box>
  );
}
