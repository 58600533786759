import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import { useUserAgent } from 'react-ua';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box, Grid, Stack, StackProps, Typography } from '@mui/material';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Tabutton } from '../../Button/Tabutton';

interface SparkSQLTemplateProps extends StackProps {
  cmds: any[];
}

export const SqlCmds = [
  { title: `List your Warehouse's databases.`, cmd: `show databases;` },
  { title: `Use the example database.`, cmd: `use examples;` },
  {
    title: `Analyze New York Taxi data`,
    cmd: `
describe table examples.nyc_taxi_yellow;

create table if not exists default.taxi_tip_rate_per_passenger_spark as
select 
  passenger_count,
  count(*) as trip_count,
  avg(tip_amount / fare_amount) as tip_rate
from examples.nyc_taxi_yellow
where 
    pickup_time between '2020-03-01' and '2020-03-31'
    and passenger_count > 0
    and fare_amount > 0
group by passenger_count;

select 
  passenger_count,  
  trip_count,
  format_number(tip_rate * 100, 2) as tip_percentage
from default.taxi_tip_rate_per_passenger_spark 
order by passenger_count;`,
  },
];

export const PySparkSqlCmds = [
  {
    title: `List your Warehouse's databases.`,
    cmd: `%%sql
show databases;`,
  },
  {
    title: `Use the example database.`,
    cmd: `%%sql
use examples;`,
  },
  {
    title: `Describe example table`,
    cmd: `%%sql
describe table examples.nyc_taxi_yellow;
`,
  },
  {
    title: `Create table`,
    cmd: `%%sql
create table if not exists default.taxi_tip_rate_per_passenger_spark as
select 
  passenger_count,
  count(*) as trip_count,
  avg(tip_amount / fare_amount) as tip_rate
from examples.nyc_taxi_yellow
where 
    pickup_time between '2020-03-01' and '2020-03-31'
    and passenger_count > 0
    and fare_amount > 0
group by passenger_count;`,
  },
  {
    title: 'Analyze data',
    cmd: `%%sql
select 
  passenger_count,  
  trip_count,
  format_number(tip_rate * 100, 2) as tip_percentage
from default.taxi_tip_rate_per_passenger_spark 
order by passenger_count;`,
  },
];

export const SparkSQLTemplate = ({
  cmds,
  ...props
}: SparkSQLTemplateProps): JSX.Element => {
  return (
    <Stack direction="column" {...props} sx={{}}>
      {cmds.map((cmd, idx) => (
        <Box key={'sqlcmd' + idx} sx={{ width: 1 }}>
          <Grid
            container
            sx={{
              width: 0.95,
              mt: 2,
              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 2,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
              }}
            >
              <Typography variant={'subtitle1'} sx={{}}>
                {cmd.title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                pre: {
                  m: 0,
                  height: 1,
                },
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              {' '}
              <SyntaxHighlighter
                language="SQL"
                style={{ ...dracula, margin: 0 as React.CSSProperties }}
                showLineNumbers={false}
              >
                {cmd.cmd}
              </SyntaxHighlighter>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 0.5,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant={'body1'}>
                {/* @ts-ignore */}
                <Tabutton
                  onClick={() => navigator.clipboard.writeText(cmd.cmd)}
                  title="Copy to clipboard"
                  size={'small'}
                  sx={{ alignSelf: 'center' }}
                  endIcon={<FileCopyIcon />}
                >
                  Copy Command
                </Tabutton>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Stack>
  );
};
