import React from 'react';

import {
  GridCallbackDetails,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
} from '@mui/x-data-grid-pro';
import { keyBy } from 'lodash';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import {
  CreateTableRequest,
  FieldLabel,
  InProperty,
  Maybe,
  PartitionFieldInput,
  PatchTableRequest,
  SchemaField,
  SchemaFieldInput,
  SortOrderFieldInput,
  TableMetadata,
} from '../../graphql/gen/graphql';
import { IcebergDataTypes } from '../../graphql/table';
import { getLogger } from '../../utils/logging';
import { PartitionFieldRow } from './PartitionEdit';
import { PropertiesRow } from './PropertiesEdit';
import { SchemaEditRowModel, SchemaFieldRow } from './SchemaEdit';
import { SortOrderFieldRow } from './SortOrderEdit';
import { SourceFieldCandidate, SourceFieldValue } from './SourceFieldSelector';
import { EditTableMode } from './TableEdit';
import { TransformValue } from './TransformSelector';

const logger = getLogger(
  'components.TableEdit.TableEditHelpers' /*FUTURE import.meta.url ?*/,
);
const DEFAULT_TRANSFORM_VALUE = {
  name: 'none',
  value: 'identity',
  description: 'Source value, unmodified (Identity)',
  sort: 10,
};
export function updateRowPosition(
  initialIndex: number,
  newIndex: number,
  rows: Array<GridRowModel>,
): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

export function isComplexType(row: GridRowModel<SchemaFieldRow>): boolean {
  return (
    row?.type?.indexOf('struct') > -1 ||
    row?.type?.indexOf('list') > -1 ||
    row?.type?.indexOf('map') > -1
  );
}

export function areChildSchemasValid(
  validityModel: Record<GridRowId, number>,
  trigger: number,
  level: number,
  rows: GridRowModel<SchemaFieldRow>[],
) {
  let allGood = true;
  rows.forEach((eachRow) => {
    if (isComplexType(eachRow)) {
      const val: number = validityModel[eachRow.rowId || ''];
      if (val === undefined) {
        logger.error(
          `${Array(level + 1).join('\t')}L${level}: Child ${eachRow.name} (${
            eachRow.rowId
          }) is not valid since it is not represented in the validity model, returning false`,
        );
        allGood = false;
      } else if (val < trigger) {
        logger.debug(
          `${Array(level + 1).join('\t')}L${level}: Child ${eachRow.name} (${
            eachRow.rowId
          }) is not valid last valid ver is (${val}), returning false`,
        );
        allGood = false;
      } else {
        logger.debug(
          `${Array(level + 1).join('\t')}L${level}:: Child model ${
            eachRow.name
          } is valid!`,
        );
      }
    }
  });

  return allGood;
}

export const validateFixed = (
  param1: any,
): { isError: boolean; helperText: string[] } => {
  let isError = false;
  let helperText = [];
  if (param1 === undefined || param1 === '') {
    isError = true;
    helperText.push('L is missing');
  }
  return { isError: isError, helperText: helperText };
};
export const validateDecimal = (
  precision: any,
  scale: any,
): { isError: boolean; helperText: string[] } => {
  let isError = false;
  let helperText: string[] = [];
  if (precision === undefined || precision === '') {
    isError = true;
    helperText.push('P is missing');
  }
  if (scale === undefined || scale === '') {
    isError = true;
    helperText.push('S is missing');
  }
  if (Number(precision) > 38) {
    isError = true;
    helperText.push('P cannot be > 38');
  }
  //0 <= s <= p
  if (Number(precision) < 0) {
    isError = true;
    helperText.push('P cannot be < 0');
  }
  if (Number(scale) < 0) {
    isError = true;
    helperText.push('S cannot be < 0');
  }
  if (Number(precision) < Number(scale)) {
    isError = true;
    helperText.push('P cannot be < S');
  }

  return { isError: isError, helperText: helperText };
};

export const validateSchemaRow = (
  row: SchemaFieldRow,
  parentSubRowsModel: SchemaEditRowModel | undefined,
  level: number,
): boolean => {
  logger.debug(
    `${Array(level + 1).join('\t')}L${level}: 👀Validating row ${row.rowId}`,
    row,
  );
  if (row.name === undefined || row.name === '') {
    logger.debug(
      `${Array(level + 1).join('\t')}L${level}: ❌ NOT VALID Row ${
        row.rowId
      } has no name.`,
    );
    return false;
  }
  if (row.type === undefined || row.type === '') {
    logger.debug(
      `${Array(level + 1).join('\t')}L${level}: ❌ NOT VALID Row ${
        row.rowId
      } has no type.`,
    );
    return false;
  }
  const typeObj = safeJSONParse(row.type, {});
  if (typeObj?.type === 'fixed') {
    const results = validateFixed(typeObj.param1);
    results.helperText.forEach((eachError) =>
      logger.debug(
        `${Array(level + 1).join('\t')}L${level}: ❌ NOT VALID Row ${
          row.rowId
        } fixed type ${eachError}`,
      ),
    );
    if (results.isError) {
      return false;
    }
  }
  if (typeObj?.type === 'decimal') {
    const results = validateDecimal(typeObj.param1, typeObj.param2);

    results.helperText.forEach((eachError) =>
      logger.debug(
        `${Array(level + 1).join('\t')}L${level}: ❌ NOT VALID Row ${
          row.rowId
        } decimal type ${eachError}`,
      ),
    );
    if (results.isError) {
      return false;
    }
  }

  logger.debug(
    `${Array(level + 1).join('\t')}L${level}: ✅ Valid row ${row.rowId} (${
      row.name
    })`,
  );
  return true;
};

export const validatePropertiesRow = (row: PropertiesRow): boolean => {
  logger.debug(`👀Validating properties row ${row.rowId}`, row);
  if (row.name === undefined || row.name === '') {
    logger.debug(`❌ NOT VALID Row ${row.rowId} has no name.`);
    return false;
  }
  if (row.value === undefined || row.value === '') {
    logger.debug(`❌ NOT VALID Row ${row.rowId} has no value.`);
    return false;
  }
  logger.debug(`✅ Valid partition row ${row.rowId} `);
  return true;
};
export const validatePartitionRow = (row: PartitionFieldRow): boolean => {
  logger.debug(`👀Validating partition row ${row.rowId}`, row);
  if (row.schemaSourceField === undefined || row.schemaSourceField === '') {
    logger.debug(`❌ NOT VALID Row ${row.rowId} has no schemaSourceField.`);
    return false;
  }
  if (row.transform === undefined || row.transform === '') {
    logger.debug(`❌ NOT VALID Row ${row.rowId} has no transform.`);
    return false;
  }

  const transformObj = safeJSONParse(row.transform, {});
  if (transformObj?.value === 'bucket') {
    if (transformObj.param1 === undefined || transformObj.param1 === '') {
      logger.debug(
        `❌ NOT VALID Row ${row.rowId} bucket transform has no param1.`,
      );
      return false;
    }
    if (Number(transformObj.param1) < 0) {
      logger.debug(
        `❌ NOT VALID Row ${row.rowId} bucket transform param cannot be negative.`,
      );
      return false;
    }
  }
  if (transformObj?.type === 'truncate') {
    if (transformObj.param1 === undefined || transformObj.param1 === '') {
      logger.debug(
        `❌ NOT VALID Row ${row.rowId} truncate type has no param1.`,
      );
      return false;
    }
    if (Number(transformObj.param1) < 0) {
      logger.debug(
        `❌ NOT VALID Row ${row.rowId} truncate param cannot be negative.`,
      );
      return false;
    }
  }

  logger.debug(
    `✅ Valid partition row ${row.rowId} (${row.schemaSourceField})`,
  );
  return true;
};

export const validateSortOrderRow = (row: SortOrderFieldRow): boolean => {
  logger.debug(`👀Validating SortOrder row ${row.rowId}`, row);
  if (row.schemaSourceField === undefined || row.schemaSourceField === '') {
    logger.debug(`❌ NOT VALID Row ${row.rowId} has no schemaSourceField.`);
    return false;
  }
  if (row.transform === undefined || row.transform === '') {
    logger.debug(`❌ NOT VALID Row ${row.rowId} has no transform.`);
    return false;
  }
  const transformObj = JSON.parse(row.transform);
  if (
    transformObj?.value === 'bucket' &&
    (transformObj.param1 === undefined || transformObj.param1 === '')
  ) {
    logger.debug(
      `❌ NOT VALID Row ${row.rowId} bucket transform has no param1.`,
    );
    return false;
  }
  if (
    transformObj?.type === 'truncate' &&
    (transformObj.param1 === undefined || transformObj.param1 === '')
  ) {
    logger.debug(`❌ NOT VALID Row ${row.rowId} truncate type has no param1.`);
    return false;
  }

  logger.debug(
    `✅ Valid SortOrder row ${row.rowId} (${row.schemaSourceField})`,
  );
  return true;
};

export const cleanTypeValue = (
  value: string,
  previousValue: string,
): { value: string; valueObj: any } => {
  if (value === undefined || value === '') return { value: '', valueObj: {} };
  let valueObj;
  try {
    valueObj = value === undefined ? undefined : JSON.parse(value);
  } catch (e) {
    logger.warn(`Could not parse iceberg type value ${value}`);
  }
  if (
    IcebergDataTypes.map((types) => types.primitiveType).includes(
      valueObj?.type ? valueObj?.type : valueObj,
    )
  ) {
    return { value, valueObj };
  } else {
    valueObj =
      previousValue === undefined || previousValue === 'undefined'
        ? undefined
        : safeJSONParse(previousValue, {});
    return { value: previousValue, valueObj: { valueObj } };
  }
};

export const cleanTransformValue = (
  value: string,
  previousValue: string,
  getTransformsMethod: () => TransformValue[],
): { value: string; valueObj: any } => {
  if (value === undefined || value === '') {
    logger.debug(`Value is undefined, returning empty object`);
    return {
      value: JSON.stringify(DEFAULT_TRANSFORM_VALUE),
      valueObj: DEFAULT_TRANSFORM_VALUE,
    };
  }
  let valueObj;
  try {
    valueObj = value === undefined ? undefined : JSON.parse(value);
  } catch (e) {
    logger.warn(`Could not parse transform type value ${value}`);
    return { value: '', valueObj: {} };
  }
  const validTransforms = getTransformsMethod();
  if (
    validTransforms
      .map((transform) => transform.value)
      .includes(valueObj?.value ? valueObj?.value : valueObj)
  ) {
    return { value, valueObj };
  } else {
    logger.warn(
      `Transform is not included, returning previous value ${previousValue}`,
    );
    valueObj =
      previousValue === undefined || previousValue === ''
        ? undefined
        : JSON.parse(previousValue);
    return { value: previousValue, valueObj: { valueObj } };
  }
};

export const cleanSourceFieldValue = (
  value: string,
  previousValue: string,
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
): { value: string; valueObj: any } => {
  if (value === undefined || value === '') return { value: '', valueObj: {} };
  let valueObj: SourceFieldValue;
  try {
    valueObj = value === undefined ? undefined : JSON.parse(value);
  } catch (e) {
    logger.warn(`Could not parse source field value ${value}`);
    return { value: '', valueObj: {} };
  }
  const validFields = findPartitionFieldCandidates(
    schemaModel,
    schemaModel['top'],
    null,
  );
  if (
    validFields
      .map((field) => field.rowId)
      .includes(valueObj?.rowId ? valueObj?.rowId : '')
  ) {
    return { value, valueObj };
  } else {
    logger.warn(
      `Cannot match current source field value with current existing field in Schema, removing`,
      value,
      valueObj,
    );
    return { value: '', valueObj: {} };
  }
};

/*
(from iceberg docs https://iceberg.apache.org/spec/#schemas-and-data-types ) The source column, selected by id, must be a primitive type and cannot be contained in a map or list, but may be nested in a struct.
 */
export const findPartitionFieldCandidates = (
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
  fields: GridRowModel<SchemaFieldRow>[],
  parentFieldName: string | null,
): SourceFieldCandidate[] => {
  if (!fields) return [];
  let candidates: SourceFieldCandidate[] = [];

  fields.forEach((eachField) => {
    if (eachField.type !== undefined) {
      const fldTypeObj = safeJSONParse(eachField.type, {});
      if (
        fldTypeObj.type === undefined ||
        (fldTypeObj.type !== 'map' &&
          fldTypeObj.type !== 'list' &&
          fldTypeObj.type !== 'struct')
      ) {
        candidates.push({
          rowId: eachField.rowId,
          name: `${parentFieldName !== null ? parentFieldName + '.' : ''}${
            eachField.name
          }`,
          type: getTypeString(fldTypeObj),
          description: eachField?.doc || '',
        });
      } else if (fldTypeObj.type === 'struct') {
        candidates.push(
          ...findPartitionFieldCandidates(
            schemaModel,
            schemaModel[eachField.rowId || ''],
            `${parentFieldName !== null ? parentFieldName + '.' : ''}${
              eachField.name
            }`,
          ),
        );
      }
    }
  });

  return candidates;
};

export const findSourceFieldCandidateById = (
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
  fields: GridRowModel<SchemaFieldRow>[],
  parentFieldName: string | null,
  targetId: number,
): SourceFieldCandidate | null => {
  if (!fields) return null;

  for (let i = 0; i < fields.length; i++) {
    const eachField = fields[i];
    if (eachField.type !== undefined) {
      const fldTypeObj = safeJSONParse(eachField.type, {});
      if (
        fldTypeObj.type === undefined ||
        (fldTypeObj.type !== 'map' &&
          fldTypeObj.type !== 'list' &&
          fldTypeObj.type !== 'struct')
      ) {
        if (eachField.id === targetId) {
          return {
            rowId: eachField.rowId,
            name: `${parentFieldName !== null ? parentFieldName + '.' : ''}${
              eachField.name
            }`,
            type: getTypeString(fldTypeObj),
          };
        }
      } else if (fldTypeObj.type === 'struct') {
        const target: SourceFieldCandidate | null =
          findSourceFieldCandidateById(
            schemaModel,
            schemaModel[eachField.rowId || ''],
            `${parentFieldName !== null ? parentFieldName + '.' : ''}${
              eachField.name
            }`,
            targetId,
          );
        if (target !== null) {
          return target;
        }
      }
    }
  }

  return null;
};

export const findSourceFieldCandidateByName = (
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
  fields: GridRowModel<SchemaFieldRow>[],
  parentFieldName: string | null,
  targetName: string,
): SourceFieldCandidate | null => {
  if (!fields) return null;

  for (let i = 0; i < fields.length; i++) {
    const eachField = fields[i];
    if (eachField.type !== undefined) {
      const fldTypeObj = safeJSONParse(eachField.type, {});
      if (
        fldTypeObj.type === undefined ||
        (fldTypeObj.type !== 'map' &&
          fldTypeObj.type !== 'list' &&
          fldTypeObj.type !== 'struct')
      ) {
        if (
          `${parentFieldName !== null ? parentFieldName + '.' : ''}${
            eachField.name
          }` === targetName
        ) {
          return {
            rowId: eachField.rowId,
            name: `${parentFieldName !== null ? parentFieldName + '.' : ''}${
              eachField.name
            }`,
            type: getTypeString(fldTypeObj),
          };
        }
      } else if (fldTypeObj.type === 'struct') {
        const target: SourceFieldCandidate | null =
          findSourceFieldCandidateByName(
            schemaModel,
            schemaModel[eachField.rowId || ''],
            `${parentFieldName !== null ? parentFieldName + '.' : ''}${
              eachField.name
            }`,
            targetName,
          );
        if (target !== null) {
          return target;
        }
      }
    }
  }

  return null;
};

export const findIdentificationFields = (
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
  fields: GridRowModel<SchemaFieldRow>[],
  parentFieldName: string | null,
): SourceFieldCandidate[] => {
  if (!fields) return [];
  let candidates: SourceFieldCandidate[] = [];

  fields.forEach((eachField) => {
    if (eachField.type !== undefined) {
      const fldTypeObj = safeJSONParse(eachField.type, {});
      if (
        eachField.identity &&
        (fldTypeObj.type === undefined ||
          (fldTypeObj.type !== 'map' &&
            fldTypeObj.type !== 'list' &&
            fldTypeObj.type !== 'struct'))
      ) {
        candidates.push({
          rowId: eachField.rowId,
          name: `${parentFieldName !== null ? parentFieldName + '.' : ''}${
            eachField.name
          }`,
          type: getTypeString(fldTypeObj),
          description: eachField?.doc || '',
        });
      } else if (fldTypeObj.type === 'struct') {
        candidates.push(
          ...findIdentificationFields(
            schemaModel,
            schemaModel[eachField.rowId || ''],
            `${parentFieldName !== null ? parentFieldName + '.' : ''}${
              eachField.name
            }`,
          ),
        );
      }
    }
  });

  return candidates;
};

export function getTypeString(typeObject: any): string {
  if (typeObject !== undefined) {
    if (typeObject.type) {
      if (typeObject.type === 'fixed') {
        return `fixed(${typeObject.param1})`;
      } else if (typeObject.type === 'decimal') {
        return `decimal(${typeObject.param1},${typeObject.param2})`;
      } else {
        return '';
      }
    } else {
      return typeObject;
    }
  } else {
    return '';
  }
}

export function getTransformsForSourceType(
  sourceType: string,
): TransformValue[] {
  let retVals: TransformValue[] = [];
  if (sourceType === undefined) {
    return [DEFAULT_TRANSFORM_VALUE];
  }
  switch (sourceType) {
    case 'timestamp':
    case 'timestamptz':
      retVals.push({
        name: 'hour',
        value: 'hour',
        description:
          'Extract a timestamp hour, as hours from 1970-01-01 00:00:00',
        sort: 20,
      });
    // eslint-disable-next-line no-fallthrough
    case 'date':
      retVals.push({
        name: 'year',
        value: 'year',
        description: 'Extract a date or timestamp year, as years from 1970',
        sort: 20,
      });
      retVals.push({
        name: 'month',
        value: 'month',
        description:
          'Extract a date or timestamp month, as months from 1970-01-01',
        sort: 20,
      });
      retVals.push({
        name: 'day',
        value: 'day',
        description: 'Extract a date or timestamp day, as days from 1970-01-01',
        sort: 20,
      });
      retVals.push(DEFAULT_TRANSFORM_VALUE);
      retVals.push({
        name: 'bucket',
        value: 'bucket',
        description: 'Hash of value, mod N',
        sort: 11,
      });
      break;

    case 'int':
    case 'long':
    case 'decimal':
    case 'string':
      retVals.push({
        name: 'truncate',
        value: 'truncate',
        description: 'Value truncated to width W',
        sort: 12,
      });

    // eslint-disable-next-line no-fallthrough
    case 'time':
    case 'uuid':
    case 'fixed':
    case 'binary':
      retVals.push({
        name: 'bucket',
        value: 'bucket',
        description: 'Hash of value, mod N',
        sort: 11,
      });

    // eslint-disable-next-line no-fallthrough
    default:
      retVals.push(DEFAULT_TRANSFORM_VALUE);
  }
  return retVals;
}

export const nukeAllEmpties = (
  rows: GridRowModel[],
  setRows: React.Dispatch<React.SetStateAction<any>>,
  fieldToCheck: string,
): boolean => {
  let model: GridRowModesModel = {};
  logger.debug(`Nuking all empties for model`, model);

  const rowsToKeep = rows.filter(
    (eachRow) =>
      !(
        (eachRow[fieldToCheck] === undefined || eachRow[fieldToCheck] === '') &&
        (eachRow['id'] === undefined || eachRow['id'] === -1)
      ),
  );
  let foundAtLeastOneEmpty = rowsToKeep.length < rows.length;
  setRows(rowsToKeep);
  return foundAtLeastOneEmpty;
};

export const DataGridControlKeys = ['Tab', 'Enter'];

export function shouldBlockPropagation(event: React.KeyboardEvent): boolean {
  if (DataGridControlKeys.includes(event.key)) {
    return false;
  }
  return true;
}

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
export function simulateMouseClick(element: HTMLElement) {
  logger.debug(`Lets simulate a click for element: ${element.id}`);
  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      }),
    ),
  );
}

export const handleRowModesModelChangeStripCellFocus = (
  rowModesModel: GridRowModesModel,
  details: GridCallbackDetails,
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
) => {
  const newModel: GridRowModesModel = Object.assign(
    {},
    ...Object.keys(rowModesModel).map((eachKey) => {
      // @ts-ignore
      const { cellToFocusAfter: _, ...eachModelEntry } = rowModesModel[eachKey];
      return { [eachKey]: { ...eachModelEntry } };
    }),
  );
  setRowModesModel(newModel);
};

export const checkOverflow = (
  tabs: HTMLButtonElement | null,
  container: HTMLElement | null,
): boolean => {
  if (tabs && container) {
    const differenceInWidth = container.scrollWidth - tabs.offsetWidth;
    if (differenceInWidth <= 26) {
      return true;
    }
  }
  return false;
};

export interface IdGenerator {
  next: () => number;
}

export const isFieldRowValid = (field: SchemaFieldRow): boolean =>
  field?.name &&
  field?.name !== '' &&
  field?.name !== 'undefined' &&
  field?.type &&
  field?.type != 'undefined';

export const isPartitionRowValid = (field: PartitionFieldRow): boolean =>
  field.schemaSourceField !== '' &&
  field.schemaSourceField !== 'undefined' &&
  field.transform !== '' &&
  field.transform !== 'undefined';

export const isSortOrderRowValid = (field: SortOrderFieldRow): boolean =>
  field.schemaSourceField !== '' &&
  field.schemaSourceField !== 'undefined' &&
  field.transform !== '' &&
  field.transform !== 'undefined' &&
  field.direction !== undefined &&
  field.direction !== '' &&
  field.nullOrder !== undefined &&
  field.nullOrder !== '';

export const getIdGenerator: () => IdGenerator = (): IdGenerator => {
  let cnt = 100000;

  const generator = {
    next: () => {
      cnt++;
      return cnt;
    },
  };

  return generator;
};

export const extractInProperty = ({
  rowId,
  isNew,
  ...rest
}: PropertiesRow): InProperty => {
  return { ...rest };
};

export const extractSortOrderFieldInput = (
  { rowId, isNew, schemaSourceField, __reorder__, ...rest }: SortOrderFieldRow,
  schemaFieldNameMap: Map<string, number>,
): SortOrderFieldInput => {
  const transformObject: TransformValue = safeJSONParse(rest.transform!, {});
  const sourceFieldObject: SourceFieldValue = safeJSONParse(
    schemaSourceField,
    {},
  );
  return {
    ...rest,
    sourceId: schemaFieldNameMap.has(sourceFieldObject.rowId)
      ? schemaFieldNameMap.get(sourceFieldObject.rowId) || -1
      : -1,
    transform: transformObject?.param1
      ? `${transformObject.value}[${transformObject.param1}]`
      : transformObject.value || '',
  };
};
export const extractPartitionFieldInput = (
  idGenerator: IdGenerator,
  { isNew, id, rowId, schemaSourceField, ...rest }: PartitionFieldRow,
  schemaFieldNameMap: Map<string, number>,
): PartitionFieldInput => {
  const transformObject: TransformValue = safeJSONParse(rest.transform!, {});
  const sourceFieldObject: SourceFieldValue = safeJSONParse(
    schemaSourceField,
    {},
  );
  return {
    ...rest,
    name: `${sourceFieldObject.name} partition`,
    id: id && id > 0 ? id : idGenerator.next(),
    sourceId: schemaFieldNameMap.has(sourceFieldObject.rowId)
      ? schemaFieldNameMap.get(sourceFieldObject.rowId) || -1
      : -1,
    transform: transformObject?.param1
      ? `${transformObject.value}[${transformObject.param1}]`
      : transformObject.value || '',
  };
};
export const extractSchemaField = (
  idGenerator: IdGenerator,

  {
    identity,
    isNew,
    id,
    rowId,
    type,
    fields,
    required,
    __detail_panel_toggle__,
    __reorder__,
    ...rest
  }: SchemaFieldRow,
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
  schemaFieldNameMap: Map<string, number>,
  identifierFieldIds: number[],
): SchemaFieldInput => {
  const typeObj = safeJSONParse(type, {});
  const fieldsArray = schemaModel[rowId || ''] || [];
  const nextId = idGenerator.next();
  const fieldId = id && id > 0 ? id : nextId;
  schemaFieldNameMap.set(rowId, fieldId);
  if (identity) {
    identifierFieldIds.push(fieldId);
  }
  return {
    id: fieldId,
    required: required === undefined ? false : required,
    type: typeObj?.type
      ? typeObj?.type === 'decimal'
        ? `decimal(${typeObj.param1}, ${typeObj.param2})`
        : typeObj?.type === 'fixed'
        ? `fixed[${typeObj.param1}]`
        : {
            type: typeObj?.type,
            fields: fieldsArray
              ? fieldsArray
                  .filter((eachField: SchemaFieldRow) =>
                    isFieldRowValid(eachField),
                  )
                  .map((eachField: SchemaFieldRow, idx: number) =>
                    extractSchemaField(
                      idGenerator,
                      eachField,
                      schemaModel,
                      schemaFieldNameMap,
                      identifierFieldIds,
                    ),
                  )
              : [],
          }
      : typeObj,
    name: rest.name,
    doc: rest.doc,
  };
};

export const generateCreateTableRequest = ({
  tableName,
  tableDescription,
  schemaColumns,
  schemaModel,
  partitionFieldModel,
  sortOrderFieldModel,
  propertiesModel,
  schemaFieldNameMap,
  generator,
  partitionFieldGenerator,
  stagedTableId,
}: {
  tableName: string;
  tableDescription: string;
  schemaColumns: SchemaFieldRow[];
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>;
  partitionFieldModel: GridRowModel<PartitionFieldRow>[];
  sortOrderFieldModel: GridRowModel<SortOrderFieldRow>[];
  propertiesModel: GridRowModel<PropertiesRow>[];
  schemaFieldNameMap: Map<string, number>;
  generator: IdGenerator;
  partitionFieldGenerator: IdGenerator;
  stagedTableId?: string;
}): CreateTableRequest => {
  const identifierFieldIds: number[] = [];
  const schemaFields = schemaColumns
    .filter((eachField: SchemaFieldRow) => isFieldRowValid(eachField))
    .map((eachSchemaRow, idx) =>
      extractSchemaField(
        generator,
        eachSchemaRow,
        schemaModel,
        schemaFieldNameMap,
        identifierFieldIds,
      ),
    );

  return {
    tableName: tableName,
    stagedTableId: stagedTableId,
    schema: {
      type: 'struct',
      fields: schemaFields,
      identifierFieldIds: identifierFieldIds,
    },
    partitionSpec: {
      fields: partitionFieldModel
        .filter((eachField: PartitionFieldRow) =>
          isPartitionRowValid(eachField),
        )
        .map((eachPartitionRow) =>
          extractPartitionFieldInput(
            partitionFieldGenerator,
            eachPartitionRow,
            schemaFieldNameMap,
          ),
        ),
    },
    writeOrder: {
      fields: sortOrderFieldModel

        .filter((eachField: SortOrderFieldRow) =>
          isSortOrderRowValid(eachField),
        )
        .map((eachPartitionRow) =>
          extractSortOrderFieldInput(eachPartitionRow, schemaFieldNameMap),
        ),
    },
    properties: [
      ...propertiesModel.map((eachPropertyRow) =>
        extractInProperty(eachPropertyRow),
      ),
      ...(tableDescription && tableDescription != ''
        ? [{ name: 'comment', value: tableDescription }]
        : []),
    ],
  };
};

export const generateEditTableRequest = ({
  schemaColumns,
  schemaModel,
  partitionFieldModel,
  sortOrderFieldModel,
  schemaFieldNameMap,
  generator,
  partitionFieldGenerator,
}: {
  schemaColumns: SchemaFieldRow[];
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>;
  partitionFieldModel: GridRowModel<PartitionFieldRow>[];
  sortOrderFieldModel: GridRowModel<SortOrderFieldRow>[];
  propertiesModel: GridRowModel<PropertiesRow>[];
  schemaFieldNameMap: Map<string, number>;
  generator: IdGenerator;
  partitionFieldGenerator: IdGenerator;
}): PatchTableRequest => {
  const identifierFieldIds: number[] = [];
  const schemaFields = schemaColumns
    .filter((eachField: SchemaFieldRow) => isFieldRowValid(eachField))
    .map((eachSchemaRow, idx) =>
      extractSchemaField(
        generator,
        eachSchemaRow,
        schemaModel,
        schemaFieldNameMap,
        identifierFieldIds,
      ),
    );

  return {
    schema: {
      type: 'struct',
      fields: schemaFields,
      identifierFieldIds: identifierFieldIds,
    },
    partitionSpec: {
      fields: partitionFieldModel
        .filter((eachField: PartitionFieldRow) =>
          isPartitionRowValid(eachField),
        )
        .map((eachPartitionRow) =>
          extractPartitionFieldInput(
            partitionFieldGenerator,
            eachPartitionRow,
            schemaFieldNameMap,
          ),
        ),
    },
    writeOrder: {
      fields: sortOrderFieldModel

        .filter((eachField: SortOrderFieldRow) =>
          isSortOrderRowValid(eachField),
        )
        .map((eachPartitionRow) =>
          extractSortOrderFieldInput(eachPartitionRow, schemaFieldNameMap),
        ),
    },
  };
};

export const isRadioTrue = (value: any): boolean => {
  return value !== undefined && (value === true || value === 'true');
};

function getTableEditType(eachField: any): string {
  return `${JSON.stringify(
    eachField.type === 'struct' ||
      eachField.type === 'list' ||
      eachField.type === 'map'
      ? { type: eachField.type }
      : eachField.type?.type && eachField.type?.type?.startsWith('decimal')
      ? {
          type: 'decimal',
          param1: eachField.type.split(/[(|)]/)[1].split(',')[0].trim(),
          param2: eachField.type.split(/[(|)]/)[1].split(',')[1].trim(),
        }
      : eachField.type?.type && eachField.type?.type?.startsWith('fixed')
      ? {
          type: 'fixed',
          param1: eachField.type?.split(/[[|]]/)[1].trim(),
        }
      : eachField.type,
  )}`;
}

interface Dictionary<T> {
  [index: string]: T;
}
function populateSchema(
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
  schemaFieldToToRowIdMap: Map<number, string>,
  key: string,
  identityFields: Maybe<number>[],
  schemaField: any,
  fieldLabels?: Dictionary<Maybe<FieldLabel>[]>,
) {
  //we are either a struct field or the top level schema object (which is a struct field)
  if (schemaField.fields) {
    schemaModel[key] = schemaField.fields.map((eachField: SchemaField) => {
      const newRowId = uuidv4();
      //@ts-ignore
      const fl = fieldLabels && fieldLabels[eachField?.id];
      if (eachField) {
        if (eachField.id) {
          schemaFieldToToRowIdMap.set(eachField.id, newRowId);
        }
        if (
          eachField.type?.type === 'struct' ||
          eachField.type?.type === 'map' ||
          eachField.type?.type === 'list'
        ) {
          populateSchema(
            schemaModel,
            schemaFieldToToRowIdMap,
            newRowId,
            identityFields,
            eachField.type,
            fieldLabels,
          );
        }
        return {
          id: eachField.id,
          rowId: newRowId,
          name: eachField.name ?? '',
          isNew: false,
          identity: identityFields.includes(eachField.id!),
          required: eachField.required,
          type: getTableEditType(eachField),
          doc: eachField.doc,
          fields: [],
          fieldLabels: fl,
        };
      } else {
        return {
          id: 0,
          rowId: '',
          name: '',
          isNew: false,
          identity: false,
          fields: [],
          fieldLabels: [],
        };
      }
    });
  } else if (schemaField.type === 'map') {
    const newRowIdKey = uuidv4();
    const newRowIdValue = uuidv4();
    if (schemaField['key-id']) {
      schemaFieldToToRowIdMap.set(schemaField['key-id'], newRowIdKey);
    }
    if (schemaField['value-id']) {
      schemaFieldToToRowIdMap.set(schemaField['value-id'], newRowIdValue);
    }
    schemaModel[key] = [
      {
        id: schemaField['key-id'],
        rowId: newRowIdKey,
        name: 'key',
        isNew: false,
        identity: identityFields.includes(schemaField['key-id']),
        required: true,
        type: getTableEditType({ type: schemaField.key }),
        fields: [],
      },
      {
        id: schemaField['value-id'],
        rowId: newRowIdValue,
        name: 'value',
        isNew: false,
        identity: identityFields.includes(schemaField['value-id']),
        required: schemaField['value-required'],
        type: getTableEditType({ type: schemaField.value }),
        fields: [],
      },
    ];
    populateSchema(
      schemaModel,
      schemaFieldToToRowIdMap,
      newRowIdKey,
      identityFields,
      schemaField.key,
    );
    populateSchema(
      schemaModel,
      schemaFieldToToRowIdMap,
      newRowIdValue,
      identityFields,
      schemaField.value,
    );
  } else if (schemaField.type === 'list') {
    const newRowIdElement = uuidv4();

    if (schemaField['element-id']) {
      schemaFieldToToRowIdMap.set(schemaField['element-id'], newRowIdElement);
    }

    schemaModel[key] = [
      {
        id: schemaField['element-id'],
        rowId: newRowIdElement,
        name: 'element',
        isNew: false,
        identity: identityFields.includes(schemaField['element-id']),
        required: schemaField['element-required'],
        type: getTableEditType({ type: schemaField.element }),
        fields: [],
      },
    ];
    populateSchema(
      schemaModel,
      schemaFieldToToRowIdMap,
      newRowIdElement,
      identityFields,
      schemaField.element,
    );
  }
}

export const getSchemaModelFromMetadata = (
  metadata?: TableMetadata,
  fieldLabels?: Dictionary<Maybe<FieldLabel>[]>,
): {
  schemaFieldToToRowIdMap: Map<number, string>;
  deserializedSchemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>;
  initialValidityModel: Record<GridRowId, number>;
} => {
  if (!metadata || !metadata.current_schema?.fields) {
    return {
      schemaFieldToToRowIdMap: new Map<number, string>(),
      deserializedSchemaModel: {},
      initialValidityModel: {},
    };
  }
  let nameMap = new Map<number, string>();
  let retSchema: Record<string, GridRowModel<SchemaFieldRow>[]> = {};
  populateSchema(
    retSchema,
    nameMap,
    'top',
    metadata.current_schema.identifier_field_ids ?? [],
    metadata.current_schema as SchemaField,
    fieldLabels,
  );
  return {
    schemaFieldToToRowIdMap: nameMap,
    deserializedSchemaModel: retSchema,
    initialValidityModel: Object.assign(
      {},
      ...Object.keys(retSchema)
        .filter((key) => key !== 'top')
        .map((key) => {
          return { [key]: -1 };
        }),
    ),
  };
};

export const getPartitionFieldModelFromMetadata = (
  metadata: TableMetadata | undefined,
  schemaFieldToToRowIdMap: Map<number, string>,
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
): GridRowModel<PartitionFieldRow>[] => {
  if (!metadata || !metadata.default_partition_spec?.fields) {
    return [];
  }
  const retModel: GridRowModel<PartitionFieldRow>[] =
    metadata.default_partition_spec.fields
      .filter((eachPartField) => eachPartField!.transform !== 'void')
      .map((eachPartField) => {
        const newRowId = uuidv4();
        if (!eachPartField) {
          return {
            rowId: newRowId,
            isNew: false,
            sourceId: 0,
            schemaSourceField: '',
            transform: '',
            direction: '',
            nullOrder: '',
            id: 0,
          };
        }

        const sourceField = findSourceFieldCandidateById(
          schemaModel,
          schemaModel['top'],
          null,
          eachPartField.source_id!,
        );

        return {
          id: eachPartField.field_id!,
          rowId: newRowId,
          isNew: false,
          sourceId: eachPartField.source_id!,
          schemaSourceField: `${JSON.stringify({
            rowId: schemaFieldToToRowIdMap.get(eachPartField.source_id ?? -1),
            name: sourceField?.name,
            type: sourceField?.type,
            description: sourceField?.description,
          })}`,

          transform: `${JSON.stringify({
            value: eachPartField.transform ?? '',
            name:
              eachPartField.transform === 'identity'
                ? 'none'
                : eachPartField.transform,
          })}`,
        };
      });

  return retModel;
};

export const getSortOrderFieldModelFromMetadata = (
  metadata: TableMetadata | undefined,
  schemaFieldToToRowIdMap: Map<number, string>,
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
): GridRowModel<SortOrderFieldRow>[] => {
  if (!metadata || !metadata.default_sort_order?.fields) {
    return [];
  }
  const retModel: GridRowModel<SortOrderFieldRow>[] =
    metadata.default_sort_order.fields.map((eachSortField) => {
      const newRowId = uuidv4();
      if (!eachSortField) {
        return {
          rowId: newRowId,
          isNew: false,
          sourceId: 0,
          schemaSourceField: '',
          transform: '',
          direction: '',
          nullOrder: '',
        };
      }

      const sourceField = findSourceFieldCandidateById(
        schemaModel,
        schemaModel['top'],
        null,
        eachSortField.source_id!,
      );

      return {
        rowId: newRowId,
        isNew: false,
        sourceId: eachSortField.source_id!,
        schemaSourceField: `${JSON.stringify({
          rowId: schemaFieldToToRowIdMap.get(eachSortField.source_id ?? -1),
          name: sourceField?.name,
          type: sourceField?.type,
          description: sourceField?.description,
        })}`,

        transform: `${JSON.stringify({
          value: eachSortField.transform ?? '',
          name:
            eachSortField.transform === 'identity'
              ? 'none'
              : eachSortField.transform,
        })}`,
        direction: eachSortField.direction ?? '',
        nullOrder: eachSortField.null_order ?? '',
      };
    });

  return retModel;
};

export const canMigrateType = (
  mode: EditTableMode,
  currentPrimativeType: string,
  destinationPrimativeType: string,
) => {
  return (
    (mode === EditTableMode.EDIT &&
      currentPrimativeType === destinationPrimativeType) ||
    currentPrimativeType.startsWith(destinationPrimativeType) ||
    (currentPrimativeType === 'int' && destinationPrimativeType === 'long') ||
    (currentPrimativeType === 'float' && destinationPrimativeType === 'double')
  );
};

export const isIdentifierEligible = (
  mode: EditTableMode,
  type: string,
  baseType: string,
  required: boolean,
  baseTypeRequired: boolean,
  level: number,
): { isEligible: boolean; msg: string[] } => {
  let msg = [];
  let eligible = true;
  if (!baseTypeRequired) {
    eligible = false;
    msg.push('Parent type must be required');
  }
  if (!required) {
    eligible = false;
    msg.push(`${level > 0 ? 'Field' : 'Column'} must be required`);
  }
  if (baseType != 'struct') {
    eligible = false;
    msg.push(`Parent type must be a struct`);
  }
  if (type === 'float' || type === 'double') {
    eligible = false;
    msg.push(`${level > 0 ? 'Field' : 'Column'} cannot be a float or double`);
  }
  if (type === 'struct' || type === 'map' || type === 'list') {
    eligible = false;
    msg.push(`${level > 0 ? 'Field' : 'Column'} must be a primitive type`);
  }
  return { isEligible: eligible, msg };
};

export const safeJSONParse = (jsonStringValue: string, defaultValue: any) => {
  try {
    return JSON.parse(jsonStringValue);
  } catch (e) {
    logger.error("can't deserialize value ", jsonStringValue, e);
    return defaultValue;
  }
};

export interface SchemaFieldResult {
  id: number;
  fullName: string;
  name: string;
  type: string;
}

export function findSchemaFields(
  targetTypes: string[],
  schemaField: any,
  getMapsListsNestedField = true,
) {
  let results: SchemaFieldResult[] = [];
  _findSchemaFields(
    targetTypes,
    schemaField,
    null,
    results,
    getMapsListsNestedField,
  );
  return results;
}
function _findSchemaFields(
  targetTypes: string[],
  schemaField: any,
  parentName: Maybe<string> | undefined,
  results: SchemaFieldResult[],
  getMapsListsNestedField: boolean,
) {
  //we are either a struct field or the top level schema object (which is a struct field)
  if (schemaField?.fields) {
    results.push(
      ...schemaField.fields.map((eachField: SchemaField) => {
        if (eachField) {
          const fullName = parentName
            ? `${parentName}.${eachField.name}`
            : eachField.name;
          if (
            eachField.type?.type === 'struct' ||
            (getMapsListsNestedField && eachField.type?.type === 'map') ||
            (getMapsListsNestedField && eachField.type?.type === 'list')
          ) {
            _findSchemaFields(
              targetTypes,
              eachField.type,
              fullName,
              results,
              getMapsListsNestedField,
            );
          }
          return {
            id: eachField.id,
            fullName: fullName,
            name: eachField.name ?? '',
            type: getTableEditType(eachField),
          };
        } else {
          return {
            id: 0,
            fullName: '',
            name: '',
            type: '',
          };
        }
      }),
    );
  } else if (schemaField?.type === 'map') {
    const keyFullName = parentName
      ? `${parentName}.${schemaField.name}`
      : schemaField.name;
    const valueFullName = parentName
      ? `${parentName}.${schemaField.name}`
      : schemaField.name;
    results.push(
      {
        id: schemaField['key-id'],
        name: schemaField.name,
        fullName: keyFullName,
        type: getTableEditType({ type: schemaField.key }),
      },
      {
        id: schemaField['value-id'],
        name: schemaField.name,
        fullName: valueFullName,
        type: getTableEditType({ type: schemaField.value }),
      },
    );
    _findSchemaFields(
      targetTypes,
      schemaField.key,
      keyFullName,
      results,
      getMapsListsNestedField,
    );
    _findSchemaFields(
      targetTypes,
      schemaField.value,
      valueFullName,
      results,
      getMapsListsNestedField,
    );
  } else if (schemaField?.type === 'list') {
    const elementFullName = parentName
      ? `${parentName}.${schemaField.name}`
      : schemaField.name;
    results.push({
      id: schemaField['element-id'],
      name: schemaField.name,
      fullName: elementFullName,
      type: getTableEditType({ type: schemaField.element }),
    });
    _findSchemaFields(
      targetTypes,
      schemaField.element,
      elementFullName,
      results,
      getMapsListsNestedField,
    );
  }
}

export function hasComplexTypes(
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
): boolean {
  return Object.keys(schemaModel).length > 1;
}

export function createCDCTargetSchemaModel(
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
): Record<string, GridRowModel<SchemaFieldRow>[]> {
  const retModel = {
    ...schemaModel,
    top: schemaModel['top'].filter((row) => row.name.toLowerCase() !== 'op'),
  };

  return retModel;
}

export function createCDCChangelogSchemaModel(
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
): Record<string, GridRowModel<SchemaFieldRow>[]> {
  const retModel = {
    ...prepareChangeLogSchema(schemaModel),
  };

  return retModel;
}

function prepareChangeLogSchema(
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
): Record<string, GridRowModel<SchemaFieldRow>[]> {
  const newRowId = uuidv4();

  const hasOpColumn = schemaModel['top'].some(
    (eachField) => eachField?.name?.toLowerCase() == 'op',
  );

  const retModel = {
    ...schemaModel,
    top: [
      ...(hasOpColumn
        ? []
        : [
            {
              rowId: uuidv4(),
              isNew: false,
              fields: [],
              name: 'op',
              type: '"string"',
              required: false,
              identity: false,
            },
          ]),
      ...schemaModel['top'],
      {
        rowId: newRowId,
        isNew: false,
        fields: [],
        name: '_load_metadata',
        type: '{"type":"struct"}',
        required: false,
        identity: false,
      },
    ],
    [newRowId]: [
      {
        rowId: uuidv4(),
        isNew: false,
        identity: false,
        name: 'source_file_name',
        fields: [],
        type: '"string"',
        required: false,
      },
      {
        rowId: uuidv4(),
        isNew: false,
        fields: [],
        name: 'source_file_creation_ts',
        identity: false,
        type: '"timestamptz"',
        required: false,
      },
      {
        rowId: uuidv4(),
        isNew: false,
        fields: [],
        name: 'load_ts',
        identity: false,
        type: '"timestamptz"',
        required: false,
      },
      {
        rowId: uuidv4(),
        isNew: false,
        fields: [],
        name: 'load_num',
        identity: false,
        type: '"long"',
        required: false,
      },
    ],
  };

  return retModel;
}

export function createCDCTargetPropertiesModel(
  propertiesModel: GridRowModel<PropertiesRow>[],
  changeLogSortOrderModel: GridRowModel<SortOrderFieldRow[]>,
  targetLag: number,
): GridRowModel<PropertiesRow[]> {
  let userSpecifiedProps = keyBy(propertiesModel, 'name');
  userSpecifiedProps['cdc.ts-column'] = {
    name: 'cdc.ts-column',
    value: changeLogSortOrderModel
      .map(
        (eachSortRow) =>
          safeJSONParse(eachSortRow.schemaSourceField, { name: '' }).name,
      )
      .join(','),
    rowId: uuidv4(),
    isNew: false,
  };
  userSpecifiedProps['etl.job-type'] = {
    name: 'etl.job-type',
    value: 'cdc',
    rowId: uuidv4(),
    isNew: false,
  };
  userSpecifiedProps['target-lag'] = {
    name: 'etl.target-lag',
    value: String(targetLag),
    rowId: uuidv4(),
    isNew: false,
  };

  const retModel = [...Object.values(userSpecifiedProps)];

  return retModel;
}

export function createCDCChangelogSortModel(
  finalChangeLogSchemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>,
  changeLogSortOrderModel: GridRowModel<SortOrderFieldRow[]>,
): GridRowModel<SortOrderFieldRow[]> {
  const retOrderModel = changeLogSortOrderModel.map((eachSortOrderField) => {
    return {
      ...eachSortOrderField,
      schemaSourceField: JSON.stringify(
        findSourceFieldCandidateByName(
          finalChangeLogSchemaModel,
          finalChangeLogSchemaModel['top'],
          null,
          safeJSONParse(eachSortOrderField.schemaSourceField, {})?.name,
        ),
      ),
    };
  });
  return [...retOrderModel];
}
