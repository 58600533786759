import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import { useUserAgent } from 'react-ua';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box, Grid, Stack, StackProps, Typography } from '@mui/material';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Tabutton } from '../../Button/Tabutton';

interface CelerDataSqlTemplateType extends StackProps {
  catalogName: string;
}
export const CelerDataSqlTemplate = ({
  catalogName,
  ...props
}: CelerDataSqlTemplateType): JSX.Element => {
  const cmds = [
    {
      title: `Set catalog to use`,
      cmd: `set catalog ${catalogName};`,
    },
    {
      title: `List your Warehouse's databases.`,
      cmd: `show databases;`,
    },
    { title: `Use the example database.`, cmd: `use examples;` },
    {
      title: `Describe example table`,
      cmd: `describe ${'`'}${catalogName}${'`'}.${'`'}examples${'`'}.${'`'}nyc_taxi_yellow${'`'};`,
    },
    {
      title: `Analyze New York Taxi data`,
      cmd: `create table if not exists ${'`'}${catalogName}${'`'}.${'`'}default${'`'}.taxi_tip_rate_per_passenger_celerdata as
select
passenger_count,
 count(*) as trip_count,
 avg(tip_amount / fare_amount) as tip_rate
from ${'`'}${catalogName}${'`'}.${'`'}examples${'`'}.nyc_taxi_yellow
where
pickup_time between '2020-03-01' and '2020-03-31'
  and passenger_count > 0
  and fare_amount > 0
group by passenger_count;`,
    },
    {
      title: 'Analyze data',
      cmd: `select
  passenger_count,
  trip_count,
  round(tip_rate * 100, 2) as tip_percentage
from ${'`'}${catalogName}${'`'}.${'`'}default${'`'}.taxi_tip_rate_per_passenger_celerdata
order by passenger_count;`,
    },
  ];

  return (
    <Stack direction="column" {...props} sx={{}}>
      {cmds.map((cmd, idx) => (
        <Box key={'sqlcmd' + idx} sx={{ width: 1 }}>
          <Grid
            container
            sx={{
              width: 0.95,
              mt: 2,
              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 2,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
              }}
            >
              <Typography variant={'subtitle1'} sx={{}}>
                {cmd.title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 0,
                pre: {
                  m: 0,
                  height: 1,
                },
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              {' '}
              <SyntaxHighlighter
                language="SQL"
                style={{ ...dracula }}
                showLineNumbers={false}
              >
                {cmd.cmd}
              </SyntaxHighlighter>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 0.5,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant={'body1'}>
                {/* @ts-ignore */}
                <Tabutton
                  onClick={() => navigator.clipboard.writeText(cmd.cmd)}
                  title="Copy to clipboard"
                  size={'small'}
                  endIcon={<FileCopyIcon />}
                >
                  Copy Command
                </Tabutton>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Stack>
  );
};
