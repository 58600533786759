import * as React from 'react';

import { Typography, Box, Stack, Divider } from '@mui/material';

type Props = {
  region?: string;
  bucketName?: string;
  type?: string;
  name: string;
  comment?: string;
};
export const NamePopover = React.forwardRef(
  ({ region, bucketName, type, name, comment, ...props }: Props, ref) => {
    return (
      <Box
        ref={ref}
        {...props}
        sx={(theme) => ({
          backgroundColor: 'popper.main',
          color: 'popper.contrastText',
          borderRadius: '4px',
          maxWidth: '480px',
          position: 'relative',
          boxShadow: theme.shadows[3],
          ml: '10px',
        })}
      >
        <Stack direction={`column`}>
          {region && (
            <>
              <Box
                display={`flex`}
                alignItems={`center`}
                sx={{
                  py: 1,
                  px: 2,
                  backgroundColor: 'popper.one',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                }}
              >
                <Typography sx={{ mr: 1 }} variant={`caption`}>
                  Region
                </Typography>
                <Typography variant={`body2`}>{region}</Typography>
              </Box>
              <Divider sx={{ backgroundColor: 'popper.one' }} />
            </>
          )}
          {bucketName && (
            <>
              <Box
                display={`flex`}
                alignItems={`center`}
                sx={{
                  py: 1,
                  px: 2,
                  backgroundColor: 'popper.one',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                }}
              >
                <Typography sx={{ mr: 1 }} variant={`caption`}>
                  Bucket
                </Typography>
                <Typography variant={`body2`}>{bucketName}</Typography>
              </Box>
              <Divider sx={{ backgroundColor: 'popper.one' }} />
            </>
          )}
          {type && (
            <>
              <Box
                display={`flex`}
                alignItems={`center`}
                sx={{
                  py: 1,
                  px: 2,
                  backgroundColor: 'popper.one',
                  borderTopLeftRadius: '4px',
                }}
              >
                <Typography sx={{ mr: 1 }} variant={`caption`}>
                  Type
                </Typography>
                <Typography variant={`body2`}>{type}</Typography>
              </Box>
              <Divider sx={{ backgroundColor: 'popper.one' }} />
            </>
          )}
          <Box
            display={`flex`}
            alignItems={`center`}
            sx={{
              py: 1,
              px: 2,
              overflowWrap: 'anywhere',
            }}
          >
            <Typography variant={`body2`}>{name}</Typography>
          </Box>
          {comment && (
            <>
              <Box
                display={`flex`}
                alignItems={`center`}
                sx={{
                  py: 1,
                  px: 2,
                  backgroundColor: 'popper.one',
                  borderBottomRightRadius: '4px',
                  borderBottomLeftRadius: '4px',
                }}
              >
                <Typography variant={`body2`}>{comment}</Typography>
              </Box>
            </>
          )}
        </Stack>
      </Box>
    );
  },
);
