import React, { useMemo } from 'react';

import {
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import fileSize from 'filesize';

import { Table } from '../../../graphql/gen/graphql';
import { Event } from '../../../schema/events/event';
import { Failed } from '../../../schema/events/failed';
import { FileLoadEntity } from '../../../schema/events/file-load';
import { TableEventTypes, Verbosity } from '../TableEventsView';
import FailedServiceDetails from './FailedServiceDetails';

type Props = {
  event: Event;
  table: Table;
  verbosity?: Verbosity;
};
export default function FileLoadView({
  event,
  table,
  verbosity = Verbosity.Summary,
}: Props) {
  const fileLoadEntity = event.context.find(
    (ctx) => ctx.type === 'FileLoad',
  ) as FileLoadEntity;

  const filesTotalCount = fileLoadEntity.files.length;

  const totalFileBytes = useMemo(
    () =>
      fileLoadEntity.files.reduce(
        (prev, fileB) => prev + (fileB.file_size || 0),
        0,
      ),
    [event],
  );

  let fileLoadMessage = (function () {
    switch (event.type) {
      case TableEventTypes.RUN_FILE_LOAD:
        return 'Scheduled FileLoad for';
      case TableEventTypes.STARTED_FILE_LOAD:
        return 'Started Loading';
      case TableEventTypes.SUCCEEDED_FILE_LOAD:
        return 'Successfully Loaded';
      default:
        return 'Failed to Load';
    }
  })();

  switch (verbosity) {
    case Verbosity.Summary:
      return (
        <div>{`${fileLoadMessage} ${filesTotalCount} file${
          filesTotalCount == 1 ? '' : 's'
        } representing ${fileSize(totalFileBytes)}`}</div>
      );
    case Verbosity.Detailed:
      if (event.type == TableEventTypes.FAILED_FILE_LOAD) {
        return <FailedServiceDetails event={event as Failed} table={table} />;
      } else {
        return (
          <>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={10}>
                <TableContainer component={Paper}>
                  <MuiTable sx={{}} aria-label="Listing">
                    <TableHead>
                      <TableRow>
                        <TableCell>File Name</TableCell>

                        <TableCell align="right">Size</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fileLoadEntity.files?.map((file) => (
                        <TableRow
                          key={file?.file_name}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {file?.file_name}
                          </TableCell>

                          <TableCell align="right">
                            {fileSize(file?.file_size as number)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </MuiTable>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        );
      }
    default:
      return null;
  }
}
