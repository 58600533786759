import React, { useState } from 'react';

import {
  Box,
  CardContent,
  CardHeader,
  Typography,
  CardActions,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Form, Formik } from 'formik';
import {
  flatten,
  includes,
  isEmpty,
  map,
  mapKeys,
  pickBy,
  forEach,
  replace,
  has,
} from 'lodash';

import { Property, Schema, StorageProfile } from '../../graphql/gen/graphql';
import {
  CompactionOverrideProperties,
  lifecycleManagementProperties,
} from '../../utils/properties';
import { Tabutton } from '../Button/Tabutton';
import { Tacard } from '../Card/Tacard';
import { getSchemeForStorageType } from '../CreateStorageBucket/ValidationStatusHelpers';
import { Switch } from '../Forms/Switch';
import TextField from '../Forms/TextField';
import SectionHeader from '../SectionHeader/SectionHeader';
import CompactionOverrides from './CompactionOverrides';
import FileLoaderFileFormat from './FileLoaderFileFormat';
import LifecycleManagement from './LifecycleManagement';
import PathWithStorageBrowser from './PathWithStorageBrowser';
import ReadOnlyWithCopy from './ReadOnlyWithCopy';
import {
  convertBooleanToString,
  convertStringToBoolean,
  getInitialValues,
  KID_GLOVE_PROPERTIES,
  SWITCH_COMPONENT_PROPERTIES,
} from './helpers';

type PropertyGroupCardPropTypes = {
  propertyGroup: any;
  mappedProps: Property[];
  userCanEdit: boolean;
  onUpdateProperties: any;
  onDeleteOverrides: any;
  bucketName: string;
  currentSchema?: Schema;
  storageProfile: StorageProfile;
};

export default function PropertyGroupCard({
  propertyGroup,
  mappedProps,
  userCanEdit,
  onUpdateProperties,
  onDeleteOverrides,
  bucketName,
  currentSchema,
  storageProfile,
}: PropertyGroupCardPropTypes) {
  const INITIAL_VALUES = getInitialValues(
    propertyGroup.properties,
    mappedProps,
    bucketName,
    getSchemeForStorageType(storageProfile?.storageType!),
  );
  const [editingPropGroup, setEditingPropGroup] = useState(false);
  const [loading, setLoading] = useState(false);

  const componentMapping = {
    TextField,
    Switch,
    CompactionOverrides,
    ReadOnlyWithCopy,
    LifecycleManagement,
    FileLoaderFileFormat,
    PathWithStorageBrowser,
  };

  const applyChanges = async (data: any) => {
    setLoading(true);
    await onUpdateProperties(data).then(() => {
      setLoading(false);
      setEditingPropGroup(false);
    });
  };

  const handleSubmitForm = async (values: any) => {
    const propertiesToDelete: string[] = [];
    let formattedProps = map(
      mapKeys(
        pickBy(values, (value, key) => !includes(KID_GLOVE_PROPERTIES, key)),
        (value, key) =>
          //@ts-ignore
          propertyGroup.properties.find((propGroup) => propGroup.name === key)
            .key,
      ),
      (value, key) => {
        const propGroup = propertyGroup.properties.find(
          //@ts-ignore
          (propGroup) => propGroup.key === key,
        );
        const valueFormatter = propGroup?.valueFormatter;
        const additionalProperties = propGroup?.additionalPropertiesGetter
          ? propGroup.additionalPropertiesGetter(value)
          : null;

        return additionalProperties
          ? [
              {
                key: key,
                value: valueFormatter ? valueFormatter(value) : value,
              },
              additionalProperties,
            ]
          : {
              key: key,
              value: valueFormatter ? valueFormatter(value) : value,
            };
      },
    );

    formattedProps = flatten(formattedProps);

    if (values?.overrides && !isEmpty(values?.overrides)) {
      //@ts-ignore
      values.overrides.forEach((override) => {
        //@ts-ignore
        const propertyForm = CompactionOverrideProperties.find(
          (prop) => prop.form.name === Object.keys(override)[0],
        ).form;
        const valueFormatter = propertyForm?.valueFormatter;
        const helperValue = propertyForm?.helperComponentName
          ? override[propertyForm.helperComponentName]
          : null;

        formattedProps.push({
          key: propertyForm.key,
          value:
            valueFormatter && helperValue
              ? valueFormatter(override[propertyForm.name], helperValue)
              : valueFormatter && !helperValue
              ? valueFormatter(override[propertyForm.name])
              : override[propertyForm.name],
        });

        if (propertyForm?.additionalProperties) {
          propertyForm.additionalProperties.forEach((prop: any) =>
            formattedProps.push(prop),
          );
        }
      });
    }

    if (values?.lifecycleMgmt) {
      forEach(values.lifecycleMgmt, (lcprop: any, keyName: string) => {
        const propertyInfo = lifecycleManagementProperties.find(
          (prop) => prop.name === keyName,
        );

        if (keyName === 'timestampColumn' && !isEmpty(lcprop)) {
          //@ts-ignore
          formattedProps.push({ key: propertyInfo.key, value: lcprop });
        }

        if (keyName === 'timeUnit' && !isEmpty(lcprop)) {
          if (values.lifecycleMgmt?.timestampType === 'long') {
            formattedProps.push({
              key: 'lifecycle.data-age-column-units',
              value: lcprop,
            });
          } else {
            propertiesToDelete.push('lifecycle.data-age-column-units');
          }
        }

        if (keyName === 'rowRecordAge' && !isEmpty(lcprop)) {
          formattedProps.push({
            //@ts-ignore
            key: propertyInfo.key,
            //@ts-ignore
            value: propertyInfo.valueFormatter(lcprop),
          });
        }

        if (keyName === 'columnMasking' && !isEmpty(lcprop)) {
          forEach(lcprop, (column) => {
            formattedProps.push({
              //@ts-ignore
              key: replace(propertyInfo.key, 'placeholder', column.identifier),
              //@ts-ignore
              value: propertyInfo.valueFormatter(column.ttl),
            });
            formattedProps.push({
              key: replace(
                //@ts-ignore
                propertyInfo.additionalProperty.key,
                'placeholder',
                column.identifier,
              ),
              //@ts-ignore
              value: propertyInfo.additionalProperty.value,
            });
          });
        }
      });
    }

    if (has(values, 'fileLoaderIgnoreHeaders')) {
      formattedProps.push({
        key: 'fileloader.csv.header',
        value: convertBooleanToString(values.fileLoaderIgnoreHeaders),
      });
    }

    if (!isEmpty(propertiesToDelete)) {
      await onDeleteOverrides(propertiesToDelete);
    }

    applyChanges(formattedProps);
  };

  return (
    <>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={propertyGroup.validator}
        enableReinitialize
        onSubmit={handleSubmitForm}
      >
        {({ resetForm, submitForm, setFieldValue, values }) => (
          <Form>
            <Tacard>
              <CardHeader
                sx={{ pb: 2 }}
                title={
                  <Typography variant={`h4`}>{propertyGroup.name}</Typography>
                }
              />
              <CardContent sx={{ p: 0, m: 0 }}>
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      flexGrow: 1,
                      px: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <Typography variant={`body2`}>
                      {propertyGroup.description}
                    </Typography>
                  </Box>

                  {/*@ts-ignore*/}
                  {propertyGroup.properties.map((property) => {
                    //@ts-ignore
                    const Component = componentMapping[property.component];
                    const isCompactionConfig = [
                      'compaction.strategy',
                      'overrides',
                    ].includes(property.key);

                    const isFileLoaderConfig = [
                      'fileloader.path',
                      'fileloader.file-format',
                      'fileloader.write-mode',
                    ].includes(property.key);

                    const isOptimizerConfig = [
                      'write.parquet.compression-codec',
                      'write.object-storage.enabled',
                    ].includes(property.key);

                    const isLifecycleConfig = ['lifecycleMgmt'].includes(
                      property.key,
                    );

                    const stdComponentProps = {
                      id: property.name,
                      name: property.name,
                      label: property?.fieldLabel,
                      disabled:
                        !userCanEdit ||
                        (isOptimizerConfig &&
                          values?.optimizerEnabled === true),
                      select: property?.options ? true : null,
                      bucketName,
                      storageProfile,
                      setEditingPropGroup,
                    };

                    const componentProps = {
                      ...stdComponentProps,
                      ...(property.component === 'CompactionOverrides'
                        ? {
                            deleteoverride: onDeleteOverrides,
                            setFieldValue: setFieldValue,
                          }
                        : {}),
                      ...(property.component === 'LifecycleManagement'
                        ? {
                            currentSchema: currentSchema,
                            setFieldValue: setFieldValue,
                            stagePropertiesForDelete: onDeleteOverrides,
                          }
                        : {}),
                      ...(property.component === 'FileLoaderFileFormat'
                        ? {
                            setFieldValue: setFieldValue,
                            ignoreHeadersProp: convertStringToBoolean(
                              mappedProps.find(
                                (existing) =>
                                  existing.key === 'fileloader.csv.header',
                              )?.value || 'false',
                            ),
                          }
                        : {}),
                    };

                    const hideCompactionConfig =
                      isCompactionConfig && values?.compactionEnabled === false;
                    const hideFileLoaderConfig =
                      isFileLoaderConfig && values?.fileloaderEnabled === false;
                    const hideLifecycleConfig =
                      isLifecycleConfig && values?.lifecycleEnabled === false;

                    return (
                      <div key={property.key}>
                        {hideCompactionConfig ||
                        hideFileLoaderConfig ||
                        hideLifecycleConfig ? null : (
                          <Box
                            sx={{
                              backgroundColor: 'controlBackground.half',
                              '&:last-of-type': { mb: 0 },
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              justifyContent: 'start',
                            }}
                          >
                            {property.label && (
                              <SectionHeader>
                                <Typography
                                  variant={`h6`}
                                  display={'inline-flex'}
                                  alignItems={'center'}
                                  sx={{}}
                                >
                                  {property.label}
                                </Typography>
                              </SectionHeader>
                            )}
                            {property.description && (
                              <Typography
                                variant={`body2`}
                                sx={{ px: 2, paddingTop: 2 }}
                              >
                                {property.description}
                              </Typography>
                            )}
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                              }}
                            >
                              {includes(
                                SWITCH_COMPONENT_PROPERTIES,
                                property.key,
                              ) ? (
                                <Box
                                  onClick={() => {
                                    !componentProps.disabled &&
                                      setEditingPropGroup(true);
                                  }}
                                  sx={{ p: 2 }}
                                >
                                  <Component
                                    {...componentProps}
                                    onClick={() => {
                                      !componentProps.disabled &&
                                        setEditingPropGroup(true);
                                    }}
                                    sx={{
                                      marginTop: 1,
                                      marginBottom: 2,
                                      justifyContent: 'flex-start',
                                    }}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    width: '100%',
                                    p:
                                      property.component ===
                                      'LifecycleManagement'
                                        ? 0
                                        : 2,
                                  }}
                                >
                                  <Component
                                    {...componentProps}
                                    onClick={() => {
                                      !componentProps.disabled &&
                                        setEditingPropGroup(true);
                                    }}
                                    sx={{
                                      marginTop: 1,
                                      marginBottom: 2,
                                      justifyContent: 'flex-start',
                                      minWidth: '160px',
                                    }}
                                  >
                                    {property?.options?.map((option: any) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        onClick={() => {
                                          !componentProps.disabled &&
                                            setEditingPropGroup(true);
                                        }}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Component>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        )}
                      </div>
                    );
                  })}
                </>
              </CardContent>
              <CardActions
                sx={(theme) => ({
                  borderTop: `1px solid ${theme.palette.midnight.two}`,
                  display: 'flex',
                  justifyContent: 'end',
                  p: 2,
                })}
              >
                <Tabutton
                  onClick={() => {
                    resetForm();
                    setEditingPropGroup(false);
                  }}
                  disabled={!editingPropGroup || loading}
                >
                  Cancel
                </Tabutton>
                <Tabutton
                  loadingBtn
                  onClick={async () => {
                    await submitForm();
                  }}
                  variant={`contained`}
                  disabled={!editingPropGroup}
                  loading={loading}
                >
                  Apply Changes
                </Tabutton>
              </CardActions>
            </Tacard>
          </Form>
        )}
      </Formik>
    </>
  );
}
