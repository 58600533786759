import * as React from 'react';
import { KeyboardEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SearchIcon from '@mui/icons-material/Search';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import {
  Box,
  CardHeader,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { includes, isEmpty, some } from 'lodash';

import { toStorageProfile } from '../RouteTable';
import { AddNewResource } from '../components/Button/AddNewResource';
import { Refresh } from '../components/Button/Refresh';
import { TaClipboardText } from '../components/Clipboard/TaClipboardText';
import DashboardMenu from '../components/DashboardMenu/DashboardMenu';
import { DropdownMenuContent } from '../components/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../components/DropdownMenu/DropdownMenuItem';
import { showCreateLabelDialog } from '../components/Modals/CreateLabelDialog';
import { showCreateRoleDialog } from '../components/Modals/CreateRoleDialog';
import { Organization, Role, Table, User } from '../graphql/gen/graphql';
import { compactNumberFormatter, formatBytes } from '../utils/numbers';
import { getDifferenceInDays, getDifferenceInHours } from '../utils/time';

export interface lastModifiedType {
  profilePic?: string;
  displayName: string;
  modifiedTimestamp: string;
  modifiedDate: string;
  tabularService: boolean;
  email: string;
}

export const STATISTICS_DAYS_BACK = 7;
export const NESTED_TYPES = ['struct', 'list', 'map'];
export const SERVICE_CREDENTIAL_TYPES = ['FEDERATED', 'SERVICE'];
export const getTotalTables = (tables: Table[]) => {
  // @ts-ignore
  return tables.toLocaleString('en-US');
};

export const getByteSize = (bytes: number) => {
  return bytes ? formatBytes(bytes) : 0;
};

export const getFileSize = (files: number) => {
  return files ? files.toLocaleString('en-US') : '--';
};

export const getSizeDifference = (
  added: number,
  removed: number,
  type: string,
) => {
  if (!added && !removed) {
    return '--';
  }
  const number = added - removed;
  const formatted =
    type === 'compact'
      ? compactNumberFormatter.format(number)
      : type === 'bytes'
      ? getByteSize(number)
      : getFileSize(number);

  return number > 0 ? `+ ${formatted}` : `- ${formatted}`;
};

export const getSizeDifferencePercentage = (
  added: number,
  removed: number,
  total: number,
) => {
  const difference = added - removed;
  const percentage = (100 * difference) / total;

  return {
    icon:
      difference > 0 ? (
        <NorthEastIcon fontSize={`small`} />
      ) : (
        <SouthEastIcon fontSize={`small`} />
      ),
    value: `${percentage.toFixed(1)}%`,
  };
};

export const getTotalSizePercentage = (
  totalBytes: number,
  allWarehouses: number,
) => {
  if (totalBytes) {
    const percentage = (100 * totalBytes) / allWarehouses;
    return { display: `${percentage.toFixed(1)}%`, value: percentage };
  }

  return { display: '--', value: 0 };
};

export const commitComparator = (v1: any, v2: any) =>
  v1.commitCnt - v2.commitCnt;
export const lastUpdateComparator = (v1: Date, v2: Date) =>
  // @ts-ignore
  new Date(v1) - new Date(v2);
export const rowCountComparator = (v1: any, v2: any) =>
  v1.totalRows - v2.totalRows;
export const sizeComparator = (v1: any, v2: any) =>
  v1.totalBytes - v2.totalBytes;

export const diffComparator = (v1: any, v2: any) => {
  const v1Percentage =
    v1.bytesAdded || v1.bytesRemoved
      ? (100 * (v1.bytesAdded - v1.bytesRemoved)) / v1.totalBytes
      : 0;
  const v2Percentage =
    v2.bytesAdded || v2.bytesRemoved
      ? (100 * (v2.bytesAdded - v2.bytesRemoved)) / v2.totalBytes
      : 0;

  return v1Percentage - v2Percentage;
};

export const fileDiffComparator = (v1: any, v2: any) => {
  const v1Percentage =
    v1.filesAdded || v1.filesRemoved
      ? (100 * (v1.filesAdded - v1.filesRemoved)) / v1.totalFiles
      : 0;
  const v2Percentage =
    v2.filesAdded || v2.filesRemoved
      ? (100 * (v2.filesAdded - v2.filesRemoved)) / v2.totalFiles
      : 0;

  return v1Percentage - v2Percentage;
};

export const warehouseSizePercentComparator = (v1: any, v2: any) => {
  const v1Percentage = v1.totalBytes
    ? (100 * v1.totalBytes) / v1.allWarehousesSize
    : 0;
  const v2Percentage = v2.totalBytes
    ? (100 * v2.totalBytes) / v2.allWarehousesSize
    : 0;

  return v1Percentage - v2Percentage;
};

export const databaseSizePercentComparator = (v1: any, v2: any) => {
  const v1Percentage = v1.totalBytes
    ? (100 * v1.totalBytes) / v1.allDatabasesSize
    : 0;
  const v2Percentage = v2.totalBytes
    ? (100 * v2.totalBytes) / v2.allDatabasesSize
    : 0;

  return v1Percentage - v2Percentage;
};

export const tableSizePercentComparator = (v1: any, v2: any) => {
  const v1Percentage = v1.totalBytes
    ? (100 * v1.totalBytes) / v1.allTablesSize
    : 0;
  const v2Percentage = v2.totalBytes
    ? (100 * v2.totalBytes) / v2.allTablesSize
    : 0;

  return v1Percentage - v2Percentage;
};

export const calculateTextWidth = (
  txt: string,
  font: string,
  iconSpace?: boolean,
) => {
  const element = document.createElement('canvas');
  const context = element.getContext('2d');
  const space = iconSpace ? 50 : 8;
  // @ts-ignore
  context.font = font;
  // @ts-ignore
  return context.measureText(txt).width + space; //add extra pixels to account for icons and padding
};

export const getCommitFrequency = (commits: number, createdAt: Date) => {
  if (!commits || !createdAt) return '--';
  const createdDaysAgo = getDifferenceInDays(new Date(), new Date(createdAt));
  const statDays = createdDaysAgo < 7 ? createdDaysAgo : 7;
  const createdHoursAgo = getDifferenceInHours(new Date(), new Date(createdAt));
  const formatResult = (number: number, unit: string) =>
    `${Math.round(number)}/${unit}`;

  const calculateByHourOrMinute = (count: number) => {
    const perMinute = count / 60;
    return perMinute < 1
      ? formatResult(count, 'hr')
      : formatResult(perMinute, 'min');
  };

  if (createdDaysAgo > 0) {
    const perDay = commits / statDays;
    if (perDay < 1) {
      return formatResult(commits, 'week');
    }
    const perHour = perDay / 24;
    if (perHour < 1) {
      return formatResult(perDay, 'day');
    }
    return calculateByHourOrMinute(perHour);
  }

  if (createdHoursAgo > 0) {
    const perHour = commits / createdHoursAgo;
    if (perHour < 1) {
      return formatResult(commits, 'day');
    }
    return calculateByHourOrMinute(perHour);
  }

  return calculateByHourOrMinute(commits);
};

export const getLastModifiedInfo = (statsInfo: any, recordInfo: any) => {
  if (!statsInfo?.lastModified) {
    return {
      lastModifiedDate: recordInfo.lastModified,
      lastModifiedBy: recordInfo.lastModifiedBy,
    };
  }

  return statsInfo.lastModified > recordInfo.lastModified
    ? {
        lastModifiedDate: statsInfo.lastModified,
        lastModifiedBy: statsInfo.lastModifiedBy,
      }
    : {
        lastModifiedDate: recordInfo.lastModified,
        lastModifiedBy: recordInfo.lastModifiedBy,
      };
};

export const teeUp = (collection: any, fn: any) => {
  const non_matches = [] as any;
  if (collection === undefined || collection === null) return;
  const matches = collection.filter((e: any, i: any, a: any) => {
    const match = fn(e, i, a);
    if (!match) non_matches.push(e);
    return match;
  });
  return matches.concat(non_matches);
};

export const ignoreEmojis =
  /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g;

export const getApplyFilterFnLastModified = (value: any) => {
  return ({ value: columnValue }: GridCellParams): boolean => {
    return columnValue?.displayName != '' || columnValue?.email != null
      ? includes(
          columnValue?.displayName?.toLowerCase(),
          value.toLowerCase(),
        ) || includes(columnValue?.email, value?.toLowerCase())
      : false;
  };
};

export const getApplyFilterFnUsers = (value: any) => {
  return ({ value: columnValue }: GridCellParams): boolean => {
    return !isEmpty(columnValue)
      ? some(
          columnValue,
          (user: User) =>
            includes(user?.displayName?.toLowerCase(), value.toLowerCase()) ||
            includes(user?.email, value?.toLowerCase()),
        )
      : false;
  };
};

export const getApplyFilterFnChildren = (value: any) => {
  return ({ value: columnValue }: GridCellParams): boolean => {
    return !isEmpty(columnValue)
      ? some(columnValue, (childRole: Role) =>
          includes(childRole?.displayName?.toLowerCase(), value.toLowerCase()),
        )
      : false;
  };
};

export const lastModifiedComparator = (
  v1: lastModifiedType,
  v2: lastModifiedType,
) =>
  //@ts-ignore
  new Date(v1.modifiedTimestamp) - new Date(v2.modifiedTimestamp);

export const usersCountComparator = (v1: User[], v2: User[]) =>
  v1.length - v2.length;

export const nestedRolesCountComparator = (v1: Role[], v2: Role[]) =>
  v1.length - v2.length;

export const ESCustomPagination = ({
  apiRef,
  pageSize,
  onBackButton,
  onNextButton,
  page,
  paginationType,
}: {
  apiRef: any;
  pageSize: number;
  onBackButton: () => void;
  onNextButton: () => void;
  page: number;
  paginationType: string;
}) => {
  const { rowCount } = apiRef?.current?.state?.pagination;
  const backDisabled = page === 1;
  const nextDisabled = rowCount < pageSize;
  const handleChange = (event: SelectChangeEvent<any>) => {
    apiRef.current.setPageSize(event.target.value);
  };

  return (
    <Box display={`flex`} alignItems={`center`} sx={{ pr: 2 }}>
      <FormControl
        sx={{
          m: 1,
          p: 0,
          mr: 4,
          minWidth: 80,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography variant={`inputLabel`} sx={{ mr: 1 }}>
          {`${paginationType} per page:`}
        </Typography>
        <Select size="small" value={pageSize} onChange={handleChange}>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={onBackButton}
          disabled={backDisabled}
          aria-label="previous page"
        >
          <ArrowBackOutlinedIcon
            sx={{
              color: backDisabled ? 'midnight.three' : 'midnight.nine',
              fontSize: '16px',
            }}
          />
        </IconButton>
        <IconButton
          onClick={onNextButton}
          disabled={nextDisabled}
          aria-label="next page"
        >
          <ArrowForwardOutlinedIcon
            sx={{
              color: nextDisabled ? 'midnight.three' : 'midnight.nine',
              fontSize: '16px',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export const RolesPageCustomToolbar = ({
  totalCount,
  apiRef,
  pageSize,
  onRefetch,
  organization,
  onRoleCreated,
  displayName,
  handleKeyUp,
  handleKeyDown,
  onBackButton,
  onNextButton,
  page,
  hasCreateRole,
}: {
  totalCount: number;
  apiRef: React.MutableRefObject<GridApiPro>;
  pageSize: number;
  onRefetch: () => void;
  organization: Organization;
  onRoleCreated: () => void;
  displayName: string;
  handleKeyUp: KeyboardEventHandler<HTMLElement>;
  handleKeyDown: KeyboardEventHandler<HTMLElement>;
  onBackButton: () => void;
  onNextButton: () => void;
  page: number;
  hasCreateRole: boolean;
}) => {
  const currentRowsCount = apiRef.current.getRowsCount();
  const properPageNum = page - 1;
  const mobileToolBar = useMediaQuery('(max-width:500px)');

  return (
    <>
      <CardHeader
        sx={(theme) => ({
          paddingY: 1,
          paddingX: 2,
          margin: 'none',
          backgroundColor: 'midnight.one',
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
        title={
          <Box
            display="flex"
            flexDirection={mobileToolBar ? 'column' : 'row'}
            justifyContent={mobileToolBar ? 'flex-start' : 'space-between'}
            padding={0}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              sx={{ wordBreak: 'break-word' }}
            >
              <Typography variant={'h6'} pr={1}>
                {`${displayName} roles`}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              justifyContent={mobileToolBar ? 'flex-start' : 'flex-end'}
              paddingTop={mobileToolBar ? 1 : 0}
            >
              <Box pr={2} display={'flex'} alignItems={'center'}>
                <Paper
                  component="form"
                  sx={(theme) => ({
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 0,
                    borderRadius: '24px',
                    backgroundColor: 'white',
                    height: '28px',
                    cursor: 'pointer',
                    border: `1px solid ${theme.palette.midnight.five}`,
                    '&:hover, &:active, &:focus': {
                      border: `1px solid ${theme.palette.sky.seven}`,
                    },
                  })}
                >
                  <IconButton
                    type="button"
                    aria-label="search"
                    sx={{ backgroundColor: 'transparent' }}
                  >
                    <SearchIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                  <InputBase
                    sx={(theme) => ({
                      ml: 1,
                      flex: 1,
                      fontSize: theme.typography.pxToRem(14),
                      fontStyle: 'italic',
                    })}
                    placeholder="Search roles"
                    inputProps={{ 'aria-label': 'search roles' }}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                  />
                </Paper>
              </Box>

              <Refresh
                refreshList={() => onRefetch()}
                title={'Refresh roles list'}
              />

              <AddNewResource
                createResource={() =>
                  showCreateRoleDialog(organization, onRoleCreated)
                }
                title={'Create new role'}
                disabled={!hasCreateRole}
              />
            </Box>
          </Box>
        }
      />
      <Box
        display={`flex`}
        alignItems={`center`}
        justifyContent={`space-between`}
        sx={(theme) => ({
          pl: 2,
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
      >
        <Typography variant={`inputLabel`}>{`Showing ${
          properPageNum * pageSize + 1
        } - ${
          properPageNum * pageSize + currentRowsCount
        } of ${totalCount}`}</Typography>
        <ESCustomPagination
          apiRef={apiRef}
          pageSize={pageSize}
          onBackButton={onBackButton}
          onNextButton={onNextButton}
          page={page}
          paginationType={`Roles`}
        />
      </Box>
    </>
  );
};

export const LabelsPageCustomToolbar = ({
  totalCount,
  apiRef,
  pageSize,
  onRefetch,
  organizationId,
  onLabelCreated,
  displayName,
  handleKeyUp,
  handleKeyDown,
  onBackButton,
  onNextButton,
  page,
  onPartialFail,
  hasCreateLabel,
}: {
  totalCount: number;
  apiRef: React.MutableRefObject<GridApiPro>;
  pageSize: number;
  onRefetch: () => void;
  organizationId: string;
  onLabelCreated: () => void;
  onPartialFail: () => void;
  displayName: string;
  handleKeyUp: KeyboardEventHandler<HTMLElement>;
  handleKeyDown: KeyboardEventHandler<HTMLElement>;
  onBackButton: () => void;
  onNextButton: () => void;
  page: number;
  hasCreateLabel: boolean;
}) => {
  const currentRowsCount = apiRef.current.getRowsCount();
  const properPageNum = page - 1;
  const mobileToolBar = useMediaQuery('(max-width:500px)');

  return (
    <>
      <CardHeader
        sx={(theme) => ({
          paddingY: 1,
          paddingX: 2,
          margin: 'none',
          backgroundColor: 'midnight.one',
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
        title={
          <Box
            display="flex"
            flexDirection={mobileToolBar ? 'column' : 'row'}
            justifyContent={mobileToolBar ? 'flex-start' : 'space-between'}
            padding={0}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              sx={{ wordBreak: 'break-word' }}
            >
              <Typography variant={'h6'} pr={1}>
                {`${displayName} labels`}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              justifyContent={mobileToolBar ? 'flex-start' : 'flex-end'}
              paddingTop={mobileToolBar ? 1 : 0}
            >
              <Box pr={2} display={'flex'} alignItems={'center'}>
                <Paper
                  component="form"
                  sx={(theme) => ({
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 0,
                    borderRadius: '24px',
                    backgroundColor: 'white',
                    height: '28px',
                    cursor: 'pointer',
                    border: `1px solid ${theme.palette.midnight.five}`,
                    '&:hover, &:active, &:focus': {
                      border: `1px solid ${theme.palette.sky.seven}`,
                    },
                  })}
                >
                  <IconButton
                    type="button"
                    aria-label="search"
                    sx={{ backgroundColor: 'transparent' }}
                  >
                    <SearchIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                  <InputBase
                    sx={(theme) => ({
                      ml: 1,
                      flex: 1,
                      fontSize: theme.typography.pxToRem(14),
                      fontStyle: 'italic',
                    })}
                    placeholder="Search labels"
                    inputProps={{ 'aria-label': 'search labels' }}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                  />
                </Paper>
              </Box>

              <Refresh
                refreshList={() => onRefetch()}
                title={'Refresh labels list'}
              />

              <AddNewResource
                createResource={() =>
                  showCreateLabelDialog(
                    organizationId,
                    onLabelCreated,
                    onPartialFail,
                  )
                }
                title={'Create new label'}
                disabled={!hasCreateLabel}
              />
            </Box>
          </Box>
        }
      />
      <Box
        display={`flex`}
        alignItems={`center`}
        justifyContent={`space-between`}
        sx={(theme) => ({
          pl: 2,
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
      >
        <Typography variant={`inputLabel`}>{`Showing ${
          properPageNum * pageSize + 1
        } - ${
          properPageNum * pageSize + currentRowsCount
        } of ${totalCount}`}</Typography>
        <ESCustomPagination
          apiRef={apiRef}
          pageSize={pageSize}
          onBackButton={onBackButton}
          onNextButton={onNextButton}
          page={page}
          paginationType={`Labels`}
        />
      </Box>
    </>
  );
};

export const HeaderDropdown = (
  resourceName: string,
  bucketName: string,
  organizationName: string,
  references: any,
) => {
  const navigateTo = useNavigate();
  const refsToCopy = references.map((ref: any) => (
    <TaClipboardText
      key={ref.refText}
      clipboardText={ref.clipBoardText}
      tooltipText={`Copy ${ref.title}`}
      sx={{ backgroundColor: ref.alternate ? 'sunrise.four' : '' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          minWidth: 0,
        }}
      >
        <Typography
          variant={`subtitle1`}
          sx={{
            color: 'midnight.seven',
          }}
        >
          {ref.title}
        </Typography>
        <Typography
          variant={`tooltipLink`}
          sx={{
            color: 'midnight.seven',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {ref.refText}
        </Typography>
      </Box>
    </TaClipboardText>
  ));

  const referenceDropdownContent = (
    <DropdownMenuContent
      menuItems={[
        <DropdownMenuItem
          key={'tableSettings'}
          titleText={`Storage profile`}
          description={bucketName}
          Icon={ArrowForwardIcon}
          onClick={() => {
            navigateTo(
              toStorageProfile(
                // @ts-ignore
                organizationName,
                bucketName || '',
              ),
            );
          }}
        />,
        ...refsToCopy,
      ]}
    />
  );

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      sx={(theme) => ({
        borderRight: `1px solid ${theme.palette.midnight.two}`,
        height: '100%',
        [theme.breakpoints.down(500)]: {
          borderRight: 'none',
        },
      })}
    >
      <DashboardMenu
        titleMenu={resourceName}
        dashboardDropdownContent={referenceDropdownContent}
      />
    </Box>
  );
};
