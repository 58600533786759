import { SearchResult, TableSearchResult } from './graphql/gen/graphql';

export const DASH_PATH = Object.freeze('-'); //this is the path of the "dashboard" used for every tab layout at whichever level it is sitting
export const ROUTES = Object.freeze({
  home: '/',
  login: '/login',
  signup: '/signup',
  reset: '/reset',
  logout: '/logout',
  error: '/error',
});

function getOrgPath(organizationName: string) {
  return `/${encodeURIComponent(organizationName)}`;
}
function getWarehousePath(organizationName: string, warehouseName: string) {
  return `${getOrgPath(organizationName)}/warehouses/${encodeURIComponent(
    warehouseName,
  )}`;
}
function getDatabasePath(
  organizationName: string,
  warehouseName: string,
  databaseName: string,
) {
  return `${getWarehousePath(
    organizationName,
    warehouseName,
  )}/${encodeURIComponent(databaseName)}`;
}
function getTablePath(
  organizationName: string,
  warehouseName: string,
  databaseName: string,
  tableName: string,
) {
  return `${getDatabasePath(
    organizationName,
    warehouseName,
    databaseName,
  )}/${encodeURIComponent(tableName)}`;
}

function getRolesPath(organizationName: string) {
  return `${getOrgPath(organizationName)}/roles`;
}
function getRolePath(organizationName: string, roleName: string) {
  return `${getRolesPath(organizationName)}/${encodeURIComponent(roleName)}`;
}

export function toOrgRoot(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/warehouses`;
}

export function toDroppedTables(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/dropped-tables`;
}
export function toWarehouses(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/warehouses`;
}

export function toMembers(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/members`;
}
export function toRoles(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/roles`;
}
export function toSingleRole(organizationName: string, roleName: string) {
  return `${getRolePath(organizationName, roleName)}/-/membership`;
}
export function toRoleSettings(organizationName: string, roleName: string) {
  return `${getRolePath(organizationName, roleName)}/-/settings`;
}
export function toLabels(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/labels`;
}
export function toOrgSettings(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/settings`;
}
export function toProfile(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/profile`;
}
export function toStorage(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/storage`;
}

export function toStorageProfile(organizationName: string, bucketName: string) {
  return `${getOrgPath(organizationName)}/storage/${encodeURIComponent(
    bucketName,
  )}/${DASH_PATH}/profile-overview`;
}

export function toCompute(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/compute`;
}
export function toSecurity(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/security`;
}
export function toUsage(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/usage`;
}
export function toOrgAccess(organizationName: string) {
  return `${getOrgPath(organizationName)}/${DASH_PATH}/org-access-controls`;
}
export function toServices(organizationName: string) {
  return `/${organizationName}/${DASH_PATH}/services`;
}
export function toPipelines(organizationName: string) {
  return `/${organizationName}/${DASH_PATH}/pipelines`;
}
export function toWarehouseRoot(
  organizationName: string,
  warehouseName: string,
) {
  return `${getWarehousePath(
    organizationName,
    warehouseName,
  )}/${DASH_PATH}/databases`;
}

export function toWarehouseSettings(
  organizationName: string,
  warehouseName: string,
) {
  return `${getWarehousePath(
    organizationName,
    warehouseName,
  )}/${DASH_PATH}/settings`;
}

export function toDatabaseRoot(
  organizationName: string,
  warehouseName: string,
  databaseName: string,
) {
  return `${getDatabasePath(
    organizationName,
    warehouseName,
    databaseName,
  )}/${DASH_PATH}/tables`;
}

export function toDatabaseSettings(
  organizationName: string,
  warehouseName: string,
  databaseName: string,
) {
  return `${getDatabasePath(
    organizationName,
    warehouseName,
    databaseName,
  )}/${DASH_PATH}/settings`;
}

export function toTableRoot(
  organizationName: string,
  warehouseName: string,
  databaseName: string,
  tableName: string,
) {
  return `${getTablePath(
    organizationName,
    warehouseName,
    databaseName,
    tableName,
  )}/${DASH_PATH}/overview`;
}

export function toTableSettings(
  organizationName: string,
  warehouseName: string,
  databaseName: string,
  tableName: string,
) {
  return `${getTablePath(
    organizationName,
    warehouseName,
    databaseName,
    tableName,
  )}/${DASH_PATH}/settings`;
}

export function toWarehouseAccessControls(
  organizationName: string,
  warehouseName: string,
) {
  return `${getWarehousePath(
    organizationName,
    warehouseName,
  )}/${DASH_PATH}/access`;
}

export function toDatabaseAccessControls(
  organizationName: string,
  warehouseName: string,
  databaseName: string,
) {
  return `${getDatabasePath(
    organizationName,
    warehouseName,
    databaseName,
  )}/${DASH_PATH}/access`;
}

export function toTableAccessControls(
  organizationName: string,
  warehouseName: string,
  databaseName: string,
  tableName: string,
) {
  return `${getTablePath(
    organizationName,
    warehouseName,
    databaseName,
    tableName,
  )}/${DASH_PATH}/access`;
}
export function toDocsRoot() {
  const currentHost = window.location.host;
  let host;
  if (currentHost.startsWith('localhost')) {
    host = 'docs.test.tabular.io';
  } else {
    const baseDomainParts = currentHost.split('.').slice(1);
    baseDomainParts.unshift('docs');
    host = baseDomainParts.join('.');
  }

  return `https://${host}/en`;
}

export function toCloudStorageAzure() {
  return `https://portal.azure.com/#blade/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.Storage%2FStorageAccounts`;
}

export function toCloudStorageS3() {
  return `https://s3.console.aws.amazon.com/s3/buckets`;
}

export function toCloudStorageGoogle() {
  return `https://console.cloud.google.com/storage`;
}

export function toApacheIcebergSlack() {
  return 'http://apache-iceberg.slack.com/channels/vendor-tabular';
}

export function joinApacheIcebergSlack() {
  return 'https://join.slack.com/t/apache-iceberg/shared_invite/zt-237mb1iz5-c2MyN6mMn~R72HpNG66P5g';
}

export const SEARCH_ENTITY_TYPE_WAREHOUSE = 1;
export const SEARCH_ENTITY_TYPE_DATABASE = 2;
export const SEARCH_ENTITY_TYPE_TABLE = 3;
export const SEARCH_ENTITY_TYPE_VIEW = 4;

export function toSearchResult(searchResult: SearchResult): string {
  switch (searchResult.et) {
    case SEARCH_ENTITY_TYPE_WAREHOUSE:
      return `/${searchResult.on}/warehouses/${searchResult.en}`;
    case SEARCH_ENTITY_TYPE_DATABASE:
      return `/${searchResult.on}/warehouses/${searchResult.wn}/${searchResult.en}`;
    case SEARCH_ENTITY_TYPE_TABLE:
    case SEARCH_ENTITY_TYPE_VIEW:
      return `/${searchResult.on}/warehouses/${searchResult.wn}/${
        (searchResult as TableSearchResult).nn
      }/${searchResult.en}`;
    default:
      return '/';
  }
}
