import React from 'react';

import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';

import { Organization } from '../../graphql/gen/graphql';
import InviteUsers from '../Forms/InviteUsers';

interface InviteUsersDialogProps {
  organization: Organization;
}
const InviteUsersDialog = create(({ organization }: InviteUsersDialogProps) => {
  const modal = useModal();

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <InviteUsers
        organization={organization}
        onCancel={() => {
          modal.resolve(false);
          return modal.hide();
        }}
        onUsersInvited={() => {
          modal.resolve(true);
          return modal.hide();
        }}
      />
    </Dialog>
  );
});

export default InviteUsersDialog;

export function showInviteUsersDialog(organization: Organization) {
  return show<boolean, any, InviteUsersDialogProps>(InviteUsersDialog, {
    organization,
  });
}
