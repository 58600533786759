import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Link, Popper, Stack, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
  GridValueFormatterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { debounce } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import {
  toDatabaseAccessControls,
  toLabels,
  toSecurity,
  toSingleRole,
  toTableAccessControls,
  toWarehouseAccessControls,
} from '../../RouteTable';
import {
  AuthDecisionResourceType,
  RoleAuthAggregateByResourceType,
  RoleAuthorizationSearchResult,
  SortByDirection,
} from '../../graphql/gen/graphql';
import {
  getRoleAuthAggregatesQuery,
  searchRoleAuthorizationsQuery,
} from '../../graphql/role';
import { calculateTextWidth, ESCustomPagination } from '../../pages/helpers';
import { getLogger } from '../../utils/logging';
import { compactNumberFormatter } from '../../utils/numbers';
import { configureButtonGroups } from '../AccessControl/ButtonConfiguration';
import { AccessButton, AccessButtonGroup } from '../Button/AccessButtonGroup';
import { Tacard } from '../Card/Tacard';
import DataGridDashboard from '../DataGridDashboard/DataGridDashboard';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { NamePopover } from '../Popovers/NamePopover';
import { RoleAuthDetailsPopover } from '../Popovers/RoleAuthDetailsPopover';
import {
  ResourceTypeDisplay,
  RoleAuthorizationsCustomToolbar,
} from './helpers';

interface AccessSearchParams {
  roleId: string;
  query: string;
  maxResults: number;
  searchAfter: string[] | undefined;
  sortBy: any[];
}

const logger = getLogger(
  'pages.Role.Authorizations' /*FUTURE import.meta.url ?*/,
);

export default function RoleAuthorizations({
  organizationId,
  orgName,
  roleName,
  roleId,
}: {
  organizationId: string;
  orgName: string;
  roleName: string;
  roleId: string;
}) {
  const apiRef = useGridApiRef();
  const [totalHitCount, setTotalHitCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [backStack, setBackStack] = useState<string[][]>([]);
  const [currentKeys, setCurrentKeys] = useState<string[]>([]);
  const { data: totalsData, loading: totalsDataLoading } = useQuery(
    getRoleAuthAggregatesQuery,
    {
      variables: {
        roleId,
      },
    },
  );

  const { data, loading, refetch } = useQuery<any, AccessSearchParams>(
    searchRoleAuthorizationsQuery,
    {
      variables: {
        roleId,
        query: searchQuery !== '' ? `*${searchQuery}*` : '*',
        maxResults: pageSize,
        searchAfter: undefined,
        sortBy: [
          {
            fieldName: 'resourceName.sort',
            sortDirection: SortByDirection.Asc,
          },
        ],
      },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  );

  const securityAdminRole = roleName === 'SECURITY_ADMIN';

  const totalResultsCount = useMemo(
    () => data?.searchRoleAuthorizations?.totalHits,
    [data],
  );
  const nextKeys: string[] = useMemo(
    () =>
      data?.searchRoleAuthorizations?.searchAfterKeys
        ? data?.searchRoleAuthorizations?.searchAfterKeys
        : [],
    [data],
  );
  const page = backStack.length + 1;
  const totalWarehouses = useMemo(
    () =>
      totalsData?.roleAuthAggregates?.find(
        (resource: RoleAuthAggregateByResourceType) =>
          resource.resourceType === AuthDecisionResourceType.Warehouse,
      )?.count,
    [totalsData],
  );
  const totalDatabases = useMemo(
    () =>
      totalsData?.roleAuthAggregates?.find(
        (resource: RoleAuthAggregateByResourceType) =>
          resource.resourceType === AuthDecisionResourceType.Database,
      )?.count,
    [totalsData],
  );
  const totalTables = useMemo(
    () =>
      totalsData?.roleAuthAggregates?.find(
        (resource: RoleAuthAggregateByResourceType) =>
          resource.resourceType === AuthDecisionResourceType.Table,
      )?.count,
    [totalsData],
  );
  const totalViews = useMemo(
    () =>
      totalsData?.roleAuthAggregates?.find(
        (resource: RoleAuthAggregateByResourceType) =>
          resource.resourceType === AuthDecisionResourceType.View,
      )?.count,
    [totalsData],
  );
  const totalLabels = useMemo(
    () =>
      totalsData?.roleAuthAggregates?.find(
        (resource: RoleAuthAggregateByResourceType) =>
          resource.resourceType === AuthDecisionResourceType.Label,
      )?.count,
    [totalsData],
  );
  const totalStorageProfiles = useMemo(
    () =>
      totalsData?.roleAuthAggregates?.find(
        (resource: RoleAuthAggregateByResourceType) =>
          resource.resourceType === AuthDecisionResourceType.StorageProfile,
      )?.count,
    [totalsData],
  );
  const totalRoles = useMemo(
    () =>
      totalsData?.roleAuthAggregates?.find(
        (resource: RoleAuthAggregateByResourceType) =>
          resource.resourceType === AuthDecisionResourceType.Role,
      )?.count,
    [totalsData],
  );

  const onKeyDownHandler = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code == 'Enter') {
        e.preventDefault();
        //search again?
      }
    },
    [],
  );

  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      //@ts-ignore
      const searchyMcSearch = e.target.value;
      setSearchQuery(searchyMcSearch);
      setBackStack([]);
      setCurrentKeys([]);
    },
    [],
  );
  const debouncedSearchChange = debounce(onSearchChange, 500);
  const pushBackStack = useCallback((keys: string[]) => {
    setBackStack((prevState) => [...prevState, keys]);
  }, []);

  const popBackStack = useCallback((): string[] => {
    if (backStack.length > 0) {
      const retVal = backStack[backStack.length - 1];
      setBackStack((prevState) => prevState.slice(0, backStack.length - 1));
      return retVal;
    } else {
      return [];
    }
  }, [backStack]);

  const handleBackButtonPagination = useCallback(() => {
    const backKeys = popBackStack();
    refetch({
      searchAfter: backKeys.length == 0 ? undefined : backKeys,
    }).then(() => setCurrentKeys(backKeys));
  }, [backStack]);

  const handleNextButtonPagination = useCallback(() => {
    if (currentKeys) {
      pushBackStack(currentKeys);
    }

    refetch({ searchAfter: nextKeys.length == 0 ? undefined : nextKeys }).then(
      () => setCurrentKeys(nextKeys),
    );
  }, [nextKeys, currentKeys]);

  const handleSortChange = useCallback((sortModel: GridSortModel) => {
    setBackStack([]);
    setCurrentKeys([]);
    const sortDirection = sortModel[0].sort;

    refetch({
      sortBy: [
        {
          fieldName:
            sortModel[0].field === 'resourceName'
              ? 'resourceName.sort'
              : 'resourceType.keyword',
          sortDirection:
            sortDirection === SortByDirection.Desc
              ? SortByDirection.Desc
              : SortByDirection.Asc,
        },
      ],
      searchAfter: undefined,
    });
  }, []);

  const getLinkForResourceName = useCallback(
    (row: RoleAuthorizationSearchResult) => {
      switch (row.resourceType) {
        case AuthDecisionResourceType.Warehouse:
          return toWarehouseAccessControls(orgName, row?.resourceName);
        case AuthDecisionResourceType.Database:
          return toDatabaseAccessControls(
            orgName,
            row?.parents?.warehouse || '',
            row.resourceName,
          );
        case AuthDecisionResourceType.Table:
          return toTableAccessControls(
            orgName,
            row?.parents?.warehouse || '',
            row?.parents?.namespace || '',
            row.resourceName,
          );
        case AuthDecisionResourceType.View:
          return toTableAccessControls(
            orgName,
            row?.parents?.warehouse || '',
            row?.parents?.namespace || '',
            row.resourceName,
          );
        case AuthDecisionResourceType.StorageProfile:
          return toSecurity(orgName);
        case AuthDecisionResourceType.Label:
          return toLabels(orgName);
        case AuthDecisionResourceType.Role:
          return toSingleRole(orgName, row.resourceName);
      }
    },
    [orgName],
  );

  const getNameDisplay = (row: any, resourceName: string) => {
    return `${row.parents?.warehouse ? row.parents.warehouse + '.' : ''}${
      row.parents?.namespace ? row.parents.namespace + '.' : ''
    }${resourceName}`;
  };

  const rows = useMemo(
    () =>
      data?.searchRoleAuthorizations?.results?.map((auth: any) => {
        return {
          ...auth,
          nameColumn: {
            name: auth.resourceName,
            parents: auth.parents,
          },
        };
      }) || [],
    [data],
  );

  const columns = useMemo(
    () => [
      {
        field: 'nameColumn',
        headerName: 'Name',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          const nameDisplay = getNameDisplay(params.row, params.value.name);
          const textWidth = calculateTextWidth(
            nameDisplay,
            '16px Source Sans Pro',
            true,
          );
          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: '1 1 100%',
                      alignItems: 'center',
                      height: '100%',
                      minWidth: 0,
                      cursor: 'pointer',
                    }}
                  >
                    <Link
                      href={getLinkForResourceName(params.row)}
                      {...bindHover(popupState)}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      variant={`inputText`}
                    >
                      {nameDisplay}
                    </Link>
                  </Box>
                  {textWidth >= params.colDef.computedWidth ||
                    (params.row?.description && (
                      <Popper {...bindPopper(popupState)} placement={`right`}>
                        <NamePopover
                          name={params.value.name}
                          comment={params.row?.description}
                        />
                      </Popper>
                    ))}
                </>
              )}
            </PopupState>
          );
        },
      },
      {
        field: 'resourceType',
        headerName: 'Type',
        width: 180,
        valueFormatter: (params: GridValueFormatterParams) => {
          //@ts-ignore
          return params.value ? ResourceTypeDisplay[params.value] : '--';
        },
      },
      {
        field: 'privileges',
        headerName: 'Privileges',
        width: 260,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const buttonGroups = configureButtonGroups(
            params.row.privileges,
            params.row.resourceType,
            '',
            false,
          );
          const resourceType = params.row.resourceType;
          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: '1 1 100%',
                      alignItems: 'center',
                      height: '100%',
                      minWidth: 0,
                      cursor: 'pointer',
                    }}
                  >
                    <Box
                      {...bindHover(popupState)}
                      sx={{ width: '100%', my: 2 }}
                    >
                      <Stack spacing={1}>
                        {buttonGroups.map(
                          (buttonGroup: AccessButton[], index: number) => (
                            <AccessButtonGroup
                              key={`${params.row.resourceType}-privs-${index}`}
                              accessButtons={buttonGroup}
                              securityAdminRole={securityAdminRole}
                            />
                          ),
                        )}
                      </Stack>
                    </Box>
                  </Box>

                  <Popper {...bindPopper(popupState)} placement={`top`}>
                    <RoleAuthDetailsPopover
                      resource={params.row}
                      resourceType={resourceType}
                      securityAdminRole={securityAdminRole}
                    />
                  </Popper>
                </>
              )}
            </PopupState>
          );
        },
      },
    ],
    [data],
  );

  const dashboardItems = useMemo(
    () => [
      {
        items: [
          {
            title: 'Warehouses',
            data: (
              <Typography variant={`subtitle1`}>
                {totalWarehouses > 0
                  ? compactNumberFormatter.format(totalWarehouses)
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: 'Databases',
            data: (
              <Typography variant={`subtitle1`}>
                {totalDatabases > 0
                  ? compactNumberFormatter.format(totalDatabases)
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: 'Tables',
            data: (
              <Typography variant={`subtitle1`}>
                {totalTables > 0
                  ? compactNumberFormatter.format(totalTables)
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: 'Views',
            data: (
              <Typography variant={`subtitle1`}>
                {totalViews > 0
                  ? compactNumberFormatter.format(totalViews)
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: 'Labels',
            data: (
              <Typography variant={`subtitle1`}>
                {totalLabels > 0
                  ? compactNumberFormatter.format(totalLabels)
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: 'Storage Profiles',
            data: (
              <Typography variant={`subtitle1`}>
                {totalStorageProfiles > 0
                  ? compactNumberFormatter.format(totalStorageProfiles)
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: 'Roles',
            data: (
              <Typography variant={`subtitle1`}>
                {totalRoles > 0
                  ? compactNumberFormatter.format(totalRoles)
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
    ],
    [
      totalWarehouses,
      totalDatabases,
      totalLabels,
      totalLabels,
      totalRoles,
      totalStorageProfiles,
    ],
  );

  useEffect(() => {
    setTotalHitCount(totalResultsCount);
  }, [totalResultsCount]);

  useEffect(() => {
    if (searchQuery === '' || searchQuery === '*') {
      refetch().catch((error) =>
        logger.error(
          'Unhandled error while searching role resource authorizations',
          error,
        ),
      );
    } else {
      refetch({
        sortBy: [
          {
            fieldName: 'resourceName.sort',
            sortDirection: SortByDirection.Asc,
          },
        ],
      }).catch((error) =>
        logger.error(
          'Unhandled error while searching role resource authorizations',
          error,
        ),
      );
    }
  }, [searchQuery]);

  return (
    <>
      <DataGridDashboard dashboardCards={dashboardItems} useMarginTop={false} />
      <Box
        sx={{
          '& .MuiDataGrid-row, .MuiDataGrid-root .MuiDataGrid-cell, .rendering-zone':
            { maxheight: 'none !important', minHeight: '67px !important' },
          '& .MuiDataGrid-root .MuiDataGrid-window': {
            position: 'relative !important',
          },
          '& .MuiDataGrid-root .MuiDataGrid-viewport': {
            maxheight: 'none !important',
          },
          '& .MuiDataGrid-root': { height: 'auto !important' },
          '& .roles-cell': {
            display: 'flex',
            flexWrap: 'wrap',
            flex: '1 1 auto',
            maxHeight: '100%',
          },
        }}
      >
        <Tacard grid>
          <DataGridPro
            rows={rows}
            columns={columns as GridColumns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            headerHeight={48}
            autoHeight
            disableColumnMenu
            apiRef={apiRef}
            pagination
            hideFooter={rows?.length < 25}
            density="comfortable"
            getRowHeight={() => 'auto'}
            sortingMode={`server`}
            sortingOrder={['asc', 'desc']}
            onSortModelChange={handleSortChange}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
              setBackStack([]);
              setCurrentKeys([]);
            }}
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: OhNoesRows,
              NoResultsOverlay: OhNoesRows,
              Toolbar: RoleAuthorizationsCustomToolbar,
              Pagination: ESCustomPagination,
            }}
            componentsProps={{
              noRowsOverlay: {
                buttonControl: (
                  <Typography variant={`h1`} color={`brandBlue.main`}>
                    No resources
                  </Typography>
                ),
              },
              toolbar: {
                apiRef: apiRef,
                totalCount: totalHitCount,
                pageSize: pageSize,
                onRefetch: refetch,
                displayName: `Resource authorizations`,
                handleKeyUp: debouncedSearchChange,
                handleKeyDown: onKeyDownHandler,
                onBackButton: handleBackButtonPagination,
                onNextButton: handleNextButtonPagination,
                page: page,
                icon: (
                  <>
                    <PopupState variant="popper">
                      {(popupState) => (
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <InfoOutlinedIcon
                              {...bindHover(popupState)}
                              fontSize={`small`}
                              sx={{ color: 'midnight.seven' }}
                            />
                          </Box>
                          <Popper {...bindPopper(popupState)} placement={`top`}>
                            <NamePopover
                              name={`Authorizations are the group of privileges this role has on a given resource.`}
                            />
                          </Popper>
                        </>
                      )}
                    </PopupState>
                  </>
                ),
              },
              pagination: {
                apiRef: apiRef,
                pageSize: pageSize,
                onBackButton: handleBackButtonPagination,
                onNextButton: handleNextButtonPagination,
                page: page,
                paginationType: 'Resources',
              },
            }}
            loading={loading || !data}
            sx={{
              ...(rows?.length === 0 || totalHitCount === 0
                ? { minHeight: 600 }
                : {}),
              border: 'none',
              '.MuiDataGrid-columnHeaders': {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              },
              '.MuiDataGrid-pinnedColumnHeaders': {
                backgroundColor: 'dusk.half',
              },
            }}
          />
        </Tacard>
      </Box>
    </>
  );
}
