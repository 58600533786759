import React from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Warehouse } from '../../../graphql/gen/graphql';
import { Tabutton } from '../../Button/Tabutton';
import { ExternalBreadcrumbs } from '../../CreateStorageBucket/BucketSteps/helpers';
import TextField from '../../Forms/TextField';
import { launchCloudFormation } from './helpers';

const serviceAccountBreadcrumbs = ['IAM & Admin', 'Roles', 'Create role'];
const bucketBreadcrumbs = ['Cloud Storage', 'Buckets', 'Bucket details'];
export const SnowflakePermissionSetup = ({
  warehouse,
  gcsWarehouse,
  connectionData,
}: {
  warehouse: Warehouse;
  gcsWarehouse: boolean;
  connectionData: any;
}) => {
  return (
    <Stack>
      {gcsWarehouse ? (
        <>
          <Typography variant={'h3'}>1. Create a custom role</Typography>
          <ExternalBreadcrumbs
            breadcrumbs={serviceAccountBreadcrumbs}
            link={'https://console.cloud.google.com/iam-admin/roles'}
          />
          <List sx={{ listStyle: 'decimal', pl: 3 }}>
            <ListItem sx={{ display: 'list-item', p: 1 }}>
              <Typography variant={'body2'}>
                In <b>GCP console</b> as a project editor go to IAM & roles and
                click <b>create role</b>.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 1, mb: 0 }}>
              <Typography variant={'body2'}>
                Enter a <b>name and description</b> for role.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 1, mb: 0 }}>
              <Typography variant={'body2'}>
                Click <b>Add Permissions</b> then select the following:
                <ListItem sx={{ p: 0, pt: 1 }}>
                  <b>storage.buckets.get</b>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <b>storage.objects.get</b>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <b>storage.objects.create</b>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <b>storage.objects.delete</b>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <b>storage.objects.list</b>
                </ListItem>
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 1, mb: 1 }}>
              <Typography variant={'body2'}>Create role.</Typography>
            </ListItem>
          </List>
          <Typography variant={'h3'}>2. Add role to service account</Typography>
          <ExternalBreadcrumbs
            breadcrumbs={bucketBreadcrumbs}
            link={`https://console.cloud.google.com/storage/browser/${warehouse?.storageProfile?.bucket};tab=permissions`}
          />
          <List sx={{ listStyle: 'decimal', pl: 3 }}>
            <ListItem sx={{ display: 'list-item', p: 1 }}>
              <Typography variant={'body2'}>
                In <b>GCP console</b> as a project editor go to Cloud
                {` Storage → Buckets and select`}{' '}
                <b>{warehouse?.storageProfile?.bucket}</b>.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 1, mb: 0 }}>
              <Typography variant={'body2'}>
                Click on <b>Permissions</b> and under <b>view by principals</b>{' '}
                click <b>Grant access</b>.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 1, mb: 0 }}>
              <Typography variant={'body2'}>
                Copy the output value of <b>STORAGE_GCP_SERVICE_ACCOUNT</b>{' '}
                generated from the SQL command in Snowflake and paste it in the{' '}
                <b>New principles</b> field.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 1, mb: 0 }}>
              <Typography variant={'body2'}>
                For <b>Assign roles</b>, select the custom role created.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 1, mb: 1 }}>
              <Typography variant={'body2'}>
                Click <b>save</b> and sync snowflake.
              </Typography>
            </ListItem>
          </List>
        </>
      ) : (
        <Formik
          onSubmit={() => {}}
          initialValues={{
            snowflakeIamUser: '',
            snowflakeExternalId: '',
          }}
          validationSchema={Yup.object({
            snowflakeIamUser: Yup.string().trim().required('Required'),
            snowflakeExternalId: Yup.string().trim().required('Required'),
          })}
        >
          {({ values }) => (
            <Box>
              <Typography>
                Setup AWS for Snowflake access. You can do this manually
                yourself, or by using the CloudFormation below.
              </Typography>
              <Typography>
                Find values for <b>STORAGE_AWS_IAM_USER_ARN</b> and{' '}
                <b>STORAGE_AWS_EXTERNAL_ID</b> in the <b>Results</b> section of
                your Snowflake worksheet.
              </Typography>
              <Form>
                <TextField
                  name={'snowflakeIamUser'}
                  id={'snowflakeIamUser'}
                  label={`STORAGE_AWS_IAM_USER_ARN`}
                  helperText={`Retrieve this value from "DESCRIBE EXTERNAL VOLUME ${connectionData?.getSnowflakeConnection?.externalVolumeId}" in Snowflake`}
                />
                <TextField
                  name={'snowflakeExternalId'}
                  id={'snowflakeExternalId'}
                  label={'STORAGE_AWS_EXTERNAL_ID'}
                  helperText={`Retrieve this value from "DESCRIBE EXTERNAL VOLUME ${connectionData?.getSnowflakeConnection?.externalVolumeId}" in Snowflake`}
                />
                <Box sx={{ m: 1 }}>
                  <Tabutton
                    onClick={async () => {
                      launchCloudFormation({
                        warehouse,
                        snowflakeExternalId:
                          values.snowflakeExternalId.trim() || '',
                        snowflakeUserARN: values.snowflakeIamUser.trim() || '',
                      });
                    }}
                    disabled={
                      !values.snowflakeExternalId || !values.snowflakeIamUser
                    }
                    variant={'outlined'}
                    title="Click to launch AWS Cloud Formation"
                    endIcon={<OpenInNewIcon />}
                  >
                    Click to Launch AWS Cloud Formation{' '}
                  </Tabutton>
                </Box>
              </Form>
            </Box>
          )}
        </Formik>
      )}
    </Stack>
  );
};
