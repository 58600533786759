import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useModal, create, show } from '@ebay/nice-modal-react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { Formik } from 'formik';

import { toWarehouseRoot } from '../../RouteTable';
import { Database, Warehouse } from '../../graphql/gen/graphql';
import { Tabutton } from '../Button/Tabutton';
import CreateDatabase from '../Forms/CreateDatabase';
import { WarehouseSelector } from '../Forms/WarehouseSelector';
import { showCreateWarehouseDialog } from './CreateWarehouseDialog';

interface WarehouseSelectionDialogProps {
  title?: string;
  warehouses: Warehouse[];
}
const WarehouseSelectionDialog = create(
  ({ title, warehouses }: WarehouseSelectionDialogProps) => {
    const modal = useModal();

    const location = useLocation();
    const pathParts: string[] = location.pathname.split('/');
    const organizationName = pathParts.length > 1 ? pathParts[1] : '';

    const navigate = useNavigate();
    useEffect(() => {
      if (warehouses?.length == 1) {
        modal.resolve(warehouses[0]);
        modal.hide();
      }
    });

    return (
      <>
        {warehouses?.length == 0 && (
          <Dialog
            open={modal.visible}
            onClose={() => modal.hide()}
            TransitionProps={{
              onExited: () => modal.remove(),
            }}
          >
            <DialogTitle>No warehouses</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={modal.hide}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              Please create a warehouse to use this feature.
            </DialogContent>
          </Dialog>
        )}
        {warehouses?.length > 1 && (
          <Dialog
            open={modal.visible}
            onClose={() => modal.hide()}
            TransitionProps={{
              onExited: () => modal.remove(),
            }}
          >
            <DialogTitle>{title ? title : 'Select a Warehouse'}</DialogTitle>
            <Formik initialValues={{ warehouse: '' }} onSubmit={() => {}}>
              <WarehouseSelector
                name={'warehouse'}
                warehouses={warehouses}
                onSelected={(warehouse) => {
                  modal.resolve(warehouse);
                  modal.hide();
                }}
                sx={{ p: 2 }}
              />
            </Formik>
          </Dialog>
        )}
      </>
    );
  },
);

export default WarehouseSelectionDialog;

export function showWarehouseSelectionDialog(
  warehouses: Warehouse[],
  title?: string,
) {
  return show<Warehouse, any, WarehouseSelectionDialogProps>(
    WarehouseSelectionDialog,
    { title, warehouses },
  );
}
