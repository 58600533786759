import React from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Grid, Typography, Box, List, ListItem } from '@mui/material';

import { ExternalBreadcrumbs } from '../../CreateStorageBucket/BucketSteps/helpers';
import { Tabulink } from '../../Link/Tabulink';

const googleCloudIAM = ['IAM & Admin', 'IAM'];

export const BigQuerySetup = ({
  serviceAccount,
}: {
  serviceAccount: string;
}) => {
  return (
    <>
      <Box>
        <Box sx={{ width: 0.95 }}>
          <Grid
            container
            sx={{
              width: 1,
              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 1 / 2,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
              }}
            >
              <Typography variant={'body1'}>
                {`Follow link to set up Big Query data owner permissions`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderBottom: 0,
                borderColor: 'midnight.two',
              }}
            >
              <Typography sx={{ display: 'inline-flex', mr: 1 }}>
                <Tabulink
                  external
                  href={`https://console.cloud.google.com/iam-admin/iam`}
                  variant="body1"
                  rel="noopener"
                  aria-label="Google cloud IAM"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {'Google Cloud IAM permissions'}
                  <OpenInNewIcon
                    fontSize="small"
                    sx={{ ml: '2px', mr: '3px' }}
                  />
                </Tabulink>
                (instructions detailed below)
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          In Google Cloud IAM:
          <ExternalBreadcrumbs breadcrumbs={googleCloudIAM} />
          <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Find associated service account: <b>{serviceAccount}</b> and
                click <b>Edit principal</b>.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Click add another role and select <b>BigQuery Data Owner</b> and{' '}
                <b>save</b>.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <Typography variant={'body1'}>
                Click <b>Sync Big Query</b> below to complete connection.
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  );
};
