import { useCallback, useEffect, useRef, useState } from 'react';

import { FormikContextType, FormikValues, useFormikContext } from 'formik';

type Callback = () => void;
export default function useSetFieldValueWithCallback<Values>(
  field: string,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  values: FormikValues,
) {
  const isWaitingRef = useRef(false);
  const valueRef = useRef<any>();
  const [callback, setCallback] = useState<Callback | null>(null);

  useEffect(() => {
    if (
      isWaitingRef.current &&
      values[field] === valueRef.current &&
      typeof callback === 'function'
    ) {
      isWaitingRef.current = false;
      valueRef.current = undefined;

      callback();
    }
  }, [values, field, callback]);

  return useCallback(
    (v: any, c: Callback) => {
      setFieldValue(field, v);

      setCallback(() => c);
      valueRef.current = v;
      isWaitingRef.current = true;
    },
    [field, setFieldValue],
  );
}
