import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  BoxProps,
  Checkbox,
  FormControl,
  FormControlLabel,
  Popover,
  Popper,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GRID_REORDER_COL_DEF,
  GridActionsCellItem,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridColumns,
  GridColumnVisibilityModel,
  GridEventListener,
  GridFilterItem,
  GridFilterOperator,
  GridLinkOperator,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowOrderChangeParams,
  GridRowParams,
  GridValueSetterParams,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-pro/hooks/features/detailPanel/gridDetailPanelToggleColDef';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { isEmpty } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import { useTableEditContext } from './TableEditContext';

import {
  IcebergContentType,
  LabelMaskMode,
  SchemaFieldInput,
  Table,
} from '../../graphql/gen/graphql';
import {
  deleteTableFieldLabels,
  fetchFullTableQuery,
} from '../../graphql/table';
import { calculateTextWidth, HeaderDropdown } from '../../pages/helpers';
import { getLogger } from '../../utils/logging';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import { Tabutton } from '../Button/Tabutton';
import { TaClipboardText } from '../Clipboard/TaClipboardText';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import {
  CustomPaging,
  MenuContentType,
} from '../DataGridToolbar/DataGridToolbar';
import { DropdownMenuContent } from '../DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../DropdownMenu/DropdownMenuItem';
import { StyledSwitch } from '../Forms/Switch';
import { CustomIcon } from '../Icons/CustomIcon';
import { LabelDisplay } from '../Labels/LabelDisplay';
import { LabelPropertyOptions } from '../Labels/helpers';
import { ConfirmationDialog } from '../Modals/ConfirmationDialog';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { LabelPopover } from '../Popovers/LabelPopover';
import { NamePopover } from '../Popovers/NamePopover';
import TableDetails from '../TableOverview/TableDetails';
import { EditToolbar } from './EditToolbar';
import { IcebergTypeEdit, IcebergTypeView } from './IcebergTypeEdit';
import { LabelMenu } from './LabelMenu';
import { SchemaEditDetailPane } from './SchemaEditDetailPane';
import {
  CustomEditDetailPanelToggle,
  GridEditTextField,
  GridTextField,
  SchemaColumnIcons,
} from './SchemaEditGridComponents';
import { SchemaEditToolbar } from './SchemaEditToolbar';
import {
  EditTableMode,
  GridDivider,
  TABLE_GRID_NO_ROWS_HEIGHT,
} from './TableEdit';
import {
  areChildSchemasValid,
  cleanTypeValue,
  handleRowModesModelChangeStripCellFocus,
  hasComplexTypes,
  isIdentifierEligible,
  isRadioTrue,
  nukeAllEmpties,
  safeJSONParse,
  updateRowPosition,
  validateSchemaRow,
} from './TableEditHelpers';

const logger = getLogger(
  'components.TableEdit.SchemaEdit' /*FUTURE import.meta.url ?*/,
);

export const GRID_COL_NAME = 'name';
export const GRID_COL_DOC = 'doc';
export const GRID_COL_REQ = 'required';
export const GRID_COL_TYPE = 'type';
export const GRID_COL_TYPE_FIELDS = 'fields';
export const GRID_COL_IDENTITY = 'identity';
export const GRID_COL_LABEL = 'fieldLabels';
export const GRID_CREATE_LABEL = 'fieldLabelActions';

const IDENTIFIER_HELP_TEXT = 'To add as an Identifier: ';

export enum RowType {
  COLUMN = 'Column',
  FIELD = 'Field',
}

export interface SchemaFieldRow extends SchemaFieldInput {
  //
  rowId: string;
  isNew: boolean;
  fields: GridRowModel<SchemaFieldRow>[];
  identity: boolean;
  __detail_panel_toggle__?: boolean;
  __reorder__?: string;
}

export type SchemaEditType = 'struct' | 'list' | 'map' | undefined;

export type SchemaEditRowModel = {
  name: string;
  parentRow: GridRowModel<SchemaFieldRow>;
  type: SchemaEditType;
  fields: GridRowModel<SchemaFieldRow>[];
};
export interface SchemaEditProps extends BoxProps {
  baseType: SchemaEditType;
  currentModelKey: string;
  validityChanged: (isValid: number) => void;
  level: number;
  onRowStartEditing?: () => void;
  onRowStopEditing?: (
    updatedRows: GridRowModel<SchemaFieldRow>[],
    rowsBeingUpdated: Record<string, any> | undefined,
  ) => void;
  title: string;
  refreshResourceContent?: MenuContentType;
  editResourceContent?: MenuContentType;
  dashboardMenuContent?: JSX.Element;
  hideColumnHeaders?: boolean;
  parentModel?: SchemaEditRowModel;
  existingTable?: Table;
}

export default function SchemaEdit({
  baseType,
  currentModelKey,
  validityChanged,
  level,
  onRowStartEditing,
  onRowStopEditing,
  title,
  refreshResourceContent,
  editResourceContent,
  dashboardMenuContent,
  hideColumnHeaders,
  parentModel,
  existingTable,
  ...props
}: SchemaEditProps) {
  const gridApiRef = useGridApiRef();
  const {
    mode,
    schemaModel,
    setSchemaModel,
    triggerValidation,
    isCancelled,
    cancelSubmission,
    setSchemaValidityModel,
    schemaValidityModel,
    isFieldInUse,
    partitionFieldModel,
    organization,
    warehouseId,
    warehouseName,
    database,
    tableName,
    tableId,
    editTableEnabled,
  } = useTableEditContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const organizationId = organization.id;
  const orgName = organization.name;
  const orgDisplayName = organization.displayName;

  const [removeTableFieldLabel] = useMutation(deleteTableFieldLabels);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [labelToRemove, setLabelToRemove] = useState({
    fieldId: '',
    labelId: '',
    labelName: '',
  });
  const [copySuccess, setCopySuccess] = useState('');
  const rows: GridRowModel<SchemaFieldRow>[] =
    schemaModel[currentModelKey] || [];

  const setRows: React.Dispatch<
    React.SetStateAction<GridRowModel<SchemaFieldRow>[]>
  > = (value) => {
    let newValue: GridRowModel<SchemaFieldRow>[];
    if (typeof value === 'function') {
      newValue = value(rows);
    } else {
      newValue = value;
    }

    setSchemaModel((prevState) => {
      return {
        ...prevState,
        [currentModelKey]: newValue,
      };
    });
  };

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );

  const [rowFilterModel, setRowFilterModel] = React.useState<GridFilterModel>({
    ...(mode === EditTableMode.CREATE_CDC_PIPELINE
      ? {
          linkOperator: GridLinkOperator.And,
          items: [
            {
              id: 1,
              columnField: GRID_COL_NAME,
              operatorValue: 'notequals',
              value: 'Op',
            },
          ],
        }
      : { items: [] }),
  });

  const notEqualsOperator: GridFilterOperator = {
    label: 'NotEquals',
    value: 'notequals',
    getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params: GridCellParams): boolean => {
        return params.value != filterItem.value;
      };
    },
    InputComponent: TextField,
    InputComponentProps: { type: 'number' },
  };

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  enum PendingActionType {
    TOGGLE,
  }
  interface PendingAction {
    type: PendingActionType;
    field: string;
  }
  const [pendingEditActions, setPendingEditActions] = React.useState<
    Record<GridRowId, PendingAction>
  >({});

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      fields: false,
      ...(mode !== EditTableMode.VIEW &&
        mode !== EditTableMode.REGISTER && {
          [GRID_COL_LABEL]: false,
          [GRID_CREATE_LABEL]: false,
        }),
      ...(mode === EditTableMode.VIEW || mode === EditTableMode.REGISTER
        ? {
            __reorder__: false,
            actions: false,
            [GRID_COL_IDENTITY]: false,
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]:
              mode !== EditTableMode.VIEW || hasComplexTypes(schemaModel),
          }
        : {}),
    });

  const [lastValidation, setLastValidation] = React.useState<number>(-1);

  const [rowsBeingEdited, setRowsBeingEdited] = React.useState<
    Record<string, boolean>
  >({});
  const [page, setPage] = React.useState(0);
  //FIXME: currently it is a problem with the way we validate,
  //right now the columns have to be visible, need to update validation to not care if the field is visible.
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [loading, setLoading] = React.useState(false);
  const [shouldAddNewRowOnNextLoop, setShouldAddNewRowOnNextLoop] =
    React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const tablePath = useMemo(
    () => `${warehouseName}.${database}.${tableName}`,
    [warehouseName, database, tableName],
  );
  const handlePopoverOpen = (event: React.SyntheticEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (mode === EditTableMode.VIEW) {
      setColumnVisibilityModel({
        __reorder__: false,
        actions: false,
        [GRID_COL_IDENTITY]: false,
        [GRID_DETAIL_PANEL_TOGGLE_FIELD]: hasComplexTypes(schemaModel),
      });
    }
  }, [mode]);

  const stopAllEditing = useCallback(() => {
    let model: GridRowModesModel = {};
    logger.debug(
      `${Array(level + 1).join('\t')}L${level}:: Stop all editing for ${
        rows.length
      } rows`,
      rowModesModel,
    );
    let foundAtLeastOneInEditMode = false;
    rows.forEach((eachRow, idx) => {
      if (gridApiRef.current.getRowMode(eachRow.rowId) === GridRowModes.Edit) {
        foundAtLeastOneInEditMode = true;
      }
      model[eachRow.rowId] = { mode: GridRowModes.View };
    });

    setRowModesModel(model);
    return foundAtLeastOneInEditMode;
  }, [level, rows, gridApiRef, rowModesModel, setRowModesModel]);

  const startEditingRow = useCallback(
    (rowId: GridRowId, fieldToFocus: string) => {
      if (gridApiRef.current.getRowMode(rowId) != GridRowModes.Edit) {
        // stopAllEditing();
        gridApiRef.current.startRowEditMode({
          id: rowId,
          fieldToFocus: fieldToFocus,
        });
        //stupidly, onRowEditStart does not fire when we put this row into edit mode. (above) so we have to count here too
        setRowsBeingEdited((prevState) => {
          return { ...prevState, [rowId]: true };
        });
        if (onRowStartEditing) {
          onRowStartEditing();
        }
      }
    },
    [gridApiRef, onRowStartEditing, setRowsBeingEdited],
  );

  useEffect(() => {
    if (level > 0 && rows.length > 0 && rows[0].isNew) {
      startEditingRow(rows[0].rowId, baseType === 'struct' ? 'name' : 'type');
    }
  }, []);

  useEffect(() => {
    if (shouldAddNewRowOnNextLoop) {
      setShouldAddNewRowOnNextLoop(false);
      handleAddColumnClick();
    }
  }, [shouldAddNewRowOnNextLoop]);

  useEffect(() => {
    if (!isCancelled && triggerValidation > lastValidation) {
      logger.debug(
        `${Array(level + 1).join('\t')}L${level}:⚡️ (parent ${
          parentModel?.parentRow.name ? parentModel?.parentRow.name : 'TOP'
        }): trigger is ${triggerValidation} and last validation is ${lastValidation}!`,
      );
      //1. check all children complex type models, are they valid for the current trigger?
      if (
        areChildSchemasValid(
          schemaValidityModel,
          triggerValidation,
          level,
          rows,
        )
      ) {
        //2. stop all editing my rows
        const foundAtLeastOneInEditMode = stopAllEditing();
        //3. if no rows being edited, then we are good, report back!
        if (
          !foundAtLeastOneInEditMode &&
          Object.keys(rowsBeingEdited).length == 0
        ) {
          logger.debug(
            `${Array(level + 1).join('\t')}L${level}:: (${
              parentModel ? parentModel?.parentRow?.name : null
            }) No rows in edit mode, now we will validate the rows`,
          );
          let allRowsGood = true;

          rows.forEach((eachRow) => {
            if (!validateSchemaRow(eachRow, parentModel, level)) {
              allRowsGood = false;
            }
          });
          if (allRowsGood) {
            logger.debug(
              `${Array(level + 1).join('\t')}L${level}: all rows valid!`,
            );
            if (triggerValidation > lastValidation) {
              validityChanged(triggerValidation);
              setLastValidation(triggerValidation);
            }
          } else {
            const nukedAtLeastOne = nukeAllEmpties(rows, setRows, 'name');
            logger.debug(
              `${Array(level + 1).join(
                '\t',
              )}L${level}: CANCELLING: Rows are not all valid!`,
            );

            cancelSubmission(
              level,
              'Rows are not all valid!',
              foundAtLeastOneInEditMode || nukedAtLeastOne,
            );
          }
        } else {
          logger.debug(
            `${Array(level + 1).join(
              '\t',
            )}L${level}:: cant trigger valid, we have rows being edited; foundAtLeastOneInEditMode: ${foundAtLeastOneInEditMode} and rowsBeingEdited length is ${
              Object.keys(rowsBeingEdited).length
            }`,
            rowsBeingEdited,
          );
        }
      } else {
        logger.debug(
          `${Array(level + 1).join(
            '\t',
          )}L${level}: Suppressing Validation because a child complex type is not yet valid!.`,
        );
      }
    }
  }, [
    level,
    rows,
    rowsBeingEdited,
    schemaValidityModel,
    triggerValidation,
    lastValidation,
    isCancelled,
    rowModesModel,
  ]);

  useEffect(() => {
    const rowModesKeys = Object.keys(rowModesModel);
    if (rowModesKeys.length > 0) {
      rowModesKeys.forEach((eachKey) => {
        const mode = rowModesModel[eachKey].mode;
        if (mode === 'edit') {
          const action = pendingEditActions[eachKey];
          if (action !== undefined) {
            if (action.type === PendingActionType.TOGGLE) {
              gridApiRef.current.setEditCellValue({
                id: eachKey,
                field: action.field,
                value: !gridApiRef.current.getCellValue(eachKey, action.field),
                debounceMs: 25,
              });
            }

            setPendingEditActions((prevState) => {
              const { [eachKey]: currentAction, ...prevStateMinusCurrent } =
                prevState;
              return { ...prevStateMinusCurrent };
            });
          }
        }
      });
    }
  }, [pendingEditActions, rowModesModel]);

  const handleCellFocusOut = useCallback(
    (
      params: GridCellParams,
      event: MuiEvent<MuiBaseEvent>,
      details: GridCallbackDetails,
    ) => {
      if (
        params.colDef.field == GRID_COL_NAME ||
        params.colDef.field == GRID_COL_TYPE
      ) {
        const currentSubRowModel = schemaValidityModel[params.id];
        if (currentSubRowModel !== undefined && !currentSubRowModel) {
          logger.debug(
            `${Array(level + 1).join(
              '\t',
            )}L${level}:: Would have prevented for ${
              params.colDef.field
            } , exit editing...`,
            currentSubRowModel,
          );
        } else {
          logger.debug(
            `${Array(level + 1).join(
              '\t',
            )}L${level}:: focus out from cell, exit editing...`,
            currentSubRowModel,
          );
        }
      }
    },
    [schemaValidityModel, level],
  );

  const handleCellClick = useCallback(
    (
      params: GridCellParams,
      event: MuiEvent<React.MouseEvent>,
      details: GridCallbackDetails,
    ) => {
      logger.debug('click on cell...');

      if (
        (mode === EditTableMode.EDIT ||
          mode == EditTableMode.CREATE ||
          mode == EditTableMode.CREATE_LIKE ||
          mode == EditTableMode.CREATE_LOAD ||
          mode == EditTableMode.CREATE_CDC_PIPELINE) &&
        params.field !== '__reorder__' &&
        params.field !== 'actions' &&
        params.field !== GRID_DETAIL_PANEL_TOGGLE_FIELD &&
        gridApiRef.current.getRowMode(params.id) === 'view'
      ) {
        gridApiRef.current.startRowEditMode({ id: params.id });
      }
    },
    [mode, gridApiRef],
  );

  const handleRowEditStart = useCallback(
    (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
      logger.debug(
        `${Array(level + 1).join('\t')}L${level}:: On row EditStart with id ${
          params.id
        } current rows editing is `,
        rowsBeingEdited,
      );
      setRowsBeingEdited((prevState) => {
        return { ...prevState, [params.id]: true };
      });
      if (onRowStartEditing) {
        onRowStartEditing();
      }
    },
    [level, rowsBeingEdited, onRowStartEditing, setRowsBeingEdited],
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback(
    (params, event) => {
      logger.debug(
        `${Array(level + 1).join('\t')}L${level}:: rowEditStop for id ${
          params.id
        } NOOP`,
        params,
      );
      setRowsBeingEdited(({ [params.id]: me, ...prevState }) => {
        return { ...prevState };
      });
      const isCurrentRowMapOrList =
        params.row?.type?.indexOf('map') > -1 ||
        params.row?.type?.indexOf('list') > -1;
      const isParentRowMapOrList = baseType === 'map' || baseType === 'list';
      if (
        params.reason === 'tabKeyDown' &&
        params.id === rows[rows.length - 1].rowId &&
        !(isCurrentRowMapOrList || isParentRowMapOrList)
      ) {
        setShouldAddNewRowOnNextLoop(true);
      }
    },
    [level, rows, setRowsBeingEdited, baseType, setShouldAddNewRowOnNextLoop],
  );

  const handleDeleteClick = useCallback(
    (id: GridRowId) => {
      if (mode === EditTableMode.EDIT) {
        const fieldId = gridApiRef.current.getCellValue(id, 'id');
        if (fieldId && Number(fieldId) > 0) {
          if (isFieldInUse(fieldId).inUse) {
            return;
          }
        }
      }
      setRows(rows.filter((row) => row.rowId !== id));
      setRowsBeingEdited(({ [id]: me, ...prevState }) => {
        return { ...prevState };
      });
    },
    [gridApiRef, mode, rows, isFieldInUse, setRows, setRowsBeingEdited],
  );

  const handleRowModesModelChange = useCallback(
    (rowModesModel: GridRowModesModel, details: GridCallbackDetails) => {
      logger.debug(
        `${Array(level + 1).join(
          '\t',
        )}L${level}:: Handle handleRowModesModelChange!`,
        rowModesModel,
        details,
      );
      if (mode === EditTableMode.VIEW) {
        logger.debug(
          `${Array(level + 1).join(
            '\t',
          )}L${level}:: View only mode, returning!`,
          rowModesModel,
          details,
        );
        return;
      }
      handleRowModesModelChangeStripCellFocus(
        rowModesModel,
        details,
        setRowModesModel,
      );
    },
    [level, rowModesModel, mode, setRowModesModel],
  );

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel<SchemaFieldRow>) => {
      logger.debug(
        `${Array(level + 1).join(
          '\t',
        )}L${level}::🚧 Handle processRowUpdate for row ${
          newRow.rowId
        }, removing from editing!`,
        newRow,
      );
      //const subRows = subRowsModel[newRow.rowId as GridRowId];
      const updatedRow = { ...newRow, isNew: false, __reorder__: newRow.name };

      setRows((prevState) => {
        return prevState.map((eachRow) =>
          eachRow.rowId === updatedRow.rowId ? updatedRow : eachRow,
        );
      });

      //handling this here because handleRowEditStop is flaky, not being triggered when save all triggered?
      let updatedRowsBeingEdited: Record<string, any> | undefined = undefined;
      setRowsBeingEdited((prevState) => {
        const { [newRow.rowId]: newGuy, ...rows } = prevState;
        updatedRowsBeingEdited = rows;
        return { ...updatedRowsBeingEdited };
      });

      if (onRowStopEditing) {
        try {
          onRowStopEditing([updatedRow], updatedRowsBeingEdited);
        } catch (e) {
          console.error(
            `${Array(level + 1).join(
              '\t',
            )}L${level}:: Error completing stop for row editing for id ${
              updatedRow.rowId
            }!`,
            e,
          );
        }
      }

      return updatedRow;
    },
    [level, onRowStopEditing, setRows, setRowsBeingEdited],
  );

  const handleRowOrderChange = useCallback(
    async (params: GridRowOrderChangeParams) => {
      setLoading(true);
      const newRows = await updateRowPosition(
        params.oldIndex,
        params.targetIndex,
        rows,
      );

      setRows(newRows);
      setLoading(false);
    },
    [setLoading, rows, setRows, setLoading],
  );

  const handleAddColumnClick = useCallback(async () => {
    if (mode === EditTableMode.VIEW) return;
    const newName = uuidv4();
    await setRows((oldRows) => [
      ...oldRows,
      { rowId: newName, isNew: true, fields: [], name: '', identity: false },
    ]);

    const destinationPage = Math.floor(rows.length / rowsPerPage);
    if (destinationPage != page) {
      await setPage(destinationPage);
      await gridApiRef.current.setPage(destinationPage);
    }
    startEditingRow(newName, 'name');
  }, [mode, setRows, startEditingRow, page, rowsPerPage, rows]);

  const onLabelRemove = async ({
    fieldId,
    labelId,
  }: {
    fieldId: string;
    labelId: string;
  }) => {
    await removeTableFieldLabel({
      variables: {
        warehouseId: warehouseId,
        database: database,
        table: tableName,
        fieldLabels: { fieldId: fieldId, labelId: labelId },
      },
      refetchQueries: [
        {
          query: fetchFullTableQuery,
          variables: {
            orgId: organizationId,
            orgName,
            orgDisplayName,
            warehouseId,
            database,
            tableName,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then((data) => {
        if (data) {
          refreshResourceContent?.action();
        }
      })
      .catch((error) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
          variant: 'error',
        });
      });
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        width: 58,
        editable: true,
        align: 'right',
        renderEditCell: (params: GridRenderEditCellParams) => (
          <CustomEditDetailPanelToggle
            {...params}
            shouldHide={mode !== EditTableMode.VIEW}
          />
        ),
        renderCell: (params: GridRenderCellParams) => (
          <CustomEditDetailPanelToggle
            {...params}
            shouldHide={mode !== EditTableMode.VIEW}
          />
        ),
      },

      {
        field: GRID_COL_NAME,
        headerName: level == 0 ? 'Column Name' : 'Field Name',
        width: 330,
        filterOperators: [notEqualsOperator],
        type: 'string',
        editable: !(baseType === 'list' || baseType === 'map'),
        hideable: true,
        // valueGetter: (params) => {
        //   logger.debug('In value getter!', params);
        //   return params.value;
        // },
        renderCell: (params) => {
          const isValid = params.value !== undefined && params.value !== '';
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
            true,
          );
          const truncate =
            textWidth >= params.colDef.computedWidth ||
            params.row?.comment?.value;
          return (
            <>
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: 0,
                        cursor: truncate ? 'pointer' : 'auto',
                      }}
                      {...bindHover(popupState)}
                    >
                      {(mode !== EditTableMode.VIEW ||
                        hasComplexTypes(schemaModel)) && <GridDivider />}

                      <GridTextField
                        label={
                          !isValid
                            ? level == 0
                              ? 'Column Name'
                              : 'Field Name'
                            : null
                        }
                        tabIndex={-1}
                        placeholder={level == 0 ? 'Column Name' : 'Field Name'}
                        value={params.value}
                        required
                        error={!isValid}
                        overview={mode === EditTableMode.VIEW}
                      />
                    </Box>
                    {mode === EditTableMode.VIEW && (
                      <SchemaColumnIcons
                        row={params.row}
                        level={level}
                      ></SchemaColumnIcons>
                    )}

                    {truncate && (
                      <Popper
                        {...bindPopper(popupState)}
                        placement={`right`}
                        modifiers={[
                          {
                            name: 'flip',
                            options: {
                              fallbackPlacements: ['bottom'],
                            },
                          },
                        ]}
                      >
                        <NamePopover
                          name={params.value}
                          comment={
                            params.row.comment ? params.row.comment.value : null
                          }
                        />
                      </Popper>
                    )}
                  </>
                )}
              </PopupState>
            </>
          );
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          return (
            <Box
              sx={{
                width: 1,
                height: 1,
                display: 'flex',

                alignItems: 'center',
              }}
            >
              <GridDivider />
              <FormControl tabIndex={-1} sx={{ flexGrow: 1 }}>
                <GridEditTextField
                  tabIndex={params.tabIndex}
                  placeholder={level == 0 ? 'Column Name' : 'Field Name'}
                  value={params.value}
                  onChange={async (event) =>
                    await gridApiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: event.target.value,
                    })
                  }
                  shouldAutoFocus={params.hasFocus}
                  //passing readonly this way so that the user is able to click on the name to enter the whole row into edit mode
                  isReadonly={baseType === 'list' || baseType === 'map'}
                  sx={{ p: 1 }}
                ></GridEditTextField>
              </FormControl>
            </Box>
          );
        },
      },
      {
        field: GRID_COL_TYPE,
        headerName:
          baseType === 'list'
            ? 'Element Type'
            : baseType === 'map'
            ? 'Key Type'
            : 'Type',
        width: 290,
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams) => {
          return (
            <>
              <GridDivider />
              <IcebergTypeEdit
                setColumnVisibilityModel={setColumnVisibilityModel}
                {...params}
                level={level}
              />
            </>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <>
            <GridDivider />
            <IcebergTypeView {...params} />
          </>
        ),
      },
      {
        field: GRID_COL_REQ,
        headerName: 'Required',
        width: 150,
        type: 'boolean',
        editable: true,
        valueSetter: (params: GridValueSetterParams) => {
          //if we get passed a space, flip current value, otherwise take the current params.value
          const checkedValue =
            params.value === ' '
              ? !isRadioTrue(params?.row?.required)
              : isRadioTrue(params.value);
          return { ...params.row, required: checkedValue };
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          const isNewField: boolean =
            mode == EditTableMode.CREATE ||
            mode == EditTableMode.CREATE_LIKE ||
            mode == EditTableMode.CREATE_LOAD ||
            mode == EditTableMode.CREATE_CDC_PIPELINE ||
            !(params.row.id > 0);
          const previousValue = gridApiRef.current.getCellValue(
            params.id,
            params.field,
          );
          const disabledDueToEditing = !(
            mode == EditTableMode.CREATE ||
            mode == EditTableMode.CREATE_LIKE ||
            mode == EditTableMode.CREATE_LOAD ||
            mode == EditTableMode.CREATE_CDC_PIPELINE ||
            (mode === EditTableMode.EDIT && previousValue === true)
          );
          return (
            <>
              <GridDivider />
              <Box
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                id={`required_edit_${params?.id}`}
              >
                <StyledSwitch
                  color={params.value ? 'success' : 'default'}
                  switchlabel={'Required'}
                  tabIndex={0}
                  autoFocus={params.hasFocus}
                  disabled={disabledDueToEditing}
                  checked={params.row.required === true}
                  onClick={(event: any) => {
                    logger.debug('EditSwitch click!', event);
                  }}
                  onChange={async (event: any) => {
                    await gridApiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: event.target.checked,
                    });
                    event.stopPropagation(); //🙏This stops the propagation of the click and therefore prevents the editing stop
                  }}
                />
                <Popover
                  tabIndex={-1}
                  id={`required_edit_${params?.id}_popover`}
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={
                    mode === EditTableMode.EDIT &&
                    disabledDueToEditing &&
                    anchorEl?.id === `required_edit_${params?.id}`
                  }
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                  disableAutoFocus //🙏 this stops the loss of focus and headaches
                  disableEnforceFocus //🙏 this stops the loss of focus and headaches
                >
                  <Box sx={{ maxWidth: 500 }}>
                    <Typography sx={{ p: 1 }}>
                      {disabledDueToEditing
                        ? isNewField
                          ? 'Cannot safely add new required field.'
                          : 'Cannot change existing optional field to required.'
                        : ''}
                    </Typography>
                  </Box>
                </Popover>
              </Box>
            </>
          );
        },
        renderCell: (params: any) => {
          const isNewField: boolean =
            mode == EditTableMode.CREATE ||
            mode == EditTableMode.CREATE_LIKE ||
            mode == EditTableMode.CREATE_LOAD ||
            mode == EditTableMode.CREATE_CDC_PIPELINE ||
            !(params.row.id > 0);
          const previousValue = gridApiRef.current.getCellValue(
            params.id,
            params.field,
          );
          const disabledDueToEditing = !(
            mode == EditTableMode.CREATE ||
            mode == EditTableMode.CREATE_LIKE ||
            mode == EditTableMode.CREATE_LOAD ||
            mode == EditTableMode.CREATE_CDC_PIPELINE ||
            (mode === EditTableMode.EDIT && previousValue === true)
          );

          //TODO:make a lightweight non MUI representation return <>{params.value}</>;

          return (
            <>
              <GridDivider />
              <Box
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                id={`required_${params?.id}`}
              >
                {(mode === EditTableMode.VIEW ||
                  mode === EditTableMode.REGISTER) && (
                  <Box>{`${
                    params.value === true ? 'Required' : 'Optional'
                  }`}</Box>
                )}
                {mode !== EditTableMode.VIEW &&
                  mode !== EditTableMode.REGISTER && (
                    <>
                      <StyledSwitch
                        title={
                          mode === EditTableMode.EDIT &&
                          !isNewField &&
                          previousValue === false
                            ? 'Cannot change existing optional field to required.'
                            : ''
                        }
                        color={params.value ? 'success' : 'default'}
                        switchlabel={'Required'}
                        autoFocus={params.hasFocus}
                        disabled={disabledDueToEditing}
                        checked={params.row.required === true}
                        readOnly
                        onClick={(event: any) => {
                          logger.debug('ViewSwitch click! Toggle value', event);
                          if (
                            mode === EditTableMode.EDIT ||
                            mode == EditTableMode.CREATE ||
                            mode == EditTableMode.CREATE_LIKE ||
                            mode == EditTableMode.CREATE_LOAD ||
                            mode == EditTableMode.CREATE_CDC_PIPELINE
                          ) {
                            setPendingEditActions((prevState) => {
                              return {
                                ...prevState,
                                [params.id]: {
                                  field: params.field,
                                  type: PendingActionType.TOGGLE,
                                },
                              };
                            });
                          }
                        }}
                      />
                      <Popover
                        tabIndex={-1}
                        id={`required_${params?.id}_popover`}
                        sx={{
                          pointerEvents: 'none',
                        }}
                        open={
                          mode === EditTableMode.EDIT &&
                          disabledDueToEditing &&
                          anchorEl?.id === `required_${params?.id}`
                        }
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                        disableAutoFocus //🙏 this stops the loss of focus and headaches
                        disableEnforceFocus //🙏 this stops the loss of focus and headaches
                      >
                        <Box sx={{ maxWidth: 500 }}>
                          <Typography sx={{ p: 1 }}>
                            {disabledDueToEditing
                              ? isNewField
                                ? 'Cannot safely add new required field.'
                                : 'Cannot change existing optional field to required.'
                              : ''}
                          </Typography>
                        </Box>
                      </Popover>
                    </>
                  )}
              </Box>
            </>
          );
        },
      },
      {
        field: GRID_COL_DOC,
        headerName: 'Description',
        minWidth: 200,
        flex: 1,
        type: 'string',
        editable: !(baseType === 'list' || baseType === 'map'),
        renderCell: (params) => {
          return (
            <>
              <GridDivider />
              <GridTextField
                placeholder={
                  mode === EditTableMode.VIEW || mode === EditTableMode.REGISTER
                    ? 'No description'
                    : 'Description'
                }
                value={params.value}
                fullWidth
                sx={{
                  display:
                    baseType === 'list' || baseType === 'map' ? 'none' : '',
                }}
              />
            </>
          );
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          return (
            <>
              <GridDivider />
              <GridEditTextField
                fullWidth
                sx={{
                  display:
                    baseType === 'list' || baseType === 'map' ? 'none' : '',
                  p: 1,
                  ml: 2.25,
                  mr: 2.25,
                }}
                placeholder={'Description'}
                value={params.value}
                onChange={async (event) =>
                  await gridApiRef.current.setEditCellValue({
                    id: params.id,
                    field: params.field,
                    value: event.target.value,
                  })
                }
                shouldAutoFocus={params.hasFocus}
              />
            </>
          );
        },
      },
      {
        field: GRID_COL_LABEL,
        headerName: 'fieldLabels',
        minWidth: 200,
        flex: 1,
        type: 'string',
        editable: false,
        hideable: true,
        renderCell: (params) => {
          const fieldLabels = params?.value;
          const jsonValueObject: any = safeJSONParse(params.row.type, {});
          const notAllowedTypes = ['map', 'list', 'struct'];
          const isView = existingTable?.contentType === IcebergContentType.View;
          const labelsNotAllowed =
            notAllowedTypes.includes(jsonValueObject.type) ||
            baseType === 'list' ||
            baseType === 'map' ||
            isView;
          const isPartitioned =
            partitionFieldModel.filter(
              (partition) => partition.sourceId === params.row.id,
            ).length > 0;
          const noFieldLabels = fieldLabels === undefined;
          return (
            <>
              <GridDivider />
              {editTableEnabled && !isPartitioned && (
                <LabelMenu
                  row={params.row}
                  labelsNotAllowed={labelsNotAllowed}
                  onLabelApplied={() => {}}
                />
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                {!noFieldLabels &&
                  fieldLabels?.map((label: any) => {
                    const behavior = !isEmpty(label.properties)
                      ? label.properties.FIELD_MASKING.payload.maskMode
                      : null;
                    const maskMode =
                      label?.properties?.[LabelPropertyOptions.FIELD_MASKING]
                        ?.payload?.maskMode;

                    const modeEnabled =
                      maskMode === LabelMaskMode.Null ||
                      maskMode === LabelMaskMode.Hide;
                    return (
                      <Box
                        key={label.labelId}
                        maxWidth={params.colDef.computedWidth}
                        overflow={'hidden'}
                      >
                        <PopupState variant="popper">
                          {(popupState) => (
                            <>
                              <LabelDisplay
                                labelId={label.labelId}
                                hasPolicy={modeEnabled}
                                maskMode={maskMode}
                                labelName={label.name}
                                {...bindHover(popupState)}
                                sx={{
                                  mr: 1,
                                  my: 0.25,
                                  cursor: 'default',
                                }}
                                fieldId={params.row.id}
                                remove={() => {
                                  setLabelToRemove({
                                    fieldId: params.row.id,
                                    labelId: label.labelId,
                                    labelName: label.name,
                                  });
                                  setShowConfirmModal(true);
                                }}
                                maxWidth={params.colDef.computedWidth - 80}
                              />
                              <Popper
                                {...bindPopper(popupState)}
                                placement={`top`}
                              >
                                <LabelPopover
                                  description={label.description}
                                  name={label.name}
                                />
                              </Popper>
                            </>
                          )}
                        </PopupState>
                      </Box>
                    );
                  })}
                {noFieldLabels && (
                  <Typography
                    variant={'inputText'}
                    sx={{ color: 'midnight.three' }}
                  >
                    {labelsNotAllowed ? '' : 'No Labels'}
                  </Typography>
                )}
              </Box>
            </>
          );
        },
      },
      {
        field: GRID_COL_IDENTITY,
        headerName: GRID_COL_IDENTITY,
        width: 65,
        type: 'boolean',
        editable: true,
        hideable: true,
        // valueGetter: (params) => {
        //   logger.debug('In value getter!', params);
        //   return params.value;
        // },
        renderCell: ({ id, field, row, ...params }) => {
          const previousValue = gridApiRef.current.getCellValue(id, 'type');

          const inVal = cleanTypeValue(row.type, previousValue);
          const eligibility = isIdentifierEligible(
            mode,
            inVal.valueObj.type ?? inVal.valueObj,
            baseType!,
            row.required,
            true,
            level,
          );
          //TODO:make a lightweight non MUI representation return <>{params.value}</>;

          return (
            <Box
              sx={{
                width: 1,
                height: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GridDivider />
              {mode === EditTableMode.VIEW && (
                <Box>{`${params.value === true ? 'ID' : ''}`}</Box>
              )}
              {mode !== EditTableMode.VIEW && (
                <>
                  <FormControlLabel
                    id={`identifier_${id}`}
                    tabIndex={eligibility.isEligible ? 0 : -1}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    sx={{
                      mr: 0,
                      ml: -2,
                      color: eligibility.isEligible ? '' : 'midnight.three',
                    }}
                    control={
                      <Checkbox
                        tabIndex={eligibility.isEligible ? 0 : -1}
                        checked={row[GRID_COL_IDENTITY] === true}
                        size="small"
                        sx={{
                          flexGrow: 1,
                          color: eligibility.isEligible ? '' : 'midnight.three',
                        }}
                        onClick={(event) => {
                          logger.debug(
                            'ViewCheckbox click! Toggle value',
                            event,
                          );
                          if (
                            mode === EditTableMode.EDIT ||
                            mode == EditTableMode.CREATE ||
                            mode == EditTableMode.CREATE_LIKE ||
                            mode == EditTableMode.CREATE_LOAD ||
                            mode == EditTableMode.CREATE_CDC_PIPELINE
                          ) {
                            setPendingEditActions((prevState) => {
                              return {
                                ...prevState,
                                [id]: {
                                  field: field,
                                  type: PendingActionType.TOGGLE,
                                },
                              };
                            });
                          }
                        }}
                      />
                    }
                    label="ID"
                  />
                  <GridDivider />
                  <Popover
                    tabIndex={-1}
                    id={`identifier_${id}_popover`}
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={
                      !eligibility.isEligible &&
                      anchorEl?.id === `identifier_${id}`
                    }
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    disableAutoFocus //🙏 this stops the loss of focus and headaches
                    disableEnforceFocus //🙏 this stops the loss of focus and headaches
                  >
                    <Box
                      sx={{ maxWidth: 500 }}
                      key={'schemaEditMenu_' + id + '_box2'}
                    >
                      <Typography
                        key={'schemaEditMenu_' + id + '_desc'}
                        sx={{ p: 1 }}
                      >
                        <div>{`${IDENTIFIER_HELP_TEXT}`}</div>
                        {!eligibility.isEligible
                          ? eligibility.msg.map((eachMsg, idx) => (
                              <div key={`view_id_reason_${idx}`}>{eachMsg}</div>
                            ))
                          : ''}
                      </Typography>
                    </Box>
                  </Popover>
                </>
              )}
            </Box>
          );
        },
        renderEditCell: ({ id, field, value, row, ...params }) => {
          const previousValue = gridApiRef.current.getCellValue(id, 'type');
          const inVal = cleanTypeValue(row.type, previousValue);
          const eligibility = isIdentifierEligible(
            mode,
            inVal.valueObj.type ?? inVal.valueObj,
            baseType!,
            row.required,
            true,
            level,
          );

          if (!eligibility.isEligible && value === true)
            gridApiRef.current.setEditCellValue({
              id: id,
              field: field,
              value: false,
            });

          return (
            <Box
              sx={{
                width: 1,
                height: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GridDivider />
              <FormControlLabel
                id={`identifier_edit_${id}`}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{
                  mr: 0,
                  ml: -2,
                  color: eligibility.isEligible ? '' : 'midnight.three',
                }}
                control={
                  <Checkbox
                    checked={row[GRID_COL_IDENTITY] === true}
                    size="small"
                    sx={{
                      flexGrow: 1,
                      color: eligibility.isEligible ? '' : 'midnight.one',
                    }}
                    onChange={async (event) => {
                      await gridApiRef.current.setEditCellValue({
                        id: id,
                        field: field,
                        value: eligibility.isEligible
                          ? event.target.checked
                          : false,
                      });

                      event.stopPropagation(); //🙏This stops the propagation of the click and therefore prevents the editing stop
                    }}
                  />
                }
                label="ID"
              />
              <GridDivider />
              <Popover
                tabIndex={-1}
                id={`identifier_${id}_popover_edit`}
                sx={{
                  pointerEvents: 'none',
                }}
                open={
                  mode !== EditTableMode.VIEW &&
                  !eligibility.isEligible &&
                  anchorEl?.id === `identifier_edit_${id}`
                }
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                disableAutoFocus //🙏 this stops the loss of focus and headaches
                disableEnforceFocus //🙏 this stops the loss of focus and headaches
              >
                <Box
                  sx={{ maxWidth: 500 }}
                  key={'schemaEditMenu_' + id + '_box2'}
                >
                  <Typography
                    key={'schemaEditMenu_' + id + '_desc'}
                    sx={{ p: 1 }}
                  >
                    <div>{`${IDENTIFIER_HELP_TEXT}`}</div>
                    {!eligibility.isEligible
                      ? eligibility.msg.map((eachMsg, idx) => (
                          <div key={`view_id_reason_${idx}`}>{eachMsg}</div>
                        ))
                      : ''}
                  </Typography>
                </Box>
              </Popover>
            </Box>
          );
        },
      },
      {
        ...GRID_REORDER_COL_DEF, // Already contains the right field
        editable: false,
        width: 50,
        renderCell: (params) => {
          return (
            <>
              <Box title={'Click and drag to re-order'}>
                <GridDivider />
                <Box tabIndex={0} sx={{ display: 'none' }}></Box>
                {GRID_REORDER_COL_DEF &&
                  GRID_REORDER_COL_DEF.renderCell &&
                  GRID_REORDER_COL_DEF.renderCell({ ...params, tabIndex: -1 })}
                <GridDivider />
              </Box>
            </>
          );
        },
        renderEditCell: (params) => {
          return <></>;
        },
      },

      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 20,
        cellClassName: 'actions',
        align: 'left',
        editable: false,
        getActions: ({ id, row, columns }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (baseType === 'list' || baseType === 'map') {
            return [];
          }

          const { inUse, locationString } = isFieldInUse(row.id);
          return [
            <>
              <GridActionsCellItem
                placeholder
                tabIndex={-1}
                key="delete"
                id={'action_delete' + id}
                icon={<CloseIcon />}
                label="Delete"
                onClick={(event) => {
                  if (!inUse) {
                    handleDeleteClick(id);
                  }

                  event.stopPropagation();
                }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ color: inUse ? 'midnight:half' : 'sunset.seven' }}
              />
              <Popover
                tabIndex={-1}
                id={'action_delete' + id + '_popover'}
                sx={{
                  pointerEvents: 'none',
                }}
                open={inUse && anchorEl?.id === 'action_delete' + id}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                disableAutoFocus //🙏 this stops the loss of focus and headaches
                disableEnforceFocus //🙏 this stops the loss of focus and headaches
              >
                <Box
                  sx={{ maxWidth: 500 }}
                  key={'schemaEditMenu_' + id + '_box2'}
                >
                  <Typography
                    key={'schemaEditMenu_' + id + '_desc'}
                    sx={{ p: 1 }}
                  >
                    {inUse
                      ? `Cannot delete ${
                          level == 0 ? 'column' : 'field'
                        } since it is in use. (${
                          locationString.indexOf('P') > -1 ? 'Partitioning' : ''
                        }${locationString.length > 1 ? ',' : ''}${
                          locationString.indexOf('W') > -1 ? 'Ordering' : ''
                        })`
                      : ''}
                  </Typography>
                </Box>
              </Popover>
            </>,
          ];
        },
      },
    ],
    [
      mode,
      level,
      baseType,
      schemaModel,
      gridApiRef,
      setColumnVisibilityModel,
      handlePopoverOpen,
      handlePopoverClose,
      anchorEl,
      handlePopoverClose,
      setPendingEditActions,
      rowModesModel,
    ],
  );

  const getDetailPanelContent = React.useCallback(
    (gridRowParams: GridRowParams) => (
      <SchemaEditDetailPane
        gridRowParams={gridRowParams}
        level={level}
        setRowModesModel={setRowModesModel}
        updateRow={processRowUpdate}
      />
    ),
    [
      setSchemaModel,
      schemaModel,
      cancelSubmission,
      schemaValidityModel,
      level,
      setSchemaValidityModel,
      setRowModesModel,
      triggerValidation,
    ],
  );

  const bucketName =
    existingTable?.location?.split('//')[1].split('/')[0] || '';
  const cloudProvider = existingTable?.location?.slice(0, 2);
  const cloudProviderIcon = cloudProvider?.includes('s3')
    ? 'aws'
    : cloudProvider?.includes('gs')
    ? 'gcp'
    : 'azure';

  const additionalLocations =
    existingTable &&
    existingTable?.additionalLocations &&
    existingTable?.additionalLocations?.length > 0;

  const additionalMetadataLocations = existingTable?.additionalLocations?.map(
    (alt) => {
      return {
        title: 'Alternate metadata location',
        refText: alt,
        clipBoardText: alt,
        toolTipText: 'Copy alternate metadata location',
        alternate: true,
      };
    },
  );

  const headerReferences = [
    {
      title: 'Warehouse ID',
      refText: warehouseId,
      clipBoardText: warehouseId,
    },
    {
      title: 'Fully qualified name',
      refText: tablePath,
      clipBoardText: tablePath,
    },
    {
      title: 'Table ref. ID',
      refText: tableId,
      clipBoardText: tableId,
    },
    {
      title: 'Iceberg table ID',
      refText: existingTable?.tableId,
      clipBoardText: existingTable?.tableId,
    },
    {
      title: 'Metadata location',
      refText: existingTable?.location,
      clipBoardText: existingTable?.location,
      toolTipText: 'Copy metadata location',
    },
    ...(additionalLocations && additionalMetadataLocations !== undefined
      ? additionalMetadataLocations
      : []),
  ];

  const headerName = HeaderDropdown(
    tableName || '',
    bucketName,
    orgName,
    headerReferences,
  );

  //*****************

  if (baseType === undefined) {
    return <></>;
  }
  const enablePaging =
    (mode !== EditTableMode.VIEW && mode !== EditTableMode.REGISTER) ||
    rows.length > 100;
  return (
    <Box {...props}>
      <DataGridPro
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(args) => {
          logger.debug('ERROR while saving row!');
        }}
        // onRowClick={handleRowClick}
        onCellClick={handleCellClick}
        onCellFocusOut={handleCellFocusOut}
        components={{
          Footer: EditToolbar,
          Toolbar: SchemaEditToolbar,
          /*@ts-ignore*/
          ColumnHeaders: () => {
            return <></>;
          },
          NoRowsOverlay: OhNoesRows,
        }}
        componentsProps={{
          toolbar: {
            className: 'grid-toolbar',
            sx: (theme: Theme) => ({
              p: 0,
              display:
                level == 0 &&
                (mode === EditTableMode.VIEW || mode === EditTableMode.REGISTER)
                  ? ''
                  : 'none',
              borderBottom: `1px solid ${theme.palette.midnight.two}`,
            }),
            title: `${
              level == 0 &&
              mode !== EditTableMode.VIEW &&
              mode !== EditTableMode.REGISTER
                ? 'Schema'
                : ''
            }`,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            apiRef: gridApiRef,
            pageSize: rowsPerPage,
            setPageSize: setRowsPerPage,
            rowsPerPageOptions: [
              10,
              15,
              25,
              50,
              100,
              ...(mode === EditTableMode.VIEW ? [500, 1000] : []),
            ],
            headerName: headerName,
            cloudProviderIcon: `/assets/img/logos/${cloudProviderIcon}.svg`,
            icon: existingTable?.contentType === IcebergContentType.View && (
              <CustomIcon
                src={'/assets/icon/view-indicator.svg'}
                sx={{ height: '24px', width: '40px', mr: 1 }}
              />
            ),

            secondRowContent: <TableDetails table={existingTable} />,
            refreshResourceContent: refreshResourceContent,
            editResourceContent: editResourceContent,

            rowCount: rows?.length,
            dashboardMenuContent: dashboardMenuContent,
            pageAtNumber: 100,
            showRange: enablePaging && rows?.length >= 100,
            setPage: setPage,
          },
          footer: {
            children: enablePaging && (
              <CustomPaging
                apiRef={gridApiRef}
                pageSize={rowsPerPage}
                rowsPerPageOptions={[
                  10,
                  15,
                  25,
                  50,
                  100,
                  ...(mode === EditTableMode.VIEW ? [500, 1000] : []),
                ]}
                setPageSize={setRowsPerPage}
                rowCount={rows?.length}
                setPage={setPage}
              />
            ),
            mode,
            onAddColumnClick: handleAddColumnClick,
            sx: {
              display:
                baseType === 'list' ||
                baseType === 'map' ||
                rows.length === 0 ||
                ((mode === EditTableMode.VIEW ||
                  mode === EditTableMode.REGISTER) &&
                  !enablePaging)
                  ? 'none'
                  : '',
              minHeight: 56,
              width: 1,
            },
            buttonText: level == 0 ? 'Add column' : 'Add Field',
          },
          noRowsOverlay: {
            GridOverlayProps: {
              top: -56,
              height: TABLE_GRID_NO_ROWS_HEIGHT - 44,
            },
            buttonControl:
              mode !== EditTableMode.VIEW ? (
                <Tabutton
                  variant={'outlined'}
                  sx={{ mt: 1, alignContent: 'center' }}
                  endIcon={<AddIcon />}
                  onClick={handleAddColumnClick}
                >
                  {`Add a ${
                    level == 0
                      ? 'column'
                      : baseType === 'list'
                      ? 'metadata'
                      : baseType === 'map'
                      ? 'metadata'
                      : 'field'
                  }`}
                </Tabutton>
              ) : (
                <></>
              ),
          },
        }}
        disableSelectionOnClick={true}
        experimentalFeatures={{ newEditingApi: true }}
        getRowHeight={() => 'auto'}
        autoHeight={rows.length > 0}
        pagination={enablePaging}
        pageSize={rowsPerPage}
        hideFooterSelectedRowCount
        disableColumnMenu
        columnVisibilityModel={columnVisibilityModel}
        rowReordering={!(baseType === 'list' || baseType === 'map')}
        onRowOrderChange={handleRowOrderChange}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={({ row }) => 'auto'}
        getRowId={(row) => row?.rowId}
        initialState={{
          filter: {
            filterModel: rowFilterModel,
          },
          detailPanel: {
            expandedRowIds:
              mode === EditTableMode.VIEW
                ? []
                : rows
                    .filter(
                      (eachRow) =>
                        eachRow?.type?.indexOf('struct') > -1 ||
                        eachRow?.type?.indexOf('map') > -1 ||
                        eachRow?.type?.indexOf('list') > -1,
                    )
                    .map((eachRow) => eachRow.rowId),
          },
        }}
        sx={{
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '4px',
          },
          '& .icebergTypeLabel': {
            backgroundColor:
              mode === EditTableMode.VIEW ? 'transparent!important' : '',
          },
          ...(rows.length == 0 ? { height: TABLE_GRID_NO_ROWS_HEIGHT } : {}),
        }}
      />
      <ConfirmationDialog
        open={showConfirmModal}
        title={`Remove ${labelToRemove.labelName}?`}
        acceptText="Remove"
        onDismiss={() => {
          setShowConfirmModal(false);
        }}
        onAccept={() => {
          onLabelRemove({
            fieldId: labelToRemove.fieldId,
            labelId: labelToRemove.labelId,
          });
          setShowConfirmModal(false);
        }}
      />
    </Box>
  );
}
