import React, { useMemo } from 'react';

import { Box, useTheme } from '@mui/material';
import { SystemProps } from '@mui/system';

import StatusPanelView from './StatusPanelView';
import { StatusBoardContextProvider } from './context/StatusBoardContextProvider';
import { useStatusBoardProps } from './useStatusBoardProps';

import { StatusBoardProps } from './index';

export default function StatusBoard(inProps: StatusBoardProps) {
  const props = useStatusBoardProps(inProps);
  const { statusPanels } = props;
  const theme = useTheme();
  return (
    <StatusBoardContextProvider props={props}>
      <Box
        className={'status-board-root'}
        {...props}
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mb: 2,
            alignSelf: 'stretch',
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.midnight.two,
            background: theme.palette.controlBackground.lakeWaterGradient,
            p: 2,
          },
          props.sx as SystemProps,
        ]}
      >
        {statusPanels.map((panel, panelIndex: number) => (
          <StatusPanelView
            statusPanel={panel}
            key={`status-panel-${panelIndex}`}
          />
        ))}
      </Box>
    </StatusBoardContextProvider>
  );
}
