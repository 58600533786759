import React from 'react';

import { Card, CardProps } from '@mui/material';

interface TacardProps extends CardProps {
  grid?: boolean;
  cardGrid?: boolean;
  borderRadius?: number;
  flexDirectionRow?: boolean;
  marginRight?: string;
}

export const Tacard = ({
  children,
  grid,
  cardGrid,
  borderRadius,
  flexDirectionRow,
  marginRight,
  ...props
}: TacardProps): JSX.Element => {
  return (
    <Card
      {...props}
      variant={'outlined'}
      sx={(theme) => ({
        ...props.sx,
        mb: 4,
        borderRadius: borderRadius || 2,
        boxShadow: theme.shadows[4],
        border: `1px solid ${theme.palette.midnight.two}`,
        filter: 'drop-shadow(0px 8px 32px rgba(26, 76, 102, 0.08))',
        ...(!cardGrid && {
          width: 'inherit',
        }),
        ...(cardGrid && {
          minHeight: '100%',
          display: 'flex',
          flexDirection: flexDirectionRow ? 'row' : 'column',
          flex: 1,
          mb: 0,
          marginRight: marginRight ? marginRight : 3,
          [theme.breakpoints.down('lg')]: {
            mr: 0,
            mb: 2,
          },
        }),
      })}
    >
      {children}
    </Card>
  );
};
