import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { GridRowId } from '@mui/x-data-grid-pro';

import { useAuth } from '../../context/auth-context';
import {
  Snapshot,
  SnapshotRef,
  SnapshotRefs,
  Table,
} from '../../graphql/gen/graphql';
import { TableSnapshotQuery } from '../../graphql/table';
import DashboardTitle from '../DashboardTitle/DashboardTitle';
import SnapshotChart from './SnapshotChart';
import SnapshotTable from './SnapshotTable';

export interface SnapshotProp extends Snapshot {
  parent: SnapshotProp;
  tag: SnapshotRefProp[];
}

export interface SnapshotRefProp extends SnapshotRef {
  name: string;
}

function getSnapshotRelationships(
  snapshotList: SnapshotProp[],
  tagRefs: Record<string, SnapshotRefProp[]>,
) {
  const snapshotMap: Record<number, SnapshotProp> = snapshotList.reduce(
    (acc, snapshot) => ({ ...acc, [snapshot.snapshot_id]: { ...snapshot } }),
    {},
  );

  const snapshots = snapshotList.map((snapshot) => {
    const parent = snapshotMap[snapshot.parent_snapshot_id];
    const child = snapshotMap[snapshot.snapshot_id];
    child.parent = parent;
    child.tag = tagRefs[snapshot.snapshot_id]?.sort(
      (a: SnapshotRefProp, b: SnapshotRefProp) => {
        return a.name.localeCompare(b.name);
      },
    );
    return child;
  });

  return { snapshotMap, snapshots };
}

interface SnapshotViewProps {
  userCanEdit: boolean;
  dashboardDropdownContent?: React.ReactElement;
  table: Table;
  isPipelineTarget?: boolean;
}

export default function SnapshotView({
  userCanEdit,
  dashboardDropdownContent,
  table,
  isPipelineTarget = false,
}: SnapshotViewProps) {
  const { user } = useAuth();
  const {
    organization: organizationName,
    warehouse: warehouseName,
    database,
    table: tableName,
  } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [branch, setBranch] = useState('main');

  const { data, loading, refetch } = useQuery(TableSnapshotQuery, {
    variables: {
      warehouseId: user.getWarehouse(organizationName, warehouseName).id,
      database,
      table: tableName,
      page: page + 1,
      pageSize: rowsPerPage,
      branch: branch,
    },
  });

  const metadata = useMemo(() => data?.fetchSnapshots, [data]);

  const refs: SnapshotRefProp[] = useMemo(
    () =>
      metadata?.refs?.map(({ key, value }: SnapshotRefs) => ({
        ...value,
        name: key,
      })),
    [metadata],
  );

  const branchRefs = useMemo(
    () => refs?.filter((ref) => ref.type === 'branch'),
    [metadata],
  );

  const tagRefs = useMemo(
    () =>
      refs
        ?.filter((ref) => ref.type === 'tag')
        .reduce(
          (tags, tag) => ({
            ...tags,
            [tag.snapshot_id]: [...(tags[tag.snapshot_id] || []), tag],
          }),
          {} as Record<string, SnapshotRefProp[]>,
        ),
    [metadata],
  );

  const { snapshots } = useMemo(
    () => getSnapshotRelationships(metadata?.snapshots ?? [], tagRefs),
    [metadata],
  );

  const resetAndRefetch = useCallback(() => {
    setPage(0);
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [page, rowsPerPage, branch]);

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
    GridRowId[]
  >([]);

  useEffect(() => setDetailPanelExpandedRowIds([]), [snapshots]);

  return (
    <>
      <DashboardTitle
        resourceName={tableName || ''}
        dashboardTitle={'Table snapshot records'}
        dashboardDropdownContent={dashboardDropdownContent}
      />
      <SnapshotChart
        snapshots={snapshots}
        setDetailPanelExpandedRowIds={setDetailPanelExpandedRowIds}
      />
      <SnapshotTable
        table={table}
        userCanEdit={userCanEdit}
        isPipelineTarget={isPipelineTarget}
        numSnapshots={metadata?.numSnapshots}
        snapshots={snapshots}
        loadingSnapshots={loading}
        refreshResourceContent={resetAndRefetch}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={setPage}
        handleChangeRowsPerPage={setRowsPerPage}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        setDetailPanelExpandedRowIds={setDetailPanelExpandedRowIds}
        branchRefs={branchRefs}
        selectedBranch={branch}
        handleChangeBranch={(branch) => {
          setBranch(branch);
          resetAndRefetch();
        }}
        handleBranchCreated={refetch}
      />
    </>
  );
}
