import React, { useState } from 'react';

import {
  Box,
  BoxProps,
  Grid,
  IconButton,
  Menu,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import DashboardMenu from '../DashboardMenu/DashboardMenu';

interface DashboardTitleProps extends BoxProps {
  resourceName: string;
  dashboardTitle: string;
  dashboardDropdownContent: JSX.Element | undefined;
}

export default function DashboardTitle({
  resourceName,
  dashboardTitle,
  dashboardDropdownContent,
}: DashboardTitleProps) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography
            variant="h4"
            paddingBottom={1}
            sx={{ wordBreak: 'break-word' }}
          >
            {resourceName}
          </Typography>
          <Typography variant="subtitle1" color={'dusk.six'}>
            {dashboardTitle}
          </Typography>
        </Grid>
        <Grid item>
          {dashboardDropdownContent ? (
            <DashboardMenu
              dashboardDropdownContent={dashboardDropdownContent}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}
