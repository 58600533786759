import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  CardContent,
  CardHeader,
  Skeleton,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { toTableRoot } from '../../RouteTable';
import { getUserFriendlyErrorMessage } from '../../components/AccessControl/common';
import { Tacard } from '../../components/Card/Tacard';
import { DropdownMenuContent } from '../../components/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenuItem';
import { showConfirmationDialog } from '../../components/Modals/ConfirmationDialogModal';
import { showTypeDeleteDialog } from '../../components/Modals/TypeDeleteDialog';
import { StatusBoardVariant } from '../../components/StatusBoard';
import StatusBoard from '../../components/StatusBoard/StatusBoard';
import { getAuthorizationDecision } from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  AuthDecisionSubjectType,
  Pipeline,
  PipelineType,
  Privilege,
} from '../../graphql/gen/graphql';
import { deletePipeline, getOrgPipelines } from '../../graphql/pipeline';

export default function Pipelines({
  organizationId,
  user,
  orgName,
}: {
  organizationId: string;
  user: any;
  orgName: string;
}) {
  const navigate = useNavigate();
  const { data, loading, refetch } = useQuery(getOrgPipelines, {
    variables: {
      organizationId,
    },
  });
  const [canUserDeletePipeline] = useLazyQuery(getAuthorizationDecision);
  const [deleteTheDangPipeline] = useMutation(deletePipeline, {
    onCompleted: async () => {
      refetch();
      enqueueSnackbar('Pipeline successfully deleted', {
        variant: 'success',
      });
    },
    onError: (e) => {
      enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
        variant: 'error',
      });
    },
  });
  const handleDeletePipeline = async (pipeline: Pipeline) => {
    const decision = await canUserDeletePipeline({
      variables: {
        request: {
          subject: {
            type: AuthDecisionSubjectType.User,
            identifier: user.id,
          },
          privileges: [Privilege.Update],
          requirement: 'ALL',
          resource: {
            type: AuthDecisionResourceType.Table,
            identifier: pipeline?.target?.context?.tableReferenceId,
          },
        },
      },
    });

    if (decision?.data?.authDecision === AuthDecisionResponseType.Deny) {
      showConfirmationDialog({
        title: 'Permission denied',
        children:
          'You need UPDATE privileges on the target table in order to delete this pipeline ',
      });
    }

    if (decision?.data?.authDecision === AuthDecisionResponseType.Allow) {
      showTypeDeleteDialog({
        title: `Delete ${pipeline.name} pipeline?`,
        confirmText: `Delete pipeline`,
        recoveryWarningText: `A deleted pipeline cannot be resumed. You can recreate it, but must bootstrap and initialize again. You will not lose data.`,
      }).then(() =>
        deleteTheDangPipeline({
          variables: {
            organizationId,
            pipelineId: pipeline.id,
          },
        }),
      );
    }
  };

  const pipelineCards = useMemo(
    () =>
      data?.pipelines?.map((p: Pipeline) => {
        return {
          id: p.id,
          sections: [
            {
              variant: 'pipeline-header' as StatusBoardVariant,
              state: p.state,
              details: undefined,
              headerName: p.type,
              statusMessages: [p.name, p.description],
              associatedMember: p.createdByMember,
            },
            {
              variant: 'pipeline-source' as StatusBoardVariant,
              statusMessages: [
                'Table',
                p?.sources?.[0]?._table
                  ? `${p?.sources?.[0]?._table?.tableName}`
                  : `Unavailable`,
              ],
              details: undefined,
              headerLabel: 'Source',
              slots: {
                headerIcon: {
                  src: '/assets/icon/table.svg',
                },
              },
            },
            {
              variant: 'pipeline-service' as StatusBoardVariant,
              details: undefined,
              headerLabel: 'Service',
              slots: {
                headerIcon: {
                  src:
                    p.type === PipelineType.Replication
                      ? '/assets/icon/auto_replication.svg'
                      : '/assets/icon/dedupe.svg',
                },
              },
            },
            {
              variant: 'pipeline-target' as StatusBoardVariant,
              statusMessages: [
                'Table',
                `${p?.target?._table?.tableName || 'Unavailable'}`,
              ],
              details: undefined,
              headerLabel: 'Target',
              slots: {
                headerIcon: {
                  src: '/assets/icon/table.svg',
                },
              },
            },
          ],
          actions: [
            {
              title: 'Delete',
              icon: {
                icon: Close,
              },
              iconButtonProps: {
                onClick: () => {
                  handleDeletePipeline(p);
                },
              },
            },
          ],
          dashboardDropdownContent: (
            <DropdownMenuContent
              menuItems={[
                <DropdownMenuItem
                  key={'goToSource'}
                  showAsMenuButton
                  titleText={'Go to source table'}
                  Icon={ArrowForwardIcon}
                  onClick={() => {
                    navigate(
                      toTableRoot(
                        orgName,
                        p?.sources?.[0]?._table?.warehouseName || '',
                        p?.sources?.[0]?._table?.namespaceName || '',
                        p?.sources?.[0]?._table?.tableName || '',
                      ),
                    );
                  }}
                />,
                <DropdownMenuItem
                  key={'goToTarget'}
                  showAsMenuButton
                  titleText={'Go to target table'}
                  Icon={ArrowForwardIcon}
                  onClick={() => {
                    navigate(
                      toTableRoot(
                        orgName,
                        p?.target?._table?.warehouseName || '',
                        p?.target?._table?.namespaceName || '',
                        p?.target?._table?.tableName || '',
                      ),
                    );
                  }}
                />,
                <DropdownMenuItem
                  key={'deletePipeline'}
                  showAsMenuButton
                  titleText={'Delete pipeline'}
                  onClick={() => {
                    handleDeletePipeline(p);
                  }}
                  dangerZone
                />,
              ]}
            />
          ),
        };
      }) || [],
    [data, orgName],
  );

  return (
    <Tacard>
      <CardHeader
        sx={{ backgroundColor: 'midnight.one' }}
        title={`Pipelines`}
      />
      <Box
        sx={(theme) => ({
          backgroundColor: 'midnight.one',
          borderTop: `1px solid ${theme.palette.midnight.two}`,
          paddingY: 1,
          paddingX: 2,
        })}
      >
        <Typography variant={`helperText`}>All Pipelines</Typography>
      </Box>
      <CardContent
        sx={(theme) => ({
          paddingTop: 4,
        })}
      >
        {loading && <Skeleton variant={`rectangular`} />}
        {!loading && pipelineCards && pipelineCards?.length > 0 ? (
          <StatusBoard
            statusPanels={pipelineCards}
            sx={{ background: 'inherit' }}
          />
        ) : (
          <Box>
            <Typography
              variant={`h5`}
              display={`flex`}
              justifyContent={`center`}
              sx={{ color: 'brandBlue.main' }}
            >
              No pipelines have been created. Create one on the Services page.
            </Typography>
          </Box>
        )}
      </CardContent>
    </Tacard>
  );
}
