import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useAuth } from '../../context/auth-context';
import { createDatabase } from '../../graphql/database';
import { Database, User } from '../../graphql/gen/graphql';
import { getApolloErrorMessage } from '../../utils/ApolloUtils';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import TextField from '../Forms/TextField';

export default function CreateDatabase({
  organizationId,
  warehouseId,
  onCancel,
  onDatabaseCreated,
}: {
  organizationId: string;
  warehouseId: string;
  onCancel: () => Promise<unknown>;
  onDatabaseCreated: (database: Database) => void;
}) {
  const {
    user,
  }: {
    user: any;
  } = useAuth();
  const [createDatabaseMutation, { error, reset }] =
    useMutation(createDatabase);
  const [submitError, setSubmitError] = useState<string | null>(null);

  interface FormValues {
    name: string;
  }
  const mbrDefRoleId =
    (user as User).loginSession?.membership?.defaultRoleId || '';
  const initialValues: FormValues = {
    name: '',
  };

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setSubmitError(null);
    createDatabaseMutation({
      variables: {
        organizationId,
        warehouseId,
        name: values.name.trim(),
      },
    })
      .then(({ data }) => {
        if (data && data.createDatabase) {
          // reset the state of the mutation
          reset();
          onDatabaseCreated(data.createDatabase);
        }
      })
      .catch((errors) => {
        if (errors) {
          setSubmitError(getApolloErrorMessage(errors));
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string()
          .trim()
          .required('Required')
          .matches(
            /^[a-zA-Z0-9_]*$/,
            'Database name may only contain alpha-numeric characters and ' +
              "underscores ('_') and may not contain any spaces.",
          ),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          {error ? (
            <Talert severity="error" onClose={() => reset()}>
              Error: {error.message}
            </Talert>
          ) : null}
          {submitError ? (
            <Talert severity="error">
              Error creating database: {submitError}
            </Talert>
          ) : null}
          <DialogTitle>Create Database</DialogTitle>
          <DialogContent>
            <DialogContentText>Create a new database.</DialogContentText>
            <TextField
              name="name"
              type="text"
              label="Database Name"
              id="name"
              variant="outlined"
              margin="normal"
              autoComplete="off"
              required
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Tabutton
              onClick={() => {
                reset();
                onCancel();
              }}
            >
              Cancel
            </Tabutton>
            <Tabutton
              type="submit"
              variant="contained"
              disabled={isSubmitting || !values.name}
            >
              Create
            </Tabutton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
