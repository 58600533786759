import React, { ReactElement, useMemo } from 'react';

import { AltRoute } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Divider,
} from '@mui/material';

import { SnapshotRefProp } from '../SnapshotViewer/SnapshotView';

export interface BranchSelectorProps extends BoxProps {
  branchRefs: SnapshotRefProp[];
  selectedBranch: string;
  handleChangeBranch: (branch: string) => void;
  showAll?: boolean;
}

export const BranchSelector = ({
  branchRefs,
  selectedBranch,
  handleChangeBranch,
  showAll,
  ...props
}: BranchSelectorProps): ReactElement => {
  const branches = useMemo(
    () =>
      branchRefs.sort((a, b) => {
        if (a.name === 'main') return -1;
        else if (b.name === 'main') return 1;
        return a.name.localeCompare(b.name);
      }),
    [branchRefs],
  );

  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '24px',
        border: 1,
        borderColor: 'midnight.two',
        backgroundColor: 'controlBackground.main',
        ...props.sx,
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        divider={
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: 'midnight.two' }}
          />
        }
        sx={{ height: '100%' }}
      >
        <AltRoute
          sx={{ color: 'primary.main', height: 16, width: 16, mx: 1 }}
        />

        <FormControl
          variant="standard"
          sx={(theme) => ({
            minWidth: 120,
            justifyContent: 'center',
          })}
        >
          <Select
            disableUnderline
            id="branch-select"
            defaultValue=""
            autoWidth
            value={
              selectedBranch == null || branchRefs.length === 0
                ? ''
                : selectedBranch
            }
            label="Branch View"
            onChange={(event) => {
              handleChangeBranch(event.target.value as string);
            }}
            displayEmpty
            sx={{
              '.MuiSelect-select': {
                '&:focus': { background: 'none' },
              },
              fontSize: 13,
            }}
            inputProps={{
              sx: { pt: 1 / 2, pb: 1 / 2, pl: 1 },
            }}
            MenuProps={{ MenuListProps: { sx: { minWidth: 120 } } }}
          >
            {showAll && (
              <MenuItem value="" key={'branch_'}>
                All
              </MenuItem>
            )}
            {branches.map((branch) => (
              <MenuItem value={branch.name} key={'branch_' + branch.name}>
                {branch.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Box>
  );
};
