import React, { useMemo } from 'react';

import { SortRounded, VpnKeyRounded } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  BoxProps,
  FormHelperText,
  Popper,
  StandardTextFieldProps,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { useTableEditContext } from './TableEditContext';

import { ListPopover } from '../Popovers/ListPopover';
import { GridEditTextFieldProps } from './IcebergTypeEdit';
import { PartitionFieldRow } from './PartitionEdit';
import { SchemaFieldRow } from './SchemaEdit';
import { SortOrderFieldRow } from './SortOrderEdit';
import { EditTableMode } from './TableEdit';
import { findIdentificationFields, safeJSONParse } from './TableEditHelpers';

export const getColorForComplexType = (
  type: string | undefined,
  mode: EditTableMode,
): string => {
  if (type === undefined) return '';
  if (mode !== EditTableMode.VIEW) {
    return type === 'struct'
      ? 'sky.two'
      : type === 'map'
      ? 'aurora.two'
      : type === 'list'
      ? 'sunrise.two'
      : '';
  } else {
    return type === 'struct'
      ? 'sky.one'
      : type === 'map'
      ? 'aurora.one'
      : type === 'list'
      ? 'sunrise.one'
      : '';
  }
};

export const GridEditTextField = ({
  shouldAutoFocus,
  isReadonly,
  ...props
}: GridEditTextFieldProps) => {
  return (
    <TextField
      autoFocus={shouldAutoFocus}
      focused={shouldAutoFocus}
      variant={'standard'}
      InputProps={{
        readOnly: !!isReadonly,
        disableUnderline: true,
        sx: {
          ...(props.error === true ? { color: 'error.main' } : {}),
        },
      }}
      {...props}
    ></TextField>
  );
};

interface GridTextFieldProps extends StandardTextFieldProps {
  overview?: boolean;
}
export const GridTextField = React.memo<GridTextFieldProps>(
  ({ overview, ...props }: GridTextFieldProps) => {
    const theValue = props.value;
    return (
      <Box sx={[{ p: 1, minWidth: 0 }, props.sx as SystemStyleObject]}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 1,
          }}
        >
          <Typography
            sx={{
              flexGrow: 1,
              color:
                theValue === undefined || theValue === null
                  ? 'midnight.three'
                  : '',
              wordBreak: 'break-word',
              ...(overview
                ? {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }
                : {}),
            }}
            variant={'inputText'}
          >
            {theValue !== undefined && theValue !== null
              ? String(theValue)
              : props.placeholder
              ? props.placeholder
              : String(theValue)}
            <FormHelperText error={props.error}>
              {props.helperText}
            </FormHelperText>
          </Typography>
          <Box>{props?.InputProps?.endAdornment}</Box>
        </Box>
      </Box>
    );

    // return (
    //   <TextField
    //     variant={'standard'}
    //     {...props}
    //     sx={[(props.sx as SystemStyleObject) ?? {}, { width: 1 }]}
    //     InputProps={{
    //       ...(props?.InputProps ? props.InputProps : {}),
    //       readOnly: true,
    //       disableUnderline: true,
    //       sx: [
    //         { ...(props.error === true ? { color: 'error.main' } : {}) },
    //         { pl: 2, pr: 2 },
    //         props?.InputProps?.sx as SystemStyleObject,
    //       ],
    //     }}
    //   />
    // );
  },
);

export interface IcebergTypeDetailPanelProps extends GridRenderCellParams {
  shouldHide: boolean;
}

export const CustomEditDetailPanelToggle = ({
  shouldHide,
  ...params
}: IcebergTypeDetailPanelProps) => {
  const { value: isExpanded, row, tabIndex } = params;

  //const currentEditRow: Record<string, any> = subRowsModel[params.] || {};

  const typeHasDetail =
    row?.type === 'map' ||
    row?.type === 'list' ||
    row?.type === 'struct' ||
    row?.type?.indexOf('map') > -1 ||
    row?.type?.indexOf('list') > -1 ||
    row?.type?.indexOf('struct') > -1;

  if (!typeHasDetail || shouldHide)
    return <TextField sx={{ display: 'none' }} tabIndex={0} />;
  return (
    <Box tabIndex={-1}>
      <IconButton
        size="small"
        tabIndex={-1}
        disabled={!typeHasDetail}
        aria-label={isExpanded ? 'Close' : 'Open'}
      >
        <ExpandMoreIcon
          tabIndex={-1}
          // tabIndex={typeHasDetail ? -1 : tabIndex}
          sx={{
            ml: '6.5px',
            mr: '6.5px',
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
    </Box>
  );
};

function getPartitionSourceFieldNames(
  partitionFieldModel: PartitionFieldRow[],
): string[] {
  return partitionFieldModel.map((eachPartitionFieldRow) => {
    const transform = safeJSONParse(
      eachPartitionFieldRow.transform,
      {},
    )?.value?.toLowerCase();
    const partitionName = safeJSONParse(
      eachPartitionFieldRow.schemaSourceField,
      {},
    ).name;
    return transform === 'identity'
      ? partitionName
      : `${transform}(${partitionName})`;
  });
}

function getSortOrderSourceFieldNames(
  sortOrderFieldModel: SortOrderFieldRow[],
): string[] {
  return sortOrderFieldModel.map((eachSortOrderFieldRow) => {
    const transform = safeJSONParse(
      eachSortOrderFieldRow.transform,
      {},
    )?.value?.toLowerCase();
    const sortOrderName = safeJSONParse(
      eachSortOrderFieldRow.schemaSourceField,
      {},
    ).name;
    return transform === 'identity'
      ? `${sortOrderName} ${eachSortOrderFieldRow.direction} ${eachSortOrderFieldRow.nullOrder}`
      : `${transform}(${sortOrderName}) ${eachSortOrderFieldRow.direction} ${eachSortOrderFieldRow.nullOrder}`;
  });
}

export interface SchemaColumnIconsProps extends BoxProps {
  row: GridRowModel<SchemaFieldRow>;
  level: number;
}

export const SchemaColumnIcons = ({
  row,
  level,
  ...props
}: SchemaColumnIconsProps) => {
  const { partitionFieldModel, sortOrderFieldModel, schemaModel } =
    useTableEditContext();

  const partFieldNames = useMemo(
    () =>
      getPartitionSourceFieldNames(
        partitionFieldModel.map((eachRow) => eachRow),
      ),
    [partitionFieldModel],
  );
  const sortFieldNames = useMemo(
    () =>
      getSortOrderSourceFieldNames(
        sortOrderFieldModel.map((eachRow) => eachRow),
      ),
    [sortOrderFieldModel],
  );
  const identificationFieldNames = useMemo(
    () =>
      findIdentificationFields(schemaModel, schemaModel['top'], null).map(
        (eachRow) => eachRow.name,
      ),
    [schemaModel],
  );

  const partArray = partitionFieldModel.filter(
    (eachRow) => eachRow?.schemaSourceField?.indexOf(row.rowId) > -1,
  );
  const sortArray = sortOrderFieldModel.filter(
    (eachRow) => eachRow?.schemaSourceField?.indexOf(row.rowId) > -1,
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      {row.identity && (
        <PopupState variant="popper">
          {(popupState) => (
            <Box
              id={`field_icon_partition_${row.rowId}`}
              {...bindHover(popupState)}
            >
              <VpnKeyRounded fontSize={'small'} />
              <Popper
                {...bindPopper(popupState)}
                placement={`right-start`}
                modifiers={[
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: ['bottom'],
                    },
                  },
                ]}
                sx={{ zIndex: 20000 }}
              >
                <ListPopover
                  title="Identified by:"
                  listItems={identificationFieldNames}
                />
              </Popper>
            </Box>
          )}
        </PopupState>
      )}
      {partArray.length > 0 && (
        <PopupState variant="popper">
          {(popupState) => (
            <Box
              id={`field_icon_partition_${row.rowId}`}
              {...bindHover(popupState)}
            >
              <img
                src="/assets/icon/partition-icon.svg"
                alt="Partition Icon"
                style={{ marginLeft: '8px' }}
                {...bindHover(popupState)}
              />
              <Popper
                {...bindPopper(popupState)}
                placement={`right-start`}
                sx={{ zIndex: 20000 }}
                modifiers={[
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: ['bottom'],
                    },
                  },
                ]}
              >
                <ListPopover
                  title="Partitioned by:"
                  listItems={partFieldNames}
                />
              </Popper>
            </Box>
          )}
        </PopupState>
      )}
      {sortArray.length > 0 && (
        <PopupState variant="popper">
          {(popupState) => (
            <Box
              id={`field_icon_partition_${row.rowId}`}
              {...bindHover(popupState)}
            >
              <SortRounded fontSize={'small'} />
              <Popper
                {...bindPopper(popupState)}
                placement={`right-start`}
                sx={{ zIndex: 20000 }}
                modifiers={[
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: ['bottom'],
                    },
                  },
                ]}
              >
                <ListPopover title="Ordered by:" listItems={sortFieldNames} />
              </Popper>
            </Box>
          )}
        </PopupState>
      )}
    </Box>
  );
};
