import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/material';

import { Tabutton } from '../Button/Tabutton';

export type ErrorMessagingType = {
  header: string;
  subHeader: string;
  description?: string;
  img: string;
  link: string;
  buttons?: React.ReactNode;
  alignItems?: string;
};

const nounProjectUrl = 'https://thenounproject.com/icon/';
const penguin = 'penguin';
const sadBear = 'sad-bear';

export const PageNotFound: ErrorMessagingType = {
  header: 'Where are we?',
  subHeader: 'We seem to have wandered far from home.',
  img: penguin,
  link: `${nounProjectUrl}penguin-624968/`,
  buttons: (
    <Box display={'flex'} alignItems={'center'} paddingTop={3}>
      <Tabutton
        onClick={() => {
          window.history.go(-1);
        }}
      >
        GO BACK
      </Tabutton>
      <Tabutton
        onClick={() => {
          window.location.href = '/';
        }}
        variant={'outlined'}
        sx={{ padding: '7px 24px', marginLeft: 1 }}
        endIcon={<ArrowForwardIcon sx={{ width: '16px', height: '16px' }} />}
      >
        TAKE ME HOME
      </Tabutton>
    </Box>
  ),
};

export const SomethingWentWrongMessage: ErrorMessagingType = {
  header: 'Oh bother',
  subHeader: 'Something went wrong.',
  img: sadBear,
  link: `${nounProjectUrl}bear-624965/`,
  buttons: (
    <Box display={'flex'} alignItems={'center'} paddingTop={3} mr={4}>
      <Tabutton
        onClick={() => {
          window.location.reload();
        }}
      >
        TRY AGAIN
      </Tabutton>
      <Tabutton
        onClick={() => {
          window.location.href = '/';
        }}
        variant={'outlined'}
        sx={{ padding: '7px 24px', marginLeft: 1 }}
        endIcon={<ArrowForwardIcon sx={{ width: '16px', height: '16px' }} />}
      >
        TAKE ME HOME
      </Tabutton>
    </Box>
  ),
  alignItems: 'flex-start',
};

export const PageAccessDeniedMessage: ErrorMessagingType = {
  header: 'Access Denied',
  subHeader: 'You do not have sufficient privileges to access this resource.',
  description:
    "If you're sure that you are in the right place, you will need to request access from an Administrator.",
  img: sadBear,
  link: `${nounProjectUrl}bear-624965/`,
};

export const OrgAccessDeniedMessage: ErrorMessagingType = {
  header: 'Access Denied',
  subHeader: 'You are not authorized to access that page',
  img: sadBear,
  link: `${nounProjectUrl}bear-624965/`,
  buttons: (
    <Box display={'flex'} marginRight={4} paddingTop={3}>
      <Tabutton
        onClick={() => {
          window.location.href = '/';
        }}
        variant={'contained'}
        endIcon={<ArrowForwardIcon />}
      >
        GO TO LOGIN
      </Tabutton>
    </Box>
  ),
  alignItems: 'flex-end',
};
