import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik } from 'formik';

import { LOGIN_DEST_ORG_ID } from '../../context/GlobalPropsContext';

import { ROUTES } from '../../RouteTable';
import { Organization, User } from '../../graphql/gen/graphql';
import { Tabutton } from '../Button/Tabutton';

interface LogoutDialogProps {
  destinationOrg: Organization;
  prompt: string;
  user: User;
}
const LogoutDialog = create(
  ({ destinationOrg, prompt, user }: LogoutDialogProps) => {
    const modal = useModal();
    const navigate = useNavigate();

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={() => {
            if (destinationOrg.id != null) {
              sessionStorage.setItem(LOGIN_DEST_ORG_ID, destinationOrg.id);
            }

            modal.hide();
            navigate(ROUTES.logout);
          }}
        >
          {({ isSubmitting, status }) => (
            <Form>
              <DialogTitle>Logout?</DialogTitle>
              <DialogContent>
                <DialogContentText>{prompt}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Tabutton
                  onClick={() => {
                    modal.hide();
                  }}
                >
                  Cancel
                </Tabutton>
                <Tabutton
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Ok
                </Tabutton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  },
);

export default LogoutDialog;

export function showLogoutDialog(
  destinationOrg: Organization,
  user: User,
  prompt: string,
) {
  return show<boolean, any, LogoutDialogProps>(LogoutDialog, {
    destinationOrg,
    user,
    prompt,
  });
}
