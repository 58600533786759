import * as React from 'react';

import { computeSlots } from '../../utils/computeSlots';

import {
  STATUS_BOARD_DEFAULT_SLOTS_COMPONENTS,
  StatusBoardProps,
  StatusBoardPropsWithDefaultValue,
  StatusBoardSlotsComponent,
} from './index';

export const STATUS_BOARD_PROPS_DEFAULT_VALUES: StatusBoardPropsWithDefaultValue =
  {};

const defaultSlots = STATUS_BOARD_DEFAULT_SLOTS_COMPONENTS;

export const useStatusBoardProps = (inProps: StatusBoardProps) => {
  const slots = React.useMemo<StatusBoardSlotsComponent>(
    () =>
      computeSlots<StatusBoardSlotsComponent>({
        defaultSlots,
        slots: inProps.slots,
      }),
    [inProps.slots],
  );

  return React.useMemo<StatusBoardProps>(
    () => ({
      ...STATUS_BOARD_PROPS_DEFAULT_VALUES,
      ...inProps,
      slots,
    }),
    [inProps, slots],
  );
};
