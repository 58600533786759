import React, { useMemo, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useField } from 'formik';
import { includes, isEmpty, some } from 'lodash';

import { Schema, SchemaField } from '../../graphql/gen/graphql';
import { lifecycleManagementProperties } from '../../utils/properties';
import { Talert } from '../Alert/Talert';
import TextField from '../Forms/TextField';
import SectionHeader from '../SectionHeader/SectionHeader';
import { findSchemaFields } from '../TableEdit/TableEditHelpers';
import LifecycleColumnMasking from './LifecycleColumnMasking';
import LifecycleRowTTL from './LifecycleRowTTL';
import { TIMESTAMP_COLUMN_TYPES } from './helpers';

interface LifecycleProps {
  currentSchema: Schema;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setEditingPropGroup: (value: boolean) => void;
  stagePropertiesForDelete: (value: string[]) => void;
}

export default function LifecycleManagement({
  currentSchema,
  setFieldValue,
  setEditingPropGroup,
  stagePropertiesForDelete,
}: LifecycleProps) {
  const [field, meta] = useField('lifecycleMgmt');
  const componentMapping = {
    TextField,
    LifecycleColumnMasking,
    LifecycleRowTTL,
  };

  const timestampColumns = useMemo(
    () =>
      currentSchema?.fields?.filter((column: any) =>
        includes(TIMESTAMP_COLUMN_TYPES, column.type),
      ),
    [currentSchema],
  );

  const identifierOptions = useMemo(
    () => findSchemaFields([], currentSchema, false),
    [currentSchema],
  );
  const handleTimestampChange = (event: any) => {
    setFieldValue('lifecycleMgmt.timestampColumn', event.target.value);
    setEditingPropGroup(true);
    //@ts-ignore
    const timestampType = timestampColumns.find(
      //@ts-ignore
      (tsCol: SchemaField) => tsCol.name === event.target.value,
    ).type;
    setFieldValue('lifecycleMgmt.timestampType', timestampType);
  };

  useEffect(() => {
    if (isEmpty(field.value.timestampColumn) && !isEmpty(timestampColumns)) {
      setFieldValue(
        'lifecycleMgmt.timestampColumn',
        //@ts-ignore
        timestampColumns[0].name,
      );
      //@ts-ignore
      setFieldValue('lifecycleMgmt.timestampType', timestampColumns[0].type);
    }

    if (isEmpty(field.value.timestampColumn) && isEmpty(timestampColumns)) {
      setFieldValue('lifecycleMgmt.timestampColumn', 'none');
      setFieldValue('lifecycleMgmt.timestampType', '');
    }
  }, [timestampColumns]);

  return (
    <Box width={`100%`}>
      {lifecycleManagementProperties.map((property: any) => {
        //@ts-ignore
        const Component = componentMapping[property.component];
        const stdComponentProps = {
          id: property.name,
          name: `lifecycleMgmt.${property.name}`,
          label: property?.fieldLabel,
          select: property?.options ? true : null,
          fullWidth: true,
          helperText: property?.helperText,
          ...(property.name === 'timestampColumn'
            ? {
                onChange: handleTimestampChange,
              }
            : {
                setFieldValue: setFieldValue,
                setEditingPropGroup: setEditingPropGroup,
                stagePropertiesForDelete: stagePropertiesForDelete,
              }),
          ...(property.name === 'columnMasking'
            ? {
                identifierOptions: identifierOptions,
                timestampColumn: field?.value?.timestampColumn,
              }
            : {}),
        };

        const missingTimestampColumn =
          isEmpty(timestampColumns) &&
          [
            'lifecycle.table.max-data-age-ms',
            'lifecycle.column.placeholder.max-data-age-ms',
          ].includes(property.key);

        return (
          <div key={property.key}>
            {missingTimestampColumn ? null : (
              <Box
                sx={{
                  mb: 2,
                  backgroundColor: 'controlBackground.half',
                  '&:last-of-type': { mb: 0 },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'start',
                }}
              >
                <SectionHeader>
                  <Typography
                    variant={`h6`}
                    display={'inline-flex'}
                    alignItems={'center'}
                    sx={{}}
                  >
                    {property.label}
                  </Typography>
                </SectionHeader>

                <Typography variant={`body2`} sx={{ px: 2, paddingTop: 2 }}>
                  {property.description}
                </Typography>
                {isEmpty(timestampColumns) ? (
                  <Box
                    sx={{
                      backgroundColor: 'midnight.half',
                      height: 48,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      my: 2,
                    }}
                  >
                    <Typography variant={`subtitle2`} color={`midnight.six`}>
                      No appropriate columns available in this table
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      p: 2,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      ...(property.name === 'timestampColumn'
                        ? {
                            'div:first-of-type': {
                              width: '100%',
                            },
                          }
                        : {}),
                    }}
                  >
                    <Component
                      {...stdComponentProps}
                      sx={{
                        mt: 1,
                        mb: 2,
                        justifyContent: 'flex-start',
                      }}
                    >
                      <MenuItem disabled value="none">
                        Select timestamp column
                      </MenuItem>
                      {timestampColumns?.map((option: any) => (
                        <MenuItem
                          key={`timestamp-column-${option.id}`}
                          value={option.name}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant={`inputText`}>
                              {option.name}
                            </Typography>
                            <Typography
                              color={'midnight.five'}
                              variant={`helperText`}
                              sx={{ ml: 2 }}
                            >
                              {option.type}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </Component>
                    {property.name === 'timestampColumn' &&
                      !isEmpty(field.value.timestampColumn) &&
                      some(
                        timestampColumns,
                        (tsCol: SchemaField) =>
                          tsCol.name === field.value.timestampColumn &&
                          tsCol.type === 'long',
                      ) && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: 200,
                            ml: 2,
                            pb: 2,
                          }}
                        >
                          <TextField
                            name={`lifecycleMgmt.timeUnit`}
                            select
                            onChange={(event) => {
                              setFieldValue(
                                'lifecycleMgmt.timeUnit',
                                event.target.value,
                              );
                              setEditingPropGroup(true);
                            }}
                            label={`Unit of time`}
                          >
                            <MenuItem disabled value={`none`}>
                              Select time unit
                            </MenuItem>
                            {[
                              { label: 'seconds', value: 's' },
                              { label: 'milliseconds', value: 'ms' },
                              { label: 'microseconds', value: 'us' },
                            ].map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                      )}
                  </Box>
                )}
              </Box>
            )}
          </div>
        );
      })}
      {meta?.touched &&
        //@ts-ignore
        meta?.error?.rowRecordAge && (
          <Talert severity={`error`}>
            Either Row Level TTL or Column level masking is required.
          </Talert>
        )}
    </Box>
  );
}
