import React from 'react';

import { useLazyQuery } from '@apollo/client';
import { Box, CardHeader, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import { enqueueSnackbar } from 'notistack';

import { StorageProfile } from '../../graphql/gen/graphql';
import { getStorageProfileValidation } from '../../graphql/organization';
import { AddNewResource } from '../Button/AddNewResource';
import { Refresh } from '../Button/Refresh';
import { Tacard } from '../Card/Tacard';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import { MenuContentType } from '../DataGridToolbar/DataGridToolbar';
import DataSourceCard from '../DataSources/DataSourceCard';

export default function StorageProfilesGrid({
  storageProfiles,
  onStorageDelete,
  loading,
  isSecurityAdmin,
  dashboardMenuContent,
  refreshResourceContent,
  addResourceContent,
  orgName,
  user,
}: {
  storageProfiles: StorageProfile[];
  onStorageDelete: any;
  loading: boolean;
  isSecurityAdmin: boolean;
  dashboardMenuContent: JSX.Element;
  refreshResourceContent: MenuContentType;
  addResourceContent: MenuContentType;
  orgName: string;
  user: any;
}) {
  const [validateStorage] = useLazyQuery(getStorageProfileValidation, {
    //network-only to force a re-validation
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      enqueueSnackbar(
        'Revalidated. Changes made in your AWS account may take a few minutes to be available here.',
        {
          variant: 'success',
        },
      );
    },
  });

  return (
    <>
      <Tacard>
        <CardHeader
          title={
            <Box
              display={`flex`}
              alignItems={`center`}
              justifyContent={`space-between`}
            >
              <Typography variant={`h6`}>Storage profiles</Typography>
              <Box display={`flex`}>
                {refreshResourceContent && (
                  <Refresh
                    refreshList={refreshResourceContent.action}
                    title={refreshResourceContent.title}
                  />
                )}
                {addResourceContent && (
                  <AddNewResource
                    createResource={addResourceContent.action}
                    title={addResourceContent.title}
                    disabled={addResourceContent.disabled}
                  />
                )}
                {dashboardMenuContent && (
                  <DashboardMenu
                    dashboardDropdownContent={dashboardMenuContent}
                  />
                )}
              </Box>
            </Box>
          }
        />
        {orderBy(
          storageProfiles,
          [(profile) => profile?.bucket?.toLowerCase()],
          'asc',
        )?.map((profile) => (
          <DataSourceCard
            key={profile.id}
            profile={profile}
            orgName={orgName}
            onValidateStorage={(id) =>
              validateStorage({ variables: { storageProfileId: id } })
            }
            onDeleteProfile={(profile) => onStorageDelete(profile)}
            isSecurityAdmin={isSecurityAdmin}
            currentUserEmail={user?.email}
          />
        ))}
      </Tacard>
    </>
  );
}
