import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import {
  Autocomplete,
  ListItemButton,
  ListItemText,
  StandardTextFieldProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { FormikValues, useField, useFormikContext } from 'formik';
import { debounce, includes } from 'lodash';

import { useAuth } from '../../context/auth-context';
import { SortByDirection } from '../../graphql/gen/graphql';
import { basicSearchRolesQuery } from '../../graphql/organization';
import { BuiltInRoleNames } from '../../graphql/role';
import { getLogger } from '../../utils/logging';
import { getFilteredRoles } from '../../utils/roleHelper';

const logger = getLogger(
  'components.Forms.RoleSelector' /*FUTURE import.meta.url ?*/,
);
interface RoleSearchParams {
  organizationId: string;
  query: string;
  maxResults: number;
  searchAfter: string[] | undefined;
  sortBy: any[];
  excludeSystemRoles: boolean;
}

interface RoleSelectorProps {
  name: string;
  creatorRoleStep?: boolean;
  textFieldProps?: TextFieldProps;
}

type SimpleRolesResult = {
  roleId: string;
  roleName: string;
};

export const RoleSelector = ({
  name,
  creatorRoleStep,
  textFieldProps,
  ...props
}: RoleSelectorProps) => {
  const [field, meta, helpers] = useField(name);
  const [roleOptions, setRoleOptions] = useState<SimpleRolesResult[]>([]);
  const [value, setValue] = useState<SimpleRolesResult>();
  const [searchQuery, setSearchQuery] = useState<string>(
    field.value ? field.value : '',
  );
  const [highlighted, setHighlighted] = useState('');
  const { user } = useAuth();
  const organizationName = user.loginSession.loggedInOrg.name;
  const organizationId = user.getOrganization(organizationName).id;
  const isSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(organizationName),
    [user, organizationName],
  );
  const error = meta.error && meta.touched;
  let helperText = textFieldProps?.helperText;
  if (error) {
    helperText = `${meta.error} ${helperText ? `: ${helperText}` : ''}`;
  }

  const { data, loading, refetch } = useQuery<any, RoleSearchParams>(
    basicSearchRolesQuery,
    {
      variables: {
        organizationId: organizationId,
        query: searchQuery !== '' ? searchQuery : '*',
        maxResults: 100,
        searchAfter: undefined,
        sortBy: [
          {
            fieldName: searchQuery ? '_score' : 'roleName.sort',
            sortDirection: searchQuery
              ? SortByDirection.Desc
              : SortByDirection.Asc,
          },
        ],
        excludeSystemRoles: false,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  );

  const persistedValue = useMemo(() => {
    if (data && field.value !== null) {
      const persistedRole = data?.searchRoles?.results.filter(
        (eachRole: SimpleRolesResult) => eachRole.roleId === field.value,
      );
      if (!persistedRole || persistedRole.length == 0) {
        return null;
      } else {
        return persistedRole[0];
      }
    }

    return null;
  }, [data, field]);

  const nextKeys: string[] = useMemo(
    () =>
      data?.searchRoles?.searchAfterKeys
        ? data?.searchRoles?.searchAfterKeys
        : [],
    [data],
  );
  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      //@ts-ignore
      const searchyMcSearch = e?.target?.value || '';
      setSearchQuery(searchyMcSearch);
    },
    [],
  );
  const debouncedSearchChange = debounce(onSearchChange, 500);

  const onRoleSelected = useCallback(
    async (roleSelected: SimpleRolesResult) => {
      helpers.setValue(roleSelected?.roleId);
    },
    [],
  );

  const handleScroll = useCallback(
    (event: React.SyntheticEvent) => {
      const listboxNode = event.currentTarget;
      const position = listboxNode.scrollTop + listboxNode.clientHeight;

      if (
        listboxNode.scrollHeight - position <= 1 &&
        roleOptions?.length < data?.searchRoles?.totalHits
      ) {
        refetch({
          searchAfter: nextKeys.length === 0 ? undefined : nextKeys,
        }).then((refetchGuys) => {
          setRoleOptions((prevRoleOptions) => [
            ...prevRoleOptions,
            ...refetchGuys?.data?.searchRoles?.results,
          ]);
        });
      }
    },
    [nextKeys, data, roleOptions],
  );

  const useUsersRoles = !isSecurityAdmin && creatorRoleStep;

  useEffect(() => {
    if (searchQuery !== undefined && !useUsersRoles) {
      refetch({
        query: searchQuery !== '' ? searchQuery : '*',
        searchAfter: undefined,
      })
        .then((refetchGuys) =>
          setRoleOptions(refetchGuys?.data?.searchRoles?.results),
        )
        .catch((error) => {
          logger.error('problem querying roles ', error);
        });
    }
    if (!isSecurityAdmin && creatorRoleStep) {
      // For creator role if user is not security admin, only show roles they are in
      const filteredRoles = getFilteredRoles(user);
      setRoleOptions(filteredRoles);
    }
  }, [searchQuery]);

  return (
    <>
      <Autocomplete
        getOptionLabel={(option) => option.roleName}
        filterOptions={(x) => x}
        loading={loading}
        options={roleOptions ? roleOptions : []}
        value={value || persistedValue || textFieldProps?.value || null}
        noOptionsText={`No roles`}
        onChange={(event, newValue) => {
          //@ts-ignore
          onRoleSelected(newValue).then(() => {
            //@ts-ignore
            setValue(newValue);
          });
        }}
        onInputChange={(event) => {
          //@ts-ignore
          debouncedSearchChange(event);
        }}
        onHighlightChange={(event, option, reason) => {
          if (reason === 'keyboard') {
            setHighlighted(option?.roleId || '');
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            helperText={helperText}
            error={!!error}
            {...field}
            label={
              textFieldProps?.label ? textFieldProps.label : 'Tabular role*'
            }
            fullWidth
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
          role: 'list-box',
        }}
        getOptionDisabled={(option) =>
          includes(
            [BuiltInRoleNames.SECURITY_ADMIN, BuiltInRoleNames.ORG_ADMIN],
            option?.roleName,
          )
        }
        isOptionEqualToValue={(option: any, value: any) =>
          option.roleId === value.roleId
        }
        sx={{ pt: 2 }}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <ListItemButton
                sx={{
                  backgroundColor:
                    highlighted === option.roleId ? 'sky.two' : 'inherit',
                }}
              >
                <ListItemText sx={{ wordBreak: 'break-word' }}>
                  {option?.roleName}
                </ListItemText>
              </ListItemButton>
            </li>
          );
        }}
      />
    </>
  );
};
