import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  Tooltip,
  IconButton,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Tabutton } from '../../Button/Tabutton';
import { ExternalBreadcrumbs } from '../../CreateStorageBucket/BucketSteps/helpers';
import { Tabulink } from '../../Link/Tabulink';

const permissionSet = JSON.stringify(
  {
    Action: [
      'glue:UpdateTable',
      'glue:UpdateDatabase',
      'glue:SearchTables',
      'glue:GetTables',
      'glue:GetTable',
      'glue:GetDatabases',
      'glue:GetDatabase',
      'glue:DeleteTable',
      'glue:DeleteDatabase',
      'glue:CreateTable',
      'glue:CreateDatabase',
    ],
    Effect: 'Allow',
    Resource: '*',
  },
  null,
  2,
);

const gluePermissions = ['IAM', 'Roles', 'Your role name'];

export const GlueSetup = ({
  region,
  roleArn,
}: {
  region: string;
  roleArn: string;
}) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  const roleName = roleArn.split('/')[1];
  return (
    <Box>
      <Box sx={{ width: 0.95 }}>
        <Grid
          container
          sx={{
            width: 1,
            border: 1,
            borderColor: 'midnight.two',
            borderRadius: '8px',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              padding: 1 / 2,
              borderRadius: '8px',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottom: 1,
              borderColor: 'midnight.two',
              backgroundColor: 'dusk.half',
            }}
          >
            <Typography variant={'body1'}>
              Follow link to set up Glue permissions for role: <b>{roleArn}</b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              padding: 1,
              borderBottom: 0,
              borderColor: 'midnight.two',
            }}
          >
            <Typography sx={{ display: 'inline-flex', mr: 1 }}>
              <Tabulink
                external
                href={`https://${region}.console.aws.amazon.com/iamv2/home?region=${region}#/roles/details/${roleName}`}
                variant="body1"
                rel="noopener"
                aria-label="Amazon IAM Policy"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                AWS IAM role permissions
                <OpenInNewIcon fontSize="small" sx={{ ml: '2px', mr: '3px' }} />
              </Tabulink>
              (instructions detailed below)
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        In AWS IAM:
        <ExternalBreadcrumbs breadcrumbs={gluePermissions} />
        <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              Click on attached <b>permission policy</b>.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              Add the following Glue permissions and <b>save</b> policy.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              Click <b>Sync Glue</b> below to complete connection.
            </Typography>
          </ListItem>
        </List>
        <Grid
          container
          sx={{
            width: 0.95,
            mt: 1,
            border: 1,
            borderColor: 'midnight.two',
            borderRadius: '8px',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              padding: 1,
              borderRadius: '8px',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottom: 1,
              borderColor: 'midnight.two',
              backgroundColor: 'dusk.half',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: 2,
            }}
          >
            <Typography
              variant={'body1'}
              color={'midnight.nine'}
              alignSelf={'center'}
            >
              Permissions
            </Typography>
            <Tooltip
              title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
              placement={`top`}
              arrow
            >
              <IconButton
                onClick={() => copyToClipBoard(permissionSet)}
                title="Copy to clipboard"
                size={'small'}
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              borderBottom: 1,
              borderColor: 'midnight.two',
            }}
          >
            <SyntaxHighlighter
              language="bash"
              style={{ ...dracula }}
              customStyle={{ margin: 0 }}
              showLineNumbers={false}
            >
              {permissionSet}
            </SyntaxHighlighter>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              padding: 1 / 2,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Tooltip
              title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
              placement={`top`}
              arrow
            >
              <Tabutton
                onClick={() => copyToClipBoard(permissionSet)}
                title="Copy to clipboard"
                size={'small'}
                sx={{ alignSelf: 'right' }}
                endIcon={<FileCopyIcon />}
              >
                Copy permissions
              </Tabutton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
