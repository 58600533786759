import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { PaletteMode } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { DASH_PATH } from '../RouteTable';
import { MainNavTabItem } from '../components/Layouts/TabLayoutGroupHeader';
import { updateUserProperties, WellKnownUserProperties } from '../graphql/user';
import { fetchVersionQuery, fetchWebPropertiesQuery } from '../graphql/utils';
import { getLogger } from '../utils/logging';
import { useAuth } from './auth-context';
import { usePosthogClient } from './posthog';

const logger = getLogger(
  'components.context.GlobalPropsContext' /*FUTURE import.meta.url ?*/,
);

export enum MainTabGroup {
  DATA = 'data',
  ORG = 'org',
  CONNECTIONS = 'connections',
  PIPELINES = 'pipelines',
}

//we were using the tabs array in the Organization.tsx component to register the tab mapping, but it cannot be exported
//this helper array can be used to register tab mapping outside of Organization component (aka single entity homepages)
export const postDashPathForGlobalNavMapping = [
  {
    href: 'warehouses',
    group: MainTabGroup.DATA,
  },
  {
    href: 'dropped-tables',
    group: MainTabGroup.DATA,
  },
  {
    href: 'members',
    group: MainTabGroup.ORG,
  },
  {
    href: 'roles',
    group: MainTabGroup.ORG,
  },
  {
    href: 'org-access-controls',
    group: MainTabGroup.ORG,
  },
  {
    href: 'labels',
    group: MainTabGroup.ORG,
  },
  {
    href: 'settings',
    group: MainTabGroup.ORG,
  },
  {
    href: 'profile',
    group: MainTabGroup.ORG,
  },
  {
    href: 'usage',
    group: MainTabGroup.ORG,
  },
  {
    href: 'storage',
    group: MainTabGroup.CONNECTIONS,
  },
  {
    href: 'compute',
    group: MainTabGroup.CONNECTIONS,
  },
  {
    href: 'security',
    group: MainTabGroup.CONNECTIONS,
  },
  {
    href: 'services',
    group: MainTabGroup.PIPELINES,
  },
  {
    href: 'pipelines',
    group: MainTabGroup.PIPELINES,
  },
];

export const LOGIN_DEST_ORG_ID = 'LOGIN_DESTINATION_ORG';
type MapVal = string | boolean | number;
type MapType = Map<string, MapVal>;
export enum KnownFeatureFlags {
  SELF_SIGNUP_ENABLED = 'web.self-signup-enabled',
  SHOW_UNKNOWN_EVENTS = 'show-unknown-events',
  SHOW_VERSION = 'show-version',
  DARK_MODE_ENABLED = 'dark-mode-enabled',
  SHOW_TABLE_CONFIG = 'internal-show-table-config-tab',
  SHOW_PREVIEW_REGIONS = 'show-preview-regions',
  GCS_TESTING_ENABLED = 'enable-gcs-testing',
  ADLS_TESTING_ENABLED = 'enable-adls-testing',
  USAGE_DATA_PAGE = 'usage-data',
  CDC_PIPELINE_CREATION_ENABLED = 'cdc-pipeline-creation-enabled',
  REGISTER_TABLE_ENABLED = 'register-table-enabled',
  PIPELINES_ENABLED = 'enable-pipelines',
}

export function processFeatureFlags(
  flags: string[],
): Record<string, string | boolean | number> {
  let retVal: Record<string, string | boolean | number> = {};

  flags.forEach((flag) => {
    retVal[flag] = true;
  });

  return retVal;
}

function getPropertyValue(val: string): string | boolean | number {
  if (val === 'true') {
    return true;
  } else if (val === 'false') {
    return false;
  } else if (/^\s*\d+\s*$/i.test(val)) {
    return Number(val);
  } else {
    return val;
  }
}
function prepareGlobalWebProps(
  propsObject: any,
): Record<string, string | boolean | number> {
  let retMap: Record<string, string | boolean | number> = {};
  if (propsObject != null) {
    Object.keys(propsObject).forEach(
      (key: any) => (retMap[key] = getPropertyValue(propsObject[key])),
    );
  }
  return retMap;
}

function findMainNavIndex(
  navTabGroup: string,
  mainTabsGroups: string[],
): number {
  return mainTabsGroups.indexOf(navTabGroup) || 0;
}

function findMainTabGroup(
  entityPaths: string[],
  orgTabsMap: Map<string, MainTabGroup>,
  activeTab: string,
): MainTabGroup {
  if (activeTab === 'warehouses') {
    return MainTabGroup.DATA;
  } else {
    return (
      orgTabsMap.get(entityPaths[1]) ||
      orgTabsMap.get(activeTab) ||
      MainTabGroup.DATA
    );
  }
}

const getEntityPathArray = () => {
  const location = window.location;
  const pathParts = location.pathname.split('/' + DASH_PATH + '/');
  const entityPathParts = pathParts[0].replace(/^\/+|\/+$/g, '').split('/');
  return entityPathParts;
};
const findActiveTab = () => {
  const location = window.location;
  const pathParts = location.pathname.split('/' + DASH_PATH + '/');
  let activeTab: string = '';
  if (pathParts.length > 1) {
    const tabPathParts = pathParts[1].replace(/^\/+|\/+$/g, '').split('/');
    activeTab = tabPathParts[0];
  }
  return activeTab;
};

export function createGlobalPropertiesCtx() {
  const ffEnabled: (flag: KnownFeatureFlags | undefined) => boolean = () =>
    false;
  const defaultMainTabGroup = MainTabGroup.DATA;
  const defPaletteControl: { togglePaletteMode: () => void } = {
    togglePaletteMode: () => {},
  };
  interface GlobalPropsValue {
    systemProps: Record<string, string | boolean | number>;
    ff: (flag: KnownFeatureFlags | undefined) => boolean;
    paletteMode: PaletteMode;
    paletteControl: { togglePaletteMode: () => void };
    featureFlagsLoading: boolean;
    globalContextLoading: boolean;
    mainTabGroup: MainTabGroup;
    mainNavIndex: number;
    registerMainNavTabItems: (tabs: MainNavTabItem[]) => void;
  }
  const defaultPaletteMode: PaletteMode = 'light';

  const ctx = createContext<GlobalPropsValue>({
    systemProps: {},
    ff: ffEnabled,
    paletteMode: defaultPaletteMode,
    paletteControl: defPaletteControl,
    featureFlagsLoading: true,
    globalContextLoading: true,
    mainTabGroup: defaultMainTabGroup,
    mainNavIndex: 0,
    registerMainNavTabItems: (tabs: MainNavTabItem[]) => {},
  });

  function Provider(props: PropsWithChildren<{}>) {
    const [mainTabsGroupArray, setMainTabsGroupArray] = useState<
      MainTabGroup[]
    >([]);

    const orgTabsMap = new Map<string, MainTabGroup>(
      postDashPathForGlobalNavMapping.map((tab) => [tab.href, tab.group]),
    );

    const [featureFlagsLoading, setFeatureFlagsLoading] =
      useState<boolean>(true);

    const [systemProps, setSystemProps] = useState<
      Record<string, string | boolean | number>
    >({});
    const initialTabGroup = findMainTabGroup(
      getEntityPathArray(),
      orgTabsMap,
      findActiveTab(),
    );
    const [mainNavIndex, setMainNavIndex] = React.useState(
      findMainNavIndex(initialTabGroup, mainTabsGroupArray),
    );

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const { user } = useAuth();

    const [paletteMode, setPaletteMode] = React.useState<PaletteMode>(
      user?.properties[WellKnownUserProperties.PALETTE_MODE]
        ? user.properties[WellKnownUserProperties.PALETTE_MODE]
        : prefersDarkMode
        ? 'light'
        : 'light',
    );
    const [mainTabGroup, setMainTabGroup] = useState(initialTabGroup);

    const { data, loading } = useQuery(fetchWebPropertiesQuery, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    });
    const { data: dataVersion, loading: loadingVersion } = useQuery(
      fetchVersionQuery,
      {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    );

    const [updateUserPropertiesMutation] = useMutation(updateUserProperties);

    const paletteControl = React.useMemo(
      () => ({
        togglePaletteMode: () => {
          let newMode: PaletteMode = paletteMode === 'light' ? 'dark' : 'light';
          setPaletteMode(newMode);

          updateUserPropertiesMutation({
            variables: {
              userId: user.id,

              userProperties: {
                properties: [
                  {
                    name: WellKnownUserProperties.PALETTE_MODE,
                    value: newMode,
                  },
                ],
              },
            },
            errorPolicy: 'all',
          }).catch((error) => {});
        },
      }),
      [paletteMode, setPaletteMode, user, updateUserPropertiesMutation],
    );
    const addFlags: (flags: string[]) => void = (flags) => {
      setSystemProps((prevState) => {
        return { ...prevState, ...processFeatureFlags(flags) };
      });
      setFeatureFlagsLoading(false);
    };
    const posthog = usePosthogClient();

    useMemo(() => {
      if (posthog) {
        posthog.onFeatureFlags((flags) => {
          //doing this manually and globally here because when using the useFeatureFlag hook I was seeing weird delays
          //even after this callback fired.  I can make my own map and use that for the tab control since the delay
          //was causing the tabs not to be present on the first couple renders.  If I could reliably detect when the other flags are
          //available, we could get rid of this.
          addFlags(flags);
        });
      }
    }, [posthog]);

    const isLoaded = useCallback(() => {
      return !systemProps.hasOwnProperty('_loaded');
    }, [systemProps]);

    const addSystemProp = (key: string, value: string) => {
      setSystemProps((prevState) => {
        return { ...prevState, [key]: value };
      });
    };
    const addSystemProps: (values: MapType | any) => void = (values) => {
      setSystemProps((prevState) => {
        return { ...prevState, ...prepareGlobalWebProps(values) };
      });
    };

    useEffect(() => {
      if (!loading && data) {
        addSystemProps(data.fetchWebProperties);
      }
    }, [data, loading]);

    useEffect(() => {
      if (!loadingVersion && dataVersion) {
        addSystemProp('version', dataVersion.fetchVersion);
      }
    }, [dataVersion, loadingVersion]);

    const ff = useCallback(
      (featureFlag: KnownFeatureFlags | undefined) => {
        if (featureFlag === undefined) return false;

        return systemProps?.[featureFlag] === true;
      },
      [systemProps],
    );

    const registerMainNavTabItems = useCallback(
      (tabs: MainNavTabItem[]) => {
        const newVal = tabs.map((tab) => tab.tabGroup);
        setMainTabsGroupArray(newVal);
        setMainNavIndex(findMainNavIndex(initialTabGroup, newVal));
        setMainTabGroup(
          findMainTabGroup(getEntityPathArray(), orgTabsMap, findActiveTab()),
        );
      },
      [setMainTabsGroupArray, initialTabGroup, mainTabsGroupArray, orgTabsMap],
    );

    //listen to location, determine top tab by location
    useEffect(() => {
      const currentTabGroup = findMainTabGroup(
        getEntityPathArray(),
        orgTabsMap,
        findActiveTab(),
      );
      const index = findMainNavIndex(currentTabGroup, mainTabsGroupArray);
      if (index != mainNavIndex) {
        setMainNavIndex(index);
        if (currentTabGroup != mainTabGroup) {
          setMainTabGroup(currentTabGroup);
        }
      }
    }, [orgTabsMap, mainNavIndex, mainTabGroup]);

    const loaded = isLoaded();
    const value = useMemo(
      () => ({
        systemProps,
        mainTabGroup,
        ff,
        paletteControl,
        paletteMode,
        featureFlagsLoading: featureFlagsLoading,
        globalContextLoading: isLoaded(),
        mainNavIndex,
        registerMainNavTabItems,
      }),
      [
        systemProps,
        mainTabGroup,
        ff,
        paletteControl,
        paletteMode,
        featureFlagsLoading,
        loaded,
        mainNavIndex,
        registerMainNavTabItems,
      ],
    );
    return <ctx.Provider value={value} {...props} />;
  }

  return [ctx, Provider] as const;
}
