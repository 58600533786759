import React, { useState } from 'react';

import {
  Box,
  Grid,
  IconButton,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Table as GqlTable, Schema } from '../../../graphql/gen/graphql';
import { TableUpdate } from '../../../schema/events/table.update';

type Props = {
  event: TableUpdate;
  currentSchema: Schema;
  previousSchema: Schema;
  table: GqlTable;
};
export default function TableUpdateSchemaDetailedView({
  event,
  currentSchema,
  previousSchema,
  table,
}: Props) {
  const sizeDelta =
    currentSchema!.fields!.length - previousSchema!.fields!.length;
  const multiple = Math.abs(sizeDelta) > 1;

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={4}>
          <div>Current Schema</div>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="Current Schema">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell align="right">Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentSchema?.fields?.map((field) => (
                  <TableRow
                    key={field?.name + 'current'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {field?.name}
                    </TableCell>
                    <TableCell align="right">{field?.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={4}>
          <div>Previous Schema</div>
          <TableContainer component={Paper}>
            <Table sx={{}} aria-label="Current Schema">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell align="right">Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {previousSchema?.fields?.map((field) => (
                  <TableRow
                    key={field?.name + 'prev'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {field?.name}
                    </TableCell>
                    <TableCell align="right">{field?.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
