import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  BoxProps,
  IconButton,
  Menu,
  Tooltip,
  Typography,
} from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import { SystemProps } from '@mui/system';

interface DashboardMenuProps extends BoxProps {
  dashboardDropdownContent: JSX.Element;
  Icon?: typeof SvgIcon;
  initializeOpen?: boolean;

  buttonSize?: 'small' | 'medium' | 'large';
  titleMenu?: string;
}

export default function DashboardMenu({
  dashboardDropdownContent,
  initializeOpen,
  Icon,
  title,
  buttonSize,
  titleMenu,
  ...props
}: DashboardMenuProps) {
  const myRef = useRef();
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const [initialDone, setInitialDone] = useState<boolean>(false);
  const dropdownOpen = Boolean(anchorEl);

  useEffect(() => {
    if (!initialDone && initializeOpen) {
      setAnchorEl(myRef.current);
    }
  }, [initializeOpen]);
  const handleDropdownClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget.parentNode as Element);
    setInitialDone(true);
  };

  const handleDropdownClose = () => {
    setAnchorEl(undefined);
    setInitialDone(true);
  };

  return (
    <>
      <Box
        {...props}
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 2,
          },
          ...(props?.sx ? [props?.sx as SystemProps] : []),
        ]}
        ref={myRef}
      >
        <Tooltip title={title} placement={'top'} arrow sx={{ display: 'flex' }}>
          <>
            {!titleMenu && (
              <IconButton
                size={buttonSize}
                sx={(theme) => ({
                  padding: '4px',
                  '&:hover': {
                    backgroundColor: theme.palette.sky.one,
                  },
                  '&:active': {
                    backgroundColor: theme.palette.sky.two,
                  },
                  '&:focus': {
                    border: `1px solid ${theme.palette.sky.seven}`,
                  },
                  border: `1px solid ${theme.palette.midnight.two}`,
                })}
                onClick={(event) => handleDropdownClick(event)}
              >
                {!Icon && (
                  <MoreVertIcon
                    sx={(theme) => ({
                      ...(buttonSize === 'small'
                        ? { height: 16, width: 16 }
                        : {}),
                      transform: dropdownOpen
                        ? 'rotate(-90deg)'
                        : 'rotate(0deg)',
                      transition: theme.transitions.create(['transform'], {
                        duration: '.5s',
                      }),
                    })}
                  />
                )}
                {Icon && (
                  <Icon
                    sx={(theme) => ({
                      ...(buttonSize === 'small'
                        ? { height: 16, width: 16 }
                        : {}),
                      transform: dropdownOpen
                        ? 'rotate(-90deg)'
                        : 'rotate(0deg)',
                      transition: theme.transitions.create(['transform'], {
                        duration: '.5s',
                      }),
                    })}
                  />
                )}
              </IconButton>
            )}

            {titleMenu && (
              <Box
                height={'100%'}
                width={'100%'}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                onClick={(event) => handleDropdownClick(event)}
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant={'h6'} sx={{ wordBreak: 'break-word' }}>
                  {titleMenu}
                </Typography>
                <ArrowDropDownIcon
                  sx={(theme) => ({
                    transform: dropdownOpen ? 'rotate(-90deg)' : 'rotate(0deg)',
                    transition: theme.transitions.create(['transform'], {
                      duration: '.5s',
                    }),
                    cursor: 'pointer',
                  })}
                />
              </Box>
            )}
          </>
        </Tooltip>
      </Box>
      <Menu
        id={`data-dashboard-dropdown`}
        anchorEl={anchorEl}
        open={dropdownOpen}
        onClose={handleDropdownClose}
        onClick={handleDropdownClose}
        transitionDuration={500}
        sx={(theme) => ({
          '& .MuiList-root': {
            paddingY: 0,
          },
          '& .MuiPaper-root': {
            border: `1px solid ${theme.palette.midnight.two}`,
            maxWidth: '320px',
          },
        })}
      >
        <Box
          sx={{
            minWidth: '325px',
            maxWidth: '400px',
          }}
        >
          {dashboardDropdownContent}
        </Box>
      </Menu>
    </>
  );
}
