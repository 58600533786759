import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { useModal, create, show } from '@ebay/nice-modal-react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import { styled } from '@mui/system';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';

import { toDocsRoot } from '../../RouteTable';
// @ts-ignore
import { SHOWED_INIT_EXPERIENCE_NAME } from '../../context/auth-context';
import { User } from '../../graphql/gen/graphql';
import { updateOrg } from '../../graphql/organization';
import { currentUser } from '../../graphql/user';
import { Tabutton } from '../Button/Tabutton';
// @ts-ignore
import StatusBars from '../Feedback/StatusBars';
import { orgNameFromDisplayName } from '../Forms/CreateOrganization';
import TextField from '../Forms/TextField';

const BlurryDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  backdropFilter: 'blur(5px)',
}));
interface WelcomeDialogProps {
  user: User;
}
const WelcomeDialog = create(({ user }: WelcomeDialogProps) => {
  const modal = useModal();
  const navigate = useNavigate();
  const [mutationError, setMutationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [updateOrgMutation, { error: orgUpdateMutationError }] =
    useMutation(updateOrg);
  const [synthesizedOrgName, setSynthesizedOrgName] = useState(
    orgNameFromDisplayName(
      user?.loginSession?.loggedInOrg?.displayName || '<your org name>',
    ),
  );

  interface FormValues {
    orgName: string;
  }

  const initialValues: FormValues = {
    orgName: user.loginSession?.loggedInOrg?.displayName || '',
  };

  const handleSubmitForm = useCallback(
    async (values: FormValues) => {
      //we will take what they give use and make that the Organization.displayName
      //we will then convert what they give into a valid urlPath for their vanity "handle" aka: Organization.name
      const orgDisplayName = values.orgName;
      const orgName = orgNameFromDisplayName(orgDisplayName);
      const { errors } = await updateOrgMutation({
        variables: {
          organizationId: user.loginSession?.loggedInOrg?.id,
          orgUpdates: {
            name: orgName,
            displayName: orgDisplayName,
          },
        },
        refetchQueries: [{ query: currentUser }],
        awaitRefetchQueries: true,
        errorPolicy: 'all',
      });
      if (orgUpdateMutationError) {
        setErrorMessage(orgUpdateMutationError.message);
        setMutationError(true);
      }
      if (errors && errors.length) {
        const errormessage =
          errors['0']?.extensions?.response?.body?.error?.message ||
          errors[0].message;

        setErrorMessage(errormessage);
        setMutationError(true);
      } else {
        window.sessionStorage.setItem(SHOWED_INIT_EXPERIENCE_NAME, '0');
        modal.hide();
        navigate('/');
      }
    },
    [updateOrgMutation],
  );

  // @ts-ignore
  return (
    <BlurryDialog
      open={modal.visible}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      sx={{ zIndex: 1600 }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmitForm}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
          <Form>
            <DialogTitle variant={'h1'} sx={{ mt: 4, ml: 2 }}>
              Getting started with Tabular
            </DialogTitle>

            <Box sx={{ ml: 5 }}>
              <Typography variant={`subtitle1`}>{'Organizations'}</Typography>
            </Box>

            <DialogContent sx={{ ml: 2, mt: 2 }}>
              <Grid container spacing={2} alignItems="center" direction="row">
                <Grid item xs={12}>
                  <Typography>
                    Organizations are the topmost resource in the Tabular entity
                    model.
                    <br />
                    Your resources access paths will contain your
                    organization&apos;s name.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component={'div'}>
                    We created an organization for you and gave it a temporary
                    name.
                    <br />
                    You can rename it now or go to
                    <div
                      style={{
                        fontWeight: 'bold',
                        display: 'inline',
                        marginLeft: '2pt',
                        marginRight: '2pt',
                      }}
                    >
                      Organization → Settings
                    </div>
                    to rename it later.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ mt: 5 }}
                spacing={2}
                alignItems="center"
                direction="row"
              >
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name={`orgName`}
                    label={`Organization Name`}
                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.code === 'Enter' && isEmpty(synthesizedOrgName)) {
                        e.preventDefault();
                      } else {
                        setSynthesizedOrgName(
                          orgNameFromDisplayName(values.orgName),
                        );
                      }
                    }}
                  />
                  <Box sx={{ ml: 0 }}>
                    <Typography
                      variant={`body2`}
                    >{`URL: app.tabular.io/${synthesizedOrgName}`}</Typography>
                  </Box>
                </Grid>
              </Grid>
              <StatusBars
                errorDisplay={mutationError}
                setErrorDisplay={setMutationError}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
              />

              <DialogActions>
                <Tabutton
                  type={`submit`}
                  variant="contained"
                  loading={isSubmitting}
                  disabled={isSubmitting || isEmpty(synthesizedOrgName)}
                  onClick={() => {
                    submitForm().then(() => {});
                  }}
                  loadingBtn
                >
                  Get Started
                </Tabutton>
              </DialogActions>

              <Grid
                container
                sx={{
                  mt: 5,
                  mb: 3,
                  borderRadius: 2,
                  backgroundColor: 'sky.half',
                  p: 1,
                  pb: 2,
                }}
                spacing={2}
                alignItems="center"
                direction="row"
              >
                <Grid item xs={1}>
                  <Box display="flex" justifyContent="center">
                    <InfoOutlinedIcon sx={{ color: 'sky.main' }} />
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  {' '}
                  <Typography variant={`subtitle1`}>Did you know?</Typography>
                  <Typography variant={`body1`}>
                    Individual users may be members of multiple organizations.
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {/* @ts-ignore so, external is not a proper property of Link, but it fixes the react router.  Chose not to use anchor for styles reasons*/}
                    <Link
                      target="_blank"
                      href={
                        toDocsRoot() + '/introducing-tabular-organizations.html'
                      }
                      style={{ textDecoration: 'none' }}
                      rel="noopener noreferrer"
                      external
                    >
                      VIEW DOCS
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </BlurryDialog>
  );
});

export default WelcomeDialog;

export function showWelcomeDialog(user: User) {
  return show<boolean, any, WelcomeDialogProps>(WelcomeDialog, { user });
}
