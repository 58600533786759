import React, { useMemo } from 'react';

import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import { Box, Divider, Grid, Popper, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEmpty } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { useTableEditContext } from '../TableEdit/TableEditContext';

import { IcebergContentType, Table } from '../../graphql/gen/graphql';
import { relativeTimeAutoFormat } from '../../utils/time';
import { Tavatar } from '../Avatar/Tavatar';
import { CustomIcon } from '../Icons/CustomIcon';
import { DescriptionPopover } from '../Popovers/DescriptionPopover';
import { LastUpdateModifiedByPopover } from '../Popovers/LastUpdateModifiedByPopover';
import { ListPopover } from '../Popovers/ListPopover';
import { EditTableMode } from '../TableEdit/TableEdit';
import {
  findSourceFieldCandidateById,
  getSchemaModelFromMetadata,
} from '../TableEdit/TableEditHelpers';

type TableDetailsProps = {
  table?: Table;
};

export default function TableDetails({ table }: TableDetailsProps) {
  const mobile = useMediaQuery('(max-width: 850px)');
  const { mode } = useTableEditContext();

  const tableDescription = table
    ? table?.properties?.find((prop) => prop != null && prop.key === 'comment')
        ?.value
    : 'No description';

  const isView = useMemo(
    () => table?.contentType === IcebergContentType.View,
    [table],
  );

  // Helper function to retrieve a schema field based on its id
  const getFieldById = (id: string) =>
    table?.metadata?.current_schema?.fields?.find(
      (f: any) =>
        f.id === id || f?.type?.fields?.find((nested: any) => nested.id === id),
    );
  const { deserializedSchemaModel } = useMemo(
    () => getSchemaModelFromMetadata(table?.metadata!),
    [table?.metadata],
  );

  /*
   * If the default partition_spec has empty fields then we consider the table as "not partitioned"
   * If the transform is "identity" we just display the column name
   * All other transform functions we display as "transform(column)"
   */
  const partitions = table?.metadata?.default_partition_spec?.fields?.map(
    (field: any) => {
      const sourceField = getFieldById(field.source_id);
      const sourceFieldName = sourceField?.name;
      const sourceFieldId = sourceField?.id;
      const partitionDisplay =
        field.transform?.toLowerCase() === 'identity'
          ? sourceFieldName
          : findSourceFieldCandidateById(
              deserializedSchemaModel,
              deserializedSchemaModel['top'],
              null,
              field.source_id,
            )?.name;

      return { name: partitionDisplay, id: sourceFieldId };
    },
  );

  /*
   * If the default sort order has empty fields then we consider the table as "not sorted"
   * If the transform is "identity" we just display the column name
   * All other transform functions we display as "transform(column)"
   */
  const sortOrder = table?.metadata?.default_sort_order?.fields?.map(
    (field: any) => {
      const sourceField = getFieldById(field.source_id);
      const sourceFieldId = sourceField?.id;
      const formattedName = findSourceFieldCandidateById(
        deserializedSchemaModel,
        deserializedSchemaModel['top'],
        null,
        field.source_id,
      )?.name;
      const sortOrderDisplay =
        field.transform?.toLowerCase() === 'identity'
          ? `${formattedName} ${field.direction} ${field.null_order}`
          : `${field.transform}(${formattedName}) ${field.direction} ${field.null_order}`;
      return { name: sortOrderDisplay, id: sourceFieldId };
    },
  );

  return (
    <Grid
      alignItems={'center'}
      container
      justifyContent={'space-between'}
      width={'100%'}
      flexWrap={'nowrap'}
      height={'32px'}
    >
      <Grid
        item
        display={'inline-flex'}
        alignItems={'center'}
        width={'100%'}
        minWidth={'150px'}
        height={'32px'}
      >
        <Grid
          item
          maxWidth={!tableDescription ? '10%' : '80%'}
          minWidth={'100px'}
        >
          <PopupState variant="popper">
            {(popupState) => (
              <Box
                sx={{
                  display: 'inline-block',
                  width: 1,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                {...bindHover(popupState)}
              >
                <Typography variant="helperText" color={'midnight.nine'}>
                  {tableDescription ? tableDescription : 'No description'}
                </Typography>
                <Popper
                  {...bindPopper(popupState)}
                  placement={`right-start`}
                  sx={{ zIndex: 20000 }}
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom'],
                      },
                    },
                  ]}
                >
                  <DescriptionPopover
                    description={
                      tableDescription ? tableDescription : 'No description'
                    }
                  />
                </Popper>
              </Box>
            )}
          </PopupState>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
        {!isView && (
          <>
            <Grid
              item
              minWidth={mobile ? '30px' : '130px'}
              maxWidth={'10%'}
              marginRight={1}
            >
              <PopupState variant="popper">
                {(popupState) => (
                  <Box {...bindHover(popupState)}>
                    <Typography
                      variant="helperTextLink"
                      color={'midnight.nine'}
                      display="inline-flex"
                      alignItems="center"
                    >
                      {!mobile &&
                        `${
                          isEmpty(partitions)
                            ? 'Not partitioned'
                            : 'Partitioned by'
                        }`}

                      <CustomIcon
                        src="/assets/icon/partition-icon.svg"
                        sx={(theme) => ({ ml: theme.spacing(1) })}
                      />
                    </Typography>
                    <Box
                      sx={{
                        width: 1,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {!mobile &&
                        partitions?.map((partition: any, index: number) => (
                          <Typography
                            key={`partition-${index}`}
                            variant={`helperText`}
                            color={'midnight.seven'}
                          >
                            {`${index > 0 ? ',' : ''}`}
                            {partition.name}
                          </Typography>
                        ))}
                    </Box>

                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      sx={{ zIndex: 20000 }}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <ListPopover
                        title="Partitioned by:"
                        listItems={
                          partitions?.map(
                            (eachPartition) => eachPartition.name ?? '',
                          ) ?? []
                        }
                      />
                    </Popper>
                  </Box>
                )}
              </PopupState>
            </Grid>
            <Grid item minWidth={mobile ? '30px' : '130px'} maxWidth={'10%'}>
              <PopupState variant="popper">
                {(popupState) => (
                  <Box {...bindHover(popupState)}>
                    <Typography
                      variant="helperTextLink"
                      color={'midnight.nine'}
                      display="inline-flex"
                      alignItems="center"
                    >
                      {!mobile &&
                        `${isEmpty(sortOrder) ? 'Not ordered' : 'Ordered by'}`}

                      <SortOutlinedIcon
                        fontSize="small"
                        sx={{ marginLeft: 1 }}
                      />
                    </Typography>
                    <Box
                      sx={{
                        width: 1,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {!mobile &&
                        sortOrder?.map((orderItem: any, index: number) => (
                          <Typography
                            key={`order-${index}`}
                            variant={`helperText`}
                            color={'midnight.seven'}
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            {`${index > 0 ? ',' : ''}`}
                            {orderItem.name}
                          </Typography>
                        ))}
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={'right-start'}
                      sx={{ zIndex: 20000 }}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <ListPopover
                        title="Ordered by:"
                        listItems={
                          sortOrder?.map((eachOrder) => eachOrder.name ?? '') ??
                          []
                        }
                      />
                    </Popper>
                  </Box>
                )}
              </PopupState>
            </Grid>
          </>
        )}
      </Grid>

      {mode !== EditTableMode.REGISTER && (
        <Grid
          item
          display={'inline-flex'}
          maxWidth={'22%'}
          height={'32px'}
          minWidth={mobile ? '60px' : '350px'}
        >
          <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
          <Grid
            item
            display={`flex`}
            alignItems={'center'}
            flexDirection={`row`}
            maxWidth={'10%'}
            minWidth={mobile ? '30px' : '175px'}
          >
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box {...bindHover(popupState)}>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      {!mobile && (
                        <Typography variant="helperText" sx={{ mr: 0.5 }}>
                          Updated{' '}
                          {table?.lastModified
                            ? relativeTimeAutoFormat(table.lastModified)
                            : '--'}
                        </Typography>
                      )}
                      <Tavatar
                        avatarProps={{ sx: { height: 24, width: 24 } }}
                        profilePic={
                          table?.lastModifiedByMembership?.properties?.[
                            `picture.url`
                          ]
                        }
                        tabularService={
                          table?.lastModifiedByMembership?.properties === null
                        }
                        displayName={
                          table?.lastModifiedByMembership?.displayName || ''
                        }
                      />
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      {table && (
                        <LastUpdateModifiedByPopover
                          modifiedBy={table.lastModifiedBy}
                          modifiedDate={table.lastModified}
                        />
                      )}
                    </Popper>
                  </Box>
                </>
              )}
            </PopupState>
          </Grid>
          <Grid
            item
            display={`flex`}
            alignItems={'center'}
            flexDirection={`row`}
            maxWidth={'10%'}
            minWidth={mobile ? '30px' : '175px'}
          >
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box {...bindHover(popupState)}>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      {!mobile && (
                        <Typography variant="helperText" sx={{ mr: 0.5 }}>
                          Created{' '}
                          {table?.createdAt
                            ? relativeTimeAutoFormat(table.createdAt)
                            : '--'}
                        </Typography>
                      )}
                      <Tavatar
                        avatarProps={{ sx: { height: 24, width: 24 } }}
                        profilePic={
                          table?.createdByMembership?.properties?.[
                            `picture.url`
                          ]
                        }
                        tabularService={
                          table?.createdByMembership?.properties === null
                        }
                        displayName={
                          table?.createdByMembership?.displayName || ''
                        }
                      />
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      {table && (
                        <LastUpdateModifiedByPopover
                          modifiedBy={table?.createdBy}
                          modifiedDate={table?.createdAt}
                          created
                        />
                      )}
                    </Popper>
                  </Box>
                </>
              )}
            </PopupState>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
