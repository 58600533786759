import React, { useEffect } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import { useUserAgent } from 'react-ua';

import { Grid, Stack, StackProps } from '@mui/material';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Warehouse } from '../../../graphql/gen/graphql';

interface TrinoClientCmdTemplateProps extends StackProps {
  warehouse: Warehouse;
  onCommandFormed?: (cmd: string) => void;
}
export const TrinoClientCmdTemplate = ({
  warehouse,
  onCommandFormed,
  ...props
}: TrinoClientCmdTemplateProps): JSX.Element => {
  const ua = useUserAgent();

  const lineContinuationChar =
    ua.os.name === 'Windows' ? '`' : ua.os.name === 'Mac OS' ? '\\' : '\\';
  const shouldQuoteChar = /\s/g.test(warehouse?.name) ? '"' : ''; //look for gross warehouse names

  const cmdTemplate = `docker exec -i -t trino-tabular trino --catalog ${shouldQuoteChar}${warehouse.name.toLowerCase()}${shouldQuoteChar}`;
  useEffect(() => {
    if (onCommandFormed) {
      onCommandFormed(cmdTemplate);
    }
  }, [cmdTemplate]);

  return (
    <Stack direction="column" {...props}>
      <Grid container width={1} alignItems={'center'}>
        <Grid
          item
          xs={12}
          sx={{
            pre: {
              m: 0,
              height: 1,
            },
          }}
        >
          {' '}
          <SyntaxHighlighter
            language="bash"
            style={{ ...dracula, margin: 0 as React.CSSProperties }}
            showLineNumbers={false}
          >
            {cmdTemplate}
          </SyntaxHighlighter>
        </Grid>
      </Grid>
    </Stack>
  );
};
