import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';

import {
  Property,
  Table,
  TableMetadata,
  Warehouse,
} from '../../graphql/gen/graphql';
import { readTableMetaDataMutation } from '../../graphql/table';
import { getLogger } from '../../utils/logging';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import HeadsUpDisplay from '../Layouts/HeadsUpDisplay';
import ChooseTableSource, {
  SourceSelection,
  UserTableIntent,
} from '../TableEdit/ChooseTableSource';
import TableEdit, { EditTableMode } from '../TableEdit/TableEdit';

const logger = getLogger(
  'components.Modals.RegisterTableDialog' /*FUTURE import.meta.url ?*/,
);
interface MetadataGeneratedEvent {
  metadata: TableMetadata;
  tableName: string;
  location?: string;
  properties?: [Property];
}
interface RegisterTableDialogProps {
  organizationId: string;
  warehouse: Warehouse;
  database: string;
  breadcrumbs: any;
}
const RegisterTableDialog = create(
  ({
    organizationId,
    warehouse,
    database,
    breadcrumbs,
  }: RegisterTableDialogProps) => {
    const modal = useModal();
    const warehouseId = warehouse.id;
    const [creationMetadata, setCreationMetadata] = useState<
      MetadataGeneratedEvent | undefined
    >(undefined);
    const [readTableMetaData, { loading }] = useMutation(
      readTableMetaDataMutation,
      {},
    );
    const [error, setError] = useState<string | undefined>(undefined);
    const [warningAccepted, setWarningAccepted] = useState(false);
    return (
      <>
        {!warningAccepted && (
          <Dialog
            open={modal.visible}
            maxWidth={'xs'}
            fullWidth
            scroll={'paper'}
            onClose={(event, reason) => {
              if (
                (reason && reason == 'backdropClick') ||
                (reason && reason == 'escapeKeyDown')
              )
                return;
              modal.hide();
            }}
            TransitionProps={{
              onExited: () => modal.remove(),
            }}
          >
            <Box display={'flex'} justifyContent={'flex-end'} sx={{ p: 1 }}>
              <IconButton onClick={modal.hide}>
                <CloseIcon
                  fontSize={'small'}
                  sx={{ color: 'midnight.six', pointer: 'cursor' }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                pb: 4,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DialogTitle
                variant={'h1'}
                sx={{ color: 'brandBlue.main', pb: 1 }}
              >
                Register a table
              </DialogTitle>
              <Typography
                variant={'h6'}
                align={'center'}
                sx={{ color: 'brandBlue.main', pb: 4 }}
              >
                Migrate an existing Iceberg table to your Tabular catalog.
              </Typography>
              <Typography align={'center'} sx={{ color: 'midnight.seven' }}>
                Before proceeding, unregister the existing Iceberg table from
                the source catalog
              </Typography>
              <Divider
                sx={{ width: '70%', '& span': { color: 'midnight.seven' } }}
              >
                Or
              </Divider>
              <Typography
                align={'center'}
                sx={{ color: 'midnight.seven', pb: 4 }}
              >
                Ensure there is no write activity.
              </Typography>
              <Typography variant={'h6'} align={'center'}>
                Please acknowledge the following warning.
              </Typography>
              <CardContent sx={{ boxShadow: 'none' }}>
                <Talert severity={'warning'}>
                  <Typography variant={'helperText'}>
                    It’s important to note that each catalog has a separate
                    pointer to the metadata file, so changes made after
                    registering will diverge.
                  </Typography>
                </Talert>
              </CardContent>
              <Tabutton
                variant="contained"
                color="error"
                onClick={() => setWarningAccepted(true)}
              >
                Acknowledge & Proceed
              </Tabutton>
            </Box>
          </Dialog>
        )}

        {warningAccepted && (
          <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={modal.visible}
            onClose={(event, reason) => {
              if (
                (reason && reason == 'backdropClick') ||
                (reason && reason == 'escapeKeyDown')
              )
                return;
              modal.hide();
            }}
            TransitionProps={{
              onExited: () => modal.remove(),
            }}
            sx={{
              backdropFilter: 'blur(2px) brightness(30%)',
              '& .MuiDialog-paper': { maxWidth: 10000 },
            }}
          >
            <Box
              sx={{ height: '90vh', display: 'flex', flexDirection: 'column' }}
            >
              {breadcrumbs && (
                <Box
                  sx={{ position: 'sticky', top: 0, width: 1, zIndex: 20000 }}
                >
                  <HeadsUpDisplay
                    breadcrumbs={breadcrumbs}
                    readonly={true}
                    sx={{
                      position: 'static',
                      backgroundColor: 'brandBlue.main',
                      color: 'white',
                      minHeight: 56,
                      '& .resource-type': {
                        backgroundColor: 'sky.eight',
                        color: 'primary.contrastText',
                        pointerEvents: 'none',
                      },
                      '& .breadcrumb-wrapper a': {
                        color: 'primary.contrastText',
                      },
                      '& ol li:first-of-type .breadcrumb-wrapper a': {
                        color: 'primary.contrastText',
                        pointerEvents: 'none',
                      },
                    }}
                  />
                  <IconButton
                    aria-label="close"
                    onClick={modal.hide}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                      backgroundColor: 'transparent',
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}

              <Typography variant={'h4'} sx={{ mt: 3, ml: 3, mb: 1 }}>
                Register a table
              </Typography>
              <DialogContent
                sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
              >
                {creationMetadata == undefined && (
                  <ChooseTableSource
                    organizationId={organizationId}
                    warehouseId={warehouseId}
                    database={database}
                    tableName={''}
                    source={'StorageProfileSource'}
                    onCancel={() => {
                      modal.hide();
                    }}
                    intent={UserTableIntent.REGISTER}
                    onSelection={(event: SourceSelection) => {
                      logger.debug(
                        'Got final event! Calling TableMetaData',
                        event.keyPaths,
                      );

                      const promise = new Promise<boolean>(
                        (resolve, reject) => {
                          readTableMetaData({
                            variables: {
                              organizationId,
                              warehouseId,
                              databaseName: database,
                              keyPaths: event.keyPaths,
                              storageProfileId: event.storageProfileId,
                            },
                          })
                            .then((result) => {
                              logger.debug(
                                'completed generation of TableMetaData ',
                                result,
                              );
                              setCreationMetadata({
                                metadata: result.data.readMetaData.metadata,
                                tableName: result.data.readMetaData.tableName,
                                location: `${event.scheme}://${event.bucket}/${event.keyPaths[0]}`,
                                properties: result.data.readMetaData.properties,
                              });
                              resolve(true);
                            })
                            .catch((ex) => {
                              logger.error(
                                'Error while reading TableMetaData!',
                                ex,
                              );
                              reject(ex);
                            });
                        },
                      );
                      return promise;
                    }}
                    regionFilter={[warehouse.region]}
                    storageTypeFilter={[warehouse.storageProfile?.storageType!]}
                    sx={{ flexGrow: 1 }}
                    subTitles={[
                      `Select a file that represents your table.`,
                      `The following selection will be inspected in order to register your table.`,
                    ]}
                    parentIsLoading={!!creationMetadata}
                    parentIsError={!!error}
                  />
                )}

                {creationMetadata != undefined && (
                  <TableEdit
                    warehouseId={warehouseId}
                    database={database}
                    onCancel={modal.hide}
                    onBack={() => {
                      setCreationMetadata(undefined);
                    }}
                    onTableEdited={(context) => {
                      modal.resolve(context.table);
                      modal.hide();
                    }}
                    mode={EditTableMode.REGISTER}
                    newTableName={
                      creationMetadata &&
                      creationMetadata?.tableName &&
                      !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
                        creationMetadata?.tableName,
                      )
                        ? creationMetadata?.tableName.replace('-', '_')
                        : undefined
                    }
                    creationMetadata={creationMetadata?.metadata}
                    metadataLocation={creationMetadata?.location}
                    table={
                      {
                        metadata: creationMetadata?.metadata,
                        properties: creationMetadata?.properties,
                      } as Table
                    }
                  />
                )}
              </DialogContent>
            </Box>
          </Dialog>
        )}
      </>
    );
  },
);

export default function showRegisterTableDialog(
  organizationId: string,
  warehouse: Warehouse,
  database: string,
  breadcrumbs: any,
) {
  return show<Table, any, RegisterTableDialogProps>(RegisterTableDialog, {
    organizationId,
    warehouse,
    database,
    breadcrumbs,
  });
}
