import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { debounce, find, includes, some } from 'lodash';

import { LOGIN_DEST_ORG_ID } from './GlobalPropsContext';

import LoadingBackdrop from '../components/Feedback/LoadingBackdrop';
import { showWelcomeDialog } from '../components/Modals/WelcomeDialog';
import {
  Organization,
  OrgAuthProvider,
  User,
  Warehouse,
} from '../graphql/gen/graphql';
import {
  signupUserWithInvite,
  signupUser,
  logoutUser,
  loginUser,
  currentUser,
  loginUserWithToken,
  loginUserOrg,
  RequestMagicLink,
  SelfInvite,
} from '../graphql/user';
import { getApolloErrorMessage } from '../utils/ApolloUtils';
import { getLogger } from '../utils/logging';
import { usePosthogClient } from './posthog';

const logger = getLogger('context.AuthContext' /*FUTURE import.meta.url ?*/);
export const noAccountErrorMessage =
  'It appears that you do not have an account with Tabular.  Please contact Tabular support if you feel this is in error.';
interface AuthContextProps {
  user: any;
  login: any;
  loginWithToken: any;
  requestMagicLink: any;
  reset: any;
  logout: any;
  signupWithInvite: any;
  signup: any;
  selfInvite: any;
  setOrg: any;
  loading: boolean;
  lastError?: string;
  clearLastError: () => void;
}

const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  login: null,
  loginWithToken: null,
  requestMagicLink: null,
  reset: null,
  logout: null,
  signupWithInvite: null,
  signup: null,
  selfInvite: null,
  setOrg: null,
  loading: true,
  lastError: undefined,
  clearLastError: () => {},
});
AuthContext.displayName = 'AuthContext';

const orgToMapEntry = (org: Organization) => [
  org.name?.toLowerCase(),
  {
    ...org,
    warehouseMap: new Map(
      org.warehouses?.map((warehouse) => [
        warehouse!.name?.toLowerCase(),
        warehouse,
      ]),
    ),
  },
];

function AuthProvider(props: any) {
  // Get the user data from the api which will also validate the current auth cookie

  const [currentOrg, setCurrentOrg] = useState(null);
  const [orgToLoginTo, setOrgToLoginTo] = useState(null);
  const { client, loading, data, refetch } = useQuery(currentUser, {
    // onError: (error) => {
    //   logger.debug('Error getting user info.', error);
    // },
    // must set this in order for refetch call status to also flow into the loading prop
    // https://www.apollographql.com/docs/react/data/queries/#inspecting-loading-states
    notifyOnNetworkStatusChange: true,
  });

  const [loginMutation] = useMutation(loginUser);
  const [loginWithTokenMutation] = useMutation(loginUserWithToken);
  const [requestMagicLinkMutation] = useMutation(RequestMagicLink);
  const [selfInviteMutation] = useMutation(SelfInvite);
  const [loginOrgMutation] = useMutation(loginUserOrg);
  const [logoutMutation] = useMutation(logoutUser);
  const [signupWithInviteMutation] = useMutation(signupUserWithInvite);
  const [signupUserMutation] = useMutation(signupUser);
  const [lastError, setLastError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const posthogClient = usePosthogClient();

  function recordUserInPostHog({
    user,
    isSecurityAdmin,
    isOrgAdmin,
  }: {
    user: User;
    isSecurityAdmin: boolean;
    isOrgAdmin: boolean;
  }) {
    if (posthogClient && user?.loginSession?.membership?.id) {
      logger.debug(
        `identifying posthog member ${user.loginSession.membership.id}`,
      );

      posthogClient.identify(
        user.loginSession.membership.id,
        {
          lastActive: new Date().toISOString(),
          isSecurityAdmin,
          isOrgAdmin,
          organization: user?.loginSession?.loggedInOrg?.name,
        },
        {
          memberId: user?.loginSession?.membership?.id,
          userId: user?.id,

          organizationId: user?.loginSession?.loggedInOrg?.id,
        },
      );
      posthogClient.group(
        'organization',
        user?.loginSession?.loggedInOrg?.id ?? 'unknown',
        {
          organizationId: user?.loginSession?.loggedInOrg?.id,
          name: user?.loginSession?.loggedInOrg?.name,
          lastActiveUser: new Date().toISOString(),
        },
      );
    }
  }
  function recordLastError(message: string, shouldThrow: boolean) {
    logger.error(message);
    setLastError(message);
    if (shouldThrow) {
      throw new Error(message);
    }
  }
  const clearLastError = useCallback(() => {
    logger.error('Clearing last error');
    setLastError(undefined);
  }, []);

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      clearLastError();
      const { errors } = await loginMutation({
        variables: {
          email: email,
          password: password,
        },
        errorPolicy: 'all',
      });
      if (errors && errors.length) {
        // TODO: Inspect errors and return more detailed response
        recordLastError('Login failed', true);
      }
      window.sessionStorage.setItem(SHOWED_INIT_EXPERIENCE_NAME, '0');
      // Refetch the current user now that we have a valid token
      await refetch();
      return true;
    },
    [loginMutation, refetch],
  );

  const loginOrg = useCallback(async ({ orgId }: { orgId: string }) => {
    clearLastError();
    const { data: userData, errors } = await loginOrgMutation({
      variables: {
        orgId: orgId,
      },
      errorPolicy: 'all',
    });
    if (errors && errors.length) {
      // TODO: Inspect errors and return more detailed response
      recordLastError('Login Org failed', true);
    }

    return userData.loginOrg;
  }, []);

  const loginWithToken = useCallback(
    async (idToken: string, orgId: string) => {
      clearLastError();
      sessionStorage.removeItem(LOGIN_DEST_ORG_ID);
      const { errors } = await loginWithTokenMutation({
        variables: {
          idToken: idToken,
          orgId: orgId,
        },
        errorPolicy: 'all',
      });
      if (errors && errors.length) {
        // TODO: Inspect errors and return more detailed response
        if (
          errors['0'].extensions?.response?.body?.error_description?.startsWith(
            'Unknown user',
          ) ||
          errors['0'].extensions?.response?.body?.error?.message?.startsWith(
            'User has no orgs with Okta auth enabled',
          )
        ) {
          recordLastError(noAccountErrorMessage, true);
        }
        recordLastError('Login failed', true);
      }
      window.sessionStorage.setItem(SHOWED_INIT_EXPERIENCE_NAME, '0');
      // Refetch the current user now that we have a valid token
      await refetch();
      navigate('/');
      return true;
    },
    [loginWithTokenMutation, refetch],
  );

  const signupWithInvite = useCallback(
    async ({
      code,
      idToken,
      password,
      authType,
      email,
      organizationId,
    }: {
      code: string;
      idToken: string;
      password: string;
      authType: string;
      email: string;
      organizationId?: string;
    }) => {
      clearLastError();
      // ensure we clear any old auth cookie, which could
      // otherwise cause an auth failure during signup
      await logoutMutation();

      const signupResult = await signupWithInviteMutation({
        variables: {
          request: {
            authType: authType,
            inviteCode: code,
            password: password,
            idToken: idToken,
          },
        },
        errorPolicy: 'all',
      });
      if (signupResult.errors && signupResult.errors.length) {
        // TODO: Inspect errors and return more detailed response
        recordLastError(
          `Signup failed: ${
            //@ts-ignore
            getApolloErrorMessage(signupResult.errors)
          }`,
          true,
        );
      } else {
        if (authType === OrgAuthProvider.Okta) {
          // signup was successful and they use okta, so lets punt them to okta!
          return navigate(
            '/login/okta?email=' + signupResult.data.signupWithInvite.email,
          );
        } else if (
          authType === OrgAuthProvider.Google ||
          authType === OrgAuthProvider.MagicLink
        ) {
          await loginWithTokenMutation({
            variables: {
              idToken: idToken,
              orgId: signupResult.data.orgId,
            },
            errorPolicy: 'all',
          });
          // signup was successful, redirect if password is allowed
          refetch().then(({ data, errors }) => {
            navigate('/');
            //if invite was for a new org then show welcome
            if (!organizationId) {
              setTimeout(() => {
                showWelcomeDialog(data.currentUser);
              }, 500);
            }
          });
        } else if (authType === OrgAuthProvider.Password) {
          await loginMutation({
            variables: {
              email: email,
              password: password,
            },
            errorPolicy: 'all',
          });
          // signup was successful, redirect if password is allowed
          await refetch();
          navigate('/');
        }
      }
    },
    [logoutMutation, signupWithInviteMutation, login],
  );

  const reset = useCallback(async () => {
    clearLastError();
    // Refetch the current user now that we have a valid token
    await refetch().then(() => navigate('/'));
  }, [refetch, navigate]);

  const logout = useCallback(async () => {
    logger.debug('logging out!');
    clearLastError();
    setCurrentOrg(null);

    // call the logout mutation which will expire the token cookie
    await logoutMutation();

    // clear the apollo client store to make sure we don't leak any data across logins
    client.stop();
    await client.clearStore();

    // clear the posthog user identity and group(s)
    if (posthogClient != null) {
      posthogClient.reset();
    }

    await localStorage.removeItem('email');
    await sessionStorage.removeItem(SHOWED_INIT_EXPERIENCE_NAME);
    // refetch the user (this should result in a 401 and cause the user context to be null)

    refetch()
      .then(() => {
        logger.debug('and then with refetch of user during logout...');
      })
      .catch((e) => {
        logger.debug('Exception with refetch of user during logout...', e);
      })
      .finally(() => {
        logger.debug('finally done with refetch of user during logout...');
        // send the user back to the login page
        setTimeout(() => {
          window.location.reload();
        }, 100); //reload, mostly for posthog, but could be nice to have some fresh memory
      });
  }, [logoutMutation, client, refetch, navigate]);

  const requestMagicLink = useCallback(
    async (email: string) => {
      clearLastError();
      logger.debug('Requesting magic link out!');

      setCurrentOrg(null);

      // call the logout mutation which will expire the token cookie
      await logoutMutation();

      // clear the apollo client store to make sure we don't leak any data across logins
      client.stop();
      await client.clearStore();

      // clear the posthog user identity and group(s)
      if (posthogClient != null) {
        posthogClient.reset();
      }

      await localStorage.removeItem('email');
      await sessionStorage.removeItem(SHOWED_INIT_EXPERIENCE_NAME);

      await requestMagicLinkMutation({ variables: { email } });

      // send the user back to the login page
      //window.location.reload();
    },
    [logoutMutation, client, refetch, navigate],
  );

  const user = useMemo(() => {
    if (data?.currentUser) {
      const currentUser = data.currentUser;

      if (data?.currentUser.loginSession === null) {
        console.log('No session.');
        navigate('/logout');
        return;
      }

      const orgMap = new Map(currentUser.organizations?.map(orgToMapEntry));
      const getOrganization = (organizationName: string): any => {
        const org = orgMap.get(organizationName.toLowerCase());
        //URL is either being hacked by user (incorrectly), or we are potentially renaming the org.  Go home and come back
        if (org == null) {
          console.log('Unknown org requested, go home.');
          navigate('/');
        } else {
          return org;
        }
      };

      const getWarehouse = (
        organizationName: string,
        warehouseName: string,
      ): Warehouse =>
        getOrganization(organizationName)?.warehouseMap.get(
          warehouseName.toLowerCase(),
        );

      const hasSystemRole = (organizationName: string, role: string) =>
        some(
          getOrganization(organizationName)?.systemRoles,
          ({ name }) => name === role,
        );

      const hasPrivilege = (privilege: any, resource: any) =>
        includes(resource?.privileges, privilege);

      const isRoleAdmin = (role: string) => {
        const roleMember = find(
          //@ts-ignore
          role?.users,
          (member) => member.id === currentUser.id,
        );
        if (!roleMember) {
          return false;
        }
        return roleMember.isAdmin;
      };

      const isOrganizationAdmin = (organizationName: string) =>
        hasSystemRole(organizationName, 'ORG_ADMIN');
      const isSecurityAdmin = (organizationName: string) =>
        hasSystemRole(organizationName, 'SECURITY_ADMIN');

      recordUserInPostHog({
        user: data.currentUser,
        isSecurityAdmin: isSecurityAdmin(
          data.currentUser?.loginSession?.loggedInOrg?.name!,
        ),
        isOrgAdmin: isOrganizationAdmin(
          data.currentUser?.loginSession?.loggedInOrg?.name!,
        ),
      });

      return {
        ...data.currentUser,
        getOrganization,
        getWarehouse,
        hasPrivilege,
        isRoleAdmin,
        isOrganizationAdmin,
        isSecurityAdmin,
      };
    }
    return null;
  }, [data]);

  const signup = useCallback(
    async ({ idToken }: { idToken: string }) => {
      clearLastError();
      // ensure we clear any old auth cookie, which could
      // otherwise cause an auth failure during signup
      await logoutMutation();

      const signupResult = await signupUserMutation({
        variables: {
          idToken: idToken,
        },
        errorPolicy: 'all',
      });
      if (signupResult.errors && signupResult.errors.length) {
        // TODO: Inspect errors and return more detailed response
        recordLastError(
          `Signup failed: ${
            //@ts-ignore
            getApolloErrorMessage(signupResult.errors)
          }`,
          true,
        );
      } else {
        await loginWithTokenMutation({
          variables: {
            idToken: idToken,
            orgId: signupResult.data.orgId,
          },
          errorPolicy: 'all',
        });
        // signup was successful, redirect if password is allowed
        refetch().then(({ data, errors }) => {
          navigate('/');
          setTimeout(() => {
            showWelcomeDialog(data.currentUser);
          }, 500);
        });
      }
    },
    [logoutMutation, signupUserMutation, loginWithTokenMutation, login],
  );

  const selfInvite = useCallback(
    async ({ email, displayName }: { email: string; displayName: string }) => {
      const signupResult = await selfInviteMutation({
        variables: { email, displayName },
        errorPolicy: 'all',
      });
      if (signupResult.errors && signupResult.errors.length) {
        // TODO: Inspect errors and return more detailed response
        recordLastError(
          `Signup failed: ${
            //@ts-ignore
            getApolloErrorMessage(signupResult.errors)
          }`,
          true,
        );
      }
      return signupResult;
    },
    [selfInviteMutation],
  );

  useEffect(() => {
    if (orgToLoginTo !== null) {
      setOrgToLoginTo(null);

      let orgId = user?.getOrganization(orgToLoginTo)?.id;
      if (user?.loginSession.loggedInOrg.name !== orgToLoginTo) {
        logger.debug(
          `Current logged in org is: ${user?.loginSession.loggedInOrg.name} -> logging into org ${orgToLoginTo} orgId: ${orgId}`,
        );

        if (orgId) {
          loginOrg({ orgId: orgId })
            .then((user) => {
              logger.debug(
                `Current logged in org is: ${user?.loginSession.loggedInOrg.name} login completed for org ${orgToLoginTo} with id ${orgId}`,
              );
            })
            .catch((e) => {
              console.error('error logging into org', e);
            });
        } else {
          console.warn('Unknown org context.');
        }
      } else {
        logger.debug(
          `already logged into org ${orgToLoginTo} with id ${orgId} ignoring`,
        );
      }
    }

    return () => {
      logger.debug('Cleaning up! last set');
    };
  }, [orgToLoginTo, user]);

  const setOrg = useCallback(
    async (org: string) => {
      if (org != null && org !== currentOrg) {
        //@ts-ignore
        setCurrentOrg(org);
        //@ts-ignore
        setOrgToLoginTo(org);
      } else {
        return () => {
          // console.log('No action Cleaning up! ');
        };
      }
    },
    [currentOrg, user],
  );

  const value = useMemo(
    () => ({
      user,
      login,
      loginWithToken,
      requestMagicLink,
      reset,
      logout,
      signupWithInvite,
      signup,
      selfInvite,
      setOrg,
      loading,
      lastError,
      clearLastError,
    }),
    [
      user,
      login,
      loginWithToken,
      requestMagicLink,
      reset,
      logout,
      signupWithInvite,
      signup,
      selfInvite,
      setOrg,
      loading,
      lastError,
      clearLastError,
    ],
  );

  if (loading) {
    return <LoadingBackdrop />;
  }

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
export const SHOWED_INIT_EXPERIENCE_NAME = 'showedInitialExperience';
