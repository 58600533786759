import React from 'react';

import Box from '@mui/material/Box';
import { find, includes } from 'lodash';

import {
  Authorization,
  InFlightCascadeTaskResult,
  Role,
} from '../../graphql/gen/graphql';
import { Tacard } from '../Card/Tacard';
import RoleDetails from './RoleDetails';
import RolePrivileges from './RolePrivileges';
import { AuthWithGrant } from './common';

type RoleCardProps = {
  organizationName: string;
  authorizations: Authorization[];
  resourceName: string;
  roleDetails: Role;
  accessType: string;
  grantingNewAccess?: boolean;
  handleApplyChanges?: any;
  showRevokeAccess: boolean;
  onRevokeAccess?: any;
  isResourceAdmin: boolean;
  currentGrants: AuthWithGrant[];
  inProgressTasks?: InFlightCascadeTaskResult[];
  user: any;
  securityAdminCard?: boolean;
  loading?: boolean;
};

export default function RoleCard({
  organizationName,
  authorizations,
  resourceName,
  roleDetails,
  accessType,
  grantingNewAccess = false,
  handleApplyChanges,
  showRevokeAccess = false,
  onRevokeAccess = () => {},
  isResourceAdmin,
  currentGrants,
  user,
  securityAdminCard = false,
  inProgressTasks = [],
  loading,
}: RoleCardProps) {
  const isSecurityAdmin = user.isSecurityAdmin(organizationName);
  const currentlyInFlight = find(inProgressTasks, (task) =>
    includes(task.subjectIds, roleDetails.id),
  );
  return (
    <Box
      sx={{
        display: 'flex',
        mb: 5,
      }}
    >
      <Tacard cardGrid marginRight={'0'}>
        {roleDetails && (
          <RoleDetails
            organizationName={organizationName || ''}
            roleDetails={roleDetails || {}}
            showRevokeAccess={showRevokeAccess}
            onRevokeAccess={onRevokeAccess}
            resourceName={resourceName || ''}
            isResourceAdmin={isResourceAdmin}
            currentUserEmail={
              user?.loginSession?.membership?.email || user?.email
            }
            accessType={accessType}
          />
        )}
        <RolePrivileges
          privileges={authorizations}
          accessType={accessType}
          roleName={roleDetails?.displayName || ''}
          selectedRoleId={roleDetails?.id}
          grantingNewAccess={grantingNewAccess}
          handleApplyChanges={handleApplyChanges}
          isResourceAdmin={isResourceAdmin}
          currentGrants={currentGrants}
          currentlyInFlight={currentlyInFlight}
          isSecurityAdmin={isSecurityAdmin}
          securityAdminCard={securityAdminCard}
        />
      </Tacard>
    </Box>
  );
}
