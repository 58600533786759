import React from 'react';

import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Tacard } from '../Card/Tacard';

type DatagridCardItem = {
  item: JSX.Element;
};
type DatagridCardsProps = {
  cards: DatagridCardItem[];
};

export default function DatagridCards({ cards }: DatagridCardsProps) {
  const mobile = useMediaQuery('(max-width: 600px)');

  return (
    <Grid container justifyItems={'center'} wrap={'wrap'} my={2} spacing={1}>
      {cards.map((detail: DatagridCardItem, index: number) => {
        return (
          <Grid
            item
            key={`dashboard-item-${index}`}
            flexGrow={1}
            maxWidth={mobile ? '100%' : '50%'}
          >
            <Tacard cardGrid marginRight={'0px'}>
              {detail.item}
            </Tacard>
          </Grid>
        );
      })}
    </Grid>
  );
}
