import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {
  Box,
  IconButton,
  Menu,
  SvgIcon,
  SvgIconProps,
  Tooltip,
} from '@mui/material';

import { AddRoleIcon } from './helpers';

type QuickAddMenuProps = {
  initializeOpen?: boolean;
  menuContent: JSX.Element;
  tooltipText: string;
  buttonSize?: SvgIconProps['fontSize'];
  disabled?: boolean;
};
export default function QuickAddMenu({
  initializeOpen,
  menuContent,
  tooltipText,
  buttonSize = 'small',
  disabled = false,
  ...props
}: QuickAddMenuProps) {
  const myRef = useRef();
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const [initialDone, setInitialDone] = useState<boolean>(false);
  const dropdownOpen = Boolean(anchorEl);

  const getIconName = () => {
    switch (tooltipText) {
      case 'Add person':
        return 'PersonAddAlt1Icon';
      case 'Add role':
        return 'SvgIcon';
      case 'Inherit from':
        return 'SvgIcon';
      default:
        return 'AddIcon';
    }
  };
  const IconMapping = { PersonAddAlt1Icon, AddIcon, SvgIcon };
  const IconComponent = IconMapping[getIconName()];

  useEffect(() => {
    if (!initialDone && initializeOpen) {
      setAnchorEl(myRef.current);
    }
  }, [initializeOpen]);
  const handleDropdownClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget.parentNode as Element);
  };

  const handleDropdownClose = () => {
    setAnchorEl(undefined);
    setInitialDone(true);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
        ref={myRef}
        {...props}
      >
        <Tooltip title={tooltipText} placement={'top'} arrow>
          <IconButton
            sx={(theme) => ({
              padding: '4px',
              backgroundColor: 'controlBackground.main',
              '&:hover': {
                backgroundColor: theme.palette.sky.one,
              },
              '&:active': {
                backgroundColor: theme.palette.sky.two,
              },
              '&:focus': {
                border: `1px solid ${theme.palette.sky.seven}`,
              },
              border: `1px solid ${theme.palette.midnight.two}`,
            })}
            onClick={(event) => handleDropdownClick(event)}
            disabled={disabled}
          >
            <IconComponent
              fontSize={buttonSize}
              {...(IconComponent === SvgIcon ? { component: AddRoleIcon } : {})}
              sx={(theme) => ({
                transform: dropdownOpen ? 'rotate(-90deg)' : 'rotate(0deg)',
                transition: theme.transitions.create(['transform'], {
                  duration: '.5s',
                }),
                ...(disabled && IconComponent === SvgIcon
                  ? {
                      '> path, rect': {
                        fill: theme.palette.midnight.three,
                      },
                    }
                  : {}),
              })}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        id={`quick-add-menu`}
        anchorEl={anchorEl}
        open={dropdownOpen}
        onClose={handleDropdownClose}
        onClick={() => {}}
        transitionDuration={500}
        sx={(theme) => ({
          '& .MuiPopover-paper': {
            border: `1px solid ${theme.palette.midnight.two}`,
            width: '250px',
            borderRadius: '8px',
          },
          '& .MuiList-root': {
            pt: 0,
          },
        })}
      >
        {menuContent}
      </Menu>
    </>
  );
}
