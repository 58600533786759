import React from 'react';

import { Box, BoxProps, BreadcrumbsProps } from '@mui/material';

interface SneezeShieldProps extends BoxProps {
  enabled: boolean;
}

export default function SneezShield({
  enabled,
  children,
  ...props
}: SneezeShieldProps) {
  return (
    <Box sx={{ pointerEvents: enabled ? 'none' : '' }} {...props}>
      {children}
    </Box>
  );
}
