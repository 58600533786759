import React, { useCallback, useEffect, useMemo, useState } from 'react';
// @ts-ignore
import { useUserAgent } from 'react-ua';

import { useLazyQuery, useMutation } from '@apollo/client';
import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, DialogContent, DialogTitle, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { Table, TableLoadStatus, Warehouse } from '../../graphql/gen/graphql';
import { loadTableData } from '../../graphql/table';
import { getApolloErrorMessage } from '../../utils/ApolloUtils';
import { getUploadBaseUrl } from '../../utils/api';
import { getLogger } from '../../utils/logging';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import ChooseTableSource, {
  UserTableIntent,
  SourceSelection,
} from '../TableEdit/ChooseTableSource';
import TableLoadStatusView from '../TableLoad/TableLoadStatusView';

const logger = getLogger(
  'components.modals.LoadTableDialog' /*FUTURE import.meta.url ?*/,
);

function getStageName(): string {
  const now = new Date();
  return `${now.getFullYear()}-${
    now.getMonth() + 1
  }-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
}
interface LoadTableDialogProps {
  warehouse: Warehouse;
  organizationId: string;
  databaseName: string;
  table: Table;
  source: string;
}
const LoadTableDialog = create(
  ({
    organizationId,
    warehouse,
    databaseName,
    table,
    source,
  }: LoadTableDialogProps) => {
    const modal = useModal();

    const [error, setError] = React.useState<string | undefined>(undefined);
    const [stageName, setStageName] = useState<string>(`${getStageName()}`);

    const [
      loadTableDataMutation,
      { error: loadTableDataError, reset: loadTableDataReset },
    ] = useMutation(loadTableData);

    const uploadUrl = useMemo(() => {
      return `${getUploadBaseUrl()}/organizations/${organizationId}/warehouses/${
        warehouse.id
      }/namespaces/${databaseName}/tables/${table.name}/stages/${stageName}`;
    }, [organizationId, warehouse, databaseName, table, stageName]);

    const handleOnSelection = useCallback(
      async (selection: SourceSelection) => {
        const promise = new Promise<boolean>((resolve, reject) => {
          loadTableDataMutation({
            variables: {
              organizationId,
              warehouseId: warehouse.id,
              tableReferenceId: table.id,
              request: {
                storageProfileId: selection.storageProfileId,
                prefixes: selection.keyPaths,
                region: selection.region,
                tableLoadMode: selection.tableLoadMode,
                fileLoaderConfig: {
                  format: selection.fileFormat,
                  delim:
                    selection.delimiter === '\\t' ? '\t' : selection.delimiter,
                  csvEscape:
                    selection.escape === '\\' ? '\\' : selection.escape,
                  csvDateFormat: selection.dateFormat,
                  csvTimestampFormat: selection.timestampFormat,
                  csvTimestampFormatNtz: selection.timestampNTZFormat,
                  hasHeader: selection.hasHeader,
                  fileExcludeGlobFilter: selection.fileExcludeGlobFilter,
                },
              },
            },
          })
            .then((result) => {
              resolve(true);
              modal.resolve(result.data.loadTableData);
              modal.hide();
            })
            .catch((ex) => {
              logger.error('problem submitting form', ex);
              setError(getApolloErrorMessage(ex));
              reject(ex);
            });
        });
        return promise;
      },
      [organizationId, warehouse, table],
    );

    return (
      <Dialog
        maxWidth={'xl'}
        fullWidth={true}
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <Box
          sx={{
            width: 1,
            maxHeight: '100vh',
            minHeight: '90vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <DialogTitle>
            <Box>
              <Typography variant={'h1'} sx={{ mb: 1 }}>
                Load table
              </Typography>
              <CloseOutlinedIcon
                onClick={() => {
                  modal.hide();
                }}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  cursor: 'pointer',
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                Close
              </CloseOutlinedIcon>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
          >
            {error ? (
              <Talert
                severity="error"
                sx={{ width: 1 }}
                onClose={() => {
                  setError(undefined);
                }}
              >
                Error:
                {error}
              </Talert>
            ) : null}

            <ChooseTableSource
              sx={{ flexGrow: 1 }}
              organizationId={organizationId}
              warehouseId={warehouse.id}
              database={databaseName}
              tableName={table.name}
              source={source}
              regionFilter={[warehouse.region]}
              storageTypeFilter={[warehouse.storageProfile?.storageType!]}
              onSelection={handleOnSelection}
              intent={UserTableIntent.LOAD}
              sourceProps={{ uploadUrl }}
              onCancel={() => {
                modal.hide();
              }}
              subTitles={
                source == 'FileUploadSource'
                  ? [
                      `Stage files to your warehouse's connected storage for table "${table.name}"`,
                      `The following files will be loaded into your table "${table.name}"`,
                    ]
                  : [
                      `Select compatible, region-local files to load into your table "${table.name}"`,
                      `The following files will be loaded into your table "${table.name}"`,
                    ]
              }
              parentIsError={!!error}
              parentIsLoading={false}
            />
          </DialogContent>
        </Box>
      </Dialog>
    );
  },
);

export function showLoadTableDialog({
  organizationId,
  warehouse,
  databaseName,
  table,
  source,
}: {
  organizationId: string;
  warehouse: Warehouse;
  databaseName: string;
  table: Table;
  source: string;
}) {
  return show<TableLoadStatus, any, LoadTableDialogProps>(LoadTableDialog, {
    organizationId,
    warehouse,
    databaseName,
    table,
    source,
  });
}
