import { styled } from '@mui/material/styles';

export const Shortcut = styled('div')(({ theme }) => {
  return {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    lineHeight: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '16px',
    marginLeft: theme.spacing(0.5),
    backgroundColor: theme.palette.sky.seven,
    padding: theme.spacing(0.5, 1),
    borderRadius: 8,
    color: theme.palette.sky.one,
  };
});
