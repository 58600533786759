import React from 'react';

import { Grid } from '@mui/material';

export default function CardGridLayout({
  cards,
  itemProps,
  noMarginTop,
  ...props
}: {
  cards: any[];
  itemProps?: any;
  noMarginTop?: boolean;
}) {
  return (
    <Grid
      container
      spacing={4}
      {...(noMarginTop ? {} : { marginTop: 1 })}
      {...props}
      marginBottom={4}
    >
      {cards?.map((card, idx) => (
        <Grid key={idx} item xs={12} md={4} {...itemProps}>
          {card}
        </Grid>
      ))}
    </Grid>
  );
}
