import React from 'react';

import { Dialog } from '@mui/material';

import { Role } from '../../../graphql/gen/graphql';
import { IAMRoleMappingForm } from '../Forms/IAMRoleMappingForm';

export function CreateIAMRoleMappingDialog({
  organizationId,
  isOpen,
  onDismiss,
  onCredentialCreated,
  isExternalOption = true,
  preSelectedRoleId,
  modalTitle,
}: {
  organizationId: string;
  isOpen: boolean;
  onDismiss: () => void;
  onCredentialCreated: (credential: Credential) => void;
  isExternalOption: boolean;
  preSelectedRoleId?: string;
  modalTitle?: string;
}) {
  return (
    <Dialog open={isOpen}>
      <IAMRoleMappingForm
        organizationId={organizationId}
        onCredentialCreated={onCredentialCreated}
        onDismiss={onDismiss}
        modal
        preSelectedRoleId={preSelectedRoleId}
        modalTitle={modalTitle}
      />
    </Dialog>
  );
}
