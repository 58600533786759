import React from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { AppBar, Box, Stack, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import { Formik } from 'formik';

import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
// @ts-ignore
import TextField from '../Forms/TextField';
// @ts-ignore
import BaseLayout from '../Layouts/BaseLayout';

export const ContactTabularForm = () => {
  return (
    <BaseLayout>
      <AppBar>
        <Toolbar>
          <img src="/assets/img/tabular.png" alt="tabular" height="40px" />
        </Toolbar>
      </AppBar>
      <Box sx={{ my: 3 }}>
        <Toolbar />
        <Container maxWidth="md">
          <Talert severity="error">
            Tabular is currently available via invite only.
          </Talert>
          <Paper sx={{ mt: 3, backgroundColor: '#f1f8fa', color: '#1e4659' }}>
            <Grid container sx={{ p: 3 }} spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" sx={{ pb: 1 }}>
                  {`Interested in what we're building?`}
                </Typography>
                <Typography>
                  Contact us to get an early preview or explore being a design
                  partner.
                </Typography>
                <Box sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
                  <MailOutlineIcon />
                  <Typography sx={{ pl: 1 }}>
                    Email: contact@tabular.io
                  </Typography>
                </Box>
                <Box sx={{ pb: 2, display: 'flex', alignItems: 'center' }}>
                  <LocationOnIcon />
                  <Typography sx={{ pl: 1 }}>San Jose, California</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                  }}
                  onSubmit={() => {}}
                >
                  <form
                    id="contact-form"
                    method="post"
                    action="https://formspree.io/f/xayalrdz"
                    role="form"
                  >
                    <Stack spacing={2}>
                      <TextField
                        type="text"
                        placeholder="Your Name"
                        name="name"
                        id="name"
                        fullWidth
                      />
                      <TextField
                        type="email"
                        placeholder="Your Email"
                        name="email"
                        id="email"
                        fullWidth
                      />
                      <TextField
                        type="text"
                        placeholder="Subject"
                        name="subject"
                        id="subject"
                        fullWidth
                      />
                      <TextField
                        placeholder="Message"
                        name="message"
                        id="message"
                        fullWidth
                        multiline
                        rows={6}
                      />
                      <Tabutton
                        type="submit"
                        value="Submit"
                        variant="contained"
                      >
                        Submit
                      </Tabutton>
                    </Stack>
                  </form>
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
    </BaseLayout>
  );
};
