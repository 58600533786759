import React from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { toDocsRoot } from '../../../RouteTable';
import { Talert } from '../../Alert/Talert';
import { Tabutton } from '../../Button/Tabutton';
import TextField from '../../Forms/TextField';
import { Tabulink } from '../../Link/Tabulink';

export const SnowflakeInitialize = ({
  handleSnowflakeConnection,
  connectionData,
  warehouseId,
  error,
}: {
  handleSnowflakeConnection: any;
  connectionData: any;
  warehouseId: string;
  error: any;
}) => {
  return (
    <Stack>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSnowflakeConnection}
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          createAccountUrl:
            connectionData?.getSnowflakeConnection.accountUrl || '',
          createSnowflakeRole: `TABULAR_${warehouseId.split('-')[0]}__SVC_ROLE`,
          createSnowflakeUser: `TABULAR_${warehouseId.split('-')[0]}__SVC_USER`,
          createSnowflakeDatabase: null,
        }}
        validationSchema={Yup.object({
          createAccountUrl: Yup.string().trim().required('Required'),
          createSnowflakeUser: Yup.string().trim().required('Required'),
          createSnowflakeRole: Yup.string().trim().required('Required'),
          createSnowflakeDatabase: Yup.string().trim().optional().nullable(),
        })}
      >
        {({ isSubmitting }) => (
          <Box>
            <Talert
              severity={'info'}
              sx={{
                width: 'fit-content',
                size: 'small',
                my: 1,
              }}
            >
              <>
                <Tabulink
                  external
                  href={
                    toDocsRoot() +
                    '/connecting-to-snowflake.html#connecting-and-configuring-tabular-and-snowflake'
                  }
                  variant="helperTextLink"
                  rel="noopener"
                  aria-label="Tabular Documentation"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                  }}
                >
                  Learn more about setting up your Snowflake connection
                  <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
                </Tabulink>
              </>
            </Talert>
            <Typography marginY={2}>
              Establish a Snowflake connection with Tabular in order to sync
              your warehouse.
            </Typography>
            <Form>
              <Typography variant={'helperText'} sx={{ mt: 1 }}>
                {`If you are unsure, your Snowflake URL can be found with a SQL
                command provided in Tabular's documentation.`}
              </Typography>
              <TextField
                name={'createAccountUrl'}
                id={'createAccountUrl'}
                label={`Snowflake account url`}
                type="text"
                sx={{ mt: 1 }}
                helperText={
                  'eg. https://<customer_host>.snowflakecomputing.com'
                }
              />

              <TextField
                name={'createSnowflakeUser'}
                id={'createSnowflakeUser'}
                label={'Snowflake user name'}
                helperText={
                  'This is the name of the user that you will utilize within your snowflake account for syncing.'
                }
              />

              <TextField
                name={'createSnowflakeRole'}
                id={'createSnowflakeRole'}
                label={'Snowflake role name'}
                helperText={
                  'This is the name of the role that you will utilize within your snowflake account for syncing.'
                }
              />
              <TextField
                name={'createSnowflakeDatabase'}
                id={'createSnowflakeDatabase'}
                label={'Snowflake database name (optional)'}
                helperText={
                  'This is the name of database Tabular will create in Snowflake.'
                }
              />
              <Tabutton
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
                sx={{ m: 1 }}
              >
                Initialize snowflake
              </Tabutton>
            </Form>
          </Box>
        )}
      </Formik>
      {error && (
        <Talert severity={'error'} alertTitle={'Error'}>
          {error}
        </Talert>
      )}
    </Stack>
  );
};
