import React, { ChangeEvent } from 'react';

import {
  Typography,
  Switch as MuiSwitch,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useField } from 'formik';

interface SwitchProps {
  switchlabel?: string | JSX.Element;
  sx?: any;
  disabled?: boolean;
  error?: boolean | undefined | string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  edge?: false | 'end' | 'start' | undefined;
  color?:
    | 'primary'
    | 'success'
    | 'error'
    | 'secondary'
    | 'info'
    | 'warning'
    | 'default'
    | undefined;
  tabIndex?: number;
  autoFocus?: boolean;
  onClick?: (e: any) => void;
  title?: string;
  readOnly?: boolean;
}

export const StyledSwitch = styled((props: SwitchProps) => (
  <FormControlLabel
    value="end"
    control={<MuiSwitch {...props} />}
    label={<Typography variant="subtitle1">{props?.switchlabel}</Typography>}
    sx={{ marginRight: props?.switchlabel ? 2 : 0, ...props?.sx }}
  />
))(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: theme.palette.aurora.five,
    opacity: 10,
  },
  '& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track': {
    opacity: 0.5,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.midnight.three,
    opacity: 10,
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.midnight.nine as string,
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.midnight.nine as string,
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    color: 'white',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export function Switch({ disabled = false, showLabel = true, ...props }) {
  const switchProps = { ...props, type: 'checkbox' }; //we need to look into formik-mui again
  //@ts-ignore
  const [field, meta] = useField(switchProps);
  const error = meta.error && meta.touched;
  if (error) {
  }

  return (
    <StyledSwitch
      error={error}
      {...field}
      disabled={disabled}
      {...(field.checked ? { color: 'success' } : {})}
      switchlabel={
        showLabel ? (props.switchlabel ? props.switchlabel : `Enabled`) : ''
      }
    />
  );
}
