import React from 'react';

import { useQuery } from '@apollo/client';
import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';

import { getLabelById } from '../../graphql/label';
import UpdateLabel from '../Forms/UpdateLabel';
import { BlendedLabelType } from '../Labels/helpers';

interface UpdateLabelDialogProps {
  organizationId: string;
  label: BlendedLabelType;
  onLabelUpdated?: (labelName: string) => void;
}
const UpdateLabelDialog = create(
  ({ organizationId, label, onLabelUpdated }: UpdateLabelDialogProps) => {
    const modal = useModal();

    const { data: labelWithAuth, refetch } = useQuery(getLabelById, {
      variables: { organizationId, labelId: label.id },
    });
    const rolesByPrivilege = label.rolesByPrivilege;
    const labelWithAuthAndRoleName = {
      ...labelWithAuth?.label,
      rolesByPrivilege,
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        maxWidth={`xl`}
        sx={{
          '& .MuiDialog-container': {
            '> .MuiPaper-root': {
              width: '705px',
            },
          },
        }}
      >
        {labelWithAuth && (
          <UpdateLabel
            label={labelWithAuthAndRoleName}
            organizationId={organizationId}
            onCancel={modal.hide}
            onLabelUpdated={(labelName: string) => {
              modal.hide();
              if (onLabelUpdated) {
                onLabelUpdated(labelName);
                refetch();
              }
            }}
          />
        )}
      </Dialog>
    );
  },
);

export default UpdateLabelDialog;

export function showUpdateLabelDialog(
  organizationId: string,
  label: BlendedLabelType,
  onLabelUpdated?: (labelName: string) => void,
) {
  return show<boolean, any, UpdateLabelDialogProps>(UpdateLabelDialog, {
    organizationId,
    label,
    onLabelUpdated,
  });
}
