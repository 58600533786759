import React, { createContext, useContext } from 'react';

import { GridRowId, GridRowModel } from '@mui/x-data-grid';

import { PartitionFieldRow } from './PartitionEdit';
import { PropertiesRow } from './PropertiesEdit';
import { SchemaFieldRow } from './SchemaEdit';
import { SortOrderFieldRow } from './SortOrderEdit';
import { EditTableMode } from './TableEdit';

export type TableEditContextProps = {
  mode: EditTableMode;
  schemaModel: Record<string, GridRowModel<SchemaFieldRow>[]>;
  setSchemaModel: React.Dispatch<
    React.SetStateAction<Record<string, GridRowModel<SchemaFieldRow[]>>>
  >;
  schemaValidityModel: Record<GridRowId, number>;
  setSchemaValidityModel: React.Dispatch<
    React.SetStateAction<Record<GridRowId, number>>
  >;
  triggerValidation: number;

  isCancelled: boolean;
  cancelSubmission: (
    level: number,
    reason: string,
    shouldTryAgainNextLoop: boolean,
  ) => void;

  partitionFieldModel: GridRowModel<PartitionFieldRow>[];
  setPartitionFieldModel: React.Dispatch<
    React.SetStateAction<GridRowModel<PartitionFieldRow>[]>
  >;

  sortOrderFieldModel: GridRowModel<SortOrderFieldRow>[];
  setSortOrderFieldModel: React.Dispatch<
    React.SetStateAction<GridRowModel<SortOrderFieldRow>[]>
  >;

  propertiesModel: GridRowModel<PropertiesRow>[];
  setPropertiesModel: React.Dispatch<
    React.SetStateAction<GridRowModel<PropertiesRow>[]>
  >;

  changelogSortOrderFieldModel: GridRowModel<SortOrderFieldRow>[];
  setChangelogSortOrderFieldModel: React.Dispatch<
    React.SetStateAction<GridRowModel<SortOrderFieldRow>[]>
  >;

  isFieldInUse: (fieldId: number) => { inUse: boolean; locationString: string };
  organization: {
    id: string;
    name: string;
    displayName: string;
    authProviders: [];
  };
  warehouseId: string;
  warehouseName: string;
  database: string;
  tableName?: string;
  tableId?: string;
  editTableEnabled: boolean;
};
export const TableEditContext = createContext<TableEditContextProps>({
  mode: 0,
  schemaModel: {}, // set a default value
  setSchemaModel: () => {},
  schemaValidityModel: {},
  setSchemaValidityModel: () => {},
  triggerValidation: -1,
  isCancelled: false,
  cancelSubmission: (level: number, reason: string, foundRowsInEditMode) => {},
  partitionFieldModel: [], // set a default value
  setPartitionFieldModel: () => {},
  sortOrderFieldModel: [], // set a default value
  setSortOrderFieldModel: () => {},
  changelogSortOrderFieldModel: [], // set a default value
  setChangelogSortOrderFieldModel: () => {},
  propertiesModel: [], // set a default value
  setPropertiesModel: () => {},
  isFieldInUse: (fieldId) => {
    return { inUse: false, locationString: '' };
  },
  organization: { id: '', name: '', displayName: '', authProviders: [] },
  warehouseId: '',
  warehouseName: '',
  database: '',
  tableName: '',
  tableId: '',
  editTableEnabled: false,
});
export const useTableEditContext = () => useContext(TableEditContext);
