import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AlertColor,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import { includes, isEmpty, some } from 'lodash';

import { toTableRoot, toWarehouseRoot } from '../../RouteTable';
import { useAuth } from '../../context/auth-context';
import { restoreDroppedTable } from '../../graphql/organization';
import { WarehouseDatabases } from '../../graphql/warehouse';
import { getApolloErrorMessage } from '../../utils/ApolloUtils';
import { getLogger } from '../../utils/logging';
import { Talert } from '../Alert/Talert';
import { TaBadge } from '../Badge/TaBadge';
import { Tabutton } from '../Button/Tabutton';
import { RestoreTableType } from '../Modals/RestoreTableDialog';
import { DatabaseOptionType } from './helpers';

export default function RestoreTable({
  orgName,
  droppedTable,
  onCancel,
}: {
  orgName: string;
  droppedTable: RestoreTableType;
  onCancel: () => void;
}) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const logger = getLogger('components.SpecialTrash.RestoreTable');
  const [databaseToRestoreTo, setDatabaseToRestoreTo] = useState(
    droppedTable.formerLocation.databaseId
      ? droppedTable.formerLocation.databaseName
      : 'none',
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState<AlertColor>('warning');
  const [duplicateNameError, setDuplicateNameError] = useState(false);
  const [noBuenoDb, setNoBuenoDb] = useState('');
  const [noAvailableDbs, setNoAvailableDbs] = useState(false);
  const [getDatabaseOptions, { data, loading: databasesLoading }] =
    useLazyQuery(WarehouseDatabases, {
      variables: {
        organizationId: droppedTable.organizationId,
        warehouseId: droppedTable.formerLocation.warehouseId,
      },
    });
  const [restoreTable, { loading }] = useMutation(restoreDroppedTable, {
    variables: {
      warehouseId: droppedTable.formerLocation.warehouseId,
      request: {
        tableRefId: droppedTable.tableRefId,
        destinationTableIdentifier: {
          namespace: [databaseToRestoreTo],
          name: droppedTable.name,
        },
      },
    },
  });

  const canCreateDatabase = useMemo(
    () => user.hasPrivilege('CREATE_DATABASE', data?.organization?.warehouse),
    [user, data],
  );

  useEffect(() => {
    if (
      !droppedTable.formerLocation.databaseId &&
      droppedTable.formerLocation.warehouseId &&
      !data &&
      !databasesLoading
    ) {
      getDatabaseOptions();
    }
  }, [droppedTable]);

  const databaseOptions = useMemo(() => {
    return data?.organization?.warehouse?.databases.filter(
      (db: DatabaseOptionType) =>
        some(db.privileges, (priv) => priv === 'CREATE_TABLE'),
    );
  }, [data, duplicateNameError]);

  useEffect(() => {
    if (
      databaseOptions?.length === 0 ||
      (databaseOptions?.length === 1 && databaseOptions?.[0].name === noBuenoDb)
    ) {
      setNoAvailableDbs(true);
    }
  }, [databaseOptions]);
  const handleRestoreTable = async () => {
    const redirectPath = toTableRoot(
      orgName,
      droppedTable.formerLocation.warehouseName,
      databaseToRestoreTo,
      droppedTable.name,
    );

    await restoreTable()
      .then(() => {
        onCancel();
        //@ts-ignore
        return navigate(redirectPath, {
          state: {
            snackbar: true,
            snackbarMessage: `${droppedTable.name} successfully restored`,
          },
        });
      })
      .catch((error) => {
        logger.debug(getApolloErrorMessage(error));
        if (includes(error, '409: Conflict')) {
          setErrorMessage(
            `A table with the same name already exists in this database.`,
          );
          setNoBuenoDb(databaseToRestoreTo);
          setDuplicateNameError(true);
          getDatabaseOptions();
          setDatabaseToRestoreTo(
            droppedTable.formerLocation.databaseId
              ? 'none'
              : databaseToRestoreTo,
          );
        } else {
          setErrorSeverity(`error`);
          setErrorMessage(
            `Something went wrong. Please refresh the page and try again.`,
          );
        }
      });
  };

  const getAlertType = () => {
    let severityLevel = 'warning' as AlertColor;
    let message = 'There are no available databases to restore this table in.';

    switch (true) {
      case !canCreateDatabase && !duplicateNameError:
        severityLevel = 'error';
        message =
          'There are no available databases to restore this table in and you do not have CREATE_DATABASE in this warehouse.';
        break;
      case canCreateDatabase && duplicateNameError:
        message =
          'A table with the same name already exists in this database and there are no available databases to restore this table in.';
        break;
      case !canCreateDatabase && duplicateNameError:
        severityLevel = 'error';
        message =
          'A table with the same name already exists in this database. There are no available databases to restore this table in and you do not have CREATE_DATABASE in this warehouse.';
        break;
      default:
        break;
    }

    return (
      <Talert
        severity={severityLevel}
        sx={{
          mb: 4,
          alignItems: 'center',
        }}
      >
        <Typography variant={`helperText`}>{message}</Typography>
      </Talert>
    );
  };

  return (
    <>
      <DialogTitle sx={{ py: 2, mb: 2 }}>{`Restore location`}</DialogTitle>
      <DialogContent>
        {!isEmpty(errorMessage) && !noAvailableDbs && (
          <Talert
            severity={errorSeverity}
            sx={{
              mb: 4,
              alignItems: 'center',
            }}
          >
            <Typography variant={`helperText`}>{errorMessage}</Typography>
          </Talert>
        )}
        {noAvailableDbs ? (
          <>{getAlertType()}</>
        ) : (
          <>
            <DialogContentText>
              {droppedTable.formerLocation.databaseId ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TaBadge
                    text={droppedTable.name}
                    textVariant={`preformatted`}
                  />
                  <Typography variant={`body1`} sx={{ ml: 1 }}>
                    will be restored to
                  </Typography>
                  {noBuenoDb !== droppedTable.formerLocation.databaseName ? (
                    <Box marginLeft={1}>
                      <TaBadge
                        text={droppedTable.formerLocation.databaseName}
                        textVariant={`preformatted`}
                      />
                    </Box>
                  ) : (
                    <Typography variant={`body1`}>
                      selected database.
                    </Typography>
                  )}
                </Box>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant={`body1`} sx={{ mr: 1 }}>
                      The former location
                    </Typography>
                    <TaBadge
                      text={droppedTable.formerLocation.databaseName}
                      textVariant={`preformatted`}
                      bgColor={`sunrise.one`}
                    />
                    <Typography variant={`body1`} sx={{ ml: 1 }}>
                      was dropped.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
                    <TaBadge
                      text={droppedTable.name}
                      textVariant={`preformatted`}
                    />
                    <Typography variant={`body1`} sx={{ ml: 1 }}>
                      will be restored to the selected database.
                    </Typography>
                  </Box>
                </Box>
              )}
            </DialogContentText>
            {(!droppedTable.formerLocation.databaseId ||
              duplicateNameError) && (
              <>
                {databasesLoading || !databaseOptions ? (
                  <Skeleton height={40} />
                ) : (
                  <Select
                    value={databaseToRestoreTo}
                    onChange={(e) => setDatabaseToRestoreTo(e.target.value)}
                    sx={{ mt: 4, minWidth: 300 }}
                  >
                    <MenuItem disabled value={'none'}>
                      Select database
                    </MenuItem>
                    {databaseOptions.flatMap(
                      ({ id, name }: { id: string; name: string }) => (
                        <MenuItem
                          key={id}
                          value={name}
                          disabled={noBuenoDb === name}
                        >
                          {name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {noAvailableDbs && !canCreateDatabase ? (
          <Tabutton onClick={() => onCancel()} variant={`contained`}>
            Ok
          </Tabutton>
        ) : (
          <>
            <Tabutton onClick={() => onCancel()}>Cancel</Tabutton>
            {noAvailableDbs && canCreateDatabase ? (
              <Tabutton
                onClick={() => {
                  onCancel();
                  return navigate(
                    toWarehouseRoot(
                      orgName,
                      droppedTable.formerLocation.warehouseName,
                    ),
                    {
                      state: {
                        launchCreateDbModal: true,
                      },
                    },
                  );
                }}
                variant={`outlined`}
              >
                Go create a database
              </Tabutton>
            ) : (
              <Tabutton
                loadingBtn
                loading={loading}
                variant={`contained`}
                onClick={() => handleRestoreTable()}
                disabled={
                  databaseToRestoreTo === 'none' ||
                  databaseToRestoreTo === noBuenoDb
                }
              >
                Restore
              </Tabutton>
            )}
          </>
        )}
      </DialogActions>
    </>
  );
}
