import { Warehouse } from '../../../graphql/gen/graphql';

export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};

export const extractBucketName = (warehouse: Warehouse): string => {
  return warehouse.location!.substring(5, warehouse.location?.indexOf('/', 5));
};

export const extractBucketPrefix = (warehouse: Warehouse): string => {
  const slashAfterBucket = warehouse.location!.indexOf('/', 5);
  return warehouse.location!.substring(
    slashAfterBucket + 1,
    warehouse.location!.indexOf('/', slashAfterBucket + 1),
  );
};

// Truncating organization name to 32 characters to ensure that we do not go over the 64 char limit for AWS role or policy names
// Replace underscores with hyphens (bucket names and stack names cannot have underscores)
// Replace spaces as well
export const transformWarehouseNameForCFT = (warehouseName: string) => {
  return (
    warehouseName?.slice(0, 32).replaceAll('_', '-').replaceAll(' ', '-') ||
    'tabular'
  );
};

export const launchCloudFormation = ({
  warehouse,
  snowflakeUserARN,
  snowflakeExternalId,
}: {
  warehouse: Warehouse;
  snowflakeUserARN: string;
  snowflakeExternalId: string;
}) => {
  const warehouseNameTruncated = transformWarehouseNameForCFT(warehouse.name);
  const REGION = process.env.REACT_APP_DEFAULT_WAREHOUSE_REGION;
  const RANDOM = getRandomInt(10000);
  const STACK_NAME = encodeURIComponent(
    `t-stack-snowflake-tabular-${warehouseNameTruncated}-${RANDOM}`,
  );
  const BUCKET_NAME = encodeURIComponent(extractBucketName(warehouse));
  const EXTERNAL_ID = encodeURIComponent(snowflakeExternalId);
  const SNOWFLAKE_USER_ARN = encodeURIComponent(snowflakeUserARN);
  const BUCKET_PREFIX = encodeURIComponent(extractBucketPrefix(warehouse));

  const cloudFormationUrl = `https://${REGION}.console.aws.amazon.com/cloudformation/home?region=${REGION}#/stacks/create/review?templateURL=${encodeURIComponent(
    process.env
      .REACT_APP_SNOWFLAKE_MIRROR_CLOUD_FORMATION_TEMPLATE_URL as string,
  )}&stackName=${STACK_NAME}&param_BucketName=${BUCKET_NAME}&param_ExternalId=${EXTERNAL_ID}&param_PrincipleSnowflakeArn=${SNOWFLAKE_USER_ARN}&param_BucketPrefix=${BUCKET_PREFIX}&param_WarehouseName=${warehouseNameTruncated}`;
  window.open(cloudFormationUrl, '_blank');
};
