import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Tabutton } from '../../Button/Tabutton';
import { ExternalBreadcrumbs } from '../../CreateStorageBucket/BucketSteps/helpers';
import { SecurityMethod } from '../helpers';

export const EMRCreateCluster = ({
  name,
  region,
  securityType,
  breadcrumbs,
  credential,
  modalRef,
}: {
  name: string;
  region: string;
  securityType: SecurityMethod;
  breadcrumbs: string[];
  credential: any;
  modalRef: React.Ref<HTMLElement>;
}) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  const iamConfig = [
    {
      Classification: 'iceberg-defaults',
      Properties: {
        'iceberg.enabled': 'true',
      },
    },
    {
      Classification: 'spark-defaults',
      Properties: {
        [`spark.sql.catalog.${name}`]: `org.apache.iceberg.spark.SparkCatalog`,
        [`spark.sql.catalog.${name}.catalog-impl`]: `org.apache.iceberg.rest.RESTCatalog`,
        [`spark.sql.catalog.${name}.rest.sigv4-enabled`]: `true`,
        [`spark.sql.catalog.${name}.uri`]: `https://iam-gw.${region}${process.env.REACT_APP_REST_SIGV4_PARTIAL}`,
        [`spark.sql.catalog.${name}.warehouse`]: `${name}`,
        [`spark.sql.defaultCatalog`]: `${name}`,
        [`spark.sql.extensions`]:
          'org.apache.iceberg.spark.extensions.IcebergSparkSessionExtensions',
      },
    },
  ];

  const credendtialConfig = [
    {
      Classification: 'iceberg-defaults',
      Properties: {
        'iceberg.enabled': 'true',
      },
    },
    {
      Classification: 'spark-defaults',
      Properties: {
        [`spark.sql.catalog.${name}`]: `org.apache.iceberg.spark.SparkCatalog`,
        [`spark.sql.catalog.${name}.catalog-impl`]: `org.apache.iceberg.rest.RESTCatalog`,
        [`spark.sql.catalog.${name}.credential`]: `${credential?.credential}`,
        [`spark.sql.catalog.${name}.region`]: `${region}`,
        [`spark.sql.catalog.${name}.uri`]: `${process.env.REACT_APP_REST_CATALOG_URL}`,
        [`spark.sql.catalog.${name}.warehouse`]: `${name}`,
        [`spark.sql.defaultCatalog`]: `${name}`,
        [`spark.sql.extensions`]:
          'org.apache.iceberg.spark.extensions.IcebergSparkSessionExtensions',
      },
    },
  ];

  const cmdTemplate =
    SecurityMethod.IamMapping == securityType
      ? JSON.stringify(iamConfig, null, 2)
      : JSON.stringify(credendtialConfig, null, 2);
  return (
    <Stack
      ref={modalRef as any}
      sx={{
        mt: 0,
        display: 'flex',
        flexDirection: 'column',
        width: 1,
      }}
    >
      In Amazon EMR Create cluster:
      <ExternalBreadcrumbs
        breadcrumbs={breadcrumbs}
        link={`https://${region}.console.aws.amazon.com/emr/home?region=${region}#/createCluster`}
      />
      <List sx={{ listStyle: 'decimal', pl: 5 }}>
        <ListItem sx={{ display: 'list-item', p: 0 }}>
          <Typography variant={'body1'}>
            Scroll to <b>Software settings</b>
          </Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item', p: 0 }}>
          <Typography variant={'body1'}>
            Copy and paste Tabular catalog configuration
          </Typography>
          <Grid
            container
            sx={{
              width: 0.95,
              mt: 1,
              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingRight: 2,
              }}
            >
              <Typography
                variant={'body1'}
                color={'midnight.nine'}
                alignSelf={'center'}
              >
                Tabular catalog configuration
              </Typography>
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <IconButton
                  onClick={() => copyToClipBoard(cmdTemplate)}
                  title="Copy to clipboard"
                  size={'small'}
                  sx={{
                    height: 12,
                    width: 12,
                    alignSelf: 'center',
                  }}
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              <SyntaxHighlighter
                language="json"
                style={{
                  ...dracula,
                  padding: 0 as React.CSSProperties,
                }}
                customStyle={{ margin: 0 }}
                showLineNumbers={false}
              >
                {cmdTemplate}
              </SyntaxHighlighter>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 1 / 2,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <Tabutton
                  onClick={() => copyToClipBoard(cmdTemplate)}
                  title="Copy to clipboard"
                  size={'small'}
                  sx={{ alignSelf: 'right' }}
                  endIcon={<FileCopyIcon />}
                >
                  Copy Tabular catalog configuration
                </Tabutton>
              </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem sx={{ display: 'list-item', p: 0, mt: 2 }}>
          <Typography variant={'body1'}>
            Complete any other configurations needed for your cluster.
          </Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item', p: 0, mt: 1 }}>
          <Typography variant={'body1'}>
            Click <b>Create cluster</b> in EMR.
          </Typography>
        </ListItem>
        {securityType == SecurityMethod.IamMapping && (
          <ListItem sx={{ display: 'list-item', p: 0, mt: 1 }}>
            <Typography variant={'body1'}>
              Be sure to map the IAM role specified in the{' '}
              <b>EC2 instance profile for Amazon EMR</b>
              {` to a role in Tabular if you haven't already.`}
            </Typography>
          </ListItem>
        )}
        <ListItem sx={{ display: 'list-item', p: 0, mt: 1 }}>
          <Typography>
            Once cluster is successfully created SSH into cluster and run{' '}
            <b>spark-sql</b>.
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            display: 'list-item',
            p: 0,
            mt: 1,
          }}
        >
          <Typography variant={'body1'}>
            Continue to test connection.
          </Typography>
        </ListItem>
      </List>
    </Stack>
  );
};
