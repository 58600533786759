import React, { useCallback, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Skeleton } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import { toDocsRoot } from '../../RouteTable';
import GrantNewAccess from '../../components/AccessControl/GrantNewAccess';
import RoleCard from '../../components/AccessControl/RoleCard';
import { PrivilegeSelected } from '../../components/AccessControl/RolePrivileges';
import {
  getRoleIds,
  getAuthsToUpdate,
  getAuthsToCreate,
  getUserFriendlyErrorMessage,
  getCurrentPrivilegesWithGrant,
} from '../../components/AccessControl/common';
import { Tabulink } from '../../components/Link/Tabulink';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import {
  createAuthorizations,
  getAuthorizationGrants,
  handleAuthorizationMutations,
  organizationAuthorizations,
} from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionSubjectType,
  Authorization,
  CreateAuthInput,
  Privilege,
  UpdateAuthInput,
} from '../../graphql/gen/graphql';

const OrganizationAccessControls = ({
  orgId,
  orgName,
  orgDisplayName,
  user,
}: {
  orgId: string;
  orgName: string;
  orgDisplayName: string;
  user: any;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [roleBeingEdited, setRoleBeingEdited] = useState('');
  //queries
  const {
    data: resourceData,
    loading,
    error,
    refetch,
  } = useQuery(organizationAuthorizations, {
    variables: {
      id: orgId,
      orgName: orgName,
      orgDisplayName: orgDisplayName,
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const { data: currentGrants } = useQuery(getAuthorizationGrants, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        resource: {
          type: AuthDecisionResourceType.Organization,
          identifier: orgId,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  //mutations
  const [createAuths] = useMutation(createAuthorizations);
  const [handleAuthMutations] = useMutation(handleAuthorizationMutations);
  //variables
  const resourceAuthorizations = useMemo(
    () => resourceData?.organizationAuthorizations,
    [resourceData],
  );
  const roleIds = useMemo(
    () => getRoleIds(resourceAuthorizations),
    [resourceAuthorizations],
  );
  const isSecurityAdmin = useMemo(() => user.isSecurityAdmin(orgName), [user]);

  const privilegesWithGrant = getCurrentPrivilegesWithGrant(
    currentGrants?.authGrants,
  );
  const isResourceAdmin = privilegesWithGrant?.length > 0 || isSecurityAdmin;

  const handleMutations = async (
    authsToUpdate: UpdateAuthInput[],
    authsToCreate: CreateAuthInput[],
    authsToDelete: string[],
  ) => {
    return await handleAuthMutations({
      variables: {
        authsToUpdate,
        authsToCreate,
        authsToDelete,
      },
      onCompleted: () =>
        closeAndRefetch(`${roleBeingEdited} privileges have been updated`),
      onError: (e) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
          variant: 'error',
          preventDuplicate: true,
        });
      },
    });
  };

  const closeAndRefetch = (message: string) => {
    setTimeout(() => {
      refetch().then(() =>
        enqueueSnackbar(message, {
          variant: 'success',
          preventDuplicate: true,
        }),
      );
    }, 1000);
  };

  const handleGrantNewAccess = useCallback(
    async (currentPrivileges: PrivilegeSelected[], selectedRoleId: string) => {
      const authsToCreate = getAuthsToCreate(
        currentPrivileges,
        orgId,
        AuthDecisionResourceType.Organization,
        selectedRoleId,
      );
      return await createAuths({
        variables: {
          authorizations: authsToCreate,
        },
        onCompleted: () => {
          closeAndRefetch(`${roleBeingEdited} privileges successfully updated`);
        },
        onError: (e) => {
          enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
            variant: 'error',
            preventDuplicate: true,
          });
        },
      });
    },
    [],
  );

  const handleApplyChanges = useCallback(
    async (
      currentPrivileges: PrivilegeSelected[],
      originalPrivileges: Authorization[],
      toCreate: PrivilegeSelected[],
      toUpdate: PrivilegeSelected[],
      toDelete: Privilege[],
    ) => {
      const roleId = originalPrivileges[0].roleId;
      const authsToCreate = getAuthsToCreate(
        toCreate,
        orgId,
        AuthDecisionResourceType.Organization,
        roleId || '',
      );

      const authsToUpdate = getAuthsToUpdate(toUpdate, originalPrivileges);

      const authsToDelete = toDelete.map((priv: Privilege) => {
        return originalPrivileges.find((p) => p.privilege === priv)?.id;
      });
      //@ts-ignore
      return handleMutations(authsToUpdate, authsToCreate, authsToDelete);
    },

    [],
  );

  const revokePrivilege = async (privileges: Authorization[]) => {
    const toDelete = privileges.map((priv: any) => {
      return priv.id;
    });

    return handleMutations([], [], toDelete);
  };

  if (error && !resourceData) return <div>Error loading access controls</div>;

  return (
    <>
      {loading && <Skeleton variant="rectangular" height={350} />}
      {!loading && isResourceAdmin && (
        <>
          <PageHeader
            resourceName={orgName}
            pageTitle={'Organization access controls'}
            marginB={0}
          >
            <GrantNewAccess
              user={user}
              organizationId={orgId}
              existingRoleIds={roleIds}
              handleGrantNewApply={async (
                currentPrivileges: PrivilegeSelected[],
                selectedRoleId: string,
                selectedRoleName: string,
              ) => {
                await setRoleBeingEdited(selectedRoleName);
                return handleGrantNewAccess(currentPrivileges, selectedRoleId);
              }}
              resourceType={AuthDecisionResourceType.Organization}
              isSecurityAdmin={isSecurityAdmin}
              currentGrants={privilegesWithGrant || []}
              orgAccessControls
            />
          </PageHeader>
          <Tabulink
            external
            href={
              toDocsRoot() +
              '/understanding-tabular-role-based-access-controls--rbac-.html'
            }
            variant="body1"
            rel="noopener"
            aria-label="Tabular Documentation"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2,
              maxWidth: '350px',
            }}
          >
            Learn about access controls and role privileges
            <OpenInNewIcon fontSize="small" sx={{ marginLeft: '2px' }} />
          </Tabulink>
        </>
      )}
      {!loading &&
        resourceData?.organizationAuthorizations &&
        !isEmpty(resourceAuthorizations) &&
        resourceAuthorizations.map((roleGroup: any, key: number) => {
          return (
            <RoleCard
              key={`role-card-${key}-${roleGroup.role.id}`}
              organizationName={orgName}
              authorizations={roleGroup.authorizations}
              resourceName={orgName}
              roleDetails={roleGroup.role}
              accessType={AuthDecisionResourceType.Organization}
              handleApplyChanges={async (
                currentPrivileges: PrivilegeSelected[],
                originalPrivileges: Authorization[],
                toCreate: PrivilegeSelected[],
                toUpdate: PrivilegeSelected[],
                toDelete: Privilege[],
              ) => {
                await setRoleBeingEdited(roleGroup.role.displayName);
                return handleApplyChanges(
                  currentPrivileges,
                  originalPrivileges,
                  toCreate,
                  toUpdate,
                  toDelete,
                );
              }}
              showRevokeAccess
              onRevokeAccess={async () => {
                await setRoleBeingEdited(roleGroup.role.displayName);
                await revokePrivilege(roleGroup.authorizations);
              }}
              isResourceAdmin={isResourceAdmin}
              currentGrants={privilegesWithGrant || []}
              user={user}
            />
          );
        })}
    </>
  );
};

export { OrganizationAccessControls };
