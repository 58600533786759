import React from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {/* @ts-ignore */}
      <Link color="inherit" external href="https://tabular.io/">
        Tabular
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
