import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { FieldArray, useField } from 'formik';
import { concat, pullAt } from 'lodash';

import { DEFAULT_TTL_IN_MS } from '../../utils/properties';
import { Tabutton } from '../Button/Tabutton';
import TextField from '../Forms/TextField';
import { SchemaFieldResult } from '../TableEdit/TableEditHelpers';
import { convertMillisecondsToDays } from './helpers';

type ColumnMaskingPropTypes = {
  name: string;
  label: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  identifierOptions: SchemaFieldResult[];
  timestampColumn?: string;
  setEditingPropGroup: (value: boolean) => void;
  stagePropertiesForDelete: (value: string[]) => void;
};

export default function LifecycleColumnMasking({
  name,
  label,
  setFieldValue,
  identifierOptions,
  timestampColumn,
  setEditingPropGroup,
  stagePropertiesForDelete,
  ...props
}: ColumnMaskingPropTypes) {
  const [field, meta, helpers] = useField('lifecycleMgmt.columnMasking');
  const [transformField, setTransformField] = useState('nullify');
  const brandSpankingNewColumn = {
    identifier: '',
    ttl: convertMillisecondsToDays(DEFAULT_TTL_IN_MS),
  };

  const addAColumn = () => {
    helpers.setValue(concat(...field.value, [brandSpankingNewColumn]));
    setEditingPropGroup(true);
  };

  const handleRemoveColumn = (index: number) => {
    const clone = [...field.value];
    pullAt(clone, [index]);
    helpers.setValue(clone);
  };

  return (
    <Box width={`100%`}>
      <FieldArray name={name}>
        {() =>
          field.value?.map((property: any, index: number) => {
            return (
              <Box key={`columnMasking-${index}`} display={`flex`}>
                <Box mr={2} width={`45%`}>
                  <TextField
                    name={`lifecycleMgmt.columnMasking[${index}].identifier`}
                    label={`Column to mask`}
                    select
                    onClick={() => setEditingPropGroup(true)}
                    sx={{ minWidth: 380, mt: 1, mb: 2 }}
                  >
                    {identifierOptions?.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.fullName}
                        disabled={option.name === timestampColumn}
                      >
                        {option.fullName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box width={`45%`} display={`flex`}>
                  <TextField
                    name={`lifecycleMgmt.columnMasking[${index}].ttl`}
                    label={'Maximum record age'}
                    onClick={() => setEditingPropGroup(true)}
                    {...props}
                    sx={{
                      mt: 1,
                      mb: 2,
                      justifyContent: 'flex-start',
                      input: {
                        textAlign: 'right',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position={`end`}
                        >{`days`}</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    select
                    name={`transformField`}
                    label={`Transform to use`}
                    value={transformField}
                    sx={{
                      mt: 1,
                      mb: 2,
                      ml: 2,
                      justifyContent: 'flex-start',
                    }}
                  >
                    <MenuItem value={`nullify`}>Nullify</MenuItem>
                  </TextField>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '10%',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleRemoveColumn(index);
                      setEditingPropGroup(true);
                      stagePropertiesForDelete([
                        `lifecycle.column.${property.identifier}.max-data-age-ms`,
                        `lifecycle.column.${property.identifier}.transform`,
                      ]);
                    }}
                  >
                    <Tooltip title={`Remove column`} placement={`top`}>
                      <ClearIcon
                        sx={{
                          color: 'sunset.seven',
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Box>
            );
          })
        }
      </FieldArray>
      <Divider sx={{ my: 2 }} />
      <Tabutton size={`small`} endIcon={<AddIcon />} onClick={addAColumn}>
        Add a column
      </Tabutton>
    </Box>
  );
}
