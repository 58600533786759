import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';

import { toTableRoot, toWarehouseRoot } from '../../RouteTable';
import { useAuth } from '../../context/auth-context';
import { deleteDatabase } from '../../graphql/database';
import StatusBars from '../Feedback/StatusBars';
import DangerZone from '../TableSettings/DangerZone';
import { getDangerZoneItems } from './helpers';

export default function DatabaseSettings({
  doDropDatabase,
  apiError,
  setApiError,
  undroppedTables,
  dropDatabasePrivilegeCheck,
  userCanDrop,
  organizationName,
  warehouseName,
  warehouseId,
  database,
  tables,
}: any) {
  const { user } = useAuth();

  const dangerZoneItems = getDangerZoneItems(database);

  return (
    <>
      {userCanDrop && (
        <DangerZone
          dangerZoneItems={dangerZoneItems}
          onConfirm={doDropDatabase}
          onPrivilegeCheck={() => dropDatabasePrivilegeCheck()}
          undroppedTables={undroppedTables}
          numOfTables={tables.length}
        />
      )}
      <StatusBars
        errorDisplay={apiError}
        setErrorDisplay={setApiError}
        errorMessage={`Something went wrong. Please refresh the page and try again.`}
      />
    </>
  );
}
