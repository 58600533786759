import React from 'react';
import { useParams } from 'react-router-dom';

import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';

import RestoreTable from '../SpecialTrash/RestoreTable';
import { droppedByType, formerLocationType } from '../SpecialTrash/helpers';

export interface RestoreTableType {
  id: string;
  tableRefId: string;
  organizationId: string;
  name: string;
  droppedBy: droppedByType;
  droppedOn: string;
  formerLocation: formerLocationType;
  size: string;
}
interface RestoreTableDialogProps {
  organizationName: string;
  droppedTable: RestoreTableType;
}
const RestoreTableDialog = create(
  ({ organizationName, droppedTable }: RestoreTableDialogProps) => {
    const modal = useModal();

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <RestoreTable
          orgName={organizationName}
          droppedTable={droppedTable}
          onCancel={() => modal.hide()}
        />
      </Dialog>
    );
  },
);

export default RestoreTableDialog;

export function showRestoreTableDialog(
  droppedTable: RestoreTableType,
  organizationName: string,
) {
  return show<boolean, any, RestoreTableDialogProps>(RestoreTableDialog, {
    organizationName,
    droppedTable,
  });
}
