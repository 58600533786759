import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { gql, useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { includes } from 'lodash';

import { KnownFeatureFlags } from '../../context/GlobalPropsContext';

import { ROUTES, toOrgRoot } from '../../RouteTable';
import { Talert } from '../../components/Alert/Talert';
import AuthFormContainer from '../../components/Forms/AuthFormContainer';
import BaseLayout from '../../components/Layouts/BaseLayout';
import { Tabulink } from '../../components/Link/Tabulink';
import { tabularWelcomeContent } from '../../components/Login/helpers';
import { showCaptchaDialog } from '../../components/Modals/CaptchaDialog';
import { ContactTabularForm } from '../../components/Signup/ContactForm';
import { InviteForm, InviteValues } from '../../components/Signup/InviteForm';
import { SignupForm } from '../../components/Signup/SignupForm';
import { GlobalPropertiesContext } from '../../context';
import { useAuth } from '../../context/auth-context';
import { getLogger } from '../../utils/logging';

const logger = getLogger('Signup' /*FUTURE import.meta.url ?*/);

const FetchInvite = gql`
  query FetchInvite($inviteCode: String!) {
    fetchInvite(inviteCode: $inviteCode) {
      orgAuthProviders
      invite {
        id
        email
        organizationId
      }
    }
  }
`;

export default function Signup() {
  const {
    user,
    signupWithInvite,
    signup,
    selfInvite,
    lastError,
    clearLastError,
  } = useAuth();
  const { ff, globalContextLoading } = useContext(GlobalPropertiesContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('invite');
  const magicToken = searchParams.get('mt');
  const [submitButtonContent, setSubmitButtonContent] = useState(
    <div>Create Account</div>,
  );
  //const [statusBar, setStatusBar] = useState<string | undefined>('DANG');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selfInviteOutcome, setSelfInviteOutcome] = useState({
    success: false,
    email: '',
  });
  const [getInvite, { loading, error, data }] = useLazyQuery(FetchInvite, {
    variables: {
      inviteCode: inviteCode,
    },
  });

  const initialFormText = inviteCode ? "You're invited" : 'Data made simple';
  const [authFormText, setAuthFormText] = useState<string>(initialFormText);
  const initialSubTitleText = `Start with Tabular for free.`;
  const [authFormSubtitleText, setAuthFormSubtitleText] =
    useState<string>(initialSubTitleText);

  function reset() {
    setAuthFormText(initialFormText);
    setAuthFormSubtitleText(initialSubTitleText);
    clearLastError();
  }

  // Retrieve invite code details
  useEffect(() => {
    if (inviteCode && !error) {
      getInvite();
    }
  }, [inviteCode, getInvite]);

  // Redirects for authenticated user
  useEffect(() => {
    if (user && user.id) {
      if (!inviteCode) {
        // Authenticated user hitting signup without invite code, redirect to home
        navigate(ROUTES.home, { replace: true });
      }
      if (data?.fetchInvite?.invite) {
        if (data?.fetchInvite?.invite?.email !== user.email) {
          // TODO: Handle invite to email different from current user
        } else if (data?.fetchInvite?.invite?.organizationId) {
          // Invite for currently logged-in user, redirect to organization associated with the invite
          const orgName = user.organizations.find(
            (org: any) => org.id === data?.fetchInvite?.invite?.organizationId,
          ).name;
          orgName
            ? navigate(toOrgRoot(orgName), { replace: true })
            : navigate(ROUTES.home, { replace: true });
        } else {
          // Generic invite for existing user, redirect to home
          navigate(ROUTES.home, { replace: true });
        }
      }
    }
  }, [user, inviteCode, data]);

  const handleSubmit = useCallback(
    (values: InviteValues, actions: any) => {
      setIsSubmitting(true);
      setSubmitButtonContent(<CircularProgress color="inherit" size={15} />);
      signupWithInvite({
        code: inviteCode,
        idToken: values.idToken,
        password: values.password,
        authType: values.authType,
        email: values.email,
        organizationId: data?.fetchInvite?.invite?.organizationId,
      }).catch((e: any) => {
        setSubmitButtonContent(<div>Create Account</div>);
        actions.setSubmitting(false); // Change Formik isSubmitting back to false
      });
    },
    [data],
  );

  const handleSelfSignupSubmit = useCallback((idToken: string) => {
    setIsSubmitting(true);
    showCaptchaDialog()
      .then((doneSuccssfully) => {
        if (doneSuccssfully) {
          signup({
            idToken: idToken,
          }).catch((e: any) => {
            if (e.message == 'Failed to fetch') {
              console.error('problem signing up.');
            }
            setIsSubmitting(false);
          });
        } else {
          setIsSubmitting(false);
        }
      })
      .catch((e) => {
        setIsSubmitting(false);
      });
  }, []);

  const handleSelfInviteSubmit = useCallback(
    (email: string, displayName?: string) => {
      setIsSubmitting(true);
      showCaptchaDialog()
        .then((doneSuccssfully) => {
          if (doneSuccssfully) {
            selfInvite({
              email,
              displayName,
            })
              .then(() => {
                setIsSubmitting(false);
                setSelfInviteOutcome({ email, success: true });
              })
              .catch((e: any) => {
                if (e.message == 'Failed to fetch') {
                  logger.error('problem signing up.');
                }
                setIsSubmitting(false);
              });
          } else {
            setIsSubmitting(false);
          }
        })
        .catch((e) => {
          setIsSubmitting(false);
        });
    },
    [],
  );

  const getErrorAlert = (message: string) => {
    return includes(message, 'already exists') ? (
      <Talert
        alertTitle={`Account already exists.`}
        severity={`warning`}
        sx={{ mb: 2, a: { color: 'sky.seven' } }}
        onClose={() => clearLastError()}
      >
        <Typography variant={`helperText`}>
          Trying to log in?{' '}
          <Tabulink href={ROUTES.login} onClick={() => clearLastError()}>
            Go to the log in page
          </Tabulink>{' '}
          or sign up with a different email account.
        </Typography>
      </Talert>
    ) : (
      <Talert severity="error" sx={{ mb: 2 }} onClose={() => clearLastError()}>
        {message}
      </Talert>
    );
  };

  const selfSignupEnabled = ff(KnownFeatureFlags.SELF_SIGNUP_ENABLED);

  if (selfSignupEnabled === undefined || (inviteCode && !data)) {
    return <></>;
  }
  if (!inviteCode && !selfSignupEnabled) {
    return <ContactTabularForm />;
  }

  return (
    <BaseLayout>
      <AuthFormContainer
        title={authFormText}
        subtitle={authFormSubtitleText}
        leftSideContent={tabularWelcomeContent}
      >
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 1,
          }}
        >
          {lastError && getErrorAlert(lastError)}
          {selfInviteOutcome.success && (
            <Box sx={{ flexGrow: 1 }}>
              <Typography>
                We just sent a link to {selfInviteOutcome.email}. Click the link
                in the email to complete your account setup.
              </Typography>
              <Box
                sx={{
                  alignItems: 'flex-end',
                  flexGrow: 1,
                  mt: 4,
                  mb: 4,
                  mr: 4,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <img
                  src="/assets/img/penguin-with-email.svg"
                  alt="Penguin /w Email"
                  height="104px"
                />
              </Box>
            </Box>
          )}

          {!selfSignupEnabled &&
            !inviteCode &&
            !isSubmitting &&
            !selfInviteOutcome.success && (
              <Box marginTop={2}>
                <SignupForm
                  submitButtonContent={submitButtonContent}
                  handleIdTokenSubmit={handleSelfSignupSubmit}
                  handleSelfInviteSubmit={handleSelfInviteSubmit}
                  onSignUpWithEmailStateChanged={(signUpWithEmailState) => {
                    setAuthFormText('Sign up with email');
                    setAuthFormSubtitleText('');
                  }}
                />
              </Box>
            )}

          {inviteCode && data?.fetchInvite && (
            <InviteForm
              inviteResult={data?.fetchInvite}
              error={error}
              handleSubmit={handleSubmit}
              submitButtonContent={submitButtonContent}
              magicToken={magicToken}
            />
          )}

          <Box display={`flex`} flexDirection={`column`} alignItems={`center`}>
            <Talert
              severity={`info`}
              TabuttonProps={{
                children: <Box>LOG IN</Box>,
                size: 'small',
                onClick: () => navigate(ROUTES.login),
              }}
              sx={{ maxWidth: 400 }}
            >
              <Typography
                variant={`helperText`}
                sx={{ color: 'midnight.seven' }}
              >
                Already have an account?
              </Typography>
            </Talert>

            <Box
              marginBottom={2}
              marginTop={4}
              display={`flex`}
              justifyContent={`center`}
            >
              <Typography variant={'helperText'} sx={{ color: 'midnight.six' }}>
                By signing up, you agree to our{' '}
                <Tabulink
                  href="https://tabular.io/legal/terms/"
                  external
                  sx={{ fontWeight: 600 }}
                >
                  Terms
                </Tabulink>{' '}
                and{' '}
                <Tabulink
                  href="https://tabular.io/legal/privacy/"
                  external
                  sx={{ fontWeight: 600 }}
                >
                  Privacy Policy
                </Tabulink>
              </Typography>
            </Box>
          </Box>
        </Box>
      </AuthFormContainer>
    </BaseLayout>
  );
}
