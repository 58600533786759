import React from 'react';

import Dialog from '@mui/material/Dialog';

import { Table } from '../../graphql/gen/graphql';
import CreateBranchOrTag from '../Forms/CreateBranchOrTag';

interface CreateBranchDialogProps {
  snapshotId: number | null;
  onBranchCreated: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'Branch' | 'Tag';
  table: Table;
}
export default function CreateBranchDialog({
  snapshotId,
  onBranchCreated,
  open,
  setOpen,
  type,
  table,
}: CreateBranchDialogProps) {
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
        <CreateBranchOrTag
          table={table}
          snapshotId={snapshotId}
          onCancel={() => setOpen(false)}
          onBranchCreated={() => {
            setOpen(false);
            onBranchCreated && onBranchCreated();
          }}
          type={type}
        />
      </Dialog>
    </>
  );
}
