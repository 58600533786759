import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Grid, Typography, Box, List, ListItem, Stack } from '@mui/material';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Warehouse } from '../../../graphql/gen/graphql';
import { Tabutton } from '../../Button/Tabutton';
import { ExternalBreadcrumbs } from '../../CreateStorageBucket/BucketSteps/helpers';

export const DatabricksCreateCluster = ({
  warehouse,
  credential,
}: {
  warehouse: Warehouse;
  credential: any;
}) => {
  const clusterCreateBreadcrumbs = ['Compute', 'New compute', 'Preview'];
  const sparkConfig = `spark.sql.catalog.${warehouse.name} org.apache.iceberg.spark.SparkCatalog
spark.sql.catalog.${warehouse.name}.catalog-impl org.apache.iceberg.rest.RESTCatalog
spark.sql.catalog.${warehouse.name}.uri ${process.env.REACT_APP_REST_CATALOG_URL}
spark.sql.catalog.${warehouse.name}.credential ${credential?.credential}
spark.sql.catalog.${warehouse.name}.warehouse ${warehouse.name}
`;
  return (
    <>
      <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', width: 1 }}>
        <ExternalBreadcrumbs breadcrumbs={clusterCreateBreadcrumbs} />
        <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
          <ListItem sx={{ display: 'list-item', p: 0.5 }}>
            <Typography variant={'body1'}>
              In the top left menu click <b>New</b> and select <b>Cluster</b>.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0.5 }}>
            <Typography variant={'body1'}>
              Under <b>Databricks runtime version</b> select{' '}
              <b>Runtime: 14.3 LTS (Scala 2.12, Spark 3.5.0)</b>.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0.5 }}>
            <Typography variant={'body1'}>
              Click <b>Advanced Options</b> then <b>Spark</b>. Add the following
              Spark configs from Tabular.
            </Typography>
            <Grid
              container
              sx={{
                width: 0.95,
                mt: 1,
                border: 1,
                borderColor: 'midnight.two',
                borderRadius: '8px',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  padding: 1,
                  borderRadius: '8px',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderBottom: 1,
                  borderColor: 'midnight.two',
                  backgroundColor: 'dusk.half',
                }}
              >
                <Typography>{'Tabular catalog configuration'}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  borderBottom: 1,
                  borderColor: 'midnight.two',
                }}
              >
                <Stack direction="column">
                  <Grid container width={1} alignItems={'center'}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        pre: {
                          m: 0,
                          height: 1,
                        },
                      }}
                    >
                      {' '}
                      <SyntaxHighlighter
                        language="bash"
                        style={{
                          ...dracula,
                          margin: 0 as React.CSSProperties,
                        }}
                        showLineNumbers={false}
                      >
                        {sparkConfig}
                      </SyntaxHighlighter>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  padding: 1 / 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography variant={'body1'}>
                  {/* @ts-ignore */}
                  <Tabutton
                    onClick={() =>
                      navigator.clipboard.writeText(sparkConfig || '')
                    }
                    title="Copy to clipboard"
                    size={'small'}
                    sx={{ alignSelf: 'center' }}
                    endIcon={<FileCopyIcon />}
                  >
                    Copy Tabular catalog configuration
                  </Tabutton>
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0.5 }}>
            <Typography variant={'body1'}>
              Complete any other configurations needed for your cluster then
              click <b>Create compute</b>
            </Typography>
          </ListItem>
        </List>
      </Box>
    </>
  );
};
