import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  DialogActions,
  DialogContent,
  Popper,
  useTheme,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik, FormikHelpers } from 'formik';
import { debounce, includes, isEmpty, orderBy, some } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { enqueueSnackbar } from 'notistack';

import { useAuth } from '../../context/auth-context';
import {
  getAuthorizationDecision,
  getAuthorizationGrants,
} from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  LabelMaskMode,
  LabelResourceType,
  Privilege,
  SearchRoleResult,
  SortByDirection,
  AuthDecisionSubjectType,
} from '../../graphql/gen/graphql';
import {
  labelAuthorizations,
  labelFieldMasking,
  updateLabel,
} from '../../graphql/label';
import { searchRolesQuery } from '../../graphql/organization';
import { getLogger } from '../../utils/logging';
import { getSecurityAdminDefault } from '../AccessControl/common';
import { Tabutton } from '../Button/Tabutton';
import { ColumnMaskingPolicy } from '../Labels/ColumnMaskingPolicy';
import { LabelAccessControls } from '../Labels/LabelAccessControls';
import { LabelDisplay } from '../Labels/LabelDisplay';
import { LabelOverview } from '../Labels/LabelOverview';
import {
  authorizationChanges,
  BlendedLabelType,
  columnMaskingChanges,
  compileAccessControls,
  createLabelValidationSchema,
  LabelFormValuesType,
  labelOverviewChanges,
  LabelPropertyOptions,
  LabelAccessRoleType,
  RoleByPrivilegeType,
  RoleSearchParams,
  TabPanel,
} from '../Labels/helpers';
import { NavTab, TabWrapper } from '../NavTabs/SubNavTabs';
import { LabelPopover } from '../Popovers/LabelPopover';

const logger = getLogger(
  'components.Forms.UpdateLabel' /*FUTURE import.meta.url ?*/,
);

export default function UpdateLabel({
  label,
  organizationId,
  onCancel,
  onLabelUpdated,
}: {
  label: BlendedLabelType;
  organizationId: string;
  onCancel: () => void;
  onLabelUpdated: (labelName: string) => void;
}) {
  const { user } = useAuth();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [roleOptions, setRoleOptions] = useState<SearchRoleResult[]>([]);
  const [maskingEnabled, setMaskingEnabled] = useState(
    includes(
      [LabelMaskMode.Hide, LabelMaskMode.Null],
      label?.properties?.[LabelPropertyOptions.FIELD_MASKING]?.payload
        ?.maskMode,
    ),
  );
  const [highlighted, setHighlighted] = useState('');
  const { data: manageAndModifyDecision } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: 'USER',
          identifier: user.id,
        },
        privileges: [Privilege.ModifyLabel, Privilege.ManageGrants],
        requirement: 'ALL',
        resource: {
          type: AuthDecisionResourceType.Label,
          identifier: label.id,
        },
      },
    },
  });
  const { data: modifyDecision } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: 'USER',
          identifier: user.id,
        },
        privileges: [Privilege.ModifyLabel],
        requirement: 'ALL',
        resource: {
          type: AuthDecisionResourceType.Label,
          identifier: label.id,
        },
      },
    },
  });
  const { data: currentGrants } = useQuery(getAuthorizationGrants, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        resource: {
          type: AuthDecisionResourceType.Label,
          identifier: label.id,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  const [getRoles, { data, loading, refetch }] = useLazyQuery<
    any,
    RoleSearchParams
  >(searchRolesQuery, {
    variables: {
      organizationId: organizationId,
      query: searchQuery !== '' ? searchQuery : '*',
      maxResults: 100,
      searchAfter: undefined,
      sortBy: [
        {
          fieldName: searchQuery ? '_score' : 'roleName.sort',
          sortDirection: searchQuery
            ? SortByDirection.Desc
            : SortByDirection.Asc,
        },
      ],
      excludeSystemRoles: false,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const [updateLabelMutation] = useMutation(updateLabel);
  const [updateFieldMasking] = useMutation(labelFieldMasking);
  const [labelAuthMutation] = useMutation(labelAuthorizations);
  const userCanEditOverview = useMemo(
    () => modifyDecision?.authDecision === AuthDecisionResponseType.Allow,
    [modifyDecision],
  );
  const userCanEditPolicy = useMemo(
    () =>
      manageAndModifyDecision?.authDecision === AuthDecisionResponseType.Allow,
    [manageAndModifyDecision],
  );
  const userCanEditAccess = useMemo(
    () =>
      user.isSecurityAdmin(user.loginSession?.loggedInOrg?.name) ||
      manageAndModifyDecision?.authDecision === AuthDecisionResponseType.Allow,
    [user, manageAndModifyDecision],
  );
  const privilegesWithGrants = useMemo(
    () =>
      currentGrants?.authGrants?.filter(
        (priv: any) => priv.withGrant || priv.privilege === 'MANAGE_GRANTS',
      ),
    [currentGrants],
  );

  const labelAccessRoleOptions: LabelAccessRoleType[] = useMemo(() => {
    return roleOptions.map((role) => {
      return {
        roleName: role.roleName,
        roleId: role.roleId,
        privileges: [],
      };
    });
  }, [roleOptions]);

  const labelAuths =
    label?.authorizations?.filter(
      (auth) => auth?.privilege !== Privilege.Select,
    ) || [];

  const security = user?.loginSession?.roles?.find(
    (role: any) => role.name === 'SECURITY_ADMIN',
  );

  const securityAdminDefault =
    security &&
    getSecurityAdminDefault(security, AuthDecisionResourceType.Label);

  const labelAccessControls = useCallback(
    () =>
      compileAccessControls(
        //@ts-ignore
        label.id,
        label.rolesByPrivilege,
        labelAuths,
        securityAdminDefault,
      ),
    [label],
  );

  const privilegesByRole = orderBy(
    labelAccessControls(),
    [(role) => role.roleName?.toLowerCase()],
    ['asc'],
  );

  const initialValues: LabelFormValuesType = useMemo(() => {
    const maskMode =
      label?.properties?.[LabelPropertyOptions.FIELD_MASKING]?.payload
        ?.maskMode;
    return {
      name: label?.name || '',
      description: label?.description || '',
      scope: isEmpty(label.allowedResourceTypes)
        ? 'ALL'
        : LabelResourceType.Field,
      mode: {
        enabled:
          maskMode === LabelMaskMode.Null || maskMode === LabelMaskMode.Hide,
        value:
          maskMode === LabelMaskMode.Null || maskMode === LabelMaskMode.Hide
            ? maskMode
            : LabelMaskMode.Null,
      },
      //@ts-ignore
      exemptRoles: label?.rolesByPrivilege?.[Privilege.Select]?.map(
        (role: RoleByPrivilegeType) => {
          return {
            roleName: role.name,
            roleId: role.id,
          };
        },
      ),
      labelAccess: privilegesByRole,
      createPrivs: [],
      updatePrivs: [],
      deletePrivs: [],
    };
  }, [label, user]);

  const nextKeys: string[] = useMemo(
    () =>
      data?.searchRoles?.searchAfterKeys
        ? data?.searchRoles?.searchAfterKeys
        : [],
    [data],
  );
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      //@ts-ignore
      const searchyMcSearch = e?.target?.value || '';
      setSearchQuery(searchyMcSearch);
    },
    [],
  );
  const debouncedSearchChange = debounce(onSearchChange, 500);
  const handleScroll = useCallback(
    (event: React.SyntheticEvent) => {
      const listboxNode = event.currentTarget;
      const position = listboxNode.scrollTop + listboxNode.clientHeight;

      if (
        listboxNode.scrollHeight - position <= 1 &&
        roleOptions?.length < data?.searchRoles?.totalHits
      ) {
        refetch({
          searchAfter: nextKeys.length === 0 ? undefined : nextKeys,
        }).then((refetchGuys) => {
          setRoleOptions((prevRoleOptions) => [
            ...prevRoleOptions,
            ...refetchGuys?.data?.searchRoles?.results,
          ]);
        });
      }
    },
    [nextKeys, data, roleOptions],
  );

  useEffect(() => {
    if (maskingEnabled && searchQuery !== undefined) {
      refetch({
        query: searchQuery !== '' ? searchQuery : '*',
        searchAfter: undefined,
      })
        .then((refetchGuys) =>
          setRoleOptions(refetchGuys?.data?.searchRoles?.results),
        )
        .catch((error) => {
          logger.error('problem querying roles ', error);
        });
    }
  }, [searchQuery, maskingEnabled]);

  useEffect(() => {
    if (isEmpty(roleOptions) && (maskingEnabled || activeTab === 2)) {
      getRoles().then((result) =>
        setRoleOptions(result?.data?.searchRoles?.results),
      );
    }
  }, [maskingEnabled, activeTab, roleOptions]);
  const submitForm = async (
    values: LabelFormValuesType,
    { setSubmitting }: FormikHelpers<any>,
  ) => {
    let promisesPromises = [];

    if (labelOverviewChanges(values, initialValues)) {
      const updateLabelRequest = {
        name: values.name,
        description: values.description,
        allowedResourceTypes: values.scope === 'ALL' ? [] : [values.scope],
      };

      promisesPromises.push(
        updateLabelMutation({
          variables: {
            organizationId,
            labelId: label.id,
            request: updateLabelRequest,
          },
        }),
      );
    }

    if (columnMaskingChanges(values, initialValues)?.changed) {
      promisesPromises.push(
        updateFieldMasking({
          variables: {
            organizationId,
            labelId: label.id,
            request: {
              mode: columnMaskingChanges(values, initialValues)?.newValue,
            },
          },
        }),
      );
    }
    //@ts-ignore
    const authChanges = authorizationChanges(
      values,
      initialValues.exemptRoles,
      privilegesByRole,
      //@ts-ignore
      label.id,
    );
    if (authChanges?.changed) {
      promisesPromises.push(
        labelAuthMutation({
          variables: {
            authsToCreate: authChanges?.auths[0],
            authsToDelete: authChanges?.auths[1],
            authsToUpdate: authChanges?.auths[2],
          },
        }),
      );
    }

    await Promise.allSettled(promisesPromises).then((result) => {
      if (some(result, (response) => response.status === 'rejected')) {
        const rejected = result.find(
          (response) => response.status === 'rejected',
        );
        //@ts-ignore
        enqueueSnackbar(`${rejected?.reason}`, { variant: 'error' });
      }
    });

    await setSubmitting(false);
    onLabelUpdated(values.name);
  };

  return (
    <Box>
      <DialogTitle>{`Edit label`}</DialogTitle>
      <DialogContent
        sx={{
          '& form': {
            minHeight: 682,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={submitForm}
          validationSchema={createLabelValidationSchema}
        >
          {({ isSubmitting, values, touched, errors, setFieldValue }) => (
            <Form>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <TabWrapper
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label={`Create Label`}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                    variant={'standard'}
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        border: 'none',
                        marginRight: '0px',
                        paddingRight: '0px',
                      },
                    }}
                  >
                    <NavTab value={0} key={`overview`} label={`Overview`} />
                    <NavTab value={1} key={`policy`} label={`Policy`} />
                    <NavTab value={2} key={`access`} label={`Access Control`} />
                  </TabWrapper>
                </Box>
                <TabPanel index={0} value={activeTab}>
                  <LabelOverview
                    touched={touched}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    userCanEditOverview={userCanEditOverview}
                  />
                </TabPanel>
                <TabPanel index={1} value={activeTab}>
                  <ColumnMaskingPolicy
                    setFieldValue={setFieldValue}
                    values={values}
                    maskingEnabled={maskingEnabled}
                    setMaskingEnabled={setMaskingEnabled}
                    isSubmitting={isSubmitting}
                    loading={loading}
                    roleOptions={roleOptions}
                    highlighted={highlighted}
                    setHighlighted={setHighlighted}
                    handleScroll={handleScroll}
                    debouncedSearchChange={debouncedSearchChange}
                    showMemberCount={false}
                    userCanEditPolicy={userCanEditPolicy}
                  />
                </TabPanel>
                <TabPanel index={2} value={activeTab}>
                  <LabelAccessControls
                    values={values}
                    initialValues={privilegesByRole}
                    setFieldValue={setFieldValue}
                    loading={loading}
                    labelAccessRoleOptions={labelAccessRoleOptions}
                    highlighted={highlighted}
                    setHighlighted={setHighlighted}
                    handleScroll={handleScroll}
                    showMemberCount={false}
                    userCanEditAccess={userCanEditAccess}
                    debouncedSearchChange={debouncedSearchChange}
                    currentGrants={privilegesWithGrants || []}
                  />
                </TabPanel>
              </Box>
              <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 2,
                }}
              >
                <Box maxWidth={'380px'} overflow={'hidden'}>
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <LabelDisplay
                          hasPolicy={values.mode.enabled}
                          maskMode={
                            values.mode.enabled ? values.mode.value : ''
                          }
                          labelName={values.name ? values.name : 'Label name'}
                          {...bindHover(popupState)}
                        />
                        <Popper
                          {...bindPopper(popupState)}
                          sx={{ zIndex: 9999999 }}
                          placement={`top`}
                        >
                          <LabelPopover
                            description={values.description}
                            name={values.name}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                </Box>
                <Box>
                  <Tabutton
                    sx={{ mr: 1 }}
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    Cancel
                  </Tabutton>
                  <Tabutton
                    type="submit"
                    variant="contained"
                    disabled={
                      isSubmitting || !values.name || Boolean(errors['name'])
                    }
                  >
                    Save Label
                  </Tabutton>
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Box>
  );
}
