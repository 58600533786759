import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import {
  BucketValidationStatus,
  StorageProfile,
  StorageType,
} from '../../graphql/gen/graphql';
import { ChipColors } from '../Chip/Tachip';
import { FullBucketConfigs } from './storage';

export const getMainValidationStatus = (validation: string) => {
  if (validation === 'PASS') {
    return ChipColors.Success;
  } else return ChipColors.Error;
};

const getWarehouseStatus = (
  warehouseList: string[],
  minimumValidation: string[],
  warehouseCount: number | undefined,
) => {
  const success =
    !warehouseList.includes(
      BucketValidationStatus.Fail || BucketValidationStatus.Unchecked,
    ) && !minimumValidation.includes(BucketValidationStatus.Fail);
  const warning =
    warehouseCount === 0 &&
    warehouseList.includes(
      BucketValidationStatus.Fail || BucketValidationStatus.Unchecked,
    ) &&
    !minimumValidation.includes(BucketValidationStatus.Fail);
  const error =
    (warehouseCount &&
      warehouseCount > 0 &&
      warehouseList.includes(BucketValidationStatus.Fail)) ||
    minimumValidation.includes(BucketValidationStatus.Fail);

  if (success) {
    return ChipColors.Success;
  } else if (warning) {
    return ChipColors.Neutral;
  } else if (error) {
    return ChipColors.Error;
  }
  return ChipColors.Neutral;
};

const getDataImportStatus = (
  dataImportList: string[],
  minimumValidation: string[],
) => {
  const success =
    !dataImportList.includes(
      BucketValidationStatus.Fail || BucketValidationStatus.Unchecked,
    ) && !minimumValidation.includes(BucketValidationStatus.Fail);
  const warning =
    dataImportList.includes(BucketValidationStatus.Fail) &&
    !minimumValidation.includes(BucketValidationStatus.Fail);
  const error = minimumValidation.includes(BucketValidationStatus.Fail);
  if (success) {
    return ChipColors.Success;
  } else if (warning) {
    return ChipColors.Neutral;
  } else if (error) {
    return ChipColors.Error;
  }
  return ChipColors.Neutral;
};

const google = '/assets/img/logos/gcp.svg';
const aws = '/assets/img/logos/aws.svg';
const azure = '/assets/img/logos/azure.svg';
export const getImgSrcForStorageType = (storageType: StorageType): string => {
  return storageType === StorageType.Adls
    ? azure
    : storageType === StorageType.Gcs
    ? google
    : aws;
};

export const getSchemeForStorageType = (storageType: StorageType): string => {
  if (!storageType) return '';
  switch (storageType) {
    case 'GCS':
      return 'gs';
    case 'S3':
      return 's3';
    case 'ADLS':
      return 'abfs';
    default:
      return '';
  }
};

export const isValidImportSource = (storageProfile: StorageProfile) => {
  const statusObject = storageProfile?.properties?.validationDetails;
  switch (storageProfile?.storageType) {
    case 'GCS':
      return (
        statusObject?.GetObject == 'PASS' && statusObject?.ListObject == 'PASS'
      );
    case 'ADLS':
      return (
        statusObject?.GetObject == 'PASS' && statusObject?.ListObject == 'PASS'
      );

    case 'S3':
      return (
        statusObject?.GetObject == 'PASS' &&
        statusObject?.ListObject == 'PASS' &&
        statusObject?.AssumeRole == 'PASS' &&
        statusObject?.BucketExists == 'PASS'
      );

    default:
      return false;
  }
};

export const getValidationStatuses = (
  statusList: any,
  warehouseCount?: number,
) => {
  // Validation statuses
  const warehouseList = [
    statusList.DeleteObject,
    statusList.GetObject,
    statusList.GetObjectMetadata,
    statusList.ListObject,
    statusList.MultipartUpload,
    statusList.PutObject,
  ];
  const dataImportList = [statusList.ListObject, statusList.GetObject];
  const minimumValidation = [statusList.AssumeRole, statusList.BucketExists];

  const warehouseStatus = getWarehouseStatus(
    warehouseList,
    minimumValidation,
    warehouseCount,
  );

  const dataImportStatus = getDataImportStatus(
    dataImportList,
    minimumValidation,
  );

  // Missing s3 configs for error messaging
  const getMissingConfigs = Object.entries(statusList);
  const missingWhConfigs = getMissingConfigs
    .filter((status) => status[1] === BucketValidationStatus.Fail)
    .map((configName) => configName[0])
    .filter(
      (config) =>
        config !== 'NoVersionDelete' && config !== 'GetObjectMetadata',
    );
  let s3WhPermissions = [] as any;

  missingWhConfigs &&
    //@ts-ignore
    missingWhConfigs.forEach((element: keyof typeof FullBucketConfigs) =>
      s3WhPermissions.push(FullBucketConfigs[element]),
    );

  const s3DataImportPermissions = missingWhConfigs.filter(
    (config) =>
      config === 'GetObject' ||
      config === 'ListObject' ||
      config === 'BucketExists',
  );

  return {
    warehouse: warehouseStatus,
    dataImportList: dataImportStatus,
    missingWhConfigs: s3WhPermissions,
    missingDataImportConfigs: s3DataImportPermissions,
  };
};

export const getValidationDisplayDetails = (status: ChipColors) => {
  const success = status === ChipColors.Success;
  const error = status === ChipColors.Error;
  const unknown = status === ChipColors.Neutral;
  const warning = status === ChipColors.Neutral;
  const icon = success ? (
    <CheckIcon fontSize={`small`} sx={{ color: 'aurora.seven', mr: 1 }} />
  ) : error ? (
    <ErrorIcon fontSize={`small`} sx={{ color: 'sunset.seven', mr: 1 }} />
  ) : (
    <></>
  );

  const label = success
    ? 'Ready'
    : error
    ? 'Not Ready'
    : warning
    ? 'Not available'
    : 'Unknown';

  return {
    status,
    success,
    warning,
    unknown,
    error,
    label,
    icon,
  };
};
