import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { toOrgRoot } from '../RouteTable';
//@ts-ignore
import AppBarLayout from '../components/Layouts/AppBarLayout';
import { useAuth } from '../context/auth-context';

export default function Home() {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.organizations && user.organizations.length > 0) {
      navigate(toOrgRoot(user.loginSession.loggedInOrg.name));
    }
  }, [user, navigate]);

  return <AppBarLayout />;
}
