import React, {
  DragEvent,
  DragEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';

import {
  Close,
  FileDownloadDoneRounded,
  FolderOutlined,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
  Box,
  BoxProps,
  Button,
  DialogActions,
  Divider,
  IconButton,
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import axios, {
  AxiosHeaderValue,
  AxiosProgressEvent,
  AxiosResponse,
} from 'axios';
import { Form, Formik, FormikValues } from 'formik';

import {
  StorageProfile,
  StorageType,
  Table,
  TableLoadStatus,
} from '../../graphql/gen/graphql';
import { getByteSize } from '../../pages/helpers';
import { getApolloErrorMessage } from '../../utils/ApolloUtils';
import { getUploadBaseUrl } from '../../utils/api';
import { getLogger } from '../../utils/logging';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import { getSchemeForStorageType } from '../CreateStorageBucket/ValidationStatusHelpers';
import {
  StorageBrowserSelectionMode,
  StorageBrowserSelectionModel,
} from '../StorageBrowser/StorageBrowser';

const logger = getLogger(
  'components.FileUpload.fileUpload' /*FUTURE import.meta.url ?*/,
);

function generateSelectionModel(
  response: AxiosResponse,
  expectedFileUploadCount: number,
): StorageBrowserSelectionModel {
  const bucketName = response.data.bucketName;
  const storageType = response.data.storageType;
  const storageProfileId = response.data.storageProfileId;
  const region = response.data.region;
  const stagedTableId = response.data.tableId;
  const fileKeyPaths: string[] = response.data.fileKeyPaths;
  if (!(storageType !== StorageType.S3 || storageType !== StorageType.Gcs)) {
    throw new Error('Unsupported storage type.');
  }
  if (response.data.fileKeyPaths.length != expectedFileUploadCount) {
    throw new Error(
      `Did not successfully transfer all files, ${response.data.fileKeyPaths.length} succeeded but expected ${expectedFileUploadCount}.`,
    );
  }

  return {
    selectionModel: fileKeyPaths,
    selectedPath: '',
    //@ts-ignore
    profile: { id: storageProfileId, bucket: bucketName, region, storageType },
    stagedTableId,
  };
}

export enum FileUploadMode {
  SINGLE,
  MULTIPLE,
}

export interface FileUploadProps extends BoxProps {
  url: string;
  onFinalSelection: (selectionModel: StorageBrowserSelectionModel) => void;
  mode: FileUploadMode;
}

const TOTAL_FILE_SIZE_LIMIT = 5000000000;
export const FileUpload = ({
  url,
  onFinalSelection,
  mode,
  ...props
}: FileUploadProps) => {
  const fileInput = useRef(null);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [files, setFiles] = useState<File[]>([]);
  //state for checking file size
  const [fileSize, setFileSize] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  // for file upload progress message
  const [fileUploadProgress, setFileUploadProgress] = useState<
    AxiosProgressEvent | undefined
  >(undefined);
  //for displaying response message
  const [fileUploadResponse, setFileUploadResponse] = useState<
    AxiosResponse | undefined
  >(undefined);

  const initialValues = { files: '' };

  const removeFile = (row: File) => {
    setFiles((prevState) => [
      ...prevState.filter(
        (eachPrevFile) =>
          !(eachPrevFile.name == row.name && eachPrevFile.size == row.size),
      ),
    ]);
  };
  const addFiles = (newFiles: File[]) => {
    if (mode === FileUploadMode.SINGLE && files.length > 0) return;
    const filesToAdd =
      mode === FileUploadMode.SINGLE ? [newFiles[0]] : newFiles;
    setFiles((prevState) => [
      ...prevState,
      ...filesToAdd.filter(
        (eachNewFile) =>
          !prevState.some(
            (eachPrevFile) =>
              eachPrevFile.name == eachNewFile.name &&
              eachPrevFile.size == eachNewFile.size,
          ),
      ),
    ]);
  };
  const uploadFileHandler:
    | React.ChangeEventHandler<HTMLInputElement>
    | undefined = (event) => {
    if (event.target.files != null) {
      addFiles([...event.target.files]);
    }
  };

  const fileSubmitHandler = useCallback(
    async (values: FormikValues) => {
      setFileSize(true);

      setFileUploadResponse(undefined);

      const formData = new FormData();
      if (files.length == 0) return;

      let totalSize = 0;

      for (let i = 0; i < files.length; i++) {
        totalSize += files[i].size;
        if (totalSize > TOTAL_FILE_SIZE_LIMIT) {
          setFileSize(false);
          setFileUploadProgress(undefined);
          setFileUploadResponse(undefined);
          return;
        }

        formData.append(`files`, files[i]);
      }

      const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
        setFileUploadProgress(progressEvent);
        logger.info(
          `got progress!! ${progressEvent.progress! * 100}`,
          progressEvent,
        );
      };
      axios
        .post(`${url}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
          onUploadProgress,
        })
        .then((response) => {
          logger.info('Done uploading uploading files!', response);
          setFileUploadResponse(response);
          try {
            onFinalSelection(generateSelectionModel(response, files.length));
          } catch (e: any) {
            setError(e.message);
          } finally {
            setFileUploadResponse(undefined);
            setFileUploadProgress(undefined);
            setFiles([]);
          }
        })
        .catch((error) => {
          logger.error('Error while uploading files!', error);
          setError(error.message);
          setFileUploadResponse(undefined);
          setFileUploadProgress(undefined);
        });
    },
    [files, fileSize, onFinalSelection, url],
  );

  const percentage =
    fileUploadProgress && fileUploadProgress.progress
      ? Math.round(100 * fileUploadProgress.progress)
      : 0;

  const handleDropHandler = useCallback(
    (ev: DragEvent<HTMLDivElement>) => {
      logger.log('File(s) dropped');
      setIsDragging(false);

      if (
        ev === null ||
        ev.dataTransfer === null ||
        fileUploadProgress != undefined
      )
        return;

      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
      if (ev.dataTransfer.files != null) {
        addFiles([...ev.dataTransfer.files]);
      }
    },
    [files],
  );

  const totalFilesSize = [...(files ? files : [])].reduce(
    (accumulator, currentValue) => accumulator + currentValue.size,
    0,
  );
  return (
    <Box
      {...props}
      sx={[
        props.sx as SystemStyleObject,
        {
          display: 'flex',
          flexDirection: 'column',

          '& .dropzone': {
            borderWidth: 2,
            borderStyle: 'dashed',
            borderColor: 'midnight.three',
          },
        },
      ]}
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragging(true);
      }}
      onDragLeave={(e) => {
        setIsDragging(false);
      }}
      onDrop={handleDropHandler}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',

          flexDirection: 'column',
        }}
      >
        {error ? (
          <Talert
            severity="error"
            sx={{ width: 1 }}
            onClose={() => {
              setError(undefined);
              setFileUploadResponse(undefined);
              setFileUploadProgress(undefined);
            }}
          >
            Error:
            {error}
          </Talert>
        ) : null}
        <Formik initialValues={initialValues} onSubmit={fileSubmitHandler}>
          {({ isSubmitting, submitForm, setFieldValue, values }) => (
            <Form
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
              }}
            >
              <Box
                className={isDragging ? 'dropzone' : ''}
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={(theme) => ({
                    flexGrow: 1,
                    minHeight: 200,
                    maxHeight: '30%',
                    mb: 2,
                    pt: 2,
                    borderWidth: 1,
                    borderColor: 'midnight.two',
                    borderStyle: 'solid',
                    backgroundColor: 'midnight.half',
                    borderRadius: '8px',
                    boxShadow: theme.shadows[3],

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  })}
                >
                  <Box
                    sx={{
                      flexGrow: 1,

                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="outlined"
                        component="label"
                        sx={{ mb: 1 }}
                        disabled={
                          fileUploadProgress != undefined ||
                          (mode === FileUploadMode.SINGLE && files.length > 0)
                        }
                      >
                        <InputAdornment position="start">
                          <FolderOutlined fontSize={'medium'} />
                        </InputAdornment>
                        Select files
                        <input
                          ref={fileInput}
                          id={'files'}
                          name={'files'}
                          type="file"
                          multiple={mode === FileUploadMode.MULTIPLE}
                          hidden
                          onChange={uploadFileHandler}
                        />
                      </Button>
                    </Box>

                    <Divider sx={{ width: 0.3 }} light>
                      <Typography variant={'body2'} color={'midnight.seven'}>
                        Or drag and drop files here
                      </Typography>
                    </Divider>
                    <Box
                      sx={{
                        m: 2,
                        borderRadius: 56,
                        backgroundColor: 'sky.half',
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="80"
                        height="80"
                        viewBox="0 0 80 80"
                        fill="none"
                      >
                        <path
                          d="M55.3001 31.6666H50.0001V15C50.0001 13.1666 48.5001 11.6666 46.6667 11.6666H33.3334C31.5001 11.6666 30.0001 13.1666 30.0001 15V31.6666H24.7001C21.7334 31.6666 20.2334 35.2666 22.3334 37.3666L37.6334 52.6666C38.9334 53.9666 41.0334 53.9666 42.3334 52.6666L57.6334 37.3666C59.7334 35.2666 58.2667 31.6666 55.3001 31.6666ZM16.6667 65C16.6667 66.8333 18.1667 68.3333 20.0001 68.3333H60.0001C61.8334 68.3333 63.3334 66.8333 63.3334 65C63.3334 63.1666 61.8334 61.6666 60.0001 61.6666H20.0001C18.1667 61.6666 16.6667 63.1666 16.6667 65Z"
                          fill="#246B8F"
                        />
                      </svg>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',

                    flexGrow: 2,
                    overflowY: 'auto',
                  }}
                >
                  {files.length > 0 && (
                    <TableContainer
                      component={Box}
                      sx={(theme) => ({
                        //flexGrow: 1,
                        maxHeight: '66%',
                        width: 1,
                        borderWidth: 1,
                        borderColor: 'midnight.two',
                        borderStyle: 'solid',
                        backgroundColor: 'midnight.half',
                        borderRadius: '8px',
                        boxShadow: theme.shadows[3],
                      })}
                    >
                      <MUITable aria-label="simple table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Selection</TableCell>
                            <TableCell align="right">Size</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {[...files].map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="right">
                                {getByteSize(row.size)}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={(event) => {
                                    removeFile(row);
                                  }}
                                >
                                  <CloseIcon
                                    fontSize={'small'}
                                    sx={{
                                      color: 'midnight.six',
                                      pointer: 'cursor',
                                    }}
                                  />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter
                          key={'final_row'}
                          sx={{
                            border: 0,
                          }}
                        >
                          <TableRow>
                            <TableCell component="th" scope="row" align="right">
                              Total size
                            </TableCell>
                            <TableCell align="right">
                              {getByteSize(totalFilesSize)}
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </MUITable>
                    </TableContainer>
                  )}
                </Box>

                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    pr: 2,
                    pb: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    backgroundColor: 'controlBackground.main',
                  }}
                >
                  {totalFilesSize > TOTAL_FILE_SIZE_LIMIT && (
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography style={{ color: 'red' }}>
                        File size exceeded!! Please select files totaling less
                        than {getByteSize(TOTAL_FILE_SIZE_LIMIT)}
                      </Typography>
                    </Box>
                  )}
                  {fileUploadProgress && (
                    <Box sx={{ flexGrow: 1 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="body1" color="text.secondary">
                          {}
                        </Typography>

                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >{`${getByteSize(
                          fileUploadProgress?.loaded
                            ? fileUploadProgress?.loaded
                            : 0,
                        )}/${getByteSize(
                          fileUploadProgress?.total!,
                        )}`}</Typography>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{ width: '100%' }}>
                          <LinearProgress
                            variant="determinate"
                            value={percentage}
                          />
                        </Box>
                        <Box
                          sx={{
                            minWidth: 35,
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                          >{`${percentage}%`}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <DialogActions>
                    <Tabutton
                      size={'medium'}
                      disabled={
                        files.length == 0 || fileUploadProgress != undefined
                      }
                      onClick={() => {
                        setFiles([]);
                        if (fileInput != null && fileInput.current != null) {
                          //@ts-ignore
                          fileInput.current.value = null;
                        }
                      }}
                    >
                      Clear selection
                    </Tabutton>
                    <Tabutton
                      size={'medium'}
                      onClick={() => {
                        submitForm();
                      }}
                      variant="contained"
                      disabled={
                        files.length == 0 ||
                        fileUploadProgress != undefined ||
                        totalFilesSize > TOTAL_FILE_SIZE_LIMIT
                      }
                    >
                      {files.length > 0
                        ? `Upload ${files?.length} file${
                            files?.length == 1 ? '' : 's'
                          }`
                        : 'Upload'}
                    </Tabutton>
                  </DialogActions>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
