import React, { useEffect, useState } from 'react';
// @ts-ignore
import { useUserAgent } from 'react-ua';

import { useLazyQuery, useMutation } from '@apollo/client';
import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Grid, Link, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// @ts-ignore
import { Simulate } from 'react-dom/test-utils';

import { Secret, StorageType, Warehouse } from '../../../graphql/gen/graphql';
import {
  createOrgCredential,
  orgCredentialUsage,
} from '../../../graphql/organization';
import { Talert } from '../../Alert/Talert';
import { Tabutton } from '../../Button/Tabutton';
import { CustomIcon } from '../../Icons/CustomIcon';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../WizardContainer';
import { CreateCredentialStep } from '../components/CreateCredentialStep';
import { TrinoClientCmdTemplate } from './TrinoClientCmdTemplate';
import { TrinoCmdTemplate } from './TrinoCmdTemplate';
import { TrinoDeleteContainerCmdTemplate } from './TrinoDeleteContainerCmdTemplate';
import { TrinoDownloadImageCmdTemplate } from './TrinoDownloadImageCmdTemplate';
import { TrinoGCSPermission } from './TrinoGCSPermission';
import { TrinoSQLTemplate } from './TrinoSQLTemplate';

const TrinoConnectionWizardDialog = create<{
  organizationId: string;
  organizationName: string;
  warehouse: Warehouse;
}>(({ warehouse, organizationName, organizationId }) => {
  const modal = useModal();
  const ua = useUserAgent();
  const [createOrgCredentialMutation] = useMutation(createOrgCredential);
  const [getOrgCredentialUsage, { data, loading, error, stopPolling }] =
    useLazyQuery(orgCredentialUsage);
  const [credential, setCredential] = useState<Secret>();
  const [errorStatus, setErrorStatus] = useState();
  const [credentialUsed, setCredentialUsed] = useState<boolean>(false);

  const [trinoDownloadImageCommand, setTrinoDownloadImageCommand] =
    useState<string>();
  const [trinoCommand, setTrinoCommand] = useState<string>();
  const [trinoClientCommand, setTrinoClientCommand] = useState<string>();
  const [trinoDeleteContainerCommand, setTrinoDeleteContainerCommand] =
    useState<string>();

  const createCredential = async () => {
    if (!credential && !errorStatus) {
      return createOrgCredentialMutation({
        variables: {
          organizationId: organizationId,
          name: 'Trino Connection Credential',
          description: 'Built with the Trino Connection Flow',
        },
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {
          if (data) {
            setCredential(data.data.createOrgCredential);
          }
        });
    }
  };

  const pollCredUsage = async () => {
    if (credential) {
      getOrgCredentialUsage({
        variables: {
          organizationId: organizationId,
          credentialKey: credential.credential.split(':')[0],
        },
        pollInterval: 2000,
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {});
    }
  };

  useEffect(() => {
    if (data?.organizationCredential?.lastSession) {
      setCredentialUsed(true);
      stopPolling();
    }
  }, [data]);

  const terminalName =
    ua.os.name === 'Windows'
      ? 'power shell'
      : ua.os.name === 'Mac OS'
      ? 'terminal'
      : 'shell';

  const gcsType = warehouse?.storageProfile?.storageType === StorageType.Gcs;
  const projectId = warehouse?.storageProfile?.properties?.[`gcp.project.id`];
  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: 'Prerequisites',
        body: (
          <Box>
            <Box sx={{}}>
              <Box sx={{ width: 0.95 }}>
                <Grid
                  container
                  sx={{
                    width: 1,
                    border: 1,
                    borderColor: 'midnight.two',
                    borderRadius: '8px',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1 / 2,
                      borderRadius: '8px',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottom: 1,
                      borderColor: 'midnight.two',
                      backgroundColor: 'dusk.half',
                    }}
                  >
                    <Typography variant={'body1'}>
                      {`Install Docker Desktop`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1,

                      borderBottom: 1,
                      borderColor: 'midnight.two',
                    }}
                  >
                    <Typography sx={{ display: 'inline-block', mr: 1, gap: 1 }}>
                      {`If you dont already have Docker Desktop installed, please download and install `}

                      <Link
                        href={process.env.REACT_APP_DOCKER_DESKTOP_DOWNLOAD_URL}
                        aria-label="Download Docker Desktop"
                        sx={{
                          textDecoration: 'none',
                        }}
                      >
                        Docker Desktop.
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1 / 2,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography variant={'body1'}>
                      {/* @ts-ignore */}
                      <Link
                        external
                        href={process.env.REACT_APP_DOCKER_DESKTOP_DOWNLOAD_URL}
                        aria-label="Download Docker Desktop"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          maxWidth: 350,
                          textDecoration: 'none',
                        }}
                      >
                        {' '}
                        <Tabutton
                          size={`small`}
                          endIcon={<OpenInNewIcon />}
                          sx={{ mt: 2 }}
                        >
                          Download Docker Desktop
                        </Tabutton>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    mt: 4,
                    border: 1,
                    borderColor: 'midnight.two',
                    borderRadius: '8px',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1 / 2,
                      borderRadius: '8px',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottom: 1,
                      borderColor: 'midnight.two',
                      backgroundColor: 'dusk.half',
                    }}
                  >
                    <Typography variant={'body1'}>
                      {`Download Tabular's Trino Docker image in a ${terminalName} by running the following command.`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      p: 0,
                      borderBottom: 1,
                      borderColor: 'midnight.two',
                    }}
                  >
                    <TrinoDownloadImageCmdTemplate
                      sx={{ width: 1, height: 1 }}
                      trinoImageName={
                        process.env.REACT_APP_TRINO_IMAGE_NAME || ''
                      }
                      onCommandFormed={(cmd) =>
                        setTrinoDownloadImageCommand(cmd)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1 / 2,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography variant={'body1'}>
                      <Tabutton
                        onClick={() =>
                          navigator.clipboard.writeText(
                            trinoDownloadImageCommand || '',
                          )
                        }
                        size={'small'}
                        title="Copy to clipboard"
                        sx={{ alignSelf: 'center' }}
                        endIcon={<FileCopyIcon />}
                      >
                        Copy Command
                      </Tabutton>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        ),
      },
      ...(gcsType
        ? [
            {
              title: 'GSC permissions',
              body: <TrinoGCSPermission projectId={projectId} />,
            },
          ]
        : []),
      {
        title: 'Create credential',
        disallowContinue: !credential,
        effect: createCredential,
        body: (
          <CreateCredentialStep
            organizationName={organizationName}
            connectionName={'Trino'}
            credential={credential?.credential}
            errorStatus={errorStatus}
          />
        ),
      },
      {
        title: 'Run Trino server',
        description: `Copy / Paste your command.`,
        body: (
          <>
            <Box
              sx={{ mt: 0, display: 'flex', flexDirection: 'column', width: 1 }}
            >
              <Typography sx={{ mb: 2 }}>
                {`
            First, make sure you launch Docker Desktop.`}
              </Typography>
              <Typography>
                {`
            Execute the following command in your 
            ${
              ua.os.name === 'Windows'
                ? 'power shell'
                : ua.os.name === 'Mac OS'
                ? 'terminal'
                : 'shell'
            }.
             `}
              </Typography>
              <Grid
                container
                sx={{
                  width: 0.95,
                  mt: 3,
                  border: 1,
                  borderColor: 'midnight.two',
                  borderRadius: '8px',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: 1,
                    borderRadius: '8px',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottom: 1,
                    borderColor: 'midnight.two',
                    backgroundColor: 'dusk.half',
                  }}
                >
                  <Typography
                    variant={'subtitle1'}
                    sx={{}}
                  >{`Docker command to run the Tabular Trino image`}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    borderBottom: 1,
                    borderColor: 'midnight.two',
                  }}
                >
                  <TrinoCmdTemplate
                    warehouse={warehouse}
                    restCatalogEndpoint={
                      process.env.REACT_APP_REST_CATALOG_URL || ''
                    }
                    trinoImageName={
                      process.env.REACT_APP_TRINO_IMAGE_NAME || ''
                    }
                    credential={credential?.credential || ''}
                    sx={{ width: 1, height: 1 }}
                    onCommandFormed={(cmd) => setTrinoCommand(cmd)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: 1 / 2,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography variant={'body1'}>
                    {/* @ts-ignore */}
                    <Tabutton
                      onClick={() =>
                        navigator.clipboard.writeText(trinoCommand || '')
                      }
                      title="Copy to clipboard"
                      size={'small'}
                      sx={{ alignSelf: 'center' }}
                      endIcon={<FileCopyIcon />}
                    >
                      Copy Command
                    </Tabutton>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </>
        ),
      },
      {
        title: 'Query example data',
        effect: pollCredUsage,
        body: (
          <>
            <Box sx={{ width: 0.5 }}>
              {!credentialUsed && (
                <Talert color="neutral">
                  Watching for connection, please perform the steps below...
                </Talert>
              )}
              {credentialUsed && (
                <Talert severity="success">
                  Successful connection detected!
                </Talert>
              )}
            </Box>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column',
                width: 0.98,
              }}
            >
              <Grid
                container
                sx={{
                  width: 0.95,
                  mt: 2,
                  border: 1,
                  borderColor: 'midnight.two',
                  borderRadius: '8px',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: 2,
                    borderRadius: '8px',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottom: 1,
                    borderColor: 'midnight.two',
                    backgroundColor: 'dusk.half',
                  }}
                >
                  <Typography variant={'subtitle1'} sx={{}}>
                    {`Run Trino client in the Docker Image in your ${terminalName}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    borderBottom: 1,
                    borderColor: 'midnight.two',
                  }}
                >
                  <TrinoClientCmdTemplate
                    warehouse={warehouse}
                    onCommandFormed={(cmd) => setTrinoClientCommand(cmd)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: 0.5,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography variant={'body1'}>
                    {/* @ts-ignore */}
                    <Tabutton
                      onClick={() =>
                        navigator.clipboard.writeText(trinoClientCommand || '')
                      }
                      title="Copy to clipboard"
                      size={'small'}
                      sx={{ alignSelf: 'center' }}
                      endIcon={<FileCopyIcon />}
                    >
                      Copy Command
                    </Tabutton>
                  </Typography>
                </Grid>
              </Grid>

              <TrinoSQLTemplate sx={{ width: 1 }} />

              <Grid
                container
                sx={{
                  width: 0.95,
                  mt: 2,
                  border: 1,
                  borderColor: 'midnight.two',
                  borderRadius: '8px',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: 2,
                    borderRadius: '8px',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottom: 1,
                    borderColor: 'midnight.two',
                    backgroundColor: 'dusk.half',
                  }}
                >
                  <Typography variant={'subtitle1'} sx={{}}>
                    {`If you are done, delete your Tabular Trino docker container in your ${terminalName}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    borderBottom: 1,
                    borderColor: 'midnight.two',
                  }}
                >
                  <TrinoDeleteContainerCmdTemplate
                    warehouse={warehouse}
                    onCommandFormed={(cmd) =>
                      setTrinoDeleteContainerCommand(cmd)
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: 0.5,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography variant={'body1'}>
                    {/* @ts-ignore */}
                    <Tabutton
                      onClick={() =>
                        navigator.clipboard.writeText(
                          trinoDeleteContainerCommand || '',
                        )
                      }
                      title="Copy to clipboard"
                      size={'small'}
                      sx={{ alignSelf: 'center' }}
                      endIcon={<FileCopyIcon />}
                    >
                      Copy Command
                    </Tabutton>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </>
        ),
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <WizardContainer
        title={'Download Trino'}
        stepsTitle={warehouse.name}
        stepsSubtitle={`Connect Trino`}
        stepsIcon={
          <CustomIcon
            src={'/assets/img/logos/trino-icon.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        backButtonClick={() => {}}
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        sx={{ minHeight: '550px', width: 1, height: '98vh' }}
      />
    </Dialog>
  );
});

export function showTrinoConnectionWizardDialog({
  warehouse,
  organizationName,
  organizationId,
}: {
  warehouse: Warehouse;
  organizationName: string;
  organizationId: string;
}) {
  return show(TrinoConnectionWizardDialog, {
    warehouse,
    organizationName,
    organizationId,
  });
}
