import React, { ReactElement } from 'react';

import { Stack, Box, BoxProps, Divider } from '@mui/material';

import { DropdownMenuItem } from './DropdownMenuItem';

export interface DropdownMenuContentProps extends BoxProps {
  menuItems: ReactElement<any, any>[];
}

export const DropdownMenuContent = ({
  menuItems,
}: DropdownMenuContentProps): ReactElement => {
  return (
    <Box>
      {menuItems.map((eachMenuItem, index) => (
        <Box key={`dditem_${index}`}>
          {eachMenuItem}
          {index < menuItems.length - 1 && <Divider />}
        </Box>
      ))}
    </Box>
  );
};
