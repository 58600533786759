import { gql } from '@apollo/client';

import { FRAGMENTS } from './fragments';

export const fetchWebPropertiesQuery = gql`
  query FetchWebProperties {
    fetchWebProperties
  }
`;

export const fetchVersionQuery = gql`
  query FetchVersion {
    fetchVersion
  }
`;

export const bucketValidator = gql`
  mutation BucketValidatorMutation(
    $region: String!
    $bucket: String!
    $roleARN: String!
    $externalId: String!
  ) {
    bucketValidator(
      region: $region
      bucket: $bucket
      roleARN: $roleARN
      externalId: $externalId
    ) {
      bucketExistsStatus {
        checkPassed
        status
        type
        message
      }
      assumeRoleStatus {
        checkPassed
        status
        type
        message
      }
      putObjectStatus {
        checkPassed
        status
        type
        message
      }
      listObjectStatus {
        checkPassed
        status
        type
        message
      }
      getObjectStatus {
        checkPassed
        status
        type
        message
      }
      getObjMetadataStatus {
        checkPassed
        status
        type
        message
      }
      deleteObjectStatus {
        checkPassed
        status
        type
        message
      }
      multipartUploadStatus {
        checkPassed
        status
        type
        message
      }
      noVersionDeleteStatus {
        checkPassed
        status
        type
        message
      }
    }
  }
`;
