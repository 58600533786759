import React from 'react';

import {
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { Table } from '../../../graphql/gen/graphql';
import { ChangeDataCapture } from '../../../schema/events/change-data-capture';
import { Event } from '../../../schema/events/event';
import { Failed } from '../../../schema/events/failed';
import { NamespaceEntity } from '../../../schema/events/namespace';
import { TableReferenceEntity } from '../../../schema/events/table.reference';
import { TableEventTypes, Verbosity } from '../TableEventsView';
import FailedServiceDetails from './FailedServiceDetails';

type Props = {
  event: Event;
  table: Table;
  verbosity?: Verbosity;
};
export default function ChangeDataCaptureView({
  event,
  table,
  verbosity = Verbosity.Summary,
}: Props) {
  const changeDataCapture = event.context.find(
    (ctx) => ctx.type === 'ChangeDataCapture',
  ) as ChangeDataCapture;

  const sourceTables = [];

  if (changeDataCapture.sources) {
    changeDataCapture.sources.forEach((s) => {
      sourceTables.push(
        (s.source_database as NamespaceEntity).name +
          '.' +
          (s.source_table_reference as TableReferenceEntity).name,
      );
    });
  } else {
    sourceTables.push(
      (changeDataCapture.source_database as NamespaceEntity).name +
        '.' +
        (changeDataCapture.source_table_reference as TableReferenceEntity).name,
    );
  }

  let changeDataCaptureMessage = (function () {
    switch (event.type) {
      case TableEventTypes.RUN_CHANGE_DATA_CAPTURE:
        return 'Scheduled';
      case TableEventTypes.STARTED_CHANGE_DATA_CAPTURE:
        return 'Started';
      case TableEventTypes.SUCCEEDED_CHANGE_DATA_CAPTURE:
        return 'Succeeded';
      default:
        return 'Failed';
    }
  })();

  switch (verbosity) {
    case Verbosity.Summary:
      return <div>CDC Job Run {changeDataCaptureMessage}</div>;
    case Verbosity.Detailed:
      if (event.type == TableEventTypes.FAILED_CHANGE_DATA_CAPTURE) {
        return <FailedServiceDetails event={event as Failed} table={table} />;
      } else {
        return (
          <>
            <Grid container margin={2}>
              <Grid item xs={12} md={6} lg={4}>
                <TableContainer component={Paper}>
                  <MuiTable aria-label="Listing">
                    <TableBody>
                      <TableRow>
                        <TableCell variant="head">CDC Type:</TableCell>
                        <TableCell>{changeDataCapture.cdc_type}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">Source Tables</TableCell>
                        <TableCell>{sourceTables.join(', ')}</TableCell>
                      </TableRow>
                    </TableBody>
                  </MuiTable>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        );
      }
    default:
      return null;
  }
}
