import React from 'react';

import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import { Tabutton } from '../Button/Tabutton';
import { PrivilegeCheck } from '../DatabaseSettings/PrivilegeCheck';
import TextField from '../Forms/TextField';

interface TypeDeleteDialogProps {
  title: string;
  recoveryWarningText: string;
  confirmText: string;
  privilegeCheck?: any;
  privilegeCheckResults?: any;
  undroppedTables?: any;
  numOfTables?: number;
}
const TypeDeleteDialog = create(
  ({
    title,
    recoveryWarningText,
    confirmText,
    privilegeCheck,
    privilegeCheckResults,
    undroppedTables,
    numOfTables,
  }: TypeDeleteDialogProps) => {
    const modal = useModal();
    return (
      <Dialog open={modal.visible}>
        <Formik
          initialValues={{ typeDelete: '' }}
          validationSchema={Yup.object({
            typeDelete: Yup.string()
              .required('You must type DELETE in this field.')
              .matches(new RegExp('(DELETE)', 'i'), {
                message: 'Enter the text exactly as displayed to confirm.',
                excludeEmptyString: false,
              }),
          })}
          enableReinitialize
          validateOnMount
          validateOnChange
          onSubmit={() => {
            modal.resolve(true); //nuke it from orbit...
            modal.hide();
          }}
        >
          {({ resetForm, errors }) => (
            <Form>
              <DialogTitle>
                {title}{' '}
                <IconButton
                  aria-label="close"
                  onClick={modal.hide}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              {privilegeCheck && (
                <Box margin={2}>
                  <PrivilegeCheck
                    results={privilegeCheckResults}
                    undroppedTables={undroppedTables}
                    numOfTables={numOfTables}
                  />
                </Box>
              )}
              {!privilegeCheck ||
              (privilegeCheck &&
                privilegeCheckResults.database &&
                privilegeCheckResults.tables &&
                isEmpty(undroppedTables)) ? (
                <>
                  <DialogContent>
                    <Box
                      sx={(theme) => ({
                        border: `1px solid ${theme.palette.sunrise.two}`,
                        borderRadius: '4px',
                        backgroundColor: theme.palette.sunrise.half,
                        p: 2,
                        display: 'flex',
                        mb: 2,
                      })}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pr: 2,
                        }}
                      >
                        <WarningIcon sx={{ color: 'sunrise.seven' }} />
                      </Box>
                      <Box sx={{ color: 'midnight.seven' }}>
                        <Typography variant={`subtitle2`} mb={`4px`}>
                          Warning
                        </Typography>
                        <Typography variant={`body2`}>
                          {recoveryWarningText}
                        </Typography>
                      </Box>
                    </Box>
                    <TextField
                      name={`typeDelete`}
                      label={`Type DELETE to confirm`}
                      fullWidth
                      autoFocus
                    />
                  </DialogContent>
                  <DialogActions
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                    }}
                  >
                    <Tabutton
                      onClick={() => {
                        resetForm();
                        modal.hide();
                      }}
                    >
                      Cancel
                    </Tabutton>
                    <Tabutton
                      type={`submit`}
                      color="error"
                      variant="contained"
                      disabled={Boolean(errors.typeDelete)}
                      loadingBtn
                    >
                      {confirmText}
                    </Tabutton>
                  </DialogActions>
                </>
              ) : null}
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  },
);

export function showTypeDeleteDialog(args: TypeDeleteDialogProps) {
  return show<boolean, any, TypeDeleteDialogProps>(TypeDeleteDialog, args);
}
