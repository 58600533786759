import React, { useContext, useMemo } from 'react';

import { Box, BoxProps, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { KnownFeatureFlags } from '../../context/GlobalPropsContext';

import { GlobalPropertiesContext } from '../../context';
import { useAuth } from '../../context/auth-context';
import { Warehouse, StorageType } from '../../graphql/gen/graphql';
import { Talert } from '../Alert/Talert';
import { showWarehouseSelectionDialog } from '../Modals/WarehouseSelectionDialog';
import Tile, { TileProps } from '../TileSelector/Tile';
import { showAthenaConnectionWizardDialog } from '../Wizards/athena/AthenaConnectionWizardDialog';
import { showAthenaSQLConnectionWizardDialog } from '../Wizards/athena/AthenaSQLConnectionWizardDialog';
import { showBigQueryWizardDialog } from '../Wizards/bigQuery/BigQueryWizardDialog';
import { showCelerDataWizardDialog } from '../Wizards/celerdata/CelerDataWizardDialog';
import { showDatabricksConnectionWizardDialog } from '../Wizards/databricks/DatabricksConnectionWizardDialog';
import { showEMRWizardDialog } from '../Wizards/emr/EMRWizardDialog';
import { showGlueWizardDialog } from '../Wizards/glue/GlueConnectionWizardDialog';
import { showRedshiftWizardDialog } from '../Wizards/redshift/RedshiftConnectionWizardDialog';
import { showSnowflakeDialog } from '../Wizards/snowflake/SnowflakeWizardDialog';
import { showSparkConnectionWizardDialog } from '../Wizards/spark/SparkConnectionWizardDialog';
import { showStarburstConnectionWizardDialog } from '../Wizards/starburst/StarburstConnectionWizardDialog';
import { showTrinoConnectionWizardDialog } from '../Wizards/trino/TrinoConnectionWizardDialog';
import ComputeConnectionsGrid from './ComputeConnectionsGrid';

interface ComputeGridProps extends BoxProps {
  warehouses: Warehouse[];
  modalView?: boolean;
  handleClose?: () => void;
  refetch: any;
}

export default function ComputeGrid({
  warehouses,
  modalView,
  handleClose,
  refetch,
  ...props
}: ComputeGridProps) {
  const theme = useTheme();
  const { user } = useAuth();
  const organizationName = user.loginSession.loggedInOrg.name;
  const organizationId = user.loginSession.loggedInOrg.id;
  const { ff } = useContext(GlobalPropertiesContext);
  const gcsTestingEnabled = ff(KnownFeatureFlags.GCS_TESTING_ENABLED);
  const isSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(organizationName),
    [user, organizationName],
  );

  const warehouseNamesWithoutSpaces = useMemo(
    () => warehouses?.filter((warehouse) => !warehouse?.name.includes(' ')),
    [warehouses],
  );

  const awsWarehouses = useMemo(
    () =>
      warehouseNamesWithoutSpaces?.filter(
        (warehouse) =>
          warehouse?.storageProfile?.storageType === StorageType.S3,
      ),
    [warehouseNamesWithoutSpaces],
  );

  const glueWarehouses = useMemo(
    () =>
      awsWarehouses?.filter(
        (i) =>
          i.properties?.find((item) => item?.key === 'mirror.glue.enabled')
            ?.value !== 'true',
      ),
    [awsWarehouses],
  );

  const googleWarehouses = useMemo(
    () =>
      warehouses?.filter(
        (warehouse) =>
          warehouse?.storageProfile?.storageType === StorageType.Gcs,
      ),
    [warehouses],
  );

  const bigQueryWarehouses = useMemo(
    () =>
      googleWarehouses?.filter(
        (i) =>
          i.properties?.find((item) => item?.key === 'mirror.bigquery.enabled')
            ?.value !== 'true',
      ),
    [googleWarehouses],
  );

  const snowflakeWarehouses = useMemo(
    () =>
      gcsTestingEnabled
        ? warehouseNamesWithoutSpaces?.filter(
            (warehouse) =>
              warehouse.properties?.find(
                (prop) =>
                  prop?.key === 'mirror.snowflake.enabled' ||
                  prop?.key === 'mirror.snowflake.configured',
              )?.value !== 'true',
          )
        : awsWarehouses?.filter(
            (warehouse) =>
              warehouse.properties?.find(
                (prop) =>
                  prop?.key === 'mirror.snowflake.enabled' ||
                  prop?.key === 'mirror.snowflake.configured',
              )?.value !== 'true',
          ),
    [warehouseNamesWithoutSpaces, awsWarehouses],
  );

  const warehouseSelected = warehouses?.length === 1;
  const awsWarehouseSelected = awsWarehouses?.length === 1;
  const bigQueryWarehouseSelected = bigQueryWarehouses?.length === 1;
  const glueWarehouseSelected = glueWarehouses?.length === 1;
  const snowflakeWarehouseSelected = snowflakeWarehouses?.length === 1;
  const disableAwsSyncs =
    warehouses?.length === 0 ||
    glueWarehouses?.length <= 0 ||
    (glueWarehouses?.length > 0 && !isSecurityAdmin);
  const mirrorEnabledWh = useMemo(
    () =>
      warehouses?.filter((item) =>
        item?.properties?.some((prop) => prop?.key.includes('mirror')),
      ),
    [warehouses],
  );

  const handleStarburstClick = () => {
    handleClose && handleClose();

    awsWarehouseSelected
      ? showStarburstConnectionWizardDialog({
          warehouse: awsWarehouses[0],
          organizationName,
          organizationId,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          awsWarehouses,
          'Select an AWS warehouse to connect to Starburst Galaxy',
        ).then((value) => {
          showStarburstConnectionWizardDialog({
            warehouse: value,
            organizationName,
            organizationId,
          }).then((res) => {});
        });
  };

  const handleTrinoClick = () => {
    handleClose && handleClose();

    warehouseSelected
      ? showTrinoConnectionWizardDialog({
          warehouse: warehouses[0],
          organizationName,
          organizationId,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          warehouses,
          'Select a warehouse to connect to Trino',
        ).then((value) => {
          showTrinoConnectionWizardDialog({
            warehouse: value,
            organizationName,
            organizationId,
          }).then(() => {});
        });
  };

  const handleSparkClick = () => {
    handleClose && handleClose();

    warehouseSelected
      ? showSparkConnectionWizardDialog({
          warehouse: warehouses[0],
          organizationName,
          organizationId,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          warehouses,
          'Select a warehouse to connect to Spark',
        ).then((value) => {
          showSparkConnectionWizardDialog({
            warehouse: value,
            organizationName,
            organizationId,
          }).then(() => {});
        });
  };

  const handleAthenaClick = () => {
    handleClose && handleClose();

    awsWarehouseSelected
      ? showAthenaConnectionWizardDialog({
          warehouse: awsWarehouses[0],
          organizationName,
          organizationId,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          awsWarehouses,
          'Select an AWS warehouse to connect to Athena',
        ).then((value) => {
          showAthenaConnectionWizardDialog({
            warehouse: value,
            organizationName,
            organizationId,
          }).then(() => {});
        });
  };

  const handleAthenaSqlClick = () => {
    handleClose && handleClose();

    awsWarehouseSelected
      ? showAthenaSQLConnectionWizardDialog({
          warehouse: awsWarehouses[0],
          organizationName,
          organizationId,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          awsWarehouses,
          'Select AWS warehouse to connect to Athena',
        ).then((value) => {
          showAthenaSQLConnectionWizardDialog({
            warehouse: value,
            organizationName,
            organizationId,
          }).then(() => {});
        });
  };
  const handleCelerDataClick = () => {
    handleClose && handleClose();

    awsWarehouseSelected
      ? showCelerDataWizardDialog({
          warehouse: awsWarehouses[0],
          organizationName,
          organizationId,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          awsWarehouses,
          'Select an AWS warehouse to connect to CelerData',
        ).then((value) => {
          showCelerDataWizardDialog({
            warehouse: value,
            organizationName,
            organizationId,
          }).then(() => {});
        });
  };
  const handleDatabricksClick = () => {
    handleClose && handleClose();
    awsWarehouseSelected
      ? showDatabricksConnectionWizardDialog({
          warehouse: awsWarehouses[0],
          organizationName,
          organizationId,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          awsWarehouses,
          'Select an AWS warehouse to connect to Databricks',
        ).then((value) => {
          showDatabricksConnectionWizardDialog({
            warehouse: value,
            organizationName,
            organizationId,
          }).then(() => {});
        });
  };
  const handleBigQueryClick = () => {
    handleClose && handleClose();

    bigQueryWarehouseSelected
      ? showBigQueryWizardDialog({
          warehouse: bigQueryWarehouses[0],
          organizationName,
          refetch: refetch,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          bigQueryWarehouses,
          'Select GCS warehouse to sync to BigQuery',
        ).then((value) => {
          showBigQueryWizardDialog({
            warehouse: value,
            organizationName,
            refetch: refetch,
          }).then(() => {});
        });
  };

  const handleGlueClick = () => {
    handleClose && handleClose();

    glueWarehouseSelected
      ? showGlueWizardDialog({
          warehouse: glueWarehouses[0],
          organizationName,
          refetch: refetch,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          glueWarehouses,
          'Select AWS warehouse to sync to Glue',
        ).then((value) => {
          showGlueWizardDialog({
            warehouse: value,
            organizationName,
            refetch: refetch,
          }).then(() => {});
        });
  };

  const handleEMRWizardClick = () => {
    handleClose && handleClose();

    awsWarehouseSelected
      ? showEMRWizardDialog({
          warehouse: awsWarehouses[0],
          organizationName,
          organizationId,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          awsWarehouses,
          'Select an AWS warehouse to connect to Amazon EMR',
        ).then((value) => {
          showEMRWizardDialog({
            warehouse: value,
            organizationName,
            organizationId,
          }).then(() => {});
        });
  };

  const handleSnowflakeClick = () => {
    handleClose && handleClose();

    snowflakeWarehouseSelected
      ? showSnowflakeDialog({
          warehouse: snowflakeWarehouses[0],
          refetch: refetch,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          snowflakeWarehouses,
          'Select warehouse to sync to Snowflake',
        ).then((value) => {
          showSnowflakeDialog({
            warehouse: value,
            refetch: refetch,
          }).then(() => {});
        });
  };

  const handleRedshiftClick = () => {
    handleClose && handleClose();

    glueWarehouseSelected
      ? showRedshiftWizardDialog({
          warehouse: glueWarehouses[0],
          organizationName,
          refetch: refetch,
        }).then(() => {})
      : showWarehouseSelectionDialog(
          glueWarehouses,
          'Select AWS warehouse to sync to Redshift',
        ).then((value) => {
          showRedshiftWizardDialog({
            warehouse: value,
            organizationName,
            refetch: refetch,
          }).then(() => {});
        });
  };

  const fullConnectionTiles: TileProps[] = [
    {
      id: 'athena_sql',
      logoImgUrl: '/assets/img/logos/athena-sql.svg',
      logoAlt: 'Athena SQL Logo',
      handleButtonClick: () => {
        handleAthenaSqlClick();
      },
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Amazon Athena SQL</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect Athena to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: warehouses?.length === 0 || awsWarehouses?.length === 0,
      toolTipTitle:
        awsWarehouses?.length === 0 && warehouses?.length > 0
          ? 'A warehouse hosted on AWS is required for Athena SQL'
          : warehouses?.length === 0
          ? 'Create an AWS hosted warehouse to connect to Amazon EMR'
          : '',
    },
    {
      id: 'athena_pyspark',
      logoImgUrl: '/assets/img/logos/athena-pyspark.svg',
      logoAlt: 'Athena Pyspark Logo',
      handleButtonClick: handleAthenaClick,
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Amazon Athena PySpark</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect Athena to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: warehouses?.length === 0 || awsWarehouses?.length === 0,
      toolTipTitle:
        awsWarehouses?.length === 0 && warehouses?.length > 0
          ? 'A warehouse hosted on AWS is required for Athena Pyspark'
          : warehouses?.length === 0
          ? 'Create an AWS hosted warehouse to connect to Athena Pyspark'
          : '',
    },
    {
      id: 'amazon_emr',
      logoImgUrl: '/assets/img/logos/amazon-emr.svg',
      logoAlt: 'Amazon EMR Logo',
      handleButtonClick: handleEMRWizardClick,
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Amazon EMR Spark</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect Amazon EMR to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: warehouses?.length === 0 || awsWarehouses?.length === 0,
      toolTipTitle:
        awsWarehouses?.length === 0 && warehouses?.length > 0
          ? 'A warehouse hosted on AWS is required for Amazon EMR'
          : warehouses?.length === 0
          ? 'Create an AWS hosted warehouse to connect to Amazon EMR'
          : '',
    },
    {
      id: 'spark',
      logoImgUrl: '/assets/img/logos/spark-icon.svg',
      logoAlt: 'Spark Logo',
      handleButtonClick: handleSparkClick,
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Spark</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect Spark to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: warehouses?.length === 0,
      toolTipTitle:
        warehouses?.length === 0
          ? 'Create a warehouse to connect to Spark'
          : '',
    },
    {
      id: 'trino',
      logoImgUrl: '/assets/img/logos/trino-icon.svg',
      logoAlt: 'Trino Logo',

      handleButtonClick: handleTrinoClick,
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Trino</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect Trino to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: warehouses?.length === 0,
      toolTipTitle:
        warehouses?.length === 0
          ? 'Create a warehouse to connect to Trino'
          : '',
    },
    {
      id: 'starburst',
      logoImgUrl:
        theme.palette.mode === 'light'
          ? '/assets/img/logos/starburst-icon-light.svg'
          : '/assets/img/logos/starburst-icon-dark.svg',
      logoAlt: 'Starburst Galaxy Logo',
      handleButtonClick: handleStarburstClick,
      body: (
        <>
          <Box>
            <Typography variant={'subtitle1'}>Starburst Galaxy</Typography>
            <Typography variant={'body2'} color={'midnight.seven'}>
              Connect Starburst to a Tabular warehouse.
            </Typography>
          </Box>
        </>
      ),
      disable: warehouses?.length === 0 || awsWarehouses?.length === 0,
      toolTipTitle:
        awsWarehouses?.length === 0 && warehouses?.length > 0
          ? 'This connection is only currently compatible with an AWS hosted warehouse'
          : warehouses?.length === 0
          ? 'Create an AWS hosted warehouse to connect to Starburst'
          : '',
    },
    {
      id: 'celerdata',
      logoImgUrl: '/assets/img/logos/celerdata.svg',
      logoAlt: 'CelerData logo',
      handleButtonClick: () => {
        handleCelerDataClick();
      },
      body: (
        <Box>
          <Typography variant={'subtitle1'}>CelerData</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect CelerData to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: warehouses?.length === 0 || awsWarehouses?.length === 0,
      toolTipTitle:
        awsWarehouses?.length === 0 && warehouses?.length > 0
          ? 'This connection is only currently compatible with an AWS hosted warehouse'
          : warehouses?.length === 0
          ? 'Create an AWS hosted warehouse to connect to CelerData'
          : '',
    },
    {
      id: 'databricks',
      logoImgUrl: '/assets/img/logos/databricks.svg',
      logoAlt: 'Databricks logo',
      handleButtonClick: () => {
        handleDatabricksClick();
      },
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Databricks</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect Databricks to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: warehouses?.length === 0 || awsWarehouses?.length === 0,
      toolTipTitle:
        awsWarehouses?.length === 0 && warehouses?.length > 0
          ? 'This connection is only currently compatible with an AWS hosted warehouse'
          : warehouses?.length === 0
          ? 'Create an AWS hosted warehouse to connect to Databricks'
          : '',
    },
  ];

  const mirrorTiles: TileProps[] = [
    {
      id: 'aws_glue',
      logoImgUrl: '/assets/img/logos/amazon-glue.svg',
      logoAlt: 'Amazon Glue Logo',
      handleButtonClick: () => {
        handleGlueClick();
      },
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Amazon Glue</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect Amazon Glue to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: disableAwsSyncs,
      toolTipTitle:
        awsWarehouses?.length === 0 &&
        isSecurityAdmin &&
        warehouses?.length !== 0
          ? 'An AWS hosted warehouse is required for Glue'
          : glueWarehouses?.length === 0 &&
            isSecurityAdmin &&
            warehouses?.length > 0
          ? 'All available warehouses have been synced'
          : warehouses?.length === 0
          ? 'Create an AWS hosted warehouse to connect to Glue'
          : 'Glue syncing is restricted to SECURITY_ADMIN',
    },
    {
      id: 'aws_redshift',
      logoImgUrl: '/assets/img/logos/amazon-redshift.svg',
      logoAlt: 'Amazon Redshift Logo',
      handleButtonClick: () => {
        handleRedshiftClick();
      },
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Amazon Redshift</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect Amazon Redshift to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: disableAwsSyncs,
      toolTipTitle:
        awsWarehouses?.length === 0 &&
        isSecurityAdmin &&
        warehouses?.length !== 0
          ? 'A warehouse hosted on S3 is required for Glue'
          : glueWarehouses?.length === 0 &&
            isSecurityAdmin &&
            warehouses?.length > 0
          ? 'All available warehouses have been synced'
          : warehouses?.length === 0
          ? 'Create an AWS hosted warehouse to connect to Glue'
          : 'Redshift syncing is restricted to SECURITY_ADMIN',
    },
    {
      id: 'big_query',
      logoImgUrl: '/assets/img/logos/big-query.svg',
      logoAlt: 'BigQuery Logo',
      handleButtonClick: () => {
        handleBigQueryClick();
      },
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Big Query</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect BigQuery to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable:
        warehouses?.length === 0 ||
        bigQueryWarehouses?.length <= 0 ||
        (bigQueryWarehouses?.length > 0 && !isSecurityAdmin),
      toolTipTitle:
        googleWarehouses?.length === 0 && isSecurityAdmin
          ? 'A warehouse hosted on GCS is required for BigQuery'
          : bigQueryWarehouses?.length === 0 &&
            isSecurityAdmin &&
            warehouses?.length > 0
          ? 'All available warehouses have been sync'
          : warehouses?.length === 0
          ? 'Create a GCS hosted warehouse to connect to BigQuery'
          : 'BigQuery syncing is restricted to SECURITY_ADMIN',
    },
    {
      id: 'snowflake',
      logoImgUrl: '/assets/img/logos/snowflake.svg',
      logoAlt: 'Snowflake Logo',
      handleButtonClick: handleSnowflakeClick,
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Snowflake</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect Snowflake to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: warehouses?.length === 0 || !isSecurityAdmin,
      toolTipTitle:
        awsWarehouses?.length === 0 &&
        warehouses?.length !== 0 &&
        isSecurityAdmin
          ? 'A warehouse hosted on AWS is required for Snowflake'
          : warehouses?.length === 0
          ? 'Create an AWS hosted warehouse to connect to BigQuery'
          : 'Snowflake syncing is restricted to SECURITY_ADMIN',
    },
    {
      id: 'databricks',
      logoImgUrl: '/assets/img/logos/databricks.svg',
      logoAlt: 'Databricks Logo',
      handleButtonClick: () => {},
      body: (
        <Box>
          <Typography variant={'subtitle1'}>Databricks</Typography>
          <Typography variant={'body2'} color={'midnight.seven'}>
            Connect Databricks to a Tabular warehouse.
          </Typography>
        </Box>
      ),
      disable: true,
      toolTipTitle: 'For more information, please contact Tabular support',
    },
  ];

  return !modalView ? (
    <>
      <Grid
        container
        justifyItems={'center'}
        spacing={5}
        columnSpacing={3}
        marginBottom={4}
      >
        {fullConnectionTiles.map((eachTileProps) => {
          return (
            <Grid
              key={eachTileProps.id}
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Tile key={`${eachTileProps.id}_tile`} {...eachTileProps} />
            </Grid>
          );
        })}
      </Grid>
      <Typography variant="h4" paddingBottom={2}>
        Catalog syncs
      </Typography>
      <Talert
        severity={'info'}
        alertTitle={'Note'}
        sx={{ mb: 4, width: 'fit-content', maxWidth: '50%' }}
      >
        {`Your catalog will stay in sync with Tabular, but
                  access controls will not carry over. Restrict access
                  within compute engine.`}
      </Talert>
      <Grid
        container
        justifyItems={'center'}
        spacing={5}
        columnSpacing={3}
        marginBottom={4}
      >
        {mirrorTiles.map((eachTileProps) => {
          return (
            <Grid
              key={eachTileProps.id}
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
            >
              <Tile key={`${eachTileProps.id}_tile`} {...eachTileProps} />
            </Grid>
          );
        })}
      </Grid>
      {mirrorEnabledWh && mirrorEnabledWh.length > 0 && (
        <ComputeConnectionsGrid
          warehouses={mirrorEnabledWh}
          orgName={organizationName}
          refetch={refetch}
          isSecurityAdmin={isSecurityAdmin}
        />
      )}
    </>
  ) : (
    <>
      <Grid
        container
        alignSelf={'center'}
        marginY={2}
        width={'80%'}
        paddingBottom={3}
        rowSpacing={2}
      >
        {fullConnectionTiles.map((eachTileProps) => (
          <Grid key={eachTileProps.id} item xs={12} alignItems={'center'}>
            <Tile key={`${eachTileProps.id}_tile`} {...eachTileProps} />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h5" alignSelf={'center'} width={'80%'}>
        Catalog syncs
      </Typography>
      <Grid
        container
        alignSelf={'center'}
        marginY={2}
        width={'80%'}
        paddingBottom={3}
        rowSpacing={2}
      >
        <Grid item>
          <Talert
            severity={'info'}
            alertTitle={'Note'}
            sx={{
              maxWidth: '94%',
            }}
          >
            {`Your catalog will stay in sync with Tabular, but
                  access controls will not carry over. Restrict access
                               within compute engine.`}
          </Talert>
        </Grid>
        {mirrorTiles.map((eachTileProps) => (
          <Grid key={eachTileProps.id} item xs={12} alignItems={'center'}>
            <Tile key={`${eachTileProps.id}_tile`} {...eachTileProps} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
