import React, { useCallback, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

import { Table, TableMetadata, Warehouse } from '../../graphql/gen/graphql';
import {
  fetchTableLoadStatusQuery,
  generateTableMetaDataMutation,
  loadTableData,
} from '../../graphql/table';
import { getApolloErrorMessage } from '../../utils/ApolloUtils';
import { getUploadBaseUrl } from '../../utils/api';
import { getLogger } from '../../utils/logging';
import { Talert } from '../Alert/Talert';
import HeadsUpDisplay from '../Layouts/HeadsUpDisplay';
import { CDCPipelineCreationSummary } from '../TableEdit/CDCPipelineCreationSummary';
import ChooseTableSource, {
  SourceSelection,
  UserTableIntent,
} from '../TableEdit/ChooseTableSource';
import TableEdit, {
  CDCPipelineCreatedContext,
  EditTableMode,
  TableEditedContext,
} from '../TableEdit/TableEdit';
import { findSchemaFields } from '../TableEdit/TableEditHelpers';

const logger = getLogger(
  'components.TableEdit.EditTableDialog' /*FUTURE import.meta.url ?*/,
);
interface MetadataGeneratedEvent {
  metadata: TableMetadata;
  tableNames: string[];
  stagedTableId?: string;
}
interface EditTableDialogProps {
  organizationId: string;
  warehouse: Warehouse;
  database: string;
  tableName?: string;
  table?: Table;
  breadcrumbs: any;
  mode: EditTableMode;
  source?: string;
}
const EditTableDialog = create(
  ({
    organizationId,
    warehouse,
    database,
    tableName,
    table,
    breadcrumbs,
    mode,
    source,
  }: EditTableDialogProps) => {
    const modal = useModal();
    const warehouseId = warehouse.id;
    const [creationMetadata, setCreationMetadata] = useState<
      MetadataGeneratedEvent | undefined
    >(undefined);
    const [generateTableMetaData, { loading }] = useMutation(
      generateTableMetaDataMutation,
      {},
    );
    const [error, setError] = useState<string | undefined>(undefined);
    const [tableCreatedContext, setTableCreatedContext] = useState<
      TableEditedContext | CDCPipelineCreatedContext | undefined
    >(undefined);
    const [navToTable, setNavToTable] = useState<Table | undefined>(undefined);

    const [sourceSelectionForLoad, setSourceSelectionForLoad] = useState<
      SourceSelection | undefined
    >(undefined);

    const [showFinalOutcome, setShowFinalOutcome] = useState<boolean>(false);

    const [
      loadTableDataMutation,
      { error: loadTableDataError, reset: loadTableDataReset },
    ] = useMutation(loadTableData);

    const creatingFromScratchOrReadyWithSource =
      !(
        mode === EditTableMode.CREATE_LOAD ||
        mode === EditTableMode.CREATE_LIKE ||
        mode === EditTableMode.CREATE_CDC_PIPELINE
      ) ||
      ((mode === EditTableMode.CREATE_LOAD ||
        mode === EditTableMode.CREATE_LIKE ||
        mode === EditTableMode.CREATE_CDC_PIPELINE) &&
        creationMetadata != undefined);

    const uploadUrl = useMemo(() => {
      return `${getUploadBaseUrl()}/organizations/${organizationId}/warehouses/${warehouseId}/namespaces/${database}/stages`;
    }, [organizationId, warehouseId, database]);

    const handleLoadTable = useCallback(
      async (
        selection: SourceSelection,
        context: TableEditedContext | CDCPipelineCreatedContext,
        navTable: Table,
      ) => {
        loadTableDataMutation({
          variables: {
            organizationId,
            warehouseId: warehouse.id,
            tableReferenceId: context.table.id,
            request: {
              storageProfileId: selection.storageProfileId,
              prefixes: selection.keyPaths,
              region: selection.region,
              tableLoadMode: selection.tableLoadMode,

              ...(mode === EditTableMode.CREATE_CDC_PIPELINE &&
              'targetTable' in context
                ? {
                    onCompletionProperties: [
                      {
                        name: 'dependent-tables',
                        value: `${context.targetTable?.databaseName}.${context.targetTable?.name}`,
                      },
                    ],
                  }
                : {}),

              fileLoaderConfig: {
                format: selection.fileFormat,
                delim:
                  selection.delimiter === '\\t' ? '\t' : selection.delimiter,
                csvEscape: selection.escape === '\\' ? '\\' : selection.escape,
                csvDateFormat: selection.dateFormat,
                csvTimestampFormat: selection.timestampFormat,
                csvTimestampFormatNtz: selection.timestampNTZFormat,
                hasHeader: selection.hasHeader,
                fileExcludeGlobFilter: selection.fileExcludeGlobFilter,
                ...(mode === EditTableMode.CREATE_CDC_PIPELINE
                  ? {
                      loadSerially: true,
                      evolveSchema: true,
                    }
                  : {}),
              },
            },
          },
        })
          .then((result) => {
            if (mode === EditTableMode.CREATE_CDC_PIPELINE) {
              setNavToTable(navTable);
              setShowFinalOutcome(true);
            } else {
              modal.resolve(navTable);
              modal.hide();
            }
          })
          .catch((ex) => {
            logger.error('problem submitting form', ex);
            setError(getApolloErrorMessage(ex));
          });
      },
      [organizationId, warehouse, table],
    );

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={modal.visible}
        onClose={(event, reason) => {
          if (
            (reason && reason == 'backdropClick') ||
            (reason && reason == 'escapeKeyDown')
          )
            return;

          modal.hide();
        }}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        sx={{
          backdropFilter: 'blur(2px) brightness(30%)',
          '& .MuiDialog-paper': { maxWidth: 10000 },
        }}
      >
        <Box sx={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
          {breadcrumbs && (
            <Box sx={{ position: 'sticky', top: 0, width: 1, zIndex: 20000 }}>
              <HeadsUpDisplay
                breadcrumbs={breadcrumbs}
                readonly={true}
                sx={{
                  position: 'static',
                  backgroundColor: 'brandBlue.main',
                  color: 'white',
                  minHeight: 56,
                  '& .resource-type': {
                    backgroundColor: 'sky.eight',
                    color: 'primary.contrastText',
                    pointerEvents: 'none',
                  },
                  '& .breadcrumb-wrapper a': {
                    color: 'primary.contrastText',
                  },
                  '& ol li:first-of-type .breadcrumb-wrapper a': {
                    color: 'primary.contrastText',
                    pointerEvents: 'none',
                  },
                }}
              />
              <IconButton
                aria-label="close"
                onClick={modal.hide}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  backgroundColor: 'transparent',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}

          <Typography variant={'h4'} sx={{ mt: 3, ml: 3, mb: 1 }}>
            {mode == EditTableMode.CREATE
              ? 'Create a table'
              : mode == EditTableMode.CREATE_LIKE
              ? 'Create table like'
              : mode == EditTableMode.CREATE_LOAD
              ? 'Create table as'
              : mode == EditTableMode.CREATE_CDC_PIPELINE
              ? 'Create CDC pipeline'
              : 'Alter table'}
          </Typography>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
          >
            {!creatingFromScratchOrReadyWithSource && (
              <>
                {error ? (
                  <Talert
                    severity="error"
                    sx={{ width: 1 }}
                    onClose={() => {
                      setError(undefined);
                    }}
                  >
                    Error:
                    {error}
                  </Talert>
                ) : null}
                <ChooseTableSource
                  organizationId={organizationId}
                  warehouseId={warehouseId}
                  database={database}
                  tableName={tableName ? tableName : ''}
                  source={source!}
                  sourceProps={{ uploadUrl }}
                  onCancel={() => {
                    modal.hide();
                  }}
                  intent={
                    mode == EditTableMode.CREATE_CDC_PIPELINE
                      ? UserTableIntent.CREATE_CDC
                      : UserTableIntent.CREATE
                  }
                  onSelection={(event: SourceSelection) => {
                    logger.debug(
                      'Got final event! Calling TableMetaData',
                      event.keyPaths,
                    );

                    const promise = new Promise<boolean>((resolve, reject) => {
                      if (
                        mode === EditTableMode.CREATE_LOAD ||
                        mode === EditTableMode.CREATE_CDC_PIPELINE
                      ) {
                        setSourceSelectionForLoad(event);
                      }

                      generateTableMetaData({
                        variables: {
                          organizationId,
                          warehouseId,
                          databaseName: database,
                          keyPaths: event.keyPaths,
                          storageProfileId: event.storageProfileId,
                          stagedTableId: event.stagedTableId,
                          fileFormat: event.fileFormat,
                          delimiter: event.delimiter,
                          escape: event.escape,
                          dateFormat: event.dateFormat,
                          timestampFormat: event.timestampFormat,
                          timestampNTZFormat: event.timestampNTZFormat,
                          hasHeader: event.hasHeader,
                          useMaps: event.useMaps,
                          inferPartitionsFromPath:
                            event.inferPartitionsFromPath,
                        },
                      })
                        .then((result) => {
                          logger.debug(
                            'completed generation of TableMetaData ',
                            result,
                          );

                          if (mode === EditTableMode.CREATE_CDC_PIPELINE) {
                            // const checkSchema = getSchemaModelFromMetadata(
                            //   result?.data?.generateMetaData?.metadata,
                            //   undefined,
                            // );
                            const schemaFields = findSchemaFields(
                              [],
                              result?.data?.generateMetaData?.metadata
                                ?.current_schema,
                              true,
                            );
                          }

                          setCreationMetadata({
                            metadata: result.data.generateMetaData.metadata,
                            tableNames: result.data.generateMetaData.tableNames,
                            stagedTableId: event.stagedTableId,
                          });
                          resolve(true);
                        })
                        .catch((ex) => {
                          logger.error(
                            'Error while generating TableMetaData!',
                            ex,
                          );

                          reject(ex);
                        });
                    });

                    return promise;
                  }}
                  regionFilter={[warehouse.region]}
                  storageTypeFilter={[warehouse.storageProfile?.storageType!]}
                  sx={{ flexGrow: 1 }}
                  subTitles={
                    mode === EditTableMode.CREATE_CDC_PIPELINE
                      ? [
                          `Select a folder containing CDC files representing changes to your table.`,
                          `Choose the type of change files found in your selected path.`,
                        ]
                      : [
                          `Select a folder or file that represents your table.`,
                          `The following selection will be inspected in order to create your table.`,
                        ]
                  }
                  parentIsLoading={!!creationMetadata}
                  parentIsError={!!error}
                />
              </>
            )}
            {showFinalOutcome && (
              <CDCPipelineCreationSummary
                sx={{ m: 4, flexGrow: 1 }}
                warehouse={warehouse}
                changelogTable={
                  (tableCreatedContext as CDCPipelineCreatedContext).table
                }
                targetTable={
                  (tableCreatedContext as CDCPipelineCreatedContext).targetTable
                }
                onClose={() => {
                  modal.resolve(
                    (tableCreatedContext as CDCPipelineCreatedContext).table,
                  );
                  modal.hide();
                }}
              />
            )}
            {creatingFromScratchOrReadyWithSource && !showFinalOutcome && (
              <TableEdit
                warehouseId={warehouseId}
                database={database}
                onCancel={modal.hide}
                onBack={() => {
                  setCreationMetadata(undefined);
                }}
                onTableEdited={(context) => {
                  if (
                    mode === EditTableMode.CREATE_LOAD ||
                    mode === EditTableMode.CREATE_CDC_PIPELINE
                  ) {
                    setTableCreatedContext(context);

                    handleLoadTable(
                      sourceSelectionForLoad!,
                      context,
                      //leaving this in case we want to switch back to sending the client to the target table.
                      'targetTable' in context ? context.table : context.table,
                    );
                  } else {
                    modal.resolve(context.table);
                    modal.hide();
                  }
                }}
                mode={mode}
                newTableName={
                  tableName
                    ? tableName
                    : creationMetadata &&
                      creationMetadata?.tableNames?.length == 1 &&
                      !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
                        creationMetadata?.tableNames[0],
                      )
                    ? creationMetadata?.tableNames[0].replace('-', '_')
                    : undefined
                }
                table={table}
                creationMetadata={creationMetadata?.metadata}
                stagedTableId={creationMetadata?.stagedTableId}
              />
            )}
          </DialogContent>
        </Box>
      </Dialog>
    );
  },
);

export default EditTableDialog;

export function showCreateTableDialog(
  organizationId: string,
  warehouse: Warehouse,
  database: string,
  breadcrumbs: any,
) {
  return show<Table, any, EditTableDialogProps>(EditTableDialog, {
    organizationId,
    warehouse,
    database,
    breadcrumbs,
    mode: EditTableMode.CREATE,
  });
}
export function showCreateTableLikeDialog(
  organizationId: string,
  warehouse: Warehouse,
  database: string,
  breadcrumbs: any,
  source: string,
) {
  return show<Table, any, EditTableDialogProps>(EditTableDialog, {
    organizationId,
    warehouse,
    database,
    breadcrumbs,
    mode: EditTableMode.CREATE_LIKE,
    source,
  });
}

export function showCreateTableLoadDialog(
  organizationId: string,
  warehouse: Warehouse,
  database: string,
  breadcrumbs: any,
  source: string,
) {
  return show<Table, any, EditTableDialogProps>(EditTableDialog, {
    organizationId,
    warehouse,
    database,
    breadcrumbs,
    mode: EditTableMode.CREATE_LOAD,
    source,
  });
}

export function showCreateCDCPipelineDialog(
  organizationId: string,
  warehouse: Warehouse,
  database: string,
  breadcrumbs: any,
  source: string,
) {
  return show<Table, any, EditTableDialogProps>(EditTableDialog, {
    organizationId,
    warehouse,
    database,
    breadcrumbs,
    mode: EditTableMode.CREATE_CDC_PIPELINE,
    source,
  });
}

export function showEditTableDialog(
  organizationId: string,
  warehouse: Warehouse,
  database: string,
  tableName: string,
  table: Table,
  breadcrumbs: any,
) {
  return show<Table, any, EditTableDialogProps>(EditTableDialog, {
    organizationId,
    warehouse,
    database,
    tableName,
    table,
    breadcrumbs,
    mode: EditTableMode.EDIT,
  });
}
