import React from 'react';

import { create, show, useModal } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';

import { StorageProfile } from '../../graphql/gen/graphql';
import ChooseBucketType from '../CreateStorageBucket/ChooseBucketType';

interface CreateStorageBucketDialogProps {
  orgName: string;
  warehouseName?: string;
  preSelectedBucketType?: string;
}
const CreateStorageBucketDialog = create(
  ({
    orgName,
    warehouseName,
    preSelectedBucketType,
  }: CreateStorageBucketDialogProps) => {
    const modal = useModal();

    return (
      <Dialog
        open={modal.visible}
        scroll={'paper'}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <ChooseBucketType
          onCancel={() => {
            modal.reject();
            modal.hide();
          }}
          orgName={orgName}
          onStorageProfileCreated={(storage) => {
            modal.resolve(storage);
            modal.hide();
          }}
          warehouseName={warehouseName}
          preSelectedBucketType={preSelectedBucketType}
        />
      </Dialog>
    );
  },
);

export default CreateStorageBucketDialog;

export function showCreateStorageBucketDialog(
  orgName: string,
  warehouseName?: string,
  preSelectedBucketType?: string,
) {
  return show<StorageProfile, any, CreateStorageBucketDialogProps>(
    CreateStorageBucketDialog,
    {
      orgName,
      warehouseName,
      preSelectedBucketType,
    },
  );
}
