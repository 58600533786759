import React, { useEffect } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import { useUserAgent } from 'react-ua';

// @ts-ignore
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Grid, Stack, StackProps } from '@mui/material';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// @ts-ignore
import { Organization, Warehouse } from '../../../graphql/gen/graphql';
import { Tabutton } from '../../Button/Tabutton';

interface TrinoDeleteContainerCmdTemplateProps extends StackProps {
  warehouse: Warehouse;
  onCommandFormed?: (cmd: string) => void;
}
export const TrinoDeleteContainerCmdTemplate = ({
  warehouse,
  onCommandFormed,
  ...props
}: TrinoDeleteContainerCmdTemplateProps): JSX.Element => {
  const cmdTemplate = `docker stop trino-tabular
docker rm trino-tabular`;
  useEffect(() => {
    if (onCommandFormed) {
      onCommandFormed(cmdTemplate);
    }
  }, [cmdTemplate]);

  return (
    <Stack direction="column" {...props}>
      <Grid container width={1} alignItems={'center'}>
        <Grid
          item
          xs={12}
          sx={{
            pre: {
              m: 0,
              height: 1,
            },
          }}
        >
          {' '}
          <SyntaxHighlighter
            language="bash"
            style={{ ...dracula, margin: 0 as React.CSSProperties }}
            showLineNumbers={false}
          >
            {cmdTemplate}
          </SyntaxHighlighter>
        </Grid>
      </Grid>
    </Stack>
  );
};
