import { useEffect } from 'react';

const useWafListener = (onCaptchaComplete: () => void) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (event.data === 'wafSuccess') {
        if (onCaptchaComplete) {
          onCaptchaComplete();
        }
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);
};

export default useWafListener;
