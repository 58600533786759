import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { concat } from 'lodash';

import {
  toDatabaseRoot,
  toStorageProfile,
  toTableRoot,
  toWarehouseRoot,
} from '../../RouteTable';

export const isCurrentMenuItem = (link: any, resourceName: string) => {
  return link.dropdown.current === resourceName;
};

export const hrefMappingEnum = Object.freeze({
  WAREHOUSE: { hrefHelper: toWarehouseRoot },
  DATABASE: { hrefHelper: toDatabaseRoot },
  TABLE: { hrefHelper: toTableRoot },
  STORAGE_PROFILE: { hrefHelper: toStorageProfile },
});

export const configureHref = (type: string, hrefParams: any, newItem: any) => {
  const completeHref = concat(hrefParams, newItem);
  //@ts-ignore
  return hrefMappingEnum[type].hrefHelper(...completeHref);
};

export const formatDropdownContent = (
  title: string,
  data: any,
  link: any,
  type: string,
  hrefParams: any,
) => {
  return (
    <Stack direction="column">
      <Box sx={{ px: 2, py: 1 }}>
        <Typography sx={{ color: 'midnight.nine' }} variant={`subtitle2`}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ my: 1, px: 2 }}>
        <Divider />
      </Box>
      <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
        <nav>
          {data.map((item: any) => (
            <Link
              key={item.name}
              variant={`inputText`}
              href={configureHref(type, hrefParams, item.name)}
              className={`breadcrumb-dropdown-link `}
              sx={(theme) => ({
                backgroundColor: isCurrentMenuItem(link, item.name)
                  ? theme.palette.dusk.one
                  : 'transparent',
                color: isCurrentMenuItem(link, item.name)
                  ? theme.palette.midnight.nine
                  : theme.palette.sky.seven,
                borderTop: isCurrentMenuItem(link, item.name)
                  ? `1px solid ${theme.palette.midnight.two}`
                  : 'none',
                borderBottom: isCurrentMenuItem(link, item.name)
                  ? `1px solid ${theme.palette.midnight.two}`
                  : 'none',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                flex: '1 1 100%',
                px: 2,
                py: 1,
              })}
            >
              {isCurrentMenuItem(link, item.name) && (
                <ArrowForwardIcon fontSize={`small`} sx={{ mr: 1 }} />
              )}
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: 270,
                  overflow: 'hidden',
                }}
                variant={`body2`}
              >
                {item.name}
              </Typography>
            </Link>
          ))}
        </nav>
      </Box>
    </Stack>
  );
};
