import React from 'react';

import { useQuery } from '@apollo/client';
import { AvatarGroup, Box, Popper } from '@mui/material';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import UserInfo from '../../../components/ProfileMenu/UserInfo';
import { getRolesByOrgIdQuery } from '../../../graphql/role';
import { Tavatar } from '../../Avatar/Tavatar';
import { AthenaError } from './components/AthenaError';

export const AthenaErrorsNonSA = ({
  organizationId,
  displayName,
}: {
  organizationId: string;
  displayName: string;
}) => {
  const { data, loading, error } = useQuery(getRolesByOrgIdQuery, {
    variables: {
      organizationId,
      roleNameFilter: 'SECURITY_ADMIN',
    },
  });
  const members = data?.organization?.roles[0]?.users;

  const AvatarRows = (
    <AvatarGroup
      max={7}
      sx={{
        '.MuiAvatar-root:last-child': {
          marginLeft: '-8px',
        },
      }}
    >
      {members &&
        !loading &&
        members.map((member: any) => (
          <PopupState
            key={`popover-${members.id}-${member.id}`}
            variant="popper"
          >
            {(popupState) => (
              <>
                <Box {...bindHover(popupState)}>
                  <Tavatar
                    profilePic={member?.properties?.['picture.url']}
                    displayName={member?.displayName || ''}
                    currentUser={displayName === member.displayName}
                    roleAdmin={member?.isAdmin || false}
                  />
                </Box>
                <Popper
                  style={{ zIndex: 1300 }}
                  {...bindPopper(popupState)}
                  placement={'top'}
                >
                  <Box
                    sx={(theme) => ({
                      backgroundColor: 'sky.one',
                      borderRadius: '4px',
                      boxShadow: theme.shadows[3],
                    })}
                  >
                    <UserInfo showAdmin={member.isAdmin} user={member} />
                  </Box>
                </Popper>
              </>
            )}
          </PopupState>
        ))}
    </AvatarGroup>
  );

  return (
    <Box>
      <AthenaError
        message={
          'Contact a security admin to setup your AWS IAM identity provider in Connections > Security: '
        }
        avatarRow={AvatarRows}
        loading={loading}
        error={error}
      />
    </Box>
  );
};
