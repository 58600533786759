import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { posthog } from 'posthog-js';
import { createRoot } from 'react-dom/client';

import App from './App';
import { AppProviders } from './context';
import { getApiUrl } from './utils/api';

const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true';
if (sentryEnabled) {
  const apiUrl = getApiUrl();

  const routingInstrumentation = Sentry.reactRouterV6Instrumentation(
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  );
  const browserTracing = new Sentry.BrowserTracing({
    routingInstrumentation,
  });
  const posthogSentryIntegration = new posthog.SentryIntegration(
    posthog,
    'tabular-io',
    Number(process.env.REACT_APP_SENTRY_PROJECT_ID),
  );
  Sentry.init({
    tracePropagationTargets: [
      'localhost',
      'tabular.io',
      'tabulardata.io',
      /^\//,
    ],
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [browserTracing, posthogSentryIntegration],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE),
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: `tabular@${process.env.REACT_APP_VERSION_TAG}`,

    // Prevent duplicate fetch breadcrumbs for apollo-link-sentry
    beforeBreadcrumb: (breadcrumb) => {
      if (breadcrumb.category === 'fetch') {
        const url = breadcrumb.data?.url ?? '';
        if (url.includes(apiUrl)) {
          return null;
        }
      }
      return breadcrumb;
    },
    beforeSend(event) {
      //PLACE TO SCRUB
      if (event?.request?.url?.includes('reset?')) {
        event.request.url = '[REDACTED]'; //nuke the code
        event.breadcrumbs = []; //nuke the password details
      }
      return event;
    },
  });
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
);
