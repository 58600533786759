import React, { useEffect, useMemo, useState } from 'react';

import { Box, Link, Popper, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  gridVisibleRowCountSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { sumBy } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { toWarehouseRoot } from '../../RouteTable';
import { StorageType, Warehouse } from '../../graphql/gen/graphql';
import {
  commitComparator,
  getByteSize,
  getCommitFrequency,
  getLastModifiedInfo,
  getSizeDifference,
  getSizeDifferencePercentage,
  getTotalSizePercentage,
  lastUpdateComparator,
  sizeComparator,
  warehouseSizePercentComparator,
} from '../../pages/helpers';
import { relativeTimeAutoFormat } from '../../utils/time';
import { Tacard } from '../Card/Tacard';
import { TaClipboardText } from '../Clipboard/TaClipboardText';
import { getImgSrcForStorageType } from '../CreateStorageBucket/ValidationStatusHelpers';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import DataGridDashboard from '../DataGridDashboard/DataGridDashboard';
import {
  CustomPaging,
  DataGridToolBar,
} from '../DataGridToolbar/DataGridToolbar';
import { DropdownMenuContent } from '../DropdownMenu/DropdownMenuContent';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { CommitsPopover } from '../Popovers/CommitsPopover';
import { LastUpdateModifiedByPopover } from '../Popovers/LastUpdateModifiedByPopover';
import { ListPopover } from '../Popovers/ListPopover';
import { NamePopover } from '../Popovers/NamePopover';
import { TrendPopover } from '../Popovers/TrendPopover';
import { ProgressBar } from '../ProgressBar/ProgressBar';

export default function ListWarehouses({
  dashboardItems,
  warehouseData,
  organizationName,
  orgDisplayName,
  onHandleCreateWarehouse,
  onHandleRefetch,
  dashboardDropdownContent,
  loading,
  organizationId,
  hasCreateWarehouse,
}: {
  dashboardItems: any;
  warehouseData: any;
  organizationName: string;
  orgDisplayName: string;
  onHandleCreateWarehouse: (orgName: string) => void;
  onHandleRefetch: () => void;
  dashboardDropdownContent: any;
  loading: boolean;
  organizationId: string;
  hasCreateWarehouse: boolean;
}) {
  const [visibleRowCount, setVisibleRowCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState(100);
  const apiRef = useGridApiRef();

  const rows = useMemo(() => {
    const allWarehousesSize = sumBy(
      warehouseData?.organization?.warehouses,
      (wh: Warehouse) => wh.statistics?.totalBytes,
    );

    return warehouseData?.organization?.warehouses?.map((wh: Warehouse) => {
      const gcsBucket = wh.storageProfile?.storageType === StorageType.Gcs;
      const bucketName = wh.storageProfile?.bucket;
      return {
        key: wh.id,
        id: wh.id,
        name: wh.name,
        region: gcsBucket
          ? wh.storageProfile?.properties['gcp.bucket.region']
          : wh.region,
        bucketName: bucketName,
        type: wh.storageProfile,
        lastModified: getLastModifiedInfo(wh.statistics, wh).lastModifiedDate,
        lastModifiedBy: getLastModifiedInfo(wh.statistics, wh).lastModifiedBy,
        managed: wh.managed,
        totalTables: wh.statistics?.totalTables,
        totalDatabases: wh.statistics?.totalNamespaces,
        // the below object params will not work with filtering,
        // to enable filtering the params will need to be converted to single variables
        commits: {
          commitCnt: wh.statistics?.commitCnt,
          opAppendCnt: wh.statistics?.opAppendCnt,
          opOverwriteCnt: wh.statistics?.opOverwriteCnt,
          opDeleteCnt: wh.statistics?.opDeleteCnt,
          opReplaceCnt: wh.statistics?.opReplaceCnt,
          createdAt: wh.createdAt,
        },
        size: {
          bytesAdded: wh.statistics?.bytesAdded,
          bytesRemoved: wh.statistics?.bytesRemoved,
          totalBytes: wh.statistics?.totalBytes,
        },
        totalSize: {
          allWarehousesSize: allWarehousesSize,
          totalBytes: wh.statistics?.totalBytes,
        },
      };
    });
  }, [warehouseData]);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Warehouse',
        minWidth: 200,
        flex: 2,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flex: '1 1 100%',
                    alignItems: 'center',
                    height: '100%',
                    minWidth: 0,
                    cursor: 'pointer',
                  }}
                >
                  <Link
                    href={toWarehouseRoot(organizationName, params.value)}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    variant={`body1`}
                    {...bindHover(popupState)}
                  >
                    {params.value}
                  </Link>
                </Box>
                <Popper
                  {...bindPopper(popupState)}
                  placement={`right-start`}
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom'],
                      },
                    },
                  ]}
                >
                  <NamePopover
                    region={params.row.region}
                    bucketName={
                      params.row.managed
                        ? `Tabular Hosted`
                        : params.row.bucketName
                    }
                    name={params.value}
                    type={params.row.type?.storageType}
                  />
                </Popper>
              </>
            )}
          </PopupState>
        ),
      },
      {
        field: 'region',
        headerName: 'Region',
        minWidth: 150,
        flex: 0.5,
        renderCell: (params: any) => {
          return <Typography>{params.value}</Typography>;
        },
      },
      {
        field: 'type',
        headerName: 'Type',
        minWidth: 100,
        flex: 0.5,
        renderCell: (params: any) => {
          const storageType = params.value?.storageType;
          const storageTypeArr = [storageType];

          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Box {...bindHover(popupState)}>
                      {storageType && (
                        <img
                          alt={storageType}
                          src={getImgSrcForStorageType(storageType)}
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: '0 auto',
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Popper
                    {...bindPopper(popupState)}
                    placement={`right-start`}
                    modifiers={[
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['bottom'],
                        },
                      },
                    ]}
                  >
                    <ListPopover
                      title={params.value?.bucket}
                      listItems={storageTypeArr.map((i: any) => i)}
                    />
                  </Popper>
                </>
              )}
            </PopupState>
          );
        },
      },
      {
        field: 'lastModified',
        headerName: 'Last update',
        flex: 1,
        minWidth: 150,
        editable: false,
        type: 'dateTime',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: lastUpdateComparator,
        renderCell: (params: GridRenderCellParams) => (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flex: '1 1 100%',
                    alignItems: 'center',
                    height: '100%',
                    cursor: 'default',
                  }}
                >
                  <Typography variant={`inputText`} {...bindHover(popupState)}>
                    {params.value ? relativeTimeAutoFormat(params.value) : '--'}
                  </Typography>
                </Box>
                <Popper
                  {...bindPopper(popupState)}
                  placement={`right-start`}
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom'],
                      },
                    },
                  ]}
                >
                  <LastUpdateModifiedByPopover
                    modifiedBy={params.row.lastModifiedBy}
                    modifiedDate={params.value}
                  />
                </Popper>
              </>
            )}
          </PopupState>
        ),
      },
      {
        field: 'commits',
        headerName: 'Commit frequency',
        flex: 1,
        minWidth: 150,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: commitComparator,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value.commitCnt ? (
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '1 1 100%',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-end',
                        cursor: 'default',
                      }}
                    >
                      <Typography
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {getCommitFrequency(
                          params.value.commitCnt,
                          params.value.createdAt,
                        )}
                      </Typography>
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <CommitsPopover
                        commitsData={[
                          { label: 'Append', count: params.value.opAppendCnt },
                          {
                            label: 'Replace',
                            count: params.value.opReplaceCnt,
                          },
                          {
                            label: 'Overwrite',
                            count: params.value.opOverwriteCnt,
                          },
                          { label: 'Delete', count: params.value.opDeleteCnt },
                        ]}
                      />
                    </Popper>
                  </>
                )}
              </PopupState>
            ) : (
              '--'
            )}
          </>
        ),
      },
      {
        field: 'totalDatabases',
        headerName: 'Databases',
        minWidth: 80,
        flex: 1,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        valueFormatter: (params: any) => {
          return params.value != 0 ? params.value : '--';
        },
      },
      {
        field: 'totalTables',
        headerName: 'Tables',
        minWidth: 80,
        flex: 1,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        valueFormatter: (params: any) => {
          return params.value != 0 ? params.value : '--';
        },
      },
      {
        field: 'size',
        headerName: 'Size',
        minWidth: 100,
        flex: 1,
        editable: false,
        type: 'number',
        sortComparator: sizeComparator,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value?.totalBytes ? (
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '1 1 100%',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        height: '100%',
                        cursor: 'default',
                      }}
                    >
                      <Typography
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {getByteSize(params.value.totalBytes)}
                      </Typography>
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <TrendPopover
                        added={getByteSize(params.value?.bytesAdded)}
                        removed={getByteSize(params.value?.bytesRemoved)}
                        total={getSizeDifference(
                          params.value?.bytesAdded,
                          params.value?.bytesRemoved,
                          'bytes',
                        )}
                        icon={
                          getSizeDifferencePercentage(
                            params.value?.bytesAdded,
                            params.value?.bytesRemoved,
                            params.value.totalBytes,
                          ).icon
                        }
                        diff={
                          getSizeDifferencePercentage(
                            params.value?.bytesAdded,
                            params.value?.bytesRemoved,
                            params.value.totalBytes,
                          ).value
                        }
                      />
                    </Popper>
                  </>
                )}
              </PopupState>
            ) : (
              '--'
            )}
          </>
        ),
      },
      {
        field: 'totalSize',
        headerName: 'Total size %',
        minWidth: 200,
        flex: 2,
        editable: false,
        type: 'number',
        align: 'center',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: warehouseSizePercentComparator,
        renderCell: (params: GridRenderCellParams) =>
          params.value.totalBytes ? (
            <ProgressBar
              value={
                getTotalSizePercentage(
                  params.value.totalBytes,
                  params.value.allWarehousesSize,
                ).value
              }
              display={
                getTotalSizePercentage(
                  params.value.totalBytes,
                  params.value.allWarehousesSize,
                ).display
              }
            />
          ) : (
            '--'
          ),
      },
    ],
    [organizationName],
  );

  const addResourceContent = {
    title: 'Create warehouse',
    action: () => onHandleCreateWarehouse(organizationName),
    disabled: !hasCreateWarehouse,
  };
  const refreshResourceContent = {
    title: 'Refresh warehouse list',
    action: () => onHandleRefetch(),
  };

  useEffect(() => {
    //we annoyingly don't get visible row count onFilterModelChange so this is solution
    //for setting appropriate grid height for quick filter results
    apiRef.current.subscribeEvent('stateChange', (params) => {
      const count = gridVisibleRowCountSelector(apiRef.current.state);
      setVisibleRowCount(count);
    });
  }, [apiRef]);

  const referenceDropdownContent = (
    <DropdownMenuContent
      menuItems={[
        <TaClipboardText
          key={organizationId}
          clipboardText={organizationId}
          tooltipText={`Copy organization ID`}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              minWidth: 0,
            }}
          >
            <Typography
              variant={`subtitle1`}
              sx={{
                color: 'midnight.seven',
              }}
            >
              {'Organization ID'}
            </Typography>
            <Typography
              variant={`tooltipLink`}
              sx={{
                color: 'midnight.seven',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {organizationId}
            </Typography>
          </Box>
        </TaClipboardText>,
      ]}
    />
  );

  return (
    <>
      <DataGridDashboard dashboardCards={dashboardItems} />
      <Tacard grid>
        <DataGridPro
          autoHeight={rows?.length > 0 || visibleRowCount > 0}
          apiRef={apiRef}
          pagination={rows?.length >= 100}
          hideFooter={rows?.length < 100}
          rowsPerPageOptions={[10, 25, 50, 100]}
          rows={rows || []}
          columns={columns as GridColumns}
          headerHeight={34}
          disableColumnMenu
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
            pagination: { page: 0, pageSize: 100 },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [''],
              },
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: DataGridToolBar,
            Pagination: CustomPaging,
            NoRowsOverlay: OhNoesRows,
            NoResultsOverlay: OhNoesRows,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              apiRef: apiRef,
              visibleRowCount: visibleRowCount,
              pageSize: pageSize,
              setPageSize: setPageSize,
              headerName: (
                <DashboardMenu
                  titleMenu={orgDisplayName}
                  dashboardDropdownContent={referenceDropdownContent}
                />
              ),
              refreshResourceContent: refreshResourceContent,
              addResourceContent: addResourceContent,
              dashboardMenuContent: dashboardDropdownContent,
              rowCount: rows?.length,
              showRange: rows?.length >= 100,
              pageAtNumber: 100,
            },
            pagination: {
              apiRef: apiRef,
              pageSize: pageSize,
              setPageSize: setPageSize,
              rowCount: rows?.length,
            },
            noRowsOverlay: {
              buttonControl: (
                <Typography variant={`h1`} color={`brandBlue.main`}>
                  No matching warehouses
                </Typography>
              ),
            },
            noResultsOverlay: {
              buttonControl: (
                <Typography variant={`h1`} color={`brandBlue.main`}>
                  No matching warehouses
                </Typography>
              ),
            },
          }}
          loading={loading || !warehouseData}
          sx={{
            ...(rows?.length === 0 || visibleRowCount === 0
              ? { minHeight: 600 }
              : {}),
            border: 'none',
            '.MuiDataGrid-columnHeaders': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
            '.MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: 'dusk.half',
            },
          }}
        />
      </Tacard>
    </>
  );
}
