import React, { useContext } from 'react';

import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CardHeader, Grid, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { KnownFeatureFlags } from '../../context/GlobalPropsContext';

import { GlobalPropertiesContext } from '../../context';
import Tile, { TileProps } from '../TileSelector/Tile';

export enum CreateTableOption {
  CREATE,
  CREATE_LIKE,
  CREATE_LOAD,
  CDC,
}

interface CreateTableOptionsDialogProps {
  hideDueToStorageType: boolean;
}

const CreateTableOptionsDialog = create(
  ({ hideDueToStorageType }: CreateTableOptionsDialogProps) => {
    const modal = useModal();
    const { ff } = useContext(GlobalPropertiesContext);
    const cdcPipelineCreationEnabled = ff(
      KnownFeatureFlags.CDC_PIPELINE_CREATION_ENABLED,
    );

    const createOptionTiles: TileProps[] = [
      {
        id: 'create',
        logoImgUrl: '/assets/img/logos/create-table.svg',
        logoAlt: 'Create Table Logo',
        handleButtonClick: () => {
          modal.resolve(CreateTableOption.CREATE);
          modal.hide();
        },
        body: (
          <Box>
            <Typography variant={'subtitle1'}>Create table</Typography>
            <Typography variant={'body2'} color={'midnight.seven'}>
              Build a custom Iceberg table
            </Typography>
          </Box>
        ),
      },
      ...(!hideDueToStorageType
        ? [
            {
              id: 'like',
              logoImgUrl: '/assets/img/logos/create-table-like.svg',
              logoAlt: 'Create Table Like Logo',
              handleButtonClick: () => {
                modal.resolve(CreateTableOption.CREATE_LIKE);
                modal.hide();
              },
              body: (
                <Box>
                  <Typography variant={'subtitle1'}>
                    Create from schema
                  </Typography>
                  <Typography variant={'body2'} color={'midnight.seven'}>
                    Build an empty table from an existing definition
                  </Typography>
                </Box>
              ),
            },
            {
              id: 'create',
              logoImgUrl: '/assets/img/logos/create-table-load.svg',
              logoAlt: 'Create Table Load Logo',
              handleButtonClick: () => {
                modal.resolve(CreateTableOption.CREATE_LOAD);
                modal.hide();
              },
              body: (
                <Box>
                  <Typography variant={'subtitle1'}>Create and load</Typography>
                  <Typography variant={'body2'} color={'midnight.seven'}>
                    Load table from existing data
                  </Typography>
                </Box>
              ),
            },
            ...(cdcPipelineCreationEnabled
              ? [
                  {
                    id: 'create',
                    logoImgUrl: '/assets/img/logos/create-table-cdc.svg',
                    logoAlt: 'Create Table CDC Logo',
                    handleButtonClick: () => {
                      modal.resolve(CreateTableOption.CDC);
                      modal.hide();
                    },
                    body: (
                      <Box>
                        <Typography variant={'subtitle1'}>
                          Create CDC pipeline
                        </Typography>
                        <Typography variant={'body2'} color={'midnight.seven'}>
                          Choose CDC files from a storage profile, create target
                          table
                        </Typography>
                      </Box>
                    ),
                  },
                ]
              : []),
          ]
        : []),
    ];

    return (
      <Dialog
        open={modal.visible}
        scroll={'paper'}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '500px',
            backgroundColor: 'controlBackground.main',
          }}
        >
          <Box display={'flex'} justifyContent={'space-between'} p={1}>
            <CardHeader
              sx={{ p: 0, ml: 1, mt: 1 }}
              title={'Select a table creation method'}
            />
            <IconButton onClick={() => modal.hide()}>
              <CloseIcon
                fontSize={'small'}
                sx={{ color: 'midnight.six', pointer: 'cursor' }}
              />
            </IconButton>
          </Box>
          <Grid
            container
            alignSelf={'center'}
            marginY={2}
            width={'80%'}
            paddingBottom={3}
            rowSpacing={2}
          >
            {createOptionTiles.map((eachTileProps) => (
              <Grid key={eachTileProps.id} item xs={12} alignItems={'center'}>
                <Tile key={`${eachTileProps.id}_tile`} {...eachTileProps} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Dialog>
    );
  },
);
export default showCreateTableOptionsDialog;

export function showCreateTableOptionsDialog(hideDueToStorageType: boolean) {
  return show<CreateTableOption, any, CreateTableOptionsDialogProps>(
    CreateTableOptionsDialog,
    { hideDueToStorageType },
  );
}
