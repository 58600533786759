import { gql } from '@apollo/client';

import { FRAGMENTS } from './fragments';

//queries
export const getOrgPipelines = gql`
  query OrgPipelines($organizationId: String!) {
    pipelines(organizationId: $organizationId) {
      ...PipelineFields
    }
  }
  ${FRAGMENTS.pipelines}
`;

export const getPipelineById = gql`
  query PipelineById($organizationId: String!, $pipelineId: String!) {
    pipeline(organizationId: $organizationId, pipelineId: $pipelineId) {
      ...PipelineFields
    }
  }
  ${FRAGMENTS.pipelines}
`;

//mutations
export const createReplicationPipeline = gql`
  mutation CreateReplicationPipeline(
    $organizationId: String!
    $request: CreatePipelineReplicationRequest!
  ) {
    createPipelineReplication(
      organizationId: $organizationId
      request: $request
    ) {
      ...PipelineFields
    }
  }
  ${FRAGMENTS.pipelines}
`;

export const createDeduplicationPipeline = gql`
  mutation CreateDedupePipeline(
    $organizationId: String!
    $request: CreatePipelineDeDuplicationRequest!
  ) {
    createPipelineDeDuplication(
      organizationId: $organizationId
      request: $request
    ) {
      ...PipelineFields
    }
  }
  ${FRAGMENTS.pipelines}
`;

export const deletePipeline = gql`
  mutation DeletePipeline($organizationId: String!, $pipelineId: String!) {
    deletePipeline(organizationId: $organizationId, pipelineId: $pipelineId)
  }
`;
