import React, { useCallback, useState } from 'react';

import { useApolloClient, useMutation } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';

import { Organization, User } from '../../graphql/gen/graphql';
import { createOrganization } from '../../graphql/organization';
import { currentUser } from '../../graphql/user';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import TextField from './TextField';

export function orgNameFromDisplayName(orgDisplayName: string): string {
  return orgDisplayName.replace(/[^a-zA-Z0-9_-]/g, '');
}

interface CreateOrganizationProps {
  user: User;
  onCancel: () => void;
  onOrganizationCreated: (org: Organization) => void;
}
export default function CreateOrganization({
  user,
  onCancel,
  onOrganizationCreated,
}: CreateOrganizationProps) {
  const client = useApolloClient();
  const [createOrganizationMutation, { error, reset }] =
    useMutation(createOrganization);
  const [synthesizedOrgName, setSynthesizedOrgName] = useState(
    orgNameFromDisplayName('<your org name>'),
  );

  interface FormValues {
    orgName: string;
  }
  const initialValues: FormValues = {
    orgName: '',
  };
  const handleSubmitForm = useCallback(
    async (
      values: FormValues,
      { setSubmitting }: FormikHelpers<FormValues>,
    ) => {
      const orgDisplayName = values.orgName;
      const orgName = orgNameFromDisplayName(orgDisplayName);
      const result = await createOrganizationMutation({
        variables: {
          name: orgName,
          displayName: orgDisplayName,
        },
        refetchQueries: [{ query: currentUser }],
        awaitRefetchQueries: true,
        errorPolicy: 'all',
      });
      if (result.data && result.data.createOrganization) {
        reset();
        onOrganizationCreated(result.data.createOrganization);
      }
      setSubmitting(false);
    },
    [createOrganizationMutation],
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmitForm}
    >
      {({ submitForm, isSubmitting, status, values }) => (
        <Form>
          {error ? (
            <Talert severity="error" onClose={() => reset()}>
              Error: {error.message}
            </Talert>
          ) : null}
          <DialogTitle>Create Organization</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Organizations allow for multiple users to collaborate on the same
              data warehouses.
            </DialogContentText>

            <Grid
              container
              sx={{}}
              spacing={2}
              alignItems="center"
              direction="row"
            >
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  name={`orgName`}
                  autoComplete="off"
                  label={`Organization Name`}
                  onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.code === 'Enter' && isEmpty(synthesizedOrgName)) {
                      e.preventDefault();
                    } else {
                      setSynthesizedOrgName(
                        orgNameFromDisplayName(values.orgName),
                      );
                    }
                  }}
                />
                <Box sx={{ ml: 0 }}>
                  <Typography
                    variant={`body2`}
                  >{`URL: app.tabular.io/${synthesizedOrgName}`}</Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Tabutton
              onClick={() => {
                reset();
                onCancel();
              }}
            >
              Cancel
            </Tabutton>
            <Tabutton type="submit" variant="contained" disabled={isSubmitting}>
              Create
            </Tabutton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
