import React from 'react';

import { FormikValues } from 'formik';
import * as Yup from 'yup';

import { StorageType } from '../../graphql/gen/graphql';
import { SUPPORTED_BUCKET_REGIONS } from '../Forms/RegionSelector';

export const AWS_REGIONS = SUPPORTED_BUCKET_REGIONS.filter(
  (region) => region.storageType === StorageType.S3,
).map((region) => region.code);
export const GCP_REGIONS = SUPPORTED_BUCKET_REGIONS.filter(
  (region) => region.storageType === StorageType.Gcs,
).map((region) => region.code);

export const AZURE_REGIONS = SUPPORTED_BUCKET_REGIONS.filter(
  (region) => region.storageType === StorageType.Adls,
).map((region) => region.code);

export const bucketNameValidation = {
  bucketName: Yup.string()
    .trim()
    .required('required')
    .matches(
      /^[a-zA-Z0-9-]*$/,
      'Name may only contain alpha-numeric characters ' +
        "or hyphens ('-') and may not contain any spaces.",
    ),
};
export const bucketValidationSchema = Yup.object().shape({
  region: Yup.string().oneOf(AWS_REGIONS).required('Region is required'),
  ...bucketNameValidation,
  iamRole: Yup.string()
    .trim()
    .required('IAM Role ARN is required.')
    .matches(
      /^arn:aws:iam::\d{12}:(role|policy)\/\S+$/,
      'Invalid IAM Role ARN, should match arn:aws:iam::aws_account_id:[aws_resource]/resource_name where [aws_resource] can be role or policy',
    ),
});

export const googleProjectValidation = {
  projectNum: Yup.string().trim().required('required'),
  projectId: Yup.string()
    .trim()
    .required('required')
    .matches(
      /^[a-z][-a-z0-9]{6,30}[a-z0-9]{1}$/,
      'Project ID should be between 6-30 characters',
    ),
};

export const googleWorkloadValidation = {
  poolId: Yup.string()
    .trim()
    .required('required')
    .matches(/^[a-z0-9_-]{4,32}$/, 'Pool ID should be between 4-32 characters'),
  providerId: Yup.string()
    .trim()
    .required('required')
    .matches(
      /^[a-z0-9_-]{4,32}$/,
      'Provider ID should be at least 4-32 characters',
    ),
};

export const googleServiceValidation = {
  externalId: Yup.string()
    .trim()
    .required('Service account email required')
    .matches(
      /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+\.iam\.gserviceaccount.com/,
      'Invalid service account email, should match service-account-name@project-id.iam.gserviceaccount.com',
    ),
};

export const googleBucketValidationSchema = Yup.object().shape({
  ...googleProjectValidation,
  ...googleWorkloadValidation,
  ...googleServiceValidation,
});

export const selfHostedWarehouseSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Warehouse name is required.')
    .matches(
      /^(?!\s)[a-zA-Z0-9-_@]{1,127}$/,
      'Warehouse name may only contain alpha-numeric characters, ' +
        "underscores ('_') and hyphens ('-') and may not contain any spaces. Can be up to 127 characters.",
    ),
  bucket: Yup.string().when('name', {
    is: (value: string) => {
      return !!(value && value.match(/^(?!\s)[a-zA-Z0-9-_@]{1,127}$/));
    },
    then: Yup.string().required('Bucket selection is required.'),
  }),
});
function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const getBucketName = (orgName: string) => {
  const organizationTruncated =
    orgName?.slice(0, 32).replaceAll('_', '-').toLowerCase() || 'tabular';
  const randomInt = getRandomInt(10000);
  const stackName = `t-stack-${organizationTruncated}-${randomInt}`;
  const bucketName = `${organizationTruncated}-${randomInt}`;
  return { stackName, bucketName };
};
export const getCloudFormationLink = (values: FormikValues) => {
  const REGION = values.region;
  const CFT_URL = `https://${REGION}.console.aws.amazon.com/cloudformation/home?region=${REGION}#/stacks/create/review?templateURL=${process.env.REACT_APP_CLOUD_FORMATION_TEMPLATE_URL}&stackName=${values.stackName}&param_BucketName=${values.bucketName}&param_ExternalId=${values.externalId}`;

  return CFT_URL;
};
