import React, { useCallback, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import { useAuth } from '../../context/auth-context';
import {
  SearchResult,
  SortByDirection,
  TableSearchResult,
  User,
} from '../../graphql/gen/graphql';
import { navSearchQuery } from '../../graphql/organization';
import { Talert } from '../Alert/Talert';
import SearchBox from './SearchBox';

function getDisplayForType(type: number): JSX.Element {
  return (
    <Box
      sx={(theme) => ({
        minWidth: '100px',
        minHeight: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.midnight.two}`,
        borderRadius: '4px',
        mr: 6,
        backgroundColor: 'white',
      })}
    >
      <Typography variant="body2">
        {type == 1
          ? 'Warehouse'
          : type == 2
          ? 'Database'
          : type == 3
          ? 'Table'
          : type == 4
          ? 'View'
          : ''}
      </Typography>
    </Box>
  );
}
function getDisplayForResult(result: SearchResult): JSX.Element {
  let contents = null;
  const dot = '.';
  switch (result.et) {
    case 1:
      contents = (
        <Grid item sx={{ color: 'sky.main', pl: '4px !important' }}>
          {result.en}
        </Grid>
      );
      break;
    case 2:
      contents = (
        <>
          <Grid item sx={{ color: 'midnight.four', pl: '4px !important' }}>
            {result.wn}
          </Grid>
          <Grid item sx={{ pl: '4px !important' }}>
            {dot}
          </Grid>
          <Grid item sx={{ color: 'sky.main', pl: '4px !important' }}>
            {result.en}
          </Grid>
        </>
      );
      break;

    case 3:
      contents = (
        <>
          <Grid item sx={{ color: 'midnight.four', pl: '4px !important' }}>
            {result.wn}
          </Grid>
          <Grid item sx={{ pl: '4px !important' }}>
            {dot}
          </Grid>
          <Grid item sx={{ color: 'midnight.four', pl: '4px !important' }}>
            {(result as TableSearchResult).nn}
          </Grid>

          <Grid item sx={{ pl: '4px !important' }}>
            {dot}
          </Grid>
          <Grid item sx={{ color: 'sky.main', pl: '4px !important' }}>
            {result.en}
          </Grid>
        </>
      );
      break;

    case 4:
      contents = (
        <>
          <Grid item sx={{ color: 'midnight.four', pl: '4px !important' }}>
            {result.wn}
          </Grid>
          <Grid item sx={{ pl: '4px !important' }}>
            {dot}
          </Grid>
          <Grid item sx={{ color: 'midnight.four', pl: '4px !important' }}>
            {(result as TableSearchResult).nn}
          </Grid>

          <Grid item sx={{ pl: '4px !important' }}>
            {dot}
          </Grid>
          <Grid item sx={{ color: 'sky.main', pl: '4px !important' }}>
            {result.en}
          </Grid>
        </>
      );
      break;
  }
  return (
    <Grid container spacing={1} justifyContent={'flex-start'}>
      {contents}
    </Grid>
  );
}
export default function NavSearch({
  onNavSelected,
  onCancel,
}: {
  onCancel: () => Promise<unknown> | void;
  onNavSelected: (searchResult: SearchResult) => void;
}) {
  const { user }: { user: User } = useAuth();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [lastSearchQuery, setLastSearchQuery] = useState<string>('');
  const [cursor, setCursor] = useState<number>(0);
  const [search, { data, loading, error, refetch, called }] = useLazyQuery(
    navSearchQuery,
    {
      variables: {
        organizationId: user.loginSession?.loggedInOrg?.id,
        maxResults: 10,
        sortBy: [
          {
            fieldName: '_score',
            sortDirection: SortByDirection.Asc,
          },
        ],
      },
    },
  );

  const moveDown = useCallback(() => {
    if (cursor < data?.search?.results?.length - 1) {
      setCursor(cursor + 1);
    }
  }, [cursor, loading, data]);
  const moveUp = useCallback(() => {
    if (cursor > 0) {
      setCursor(cursor - 1);
    }
  }, [cursor, loading, data]);
  const onKeyDownHandler = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code == 'ArrowUp') {
        moveUp();
        e.preventDefault();
      } else if (e.code == 'ArrowDown') {
        moveDown();
        e.preventDefault();
      } else if (e.code == 'Enter') {
        e.preventDefault();
        onNavSelected(data?.search?.results[cursor]);
      }
    },
    [cursor, loading, data],
  );
  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      // @ts-ignore
      const currentSearchValue = e.target.value;
      if (currentSearchValue !== searchQuery) {
        setSearchQuery(currentSearchValue);
      }
    },
    [],
  );

  useEffect(() => {
    if (searchQuery !== '' && searchQuery !== lastSearchQuery) {
      setSearchQuery('');
      setLastSearchQuery(searchQuery);
      setCursor(0);
      if (called) {
        refetch({
          query: searchQuery,
        });
      } else {
        search({
          variables: {
            query: searchQuery,
          },
        });
      }
    }
  }, [searchQuery, lastSearchQuery, called]);

  return (
    <>
      {error ? <Talert severity="error">Error: {error.message}</Talert> : null}
      <Box sx={{ backgroundColor: data ? 'controlBackground.main' : '' }}>
        <Box
          sx={{
            px: 1,
            py: 2,
            backgroundColor: 'midnight.one',
          }}
        >
          <SearchBox
            name="query"
            onKeyUp={onSearchChange}
            onKeyDown={onKeyDownHandler}
          />
        </Box>

        <List>
          {!loading && data?.search?.results?.length === 0 && (
            <ListItem key={'search_list_item_no_results'} disablePadding>
              <ListItemButton>
                <ListItemText primary={'No results'} />
              </ListItemButton>
            </ListItem>
          )}

          {!loading &&
            data?.search?.results?.length > 0 &&
            data?.search?.results?.map((result: SearchResult, i: number) => (
              <>
                <ListItem key={'search_list_item_' + result.eid} disablePadding>
                  <ListItemButton
                    selected={cursor === i}
                    onClick={() => {
                      onNavSelected(result);
                    }}
                  >
                    <ListItemIcon>{getDisplayForType(result.et)}</ListItemIcon>
                    <ListItemText sx={{ wordBreak: 'break-word' }}>
                      {getDisplayForResult(result)}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            ))}
        </List>
      </Box>
    </>
  );
}
