import React from 'react';

import { Snackbar } from '@mui/material';

import { Talert } from '../Alert/Talert';

type StatusBarProps = {
  successDisplay?: boolean;
  handleSuccessClose?: () => void;
  successMessage?: string;
  errorDisplay?: boolean;
  setErrorDisplay?: (value: boolean) => void;
  errorMessage?: string;
  setErrorMessage?: (value: string) => void;
  refetch?: any;
  autoHide?: boolean;
};
export default function StatusBars({
  successDisplay,
  handleSuccessClose,
  successMessage,
  errorDisplay,
  setErrorDisplay,
  errorMessage,
  setErrorMessage,
  refetch,
  autoHide = true,
}: StatusBarProps) {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={successDisplay}
        onClose={handleSuccessClose}
        {...(autoHide ? { autoHideDuration: 6000 } : {})}
      >
        <Talert
          onClose={handleSuccessClose}
          color="darkSuccess"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Talert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorDisplay}
        onClose={() => {
          setErrorDisplay && setErrorDisplay(false);
          setErrorMessage && setErrorMessage('');
          if (refetch) {
            refetch();
          }
        }}
      >
        <Talert
          onClose={() => {
            setErrorDisplay && setErrorDisplay(false);
            setErrorMessage && setErrorMessage('');
            if (refetch) {
              refetch();
            }
          }}
          color="darkError"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Talert>
      </Snackbar>
    </>
  );
}
