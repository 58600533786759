import React, { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import { Box, Button, Typography, Tooltip, useMediaQuery } from '@mui/material';
import { has } from 'lodash';

import { AuthDecisionResourceType } from '../../graphql/gen/graphql';
import { TaBadge } from '../Badge/TaBadge';
import {
  GrantButton,
  AccessButton,
  StyledButtonGroup,
} from '../Button/AccessButtonGroup';
import { Tabutton } from '../Button/Tabutton';
import { ConfirmationDialog } from '../Modals/ConfirmationDialog';
import { PrivilegeSelected } from './RolePrivileges';

export default function PrivilegeSelector({
  accessButtons,
  groupName,
  handlePrivChange,
  grantingNewAccess,
  securityAdminCard,
  roleId,
}: {
  accessButtons: AccessButton[];
  groupName: string;
  handlePrivChange: (
    event: PrivilegeSelected,
    withGrant?: boolean,
    multiple?: boolean,
    roleId?: string,
  ) => void;
  grantingNewAccess: boolean;
  securityAdminCard?: boolean;
  roleId?: string;
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [privilege, setPrivilege] = useState('');
  const [lowerPrivs, setLowerPrivs] = useState(null as any);
  const smallScreenView = useMediaQuery('(max-width: 600px)');

  const getAllLowerPrivs = (button: AccessButton) => {
    setLowerPrivs(button?.level);

    handlePrivChange(
      {
        privilege: button.privilege,
        withGrant: false,
        selected: !button.selected,
        groupingName: groupName,
        group: button.group,
        level: button.level,
        roleId: roleId,
      },
      false,
      true,
    );
  };

  const hasManageGrants = accessButtons?.find((priv: AccessButton) =>
    priv.privilege?.includes('GRANTS'),
  );

  const orgAccess =
    accessButtons[0].groupingName === AuthDecisionResourceType.Organization;

  const allPrivsSelectedCheck = accessButtons.map(
    (priv) => priv.selected === false,
  );
  const privsSelectedCheck = allPrivsSelectedCheck.slice(0, -1);

  const checkForCleanSlate = (cleanSlate: boolean[]) =>
    cleanSlate.every(Boolean);
  const cleanSlate = checkForCleanSlate(
    orgAccess ? allPrivsSelectedCheck : privsSelectedCheck,
  );

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      marginX={2}
      marginY={smallScreenView ? 0 : 1}
      sx={(theme) => ({
        ...(grantingNewAccess && {
          flexDirection: 'column',
          alignItems: 'flex-start',
        }),
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      })}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
          [theme.breakpoints.down('sm')]: {
            mt: 1,
          },
        })}
      >
        <Box
          display={'flex'}
          sx={(theme) => ({
            borderRadius: '2px',
            border: `1px solid ${theme.palette.midnight.two}`,
            ...(grantingNewAccess && {
              mb: 2,
            }),
            [theme.breakpoints.down('md')]: {
              mb: 1,
              width: '100%',
              alignItems: 'center',
            },
          })}
        >
          <TaBadge
            text={groupName}
            minWidth={'145px'}
            height={'20px'}
            fullWidth
            bgColor={'controlBackground.main'}
          />
        </Box>
      </Box>
      <StyledButtonGroup
        orientation={smallScreenView ? 'vertical' : 'horizontal'}
      >
        {accessButtons.map((button: AccessButton, index: any) => {
          const myVariant = button.selected
            ? 'filledAccessMain'
            : 'blankAccessMain';
          const withGrant = button.withGrant;
          const manageGrantsButton = button?.privilege?.includes('GRANTS');
          const disable = !button.hasAbilityToGrantPriv;
          const grantSelected = button.selected || securityAdminCard;
          const lightEmUp =
            lowerPrivs !== null &&
            cleanSlate &&
            button.level <= lowerPrivs &&
            !disable;

          const enableWithGrant = has(button, 'withGrant');
          return !manageGrantsButton ? (
            <Box
              key={`access-button-${Math.random()}-${index}`}
              sx={(theme) => ({
                marginLeft: smallScreenView ? '0px' : '10px',
                color: 'black !important',
                position: 'relative',
                minWidth: '125px',
                width: '100%',
                [theme.breakpoints.down('md')]: {
                  my: 1,
                  '&:first-of-type': { marginLeft: 0 },
                },
              })}
              display={'flex'}
              flex={1}
            >
              <Tooltip
                PopperProps={{
                  sx: [
                    {
                      lineHeight: '16px',
                    },
                  ],
                }}
                arrow
                placement={'top'}
                title={button.description}
              >
                <Button
                  size={`small`}
                  variant={myVariant}
                  disabled={disable}
                  sx={{
                    boxShadow: 'none !important',
                    p: 0,
                    m: 0,
                    height: '24px',
                    fontSize: '12px',
                    '&:hover': {
                      backgroundColor:
                        disable && !button.selected
                          ? 'midnight.two'
                          : disable && button.selected
                          ? 'aurora.four'
                          : 'aurora.two',
                    },
                    '&.Mui-disabled': {
                      cursor: 'not-allowed',
                      pointerEvents: 'auto',
                    },
                    ...(lightEmUp && {
                      backgroundColor: 'aurora.two',
                      color: 'midnight.seven',
                    }),
                  }}
                  onMouseEnter={() => cleanSlate && setLowerPrivs(button.level)}
                  onMouseLeave={() => cleanSlate && setLowerPrivs(null)}
                  onClick={() => {
                    if (cleanSlate && button.level > 0) {
                      getAllLowerPrivs(button);
                    } else {
                      handlePrivChange({
                        privilege: button.privilege,
                        withGrant: false,
                        selected: !button.selected,
                        groupingName: groupName,
                        roleId: roleId,
                      });
                      setLowerPrivs(null);
                    }
                  }}
                >
                  <Box
                    sx={{
                      ml: orgAccess ? 2 : 4,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {!button.buttonText ? (
                      <CheckIcon
                        fontSize={`small`}
                        sx={{
                          color: `${
                            button.selected ? 'midnight.nine' : 'midnight.two'
                          }`,
                        }}
                      />
                    ) : (
                      <>
                        {button.selected && (
                          <CheckIcon
                            fontSize={`small`}
                            sx={{ color: 'black' }}
                          />
                        )}
                        <Typography
                          sx={{
                            color: `${
                              button.selected ? 'black' : 'midnight.seven'
                            }`,
                            ml: 0.5,
                          }}
                          variant={'smallButton'}
                        >
                          {button.buttonText}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Button>
              </Tooltip>
              {enableWithGrant && (
                <GrantButton
                  popover={false}
                  grantSelected={
                    withGrant ||
                    hasManageGrants?.selected ||
                    securityAdminCard ||
                    false
                  }
                  disable={disable || securityAdminCard}
                  handleClick={() => {
                    if (hasManageGrants?.selected) {
                      setPrivilege(button.buttonText);
                      setShowConfirmation(true);
                      if (button.withGrant) {
                        handlePrivChange(
                          {
                            privilege: button.privilege,
                            withGrant: !button.withGrant,
                            selected: button.selected,
                            groupingName: groupName,
                            roleId: roleId,
                          },
                          true,
                        );
                      }
                    }

                    if (!hasManageGrants?.selected) {
                      handlePrivChange(
                        {
                          privilege: button.privilege,
                          withGrant: !button.withGrant,
                          selected: !button.selected,
                          groupingName: groupName,
                          roleId: roleId,
                        },
                        true,
                      );
                    }
                  }}
                />
              )}
            </Box>
          ) : (
            <Box
              display="flex"
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  justifyContent: 'right',
                },
              })}
            >
              <Tooltip
                key={button.privilege}
                title={'Ability to grant access to all privileges'}
                PopperProps={{
                  sx: [
                    {
                      lineHeight: '16px',
                    },
                  ],
                }}
                arrow
                placement={'top'}
              >
                <Box
                  key={`access-button-${Math.random()}-${index}`}
                  sx={{
                    marginRight: '0px',
                    marginLeft: '10px !important',
                    color: 'black !important',
                    minWidth: '125px',
                    width: 'fit-content',
                    marginTop: smallScreenView ? 1 : 0,
                  }}
                  display={'flex'}
                >
                  <Tabutton
                    size={`small`}
                    variant={'text'}
                    disabled={disable || securityAdminCard}
                    sx={(theme) => ({
                      p: 0,
                      m: 0,
                      marginY: smallScreenView ? 2 : 0,
                      height: '24px',
                      borderRadius: '12px',
                      '&.Mui-disabled': {
                        background: grantSelected
                          ? 'sunrise.four'
                          : 'transparent',
                        color: grantSelected
                          ? 'midnight.nine'
                          : 'brandBlue.main',
                        cursor: 'not-allowed',
                        pointerEvents: 'auto',
                      },
                      '&:hover': {
                        backgroundColor:
                          disable && !button.selected
                            ? 'midnight.two'
                            : disable && grantSelected
                            ? 'sunrise.four'
                            : 'sunrise.two',
                        color: grantSelected
                          ? 'midnight.nine'
                          : 'midnight.seven',
                      },
                      ...(grantSelected && {
                        backgroundColor: 'sunrise.four',
                        color: 'midnight.nine',
                      }),
                    })}
                    onClick={() => {
                      handlePrivChange({
                        privilege: button.privilege,
                        withGrant: true,
                        selected: !button.selected,
                        groupingName: groupName,
                        roleId: roleId,
                      });
                    }}
                    endIcon={<GppGoodOutlinedIcon />}
                  >
                    {button.buttonText}
                  </Tabutton>
                </Box>
              </Tooltip>
            </Box>
          );
        })}
      </StyledButtonGroup>
      <ConfirmationDialog
        open={showConfirmation}
        title={`Remove grant access for ${privilege}`}
        acceptText="Remove"
        onDismiss={() => {
          setShowConfirmation(false);
        }}
        onAccept={() => {
          hasManageGrants &&
            handlePrivChange({
              privilege: hasManageGrants.privilege,
              withGrant: false,
              selected: false,
              groupingName: groupName,
              roleId: roleId,
            });
          setShowConfirmation(false);
        }}
      >
        Removing this privileges ability to grant will also remove admin
        privileges.
      </ConfirmationDialog>
    </Box>
  );
}
