import React, { useEffect, useMemo, useReducer, useState } from 'react';

import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/base/useAutocomplete/useAutocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, InputBase } from '@mui/material';

enum FilterActions {
  ADD_SEARCH_FILTER,
  ADD_CATEGORY_FILTER,
  REMOVE_SEARCH_FILTER,
  REMOVE_CATEGORY_FILTER,
  CLEAR,
}

export interface Filters {
  searchFilter: string[];
  categoryFilter: { [key: string]: string[] };
}

const initialState = {
  searchFilter: [],
  categoryFilter: {},
};

interface ESCustomSearchBarProps {
  onSearchChange: (filters: Filters) => void;
  options: { [key: string]: string[] };
}

export default function ESCustomSearchBar({
  onSearchChange,
  options,
}: ESCustomSearchBarProps) {
  const categories = useMemo(() => Object.keys(options), [options]);
  const rootSuggestions = useMemo(
    () => categories.map((c) => c + ':'),
    [options],
  );
  const categorySuggestions = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(options).map(([key, values]) => [
          key,
          values.map((value) => `${key}:${value}`),
        ]),
      ),
    [options],
  );
  const allSuggestions = useMemo(
    () => rootSuggestions.concat(...Object.values(categorySuggestions)),
    [options],
  );

  function filtersReducer(
    filters: Filters,
    action: {
      type: FilterActions;
      payload: { category: string; value: string };
    },
  ) {
    switch (action.type) {
      case FilterActions.ADD_SEARCH_FILTER:
        return {
          ...filters,
          searchFilter: [...filters.searchFilter, action.payload.value],
        };
      case FilterActions.ADD_CATEGORY_FILTER:
        const { category, value } = action.payload;
        const updatedCategoryFilter = filters.categoryFilter[category]
          ? [...filters.categoryFilter[category], value]
          : [value];

        return {
          ...filters,
          categoryFilter: {
            ...filters.categoryFilter,
            [category]: updatedCategoryFilter,
          },
        };
      case FilterActions.REMOVE_SEARCH_FILTER:
        const updatedSearchFilter = filters.searchFilter.filter(
          (filter) => filter !== action.payload.value,
        );

        return {
          ...filters,
          searchFilter: updatedSearchFilter,
        };
      case FilterActions.REMOVE_CATEGORY_FILTER: {
        const { category, value } = action.payload;

        return {
          ...filters,
          categoryFilter: {
            ...filters.categoryFilter,
            [category]: [],
          },
        };
      }
      case FilterActions.CLEAR:
        return initialState;
    }
  }

  const [inputValue, setInputValue] = useState('');
  const [focus, setFocus] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>(rootSuggestions);
  const [filters, dispatchFilters] = useReducer(filtersReducer, initialState);

  /*
    we can consider using the actual filters as the display values and using renderTags to style in the future
    that will also help with removing a single category filter instead of resetting the entire category to []
   */
  const displayedFilters = useMemo(() => {
    const searchFilter = filters.searchFilter;
    const categoryFilter = Object.keys(filters.categoryFilter).map(
      (category) => {
        const values = filters.categoryFilter[category];
        if (values && values.length > 0) {
          return `${category}:${values.join(' OR ')}`;
        }
        return '';
      },
    );
    return [...searchFilter, ...categoryFilter].filter(
      (value) => value.length > 0,
    );
  }, [filters]);

  useEffect(() => {
    onSearchChange(filters);
  }, [filters]);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
    if (value !== '' && value.endsWith(':')) {
      const [category] = value.split(':');
      setSuggestions(categorySuggestions[category] || []);
    } else if (value !== '') {
      setSuggestions(allSuggestions);
    } else {
      setSuggestions(rootSuggestions);
    }
  };

  const handleFilterSelected = (
    event: React.SyntheticEvent,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails,
  ) => {
    switch (reason) {
      case 'selectOption':
      case 'createOption':
        if (details?.option?.endsWith(':')) {
          const [category] = details.option.split(':');
          setInputValue(details.option);
          setSuggestions(categorySuggestions[category]);
          return;
        } else if (details?.option?.includes(':')) {
          const [cat, catValue] = details.option.split(':');
          if (
            !filters.categoryFilter[cat] ||
            !filters.categoryFilter[cat].includes(catValue)
          ) {
            dispatchFilters({
              type: FilterActions.ADD_CATEGORY_FILTER,
              payload: {
                category: cat,
                value: catValue,
              },
            });
          }
        } else if (details?.option) {
          dispatchFilters({
            type: FilterActions.ADD_SEARCH_FILTER,
            payload: {
              category: '',
              value: details.option,
            },
          });
        }
        break;
      case 'removeOption':
        if (details?.option?.includes(':')) {
          const [cat, catValue] = details.option.split(':');
          dispatchFilters({
            type: FilterActions.REMOVE_CATEGORY_FILTER,
            payload: {
              category: cat,
              value: catValue,
            },
          });
        } else if (details?.option) {
          dispatchFilters({
            type: FilterActions.REMOVE_SEARCH_FILTER,
            payload: {
              category: '',
              value: details.option,
            },
          });
        }
        break;
      case 'clear':
        dispatchFilters({
          type: FilterActions.CLEAR,
          payload: {
            category: '',
            value: '',
          },
        });
    }
  };

  return (
    <Autocomplete
      sx={(theme) => ({
        width: '100%',
        alignItems: 'center',
      })}
      fullWidth
      size={'small'}
      open={focus || inputValue.length > 0}
      filterSelectedOptions
      freeSolo
      multiple
      clearOnBlur
      openOnFocus
      options={suggestions}
      value={displayedFilters}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleFilterSelected}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      clearIcon={<ClearIcon sx={{ fontSize: '16px' }} />}
      renderInput={(params) => (
        <InputBase
          sx={(theme) => ({
            p: 'none',
            width: '100%',
            flex: 1,
            fontSize: theme.typography.pxToRem(14),
            '& .MuiAutocomplete-endAdornment': {
              top: 'auto',
              mr: 1,
            },
            '& .MuiAutocomplete-tag': {
              height: '100%',
            },
            '& .MuiChip-label': {
              whiteSpace: 'normal',
            },
          })}
          placeholder="Search"
          {...params.InputProps}
          inputProps={{
            ...params.inputProps,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            },
          }}
        />
      )}
    />
  );
}
