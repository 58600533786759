import React from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Typography, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function AuthFormContainer({
  title,
  subtitle,
  backButton,
  backButtonClick,
  leftSideContent,
  children,
  ghostMode,
}: {
  title: string;
  subtitle: string;
  backButton?: any;
  backButtonClick?: any;
  leftSideContent: any;
  children: any;
  ghostMode?: boolean;
}) {
  return (
    <>
      {ghostMode && <>{children}</>}

      {!ghostMode && (
        <>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '800px',
              }}
            >
              <Grid
                container
                sx={(theme) => ({
                  width: '100%',
                  minHeight: '420px',
                  boxShadow: theme.shadows[6],
                  backgroundColor: 'controlBackground.half',
                })}
                spacing={0}
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    pt: 5,
                    pb: 5,
                    backgroundColor: 'brandBlue.main',
                    color: 'primary.contrastText',
                    display: { xs: 'none', md: 'block', lg: 'block' },
                  }}
                >
                  {leftSideContent}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                    p: 5,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: grey[400],
                  }}
                >
                  <Box sx={{ height: '100%', width: 1 }}>
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mb: 2,
                          position: 'relative',
                        }}
                      >
                        {backButton && (
                          <ArrowBackIosIcon
                            sx={{
                              cursor: 'pointer',
                              position: 'absolute',
                              left: 0,
                            }}
                            onClick={() => backButtonClick()}
                          />
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="h1" color={'brandBlue.main'}>
                            {title}
                          </Typography>
                          <Typography variant={`subtitle1`}>
                            {subtitle}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        height: 0.9,
                        width: 1,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {children}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
