import React from 'react';

import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';

import { Organization, Role } from '../../graphql/gen/graphql';
import CreateRole from '../Forms/CreateRole';

interface CreateRoleDialogProps {
  organization: Organization;
  onRoleCreated: (role: Role) => void;
}
const CreateRoleDialog = create(
  ({ organization, onRoleCreated }: CreateRoleDialogProps) => {
    const modal = useModal();

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        maxWidth="xl"
      >
        <CreateRole
          organizationId={organization?.id || ''}
          onCancel={modal.hide}
          onRoleCreated={(role: Role) => {
            modal.hide();
            onRoleCreated && onRoleCreated(role);
          }}
        />
      </Dialog>
    );
  },
);

export default CreateRoleDialog;

export function showCreateRoleDialog(
  organization: Organization,
  onRoleCreated: (role: Role) => void,
) {
  return show<boolean, any, CreateRoleDialogProps>(CreateRoleDialog, {
    organization,
    onRoleCreated,
  });
}
