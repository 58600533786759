import React from 'react';

import { PaletteMode } from '@mui/material';
import {
  CSSProperties,
  TypographyOptions,
} from '@mui/material/styles/createTypography';

import { Tabulink } from '../components/Link/Tabulink';

interface ExtendedTypographyOptions extends TypographyOptions {
  h1Bold: CSSProperties;
  h2Bold: CSSProperties;
  inputText: CSSProperties;
  smallButton: CSSProperties;

  caption: CSSProperties;
  overline: CSSProperties;

  inputLabel: CSSProperties;
  boldInputLabel: CSSProperties;
  tooltip: CSSProperties;
  tooltipLink: CSSProperties;
  semanticH2styledSubtitle: CSSProperties;
  semanticH2styledH6: CSSProperties;
  semanticH3styledSubtitle: CSSProperties;
  semanticH4styledOverline: CSSProperties;
  avatarLetter: CSSProperties;
  preformatted: CSSProperties;
  helperText: CSSProperties;
  helperTextLink: CSSProperties;
  inputTextLink: CSSProperties;
  smallPreformat: CSSProperties;
  buttonsmall: CSSProperties;
}
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      dusk: React.CSSProperties['color'];
      custom: React.CSSProperties['color'];
      neutral: React.CSSProperties['color'];
      aurora: React.CSSProperties['color'];
      brandBlue: React.CSSProperties['color'];
      brandWhite: React.CSSProperties['color'];
      brandOrange: React.CSSProperties['color'];
      frost: React.CSSProperties['color'];
      glass: React.CSSProperties['color'];
      midnight: React.CSSProperties['color'];
      sky: React.CSSProperties['color'];
      sunset: React.CSSProperties['color'];
      sunrise: React.CSSProperties['color'];
      controlBackground: React.CSSProperties['color'];
      popper: React.CSSProperties['color'];
      danger: React.CSSProperties['color'];
      darkSuccess: React.CSSProperties['color'];
      darkError: React.CSSProperties['color'];
      darkWarning: React.CSSProperties['color'];
      darkInfo: React.CSSProperties['color'];
      darkNeutral: React.CSSProperties['color'];
    };
  }
  interface Palette {
    dusk: Palette['primary'];
    custom: Palette['primary'];
    neutral: Palette['primary'];
    aurora: Palette['primary'];
    brandBlue: Palette['primary'];
    brandWhite: Palette['primary'];
    brandOrange: Palette['primary'];
    midnight: Palette['primary'];
    sky: Palette['primary'];
    sunset: Palette['primary'];
    sunrise: Palette['primary'];
    controlBackground: Palette['primary'];
    popper: Palette['primary'];
    darkSuccess: Palette['primary'];
    darkError: Palette['primary'];
    darkWarning: Palette['primary'];
    darkInfo: Palette['primary'];
    darkNeutral: Palette['primary'];
  }
  interface PaletteOptions {
    custom?: PaletteOptions['primary'];
    neutral?: PaletteOptions['primary'];
    aurora: PaletteOptions['primary'];
    brandBlue: PaletteOptions['primary'];
    brandWhite: PaletteOptions['primary'];
    brandOrange: PaletteOptions['primary'];
    dusk: PaletteOptions['primary'];
    midnight: PaletteOptions['primary'];
    sky: PaletteOptions['primary'];
    sunset: PaletteOptions['primary'];
    sunrise: PaletteOptions['primary'];
    controlBackground: PaletteOptions['primary'];
    popper: PaletteOptions['primary'];
    darkSuccess: Palette['primary'];
    darkError: Palette['primary'];
    darkWarning: Palette['primary'];
    darkInfo: Palette['primary'];
    darkNeutral: Palette['primary'];
  }
  interface PaletteColor {
    half?: string;
    one?: string;
    two?: string;
    three?: string;
    four?: string;
    five?: string;
    six?: string;
    seven?: string;
    eight?: string;
    nine?: string;

    gradient?: string;
    loginBackgroundGradient?: string;
    lakeWaterGradient?: string;
  }

  interface SimplePaletteColorOptions {
    light?: string;
    half?: string;
    one?: string;
    two?: string;
    three?: string;
    four?: string;
    five?: string;
    six?: string;
    seven?: string;
    eight?: string;
    nine?: string;
    contrastText?: string;
    gradient?: string;
    loginBackgroundGradient?: string;
    lakeWaterGradient?: string;
  }

  interface ThemeOptions {
    status: {
      dusk: React.CSSProperties['color'];
      custom: React.CSSProperties['color'];
      neutral: React.CSSProperties['color'];
      aurora: React.CSSProperties['color'];
      brandBlue: React.CSSProperties['color'];
      brandWhite: React.CSSProperties['color'];
      brandOrange: React.CSSProperties['color'];
      frost: React.CSSProperties['color'];
      glass: React.CSSProperties['color'];
      midnight: React.CSSProperties['color'];
      sky: React.CSSProperties['color'];
      sunset: React.CSSProperties['color'];
      sunrise: React.CSSProperties['color'];
      controlBackground: React.CSSProperties['color'];
      popper: React.CSSProperties['color'];
      danger: React.CSSProperties['color'];
    };
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    dusk: true;
    custom: true;
    neutral: true;
    aurora: true;
    brandBlue: true;
    brandWhite: true;
    brandOrange: true;
    frost: true;
    glass: true;
    midnight: true;
    sky: true;
    sunset: true;
    sunrise: true;
    controlBackground: true;
    popper: true;
    danger: true;
  }

  interface ButtonPropsVariantOverrides {
    filledAccessMain: true;
    blankAccessMain: true;
    filledAccessPopover: true;
    blankAccessPopover: true;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Bold: true;
    smallButton: true;
    caption: true;
    overline: true;
    inputText: true;
    inputLabel: true;
    boldInputLabel: true;
    tooltip: true;
    tooltipLink: true;
    semanticH2styledSubtitle: true;
    semanticH2styledH6: true;
    semanticH3styledSubtitle: true;
    semanticH4styledOverline: true;
    avatarLetter: true;
    preformatted: true;
    helperText: true;
    helperTextLink: true;
    inputTextLink: true;
    smallPreformat: true;
    buttonsmall: true;
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    neutral: true;
    darkSuccess: true;
    darkError: true;
    darkWarning: true;
    darkInfo: true;
    darkNeutral: true;
  }
  interface AlertPropsVariantOverrides {
    neutral: true;
    darkSuccess: true;
    darkError: true;
    darkWarning: true;
    darkInfo: true;
    darkNeutral: true;
  }
}
declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    brandBlue: true;
  }
}

const lightMode = {
  custom: {
    main: '#EBF5FA',
    loginBackgroundGradient:
      'linear-gradient(0deg, rgba(16, 49, 65, 0.6), rgba(16, 49, 65, 0.6)), radial-gradient(100% 239.32% at 0% 0%, #C6E2F1 0%, rgba(198, 226, 241, 0) 100%), radial-gradient(239.32% 100% at 100% 0%, #262131 0%, rgba(38, 33, 49, 0) 100%), radial-gradient(239.32% 100% at 100% 0%, rgba(55, 129, 55, 0) 0%, #378137 100%), radial-gradient(100% 239.32% at 0% 0%, rgba(60, 69, 73, 0) 0%, #3C4549 100%)',
    gradient:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), radial-gradient(100% 239.32% at 0% 0%, #EFF7FB 0%, rgba(239, 247, 251, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(123.65% 215.67% at 100% 0%, #D9D4E2 0%, rgba(217, 212, 226, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(123.65% 215.67% at 100% 0%, rgba(241, 249, 241, 0) 0%, #F1F9F1 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(100% 239.32% at 0% 0%, rgba(244, 245, 246, 0) 0%, #F4F5F6 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFFFFF;',
    lakeWaterGradient:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.84), rgba(255, 255, 255, 0.84)), linear-gradient(34.25deg, #438893 15.55%, #74B8D1 58.43%, #79CFE9 96.55%);',
    blueGradient:
      'linear-gradient(34.25deg, rgba(5, 75, 86, 0.4) 15.55%, rgba(19, 106, 138, 0.4) 58.43%, rgba(41, 150, 182, 0.4) 96.55%)',
  },
  primary: {
    light: '#EBF5FA' /* sky-100 */,
    main: '#246B8F' /* sky-700 */,
    dark: '#0F2E3D' /* sky-900 */,
    contrastText: '#FFF',
  },
  secondary: {
    // light: will be calculated from palette.secondary.main,
    main: '#DD523A' /* sunset */,
    // dark: will be calculated from palette.secondary.main,
    // contrastText: will be calculated to contrast with palette.secondary.main
  },
  error: {
    main: '#B22400' /* sunset-700 */,
  },
  neutral: {
    main: '#6A7981' /* ??? */,
    contrastText: '#FFF',
    light: '#64748B',
  },
  darkSuccess: {
    main: '#367D36',
    contrastText: '#FFF',
    light: '#82C982',
  },
  darkError: {
    main: '#B22400',
    contrastText: '#FFF',
    light: '#FF714C',
  },
  darkWarning: {
    main: '#B27700',
    contrastText: '#FFF',
    light: '#FFC34C',
  },
  darkInfo: {
    main: '#246B8F',
    contrastText: '#FFF',
    light: '#70B8DB',
  },
  darkNeutral: {
    main: '#505C62',
    contrastText: '#FFF',
    light: '#9DA9AF',
  },
  // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
  aurora: {
    main: '#A6D9A6',
    half: '#F8FCF8',
    one: '#EDF7ED',
    two: '#C9E8C9',
    three: '#A6D9A6',
    four: '#82C982',
    five: '#5EBA5E',
    seven: '#367D36',
  },
  brandBlue: {
    main: '#1C708D',
    light: '#599AB0',
    dark: '#03445A',
    gradient: '',
    contrastText: 'rgba(255, 255, 255, 0.87)',
  },
  brandWhite: {
    main: 'rgba(255, 255, 255, 255)',
    light: '#599AB0',
    dark: '#599AB0',
    gradient: '',
    contrastText: '#1C708D',
  },
  brandOrange: {
    main: '#F39A37',
    light: '#FFC687',
    dark: '#A95B03',
    contrastText: 'rgba(255, 255, 255, 0.87)',
  },
  dusk: {
    main: '#F1F0F5',
    half: '#FAF9FB',
    one: '#F1F0F5',
    two: '#D6D1E0',
    three: '#BBB2CC',
    six: '#6B5C8A',
    seven: '#53476B',
  },

  midnight: {
    main: '#F1F3F4',
    half: '#F9FAFA',
    one: '#F1F3F4',
    two: '#D5DADD',
    three: '#B9C1C6',
    four: '#9DA9AF',
    five: '#819098',
    six: '#67777E',
    seven: '#505C62',
    eight: '#394246',
    nine: '#22282A',
  },
  sky: {
    main: '#246B8F',
    half: '#F7FBFD',
    one: '#EBF5FA',
    two: '#C2E0F0',
    three: '#99CCE5',
    four: '#70B8DB',
    seven: '#246B8F',
    eight: '#1A4C66',
    nine: '#0F2E3D',
  },
  sunset: {
    main: '#FFEBE5',
    half: '#FFF7F5',
    one: '#FFEBE5',
    two: '#FFC2B3',
    four: '#FF714C',
    seven: '#B22400',
    eight: '#801A00',
    nine: '#4D1000',
  },
  sunrise: {
    main: '#FFFCF5',
    half: '#FFFCF5',
    one: '#FFF6E5',
    two: '#FFE5B3',
    four: '#FFC34C',
    seven: '#B27700',
  },
  controlBackground: {
    half: '#FFF',
    main: '#FFF',
    one: '#FFF',
    gradient: 'linear-gradient(#1C708D,#1C708D)',
    lakeWaterGradient:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.84), rgba(255, 255, 255, 0.84)), linear-gradient(34.25deg, #438893 15.55%, #74B8D1 58.43%, #79CFE9 96.55%)',
  },
  popper: {
    main: '#22282A',
    one: '#505C62',
    contrastText: '#fff',
  },
};

const darkMode = {
  custom: {
    main: '#EBF5FA',
    loginBackgroundGradient:
      'linear-gradient(0deg, rgba(16, 49, 65, 0.6), rgba(16, 49, 65, 0.6)), radial-gradient(100% 239.32% at 0% 0%, #C6E2F1 0%, rgba(198, 226, 241, 0) 100%), radial-gradient(239.32% 100% at 100% 0%, #262131 0%, rgba(38, 33, 49, 0) 100%), radial-gradient(239.32% 100% at 100% 0%, rgba(55, 129, 55, 0) 0%, #378137 100%), radial-gradient(100% 239.32% at 0% 0%, rgba(60, 69, 73, 0) 0%, #3C4549 100%)',
    gradient:
      'linear-gradient(34.25deg, #054B56 15.55%, #136A8A 58.43%, #2996B6 96.55%)',
    lakeWaterGradient:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.84), rgba(255, 255, 255, 0.84)), linear-gradient(34.25deg, #438893 15.55%, #74B8D1 58.43%, #79CFE9 96.55%);',
    blueGradient:
      'linear-gradient(34.25deg, rgba(5, 75, 86, 0.4) 15.55%, rgba(19, 106, 138, 0.4) 58.43%, rgba(41, 150, 182, 0.4) 96.55%)',
  },
  primary: {
    light: '#EBF5FA' /* sky-100 */,
    main: '#246B8F' /* sky-700 */,
    dark: '#0F2E3D' /* sky-900 */,
    contrastText: '#0F2E3D',
  },
  secondary: {
    // light: will be calculated from palette.secondary.main,
    main: '#DD523A' /* sunset */,
    // dark: will be calculated from palette.secondary.main,
    // contrastText: will be calculated to contrast with palette.secondary.main
  },
  error: {
    main: '#B22400' /* sunset-700 */,
  },
  neutral: {
    main: '#6A7981' /* ??? */,
    contrastText: '#FFF',
    light: '#64748B',
  },
  // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
  aurora: {
    main: '#A6D9A6',
    two: '#C9E8C9',
    three: '#A6D9A6',
    four: '#82C982',
    five: '#5EBA5E',
    seven: '#367D36',
  },
  brandBlue: {
    main: '#1C708D',
    light: '#599AB0',
    dark: '#03445A',
    contrastText: 'rgba(255, 255, 255, 0.87)',
  },
  brandWhite: {
    main: 'rgba(255, 255, 255, 0.87)',
    light: '#599AB0',
    dark: '#03445A',
    gradient: '',
    contrastText: '#1C708D',
  },
  brandOrange: {
    main: '#F39A37',
    light: '#FFC687',
    dark: '#A95B03',
    contrastText: 'rgba(255, 255, 255, 0.87)',
  },
  dusk: {
    main: '#6B5C8A',

    half: '#53476B',
    one: '#6B5C8A',
    six: '#F1F0F5',
    seven: '#FAF9FB',
  },

  midnight: {
    nine: '#F9FAFA',
    eight: '#394246',
    seven: '#F1F3F4',
    six: '#F1F3F4',
    five: '#D5DADD',
    four: '#9DA9AF',
    three: '#B9C1C6',
    two: '#819098',
    one: '#67777E',
    main: '#505C62',
    half: '#22282A',
  },
  sky: {
    main: '#C2E0F0',

    half: '#0F2E3D',
    one: '#1A4C66',
    two: '#246B8F',
    three: '#99CCE5',
    four: '#70B8DB',
    seven: '#C2E0F0',
    eight: '#EBF5FA',
    nine: '#F7FBFD',
  },
  sunset: {
    main: '#FFEBE5',
    half: '#FFF7F5',
    one: '#FFEBE5',
    two: '#FFC2B3',
    seven: '#B22400',
    eight: '#801A00',
    nine: '#4D1000',
  },
  sunrise: {
    main: '#FFFCF5',
    half: '#FFFCF5',
    two: '#FFE5B3',
    seven: '#B27700',
  },
  controlBackground: {
    half: '#000',
    main: '#22282A',
    one: '#241F2E',
    gradient:
      'linear-gradient(34.25deg, #054B56 15.55%, #136A8A 58.43%, #2996B6 96.55%)',
    lakeWaterGradient:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.84), rgba(255, 255, 255, 0.84)), linear-gradient(34.25deg, #438893 15.55%, #74B8D1 58.43%, #79CFE9 96.55%)',
  },
  popper: {
    main: '#22282A',
    one: '#505C62',
    contrastText: '#fff',
  },
};

export const getThemeOptions = (mode: PaletteMode) => ({
  palette: {
    mode: mode,
    ...(mode === 'light' ? lightMode : darkMode),
  },
  shadows: [
    'none',
    '0px 2px 4px -2px rgba(26, 76, 102, 0.24)', //Paper [1]
    '0px 8px 8px rgba(26, 76, 102, 0.04), 0px 2px 0px -2px rgba(26, 76, 102, 0.08)', //Subtle glass [2]
    '0px 16px 40px -24px rgba(26, 76, 102, 0.24)', //Hover [3]
    '0px 4px 4px -2px rgba(26, 76, 102, 0.08), 0px 8px 32px rgba(26, 76, 102, 0.08), 0px 2px 2px -2px rgba(26, 76, 102, 0.16)', //Raised surface [4]
    '0px 2px 40px -3px rgba(26, 76, 102, 0.16), 0px 16px 48px -24px rgba(26, 76, 102, 0.8)', //Menu [5]
    '0px 4px 48px 40px rgba(26, 76, 102, 0.08), 0px 8px 48px rgba(26, 76, 102, 0.16), 0px 16px 80px -2px rgba(26, 76, 102, 0.16)', //Modal [6]
    ...Array(18).fill('none'),
  ],
  typography: {
    fontFamily: 'Source Sans Pro, Merriweather Sans, Source Code Pro',
    h1: {
      fontFamily: 'Merriweather Sans',
      fontWeight: 700,
      fontSize: '26px',
      lineHeight: '31px',
      letterSpacing: 0,
    },
    h2: {
      fontFamily: 'Merriweather Sans',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '29px',
      letterSpacing: '0.25px',
    },
    h3: {
      fontFamily: 'Merriweather Sans',
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '26px',
      letterSpacing: '0.5px',
    },
    h4: {
      fontFamily: 'Merriweather Sans',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    h5: {
      fontFamily: 'Merriweather Sans',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: 0,
    },
    h6: {
      fontFamily: 'Merriweather Sans',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '18px',
      letterSpacing: '0.5px',
    },
    subtitle2: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    body1: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.25px',
    },
    body2: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.5px',
    },
    button: {
      fontFamily: 'Merriweather Sans',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '1px',
    },
    smallButton: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.75px',
    },
    caption: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 700,
      fontSize: '11px',
      lineHeight: '18px',
      letterSpacing: '0.75px',
    },
    overline: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '12px',
      letterSpacing: '1.5px',
    },
    inputText: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '18px',
      letterSpacing: '0.25px',
    },
    inputTextLink: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.25px',
    },
    inputLabel: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '14px',
      letterSpacing: '0.25px',
    },
    boldInputLabel: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '14px',
      letterSpacing: '0.25px',
    },
    tooltip: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '11px',
      lineHeight: '13px',
      letterSpacing: 0,
    },
    tooltipLink: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '11px',
      lineHeight: '15px',
      letterSpacing: '0.25px',
    },
    semanticH2styledSubtitle: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '18px',
      letterSpacing: '0.5px',
    },
    semanticH2styledH6: {
      fontFamily: 'Merriweather Sans',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.25px',
    },
    semanticH3styledSubtitle: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '18px',
      letterSpacing: '0.5px',
    },
    semanticH4styledOverline: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '12px',
      letterSpacing: '1.5px',
    },
    avatarLetter: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 700,
      fontSize: '21px',
      lineHeight: '25px',
      letterSpacing: '-0.25px',
    },
    preformatted: {
      fontFamily: 'Source Code Pro',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0',
    },
    helperText: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '17px',
      letterSpacing: '0.25px',
    },
    helperTextLink: {
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '17px',
      letterSpacing: '0.25px',
    },
    smallPreformat: {
      fontFamily: 'Source Code Pro',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '17px',
      letterSpacing: '0.25px',
    },
    buttonsmall: {
      fontSize: 12,
      fontFamily: 'Source Sans Pro, sans-serif',
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: '0.75px',
      lineHeight: '14px',
      textTransform: 'uppercase',
    },
  } as unknown as ExtendedTypographyOptions,
  spacing: 8,
  components: {
    MuiButton: {
      styleOverrides: {
        containedBrandWhite: ({ theme }: { theme: any }) => ({
          '&:hover': {
            color: theme.palette.primary.contrastText,
          },
        }),
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: ({ theme }: { theme: any }) => ({
            boxShadow: theme.shadows[1],
            '&:hover': {
              backgroundColor: theme.palette.sky.eight,
            },
            '&:focus': {
              outline: `2px solid ${theme.palette.sky.nine}`,
            },
            '&:active': {
              backgroundColor: theme.palette.sky.nine,
              color: theme.palette.sky.two,
            },
          }),
        },
        {
          props: { variant: 'filledAccessMain' },
          style: ({ theme }: { theme: any }) => ({
            boxShadow: theme.shadows[1],
            backgroundColor: theme.palette.aurora.four,
            justifyContent: 'flex-start',
            '&:hover': {
              backgroundColor: theme.palette.aurora.two,
              color: theme.palette.midnight.seven,
            },
            '&:focus': {
              outline: `1px solid ${theme.palette.aurora.five}`,
            },
            '&:active': {
              backgroundColor: theme.palette.aurora.four,
              color: theme.palette.midnight.seven,
            },
          }),
        },
        {
          props: { variant: 'filledAccessPopover' },
          style: ({ theme }: { theme: any }) => ({
            boxShadow: theme.shadows[1],
            color: 'white',
            backgroundColor: theme.palette.aurora.seven,
            '&:hover': {
              backgroundColor: theme.palette.aurora.seven,
            },
            '&:focus': {
              outline: `1px solid ${theme.palette.aurora.five}`,
            },
            '&:active': {
              backgroundColor: theme.palette.aurora.seven,
              color: 'white',
            },
          }),
        },
        {
          props: { variant: 'blankAccessMain' },
          style: ({ theme }: { theme: any }) => ({
            boxShadow: theme.shadows[1],
            justifyContent: 'flex-start',
            backgroundColor: theme.palette.midnight.two,
            '&:hover': {
              backgroundColor: theme.palette.aurora.two,
              color: theme.palette.midnight.seven,
            },
            '&:focus': {
              outline: `1px solid ${theme.palette.midnight.two}`,
            },
            '&:active': {
              backgroundColor: theme.palette.midnight.two,
              color: theme.palette.midnight.two,
            },
          }),
        },
        {
          props: { variant: 'blankAccessPopover' },
          style: ({ theme }: { theme: any }) => ({
            boxShadow: theme.shadows[1],
            color: theme.palette.midnight.four,
            backgroundColor: theme.palette.midnight.eight,
            '&:hover': {
              backgroundColor: theme.palette.midnight.eight,
            },
            '&:focus': {
              outline: `1px solid ${theme.palette.midnight.six}`,
            },
            '&:active': {
              backgroundColor: theme.palette.midnight.eight,
              color: theme.palette.midnight.four,
            },
          }),
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: ({ theme }: { theme: any }) => ({
            '&:hover': {
              backgroundColor: theme.palette.sunset.eight,
            },
            '&:focus': {
              outline: `2px solid ${theme.palette.sunset.nine}`,
            },
            '&:active': {
              backgroundColor: theme.palette.sunset.nine,
              color: theme.palette.sunset.two,
            },
          }),
        },
        {
          props: { variant: 'contained', color: 'neutral' },
          style: ({ theme }: { theme: any }) => ({
            '&:hover': {
              backgroundColor: theme.palette.midnight.four,
            },
            '&:focus': {
              outline: `2px solid ${theme.palette.midnight.six}`,
            },
            '&:active': {
              backgroundColor: theme.palette.midnight.six,
            },
          }),
        },
        {
          props: { variant: 'outlined' },
          style: ({ theme }: { theme: any }) => ({
            border: `1px solid ${theme.palette.sky.seven}`,
            '&:hover': {
              backgroundColor: theme.palette.sky.one,
            },
            '&:focus': {
              backgroundColor: '#fff',
              outline: `2px solid ${theme.palette.sky.seven}`,
            },
            '&:active': {
              backgroundColor: theme.palette.sky.two,
              color: theme.palette.sky.nine,
            },
          }),
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: ({ theme }: { theme: any }) => ({
            border: `1px solid ${theme.palette.sunset.seven}`,
            '&:hover': {
              backgroundColor: theme.palette.sunset.one,
            },
            '&:focus': {
              backgroundColor: '#fff',
              outline: `2px solid ${theme.palette.sunset.seven}`,
            },
            '&:active': {
              backgroundColor: theme.palette.sunset.two,
              color: theme.palette.sunset.nine,
            },
          }),
        },

        {
          props: { variant: 'text' },
          style: ({ theme }: { theme: any }) => ({
            '&:hover': {
              backgroundColor: theme.palette.sky.one,
            },
            '&:focus': {
              backgroundColor: '#fff',
              outline: `2px solid ${theme.palette.sky.seven}`,
            },
            '&:active': {
              backgroundColor: theme.palette.sky.two,
              color: theme.palette.sky.nine,
            },
          }),
        },
        {
          props: { size: 'small' },
          style: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            letterSpacing: '0.75px',
            borderRadius: '12px',
            padding: '4px 16px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
            letterSpacing: '0.75px',
            borderRadius: '16px',
            padding: '8px 24px',
          },
        },
        {
          props: { size: 'large' },
          style: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '1px',
            borderRadius: '24px',
            padding: '12px 24px',
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: Tabulink,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.controlBackground.main,
          '&:disabled': {
            backgroundColor: theme.palette.controlBackground.main,
            color: theme.palette.midnight.three,
          },
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          display: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
          color: '#505C62',
          padding: '12px 16px',
          boxShadow: theme.shadows[1],
          borderRadius: '8px',
          '& .MuiAlert-icon': {
            marginRight: 16,
            paddingTop: 0,
            paddingBottom: 0,
          },
          '& .MuiAlert-message': {
            paddingTop: '0px',
            paddingBottom: '0px',
          },
          ' & .MuiAlert-action': {
            paddingTop: '0px',
          },
        }),
      },
      variants: [
        {
          props: { severity: 'info' },
          style: ({ theme }: { theme: any }) => ({
            backgroundColor: theme.palette.sky.half,
            border: `1px solid ${theme.palette.sky.one}`,
            '& .MuiAlert-icon': {
              color: theme.palette.sky.seven,
              fontSize: '16px',
            },
          }),
        },
        {
          props: { severity: 'error' },
          style: ({ theme }: { theme: any }) => ({
            backgroundColor: theme.palette.sunset.half,
            border: `1px solid ${theme.palette.sunset.one}`,
            '& .MuiAlert-icon': {
              color: theme.palette.sunset.seven,
              fontSize: '16px',
            },
          }),
        },
        {
          props: { severity: 'warning' },
          style: ({ theme }: { theme: any }) => ({
            backgroundColor: theme.palette.sunrise.half,
            border: `1px solid ${theme.palette.sunrise.one}`,
            '& .MuiAlert-icon': {
              color: theme.palette.sunrise.seven,
              fontSize: '16px',
            },
          }),
        },
        {
          props: { severity: 'success' },
          style: ({ theme }: { theme: any }) => ({
            backgroundColor: theme.palette.aurora.one,
            border: `1px solid ${theme.palette.aurora.two}`,
            '& .MuiAlert-icon': {
              color: theme.palette.aurora.seven,
              fontSize: '16px',
            },
          }),
        },
        {
          props: { color: 'neutral' },
          style: ({ theme }: { theme: any }) => ({
            backgroundColor: theme.palette.midnight.half,
            border: `1px solid ${theme.palette.midnight.one}`,
            '& .MuiAlert-icon': {
              color: theme.palette.midnight.seven,
              fontSize: '16px',
            },
          }),
        },
        {
          props: { color: 'darkSuccess' },
          style: ({ theme }: { theme: any }) => ({
            backgroundColor: theme.palette.aurora.seven,
            border: `1px solid ${theme.palette.aurora.four}`,
            color: theme.palette.primary.contrastText,
            '& .MuiAlert-icon': {
              fontSize: '16px',
            },
          }),
        },
        {
          props: { color: 'darkError' },
          style: ({ theme }: { theme: any }) => ({
            backgroundColor: theme.palette.sunset.seven,
            border: `1px solid ${theme.palette.sunset.four}`,
            color: theme.palette.primary.contrastText,
            '& .MuiAlert-icon': {
              color: theme.palette.sunset.four,
              fontSize: '16px',
            },
          }),
        },
        {
          props: { color: 'darkWarning' as any },
          style: ({ theme }: { theme: any }) => ({
            backgroundColor: theme.palette.sunrise.seven,
            border: `1px solid ${theme.palette.sunrise.four}`,
            color: theme.palette.primary.contrastText,
            '& .MuiAlert-icon': {
              color: theme.palette.sunrise.four,
              fontSize: '16px',
            },
          }),
        },
        {
          props: { color: 'darkInfo' as any },
          style: ({ theme }: { theme: any }) => ({
            backgroundColor: theme.palette.sky.seven,
            border: `1px solid ${theme.palette.sky.four}`,
            color: theme.palette.primary.contrastText,
            '& .MuiAlert-icon': {
              color: theme.palette.sky.four,
              fontSize: '16px',
            },
          }),
        },
        {
          props: { color: 'darkNeutral' as any },
          style: ({ theme }: { theme: any }) => ({
            backgroundColor: theme.palette.midnight.seven,
            border: `1px solid ${theme.palette.midnight.four}`,
            color: theme.palette.primary.contrastText,
            '& .MuiAlert-icon': {
              color: theme.palette.midnight.four,
              fontSize: '16px',
            },
          }),
        },
      ],
    },
    MuiSnackbar: {
      styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
          boxShadow: theme.shadows[5],
          bottom: '36px !important',
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
          backgroundColor: theme.palette.controlBackground.main,
          // inputText
          fontFamily: 'Source Sans Pro',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '18px',
          letterSpacing: '0.25px',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.dusk.half,
          },
          '.MuiDataGrid-columnHeaderTitle': {
            // subtitle1
            fontFamily: 'Source Sans Pro',
            fontWeight: 600,
            fontSize: '15px',
            lineHeight: '18px',
            letterSpacing: '0.5px',
          },
          '.MuiDataGrid-iconButtonContainer button': {
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }: { theme: any }) => ({
          boxShadow: theme.shadows[6],
          minWidth: '500px',
          padding: theme.spacing,
          [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
          },
        }),
        root: ({ theme }: { theme: any }) => ({
          background: theme.palette.custom.blueGradient,
          backdropFilter: ' blur(1px)',
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          top: 'unset', // allow nav drawer to appear below app bar
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
          backgroundColor: theme.palette.controlBackground.main,
          '&:hover': {
            backgroundColor: theme.palette.controlBackground.main,
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.controlBackground.main,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '13px',
          lineHeight: '14px',
          letterSpacing: '0.25px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '13px',
          letterSpacing: '0.25px',
        },
        shrink: {
          transform: 'translate(14px, -9px) scale(.95)',
          transformOrigin: 'top left',
          width: '100%',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
          color: theme.palette.sky.main,
        }),
      },

      defaultProps: {
        //@ts-ignore
        component: Tabulink,
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
          '& .MuiPaper-elevation8': {
            boxShadow: theme.shadows[5],
          },
        }),
      },
    },
    MuiModal: {
      styleOverrides: {
        paper: ({ theme }: { theme: any }) => ({
          boxShadow: theme.shadows[6],
        }),
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#EEEEEE',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
          minHeight: 32,
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          semanticH2styledSubtitle: 'h2',
          semanticH2styledH6: 'h2',
          semanticH3styledSubtitle: 'h3',
          semanticH4styledOverline: 'h4',
          avatarLetter: 'span',
          tooltip: 'span',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }: { theme: any }) => ({
          '&.MuiListItemButton-root:hover': {
            backgroundColor: theme.palette.dusk.half,
          },
          '&.Mui-selected:hover': {
            backgroundColor: theme.palette.dusk.half,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.dusk.one,
          },
        }),
      },
    },
  },
  zIndex: {
    appBar: 1201, // theme.zIndex.drawer + 1
  },
});
