import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { useMutation } from '@apollo/client';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Formik, FormikValues, Form } from 'formik';
import { includes } from 'lodash';
import { defaultStyle } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import { CredentialTypes } from '../../../graphql/gen/graphql';
import { createOrgCredential } from '../../../graphql/organization';
import { getApolloErrorMessage } from '../../../utils/ApolloUtils';
import { getLogger } from '../../../utils/logging';
import { Talert } from '../../Alert/Talert';
import { Tabutton } from '../../Button/Tabutton';
import { RoleSelector } from '../../Forms/RoleSelector';
import TextField from '../../Forms/TextField';
import { validationSchema } from '../helpers';

export function IAMRoleMappingForm({
  organizationId,
  onCredentialCreated,
  onDismiss,
  modal = true,
  setFormVisible,
  preSelectedRoleId,
  modalTitle,
}: {
  organizationId: string;
  onCredentialCreated: (data: any) => void;
  onDismiss?: () => void;
  modal?: boolean;
  setFormVisible?: () => void;
  preSelectedRoleId?: string;
  modalTitle?: string;
}) {
  const [createCredentialMutation, { error }] =
    useMutation(createOrgCredential);
  const [errorMessage, setErrorMessage] = useState('');
  const logger = getLogger('components.Credentials.CreateIAMRoleMappingDialog');

  const handleCancel = () => {
    setErrorMessage('');
    onDismiss && onDismiss();
    setFormVisible && setFormVisible();
  };

  const onSubmit = async (values: FormikValues) => {
    const trimmedArn = values.roleArn.trim();
    const awsRoleNameIndex = trimmedArn.search('\\/\\S+$');
    const name = trimmedArn.slice(awsRoleNameIndex + 1);

    await createCredentialMutation({
      variables: {
        organizationId,
        name: name,
        type: CredentialTypes.Iam,
        roleId: values.roleId,
        roleArn: trimmedArn,
      },
    })
      .then((data) => {
        if (data) {
          onCredentialCreated(data.data.createOrgCredential.credential);
          onDismiss && onDismiss();
        }
      })
      .catch((error) => {
        logger.debug(getApolloErrorMessage(error));
        if (includes(error, '409: Conflict')) {
          setErrorMessage('AWS IAM role already linked to a Tabular role');
        } else {
          setErrorMessage('Something went wrong. Please try again.');
        }
      });
  };

  const arnExample = (
    <SyntaxHighlighter
      language={'text'}
      style={defaultStyle}
      customStyle={{
        display: 'inline-flex',
        fontSize: '13px',
        lineHeight: '1em',
        margin: 0,
        marginTop: 4,
        borderRadius: '4px',
        paddingTop: '.3em',
        paddingBottom: '.3em',
        wordBreak: 'break-word',
      }}
    >
      {`arn:aws:sts::<aws_acct_id>:assumed-role/<role>`}
    </SyntaxHighlighter>
  );

  return (
    <Formik
      initialValues={{
        roleId: preSelectedRoleId || '',
        roleArn: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <DialogTitle sx={{ p: 2 }}>
            {modalTitle
              ? modalTitle
              : 'Connect an AWS IAM role to a Tabular role'}
            <Typography
              variant={'helperText'}
              display={'block'}
              sx={{ pt: 2, pb: 0, pr: 2 }}
            >
              The full ARN of the role that Tabular is authorized to assume,
              example: {arnExample}
            </Typography>
          </DialogTitle>
          <DialogContent>
            {error && errorMessage && (
              <Talert severity="error" sx={{ mb: 1 }}>
                <Typography>{errorMessage}</Typography>
              </Talert>
            )}
            <TextField
              name="roleArn"
              type="text"
              label="AWS IAM Role ARN"
              id="roleArn"
              fullWidth
              required
              autoComplete="off"
              sx={{ my: 2 }}
            />
            {!preSelectedRoleId && <RoleSelector name="roleId" />}
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Tabutton onClick={handleCancel} disabled={isSubmitting}>
              Cancel
            </Tabutton>
            <Tabutton type="submit" disabled={isSubmitting} variant="contained">
              Create
            </Tabutton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
