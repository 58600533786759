import React, { useState } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFormikContext, FormikValues } from 'formik';
import { isEmpty } from 'lodash';

import TextField from '../../Forms/TextField';
import { GcsServiceAccountLink } from '../storage';
import { ExternalBreadcrumbs, serviceAccountBreadcrumbs } from './helpers';

export const ServiceAccountInstructions = ({
  principal,
  textField = false,
}: {
  principal: string;
  textField?: boolean;
}) => {
  const [copySuccess, setCopySuccess] = useState('');
  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  return (
    <List sx={{ listStyle: 'decimal', pl: 3 }}>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          <b>Name</b> service account, and optionally add a description.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0, mb: 0 }}>
        <Typography variant={'body2'}>
          Grant service account access by adding the following role:{' '}
          <b>Storage Object Admin</b>.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0, mb: 1 }}>
        <Typography variant={'body2'}>
          Click on newly created service account choose <b>Permissions</b> then{' '}
          <b>Grant Access</b>. Copy principal below and paste it in{' '}
          <b>New Principles</b>:
        </Typography>
      </ListItem>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Box display={'flex'} flexDirection={'column'} maxWidth={'400px'}>
          <Typography
            variant={'body2'}
            sx={{
              pr: 1,
              color: 'midnight.seven',
              wordWrap: 'break-word',
            }}
          >
            {principal}
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Tooltip
            title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
            placement={`top`}
            arrow
          >
            <ContentCopyOutlinedIcon
              onClick={() => {
                copyToClipBoard(principal);
              }}
              fontSize={'small'}
              sx={{
                cursor: 'pointer',
                marginLeft: 1,
                color: 'brandBlue.main',
              }}
            />
          </Tooltip>
        </Box>
      </Box>
      <ListItem sx={{ display: 'list-item', p: 0, mb: 1 }}>
        <Typography variant={'body2'}>
          Change the role to <b>Workload Identity User</b> then save.
        </Typography>
      </ListItem>
      {textField && (
        <ListItem sx={{ display: 'list-item', p: 0, mb: 1 }}>
          <Typography variant={'body2'}>
            Enter <b>Service account</b> below.
          </Typography>
        </ListItem>
      )}
    </List>
  );
};

export const GoogleServiceAccountStep = () => {
  const { values } = useFormikContext<FormikValues>();
  const principal = `principalSet://iam.googleapis.com/projects/${
    values.projectNum || 'project-number'
  }/locations/global/workloadIdentityPools/${values.poolId || 'pool-id'}/*`;

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} sx={{ px: 5, pb: 3 }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant="body1">
            {`Create a Service Account and add permissions`}
          </Typography>
        </Box>
        <Card variant={'outlined'} sx={{ borderRadius: 2, mt: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
            <CardHeader
              title={
                <>
                  <Typography variant={'subtitle1'} sx={{ mb: 1 }}>
                    {'Service Account'}
                  </Typography>
                  <ExternalBreadcrumbs
                    breadcrumbs={serviceAccountBreadcrumbs}
                    link={GcsServiceAccountLink}
                  />
                </>
              }
              sx={(theme) => ({
                pt: 2,
                borderBottom: `1px solid ${theme.palette.midnight.two}`,
              })}
            />
            <CardContent>
              <ServiceAccountInstructions principal={principal} textField />
              <Box margin={0}>
                <TextField
                  name="externalId"
                  type="text"
                  label="EX: <service-account-name>@<project-id>.iam.gserviceaccount.com"
                  id="externalId"
                  variant="standard"
                  margin="normal"
                  autoComplete="off"
                  helperText="Service account email found under IAM & Admin -> Service Accounts"
                  sx={{ mr: 2, width: '100%', padding: 0 }}
                />
              </Box>
            </CardContent>
          </Box>
        </Card>
      </Box>
    </>
  );
};
