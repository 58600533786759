import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { createOrgCredential } from '../../../graphql/organization';
import { Tabutton } from '../../Button/Tabutton';

export function CreateOrgCredentialDialogResults({
  credential,
  onDismiss,
}: {
  credential: any;
  onDismiss: any;
}) {
  return (
    <Dialog open={true}>
      <DialogTitle>New Credential</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Here's your new credential, make sure to copy into a secure location`}
        </DialogContentText>
        <Stack mt={2} direction="row">
          <TextField
            inputProps={{ spellCheck: 'false' }}
            autoFocus
            label="Credential"
            type="text"
            multiline
            value={credential}
            contentEditable={false}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onDismiss();
              }
            }}
            fullWidth
          />
          <IconButton
            onClick={() => navigator.clipboard.writeText(credential)}
            title="Copy to clipboard"
          >
            <FileCopyIcon />
          </IconButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Tabutton onClick={onDismiss}>Done</Tabutton>
      </DialogActions>
    </Dialog>
  );
}

export function CreateOrgCredentialDialog({
  organizationId,
  isOpen,
  onDismiss,
  onCredentialCreated,
  isExternalOption = false,
}: {
  organizationId: string;
  isOpen: boolean;
  onDismiss: () => void;
  onCredentialCreated: (result: string) => void;
  isExternalOption: boolean;
}) {
  const [name, setName] = useState('');
  const [result, setResult] = useState('');
  const [createCredentialMutation] = useMutation(createOrgCredential);

  const createCredential = async () => {
    const data = await createCredentialMutation({
      variables: {
        organizationId,
        name: name.trim(),
        external: isExternalOption,
      },
    });

    if (data) {
      // @ts-ignore
      setResult(data.data.createOrgCredential.credential);
    }
  };

  const reset = () => {
    setName('');
    setResult('');
  };

  if (!isOpen) return null;

  return result ? (
    <CreateOrgCredentialDialogResults
      credential={result}
      onDismiss={() => {
        reset();
        onCredentialCreated(result);
      }}
    />
  ) : (
    <Dialog open={true}>
      <DialogTitle sx={{ p: 2, pb: 0 }}>
        {`${
          isExternalOption
            ? 'Create custom identity provider'
            : 'Create member credential'
        }`}
        <Typography
          variant={'helperText'}
          display={'block'}
          sx={{ pt: 2, pb: 0, pr: 2 }}
        >
          {isExternalOption
            ? 'A custom identity provider enables a service to act on behalf of\n' +
              'authenticated users.'
            : ''}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Credential name"
          type="text"
          onChange={(e) => setName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              createCredential();
            }
          }}
          fullWidth
          required
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Tabutton onClick={onDismiss}>Cancel</Tabutton>
        <Tabutton
          disabled={!name.length}
          onClick={createCredential}
          variant="contained"
        >
          Create
        </Tabutton>
      </DialogActions>
    </Dialog>
  );
}
