import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Dialog, Link, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { isEmpty } from 'lodash';
import { enqueueSnackbar } from 'notistack';

import { toPipelines, toTableRoot } from '../../../../RouteTable';
import { useAuth } from '../../../../context/auth-context';
import { createDeduplicationPipeline } from '../../../../graphql/pipeline';
import { Talert } from '../../../Alert/Talert';
import { SearchAndNavigateItem } from '../../../Forms/SearchAndNavigateResources';
import { CustomIcon } from '../../../Icons/CustomIcon';
import { StatusBoardVariant } from '../../../StatusBoard';
import StatusBoard from '../../../StatusBoard/StatusBoard';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../../WizardContainer';
import { ChooseSource } from './ChooseSource';
import { ChooseTarget } from './ChooseTarget';
import Configure from './Configure';

const DedupeWizardDialog = create<{
  organizationId: string;
  preSelectedSource?: SearchAndNavigateItem;
}>(({ organizationId, preSelectedSource }) => {
  const { user } = useAuth();
  const modal = useModal();
  const navigate = useNavigate();

  const [sourceTable, setSourceTable] = useState<SearchAndNavigateItem | null>(
    preSelectedSource ? preSelectedSource : null,
  );
  const [sourceTablePath, setSourceTablePath] = useState<string>(
    preSelectedSource
      ? `${preSelectedSource.whName}.${preSelectedSource.dbName}.${preSelectedSource.tblName}`
      : '',
  );
  const [identifierError, setIdentifierError] = useState<
    undefined | SearchAndNavigateItem
  >(undefined);
  const [targetNameError, setTargetNameError] = useState<boolean>(false);
  const [target, setTarget] = useState<SearchAndNavigateItem>();
  const [targetDbPath, setTargetDbPath] = useState<string>('');
  const [targetTableName, setTargetTableName] = useState<string>('');
  const [pipelineName, setPipelineName] = useState<string>('');
  const [pipelineDescription, setPipelineDescription] = useState<string>('');

  const [createPipeline] = useMutation(createDeduplicationPipeline);
  const orgName = useMemo(() => user?.loginSession?.loggedInOrg?.name, [user]);

  const associatedMember = useMemo(() => {
    return {
      displayName: user?.loginSession?.membership?.displayName,
      properties: user?.loginSession?.membership?.properties,
      currentUser: true,
      id: user?.loginSession?.membership?.id,
      organizationId: user?.loginSession?.loggedInOrg?.id,
      user: {
        id: user?.id,
        email: user?.email,
      },
    };
  }, [user]);

  const sourceStatusContent = useMemo(() => {
    return [
      {
        id: 'dedupe-wizard-card',
        sections: [
          {
            variant: 'pipeline-header' as StatusBoardVariant,
            headerName: 'Pipeline',
            statusMessages: [
              pipelineName || 'My deduplication pipeline',
              pipelineDescription || '',
            ],
            state: 'Creating',
            associatedMember: associatedMember,
          },
          {
            variant: 'pipeline-source' as StatusBoardVariant,
            statusMessages: [
              'Source',
              sourceTablePath || 'Selecting source...',
            ],
            headerLabel: 'Source',
            slots: {
              headerIcon: {
                src: '/assets/icon/table.svg',
                sx: { width: 53, height: 32 },
              },
            },
          },
        ],
      },
    ];
  }, [sourceTablePath, associatedMember]);

  const targetStatusContent = useMemo(() => {
    return [
      {
        id: 'dedupe-wizard-card',
        sections: [
          {
            variant: 'pipeline-header' as StatusBoardVariant,
            headerName: 'Pipeline',
            statusMessages: [
              pipelineName || 'My deduplication pipeline',
              pipelineDescription || '',
            ],
            state: 'Creating',
            associatedMember: associatedMember,
          },
          {
            variant: 'pipeline-source' as StatusBoardVariant,
            statusMessages: [
              'Source',
              sourceTablePath || 'Selecting source...',
            ],
            headerLabel: 'Source',
            slots: {
              headerIcon: {
                src: '/assets/icon/table.svg',
                sx: { width: 53, height: 32 },
              },
            },
          },
          {
            variant: 'pipeline-target' as StatusBoardVariant,
            statusMessages: [
              'Target',
              targetDbPath
                ? `${targetDbPath}.${targetTableName}`
                : 'Selecting target...',
            ],
            headerLabel: 'Target',
            slots: {
              headerIcon: {
                src: '/assets/icon/table.svg',
                sx: { width: 53, height: 32 },
              },
            },
          },
        ],
      },
    ];
  }, [
    sourceTablePath,
    associatedMember,
    targetDbPath,
    targetTableName,
    pipelineName,
    pipelineDescription,
  ]);

  const handleCreatePipeline = async () => {
    const pipelineContext = {
      sourceBranchName: 'main',
      sourceTableReferenceId: sourceTable?.id,
      targetBranchName: 'main',
      targetDatabaseName: target?.dbName,
      targetWarehouseId: target?.whId,
      targetTableName,
    };

    await createPipeline({
      variables: {
        organizationId,
        request: {
          name: pipelineName,
          description: pipelineDescription,
          context: {
            sourceAndTargetContext: pipelineContext,
            targetFrequencySeconds: 5000,
          },
        },
      },
      onCompleted: (result) => {
        enqueueSnackbar(
          `${result?.createPipelineDeDuplication?.name} pipeline created successfully`,
          {
            variant: 'success',
          },
        );
        navigate(toPipelines(orgName));
      },
    });
  };

  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: 'Source table',
        subTitle: 'Choose source table',
        subHeader: <StatusBoard statusPanels={sourceStatusContent} />,
        body: (
          <ChooseSource
            onSourceSelected={(selected: SearchAndNavigateItem) => {
              setIdentifierError(undefined);
              setSourceTable(selected);
              setSourceTablePath(
                `${selected.whName}.${selected.dbName}.${selected.tblName}`,
              );
            }}
            selectedId={sourceTable?.id}
            onDisplayIdentifierError={(table: SearchAndNavigateItem) =>
              setIdentifierError(table)
            }
          />
        ),
        disallowContinue: !sourceTable,
        footerContent: !isEmpty(identifierError) ? (
          <Box>
            <Talert severity={`error`}>
              <Box display={`flex`}>
                <Typography variant={'subtitle2'}>
                  {`This source table does not have identifier fields configured.
                  Please`}{' '}
                  <Link
                    target="_blank"
                    href={toTableRoot(
                      orgName,
                      identifierError?.whName,
                      identifierError?.dbName,
                      identifierError?.tblName,
                    )}
                  >
                    edit the table
                  </Link>{' '}
                  {`or select a table with identifier fields
                  that can be used for deduplication.`}
                </Typography>
              </Box>
            </Talert>
          </Box>
        ) : sourceTablePath ? (
          <Box>
            <Talert severity={`success`}>
              <Box display={`flex`}>
                <Typography variant={'subtitle2'} sx={{ mr: 1 }}>
                  {`Selected table: `}
                </Typography>
                <Typography variant={`helperText`}>
                  {sourceTablePath}
                </Typography>
              </Box>
            </Talert>
          </Box>
        ) : (
          <></>
        ),
      },
      {
        title: 'Target database',
        subTitle: 'Choose database for target table',
        subHeader: <StatusBoard statusPanels={targetStatusContent} />,
        body: (
          <ChooseTarget
            onTargetSelected={(selected: SearchAndNavigateItem) => {
              setTarget(selected);
              setTargetDbPath(`${selected.whName}.${selected.dbName}`);
            }}
            selectedId={target?.id}
          />
        ),
        disallowContinue: !target,
        footerContent: targetDbPath ? (
          <Box>
            <Talert severity={`success`}>
              <Box display={`flex`}>
                <Typography variant={'subtitle2'} sx={{ mr: 1 }}>
                  {`Selected database: `}
                </Typography>
                <Typography variant={`helperText`}>{targetDbPath}</Typography>
              </Box>
            </Talert>
          </Box>
        ) : (
          <></>
        ),
      },
      {
        title: 'Configuration',
        subHeader: <StatusBoard statusPanels={targetStatusContent} />,
        body: (
          <Configure
            pipelineName={pipelineName}
            setPipelineName={setPipelineName}
            targetTableName={targetTableName}
            setTargetTableName={setTargetTableName}
            description={pipelineDescription}
            setDescription={setPipelineDescription}
            onTargetNameError={setTargetNameError}
          />
        ),
        disallowContinue: !pipelineName || !targetTableName || targetNameError,
        nextButtonEffect: handleCreatePipeline,
        buttonText: 'Start pipeline',
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <WizardContainer
        title={`Deduplication`}
        stepsTitle={'Deduplication'}
        stepsSubtitle={`Pipeline`}
        stepsIcon={
          <CustomIcon
            src={'/assets/icon/dedupe.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        backButtonClick={() => {}}
        customStartStep={preSelectedSource ? 1 : 0}
      />
    </Dialog>
  );
});

export function showDedupeWizard({
  organizationId,
  preSelectedSource,
}: {
  organizationId: string;
  preSelectedSource?: SearchAndNavigateItem;
}) {
  return show(DedupeWizardDialog, { organizationId, preSelectedSource });
}
