import React from 'react';

import { useLazyQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { SearchEntityType } from '../../../../graphql/gen/graphql';
import { fetchTableMetadata } from '../../../../graphql/table';
import SearchAndNavigateResources, {
  SearchAndNavigateItem,
} from '../../../Forms/SearchAndNavigateResources';

export const ChooseSource = ({
  onSourceSelected,
  selectedId,
  onDisplayIdentifierError,
}: {
  onSourceSelected: (selected: SearchAndNavigateItem) => void;
  selectedId?: string;
  onDisplayIdentifierError: (table: SearchAndNavigateItem) => void;
}): JSX.Element => {
  const [checkTableIdentifiers] = useLazyQuery(fetchTableMetadata);

  const checkValidation = async (selected: SearchAndNavigateItem) => {
    await checkTableIdentifiers({
      variables: {
        warehouseId: selected.whId,
        databaseName: selected.dbName,
        tableName: selected.tblName,
      },
      onCompleted: (data) => {
        if (
          !isEmpty(
            data?.fetchTableMetadata?.current_schema?.identifier_field_ids,
          )
        ) {
          onSourceSelected(selected);
        } else {
          onDisplayIdentifierError(selected);
        }
      },
    });
  };

  return (
    <>
      <Typography
        variant={`body1`}
        sx={{ mb: 2 }}
      >{`Choose a source table to deduplicate. The deduplication pipeline will look for duplicates in the source table (main branch). The deduplicated version will be published on your target table.`}</Typography>
      <Typography fontWeight={`bold`} variant={`body1`} sx={{ mb: 1 }}>
        Search and select your source table.
      </Typography>
      <SearchAndNavigateResources
        onResourceSelected={(selected: SearchAndNavigateItem) =>
          checkValidation(selected)
        }
        resourceType={SearchEntityType.Table}
        selectedId={selectedId}
      />
    </>
  );
};
