import React from 'react';
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import { useUserAgent } from 'react-ua';

// @ts-ignore
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Paper,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
// @ts-ignore
import { grey } from '@mui/material/colors';
// @ts-ignore
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// @ts-ignore
import { Organization, Warehouse } from '../../../graphql/gen/graphql';
import { Tabutton } from '../../Button/Tabutton';

interface TrinoSQLTemplateProps extends StackProps {}
export const TrinoSQLTemplate = ({
  ...props
}: TrinoSQLTemplateProps): JSX.Element => {
  const ua = useUserAgent();

  const cmds = [
    {
      title: `List your Warehouses (called catalogs in Trino)`,
      cmd: `show catalogs;`,
    },
    {
      title: `List your default Warehouse's databases (called schemas in Trino)`,
      cmd: `show schemas;`,
    },
    { title: `Use the example database.`, cmd: `use examples;` },
    {
      title: `Analyze New York Taxi data`,
      cmd: `
describe examples.nyc_taxi_yellow;

create table if not exists default.taxi_tip_rate_per_passenger_trino as
select 
  passenger_count,
  count(*) as trip_count,
  avg(tip_amount / fare_amount) as tip_rate
from examples.nyc_taxi_yellow
where 
    pickup_time between date '2020-03-01' and date '2020-03-31'
    and passenger_count > 0
    and fare_amount > 0
group by passenger_count;

select 
  passenger_count,  
  trip_count,
  format('%,.2f', tip_rate * 100) as tip_percentage
from default.taxi_tip_rate_per_passenger_trino 
order by passenger_count;`,
    },
  ];

  return (
    <Stack direction="column" {...props} sx={{}}>
      {cmds.map((cmd, idx) => (
        <Box key={'sqlcmd' + idx} sx={{ width: 1 }}>
          <Grid
            container
            sx={{
              width: 0.95,
              mt: 2,
              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 2,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
              }}
            >
              <Typography variant={'subtitle1'} sx={{}}>
                {cmd.title}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 0,
                pre: {
                  m: 0,
                  height: 1,
                },
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              {' '}
              <SyntaxHighlighter
                language="SQL"
                style={{ ...dracula }}
                showLineNumbers={false}
              >
                {cmd.cmd}
              </SyntaxHighlighter>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 0.5,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant={'body1'}>
                {/* @ts-ignore */}
                <Tabutton
                  onClick={() => navigator.clipboard.writeText(cmd.cmd)}
                  title="Copy to clipboard"
                  size={'small'}
                  sx={{ alignSelf: 'center' }}
                  endIcon={<FileCopyIcon sx={{ ml: 1 }} />}
                >
                  Copy Command
                </Tabutton>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Stack>
  );
};
