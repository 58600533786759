import React from 'react';

import { Box, FormControlLabel, Radio, Stack, Typography } from '@mui/material';
import {
  Field,
  FormikErrors,
  FormikTouched,
  FormikValues,
  useFormikContext,
} from 'formik';
import { RadioGroup, TextField } from 'formik-mui';

import { LabelResourceType } from '../../graphql/gen/graphql';

type LabelOverviewProps = {
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
  isSubmitting: boolean;
  userCanEditOverview: boolean;
};

export function LabelOverview({
  touched,
  errors,
  isSubmitting,
  userCanEditOverview,
}: LabelOverviewProps) {
  const { setFieldValue, handleChange, setFieldTouched } =
    useFormikContext<FormikValues>();
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant={`body1`}>
        Using a descriptive name will help when applying and using the label.
      </Typography>
      <Field
        component={TextField}
        id="name"
        name="name"
        type="text"
        label="Label name*"
        variant="outlined"
        margin="normal"
        autoComplete="off"
        fullWidth
        error={touched['name'] && errors['name']}
        disabled={!userCanEditOverview || isSubmitting}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldTouched('name', true);
          setFieldValue('name', e.target.value);
          handleChange(e);
        }}
      />
      <Field
        component={TextField}
        id="description"
        name="description"
        type="text"
        label="Description"
        variant="outlined"
        margin="normal"
        autoComplete="off"
        fullWidth
        multiline
        rows={4}
        error={touched['description'] && errors['description']}
        disabled={!userCanEditOverview || isSubmitting}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldTouched('description', true);
          setFieldValue('description', e.target.value);
          handleChange(e);
        }}
      />
      {/*  Removing scope until we can apply labels to more resources */}
      {/*<Box>*/}
      {/*  <Typography variant={`body1`} fontWeight={600}>*/}
      {/*    Scope*/}
      {/*  </Typography>*/}
      {/*  <Typography variant={`body1`} marginY={1}>*/}
      {/*    This label can be applied to:*/}
      {/*  </Typography>*/}
      {/*  <Field component={RadioGroup} name="scope">*/}
      {/*    <FormControlLabel*/}
      {/*      value="ALL"*/}
      {/*      control={<Radio disabled={isSubmitting} />}*/}
      {/*      label="All resources"*/}
      {/*      disabled={isSubmitting}*/}
      {/*    />*/}
      {/*    <FormControlLabel*/}
      {/*      value={LabelResourceType.Field}*/}
      {/*      control={<Radio disabled={isSubmitting} />}*/}
      {/*      label="Columns"*/}
      {/*      disabled={isSubmitting}*/}
      {/*    />*/}
      {/*  </Field>*/}
      {/*</Box>*/}
    </Stack>
  );
}
