import React from 'react';

import { Box } from '@mui/material';

import { Tabutton } from '../Button/Tabutton';
import TextField from '../Forms/TextField';

export default function EmailButtonGroup({
  buttonText,
  formAutoComplete,
  emailValue,
  setEmailValue,
  setFieldValue,
  buttonCallback,
  emailDisabled = false,
  buttonDisabled = false,
}: {
  buttonText: string;
  formAutoComplete: string;
  emailValue: string;
  setEmailValue: (email: string) => void;
  setFieldValue: any;
  buttonCallback: () => void;
  emailDisabled?: boolean;
  buttonDisabled: boolean;
}) {
  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        name="email"
        type="email"
        label="Email"
        id="email"
        variant="outlined"
        margin="normal"
        fullWidth
        autoFocus
        autoComplete={formAutoComplete}
        value={emailValue}
        onChange={(e) => {
          setEmailValue(e.target.value);
          setFieldValue('email', e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !buttonDisabled) {
            buttonCallback();
          }
        }}
        disabled={emailDisabled}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tabutton
          variant="contained"
          disabled={buttonDisabled}
          onClick={() => buttonCallback()}
        >
          {buttonText}
        </Tabutton>
      </Box>
    </Box>
  );
}
