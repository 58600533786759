import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  DialogActions,
  DialogContent,
  Popper,
  useTheme,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik, FormikHelpers } from 'formik';
import { concat, debounce, includes, isEmpty } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { enqueueSnackbar } from 'notistack';

import { useAuth } from '../../context/auth-context';
import {
  AuthDecisionResourceType,
  Privilege,
  SearchRoleResult,
  SortByDirection,
} from '../../graphql/gen/graphql';
import { createLabel, labelFieldMasking } from '../../graphql/label';
import { searchRolesQuery } from '../../graphql/organization';
import { getLogger } from '../../utils/logging';
import { getSecurityAdminDefault } from '../AccessControl/common';
import { Tabutton } from '../Button/Tabutton';
import { ColumnMaskingPolicy } from '../Labels/ColumnMaskingPolicy';
import { LabelAccessControls } from '../Labels/LabelAccessControls';
import { LabelDisplay } from '../Labels/LabelDisplay';
import { LabelOverview } from '../Labels/LabelOverview';
import {
  compileGrantsList,
  createLabelValidationSchema,
  LabelAccessRoleType,
  RoleSearchParams,
  TabPanel,
} from '../Labels/helpers';
import { NavTab, TabWrapper } from '../NavTabs/SubNavTabs';
import { LabelPopover } from '../Popovers/LabelPopover';

const logger = getLogger(
  'components.Forms.CreateLabel' /*FUTURE import.meta.url ?*/,
);

export default function CreateLabel({
  organizationId,
  onCancel,
  onLabelCreated,
  onPartialFail,
}: {
  organizationId: string;
  onCancel: () => void;
  onLabelCreated: (label: any) => void;
  onPartialFail: () => void;
}) {
  const { user } = useAuth();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [roleOptions, setRoleOptions] = useState<SearchRoleResult[]>([]);
  const [maskingEnabled, setMaskingEnabled] = useState(false);
  const [highlighted, setHighlighted] = useState('');
  const [getRoles, { data, loading, refetch }] = useLazyQuery<
    any,
    RoleSearchParams
  >(searchRolesQuery, {
    variables: {
      organizationId: organizationId,
      query: searchQuery !== '' ? searchQuery : '*',
      maxResults: 100,
      searchAfter: undefined,
      sortBy: [
        {
          fieldName: searchQuery ? '_score' : 'roleName.sort',
          sortDirection: searchQuery
            ? SortByDirection.Desc
            : SortByDirection.Asc,
        },
      ],
      excludeSystemRoles: false,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const [createLabelMutation] = useMutation(createLabel);
  const [updateFieldMasking] = useMutation(labelFieldMasking);
  const initialGrantRoleOptions: LabelAccessRoleType[] = useMemo(() => {
    return roleOptions.map((role) => {
      return {
        roleName: role.roleName,
        roleId: role.roleId,
        privileges: [],
      };
    });
  }, [roleOptions]);
  const initialValues = useMemo(() => {
    const everyoneRole = user?.loginSession?.roles?.find(
      (role: any) => role.name === 'EVERYONE',
    );
    const security = user?.loginSession?.roles?.find(
      (role: any) => role.name === 'SECURITY_ADMIN',
    );

    const securityAdminDefault = getSecurityAdminDefault(
      security,
      AuthDecisionResourceType.Label,
    );

    return {
      name: '',
      description: '',
      scope: 'ALL',
      mode: { enabled: false, value: 'NULL' },
      exemptRoles: [],
      labelAccess: [
        {
          roleId: everyoneRole?.id,
          roleName: everyoneRole?.name,
          privileges: [],
        },
        {
          roleId: security?.id,
          roleName: security?.name,
          privileges: [securityAdminDefault.authorizations],
        },
      ],
      createPrivs: [],
      updatePrivs: [],
      deletePrivs: [],
    };
  }, [user]);

  const nextKeys: string[] = useMemo(
    () =>
      data?.searchRoles?.searchAfterKeys
        ? data?.searchRoles?.searchAfterKeys
        : [],
    [data],
  );
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      //@ts-ignore
      const searchyMcSearch = e?.target?.value || '';
      setSearchQuery(searchyMcSearch);
    },
    [],
  );
  const debouncedSearchChange = debounce(onSearchChange, 500);
  const handleScroll = useCallback(
    (event: React.SyntheticEvent) => {
      const listboxNode = event.currentTarget;
      const position = listboxNode.scrollTop + listboxNode.clientHeight;

      if (
        listboxNode.scrollHeight - position <= 1 &&
        roleOptions?.length < data?.searchRoles?.totalHits
      ) {
        refetch({
          searchAfter: nextKeys.length === 0 ? undefined : nextKeys,
        }).then((refetchGuys) => {
          setRoleOptions((prevRoleOptions) => [
            ...prevRoleOptions,
            ...refetchGuys?.data?.searchRoles?.results,
          ]);
        });
      }
    },
    [nextKeys, data, roleOptions],
  );

  useEffect(() => {
    if (maskingEnabled && searchQuery !== undefined) {
      refetch({
        query: searchQuery !== '' ? searchQuery : '*',
        searchAfter: undefined,
      })
        .then((refetchGuys) =>
          setRoleOptions(refetchGuys?.data?.searchRoles?.results),
        )
        .catch((error) => {
          logger.error('problem querying roles ', error);
        });
    }
  }, [searchQuery, maskingEnabled]);

  useEffect(() => {
    if (isEmpty(roleOptions) && (maskingEnabled || activeTab === 2)) {
      getRoles().then((result) =>
        setRoleOptions(result?.data?.searchRoles?.results),
      );
    }
  }, [maskingEnabled, activeTab, roleOptions]);

  const submitForm = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>,
  ) => {
    const grantsList = compileGrantsList(values.createPrivs);
    const exceptionList = values.exemptRoles?.map((role: SearchRoleResult) => {
      return {
        privilege: Privilege.Select,
        roleId: role.roleId,
        withGrant: false,
      };
    });
    const createLabelRequest = {
      name: values.name,
      description: values.description,
      allowedResourceTypes: values.scope === 'ALL' ? [] : [values.scope],
      initialGrants: concat(grantsList, exceptionList),
    };

    const result = await createLabelMutation({
      variables: {
        organizationId,
        request: createLabelRequest,
      },
    });

    if (result?.data?.createLabel?.id && values.mode.enabled) {
      const maskingResult = await updateFieldMasking({
        variables: {
          organizationId,
          labelId: result.data.createLabel.id,
          request: { mode: values.mode.value },
        },
        onError: (error) => {
          const permissionsError = includes(error.message, '403');
          onPartialFail();
          enqueueSnackbar(
            `${
              permissionsError
                ? 'Label was created but you do not have privileges to set the label policy.'
                : 'Label was created but something went wrong with setting the policy. Edit the label to set the policy.'
            }`,
            { variant: 'warning' },
          );
        },
      });

      if (maskingResult?.data?.updateLabelFieldMasking?.id) {
        await setSubmitting(false);
        onLabelCreated(maskingResult?.data?.updateLabelFieldMasking);
      }
    } else {
      await setSubmitting(false);
      onLabelCreated(result.data.createLabel);
    }
  };

  return (
    <Box>
      <DialogTitle>Create a label</DialogTitle>
      <DialogContent
        sx={{
          '& form': {
            minHeight: 682,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={submitForm}
          validationSchema={createLabelValidationSchema}
        >
          {({ isSubmitting, values, touched, errors, setFieldValue }) => (
            <Form>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <TabWrapper
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label={`Create Label`}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                    variant={'standard'}
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        border: 'none',
                        marginRight: '0px',
                        paddingRight: '0px',
                      },
                    }}
                  >
                    <NavTab value={0} key={`overview`} label={`Overview`} />
                    <NavTab value={1} key={`policy`} label={`Policy`} />
                    <NavTab value={2} key={`access`} label={`Access Control`} />
                  </TabWrapper>
                </Box>
                <TabPanel index={0} value={activeTab}>
                  <LabelOverview
                    touched={touched}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    userCanEditOverview={true}
                  />
                </TabPanel>
                <TabPanel index={1} value={activeTab}>
                  <ColumnMaskingPolicy
                    setFieldValue={setFieldValue}
                    values={values}
                    maskingEnabled={maskingEnabled}
                    setMaskingEnabled={setMaskingEnabled}
                    isSubmitting={isSubmitting}
                    loading={loading}
                    roleOptions={roleOptions}
                    highlighted={highlighted}
                    setHighlighted={setHighlighted}
                    handleScroll={handleScroll}
                    debouncedSearchChange={debouncedSearchChange}
                    showMemberCount={true}
                    userCanEditPolicy={true}
                  />
                </TabPanel>
                <TabPanel index={2} value={activeTab}>
                  <LabelAccessControls
                    values={values}
                    setFieldValue={setFieldValue}
                    loading={loading}
                    labelAccessRoleOptions={initialGrantRoleOptions}
                    highlighted={highlighted}
                    setHighlighted={setHighlighted}
                    handleScroll={handleScroll}
                    showMemberCount={true}
                    userCanEditAccess={true}
                    debouncedSearchChange={debouncedSearchChange}
                  />
                </TabPanel>
              </Box>
              <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 2,
                  flexWrap: 'wrap',
                }}
              >
                <Box maxWidth={'360px'} overflow={'hidden'}>
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <LabelDisplay
                          hasPolicy={values.mode.enabled}
                          maskMode={
                            values.mode.enabled ? values.mode.value : ''
                          }
                          labelName={values.name ? values.name : 'Label name'}
                          {...bindHover(popupState)}
                        />
                        <Popper
                          {...bindPopper(popupState)}
                          sx={{ zIndex: 9999999 }}
                          placement={`top`}
                        >
                          <LabelPopover
                            description={values.description}
                            name={values.name}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                </Box>
                <Box>
                  <Tabutton
                    sx={{ mr: 1 }}
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    Cancel
                  </Tabutton>
                  <Tabutton
                    type="submit"
                    variant="contained"
                    disabled={
                      isSubmitting || !values.name || Boolean(errors['name'])
                    }
                  >
                    Create Label
                  </Tabutton>
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Box>
  );
}
