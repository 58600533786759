import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Box, CardHeader, Paper, Typography, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

import { ESCustomPagination } from '../../pages/helpers';
import { BranchSelector } from '../BranchSelector/BranchSelector';
import { Refresh } from '../Button/Refresh';
import ESCustomSearchBar, { Filters } from '../SearchBar/ESCustomSearchBar';
import { SnapshotRefProp } from '../SnapshotViewer/SnapshotView';

export default function TableEventToolbar({
  totalCount,
  apiRef,
  pageSize,
  onRefetch,
  onBackButton,
  onNextButton,
  page,
  onSearchChange,
  options,
  branchRefs,
  selectedBranch,
  handleChangeBranch,
}: {
  totalCount: number;
  apiRef: React.MutableRefObject<GridApiPro>;
  pageSize: number;
  onRefetch: () => void;
  onBackButton: () => void;
  onNextButton: () => void;
  page: number;
  onSearchChange: (query: Filters) => void;
  options: { [key: string]: string[] };
  branchRefs: SnapshotRefProp[];
  selectedBranch: string;
  handleChangeBranch: (branch: string) => void;
}) {
  const currentRowsCount = apiRef.current.getRowsCount();
  const properPageNum = page - 1;
  const mobileToolBar = useMediaQuery('(max-width:500px)');

  return (
    <>
      <CardHeader
        sx={(theme) => ({
          paddingY: 1,
          paddingX: 2,
          margin: 'none',
          backgroundColor: 'midnight.one',
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
        title={
          <>
            <Box
              display="flex"
              flexDirection={mobileToolBar ? 'column' : 'row'}
              justifyContent={'flex-start'}
              padding={0}
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                sx={{ wordBreak: 'break-word' }}
              >
                <Typography variant={'h6'}>{`Table activity`}</Typography>
              </Box>

              <Box
                display={'flex'}
                justifyContent={mobileToolBar ? 'flex-start' : 'flex-end'}
                paddingTop={mobileToolBar ? 1 : 0}
                flex={1}
              >
                <Refresh
                  refreshList={() => onRefetch()}
                  title={'Refresh event list'}
                />
              </Box>
            </Box>
            <Box
              sx={{ pt: 1, display: 'flex', alightItems: 'center', flex: 1 }}
            >
              <BranchSelector
                branchRefs={branchRefs || []}
                selectedBranch={selectedBranch}
                handleChangeBranch={handleChangeBranch}
                showAll
                sx={{ height: 34, mr: 1 }}
              />
              <Paper
                component="form"
                sx={(theme) => ({
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  py: 0,
                  borderRadius: '24px',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  border: `1px solid ${theme.palette.midnight.two}`,
                  '&:hover, &:active, &:focus': {
                    border: `1px solid ${theme.palette.sky.seven}`,
                  },
                })}
              >
                <IconButton type="button" aria-label="search">
                  <SearchIcon sx={{ fontSize: '16px' }} />
                </IconButton>
                <ESCustomSearchBar
                  onSearchChange={onSearchChange}
                  options={options}
                />
              </Paper>
            </Box>
          </>
        }
      />
      <Box
        display={`flex`}
        alignItems={`center`}
        justifyContent={`space-between`}
        sx={(theme) => ({
          pl: 2,
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
      >
        <Typography variant={`inputLabel`}>{`Showing ${
          properPageNum * pageSize + 1
        } - ${
          properPageNum * pageSize + currentRowsCount
        } of ${totalCount}`}</Typography>
        <ESCustomPagination
          apiRef={apiRef}
          pageSize={pageSize}
          onBackButton={onBackButton}
          onNextButton={onNextButton}
          page={page}
          paginationType={`Events`}
        />
      </Box>
    </>
  );
}
