import React, { useState } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';

import TextField from '../../Forms/TextField';
import { AzureAppRegistrationLink, GcsWorkloadIdentityLink } from '../storage';
import { ExternalBreadcrumbs, createPoolbreadcrumbs } from './helpers';

const RegisterAppInstructions = ({
  textField = false,
}: {
  textField?: boolean;
}) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  return (
    <List sx={{ listStyle: 'decimal', pl: 3 }}>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Create an app registration. Enter a <b>Name</b> (such as
          &quot;Tabular&quot;).
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Accept the defaults and click <b>Register</b>.
        </Typography>
      </ListItem>

      <ListItem sx={{ display: 'list-item', p: 0, m: 0 }}>
        <Typography variant={'body2'} paddingRight={1}>
          Enter <b>Application (client) ID</b> and <b>Directory (tenant) ID</b>{' '}
          below.
        </Typography>
      </ListItem>
    </List>
  );
};

export default function AzureAppStep() {
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} sx={{ px: 5, pb: 3 }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant="body1">
            {`Create an Azure app registration`}
          </Typography>
        </Box>
        <Card variant={'outlined'} sx={{ borderRadius: 2, mt: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
            <CardHeader
              title={
                <>
                  <Typography variant={'subtitle1'} sx={{ mb: 1 }}>
                    App registrations
                  </Typography>
                  <ExternalBreadcrumbs
                    breadcrumbs={[
                      'Home',
                      'App Registrations',
                      '+ New Registration',
                    ]}
                    link={AzureAppRegistrationLink}
                  />
                </>
              }
              sx={(theme) => ({
                pt: 2,
                borderBottom: `1px solid ${theme.palette.midnight.two}`,
              })}
            />
            <CardContent>
              <RegisterAppInstructions textField />
              <Box display={'flex'} flexDirection={'column'}>
                <TextField
                  name="applicationId"
                  type="text"
                  label="Application (client) ID"
                  id="applicationId"
                  variant="standard"
                  margin="normal"
                  autoComplete="off"
                  helperText="The Application (client) ID of your newly created Azure app."
                  sx={{ mr: 2 }}
                  fullWidth
                />
                <TextField
                  name="tenantId"
                  type="text"
                  label="Directory (tenant) ID"
                  id="tenantId"
                  variant="standard"
                  margin="normal"
                  autoComplete="off"
                  helperText="The Directory (tenant) ID of your newly created Azure app."
                  sx={{ mr: 2 }}
                  fullWidth
                />
              </Box>
            </CardContent>
          </Box>
        </Card>
      </Box>
    </>
  );
}
