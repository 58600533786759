import * as React from 'react';

import { Typography, Box, Stack, Divider } from '@mui/material';
import PropTypes from 'prop-types';

type Props = {
  added: string | number;
  removed: string | number;
  total: string;
  icon: object;
  diff: string;
  exactRows?: string;
};
export const TrendPopover = React.forwardRef(
  ({ added, removed, total, icon, diff, exactRows, ...props }: Props, ref) => {
    // @ts-ignore
    // @ts-ignore
    return (
      <Box
        ref={ref}
        {...props}
        display={`flex`}
        flexDirection={`column`}
        sx={(theme) => ({
          borderRadius: '4px',
          position: 'relative',
          ml: '10px',
          boxShadow: theme.shadows[3],
        })}
      >
        <Box
          sx={{
            backgroundColor: 'popper.main',
            color: 'popper.contrastText',
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          }}
        >
          <Typography variant={`subtitle2`}>Last 7 days</Typography>
        </Box>
        <Box display={`flex`}>
          <Box
            sx={{
              backgroundColor: 'popper.main',
              color: 'popper.contrastText',
              minWidth: 176,
              flexBasis: '35%',
              borderBottomLeftRadius: exactRows ? '0' : '4px',
            }}
          >
            <Stack direction={`column`}>
              <Box
                display={`flex`}
                alignItems={`center`}
                sx={{
                  py: 1,
                  px: 2,
                  backgroundColor: 'popper.one',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexBasis: '45%',
                    justifyContent: 'flex-end',
                    mr: 1,
                  }}
                >
                  <Typography sx={{ whiteSpace: 'nowrap' }} variant={`caption`}>
                    {added}
                  </Typography>
                </Box>
                <Typography variant={`inputLabel`}>Added</Typography>
              </Box>
              <Divider sx={{ backgroundColor: 'popper.one' }} />
              <Box
                display={`flex`}
                alignItems={`center`}
                sx={{
                  py: 1,
                  px: 2,
                  backgroundColor: 'popper.one',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexBasis: '45%',
                    justifyContent: 'flex-end',
                    mr: 1,
                  }}
                >
                  <Typography sx={{ whiteSpace: 'nowrap' }} variant={`caption`}>
                    {removed}
                  </Typography>
                </Box>
                <Typography variant={`inputLabel`}>Removed</Typography>
              </Box>
              <Divider sx={{ backgroundColor: 'popper.one' }} />
              <Box
                display={`flex`}
                alignItems={`center`}
                sx={{
                  py: 1,
                  px: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexBasis: '45%',
                    justifyContent: 'flex-end',
                    mr: 1,
                  }}
                >
                  <Typography sx={{ whiteSpace: 'nowrap' }} variant={`caption`}>
                    {total}
                  </Typography>
                </Box>
                <Typography variant={`inputLabel`}>Total</Typography>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              minWidth: '86px',
              backgroundColor: 'midnight.six',
              color: 'popper.contrastText',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottomRightRadius: `${exactRows ? '0' : '4px'}`,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <>
                  {icon}
                  <Typography variant={`caption`} sx={{ ml: '4px' }}>
                    {diff}
                  </Typography>
                </>
              </Box>
            </Box>
          </Box>
        </Box>
        {exactRows && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Divider sx={{ backgroundColor: 'midnight.five' }} />
            <Box
              sx={{
                backgroundColor: 'popper.main',
                color: 'popper.contrastText',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 1,
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
              }}
            >
              <Typography variant={`inputLabel`} sx={{ mr: 1 }}>
                Exact rows
              </Typography>
              <Typography variant={`caption`} sx={{ ml: '4px' }}>
                {exactRows}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  },
);
