import React from 'react';

import Box from '@mui/material/Box';

import Footer from './Footer';

export default function BaseLayout({ children }: { children: any }) {
  return (
    // Use flexbox, fill vertically, and use 100% of the viewport height
    <Box display="flex" flexDirection="column" height="100vh">
      {/*Main content area grows to fill any empty vertical space which pushes footer to the bottom of the viewport*/}
      <Box component="main" flex="1 0 auto">
        {children}
      </Box>
      <Footer />
    </Box>
  );
}
