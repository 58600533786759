import { gql } from '@apollo/client';

import { FRAGMENTS } from './fragments';

//queries
export const organizationAuthorizations = gql`
  query authorizations(
    $id: String!
    $orgName: String!
    $orgDisplayName: String!
  ) {
    organizationAuthorizations(
      id: $id
      orgName: $orgName
      orgDisplayName: $orgDisplayName
    ) {
      authorizations {
        ...ResourceFields
      }
      role {
        ...SubjectFields
        users {
          ...RoleMemberFields
        }
        children {
          id
          name
          displayName
          users {
            ...RoleMemberFields
          }
        }
        parents {
          id
          name
          displayName
        }
      }
    }
  }

  ${FRAGMENTS.resource}
  ${FRAGMENTS.subject}
  ${FRAGMENTS.roleMember}
`;

export const warehouseResourceAuthorizationsQuery = gql`
  query WarehouseResourceAuthorizations(
    $organizationId: String!
    $warehouseId: String!
  ) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouse(id: $warehouseId) {
        ...WarehouseFields
        authorizations {
          authorizations {
            ...ResourceFields
          }
          role {
            ...SubjectFields
            users {
              ...RoleMemberFields
            }
            children {
              id
              name
              displayName
              users {
                ...RoleMemberFields
              }
            }
            parents {
              id
              name
              displayName
            }
          }
        }
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.warehouse}
  ${FRAGMENTS.resource}
  ${FRAGMENTS.subject}
  ${FRAGMENTS.roleMember}
`;

export const databaseResourceAuthorizationsQuery = gql`
  query DatabaseResourceAuthorizations(
    $organizationId: String!
    $warehouseId: String!
    $database: String!
  ) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouse(id: $warehouseId) {
        ...WarehouseFields
        database(name: $database) {
          id
          name
          warehouseId
          authorizations {
            authorizations {
              ...ResourceFields
            }
            role {
              ...SubjectFields
              users {
                ...RoleMemberFields
              }
              children {
                id
                name
                displayName
                users {
                  ...RoleMemberFields
                }
              }
              parents {
                id
                name
                displayName
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.warehouse}
  ${FRAGMENTS.resource}
  ${FRAGMENTS.subject}
  ${FRAGMENTS.roleMember}
`;

export const tableAuthorizationsQuery = gql`
  query TableResourceAuthorizations(
    $organizationId: String!
    $organizationName: String!
    $organizationDisplayName: String!
    $tableId: String!
  ) {
    getTableAuthorizations(
      organizationId: $organizationId
      organizationName: $organizationName
      organizationDisplayName: $organizationDisplayName
      tableId: $tableId
    ) {
      authorizations {
        ...ResourceFields
      }
      role {
        ...SubjectFields
        users {
          ...RoleMemberFields
        }
        children {
          id
          name
          displayName
          users {
            ...RoleMemberFields
          }
        }
        parents {
          id
          name
          displayName
        }
      }
    }
  }
  ${FRAGMENTS.resource}
  ${FRAGMENTS.subject}
  ${FRAGMENTS.roleMember}
`;

export const storageProfileAuthorizationsQuery = gql`
  query StorageProfileResourceAuthorizations(
    $organizationId: String!
    $organizationName: String!
    $organizationDisplayName: String!
    $storageProfileId: String!
  ) {
    getStorageProfileAuthorizations(
      organizationId: $organizationId
      organizationName: $organizationName
      organizationDisplayName: $organizationDisplayName
      storageProfileId: $storageProfileId
    ) {
      authorizations {
        ...ResourceFields
      }
      role {
        ...SubjectFields
        users {
          ...RoleMemberFields
        }
        children {
          id
          name
          displayName
          users {
            ...RoleMemberFields
          }
        }
        parents {
          id
          name
          displayName
        }
      }
    }
  }
  ${FRAGMENTS.resource}
  ${FRAGMENTS.subject}
  ${FRAGMENTS.roleMember}
`;

export const inFlightDatabaseCascadeTasks = gql`
  query GetInFlightDatabaseCascadeTasks($namespaceId: String!) {
    inFlightDatabaseCascadeTasks(namespaceId: $namespaceId) {
      ...InFlightCascadeTaskFields
    }
  }
  ${FRAGMENTS.inFlightCascadeTasks}
`;

export const inFlightWarehouseCascadeTasks = gql`
  query GetInFlightWarehouseCascadeTasks($warehouseId: String!) {
    inFlightWarehouseCascadeTasks(warehouseId: $warehouseId) {
      ...InFlightCascadeTaskFields
    }
  }
  ${FRAGMENTS.inFlightCascadeTasks}
`;

export const getAuthorizationDecision = gql`
  query authDecision($request: AuthDecisionInput!) {
    authDecision(request: $request)
  }
`;

export const getAuthorizationGrants = gql`
  query authGrants($request: AuthGrantInput!) {
    authGrants(request: $request) {
      privilege
      withGrant
    }
  }
`;

//mutations
export const createAuthorizations = gql`
  mutation CreateAuthorizationsMutation($authorizations: [CreateAuthInput]) {
    createAuthorizations(authorizations: $authorizations)
  }
`;

export const updateAuthorizations = gql`
  mutation UpdateAuthorizationsMutation($authorizations: [UpdateAuthInput]) {
    updateAuthorizations(authorizations: $authorizations)
  }
`;

export const revokeAuthorizations = gql`
  mutation DeleteAuthorizationsMutation($ids: [String]) {
    revokeAuthorizations(ids: $ids)
  }
`;

export const handleAuthorizationMutations = gql`
  mutation HandlingAuthorizationMutations(
    $authsToUpdate: [UpdateAuthInput]
    $authsToCreate: [CreateAuthInput]
    $authsToDelete: [String]
  ) {
    handleAuthMutations(
      authsToUpdate: $authsToUpdate
      authsToCreate: $authsToCreate
      authsToDelete: $authsToDelete
    )
  }
`;

export const cascadeDatabaseAuthorizationsMutation = gql`
  mutation cascadeDatabaseAuthorizations(
    $namespaceId: String!
    $roleId: String!
  ) {
    cascadeDatabaseAuthorizations(namespaceId: $namespaceId, roleId: $roleId) {
      taskId
    }
  }
`;

export const cascadeWarehouseAuthorizationsMutation = gql`
  mutation CascadeWarehouseAuthorizations(
    $warehouseId: String!
    $roleId: String!
  ) {
    cascadeWarehouseAuthorizations(warehouseId: $warehouseId, roleId: $roleId) {
      taskId
    }
  }
`;
