import * as React from 'react';
import { useMemo } from 'react';

import { computeSlots } from '../../utils/computeSlots';

import {
  getDefaultStatusPanelSectionSlots,
  StatusPanelSectionProps,
  StatusPanelSectionPropsWithDefaultValue,
  StatusPanelSectionSlotsComponent,
} from './index';

export const STATUS_PANEL_SECTION_PROPS_DEFAULT_VALUES: StatusPanelSectionPropsWithDefaultValue =
  {
    variant: 'task',
  };

export const useStatusPanelSectionProps = (
  inProps: StatusPanelSectionProps,
) => {
  const defaultSlots = useMemo(
    () => getDefaultStatusPanelSectionSlots(inProps),
    [inProps],
  );

  const slots = React.useMemo<StatusPanelSectionSlotsComponent>(
    () =>
      computeSlots<StatusPanelSectionSlotsComponent>({
        defaultSlots,
        slots: inProps.slots,
      }),
    [defaultSlots, inProps.slots],
  );

  return React.useMemo<StatusPanelSectionProps>(
    () => ({
      ...STATUS_PANEL_SECTION_PROPS_DEFAULT_VALUES,
      ...inProps,
      slots,
    }),
    [inProps, slots],
  );
};
