import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Autocomplete,
  Box,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { debounce, includes, isEmpty } from 'lodash';

import { getAuthorizationGrants } from '../../graphql/authorization';
import {
  User,
  Role,
  SortByDirection,
  SearchRoleResult,
  AuthDecisionSubjectType,
  AuthDecisionResourceType,
  Authorization,
  Privilege,
} from '../../graphql/gen/graphql';
import { searchRolesQuery } from '../../graphql/organization';
import { getLogger } from '../../utils/logging';
import { Tabutton } from '../Button/Tabutton';
import { Tacard } from '../Card/Tacard';
import CreateRole from '../Forms/CreateRole';
import CreateRoleOnTheFly from '../Forms/CreateRoleOnTheFly';
import { Tabulink } from '../Link/Tabulink';
import RolePrivileges from './RolePrivileges';
import { AuthWithGrant } from './common';

const logger = getLogger(
  'components.AccessControl.GrantNewAccess' /*FUTURE import.meta.url ?*/,
);

type Props = {
  user: User;
  handleGrantNewApply: any;
  organizationId: string;
  existingRoleIds: string[];
  resourceType: string;
  currentGrants: AuthWithGrant[];
  onError?: any;
  isSecurityAdmin: boolean;
  orgAccessControls?: boolean;
};

interface RoleSearchParams {
  organizationId: string;
  query: string;
  maxResults: number;
  searchAfter: string[] | undefined;
  sortBy: any[];
  excludeSystemRoles: boolean;
}
export default function GrantNewAccess({
  user,
  organizationId,
  existingRoleIds,
  resourceType,
  handleGrantNewApply,
  currentGrants,
  onError,
  isSecurityAdmin,
  orgAccessControls,
}: Props) {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [openRoleSelectModal, setOpenRoleSelectModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [selectedRoleName, setSelectedRoleName] = useState('');
  const [showPrivileges, setShowPrivileges] = useState(false);
  const [showCreateRole, setShowCreateRole] = useState(false);
  const [roleOptions, setRoleOptions] = useState<SearchRoleResult[]>([]);
  const [value, setValue] = useState<SearchRoleResult>();
  const [inputValue, setInputValue] = useState('');
  const [highlighted, setHighlighted] = useState('');
  const [getRoles, { data, loading, refetch }] = useLazyQuery<
    any,
    RoleSearchParams
  >(searchRolesQuery, {
    variables: {
      organizationId,
      query: searchQuery !== '' ? searchQuery : '*',
      maxResults: 100,
      searchAfter: undefined,
      sortBy: [
        {
          fieldName: searchQuery ? '_score' : 'roleName.sort',
          sortDirection: searchQuery
            ? SortByDirection.Desc
            : SortByDirection.Asc,
        },
      ],
      excludeSystemRoles: false,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const { data: currentOrgGrants } = useQuery(getAuthorizationGrants, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        resource: {
          type: AuthDecisionResourceType.Organization,
          identifier: organizationId,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const hasCreateRole = currentOrgGrants?.authGrants?.some(
    (auth: Authorization) => auth.privilege === Privilege.CreateRole,
  );

  const nextKeys: string[] = useMemo(
    () =>
      data?.searchRoles?.searchAfterKeys
        ? data?.searchRoles?.searchAfterKeys
        : [],
    [data],
  );

  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      //@ts-ignore
      const searchyMcSearch = e?.target?.value || '';
      setSearchQuery(searchyMcSearch);
    },
    [],
  );
  const debouncedSearchChange = debounce(onSearchChange, 500);

  const onRoleSelected = useCallback(async (roleSelected: SearchRoleResult) => {
    setSelectedRoleId(roleSelected ? roleSelected.roleId : '');
    setSelectedRoleName(roleSelected ? roleSelected.roleName : '');
  }, []);

  const onShellRoleCreated = useCallback((role: Role) => {
    if (role && role?.name && role?.id) {
      setSelectedRoleId(role.id);
      setSelectedRoleName(role.name);
      setShowPrivileges(true);
      setShowCreateRole(false);
    }
  }, []);

  const handleScroll = useCallback(
    (event: React.SyntheticEvent) => {
      const listboxNode = event.currentTarget;
      const position = listboxNode.scrollTop + listboxNode.clientHeight;

      if (
        listboxNode.scrollHeight - position <= 1 &&
        roleOptions?.length < data?.searchRoles?.totalHits
      ) {
        refetch({
          searchAfter: nextKeys.length === 0 ? undefined : nextKeys,
        }).then((refetchGuys) => {
          setRoleOptions((prevRoleOptions) => [
            ...prevRoleOptions,
            ...refetchGuys?.data?.searchRoles?.results,
          ]);
        });
      }
    },
    [nextKeys, data, roleOptions],
  );

  useEffect(() => {
    if (openRoleSelectModal && searchQuery !== undefined) {
      refetch({
        query: searchQuery !== '' ? searchQuery : '*',
        searchAfter: undefined,
      })
        .then((refetchGuys) =>
          setRoleOptions(refetchGuys?.data?.searchRoles?.results),
        )
        .catch((error) => {
          logger.error('problem querying roles ', error);
        });
    }
  }, [searchQuery, openRoleSelectModal]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          flexWrap: 'wrap',
          mb: 2,
        }}
      >
        <Tabutton
          onClick={() => {
            setOpenRoleSelectModal(true);
            setShowPrivileges(false);
            setShowCreateRole(false);
            return getRoles();
          }}
        >
          Grant Role Access +
        </Tabutton>
      </Box>
      <Dialog
        open={openRoleSelectModal}
        sx={{ '.MuiDialog-paper': { maxWidth: '100%' } }}
      >
        <DialogContent>
          <Box
            sx={(theme) => ({
              minWidth: 600,
              mx: 1,
              [theme.breakpoints.down('sm')]: {
                minWidth: 'unset',
              },
            })}
          >
            <Typography variant={'h2'} sx={{ mb: 2 }}>
              Grant a role access
            </Typography>
            {!showPrivileges && !showCreateRole && (
              <Box>
                <Typography
                  sx={{
                    mb: 2,
                    a: {
                      color: 'brandBlue.main',
                      cursor: !hasCreateRole ? 'not-allowed' : 'grab',
                    },
                  }}
                >
                  Choose from existing roles or{' '}
                  <Tabulink
                    onClick={(event: React.MouseEvent) => {
                      event.preventDefault();
                      setShowCreateRole(true);
                    }}
                  >
                    create a new role.
                  </Tabulink>
                </Typography>
                <Autocomplete
                  id={`role-select-combo-box`}
                  //@ts-ignore
                  getOptionLabel={(option) => option.roleName}
                  filterOptions={(x) => x}
                  loading={loading}
                  options={roleOptions ? roleOptions : []}
                  value={value}
                  noOptionsText={`No roles`}
                  onChange={(event, newValue) => {
                    //@ts-ignore
                    onRoleSelected(newValue).then(() => {
                      setRoleOptions([...roleOptions]);
                      //@ts-ignore
                      setValue(newValue);
                    });
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    //@ts-ignore
                    debouncedSearchChange(event);
                  }}
                  onHighlightChange={(event, option, reason) => {
                    if (reason === 'keyboard') {
                      setHighlighted(option?.roleId || '');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Search roles" fullWidth />
                  )}
                  ListboxProps={{
                    onScroll: handleScroll,
                    role: 'list-box',
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li
                        {...props}
                        aria-disabled={
                          includes(existingRoleIds, option?.roleId) ||
                          option.roleName === 'SECURITY_ADMIN'
                        }
                      >
                        <ListItemButton
                          disabled={
                            includes(existingRoleIds, option?.roleId) ||
                            option.roleName === 'SECURITY_ADMIN'
                          }
                          sx={{
                            backgroundColor:
                              highlighted === option.roleId
                                ? 'sky.two'
                                : 'inherit',
                          }}
                        >
                          <ListItemText sx={{ wordBreak: 'break-word' }}>
                            {option?.roleName}
                          </ListItemText>
                        </ListItemButton>
                      </li>
                    );
                  }}
                />
              </Box>
            )}
            {showCreateRole && (
              // <CreateRoleOnTheFly
              //   organization={organization}
              //   onCancel={() => setShowCreateRole(false)}
              //   sliderSteps={sliderSteps}
              //   futureDatabases={futureDatabases}
              //   futureTables={futureTables}
              //   isResourceAdmin
              //   isSecurityAdmin={isSecurityAdmin}
              //   setNewRoleName={setSelectedRoleName}
              //   setNewRoleId={setSelectedRoleId}
              //   handleNewRoleAccess={handleNewRoleAccess}
              //   currentGrants={currentGrants}
              // />
              <CreateRole
                organizationId={organizationId}
                onCancel={() => setShowCreateRole(false)}
                onRoleCreated={onShellRoleCreated}
                roleOnTheFly
              />
            )}

            {showPrivileges && (
              <>
                <Typography
                  sx={{ mb: 2 }}
                  variant="subtitle1"
                  color={'dusk.six'}
                >
                  Set Privileges
                </Typography>
                {selectedRoleName && (
                  <Tacard
                    cardGrid
                    marginRight={'0'}
                    sx={{ ...(orgAccessControls && { minWidth: '840px' }) }}
                  >
                    <CardHeader
                      sx={(theme) => ({
                        borderBottom: `1px solid ${theme.palette.midnight.two}`,
                        backgroundColor: 'dusk.half',
                        alignItems: 'center',
                        pl: 3,
                        mb: 1,
                      })}
                      title={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant={'subtitle1'}>
                            Privileges for {selectedRoleName}
                          </Typography>
                        </Box>
                      }
                    />
                    <RolePrivileges
                      privileges={[]}
                      accessType={resourceType}
                      roleName={selectedRoleName}
                      selectedRoleId={selectedRoleId}
                      grantingNewAccess
                      onCancelNewAccess={() => setShowPrivileges(false)}
                      handleApplyChanges={handleGrantNewApply}
                      currentGrants={currentGrants}
                      isResourceAdmin
                      isSecurityAdmin={isSecurityAdmin}
                    />
                  </Tacard>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {!showPrivileges && !showCreateRole && (
            <>
              <Tabutton
                onClick={() => {
                  setOpenRoleSelectModal(false);
                  setRoleOptions([]);
                  setSelectedRoleId('');
                  setSelectedRoleName('');
                  setSearchQuery('');
                }}
              >
                Cancel
              </Tabutton>
              <Tabutton
                onClick={() => {
                  if (!showPrivileges && openRoleSelectModal) {
                    setShowPrivileges(true);
                  }
                }}
                variant="contained"
                disabled={isEmpty(selectedRoleId)}
              >
                Next
              </Tabutton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
