import { useEffect } from 'react';

const useLoginListener = (onToken: (token: string) => void) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (event.data && String(event.data).startsWith('tokenSuccess')) {
        const tokenParts = event.data.split('|');
        if (onToken) {
          onToken(tokenParts[1]);
        }
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);
};

export default useLoginListener;
