import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  Box,
  Popper,
  Typography,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, GridColumns, useGridApiRef } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { toSingleRole } from '../../../RouteTable';
import { OrganizationCredential, Role } from '../../../graphql/gen/graphql';
import { calculateTextWidth } from '../../../pages/helpers';
import { relativeTimeAutoFormat } from '../../../utils/time';
import { Tavatar } from '../../Avatar/Tavatar';
import { ChipColors, Tachip } from '../../Chip/Tachip';
import {
  DataGridToolBar,
  CustomPaging,
  MenuContentType,
} from '../../DataGridToolbar/DataGridToolbar';
import { StyledSwitch } from '../../Forms/Switch';
import { CustomIcon } from '../../Icons/CustomIcon';
import { NamePopover } from '../../Popovers/NamePopover';
import UserInfo from '../../ProfileMenu/UserInfo';
import { dateComparator, getApplyFilterFnCreatorName } from '../helpers';

export default function IAMRoleMapping({
  credentialsData,
  onCredentialDelete,
  onCredentialUpdate,
  currentUserEmail,
  refreshResourceContent,
  addResourceContent,
  orgName,
  roles,
  rolesLoading,
  loading,
  hideDelete = false,
  showTabularRole = true,
  disabled,
}: {
  credentialsData: [OrganizationCredential];
  onCredentialDelete?: any;
  onCredentialUpdate?: any;
  currentUserEmail?: string;
  refreshResourceContent?: MenuContentType;
  addResourceContent?: MenuContentType;
  orgName: string;
  roles: Role[];
  loading?: boolean;
  rolesLoading?: boolean;
  hideDelete?: boolean;
  showTabularRole?: boolean;
  disabled?: boolean;
}) {
  const [copySuccess, setCopySuccess] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  const onCellEdit = (params: any) => {
    onCredentialUpdate(params.id, { [params.field]: params.value });
  };

  const rows = useMemo(() => {
    return credentialsData?.map((cred) => {
      const role = roles?.find((role) => role.id === cred.roleId);
      const roleDisplayName = role ? role.displayName : 'Unknown';
      const roleArn = cred.iamRoles && cred.iamRoles[0];
      return {
        id: cred.key,
        created: {
          displayName: cred?.member?.displayName,
          profilePic: cred?.member?.properties?.['picture.url'],
          currentUser: currentUserEmail === cred?.member?.user?.email,
          email: cred?.member?.user?.email,
          createdAt: cred.createdAt,
        },
        name: cred.name,
        roleArn: roleArn?.roleArn,
        lastSession: cred.lastSession,
        key: cred.key,
        active: cred.active,
        ...(showTabularRole && {
          tabularRole: roleDisplayName,
          roleName: role?.name,
        }),
      };
    });
  }, [credentialsData, hideDelete, roles]);

  const columns = useMemo(
    () => [
      {
        field: 'roleArn',
        headerName: 'AWS IAM role ARN',
        minWidth: 200,
        editable: false,
        flex: 2,
        renderCell: (params: any) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
            true,
          );
          return (
            <Box
              sx={{
                display: 'flex',
                flex: '1 1 100%',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '100%',
                minWidth: 0,
              }}
            >
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      {...bindHover(popupState)}
                    >
                      {params.value}
                    </Typography>
                    {textWidth >= params.colDef.computedWidth && (
                      <Popper
                        style={{ zIndex: 1300 }}
                        {...bindPopper(popupState)}
                        placement={`right-start`}
                        modifiers={[
                          {
                            name: 'flip',
                            options: {
                              fallbackPlacements: ['bottom'],
                            },
                          },
                        ]}
                      >
                        <NamePopover name={params.value} />
                      </Popper>
                    )}
                  </>
                )}
              </PopupState>
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <ContentCopyOutlinedIcon
                  fontSize={'small'}
                  sx={{
                    cursor: 'pointer',
                    color: 'brandBlue.main',
                    display: 'flex',
                    marginLeft: 1,
                  }}
                  onClick={() => {
                    copyToClipBoard(params.value);
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
      ...(showTabularRole
        ? [
            {
              field: 'tabularRole',
              headerName: 'Tabular role',
              minWidth: 200,
              editable: false,
              flex: 1,
              renderCell: (params: any) => {
                const textWidth = calculateTextWidth(
                  params.value,
                  '16px Source Sans Pro',
                  true,
                );
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flex: '1 1 100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '100%',
                      minWidth: 0,
                    }}
                  >
                    <PopupState variant="popper">
                      {(popupState) => (
                        <>
                          <Tachip
                            type={ChipColors.Neutral}
                            size={'small'}
                            onClick={() => {
                              if (params.row.roleName) {
                                navigate(
                                  toSingleRole(orgName, params.row.roleName),
                                );
                              }
                            }}
                            label={
                              <Typography
                                variant={'subtitle2'}
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {params.value}
                              </Typography>
                            }
                            {...bindHover(popupState)}
                          />

                          {textWidth >= params.colDef.computedWidth && (
                            <Popper
                              {...bindPopper(popupState)}
                              placement={`right`}
                              modifiers={[
                                {
                                  name: 'flip',
                                  options: {
                                    fallbackPlacements: ['bottom'],
                                  },
                                },
                              ]}
                            >
                              <NamePopover name={params.value} />
                            </Popper>
                          )}
                        </>
                      )}
                    </PopupState>
                  </Box>
                );
              },
            },
          ]
        : []),
      {
        field: 'created',
        headerName: 'Created',
        minWidth: 175,
        flex: 0.5,
        editable: false,
        sortComparator: dateComparator,
        getApplyQuickFilterFn: getApplyFilterFnCreatorName,
        sortingOrder: ['desc', 'asc', null],

        renderCell: (params: any) => {
          const createdBy = params.value;
          const createdAt = params.value?.createdAt;
          return (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PopupState variant="popper">
                  {(popupState) => (
                    <>
                      <Box {...bindHover(popupState)}>
                        <Tavatar
                          displayName={createdBy?.displayName}
                          profilePic={createdBy?.profilePic}
                          currentUser={createdBy?.currentUser}
                        />
                      </Box>
                      <Popper {...bindPopper(popupState)} placement={`top`}>
                        <Box
                          sx={(theme) => ({
                            backgroundColor: 'controlBackground.main',
                            borderRadius: '4px',
                            boxShadow: theme.shadows[3],
                          })}
                        >
                          <UserInfo
                            user={{
                              displayName: createdBy?.displayName,
                              profilePic: createdBy?.profilePic,
                              email: createdBy?.email,
                            }}
                            currentUser={createdBy?.currentUser}
                          />
                        </Box>
                      </Popper>
                    </>
                  )}
                </PopupState>
              </Box>
              <Typography display={'flex'} alignItems={'center'} sx={{ ml: 1 }}>
                {createdAt ? relativeTimeAutoFormat(createdAt) : '--'}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'lastSession',
        headerName: 'Last session',
        minWidth: 150,
        flex: 0.5,
        getApplyQuickFilterFn: undefined,
        editable: false,
        type: 'dateTime',
        sortingOrder: ['desc', 'asc', null],
        valueFormatter: (params: any) => {
          return params.value ? relativeTimeAutoFormat(params.value) : '--';
        },
      },
      {
        field: 'active',
        headerName: 'Active',
        minWidth: 80,
        renderCell: (params: any) => (
          <StyledSwitch
            disabled={disabled}
            checked={params.value}
            onChange={() =>
              onCredentialUpdate(params.row.key, {
                active: !params.value,
              })
            }
          />
        ),
      },
      {
        field: 'delete',
        headerName: '',
        width: 60,
        editable: false,
        hide: hideDelete,
        renderCell: (params: any) => (
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Tooltip
              title={`${
                params.row.active
                  ? 'Deactivate credential before deleting'
                  : 'Delete'
              }`}
              placement={`top`}
              arrow
            >
              <span>
                <IconButton
                  onClick={() => onCredentialDelete(params.row)}
                  disabled={disabled || params.row.active}
                >
                  <CloseIcon
                    sx={{
                      color: params.row.active
                        ? 'midnight.three'
                        : 'error.main',
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [orgName, disabled],
  );
  return (
    <>
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        pagination={rows?.length >= 10}
        hideFooter={rows?.length < 10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        rows={rows || []}
        columns={columns as GridColumns}
        headerHeight={34}
        onCellEditCommit={onCellEdit}
        disableColumnMenu
        isRowSelectable={() => false}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created', sort: 'desc' }],
          },
          pinnedColumns: { right: ['active', 'delete'] },
          pagination: { page: 0, pageSize: 10 },
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No AWS IAM role mappings
            </Stack>
          ),
          Toolbar: DataGridToolBar,
          Pagination: CustomPaging,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            apiRef: apiRef,
            pageSize: pageSize,
            setPageSize: setPageSize,
            headerName: (
              <Box display={'flex'} paddingLeft={2} alignItems={'center'}>
                {'AWS IAM role mapping'}
              </Box>
            ),
            icon: (
              <CustomIcon
                src={'/assets/img/logos/aws.svg'}
                sx={{ height: '24px', width: '40px' }}
              />
            ),
            refreshResourceContent: refreshResourceContent,
            addResourceContent: addResourceContent,
            rowCount: credentialsData?.length,
            pageAtNumber: 10,
          },
          pagination: {
            apiRef: apiRef,
            pageSize: pageSize,
            setPageSize: setPageSize,
            rowCount: credentialsData?.length,
          },
        }}
        loading={loading || rolesLoading}
        sx={{
          border: 'none',
          '.MuiDataGrid-columnHeaders': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
          '.MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: 'dusk.half',
          },
        }}
      />
    </>
  );
}
