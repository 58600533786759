import { gql } from '@apollo/client';

import { FRAGMENTS } from './fragments';

//queries
export const organizationById = gql`
  query Organization($organizationId: String!, $noCache: Boolean) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouses {
        ...WarehouseFields
        databases {
          warehouseId
          name
          tables {
            ...TableFields
          }
        }
      }
      roles(noCache: $noCache) {
        ...SubjectFields
        children {
          id
          name
          users {
            ...RoleMemberFields
          }
        }
        parents {
          id
          name
        }
        users {
          ...RoleMemberFields
        }
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.warehouse}
  ${FRAGMENTS.extTable}
  ${FRAGMENTS.subject}
  ${FRAGMENTS.roleMember}
`;

export const organizationPageQuery = gql`
  query OrganizationPage($organizationId: String!, $daysBack: Int) {
    organization(id: $organizationId) {
      ...OrgFields
      properties
      warehouses {
        ...WarehouseFields
        statistics(daysBack: $daysBack) {
          ...ResourceStatsFields
          totalNamespaces
        }
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.warehouse}
  ${FRAGMENTS.resourceStats}
`;
export const organizationMembersQuery = gql`
  query OrganizationMembers($organizationId: String!) {
    organization(id: $organizationId) {
      ...OrgFields
      members {
        ...MemberFieldsLight
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.memberFieldsLight}
`;

export const organizationMemberAndInvitesQuery = gql`
  query OrganizationMembersAndInvites($organizationId: String!) {
    organization(id: $organizationId) {
      ...OrgFields
      members {
        ...MemberFieldsLight
      }
      invites {
        ...OrgInviteFields
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.memberFieldsLight}
  ${FRAGMENTS.orgInvite}
`;

export const organizationRolesQuery = gql`
  query OrganizationRoles($organizationId: String!) {
    organization(id: $organizationId) {
      ...OrgFields
      roles {
        ...SubjectFields
        children {
          id
          name
          displayName
          users {
            ...RoleMemberFields
          }
        }
        parents {
          id
          name
          displayName
        }
        users {
          ...RoleMemberFields
        }
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.subject}
  ${FRAGMENTS.roleMember}
`;

// Query for listing storage profiles when selecting. This excludes excess data and includes the USAGE query.
export const getOrgStorageProfilesList = gql`
  query StorageProfilesList($organizationId: String!) {
    organization(id: $organizationId) {
      id
      storageProfiles {
        ...StorageProfilesListWithUsage
      }
    }
  }
  ${FRAGMENTS.storageProfilesList}
`;

export const getOrgStorageProfiles = gql`
  query StorageProfiles($organizationId: String!) {
    organization(id: $organizationId) {
      id
      storageProfiles {
        ...StorageProfileFields
        warehouses {
          ...WarehouseSummaryFields
        }
      }
    }
  }
  ${FRAGMENTS.storageProfile}
  ${FRAGMENTS.warehouseSummary}
`;

export const geStorageProfilePeers = gql`
  query StorageProfilePeers($organizationId: String!) {
    organization(id: $organizationId) {
      id
      name
      storageProfiles {
        id
        bucket
      }
    }
  }
`;

export const getStorageProfileQuery = gql`
  query storageProfile($bucketName: String!) {
    getStorageProfile(bucketName: $bucketName) {
      ...StorageProfileFields
      warehouses {
        ...WarehouseSummaryFields
        statistics {
          ...ResourceStatsFields
        }
      }
    }
  }
  ${FRAGMENTS.storageProfile}
  ${FRAGMENTS.warehouseSummary}
  ${FRAGMENTS.resourceStats}
`;

export const getStorageProfileValidation = gql`
  query storageProfileValidateQuery($storageProfileId: String!) {
    storageProfileValidate(id: $storageProfileId) {
      id
      ...StorageProfileFields
      warehouses {
        ...WarehouseSummaryFields
      }
    }
  }
  ${FRAGMENTS.storageProfile}
  ${FRAGMENTS.warehouseSummary}
`;

export const listStorageProfileQuery = gql`
  query ListStorageProfile(
    $organizationId: String!
    $storageProfileId: String!
    $prefix: String
    $pageSize: Int
    $continuationToken: String
  ) {
    listStorageProfile(
      organizationId: $organizationId
      storageProfileId: $storageProfileId
      prefix: $prefix
      pageSize: $pageSize
      continuationToken: $continuationToken
    ) {
      continuationToken
      directories
      files {
        fileName
        fileVersion
        fileSize
        fileCreatedAt
        lastModified
      }
      truncated
    }
  }
`;

export const deleteStorageProfile = gql`
  mutation DeleteStorageProfile($id: String!) {
    deleteStorageProfile(id: $id)
  }
`;

export const authConfigByIssuer = gql`
  query AuthConfigForIssuerQuery($issuer: String!) {
    authConfigForIssuer(issuer: $issuer) {
      ...AuthConfigFields
    }
  }
  ${FRAGMENTS.authConfig}
`;

export const searchRolesQuery = gql`
  query SearchRoles(
    $organizationId: String!
    $query: String!
    $maxResults: Int
    $searchAfter: [String]
    $sortBy: [SortByInput]
    $excludeSystemRoles: Boolean
  ) {
    searchRoles(
      organizationId: $organizationId
      query: $query
      maxResults: $maxResults
      searchAfter: $searchAfter
      sortBy: $sortBy
      excludeSystemRoles: $excludeSystemRoles
    ) {
      resultSize
      totalHits
      searchAfterKeys
      results {
        roleId
        roleName
        memberCount
        adminMemberCount
        adminRoleCount
        childRoleCount
        createdAt
        lastModified
        createdBy {
          id
          displayName
          userId
          email
          properties
          admin
        }
        lastModifiedBy {
          id
          displayName
          userId
          email
          properties
          admin
        }
      }
    }
  }
`;

export const basicSearchRolesQuery = gql`
  query BasicSearchRoles(
    $organizationId: String!
    $query: String!
    $maxResults: Int
    $searchAfter: [String]
    $sortBy: [SortByInput]
    $excludeSystemRoles: Boolean
  ) {
    searchRoles(
      organizationId: $organizationId
      query: $query
      maxResults: $maxResults
      searchAfter: $searchAfter
      sortBy: $sortBy
      excludeSystemRoles: $excludeSystemRoles
    ) {
      resultSize
      totalHits
      searchAfterKeys
      results {
        roleId
        roleName
        memberCount
        childRoleCount
      }
    }
  }
`;

export const navSearchQuery = gql`
  query Search(
    $organizationId: String!
    $query: String!
    $maxResults: Int
    $searchAfter: [String]
    $sortBy: [SortByInput]
    $entityTypesFilter: [SearchEntityType]
    $warehouseIdFilter: [String]
    $databaseIdFilter: [String]
  ) {
    search(
      organizationId: $organizationId
      query: $query
      maxResults: $maxResults
      searchAfter: $searchAfter
      sortBy: $sortBy
      entityTypesFilter: $entityTypesFilter
      warehouseIdFilter: $warehouseIdFilter
      databaseIdFilter: $databaseIdFilter
    ) {
      searchAfterKeys
      totalHits
      resultSize
      results {
        ... on WarehouseSearchResult {
          et
          eid
          en
          on
          wid
          wn
        }
        ... on DatabaseSearchResult {
          et
          eid
          en
          on
          wid
          wn
          nid
          nn
        }
        ... on TableSearchResult {
          et
          eid
          en
          on
          wid
          wn
          nid
          nn
          tid
          tn
        }
        ... on ViewSearchResult {
          et
          eid
          en
          on
          wid
          wn
          nid
          nn
          tid
          tn
        }
      }
    }
  }
`;

export const organizationCredentials = gql`
  query OrganizationCredentialsQuery($organizationId: String!) {
    organizationCredentials(organizationId: $organizationId) {
      ...OrgCredentialFields
      member {
        id
        displayName
        properties
        user {
          id
          email
        }
      }
    }
  }
  ${FRAGMENTS.orgCredential}
`;

export const myOrganizationCredentials = gql`
  query MyOrganizationCredentialsQuery($organizationId: String!) {
    myOrganizationCredentials(organizationId: $organizationId) {
      ...OrgCredentialFields
    }
  }
  ${FRAGMENTS.orgCredential}
`;

export const orgCredential = gql`
  query OrgCredentialQuery($organizationId: String!, $credentialKey: String!) {
    organizationCredential(
      organizationId: $organizationId
      credentialKey: $credentialKey
    ) {
      ...OrgCredentialFields
    }
  }
  ${FRAGMENTS.orgCredential}
`;

export const orgCredentialUsage = gql`
  query OrgCredentialUsageQuery(
    $organizationId: String!
    $credentialKey: String!
  ) {
    organizationCredential(
      organizationId: $organizationId
      credentialKey: $credentialKey
    ) {
      lastSession
    }
  }
`;

export const OrgDashboardCardsQuery = gql`
  query OrgDashboardQuery($organizationId: String!, $daysBack: Int) {
    organization(id: $organizationId) {
      ...OrgFields
      statistics(daysBack: $daysBack) {
        ...StatisticsFields
        totalTables
        totalNamespaces
      }
      compactionSummary(daysBack: $daysBack) {
        ...CompactionFields
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.stats}
  ${FRAGMENTS.compaction}
`;

export const orgDroppedTables = gql`
  query OrgDroppedTables($organizationId: String!) {
    organization(id: $organizationId) {
      ...OrgFields
      droppedTables {
        ...DroppedTableFields
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.droppedTable}
`;

export const getOrganizationAuths = gql`
  query auths($organizationId: String!) {
    organization(id: $organizationId) {
      id
      auths {
        ...OrgAuthFields
      }
    }
  }
  ${FRAGMENTS.orgAuths}
`;

export const getOrgSystemRoles = gql`
  query orgSystemRoles($organizationId: String!) {
    orgSystemRoles(organizationId: $organizationId) {
      ...SubjectFields
      users {
        ...RoleMemberFields
      }
      children {
        ...SubjectFields
      }
    }
  }
  ${FRAGMENTS.subject}
  ${FRAGMENTS.roleMember}
`;

//mutations
export const createOrgCredential = gql`
  mutation CreateOrgCredentialMutation(
    $organizationId: String!
    $name: String!
    $description: String
    $type: CredentialTypes
    $cidrs: [String]
    $external: Boolean
    $roleId: String
    $roleArn: String
  ) {
    createOrgCredential(
      organizationId: $organizationId
      name: $name
      description: $description
      type: $type
      cidrs: $cidrs
      external: $external
      roleId: $roleId
      roleArn: $roleArn
    ) {
      credential
      name
      description
      cidrs
      roleId
      roleArn
    }
  }
`;

export const updateOrgCredential = gql`
  mutation UpdateOrgCredentialMutation(
    $organizationId: String!
    $credentialKey: String!
    $updates: OrganizationCredentialUpdate!
  ) {
    updateOrgCredential(
      organizationId: $organizationId
      credentialKey: $credentialKey
      updates: $updates
    ) {
      ...OrgCredentialFields
    }
  }
  ${FRAGMENTS.orgCredential}
`;

export const deleteOrgCredential = gql`
  mutation DeleteOrgCredential(
    $organizationId: String!
    $credentialKey: String!
  ) {
    deleteOrgCredential(
      organizationId: $organizationId
      credentialKey: $credentialKey
    )
  }
`;

export const createOrganization = gql`
  mutation CreateOrganizationMutation($name: String!, $displayName: String) {
    createOrganization(name: $name, displayName: $displayName) {
      id
      name
      displayName
    }
  }
`;

export const deleteOrg = gql`
  mutation DeleteOrgMutation($organizationId: String!) {
    deleteOrganization(organizationId: $organizationId)
  }
`;

export const updateOrg = gql`
  mutation UpdateOrg(
    $organizationId: String!
    $orgUpdates: OrganizationUpdate!
  ) {
    updateOrganization(
      organizationId: $organizationId
      orgUpdates: $orgUpdates
    ) {
      ...OrgFields
    }
  }
  ${FRAGMENTS.org}
`;

export const updateOrganizationProperties = gql`
  mutation updateOrganizationProperties(
    $organizationId: String!
    $propertyUpdateRequest: PropertyUpdateRequest!
  ) {
    updateOrganizationProperties(
      organizationId: $organizationId
      propertyUpdateRequest: $propertyUpdateRequest
    ) {
      missing
      organization {
        ...OrgFields
        properties
      }
    }
  }
  ${FRAGMENTS.org}
`;

export const removeOrgUser = gql`
  mutation RemoveOrganizationUser($organizationId: String!, $userId: String!) {
    removeOrganizationUser(organizationId: $organizationId, userId: $userId)
  }
`;

export const removeOrgInvite = gql`
  mutation RemoveOrganizationInvite(
    $organizationId: String!
    $inviteCode: String!
  ) {
    removeOrganizationInvite(
      organizationId: $organizationId
      inviteCode: $inviteCode
    )
  }
`;

export const restoreDroppedTable = gql`
  mutation RestoreTable($warehouseId: String!, $request: RestoreTableRequest!) {
    restoreDroppedTable(warehouseId: $warehouseId, request: $request)
  }
`;

export const createStorageProfile = gql`
  mutation CreateStorage(
    $organizationId: String!
    $request: StorageProfileRequest!
    $creatorRoleId: String
  ) {
    createStorageProfile(
      organizationId: $organizationId
      request: $request
      creatorRoleId: $creatorRoleId
    ) {
      id
      bucket
      region
      properties
    }
  }
`;

export const sendFeedback = gql`
  mutation SendFeedback(
    $description: String!
    $type: FeedbackType!
    $props: [InProperty]
  ) {
    submitFeedback(description: $description, type: $type, props: $props) {
      receipt
    }
  }
`;

export const createOrganizationAuth = gql`
  mutation createOrganizationAuth(
    $organizationId: String!
    $request: CreateOrgAuthInput!
  ) {
    createOrganizationAuth(id: $organizationId, request: $request)
  }
`;

export const removeOrganizationAuth = gql`
  mutation removeOrganizationAuth($organizationId: String!, $authId: String!) {
    removeOrganizationAuth(id: $organizationId, authId: $authId)
  }
`;
