import React, { useCallback, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Skeleton } from '@mui/material';
import { concat, isEmpty, orderBy, compact } from 'lodash';
import { useSnackbar } from 'notistack';

import { toDocsRoot } from '../../RouteTable';
import {
  createAuthorizations,
  getAuthorizationGrants,
  handleAuthorizationMutations,
  storageProfileAuthorizationsQuery,
} from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionSubjectType,
  Authorization,
  CreateAuthInput,
  Privilege,
  StorageProfile,
  UpdateAuthInput,
} from '../../graphql/gen/graphql';
import { getRoleByName } from '../../graphql/role';
import GrantNewAccess from '../AccessControl/GrantNewAccess';
import RoleCard from '../AccessControl/RoleCard';
import { PrivilegeSelected } from '../AccessControl/RolePrivileges';
import {
  getAuthsToCreate,
  getAuthsToUpdate,
  getRoleIds,
  getSecurityAdminDefault,
  getUserFriendlyErrorMessage,
} from '../AccessControl/common';
import { Tabulink } from '../Link/Tabulink';
import { PageHeader } from '../PageHeader/PageHeader';

export default function StorageProfileAccessControls({
  profile,
  orgId,
  orgName,
  orgDisplayName,
  loading,
  user,
}: {
  profile: StorageProfile;
  orgId: string;
  orgName: string;
  orgDisplayName: string;
  loading: boolean;
  user: any;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [roleBeingEdited, setRoleBeingEdited] = useState('');
  const {
    data,
    loading: authsLoading,
    refetch,
  } = useQuery(storageProfileAuthorizationsQuery, {
    skip: !profile,
    variables: {
      organizationId: orgId,
      organizationName: orgName,
      organizationDisplayName: orgDisplayName,
      storageProfileId: profile?.id,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const securityAdminAuth = useMemo(
    () =>
      data?.getStorageProfileAuthorizations?.filter(
        (auth: any) => auth?.role?.displayName === 'Security Admin',
      )[0],
    [data],
  );

  const { data: securityAdminRole, loading: securityAdminLoading } = useQuery(
    getRoleByName,
    {
      skip: loading || securityAdminAuth,
      variables: {
        organizationId: orgId,
        roleName: 'SECURITY_ADMIN',
        organizationName: orgName,
        organizationDisplayName: orgDisplayName,
      },
    },
  );

  const { data: currentGrants } = useQuery(getAuthorizationGrants, {
    skip: !profile,
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        resource: {
          type: AuthDecisionResourceType.StorageProfile,
          identifier: profile?.id,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  //mutations
  const [createAuths] = useMutation(createAuthorizations);
  const [handleAuthMutations] = useMutation(handleAuthorizationMutations);

  const resourceAuthorizations = useMemo(
    () => data?.getStorageProfileAuthorizations,
    [data],
  );

  const saRole = securityAdminRole && securityAdminRole?.fetchRoleByName;

  const securityAdminDefault =
    saRole &&
    getSecurityAdminDefault(saRole, AuthDecisionResourceType.StorageProfile);

  const resourceAuthorizationsWithDefault = !securityAdminAuth
    ? compact(concat(resourceAuthorizations, securityAdminDefault))
    : resourceAuthorizations;

  const sortedAuths = orderBy(
    resourceAuthorizationsWithDefault,
    [(auth) => auth?.role?.displayName?.toLowerCase()],
    ['asc'],
  );

  const securityAdminIncluded = !securityAdminAuth
    ? !securityAdminLoading && securityAdminRole
    : true;

  const roleIds = useMemo(
    () => getRoleIds(resourceAuthorizations),
    [resourceAuthorizations],
  );
  const isSecurityAdmin = useMemo(() => user.isSecurityAdmin(orgName), [user]);
  const isResourceAdmin = useMemo(
    () =>
      currentGrants?.authGrants?.filter(
        (priv: any) =>
          (priv.withGrant && !priv.privilege.includes('FUTURE')) ||
          priv.privilege === 'MANAGE_GRANTS',
      )?.length > 0 || isSecurityAdmin,
    [user, currentGrants],
  );
  const privilegesWithGrants = useMemo(
    () =>
      currentGrants?.authGrants?.filter(
        (priv: any) => priv.withGrant || priv.privilege === 'MANAGE_GRANTS',
      ),
    [currentGrants],
  );

  const closeAndRefetch = (message: string) => {
    setTimeout(() => {
      refetch().then(() =>
        enqueueSnackbar(message, {
          variant: 'success',
          preventDuplicate: true,
        }),
      );
    }, 1000);
  };

  const handleMutations = async (
    authsToUpdate: UpdateAuthInput[],
    authsToCreate: CreateAuthInput[],
    authsToDelete: string[],
  ) => {
    return await handleAuthMutations({
      variables: {
        authsToUpdate,
        authsToCreate,
        authsToDelete,
      },
      onCompleted: () =>
        closeAndRefetch(`${roleBeingEdited} privileges have been updated`),
      onError: (e) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
          variant: 'error',
          preventDuplicate: true,
        });
      },
    });
  };

  const handleGrantNewAccess = useCallback(
    async (currentPrivileges: PrivilegeSelected[], selectedRoleId: string) => {
      const authsToCreate = getAuthsToCreate(
        currentPrivileges,
        profile?.id,
        AuthDecisionResourceType.StorageProfile,
        selectedRoleId,
      );

      return await createAuths({
        variables: {
          authorizations: authsToCreate,
        },
        onCompleted: () => {
          closeAndRefetch(`${roleBeingEdited} privileges successfully updated`);
        },
        onError: (e) => {
          enqueueSnackbar(getUserFriendlyErrorMessage(e.message), {
            variant: 'error',
            preventDuplicate: true,
          });
        },
      });
    },
    [profile],
  );

  const handleApplyChanges = useCallback(
    async (
      currentPrivileges: PrivilegeSelected[],
      originalPrivileges: Authorization[],
      toCreate: PrivilegeSelected[],
      toUpdate: PrivilegeSelected[],
      toDelete: Privilege[],
      cascade: boolean,
      selectedRoleId: string,
    ) => {
      const roleId = originalPrivileges[0].roleId || selectedRoleId;
      const authsToCreate = getAuthsToCreate(
        toCreate,
        profile?.id,
        AuthDecisionResourceType.StorageProfile,
        roleId,
      );

      const authsToUpdate = getAuthsToUpdate(toUpdate, originalPrivileges);

      const authsToDelete = toDelete.map((priv: Privilege) => {
        return originalPrivileges.find((p) => p.privilege === priv)?.id;
      });
      //@ts-ignore
      return handleMutations(authsToUpdate, authsToCreate, authsToDelete);
    },

    [profile],
  );

  const revokePrivilege = async (privileges: Authorization[]) => {
    const toDelete = privileges.map((priv: any) => {
      return priv.id;
    });

    return handleMutations([], [], toDelete);
  };

  return (
    <>
      {loading && <Skeleton variant="rectangular" height={350} />}
      {!loading && !authsLoading && isResourceAdmin && (
        <>
          <PageHeader
            resourceName={profile?.bucket}
            pageTitle={'Storage profile access controls'}
            marginB={0}
          >
            <GrantNewAccess
              user={user}
              organizationId={orgId}
              existingRoleIds={roleIds}
              handleGrantNewApply={async (
                currentPrivileges: PrivilegeSelected[],
                selectedRoleId: string,
                selectedRoleName: string,
              ) => {
                await setRoleBeingEdited(selectedRoleName);
                return handleGrantNewAccess(currentPrivileges, selectedRoleId);
              }}
              resourceType={AuthDecisionResourceType.StorageProfile}
              currentGrants={privilegesWithGrants || []}
              isSecurityAdmin={isSecurityAdmin}
            />
          </PageHeader>
          <Tabulink
            external
            href={
              toDocsRoot() +
              '/understanding-tabular-role-based-access-controls--rbac-.html'
            }
            variant="body1"
            rel="noopener"
            aria-label="Tabular Documentation"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2,
              maxWidth: '350px',
            }}
          >
            Learn about access controls and role privileges
            <OpenInNewIcon fontSize="small" sx={{ marginLeft: '2px' }} />
          </Tabulink>
        </>
      )}
      {!loading &&
        !authsLoading &&
        !isEmpty(sortedAuths) &&
        securityAdminIncluded &&
        sortedAuths.map((roleGroup: any, key: number) => {
          const securityAdminCard = roleGroup.role.name === 'SECURITY_ADMIN';
          return (
            <RoleCard
              key={`role-card-${key}-${roleGroup.role.id}`}
              organizationName={orgName}
              authorizations={roleGroup.authorizations}
              resourceName={profile?.bucket}
              roleDetails={roleGroup.role}
              accessType={AuthDecisionResourceType.StorageProfile}
              handleApplyChanges={async (
                currentPrivileges: PrivilegeSelected[],
                originalPrivileges: Authorization[],
                toCreate: PrivilegeSelected[],
                toUpdate: PrivilegeSelected[],
                toDelete: Privilege[],
                cascade: boolean,
                selectedRoleId: string,
              ) => {
                await setRoleBeingEdited(roleGroup.role.displayName);
                return handleApplyChanges(
                  currentPrivileges,
                  originalPrivileges,
                  toCreate,
                  toUpdate,
                  toDelete,
                  (cascade = false),
                  selectedRoleId,
                );
              }}
              showRevokeAccess={!securityAdminCard}
              onRevokeAccess={async () => {
                await setRoleBeingEdited(roleGroup.role.displayName);
                await revokePrivilege(roleGroup.authorizations);
              }}
              isResourceAdmin={isResourceAdmin}
              currentGrants={privilegesWithGrants || []}
              user={user}
              securityAdminCard={securityAdminCard}
            />
          );
        })}
    </>
  );
}
