import React, { useEffect } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import { useUserAgent } from 'react-ua';

import { Grid, Stack, StackProps } from '@mui/material';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Warehouse } from '../../../graphql/gen/graphql';

interface TrinoCmdTemplateProps extends StackProps {
  warehouse: Warehouse;
  restCatalogEndpoint: string;
  credential: string;
  trinoImageName: string;
  onCommandFormed?: (cmd: string) => void;
}
export const TrinoCmdTemplate = ({
  warehouse,
  restCatalogEndpoint,
  credential,
  onCommandFormed,
  trinoImageName,
  ...props
}: TrinoCmdTemplateProps): JSX.Element => {
  const ua = useUserAgent();

  const lineContinuationChar =
    ua.os.name === 'Windows' ? '`' : ua.os.name === 'Mac OS' ? '\\' : '\\';
  const shouldQuoteChar = /\s/g.test(warehouse?.name) ? '"' : ''; //look for gross warehouse names

  const localExtras = `
  -e AWS_REGION=us-west-2 ${lineContinuationChar}
  --network="host" ${lineContinuationChar}`;

  const cmdTemplate = `docker run ${lineContinuationChar}
  --name trino-tabular ${lineContinuationChar}
  -p 8080:8080 ${lineContinuationChar}
  -d  ${lineContinuationChar}
  -e TABULAR_CREDENTIAL=${credential} ${lineContinuationChar}
  -e TABULAR_CATALOG_URL=${restCatalogEndpoint} ${lineContinuationChar}${
    restCatalogEndpoint.indexOf('localhost') > 0 ? localExtras : ''
  }
  ${trinoImageName}
`;
  useEffect(() => {
    if (onCommandFormed) {
      onCommandFormed(cmdTemplate);
    }
  }, [cmdTemplate]);

  return (
    <Stack direction="column" {...props}>
      <Grid container alignItems={'center'}>
        <Grid
          item
          xs={12}
          sx={{
            pre: {
              m: 0,
              height: 1,
            },
          }}
        >
          {' '}
          <SyntaxHighlighter
            language="bash"
            style={{ ...dracula, margin: 0 as React.CSSProperties }}
            showLineNumbers={false}
          >
            {cmdTemplate}
          </SyntaxHighlighter>
        </Grid>
      </Grid>
    </Stack>
  );
};
