import React from 'react';

import { useLazyQuery } from '@apollo/client';
import { Typography } from '@mui/material';

import { SearchEntityType } from '../../../../graphql/gen/graphql';
import { fetchTableLabelCount } from '../../../../graphql/table';
import SearchAndNavigateResources, {
  SearchAndNavigateItem,
} from '../../../Forms/SearchAndNavigateResources';

export const ChooseSource = ({
  onSourceSelected,
  selectedId,
  onDisplayLabelError,
}: {
  onSourceSelected: (selected: SearchAndNavigateItem) => void;
  selectedId?: string;
  onDisplayLabelError: () => void;
}): JSX.Element => {
  const [checkLabelCount] = useLazyQuery(fetchTableLabelCount);
  const checkValidation = async (selected: SearchAndNavigateItem) => {
    await checkLabelCount({
      variables: {
        warehouseId: selected.whId,
        databaseName: selected.dbName,
        tableName: selected.tblName,
      },
      onCompleted: (data) => {
        if (data?.fetchTableLabelCount === 0) {
          onSourceSelected(selected);
        } else {
          onDisplayLabelError();
        }
      },
    });
  };

  return (
    <>
      <Typography
        variant={`body1`}
        sx={{ mb: 2 }}
      >{`Choose your source table from which you'd like to replicate data. This Auto-Replicate pipeline will look for changes to the source table (main branch) and apply those changes on the target table.`}</Typography>
      <Typography fontWeight={`bold`} variant={`body1`} sx={{ mb: 1 }}>
        Search and select your source table.
      </Typography>
      <SearchAndNavigateResources
        onResourceSelected={(selected: SearchAndNavigateItem) =>
          checkValidation(selected)
        }
        resourceType={SearchEntityType.Table}
        selectedId={selectedId}
      />
    </>
  );
};
