import React from 'react';

import { Box, Typography } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';

import { StorageType } from '../../../graphql/gen/graphql';
import RegionSelector from '../../Forms/RegionSelector';
import TextField from '../../Forms/TextField';
import { BucketCreationType } from '../storage';

export default function BucketForm({
  messaging,
  type,
}: {
  messaging: React.ReactNode;
  type: BucketCreationType;
}) {
  const { values } = useFormikContext() as FormikValues;
  return (
    <Box sx={{ px: 5, pb: 6, boxShadow: 'none' }}>
      <Typography variant={'h1'} sx={{ pb: 1 }}>
        {values?.bucketName} bucket settings
      </Typography>
      <Typography variant={'subtitle1'}>Output</Typography>
      {messaging}
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
        <Typography variant={'subtitle2'} sx={{ mb: 2 }}>
          Resource Details
        </Typography>
        <Box display={'flex'} flexDirection={'row'}>
          <RegionSelector
            storageType={
              type === BucketCreationType.Google
                ? StorageType.Gcs
                : type === BucketCreationType.Azure
                ? StorageType.Adls
                : StorageType.S3
            }
            id="region"
            textFieldProps={{
              helperText: 'AWS Region where the S3 bucket is located.',
            }}
            sx={{ mr: 1 }}
          />
          <TextField
            name="bucketName"
            type="text"
            label="Bucket"
            id="bucketName"
            variant="standard"
            margin="normal"
            autoComplete="off"
            helperText="Bucket name"
            fullWidth
            sx={{ mx: 2, width: '235px' }}
          />
        </Box>
        {type === BucketCreationType.CloudFormation && (
          <Typography variant={'body1'} pt={3}>
            Copy the value for <b>TabularRoleARN</b> and paste it in the field
            below.
          </Typography>
        )}
        <TextField
          name="iamRole"
          type="text"
          label={
            type === BucketCreationType.Manual
              ? 'IAM Role ARN'
              : 'TabularRoleARN'
          }
          id="iamRole"
          variant="standard"
          margin="normal"
          autoComplete="off"
          helperText="The full ARN of the role that Tabular is authorized to assume, example: arn:aws:iam::aws_acct_id:role/role_name"
          sx={{ mr: 2 }}
        />
        <TextField
          name="externalId"
          type="text"
          label="External Id*"
          id="externalId"
          variant="standard"
          margin="normal"
          autoComplete="off"
          helperText="External Id generated by Tabular used when Tabular assumes the IAMRole above. Used within your IAM role trust policy to designate that Tabular can assume this role."
          sx={{ mr: 2 }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
    </Box>
  );
}
