import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
// @ts-ignore
import { UserAgentProvider } from 'react-ua';

import { ApolloProvider } from '@apollo/client';
import NiceModal from '@ebay/nice-modal-react';
import { CssBaseline, GlobalStyles, PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';

import {
  createGlobalPropertiesCtx,
  KnownFeatureFlags,
} from './GlobalPropsContext';

import SomethingWentWrong, {
  SomethingWentWrong2,
} from '../components/Errors/SomethingWentWrong';
import LoadingBackdrop from '../components/Feedback/LoadingBackdrop';
import { DocumentTitleProvider } from './DocumentTitle';
import { AuthProvider } from './auth-context';
import useApolloClient from './client';
import { usePosthogClient } from './posthog';
import { getThemeOptions } from './theme';

const [ctx, GlobalPropertiesProvider] = createGlobalPropertiesCtx();

export const GlobalPropertiesContext = ctx;
function AppProviders({ children }: { children: React.ReactNode }) {
  const posthog = usePosthogClient();

  const [darkModeEnabled, setDarkModeEnabled] = useState<boolean>(false);
  const [currentPaletteMode, setCurrentPaletteMode] =
    useState<PaletteMode>('light');

  const theme = useMemo(() => {
    return createTheme(
      // @ts-ignore
      getThemeOptions(darkModeEnabled ? currentPaletteMode : 'light'),
    );
  }, [currentPaletteMode, darkModeEnabled]);

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.darkSuccess.main,
      border: `1px solid ${theme.palette.aurora.four}`,
      color: theme.palette.primary.contrastText,
      borderRadius: '8px',
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.darkError.main,
      border: `1px solid ${theme.palette.sunset.four}`,
      color: theme.palette.primary.contrastText,
      borderRadius: '8px',
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.darkWarning.main,
      border: `1px solid ${theme.palette.darkWarning.light}`,
      color: theme.palette.primary.contrastText,
      borderRadius: '8px',
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.palette.darkInfo.main,
      border: `1px solid ${theme.palette.darkInfo.light}`,
      color: theme.palette.primary.contrastText,
      borderRadius: '8px',
    },
  }));

  const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true';

  const apolloClient = useApolloClient();

  if (!apolloClient) {
    return <LoadingBackdrop />;
  }

  const CoreFunctionality = () => {
    return (
      <ApolloProvider client={apolloClient}>
        <UserAgentProvider>
          <AuthProvider>
            <GlobalPropertiesProvider>
              <>
                <DarkListener />

                <DocumentTitleProvider>
                  <SnackbarProvider
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                    autoHideDuration={6000}
                    Components={{
                      success: StyledMaterialDesignContent,
                      error: StyledMaterialDesignContent,
                    }}
                  >
                    <NiceModal.Provider>{children}</NiceModal.Provider>
                  </SnackbarProvider>
                </DocumentTitleProvider>
              </>
            </GlobalPropertiesProvider>
          </AuthProvider>
        </UserAgentProvider>
      </ApolloProvider>
    );
  };
  const DarkListener = () => {
    const { ff, paletteMode } = useContext(GlobalPropertiesContext);
    const darkMode = ff(KnownFeatureFlags.DARK_MODE_ENABLED);
    useEffect(() => {
      if (darkMode) {
        setDarkModeEnabled(true);
        setCurrentPaletteMode(paletteMode);
      }
    }, [darkMode, paletteMode]);
    return <></>;
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {sentryEnabled && !!posthog && (
          <Sentry.ErrorBoundary fallback={<SomethingWentWrong />}>
            <PostHogProvider client={posthog}>
              <CoreFunctionality />
            </PostHogProvider>
          </Sentry.ErrorBoundary>
        )}
        {sentryEnabled && !posthog && (
          <Sentry.ErrorBoundary fallback={<SomethingWentWrong />}>
            <CoreFunctionality />
          </Sentry.ErrorBoundary>
        )}
        {!sentryEnabled && !!posthog && (
          <ErrorBoundary FallbackComponent={SomethingWentWrong2}>
            <PostHogProvider client={posthog}>
              <CoreFunctionality />
            </PostHogProvider>
          </ErrorBoundary>
        )}
        {!sentryEnabled && !posthog && (
          <ErrorBoundary FallbackComponent={SomethingWentWrong2}>
            <CoreFunctionality />
          </ErrorBoundary>
        )}
      </ThemeProvider>
    </Router>
  );
}

export { AppProviders };
