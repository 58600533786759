import React from 'react';

export interface UseNavSearchKeyboardEventsProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

function isEditingContent(event: KeyboardEvent): boolean {
  const element = event.target as HTMLElement;
  const tagName = element.tagName;

  return (
    element.isContentEditable ||
    tagName === 'INPUT' ||
    tagName === 'SELECT' ||
    tagName === 'TEXTAREA'
  );
}

export function useNavSearchKeyboardEvents({
  isOpen,
  onOpen,
  onClose,
}: UseNavSearchKeyboardEventsProps) {
  function onKeyDown(event: KeyboardEvent) {
    if (!isEditingContent(event) && event.key === '/') {
      event.preventDefault();

      onOpen();
    }
  }

  window.addEventListener('keydown', onKeyDown);

  return () => {
    window.removeEventListener('keydown', onKeyDown);
  };
}
