import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import * as Yup from 'yup';

export const REQUIRED_ERROR = 'Password is required.';
export const LENGTH_ERROR = 'Must be minimum 8 characters.';
export const UNIQUE_ERROR = 'Must be unique.';
export const PASSWORDS_BLACKLIST = [
  '123456',
  '123456789',
  'qwerty',
  'password',
  '12345',
  'qwerty123',
  '1q2w3e',
  '12345678',
  '111111',
  '1234567890',
];

export const containsBlacklistPassword = (string: string) => {
  const regEx = new RegExp(PASSWORDS_BLACKLIST.join('|'), 'gi');
  return regEx.test(string);
};

export const RESET_EMAIL_TEXT = (
  <>
    <Typography>{`We sent you an email to change your password. If you don’t see it in a few minutes, check your Spam folder.`}</Typography>
  </>
);

export const resetPasswordContent = (
  <>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <img
        alt="Tabular logo"
        src="/assets/img/tabular-logomark-no-color.png"
        width={`80px`}
      />
    </Box>
    <Box sx={{ mt: 2, p: 2 }}>
      <Typography variant="body1">
        Passwords are like underwear: you don’t let people see it, you should
        change it very often, and you shouldn’t share it with strangers.
      </Typography>
    </Box>
  </>
);

export const linkTimeoutContent = (
  <>
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AccessTimeIcon sx={{ fontSize: '146px' }} />
    </Box>
  </>
);

export const passwordResetContent = (
  <>
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MailOutlineIcon sx={{ fontSize: '146px' }} />
    </Box>
  </>
);

export const tabularWelcomeContent = (
  <Grid
    height={'100%'}
    container
    direction="column"
    justifyContent="space-between"
    alignItems="center"
  >
    <Grid item>
      <img
        alt="Tabular logo"
        src="/assets/img/tabular-logomark-no-color.png"
        width="80px"
      />
    </Grid>
  </Grid>
);

export const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address.')
    .required('Email is required.'),
});

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required(REQUIRED_ERROR)
    //@ts-ignore
    .test('len', LENGTH_ERROR, (val) => val && val.length >= 8)
    .test(
      'unique',
      UNIQUE_ERROR,
      //@ts-ignore
      (val) => val && !containsBlacklistPassword(val),
    ),
});

export const loginSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string().required(REQUIRED_ERROR),
});
