import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { ApolloError } from '@apollo/client';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import EmailIcon from '@mui/icons-material/Email';
import { Box, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Form, Formik, FormikHelpers } from 'formik';
import { flatten } from 'lodash';
import * as Yup from 'yup';

import { InviteResult, OrgAuthProvider } from '../../graphql/gen/graphql';
import useSetFieldValueWithCallback from '../../utils/UseSetFieldValueWithCallback';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
// @ts-ignore
import TextField from '../Forms/TextField';
import GoogleAuthButton, { GoogleButtonText } from '../Login/GoogleAuthButton';
import { PleaseWait } from '../PleaseWait/PleaseWait';

export interface InviteValues {
  email: string;
  password: string;
  idToken: string;
  authType: string;
}
export const InviteForm = ({
  inviteResult,
  error,
  handleSubmit,
  submitButtonContent,

  magicToken,
}: {
  inviteResult: InviteResult;
  error: ApolloError | undefined;
  handleSubmit: (
    values: InviteValues,
    helpers: FormikHelpers<InviteValues>,
  ) => void | Promise<any>;
  submitButtonContent: JSX.Element;

  magicToken: string | null;
}) => {
  const initialValues: InviteValues = {
    email: inviteResult?.invite?.email || '',
    password: '',
    idToken: '',
    authType: '',
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('invite');
  const [autoSignup, setAutoSignup] = useState<boolean>(false);
  const showContinueWithGoogle: boolean =
    inviteResult?.orgAuthProviders?.includes(OrgAuthProvider.Google) || false;

  const showContinueWithEmail: boolean =
    inviteResult?.invite?.organizationId === null ||
    inviteResult?.orgAuthProviders?.includes(OrgAuthProvider.MagicLink) ||
    false;

  const showContinueWithOkta: boolean =
    inviteResult?.orgAuthProviders?.includes(OrgAuthProvider.Okta) || false;

  const showDivider: boolean =
    !!inviteResult?.orgAuthProviders &&
    inviteResult?.orgAuthProviders?.length > 0;

  useEffect(() => {
    if (
      showContinueWithEmail &&
      !(showContinueWithOkta || showContinueWithGoogle)
    ) {
      const main = window as any;
      if (main.debouncedSignup) {
        main.clearTimeout(main.debouncedSignup);
      }
      main.debouncedSignup = setTimeout(() => {
        setAutoSignup(true);
      }, 250);
    }
  }, [showContinueWithEmail]);

  return (
    <>
      {error && (
        <Talert severity={'error'}>
          Your invite code is invalid or expired, please email us at
          <a href="mailto:support@tabular.io?subject=Help with Tabular Invite Code">
            {' '}
            support@tabular.io{' '}
          </a>
          for assistance.
        </Talert>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, submitForm, setFieldValue, values }) => {
          const setIdTokenLater = useSetFieldValueWithCallback(
            'idToken',
            setFieldValue,
            values,
          );
          const setAuthTypeLater = useSetFieldValueWithCallback(
            'authType',
            setFieldValue,
            values,
          );

          useMemo(() => {
            if (!isSubmitting && autoSignup && magicToken !== null) {
              setIdTokenLater(magicToken, () => {
                setAuthTypeLater(OrgAuthProvider.MagicLink, () => {
                  submitForm();
                });
              });
            }
          }, [isSubmitting, autoSignup]);

          return (
            <Form>
              {inviteResult?.invite?.email && (
                <Box
                  sx={{
                    display: 'flex',
                    mb: 4,
                  }}
                >
                  <Box
                    sx={{
                      p: 1,
                      backgroundColor: 'midnight.one',
                      borderRadius: '4px',
                      display: 'flex',

                      alignItems: 'center',
                    }}
                  >
                    <EmailIcon
                      sx={{
                        width: '16px',
                        mr: 1,
                        color: 'midnight.seven',
                      }}
                    />
                    {inviteResult?.invite?.email}
                  </Box>
                </Box>
              )}

              <input name="email" type="hidden" id="email" />
              {isSubmitting && <PleaseWait />}
              <Routes>
                <Route
                  path={'/'}
                  element={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {showContinueWithGoogle && !isSubmitting && (
                        <>
                          <GoogleAuthButton
                            googleButtonText={GoogleButtonText.continue_with}
                            onGoogleSignIn={(idToken) => {
                              setIdTokenLater(idToken, () => {
                                setAuthTypeLater(OrgAuthProvider.Google, () => {
                                  submitForm();
                                });
                              });
                            }}
                            hidden={isSubmitting}
                            sx={{ p: 0, mt: 4 }}
                          ></GoogleAuthButton>
                        </>
                      )}
                      {showContinueWithOkta && !isSubmitting && (
                        <>
                          <Tabutton
                            type="submit"
                            onClick={(event) => {
                              setAuthTypeLater(OrgAuthProvider.Okta, () => {
                                submitForm();
                              });
                            }}
                            variant="outlined"
                            disabled={!!(error || isSubmitting)}
                            sx={{ mt: 2, width: 235, borderRadius: '4px' }}
                          >
                            <img
                              src="/assets/img/logos/Okta_Aura_Solid_BrightBlue_40percent-40px.png"
                              alt="tabular"
                              height="20px"
                            />
                            <Typography variant="subtitle1" sx={{ ml: 1 }}>
                              Log in with Okta
                            </Typography>
                          </Tabutton>
                        </>
                      )}
                      {showContinueWithEmail && !isSubmitting && (
                        <>
                          {showDivider && (
                            <Divider sx={{ mt: 4, mb: 4 }}>
                              {(showContinueWithOkta ||
                                showContinueWithGoogle) && (
                                <Typography
                                  variant="overline"
                                  color={'midnight.seven'}
                                >
                                  OR
                                </Typography>
                              )}
                            </Divider>
                          )}

                          <Tabutton
                            variant="outlined"
                            onClick={() =>
                              setIdTokenLater(magicToken, () => {
                                setAuthTypeLater(
                                  OrgAuthProvider.MagicLink,
                                  () => {
                                    submitForm();
                                  },
                                );
                              })
                            }
                            sx={{ mb: 4 }}
                          >
                            Continue with email <ArrowForwardOutlinedIcon />
                          </Tabutton>
                        </>
                      )}
                    </Box>
                  }
                />
                <Route
                  path={'pass/*'}
                  element={
                    <>
                      <Talert
                        severity="info"
                        sx={{ mt: 4, mb: 4 }}
                        alertTitle={'Password Requirements'}
                      >
                        <Typography variant="inputText">
                          {"8-character minimum. Make sure it's unique."}
                        </Typography>{' '}
                      </Talert>
                      <Grid
                        alignItems="center"
                        container
                        spacing={1}
                        sx={{ mb: 4 }}
                      >
                        <Grid item md={10}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            name="password"
                            label="Create a password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={2}>
                          <Tabutton
                            sx={{
                              height: '24px',
                              width: '24px',
                              minWidth: 0,
                              padding: '4px',
                            }}
                            type="submit"
                            onClick={(event) => {
                              setAuthTypeLater(OrgAuthProvider.Password, () => {
                                submitForm();
                              });
                            }}
                            variant="contained"
                            disabled={!!(error || isSubmitting)}
                          >
                            <ArrowForwardOutlinedIcon sx={{ width: '16px' }} />
                          </Tabutton>
                        </Grid>
                      </Grid>
                    </>
                  }
                ></Route>
              </Routes>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
