import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { Tabutton } from '../Button/Tabutton';

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  acceptText: string;
  onAccept: () => void;
  onDismiss: () => void;
  loading?: boolean;
  children?: any;
  disabled?: boolean;
}

export function ConfirmationDialog({
  open,
  title,
  acceptText = 'ok',
  onAccept,
  onDismiss,
  loading = false,
  children,
  disabled = false,
}: ConfirmationDialogProps) {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Tabutton onClick={onDismiss}>Cancel</Tabutton>
        <Tabutton
          loading={loading}
          onClick={onAccept}
          variant="contained"
          loadingBtn
          disabled={disabled}
        >
          {acceptText}
        </Tabutton>
      </DialogActions>
    </Dialog>
  );
}
