import React, { useState } from 'react';
//@ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  List,
  ListItem,
  Stack,
  Typography,
  Box,
  Grid,
  Tooltip,
} from '@mui/material';
import { isEmpty } from 'lodash';

import { Tabutton } from '../../Button/Tabutton';
import { ExternalBreadcrumbs } from '../../CreateStorageBucket/BucketSteps/helpers';

const gcsIamBreadcrumbs = [
  'IAM & Admin',
  'Service Accounts',
  'Your service account',
];

export const TrinoGCSPermission = (projectId: any) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const property = `hive.gcs.json-key-file-path=/path/to/your/tabular-gcp-key.json`;

  return (
    <Stack direction="column">
      <Typography variant={'body1'}>
        Download JSON key file:
        <ExternalBreadcrumbs
          breadcrumbs={gcsIamBreadcrumbs}
          link={`https://console.cloud.google.com/iam-admin/serviceaccounts?project=${projectId}`}
        />
        <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              Choose appropriate <b>service account</b>.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              Click on <b>Add Key</b> then <b>Create new key</b>.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              Select <b>JSON</b> for key type and <b>create</b>, this will
              download your key file.
            </Typography>
          </ListItem>
        </List>
      </Typography>

      <Typography variant={'body1'} paddingTop={1}>
        Configure Trino to use this file for authentication:
      </Typography>
      <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
        <ListItem sx={{ display: 'list-item', p: 0 }}>
          <Typography variant={'body1'}>
            Locate your <b>Trino</b> catalog configuration, typically found at{' '}
            <b>/etc/trino/catalog/</b>.
          </Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item', p: 0 }}>
          <Typography variant={'body1'}>
            In the catalog directory <b>find or create</b> your properties file
            for your GCS catalog.
          </Typography>
        </ListItem>
        <ListItem sx={{ display: 'list-item', p: 0, width: 'fit-content' }}>
          <Typography variant={'body1'}>
            <b>Add</b> the following property to file.{' '}
            <b>Replace example path</b> with the actual path to your JSON key
            file:
          </Typography>
        </ListItem>
      </List>
      <Grid
        container
        sx={{
          width: 1,
          mt: 4,
          border: 1,
          borderColor: 'midnight.two',
          borderRadius: '8px',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            padding: 1,
            borderRadius: '8px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: 1,
            borderColor: 'midnight.two',
            backgroundColor: 'dusk.half',
          }}
        >
          <Typography variant={'body1'} sx={{}}>
            Property
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: 1,
            borderBottom: 1,
            borderColor: 'midnight.two',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pre: {
                m: 0,
                height: 1,
              },
            }}
          >
            <SyntaxHighlighter
              className="ph-no-capture"
              language="bash"
              style={{ margin: 0 as React.CSSProperties }}
              showLineNumbers={false}
            >
              {property}
            </SyntaxHighlighter>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: 1,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip
            title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
            placement={`top`}
            arrow
          >
            <Tabutton
              onClick={() => copyToClipBoard(property)}
              title="Copy to clipboard"
              size={'small'}
              endIcon={<FileCopyIcon />}
            >
              Copy Property
            </Tabutton>
          </Tooltip>
        </Grid>
      </Grid>
    </Stack>
  );
};
