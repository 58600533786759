import React, { useState } from 'react';

import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  joinApacheIcebergSlack,
  toApacheIcebergSlack,
  toDocsRoot,
} from '../../RouteTable';
import { useAuth } from '../../context/auth-context';
import { Tabutton } from '../Button/Tabutton';
import { DropdownMenuContent } from '../DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../DropdownMenu/DropdownMenuItem';
import { Tabulink } from '../Link/Tabulink';
import { showSendFeedbackDialog } from '../Modals/SendFeedbackDialog';

export default function HelpMenu() {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = (event: any) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <Box marginLeft={2} marginRight={1}>
      <IconButton
        onClick={openMenu}
        sx={{
          cursor: 'pointer',
          backgroundColor: 'sky.eight',
          '&:hover': {
            backgroundColor: 'sky.nine',
          },
        }}
      >
        <Tooltip title={'Help'} arrow>
          <HeadsetMicIcon fontSize={`small`} sx={{ color: 'sky.three' }} />
        </Tooltip>
      </IconButton>
      <Menu
        id="help-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mt: 1,
          ul: {
            pt: 0,
            pb: 0,
            minWidth: '320px',
          },
          a: {
            textDecoration: 'none',
          },
        }}
      >
        <DropdownMenuContent
          menuItems={[
            <DropdownMenuItem
              key={'helpDocs'}
              titleText={'Need some help?'}
              description={'Documentation is a great place to start.'}
              Icon={LibraryBooksIcon}
              highlightBackground
              onClick={() => window.open(toDocsRoot(), '_blank')}
              linkCallout={
                <Tabulink
                  href={toDocsRoot()}
                  external
                  variant="tooltipLink"
                  rel="noopener"
                  aria-label="Tabular Documentation"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Tabular docs
                  <OpenInNewIcon fontSize="small" sx={{ marginLeft: '6px' }} />
                </Tabulink>
              }
            />,
            <DropdownMenuItem
              key={'joinIcebergSlack'}
              titleText={'Apache Iceberg Slack'}
              description={'Join the Iceberg community on Slack'}
              customIconPath={'/assets/icon/slack-icon.svg'}
              highlightBackground
              onClick={() =>
                window.open(joinApacheIcebergSlack(), '_blank', 'rel=noopener')
              }
              linkCallout={
                <Tabulink
                  href={joinApacheIcebergSlack()}
                  external
                  variant="tooltipLink"
                  rel="noopener"
                  aria-label="Apache Iceberg slack workspace"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Join apache-iceberg on Slack
                  <OpenInNewIcon fontSize="small" sx={{ marginLeft: '6px' }} />
                </Tabulink>
              }
            />,

            <DropdownMenuItem
              key={'loginIcebergSlack'}
              onClick={() =>
                window.open(toApacheIcebergSlack(), '_blank', 'rel=noopener')
              }
              titleText={'Already have an account?'}
              description={'Log in to Slack'}
              Icon={OpenInNewIcon}
            />,
          ]}
        />

        <Divider sx={{ my: '0 !important' }} />
        <MenuItem
          onClick={() => {
            showSendFeedbackDialog(user);
            closeMenu();
          }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: 2,
            a: {
              textDecoration: 'none',
            },
          }}
        >
          <Tabutton size={`small`} startIcon={<EmojiPeopleIcon />}>
            Contact Us
          </Tabutton>
        </MenuItem>
      </Menu>
    </Box>
  );
}
