import React from 'react';

import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';

import { Database } from '../../graphql/gen/graphql';
import CreateDatabase from '../Forms/CreateDatabase';

interface CreateDatabaseDialogProps {
  organizationId: string;
  warehouseId: string;
  onDatabaseCreated: (database: Database) => void;
}
const CreateDatabaseDialog = create(
  ({
    organizationId,
    warehouseId,
    onDatabaseCreated,
  }: CreateDatabaseDialogProps) => {
    const modal = useModal();

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <CreateDatabase
          organizationId={organizationId}
          warehouseId={warehouseId}
          onCancel={modal.hide}
          onDatabaseCreated={(database) => {
            onDatabaseCreated(database);
            modal.hide();
          }}
        />
      </Dialog>
    );
  },
);

export default CreateDatabaseDialog;

export function showCreateDatabaseDialog(
  organizationId: string,
  warehouseId: string,
  onDatabaseCreated: (database: Database) => void,
) {
  return show<boolean, any, CreateDatabaseDialogProps>(CreateDatabaseDialog, {
    organizationId,
    warehouseId,
    onDatabaseCreated,
  });
}
