import React, { useRef } from 'react';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Typography,
  Box,
  BoxProps,
  BreadcrumbsProps,
  Badge,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { NavTab, TabWrapper } from '../NavTabs/SubNavTabs';

interface HeadsUpDisplayProps extends BoxProps {
  breadcrumbs: any;
  tabs?: any;
  onTabChange?: (event: any, newValue: any) => void;
  tabIndex?: number;
  accessDenied?: boolean;
  readonly?: boolean;
  BreadcrumbProps?: BreadcrumbsProps;
}

export default function HeadsUpDisplay({
  breadcrumbs,
  tabs,
  onTabChange,
  tabIndex,
  accessDenied,
  readonly,
  BreadcrumbProps,
  ...props
}: HeadsUpDisplayProps) {
  const theme = useTheme();
  const tabRef = useRef<HTMLButtonElement>(null);
  const scrollRef = useRef<HTMLElement | null>(null);
  const mobileNav = useMediaQuery(theme.breakpoints.down('sm'));
  const breakPointStyles = {
    overflowX: 'auto',
    overflowY: 'hidden',
    mt: 2,
    width: '100%',
  };
  return (
    <Box
      {...props}
      sx={{
        position: 'fixed',
        top: 72,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.controlBackground.half,
        zIndex: 1200,
        minHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 3,
        borderBottom: `1px solid ${theme.palette.midnight.two}`,
        ...(props.sx ? (props.sx as SystemStyleObject) : {}),
        boxShadow: theme.shadows[1],
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'flex-start',
        },
      }}
    >
      <Box sx={{ overflowX: 'auto', scrollbarWidth: 'none' }}>
        <Breadcrumbs
          {...(BreadcrumbProps !== undefined ? BreadcrumbProps : {})}
          links={breadcrumbs}
          readonly={readonly}
        />
        {accessDenied && (
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
            {' '}
            <LockOutlinedIcon
              fontSize={'small'}
              sx={{ color: 'sunset.seven', mr: 1 }}
            />
            <Typography variant={'subtitle2'} color={'sunset.seven'}>
              Access Denied
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        display={`flex`}
        sx={{
          pt: 2,
          justifyContent: 'center',
          [theme.breakpoints.down('sm')]: {
            pt: 0,
            overflowX: 'auto',
          },
        }}
      >
        {tabs?.length > 1 && (
          <Box
            ref={scrollRef}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                ...breakPointStyles,
              },
            }}
          >
            <TabWrapper
              ref={tabRef as any}
              value={tabIndex}
              onChange={onTabChange}
              variant={mobileNav ? 'scrollable' : 'standard'}
              sx={{
                [theme.breakpoints.down('sm')]: {
                  border: 'none',
                  marginRight: '0px',
                  paddingRight: '0px',
                },
              }}
            >
              {tabs.map((tab: any) => (
                <NavTab
                  key={tab.id}
                  id={tab.id}
                  disabled={tab.disabled}
                  sx={{
                    [theme.breakpoints.down('sm')]: {
                      fontSize: '12px',
                      lineHeight: '14px',
                    },
                  }}
                  label={
                    'ff' in tab && tab.ff.startsWith('internal') ? (
                      <Badge
                        badgeContent={'🚩'}
                        title={'Internal Only'}
                        style={{
                          paddingRight: '10px',
                          paddingLeft: '10px',
                        }}
                      >
                        {tab.label}
                      </Badge>
                    ) : (
                      <>{tab.label}</>
                    )
                  }
                />
              ))}
            </TabWrapper>
          </Box>
        )}
      </Box>
    </Box>
  );
}
