import React from 'react';

import * as Yup from 'yup';

import { StepType } from '../ConnectionAssistant';
import {
  bucketNameValidation,
  bucketValidationSchema,
  GCP_REGIONS,
  googleBucketValidationSchema,
  googleProjectValidation,
  googleServiceValidation,
  googleWorkloadValidation,
  AWS_REGIONS,
  AZURE_REGIONS,
  getCloudFormationLink,
} from '../helpers';
import { BucketCreationType } from '../storage';
import AzureAppStep from './AzureAppStep';
import AzureApplyAppPermissionsStep from './AzureApplyAppPermissionsStep';
import AzureCreateAppCredentialStep from './AzureCreateAppCredentialStep';
import BucketBasics from './BucketBasics';
import BucketForm from './BucketForm';
import GoogleBucketForm from './GoogleBucketForm';
import GoogleProjectStep from './GoogleProjectStep';
import { GoogleServiceAccountStep } from './GoogleServiceAccountStep';
import GoogleWorkloadStep from './GoogleWorkloadStep';
import ManualBucketStep from './ManualBucketStep';
import { CloudFormation, Manual, Google, Azure } from './helpers';

export const cloudFormationSteps: StepType[] = [
  {
    onClick: ({ setFieldValue, values }) => {
      const formattedLink = getCloudFormationLink(values);
      setFieldValue('iamRole', '', false);
      alert(
        'You are leaving Tabular for AWS in order to build your resources via Cloud Formation. Come back to this tab with your newly created TabularRoleARN role arn when completed.',
      );
      window.open(formattedLink, '_blank');
    },
    component: <BucketBasics content={CloudFormation} />,
    primaryButton: 'Launch AWS',
    secondaryButton: 'Back',
    bucketValidationSchema: Yup.object({
      region: Yup.string().oneOf(AWS_REGIONS).required('Region is required'),
    }),
  },

  {
    //test
    component: (
      <BucketForm
        messaging={CloudFormation.bucketSettingsMessage}
        type={BucketCreationType.CloudFormation}
      />
    ),
    primaryButton: 'Next',
    secondaryButton: 'Back',
    bucketValidationSchema: bucketValidationSchema,
  },
];
export const manualBucketSteps: StepType[] = [
  {
    component: <BucketBasics content={Manual} />,
    primaryButton: 'Next',
    secondaryButton: 'Cancel',
    bucketValidationSchema: Yup.object({
      ...bucketNameValidation,
    }),
  },
  {
    component: <ManualBucketStep />,
    primaryButton: 'Next',
    secondaryButton: 'Back',
  },
  {
    component: (
      <BucketForm
        messaging={Manual.bucketSettingsMessage}
        type={BucketCreationType.Manual}
      />
    ),
    primaryButton: 'Next',
    secondaryButton: 'Back',
    bucketValidationSchema: bucketValidationSchema,
  },
];

export const googleManualBucketSteps: StepType[] = [
  {
    component: <BucketBasics content={Google} />,
    primaryButton: 'Next',
    secondaryButton: 'Cancel',
    bucketValidationSchema: Yup.object({
      ...bucketNameValidation,
      region: Yup.string().oneOf(GCP_REGIONS).required('Region is required'),
    }),
  },
  {
    component: <GoogleProjectStep />,
    primaryButton: 'Next',
    secondaryButton: 'Back',
    bucketValidationSchema: Yup.object({ ...googleProjectValidation }),
  },
  {
    component: <GoogleWorkloadStep />,
    primaryButton: 'Next',
    secondaryButton: 'Back',
    bucketValidationSchema: Yup.object({ ...googleWorkloadValidation }),
  },
  {
    component: <GoogleServiceAccountStep />,
    primaryButton: 'Next',
    secondaryButton: 'Back',
    bucketValidationSchema: Yup.object({ ...googleServiceValidation }),
  },
  {
    component: <GoogleBucketForm messaging={Google.bucketSettingsMessage} />,
    primaryButton: 'Next',
    secondaryButton: 'Back',
    bucketValidationSchema: googleBucketValidationSchema,
  },
];

export const azureManualBucketSteps: StepType[] = [
  {
    component: <BucketBasics content={Azure} />,
    primaryButton: 'Next',
    secondaryButton: 'Cancel',
    bucketValidationSchema: Yup.object({
      storageAccountName: Yup.string()
        .trim()
        .required('Storage account name required'),
      bucketName: Yup.string().trim().required('Container name required'),
      creatorRoleId: Yup.string().required('Creator role is required'),
      region: Yup.string().oneOf(AZURE_REGIONS).required('Region is required'),
    }),
  },
  {
    component: <AzureAppStep />,
    primaryButton: 'Next',
    secondaryButton: 'Back',
    bucketValidationSchema: Yup.object({
      tenantId: Yup.string().trim().required('TenantId required'),
      applicationId: Yup.string().trim().required('ApplicationId required'),
    }),
  },
  {
    component: <AzureCreateAppCredentialStep />,
    primaryButton: 'Next',
    secondaryButton: 'Back',
    bucketValidationSchema: null,
  },
  {
    component: <AzureApplyAppPermissionsStep />,
    primaryButton: 'Next',
    secondaryButton: 'Back',
    bucketValidationSchema: null,
  },
];
