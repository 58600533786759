import { some } from 'lodash';

import {
  Privilege,
  RoleResourcePrivilegeType,
} from '../../graphql/gen/graphql';

export const configureOrganizationButtons = (
  privs: string[],
  groupName?: string,
  displayText = false,
) => {
  return [
    [
      {
        privilege: Privilege.CreateLabel,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.CreateLabel,
        ),
        buttonText: displayText ? 'CREATE LABEL' : null,
        groupingName: 'ORGANIZATION',
        description: 'Allows creating a label',
        level: 0,
        group: 0,
      },

      {
        privilege: Privilege.CreateRole,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.CreateRole,
        ),
        buttonText: displayText ? 'CREATE ROLE' : null,
        groupingName: 'ORGANIZATION',
        description: 'Allows creating a role',
        level: 1,
        group: 0,
      },
      {
        privilege: Privilege.CreateWarehouse,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.CreateWarehouse,
        ),
        buttonText: displayText ? 'CREATE WAREHOUSE' : null,
        groupingName: 'ORGANIZATION',
        description: 'Allows creating a warehouse and storage profile',
        level: 2,
        group: 0,
      },
      {
        privilege: Privilege.ManageUsers,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ManageUsers,
        ),
        buttonText: displayText ? 'MANAGE USERS' : null,
        groupingName: 'ORGANIZATION',
        description: 'Allows adding and removing users from the organization',
        level: 3,
        group: 0,
      },
    ],
  ];
};

export const configureWarehouseButtons = (
  privs: string[],
  groupName?: string,
  displayText = false,
) => {
  return [
    [
      {
        privilege: Privilege.ListDatabases,
        selected: some(
          privs,
          (priv: any) => priv.privilege === Privilege.ListDatabases,
        ),
        buttonText: displayText ? 'LIST' : null,
        groupingName: 'WAREHOUSE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ListDatabases &&
            priv.withGrant === true,
        ),
        description: 'Allows listing all of the databases in a warehouse.',
        level: 0,
        group: 0,
      },
      {
        privilege: Privilege.CreateDatabase,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.CreateDatabase,
        ),
        buttonText: displayText ? 'CREATE' : null,
        groupingName: 'WAREHOUSE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.CreateDatabase &&
            priv.withGrant === true,
        ),
        description: 'Allows creating a database in the warehouse.',
        level: 1,
        group: 0,
      },
      {
        privilege: Privilege.ModifyWarehouse,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ModifyWarehouse,
        ),
        buttonText: displayText ? 'MODIFY' : null,
        groupingName: 'WAREHOUSE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ModifyWarehouse &&
            priv.withGrant === true,
        ),
        description: 'Allows dropping or renaming the warehouse.',
        level: 2,
        group: 0,
      },
      {
        privilege: Privilege.ManageGrants,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ManageGrants,
        ),
        buttonText: displayText ? 'ADMIN' : null,
        groupingName: 'WAREHOUSE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ManageGrants &&
            priv.withGrant === true,
        ),
        description:
          'Allows setting the privilege levels for other roles on the warehouse, future databases, and tables.',
        group: 0,
      },
    ],
    [
      {
        privilege: Privilege.FutureListTables,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureListTables,
        ),
        buttonText: displayText ? 'LIST' : null,
        groupingName: 'FUTURE DATABASES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureListTables &&
            priv.withGrant === true,
        ),
        description: 'Allows listing all of the tables in a database.',
        level: 0,
        group: 1,
      },
      {
        privilege: Privilege.FutureCreateTable,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureCreateTable,
        ),
        buttonText: displayText ? 'CREATE' : null,
        groupingName: 'FUTURE DATABASES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureCreateTable &&
            priv.withGrant === true,
        ),
        description: 'Allows creating a table in the database.',
        level: 1,
        group: 1,
      },
      {
        privilege: Privilege.FutureModifyDatabase,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureModifyDatabase,
        ),
        buttonText: displayText ? 'MODIFY' : null,
        groupingName: 'FUTURE DATABASES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureModifyDatabase &&
            priv.withGrant === true,
        ),
        description: 'Allows dropping or renaming the database.',
        level: 2,
        group: 1,
      },
      {
        privilege: Privilege.FutureManageGrantsDatabase,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureManageGrantsDatabase,
        ),
        buttonText: displayText ? 'ADMIN' : null,
        groupingName: 'FUTURE DATABASES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureManageGrantsDatabase &&
            priv.withGrant === true,
        ),
        description:
          'Allows setting the privilege levels for other roles on the database, including the default privileges on future tables.',
        group: 1,
      },
    ],
    [
      {
        privilege: Privilege.FutureSelect,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureSelect,
        ),
        buttonText: displayText ? 'SELECT' : null,
        groupingName: 'FUTURE TABLES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureSelect &&
            priv.withGrant === true,
        ),
        description:
          'Allows reading from any column in the table, including columns in all active table snapshots.',
        level: 0,
        group: 2,
      },
      {
        privilege: Privilege.FutureUpdate,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureUpdate,
        ),
        buttonText: displayText ? 'UPDATE' : null,
        groupingName: 'FUTURE TABLES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureUpdate &&
            priv.withGrant === true,
        ),
        description:
          'Allows inserting, updating, and deleting any data in the table.',
        level: 1,
        group: 2,
      },
      {
        privilege: Privilege.FutureDropTable,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureDropTable,
        ),
        buttonText: displayText ? 'DROP' : null,
        groupingName: 'FUTURE TABLES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureDropTable &&
            priv.withGrant === true,
        ),
        description:
          'Allows dropping, renaming, or moving the table to another database.',
        level: 2,
        group: 2,
      },
      {
        privilege: Privilege.FutureManageGrantsTable,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureManageGrantsTable,
        ),
        buttonText: displayText ? 'ADMIN' : null,
        groupingName: 'FUTURE TABLES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureManageGrantsTable &&
            priv.withGrant === true,
        ),
        description:
          'Allows setting the privilege levels for other roles on this table.',
        group: 2,
      },
    ],
  ];
};

export const configureDatabaseButtons = (
  privs: string[],
  groupName?: string,
  displayText = false,
) => {
  return [
    [
      {
        privilege: Privilege.ListTables,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ListTables,
        ),
        buttonText: displayText ? 'LIST' : null,
        groupingName: 'DATABASE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ListTables && priv.withGrant === true,
        ),
        description: 'Allows listing all of the tables in a database.',
        level: 0,
        group: 0,
      },
      {
        privilege: Privilege.CreateTable,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.CreateTable,
        ),
        buttonText: displayText ? 'CREATE' : null,
        groupingName: 'DATABASE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.CreateTable && priv.withGrant === true,
        ),
        description: 'Allows creating a table in the database.',
        level: 1,
        group: 0,
      },
      {
        privilege: Privilege.ModifyDatabase,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ModifyDatabase,
        ),
        buttonText: displayText ? 'MODIFY' : null,
        groupingName: 'DATABASE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ModifyDatabase &&
            priv.withGrant === true,
        ),
        description: 'Allows dropping or renaming the database.',
        level: 2,
        group: 0,
      },
      {
        privilege: Privilege.ManageGrants,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ManageGrants,
        ),
        buttonText: displayText ? 'ADMIN' : null,
        groupingName: 'WAREHOUSE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ManageGrants &&
            priv.withGrant === true,
        ),
        description:
          'Allows setting the privilege levels for other roles on the database, including the default privileges on future tables.',
        group: 0,
      },
    ],
    [
      {
        privilege: Privilege.FutureSelect,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureSelect,
        ),
        buttonText: displayText ? 'SELECT' : null,
        groupingName: 'FUTURE TABLES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureSelect &&
            priv.withGrant === true,
        ),
        description:
          'Allows reading from any column in the table, including columns in all active table snapshots.',
        level: 0,
        group: 1,
      },
      {
        privilege: Privilege.FutureUpdate,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureUpdate,
        ),
        buttonText: displayText ? 'UPDATE' : null,
        groupingName: 'FUTURE TABLES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureUpdate &&
            priv.withGrant === true,
        ),
        description:
          'Allows inserting, updating, and deleting any data in the table.',
        level: 1,
        group: 1,
      },
      {
        privilege: Privilege.FutureDropTable,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureDropTable,
        ),
        buttonText: displayText ? 'DROP' : null,
        groupingName: 'FUTURE TABLES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureDropTable &&
            priv.withGrant === true,
        ),
        description:
          'Allows dropping, renaming, or moving the table to another database.',
        level: 2,
        group: 1,
      },
      {
        privilege: Privilege.FutureManageGrantsTable,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureManageGrantsTable,
        ),
        buttonText: displayText ? 'ADMIN' : null,
        groupingName: 'FUTURE TABLES',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.FutureManageGrantsTable &&
            priv.withGrant === true,
        ),
        description:
          'Allows setting the privilege levels for other roles on this table.',
        group: 1,
      },
    ],
  ];
};

export const configureTableButtons = (
  privs: string[],
  groupName?: string,
  displayText = false,
) => {
  return [
    [
      {
        privilege: Privilege.Select,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.Select,
        ),
        buttonText: displayText ? 'SELECT' : null,
        groupingName: 'TABLE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.Select && priv.withGrant === true,
        ),
        description:
          'Allows reading from any column in the table, including columns in all active table snapshots.',
        level: 0,
        group: 0,
      },
      {
        privilege: Privilege.Update,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.Update,
        ),
        buttonText: displayText ? 'UPDATE' : null,
        groupingName: 'TABLE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.Update && priv.withGrant === true,
        ),
        description:
          'Allows inserting, updating, and deleting any data in the table.',
        level: 1,
        group: 0,
      },
      {
        privilege: Privilege.Drop,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.Drop,
        ),
        buttonText: displayText ? 'DROP' : null,
        groupingName: 'TABLE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.Drop && priv.withGrant === true,
        ),
        description:
          'Allows dropping, renaming, or moving the table to another database.',
        level: 2,
        group: 0,
      },
      {
        privilege: Privilege.ManageGrants,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ManageGrants,
        ),
        buttonText: displayText ? 'ADMIN' : null,
        groupingName: 'TABLE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ManageGrants &&
            priv.withGrant === true,
        ),
        description:
          'Allows setting the privilege levels for other roles on this table.',
        group: 0,
      },
    ],
  ];
};

export const configureLabelButtons = (
  privs: string[],
  groupName?: string,
  displayText = false,
) => {
  return [
    [
      {
        privilege: Privilege.Select,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.Select,
        ),
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.Select && priv.withGrant === true,
        ),
        buttonText: displayText ? 'SELECT' : null,
        groupingName: 'LABEL',
        level: 0,
        group: 0,
      },
      {
        privilege: Privilege.ApplyLabel,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ApplyLabel,
        ),
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ApplyLabel && priv.withGrant === true,
        ),
        buttonText: displayText ? 'APPLY' : null,
        //@ts-ignore
        groupingName: groupName,
        level: 0,
        group: 0,
      },
      {
        privilege: Privilege.ModifyLabel,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ModifyLabel,
        ),
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ModifyLabel && priv.withGrant === true,
        ),
        buttonText: displayText ? 'MODIFY' : null,
        //@ts-ignore
        groupingName: groupName,
        level: 1,
        group: 0,
      },
      {
        privilege: Privilege.ManageGrants,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            //@ts-ignore
            priv.privilege === Privilege.ManageGrants && priv.selected,
        ),
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ManageGrants &&
            priv.withGrant === true,
        ),
        buttonText: displayText ? 'ADMIN' : null,
        //@ts-ignore
        groupingName: groupName,
        level: 2,
        group: 0,
      },
    ],
  ];
};

export const configureStorageProfileButtons = (
  privs: string[],
  groupName?: string,
  displayText = false,
) => {
  return [
    [
      {
        privilege: Privilege.Usage,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.Usage,
        ),
        buttonText: displayText ? 'USAGE' : null,
        groupingName: 'STORAGE PROFILE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.Usage && priv.withGrant === true,
        ),
        description:
          'Allows for reading data from any path within a specified object store',
        level: 0,
        group: 0,
      },
      {
        privilege: Privilege.ManageGrants,
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ManageGrants,
        ),
        buttonText: displayText ? 'ADMIN' : null,
        groupingName: 'STORAGE PROFILE',
        withGrant: some(
          privs,
          (priv: RoleResourcePrivilegeType) =>
            priv.privilege === Privilege.ManageGrants &&
            priv.withGrant === true,
        ),
        description:
          'Allows setting the privilege levels for other roles on this storage profile.',
        group: 0,
      },
    ],
  ];
};

export const configureRoleButtons = (
  privs: string[],
  groupName?: string,
  displayText = false,
) => {
  return [
    [
      {
        selected: some(
          privs,
          (priv: RoleResourcePrivilegeType) => priv.privilege === 'MODIFY_ROLE',
        ),
        buttonText: displayText ? 'MODIFY ROLE' : null,
        groupingName: 'ROLE',
      },
    ],
  ];
};

export const ButtonConfigMapping = {
  ORGANIZATION: { config: configureOrganizationButtons },
  WAREHOUSE: { config: configureWarehouseButtons },
  DATABASE: { config: configureDatabaseButtons },
  TABLE: { config: configureTableButtons },
  VIEW: { config: configureTableButtons },
  LABEL: { config: configureLabelButtons },
  STORAGE_PROFILE: { config: configureStorageProfileButtons },
  ROLE: { config: configureRoleButtons },
};

export const configureButtonGroups = (
  privilege: any,
  type: any,
  groupName: string,
  displayText = false,
) => {
  //@ts-ignore
  return ButtonConfigMapping[type].config(privilege, groupName, displayText);
};
