import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  ListItem,
  ListItemText,
  Stack,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { some, sortBy } from 'lodash';

import { Maybe, RoleMember, Member } from '../../graphql/gen/graphql';
import { organizationMembersQuery } from '../../graphql/organization';
import { getLogger } from '../../utils/logging';
import { Tavatar } from '../Avatar/Tavatar';

const logger = getLogger(
  'components.Forms.QuickAddPerson' /*FUTURE import.meta.url ?*/,
);

export default function QuickAddPerson({
  organizationId,
  onPersonAdded,
  existingPeople,
  currentUserEmail,
}: {
  organizationId: string;
  onPersonAdded: (person: Member) => void;
  existingPeople: Maybe<RoleMember>[];
  currentUserEmail: string;
}) {
  const { data, loading } = useQuery(organizationMembersQuery, {
    variables: {
      organizationId,
    },
    context: {
      headers: {
        'Cache-Control': 'no-cache', //tell our graphql code not to ever cache
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError: (error) =>
      logger.error('problem querying members in quick add person', error),
  });

  const peopleOptions = useMemo(
    () => sortBy(data?.organization?.members, (member) => member.displayName),
    [data],
  );

  const CustomPopper = useCallback((props: any) => {
    const { className, anchorEl, style, ...rest } = props;
    return (
      <Box
        {...rest}
        sx={(theme) => ({
          '& .MuiPaper-root': {
            boxShadow: 'unset',
          },
          '& ::-webkit-scrollbar': {
            width: '4px',
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: 'midnight.half',
          },
          '& ::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: 'midnight.two',
          },
          borderTop: `1px solid ${theme.palette.midnight.two}`,
          zIndex: 9999,
          width: '250px',
          display: 'flex',
          justifyItems: 'flex-start',
        })}
      />
    );
  }, []);

  return (
    <Stack direction={'column'} alignItems={'center'} height={'300px'}>
      <Autocomplete
        id={`quick-add-person-combo-box`}
        open
        disableCloseOnSelect
        loading={loading}
        noOptionsText={`No people`}
        popupIcon={''}
        options={peopleOptions || []}
        isOptionEqualToValue={(option: any, value: any) =>
          option.id === value.id
        }
        PopperComponent={CustomPopper}
        getOptionLabel={(option) => option.user.email}
        getOptionDisabled={(option) =>
          some(existingPeople, (existing) => existing?.id === option?.user?.id)
        }
        filterOptions={(options, state) => {
          return options.filter(
            (option) =>
              option.displayName
                .toLowerCase()
                .trim()
                .includes(state.inputValue.toLowerCase().trim()) ||
              option.user.email
                .toLowerCase()
                .trim()
                .includes(state.inputValue.toLowerCase().trim()),
          );
        }}
        renderOption={(props: any, option: any) => {
          return (
            <ListItem
              {...props}
              style={{
                padding: '8px',
              }}
            >
              <Tavatar
                profilePic={option?.properties?.[`picture.url`]}
                displayName={option?.displayName}
                currentUser={currentUserEmail === option?.user?.email}
              />
              <ListItemText sx={{ wordBreak: 'break-word', pl: 2 }}>
                <Typography variant={`subtitle2`}>
                  {option?.displayName}
                </Typography>
                <Typography variant={`helperText`}>
                  {option.user.email}
                </Typography>
              </ListItemText>
            </ListItem>
          );
        }}
        onChange={(event, newValue, reason) => {
          if (reason !== 'clear' && newValue !== null) {
            //@ts-ignore
            onPersonAdded(newValue);
          }
        }}
        renderTags={() => null}
        ListboxProps={{
          style: {
            maxHeight: '250px',
            width: '245px',
          },
          role: 'list-box',
        }}
        renderInput={(params: any) => (
          <MuiTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              style: {
                width: '225px',
                borderRadius: 24,
                height: '30px',
                alignContent: 'center',
              },
              startAdornment: (
                <SearchIcon
                  fontSize={'small'}
                  sx={{ color: 'brandBlue.main' }}
                />
              ),
            }}
            placeholder="Search"
            margin="normal"
            onKeyDown={(event: any) => {
              if (event.key === 'Backspace') {
                event.stopPropagation();
              }
            }}
          />
        )}
      />
    </Stack>
  );
}
