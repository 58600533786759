import React, { useCallback, useEffect, useMemo } from 'react';

import {
  MutationHookOptions,
  MutationTuple,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  AddOutlined,
  CancelOutlined,
  Pause,
  SkipNextRounded,
} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, BoxProps, IconButton, Tooltip, Typography } from '@mui/material';
import { CANCELLED } from 'dns';

import { toDatabaseRoot } from '../../RouteTable';
import {
  AsyncState,
  Table,
  TableLoadState,
  TableLoadStatus,
} from '../../graphql/gen/graphql';
import {
  cancelTableLoadMutation,
  dropTable,
  fetchMostRecentStatusForTableQuery,
  fetchTableLoadStatusQuery,
  skipAutoLoadDetectionMutation,
} from '../../graphql/table';
import { getByteSize } from '../../pages/helpers';
import {
  formatDateToMonDayYear,
  formatTimestamp,
  getDifferenceInDays,
  relativeTimeAutoFormat,
} from '../../utils/time';
import { Tabutton } from '../Button/Tabutton';
import { DropdownMenuContent } from '../DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../DropdownMenu/DropdownMenuItem';
import { showConfirmationDialog } from '../Modals/ConfirmationDialogModal';
import RelativeTimeDisplay from '../RelativeTimeDisplay/RelativeTimeDisplay';
import { StatusPanel } from '../StatusBoard';
import StatusActionView from '../StatusBoard/StatusActionView';
import StatusBoard from '../StatusBoard/StatusBoard';
import { getTableLoadState, getTableLoadStatusMessage } from './helpers';

export interface TableStatusProps extends BoxProps {
  organizationId: string;
  tableName: string;
  recentStatusData: TableLoadStatus;
}

function isDateInFuture(dateString: string): boolean {
  return dateString
    ? new Date().getTime() < new Date(dateString).getTime()
    : false;
}
function isDateInPast(dateString: string): boolean {
  return dateString
    ? new Date().getTime() > new Date(dateString).getTime()
    : false;
}

export const TableStatus = ({
  organizationId,
  tableName,
  recentStatusData,
  ...props
}: TableStatusProps) => {
  //for now I am handling the polling inside this component instead of parent to avoid re-rendering entire parent.
  //letting the parent refresh the main recent-status query and pass that to me. this allows parent to cause me to refresh
  const [
    pollStatus,
    { data: pollData, loading: statusLoading, error: statusError, stopPolling },
  ] = useLazyQuery(fetchTableLoadStatusQuery);

  useEffect(() => {
    if (
      pollData?.fetchTableLoadStatus?.state === AsyncState.Complete ||
      pollData?.fetchTableLoadStatus?.state === AsyncState.Failed ||
      pollData?.fetchTableLoadStatus?.state === AsyncState.TimedOut ||
      (pollData?.fetchTableLoadStatus?.state === AsyncState.Cancelled &&
        pollData?.fetchTableLoadStatus?.loadTableState ===
          TableLoadState.Cancelled)
    ) {
      stopPolling();
    }
  }, [pollData]);

  const [cancelTableLoad] = useMutation(cancelTableLoadMutation, {
    variables: {},
  });

  const [skipAutoLoadDetection] = useMutation(skipAutoLoadDetectionMutation, {
    variables: {},
  });

  useEffect(() => {
    if (
      recentStatusData.state === AsyncState.InProgress ||
      recentStatusData.state === AsyncState.Created ||
      recentStatusData.state === AsyncState.Submitted ||
      (recentStatusData.state === AsyncState.Cancelled &&
        recentStatusData.loadTableState === TableLoadState.Cancelling)
    ) {
      pollStatus({
        variables: {
          organizationId: organizationId,
          tableLoadId: recentStatusData.tableLoadId,
        },
        pollInterval: 2000,
      });
    }
  }, [recentStatusData]);

  const statusData = pollData?.fetchTableLoadStatus ?? recentStatusData;

  const handleCancelAsyncTask = useCallback(() => {
    showConfirmationDialog({
      title: 'Do you want to stop the table load?',
    }).then(() => {
      cancelTableLoad({
        variables: {
          organizationId: organizationId,
          tableLoadId: statusData.tableLoadId,
        },
      });
    });
  }, [statusData]);

  const handleSkipAutoLoadDetection = useCallback(() => {
    showConfirmationDialog({
      title: 'Skip auto file load detection?',

      children: (
        <Typography variant={'body1'}>
          Only skip if you know files are not landing in your bucket path.
        </Typography>
      ),
    }).then(() => {
      skipAutoLoadDetection({
        variables: {
          organizationId: organizationId,
          tableLoadId: statusData.tableLoadId,
        },
      });
    });
  }, [statusData]);

  const statusPanels: StatusPanel[] | undefined = useMemo(() => {
    if (statusData) {
      let actions = undefined;
      let dashboardDropdownContent = undefined;
      let relevantDate = undefined;

      if (statusData.state === AsyncState.InProgress) {
        relevantDate =
          statusData.loadTableState === TableLoadState.PendingBackFill
            ? statusData.wakeAt
            : statusData.createdAt;
        actions = [
          {
            title: 'Cancel',
            icon: {
              icon: CancelOutlined,
              sx: { color: 'sunset.seven' },
            },
            iconButtonProps: {
              onClick: handleCancelAsyncTask,
            },
          },
        ];
        dashboardDropdownContent = (
          <DropdownMenuContent
            menuItems={[
              <DropdownMenuItem
                key={`cancelLoad`}
                showAsMenuButton
                titleText={`Cancel`}
                Icon={CancelOutlined}
                onClick={handleCancelAsyncTask}
              />,
            ]}
          />
        );
      } else {
        relevantDate = statusData.lastModified;
      }

      return [
        {
          sections: [
            {
              headerLabel: 'service',
              headerName:
                statusData.tableLoadMode === 'APPEND_AUTO_LOAD' ||
                statusData.tableLoadMode === 'REPLACE_AUTO_LOAD'
                  ? 'Bootstrap'
                  : 'Table loader',
              onHeaderClick: async () => {},
              state: getTableLoadState(statusData),
              progress: statusData.progress,
              progressType:
                statusData?.loadTableState == TableLoadState.PendingBackFill ||
                statusData?.loadTableState == TableLoadState.Init
                  ? 'indeterminate'
                  : 'determinate',
              statusMessages: [
                getTableLoadStatusMessage(
                  tableName,
                  statusData,
                  handleSkipAutoLoadDetection,
                ),
                `Loaded ${
                  statusData?.totalLoadedFileCount === undefined
                    ? '-'
                    : statusData?.totalLoadedFileCount
                }/${
                  statusData?.totalFileCount === undefined
                    ? '-'
                    : statusData?.totalFileCount
                } files and ${getByteSize(
                  statusData?.totalLoadedBytes,
                )}/${getByteSize(statusData?.totalBytes)}`,
                ...(!(
                  statusData.loadTableState ===
                    TableLoadState.PendingBackFill && isDateInPast(relevantDate)
                )
                  ? [
                      <RelativeTimeDisplay
                        key={'relevantDateString'}
                        dateString={relevantDate}
                        prefix={
                          statusData.loadTableState ===
                            TableLoadState.PendingBackFill &&
                          isDateInFuture(relevantDate)
                            ? 'backfill '
                            : ''
                        }
                        //turn off live update when runnning to avoid jiggling of the number since the polling also causes updates
                        liveUpdate={statusData?.state != AsyncState.InProgress}
                      />,
                    ]
                  : ['preparing backfill']),
              ],
              details: undefined,
              slots: {
                headerIcon: {
                  src: '/assets/icon/table.svg',
                },
              },
            },
          ],
          actions,
          dashboardDropdownContent,
        },
      ];
    } else {
      return undefined;
    }
  }, [statusData, handleCancelAsyncTask]);

  if (!statusPanels) {
    return null;
  }

  return <StatusBoard statusPanels={statusPanels} {...props} />;
};
