import React from 'react';

import { Chip, Popper } from '@mui/material';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { TableEvent } from '../../../graphql/gen/graphql';
import { calculateTextWidth } from '../../../pages/helpers';
import { NamePopover } from '../../Popovers/NamePopover';
import { TableEventTypes, TableEventTypesUtil } from '../TableEventsView';

type Props = {
  event: TableEvent;
  columnSize: number;
};
export default function TableEventChip({ event, columnSize, ...props }: Props) {
  const tableEventTypesUtil = new TableEventTypesUtil();

  const eventDisplayName = tableEventTypesUtil.displayName(
    event.operation as TableEventTypes,
  );
  const textWidth = calculateTextWidth(
    eventDisplayName,
    '11px Source Sans Pro',
    true,
  );
  return (
    <>
      <PopupState variant="popper">
        {(popupState) => (
          <>
            <Chip
              label={eventDisplayName}
              size="small"
              sx={{
                minWidth: 3 / 4,
                backgroundColor: 'neutral.main',
                color: '#fff',
                fontSize: '11px',
                fontWeight: 600,
                my: 1,
                mr: 1,
              }}
              {...props}
              {...bindHover(popupState)}
            />
            {textWidth >= columnSize && (
              <Popper
                {...bindPopper(popupState)}
                placement={`right`}
                modifiers={[
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: ['bottom'],
                    },
                  },
                ]}
              >
                <NamePopover
                  name={tableEventTypesUtil.displayName(
                    event.operation as TableEventTypes,
                  )}
                />
              </Popper>
            )}
          </>
        )}
      </PopupState>
    </>
  );
}
