import React, { useState } from 'react';

import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { unitOptions } from '../../utils/properties';
import TextField from '../Forms/TextField';

type ByteUnitSelectorPropTypes = {
  name: string;
  label: string;
  helpercomponentname: string;
  helpercomponentdefault: string;
  helpercomponentfieldname: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export default function ByteUnitSelector({
  name,
  label,
  helpercomponentname,
  helpercomponentdefault,
  helpercomponentfieldname,
  setFieldValue,
  ...props
}: ByteUnitSelectorPropTypes) {
  const [selectValue, setSelectValue] = useState(helpercomponentdefault);
  const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectValue(event.target.value);
    setFieldValue(helpercomponentfieldname, event.target.value);
  };

  return (
    <Box display={`flex`}>
      <Box mr={2}>
        <TextField id={name} name={name} label={label} {...props} />
      </Box>
      <Box>
        <TextField
          id={helpercomponentname}
          name={helpercomponentname}
          select
          value={selectValue}
          onChange={handleUnitChange}
          //@ts-ignore
          disabled={props.disabled}
        >
          {unitOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
}
