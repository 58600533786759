import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { sortBy } from 'lodash';

import { useTableEditContext } from './TableEditContext';

import { getLogger } from '../../utils/logging';
import {
  getColorForComplexType,
  GridTextField,
} from './SchemaEditGridComponents';
import { EditTableMode } from './TableEdit';
import {
  cleanSourceFieldValue,
  cleanTransformValue,
  getTransformsForSourceType,
  safeJSONParse,
  shouldBlockPropagation,
  simulateMouseClick,
} from './TableEditHelpers';

const logger = getLogger(
  'components.TableEdit.TransformSelector' /*FUTURE import.meta.url ?*/,
);

export function TransformSelectionView({
  id,
  value,
  field,
  tabIndex,
}: GridRenderCellParams) {
  if (value === undefined || value === '') {
    return (
      <GridTextField label={'Select transform'} required error></GridTextField>
    );
  }
  let jsonValueObject: any;
  const { mode } = useTableEditContext();

  jsonValueObject = safeJSONParse(value, {});

  let isError =
    !jsonValueObject.value ||
    ((jsonValueObject.value === 'bucket' ||
      jsonValueObject.value === 'truncate') &&
      (jsonValueObject.param1 === undefined ||
        jsonValueObject.param1 == '' ||
        Number(jsonValueObject.param1) < 0));

  return (
    <FormControl>
      <GridTextField
        className="icebergTypeLabel"
        sx={(theme) => ({
          pl: 2,
          pr: 2,
          width: 1,
          border: `1px solid ${theme.palette.midnight.two}`,
          borderRadius: '4px',
          backgroundColor: getColorForComplexType(jsonValueObject?.type, mode),
        })}
        InputProps={{
          ...(mode !== EditTableMode.VIEW
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                ),
              }
            : {}),
          sx: { pl: 2, pr: 0, pt: 0.5, pb: 0.5 },
        }}
        required
        error={isError}
        // tabIndex={tabIndex}
        value={`${jsonValueObject?.name}${
          jsonValueObject.value === 'bucket' ||
          jsonValueObject.value === 'truncate'
            ? '[' + jsonValueObject.param1 + ']'
            : ''
        }`}
      />
    </FormControl>
  );
}

export interface TransformValue {
  name: string;
  value?: string;
  description: string;
  param1?: number;
  sort: number;
}

export interface TransformSelectionEditProps extends GridRenderEditCellParams {
  usedTransforms: string[];
}

const SCHEMA_TYPE_VAL_NONE = 'none';

export function TransformSelectionEdit({
  id,
  value,
  field,
  row,
  level,
  hasFocus,
  tabIndex,
  usedTransforms,
  ...rest
}: TransformSelectionEditProps) {
  const apiRef = useGridApiContext();
  const { schemaModel } = useTableEditContext();
  const previousValue = apiRef.current.getCellValue(id, field);

  const getSourceValue = () => {
    const previousSourceValue = apiRef.current.getCellValue(
      id,
      'schemaSourceField',
    );
    const sourceColumnValue = row.schemaSourceField;
    const source = cleanSourceFieldValue(
      sourceColumnValue,
      previousSourceValue,
      schemaModel,
    );
    return source;
  };
  const getValidTransformsForField = (): TransformValue[] => {
    const source = getSourceValue();

    const transformValues = getTransformsForSourceType(source?.valueObj?.type);

    return sortBy(
      transformValues.filter(
        (eachPotentialTransform) =>
          !usedTransforms.includes(eachPotentialTransform.value ?? ''),
      ),
      (item) => item.sort + item.name,
    );
  };

  const cleanValue = useCallback(() => {
    return cleanTransformValue(value, previousValue, () => {
      const source = getSourceValue();

      const transformValues = getTransformsForSourceType(
        source?.valueObj?.type,
      );
      return transformValues;
    });
  }, [value, previousValue]);

  const inVal = cleanValue();
  useEffect(() => {
    if (value != inVal.value) {
      apiRef.current.setEditCellValue({ id, field, value: inVal.value });
    }
  }, [value, inVal]);

  const [currentVal, setCurrentVal] = useState<string>(inVal.value);
  const [currentValObj, setCurrentValObj] = useState<any>(inVal.valueObj);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectOpen, setSelectOpen] = React.useState(hasFocus);
  const [lastSelectCloseTs, setLastSelectCloseTs] = React.useState(0);

  const handlePopoverOpen = (event: React.SyntheticEvent<HTMLElement>) => {
    if (selectOpen) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const setValue = async (newValue: string) => {
    let valueObj;

    valueObj = newValue === undefined ? undefined : safeJSONParse(newValue, {});

    setCurrentVal(newValue);
    setCurrentValObj(valueObj);
    console.debug(
      `Setting cell value for row ${id} and field ${field} with value ->${newValue}<-`,
    );
    await apiRef.current.setEditCellValue({ id, field, value: newValue });
    return valueObj;
  };

  const setParamValue = async (paramName: string, newValue: string) => {
    const newValueObjString = JSON.stringify({
      ...currentValObj,
      [paramName]: newValue,
    });
    await setValue(newValueObjString);
  };

  const handleValueChange = async (
    event: SelectChangeEvent<string>,
    child?: ReactNode,
  ) => {
    const valueObj = await setValue(event.target.value);

    logger.debug('select changed, closing');
    setSelectOpen(false);
  };
  const menuItems = getValidTransformsForField().map(
    (partitionTransformValue) => [
      [
        // @ts-ignore
        <MenuItem
          id={'candidate_' + id + '_' + partitionTransformValue.value}
          key={'candidateMenu_' + id + '_' + partitionTransformValue.value}
          value={`${JSON.stringify({
            value: partitionTransformValue.value,
            name: partitionTransformValue.name,
          })}`}
          aria-owns={open ? 'candidate_' + id + '_popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          onFocus={(event) => {
            handlePopoverOpen(event);
          }}
          onBlur={(event) => {
            handlePopoverClose();
          }}
          onKeyDown={(event) => {
            logger.debug(
              `menuitem ${event.currentTarget.id} got keydown ${event.key}`,
            );
            //simulate a click if they hit space bar on the menu item.
            if (event.key === ' ') {
              simulateMouseClick(event.currentTarget);
            }
          }}
        >
          <Box>
            <ListItemText>{partitionTransformValue.name}</ListItemText>
          </Box>
        </MenuItem>,
        <Popover
          tabIndex={-1}
          key={
            'candidate_' + id + '_' + partitionTransformValue.value + '_popover'
          }
          id={
            'candidate_' + id + '_' + partitionTransformValue.value + '_popover'
          }
          sx={{
            pointerEvents: 'none',
          }}
          open={
            open &&
            anchorEl?.id ==
              'candidate_' + id + '_' + partitionTransformValue.value
          }
          anchorEl={anchorEl?.style.display != 'none' ? anchorEl : undefined}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableAutoFocus //🙏 this stops the loss of focus and headaches
          disableEnforceFocus //🙏 this stops the loss of focus and headaches
          disableRestoreFocus
        >
          <Box sx={{ maxWidth: 500 }}>
            <Typography sx={{ p: 1 }}>
              {`${partitionTransformValue.description}`}
            </Typography>
          </Box>
        </Popover>,
      ],
    ],
  );

  // @ts-ignore
  return (
    <Box
      tabIndex={-1}
      sx={{ display: 'flex', width: 1 }}
      onKeyDown={(event) => {
        logger.debug(`in box control ${event.key} !`);
      }}
    >
      {/*There is a weird quirk / bug in the mui select here.  If I get rid of the flexGrow:1 or width:1, I can tab
      once into the select... otherwise it is twice!  maybe is the react strict mode issue local?*/}
      <FormControl
        variant="standard"
        sx={{
          flexGrow: 1,
          '& .MuiInputBase-formControl': {
            borderRadius: '5px',
            border: 1,
            borderColor: 'midnight.two',
          },
        }}
        onKeyDown={(event) => {
          logger.debug(`in form control ${event.key} !`);
          //if we are a complex control with a param, we need to block tab so the datagrid doesnt skip it if this column is on the end
          if (
            shouldBlockPropagation(event) ||
            currentValObj.value === 'bucket' ||
            currentValObj.value === 'truncate'
          ) {
            event.stopPropagation();
          }
        }}
      >
        <Select
          sx={{
            ...(!currentVal || currentVal === SCHEMA_TYPE_VAL_NONE
              ? { color: 'midnight.four' }
              : {}),
          }}
          autoFocus={hasFocus}
          disableUnderline
          onBlur={() => {
            setSelectOpen(false);
          }}
          onFocus={(e) => {
            if (e.currentTarget === e.target) {
              logger.debug('focused self');
            } else {
              logger.debug('focused child', e.target);
            }
            if (!e.currentTarget.contains(e.relatedTarget)) {
              // Not triggered when swapping focus between children
              logger.debug('focus entered self');
              if (e.timeStamp - lastSelectCloseTs > 1000) {
                if (e.relatedTarget) {
                  setSelectOpen(true);
                }
              }
            }
          }}
          inputProps={{
            sx: { pt: 1 / 2, pb: 1 / 2, pl: 2 },
          }}
          onChange={handleValueChange}
          onPointerDown={() => {
            logger.debug(`pointer down`);
            apiRef.current.setCellFocus(id, field);
            setSelectOpen(!selectOpen);
          }}
          open={selectOpen}
          onOpen={() => {
            logger.debug('On open!');
          }}
          onClose={(event) => {
            logger.debug('On close!');
            setSelectOpen(false);
            setLastSelectCloseTs(event.timeStamp);
            setAnchorEl(null);
          }}
          onKeyDown={(event) => {
            logger.debug(`in transform selector ${event.key} !`);
          }}
          value={`${
            currentVal
              ? currentValObj?.value
                ? JSON.stringify({
                    value: currentValObj?.value,
                    name: currentValObj?.name,
                  })
                : currentVal
              : SCHEMA_TYPE_VAL_NONE
          }`}
          placeholder={'Select transform'}
        >
          <MenuItem
            disabled
            value={SCHEMA_TYPE_VAL_NONE}
            key={'partitionEditMenu_0' + id}
          >
            Select transform
          </MenuItem>
          {menuItems}
        </Select>
      </FormControl>
      {(currentValObj?.value === 'bucket' ||
        currentValObj?.value === 'truncate') && (
        <FormControl
          sx={{
            width: 50,
            alignSelf: 'center',
            '& .MuiInputBase-root': { minWidth: 50 },
            '& input[type=number]': {
              '-moz-appearance': 'textfield',
            },
            '& input[type=number]::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            '& input[type=number]::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            ml: 1,
          }}
          onKeyDown={(event) => {
            logger.debug(`in form control onkeydown '${event.key}' !`);
            if (shouldBlockPropagation(event)) {
              event.stopPropagation();
            }
          }}
        >
          <TextField
            value={currentValObj?.param1 ? currentValObj.param1 : ''}
            variant="standard"
            fullWidth
            id="param1"
            placeholder={currentValObj?.value === 'bucket' ? 'N' : 'W'}
            title={currentValObj?.value === 'bucket' ? 'Hash % N' : 'Width'}
            type="number"
            required
            error={
              currentValObj?.param1 === undefined ||
              currentValObj?.param1 === ''
            }
            onChange={async (event) => {
              await setParamValue('param1', event.target.value);
            }}
          ></TextField>
        </FormControl>
      )}
    </Box>
  );
}
