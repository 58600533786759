import {
  AsyncState,
  AuthDecisionResourceType,
} from '../../graphql/gen/graphql';

const roleDescriptions = [
  { role: 'EVERYONE', description: 'Default role for all users and roles' },
  {
    role: 'SECURITY_ADMIN',
    description:
      'Manage org users, modify all roles, manage org grants, create org roles',
  },
  {
    role: 'ORG_ADMIN',
    description: 'Modify org, manage billing, modify security admin role',
  },
];

export const getRoleDescription = (roleName: string) => {
  // @ts-ignore
  return roleDescriptions.find((role) => role.role === roleName)?.description;
};

export const getCascadeDescription = (type: string) => {
  if (type === AuthDecisionResourceType.Warehouse) {
    return 'Cascade futures to current databases, tables, and views';
  } else {
    return 'Cascade future table access to current tables and views';
  }
};

export const getCascadeRevokeDescription = (type: string) => {
  return `Cascade revoke to all current ${
    type === AuthDecisionResourceType.Warehouse ? 'databases ' : ''
  } tables and views `;
};

export const getCascadeFeedback = (
  taskStatus: any,
  roleName: string,
  success: boolean,
) => {
  const failMessage = `Cascade privileges ${
    taskStatus.status === AsyncState.Failed ? 'failed' : 'timed out'
  } for the ${roleName} role.`;

  const successMessage = `${roleName} privileges have been updated.`;

  return `${success ? successMessage : failMessage} ${
    taskStatus.databasesUpdatedCount
  } 
              ${
                taskStatus.databasesUpdatedCount === 1
                  ? 'database was'
                  : 'databases were'
              } updated, ${taskStatus.databasesSkippedCount} ${
    taskStatus.databasesSkippedCount === 1 ? 'database was' : 'databases were'
  } skipped.
              
              ${taskStatus.tablesUpdatedCount} ${
    taskStatus.tablesUpdatedCount === 1 ? 'table was' : 'tables were'
  } updated, ${taskStatus.tablesSkippedCount} ${
    taskStatus.tablesSkippedCount === 1 ? 'table was' : 'tables were'
  } skipped.`;
};
