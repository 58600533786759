import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import {
  AddOutlined,
  DriveFileRenameOutlineOutlined,
  RefreshOutlined,
} from '@mui/icons-material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import RestoreIcon from '@mui/icons-material/Restore';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Box, Popper, Typography, Link } from '@mui/material';
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { sumBy } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { MainTabGroup } from '../../context/GlobalPropsContext';

import {
  toDatabaseRoot,
  toDroppedTables,
  toOrgRoot,
  toWarehouseRoot,
} from '../../RouteTable';
import { WarehouseAccessControl } from '../../components/AccessControl/WarehouseAccessControl';
import { getImgSrcForStorageType } from '../../components/CreateStorageBucket/ValidationStatusHelpers';
import { CreateOrgCredentialDialog } from '../../components/Credentials/Dialogs/CreateOrgCredentialDialog';
import DataGridDashboard from '../../components/DataGridDashboard/DataGridDashboard';
import { DropdownMenuContent } from '../../components/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenuItem';
import NotFound from '../../components/Errors/NotFound';
import TabLayout from '../../components/Layouts/TabLayout';
import { ListDatabases } from '../../components/ListDatabases/ListDatabases';
import { showCreateDatabaseDialog } from '../../components/Modals/CreateDatabaseDialog';
import { showRenameWarehouseDialog } from '../../components/Modals/RenameWarehouseDialog';
import { showSelectWizardDialog } from '../../components/Modals/SelectWizardDialog';
import { showTypeDeleteDialog } from '../../components/Modals/TypeDeleteDialog';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { CommitsPopover } from '../../components/Popovers/CommitsPopover';
import { CompactionResultsPopover } from '../../components/Popovers/CompactionResultsPopover';
import { LastUpdateModifiedByPopover } from '../../components/Popovers/LastUpdateModifiedByPopover';
import { NamePopover } from '../../components/Popovers/NamePopover';
import { TrendPopover } from '../../components/Popovers/TrendPopover';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import { WarehouseSettings } from '../../components/WarehouseSettings/WarehouseSettings';
import { getDangerZoneItems } from '../../components/WarehouseSettings/helpers';
import { useAuth } from '../../context/auth-context';
import { Database } from '../../graphql/gen/graphql';
import { getOrgStorageProfiles } from '../../graphql/organization';
import {
  deleteWarehouse,
  warehouseById,
  WarehouseDashboardCardsQuery,
} from '../../graphql/warehouse';
import { compactNumberFormatter } from '../../utils/numbers';
import { relativeTimeAutoFormat } from '../../utils/time';
import {
  calculateTextWidth,
  commitComparator,
  databaseSizePercentComparator,
  getByteSize,
  getFileSize,
  getCommitFrequency,
  getLastModifiedInfo,
  getSizeDifference,
  getSizeDifferencePercentage,
  getTotalSizePercentage,
  lastUpdateComparator,
  sizeComparator,
  STATISTICS_DAYS_BACK,
  HeaderDropdown,
} from '../helpers';

export default function Warehouse() {
  const { user } = useAuth();
  const { organization: organizationNameParam, warehouse: warehouseNameParam } =
    useParams();
  const organizationName = organizationNameParam || '';
  const warehouseName = warehouseNameParam || '';
  const navigate = useNavigate();
  const location = useLocation();
  const [launchCreateDbModal, setLaunchCreateDbModal] = useState(
    location?.state ? location.state?.launchCreateDbModal : false,
  );
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const organizationId = user.getOrganization(organizationName)?.id;
  const warehouseId = user.getWarehouse(organizationName, warehouseName)?.id;
  const {
    data,
    loading,
    error,
    refetch: refetchWarehouse,
  } = useQuery(warehouseById, {
    skip: !warehouseId,
    variables: {
      organizationId,
      warehouseId,
      daysBack: STATISTICS_DAYS_BACK,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });
  const { data: dashboardData, refetch: refetchStats } = useQuery(
    WarehouseDashboardCardsQuery,
    {
      skip: !warehouseId,
      variables: {
        organizationId,
        warehouseId,
        daysBack: STATISTICS_DAYS_BACK,
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const [mutationError, setMutationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dropWarehouse] = useMutation(deleteWarehouse, {
    variables: {
      warehouseId: data?.organization?.warehouse?.id,
    },
    refetchQueries: [
      {
        query: getOrgStorageProfiles,
        variables: { organizationId },
      },
    ],
    onError: () => {
      setErrorMessage(
        'Something went wrong, please refresh the page and try again.',
      );
      setMutationError(true);
    },
    onCompleted: () =>
      navigate(toOrgRoot(data?.organization?.name!), {
        state: {
          snackbar: true,
          snackbarMessage: `${data?.organization?.warehouse.name} successfully dropped.`,
        },
      }),
  });

  const warehouseDisplayName = useMemo(
    () =>
      user.getWarehouse(organizationName, warehouseName)?.name || warehouseName,
    [user, organizationName, warehouseName],
  );
  const databases: Database[] = useMemo(
    () => data?.organization?.warehouse?.databases,
    [data],
  );
  const canCreateDatabase: boolean = useMemo(
    () => user.hasPrivilege('CREATE_DATABASE', data?.organization?.warehouse),
    [user, data],
  );
  const userCanDrop = useMemo(
    () => user.hasPrivilege('MODIFY_WAREHOUSE', data?.organization?.warehouse),
    [user, data],
  );
  const dashboardStatistics = useMemo(
    () => dashboardData?.organization?.warehouse?.statistics,
    [dashboardData],
  );
  const dashboardCompaction = useMemo(
    () => dashboardData?.organization?.warehouse?.compactionSummary,
    [dashboardData],
  );

  const doDropWarehouse = async () => {
    await dropWarehouse();
  };

  const handleDropWarehouse = useCallback(() => {
    if (userCanDrop) {
      showTypeDeleteDialog({
        ...getDangerZoneItems(warehouseName, warehouseId)[1].modalContent,
      }).then((doit) => {
        doDropWarehouse();
      });
    }
  }, [userCanDrop, warehouseName, warehouseId, doDropWarehouse]);

  const handleDangerZoneMutations = (
    mutationName: string,
    newName?: string,
  ) => {
    if (mutationName === 'dropWarehouse') {
      doDropWarehouse();
    }
    if (mutationName === 'updateWarehouse' && newName) {
      navigate(toWarehouseRoot(organizationName, newName));
    }
  };

  const handleRefetch = () => {
    refetchWarehouse({
      //@ts-ignore
      noCache: true,
    }).then(() => refetchStats());
  };

  const handleCreateDatabase = () => {
    return showCreateDatabaseDialog(
      user.loginSession.loggedInOrg.id,
      user.getWarehouse(organizationName, warehouseName).id,
      (database) =>
        database?.name &&
        navigate(
          toDatabaseRoot(organizationName, warehouseName, database?.name),
        ),
    );
  };

  const handleRenameWarehouse = useCallback(
    () =>
      showRenameWarehouseDialog({
        ...getDangerZoneItems(warehouseName, warehouseId)[0].modalContent,
      }).then((response) => {
        navigate(toWarehouseRoot(organizationName, response.newName));
      }),
    [warehouseId, warehouseName],
  );

  useEffect(() => {
    if (launchCreateDbModal) {
      handleCreateDatabase();
    }
  }, [launchCreateDbModal]);

  const breadcrumbs = useMemo(
    () => [
      {
        href: toWarehouseRoot(organizationName, warehouseName),
        text: warehouseDisplayName,
        dropdown: {
          type: 'WAREHOUSE',
          variables: {
            organizationId: user.getOrganization(organizationName)?.id,
          },
          current: user.getWarehouse(organizationName, warehouseName)?.name,
        },
      },
    ],
    [user, organizationName, warehouseName],
  );

  const rows = useMemo(() => {
    const allDatabasesSize = sumBy(
      databases,
      (db) => db.statistics?.totalBytes,
    );

    return databases?.map((db) => {
      return {
        id: db.name,
        name: db.name,
        hasAccess:
          user.isSecurityAdmin(organizationName) ||
          user.hasPrivilege('LIST_TABLES', db),
        lastModified: getLastModifiedInfo(db.statistics, db).lastModifiedDate,
        lastModifiedBy: getLastModifiedInfo(db.statistics, db).lastModifiedBy,
        totalTables: db.statistics?.totalTables,
        // the below object params will not work with filtering,
        // to enable filtering the params will need to be converted to single variables
        commits: {
          commitCnt: db.statistics?.commitCnt,
          opAppendCnt: db.statistics?.opAppendCnt,
          opOverwriteCnt: db.statistics?.opOverwriteCnt,
          opDeleteCnt: db.statistics?.opDeleteCnt,
          opReplaceCnt: db.statistics?.opReplaceCnt,
          createdAt: db.createdAt,
        },
        size: {
          bytesAdded: db.statistics?.bytesAdded,
          bytesRemoved: db.statistics?.bytesRemoved,
          totalBytes: db.statistics?.totalBytes,
        },
        totalSize: {
          allDatabasesSize: allDatabasesSize,
          totalBytes: db.statistics?.totalBytes,
        },
      };
    });
  }, [databases]);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Database',
        flex: 2,
        minWidth: 200,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
          );
          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: '1 1 100%',
                      alignItems: 'center',
                      height: '100%',
                      minWidth: 0,
                      cursor:
                        textWidth >= params.colDef.computedWidth
                          ? 'pointer'
                          : 'auto',
                    }}
                  >
                    {params.row.hasAccess ? (
                      <Link
                        href={toDatabaseRoot(
                          organizationName,
                          warehouseName,
                          params.value,
                        )}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {params.value}
                      </Link>
                    ) : (
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {params.value}
                      </Typography>
                    )}
                  </Box>
                  {textWidth >= params.colDef.computedWidth && (
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <NamePopover name={params.value} />
                    </Popper>
                  )}
                </>
              )}
            </PopupState>
          );
        },
      },
      {
        field: 'lastModified',
        headerName: 'Last update',
        flex: 1,
        minWidth: 150,
        editable: false,
        type: 'dateTime',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: lastUpdateComparator,
        renderCell: (params: GridRenderCellParams) => (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flex: '1 1 100%',
                    alignItems: 'center',
                    height: '100%',
                    cursor: 'default',
                  }}
                >
                  <Typography variant={`inputText`} {...bindHover(popupState)}>
                    {params.value ? relativeTimeAutoFormat(params.value) : '--'}
                  </Typography>
                </Box>
                <Popper
                  {...bindPopper(popupState)}
                  placement={`right-start`}
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom'],
                      },
                    },
                  ]}
                >
                  <LastUpdateModifiedByPopover
                    modifiedBy={params.row.lastModifiedBy}
                    modifiedDate={params.value}
                  />
                </Popper>
              </>
            )}
          </PopupState>
        ),
      },
      {
        field: 'commits',
        headerName: 'Commit frequency',
        flex: 1,
        minWidth: 150,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: commitComparator,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value.commitCnt ? (
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '1 1 100%',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-end',
                        cursor: 'default',
                      }}
                    >
                      <Typography
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {getCommitFrequency(
                          params.value.commitCnt,
                          params.value.createdAt,
                        )}
                      </Typography>
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <CommitsPopover
                        commitsData={[
                          { label: 'Append', count: params.value.opAppendCnt },
                          {
                            label: 'Replace',
                            count: params.value.opReplaceCnt,
                          },
                          {
                            label: 'Overwrite',
                            count: params.value.opOverwriteCnt,
                          },
                          { label: 'Delete', count: params.value.opDeleteCnt },
                        ]}
                      />
                    </Popper>
                  </>
                )}
              </PopupState>
            ) : (
              '--'
            )}
          </>
        ),
      },
      {
        field: 'totalTables',
        headerName: 'Tables',
        minWidth: 80,
        flex: 1,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value != 0 ? params.value : '--';
        },
      },
      {
        field: 'size',
        headerName: 'Size',
        minWidth: 100,
        flex: 1,
        editable: false,
        type: 'number',
        sortComparator: sizeComparator,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value?.totalBytes ? (
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '1 1 100%',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        height: '100%',
                        cursor: 'default',
                      }}
                    >
                      <Typography
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {getByteSize(params.value.totalBytes)}
                      </Typography>
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <TrendPopover
                        added={getByteSize(params.value?.bytesAdded)}
                        removed={getByteSize(params.value?.bytesRemoved)}
                        total={getSizeDifference(
                          params.value?.bytesAdded,
                          params.value?.bytesRemoved,
                          'bytes',
                        )}
                        icon={
                          getSizeDifferencePercentage(
                            params.value?.bytesAdded,
                            params.value?.bytesRemoved,
                            params.value.totalBytes,
                          ).icon
                        }
                        diff={
                          getSizeDifferencePercentage(
                            params.value?.bytesAdded,
                            params.value?.bytesRemoved,
                            params.value.totalBytes,
                          ).value
                        }
                      />
                    </Popper>
                  </>
                )}
              </PopupState>
            ) : (
              '--'
            )}
          </>
        ),
      },
      {
        field: 'totalSize',
        headerName: 'Total size %',
        minWidth: 200,
        flex: 2,
        editable: false,
        type: 'number',
        align: 'center',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: databaseSizePercentComparator,
        renderCell: (params: GridRenderCellParams) =>
          params.value.totalBytes ? (
            <ProgressBar
              value={
                getTotalSizePercentage(
                  params.value.totalBytes,
                  params.value.allDatabasesSize,
                ).value
              }
              display={
                getTotalSizePercentage(
                  params.value.totalBytes,
                  params.value.allDatabasesSize,
                ).display
              }
            />
          ) : (
            '--'
          ),
      },
    ],
    [organizationName, warehouseName],
  );

  const dashboardDropdownContent = useMemo(
    () => (
      <DropdownMenuContent
        menuItems={[
          <DropdownMenuItem
            key={'refresh'}
            showAsMenuButton
            titleText={'Refresh'}
            Icon={RefreshOutlined}
            onClick={() => {
              handleRefetch();
            }}
          />,

          <>
            <DropdownMenuItem
              key={'createDatabase'}
              showAsMenuButton
              titleText={'Create database'}
              Icon={AddOutlined}
              onClick={() => {
                handleCreateDatabase();
              }}
              disabled={!canCreateDatabase}
            />
          </>,
          <DropdownMenuItem
            key={'droppedTables'}
            showAsMenuButton
            titleText={'Restore Tables'}
            Icon={RestoreIcon}
            onClick={() => {
              navigate(toDroppedTables(organizationName), {
                state: { searchTerm: warehouseName },
              });
            }}
          />,

          <DropdownMenuItem
            key={'createCredential'}
            titleText={'Manually create a credential'}
            description={
              'Once you have a valid credential, you can configure various data compute engines.'
            }
            Icon={VpnKeyIcon}
            onClick={() => {
              setCreateDialogVisible(true);
            }}
          />,
          <DropdownMenuItem
            key={'connectCompute'}
            titleText={'Connect to compute'}
            description={
              'Connect to Spark, Trino, Starburst, Athena, and more!'
            }
            Icon={AutoFixHighIcon}
            onClick={() => {
              showSelectWizardDialog(
                [data?.organization?.warehouse] || [],
                refetchWarehouse,
              ).then((res) => {});
            }}
          />,

          <DropdownMenuItem
            key={'renameWarehouse'}
            showAsMenuButton
            titleText={'Rename warehouse'}
            Icon={DriveFileRenameOutlineOutlined}
            onClick={handleRenameWarehouse}
            disabled={!userCanDrop}
          />,

          <DropdownMenuItem
            key={'dropWarehouse'}
            showAsMenuButton
            titleText={'Drop warehouse'}
            disabled={!userCanDrop}
            onClick={handleDropWarehouse}
            dangerZone
          />,
        ]}
      />
    ),
    [
      data,
      warehouseId,
      organizationName,
      warehouseName,
      canCreateDatabase,
      handleDropWarehouse,
    ],
  );

  const dashboardItems = useMemo(
    () => [
      {
        items: [
          {
            title: 'Databases',
            data: (
              <Typography variant={`subtitle1`}>
                {dashboardStatistics?.totalNamespaces
                  ? compactNumberFormatter.format(
                      dashboardStatistics.totalNamespaces,
                    )
                  : '--'}
              </Typography>
            ),
          },
          {
            title: 'Tables',
            data: (
              <Typography variant={`subtitle1`}>
                {dashboardStatistics?.totalTables
                  ? compactNumberFormatter.format(
                      dashboardStatistics.totalTables,
                    )
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: 'Warehouse Size',
            data: (
              <Typography variant={`subtitle1`}>
                {getByteSize(dashboardStatistics?.totalBytes) || '--'}
              </Typography>
            ),
          },
          {
            title: `Last ${STATISTICS_DAYS_BACK} Days`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {dashboardStatistics?.totalBytes
                    ? getSizeDifferencePercentage(
                        dashboardStatistics?.bytesAdded,
                        dashboardStatistics?.bytesRemoved,
                        dashboardStatistics.totalBytes,
                      ).icon
                    : ''}
                  {dashboardStatistics?.totalBytes ? (
                    <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                      {
                        getSizeDifferencePercentage(
                          dashboardStatistics?.bytesAdded,
                          dashboardStatistics?.bytesRemoved,
                          dashboardStatistics.totalBytes,
                        ).value
                      }
                    </Typography>
                  ) : (
                    '--'
                  )}
                </Box>
              </Box>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: `File Compaction`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {dashboardCompaction?.filePercent ? (
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          {...bindHover(popupState)}
                        >
                          <SouthEastIcon fontSize={`small`} />
                          <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                            {`${dashboardCompaction.filePercent}%`}
                          </Typography>
                        </Box>
                        <Popper
                          {...bindPopper(popupState)}
                          placement={`bottom`}
                        >
                          <CompactionResultsPopover
                            type={`files`}
                            numberOfDays={STATISTICS_DAYS_BACK}
                            starting={getFileSize(
                              dashboardCompaction?.startingFiles,
                            )}
                            // @ts-ignore
                            rewritten={getFileSize(
                              dashboardCompaction?.rewrittenFiles,
                            )}
                            // @ts-ignore
                            total={getFileSize(
                              dashboardCompaction?.startingFiles -
                                dashboardCompaction?.rewrittenFiles,
                            )}
                            downward={true}
                            percent={`${dashboardCompaction?.filePercent}%`}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                ) : (
                  '--'
                )}
              </Box>
            ),
          },
          {
            title: `Storage Change`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {dashboardCompaction?.sizePercent ? (
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          {...bindHover(popupState)}
                        >
                          {dashboardCompaction.sizeChange < 0 ? (
                            <NorthEastIcon fontSize={`small`} />
                          ) : (
                            <SouthEastIcon fontSize={`small`} />
                          )}
                          <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                            {`${dashboardCompaction.sizePercent}%`}
                          </Typography>
                        </Box>
                        <Popper
                          {...bindPopper(popupState)}
                          placement={`bottom`}
                        >
                          <CompactionResultsPopover
                            type={`size`}
                            numberOfDays={STATISTICS_DAYS_BACK}
                            starting={getByteSize(
                              dashboardCompaction?.startingSize,
                            )}
                            rewritten={getByteSize(
                              dashboardCompaction?.rewrittenSize,
                            )}
                            total={
                              getByteSize(
                                dashboardCompaction?.startingSize -
                                  dashboardCompaction?.rewrittenSize,
                              ) || '--'
                            }
                            downward={dashboardCompaction.sizeChange > 0}
                            percent={`${dashboardCompaction.sizePercent}%`}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                ) : (
                  '--'
                )}
              </Box>
            ),
          },
        ],
      },
    ],
    [dashboardCompaction, dashboardStatistics],
  );

  const storageType =
    data?.organization?.warehouse?.storageProfile?.storageType;

  const cloudProviderIcon =
    storageType &&
    getImgSrcForStorageType(
      data?.organization?.warehouse?.storageProfile?.storageType,
    );

  const headerReferences = [
    {
      title: 'Warehouse ID',
      refText: warehouseId,
      clipBoardText: warehouseId,
    },
  ];

  const headerName = HeaderDropdown(
    warehouseName,
    data?.organization?.warehouse?.storageProfile?.bucket,
    organizationName,
    headerReferences,
  );

  const addResourceContent = {
    title: 'Create database',
    action: () => handleCreateDatabase(),
    disabled: !canCreateDatabase,
  };

  const refreshResourceContent = {
    title: 'Refresh database list',
    action: () => handleRefetch(),
  };

  const tabs = useMemo(
    () => [
      {
        id: 'databases',
        label: 'Warehouse Overview',
        group: MainTabGroup.DATA,
        content: (
          <>
            <DataGridDashboard dashboardCards={dashboardItems} />
            <ListDatabases
              rows={rows}
              columns={columns}
              loading={loading}
              refreshResourceContent={refreshResourceContent}
              addResourceContent={addResourceContent}
              dashboardDropdownContent={dashboardDropdownContent}
              warehouseName={warehouseName}
              warehouseId={warehouseId}
              headerName={headerName}
              cloudProviderIcon={cloudProviderIcon}
            />
          </>
        ),
        hide: false,
        href: 'databases',
      },
      {
        id: 'access',
        label: 'Access Control',
        group: MainTabGroup.DATA,
        content: (
          <>
            {data && (
              <WarehouseAccessControl
                warehouseId={data?.organization?.warehouse?.id}
                organizationId={data?.organization?.id}
                orgName={data?.organization?.name}
              />
            )}
          </>
        ),
        hide: false,
        href: 'access',
      },
      {
        id: 'settings',
        label: 'Settings',
        group: MainTabGroup.DATA,
        content: (
          <>
            {data && (
              <>
                <PageHeader
                  resourceName={data?.organization?.warehouse?.name}
                  pageTitle={'Warehouse settings'}
                />
                <WarehouseSettings
                  warehouse={data?.organization?.warehouse}
                  organization={data?.organization}
                  refetchQuery={handleRefetch}
                  userCanDrop={userCanDrop}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  mutationError={mutationError}
                  setMutationError={setMutationError}
                  handleDangerZoneMutation={handleDangerZoneMutations}
                />
              </>
            )}
          </>
        ),
        hide: false,
        href: 'settings',
      },
    ],
    [
      data,
      user,
      rows,
      columns,
      handleRefetch,
      loading,
      dashboardDropdownContent,
      dashboardItems,
    ],
  );

  if (error && !data) {
    return <Navigate to="/error" replace />;
  }
  if (!warehouseId) {
    return <NotFound />;
  }
  return (
    <>
      <TabLayout
        resourceType="Warehouse"
        breadcrumbs={breadcrumbs}
        tabs={tabs}
      />

      <CreateOrgCredentialDialog
        isOpen={createDialogVisible}
        onDismiss={() => setCreateDialogVisible(false)}
        organizationId={user.loginSession.loggedInOrg.id}
        onCredentialCreated={async () => {
          setCreateDialogVisible(false);
        }}
        isExternalOption={false}
      />
    </>
  );
}
