import * as React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import BadgeIcon from '@mui/icons-material/Badge';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  IconButton,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { enqueueSnackbar } from 'notistack';

import { toSingleRole } from '../../RouteTable';
import {
  createAuthorizations,
  revokeAuthorizations,
} from '../../graphql/authorization';
import { Role, SearchRoleResult } from '../../graphql/gen/graphql';
import { getRoleByName } from '../../graphql/role';
import { calculateTextWidth } from '../../pages/helpers';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import { Tacard } from '../Card/Tacard';
import { ChipColors, Tachip } from '../Chip/Tachip';
import {
  CustomPaging,
  DataGridToolBar,
} from '../DataGridToolbar/DataGridToolbar';
import QuickAddRole from '../Forms/QuickAddRole';
import { ConfirmationDialog } from '../Modals/ConfirmationDialog';
import { NamePopover } from '../Popovers/NamePopover';
import QuickAddMenu from '../QuickAddMenu/QuickAddMenu';

export default function RoleAccessControl({
  role,
  loading,
  refreshResourceContent,
  orgName,
  orgId,
  orgDisplayName,
  canModifyRole,
}: {
  role: Role;
  loading: boolean;
  refreshResourceContent: () => void;
  orgName: string;
  orgId: string;
  orgDisplayName: string;
  canModifyRole: boolean;
}) {
  const apiRef = useGridApiRef();
  const [pageSize, setPageSize] = useState(25);
  const [confirmModal, setConfirmModal] = useState(false);
  const [roleToRemove, setRoleToRemove] = useState<any>();
  const navigate = useNavigate();
  const [addNewAccess] = useMutation(createAuthorizations);
  const [removeAccess] = useMutation(revokeAuthorizations);

  const rows = useMemo(
    () =>
      role?.authorizations?.map(
        (item: any) =>
          ({
            ...item.role,
            displayName: item.role.displayName || item.role.name,
            authId: item.id,
          } || []),
      ),
    [role],
  );
  const handleRemoveAccess = async () => {
    await removeAccess({
      variables: {
        ids: [roleToRemove.authId],
      },
      refetchQueries: [
        {
          query: getRoleByName,
          variables: {
            organizationId: orgId,
            roleName: role.name,
            organizationName: orgName,
            organizationDisplayName: orgDisplayName,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(async () => {
        await enqueueSnackbar(`Successfully revoked access`, {
          variant: 'success',
        });
        setRoleToRemove(undefined);
        setConfirmModal(false);
      })
      .catch((error) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
          variant: 'error',
        });
      });
  };

  const onSubmitRole = async (selected: SearchRoleResult) => {
    const newGuy = {
      roleId: selected?.roleId,
      privilege: 'MODIFY_ROLE',
      resource: role?.id,
      resourceType: 'ROLE',
      withGrant: true,
    };

    await addNewAccess({
      variables: {
        authorizations: [newGuy],
      },
      refetchQueries: [
        {
          query: getRoleByName,
          variables: {
            organizationId: orgId,
            roleName: role.name,
            organizationName: orgName,
            organizationDisplayName: orgDisplayName,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(async () => {
        await enqueueSnackbar(
          `${selected.roleName} successfully granted access`,
          { variant: 'success' },
        );
      })
      .catch((error) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
          variant: 'error',
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        field: 'avatar',
        headerName: '',
        width: 75,
        sortable: false,
        align: 'center',
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return <BadgeIcon sx={{ color: 'midnight.six' }} />;
        },
      },
      {
        field: 'displayName',
        headerName: 'Name',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
            true,
          );

          <Box
            sx={{
              display: 'flex',
              flex: '1 1 100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '100%',
              minWidth: 0,
            }}
          >
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Tachip
                    type={ChipColors.Neutral}
                    size={'small'}
                    onClick={() =>
                      navigate(toSingleRole(orgName, params.row.name))
                    }
                    label={
                      <Typography
                        variant={'subtitle2'}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {params.value}
                      </Typography>
                    }
                    {...bindHover(popupState)}
                  />

                  {textWidth >= params.colDef.computedWidth && (
                    <Popper {...bindPopper(popupState)} placement={`right`}>
                      <NamePopover name={params.value} />
                    </Popper>
                  )}
                </>
              )}
            </PopupState>
          </Box>;
        },
      },
      {
        field: 'memberCount',
        headerName: 'People',
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params?.row?.users && params.row?.users?.length !== 0
            ? params.row?.users?.length
            : '--';
        },
      },
      {
        field: 'remove',
        headerName: '',
        width: 60,
        editable: false,
        renderCell: (params: any) => (
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Tooltip title={'Revoke access'} placement={`top`} arrow>
              <span>
                <IconButton
                  disabled={!canModifyRole}
                  onClick={() => {
                    setRoleToRemove(params.row);
                    setConfirmModal(true);
                  }}
                >
                  <CloseIcon
                    sx={{
                      color: canModifyRole ? 'error.main' : 'midnight.three',
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [rows, canModifyRole],
  );

  const AddRoleComponent = (
    <Box paddingLeft={2}>
      <QuickAddMenu
        buttonSize={`medium`}
        disabled={!canModifyRole}
        menuContent={
          <QuickAddRole
            organizationId={orgId}
            onRoleAdded={onSubmitRole}
            existingRoles={rows || []}
          />
        }
        tooltipText={`Add role`}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        '& .MuiDataGrid-row, .MuiDataGrid-root .MuiDataGrid-cell, .rendering-zone':
          { maxheight: 'none !important', minHeight: '67px !important' },
        '& .MuiDataGrid-root .MuiDataGrid-window': {
          position: 'relative !important',
        },
        '& .MuiDataGrid-root .MuiDataGrid-viewport': {
          maxheight: 'none !important',
        },
        '& .MuiDataGrid-root': { height: 'auto !important' },
        '& .roles-cell': {
          display: 'flex',
          flexWrap: 'wrap',
          flex: '1 1 auto',
          maxHeight: '100%',
        },
      }}
    >
      <Tacard grid>
        <DataGridPro
          rows={rows || []}
          columns={columns as GridColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          headerHeight={34}
          autoHeight
          apiRef={apiRef}
          pagination={rows ? rows?.length >= 25 : false}
          hideFooter={rows ? rows?.length < 25 : false}
          disableColumnMenu
          density="comfortable"
          getRowHeight={() => 'auto'}
          initialState={{
            sorting: {
              sortModel: [{ field: 'displayName', sort: 'asc' }],
            },
            pagination: { page: 0, pageSize: 25 },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [''],
              },
            },
          }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No roles
              </Stack>
            ),
            Toolbar: DataGridToolBar,
            Pagination: CustomPaging,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              apiRef: apiRef,
              pageSize: pageSize,
              setPageSize: setPageSize,
              headerName: (
                <Box display={'flex'} paddingLeft={2} alignItems={'center'}>
                  {'Role access control'}
                </Box>
              ),
              icon: (
                <>
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <InfoOutlinedIcon
                            {...bindHover(popupState)}
                            fontSize={`small`}
                            sx={{ color: 'midnight.seven' }}
                          />
                        </Box>
                        <Popper {...bindPopper(popupState)} placement={`top`}>
                          <NamePopover
                            name={`Allow other roles to modify this role.`}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                </>
              ),
              refreshResourceContent: {
                title: 'Refresh list',
                action: refreshResourceContent,
              },
              customButton1: AddRoleComponent,
              rowCount: rows?.length,
              showRange: rows ? rows?.length >= 25 : false,
              pageAtNumber: 25,
            },
            pagination: {
              apiRef: apiRef,
              pageSize: pageSize,
              setPageSize: setPageSize,
              rowCount: rows?.length,
            },
          }}
          loading={loading}
          sx={{
            border: 'none',
            '.MuiDataGrid-columnHeaders': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
            '.MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: 'dusk.half',
            },
          }}
        />
      </Tacard>
      <ConfirmationDialog
        open={confirmModal}
        title="Revoke access?"
        acceptText="Revoke"
        onDismiss={() => {
          setConfirmModal(false);
        }}
        onAccept={handleRemoveAccess}
      >
        {`Revoke ${roleToRemove?.displayName} access from ${role?.name}?`}
      </ConfirmationDialog>
    </Box>
  );
}
