import React, { MouseEvent, useEffect, useMemo, useState } from 'react';

import { create, show, useModal } from '@ebay/nice-modal-react';
import { FileUploadRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { Table } from '../../graphql/gen/graphql';
import { UserTableIntent } from '../TableEdit/ChooseTableSource';
import { TileContext, TileProps } from '../TileSelector/Tile';
import TileSelector from '../TileSelector/TileSelector';

interface ChooseTableSourceDialogProps {
  intent: UserTableIntent;
}
const ChooseTableSourceDialog = create(
  ({ intent }: ChooseTableSourceDialogProps) => {
    const modal = useModal();

    const resolveModal = (source: string) => {
      modal.resolve(source);
      modal.hide();
    };

    const handleButtonClick = (event: MouseEvent, context: TileContext) => {
      resolveModal(context.ctx);
    };

    const tiles = [
      {
        id: 'storageProfile',
        logoImgUrl: '/assets/img/icebucket-alt.svg',
        logoAlt: 'Connected Storage',
        ctx: 'StorageProfileSource',
        handleButtonClick: handleButtonClick,
        body: (
          <>
            <Box>
              <Typography variant={'subtitle1'}>Connected Storage</Typography>
              <Typography variant={'body2'} color={'midnight.seven'}>
                {intent === UserTableIntent.CREATE
                  ? `Create a table from data files in your connected storage.`
                  : intent === UserTableIntent.CREATE_CDC
                  ? `Choose a path from connected storage containing DMS change files and create a new CDC pipeline.`
                  : `Load one or more files from connected storage.`}
              </Typography>
            </Box>
          </>
        ),
      },
      ...(intent === UserTableIntent.CREATE_CDC ||
      intent === UserTableIntent.REGISTER
        ? []
        : [
            {
              id: 'fileUpload',
              logoAlt: 'File upload',
              logoIcon: FileUploadRounded,
              ctx: 'FileUploadSource',
              handleButtonClick: handleButtonClick,
              body: (
                <>
                  <Box>
                    <Typography variant={'subtitle1'}>File upload</Typography>
                    <Typography variant={'body2'} color={'midnight.seven'}>
                      {intent === UserTableIntent.CREATE
                        ? `Upload a file and create a table based on its schema.`
                        : `Load local files from your filesystem.`}
                    </Typography>
                  </Box>
                </>
              ),
            },
          ]),
    ];

    useEffect(() => {
      if (tiles.length == 1) {
        setTimeout(() => resolveModal(tiles[0].ctx), 10);
      }
    }, []);
    return (
      <Dialog
        open={modal.visible}
        scroll={'paper'}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <TileSelector
          tiles={tiles}
          onCancel={modal.hide}
          title={'Select a source'}
        />
      </Dialog>
    );
  },
);

export default ChooseTableSourceDialog;

export function showChooseTableCreationSourceDialog(intent: UserTableIntent) {
  return show<string, any, ChooseTableSourceDialogProps>(
    ChooseTableSourceDialog,
    { intent },
  );
}
