import React, { useEffect, useMemo, useRef, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useModal, create, show } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Typography, List, ListItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { defaultStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useAuth } from '../../../context/auth-context';
import { Secret, Warehouse } from '../../../graphql/gen/graphql';
import {
  createOrgCredential,
  orgCredentialUsage,
} from '../../../graphql/organization';
import { Talert } from '../../Alert/Talert';
import { CustomIcon } from '../../Icons/CustomIcon';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../WizardContainer';
import { CreateCredentialStep } from '../components/CreateCredentialStep';
import { IAMRoleMappingStep } from '../components/IAMRoleMappingStep';
import { SecurityMethod } from '../helpers';
import { SparkSQLTemplate, PySparkSqlCmds } from '../spark/SparkSQLTemplate';
import { AthenaNotebookSetup } from './AthenaNotebookSetup';
import { AthenaWorkgroupSetup } from './AthenaWorkgroupSetup';

const PysparkWorkgroup = [
  { title: 'PySpark' },
  { analyticsEngine: 'Apache Spark' },
  {
    step: (
      <Typography variant={'body1'}>
        Open <b>IAM role configurations </b> and <b>create or select </b> an{' '}
        <b> IAM role </b>
      </Typography>
    ),
  },
  {
    step: (
      <Typography variant={'body1'}>
        Open <b>Calculation result settings</b> and <b>create or select</b> a{' '}
        <b>Bucket</b>{' '}
      </Typography>
    ),
  },
];

const iamMappingInstructions = {
  step1: (
    <Typography variant={'body1'}>
      Select your newly created workgroup, open linked <b>Role ARN</b>, copy ARN
      and map it to a role in Tabular.
    </Typography>
  ),
  example: `arn:aws:iam::<aws_acct_id>:role/service-role/AWSAthenaSparkExecutionRole`,
  step2: (
    <Typography variant={'body1'}>
      {' '}
      Under <b>Permissions</b> click <b>Add permissions</b> then{' '}
      <b>Attach policies</b> and search for{' '}
      <b>AmazonAPIGatewayInvokeFullAccess</b>, select and add permission.
    </Typography>
  ),
};

const AthenaConnectionWizardDialog = create<{
  organizationId: string;
  organizationName: string;
  warehouse: Warehouse;
}>(({ warehouse, organizationName, organizationId }) => {
  const modal = useModal();
  const { user } = useAuth();
  const [createOrgCredentialMutation] = useMutation(createOrgCredential);
  const [getOrgCredentialUsage, { data, stopPolling }] =
    useLazyQuery(orgCredentialUsage);
  const [credential, setCredential] = useState<Secret>();
  const [errorStatus, setErrorStatus] = useState();
  const [credentialUsed, setCredentialUsed] = useState<boolean>(false);
  const isSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(organizationName),
    [user, organizationName],
  );
  const [securityType, setSecurityType] = useState(
    isSecurityAdmin
      ? SecurityMethod.IamMapping
      : SecurityMethod.MemberCredential,
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    setSecurityType((event.target as HTMLInputElement).value);
  };

  const region = warehouse.region;

  const createCredential = async () => {
    if (!credential && !errorStatus) {
      return createOrgCredentialMutation({
        variables: {
          organizationId: organizationId,
          name: 'Amazon Athena PySpark Connection Credential',
          description: 'Built with the Amazon Athena PySpark Connection Flow',
        },
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {
          if (data) {
            setCredential(data.data.createOrgCredential);
          }
        });
    }
  };

  const pollCredUsage = async () => {
    if (credential) {
      getOrgCredentialUsage({
        variables: {
          organizationId: organizationId,
          credentialKey: credential.credential.split(':')[0],
        },
        pollInterval: 2000,
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {});
    }
  };

  useEffect(() => {
    if (data?.organizationCredential?.lastSession) {
      setCredentialUsed(true);
      stopPolling();
    }
  }, [data]);

  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: 'Athena PySpark setup',
        body: (
          <AthenaWorkgroupSetup
            region={region}
            steps={PysparkWorkgroup}
            securityType={securityType}
            isSecurityAdmin={isSecurityAdmin}
            handleChange={handleChange}
          />
        ),
      },
      {
        title: 'Security setup',
        effect:
          securityType == SecurityMethod.MemberCredential
            ? createCredential
            : () => {},
        body:
          securityType == SecurityMethod.MemberCredential ? (
            <CreateCredentialStep
              organizationName={organizationName}
              connectionName={'Athena PySpark'}
              credential={credential?.credential}
              errorStatus={errorStatus}
            />
          ) : (
            <>
              {isSecurityAdmin ? (
                <IAMRoleMappingStep
                  organizationId={organizationId}
                  organizationName={organizationName}
                  instructions={iamMappingInstructions}
                />
              ) : (
                <>
                  <Talert
                    severity={'info'}
                    alertTitle={'Security admin required'}
                    sx={{ width: 'fit-content', mt: 2 }}
                  >
                    {' '}
                    {`Contact a security admin to add a new AWS IAM role mapping in Connections > Security `}
                  </Talert>
                </>
              )}
            </>
          ),
      },

      {
        title: 'Create notebook',
        body: (
          <AthenaNotebookSetup
            warehouseName={warehouse.name}
            credential={credential?.credential || ''}
            region={region}
            securityType={securityType}
          />
        ),
      },
      {
        title: 'Test connection',
        effect: pollCredUsage,
        body: (
          <>
            <Box sx={{ width: 0.8 }}>
              {credential && !credentialUsed && (
                <Talert color="neutral" sx={{ width: 'fit-content' }}>
                  Watching for connection, please configure and test your new
                  catalog in AWS Athena.
                </Talert>
              )}
              {credential && credentialUsed && (
                <Talert severity="success">
                  Successful connection detected!
                </Talert>
              )}
              <SparkSQLTemplate cmds={PySparkSqlCmds} />
            </Box>
          </>
        ),
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <WizardContainer
        title={'Amazon Athena'}
        stepsTitle={warehouse.name}
        stepsSubtitle={`Connect Amazon Athena`}
        stepsIcon={
          <CustomIcon
            src={'/assets/img/logos/athena-pyspark.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        backButtonClick={() => {}}
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        sx={{ minHeight: '550px', width: 1, height: '98vh' }}
      />
    </Dialog>
  );
});

export function showAthenaConnectionWizardDialog({
  warehouse,
  organizationName,
  organizationId,
}: {
  warehouse: Warehouse;
  organizationName: string;
  organizationId: string;
}) {
  return show(AthenaConnectionWizardDialog, {
    warehouse,
    organizationName,
    organizationId,
  });
}
