import { Warehouse } from '../../graphql/gen/graphql';

export const getDangerZoneItems = (
  warehouseName: string,
  warehouseId: string,
): any => {
  return [
    {
      title: 'Rename warehouse',
      description:
        'Renaming a warehouse may disrupt configurations that reference warehouse name. e.g. Spark, Trino, Snowflake, Athena, etc.',
      buttonText: 'Rename this warehouse',
      confirmModal: 'RenameWarehouseDialog',
      modalContent: {
        title: `Rename warehouse`,
        confirmText: 'Rename warehouse',
        recoveryWarningText: `Renaming a warehouse may disrupt configurations that reference warehouse name. e.g. Spark, Trino, Snowflake, Athena, etc.`,
        warehouseName: warehouseName,
        warehouseId: warehouseId,
      },
      mutationName: 'updateWarehouse',
    },
    {
      title: 'Drop warehouse',
      description:
        'This will drop the warehouse and metadata from Tabular, but will not alter data or metadata files in your object store.',
      buttonText: 'Drop this warehouse',
      confirmModal: 'TypeDeleteDialog',
      modalContent: {
        title: `Drop ${warehouseName} warehouse`,
        confirmText: 'Drop warehouse',
        recoveryWarningText: `This will drop the ${warehouseName} warehouse and metadata from Tabular, but will not alter data or metadata files in your object store.`,
      },
      mutationName: 'dropWarehouse',
    },
  ];
};
