import React, { useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Popper,
  Typography,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, GridColumns, useGridApiRef } from '@mui/x-data-grid-pro';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { OrganizationCredential } from '../../../graphql/gen/graphql';
import { calculateTextWidth } from '../../../pages/helpers';
import { relativeTimeAutoFormat } from '../../../utils/time';
import { Tavatar } from '../../Avatar/Tavatar';
import {
  DataGridToolBar,
  CustomPaging,
  MenuContentType,
} from '../../DataGridToolbar/DataGridToolbar';
import { StyledSwitch } from '../../Forms/Switch';
import { NamePopover } from '../../Popovers/NamePopover';
import UserInfo from '../../ProfileMenu/UserInfo';
import { dateComparator, getApplyFilterFnCreatorName } from '../helpers';

export default function CredentialsGrid({
  credentialsData,
  onCredentialDelete,
  onCredentialUpdate,
  headerName,
  icon,
  showCreatorColumn,
  currentUserEmail,
  refreshResourceContent,
  addResourceContent,
  dashboardMenuContent,
  loading,
}: {
  credentialsData: [OrganizationCredential];
  onCredentialDelete: any;
  onCredentialUpdate: any;
  headerName: string;
  icon?: JSX.Element;
  showCreatorColumn?: boolean;
  currentUserEmail?: string;
  refreshResourceContent?: MenuContentType;
  addResourceContent?: MenuContentType;
  dashboardMenuContent?: JSX.Element;
  loading?: boolean;
}) {
  const onCellEdit = (params: any) => {
    onCredentialUpdate(params.id, { [params.field]: params.value });
  };
  const [pageSize, setPageSize] = useState(10);
  const apiRef = useGridApiRef();

  const rows = useMemo(() => {
    return credentialsData?.map((cred) => {
      return {
        id: cred.key,
        created: {
          displayName: cred?.member?.displayName || '',
          profilePic: cred?.member?.properties?.['picture.url'] || '',
          currentUser: currentUserEmail === cred?.member?.user?.email || '',
          email: cred?.member?.user?.email || '',
          createdAt: cred?.createdAt,
        },
        key: cred.key,
        name: cred.name,
        lastSession: cred.lastSession,
        active: cred.active,
      };
    });
  }, [credentialsData]);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 200,
        editable: true,
        flex: 1.5,
        renderCell: (params: any) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
            true,
          );

          return (
            <Box
              sx={{
                display: 'flex',
                flex: '1 1 100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
                minWidth: 0,
              }}
            >
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      variant={`inputText`}
                      {...bindHover(popupState)}
                    >
                      {params.value}
                    </Typography>
                    {textWidth >= params.colDef.computedWidth && (
                      <Popper
                        {...bindPopper(popupState)}
                        placement={`right-start`}
                        modifiers={[
                          {
                            name: 'flip',
                            options: {
                              fallbackPlacements: ['bottom'],
                            },
                          },
                        ]}
                      >
                        <NamePopover name={params.value} />
                      </Popper>
                    )}
                  </>
                )}
              </PopupState>
            </Box>
          );
        },
      },
      {
        field: 'key',
        headerName: 'Key',
        flex: 1,
        minWidth: 150,
        editable: false,
        renderCell: (params: any) => (
          <Box sx={{ flexDirection: 'column' }}>
            <Typography
              variant="inputText"
              sx={{
                display: 'inline-flex',
                pb: 1,
              }}
            >
              {params.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'created',
        headerName: 'Created',
        flex: 0.5,
        minWidth: 175,
        editable: false,
        sortComparator: dateComparator,
        getApplyQuickFilterFn: getApplyFilterFnCreatorName,
        sortingOrder: ['desc', 'asc', null],
        renderCell: (params: any) => {
          const createdBy = params.value;
          const createdAt = params.value?.createdAt;
          return (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
            >
              {showCreatorColumn && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <Box {...bindHover(popupState)}>
                          <Tavatar
                            displayName={createdBy.displayName}
                            profilePic={createdBy.profilePic}
                            currentUser={createdBy.currentUser}
                          />
                        </Box>
                        <Popper {...bindPopper(popupState)} placement={`top`}>
                          <Box
                            sx={(theme) => ({
                              backgroundColor: 'controlBackground.main',
                              borderRadius: '4px',
                              boxShadow: theme.shadows[3],
                            })}
                          >
                            <UserInfo
                              user={{
                                displayName: createdBy.displayName,
                                profilePic: createdBy?.profilePic,
                                email: createdBy.email,
                              }}
                              currentUser={createdBy.currentUser}
                            />
                          </Box>
                        </Popper>
                      </>
                    )}
                  </PopupState>
                </Box>
              )}
              <Typography
                display={'flex'}
                alignItems={'center'}
                sx={{ ml: showCreatorColumn ? 1 : 0 }}
              >
                {createdAt ? relativeTimeAutoFormat(createdAt) : '--'}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'lastSession',
        headerName: 'Last session',
        flex: 0.5,
        minWidth: 200,
        editable: false,
        getApplyQuickFilterFn: undefined,
        type: 'dateTime',
        sortingOrder: ['desc', 'asc', null],
        valueFormatter: (params: any) => {
          return params.value ? relativeTimeAutoFormat(params.value) : '--';
        },
      },
      {
        field: 'active',
        headerName: 'Active',
        minWidth: 80,
        renderCell: (params: any) => (
          <StyledSwitch
            checked={params.value}
            onChange={() =>
              onCredentialUpdate(params.row.key, {
                active: !params.value,
              })
            }
          />
        ),
      },
      {
        field: 'delete',
        headerName: '',
        width: 60,
        editable: false,
        renderCell: (params: any) => (
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Tooltip
              title={`${
                params.row.active
                  ? 'Deactivate credential before deleting'
                  : 'Delete'
              }`}
              placement={`top`}
              arrow
            >
              <span>
                <IconButton
                  onClick={() => onCredentialDelete(params.row)}
                  disabled={params.row.active}
                >
                  <CloseIcon
                    sx={{
                      color: params.row.active
                        ? 'midnight.three'
                        : 'error.main',
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [credentialsData],
  );

  return (
    <>
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        pagination={rows?.length >= 10}
        hideFooter={rows?.length < 10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        rows={rows || []}
        columns={columns as GridColumns}
        headerHeight={34}
        onCellEditCommit={onCellEdit}
        disableColumnMenu
        isRowSelectable={() => false}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created', sort: 'desc' }],
          },
          pinnedColumns: { right: ['active', 'delete'] },
          pagination: { page: 0, pageSize: 10 },
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [''],
            },
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No credentials
            </Stack>
          ),
          Toolbar: DataGridToolBar,
          Pagination: CustomPaging,
        }}
        loading={loading}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            apiRef: apiRef,
            pageSize: pageSize,
            setPageSize: setPageSize,
            headerName: (
              <Box display={'flex'} paddingLeft={2} alignItems={'center'}>
                {headerName}
              </Box>
            ),
            icon: icon,
            refreshResourceContent: refreshResourceContent,
            addResourceContent: addResourceContent,
            dashboardMenuContent: dashboardMenuContent,
            rowCount: credentialsData?.length,
            pageAtNumber: 10,
          },
          pagination: {
            apiRef: apiRef,
            pageSize: pageSize,
            setPageSize: setPageSize,
            rowCount: credentialsData?.length,
          },
        }}
        sx={{
          border: 'none',
          '.MuiDataGrid-columnHeaders': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
          '.MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: 'dusk.half',
          },
        }}
      />
    </>
  );
}
