import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import {
  AddOutlined,
  DriveFileRenameOutlineOutlined,
  EditOutlined,
  RefreshOutlined,
} from '@mui/icons-material';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SettingsIcon from '@mui/icons-material/Settings';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import { Box, Popper, Skeleton, Typography } from '@mui/material';
import { capitalize, includes, isEmpty, some } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import {
  KnownFeatureFlags,
  MainTabGroup,
} from '../../context/GlobalPropsContext';

import {
  toDatabaseRoot,
  toTableRoot,
  toTableSettings,
  toWarehouseRoot,
} from '../../RouteTable';
import { TableAccessControl } from '../../components/AccessControl/TableAccessControl';
import { DropdownMenuContent } from '../../components/DropdownMenu/DropdownMenuContent';
import { DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenuItem';
import NotFound from '../../components/Errors/NotFound';
import OrgAccessDenied from '../../components/Errors/OrgAccessDenied';
import PageAccessDenied from '../../components/Errors/PageAccessDenied';
import SomethingWentWrong from '../../components/Errors/SomethingWentWrong';
import StatusBars from '../../components/Feedback/StatusBars';
import TabLayout from '../../components/Layouts/TabLayout';
import { MaintenanceActions } from '../../components/MaintenanceActions';
import { showChooseTableCreationSourceDialog } from '../../components/Modals/ChooseTableSourceDialog';
import { showEditTableDialog } from '../../components/Modals/EditTableDialog';
import { showLoadTableDialog } from '../../components/Modals/LoadTableDialog';
import { showRenameTableDialog } from '../../components/Modals/RenameTableDialog';
import { showTypeDeleteDialog } from '../../components/Modals/TypeDeleteDialog';
import { OrgProperties } from '../../components/OrgSettings/OrgProperties';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { CompactionResultsPopover } from '../../components/Popovers/CompactionResultsPopover';
import SnapshotView from '../../components/SnapshotViewer/SnapshotView';
import TableActivityView from '../../components/TableActivity/TableActivityView';
import TableDataPreview from '../../components/TableDataPreview/TableDataPreview';
import { UserTableIntent } from '../../components/TableEdit/ChooseTableSource';
import { TableOverview } from '../../components/TableOverview/TableOverview';
import { TableSettings } from '../../components/TableSettings/TableSettings';
import { getDangerZoneItems } from '../../components/TableSettings/helpers';
import { TablePipeline } from '../../components/TableStatus/TablePipeline';
import { TableStatus } from '../../components/TableStatus/TableStatus';
import ViewSql from '../../components/ViewSql/ViewSql';
import { showAutoReplicateWizard } from '../../components/Wizards/Pipelines/AutoReplicate/AutoReplicateWizardDialog';
import { showDedupeWizard } from '../../components/Wizards/Pipelines/Dedupe/DedupeWizardDialog';
import { GlobalPropertiesContext } from '../../context';
import { useAuth } from '../../context/auth-context';
import { getAuthorizationDecision } from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionResponseType,
  AuthDecisionSubjectType,
  IcebergContentType,
  PipelineReference,
  PipelineReferenceType,
  Privilege,
  SearchEntityType,
  StorageType,
} from '../../graphql/gen/graphql';
import {
  dropTable,
  fetchFullTableQuery,
  fetchMostRecentStatusForTableQuery,
  TableWarehouseQuery,
} from '../../graphql/table';
import { compactNumberFormatter } from '../../utils/numbers';
import {
  getByteSize,
  getCommitFrequency,
  getFileSize,
  getSizeDifferencePercentage,
  STATISTICS_DAYS_BACK,
} from '../helpers';

/**
 * Component that houses the main table page containing the
 * Overview, Schema, Properties, and Snapshots sections.
 *
 * @example
 * return (
 *   <Table />
 * )
 */
export default function Table() {
  const { user } = useAuth();
  const {
    organization: organizationNameParam,
    warehouse: warehouseNameParam,
    database: databaseParam,
    table: tableParam,
  } = useParams();
  const organizationName = organizationNameParam || '';
  const warehouseName = warehouseNameParam || '';
  const database = databaseParam || '';
  const table = tableParam || '';
  const location = useLocation();
  const { ff } = useContext(GlobalPropertiesContext);
  const pipelinesEnabled = ff(KnownFeatureFlags.PIPELINES_ENABLED);
  const gcsTestingEnabled = ff(KnownFeatureFlags.GCS_TESTING_ENABLED);
  const adlsTestingEnabled = ff(KnownFeatureFlags.ADLS_TESTING_ENABLED);
  const [openSnackbar, setOpenSnackbar] = useState(
    location?.state ? location.state.snackbar : false,
  );
  const [apiError, setApiError] = useState(false);
  const organizationId = useMemo(
    () => user.getOrganization(organizationName)?.id,
    [user, organizationName],
  );
  const orgDisplayName = useMemo(
    () => user.getOrganization(organizationName)?.displayName,
    [user, organizationName],
  );
  const warehouseId = useMemo(
    () => user.getWarehouse(organizationName, warehouseName)?.id,
    [user, organizationName, warehouseName],
  );
  const navigateTo = useNavigate();
  const {
    data: tableQueryData,
    error,
    loading,
    refetch: refetchTableQuery,
  } = useQuery(fetchFullTableQuery, {
    variables: {
      orgId: organizationId,
      orgName: organizationName,
      orgDisplayName,
      warehouseId,
      database,
      tableName: table,
      daysBack: STATISTICS_DAYS_BACK,
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const { data: tableWarehouseData } = useQuery(TableWarehouseQuery, {
    variables: {
      organizationId,
      warehouseId,
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const warehouseData = useMemo(
    () => tableWarehouseData?.organization?.warehouse,
    [tableWarehouseData],
  );

  const tableData = useMemo(
    () => tableQueryData?.fetchFullTableByName,
    [tableQueryData],
  );

  const { data: recentStatusData, refetch: recentStatusRefetch } = useQuery(
    fetchMostRecentStatusForTableQuery,
    {
      variables: {
        organizationId,
        tableRefId: tableData?.id,
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const [deleteTable] = useMutation(dropTable, {
    variables: {
      warehouseId,
      database,
      table: table,
      contentType: tableData?.contentType,
    },
    onError: () => setApiError(true),
    onCompleted: () =>
      navigateTo(toDatabaseRoot(organizationName, warehouseName, database), {
        state: {
          snackbar: true,
          snackbarMessage: `${table} successfully dropped.`,
        },
      }),
  });

  const organizationDisplayName = useMemo(
    () =>
      user.getOrganization(organizationName)?.displayName || organizationName,
    [user, organizationName],
  );
  const warehouseDisplayName = useMemo(
    () =>
      user.getWarehouse(organizationName, warehouseName)?.name || warehouseName,
    [user, organizationName, warehouseName],
  );
  const dashboardStatistics = useMemo(
    () => tableData?.statistics,
    [tableQueryData],
  );
  const dashboardCompaction = useMemo(
    () => tableData?.compactionSummary,
    [tableQueryData],
  );

  const tableProperties = useMemo(() => tableData?.properties, [tableData]);
  const tableMetadata = useMemo(() => tableData?.metadata, [tableData]);
  // Table description is stored as an Iceberg table property with key === 'comment'
  const comment = useMemo(
    () => tableProperties?.find((p: any) => p.key === 'comment'),
    [tableProperties],
  );
  const bucketName = useMemo(
    () => warehouseData?.storageProfile?.bucket,
    [warehouseData],
  );
  const tabularHostedBucket = useMemo(
    () => warehouseData?.managed,
    [warehouseData],
  );

  const { data: updateDecision } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        privileges: [Privilege.Update],
        requirement: 'ALL',
        resource: {
          type: AuthDecisionResourceType.Table,
          identifier: tableData?.id,
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: dropDecision } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        privileges: [Privilege.Drop],
        requirement: 'ALL',
        resource: {
          type: AuthDecisionResourceType.Table,
          identifier: tableData?.id,
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const userCanEdit =
    updateDecision?.authDecision === AuthDecisionResponseType.Allow;
  const userCanDrop =
    dropDecision?.authDecision === AuthDecisionResponseType.Allow;
  const isView = useMemo(
    () => tableData?.contentType === IcebergContentType.View,
    [tableQueryData],
  );
  const isPipelineTargetTable = useMemo(() => {
    return some(
      tableData?.pipelineReferences,
      (pipeline) => pipeline?.referenceType === PipelineReferenceType.Target,
    );
  }, [tableData]);
  const belongsToPipeline = useMemo(() => {
    return !isEmpty(tableData?.pipelineReferences);
  }, [tableData]);
  const resourceType = isView ? 'view' : 'table';

  const hideDueToStorageType = useMemo(
    () =>
      warehouseData?.storageProfile?.storageType === StorageType.Gcs
        ? !gcsTestingEnabled
        : warehouseData?.storageProfile?.storageType === StorageType.Adls
        ? !adlsTestingEnabled
        : false,
    [warehouseData],
  );

  const isForbiddenError = (errorObject: any) => {
    return errorObject?.graphQLErrors?.some((error: any) =>
      includes(error.message, '403'),
    );
  };
  const isNotFoundError = (errorObject: any) => {
    return errorObject?.graphQLErrors?.some((error: any) =>
      includes(error.message, '404'),
    );
  };
  const isUnauthorizedError = (errorObject: any) => {
    return errorObject?.graphQLErrors?.some((error: any) =>
      includes(error.message, '401'),
    );
  };
  const notFoundError = useMemo(() => isNotFoundError(error), [error]);
  const permissionsError = useMemo(
    () => isForbiddenError(error) && user.isSecurityAdmin(organizationName),
    [error],
  );
  const orgDataPreviewEnabled = useMemo(
    () =>
      user.loginSession?.loggedInOrg?.properties &&
      user.loginSession?.loggedInOrg?.properties[
        OrgProperties.ALLOW_DATA_PREVIEW
      ],
    [user],
  );
  const isForbidden = useMemo(() => isForbiddenError(error), [error]);
  const isUnauthorized = useMemo(() => isUnauthorizedError(error), [error]);
  const handleRefetch = () => {
    refetchTableQuery({
      //@ts-ignore
      noCache: true,
    }).then(recentStatusRefetch);
  };

  const breadcrumbs = [
    {
      href: toWarehouseRoot(organizationName, warehouseName),
      text: warehouseDisplayName,
      dropdown: {
        type: 'WAREHOUSE',
        variables: {
          organizationId,
        },
        current: warehouseName,
      },
    },
    {
      href: toDatabaseRoot(
        organizationName,
        warehouseName,
        tableData?.databaseName ?? database,
      ),
      text: tableData?.databaseName ?? database,
      dropdown: {
        type: 'DATABASE',
        variables: {
          organizationId,
          warehouseId,
        },
        current: tableData?.databaseName ?? database,
      },
    },
    {
      href: toTableRoot(
        organizationName,
        warehouseName,
        tableData?.databaseName ?? database,
        tableData?.name ?? table,
      ),
      text: tableData?.name || table,
      dropdown: {
        type: 'TABLE',
        variables: {
          organizationId,
          warehouseId,
          database: tableData?.databaseName ?? database,
        },
        current: tableData?.name ?? table,
      },
    },
  ];

  const handleRenameTable = useCallback(
    () =>
      showRenameTableDialog({
        organizationId,
        warehouseId,
        database,
        table,
        contentType: tableData?.contentType,
      }).then((response) => {
        navigateTo(
          toTableRoot(
            organizationName,
            warehouseName,
            response.newDatabaseName,
            response.newTableName,
          ),
        );
      }),
    [user, organizationName, warehouseName, database, breadcrumbs],
  );

  const handleEditTable = useCallback(
    () =>
      showEditTableDialog(
        organizationId,
        user.getWarehouse(organizationName, warehouseName),
        database,
        table,
        tableData,
        breadcrumbs,
      ).then((table) => {
        handleRefetch();
      }),
    [user, organizationName, warehouseName, database, breadcrumbs],
  );

  const handleLoadTable = useCallback(() => {
    showChooseTableCreationSourceDialog(UserTableIntent.LOAD).then((source) => {
      showLoadTableDialog({
        organizationId,
        warehouse: user.getWarehouse(organizationName, warehouseName),
        databaseName: database,
        table: tableData,
        source: source,
      }).then((status) => {
        handleRefetch();
      });
    });
  }, [user, organizationName, warehouseName, database, breadcrumbs]);

  const handleDropTable = useCallback(() => {
    if (userCanDrop) {
      showTypeDeleteDialog({
        ...getDangerZoneItems(table, resourceType)[0].modalContent,
      }).then((doit) => {
        deleteTable();
      });
    }
  }, [
    userCanDrop,
    table,
    user,
    organizationName,
    warehouseName,
    breadcrumbs,
    deleteTable,
  ]);

  const dashboardItems = useMemo(
    () => [
      {
        items: [
          {
            title: 'Rows',
            data: (
              <Typography variant={`subtitle1`}>
                {dashboardStatistics?.totalRows
                  ? compactNumberFormatter.format(dashboardStatistics.totalRows)
                  : '--'}
              </Typography>
            ),
          },
          {
            title: 'Columns',
            data: (
              <Typography variant={`subtitle1`}>
                {tableMetadata?.current_schema?.fields?.length || '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: 'Table Size',
            data: (
              <Typography variant={`subtitle1`}>
                {getByteSize(dashboardStatistics?.totalBytes) || '--'}
              </Typography>
            ),
          },
          {
            title: `Last ${STATISTICS_DAYS_BACK} Days`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {dashboardStatistics?.totalBytes
                    ? getSizeDifferencePercentage(
                        dashboardStatistics?.bytesAdded,
                        dashboardStatistics?.bytesRemoved,
                        dashboardStatistics.totalBytes,
                      ).icon
                    : ''}
                  {dashboardStatistics?.totalBytes ? (
                    <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                      {
                        getSizeDifferencePercentage(
                          dashboardStatistics?.bytesAdded,
                          dashboardStatistics?.bytesRemoved,
                          dashboardStatistics.totalBytes,
                        ).value
                      }
                    </Typography>
                  ) : (
                    '--'
                  )}
                </Box>
              </Box>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: `Commit freq.`,
            data: (
              <Typography variant={`subtitle1`}>
                {dashboardStatistics?.commitCnt
                  ? getCommitFrequency(
                      dashboardStatistics?.commitCnt,
                      tableData?.createdAt,
                    )
                  : '--'}
              </Typography>
            ),
          },
        ],
      },
      {
        items: [
          {
            title: `File Compaction`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {dashboardCompaction?.filePercent ? (
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          {...bindHover(popupState)}
                        >
                          <SouthEastIcon fontSize={`small`} />
                          <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                            {`${dashboardCompaction.filePercent}%`}
                          </Typography>
                        </Box>
                        <Popper
                          {...bindPopper(popupState)}
                          placement={`bottom`}
                        >
                          <CompactionResultsPopover
                            type={`files`}
                            numberOfDays={STATISTICS_DAYS_BACK}
                            starting={getFileSize(
                              dashboardCompaction?.startingFiles,
                            )}
                            // @ts-ignore
                            rewritten={getFileSize(
                              dashboardCompaction?.rewrittenFiles,
                            )}
                            // @ts-ignore
                            total={getFileSize(
                              dashboardCompaction?.startingFiles -
                                dashboardCompaction?.rewrittenFiles,
                            )}
                            downward={true}
                            percent={`${dashboardCompaction?.filePercent}%`}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                ) : (
                  '--'
                )}
              </Box>
            ),
          },
          {
            title: `Storage Change`,
            data: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {dashboardCompaction?.sizePercent ? (
                  <PopupState variant="popper">
                    {(popupState) => (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          {...bindHover(popupState)}
                        >
                          {dashboardCompaction.sizeChange < 0 ? (
                            <NorthEastIcon fontSize={`small`} />
                          ) : (
                            <SouthEastIcon fontSize={`small`} />
                          )}
                          <Typography variant={`subtitle1`} sx={{ ml: '4px' }}>
                            {`${dashboardCompaction.sizePercent}%`}
                          </Typography>
                        </Box>
                        <Popper
                          {...bindPopper(popupState)}
                          placement={`bottom`}
                        >
                          <CompactionResultsPopover
                            type={`size`}
                            numberOfDays={STATISTICS_DAYS_BACK}
                            starting={getByteSize(
                              dashboardCompaction?.startingSize,
                            )}
                            rewritten={getByteSize(
                              dashboardCompaction?.rewrittenSize,
                            )}
                            total={
                              getByteSize(
                                dashboardCompaction?.startingSize -
                                  dashboardCompaction?.rewrittenSize,
                              ) || '--'
                            }
                            downward={dashboardCompaction.sizeChange > 0}
                            percent={`${dashboardCompaction.sizePercent}%`}
                          />
                        </Popper>
                      </>
                    )}
                  </PopupState>
                ) : (
                  '--'
                )}
              </Box>
            ),
          },
        ],
      },
    ],
    [dashboardCompaction, dashboardStatistics, tableMetadata],
  );

  const preSelectedSource = useMemo(() => {
    return {
      id: tableData?.id,
      type: SearchEntityType.Table,
      whName: warehouseName,
      whId: tableData?.warehouseId,
      dbName: tableData?.databaseName,
      dbId: tableData?.namespaceId,
      tblName: tableData?.name,
    };
  }, [tableData, warehouseName]);

  const tableOnlyMenuItems = !isView
    ? [
        <DropdownMenuItem
          key={'alterTable'}
          showAsMenuButton
          titleText={'Alter Table'}
          Icon={EditOutlined}
          onClick={handleEditTable}
          disabled={!userCanEdit || (pipelinesEnabled && isPipelineTargetTable)}
        />,
        <DropdownMenuItem
          key={'loadTable'}
          titleText={'Load table from source'}
          description={'Pick a source and load data into your table'}
          onClick={handleLoadTable}
          Icon={AddOutlined}
          disabled={!userCanEdit || (pipelinesEnabled && isPipelineTargetTable)}
        />,
        ...(pipelinesEnabled &&
        !isPipelineTargetTable &&
        isEmpty(tableData?.fieldLabels)
          ? [
              <DropdownMenuItem
                key={'autoReplicate'}
                titleText={'Create auto-replicate pipeline'}
                description={
                  'Use this table as a source for auto-replicate pipeline'
                }
                onClick={() =>
                  showAutoReplicateWizard({
                    organizationId,
                    preSelectedSource,
                  }).then(() => {})
                }
                Icon={AddOutlined}
              />,
            ]
          : []),
        ...(pipelinesEnabled &&
        !isPipelineTargetTable &&
        !isEmpty(tableMetadata?.current_schema?.identifier_field_ids)
          ? [
              <DropdownMenuItem
                key={'dedupe'}
                titleText={'Create deduplication pipeline'}
                description={
                  'Use this table as a source for deduplication pipeline'
                }
                onClick={() =>
                  showDedupeWizard({
                    organizationId,
                    preSelectedSource,
                  }).then(() => {})
                }
                Icon={AddOutlined}
              />,
            ]
          : []),
      ]
    : [];

  const dashboardDropdownContent = (
    <DropdownMenuContent
      menuItems={[
        <DropdownMenuItem
          key={'refresh'}
          showAsMenuButton
          titleText={'Refresh'}
          Icon={RefreshOutlined}
          onClick={handleRefetch}
        />,
        ...tableOnlyMenuItems,
        <DropdownMenuItem
          key={'tableSettings'}
          titleText={`${
            resourceType[0]?.toUpperCase() + resourceType?.slice(1)
          } settings`}
          description={
            'Edit description, modify services, and create custom properties.'
          }
          Icon={SettingsIcon}
          onClick={() => {
            navigateTo(
              toTableSettings(
                // @ts-ignore
                organizationName,
                warehouseName,
                database,
                table,
              ),
            );
          }}
        />,
        <DropdownMenuItem
          key={'renameTable'}
          showAsMenuButton
          titleText={`Rename/Move ${resourceType}`}
          Icon={DriveFileRenameOutlineOutlined}
          onClick={handleRenameTable}
          disabled={!userCanDrop}
        />,
        <DropdownMenuItem
          key={'dropTable'}
          showAsMenuButton
          titleText={`Drop ${resourceType}`}
          disabled={!userCanDrop || (pipelinesEnabled && belongsToPipeline)}
          onClick={handleDropTable}
          dangerZone
        />,
      ]}
    />
  );

  const editResourceContent = !isView && {
    title: 'Alter table',
    action: () => handleEditTable(),
    disabled: !userCanEdit || (pipelinesEnabled && isPipelineTargetTable),
  };

  const refreshResourceContent = {
    title: `Refresh ${resourceType}`,
    action: () => handleRefetch(),
  };

  const tabs = useMemo(
    () => [
      {
        id: 'schema',
        label: `${capitalize(resourceType)} Overview`,
        group: MainTabGroup.DATA,
        content: (
          <>
            {organizationId &&
              tableData &&
              pipelinesEnabled &&
              !isEmpty(tableData.pipelineReferences) &&
              tableData.pipelineReferences?.map(
                (pipelineReference: PipelineReference) => (
                  <TablePipeline
                    key={pipelineReference.pipelineId}
                    organizationId={organizationId}
                    orgName={organizationName}
                    basicPipelineReference={pipelineReference}
                    onPipelineDeleted={() => refetchTableQuery()}
                    user={user}
                    tableId={tableData?.id}
                  />
                ),
              )}

            {organizationId &&
              table &&
              recentStatusData?.fetchMostRecentStatusForTable && (
                <TableStatus
                  organizationId={organizationId}
                  tableName={table}
                  recentStatusData={
                    recentStatusData.fetchMostRecentStatusForTable
                  }
                />
              )}

            <TableOverview
              dashboardItems={dashboardItems}
              showPermissionsError={error && permissionsError}
              organizationName={organizationName || ''}
              warehouseName={warehouseName || ''}
              warehouseId={warehouseId}
              databaseName={database || ''}
              tableName={table || ''}
              table={tableData}
              loading={loading}
              refreshResourceContent={refreshResourceContent}
              //@ts-ignore
              editResourceContent={editResourceContent}
              dashboardMenuContent={dashboardDropdownContent}
              isView={isView}
              userCanEdit={userCanEdit}
            />
            <StatusBars
              successDisplay={openSnackbar}
              handleSuccessClose={() => {
                setOpenSnackbar(false);
                window.history.replaceState({}, document.title);
              }}
              successMessage={
                location?.state?.snackbarMessage ??
                `${resourceType} settings have been updated.`
              }
              errorDisplay={apiError}
              setErrorDisplay={setApiError}
              errorMessage={`Something went wrong. Please refresh the page and try again.`}
            />
          </>
        ),
        hide: false,
        href: 'overview',
      },
      {
        id: 'sql',
        label: 'SQL',
        group: MainTabGroup.DATA,
        content: (
          <>
            {tableData && (
              <>
                <PageHeader resourceName={table} />
                <ViewSql viewData={tableData} />
              </>
            )}
          </>
        ),
        hide: !isView,
        disabled: permissionsError,
        href: 'sql',
      },
      {
        id: 'dataPreview',
        label: 'Data Preview',
        group: MainTabGroup.DATA,
        content: (
          <TableDataPreview
            organizationId={organizationId || ''}
            warehouseId={warehouseId}
            databaseName={database || ''}
            tableName={table || ''}
          />
        ),
        hide: !orgDataPreviewEnabled || isView,
        disabled: permissionsError,
        href: 'preview',
      },
      {
        id: 'activity',
        label: 'Activity',
        group: MainTabGroup.DATA,
        content: (
          <TableActivityView table={tableData} userCanEdit={userCanEdit} />
        ),
        hide: isView,
        disabled: permissionsError,
        href: 'activity',
      },

      {
        id: 'snapshots',
        label: 'Snapshots',
        group: MainTabGroup.DATA,
        content: (
          <SnapshotView
            table={tableData}
            userCanEdit={userCanEdit}
            isPipelineTarget={pipelinesEnabled && isPipelineTargetTable}
          />
        ),
        hide: isView,
        disabled: permissionsError,
        href: 'snapshots',
      },
      {
        id: 'accessControl',
        label: 'Access Control',
        group: MainTabGroup.DATA,
        content: (
          <>
            {tableQueryData && (
              <TableAccessControl
                organizationName={organizationName}
                organizationId={organizationId}
                organizationDisplayName={organizationDisplayName}
                warehouseId={warehouseId}
                databaseId={tableData?.namespaceId}
                databaseName={database}
                tableName={tableData?.name || table}
                tableId={tableData?.id}
                isView={isView}
              />
            )}
          </>
        ),
        hide: tableData?.primary
          ? !tableData.primary
          : !user.isSecurityAdmin(organizationName),
        href: 'access',
      },
      {
        id: 'settings',
        label: 'Settings',
        group: MainTabGroup.DATA,
        content: loading ? (
          <Skeleton />
        ) : (
          <>
            <PageHeader
              resourceName={tableData?.name ?? table}
              pageTitle={`${
                resourceType[0]?.toUpperCase() + resourceType?.slice(1)
              } settings & properties`}
              marginB={3}
            />
            <TableSettings
              tableName={tableData?.name ?? table}
              description={comment?.value}
              organizationId={organizationId}
              warehouseId={warehouseId}
              database={database}
              lastUpdated={tableData?.lastModified ?? null}
              updatedBy={tableData?.lastModifiedBy ?? null}
              userCanEdit={userCanEdit}
              tableProperties={tableProperties}
              bucketName={bucketName}
              tabularHostedBucket={tabularHostedBucket}
              currentSchema={tableMetadata?.current_schema}
              storageProfile={warehouseData?.storageProfile}
              hideDueToStorageType={hideDueToStorageType}
              isView={isView}
              resourceType={resourceType}
              orgName={organizationName}
              orgDisplayName={organizationDisplayName}
              isPipelineTarget={pipelinesEnabled && isPipelineTargetTable}
            />
          </>
        ),
        hide: false,
        disabled: permissionsError,
        href: 'settings',
      },
      {
        id: 'config',
        label: 'Maintenance',
        group: MainTabGroup.DATA,
        content: (
          <>
            <PageHeader
              resourceName={tableData?.name ?? table}
              pageTitle={'Maintenance actions'}
            />
            <MaintenanceActions />
          </>
        ),
        ff: KnownFeatureFlags.SHOW_TABLE_CONFIG,
        hide: false,
        disabled: permissionsError,
        href: 'config',
      },
    ],
    [
      tableQueryData,
      tableData,
      dashboardStatistics,
      dashboardCompaction,
      tableProperties,
      loading,
      dashboardItems,
      dashboardDropdownContent,
      userCanEdit,
      userCanDrop,
      organizationDisplayName,
      pipelinesEnabled,
      organizationId,
      isView,
    ],
  );

  if (error && !permissionsError) {
    if (notFoundError) {
      return <NotFound />;
    }
    if (isForbidden && !permissionsError) {
      return (
        <PageAccessDenied
          resourceType={`Table${
            tableData && !tableData?.primary ? ' (SHARED)' : ''
          }`}
          breadcrumbs={breadcrumbs}
        />
      );
    } else if (isUnauthorized) {
      return <OrgAccessDenied />;
    }
    return <SomethingWentWrong />;
  }

  return <TabLayout breadcrumbs={breadcrumbs} tabs={tabs} />;
}
