import React from 'react';

import { useModal, create, show } from '@ebay/nice-modal-react';
import { DialogContent, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import useWafListener from '../../hooks/UseWafListener';
import { getApiWafUrl } from '../../utils/api';

interface CaptchaDialogProps {
  title?: string;
}
const CaptchaDialog = create(({ title }: CaptchaDialogProps) => {
  const modal = useModal();

  useWafListener(() => {
    modal.resolve(true);
    modal.hide();
  });

  return (
    <>
      <Dialog
        open={modal.visible}
        onClose={() => {
          modal.resolve(false);
          modal.hide();
        }}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <iframe
            title={'Challenge'}
            id="wafFrame"
            src={getApiWafUrl()}
            style={{
              height: '500px',
              width: '500px',
              border: 'none',
              overflow: 'hidden',
            }}
          ></iframe>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default CaptchaDialog;

export function showCaptchaDialog(title?: string) {
  return show<boolean, any, CaptchaDialogProps>(CaptchaDialog, { title });
}
