import React, { useMemo, useState } from 'react';

import { Box, useTheme } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';

import { compactNumberFormatter } from '../../utils/numbers';
import { formatTimestamp } from '../../utils/time';
import { SnapshotProp } from './SnapshotView';

function formatChartData(snapshot: SnapshotProp) {
  return {
    snapshot_id: snapshot.snapshot_id,
    timestamp_ms: snapshot.timestamp_ms,
    added_records: Number(snapshot.summary['added-records'] ?? 0),
    deleted_records: -(
      Number(snapshot.summary['total-position-deletes'] ?? 0) +
      Number(snapshot.summary['total-equality-deletes'] ?? 0)
    ),
    total_records: Number(snapshot.summary['total-records'] ?? 0),
  };
}

type SnapshotChartProps = {
  snapshots: SnapshotProp[];
  setDetailPanelExpandedRowIds: React.Dispatch<
    React.SetStateAction<GridRowId[]>
  >;
};

export default function SnapshotChart({
  snapshots,
  setDetailPanelExpandedRowIds,
}: SnapshotChartProps) {
  const snapshotsToPlot = useMemo(
    () =>
      snapshots
        .map(formatChartData)
        .sort((a, b) => a.timestamp_ms - b.timestamp_ms),
    [snapshots],
  );
  const [hoverState, setHoverState] = useState<string>();
  const [seriesHidden, setSeriesHidden] = useState<any>({});

  const hoverOpacity = (dataKey: any) => (hoverState === dataKey ? 0.6 : 1);
  const onLegendMouseEnter = (e: any) => setHoverState(e.dataKey);
  const onLegendMouseExit = () => setHoverState(undefined);
  const onLegendClick = (e: any) =>
    setSeriesHidden({ ...seriesHidden, [e.dataKey]: !seriesHidden[e.dataKey] });
  const onDataClick = ({ snapshot_id }: { snapshot_id: number }) => {
    setDetailPanelExpandedRowIds((prevState) => [...prevState, snapshot_id]);
    setTimeout(() => {
      document
        .getElementById(`snapshot-table-summary-${snapshot_id}`)
        ?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
    }, 250); // hacky timeout - run scrollIntoView *after* <Collapse> animation is finished
  };
  const theme = useTheme();
  const legend = (
    <Legend
      wrapperStyle={{
        cursor: 'pointer',
        paddingLeft: 20,
        marginTop: -15,
      }}
      onMouseEnter={onLegendMouseEnter}
      onMouseLeave={onLegendMouseExit}
      onClick={onLegendClick}
      align="right"
      layout="vertical"
      verticalAlign="middle"
      width={180}
    />
  );

  function CustomTooltip({
    payload,
    active,
  }: {
    payload?: any;
    active?: boolean;
  }) {
    if (active && !isEmpty(payload)) {
      return (
        <Box sx={{ backgroundColor: 'white', border: '#bbb 1.5px solid' }}>
          <p
            style={{
              margin: '0 0',
              padding: '3px 7.5px',
              color: theme.palette.primary.main,
            }}
          >{`${payload?.[0]?.name} : ${payload?.[0]?.value}`}</p>
          <p
            style={{
              margin: '0 0',
              padding: '3px 7.5px',
              color: theme.palette.info.main,
            }}
          >{`${payload?.[1]?.name} : ${payload?.[1]?.value}`}</p>
          <p
            style={{
              margin: '0 0',
              padding: '3px 7.5px',
              color: theme.palette.primary.main,
            }}
          >{`${formatTimestamp(payload?.[0]?.payload?.timestamp_ms)}`}</p>
        </Box>
      );
    }

    return null;
  }

  return (
    <Box sx={{ height: '500px', paddingTop: 4 }}>
      <ResponsiveContainer width="100%" height="50%">
        <LineChart data={snapshotsToPlot} syncId="snapshots">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dt" />
          <YAxis tickFormatter={compactNumberFormatter.format} />
          <Tooltip
            formatter={compactNumberFormatter.format as Formatter<any, any>}
          />
          <Line
            type="monotone"
            activeDot={{
              r: 8,
              style: { cursor: 'pointer' },
              onClick: (_, e: any) => onDataClick(e.payload),
            }}
            stroke={theme.palette.primary.main}
            strokeWidth="2"
            dataKey="total_records"
            opacity={hoverOpacity('total_records')}
            hide={seriesHidden.total_records}
          />
          {legend}
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height="50%">
        <ComposedChart
          data={snapshotsToPlot}
          stackOffset="sign"
          syncId="snapshots"
          barCategoryGap="20%"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp_ms" tickFormatter={formatTimestamp} />
          <YAxis tickFormatter={compactNumberFormatter.format} />
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke="#000" />
          <Bar
            style={{ cursor: 'pointer' }}
            onClick={onDataClick}
            dataKey="added_records"
            fill={theme.palette.primary.main}
            stackId="stack"
            hide={seriesHidden.added_records}
            opacity={hoverOpacity('added_records')}
          />
          <Bar
            style={{ cursor: 'pointer' }}
            onClick={onDataClick}
            dataKey="deleted_records"
            fill={theme.palette.info.main}
            stackId="stack"
            hide={seriesHidden.deleted_records}
            opacity={hoverOpacity('deleted_records')}
          />
          {legend}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}
