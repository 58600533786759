import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Box,
  BoxProps,
  DialogActions,
  Popper,
  Stack,
  TablePagination,
  Typography,
  Tooltip,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import {
  DataGridPro,
  GridCellParams,
  GridColumns,
  GridRenderCellParams,
  GridSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { Form, Formik, FormikValues } from 'formik';
import { keyBy } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import {
  StorageProfile,
  StorageProfileFile,
  StorageType,
} from '../../graphql/gen/graphql';
import {
  getOrgStorageProfilesList,
  listStorageProfileQuery,
  organizationPageQuery,
} from '../../graphql/organization';
import {
  getByteSize,
  sizeComparator,
  STATISTICS_DAYS_BACK,
} from '../../pages/helpers';
import { getDifferenceInHours, relativeTimeAutoFormat } from '../../utils/time';
import { Tabutton } from '../Button/Tabutton';
import {
  getImgSrcForStorageType,
  getSchemeForStorageType,
  isValidImportSource,
} from '../CreateStorageBucket/ValidationStatusHelpers';
import TextField from '../Forms/TextField';
import { ListPopover } from '../Popovers/ListPopover';

function getFileExtension(fileName: string): string {
  const shortName = fileName.split('/');
  const fileNameParts = shortName[shortName.length - 1].split('.');
  return fileNameParts.length > 1
    ? fileNameParts[fileNameParts.length - 1]
    : 'File';
}

function parsePath(uri: string): {
  proto: string;
  bucket: string;
  keyPath: string;
  pathParts: string[];
} {
  const uriParts = uri.split('://');
  if (uriParts.length !== 2) {
    throw new Error('Path is not an bucket URI.');
  }

  const pathParts = uriParts[1].split('/');

  return {
    proto: uriParts.length > 0 ? uriParts[0] : '',
    bucket: pathParts.length > 0 ? pathParts[0] : '',
    pathParts,
    keyPath: pathParts.length > 0 ? pathParts.slice(1).join('/') : '',
  };
}

export enum StorageBrowserSelectionMode {
  PATH,
  FILES_FOLDERS,
  SINGLE_FILE_OR_FOLDER,
  SINGLE_FILE,
}

export interface StorageBrowserSelectionModel {
  selectionModel: GridSelectionModel;
  selectedPath: string;
  profile: StorageProfile;
  stagedTableId?: string;
}

interface IBrowseItem {
  id: string;
  bucket: string;
  path: string;
  name: string;
  region: string;
  storageType: StorageType;
  size?: number;
  isWarehouse: boolean;
  warehouseName: string;
  isRegionCompatible: boolean;
  isSelectable: boolean;
}

interface ListStorageProfileParams {
  organizationId: string;
  storageProfileId?: string;
  prefix?: string;
  pageSize?: number;
  continuationToken?: string;
}
interface FormValues {
  path: string;
}

export interface StorageBrowserProps extends BoxProps {
  organizationId: string;
  selectionMode: StorageBrowserSelectionMode;
  regionFilter: string[];
  storageTypeFilter: string[];
  onSelectionModelChange?: (
    selectionModel: StorageBrowserSelectionModel,
  ) => void;
  onFinalSelection: (selectionModel: StorageBrowserSelectionModel) => void;
  onCancel: () => void;
  DatagridSx?: SystemStyleObject;
  showBack?: boolean;
}

const FIELD_NAME = 'name';

export default function StorageBrowser({
  organizationId,
  selectionMode,
  regionFilter,
  storageTypeFilter,
  onSelectionModelChange,
  onFinalSelection,
  onCancel,
  DatagridSx,
  ...props
}: StorageBrowserProps) {
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [page, setPage] = useState(0);
  const [isTopLevel, setIsTopLevel] = useState(true);
  const [listQueryParams, setListQueryParams] =
    useState<ListStorageProfileParams>({ organizationId });

  const isSecondToTopLevel =
    listQueryParams.prefix !== undefined &&
    listQueryParams.prefix.split('/').length === 1;
  const [pageSize, setPageSize] = useState(10);
  const mapPageToNextCursor = useRef<{ [page: number]: string }>({});
  const gridApiRef = useGridApiRef();
  //const [path, setPath] = useState<string[]>([]);
  const [profilesByBucketName, setProfilesByBucketName] = useState<
    Record<string, StorageProfile>
  >({});
  const [profilesByProfileId, setProfilesByProfileId] = useState<
    Record<string, StorageProfile>
  >({});

  const { loading: bucketLoading, data: bucketData } = useQuery(
    getOrgStorageProfilesList,
    {
      variables: { organizationId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const {
    data: orgData,
    loading: orgDataLoading,
    error: orgDataError,
  } = useQuery(organizationPageQuery, {
    variables: {
      organizationId,
      daysBack: STATISTICS_DAYS_BACK,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const warehousePrefixes = orgData
    ? keyBy(orgData?.organization?.warehouses, 'id')
    : {};

  const [listStorageProfile, { loading: listingLoading, data: listingData }] =
    useLazyQuery(listStorageProfileQuery, {
      variables: listQueryParams,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });

  React.useEffect(() => {
    if (
      !listingLoading &&
      listingData?.listStorageProfile?.truncated &&
      listingData.listStorageProfile?.continuationToken
    ) {
      // We add nextCursor when available
      mapPageToNextCursor.current[page + 1] =
        listingData.listStorageProfile?.continuationToken;
    }
  }, [
    page,
    listingLoading,
    listingData?.listStorageProfile?.continuationToken,
  ]);

  const CustomPagination = useCallback(() => {
    return (
      <TablePagination
        count={
          listingData?.listStorageProfile?.truncated
            ? page * pageSize + pageSize + 1
            : page * pageSize
        }
        page={page}
        rowsPerPage={pageSize}
        labelDisplayedRows={() => {
          return <></>;
        }}
        onRowsPerPageChange={(event) => {
          const newRowsPerPage = Number(event.target.value);
          setPageSize(newRowsPerPage);
          setPage(0);
          mapPageToNextCursor.current = {};
          const newParams = {
            ...listQueryParams,
            continuationToken: '',
            pageSize: newRowsPerPage - 1,
          };
          setListQueryParams(newParams);
          listStorageProfile({
            variables: newParams,
          });
        }}
        onPageChange={(
          event: React.MouseEvent<HTMLButtonElement> | null,
          toPage: number,
        ) => {
          const newParams = {
            ...listQueryParams,
            continuationToken:
              toPage === 0 ? '' : mapPageToNextCursor.current[toPage],
          };

          setListQueryParams(newParams);
          setPage(toPage);
          listStorageProfile({
            variables: newParams,
          });
        }}
      />
    );
  }, [page, listQueryParams, mapPageToNextCursor, listingData, pageSize]);

  const initialValues: FormValues = {
    path: '',
  };

  const notifyOnSelectionUpdated = useCallback(
    (
      currentPath: string,
      selectionModel: GridSelectionModel,
      profile: StorageProfile,
    ) => {
      if (onSelectionModelChange) {
        onSelectionModelChange({
          selectedPath: currentPath,
          selectionModel,
          profile,
        });
      }
    },
    [],
  );

  const navigateBucket = useCallback(
    async (bucket: string, keyPath: string) => {
      setPage(0);
      mapPageToNextCursor.current = {};
      const params = {
        organizationId,
        storageProfileId: profilesByBucketName[bucket].id,
        prefix: keyPath,
        pageSize: pageSize - 1,
        continuationToken: '',
      };
      setListQueryParams(params);
      await listStorageProfile({
        variables: params,
      });
    },
    [profilesByBucketName, pageSize],
  );

  const onListItemClick = useCallback(
    async (
      args: GridCellParams,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
      ) => void,
    ) => {
      if (
        !(args.row['type'] === 'Folder' || args.row['type'] === 'Bucket') ||
        args.row['isRegionCompatible'] === false ||
        args.row['isWarehouse'] === true
      ) {
        return;
      }
      const isGoBack = args.value === '..';
      const proto = getSchemeForStorageType(args.row['storageType']);
      if (isGoBack && args.row['type'] === 'Bucket') {
        setIsTopLevel(true);
        setFieldValue('path', proto ? `${proto}://` : '', false);
        setSelectionModel([]); //reset selection if they go back to the top
        return;
      }
      setIsTopLevel(false);
      const bucket = args.row['bucket'];
      const parsedPath = parsePath(`${proto}://${bucket}/${args.row['path']}`);
      const sliceEnd = isGoBack
        ? parsedPath.pathParts.length - 2
        : parsedPath.pathParts.length;

      const path: string = `${proto}://${parsedPath.pathParts
        .slice(0, sliceEnd)
        .join('/')}${isGoBack && parsedPath.pathParts.length > 3 ? '/' : ''}`;
      const keyPath: string = `${parsedPath.pathParts
        .slice(1, sliceEnd)
        .join('/')}${isGoBack && parsedPath.pathParts.length > 3 ? '/' : ''}`;
      setFieldValue('path', path, false);
      await navigateBucket(bucket, `${keyPath}`);
      notifyOnSelectionUpdated(
        path,
        selectionModel,
        profilesByBucketName[bucket],
      );
    },
    [profilesByBucketName, pageSize, selectionModel],
  );

  const onSubmit = useCallback(
    async (values: FormValues, { setSubmitting }: FormikValues) => {},
    [],
  );

  const buckets: StorageProfile[] = useMemo(() => {
    const filteredBuckets = bucketData?.organization?.storageProfiles.filter(
      (bucket: StorageProfile) => isValidImportSource(bucket),
    );
    if (filteredBuckets && filteredBuckets.length > 0) {
      const profilesByBucketName = keyBy(filteredBuckets, 'bucket');
      setProfilesByBucketName(profilesByBucketName);
      const profilesById = keyBy(filteredBuckets, 'id');
      setProfilesByProfileId(profilesById);
    }
    return filteredBuckets;
  }, [bucketData]);

  let rows: IBrowseItem[] = useMemo(() => {
    if (isTopLevel && buckets !== undefined) {
      return buckets.map((bucket) => {
        return {
          id: bucket.bucket,
          name: bucket.bucket,
          path: '',
          region: bucket.region,
          bucket: bucket.bucket,
          type: 'Bucket',
          storageType: bucket.storageType,
          warehouseName: '',
          isWarehouse: false,
          isRegionCompatible:
            (regionFilter === undefined ||
              regionFilter.length == 0 ||
              regionFilter.includes(bucket.region)) &&
            (storageTypeFilter === undefined ||
              storageTypeFilter.length == 0 ||
              storageTypeFilter.includes(bucket.storageType!)),
          isSelectable: false,
          isAuthorized: bucket.requesterHasUsage,
        };
      });
    } else if (buckets !== undefined) {
      if (listingData?.listStorageProfile) {
        //setListing(listingData.listStorageProfile);
        let currentPageRows = [
          {
            id: `back${page}`,
            name: `..`,
            region: null,
            type: isSecondToTopLevel ? 'Bucket' : 'Folder',
            path: listQueryParams.prefix,
            storageType:
              profilesByProfileId[listQueryParams.storageProfileId!]
                .storageType,
            bucket:
              profilesByProfileId[listQueryParams.storageProfileId!].bucket,
            isWarehouse: false,
            isRegionCompatible: true,
            isSelectable: false,
            isAuthorized:
              profilesByProfileId[listQueryParams.storageProfileId!]
                .requesterHasUsage,
          },
          ...listingData?.listStorageProfile?.directories.map(
            (directory: string) => {
              const directoryParts = directory.split('/');
              return {
                id: directory,
                path: directory,
                name: `${directoryParts[directoryParts.length - 2]}${
                  !!warehousePrefixes[
                    directoryParts[directoryParts.length - 2]
                  ] && isSecondToTopLevel
                    ? ` (${
                        warehousePrefixes[
                          directoryParts[directoryParts.length - 2]
                        ].name
                      })`
                    : ''
                }`,
                region:
                  profilesByProfileId[listQueryParams.storageProfileId!].region,
                type:
                  !!warehousePrefixes[
                    directoryParts[directoryParts.length - 2]
                  ] && isSecondToTopLevel
                    ? 'Warehouse'
                    : 'Folder',
                bucket:
                  profilesByProfileId[listQueryParams.storageProfileId!].bucket,
                storageType:
                  profilesByProfileId[listQueryParams.storageProfileId!]
                    .storageType,
                isWarehouse:
                  !!warehousePrefixes[
                    directoryParts[directoryParts.length - 2]
                  ] && isSecondToTopLevel,
                warehouseName:
                  warehousePrefixes[directoryParts[directoryParts.length - 2]]
                    ?.name,
                isRegionCompatible: true,
                isSelectable:
                  !(
                    warehousePrefixes[
                      directoryParts[directoryParts.length - 2]
                    ] && isSecondToTopLevel
                  ) &&
                  (selectionMode ===
                    StorageBrowserSelectionMode.FILES_FOLDERS ||
                    selectionMode ==
                      StorageBrowserSelectionMode.SINGLE_FILE_OR_FOLDER),
                isAuthorized:
                  profilesByProfileId[listQueryParams.storageProfileId!]
                    .requesterHasUsage,
              };
            },
          ),
          ...listingData.listStorageProfile.files
            .filter(
              (file: StorageProfileFile) =>
                file.fileName !== listQueryParams.prefix,
            )
            .map((file: StorageProfileFile) => {
              const fileParts = file.fileName.split('/');
              return {
                id: file.fileName,
                path: file.fileName,
                name: fileParts[fileParts.length - 1],
                timestamp: file.lastModified,
                region:
                  profilesByProfileId[listQueryParams.storageProfileId!].region,
                type: getFileExtension(file.fileName),
                size: file.fileSize,
                bucket:
                  profilesByProfileId[listQueryParams.storageProfileId!].bucket,
                storageType:
                  profilesByProfileId[listQueryParams.storageProfileId!]
                    .storageType,
                isWarehouse: false,
                warehouseName: '',
                isRegionCompatible: true,
                isSelectable: true,
                isAuthorized:
                  profilesByProfileId[listQueryParams.storageProfileId!]
                    .requesterHasUsage,
              };
            }),
        ];

        return currentPageRows;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [
    pageSize,
    page,
    listingData,
    profilesByProfileId,
    listQueryParams,
    warehousePrefixes,
    isSecondToTopLevel,
  ]);

  const columns = useMemo(
    () => [
      {
        field: FIELD_NAME,
        headerName: 'Name',
        minWidth: 400,
        editable: false,
        flex: 1,
        cellClassName: (params: GridCellParams<number>) =>
          (params.row['isWarehouse'] && isSecondToTopLevel) ||
          !params.row['isRegionCompatible'] ||
          !params.row['isAuthorized']
            ? 'disabled-nav-column'
            : params.row['type'] === 'Folder' || params.row['type'] === 'Bucket'
            ? 'nav-column'
            : '',
        renderCell: (params: any) => {
          const isWarehouse = params.row.isWarehouse && isSecondToTopLevel;
          const regionCompatible = params.row.isRegionCompatible;
          const authorized = params.row.isAuthorized;
          return (
            <Tooltip
              title={
                authorized && regionCompatible && !isWarehouse
                  ? ''
                  : authorized && !regionCompatible
                  ? 'Region not compatible'
                  : !authorized && regionCompatible
                  ? 'Insufficient permissions on storage profile'
                  : !authorized && !regionCompatible
                  ? 'Insufficient permissions and region not compatible'
                  : 'Cannot select a Tabular warehouse'
              }
            >
              <Typography>{params.value}</Typography>
            </Tooltip>
          );
        },
      },
      {
        field: 'timestamp',
        headerName: 'Timestamp',
        minWidth: 250,
        sortable: true,
        renderCell: (params: GridRenderCellParams) =>
          params.value && (
            <Tooltip
              title={new Date(params.value).toUTCString()}
              placement={'top'}
            >
              <Typography variant={`inputText`} whiteSpace={`nowrap`}>
                {getDifferenceInHours(new Date(), new Date(params.value)) < 24
                  ? relativeTimeAutoFormat(params.value)
                  : new Date(params.value).toUTCString()}
              </Typography>
            </Tooltip>
          ),
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        maxWidth: 100,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        sortable: true,
        renderCell: (params: any) => {
          if (params.row.type !== 'Bucket') {
            return <Box>{params.value}</Box>;
          }
          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                    {...bindHover(popupState)}
                  >
                    <Box>
                      {params.value && (
                        <img
                          alt={params.value}
                          src={getImgSrcForStorageType(
                            params?.row?.storageType,
                          )}
                          style={{
                            height: '30px',
                            width: '30px',
                            margin: '0 auto',
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Popper
                    sx={{ zIndex: 11000 }}
                    {...bindPopper(popupState)}
                    placement={`right-start`}
                    modifiers={[
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['bottom'],
                        },
                      },
                    ]}
                  >
                    <ListPopover
                      title={params?.row[FIELD_NAME]}
                      listItems={[params?.row?.storageType]}
                    />
                  </Popper>
                </>
              )}
            </PopupState>
          );
        },
      },
      {
        field: 'size',
        headerName: 'Size',
        minWidth: 100,
        editable: false,
        type: 'number',
        sortComparator: sizeComparator,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value ? (
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box {...bindHover(popupState)}>
                      <Typography variant={`inputText`}>
                        {getByteSize(params.value)}
                      </Typography>
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <Box>{params.value}</Box>
                    </Popper>
                  </>
                )}
              </PopupState>
            ) : (
              '--'
            )}
          </>
        ),
      },

      {
        field: 'region',
        headerName: 'Region',
        minWidth: 100,
        editable: false,
        align: 'left',
      },
    ],
    [organizationId, isSecondToTopLevel],
  );

  return (
    <Box
      {...props}
      sx={[
        props.sx as SystemStyleObject,
        { display: 'flex', flexDirection: 'column' },
      ]}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, setFieldValue, values }) => (
          <Form
            style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
          >
            <TextField
              name={'path'}
              label={
                selectionMode === StorageBrowserSelectionMode.PATH
                  ? 'Selected Path'
                  : 'Path'
              }
              fullWidth
              onKeyDown={async (args) => {
                if (args.key === 'Enter') {
                  const parsedPath = parsePath(values['path']);
                  await navigateBucket(parsedPath.bucket, parsedPath.keyPath);
                  notifyOnSelectionUpdated(
                    values['path'],
                    selectionModel,
                    profilesByProfileId[listQueryParams.storageProfileId!],
                  );
                }
              }}
            />

            <DataGridPro
              apiRef={gridApiRef}
              keepNonExistentRowsSelected
              selectionModel={selectionModel}
              checkboxSelection={
                selectionMode == StorageBrowserSelectionMode.FILES_FOLDERS ||
                selectionMode == StorageBrowserSelectionMode.SINGLE_FILE ||
                selectionMode ==
                  StorageBrowserSelectionMode.SINGLE_FILE_OR_FOLDER
              }
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              disableSelectionOnClick
              pagination={true}
              paginationMode="server"
              page={page}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rows={rows || []}
              columns={columns as GridColumns}
              headerHeight={48}
              disableColumnMenu
              onCellClick={(args) => {
                if (args.field === FIELD_NAME) {
                  onListItemClick(args, setFieldValue);
                }
              }}
              isRowSelectable={(args) =>
                args.row['isSelectable'] &&
                (selectionMode === StorageBrowserSelectionMode.FILES_FOLDERS ||
                  ((selectionMode === StorageBrowserSelectionMode.SINGLE_FILE ||
                    selectionMode ==
                      StorageBrowserSelectionMode.SINGLE_FILE_OR_FOLDER) &&
                    selectionModel.length == 0) ||
                  selectionModel.some(
                    (eachSelected) => eachSelected === args.row.id,
                  ))
              }
              initialState={{
                sorting: {
                  sortModel: [{ field: 'created', sort: 'desc' }],
                },
                pinnedColumns: { right: ['active', 'delete'] },
                pagination: { page: 0, pageSize: 10 },
              }}
              components={{
                Pagination: CustomPagination,
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {isTopLevel ? 'No buckets' : 'No files or folders'}
                  </Stack>
                ),
              }}
              loading={bucketLoading || listingLoading}
              sx={[
                ...(DatagridSx
                  ? [DatagridSx]
                  : [{ minHeight: 200, flexGrow: 1 }]),

                {
                  border: 'none',
                  '.MuiDataGrid-columnHeaders': {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  },
                  // disable cell selection style
                  '.MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  // pointer cursor on ALL rows
                  '& .nav-column:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  },
                  '& .disabled-nav-column:hover': {
                    cursor: 'not-allowed',
                  },
                  '& .disabled-nav-column': {
                    color: 'midnight.four',
                  },
                  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                    {
                      display:
                        selectionMode ===
                          StorageBrowserSelectionMode.SINGLE_FILE ||
                        selectionMode ===
                          StorageBrowserSelectionMode.SINGLE_FILE_OR_FOLDER
                          ? 'none'
                          : '',
                    },
                },
              ]}
            />
            <DialogActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Box>
                <Tabutton
                  onClick={() => {
                    if (
                      selectionMode ===
                        StorageBrowserSelectionMode.FILES_FOLDERS ||
                      selectionMode ==
                        StorageBrowserSelectionMode.SINGLE_FILE ||
                      selectionMode ==
                        StorageBrowserSelectionMode.SINGLE_FILE_OR_FOLDER
                    ) {
                      setSelectionModel([]);
                    } else {
                      onCancel();
                    }
                  }}
                >
                  {selectionMode ===
                    StorageBrowserSelectionMode.FILES_FOLDERS ||
                  selectionMode == StorageBrowserSelectionMode.SINGLE_FILE ||
                  selectionMode ==
                    StorageBrowserSelectionMode.SINGLE_FILE_OR_FOLDER
                    ? 'Clear selection'
                    : 'Cancel'}
                </Tabutton>
                <Tabutton
                  variant="contained"
                  disabled={
                    isSubmitting ||
                    ((selectionMode ===
                      StorageBrowserSelectionMode.FILES_FOLDERS ||
                      selectionMode ==
                        StorageBrowserSelectionMode.SINGLE_FILE ||
                      selectionMode ==
                        StorageBrowserSelectionMode.SINGLE_FILE_OR_FOLDER) &&
                      selectionModel.length == 0)
                  }
                  onClick={() => {
                    onFinalSelection({
                      selectedPath: values['path'],
                      selectionModel:
                        selectionMode === StorageBrowserSelectionMode.PATH
                          ? [parsePath(values['path']).keyPath]
                          : selectionModel,
                      profile:
                        profilesByProfileId[listQueryParams.storageProfileId!],
                    });
                  }}
                >
                  {selectionMode ===
                    StorageBrowserSelectionMode.FILES_FOLDERS ||
                  selectionMode == StorageBrowserSelectionMode.SINGLE_FILE ||
                  selectionMode ==
                    StorageBrowserSelectionMode.SINGLE_FILE_OR_FOLDER
                    ? `Select ${selectionModel.length} items`
                    : 'Select path'}
                </Tabutton>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
