import React from 'react';

import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SecurityIcon from '@mui/icons-material/Security';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { Tavatar } from '../Avatar/Tavatar';
import { TaBadge } from '../Badge/TaBadge';

export default function UserInfo({
  user,
  children,
  showAdmin = false,
  currentUser = false,
  securityAdmin = false,
  orgAdmin = false,
  tabularService = false,
  showBadge = false,
  showBorder = true,
}: {
  user: any;
  children?: any;
  showAdmin?: boolean;
  currentUser?: boolean;
  securityAdmin?: boolean;
  orgAdmin?: boolean;
  tabularService?: boolean;
  showBadge?: boolean;
  showBorder?: boolean;
}) {
  const profilePic =
    user?.loginSession?.membership?.properties?.[`picture.url`] ||
    user?.properties?.[`picture.url`] ||
    user?.['pictureUrl'] ||
    user?.profilePic;

  const displayName =
    user?.loginSession?.membership?.displayName || user?.displayName;

  const userEmail = user?.email || user?.user?.email;

  return (
    <Box
      display="flex"
      flexGrow={1}
      alignItems="center"
      p={1}
      sx={(theme) => ({
        border: showBorder ? `1px solid ${theme.palette.midnight.two}` : `none`,
        borderRadius: '4px',
      })}
    >
      <Tavatar
        roleAdmin={showAdmin}
        profilePic={profilePic}
        displayName={displayName}
        currentUser={currentUser}
        securityAdmin={securityAdmin}
        orgAdmin={orgAdmin}
        tabularService={tabularService}
      />
      <Box flexGrow={1} px={2}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="h6" sx={{ color: 'midnight.seven' }}>
            {tabularService
              ? 'Tabular Service'
              : user?.loginSession?.membership?.displayName || user.displayName}
          </Typography>
        </Box>
        {showAdmin && (
          <Box>
            <Typography variant="helperText" sx={{ color: 'midnight.seven' }}>
              Role Admin
            </Typography>
          </Box>
        )}
        {userEmail !== '' && (
          <Box>
            <Typography variant="helperText" sx={{ color: 'midnight.seven' }}>
              {userEmail}
            </Typography>
          </Box>
        )}
        {showBadge && (
          <>
            {(securityAdmin || orgAdmin) && (
              <Box sx={{ mt: 1 }}>
                <TaBadge
                  text={orgAdmin ? 'ORG ADMIN' : 'SECURITY ADMIN'}
                  //@ts-ignore
                  icon={
                    orgAdmin ? (
                      <SecurityIcon sx={{ fontSize: '16px', marginLeft: 1 }} />
                    ) : (
                      <LocalPoliceIcon
                        sx={{ fontSize: '16px', marginLeft: 1 }}
                      />
                    )
                  }
                />
              </Box>
            )}
          </>
        )}
      </Box>
      {children}
    </Box>
  );
}
