import React, { useEffect, useMemo, useState } from 'react';

import { Box, Link, Popper, Skeleton, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  gridVisibleRowCountSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { toWarehouseRoot } from '../../RouteTable';
import {
  StorageProfile,
  StorageType,
  Warehouse,
} from '../../graphql/gen/graphql';
import {
  commitComparator,
  getByteSize,
  getCommitFrequency,
  getSizeDifference,
  getSizeDifferencePercentage,
  lastUpdateComparator,
  sizeComparator,
} from '../../pages/helpers';
import {
  formatDateToMonDayYear,
  getDifferenceInDays,
  relativeTimeAutoFormat,
} from '../../utils/time';
import { Tacard } from '../Card/Tacard';
import {
  CustomPaging,
  DataGridToolBar,
} from '../DataGridToolbar/DataGridToolbar';
import DataSourceCard from '../DataSources/DataSourceCard';
import { showCreateWarehouseDialog } from '../Modals/CreateWarehouseDialog';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { CommitsPopover } from '../Popovers/CommitsPopover';
import { LastUpdateModifiedByPopover } from '../Popovers/LastUpdateModifiedByPopover';
import { ListPopover } from '../Popovers/ListPopover';
import { TrendPopover } from '../Popovers/TrendPopover';

export default function ConnectedWarehouses({
  profile,
  storageProfileType,
  orgName,
  loading,
  addResourceContent,
  refreshResourceContent,
  dashboardMenuContent,
  isSecurityAdmin,
  user,
}: {
  profile: StorageProfile;
  storageProfileType: StorageType;
  orgName: string;
  loading: boolean;
  addResourceContent: any;
  refreshResourceContent: any;
  dashboardMenuContent: any;
  isSecurityAdmin: boolean;
  user: any;
}) {
  const [visibleRowCount, setVisibleRowCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const apiRef = useGridApiRef();

  const connectedWarehouseRows = useMemo(() => {
    return (
      profile?.warehouses?.map((wh: any) => {
        return {
          id: wh.id,
          name: wh.name,
          region: wh.region,
          type: storageProfileType,
          managed: wh.managed,
          bucketName: profile?.bucket,
          lastUpdate: {
            lastModified: wh.lastModified,
            lastModifiedBy: wh.lastModifiedBy,
          },
          commits: {
            commitCnt: wh.statistics?.commitCnt,
            opAppendCnt: wh.statistics?.opAppendCnt,
            opOverwriteCnt: wh.statistics?.opOverwriteCnt,
            opDeleteCnt: wh.statistics?.opDeleteCnt,
            opReplaceCnt: wh.statistics?.opReplaceCnt,
            createdAt: wh.createdAt,
          },
          size: {
            bytesAdded: wh.statistics?.bytesAdded,
            bytesRemoved: wh.statistics?.bytesRemoved,
            totalBytes: wh.statistics?.totalBytes,
          },
          totalDatabases: wh.statistics?.totalNamespaces,
          totalTables: wh.statistics?.totalTables,
        };
      }) || []
    );
  }, [profile, storageProfileType]);

  const connectedWarehousesColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 200,
        flex: 2,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 100%',
              alignItems: 'center',
              height: '100%',
              minWidth: 0,
              cursor: 'pointer',
            }}
          >
            <Link
              href={toWarehouseRoot(orgName, params.value)}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              variant={`body1`}
            >
              {params.value}
            </Link>
          </Box>
        ),
      },
      {
        field: 'totalDatabases',
        headerName: 'Databases',
        minWidth: 80,
        flex: 1,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        valueFormatter: (params: any) => {
          return params.value != 0 ? params.value : '--';
        },
      },
      {
        field: 'totalTables',
        headerName: 'Tables',
        minWidth: 80,
        flex: 1,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        valueFormatter: (params: any) => {
          return params.value != 0 ? params.value : '--';
        },
      },
      {
        field: 'lastUpdate',
        headerName: 'Last update',
        flex: 1,
        minWidth: 150,
        editable: false,
        type: 'dateTime',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: lastUpdateComparator,
        renderCell: (params: GridRenderCellParams) => (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flex: '1 1 100%',
                    alignItems: 'center',
                    height: '100%',
                    cursor: 'default',
                  }}
                >
                  <Typography variant={`inputText`} {...bindHover(popupState)}>
                    {getDifferenceInDays(
                      new Date(),
                      new Date(params.value.lastModified),
                    ) > 7
                      ? formatDateToMonDayYear(params.value.lastModified)
                      : relativeTimeAutoFormat(params.value.lastModified)}
                  </Typography>
                </Box>
                <Popper
                  {...bindPopper(popupState)}
                  placement={`right-start`}
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom'],
                      },
                    },
                  ]}
                >
                  <LastUpdateModifiedByPopover
                    modifiedBy={params.value.lastModifiedBy}
                    modifiedDate={params.value.lastModified}
                  />
                </Popper>
              </>
            )}
          </PopupState>
        ),
      },
      {
        field: 'commits',
        headerName: 'Commit frequency',
        flex: 1,
        minWidth: 150,
        editable: false,
        type: 'number',
        sortingOrder: ['desc', 'asc', null],
        sortComparator: commitComparator,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value.commitCnt ? (
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '1 1 100%',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-end',
                        cursor: 'default',
                      }}
                    >
                      <Typography
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {getCommitFrequency(
                          params.value.commitCnt,
                          params.value.createdAt,
                        )}
                      </Typography>
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <CommitsPopover
                        commitsData={[
                          { label: 'Append', count: params.value.opAppendCnt },
                          {
                            label: 'Replace',
                            count: params.value.opReplaceCnt,
                          },
                          {
                            label: 'Overwrite',
                            count: params.value.opOverwriteCnt,
                          },
                          { label: 'Delete', count: params.value.opDeleteCnt },
                        ]}
                      />
                    </Popper>
                  </>
                )}
              </PopupState>
            ) : (
              '--'
            )}
          </>
        ),
      },
      {
        field: 'size',
        headerName: 'Size',
        minWidth: 100,
        flex: 1,
        editable: false,
        type: 'number',
        sortComparator: sizeComparator,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value?.totalBytes ? (
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '1 1 100%',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        height: '100%',
                        cursor: 'default',
                      }}
                    >
                      <Typography
                        variant={`inputText`}
                        {...bindHover(popupState)}
                      >
                        {getByteSize(params.value.totalBytes)}
                      </Typography>
                    </Box>
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right-start`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <TrendPopover
                        added={getByteSize(params.value?.bytesAdded)}
                        removed={getByteSize(params.value?.bytesRemoved)}
                        total={getSizeDifference(
                          params.value?.bytesAdded,
                          params.value?.bytesRemoved,
                          'bytes',
                        )}
                        icon={
                          getSizeDifferencePercentage(
                            params.value?.bytesAdded,
                            params.value?.bytesRemoved,
                            params.value.totalBytes,
                          ).icon
                        }
                        diff={
                          getSizeDifferencePercentage(
                            params.value?.bytesAdded,
                            params.value?.bytesRemoved,
                            params.value.totalBytes,
                          ).value
                        }
                      />
                    </Popper>
                  </>
                )}
              </PopupState>
            ) : (
              '--'
            )}
          </>
        ),
      },
    ],
    [connectedWarehouseRows],
  );

  useEffect(() => {
    //we annoyingly don't get visible row count onFilterModelChange so this is solution
    //for setting appropriate grid height for quick filter results
    profile &&
      apiRef.current.subscribeEvent('stateChange', (params) => {
        const count = gridVisibleRowCountSelector(apiRef.current.state);
        setVisibleRowCount(count);
      });
  }, [profile, apiRef]);

  return (
    <>
      {!profile ? (
        <Skeleton />
      ) : (
        <>
          <DataSourceCard
            profile={profile}
            orgName={orgName}
            isSecurityAdmin={isSecurityAdmin}
            showActions={false}
            currentUserEmail={user?.email}
            spaceX={false}
          />
          <Tacard grid>
            <DataGridPro
              autoHeight={
                connectedWarehouseRows?.length > 0 || visibleRowCount > 0
              }
              apiRef={apiRef}
              pagination={connectedWarehouseRows?.length >= 100}
              hideFooter={connectedWarehouseRows?.length < 100}
              rowsPerPageOptions={[10, 25, 50, 100]}
              rows={connectedWarehouseRows || []}
              columns={connectedWarehousesColumns as GridColumns}
              headerHeight={34}
              disableColumnMenu
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
                pagination: { page: 0, pageSize: 100 },
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterValues: [''],
                  },
                },
              }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              components={{
                LoadingOverlay: LinearProgress,
                Toolbar: DataGridToolBar,
                Pagination: CustomPaging,
                NoRowsOverlay: OhNoesRows,
                NoResultsOverlay: OhNoesRows,
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  apiRef: apiRef,
                  visibleRowCount: visibleRowCount,
                  pageSize: pageSize,
                  setPageSize: setPageSize,
                  headerName: (
                    <Box display={'flex'} paddingLeft={2} alignItems={'center'}>
                      {'Warehouses'}
                    </Box>
                  ),
                  refreshResourceContent: refreshResourceContent,
                  addResourceContent: addResourceContent,
                  dashboardMenuContent: dashboardMenuContent,
                  rowCount: connectedWarehouseRows?.length,
                  showRange: connectedWarehouseRows?.length >= 100,
                  pageAtNumber: 100,
                },
                pagination: {
                  apiRef: apiRef,
                  pageSize: pageSize,
                  setPageSize: setPageSize,
                  rowCount: connectedWarehouseRows?.length,
                },
                noRowsOverlay: {
                  buttonControl: (
                    <Typography variant={`h1`} color={`brandBlue.main`}>
                      No warehouses
                    </Typography>
                  ),
                },
                noResultsOverlay: {
                  buttonControl: (
                    <Typography variant={`h1`} color={`brandBlue.main`}>
                      No warehouses
                    </Typography>
                  ),
                },
              }}
              loading={loading || !profile}
              sx={{
                ...(connectedWarehouseRows?.length === 0 ||
                visibleRowCount === 0
                  ? { minHeight: 600 }
                  : {}),
                border: 'none',
                '.MuiDataGrid-columnHeaders': {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                },
                '.MuiDataGrid-pinnedColumnHeaders': {
                  backgroundColor: 'dusk.half',
                },
              }}
            />
          </Tacard>
        </>
      )}
    </>
  );
}
