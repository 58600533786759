import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Link,
  Popper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGridPro,
  GridColumns,
  GridFilterModel,
  GridRenderCellParams,
  GridSortModel,
  useGridApiRef,
  gridVisibleRowCountSelector,
} from '@mui/x-data-grid-pro';
import { some } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { toDatabaseRoot, toWarehouseRoot } from '../../RouteTable';
import UserInfo from '../../components/ProfileMenu/UserInfo';
import { useAuth } from '../../context/auth-context';
import { DroppedTable } from '../../graphql/gen/graphql';
import { orgDroppedTables } from '../../graphql/organization';
import { calculateTextWidth, getByteSize } from '../../pages/helpers';
import { relativeTimeAutoFormat } from '../../utils/time';
import { Tavatar } from '../Avatar/Tavatar';
import { TaBadge } from '../Badge/TaBadge';
import { Refresh } from '../Button/Refresh';
import { Tabutton } from '../Button/Tabutton';
import { showRestoreTableDialog } from '../Modals/RestoreTableDialog';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { PageHeader } from '../PageHeader/PageHeader';
import { ArrowPosition, DatePopover } from '../Popovers/DatePopover';
import { NamePopover } from '../Popovers/NamePopover';
import {
  CustomPagination,
  droppedByComparator,
  formerLocationComparator,
  getApplyFilterFnDroppedBy,
  getApplyFilterFnFormerLocation,
  QuickSearchToolbar,
} from './helpers';

export default function SpecialTrash({
  organizationId,
}: {
  organizationId: string;
}) {
  const { user } = useAuth();
  const { organization: orgPath } = useParams();
  const orgName = orgPath || 'unknown';
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState(
    location?.state ? location?.state?.searchTerm : '',
  );
  const [visibleRowCount, setVisibleRowCount] = useState<number>(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'droppedOn',
      sort: 'desc',
    },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [pageSize, setPageSize] = useState(100);
  const apiRef = useGridApiRef();
  const currentUserEmail = useMemo(
    () => user?.loginSession?.membership?.email || user?.email,
    [user],
  );
  const userIsSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(orgName),
    [user, orgName],
  );
  const { data, loading, error, refetch } = useQuery(orgDroppedTables, {
    variables: {
      organizationId,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const rows = useMemo(() => {
    return data?.organization?.droppedTables.map(
      (table: DroppedTable, index: number) => {
        return {
          id: `${table.id}-table-${index}`,
          tableRefId: table.id,
          organizationId: organizationId,
          name: table.name,
          droppedBy: {
            droppedByEmail: table?.droppedBy,
            droppedByMember: table?.droppedByMember,
          },
          droppedOn: table?.droppedOn,
          formerLocation: {
            warehouseId: table?.warehouseId,
            warehouseName: table?.warehouseName,
            databaseName: table?.namespaceName,
            databaseId: table?.namespaceId,
          },
          size: table?.totalBytes,
          canRestore:
            userIsSecurityAdmin ||
            some(table.privileges, (priv) => priv === 'DROP'),
        };
      },
    );
  }, [data, userIsSecurityAdmin]);

  const columns = useMemo(
    () => [
      {
        field: 'restore',
        headerName: '',
        width: 120,
        editable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Tabutton
              variant={`outlined`}
              size={`small`}
              onClick={() => showRestoreTableDialog(params.row, orgName)}
              disabled={!params.row.canRestore}
              sx={{
                color: params.row.canRestore ? 'sky.seven' : 'midnight.five',
              }}
            >
              RESTORE
            </Tabutton>
          </Box>
        ),
      },
      {
        field: 'name',
        headerName: 'Table',
        minWidth: 200,
        flex: 1.5,
        editable: false,
        sortingOrder: ['desc', 'asc', null],
        renderCell: (params: GridRenderCellParams) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
          );
          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: '1 1 100%',
                      alignItems: 'center',
                      height: '100%',
                      minWidth: 0,
                      cursor:
                        textWidth >= params.colDef.computedWidth ||
                        params.row?.comment?.value
                          ? 'pointer'
                          : 'default',
                    }}
                    {...bindHover(popupState)}
                  >
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontWeight: 600,
                        color: 'sky.seven',
                      }}
                      variant={`inputText`}
                    >
                      {params.value}
                    </Typography>
                  </Box>
                  {textWidth >= params.colDef.computedWidth && (
                    <Popper
                      {...bindPopper(popupState)}
                      placement={`right`}
                      modifiers={[
                        {
                          name: 'flip',
                          options: {
                            fallbackPlacements: ['bottom'],
                          },
                        },
                      ]}
                    >
                      <NamePopover name={params.value} />
                    </Popper>
                  )}
                </>
              )}
            </PopupState>
          );
        },
      },
      {
        field: 'droppedOn',
        headerName: 'Dropped',
        width: 160,
        editable: false,
        sortingOrder: ['desc', 'asc', null],
        getApplyQuickFilterFn: undefined,
        renderCell: (params: GridRenderCellParams) => (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  {...bindHover(popupState)}
                  sx={{
                    cursor: 'default',
                  }}
                >
                  <Typography variant={`inputText`}>
                    {params.value ? relativeTimeAutoFormat(params.value) : '--'}
                  </Typography>
                </Box>
                <Popper
                  {...bindPopper(popupState)}
                  placement={`right`}
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom'],
                      },
                    },
                  ]}
                >
                  <DatePopover modifiedDate={params.value} />
                </Popper>
              </>
            )}
          </PopupState>
        ),
      },
      {
        field: 'droppedBy',
        headerName: 'Dropped By',
        flex: 1,
        minWidth: 120,
        editable: false,
        sortComparator: droppedByComparator,
        sortingOrder: ['desc', 'asc', null],
        getApplyQuickFilterFn: getApplyFilterFnDroppedBy,
        renderCell: (params: GridRenderCellParams) => (
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  {...bindHover(popupState)}
                >
                  <Tavatar
                    displayName={params.value?.droppedByMember?.displayName}
                    profilePic={
                      params.value.droppedByMember?.properties?.['picture.url']
                    }
                    tabularService={params.value.droppedBy === ''}
                    currentUser={
                      currentUserEmail ===
                      params.value.droppedByMember?.user?.email
                    }
                  />
                  <Typography
                    variant={`inputText`}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      ml: 1,
                    }}
                  >
                    {params.value.droppedByMember?.displayName}
                  </Typography>
                </Box>
                <Popper {...bindPopper(popupState)} placement={`top`}>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: 'controlBackground.main',
                      borderRadius: '4px',
                      boxShadow: theme.shadows[3],
                    })}
                  >
                    <UserInfo
                      user={{
                        displayName: params.value.droppedByMember?.displayName,
                        profilePic:
                          params.value.droppedByMember?.properties?.[
                            'picture.url'
                          ],
                        email: params.value.droppedByMember?.user?.email,
                      }}
                      currentUser={
                        currentUserEmail === params.value.droppedByMember?.email
                      }
                    />
                  </Box>
                </Popper>
              </>
            )}
          </PopupState>
        ),
      },
      {
        field: 'formerLocation',
        headerName: 'Former location',
        minWidth: 400,
        flex: 2,
        editable: false,
        sortingOrder: ['desc', 'asc', null],
        sortComparator: formerLocationComparator,
        getApplyQuickFilterFn: getApplyFilterFnFormerLocation,
        renderCell: (params: GridRenderCellParams) => (
          <Box display={`flex`} alignItems={`center`}>
            <Link
              href={toWarehouseRoot(orgName || '', params.value.warehouseName)}
              variant={`subtitle2`}
              color={`sky.seven`}
              sx={{ textDecoration: 'none' }}
            >
              {params.value.warehouseName}
            </Link>
            <ArrowForwardIosIcon
              fontSize={`small`}
              sx={{ color: 'midnight.two', mx: 1 }}
            />
            <Link
              href={toDatabaseRoot(
                orgName || '',
                params.value.warehouseName,
                params.value.databaseName,
              )}
              variant={`subtitle2`}
              color={`${
                !params.value.databaseId ? 'midnight.three' : 'sky.seven'
              }`}
              sx={{
                textDecoration: 'none',
                pointerEvents: `${
                  !params.value.databaseId ? 'none' : 'inherit'
                }`,
              }}
            >
              {params.value.databaseName}
            </Link>
            {!params.value.databaseId && (
              <Box sx={{ ml: 2 }}>
                <TaBadge text={`DROPPED`} bgColor={`sunrise.one`} />
              </Box>
            )}
          </Box>
        ),
      },
      {
        field: 'size',
        headerName: 'Size',
        headerAlign: 'right',
        align: 'right',
        editable: false,
        width: 160,
        sortingOrder: ['desc', 'asc', null],
        getApplyQuickFilterFn: undefined,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.value ? (
              <Typography>{getByteSize(params.value)}</Typography>
            ) : (
              '--'
            )}
          </>
        ),
      },
    ],
    [orgName],
  );

  useEffect(() => {
    //we annoyingly don't get visible row count onFilterModelChange so this is solution
    //for setting appropriate grid height for quick filter results
    apiRef.current.subscribeEvent('stateChange', (params) => {
      const count = gridVisibleRowCountSelector(apiRef.current.state);
      setVisibleRowCount(count);
    });
  }, [apiRef]);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  if (error && !data) {
    return <Navigate to="/error" replace />;
  }

  return (
    <>
      <PageHeader
        resourceName={`Table restore`}
        pageTitle={''}
        marginB={matchesSM ? 16 : 10}
      >
        <Refresh refreshList={() => refetch()} title={'Refresh table list'} />
      </PageHeader>
      <DataGridPro
        apiRef={apiRef}
        autoHeight={rows?.length > 0 || visibleRowCount > 0}
        pagination={rows?.length >= 100}
        rows={rows || []}
        columns={columns as GridColumns}
        headerHeight={48}
        disableColumnMenu
        isRowSelectable={() => false}
        sortModel={sortModel as GridSortModel}
        onSortModelChange={(newSortModel: GridSortModel) =>
          setSortModel(newSortModel)
        }
        filterModel={filterModel as GridFilterModel}
        onFilterModelChange={(newFilterModel: GridFilterModel) => {
          setFilterModel(newFilterModel);
          setSearchTerm('');
          // we need to clear the inital search term if navigating here from wh/db pages
          window.history.replaceState({}, document.title);
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: QuickSearchToolbar,
          NoRowsOverlay: OhNoesRows,
          NoResultsOverlay: OhNoesRows,
          Pagination: CustomPagination,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            apiRef: apiRef,
            visibleRowCount: visibleRowCount,
            pageSize: pageSize,
          },
          pagination: {
            apiRef: apiRef,
            pageSize: pageSize,
          },
          noRowsOverlay: {
            buttonControl: (
              <Typography variant={`h1`} color={`brandBlue.main`}>
                Nothing to restore
              </Typography>
            ),
          },
          noResultsOverlay: {
            buttonControl: (
              <Typography variant={`h1`} color={`brandBlue.main`}>
                No matching tables found
              </Typography>
            ),
          },
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [searchTerm],
            },
          },
        }}
        loading={loading || !data}
        sx={(theme) => ({
          ...(rows?.length === 0 || visibleRowCount === 0
            ? { minHeight: 600 }
            : {}),
          boxShadow: theme.shadows[4],
          '.MuiDataGrid-columnHeaders': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        })}
      />
    </>
  );
}
