import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Talert } from '../../../Alert/Talert';
import LoadingBackdrop from '../../../Feedback/LoadingBackdrop';

export const AthenaError = ({
  message,
  avatarRow,
  loading = false,
  error,
}: {
  message: string;
  avatarRow?: JSX.Element;
  loading?: boolean;
  error?: any;
}) => {
  return (
    <>
      <Grid
        container
        sx={{
          width: 1,
          border: 1,
          borderColor: 'midnight.two',
          borderRadius: '8px',
          mt: 2,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            padding: 1 / 2,
            pl: 2,
            borderRadius: '8px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: 1,
            borderColor: 'midnight.two',
            backgroundColor: 'dusk.half',
          }}
        >
          <Typography variant={'body1'}>{`Athena Errors:`}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: 2,
            borderBottom: 0,
            borderColor: 'midnight.two',
          }}
        >
          <Talert
            severity={'error'}
            alertTitle={'Error fetching databases or Failed to invoke lambda'}
            sx={{ mb: 1, width: 'fit-content' }}
          >
            {`You must have an AWS IAM identity provider setup in Tabular to
                          authorize lambda.`}
          </Talert>
          <Typography sx={{ display: 'inline' }}>
            {`The correct ARN needed is within
                    the Athena error message`}{' '}
            {`EX: arn:aws:sts::<aws_acct_id>:assumed-role/<role>`}
          </Typography>
          <Typography>{message}</Typography>
          {loading && <LoadingBackdrop />}
          {error && (
            <Typography color={'error'}>
              Error loading security admin list
            </Typography>
          )}
          {avatarRow && (
            <Box display={'flex'} alignContent={'flex-start'} mt={1}>
              {avatarRow}
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
