import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty, keyBy, uniq } from 'lodash';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import {
  toCloudStorageAzure,
  toCloudStorageGoogle,
  toCloudStorageS3,
  toDocsRoot,
  toProfile,
} from '../../../RouteTable';
import { StorageType } from '../../../graphql/gen/graphql';
import { Talert } from '../../Alert/Talert';
import { Tabutton } from '../../Button/Tabutton';
import RegionSelector from '../../Forms/RegionSelector';
import { StyledSwitch } from '../../Forms/Switch';
import TextField from '../../Forms/TextField';
import { Tabulink } from '../../Link/Tabulink';
import {
  AwsCreatePolicyLink,
  AwsCreateRoleLink,
  GcsServiceAccountLink,
  GcsWorkloadIdentityLink,
} from '../storage';
import { ServiceAccountInstructions } from './GoogleServiceAccountStep';
import { WorkloadInstructions } from './GoogleWorkloadStep';

const federationDataByAzureRegion = JSON.parse(
  process.env.REACT_APP_AZURE_FEDERATION ?? '{}',
);

export const getAzureCognitoPoolId = (azureRegionCode: string) => {
  return federationDataByAzureRegion[azureRegionCode]?.poolId;
};

export const getAzureCognitoSubjectId = (azureRegionCode: string) => {
  return federationDataByAzureRegion[azureRegionCode]?.subjectId;
};

export const getAzureSuggestedCredentialName = (azureRegionCode: string) => {
  return `tabular_credential_${azureRegionCode}`;
};

export type Content = {
  header: string;
  subHeader: string;
  description: React.ReactNode;
  inputs: React.ReactNode;
  bucketSettingsMessage: React.ReactNode;
};

export const CloudFormation = {
  header: 'Connection assistant',
  subHeader: 'Choose a region',
  description: (
    <>
      <Typography variant="body1">
        {`When you`}{' '}
        <Box component={`span`} sx={{ fontWeight: 'bold' }}>
          {`Launch AWS`}
        </Box>
        {`, you will be presented with
          a pre-populated `}
        <Box component={`span`} sx={{ fontStyle: 'italic' }}>
          AWS Cloud Formation template.
        </Box>
        {` The template will create
          an S3 bucket and an IAM role that is configured to allow Tabular to
          manage access to data in the bucket.`}
      </Typography>
      <Typography variant={`body1`} sx={{ mt: 2 }}>
        {`Once the cloud formation script has completed successfully, click on the `}
        <Box component={`span`} sx={{ fontWeight: 'bold' }}>
          Outputs
        </Box>{' '}
        {`tab in the AWS console to view the details of the created resource and provide them in the next step.`}
      </Typography>
    </>
  ),
  inputs: (
    <Box sx={{ pt: 2, width: '100%' }}>
      <RegionSelector
        storageType={StorageType.S3}
        textFieldProps={{
          variant: 'standard',
          id: 'region',
          helperText: 'AWS Region where the S3 bucket is located.',
          sx: { mr: 1, width: '100%' },
        }}
      />
    </Box>
  ),
  bucketSettingsMessage: (
    <Box sx={{ py: 4 }}>
      <Typography variant="body1">
        {`In AWS CloudFormation stack, click on the`}{' '}
        <Box component={`span`} sx={{ fontWeight: 'bold' }}>
          Outputs
        </Box>{' '}
        {`tab to view the details of the created resource.`}
      </Typography>
    </Box>
  ),
};

export const Manual = {
  header: 'Connection assistant',
  subHeader: 'Provide bucket name',
  description: (
    <Box>
      <Talert severity="info">
        <Typography variant={'subtitle2'}>AWS account required</Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography variant={'helperText'} sx={{ flexGrow: 1 }}>
            If you have not set up a bucket in AWS S3, learn how to set up a
            container before proceeding.
          </Typography>
          <Tabulink
            href={toDocsRoot() + '/understanding-storage-profiles.html'}
            external
            variant="body2"
            rel="noopener"
            aria-label="Tabular Documentation"
            sx={{
              whiteSpace: 'nowrap',
              mr: 1,
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            TABULAR DOCS
            <OpenInNewIcon fontSize="small" sx={{ marginLeft: '6px' }} />
          </Tabulink>
        </Box>
      </Talert>
    </Box>
  ),
  inputs: (
    <>
      <Typography variant={'h6'} sx={{ mt: 3, whiteSpace: 'nowrap' }}>
        AWS S3 storage
        <Tabulink
          href={toCloudStorageS3()}
          external
          variant="body2"
          rel="noopener"
          aria-label="AWS S3"
          title={toCloudStorageS3()}
          sx={{
            whiteSpace: 'nowrap',

            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          View your account
          <OpenInNewIcon fontSize="small" sx={{}} />
        </Tabulink>
      </Typography>
      <TextField
        name="bucketName"
        type="text"
        label="Bucket name"
        id="bucketName"
        variant="standard"
        margin="normal"
        autoComplete="off"
        helperText="Amazon S3→Buckets→<YourExistingBucketName>"
        fullWidth
      />
    </>
  ),
  bucketSettingsMessage: (
    <Box sx={{ py: 4 }}>
      <Typography variant="body1">
        {`In the AWS IAM Management console, click on the role you created and click to copy the IAM role ARN and paste it below.`}
      </Typography>
    </Box>
  ),
};

export const Google = {
  header: 'Connection assistant',
  subHeader: 'Storage profile creation',
  description: (
    <Box>
      <Talert severity="info">
        <Typography variant={'subtitle2'}>
          Google cloud services account required
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography variant={'helperText'} sx={{ flexGrow: 1 }}>
            If you have not set up Google cloud storage, learn how to set up a
            container before proceeding.
          </Typography>
          <Tabulink
            href={toDocsRoot() + '/understanding-storage-profiles.html'}
            external
            variant="body2"
            rel="noopener"
            aria-label="Tabular Documentation"
            sx={{
              whiteSpace: 'nowrap',
              mr: 1,
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            TABULAR DOCS
            <OpenInNewIcon fontSize="small" sx={{ marginLeft: '6px' }} />
          </Tabulink>
        </Box>
      </Talert>
    </Box>
  ),
  inputs: (
    <Box sx={{ pt: 2, width: '100%' }}>
      <Typography variant={'h6'} sx={{ mt: 3, whiteSpace: 'nowrap' }}>
        Google cloud storage
        <Tabulink
          href={toCloudStorageGoogle()}
          external
          variant="body2"
          rel="noopener"
          aria-label="Google cloud storage"
          title={toCloudStorageGoogle()}
          sx={{
            whiteSpace: 'nowrap',

            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          View your account
          <OpenInNewIcon fontSize="small" sx={{}} />
        </Tabulink>
      </Typography>
      <TextField
        name="bucketName"
        type="text"
        label="Bucket name"
        id="bucketName"
        variant="standard"
        margin="normal"
        autoComplete="off"
        helperText="Cloud Storage→Buckets→<YourExistingBucketName>"
        fullWidth
      />
      <RegionSelector
        storageType={StorageType.Gcs}
        textFieldProps={{
          variant: 'standard',
          id: 'region',
          helperText: 'GCP Region where the bucket is located.',
          sx: { mr: 1, width: '100%' },
          type: 'google',
        }}
      />
    </Box>
  ),
  bucketSettingsMessage: (
    <Box sx={{ pb: 4 }}>
      <Typography variant="body1">{`Review Google cloud service settings`}</Typography>
    </Box>
  ),
};

export const Azure = {
  header: 'Connection assistant',
  subHeader: 'Storage profile creation',
  description: (
    <Box>
      <Talert severity="info">
        <Typography variant={'subtitle2'}>
          Microsoft Azure account required
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography variant={'helperText'} sx={{ flexGrow: 1 }}>
            If you have not set up an Azure storage account, learn how to set up
            a container before proceeding.
          </Typography>
          <Tabulink
            href={toDocsRoot() + '/understanding-storage-profiles.html'}
            external
            variant="body2"
            rel="noopener"
            aria-label="Tabular Documentation"
            sx={{
              whiteSpace: 'nowrap',

              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            TABULAR DOCS
            <OpenInNewIcon fontSize="small" sx={{ marginLeft: '6px' }} />
          </Tabulink>
        </Box>
      </Talert>
    </Box>
  ),
  inputs: (
    <Box sx={{ pt: 2, width: '100%' }}>
      <Typography variant={'h6'} sx={{ mt: 3, whiteSpace: 'nowrap' }}>
        Azure storage account
        <Tabulink
          href={toCloudStorageAzure()}
          external
          variant="body2"
          rel="noopener"
          aria-label="Azure cloud storage"
          title={toCloudStorageAzure()}
          sx={{
            whiteSpace: 'nowrap',

            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          View your account
          <OpenInNewIcon fontSize="small" sx={{}} />
        </Tabulink>
      </Typography>
      <TextField
        name="storageAccountName"
        type="text"
        label="Storage Account Name"
        id="storageAccountName"
        variant="standard"
        margin="normal"
        autoComplete="off"
        helperText="Home→Storage accounts→<yourStorageAccount>"
        fullWidth
      />
      <TextField
        name="bucketName"
        type="text"
        label="Container Name"
        id="bucketName"
        variant="standard"
        margin="normal"
        autoComplete="off"
        helperText="Home→Storage accounts→<yourStorageAccount>→<YourExistingContainerName>"
        fullWidth
      />
      <RegionSelector
        storageType={StorageType.Adls}
        textFieldProps={{
          variant: 'standard',
          id: 'region',
          helperText: 'Microsoft Azure Region where the bucket is located.',
          sx: { mr: 1, width: '100%' },
          type: 'google',
        }}
      />
    </Box>
  ),
  bucketSettingsMessage: (
    <Box sx={{ pb: 4 }}>
      <Typography variant="body1">{`Review Microsoft Azure cloud service settings.`}</Typography>
    </Box>
  ),
};

export const IAMPolicyJSON = (bucketName: string, readOnly: boolean) => {
  const readOnlyStatement = [
    {
      Effect: 'Allow',
      Action: ['s3:ListBucket'],
      Resource: [`arn:aws:s3:::${bucketName}`],
    },
    {
      Effect: 'Allow',
      Action: ['s3:GetObject'],
      Resource: [`arn:aws:s3:::${bucketName}/*`],
    },
  ];

  const readWriteStatement = [
    {
      Effect: 'Allow',
      Action: [
        's3:ListBucket',
        's3:GetBucketLocation',
        's3:GetBucketNotification',
        's3:PutBucketNotification',
      ],
      Resource: [`arn:aws:s3:::${bucketName}`],
    },
    {
      Effect: 'Allow',
      Action: [
        's3:PutObject',
        's3:GetObject',
        's3:DeleteObject',
        's3:PutObjectAcl',
        's3:AbortMultipartUpload',
      ],
      Resource: [`arn:aws:s3:::${bucketName}/*`],
    },
  ];

  return JSON.stringify(
    {
      Version: '2012-10-17',
      Statement: readOnly ? readOnlyStatement : readWriteStatement,
    },
    null,
    2,
  );
};
export const IamRoleJSON = (externalId: string) => {
  return JSON.stringify(
    {
      Version: '2008-10-17',
      Statement: [
        {
          Effect: 'Allow',
          Principal: {
            AWS: `arn:aws:iam::${process.env.REACT_APP_AWS_ACCOUNT_ID}:root`,
          },
          Action: ['sts:AssumeRole', 'sts:TagSession'],
          Condition: {
            StringEquals: {
              'sts:ExternalId': `${externalId}`,
            },
            ArnLike: {
              'aws:PrincipalArn': `arn:aws:iam::${process.env.REACT_APP_AWS_ACCOUNT_ID}:role/TabularSignerServiceRole*`,
            },
          },
        },
      ],
    },
    null,
    2,
  );
};

const roleBreadcrumbs = ['IAM', 'Roles', 'Create role'];
const policyBreadcrumbs = ['IAM', 'Policies', 'Create policy'];
export const athenaPySparkWorkgroup = [
  'Amazon Athena',
  'Workgroups',
  'Create workgroup',
];
export const athenaPySparkNotebook = [
  'Amazon Athena',
  'Workgroups',
  'Your workgroup name',
];
export const athenaSqlDataSource = [
  'Amazon Athena',
  'Data sources',
  'Create data source',
];
const Svg = styled('svg')(({ theme }) => ({
  backgroundColor: theme.palette.dusk.three,
  height: '18px',
  width: '5px',
  margin: '0px 10px',
  mask: `url("/assets/icon/divider.svg") no-repeat center`,
}));

export const ExternalBreadcrumbs = ({
  breadcrumbs,
  link,
}: {
  breadcrumbs: string[];
  link?: string;
}) => {
  const lastLink = breadcrumbs.length - 1;
  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        paddingY={1}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            backgroundColor: 'dusk.one',
            height: '27px',
            px: 2,
            borderRadius: 1,
            alignItems: 'center',
          }}
        >
          <Tabulink
            external
            href={link}
            variant="body1"
            rel="noopener"
            aria-label="Link to external resource"
            sx={{
              display: 'flex',
              textDecoration: 'none',
              cursor: link ? 'pointer' : 'auto',
            }}
          >
            {breadcrumbs.map((crumb, index) => (
              <Box
                display={'flex'}
                flexDirection={'row'}
                key={crumb}
                alignItems={'center'}
              >
                <Typography variant={'helperText'} sx={{ color: 'dusk.seven' }}>
                  {crumb}
                </Typography>
                {lastLink !== index && <Svg />}
              </Box>
            ))}
            {link && (
              <OpenInNewIcon
                sx={{
                  height: '16px',
                  width: '16px',
                  color: 'dusk.seven',
                  ml: 1,
                }}
              />
            )}
          </Tabulink>
        </Box>
      </Box>
      {breadcrumbs === roleBreadcrumbs && <></>}
    </>
  );
};

export const AwsRoleConfigurations = (
  bucketName: string,
  externalId: string,
  region: string,
  missingConfigs?: any,
  warehouseName?: string,
  readOnly = false,
  setFieldValue?: any,
): JSX.Element => {
  const [copySuccess, setCopySuccess] = useState('');
  const [readOnlyPermissions, setReadOnlyPermissions] = useState(readOnly);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReadOnlyPermissions(event.target.checked);
    setFieldValue && setFieldValue('readOnly', event.target.checked);
  };

  const policies = [
    {
      type: 'policy',
      subheader: 'Permissions for your IAM policy',
      instruction: `If you're creating a new policy, go to`,
      policy: IAMPolicyJSON(bucketName, readOnlyPermissions),
      step: `...then, in "Step 1: Specify permissions" click on`,
      boldStep: ' JSON.',
      link: AwsCreatePolicyLink(region),
    },
    {
      type: 'role',
      subheader: 'Permissions for your IAM role',
      instruction: `If you're creating a new role, go to`,
      policy: IamRoleJSON(externalId),
      step: `...then, in "Step 1: Select trusted entity" click on`,
      boldStep: ' Custom trust policy.',
      link: AwsCreateRoleLink(region),
    },
  ];
  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const configErrors = uniq(missingConfigs);
  return (
    <>
      {missingConfigs && missingConfigs.length > 0 && (
        <Talert
          sx={{ my: 2 }}
          severity={'error'}
          alertTitle={'Errors in the policy configuration'}
        >
          {configErrors.map((config: any) => (
            <Typography key={config} display={'block'} variant={'helperText'}>
              {`- ${
                config === 'BucketExists' ? 'Bucket does not exist' : config
              }`}
            </Typography>
          ))}
        </Talert>
      )}
      <Talert
        severity={'info'}
        sx={{
          width: 'fit-content',
          size: 'small',
          my: 1,
        }}
      >
        <>
          <Tabulink
            external
            href={
              toDocsRoot() +
              '/creating-a-storage-profile-for-aws.html#pre-configuring-aws-s3'
            }
            variant="helperTextLink"
            rel="noopener"
            aria-label="Tabular Documentation"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
            }}
          >
            Learn more about configuring S3
            <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
          </Tabulink>
        </>
      </Talert>
      {!warehouseName && (
        <>
          <StyledSwitch
            switchlabel={'Read only configuration'}
            checked={readOnlyPermissions}
            onChange={handleChange}
          />

          <Typography variant={'helperText'} sx={{ maxWidth: '80%' }}>
            This policy configuration allows Tabular to read from your bucket,
            but not write. This is useful for importing data.
          </Typography>
        </>
      )}
      {policies.map((policy, key) => {
        const breadcrumbs =
          policy.type === 'policy' ? policyBreadcrumbs : roleBreadcrumbs;
        return (
          <Card
            variant={'outlined'}
            sx={{ mb: 2, borderRadius: 2, mt: 3 }}
            key={key}
          >
            <CardHeader
              title={
                <>
                  <Typography variant={'subtitle1'} sx={{ mb: 1 }}>
                    {policy.subheader}
                  </Typography>
                  <Typography variant={'helperText'}>
                    {policy.instruction}
                  </Typography>
                  <ExternalBreadcrumbs
                    breadcrumbs={breadcrumbs}
                    link={policy.link}
                  />
                  <Typography variant={'helperText'} sx={{ py: 1 }}>
                    {policy.step}
                  </Typography>
                  <Typography variant={'helperTextLink'} sx={{ mb: 1 }}>
                    {policy.boldStep}
                  </Typography>
                </>
              }
              sx={(theme) => ({
                py: 2,
                px: 2,

                borderBottom: `1px solid ${theme.palette.midnight.two}`,
              })}
            />
            <CardContent sx={{ p: 0 }}>
              <SyntaxHighlighter
                language="json"
                style={github}
                customStyle={{
                  fontSize: '13px',
                  lineHeight: '1em',
                  margin: 0,
                  backgroundColor: '#F1F3F4',
                }}
              >
                {policy.policy}
              </SyntaxHighlighter>
            </CardContent>
            <CardActions
              sx={(theme) => ({
                borderTop: `1px solid ${theme.palette.midnight.two}`,
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                flexDirection: 'column',
              })}
            >
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <Box>
                  <Tabutton
                    onClick={() => copyToClipBoard(policy.policy)}
                    size={'small'}
                    sx={{ marginY: 1 }}
                  >
                    {`Copy ${policy.type} permissions`}
                    <ContentCopyIcon fontSize={'small'} sx={{ ml: 1 }} />
                  </Tabutton>
                </Box>
              </Tooltip>
              {policy.type === 'role' && (
                <Talert color={'neutral'} sx={{ mb: 1 }}>
                  {`If you see an "Unsupported Action For Condition Key" error, it
                  can safely be ignored.`}
                </Talert>
              )}
            </CardActions>
          </Card>
        );
      })}
    </>
  );
};

export const serviceAccountBreadcrumbs = [
  'IAM & Admin',
  'Service Accounts',
  'Create Service Account',
];
export const createPoolbreadcrumbs = [
  'IAM & Admin',
  'Workload Identity Federation',
  'Create Pool',
];

export const GoogleServiceAccountConfig = (
  projectNum?: string,
  poolId?: string,
  missingConfigs?: any,
): JSX.Element => {
  const principle = `principalSet://iam.googleapis.com/projects/${
    projectNum || 'project-number'
  }/locations/global/workloadIdentityPools/${poolId || 'pool-id'}/*`;

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} sx={{ px: 2, pb: 3 }}>
        {missingConfigs && missingConfigs.length > 0 && (
          <Talert
            sx={{ my: 2 }}
            severity={'error'}
            alertTitle={'Errors in the policy configuration'}
          />
        )}
        <Typography variant="body1">
          {`Ensure you have setup your Workload Identity Federation and Service Account correctly`}
        </Typography>
        <>
          {/*<Talert*/}
          {/*  severity={'info'}*/}
          {/*  sx={{*/}
          {/*    width: 'fit-content',*/}
          {/*    size: 'small',*/}
          {/*    mt: 2,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <>*/}
          {/*    <Tabulink*/}
          {/*      external*/}
          {/*      href="https://docs.tabular.io/configuring-s3#iam-policy"*/}
          {/*      variant="helperTextLink"*/}
          {/*      rel="noopener"*/}
          {/*      aria-label="Tabular Documentation"*/}
          {/*      sx={{*/}
          {/*        display: 'flex',*/}
          {/*        alignItems: 'center',*/}
          {/*        textDecoration: 'none',*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Learn more about configuring GCS*/}
          {/*      <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />*/}
          {/*    </Tabulink>*/}
          {/*  </>*/}
          {/*</Talert>*/}
          <Card variant={'outlined'} sx={{ borderRadius: 2, mt: 3 }}>
            <CardHeader
              title={
                <>
                  <Typography variant={'subtitle1'} sx={{ mb: 1 }}>
                    {'Workload Identity Federation'}
                  </Typography>
                  <ExternalBreadcrumbs
                    breadcrumbs={createPoolbreadcrumbs}
                    link={`${GcsWorkloadIdentityLink}/create`}
                  />
                </>
              }
              sx={(theme) => ({
                pt: 2,
                borderBottom: `1px solid ${theme.palette.midnight.two}`,
              })}
            />
            <CardContent
              sx={{
                p: 0,
                '&:last-child': {
                  p: 1,
                },
              }}
            >
              <WorkloadInstructions />
            </CardContent>
          </Card>
          <Card variant={'outlined'} sx={{ borderRadius: 2, mt: 3 }}>
            <CardHeader
              title={
                <>
                  <Typography variant={'subtitle1'} sx={{ mb: 1 }}>
                    {'Service Account'}
                  </Typography>
                  <ExternalBreadcrumbs
                    breadcrumbs={serviceAccountBreadcrumbs}
                    link={GcsServiceAccountLink}
                  />
                </>
              }
              sx={(theme) => ({
                p: 2,
                borderBottom: `1px solid ${theme.palette.midnight.two}`,
              })}
            />
            <CardContent
              sx={{
                p: 0,
                '&:last-child': {
                  p: 1,
                },
              }}
            >
              <ServiceAccountInstructions principal={principle} />
            </CardContent>
          </Card>
        </>
      </Box>
    </>
  );
};
