import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, ButtonProps } from '@mui/material';

export interface TabuttonProps extends ButtonProps {
  loadingBtn?: boolean;
  loading?: boolean;
  loadingPosition?: 'end' | 'start' | 'center';
}
export const Tabutton = ({
  loadingBtn,
  children,
  ...props
}: TabuttonProps): JSX.Element => {
  return (
    <>
      {loadingBtn ? (
        <LoadingButton {...props}>{children}</LoadingButton>
      ) : (
        <Button {...props}>{children}</Button>
      )}
    </>
  );
};
