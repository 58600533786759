import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import PasswordIcon from '@mui/icons-material/Password';
import TagIcon from '@mui/icons-material/Tag';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, ChipProps, IconButton, Typography } from '@mui/material';

import { useAuth } from '../../context/auth-context';
import { getAuthorizationDecision } from '../../graphql/authorization';
import {
  AuthDecisionResponseType,
  LabelMaskMode,
} from '../../graphql/gen/graphql';

interface LabelDisplayProps extends ChipProps {
  labelId?: string;
  hasPolicy: boolean;
  maskMode?: LabelMaskMode | string;
  labelName: string;
  fieldId?: string;
  remove?: () => void;
  maxWidth?: number;
}

const getDecisionData = (labelId: string) => {
  const { user } = useAuth();

  const { data: decisionData } = useQuery(getAuthorizationDecision, {
    variables: {
      request: {
        subject: {
          type: 'USER',
          identifier: user.id,
        },
        privileges: ['APPLY_LABEL'],
        requirement: 'ALL',
        resource: {
          type: 'LABEL',
          identifier: labelId,
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return decisionData?.authDecision === AuthDecisionResponseType.Deny;
};
export function LabelDisplay({
  labelId,
  hasPolicy,
  maskMode,
  labelName,
  fieldId,
  remove,
  maxWidth,
  ...props
}: LabelDisplayProps) {
  const labelIcon = useMemo(() => {
    if (maskMode === LabelMaskMode.Hide) {
      return <VisibilityOffIcon fontSize={`small`} sx={{ color: 'white' }} />;
    }

    if (maskMode === LabelMaskMode.Null) {
      return <PasswordIcon fontSize={`small`} sx={{ color: 'white' }} />;
    }

    return <TagIcon fontSize={`small`} sx={{ color: 'white' }} />;
  }, [maskMode, labelName]);

  const notAuthorized = remove && labelId ? getDecisionData(labelId) : true;

  return (
    <Box
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: hasPolicy ? 'dusk.two' : 'midnight.two',
        display: 'flex',
        height: '24px',
        paddingRight: !notAuthorized && remove ? 0.5 : '12px',
        borderRadius: '4px',
        maxWidth: maxWidth || '650px',
      }}
    >
      <Box
        sx={{
          height: '100%',
          minWidth: '32px',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          backgroundColor: hasPolicy ? 'dusk.six' : 'midnight.seven',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {labelIcon}
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          overflow: 'hidden',
        }}
      >
        <Typography
          variant={`subtitle2`}
          sx={{
            color: 'midnight.nine',
            ml: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {labelName}
        </Typography>
      </Box>
      {remove && labelId && !notAuthorized && (
        <IconButton
          onClick={remove}
          disabled={notAuthorized}
          sx={{ backgroundColor: 'transparent' }}
        >
          <CloseIcon
            fontSize={'small'}
            sx={{
              color: notAuthorized ? 'midnight.four' : 'midnight.nine',
              pointer: 'cursor',
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}
