import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Snackbar, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, GridColumns, useGridApiRef } from '@mui/x-data-grid-pro';

import { Talert } from '../Alert/Talert';
import { Tacard } from '../Card/Tacard';
import { TaClipboardText } from '../Clipboard/TaClipboardText';
import {
  CustomPaging,
  DataGridToolBar,
} from '../DataGridToolbar/DataGridToolbar';

export function ListDatabases({
  rows,
  columns,
  loading,
  refreshResourceContent,
  addResourceContent,
  dashboardDropdownContent,
  warehouseName,
  warehouseId,
  headerName,
  cloudProviderIcon,
}: {
  rows: any;
  columns: any;
  loading: boolean;
  refreshResourceContent: any;
  addResourceContent: any;
  dashboardDropdownContent: any;
  warehouseName: string;
  warehouseId: string;
  headerName: JSX.Element;
  cloudProviderIcon: string;
}) {
  const location = useLocation();
  const [openSnackbar, setOpenSnackbar] = useState(
    location?.state ? location.state.snackbar : false,
  );
  const apiRef = useGridApiRef();
  const [pageSize, setPageSize] = useState(100);

  return (
    <>
      <Tacard grid>
        <DataGridPro
          autoHeight
          apiRef={apiRef}
          pagination={rows?.length >= 100}
          hideFooter={rows?.length <= 100}
          rowsPerPageOptions={[10, 25, 50, 100]}
          rows={rows || []}
          columns={columns || ([] as GridColumns)}
          headerHeight={34}
          disableColumnMenu
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
            pagination: { page: 0, pageSize: 100 },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [''],
              },
            },
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: DataGridToolBar,
            Pagination: CustomPaging,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              apiRef: apiRef,
              pageSize: pageSize,
              setPageSize: setPageSize,
              headerName: headerName,
              cloudProviderIcon: cloudProviderIcon,
              refreshResourceContent: refreshResourceContent,
              addResourceContent: addResourceContent,
              dashboardMenuContent: dashboardDropdownContent,
              rowCount: rows?.length,
              showRange: rows?.length >= 100,
              pageAtNumber: 100,
            },
            pagination: {
              apiRef: apiRef,
              pageSize: pageSize,
              setPageSize: setPageSize,
              rowCount: rows?.length,
            },
          }}
          loading={!!warehouseId && (loading || !rows)}
          sx={{
            border: 'none',
            '.MuiDataGrid-columnHeaders': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
            '.MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: 'dusk.half',
            },
          }}
        />
      </Tacard>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
          window.history.replaceState({}, document.title);
        }}
      >
        <Talert
          onClose={() => {
            setOpenSnackbar(false);
            window.history.replaceState({}, document.title);
          }}
          color="darkSuccess"
          sx={{ width: '100%' }}
        >
          {location?.state?.snackbarMessage ?? 'Database successfully dropped.'}
        </Talert>
      </Snackbar>
    </>
  );
}
