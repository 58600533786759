import React from 'react';

import { create, show, useModal } from '@ebay/nice-modal-react';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { StorageProfile } from '../../graphql/gen/graphql';
import StorageBrowser, {
  StorageBrowserSelectionMode,
  StorageBrowserSelectionModel,
} from '../StorageBrowser/StorageBrowser';

interface StorageProfileBrowserDialogProps {
  organizationId: string;
  selectionMode?: StorageBrowserSelectionMode;
  destinationStorageProfile?: StorageProfile;
}
const StorageProfileBrowserDialog = create(
  ({
    organizationId,
    selectionMode,
    destinationStorageProfile,
  }: StorageProfileBrowserDialogProps) => {
    const modal = useModal();

    return (
      <Dialog
        fullWidth
        maxWidth={'xl'}
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <IconButton
          aria-label="close"
          onClick={modal.hide}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <StorageBrowser
            sx={{ mt: 1 }}
            organizationId={organizationId}
            regionFilter={
              destinationStorageProfile
                ? [destinationStorageProfile?.region]
                : []
            }
            storageTypeFilter={[destinationStorageProfile?.storageType!]}
            selectionMode={
              selectionMode ? selectionMode : StorageBrowserSelectionMode.PATH
            }
            onCancel={modal.hide}
            onFinalSelection={(selectionModel) => {
              modal.resolve(selectionModel);
              modal.hide();
            }}
            DatagridSx={{ minHeight: '70vh' }}
          />
        </DialogContent>
      </Dialog>
    );
  },
);

export default StorageProfileBrowserDialog;

export function showStorageProfileBrowserDialog(
  organizationId: string,
  selectionMode?: StorageBrowserSelectionMode,
  destinationStorageProfile?: StorageProfile,
) {
  return show<
    StorageBrowserSelectionModel,
    any,
    StorageProfileBrowserDialogProps
  >(StorageProfileBrowserDialog, {
    organizationId,
    selectionMode,
    destinationStorageProfile,
  });
}
