import * as React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import BadgeIcon from '@mui/icons-material/Badge';
import { Box, Popper, Stack, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { includes } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import { enqueueSnackbar } from 'notistack';

import { toSingleRole } from '../../RouteTable';
import { Role, SearchRoleResult } from '../../graphql/gen/graphql';
import { createRoleRelationship, getRoleByName } from '../../graphql/role';
import { calculateTextWidth } from '../../pages/helpers';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import { Tacard } from '../Card/Tacard';
import { ChipColors, Tachip } from '../Chip/Tachip';
import {
  CustomPaging,
  DataGridToolBar,
} from '../DataGridToolbar/DataGridToolbar';
//@ts-ignore
import QuickAddRole from '../Forms/QuickAddRole';
import { NamePopover } from '../Popovers/NamePopover';
import QuickAddMenu from '../QuickAddMenu/QuickAddMenu';

export default function Inheritance({
  parents,
  loading,
  refreshResourceContent,
  orgName,
  orgDisplayName,
  orgId,
  roleId,
  roleName,
}: {
  parents: [Role];
  loading: boolean;
  refreshResourceContent: () => void;
  orgName: string;
  orgDisplayName: string;
  orgId: string;
  roleId: string;
  roleName: string;
}) {
  const parentApiRef = useGridApiRef();
  const [parentPageSize, setParentPageSize] = useState(25);
  const navigate = useNavigate();
  const [newRents] = useMutation(createRoleRelationship);
  const handleAddParentRole = async (selectedRole: SearchRoleResult) => {
    await newRents({
      variables: {
        parentRoleId: selectedRole.roleId,
        childRoleId: roleId,
      },
      refetchQueries: [
        {
          query: getRoleByName,
          variables: {
            organizationId: orgId,
            roleName,
            organizationName: orgName,
            organizationDisplayName: orgDisplayName,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then((result) => {
        enqueueSnackbar(
          `${roleName} successfully added as member of ${selectedRole.roleName}`,
          { variant: 'success' },
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          includes(error.message, 409)
            ? 'This role cannot be added as it causes a relationship cycle.'
            : getUserFriendlyErrorMessage(error.message),
          {
            variant: 'error',
          },
        );
      });
  };

  const parentRows = useMemo(() => {
    return (
      parents?.map((role: Role) => {
        return {
          id: role.id,
          name: role.name,
          displayName: role.displayName || role.name,
          peopleCount: role?.users?.length,
          childrenCount: role?.children?.length,
        };
      }) || []
    );
  }, [parents]);

  const parentColumns = useMemo(
    () => [
      {
        field: 'roleIcon',
        headerName: '',
        width: 80,
        editable: false,
        disableColumnMenu: true,
        align: 'center',
        renderCell: () => (
          <Box display={`flex`} justifyContent={`center`} alignItems={`center`}>
            <BadgeIcon sx={{ color: 'midnight.six' }} />
          </Box>
        ),
      },
      {
        field: 'displayName',
        headerName: 'Name',
        flex: 1,
        minWidth: 200,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          const textWidth = calculateTextWidth(
            params.value,
            '16px Source Sans Pro',
            true,
          );
          return (
            <Box
              sx={{
                display: 'flex',
                flex: '1 1 100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
                minWidth: 0,
              }}
            >
              <PopupState variant="popper">
                {(popupState) => (
                  <>
                    <Tachip
                      type={ChipColors.Neutral}
                      size={'small'}
                      onClick={() =>
                        navigate(toSingleRole(orgName, params.row.name))
                      }
                      label={
                        <Typography
                          variant={'subtitle2'}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {params.value}
                        </Typography>
                      }
                      {...bindHover(popupState)}
                    />

                    {textWidth >= params.colDef.computedWidth && (
                      <Popper {...bindPopper(popupState)} placement={`right`}>
                        <NamePopover name={params.value} />
                      </Popper>
                    )}
                  </>
                )}
              </PopupState>
            </Box>
          );
        },
      },
      {
        field: 'peopleCount',
        headerName: 'People',
        flex: 1,
        editable: false,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value != 0 ? params.value : '--';
        },
      },
      {
        field: 'childrenCount',
        headerName: 'Nested roles',
        flex: 1,
        editable: false,
        valueFormatter: (params: GridValueFormatterParams) => {
          return params.value != 0 ? params.value : '--';
        },
      },
    ],
    [parents],
  );

  const AddParentRoleComponent = (
    <Box paddingLeft={2}>
      <QuickAddMenu
        buttonSize={`medium`}
        menuContent={
          <QuickAddRole
            organizationId={orgId}
            onRoleAdded={handleAddParentRole}
            existingRoles={parents || []}
          />
        }
        tooltipText={`Inherit from`}
      />
    </Box>
  );

  return (
    <Box>
      <Stack direction={`column`} spacing={2}>
        <Box
          sx={{
            '& .MuiDataGrid-row, .MuiDataGrid-root .MuiDataGrid-cell, .rendering-zone':
              { maxheight: 'none !important', minHeight: '67px !important' },
            '& .MuiDataGrid-root .MuiDataGrid-window': {
              position: 'relative !important',
            },
            '& .MuiDataGrid-root .MuiDataGrid-viewport': {
              maxheight: 'none !important',
            },
            '& .MuiDataGrid-root': { height: 'auto !important' },
            '& .roles-cell': {
              display: 'flex',
              flexWrap: 'wrap',
              flex: '1 1 auto',
              maxHeight: '100%',
            },
          }}
        >
          <Tacard grid>
            <DataGridPro
              rows={parentRows}
              columns={parentColumns as GridColumns}
              pageSize={parentPageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              headerHeight={34}
              autoHeight
              apiRef={parentApiRef}
              pagination={parentRows?.length >= 25}
              hideFooter={parentRows?.length < 25}
              disableColumnMenu
              density="comfortable"
              getRowHeight={() => 'auto'}
              initialState={{
                pagination: { page: 0, pageSize: 25 },
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterValues: [''],
                  },
                },
              }}
              onPageSizeChange={(newPageSize) => setParentPageSize(newPageSize)}
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No parent roles
                  </Stack>
                ),
                Toolbar: DataGridToolBar,
                Pagination: CustomPaging,
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  apiRef: parentApiRef,
                  pageSize: parentPageSize,
                  setPageSize: setParentPageSize,
                  headerName: (
                    <Box display={'flex'} paddingLeft={2} alignItems={'center'}>
                      {'Parent roles'}
                    </Box>
                  ),
                  refreshResourceContent: {
                    title: 'Refresh parent roles',
                    action: refreshResourceContent,
                  },
                  customButton1: AddParentRoleComponent,
                  rowCount: parentRows?.length,
                  showRange: parentRows?.length >= 25,
                  pageAtNumber: 25,
                },
                pagination: {
                  apiRef: parentApiRef,
                  pageSize: parentPageSize,
                  setPageSize: setParentPageSize,
                  rowCount: parentRows?.length,
                },
              }}
              loading={loading}
              sx={{
                border: 'none',
                '.MuiDataGrid-columnHeaders': {
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                },
                '.MuiDataGrid-pinnedColumnHeaders': {
                  backgroundColor: 'dusk.half',
                },
              }}
            />
          </Tacard>
        </Box>
      </Stack>
    </Box>
  );
}
