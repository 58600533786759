import React, { useState } from 'react';

import { FolderOutlined } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FieldHookConfig, useField } from 'formik';
import { isEmpty } from 'lodash';

import { useAuth } from '../../context/auth-context';
import { StorageProfile } from '../../graphql/gen/graphql';
import { Tabutton } from '../Button/Tabutton';
import TextField from '../Forms/TextField';
import { showStorageProfileBrowserDialog } from '../Modals/StorageProfileBrowserDialog';
import { StorageBrowserSelectionMode } from '../StorageBrowser/StorageBrowser';

export default function PathWithStorageBrowser({
  storageProfile,
  setEditingPropGroup,
  ...props
}: {
  storageProfile: StorageProfile;
  name: string;
  label: string;
  setEditingPropGroup: (value: boolean) => void;
}) {
  const { user } = useAuth();
  const [copySuccess, setCopySuccess] = useState('');
  const [field, meta, helpers] = useField(props as FieldHookConfig<any>);

  const { setValue } = helpers;
  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        '> div': {
          width: '100%',
        },
      }}
    >
      <TextField
        {...field}
        {...props}
        fullWidth
        //@ts-ignore
        InputProps={{ ...props.InputProps, readOnly: true }}
      />
      <IconButton sx={{ ml: 2 }} onClick={() => copyToClipBoard(field.value)}>
        <Tooltip
          title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
          placement={`top`}
        >
          <ContentCopyIcon sx={{ color: 'sky.seven' }} />
        </Tooltip>
      </IconButton>
      <Tabutton
        endIcon={<FolderOutlined />}
        onClick={() => {
          showStorageProfileBrowserDialog(
            user.loginSession.loggedInOrg.id,
            StorageBrowserSelectionMode.PATH,
            storageProfile,
          ).then((selectionModel) => {
            setEditingPropGroup(true);
            setValue(selectionModel.selectedPath);
          });
        }}
      >
        Browse
      </Tabutton>
    </Box>
  );
}
