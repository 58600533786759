import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Snackbar,
  Box,
  Tooltip,
  Typography,
  Popper,
  Stack,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { sortBy } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { Invite, Member, Organization } from '../../graphql/gen/graphql';
import { removeOrgUser, removeOrgInvite } from '../../graphql/organization';
import { getApolloErrorMessage } from '../../utils/ApolloUtils';
import { relativeTimeAutoFormat } from '../../utils/time';
import { Talert } from '../Alert/Talert';
import { Tavatar } from '../Avatar/Tavatar';
import { Tacard } from '../Card/Tacard';
import {
  CustomPaging,
  DataGridToolBar,
  MenuContentType,
} from '../DataGridToolbar/DataGridToolbar';
import { ConfirmationDialog } from '../Modals/ConfirmationDialog';
import { InvitePopover } from '../Popovers/InvitePopover';
import { MemberJoinedPopover } from '../Popovers/MemberJoinedPopover';

export default function MemberGrid({
  organization,
  enableRemoveMember,
  currentUserEmail,
  addResourceContent,
  refreshResourceContent,
  loading,
}: {
  organization: Organization;
  enableRemoveMember: boolean;
  addResourceContent: MenuContentType;
  refreshResourceContent: MenuContentType;
  currentUserEmail: string;
  loading: boolean;
}) {
  // State
  const [confirmMemberModal, setConfirmMemberModal] = useState(false);
  const [confirmInviteModal, setConfirmInviteModal] = useState(false);
  const [removeMember, setRemoveMember] = useState<Member>();
  const [removeInvite, setRemoveInvite] = useState<Invite>();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [removeMemberError, setRemoveMemberError] = useState();
  const [inviteError, setInviteError] = useState();
  // Mutations
  const [removeOrganizationUser, { reset: reset }] = useMutation(removeOrgUser);
  const [removeOrganizationInvite, { reset: inviteReset }] =
    useMutation(removeOrgInvite);
  const apiRef = useGridApiRef();
  const [pageSize, setPageSize] = useState(25);

  //I created the following two GridItem* classes in order to avoid having ternary operators / switch statements everywhere
  //Would be an interface potentially in typescript, but for now, I am populating the graphql results into these
  //objects so that I can clean up the rendering code below.  Not 100% this is the best pattern for adding dissimilar
  //objects to a grid and trying to have them co-exist cleanly, discussed with Jason about graphql a

  class GridItemMember {
    member: Member;
    constructor(member: Member) {
      this.member = member;
    }
    get displayName() {
      return this.member.displayName;
    }
    get pictureUrl() {
      return this.member?.properties?.[`picture.url`];
    }
    get id() {
      return this.member.id;
    }
    get userId() {
      return this.member.user.id;
    }
    get email() {
      return this.member.user.email;
    }
    get removeTooltipText() {
      return 'Remove from organization';
    }
    onRemoveClick() {
      setRemoveMember(this.member);
      setConfirmMemberModal(true);
    }
    //for sorting
    get joinedAt() {
      return this.member.createdAt;
    }
    get joinedAtDisplayValue() {
      return relativeTimeAutoFormat(this.member.createdAt || '');
    }
    get joinedPopper() {
      return <MemberJoinedPopover joinedDate={this.member.createdAt || ''} />;
    }
  }

  class GridItemInvite {
    invite: Invite;
    constructor(invite: Invite) {
      this.invite = invite;
    }
    get displayName() {
      return this.invite.email;
    }
    get id() {
      return this.invite.inviteCode;
    }
    get pictureUrl() {
      return undefined;
    }
    get email() {
      return this.invite.email;
    }
    get removeTooltipText() {
      return 'Revoke invite';
    }
    onRemoveClick() {
      setRemoveInvite(this.invite);
      setConfirmInviteModal(true);
    }
    //for sorting
    get joinedAt() {
      return this.invite.createdAt;
    }
    get joinedAtDisplayValue() {
      return <i>Invited</i>;
    }
    get joinedPopper() {
      return (
        <InvitePopover
          invitedBy={this.invite.createdBy || ''}
          invitedDate={this.invite.createdAt || ''}
        />
      );
    }
  }

  type GridItem = GridItemMember | GridItemInvite;

  const gridItems: GridItem[] = [
    ...(organization?.members
      ? organization.members.map((user) => new GridItemMember(user!))
      : []),
    ...(organization?.invites
      ? organization.invites.map((invite) => new GridItemInvite(invite!))
      : []),
  ];
  // Memos
  const users = useMemo(
    () => sortBy(gridItems, (item) => item.displayName?.toLowerCase()),
    [organization, loading],
  );

  const columns = useMemo(() => {
    // adapted from the built-in singleSelect operator
    // https://github.com/mui/mui-x/blob/master/packages/grid/x-data-grid/src/colDef/gridSingleSelectOperators.ts

    const columns = [
      {
        field: 'avatar',
        headerName: '',
        minWidth: 75,
        flex: 0.5,
        sortable: false,
        align: 'center',
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <Tavatar
            profilePic={params.row.pictureUrl}
            displayName={params.row.displayName}
            currentUser={currentUserEmail === params.row.email}
          />
        ),
      },
      { field: 'displayName', headerName: 'Name', minWidth: 150, flex: 1 },
      { field: 'email', headerName: 'Email', minWidth: 225, flex: 1.5 },

      {
        field: 'joinedAt',
        headerName: 'Joined',
        width: 175,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <PopupState variant="popper">
              {(popupState) => (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flex: '1 1 100%',
                      alignItems: 'center',
                      height: '100%',
                      cursor: 'default',
                    }}
                  >
                    <Typography
                      variant={`inputText`}
                      {...bindHover(popupState)}
                    >
                      {params.row.joinedAtDisplayValue}
                    </Typography>
                  </Box>
                  <Popper
                    {...bindPopper(popupState)}
                    placement={'right'}
                    modifiers={[
                      {
                        name: 'flip',
                        options: {
                          fallbackPlacements: ['bottom'],
                        },
                      },
                    ]}
                  >
                    {params.row.joinedPopper}
                  </Popper>
                </>
              )}
            </PopupState>
          );
        },
      },
      ...(enableRemoveMember
        ? [
            {
              field: 'actions',
              type: 'actions',
              width: 80,
              //@ts-ignore
              getActions: (params: any) => [
                <GridActionsCellItem
                  placeholder
                  key="delete-action"
                  icon={
                    <Tooltip
                      title={params.row.removeTooltipText}
                      placement="top"
                      arrow
                    >
                      <RemoveCircleIcon />
                    </Tooltip>
                  }
                  label="Delete"
                  color="warning"
                  onClick={() => {
                    params.row.onRemoveClick();
                  }}
                  showInMenu={false}
                />,
              ],
            },
          ]
        : []),
    ];

    return columns;
  }, [enableRemoveMember]);

  // Callbacks
  const handleRemoveMember = useCallback(async () => {
    setDialogLoading(true);
    removeOrganizationUser({
      variables: {
        organizationId: organization.id,
        userId: removeMember && removeMember.user.id,
      },
    })
      .then((r) => {
        reset();
        refreshResourceContent.action();
        setRemoveMember(undefined);
        setConfirmMemberModal(false);
        setOpenSnackbar(true);
      })
      .catch((reason) => {
        setRemoveMemberError(reason);
      })
      .finally(() => {
        setDialogLoading(false);
      });
  }, [
    organization,
    removeMember,
    reset,
    setRemoveMember,
    setConfirmMemberModal,
    removeOrganizationUser,
    removeMemberError,
  ]);

  const handleRemoveInvite = useCallback(async () => {
    setDialogLoading(true);
    removeOrganizationInvite({
      variables: {
        organizationId: organization.id,
        inviteCode: removeInvite?.inviteCode,
      },
    })
      .then((r) => {
        inviteReset();
        refreshResourceContent.action();
        setRemoveInvite(undefined);
        setConfirmInviteModal(false);
        setOpenSnackbar(true);
      })
      .catch((reason) => {
        setInviteError(reason);
      })
      .finally(() => {
        setDialogLoading(false);
      });
  }, [
    organization,
    removeInvite,
    inviteReset,
    setRemoveInvite,
    setConfirmInviteModal,
    removeOrganizationInvite,
  ]);

  return (
    <Box
      sx={{
        '& .MuiDataGrid-row, .MuiDataGrid-root .MuiDataGrid-cell, .rendering-zone':
          { maxheight: 'none !important', minHeight: '67px !important' },
        '& .MuiDataGrid-root .MuiDataGrid-window': {
          position: 'relative !important',
        },
        '& .MuiDataGrid-root .MuiDataGrid-viewport': {
          maxheight: 'none !important',
        },
        '& .MuiDataGrid-root': { height: 'auto !important' },
        '& .roles-cell': {
          display: 'flex',
          flexWrap: 'wrap',
          flex: '1 1 auto',
          maxHeight: '100%',
        },
      }}
    >
      <Tacard grid>
        <DataGridPro
          rows={users}
          columns={columns as GridColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          headerHeight={34}
          autoHeight
          apiRef={apiRef}
          pagination={users?.length >= 25}
          hideFooter={users?.length < 25}
          disableColumnMenu
          density="comfortable"
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: { page: 0, pageSize: 25 },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [''],
              },
            },
          }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No members
              </Stack>
            ),
            Toolbar: DataGridToolBar,
            Pagination: CustomPaging,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              apiRef: apiRef,
              pageSize: pageSize,
              setPageSize: setPageSize,
              headerName: (
                <Box display={'flex'} paddingLeft={2} alignItems={'center'}>
                  {'Members list'}
                </Box>
              ),
              refreshResourceContent: refreshResourceContent,
              addResourceContent: addResourceContent,
              rowCount: users?.length,
              showRange: users?.length >= 25,
              pageAtNumber: 25,
            },
            pagination: {
              apiRef: apiRef,
              pageSize: pageSize,
              setPageSize: setPageSize,
              rowCount: users?.length,
            },
          }}
          loading={loading}
          sx={{
            border: 'none',
            '.MuiDataGrid-columnHeaders': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
            '.MuiDataGrid-pinnedColumnHeaders': {
              backgroundColor: 'dusk.half',
            },
          }}
        />
      </Tacard>
      {removeMember && (
        <ConfirmationDialog
          open={confirmMemberModal}
          title="Remove member?"
          acceptText="Remove"
          onDismiss={() => {
            setConfirmMemberModal(false);
          }}
          onAccept={handleRemoveMember}
          loading={dialogLoading}
        >
          {removeMemberError ? (
            <Talert
              severity="error"
              onClose={() => {
                reset();
                setRemoveMemberError(undefined);
              }}
            >
              Error: {getApolloErrorMessage(removeMemberError)}
            </Talert>
          ) : null}
          Permanently remove
          {removeMember.displayName || removeMember.user.email} and their
          associated privileges from{' '}
          {organization.displayName || organization.name}?
        </ConfirmationDialog>
      )}
      {removeInvite && (
        <ConfirmationDialog
          open={confirmInviteModal}
          title="Remove invite?"
          acceptText="Remove"
          onDismiss={() => {
            setConfirmInviteModal(false);
          }}
          onAccept={handleRemoveInvite}
          loading={dialogLoading}
        >
          {inviteError ? (
            <Talert
              severity="error"
              onClose={() => {
                inviteReset();
                //@ts-ignore
                setInviteError(null);
              }}
            >
              Error: {getApolloErrorMessage(inviteError)}
            </Talert>
          ) : null}
          {`Remove the invite for ${removeInvite.email} to ${
            organization?.displayName || organization?.name
          }?`}
        </ConfirmationDialog>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={6000}
      >
        <Talert
          onClose={() => setOpenSnackbar(false)}
          color="darkSuccess"
          sx={{ width: '100%' }}
        >
          {`${organization?.displayName} has been updated.`}
        </Talert>
      </Snackbar>
    </Box>
  );
}
