import React from 'react';

import { Box, BoxProps, Tooltip, Typography, useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { SystemProps } from '@mui/system';

import { CustomIcon } from '../Icons/CustomIcon';
import { useStatusBoardRootProps } from './context/useStatusBoardRootProps';
import { useStatusPanelSectionRootProps } from './context/useStatusPanelSectionRootProps';

import {
  STATUS_PANEL_SECTION_DEFAULT_STATE_COMPONENT,
  StatusPanelSectionProps,
} from './index';

export default function StatusPanelSectionLayoutTask({
  headerName,
  headerLabel,
  headerProps,
  state,
  statusMessages,
  progress,
  progressType,
  ...props
}: StatusPanelSectionProps) {
  const theme = useTheme();

  const { slots, slotProps } = useStatusPanelSectionRootProps();
  const stateProps = slots.stateProps[state?.toLocaleLowerCase() ?? 'default']
    ? slots.stateProps[state?.toLocaleLowerCase() ?? 'default']
    : slots.stateProps['default'];
  return (
    <>
      {headerLabel && (
        <Box
          {...headerProps}
          sx={[
            {
              display: 'flex',
              py: 2,
              px: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              alignSelf: 'stretch',
              backgroundColor: 'midnight.one',
              height: '100%',
            },
            ...(Array.isArray(headerProps?.sx)
              ? headerProps?.sx ?? []
              : [headerProps?.sx]),
          ]}
        >
          <Typography
            variant={'buttonsmall'}
            sx={{
              textAlign: 'center',
              textOrientation: 'sideways',

              textDecoration: 'none',
              writingMode: 'vertical-lr',
              transform: 'rotate(180deg)',
              borderLeftColor: theme.palette.midnight.one,
            }}
          >
            {headerLabel}
          </Typography>
        </Box>
      )}

      <Box
        {...props}
        sx={[
          {
            display: 'flex',
            backgroundColor: 'controlBackground.main',
            ...(headerLabel
              ? {
                  borderLeftWidth: 1,
                  borderLeftColor: 'midnight.two',
                  borderLeftStyle: 'solid',
                }
              : {}),
          },
          props.sx as SystemProps,
        ]}
      >
        <Box
          className={'status-panel-hide-small'}
          sx={{
            display: 'flex',
            paddingX: 2,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            alignSelf: 'stretch',
            borderLeftWidth: 1,
            borderLeftColor: 'midnight.one',
            borderLeftStyle: 'solid',
          }}
        >
          {slots.HeaderIconComponent && (
            <slots.HeaderIconComponent
              sx={[
                {
                  height: '32px',
                  width: '32px',
                  margin: '0 auto',
                  alignSelf: 'center',
                  color: 'brandBlue.main',
                },
                ...(Array.isArray(slots.headerIcon?.sx)
                  ? slots.headerIcon?.sx
                  : [slots.headerIcon?.sx]),
              ]}
              {...slots.headerIcon}
              {...slotProps?.headerIconComponent}
            />
          )}
          {headerName && (
            <Typography variant={'subtitle2'} sx={{ whiteSpace: 'nowrap' }}>
              {headerName}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            paddingX: 2,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 1,
            alignSelf: 'stretch',
            borderLeftWidth: 1,
            borderLeftColor: 'midnight.one',
            borderLeftStyle: 'solid',
          }}
        >
          <Box
            className={'status-panel-mobile'}
            sx={{
              display: 'flex',
            }}
          >
            {slots.StateIconComponent &&
              (slots.StateIconComponent !=
                STATUS_PANEL_SECTION_DEFAULT_STATE_COMPONENT ||
                state != undefined) && (
                <slots.StateIconComponent
                  sx={{ p: 0, paddingX: 1 }}
                  {...stateProps?.chipProps}
                  label={stateProps?.displayName ?? state}
                  icon={
                    <CustomIcon
                      sx={{ height: 16, width: 16 }}
                      {...stateProps?.iconProps}
                    />
                  }
                  size={`small`}
                  showBorder
                  {...slotProps?.stateIconComponent}
                />
              )}

            {statusMessages &&
              statusMessages.map((eachStatusMessage, idx) => (
                <Box
                  className={[
                    'item',
                    'status-panel-mobile',
                    ...(idx > 0 ? ['status-panel-hide-tiny'] : []),
                  ].join(' ')}
                  key={`status-message-${idx}`}
                  sx={{
                    borderLeftWidth: idx > 0 ? 1 : 0,
                    borderLeftColor: 'midnight.one',
                    borderLeftStyle: 'solid',
                    paddingX: 2,
                  }}
                >
                  <Typography
                    variant={'helperText'}
                    sx={{
                      display: '-webkit-box',
                      '-webkit-box-orient': 'vertical',
                      alignSelf: 'stretch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordWrap: 'break-word',
                      '-webkit-line-clamp': '1',
                    }}
                  >
                    {eachStatusMessage}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box className={'status-panel-hide-tiny'} sx={{ width: 1 }}>
            <Tooltip
              title={`${
                !progressType || progressType === 'determinate'
                  ? `${Math.round(
                      progress === undefined ? 0 : progress * 100,
                    )}%`
                  : ``
              }`}
            >
              <LinearProgress
                variant={progressType ?? 'determinate'}
                value={Math.round(progress === undefined ? 0 : progress * 100)}
              />
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
}
