import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useSubscription } from '@apollo/client';
import { Slide, SlideProps, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';

import { useAuth } from '../../context/auth-context';
import { SystemMessage, SystemMessageType } from '../../graphql/gen/graphql';
import { OnSystemMessageReceived } from '../../graphql/webEvents';
import { getLogger } from '../../utils/logging';
import { Talert } from '../Alert/Talert';
import IdleTimer from '../IdleTimer/IdleTimer';
import { OrgProperties } from '../OrgSettings/OrgProperties';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function TransitionLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

export default function MainLayout() {
  const logger = getLogger(
    'components.Layouts.MainLayout' /*FUTURE import.meta.url ?*/,
  );
  const { organization } = useParams();
  const containerSt = useStyles();
  const { setOrg } = useAuth();

  const [currentSystemMessage, setCurrentSystemMessage] =
    useState<SystemMessage | null>(null);

  const { data: webEventsData, loading: webEventsLoading } = useSubscription(
    OnSystemMessageReceived,
  );

  useEffect(() => {
    if (webEventsData) {
      logger.debug('Got web event!', webEventsData);
      setCurrentSystemMessage(webEventsData.systemMessage);
    }
  }, [webEventsData]);

  useEffect(() => {
    setOrg(organization);
  }, [organization]);
  const handleExited = () => {
    setCurrentSystemMessage(null);
  };

  const { user } = useAuth();
  const loggedInOrg = user?.loginSession?.loggedInOrg;
  const timeout =
    loggedInOrg?.properties &&
    loggedInOrg?.properties[OrgProperties.USER_IDLE_TIMEOUT];

  return (
    <Box display="flex">
      <Container maxWidth={false} classes={{ root: containerSt.root }}>
        <Outlet />
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={currentSystemMessage != null}
        onClose={() => {
          setCurrentSystemMessage(null);
        }}
        TransitionComponent={TransitionLeft}
        autoHideDuration={
          currentSystemMessage?.autoHideDuration
            ? currentSystemMessage?.autoHideDuration
            : undefined
        }
      >
        <Box>
          <Talert
            onClose={() => {
              setCurrentSystemMessage(null);
            }}
            alertTitle={
              currentSystemMessage?.title
                ? currentSystemMessage?.title
                : undefined
            }
            color={
              currentSystemMessage?.type === SystemMessageType.Refresh
                ? 'darkWarning'
                : 'darkInfo'
            }
            sx={{ width: '100%', maxWidth: 600 }}
            TabuttonProps={
              currentSystemMessage?.type === SystemMessageType.Refresh
                ? {
                    children: <Box>Refresh</Box>,
                    onClick: () => {
                      window.location.reload();
                    },
                    size: 'small',
                    sx: {
                      ml: 2,
                      color:
                        currentSystemMessage?.type === SystemMessageType.Refresh
                          ? 'darkWarning.contrastText'
                          : 'darkInfo.contrastText',
                      '&:hover': {
                        borderColor: '#fff',
                        backgroundColor: '#fff',
                        color:
                          currentSystemMessage?.type ===
                          SystemMessageType.Refresh
                            ? 'darkWarning.main'
                            : 'darkInfo.main',
                      },
                    },
                  }
                : undefined
            }
          >
            <Box sx={{ display: 'flex' }}>
              <Box>{currentSystemMessage?.msg}</Box>
            </Box>
          </Talert>
        </Box>
      </Snackbar>
      {timeout && <IdleTimer timeout={timeout} />}
    </Box>
  );
}
