import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { isEmpty, take } from 'lodash';

import { toDatabaseRoot } from '../../RouteTable';
import { Tabutton } from '../Button/Tabutton';

export function PrivilegeCheck({
  results,
  undroppedTables,
  numOfTables,
}: {
  results: any;
  undroppedTables: any[];
  numOfTables?: number;
}) {
  const navigate = useNavigate();
  const { organization, warehouse, database } = useParams();

  return (
    <>
      {(!results?.database ||
        !results?.tables ||
        !isEmpty(undroppedTables)) && (
        <Box
          sx={{
            borderRadius: '4px',
            backgroundColor: 'sunset.half',
            p: 2,
            display: 'flex',
            my: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              pr: 2,
            }}
          >
            <ErrorOutlineIcon color={`error`} />
          </Box>
          <Box sx={{ color: 'midnight.seven' }}>
            <Typography variant={`subtitle2`} mb={`4px`}>
              Error
            </Typography>
            <Typography variant={`body2`}>
              {`${
                !isEmpty(undroppedTables)
                  ? 'This Database could not be dropped.'
                  : 'This Database cannot be dropped.'
              }`}
            </Typography>
          </Box>
        </Box>
      )}
      {!isEmpty(results?.tablesWithoutPrivs) || !isEmpty(undroppedTables) ? (
        <Box sx={{ my: 4 }}>
          {!isEmpty(undroppedTables) ? (
            <Typography variant={`subtitle1`}>{`${
              numOfTables! - undroppedTables.length
            } table${
              numOfTables! - undroppedTables.length === 1 ? ' was ' : 's were '
            }dropped. ${undroppedTables.length} table${
              undroppedTables.length === 1 ? ' was ' : 's were '
            }not dropped.`}</Typography>
          ) : (
            <Typography
              variant={`subtitle1`}
            >{`You don't have DROP privilege on ${
              results.tablesWithoutPrivs.length
            } table${
              results.tablesWithoutPrivs.length > 1 ? 's:' : ':'
            }`}</Typography>
          )}
          {!isEmpty(results?.tablesWithoutPrivs) && (
            <List
              sx={(theme) => ({
                border: `1px solid ${theme.palette.midnight.two}`,
                borderRadius: '4px',
                mt: 2,
              })}
            >
              {take(results?.tablesWithoutPrivs, 10).map(
                (table: any, index) => (
                  <ListItem
                    key={`${table.tableName}-${index}`}
                    secondaryAction={
                      <IconButton edge={`end`}>
                        <ChevronRightIcon sx={{ color: 'sky.seven' }} />
                      </IconButton>
                    }
                    sx={(theme) => ({
                      cursor: 'pointer',
                      borderBottom: `1px solid ${theme.palette.midnight.two}`,
                      '&:last-of-type': {
                        borderBottom: '1px solid transparent',
                      },
                    })}
                    onClick={() => navigate(table.tableLink)}
                  >
                    <ListItemText
                      primary={table?.tableName}
                      sx={{ color: 'sky.seven' }}
                    />
                  </ListItem>
                ),
              )}
              {results?.tablesWithoutPrivs.length > 10 && (
                <ListItem>
                  <ListItemText
                    primary={`... plus ${
                      results.tablesWithoutPrivs.length - 10
                    } more!`}
                  />
                </ListItem>
              )}
            </List>
          )}
          {!isEmpty(undroppedTables) && (
            <Box sx={{ my: 4 }}>
              <Tabutton
                variant={`contained`}
                onClick={() =>
                  navigate(toDatabaseRoot(organization!, warehouse!, database!))
                }
              >
                Show undropped tables
              </Tabutton>
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
}
