import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { googlecode } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Tabutton } from '../Button/Tabutton';

export default function ViewSql({ viewData }: any) {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  const versionId = viewData?.metadata?.current_version_id;

  const sql = viewData?.metadata?.versions?.find(
    (version: any) => version.version_id === versionId,
  );

  return (
    <Box>
      {sql?.representations.map((version: any, index: number) => {
        return (
          <Grid
            key={index}
            container
            sx={{
              width: 0.95,

              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              <Typography variant={'body1'}>
                {version.dialect}-{version.type}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                pre: {
                  m: 0,
                  height: 1,
                },
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              {' '}
              <SyntaxHighlighter
                language="SQL"
                style={{ ...googlecode, margin: 0 as React.CSSProperties }}
                customStyle={{
                  backgroundColor: '#F9FAFA',
                  border: `1px solid #D5DADD`,
                }}
                showLineNumbers={false}
              >
                {version.sql}
              </SyntaxHighlighter>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 1 / 2,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <Tabutton
                  onClick={() => copyToClipBoard(version.sql)}
                  size={'small'}
                  sx={{ alignSelf: 'right' }}
                  endIcon={<FileCopyIcon />}
                >
                  Copy SQL
                </Tabutton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}
