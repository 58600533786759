import React from 'react';

import { Box, BoxProps, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';

import {
  DashboardContentType,
  DataGridToolBar,
  MenuContentType,
} from '../DataGridToolbar/DataGridToolbar';

export interface SchemaEditToolbarProps extends BoxProps {
  title: string;
  showQuickFilter: boolean;
  apiRef: any;
  pageSize: number;
  setPageSize: () => void;
  rowsPerPageOptions: any;
  headerName: JSX.Element;
  icon?: JSX.Element;
  cloudProviderIcon?: string;
  refreshResourceContent: MenuContentType;
  editResourceContent: MenuContentType;
  addResourceDashboardContent: DashboardContentType;
  dashboardMenuContent: JSX.Element;
  secondRowContent: JSX.Element;
  rowCount: number;
  showRange: boolean;
  pageAtNumber: number;
  setPage: any;
}

export function SchemaEditToolbar({
  title,
  showQuickFilter,
  apiRef,
  pageSize,
  setPageSize,
  rowsPerPageOptions,
  headerName,
  cloudProviderIcon,
  icon,
  refreshResourceContent,
  editResourceContent,
  addResourceDashboardContent,
  dashboardMenuContent,
  secondRowContent,
  rowCount,
  showRange,
  pageAtNumber,
  setPage,
  ...props
}: SchemaEditToolbarProps) {
  return (
    <Box {...props}>
      <DataGridToolBar
        apiRef={apiRef}
        pageSize={pageSize}
        setPageSize={setPageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        headerName={headerName}
        cloudProviderIcon={cloudProviderIcon}
        icon={icon}
        refreshResourceContent={refreshResourceContent}
        addResourceDashboardContent={addResourceDashboardContent}
        editResourceContent={editResourceContent}
        rowCount={rowCount}
        dashboardMenuContent={dashboardMenuContent}
        pageAtNumber={pageAtNumber}
        showRange={showRange}
        setPage={setPage}
        secondRowContent={secondRowContent}
        showQuickFilter={showQuickFilter}
      />
      <GridToolbarContainer sx={{ backgroundColor: 'dusk.half' }}>
        {title !== undefined && title !== '' && (
          <Typography variant={'subtitle1'} p={1}>
            {title}
          </Typography>
        )}
      </GridToolbarContainer>
    </Box>
  );
}
