import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';

// @ts-ignore
import { toSearchResult } from '../../RouteTable';
import { SearchResult } from '../../graphql/gen/graphql';
// @ts-ignore
import NavSearch from '../Forms/NavSearch';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    top: 50,
    padding: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
});
interface NavSearchDialogProps {
  onHideController?: (onHideFunc: () => void) => void;
}
const NavSearchDialog = create(({ onHideController }: NavSearchDialogProps) => {
  const modal = useModal();
  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      classes={{
        paper: classes.dialog,
      }}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <NavSearch
        onCancel={modal.hide}
        onNavSelected={(searchResult: SearchResult) => {
          modal.hide();
          navigate(toSearchResult(searchResult));
          //onNavSelected(searchResult);
        }}
      />
    </Dialog>
  );
});

export default NavSearchDialog;

export function showNavSearchDialog(
  onHideController?: (onHideFunc: () => void) => void,
) {
  return show<boolean, any, NavSearchDialogProps>(NavSearchDialog, {
    onHideController,
  });
}
