import React from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GroupIcon from '@mui/icons-material/Group';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SecurityIcon from '@mui/icons-material/Security';
import {
  Box,
  Typography,
  CardHeader,
  Tooltip,
  IconButton,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { toSingleRole } from '../../RouteTable';
import { Organization } from '../../graphql/gen/graphql';
import { getRoleDescription } from '../AccessControl/RoleHelpers';
import { Tacard } from '../Card/Tacard';

const useCardHeaderStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiCardHeader-content': {
        minWidth: 0,
      },

      '& .MuiCardHeader-title': {
        fontSize: '20px',
        fontWeight: 500,
        fontFamily: 'Merriweather Sans',
      },
    },
  }),
);

type RoleDetailsProps = {
  organization: Organization;
  peopleCount: number;
  nestedRoleCount: number;
  roleName: string;
  roleDisplayName: string;
};

export default function SystemRoleDetails({
  organization,
  peopleCount,
  nestedRoleCount,
  roleName,
  roleDisplayName,
}: RoleDetailsProps) {
  const cardHeaderClasses = useCardHeaderStyles();
  const navigate = useNavigate();
  return (
    <>
      <Tacard cardGrid marginRight={'0'}>
        <CardHeader
          className={cardHeaderClasses.root}
          title={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                wordWrap: 'break-word',
                minWidth: '0px',
              }}
            >
              <Typography variant="h6" sx={{ minWidth: '0px' }}>
                {roleDisplayName}
              </Typography>
              {roleName === 'SECURITY_ADMIN' && (
                <LocalPoliceIcon sx={{ ml: '4px' }} />
              )}
              {roleName === 'ORG_ADMIN' && <SecurityIcon sx={{ ml: '4px' }} />}
              {roleName === 'EVERYONE' && <GroupIcon sx={{ ml: '4px' }} />}
            </Box>
          }
          subheader={
            <>
              <Box
                onClick={() =>
                  navigate(toSingleRole(organization.name || '', roleName))
                }
                sx={{
                  cursor: 'pointer',
                }}
              >
                {roleName === 'EVERYONE' ? (
                  <Typography variant={'subtitle2'} color={'sky.seven'}>
                    {`All people | All roles`}
                  </Typography>
                ) : (
                  <Typography variant={'subtitle2'} color={'sky.seven'}>
                    {`${peopleCount} ${
                      peopleCount === 1 ? 'person' : 'people'
                    } | ${nestedRoleCount} ${
                      nestedRoleCount === 1 ? 'role' : 'roles'
                    }`}
                  </Typography>
                )}
              </Box>
              <Typography variant="body2" mt={1} color={'midnight.nine'}>
                {getRoleDescription(roleName)}
              </Typography>
            </>
          }
          action={
            <>
              <Tooltip title="Role details" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    navigate(toSingleRole(organization.name || '', roleName))
                  }
                  sx={(theme) => ({
                    padding: '4px',
                    border: `1px solid ${theme.palette.midnight.two}`,
                    '&:hover': {
                      backgroundColor: theme.palette.sky.one,
                    },
                    '&:active': {
                      backgroundColor: theme.palette.sky.two,
                    },
                    '&:focus': {
                      border: `1px solid ${theme.palette.sky.seven}`,
                    },
                  })}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Tooltip>
            </>
          }
          sx={{ p: 3 }}
        />
      </Tacard>
    </>
  );
}
