import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Formik, FormikValues, Form } from 'formik';
import { includes } from 'lodash';

import { OrgAuthProvider } from '../../../graphql/gen/graphql';
import { createOrganizationAuth } from '../../../graphql/organization';
import { getApolloErrorMessage } from '../../../utils/ApolloUtils';
import { getLogger } from '../../../utils/logging';
import { AWSIdentityProviderForm } from '../Forms/AWSIdentityProviderForm';

export function CreateIdentityProviderDialog({
  organizationId,
  isOpen,
  onDismiss,
  onIdentityProviderCreated,
}: {
  organizationId: string;
  isOpen: boolean;
  onDismiss: () => void;
  onIdentityProviderCreated: (data: boolean) => void;
}) {
  const [createSSO, { error }] = useMutation(createOrganizationAuth);
  const [errorMessage, setErrorMessage] = useState('');
  const logger = getLogger(
    'components.Credentials.CreateIdentityProviderDialog',
  );

  const onSubmit = async (values: FormikValues) => {
    const accountId = values.clientId.split(':')[4];
    await createSSO({
      variables: {
        organizationId: organizationId,
        request: {
          clientId: values.clientId,
          issuer: accountId,
          authType: OrgAuthProvider.IamSso,
        },
      },
    })
      .then((data) => {
        if (data) {
          onIdentityProviderCreated(data.data.createOrganizationAuth);
          onDismiss();
        }
      })

      .catch((error) => {
        logger.debug(getApolloErrorMessage(error));
        if (includes(error, '409: Conflict')) {
          setErrorMessage('ARN is already in use as a Tabular identity');
        } else {
          setErrorMessage('Something went wrong. Please try again.');
        }
      });
  };

  const handleCancel = () => {
    setErrorMessage('');
    onDismiss();
  };

  return (
    <Dialog open={isOpen}>
      <AWSIdentityProviderForm
        organizationId={organizationId}
        onIdentityProviderCreated={onIdentityProviderCreated}
        onDismiss={onDismiss}
      />
    </Dialog>
  );
}
