import { gql } from '@apollo/client';

export const FRAGMENTS = {
  user: gql`
    fragment UserFields on User {
      id
      email
      displayName
      properties
      loginSession {
        loggedInOrg {
          id
          name
        }
        membership {
          id
          displayName
          properties
        }
      }
      organizations {
        id
        name
      }
    }
  `,
  roleMember: gql`
    fragment RoleMemberFields on RoleMember {
      id
      roleId
      email
      displayName
      properties
      isAdmin
    }
  `,
  subject: gql`
    fragment SubjectFields on Role {
      id
      name
      displayName
      createdAt
      createdBy
      createdByMember {
        id
        properties
        displayName
      }
      lastModified
      lastModifiedBy
      lastModifiedByMember {
        id
        properties
        displayName
      }
      isBuiltIn @client
    }
  `,
  resource: gql`
    fragment ResourceFields on Authorization {
      id
      privilege
      resource
      resourceType
      withGrant
      roleId
      role {
        id
        displayName
      }
    }
  `,
  roleAuths: gql`
    fragment RoleAuthFields on Authorization {
      id
      privilege
      resource
      resourceType
      withGrant
      subjectId
      role {
        id
        name
        displayName
        users {
          ...RoleMemberFields
        }
      }
    }
  `,
  org: gql`
    fragment OrgFields on Organization {
      id
      name
      displayName
    }
  `,
  memberFieldsLight: gql`
    fragment MemberFieldsLight on Member {
      id
      displayName
      properties
      defaultRoleId
      createdAt
      user {
        id
        email
      }
    }
  `,
  orgInvite: gql`
    fragment OrgInviteFields on Invite {
      inviteCode
      email
      organizationId
      createdAt
      createdBy
    }
  `,

  orgCredential: gql`
    fragment OrgCredentialFields on OrganizationCredential {
      organizationId
      key
      creator
      name
      active
      createdAt
      lastSession
      lastModified
      description
      type
      roleId
      warehouses {
        id
        name
      }
      iamRoles {
        roleArn
      }
    }
  `,
  linkedCredential: gql`
    fragment LinkedCredentialFields on LinkedCredentialType {
      organizationId
      key
      creator
      name
      active
      createdAt
      member {
        id
        displayName
        properties
        user {
          email
        }
      }
      lastSession
      lastModified
      description
      type
      roleId
      warehouses {
        id
        name
      }
      iamRoles {
        roleArn
      }
    }
  `,

  authConfig: gql`
    fragment AuthConfigFields on AuthConfig {
      organizationId
      issuer
      clientId
    }
  `,
  warehouse: gql`
    fragment WarehouseFields on Warehouse {
      id
      name
      region
      location
      roleArn
      lastModified
      lastModifiedBy
      managed
      properties {
        key
        value
      }
      createdAt
      storageProfile {
        id
        bucket
        storageType
        properties
      }
    }
  `,
  resourceStats: gql`
    fragment ResourceStatsFields on TableStatistics {
      id
      commitCnt
      opAppendCnt
      opOverwriteCnt
      opDeleteCnt
      opReplaceCnt
      totalNamespaces
      totalTables
      totalBytes
      bytesAdded
      bytesRemoved
      maxBytes
      lastModified
      lastModifiedBy
    }
  `,
  extTable: gql`
    fragment TableFields on Table {
      warehouseId
      databaseName
      name
      id
      tableId
      location
      createdAt
      lastModified
      lastModifiedBy
      primary
      contentType
      namespaceId
      properties {
        key
        value
      }
      additionalLocations
    }
  `,
  droppedTable: gql`
    fragment DroppedTableFields on DroppedTable {
      warehouseId
      warehouseName
      namespaceId
      namespaceName
      name
      id
      droppedOn
      droppedBy
      droppedByMember {
        id
        displayName
        properties
        user {
          id
          email
        }
      }
      totalBytes
      privileges
    }
  `,
  tableRef: gql`
    fragment TableRefFields on TableRef {
      name
      id
      namespaceId
      contentType
    }
  `,
  bucket: gql`
    fragment BucketFields on Bucket {
      name
      region
      roleArn
      externalId
    }
  `,
  tableMetadata: gql`
    fragment MetadataFields on TableMetadata {
      format_version
      table_uuid
      location
      last_updated_ms
      last_column_id
      current_schema_id
      default_spec_id
      last_partition_id
      default_sort_order_id
      current_snapshot_id
      current_version_id
      versions {
        schema_id
        version_id
        representations {
          type
          sql
          dialect
        }
      }
    }
  `,
  table: gql`
    fragment PartitionFields on PartitionField {
      name
      transform
      source_id
      field_id
    }

    fragment SortOrderFields on SortOrderField {
      transform
      source_id
      direction
      null_order
    }

    fragment SchemaFields on SchemaField {
      id
      name
      required
      type
      doc
    }
  `,
  snapshot: gql`
    fragment SnapshotFields on Snapshot {
      snapshot_id
      parent_snapshot_id
      timestamp_ms
      summary
      manifest_list
      schema_id
    }
  `,
  stats: gql`
    fragment StatisticsFields on TableStatistics {
      id
      commitCnt
      opAppendCnt
      opOverwriteCnt
      opDeleteCnt
      opReplaceCnt
      totalBytes
      bytesAdded
      bytesRemoved
      lastModified
      lastModifiedBy
      totalRows
      rowsAdded
      rowsDeleted
    }
  `,
  compaction: gql`
    fragment CompactionFields on CompactionSummary {
      compactions
      startingFiles
      rewrittenFiles
      filePercent
      startingSize
      rewrittenSize
      sizeChange
      sizePercent
    }
  `,

  membership: gql`
    fragment MembershipFields on Membership {
      id
      displayName
      properties
      defaultRoleId
    }
  `,
  warehouseSummary: gql`
    fragment WarehouseSummaryFields on Warehouse {
      createdAt
      createdBy
      lastModified
      lastModifiedBy
      id
      name
      region
      roleArn
      managed
      location
    }
  `,
  storageProfile: gql`
    fragment StorageProfileFields on StorageProfile {
      createdAt
      createdBy
      createdByMember {
        id
        displayName
        properties
        user {
          id
          email
        }
      }
      lastModified
      lastModifiedBy
      id
      organizationId
      accountId
      region
      bucket
      bucketRegion
      roleArn
      externalId
      properties
      storageType
      filteredWarehouseCount
    }
  `,
  storageProfilesList: gql`
    fragment StorageProfilesListWithUsage on StorageProfile {
      id
      organizationId
      accountId
      region
      bucket
      bucketRegion
      roleArn
      externalId
      properties
      storageType
      filteredWarehouseCount
      requesterHasUsage
    }
  `,
  orgAuths: gql`
    fragment OrgAuthFields on OrgAuth {
      id
      clientId
      issuer
      authType
      createdAt
      createdBy
      createdByMember {
        id
        displayName
        properties
        user {
          email
        }
      }
      lastSession
    }
  `,
  inFlightCascadeTasks: gql`
    fragment InFlightCascadeTaskFields on InFlightCascadeTaskResult {
      taskId
      description
      status
      createdAuthCount
      modifiedAuthCount
      deletedAuthCount
      skippedAuthCount
      subjectIds
      tablesSkippedCount
      tablesUpdatedCount
      databasesSkippedCount
      databasesUpdatedCount
      tablesTotalCount
      progress
      createdBy
    }
  `,
  pipelines: gql`
    fragment PipelineFields on Pipeline {
      id
      organizationId
      name
      type
      state
      description
      sources {
        ... on PipelineTableSource {
          id
          type
          context {
            tableReferenceId
            branch
          }
          _table {
            tableId
            namespaceId
            warehouseId
            tableName
            namespaceName
            warehouseName
          }
        }
      }
      target {
        ... on PipelineTableTarget {
          id
          type
          context {
            tableReferenceId
            branch
          }
          _table {
            tableId
            namespaceId
            warehouseId
            tableName
            namespaceName
            warehouseName
          }
        }
      }
      createdAt
      lastModified
      createdByMember {
        id
        organizationId
        user {
          email
          id
        }
        properties
        displayName
      }
      lastModifiedByMember {
        id
        organizationId
        user {
          email
          id
        }
        properties
        displayName
      }
    }
  `,
  tableLoadStatus: gql`
    fragment TableLoadStatusFragment on TableLoadStatus {
      tableLoadId
      warehouseId
      tableRefId
      statusMessage
      state
      progress
      attempt
      totalBytes
      totalLoadedBytes
      totalFileCount
      totalLoadedFileCount
      backFillFileCount
      lastModified
      createdAt
      expireAt
      timeoutAt
      wakeAt
      ts
      loadTableState
      tableLoadMode
      autoLoaderDetected
      notificationConfigIssueDetected
      postDMZTotalFileCount
    }
  `,
};
