import * as React from 'react';
import { KeyboardEventHandler } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Box, CardHeader, IconButton, Typography } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { some } from 'lodash';

import {
  Privilege,
  Role,
  RoleMember,
  RoleResourcePrivilegeType,
} from '../../graphql/gen/graphql';
import { ESCustomPagination } from '../../pages/helpers';
import { Refresh } from '../Button/Refresh';

export interface RoleMemberType
  extends Omit<Role, '__typename'>,
    Omit<RoleMember, '__typename'> {
  __typename: string;
  email: string;
}
export const ResourceTypeDisplay = {
  LABEL: 'Label',
  DATABASE: 'Database',
  STORAGE_PROFILE: 'Storage profile',
  TABLE: 'Table',
  WAREHOUSE: 'Warehouse',
  ROLE: 'Role',
  VIEW: 'View',
};

export const RoleAuthorizationsCustomToolbar = ({
  totalCount,
  apiRef,
  pageSize,
  onRefetch,
  displayName,
  handleKeyUp,
  handleKeyDown,
  onBackButton,
  onNextButton,
  page,
  icon,
}: {
  totalCount: number;
  apiRef: React.MutableRefObject<GridApiPro>;
  pageSize: number;
  onRefetch: () => void;
  displayName: string;
  handleKeyUp: KeyboardEventHandler<HTMLElement>;
  handleKeyDown?: KeyboardEventHandler<HTMLElement>;
  onBackButton: () => void;
  onNextButton: () => void;
  page: number;
  icon?: JSX.Element;
}) => {
  const currentRowsCount = apiRef.current.getRowsCount();
  const properPageNum = page - 1;
  const mobileToolBar = useMediaQuery('(max-width:500px)');

  return (
    <>
      <CardHeader
        sx={(theme) => ({
          paddingY: 1,
          paddingX: 2,
          margin: 'none',
          backgroundColor: 'midnight.one',
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
        title={
          <Box
            display="flex"
            flexDirection={mobileToolBar ? 'column' : 'row'}
            justifyContent={mobileToolBar ? 'flex-start' : 'space-between'}
            padding={0}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              sx={{ wordBreak: 'break-word' }}
            >
              <Typography variant={'h6'} pr={1}>
                {`${displayName}`}
              </Typography>
              {icon}
            </Box>
            <Box
              display={'flex'}
              justifyContent={mobileToolBar ? 'flex-start' : 'flex-end'}
              paddingTop={mobileToolBar ? 1 : 0}
            >
              <Box pr={2} display={'flex'} alignItems={'center'}>
                <Paper
                  component="form"
                  sx={(theme) => ({
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 0,
                    borderRadius: '24px',
                    backgroundColor: 'white',
                    height: '28px',
                    cursor: 'pointer',
                    border: `1px solid ${theme.palette.midnight.five}`,
                    '&:hover, &:active, &:focus': {
                      border: `1px solid ${theme.palette.sky.seven}`,
                    },
                  })}
                >
                  <IconButton
                    type="button"
                    aria-label="search"
                    sx={{ padding: '4px' }}
                  >
                    <SearchIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                  <InputBase
                    sx={(theme) => ({
                      ml: 1,
                      flex: 1,
                      fontSize: theme.typography.pxToRem(14),
                      fontStyle: 'italic',
                    })}
                    placeholder="Search resources"
                    inputProps={{ 'aria-label': 'search resources' }}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                  />
                </Paper>
              </Box>

              <Refresh refreshList={() => onRefetch()} title={'Refresh list'} />
            </Box>
          </Box>
        }
      />
      <Box
        display={`flex`}
        alignItems={`center`}
        justifyContent={`space-between`}
        sx={(theme) => ({
          pl: 2,
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
      >
        <Typography variant={`inputLabel`}>{`Showing ${
          properPageNum * pageSize + 1
        } - ${
          properPageNum * pageSize + currentRowsCount
        } of ${totalCount}`}</Typography>
        <ESCustomPagination
          apiRef={apiRef}
          pageSize={pageSize}
          onBackButton={onBackButton}
          onNextButton={onNextButton}
          page={page}
          paginationType={`Resources`}
        />
      </Box>
    </>
  );
};
