import React from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import {
  athenaPySparkWorkgroup,
  ExternalBreadcrumbs,
} from '../../CreateStorageBucket/BucketSteps/helpers';
import { Tabulink } from '../../Link/Tabulink';
import { SecurityMethod } from '../helpers';

export const AthenaWorkgroupSetup = ({
  region,
  steps,
  securityType,
  isSecurityAdmin,
  handleChange,
}: {
  region: string;
  steps: any;
  securityType: SecurityMethod;
  isSecurityAdmin: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const individualSteps = steps.slice(2);
  return (
    <Box>
      <Box sx={{ width: 0.95 }}>
        <Grid
          container
          sx={{
            width: 1,
            border: 1,
            borderColor: 'midnight.two',
            borderRadius: '8px',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              padding: 1 / 2,
              borderRadius: '8px',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottom: 1,
              borderColor: 'midnight.two',
              backgroundColor: 'dusk.half',
            }}
          >
            <Typography variant={'body1'}>
              {`Follow link to Amazon Athena ${steps[0].title} setup`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              padding: 1,
              borderBottom: 0,
              borderColor: 'midnight.two',
            }}
          >
            <Typography sx={{ display: 'inline-flex', mr: 1 }}>
              <Tabulink
                external
                href={`https://${region}.console.aws.amazon.com/athena/home?region=${region}#/workgroups/create`}
                variant="body1"
                rel="noopener"
                aria-label="Amazon Athena create workgroup"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {'Amazon Athena workgroup creation'}
                <OpenInNewIcon fontSize="small" sx={{ ml: '2px', mr: '3px' }} />
              </Tabulink>
              (instructions detailed below)
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        In Amazon Athena Create workgroup:
        <ExternalBreadcrumbs breadcrumbs={athenaPySparkWorkgroup} />
        <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              Start by entering a name in <b>Workgroup name</b>
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              For the <b>Analytics engine</b>, select{' '}
              <b>{steps[1].analyticsEngine}</b>
            </Typography>
          </ListItem>
          {individualSteps.map((step: any, index: number) => (
            <ListItem
              key={step.step + index}
              sx={{ display: 'list-item', p: 0 }}
            >
              {step.step}
            </ListItem>
          ))}
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              <b>Create workgroup</b> and continue below
            </Typography>
          </ListItem>
        </List>
        <Typography paddingTop={3} variant={'body1'}>
          <b>Choose a security method for this connection and continue:</b>
        </Typography>
        <RadioGroup
          sx={{ ml: 2, width: 'fit-content' }}
          defaultValue={SecurityMethod.IamMapping}
          value={securityType}
          onChange={handleChange}
        >
          <FormControlLabel
            value={SecurityMethod.IamMapping}
            control={<Radio />}
            label={
              isSecurityAdmin
                ? 'AWS IAM role mapping'
                : 'AWS IAM role mapping (Security admin is required to add a new IAM role mapping)'
            }
          />
          <Typography variant={'helperText'}>
            An AWS IAM identity can be mapped to a Tabular role for
            authentication without the need for a credential
          </Typography>
          <FormControlLabel
            value={SecurityMethod.MemberCredential}
            control={<Radio />}
            label="Member credential"
          />
          <Typography variant={'helperText'}>
            A Member credential will provide authentication for a specific user
          </Typography>
        </RadioGroup>
      </Box>
    </Box>
  );
};
