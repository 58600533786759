import React, { useState, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Popper,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { concat, forEach, isEmpty, remove } from 'lodash';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import * as Yup from 'yup';

import { Property } from '../../graphql/gen/graphql';
import {
  deleteTableProperties,
  fetchFullTableQuery,
  updateTableProperties,
} from '../../graphql/table';
import { TablePropertyGroups } from '../../utils/properties';
import { relativeTimeAutoFormat } from '../../utils/time';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import { Tacard } from '../Card/Tacard';
import StatusBars from '../Feedback/StatusBars';
import TextField from '../Forms/TextField';
import { LastUpdateModifiedByPopover } from '../Popovers/LastUpdateModifiedByPopover';
import PropertyGroupCard from './PropertyGroupCard';
import { TableProperties } from './TableProperties';
import { TableSettingsPropsType } from './types';

const TableSettings = ({
  tableName,
  description,
  organizationId,
  warehouseId,
  database,
  lastUpdated,
  updatedBy,
  userCanEdit,
  tableProperties,
  bucketName,
  tabularHostedBucket,
  currentSchema,
  storageProfile,
  hideDueToStorageType,
  isView,
  resourceType,
  orgName,
  orgDisplayName,
  isPipelineTarget = false,
}: TableSettingsPropsType) => {
  const [descriptionValue, setDescriptionValue] = useState(description);
  const [overridesToDelete, setOverridesToDelete] = useState([]);
  const [editingDescription, setEditingDescription] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [formChange, setFormChange] = useState(false);
  const [deleteProperties] = useMutation(deleteTableProperties, {
    variables: {
      warehouseId,
      database,
      table: tableName,
      contentType: resourceType?.toUpperCase(),
    },
    onError: () => setApiError(true),
  });
  const [setTableProperties, { loading }] = useMutation(updateTableProperties, {
    variables: {
      warehouseId,
      database,
      table: tableName,
      contentType: resourceType?.toUpperCase(),
    },
    refetchQueries: [
      {
        query: fetchFullTableQuery,
        variables: {
          orgId: organizationId,
          orgName,
          orgDisplayName,
          warehouseId,
          database,
          tableName,
        },
      },
    ],
    awaitRefetchQueries: true,
    onError: () => setApiError(true),
  });

  const mappedProps = useMemo(
    () =>
      tableProperties?.map(({ key, value }) => ({
        key,
        value,
      })),
    [tableProperties],
  );
  const isTabularHostedBucket = () => {
    return tabularHostedBucket || hideDueToStorageType;
  };

  const helperFunctions = Object.freeze({
    hostedBucket: { helperFunction: isTabularHostedBucket },
  });

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDescriptionValue(event.target.value);
  };

  const finishReset = () => {
    if (editingDescription) {
      setEditingDescription(false);
    } else {
      setOpenSnackbar(true);
    }
  };

  const handlePropertySubmit = async (propArray: Property[]) => {
    const clonedArray = [...propArray];
    await forEach(propArray, async ({ key, value }) => {
      if (isEmpty(value)) {
        // if property value is empty ie: description/comment, then delete property
        remove(clonedArray, (prop) => prop.key === key);

        if (mappedProps.some((prop) => prop.key === key)) {
          //@ts-ignore
          await deleteProperties({ variables: { properties: [key] } });
        }
      }
    });

    if (!isEmpty(overridesToDelete)) {
      //@ts-ignore
      await deleteProperties({ variables: { properties: overridesToDelete } });
    }

    if (!isEmpty(clonedArray)) {
      await setTableProperties({
        variables: {
          //@ts-ignore
          properties: clonedArray,
        },
      }).then(() => finishReset());
    } else {
      finishReset();
    }
  };
  const handleDeleteOverrides = (overridesArray: any) => {
    return new Promise((resolve) => {
      resolve(
        //@ts-ignore
        setOverridesToDelete(concat([...overridesToDelete], overridesArray)),
      );
    });
  };

  const TABLE_DESCRIPTION_INITIAL_VALUES = {
    description: descriptionValue,
  };

  const descriptionCard = (
    <Tacard>
      <CardHeader
        title={`${
          resourceType[0]?.toUpperCase() + resourceType?.slice(1)
        } description`}
      />
      <Formik
        initialValues={TABLE_DESCRIPTION_INITIAL_VALUES}
        validationSchema={Yup.object({
          description: Yup.string(),
        })}
        enableReinitialize
        onSubmit={async (values) => {
          await handlePropertySubmit([
            { key: 'comment', value: values.description },
          ]);
          setFormChange(false);
        }}
      >
        {({ resetForm }) => (
          <Form>
            <CardContent
              sx={(theme) => ({
                borderTop: `1px solid ${theme.palette.midnight.two}`,
              })}
            >
              {descriptionValue || userCanEdit ? (
                <TextField
                  name={'description'}
                  id={'description-input'}
                  multiline
                  //@ts-ignore
                  readOnly={!userCanEdit}
                  maxRows={3}
                  label={'Start typing to add a description'}
                  disabled={!userCanEdit || isPipelineTarget}
                  sx={{
                    width: '100%',
                    '& .MuiInputLabel-root': {
                      marginTop: '3px',
                      '&.Mui-focused': {
                        marginTop: '0px',
                        marginRight: '10px',
                      },
                      '&.MuiFormLabel-filled': {
                        marginTop: '0px',
                      },
                    },
                  }}
                  onClick={() => userCanEdit && setFormChange(true)}
                  onChange={handleDescriptionChange}
                />
              ) : (
                <Typography>No Description</Typography>
              )}
            </CardContent>
            <CardActions
              sx={(theme) => ({
                borderTop: `1px solid ${theme.palette.midnight.two}`,
                display: 'flex',
                justifyContent: 'end',
                p: 2,
              })}
            >
              <Tabutton
                onClick={() => {
                  setDescriptionValue(description);
                  resetForm();
                  setFormChange(false);
                }}
                disabled={!formChange || loading}
              >
                Cancel
              </Tabutton>
              <Tabutton
                loadingBtn
                loading={loading}
                type={'submit'}
                variant={`contained`}
                disabled={!formChange || loading || isPipelineTarget}
              >
                Save Description
              </Tabutton>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Tacard>
  );

  return (
    <>
      {isPipelineTarget && (
        <Talert severity={`warning`} sx={{ mb: 2 }}>
          This table is currently a target table in an active pipeline. Table
          settings have been disabled.
        </Talert>
      )}
      <Talert severity={'info'} sx={{ mb: 2, maxWidth: '400px' }}>
        <>
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Box {...bindHover(popupState)}>
                  <Typography variant={'subtitle2'}>
                    Last updated: {relativeTimeAutoFormat(lastUpdated)}
                  </Typography>
                </Box>
                <Popper
                  {...bindPopper(popupState)}
                  placement={`right`}
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom'],
                      },
                    },
                  ]}
                >
                  <LastUpdateModifiedByPopover
                    modifiedBy={updatedBy}
                    modifiedDate={lastUpdated}
                  />
                </Popper>
              </>
            )}
          </PopupState>
        </>
      </Talert>
      {descriptionCard}
      {!isView &&
        TablePropertyGroups.map((propertyGroup: any, index: number) => {
          const hidePropertyCard =
            //@ts-ignore
            helperFunctions[propertyGroup?.hide]?.helperFunction();

          return hidePropertyCard ? null : (
            <PropertyGroupCard
              key={`property-group-${index}`}
              propertyGroup={propertyGroup}
              mappedProps={mappedProps}
              userCanEdit={userCanEdit && !isPipelineTarget}
              onUpdateProperties={handlePropertySubmit}
              onDeleteOverrides={handleDeleteOverrides}
              bucketName={bucketName}
              currentSchema={currentSchema}
              storageProfile={storageProfile}
            />
          );
        })}
      <TableProperties
        tableProperties={tableProperties}
        warehouseId={warehouseId}
        organizationId={organizationId}
        orgName={orgName}
        orgDisplayName={orgDisplayName}
        userCanEdit={userCanEdit && !isPipelineTarget}
        resourceType={resourceType}
      />

      <StatusBars
        successDisplay={openSnackbar}
        handleSuccessClose={() => setOpenSnackbar(false)}
        successMessage={`${
          resourceType[0]?.toUpperCase() + resourceType?.slice(1)
        } settings have been updated.`}
        errorDisplay={apiError}
        setErrorDisplay={setApiError}
        errorMessage={`Something went wrong. Please refresh the page and try again.`}
      />
    </>
  );
};

export { TableSettings };
