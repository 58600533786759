import React from 'react';

import {
  Box,
  BoxProps,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import { FormikValues, useField, useFormikContext } from 'formik';
import { sortBy } from 'lodash';

import { Warehouse } from '../../graphql/gen/graphql';

interface WarehouseSelectorProps extends BoxProps {
  warehouses: Warehouse[];
  name: string;
  helperText?: string;
  onSelected: (warehouse: Warehouse | undefined) => void;
}
export const WarehouseSelector = ({
  warehouses,
  name,
  helperText,
  onSelected,
  ...props
}: WarehouseSelectorProps) => {
  const [field, meta] = useField(name);
  const { setFieldValue, handleChange } = useFormikContext<FormikValues>();
  const error = meta.error && meta.touched;
  if (error) {
    helperText = `${meta.error} ${helperText ? `: ${helperText}` : ''}`;
  }

  return (
    <>
      <Box {...props}>
        {warehouses && warehouses.length > 0 && (
          <FormControl fullWidth>
            <TextField
              {...field}
              error={!!error}
              select
              label={'Select a warehouse to connect*'}
              onChange={(e) => {
                setFieldValue(name, e.target.value);
                onSelected(
                  warehouses.find((target) => target.id === e.target.value),
                );
                handleChange(e);
              }}
            >
              <MenuItem disabled value="none">
                Select Warehouse
              </MenuItem>
              {sortBy(warehouses, (warehouse) =>
                warehouse.name?.toLowerCase(),
              ).flatMap(({ id, name, region }) => [
                <MenuItem key={id} value={id}>
                  <Box
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <ListItemText>
                      {name} ({region})
                    </ListItemText>
                  </Box>
                </MenuItem>,
              ])}
            </TextField>

            <FormHelperText error={!!error}>{helperText}</FormHelperText>
          </FormControl>
        )}
      </Box>
    </>
  );
};
