import React, { useState } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import TextField from '../../Forms/TextField';
import { AzureAppRegistrationLink, GcsWorkloadIdentityLink } from '../storage';
import {
  ExternalBreadcrumbs,
  createPoolbreadcrumbs,
  getAzureCognitoPoolId,
  getAzureSuggestedCredentialName,
  getAzureCognitoSubjectId,
} from './helpers';

const ApplyAppPermissionsSteps = ({
  federationPoolId,
  federationSubjectId,
  suggestedCredentialName,
}: {
  federationPoolId: string;
  federationSubjectId: string;
  suggestedCredentialName: string;
}) => {
  const { values } = useFormikContext<FormikValues>();
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  return (
    <List sx={{ listStyle: 'decimal', pl: 3 }}>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          On your Azure <i>Storage Account</i>&apos;s home page (
          {values.storageAccountName}) , click <b>Access Control (IAM)</b>.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Click the <b>+ Add </b> tab button and then{' '}
          <b>+ Add role assignment</b>.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          On Role tab: Choose <b>Storage Blob Delegator</b> role from the Job
          function roles.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Click Members tab: Click <b>+ Select Members</b>, search for and
          choose the app you registered, and then <b>select</b>.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Click Review + assign tab: Click the <b>Review + assign</b> button.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          On your Azure <i>Storage Container</i>&apos;s home page (
          {values.bucketName}) , click <b>Access Control (IAM)</b>.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Click the <b>+ Add </b> tab button and then{' '}
          <b>+ Add role assignment</b>.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          On Role tab: Choose <b>Storage Blob Data Contributor</b> role from the
          Job function roles.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Click Members tab: Click <b>+ Select Members</b>, search for and
          choose the app you registered, and then <b>select</b>.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Click Review + assign tab: Click the <b>Review + assign</b> button.
        </Typography>
      </ListItem>
    </List>
  );
};

export default function AzureApplyAppPermissionsStep() {
  const { values } = useFormikContext<FormikValues>();
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} sx={{ px: 5, pb: 3 }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant="body1">
            {`Grant access to your container for your new Azure Tabular app.`}
          </Typography>
        </Box>
        <Card variant={'outlined'} sx={{ borderRadius: 2, mt: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
            <CardHeader
              title={
                <>
                  <Typography variant={'subtitle1'} sx={{ mb: 1 }}>
                    {"Storage container's Access Control (IAM)"}
                  </Typography>
                  <ExternalBreadcrumbs
                    breadcrumbs={[
                      'Home',
                      '<your storage account>',
                      values.bucketName,
                      'Access Control (IAM)',
                    ]}
                    link={'https://portal.azure.com/'}
                  />
                </>
              }
              sx={(theme) => ({
                pt: 2,
                borderBottom: `1px solid ${theme.palette.midnight.two}`,
              })}
            />
            <CardContent>
              <ApplyAppPermissionsSteps
                federationPoolId={getAzureCognitoPoolId(values.region)}
                federationSubjectId={getAzureCognitoSubjectId(values.region)}
                suggestedCredentialName={getAzureSuggestedCredentialName(
                  values.region,
                )}
              />
            </CardContent>
          </Box>
        </Card>
      </Box>
    </>
  );
}
