import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  StandardTextFieldProps,
  TextField,
  Typography,
} from '@mui/material';
import {
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { useTableEditContext } from './TableEditContext';

import { IcebergDataTypes } from '../../graphql/table';
import { getLogger } from '../../utils/logging';
import { SchemaEditType } from './SchemaEdit';
import {
  getColorForComplexType,
  GridTextField,
} from './SchemaEditGridComponents';
import { EditTableMode } from './TableEdit';
import {
  canMigrateType,
  cleanTypeValue,
  safeJSONParse,
  shouldBlockPropagation,
  simulateMouseClick,
  validateDecimal,
  validateFixed,
} from './TableEditHelpers';

const logger = getLogger(
  'components.TableEdit.IcebergTypeEdit' /*FUTURE import.meta.url ?*/,
);

export interface GridEditTextFieldProps extends StandardTextFieldProps {
  shouldAutoFocus?: boolean;
  isReadonly?: boolean;
}

export const IcebergTypeView = React.memo<GridRenderCellParams>(
  ({ id, value, field, tabIndex }: GridRenderCellParams) => {
    const { mode } = useTableEditContext();
    if (value === undefined || value === '') {
      return (
        <GridTextField
          label={'Select type'}
          sx={(theme) => ({
            pl: 1,
            pr: 1,
            width: 1,
            ...(mode !== EditTableMode.VIEW && mode !== EditTableMode.REGISTER
              ? {
                  border: `1px solid ${theme.palette.midnight.two}`,
                  borderRadius: '4px',
                }
              : {}),
          })}
          //placeholder={'Select type'}
          InputProps={{
            ...(mode !== EditTableMode.VIEW && mode !== EditTableMode.REGISTER
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                }
              : {}),
          }}
          required
          error
        ></GridTextField>
      );
    }
    let jsonValueObject: any = safeJSONParse(value, {});

    let isError = false;
    let helperText: string[] = [];
    if (jsonValueObject.type === 'decimal') {
      const results = validateDecimal(
        jsonValueObject.param1,
        jsonValueObject.param2,
      );
      isError = isError || results.isError;
      helperText = helperText.concat(results.helperText);
    }

    if (jsonValueObject.type === 'fixed') {
      const results = validateFixed(jsonValueObject.param1);
      isError = isError || results.isError;
      helperText = helperText.concat(results.helperText);
    }

    return (
      <FormControl fullWidth>
        <GridTextField
          className="icebergTypeLabel"
          sx={(theme) => ({
            p: 1.25,
            pl: 2,
            pr: 0,
            width: 1,
            ...(mode !== EditTableMode.VIEW && mode !== EditTableMode.REGISTER
              ? {
                  border: `1px solid ${theme.palette.midnight.two}`,
                  borderRadius: '4px',
                }
              : {}),
            backgroundColor: getColorForComplexType(
              jsonValueObject?.type,
              mode,
            ),
          })}
          InputProps={{
            ...(mode !== EditTableMode.VIEW && mode !== EditTableMode.REGISTER
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                }
              : {}),
          }}
          required
          error={isError}
          helperText={helperText.join('\n')}
          // tabIndex={tabIndex}
          value={
            jsonValueObject?.type
              ? jsonValueObject.type == 'decimal'
                ? `decimal(${jsonValueObject?.param1},${jsonValueObject?.param2})`
                : jsonValueObject.type == 'fixed'
                ? `fixed(${jsonValueObject?.param1})`
                : jsonValueObject.type
              : jsonValueObject
          }
        />
      </FormControl>
    );
  },
);

export interface IcebergTypeEditProps extends GridRenderEditCellParams {
  level: number;
  setColumnVisibilityModel: React.Dispatch<
    React.SetStateAction<GridColumnVisibilityModel>
  >;
}

const SCHEMA_TYPE_VAL_NONE = 'none';

export const IcebergTypeEdit = React.memo<IcebergTypeEditProps>(
  ({
    id,
    value,
    field,
    row,
    level,
    hasFocus,
    tabIndex,
    setColumnVisibilityModel,
    ...rest
  }: IcebergTypeEditProps) => {
    const apiRef = useGridApiContext();

    const previousValue = apiRef.current.getCellValue(id, field);
    const previousValueObj = useMemo(
      () =>
        previousValue === undefined ? {} : safeJSONParse(previousValue, {}),
      [previousValue],
    );

    const { mode, setSchemaModel, setSchemaValidityModel } =
      useTableEditContext();

    const isNewField: boolean =
      mode == EditTableMode.CREATE ||
      mode == EditTableMode.CREATE_LIKE ||
      mode == EditTableMode.CREATE_LOAD ||
      mode == EditTableMode.CREATE_CDC_PIPELINE ||
      !(row.id > 0);
    const inVal = useMemo(
      () => cleanTypeValue(value, previousValue),
      [value, previousValue],
    );
    const [currentVal, setCurrentVal] = useState<string>(inVal.value);
    const [currentValObj, setCurrentValObj] = useState<any>(inVal.valueObj);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [selectOpen, setSelectOpen] = React.useState(hasFocus);
    const [lastSelectCloseTs, setLastSelectCloseTs] = React.useState(0);
    const [param1ErrorText, setParam1ErrorText] = React.useState<string>('');

    const handlePopoverOpen = useCallback(
      (event: React.SyntheticEvent<HTMLElement>) => {
        if (selectOpen) {
          setAnchorEl(event.currentTarget);
        }
      },
      [selectOpen, setAnchorEl],
    );

    const handlePopoverClose = useCallback(() => {
      setAnchorEl(null);
    }, []);
    const open = Boolean(anchorEl);

    const setValue = useCallback(
      (newValue: string) => {
        let valueObj;
        try {
          valueObj =
            newValue === undefined
              ? undefined
              : safeJSONParse(newValue, undefined);
        } catch (e) {
          console.warn(`Could not parse iceberg type value ${newValue}`);
        }
        if (
          IcebergDataTypes.map((types) => types.primitiveType).includes(
            valueObj?.type ? valueObj?.type : valueObj,
          )
        ) {
          setCurrentVal(newValue);
          const valueObj =
            newValue === undefined
              ? undefined
              : safeJSONParse(newValue, undefined);
          setCurrentValObj(valueObj);

          apiRef.current.setEditCellValue({ id, field, value: newValue });
          return valueObj;
        } else {
          return currentValObj;
        }
      },
      [apiRef, setCurrentVal, setCurrentValObj, currentValObj],
    );

    const setParamValue = useCallback(
      (paramName: string, newValue: string) => {
        const newValueObjString = JSON.stringify({
          ...currentValObj,
          [paramName]: newValue,
        });
        setValue(newValueObjString);
      },
      [currentValObj, setValue],
    );
    const startEditingComplexType = useCallback(
      (curType: SchemaEditType) => {
        const initialRowForListOrMap =
          curType === 'list'
            ? [
                {
                  name: 'element',
                  rowId: `${uuidv4()}_element`,
                  isNew: true,
                  identity: false,
                  fields: [],
                },
              ]
            : curType === 'map'
            ? [
                {
                  name: 'key',
                  rowId: `${uuidv4()}_key`,
                  isNew: true,
                  identity: false,
                  required: true,
                  fields: [],
                },
                {
                  name: 'value',
                  rowId: `${uuidv4()}_value`,
                  isNew: true,
                  identity: false,
                  fields: [],
                },
              ]
            : [
                {
                  rowId: uuidv4(),
                  isNew: true,
                  identity: false,
                  name: '',
                  fields: [],
                },
              ];

        setSchemaModel((prevState) => {
          return {
            ...prevState,
            [id]: [...initialRowForListOrMap],
          };
        });

        //set the child's version of validity to -1
        setSchemaValidityModel((prevState) => {
          return {
            ...prevState,
            [id as GridRowId]: -1,
          };
        });

        const currentExpandedPanels = apiRef.current.getExpandedDetailPanels();
        apiRef.current.setExpandedDetailPanels([...currentExpandedPanels, id]);
      },
      [setSchemaModel, apiRef, id, setSchemaValidityModel],
    );

    const stopAndEraseEditingComplexType = useCallback(() => {
      setSchemaModel((prevState) => {
        const { [id]: nuke, ...rest } = prevState;
        return {
          ...rest,
        };
      });

      //remove the child's validity model
      setSchemaValidityModel((prevState) => {
        let { [id as GridRowId]: currentValidityModel, ...restValidityModels } =
          prevState;
        return {
          ...restValidityModels,
        };
      });
      //hide the panel
      const currentExpandedPanels = apiRef.current.getExpandedDetailPanels();
      apiRef.current.setExpandedDetailPanels([
        ...currentExpandedPanels.filter((expandedId) => expandedId != id),
      ]);
    }, [setSchemaModel, id, setSchemaValidityModel, rest, apiRef]);

    const handleValueChange = useCallback(
      (event: SelectChangeEvent<string>, child?: ReactNode) => {
        const valueObj = setValue(event.target.value);

        const primitiveType =
          valueObj === undefined
            ? undefined
            : valueObj.type
            ? valueObj.type
            : valueObj;

        if (
          primitiveType === 'map' ||
          primitiveType === 'list' ||
          primitiveType === 'struct'
        ) {
          startEditingComplexType(primitiveType);
        } else {
          stopAndEraseEditingComplexType();
        }
        logger.debug('select changed, closing');
        setSelectOpen(false);
      },
      [
        setValue,
        startEditingComplexType,
        stopAndEraseEditingComplexType,
        setSelectOpen,
      ],
    );

    const menuItems = useMemo(
      () =>
        IcebergDataTypes.filter((icebergType) =>
          isNewField
            ? true
            : canMigrateType(
                mode,
                currentValObj.type ? currentValObj.type : currentValObj,
                icebergType.primitiveType,
              ),
        ).map(({ primitiveType, description }, index) => [
          ...(primitiveType === 'map'
            ? [<Divider key={id + 'divider'} />]
            : []),
          [
            // @ts-ignore
            <MenuItem
              id={'type_' + id + '_' + primitiveType}
              key={'schemaEditMenu_' + id + '_' + primitiveType}
              value={`${JSON.stringify(
                primitiveType === 'struct' ||
                  primitiveType === 'list' ||
                  primitiveType === 'map'
                  ? { type: primitiveType }
                  : primitiveType === 'decimal' || primitiveType === 'fixed'
                  ? { type: primitiveType }
                  : primitiveType,
              )}`}
              aria-owns={
                open
                  ? 'type_' + id + '_' + primitiveType + '_popover'
                  : undefined
              }
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onFocus={(event) => {
                handlePopoverOpen(event);
              }}
              onBlur={(event) => {
                handlePopoverClose();
              }}
              onKeyDown={(event) => {
                logger.debug(
                  `menuitem ${event.currentTarget.id} got keydown ${event.key}`,
                );
                //simulate a click if they hit space bar on the menu item.
                if (event.key === ' ') {
                  simulateMouseClick(event.currentTarget);
                }
              }}
            >
              <Box key={'schemaEditMenu_' + id + '_box'}>
                <ListItemText key={'schemaEditMenu_' + id + '_lit'}>
                  {primitiveType}
                </ListItemText>
              </Box>
            </MenuItem>,
          ],
        ]),
      [
        isNewField,
        canMigrateType,
        mode,
        currentValObj,
        open,
        handlePopoverOpen,
        handlePopoverClose,
        simulateMouseClick,
        id,
      ],
    );

    const popovers = useMemo(
      () =>
        IcebergDataTypes.map(({ primitiveType, description }, index) => (
          <Popover
            tabIndex={-1}
            key={'type_' + id + '_' + primitiveType + '_popover'}
            id={'type_' + id + '_' + primitiveType + '_popover'}
            sx={{
              pointerEvents: 'none',
            }}
            open={open && anchorEl?.id == 'type_' + id + '_' + primitiveType}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            disableAutoFocus //🙏 this stops the loss of focus and headaches
            disableEnforceFocus //🙏 this stops the loss of focus and headaches
          >
            <Box sx={{ maxWidth: 500 }} key={'schemaEditMenu_' + id + '_box2'}>
              <Typography key={'schemaEditMenu_' + id + '_desc'} sx={{ p: 1 }}>
                {description}
              </Typography>
            </Box>
          </Popover>
        )),
      [id, open, handlePopoverClose, anchorEl],
    );

    // @ts-ignore
    return (
      <Box tabIndex={-1} sx={{ display: 'flex', width: 1 }}>
        {/*There is a weird quirk / bug in the mui select here.  If I get rid of the flexGrow:1 or width:1, I can tab
      once into the select... otherwise it is twice!  maybe is the react strict mode issue local?*/}
        <FormControl
          className="icebergTypeLabel"
          variant="standard"
          sx={{
            flexGrow: 1,
            '& .MuiInputBase-formControl': {
              borderRadius: '5px',
              border: 1,
              borderColor: 'midnight.two',
              backgroundColor: getColorForComplexType(
                currentValObj?.type,
                mode,
              ),
            },
          }}
          onKeyDown={(event) => {
            logger.debug(`in form control ${event.key} !`);
            //if we are a complex control with a param, we need to block tab so the datagrid doesnt skip it if this colum is on the end
            if (shouldBlockPropagation(event)) {
              event.stopPropagation();
            }
          }}
        >
          <Select
            sx={{
              ...(!currentVal ? { color: 'midnight.four' } : {}),
            }}
            autoFocus={hasFocus}
            // tabIndex={tabIndex}
            disableUnderline
            onBlur={() => {
              logger.debug('on blur');
              setSelectOpen(false);
            }}
            onFocus={(e) => {
              if (e.currentTarget === e.target) {
                logger.debug('focused self');
              } else {
                logger.debug('focused child', e.target);
              }
              if (!e.currentTarget.contains(e.relatedTarget)) {
                // Not triggered when swapping focus between children
                logger.debug('focus entered self');
                if (e.timeStamp - lastSelectCloseTs > 1000) {
                  if (e.relatedTarget) {
                    setSelectOpen(true);
                  }
                }
              }
            }}
            inputProps={{
              sx: { pt: 1 / 2, pb: 1 / 2, pl: 2 },
            }}
            onChange={handleValueChange}
            onPointerDown={() => {
              logger.debug(`pointer down`);
              apiRef.current.setCellFocus(id, field);
              setSelectOpen(!selectOpen);
            }}
            open={selectOpen}
            onOpen={() => {
              logger.debug('On open!');
            }}
            onClose={(event) => {
              logger.debug('On close!');
              setSelectOpen(false);
              setAnchorEl(null);
              setLastSelectCloseTs(event.timeStamp);
            }}
            value={
              currentVal
                ? currentValObj?.type
                  ? JSON.stringify({ type: currentValObj?.type })
                  : currentVal
                : SCHEMA_TYPE_VAL_NONE
            }
            placeholder={isNewField ? 'Select type' : 'Allowed types'}
          >
            <MenuItem
              disabled
              value={SCHEMA_TYPE_VAL_NONE}
              key={'schemaEditMenu_0' + id}
            >
              {isNewField ? 'Select type' : 'Allowed types'}
            </MenuItem>
            {menuItems}
          </Select>
        </FormControl>
        {(currentValObj?.type === 'decimal' ||
          currentValObj?.type === 'fixed') && (
          <FormControl
            sx={{
              width: 50,
              alignSelf: 'center',
              '& .MuiInputBase-root': { minWidth: 50 },
              '& input[type=number]': {
                '-moz-appearance': 'textfield',
              },
              '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              ml: 1,
            }}
            onKeyDown={(event) => {
              logger.debug(`in form control ${event.key} !`);
              if (shouldBlockPropagation(event)) {
                event.stopPropagation();
              }
            }}
          >
            <TextField
              defaultValue={currentValObj?.param1 ? currentValObj.param1 : ''}
              variant="standard"
              fullWidth
              disabled={!isNewField && currentValObj?.type !== 'decimal'}
              id="param1"
              placeholder={currentValObj?.type === 'decimal' ? 'P' : 'L'}
              title={currentValObj?.type === 'decimal' ? 'Precision' : 'Length'}
              type="number"
              required
              helperText={param1ErrorText}
              error={
                currentValObj?.param1 === undefined ||
                currentValObj?.param1 === '' ||
                param1ErrorText != ''
              }
              onChange={(event) => {
                if (
                  !isNewField &&
                  Number(event.target.value) < Number(previousValueObj?.param1)
                ) {
                  setParam1ErrorText('🚫lower');
                  return;
                } else {
                  setParam1ErrorText('');
                  setParamValue('param1', event.target.value);
                }
              }}
            ></TextField>
          </FormControl>
        )}
        {currentValObj?.type === 'decimal' && (
          <FormControl
            sx={{
              height: 1,
              width: 50,
              alignSelf: 'center',
              '& .MuiInputBase-root': { minWidth: 50 },
              '& input[type=number]': {
                '-moz-appearance': 'textfield',
              },
              '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              ml: 1,
            }}
            onKeyDown={(event) => {
              logger.debug(`in form control onkeydown '${event.key}' !`);
              if (shouldBlockPropagation(event)) {
                event.stopPropagation();
              }
            }}
          >
            <TextField
              defaultValue={currentValObj?.param2 ? currentValObj.param2 : ''}
              variant="standard"
              fullWidth
              disabled={!isNewField}
              id="param2"
              sx={{ alignSelf: 'center' }}
              placeholder={'S'}
              title={'Scale'}
              type="number"
              required
              error={
                currentValObj?.param2 === undefined ||
                currentValObj?.param2 === ''
              }
              onChange={(event) => {
                setParamValue('param2', event.target.value);
              }}
            ></TextField>
          </FormControl>
        )}
        {popovers}
      </Box>
    );
  },
);
