import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { toProfile } from '../../../RouteTable';
import { getApolloErrorMessage } from '../../../utils/ApolloUtils';
import { Talert } from '../../Alert/Talert';
import { Tabutton } from '../../Button/Tabutton';
import { Tabulink } from '../../Link/Tabulink';

export const CreateCredentialStep = ({
  organizationName,
  connectionName,
  credential,
  errorStatus,
}: {
  organizationName: string;
  connectionName: string;
  credential?: string;
  errorStatus: any;
}) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  return (
    <Stack sx={{ width: 0.95 }}>
      {errorStatus && (
        <Talert severity="error">{getApolloErrorMessage(errorStatus)}</Talert>
      )}
      {credential && (
        <>
          <Box sx={{ mt: 1 }}>
            <Typography variant={'body1'} sx={{ display: 'flex' }}>
              {`We have created a new personal credential for you to use with ${connectionName}`}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant={'body1'} display={'inline-flex'}>
              Your credentials can be managed in{' '}
              <Tabulink
                external
                href={toProfile(organizationName)}
                variant="body1"
                rel="noopener"
                aria-label="Tabular profile settings"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: '3px',
                }}
              >
                your profile settings.
                <OpenInNewIcon fontSize="small" sx={{ ml: '2px', mr: '3px' }} />
              </Tabulink>
            </Typography>
          </Box>
          <Talert
            severity={'info'}
            sx={{ maxWidth: 'fit-content', mt: 4 }}
            alertTitle={'Please note'}
          >
            <Typography variant={'body1'}>
              This credential will be included in the next step automatically.
            </Typography>
          </Talert>
          <Grid
            container
            sx={{
              width: 1,
              mt: 4,
              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
              }}
            >
              <Typography
                variant={'body1'}
                sx={{}}
              >{`Credentials have the format <key>:<secret>`}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pre: {
                    m: 0,
                    height: 1,
                  },
                }}
              >
                <SyntaxHighlighter
                  className="ph-no-capture"
                  language="bash"
                  style={{ margin: 0 as React.CSSProperties }}
                  showLineNumbers={false}
                >
                  {credential}
                </SyntaxHighlighter>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <Tabutton
                  onClick={() => copyToClipBoard(credential)}
                  title="Copy to clipboard"
                  size={'small'}
                  endIcon={<FileCopyIcon />}
                >
                  Copy Credential
                </Tabutton>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
};
