import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ApolloError, useMutation } from '@apollo/client';
import { Box, DialogActions } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useAuth } from '../../context/auth-context';
import { Table } from '../../graphql/gen/graphql';
import { createBranch, createTag } from '../../graphql/table';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import {
  convertDaysToMilliseconds,
  convertMillisecondsToDays,
} from '../TableSettings/helpers';
import TextField from './TextField';

export default function CreateBranchOrTag({
  snapshotId,
  onCancel,
  onBranchCreated,
  type,
  table,
}: {
  snapshotId: number | null;
  onCancel: () => void;
  onBranchCreated: () => void;
  type: 'Branch' | 'Tag';
  table: Table;
}) {
  const { user } = useAuth();
  const {
    organization: organizationName,
    warehouse: warehouseName,
    database,
    table: tableName,
  } = useParams();

  const [errorMessage, setErrorMessage] = useState('');
  const [mutationError, setMutationError] = useState(false);

  const [createBranchMutation, { reset: resetBranch }] =
    useMutation(createBranch);
  const [createTagMutation, { reset: resetTag }] = useMutation(createTag);

  const onSubmit = useCallback(
    async (values: any, { setSubmitting }: any) => {
      const options = {
        variables: {
          warehouseId: user.getWarehouse(organizationName, warehouseName).id,
          database,
          table: tableName,
          name: values.name,
          snapshotId,
          maxRefAgeMs:
            values?.maxRefAgeMs != null && values?.maxRefAgeMs != ''
              ? convertDaysToMilliseconds(values.maxRefAgeMs)
              : null,
        },
        onError: (e: ApolloError) => {
          if (
            e.graphQLErrors[0].extensions?.response?.body?.error?.message.includes(
              `Ref ${values.name} already exists`,
            )
          ) {
            setErrorMessage(`Ref ${values.name} already exists.`);
          } else {
            setErrorMessage(
              'An unexpected error occurred. Please refresh the page and try again. ',
            );
          }

          setMutationError(true);
        },
      };
      const result = await (type === 'Branch'
        ? createBranchMutation(options)
        : createTagMutation(options));

      await setSubmitting(false);
      if (result?.data) {
        enqueueSnackbar(`New ${type} Created: ${values.name}`, {
          variant: 'success',
        });
        onBranchCreated();
      }
    },
    [type],
  );

  const initialValues = useMemo(() => {
    const maxRefAgeMs = table?.properties?.find(
      (p) => p?.key === 'history.expire.max-ref-age-ms',
    )?.value;
    return {
      name: '',
      snapshotId,
      maxRefAgeMs: maxRefAgeMs ? convertMillisecondsToDays(maxRefAgeMs) : null,
    };
  }, [snapshotId, table]);

  const validationSchema = Yup.object({
    name: Yup.string().required(`${type} name is required.`),
    snapshotId: Yup.string().required('Snapshot ID is required.'),
    maxRefAgeMs: Yup.number()
      .min(0, 'Value must be at least 0.')
      .typeError('Value must be a number.')
      .nullable(),
  });

  return (
    <Box>
      <DialogTitle>Create {type}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              {mutationError && (
                <Talert
                  severity="error"
                  onClose={() => {
                    type === 'Branch' ? resetBranch() : resetTag();
                    setMutationError(false);
                    setErrorMessage('');
                  }}
                >
                  {errorMessage}
                </Talert>
              )}

              <TextField
                id="name"
                name="name"
                type="text"
                label={`${type} name`}
                variant="outlined"
                margin="normal"
                autoComplete="off"
                fullWidth
              />

              {type === 'Tag' && (
                <TextField
                  id="maxRefAgeMs"
                  name="maxRefAgeMs"
                  type="text"
                  label="Number of days to retain"
                  variant="outlined"
                  margin="normal"
                  autoComplete="off"
                  fullWidth
                />
              )}

              <TextField
                disabled={true}
                id="snapshotId"
                name="snapshotId"
                type="text"
                label="Snapshot ID"
                variant="outlined"
                margin="normal"
                autoComplete="off"
                fullWidth
              />

              <DialogActions sx={{ mt: 2 }}>
                <Tabutton
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Cancel
                </Tabutton>
                <Tabutton
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Create {type}
                </Tabutton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Box>
  );
}
