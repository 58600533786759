import React from 'react';

import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { lazy } from 'yup';

import {
  configureDelimiter,
  convertBooleanToString,
  convertBytesToGB,
  convertBytesToMB,
  convertCsvToTsv,
  convertDaysToMilliseconds,
  convertGBtoBytes,
  convertMillisecondsToDays,
  convertStringToBoolean,
  convertToBytes,
  convertTsvToCsv,
  formatFileLoaderPath,
} from '../components/TableSettings/helpers';

const compressionOptions = [
  {
    value: 'zstd',
    label: 'zstd',
  },
  {
    value: 'gzip',
    label: 'gzip',
  },
  {
    value: 'snappy',
    label: 'snappy',
  },
];

const strategyOptions = [
  {
    value: 'binpack',
    label: 'binpack',
  },
  {
    value: 'sort',
    label: 'sort',
  },
];

const jobOrderOptions = [
  {
    value: 'NONE',
    label: 'None',
  },
  {
    value: 'bytes-asc',
    label: 'bytes-asc',
  },
  {
    value: 'bytes-desc',
    label: 'bytes-desc',
  },
  {
    value: 'files-asc',
    label: 'files-asc',
  },
  {
    value: 'files-desc',
    label: 'files-desc',
  },
];

export const unitOptions = [
  {
    value: 'B',
    label: 'Bytes',
  },
  {
    value: 'KB',
    label: 'KB',
  },
  {
    value: 'MB',
    label: 'MB',
  },
  {
    value: 'GB',
    label: 'GB',
  },
  {
    value: 'TB',
    label: 'TB',
  },
];

export const fileFormatOptions = [
  {
    value: 'json',
    label: 'json',
  },
  {
    value: 'csv',
    label: 'csv',
  },
  {
    value: 'parquet',
    label: 'parquet',
  },
  {
    value: 'tsv',
    label: 'tsv',
  },
];

const writeModeOptions = [
  {
    value: 'append',
    label: 'append',
  },
  {
    value: 'replace',
    label: 'replace',
  },
];

export const DEFAULT_TTL_IN_MS = '31536000000';

export const TablePropertyGroups = [
  {
    name: 'Snapshot management',
    description:
      'Table snapshots allow time-travel to previous versions of the table. ' +
      'These settings control snapshot retention in order to facilitate time-travel queries and rollbacks. ' +
      'Keeping large numbers of snapshots may impact table performance.',
    properties: [
      {
        key: 'history.expire.max-snapshot-age-ms',
        label: 'Maximum snapshot age',
        description:
          'The amount of time (in days) that snapshots will be retained.',
        default: '432000000',
        displayFormatter: convertMillisecondsToDays,
        valueFormatter: convertDaysToMilliseconds,
        name: 'snapshotAge',
        fieldLabel: 'Number of days',
        component: 'TextField',
        direction: 'column',
      },
      {
        key: 'history.expire.min-snapshots-to-keep',
        label: 'Minimum snapshots',
        description:
          'The minimum number of snapshots to retain.  This takes precedence over "Max Snapshot Age" such that ' +
          'there will always be at least this many snapshots in the table history regardless of their age.',
        default: '1',
        name: 'minSnapshots',
        fieldLabel: 'Number of snapshots',
        component: 'TextField',
        direction: 'column',
      },
      {
        key: 'history.expire.max-ref-age-ms',
        label: 'Maximum ref age',
        description:
          'The amount of time (in days) that refs(tags) will be retained. If left blank, the default value will be: Long.MAX_VALUE (forever).',
        default: '',
        displayFormatter: convertMillisecondsToDays,
        valueFormatter: convertDaysToMilliseconds,
        name: 'refAge',
        fieldLabel: 'Number of days',
        component: 'TextField',
        direction: 'column',
      },
    ],
    validator: Yup.object({
      snapshotAge: Yup.number()
        .required('This field is required.')
        .min(0, 'Value must be at least 0.')
        .typeError('Value must be a number.'),
      minSnapshots: Yup.number()
        .required('This field is required.')
        .min(1, 'Value must be at least 1.')
        .typeError('Value must be a number.'),
      refAge: Yup.number()
        .min(0, 'Value must be at least 0.')
        .typeError('Value must be a number.')
        .nullable(),
    }),
  },
  {
    name: 'Automatic compaction',
    description:
      'Automatically combine small files and apply the currently configured compression codecs and table write order. Compaction will attempt to produce files according to the configured target file size (default is 512MB).',
    properties: [
      {
        key: 'compaction.enabled',
        label: '',
        description: null,
        default: 'true',
        displayFormatter: convertStringToBoolean,
        valueFormatter: convertBooleanToString,
        name: 'compactionEnabled',
        component: 'Switch',
        direction: 'row',
      },
      {
        key: 'compaction.strategy',
        label: 'Compaction strategy',
        description: '',
        default: 'binpack',
        name: 'strategy',
        fieldLabel: 'Strategy',
        component: 'TextField',
        direction: 'column',
        options: strategyOptions,
      },
      {
        key: 'overrides',
        label: 'User overrides',
        description:
          'Compaction properties that have been overriden will be listed here.',
        name: 'overrides',
        default: [],
        component: 'CompactionOverrides',
        direction: 'column',
      },
    ],
    validator: Yup.object({
      compactionEnabled: Yup.boolean().required('This field is required.'),
      strategy: Yup.string()
        .required('This field is required.')
        .oneOf(strategyOptions.map((_) => _.value)),
      overrides: Yup.array().of(
        //@ts-ignore
        lazy((item) => {
          switch (Object.keys(item)[0]) {
            case 'partialProgress':
              return Yup.object({
                partialProgress: Yup.number()
                  .required('This field is required.')
                  .min(1, 'Value must be at least 1.')
                  .typeError('Value must be a number.'),
              });
            case 'commitGroup':
              return Yup.object({
                commitGroup: Yup.number()
                  .required('This field is required.')
                  .min(1, 'Value must be at least 1.')
                  .typeError('Value must be a number.'),
              });
            case 'concurrentGroups':
              return Yup.object({
                concurrentGroups: Yup.number()
                  .required('This field is required.')
                  .min(1, 'Value must be at least 1.')
                  .typeError('Value must be a number.'),
              });
            case 'fileSize':
              return Yup.object({
                fileSize: Yup.number()
                  .required('This field is required.')
                  .min(1, 'Value must be at least 1.')
                  .typeError('Value must be a number.'),
              });
            case 'byteUnit':
              return Yup.object({
                byteUnit: Yup.string()
                  .required('This field is required')
                  .oneOf(unitOptions.map((_) => _.value)),
              });
            case 'jobOrder':
              return Yup.object({
                jobOrder: Yup.string()
                  .required('This field is required.')
                  .oneOf(
                    jobOrderOptions
                      .filter((option) => option.value !== 'NONE')
                      .map((_) => _.value),
                    'Please choose a valid order.',
                  ),
              });
          }
        }),
      ),
    }),
  },
  {
    name: 'File loader',
    description: `File Loader automatically loads new data files as they arrive in cloud storage directly into Tabular tables without any additional setup or infrastructure.
      
      File loader supports csv, tsv, json, and parquet files. 
      Data in these files is expected to match the table schema.
      
      Tabular will make a best effort to coerce the incoming data values to match the expected types for each field. 
      Tabular will also ignore extra fields and insert null values for missing fields.`,
    hide: 'hostedBucket',
    properties: [
      {
        key: 'fileloader.enabled',
        label: '',
        description:
          'When enabled, Tabular will automatically load all files that are written to the configured File Loader Path into this table.',
        default: 'false',
        displayFormatter: convertStringToBoolean,
        valueFormatter: convertBooleanToString,
        name: 'fileloaderEnabled',
        component: 'Switch',
        direction: 'row',
      },
      {
        key: 'fileloader.path',
        label: 'File loader path',
        description:
          'The root path that files will be loaded from.  The path must be in an existing storage profile.',
        dynamicDefault: formatFileLoaderPath,
        default: '',
        name: 'fileloaderPath',
        fieldLabel: 'Bucket path',
        component: 'PathWithStorageBrowser',
        direction: 'column',
      },
      {
        key: 'fileloader.file-format',
        label: 'File format',
        description:
          'The file format of the incoming files to be loaded.  Supported formats are csv, tsv, json, and parquet. ' +
          'The schema of the incoming files should match the table schema.',
        default: '',
        additionalPropertiesGetter: configureDelimiter,
        valueFormatter: convertTsvToCsv,
        displayFormatter: convertCsvToTsv,
        helperProperty: 'fileloader.csv.column-delimiter',
        name: 'fileFormat',
        fieldLabel: 'Format*',
        component: 'FileLoaderFileFormat',
        direction: 'column',
        options: fileFormatOptions,
      },
      {
        key: 'fileloader.write-mode',
        label: 'Write mode',
        description:
          'Determines if the incoming data should be appended to the table or should fully replace the existing data.',
        default: '',
        name: 'writeMode',
        fieldLabel: 'Write mode*',
        component: 'TextField',
        direction: 'column',
        options: writeModeOptions,
      },
    ],
    validator: Yup.object({
      fileloaderEnabled: Yup.boolean().required('This field is required.'),
      fileloaderPath: Yup.string().when('fileloaderEnabled', {
        is: true,
        then: Yup.string().required('This field is required.'),
        otherwise: Yup.string(),
      }),
      fileFormat: Yup.string().when('fileloaderEnabled', {
        is: true,
        then: Yup.string()
          .required('This field is required.')
          .oneOf(fileFormatOptions.map((_) => _.value)),
        otherwise: Yup.string(),
      }),
      writeMode: Yup.string().when('fileloaderEnabled', {
        is: true,
        then: Yup.string()
          .required('This field is required.')
          .oneOf(writeModeOptions.map((_) => _.value)),
        otherwise: Yup.string(),
      }),
    }),
  },
  {
    name: 'Storage optimization',
    description:
      'Tabular periodically conducts table storage analysis to determine optimal configuration related to parquet file settings and table write order.  When storage optimization is enabled, these recommended configurations will be applied automatically. ',
    properties: [
      {
        key: 'optimizer.enabled',
        label: '',
        description: null,
        default: 'true',
        displayFormatter: convertStringToBoolean,
        valueFormatter: convertBooleanToString,
        name: 'optimizerEnabled',
        component: 'Switch',
        direction: 'row',
      },
      {
        key: 'write.parquet.compression-codec',
        label: 'Compression codec',
        description:
          'Target compression codec to be used when writing files for this table.',
        default: 'zstd',
        name: 'codec',
        fieldLabel: 'Codec',
        component: 'TextField',
        direction: 'column',
        options: compressionOptions,
      },
      {
        key: 'write.object-storage.enabled',
        label: 'Object storage layout',
        description:
          'When enabled, the file paths for this table will be prepended with a hash component optimized for object storage.  This helps spread out api load on the object store to avoid 429: Too Many Requests errors during read and write operations.',
        default: 'true',
        name: 'objectStorage',
        displayFormatter: convertStringToBoolean,
        valueFormatter: convertBooleanToString,
        component: 'Switch',
        direction: 'column',
      },
    ],
    validator: Yup.object({
      optimizerEnabled: Yup.boolean().required('This field is required.'),
      codec: Yup.string().when('optimizerEnabled', {
        is: false,
        then: Yup.string()
          .required('This field is required.')
          .oneOf(compressionOptions.map((_) => _.value)),
        otherwise: Yup.string(),
      }),
      objectStorage: Yup.boolean().when('optimizerEnabled', {
        is: false,
        then: Yup.boolean().required('This field is required.'),
        otherwise: Yup.boolean(),
      }),
    }),
  },
  {
    name: 'Data lifecycle policies',
    description:
      'Automatically remove records from the table that are older than a configured threshold. ' +
      'Record age is determined by the value in the specified timestamp column.',
    properties: [
      {
        key: 'lifecycle.enabled',
        label: '',
        description: null,
        default: 'false',
        displayFormatter: convertStringToBoolean,
        valueFormatter: convertBooleanToString,
        name: 'lifecycleEnabled',
        component: 'Switch',
        direction: 'row',
      },
      {
        key: 'lifecycleMgmt',
        label: '',
        description: '',
        name: 'lifecycleMgmt',
        default: {},
        component: 'LifecycleManagement',
        direction: 'column',
      },
    ],
    validator: Yup.object({
      lifecycleEnabled: Yup.boolean().required('This field is required.'),
      lifecycleMgmt: Yup.object().when('lifecycleEnabled', {
        is: true,
        then: Yup.object().shape(
          {
            timestampColumn: Yup.string().test(
              'empty-check',
              'Timestamp column is required.',
              (value) => value !== 'none',
            ),
            timeUnit: Yup.string().when('timestampType', {
              is: 'long',
              then: Yup.string().required('Time unit is required.'),
              otherwise: Yup.string().nullable(),
            }),
            rowRecordAge: Yup.string().when('columnMasking', {
              is: (value: any) => isEmpty(value),
              then: Yup.string().required(
                'Either one Row level TTL or one Column masking is required.',
              ),
              otherwise: Yup.string(),
            }),
            columnMasking: Yup.array().when('rowRecordAge', {
              is: (value: any) => isEmpty(value),
              then: Yup.array().of(
                Yup.object({
                  identifier: Yup.string().required(
                    'Column identifier is required.',
                  ),
                  ttl: Yup.number()
                    .required('Column TTL is required.')
                    .min(1, 'Value must be at least 1.')
                    .typeError('Value must be a number.'),
                }),
              ),
              otherwise: Yup.array().of(
                //@ts-ignore
                lazy(() => {
                  return Yup.object({
                    identifier: Yup.string().required(
                      'Column identifier is required.',
                    ),
                    ttl: Yup.number()
                      .required('Column TTL is required.')
                      .min(1, 'Value must be at least 1.')
                      .typeError('Value must be a number.'),
                  });
                }),
              ),
            }),
          },
          //@ts-ignore
          [['rowRecordAge', 'columnMasking', 'timestampType']],
        ),
      }),
      otherwise: Yup.object(),
    }),
  },
];
type JobOrderOptionsType = {
  value: string;
  label: string;
};
export type AdditionalPropertiesType = {
  key: string;
  value: string;
};

type PropertyFormType = {
  title: string;
  component: string;
  name: string;
  key: string;
  default: string;
  helperText: string;
  fieldLabel: string;
  inputAdornment?: string;
  displayFormatter?: (bytes: any) => void;
  valueFormatter?: (size: any, type?: any) => void;
  helperComponentName?: string;
  helperComponentDefault?: string;
  options?: JobOrderOptionsType[];
  additionalProperties?: AdditionalPropertiesType[];
};

export type CompactionOverridePropertyType = {
  key: string;
  label: string;
  default: string;
  form: PropertyFormType;
};

export const CompactionOverrideProperties: CompactionOverridePropertyType[] = [
  {
    key: 'compaction.options.partial-progress.enabled',
    label:
      'Enable committing groups of files prior to the entire rewrite completing',
    default: 'false',
    form: {
      title: 'Partial progress',
      component: 'TextField',
      name: 'partialProgress',
      key: 'compaction.options.partial-progress.max-commits',
      default: '10',
      fieldLabel: 'Max number of commits',
      helperText: 'Default is 10',
      additionalProperties: [
        { key: 'compaction.options.partial-progress.enabled', value: 'true' },
      ],
    },
  },
  {
    key: 'compaction.options.max-file-group-size-bytes',
    label: 'Change the max size of a commit group',
    default: '107374182400',
    form: {
      title: 'Commit group',
      component: 'TextField',
      name: 'commitGroup',
      key: 'compaction.options.max-file-group-size-bytes',
      default: '107374182400',
      fieldLabel: 'Max size',
      helperText: 'Default is 100 GB',
      inputAdornment: 'GB',
      displayFormatter: convertBytesToGB,
      valueFormatter: convertGBtoBytes,
    },
  },
  {
    key: 'compaction.options.max-concurrent-file-group-rewrites',
    label: 'Change the number of concurrent groups to rewrite in parallel',
    default: '1',
    form: {
      title: 'Concurrent groups to rewrite in parallel',
      component: 'TextField',
      name: 'concurrentGroups',
      key: 'compaction.options.max-concurrent-file-group-rewrites',
      default: '1',
      helperText: 'Default is 1',
      fieldLabel: 'Number of groups',
    },
  },
  {
    key: 'compaction.options.rewrite-job-order',
    label: 'Set the rewrite job order',
    default: 'NONE',
    form: {
      title: 'Rewrite job order',
      component: 'TextField',
      name: 'jobOrder',
      key: 'compaction.options.rewrite-job-order',
      default: 'NONE',
      helperText: 'Default is None',
      fieldLabel: 'Order',
      options: jobOrderOptions,
    },
  },
];

export const lifecycleManagementProperties = [
  {
    key: 'lifecycle.data-age-column',
    label: 'Record origin timestamp*',
    description:
      'This column indicates when a given record originated. Once the duration between the current timestamp and the value in this column is greater than the "Maximum record age", the policy will be enforced.',
    name: 'timestampColumn',
    fieldLabel: 'Column to use as timestamp origin',
    default: 'none',
    component: 'TextField',
    options: true,
  },
  {
    key: 'lifecycle.table.max-data-age-ms',
    label: 'Row-level policy',
    description: 'Age at which the row-level policy will be enforced.',
    default: '',
    displayFormatter: convertMillisecondsToDays,
    valueFormatter: convertDaysToMilliseconds,
    name: 'rowRecordAge',
    component: 'LifecycleRowTTL',
    direction: 'column',
  },
  {
    key: 'lifecycle.column.placeholder.max-data-age-ms',
    label: 'Column-level policy',
    description:
      'Column-level policies can be enforced on multiple records, each with unique maximum age and transform.',
    default: [],
    name: 'columnMasking',
    displayFormatter: convertMillisecondsToDays,
    valueFormatter: convertDaysToMilliseconds,
    component: 'LifecycleColumnMasking',
    direction: 'column',
    additionalProperty: {
      key: 'lifecycle.column.placeholder.transform',
      value: 'nullify',
    },
  },
];
