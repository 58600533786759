import { styled } from '@mui/material/styles';

export const SearchButton = styled('button')(({ theme }) => [
  {
    minHeight: 28,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    paddingLeft: theme.spacing(1),
    minWidth: 140,
    [theme.breakpoints.down(850)]: {
      minWidth: 200,
    },
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    backgroundColor: theme.palette.sky.eight,
    color: theme.palette.sky.three,
    fontSize: theme.typography.pxToRem(14),
    fontStyle: 'italic',
    border: 'none',
    borderRadius: 24,
    cursor: 'pointer',
    transitionProperty: 'all',
    transitionDuration: '150ms',
    '&:hover': {
      background: theme.palette.sky.nine,
      color: '#FFF',
    },
  },
]);
