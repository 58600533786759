import React, { useCallback } from 'react';

import { Box, Chip, Divider } from '@mui/material';
import { GridRowModesModel } from '@mui/x-data-grid';
import {
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';

import { useTableEditContext } from './TableEditContext';

import { getLogger } from '../../utils/logging';
import {
  GRID_COL_TYPE,
  GRID_COL_TYPE_FIELDS,
  SchemaEditRowModel,
  SchemaFieldRow,
} from './SchemaEdit';
import SchemaEditContainer from './SchemaEditContainer';
import { getColorForComplexType } from './SchemaEditGridComponents';
import { safeJSONParse } from './TableEditHelpers';

const logger = getLogger(
  'components.TableEdit.SchemaEditDetailPane' /*FUTURE import.meta.url ?*/,
);

interface EditDetailPaneProps {
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  updateRow: (row: GridRowModel<SchemaFieldRow>) => void;
  gridRowParams: GridRowParams;
  level: number;
}

export function SchemaEditDetailPane({
  gridRowParams,
  level,
  setRowModesModel,
  updateRow,
}: EditDetailPaneProps) {
  const apiRef = useGridApiContext();
  const {
    mode,
    schemaModel,
    setSchemaModel,
    schemaValidityModel,
    setSchemaValidityModel,
  } = useTableEditContext();
  const backgroundLightness = 100 - 5 * (level + 1);

  const getCurrentOrEditState = useCallback(
    (row: any): any => {
      const rowId = row.rowId;
      const state = apiRef.current.state;
      if (apiRef.current.getRowMode(rowId) === GridRowModes.Edit) {
        const editRow = state.editRows[rowId];
        return editRow
          ? Object.assign(
              {},
              ...[
                ...Object.keys(editRow).map((eachKey) => {
                  return { [eachKey]: editRow[eachKey].value };
                }),
                { rowId: rowId },
              ],
            )
          : {};
      } else {
        return row;
      }
    },
    [apiRef],
  );

  const getSubRowModel = useCallback((): SchemaEditRowModel => {
    const fields = schemaModel[gridRowParams.row.rowId] || [];
    const row = getCurrentOrEditState(gridRowParams.row);

    let typeObj;

    typeObj = row?.type ? safeJSONParse(row?.type, {}) : {};

    const modelObj = {
      parentRow: row,
      type: typeObj.type,
      fields: fields,
      isSchemaValid: false,
      name: row.name,
    };

    return modelObj;
  }, [schemaModel, getCurrentOrEditState, gridRowParams]);

  const currentSubRowModel = getSubRowModel();

  const setChildSchemaModel: React.Dispatch<
    React.SetStateAction<Record<string, GridRowModel<SchemaFieldRow[]>>>
  > = (value) => {
    let newValue: Record<string, GridRowModel<SchemaFieldRow[]>>;
    if (typeof value === 'function') {
      newValue = value(schemaModel);
    } else {
      newValue = value;
    }

    logger.debug(
      `${Array(level + 1).join(
        '\t',
      )}L${level}:: Child grid is setting it's rows.  Now writing them to ${
        currentSubRowModel.parentRow.name
      }'s (${gridRowParams.row.rowId}) model!`,
      currentSubRowModel,
    );

    setSchemaModel(newValue);

    setSchemaValidityModel((prevState) => {
      return {
        ...prevState,
        [gridRowParams.id as GridRowId]: -1,
      };
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: `hsl(0,0%,${backgroundLightness}%)`,
        boxShadow: '1px 1px 2px #CCC inset',
        pl: 0,
        pt: 0,
        pb: 2,
        height: 1,
        width: 1,
        display: 'flex',
        flex: '3 0px',
      }}
    >
      <Box sx={{ ml: 0 }}>
        <Divider
          orientation="vertical"
          //transform: 'rotate(-90deg)'

          sx={{ ml: 0, height: 1 }}
        >
          <Box
            className="icebergTypeLabel"
            sx={{
              transform: 'rotate(-90deg)',
              backgroundColor: getColorForComplexType(
                currentSubRowModel?.type,
                mode,
              ),
              borderRadius: 1.5,
              p: 1,
              minWidth: 54,
              maxWidth: 54,
            }}
          >
            {currentSubRowModel?.type}
          </Box>
        </Divider>
      </Box>
      <SchemaEditContainer
        apiRef={apiRef}
        baseType={currentSubRowModel?.type}
        sx={{ flexGrow: 100 }}
        level={level + 1}
        parentModel={currentSubRowModel}
        currentField={GRID_COL_TYPE}
        setParentGridRowModes={setRowModesModel}
        updateRow={updateRow}
        schemaValidChanged={(isValid: number) => {
          logger.debug(
            `${Array(level + 1).join(
              '\t',
            )}L${level}: Child schema validity changed, child responds with version ${isValid} for ${
              gridRowParams.id
            } (${gridRowParams.row.name}) `,
          );

          //set the child model with the version of the valid model
          setSchemaValidityModel((prevState) => {
            return {
              ...prevState,
              [gridRowParams.id as GridRowId]: isValid,
            };
          });
        }}
      />
    </Box>
  );
}
