import React, { useState } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import TextField from '../../Forms/TextField';
import { AzureAppRegistrationLink, GcsWorkloadIdentityLink } from '../storage';
import {
  ExternalBreadcrumbs,
  createPoolbreadcrumbs,
  getAzureCognitoPoolId,
  getAzureSuggestedCredentialName,
  getAzureCognitoSubjectId,
} from './helpers';

const FederatedCredentialInstructions = ({
  federationPoolId,
  federationSubjectId,
  suggestedCredentialName,
}: {
  federationPoolId: string;
  federationSubjectId: string;
  suggestedCredentialName: string;
}) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  return (
    <List sx={{ listStyle: 'decimal', pl: 3 }}>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          On your new App&apos;s home page, in the Client credentials section
          click <b>Add a certificate or secret</b>.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Click the <b>Federated credentials</b> tab and then{' '}
          <b>+ Add credential</b>.
        </Typography>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Choose <b>Other issuer</b> for the Federated credential scenario.
        </Typography>
      </ListItem>
      <ListItem
        sx={{
          display: 'list-item',
          p: 0,
          mb: 0,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant={'body2'}>{`Copy issuer:`}</Typography>

          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography sx={{ pl: 1, color: 'midnight.seven' }}>
              https://cognito-identity.amazonaws.com
            </Typography>
            <Tooltip
              title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
              placement={`top`}
              arrow
            >
              <ContentCopyOutlinedIcon
                onClick={() => {
                  copyToClipBoard('https://cognito-identity.amazonaws.com');
                }}
                fontSize={'small'}
                sx={{
                  cursor: 'pointer',
                  marginLeft: 1,
                  color: 'brandBlue.main',
                  display: 'flex',
                  justifySelf: 'flex-end',
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </ListItem>
      <ListItem
        sx={{
          display: 'list-item',
          p: 0,
          mb: 0,
        }}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'body2'}
          >{`Copy subject identifier:`}</Typography>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography sx={{ pl: 1, color: 'midnight.seven' }}>
              {federationSubjectId}
            </Typography>
            <Tooltip
              title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
              placement={`top`}
              arrow
            >
              <ContentCopyOutlinedIcon
                onClick={() => {
                  copyToClipBoard(federationSubjectId);
                }}
                fontSize={'small'}
                sx={{
                  cursor: 'pointer',
                  marginLeft: 1,
                  color: 'brandBlue.main',
                  display: 'flex',
                  justifySelf: 'flex-end',
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </ListItem>
      <ListItem
        sx={{
          display: 'list-item',
          p: 0,
          mb: 0,
        }}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant={'body2'}>{`Copy credential name:`}</Typography>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography sx={{ pl: 1, color: 'midnight.seven' }}>
              {suggestedCredentialName}
            </Typography>
            <Tooltip
              title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
              placement={`top`}
              arrow
            >
              <ContentCopyOutlinedIcon
                onClick={() => {
                  copyToClipBoard(suggestedCredentialName);
                }}
                fontSize={'small'}
                sx={{
                  cursor: 'pointer',
                  marginLeft: 1,
                  color: 'brandBlue.main',
                  display: 'flex',
                  justifySelf: 'flex-end',
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </ListItem>
      <ListItem
        sx={{
          display: 'list-item',
          p: 0,
          mb: 0,
        }}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant={'body2'}>{`Copy audience name:`}</Typography>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Typography sx={{ pl: 1, color: 'midnight.seven' }}>
              {federationPoolId}
            </Typography>
            <Tooltip
              title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
              placement={`top`}
              arrow
            >
              <ContentCopyOutlinedIcon
                onClick={() => {
                  copyToClipBoard(federationPoolId);
                }}
                fontSize={'small'}
                sx={{
                  cursor: 'pointer',
                  marginLeft: 1,
                  color: 'brandBlue.main',
                  display: 'flex',
                  justifySelf: 'flex-end',
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Typography variant={'body2'}>
          Click <b>Add</b>.
        </Typography>
      </ListItem>
    </List>
  );
};

export default function AzureCreateAppCredentialStep() {
  const { values } = useFormikContext<FormikValues>();
  return (
    <>
      <Box display={'flex'} flexDirection={'column'} sx={{ px: 5, pb: 3 }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant="body1">
            {`Add a credential to your new app.`}
          </Typography>
        </Box>
        <Card variant={'outlined'} sx={{ borderRadius: 2, mt: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
            <CardHeader
              title={
                <>
                  <Typography variant={'subtitle1'} sx={{ mb: 1 }}>
                    Workload Identity Federation
                  </Typography>
                  <ExternalBreadcrumbs
                    breadcrumbs={[
                      'Home',
                      'App Registrations',
                      '<your_new_app>',
                    ]}
                    link={AzureAppRegistrationLink}
                  />
                </>
              }
              sx={(theme) => ({
                pt: 2,
                borderBottom: `1px solid ${theme.palette.midnight.two}`,
              })}
            />
            <CardContent>
              <FederatedCredentialInstructions
                federationPoolId={getAzureCognitoPoolId(values.region)}
                federationSubjectId={getAzureCognitoSubjectId(values.region)}
                suggestedCredentialName={getAzureSuggestedCredentialName(
                  values.region,
                )}
              />
            </CardContent>
          </Box>
        </Card>
      </Box>
    </>
  );
}
