import * as React from 'react';

import { Typography, Box, Stack } from '@mui/material';

type Props = {
  joinedDate: string;
};
export const MemberJoinedPopover = React.forwardRef(
  ({ joinedDate, ...props }: Props, ref) => {
    return (
      <Box
        ref={ref}
        {...props}
        sx={(theme) => ({
          backgroundColor: 'popper.main',
          color: 'popper.contrastText',
          borderRadius: '4px',
          position: 'relative',
          boxShadow: theme.shadows[3],
          ml: '10px',
          m: 2,
        })}
      >
        <Stack direction={`column`}>
          <Box display={`flex`} alignItems={`center`} sx={{ py: 1, px: 2 }}>
            <Typography variant={`body2`}>
              {joinedDate ? new Date(joinedDate).toUTCString() : '--'}
            </Typography>
          </Box>
        </Stack>
      </Box>
    );
  },
);
