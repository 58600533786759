import React, { useCallback } from 'react';
import { treeHandlers } from 'react-hyper-tree';

import { BoxProps, Paper, PaperProps } from '@mui/material';
import { GridRowModesModel } from '@mui/x-data-grid';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

import { getLogger } from '../../utils/logging';
import SchemaEdit, {
  SchemaEditRowModel,
  SchemaEditType,
  SchemaFieldRow,
} from './SchemaEdit';

const logger = getLogger(
  'components.TableEdit.SchemaEditContainer' /*FUTURE import.meta.url ?*/,
);
interface SchemaEditContainerProps extends PaperProps {
  baseType: SchemaEditType;
  level: number;
  schemaValidChanged: (isSchemaValid: number) => void;
  apiRef: React.MutableRefObject<GridApiPro>;
  currentField: string;
  parentModel: SchemaEditRowModel;
  setParentGridRowModes: React.Dispatch<
    React.SetStateAction<GridRowModesModel>
  >;
  updateRow: (row: GridRowModel<SchemaFieldRow>) => void;
}
export default function SchemaEditContainer({
  baseType,
  level,
  schemaValidChanged,
  apiRef,
  currentField,
  parentModel,
  setParentGridRowModes,
  updateRow,
  ...props
}: SchemaEditContainerProps) {
  if (parentModel === undefined) {
    return <></>;
  }

  const handleOnRowStartEditing = useCallback(() => {
    logger.debug(
      `${Array(level + 1).join('\t')}L${level}: Child of Parent(${
        parentModel.parentRow.rowId
      }) is started editing!`,
    );
  }, [level, parentModel]);

  const handleOnRowStopEditing = useCallback(
    (
      updatedRows: GridRowModel<SchemaFieldRow>[],
      rowsBeingUpdated: Record<string, any> | undefined,
    ) => {
      logger.debug(
        `${Array(level + 1).join('\t')}L${level}: Child of (${
          parentModel.parentRow.name
        }) is stopped editing!`,
      );
      if (
        updatedRows
          .flatMap((eachField) => eachField)
          .filter((eachField) => eachField.required).length > 0
      ) {
        const updatedRow: GridRowModel<SchemaFieldRow> = {
          ...parentModel.parentRow,
          required: true,
        };
        updateRow(updatedRow);
      }
    },
    [level, parentModel],
  );

  return (
    <Paper {...props}>
      <SchemaEdit
        baseType={baseType}
        currentModelKey={parentModel.parentRow?.rowId}
        level={level}
        title={parentModel.parentRow?.name}
        parentModel={parentModel}
        onRowStartEditing={handleOnRowStartEditing}
        onRowStopEditing={handleOnRowStopEditing}
        validityChanged={(validVersion) => {
          schemaValidChanged(validVersion);
        }}
        hideColumnHeaders={true}
      ></SchemaEdit>
    </Paper>
  );
}
