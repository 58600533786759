import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import EmailIcon from '@mui/icons-material/Email';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { OrgAuthProvider } from '../../graphql/gen/graphql';
import useSetFieldValueWithCallback from '../../utils/UseSetFieldValueWithCallback';
import { Talert } from '../Alert/Talert';
import { Tabutton } from '../Button/Tabutton';
import TextField from '../Forms/TextField';
import GoogleAuthButton, { GoogleButtonText } from '../Login/GoogleAuthButton';
import { PleaseWait } from '../PleaseWait/PleaseWait';
import { InviteValues } from './InviteForm';

export const SignupForm = ({
  handleIdTokenSubmit,
  handleSelfInviteSubmit,
  onSignUpWithEmailStateChanged,
}: {
  handleIdTokenSubmit: (idToken: string) => void | Promise<any>;
  handleSelfInviteSubmit: (
    email: string,
    displayName?: string,
  ) => void | Promise<any>;
  onSignUpWithEmailStateChanged: (state: boolean) => void;
  submitButtonContent: JSX.Element;
}) => {
  const [signUpWithEmail, setSignUpWithEmail] = useState<boolean>(false);
  const handleGoogleToken = async (idToken: string) => {
    await handleIdTokenSubmit(idToken);
  };
  const handleSelfSignup = async (
    values: SelfInviteValues,
    helpers: FormikHelpers<SelfInviteValues>,
  ) => {
    await handleSelfInviteSubmit(values.email, values.displayName);
  };

  interface SelfInviteValues {
    email: string;
    displayName?: string;
  }

  const initialValues: SelfInviteValues = {
    email: '',
    displayName: '',
  };

  return (
    <>
      {!signUpWithEmail && (
        <Stack alignItems="center">
          <GoogleAuthButton
            googleButtonText={GoogleButtonText.signup_with}
            onGoogleSignIn={handleGoogleToken}
            sx={{ p: 0, width: 235 }}
          />

          <Tabutton
            onClick={() => {
              setSignUpWithEmail(true);
              onSignUpWithEmailStateChanged(true);
            }}
            startIcon={<MailOutlineIcon />}
            endIcon={<AddIcon />}
            size={'small'}
            variant={`outlined`}
            sx={{
              mt: 2,
              pt: 1,
              pb: 1,
              width: 235,
              borderRadius: '4px',
            }}
          >
            Sign up with email
          </Tabutton>
        </Stack>
      )}
      {signUpWithEmail && (
        <Box>
          <Typography>Enter your email address to create an account</Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            })}
            onSubmit={handleSelfSignup}
          >
            {({ isSubmitting, submitForm, setFieldValue, values }) => {
              return (
                <Form>
                  {isSubmitting && <PleaseWait />}
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box>
                      <TextField
                        name="email"
                        type="email"
                        label="Email"
                        id="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        autoFocus
                      />
                      <TextField
                        name="displayName"
                        label="Your name"
                        id="displayName"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Tabutton sx={{}} type={'submit'} variant={'contained'}>
                        Next
                      </Tabutton>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}
    </>
  );
};
