import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Skeleton } from '@mui/material';
import { concat, isEmpty, orderBy, compact } from 'lodash';

import { toDocsRoot } from '../../RouteTable';
import { useAuth } from '../../context/auth-context';
import {
  createAuthorizations,
  getAuthorizationGrants,
  handleAuthorizationMutations,
  tableAuthorizationsQuery,
} from '../../graphql/authorization';
import {
  AuthDecisionResourceType,
  AuthDecisionSubjectType,
  Authorization,
  CreateAuthInput,
  Privilege,
  UpdateAuthInput,
} from '../../graphql/gen/graphql';
import { getRoleByName } from '../../graphql/role';
import { fetchTableQuery } from '../../graphql/table';
import StatusBars from '../Feedback/StatusBars';
import { Tabulink } from '../Link/Tabulink';
import { PageHeader } from '../PageHeader/PageHeader';
import GrantNewAccess from './GrantNewAccess';
import RoleCard from './RoleCard';
import { PrivilegeSelected } from './RolePrivileges';
import {
  getAuthsToCreate,
  getAuthsToUpdate,
  getCurrentPrivilegesWithGrant,
  getRoleIds,
  getSecurityAdminDefault,
  getUserFriendlyErrorMessage,
} from './common';

const TableAccessControl = ({
  organizationName,
  organizationDisplayName,
  organizationId,
  warehouseId,
  databaseId,
  databaseName,
  tableName,
  tableId,
  isView,
}: {
  organizationName: string;
  organizationDisplayName: string;
  organizationId: string;
  warehouseId: string;
  databaseId: string;
  databaseName: string;
  tableName: string;
  tableId: string;
  isView: boolean;
}) => {
  const { user } = useAuth();
  const { table: tableParamName } = useParams();
  const passedTableName = tableName || tableParamName;
  //states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [mutationError, setMutationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [roleBeingEdited, setRoleBeingEdited] = useState('');
  const resourceType = isView
    ? AuthDecisionResourceType.View
    : AuthDecisionResourceType.Table;
  //queries
  const { data: tableRefData } = useQuery(fetchTableQuery, {
    variables: {
      warehouseId,
      database: databaseName,
      tableName: passedTableName,
    },
  });

  const tableIdAvailable = tableId || tableRefData?.fetchTableByName?.id;

  const {
    data: resourceData,
    loading,
    error,
    refetch,
  } = useQuery(tableAuthorizationsQuery, {
    // Don't call until we have an ID
    skip: !tableIdAvailable,
    variables: {
      organizationId,
      organizationName,
      organizationDisplayName,
      tableId: tableIdAvailable,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const securityAdminAuth = useMemo(
    () =>
      resourceData?.getTableAuthorizations?.filter(
        (auth: any) => auth?.role?.displayName === 'Security Admin',
      )[0],
    [resourceData],
  );

  const { data: securityAdminRole, loading: securityAdminLoading } = useQuery(
    getRoleByName,
    {
      skip: loading || securityAdminAuth,
      variables: {
        organizationId,
        roleName: 'SECURITY_ADMIN',
        organizationName: organizationName,
        organizationDisplayName: organizationDisplayName,
      },
    },
  );

  const { data: currentGrants } = useQuery(getAuthorizationGrants, {
    // Don't call until we have an ID
    skip: !tableIdAvailable,
    variables: {
      request: {
        subject: {
          type: AuthDecisionSubjectType.User,
          identifier: user.id,
        },
        resource: {
          type: resourceType,
          identifier: tableIdAvailable,
        },
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  //mutations
  const [createAuths] = useMutation(createAuthorizations);
  const [handleAuthMutations] = useMutation(handleAuthorizationMutations);
  //variables
  const resourceAuthorizations = useMemo(
    () => resourceData?.getTableAuthorizations,
    [resourceData],
  );
  const roleIds = useMemo(
    () => getRoleIds(resourceAuthorizations),
    [resourceAuthorizations],
  );
  const isSecurityAdmin = useMemo(
    () => user.isSecurityAdmin(organizationName),
    [user],
  );

  const privilegesWithGrant = getCurrentPrivilegesWithGrant(
    currentGrants?.authGrants,
  );
  const isResourceAdmin = privilegesWithGrant?.length > 0 || isSecurityAdmin;

  const saRole = securityAdminRole && securityAdminRole?.fetchRoleByName;

  const securityAdminDefault =
    saRole && getSecurityAdminDefault(saRole, AuthDecisionResourceType.Table);

  const resourceAuthorizationsWithDefault = !securityAdminAuth
    ? compact(concat(resourceAuthorizations, securityAdminDefault))
    : resourceAuthorizations;

  const sortedAuths = orderBy(
    resourceAuthorizationsWithDefault,
    [(auth) => auth?.role?.displayName?.toLowerCase()],
    ['asc'],
  );

  const securityAdminIncluded = !securityAdminAuth
    ? !securityAdminLoading && securityAdminRole
    : true;
  const handleMutations = async (
    authsToUpdate: UpdateAuthInput[],
    authsToCreate: CreateAuthInput[],
    authsToDelete: string[],
  ) => {
    return await handleAuthMutations({
      variables: {
        authsToUpdate,
        authsToCreate,
        authsToDelete,
      },
      onCompleted: () => closeAndRefetch(),
      onError: (e) => {
        setErrorMessage(getUserFriendlyErrorMessage(e.message));
        setMutationError(true);
      },
    });
  };

  const closeAndRefetch = () => {
    setTimeout(() => {
      refetch().then(() => setOpenSnackbar(true));
    }, 1000);
  };

  const handleGrantNewAccess = useCallback(
    async (currentPrivileges: PrivilegeSelected[], selectedRoleId: string) => {
      const authsToCreate = getAuthsToCreate(
        currentPrivileges,
        tableIdAvailable,
        resourceType,
        selectedRoleId,
      );

      return await createAuths({
        variables: {
          authorizations: authsToCreate,
        },
        onCompleted: () => {
          closeAndRefetch();
        },
        onError: (e) => {
          setErrorMessage(getUserFriendlyErrorMessage(e.message));
          setMutationError(true);
        },
      });
    },
    [tableId, tableRefData],
  );

  const handleApplyChanges = useCallback(
    async (
      currentPrivileges: PrivilegeSelected[],
      originalPrivileges: Authorization[],
      toCreate: PrivilegeSelected[],
      toUpdate: PrivilegeSelected[],
      toDelete: Privilege[],
      cascade: boolean,
      selectedRoleId: string,
    ) => {
      const roleId = originalPrivileges[0].roleId || selectedRoleId;
      const authsToCreate = getAuthsToCreate(
        toCreate,
        tableIdAvailable,
        resourceType,
        roleId,
      );

      const authsToUpdate = getAuthsToUpdate(toUpdate, originalPrivileges);

      const authsToDelete = toDelete.map((priv: Privilege) => {
        return originalPrivileges.find((p) => p.privilege === priv)?.id;
      });
      //@ts-ignore
      return handleMutations(authsToUpdate, authsToCreate, authsToDelete);
    },

    [tableRefData, tableId],
  );

  const revokePrivilege = async (privileges: Authorization[]) => {
    const toDelete = privileges.map((priv: any) => {
      return priv.id;
    });

    return handleMutations([], [], toDelete);
  };

  if (error && !resourceData && !user.isSecurityAdmin(organizationName))
    return <div>Error loading access controls</div>;

  return (
    <>
      {loading && <Skeleton variant="rectangular" height={350} />}
      {!loading && isResourceAdmin && (
        <>
          <PageHeader
            resourceName={tableName}
            pageTitle={`${
              resourceType.charAt(0) + resourceType.substring(1).toLowerCase()
            } access controls`}
            marginB={0}
          >
            <GrantNewAccess
              user={user}
              organizationId={organizationId}
              existingRoleIds={roleIds}
              handleGrantNewApply={async (
                currentPrivileges: PrivilegeSelected[],
                selectedRoleId: string,
                selectedRoleName: string,
              ) => {
                await setRoleBeingEdited(selectedRoleName);
                return handleGrantNewAccess(currentPrivileges, selectedRoleId);
              }}
              resourceType={resourceType}
              currentGrants={privilegesWithGrant || []}
              isSecurityAdmin={isSecurityAdmin}
            />
          </PageHeader>
          <Tabulink
            external
            href={
              toDocsRoot() +
              '/understanding-tabular-role-based-access-controls--rbac-.html'
            }
            variant="body1"
            rel="noopener"
            aria-label="Tabular Documentation"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2,
              maxWidth: '350px',
            }}
          >
            Learn about access controls and role privileges
            <OpenInNewIcon fontSize="small" sx={{ marginLeft: '2px' }} />
          </Tabulink>
        </>
      )}
      {!loading &&
        !isEmpty(sortedAuths) &&
        securityAdminIncluded &&
        sortedAuths.map((roleGroup: any, key: number) => {
          const securityAdminCard = roleGroup.role.name === 'SECURITY_ADMIN';
          return (
            <RoleCard
              key={`role-card-${key}-${roleGroup.role.id}`}
              organizationName={organizationName}
              authorizations={roleGroup.authorizations}
              resourceName={tableName}
              roleDetails={roleGroup.role}
              accessType={resourceType}
              handleApplyChanges={async (
                currentPrivileges: PrivilegeSelected[],
                originalPrivileges: Authorization[],
                toCreate: PrivilegeSelected[],
                toUpdate: PrivilegeSelected[],
                toDelete: Privilege[],
                cascade: boolean,
                selectedRoleId: string,
              ) => {
                await setRoleBeingEdited(roleGroup.role.displayName);
                return handleApplyChanges(
                  currentPrivileges,
                  originalPrivileges,
                  toCreate,
                  toUpdate,
                  toDelete,
                  (cascade = false),
                  selectedRoleId,
                );
              }}
              showRevokeAccess={!securityAdminCard}
              onRevokeAccess={async () => {
                await setRoleBeingEdited(roleGroup.role.displayName);
                await revokePrivilege(roleGroup.authorizations);
              }}
              isResourceAdmin={isResourceAdmin}
              currentGrants={privilegesWithGrant || []}
              user={user}
              securityAdminCard={securityAdminCard}
            />
          );
        })}
      <StatusBars
        successDisplay={openSnackbar}
        handleSuccessClose={() => {
          setOpenSnackbar(false);
          setRoleBeingEdited('');
        }}
        successMessage={`${roleBeingEdited} privileges have been updated.`}
        errorDisplay={mutationError}
        setErrorDisplay={setMutationError}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </>
  );
};

export { TableAccessControl };
