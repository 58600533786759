import { gql } from '@apollo/client';

//subscriptions

export const OnSystemMessageReceived = gql`
  subscription onSystemMessageReceived {
    systemMessage {
      type
      title
      msg
      ts
      sev
      autoHideDuration
    }
  }
`;
