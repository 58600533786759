import React, { useState, useCallback, cloneElement } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  InputAdornment,
  Typography,
  IconButton,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { FieldArray, useField } from 'formik';
import { concat, isEmpty, pullAt, some } from 'lodash';

import {
  CompactionOverrideProperties,
  CompactionOverridePropertyType,
  AdditionalPropertiesType,
} from '../../utils/properties';
import TextField from '../Forms/TextField';
import ByteUnitSelector from './ByteUnitSelector';

type CompactionOverridesPropTypes = {
  disabled: boolean;
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  deleteoverride: any;
  setEditingPropGroup: (value: boolean) => void;
};

export default function CompactionOverrides({
  disabled,
  name,
  setFieldValue,
  deleteoverride,
  setEditingPropGroup,
}: CompactionOverridesPropTypes) {
  const [selectValue, setSelectValue] = useState('');
  const [field, meta, helpers] = useField('overrides');

  const componentMapping = {
    TextField,
    ByteUnitSelector,
  };

  const handleEditProperty = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectValue(event.target.value);
      const propForm = CompactionOverrideProperties.find(
        (prop) => prop.key === event.target.value,
      )?.form;
      const newProperty = propForm && {
        [propForm.name]: propForm?.displayFormatter
          ? propForm.displayFormatter(propForm.default)
          : propForm.default,
      };
      if (newProperty && propForm?.helperComponentName) {
        newProperty[propForm?.helperComponentName] =
          propForm?.helperComponentDefault;
      }
      helpers.setValue(
        isEmpty(field.value)
          ? [newProperty]
          : concat([...field.value], [newProperty]),
      );
    },
    [field, helpers],
  );

  const handleRemoveFromStaging = (index: number) => {
    const clone = [...field.value];
    pullAt(clone, [index]);
    helpers.setValue(clone);
    setSelectValue('');
  };

  const handleDeleteProperty = (
    propertiesToDelete: string[],
    index: number,
  ) => {
    deleteoverride(propertiesToDelete);
    const clone = [...field.value];
    pullAt(clone, [index]);
    helpers.setValue(clone);
    setSelectValue('');
  };

  return (
    <Box width={`100%`}>
      <FieldArray name={name}>
        {() =>
          field.value?.map((property: any, index: number) => {
            const propertyForm = CompactionOverrideProperties.find(
              (prop: any) => prop.form.name === Object.keys(property)[0],
            )?.form;
            if (!propertyForm) return null;
            //@ts-ignore
            const Component = componentMapping[propertyForm.component];
            return (
              <Box
                key={`compaction-${propertyForm.name}`}
                sx={{ display: 'flex' }}
              >
                <Box
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.midnight.two}`,
                    mb: 2,
                    p: 2,
                    width: '90%',
                    borderRadius: '4px',
                  })}
                >
                  <Typography variant={`h6`} color={'midnight.seven'} mb={1}>
                    {propertyForm.title}
                  </Typography>
                  {cloneElement(
                    <Component
                      onClick={() => setEditingPropGroup(true)}
                      id={propertyForm.name}
                      name={`overrides[${index}].${propertyForm.name}`}
                      disabled={disabled}
                      label={propertyForm?.fieldLabel}
                      sx={{ minWidth: 285 }}
                      select={propertyForm?.options ? true : null}
                      helperText={propertyForm?.helperText}
                      helpercomponentname={propertyForm?.helperComponentName}
                      helpercomponentdefault={
                        propertyForm?.helperComponentDefault
                      }
                      helpercomponentfieldname={`overrides[${index}].${propertyForm?.helperComponentName}`}
                      InputProps={{
                        endAdornment: propertyForm?.inputAdornment ? (
                          <InputAdornment position={`end`}>
                            {propertyForm.inputAdornment}
                          </InputAdornment>
                        ) : null,
                      }}
                    >
                      {propertyForm?.options?.map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Component>,
                    propertyForm.component === 'ByteUnitSelector'
                      ? {
                          setFieldValue: setFieldValue,
                        }
                      : {},
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '10%',
                  }}
                >
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      setEditingPropGroup(true);
                      if (property?.existing) {
                        const propArray = [propertyForm.key];
                        if (propertyForm?.additionalProperties) {
                          propertyForm.additionalProperties.forEach(
                            (prop: AdditionalPropertiesType) =>
                              propArray.push(prop.key),
                          );
                        }
                        handleDeleteProperty(propArray, index);
                      } else {
                        handleRemoveFromStaging(index);
                      }
                    }}
                  >
                    <Tooltip title={`Reset to Default`} placement={`top`}>
                      <ClearIcon
                        sx={{
                          color: disabled ? 'midnight.two' : 'sunset.seven',
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Box>
            );
          })
        }
      </FieldArray>
      <TextField
        name={`overrideSelect`}
        select
        label={`Add a compaction property`}
        sx={(theme) => ({
          minWidth: '550px',
          [theme.breakpoints.down(680)]: {
            minWidth: '300px',
          },
          [theme.breakpoints.down(630)]: {
            minWidth: '300px',
          },
        })}
        disabled={disabled}
        value={selectValue}
        onChange={handleEditProperty}
      >
        {CompactionOverrideProperties.map(
          (property: CompactionOverridePropertyType) => (
            <MenuItem
              key={property.key}
              value={property.key}
              disabled={some(
                meta.value,
                (prop) => Object.keys(prop)[0] === property.form.name,
              )}
            >
              {property.label}
            </MenuItem>
          ),
        )}
      </TextField>
    </Box>
  );
}
