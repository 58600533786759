import React, { useCallback, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, BoxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  GridActionsCellItem,
  GridCellParams,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridToolbarContainer,
  MuiBaseEvent,
  DataGridPro,
  GridCallbackDetails,
  GridColumns,
  GridRenderCellParams,
  MuiEvent,
  useGridApiRef,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-pro/hooks/features/detailPanel/gridDetailPanelToggleColDef';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { useTableEditContext } from './TableEditContext';

import {
  PartitionFieldInput,
  SchemaFieldInput,
} from '../../graphql/gen/graphql';
import { getLogger } from '../../utils/logging';
import { Tabutton } from '../Button/Tabutton';
import { OhNoesRows } from '../OhNosRows/OhNoesRows';
import { EditToolbar } from './EditToolbar';
import { RowType } from './SchemaEdit';
import { SchemaEditToolbar } from './SchemaEditToolbar';
import {
  SourceFieldSelectionEdit,
  SourceFieldSelectionView,
} from './SourceFieldSelector';
import {
  EditTableMode,
  GridDivider,
  TABLE_GRID_NO_ROWS_HEIGHT,
} from './TableEdit';
import {
  handleRowModesModelChangeStripCellFocus,
  nukeAllEmpties,
  safeJSONParse,
  validatePartitionRow,
} from './TableEditHelpers';
import {
  TransformSelectionEdit,
  TransformSelectionView,
} from './TransformSelector';

const logger = getLogger(
  'components.TableEdit.PartitionEdit' /*FUTURE import.meta.url ?*/,
);
export interface PartitionFieldRow extends PartitionFieldInput {
  //
  rowId: string;
  isNew: boolean;
  schemaSourceField: string;
}

export interface PartitionEditProps extends BoxProps {
  validityChanged: (isValid: number) => void;
}
export default function PartitionEdit({
  validityChanged,
  ...props
}: PartitionEditProps) {
  const gridApiRef = useGridApiRef();

  const {
    schemaModel,
    mode,
    partitionFieldModel: rows,
    setPartitionFieldModel: setRows,
    triggerValidation,
    isCancelled,
    cancelSubmission,
  } = useTableEditContext();

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );
  const [rowsBeingEdited, setRowsBeingEdited] = React.useState<
    Record<string, boolean>
  >({});
  const [validityModel, setValidityModel] = React.useState<
    Record<GridRowId, number>
  >({});

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      ...(mode === EditTableMode.VIEW ? { actions: false } : {}),
    });

  const [lastValidation, setLastValidation] = React.useState<number>(-1);

  const [shouldAddNewRowOnNextLoop, setShouldAddNewRowOnNextLoop] =
    React.useState(false);

  const stopAllEditing = (): boolean => {
    let model: GridRowModesModel = {};
    logger.debug(`Stop all editing for ${rows.length} rows`, rowModesModel);
    let foundAtLeastOneInEditMode = false;
    rows.forEach((eachRow, idx) => {
      if (
        gridApiRef.current.getRowMode(eachRow.rowId as GridRowId) ==
        GridRowModes.Edit
      ) {
        foundAtLeastOneInEditMode = true;
      }
      model[eachRow.rowId as GridRowId] = { mode: GridRowModes.View };
    });

    setRowModesModel(model);
    return foundAtLeastOneInEditMode;
  };

  const startEditingRow = (rowId: GridRowId) => {
    gridApiRef.current.setCellFocus(rowId, 'schemaSourceField');
    if (gridApiRef.current.getRowMode(rowId) != GridRowModes.Edit) {
      // stopAllEditing();
      gridApiRef.current.startRowEditMode({
        id: rowId,
        fieldToFocus: 'schemaSourceField',
      });
      //stupidly, onRowEditStart does not fire when we put this row into edit mode. (above) so we have to count here too
      setRowsBeingEdited((prevState) => {
        return { ...prevState, [rowId]: true };
      });
    }
  };

  useEffect(() => {
    if (shouldAddNewRowOnNextLoop) {
      setShouldAddNewRowOnNextLoop(false);
      handleAddColumnClick();
    }
  }, [shouldAddNewRowOnNextLoop]);

  useEffect(() => {
    if (!isCancelled && triggerValidation > lastValidation) {
      logger.debug(
        `⚡️PartitionEdit: validation
        }): trigger is ${triggerValidation} and last validation is ${lastValidation}!`,
      );

      //1. stop all editing my rows
      const foundAtLeastOneInEditMode = stopAllEditing();
      //2. if no rows being edited, then we are good, report back!
      if (
        !foundAtLeastOneInEditMode &&
        Object.keys(rowsBeingEdited).length == 0
      ) {
        logger.debug(`PartitionEdit Triggering schema valid ALL GOOD`);
        let allRowsGood = true;
        if (rows.length == 0) {
          allRowsGood = true;
        }
        rows.forEach((eachRow) => {
          if (!validatePartitionRow(eachRow)) {
            allRowsGood = false;
          }
        });
        if (allRowsGood) {
          logger.debug(`All partition rows valid!`);
          if (triggerValidation > lastValidation) {
            validityChanged(triggerValidation);
            setLastValidation(triggerValidation);
          }
        } else {
          logger.debug(`CANCELLING: Partition rows are not all valid!`);
          const nukedAtLeastOne = nukeAllEmpties(
            rows,
            setRows,
            'schemaSourceField',
          );
          cancelSubmission(
            0,
            'Partition Rows are not all valid!',
            foundAtLeastOneInEditMode || nukedAtLeastOne,
          );
        }
      } else {
        logger.debug(
          `Partition edit cant trigger valid, we have rows being edited `,
          rowsBeingEdited,
        );
      }
    }
  }, [
    rows,
    rowsBeingEdited,
    validityModel,
    triggerValidation,
    lastValidation,
    isCancelled,
    rowModesModel,
  ]);

  const handleCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails,
  ) => {
    logger.debug('click on cell...');

    if (
      (mode === EditTableMode.EDIT ||
        mode == EditTableMode.CREATE ||
        mode == EditTableMode.CREATE_LIKE ||
        mode == EditTableMode.CREATE_LOAD ||
        mode == EditTableMode.CREATE_CDC_PIPELINE) &&
      params.field !== '__reorder__' &&
      params.field !== 'actions' &&
      params.field !== GRID_DETAIL_PANEL_TOGGLE_FIELD &&
      gridApiRef.current.getRowMode(params.id) === 'view'
    ) {
      gridApiRef.current.startRowEditMode({ id: params.id });
    }
  };

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    logger.debug(
      `: On row EditStart with id ${params.id} current rows editing is `,
      rowsBeingEdited,
    );
    setRowsBeingEdited((prevState) => {
      return { ...prevState, [params.id]: true };
    });
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    logger.debug(`: rowEditStop for id ${params.id} NOOP`);

    if (
      params.reason === 'tabKeyDown' &&
      params.id === rows[rows.length - 1].rowId
    ) {
      setShouldAddNewRowOnNextLoop(true);
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.rowId !== id));
    setRowsBeingEdited(({ [id]: me, ...prevState }) => {
      return { ...prevState };
    });
  };

  const handleRowModesModelChange = (
    rowModesModel: GridRowModesModel,
    details: GridCallbackDetails,
  ) => {
    logger.debug(`Handle handleRowModesModelChange!`, rowModesModel, details);
    handleRowModesModelChangeStripCellFocus(
      rowModesModel,
      details,
      setRowModesModel,
    );
  };

  const getUsedTransforms = useCallback(
    (sourceColumn: string, myRowId: string) => {
      const usedTransforms = rows
        .filter(
          (eachPartField) =>
            eachPartField.schemaSourceField === sourceColumn &&
            eachPartField.rowId !== myRowId,
        )
        .map((eachUsedField) =>
          eachUsedField.transform && eachUsedField.transform !== ''
            ? safeJSONParse(eachUsedField.transform, { name: '' }).name
            : '',
        );
      return usedTransforms;
    },
    [rows],
  );

  const processRowUpdate = async (newRow: GridRowModel<PartitionFieldRow>) => {
    logger.debug(
      `🚧 Handle processRowUpdate for row ${newRow.rowId}, removing from editing!`,
      newRow,
    );
    //const subRows = subRowsModel[newRow.id as GridRowId];
    const updatedRow = { ...newRow, isNew: false };

    setRows((prevState) => {
      return prevState.map((eachRow) =>
        eachRow.rowId === updatedRow.rowId ? updatedRow : eachRow,
      );
    });

    //handling this here because handleRowEditStop is flaky, not being triggered when save all triggered?
    let updatedRowsBeingEdited: Record<string, any> | undefined = undefined;
    setRowsBeingEdited((prevState) => {
      const { [newRow.rowId]: newGuy, ...rows } = prevState;
      updatedRowsBeingEdited = rows;
      return { ...updatedRowsBeingEdited };
    });

    return updatedRow;
  };

  const handleAddColumnClick = () => {
    if (mode === EditTableMode.VIEW) return;

    const newName = uuidv4();
    setRows((oldRows) => [
      ...oldRows,
      {
        id: -1,
        rowId: newName,
        isNew: true,
        schemaSourceField: '',
        sourceId: -1,
        transform: '',
      },
    ]);
    startEditingRow(newName);
  };

  const columns: GridColumns = [
    {
      field: 'schemaSourceField',
      headerName: 'Source',
      width: 460,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <SourceFieldSelectionEdit
            schemaModel={schemaModel}
            {...params}
          ></SourceFieldSelectionEdit>
        );
      },
      renderCell: (params: GridRenderCellParams) => (
        <>
          <SourceFieldSelectionView {...params} />
        </>
      ),
    },
    {
      field: 'transform',
      headerName: 'Transform',
      width: 200,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => {
        let currentTransformValue: string = '';

        currentTransformValue = safeJSONParse(params.value, {
          value: '',
        }).value;

        return (
          <>
            {/*<GridDivider />*/}
            <TransformSelectionEdit
              usedTransforms={getUsedTransforms(
                params.row.schemaSourceField,
                params.row.id,
              ).filter(
                (eachTransform) => eachTransform != currentTransformValue,
              )}
              {...params}
            ></TransformSelectionEdit>
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => (
        <>
          {/*<GridDivider />*/}
          <TransformSelectionView {...params} />
        </>
      ),
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 20,
      cellClassName: 'actions',
      align: 'right',
      flex: 1,
      getActions: ({ id, row, columns }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        return [
          <GridActionsCellItem
            placeholder
            key="delete"
            icon={<CloseIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            sx={{ color: 'sunset.seven', mr: 2 }}
          />,
        ];
      },
    },
  ];

  return (
    <Box {...props}>
      <DataGridPro
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(args) => {
          logger.debug('ERROR while saving row!');
        }}
        onCellClick={handleCellClick}
        components={{
          Footer: EditToolbar,
          Toolbar: SchemaEditToolbar,
          /*@ts-ignore*/
          ColumnHeaders: () => {
            return <></>;
          },
          NoRowsOverlay: OhNoesRows,
        }}
        componentsProps={{
          toolbar: {
            className: 'grid-toolbar',
            sx: {},
            title: ``,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            apiRef: gridApiRef,
            rowCount: rows?.length,
            showRange: false,
          },
          footer: {
            mode,
            setRowsBeingEdited,
            level: 0,
            stopAllEditing,
            startEditingRow,
            buttonText: 'Add Partition',
            onAddColumnClick: handleAddColumnClick,
            sx: {
              display: rows.length === 0 ? 'none' : '',
              minHeight: 56,
            },
          },
          noRowsOverlay: {
            GridOverlayProps: {
              top: -56,
              height: TABLE_GRID_NO_ROWS_HEIGHT - 44,
            },
            buttonControl:
              mode !== EditTableMode.VIEW ? (
                <Tabutton
                  variant={'outlined'}
                  sx={{ mt: 1, alignContent: 'center' }}
                  endIcon={<AddIcon />}
                  onClick={handleAddColumnClick}
                >
                  {`Add partition fields`}
                </Tabutton>
              ) : (
                <Box>This table has no partitioning.</Box>
              ),
          },
        }}
        disableSelectionOnClick={true}
        experimentalFeatures={{ newEditingApi: true }}
        autoHeight={rows.length > 0}
        hideFooterPagination
        hideFooterSelectedRowCount
        disableColumnMenu
        getDetailPanelHeight={({ row }) => 'auto'}
        getRowId={(row) => row?.rowId}
        initialState={{}}
        sx={{
          ...(rows.length == 0 ? { height: TABLE_GRID_NO_ROWS_HEIGHT } : {}),
        }}
      />
    </Box>
  );
}
