import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Link,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { toStorageProfile } from '../../RouteTable';
import { StorageProfile, StorageType } from '../../graphql/gen/graphql';
import {
  formatDateToMonDayYear,
  formatTimestamp,
  getDifferenceInDays,
  relativeTimeAutoFormat,
} from '../../utils/time';
import { TaBadge } from '../Badge/TaBadge';
import { Refresh } from '../Button/Refresh';
import { Tachip } from '../Chip/Tachip';
import {
  getMainValidationStatus,
  getValidationDisplayDetails,
  getValidationStatuses,
} from '../CreateStorageBucket/ValidationStatusHelpers';
import UserInfo from '../ProfileMenu/UserInfo';

export default function DataSourceCard({
  profile,
  orgName,
  onValidateStorage,
  onDeleteProfile,
  isSecurityAdmin,
  showActions = true,
  showStatus = true,
  currentUserEmail,
  spaceX = true,
}: {
  profile: StorageProfile;
  orgName: string;
  onValidateStorage?: (id: string) => void;
  onDeleteProfile?: (profile: StorageProfile) => void;
  isSecurityAdmin: boolean;
  showActions?: boolean;
  showStatus?: boolean;
  currentUserEmail: string;
  spaceX?: boolean;
}) {
  const navigate = useNavigate();
  const warehouseCount = useMemo(() => profile?.warehouses?.length, [profile]);
  const individualChecks = useMemo(
    () =>
      profile?.properties.validationDetails &&
      getValidationStatuses(
        profile?.properties?.validationDetails,
        warehouseCount,
      ),
    [profile?.properties?.validationDetails, warehouseCount],
  );
  const mainStatus = useMemo(
    () => getMainValidationStatus(profile?.properties?.validationStatus),
    [profile?.properties?.validationStatus],
  );
  const deleteDisabled = useMemo(
    () => warehouseCount && warehouseCount > 0,
    [warehouseCount],
  );
  const {
    status: warehouseStatus,
    label: warehouseLabel,
    icon: warehouseIcon,
  } = getValidationDisplayDetails(
    !profile?.properties?.validationDetails
      ? mainStatus
      : individualChecks.warehouse,
  );
  const {
    status: importStatus,
    label: importLabel,
    icon: importIcon,
  } = getValidationDisplayDetails(
    !profile?.properties?.validationDetails
      ? mainStatus
      : individualChecks.dataImportList,
  );

  const getCloudLogo = (type: string) => {
    switch (true) {
      case type === StorageType.Gcs:
        return '/assets/img/logos/gcp.svg';
      case type === StorageType.S3:
        return '/assets/img/logos/aws.svg';
      case type === StorageType.Adls:
        return '/assets/img/logos/azure.svg';
    }
  };

  const getTooltipText = () => {
    if (!isSecurityAdmin) {
      return 'You do not have permissions to delete storage profiles';
    }
    if (deleteDisabled) {
      return 'Warehouses must be dropped to delete storage';
    }
    return `Deleting a storage profile in Tabular will NOT delete it from ${profile?.storageType}`;
  };

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.midnight.two}`,
        borderRadius: '16px',
        p: 0,
        display: 'flex',
        mb: 2,
        mx: spaceX ? 2 : 0,
        overflowX: 'auto',
        scrollbarWidth: 'none',
      })}
    >
      {/*cloud icon*/}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
        }}
      >
        {profile?.storageType && (
          <img
            alt={profile.storageType}
            src={getCloudLogo(profile.storageType)}
            style={{
              height: '48px',
              width: '48px',
              margin: '0 auto',
            }}
          />
        )}
      </Box>
      {/* name, region */}
      <Box
        sx={(theme) => ({
          borderLeft: `1px solid ${theme.palette.midnight.two}`,
          borderRight: `1px solid ${theme.palette.midnight.two}`,
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          px: 2,
          py: 1,
        })}
      >
        <Box
          display={`flex`}
          flexDirection={`column`}
          justifyContent={`space-evenly`}
          sx={{ height: '100%' }}
        >
          <Link
            href={toStorageProfile(orgName, profile?.bucket)}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: 'sky.seven',
              mr: 2,
            }}
            variant={`h6`}
          >
            {profile?.bucket}
          </Link>
          <TaBadge
            bgColor={`white`}
            showBorder
            text={profile?.bucketRegion || profile?.region}
          />
        </Box>
      </Box>
      {/* warehouses and data import */}
      {showStatus && (
        <Box
          sx={(theme) => ({
            width: 360,
            display: 'flex',
            borderRight: `1px solid ${theme.palette.midnight.two}`,
          })}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            flexBasis={'50%'}
            p={2}
            sx={(theme) => ({
              height: '100%',
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                right: 0,
                background: `linear-gradient(270deg, ${theme.palette.midnight.one} 0%, rgba(255, 255, 255, 0) 100%)`,
                height: 48,
                width: 2,
              },
            })}
          >
            <Typography
              variant={`overline`}
              sx={{ color: 'midnight.six', mb: 1 }}
            >
              WAREHOUSES
            </Typography>
            <Box display={`flex`} alignItems={`center`} flexWrap={`nowrap`}>
              {warehouseCount ? (
                <Tachip
                  type={warehouseStatus}
                  label={`${warehouseCount} ${
                    warehouseCount === 1 ? 'Warehouse' : 'Warehouses'
                  }`}
                  icon={warehouseIcon}
                  size={`small`}
                  clickable
                  onClick={() =>
                    navigate(toStorageProfile(orgName, profile?.bucket))
                  }
                />
              ) : (
                <Tachip
                  type={warehouseStatus}
                  label={warehouseLabel}
                  icon={warehouseIcon}
                  size={`small`}
                  clickable
                  onClick={() =>
                    navigate(toStorageProfile(orgName, profile?.bucket))
                  }
                />
              )}
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            flexBasis={'50%'}
            p={2}
            sx={{ height: '100%' }}
          >
            <Typography
              variant={`overline`}
              sx={{ color: 'midnight.six', mb: 1 }}
            >
              DATA IMPORT
            </Typography>
            <Box display={`flex`} alignItems={`center`} flexWrap={`nowrap`}>
              <Tachip
                type={importStatus}
                label={importLabel}
                icon={importIcon}
                size={`small`}
                clickable
                onClick={() =>
                  navigate(toStorageProfile(orgName, profile?.bucket))
                }
              />
            </Box>
          </Box>
        </Box>
      )}
      {/* created */}
      <Box
        sx={(theme) => ({
          width: 130,
          borderRight: `1px solid ${theme.palette.midnight.two}`,
        })}
      >
        <Box
          sx={{ height: '100%' }}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          p={2}
        >
          <Typography
            variant={`overline`}
            sx={{ color: 'midnight.six', mb: 1 }}
          >
            CREATED
          </Typography>
          <PopupState variant="popper">
            {(popupState) => (
              <>
                {profile?.createdAt && (
                  <Typography
                    variant={`subtitle1`}
                    whiteSpace={`nowrap`}
                    {...bindHover(popupState)}
                    sx={{ cursor: 'default' }}
                    fontWeight={600}
                  >
                    {getDifferenceInDays(
                      new Date(),
                      new Date(profile.createdAt),
                    ) > 7
                      ? formatDateToMonDayYear(profile.createdAt)
                      : relativeTimeAutoFormat(profile.createdAt)}
                  </Typography>
                )}
                <Popper {...bindPopper(popupState)} placement={`top`}>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: 'controlBackground.main',
                      borderRadius: '4px',
                      boxShadow: theme.shadows[3],
                    })}
                  >
                    <UserInfo
                      user={{
                        displayName: profile?.createdByMember?.displayName,
                        profilePic:
                          profile?.createdByMember?.properties?.['picture.url'],
                        email: profile?.createdByMember?.user?.email,
                      }}
                      currentUser={
                        currentUserEmail ===
                        profile?.createdByMember?.user?.email
                      }
                    >
                      <Typography variant={`subtitle1`}>
                        {formatTimestamp(profile.createdAt || '')}
                      </Typography>
                    </UserInfo>
                  </Box>
                </Popper>
              </>
            )}
          </PopupState>
        </Box>
      </Box>
      {/* validated */}
      <Box
        sx={(theme) => ({
          width: 130,
          ...(showActions
            ? { borderRight: `1px solid ${theme.palette.midnight.two}` }
            : {}),
        })}
      >
        <Box
          sx={{ height: '100%' }}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          p={2}
        >
          <Typography
            variant={`overline`}
            sx={{ color: 'midnight.six', mb: 1 }}
          >
            VALIDATED
          </Typography>

          <Typography
            variant={`subtitle1`}
            whiteSpace={`nowrap`}
            fontWeight={600}
          >
            {profile?.lastModified
              ? relativeTimeAutoFormat(profile.lastModified)
              : '--'}
          </Typography>
        </Box>
      </Box>
      {/* actions */}
      {showActions && (
        <Box
          sx={(theme) => ({
            width: 100,
            backgroundColor: 'midnight.one',
            borderTopRightRadius: '16px',
            borderBottomRightRadius: '16px',
          })}
        >
          <Box
            sx={{ height: '100%' }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
          >
            <Refresh
              refreshList={() =>
                onValidateStorage && onValidateStorage(profile.id)
              }
              title={`Revalidate`}
              iconSize={`small`}
            />
            <Tooltip title={getTooltipText()} placement={'top'} arrow>
              <Box>
                <IconButton
                  disabled={deleteDisabled || !isSecurityAdmin}
                  onClick={() => onDeleteProfile && onDeleteProfile(profile)}
                  sx={{ padding: '4px' }}
                >
                  <CloseIcon
                    fontSize={'small'}
                    sx={{
                      color:
                        deleteDisabled || !isSecurityAdmin
                          ? 'midnight.three'
                          : 'sunset.seven',
                    }}
                  />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      )}
    </Box>
  );
}
