import React from 'react';

import { Box } from '@mui/material';
import { useFormikContext, FormikValues } from 'formik';

import { AwsRoleConfigurations } from './helpers';

export default function ManualBucketStep() {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  return (
    <Box display={'flex'} flexDirection={'column'} sx={{ px: 5, pb: 3 }}>
      {AwsRoleConfigurations(
        values.bucketName,
        values.externalId,
        values.region,
        { missingConfigs: [] },
        values.warehouseName,
        values.readOnly,
        setFieldValue,
      )}
    </Box>
  );
}
