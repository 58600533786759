import React, { FC } from 'react';

import { Container, Paper, Stack } from '@mui/material';

interface Props {
  children: React.ReactNode;
  activeTab: string;

  paddingTop?: number;
}

const pageSizeLg = [
  'members',
  'settings',
  'activity',
  'config',
  'profile',
  'compute',
  'membership',
  'inheritance',
  'role-security',
  'authorizations',
  'role-access',
  'access',
  'profile-overview',
  'connected-warehouses',
  'profile-access',
  'profile-settings',
  'sql',
  'services',
  'org-access-controls',
];
export const PageContentCard: FC<Props> = ({
  children,
  activeTab,
  paddingTop,
}) => {
  const checkPageSize = pageSizeLg.includes(activeTab) ? 'lg' : false;

  return (
    <Container
      maxWidth={checkPageSize}
      sx={{
        margin: 'auto',
        padding: 0,
        ...(paddingTop && { paddingTop: paddingTop }),
      }}
    >
      <Paper
        elevation={0}
        sx={(theme) => ({
          boxShadow: 'none',
          paddingY: 4,
          backgroundColor: 'controlBackground.half',
          [theme.breakpoints.down('sm')]: {
            padding: 2,
          },
        })}
      >
        <Stack direction={`column`} display="flex" spacing={4}>
          {children}
        </Stack>
      </Paper>
    </Container>
  );
};
