import { useEffect, useState } from 'react';

import { PostHog, posthog } from 'posthog-js';

const posthogEnabled = process.env.REACT_APP_POSTHOG_ENABLED === 'true';
const posthogApiToken = process.env.REACT_APP_POSTHOG_API_TOKEN;

export function usePosthogClient() {
  const [posthogClient, setPosthogClient] = useState<PostHog | undefined>();

  useEffect(() => {
    if (posthogEnabled && posthogApiToken) {
      const posthogClient = posthog.init(posthogApiToken, {
        api_host: 'https://app.posthog.com',
        disable_persistence: true,
      });

      if (posthogClient) {
        setPosthogClient(posthogClient);
      }
    }
  }, []);

  return posthogClient;
}
