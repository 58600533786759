import React from 'react';

import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded';
import { Box, BoxProps, SxProps, Typography, useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { GridOverlay } from '@mui/x-data-grid';

export interface SVGGraphic {
  svgUrl?: string;
  height?: number;
  width?: number;
  attribution?: string;
  stroke?: string;
  svgIcon?: typeof SvgIcon;
  backgroundColor?: string;
}

export interface SchemaEditNoRowsOverlayProps extends BoxProps {
  graphicProps?: SVGGraphic;
  GridOverlayProps?: SxProps;
  buttonControl?: JSX.Element;
  buttonPosition?: 'top' | 'bottom';
}

export function OhNoesRows({
  GridOverlayProps,
  graphicProps,
  buttonControl,
  buttonPosition,
  ...props
}: SchemaEditNoRowsOverlayProps): JSX.Element {
  const theme = useTheme();

  const graphicSettings: SVGGraphic = graphicProps || {
    svgIcon: TableViewRoundedIcon,
    stroke: theme.palette.dusk.two,
    height: 80,
    width: 80,
    backgroundColor: theme.palette.dusk.half,
  };

  return (
    <GridOverlay
      sx={[
        (theme) => ({
          background: theme.palette.controlBackground.main,
          height: '456px',
          width: 1,
        }),
        typeof GridOverlayProps === 'function'
          ? (GridOverlayProps(theme) as SystemStyleObject)
          : (GridOverlayProps as SystemStyleObject) ?? {},
      ]}
    >
      <Box
        {...props}
        display="flex"
        flexDirection={
          buttonPosition === undefined || buttonPosition === 'bottom'
            ? 'column'
            : 'column-reverse'
        }
        alignItems={'center'}
        sx={[
          (props.sx as SystemStyleObject) ?? {},
          { width: 1, pointerEvents: 'all' },
        ]}
      >
        {buttonControl != undefined && <Box>{buttonControl}</Box>}
        <Box sx={{ m: 2 }}>
          {graphicSettings.svgIcon && (
            <graphicSettings.svgIcon
              sx={{
                color: graphicSettings.stroke,
                height: graphicSettings.height,
                width: graphicSettings.width,
                backgroundColor: graphicSettings.backgroundColor,
                borderRadius: 56,
              }}
            ></graphicSettings.svgIcon>
          )}
          {graphicSettings.svgUrl && (
            <svg
              stroke={graphicSettings.stroke}
              height={graphicSettings.height}
              width={graphicSettings.width}
              style={{ backgroundColor: graphicSettings.backgroundColor }}
            >
              <use href={graphicSettings.svgUrl} />
            </svg>
          )}

          {graphicSettings.attribution && (
            <Typography
              variant="tooltip"
              sx={{
                color: 'midnight.three',
                textAlign: 'center',
                paddingTop: 2,
              }}
            >
              {graphicSettings.attribution} {/* @ts-ignore */}
            </Typography>
          )}
        </Box>
      </Box>
    </GridOverlay>
  );
}
