import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Grid, Typography, Box, List, ListItem, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Tabutton } from '../../Button/Tabutton';
import {
  athenaSqlDataSource,
  ExternalBreadcrumbs,
} from '../../CreateStorageBucket/BucketSteps/helpers';
import { Tabulink } from '../../Link/Tabulink';

export const AthenaDataSourceSetup = ({
  region,
  warehouseName,
}: {
  region: string;
  warehouseName: string;
}) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  return (
    <Box>
      <Box sx={{ width: 0.95 }}>
        <Grid
          container
          sx={{
            width: 1,
            border: 1,
            borderColor: 'midnight.two',
            borderRadius: '8px',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              padding: 1 / 2,
              borderRadius: '8px',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottom: 1,
              borderColor: 'midnight.two',
              backgroundColor: 'dusk.half',
            }}
          >
            <Typography variant={'body1'}>
              {`Follow link below to create a data source`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              padding: 1,
              borderBottom: 0,
              borderColor: 'midnight.two',
            }}
          >
            <Typography sx={{ display: 'inline-flex', mr: 1 }}>
              <Tabulink
                external
                href={`https://${region}.console.aws.amazon.com/athena/home?region=${region}#/data-sources/create`}
                variant="body1"
                rel="noopener"
                aria-label="Amazon Athena create data source"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {'Amazon Athena data source creation'}
                <OpenInNewIcon fontSize="small" sx={{ ml: '2px', mr: '3px' }} />
              </Tabulink>
              (instructions detailed below)
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 4 }}>
        In Create data source:
        <ExternalBreadcrumbs breadcrumbs={athenaSqlDataSource} />
        <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
          <ListItem sx={{ display: 'list-item', p: 0 }}>
            <Typography variant={'body1'}>
              Scroll down and select tile <b>Custom or shared connector</b>,
              click next
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item', p: 0, mb: 1 }}>
            <Typography variant={'body1'}>
              <b>Name</b> data source, this must match your{' '}
              <b>tabular warehouse name</b>.
            </Typography>
          </ListItem>
          <Grid
            container
            sx={{
              width: 0.95,
              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
              }}
            >
              <Typography variant={'body1'}>
                {`Copy warehouse name for datasource name`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                pre: {
                  m: 0,
                  height: 1,
                },
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              {' '}
              <SyntaxHighlighter
                language="SQL"
                style={{ ...dracula, margin: 0 as React.CSSProperties }}
                showLineNumbers={false}
              >
                {warehouseName}
              </SyntaxHighlighter>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 1 / 2,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <Tabutton
                  onClick={() => copyToClipBoard(warehouseName)}
                  size={'small'}
                  sx={{ alignSelf: 'right' }}
                  endIcon={<FileCopyIcon />}
                >
                  Copy Name
                </Tabutton>
              </Tooltip>
            </Grid>
          </Grid>
          <ListItem sx={{ display: 'list-item', p: 0, my: 1 }}>
            <Typography variant={'body1'}>
              For <b>Connection details</b> copy and paste lambda below:
            </Typography>
          </ListItem>
          <Grid
            container
            sx={{
              width: 0.95,

              border: 1,
              borderColor: 'midnight.two',
              borderRadius: '8px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: 1,
                borderRadius: '8px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 1,
                borderColor: 'midnight.two',
                backgroundColor: 'dusk.half',
              }}
            >
              <Typography variant={'body1'}>
                {`Copy lambda and paste in data source setup`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                pre: {
                  m: 0,
                  height: 1,
                },
                borderBottom: 1,
                borderColor: 'midnight.two',
              }}
            >
              {' '}
              <SyntaxHighlighter
                language="SQL"
                style={{ ...dracula, margin: 0 as React.CSSProperties }}
                showLineNumbers={false}
              >
                {`arn:aws:lambda:${region}:${process.env.REACT_APP_AWS_ACCOUNT_ID}:function:iceracer-athena-tabular:latest`}
              </SyntaxHighlighter>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: 1 / 2,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <Tabutton
                  onClick={() =>
                    copyToClipBoard(
                      `arn:aws:lambda:${region}:${process.env.REACT_APP_AWS_ACCOUNT_ID}:function:iceracer-athena-tabular:latest`,
                    )
                  }
                  size={'small'}
                  sx={{ alignSelf: 'right' }}
                  endIcon={<FileCopyIcon />}
                >
                  Copy Lambda
                </Tabutton>
              </Tooltip>
            </Grid>
          </Grid>
          <ListItem sx={{ display: 'list-item', p: 1, mb: 1 }}>
            <Typography variant={'body1'}>
              Click <b>next</b>, then <b>Create data source</b> and continue.
            </Typography>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};
