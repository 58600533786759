import { StorageType } from '../../graphql/gen/graphql';

export enum SecurityMethod {
  IamMapping,
  MemberCredential,
}

export const getBundle = (storageType: string) => {
  return storageType === StorageType.S3
    ? process.env.REACT_APP_SPARK_WIZ_AWS_BUNDLE
    : storageType === StorageType.Gcs
    ? process.env.REACT_APP_SPARK_WIZ_GCP_BUNDLE
    : process.env.REACT_APP_SPARK_WIZ_AZURE_BUNDLE;
};
