import React from 'react';

import { Box } from '@mui/material';

import { ErrorMessagingType } from '../components/Errors/ErrorMessaging';
import ErrorModal from '../components/Errors/ErrorModal';
import BaseLayout from '../components/Layouts/BaseLayout';
import HeadsUpDisplay from '../components/Layouts/HeadsUpDisplay';

type Props = {
  message: ErrorMessagingType;
  breadcrumbs?: any;
  resourceType?: string;
};

const Error = ({ message, breadcrumbs, resourceType }: Props) => {
  return (
    <BaseLayout>
      {breadcrumbs && <HeadsUpDisplay breadcrumbs={breadcrumbs} accessDenied />}
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ErrorModal message={message} />
      </Box>
    </BaseLayout>
  );
};

export default Error;
