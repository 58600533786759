import React, { useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useModal, create, show } from '@ebay/nice-modal-react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { isEmpty } from 'lodash';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Secret, Warehouse } from '../../../graphql/gen/graphql';
import {
  createOrgCredential,
  orgCredentialUsage,
} from '../../../graphql/organization';
import { Talert } from '../../Alert/Talert';
import { Tabutton } from '../../Button/Tabutton';
import { CustomIcon } from '../../Icons/CustomIcon';
import WizardContainer, {
  WIZARD_START_KEY,
  WizardStep,
} from '../WizardContainer';
import { CreateCredentialStep } from '../components/CreateCredentialStep';
import { CelerDataSetup } from './CelerDataSetup';
import { CelerDataSqlTemplate } from './CelerDataSqlTemplate';

const CelerDataWizardDialog = create<{
  organizationId: string;
  organizationName: string;
  warehouse: Warehouse;
}>(({ warehouse, organizationName, organizationId }) => {
  const modal = useModal();
  const [createOrgCredentialMutation] = useMutation(createOrgCredential);
  const [getOrgCredentialUsage, { data, stopPolling }] =
    useLazyQuery(orgCredentialUsage);
  const [credential, setCredential] = useState<Secret>();
  const [errorStatus, setErrorStatus] = useState();
  const [credentialUsed, setCredentialUsed] = useState<boolean>(false);

  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const createCredential = async () => {
    if (!credential && !errorStatus) {
      return createOrgCredentialMutation({
        variables: {
          organizationId: organizationId,
          name: 'CelerData Connection Credential',
          description: 'Built with the CelerData Connection Flow',
        },
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {
          if (data) {
            setCredential(data.data.createOrgCredential);
          }
        });
    }
  };

  const pollCredUsage = async () => {
    if (credential) {
      getOrgCredentialUsage({
        variables: {
          organizationId: organizationId,
          credentialKey: credential.credential.split(':')[0],
        },
        pollInterval: 2000,
      })
        .catch((errors) => {
          setErrorStatus(errors);
        })
        .then((data) => {});
    }
  };

  useEffect(() => {
    if (data?.organizationCredential?.lastSession) {
      setCredentialUsed(true);
      stopPolling();
    }
  }, [data]);

  const catalogURL = process.env.REACT_APP_REST_CATALOG_URL?.replace(/\/$/, '');
  const catalogName = warehouse.name.replace(/-/g, '_');

  const cmdTemplate = `create external CATALOG ${catalogName} properties (
  "type" = "iceberg",
  "iceberg.catalog.type" = "rest",
  "iceberg.catalog.uri" = "${catalogURL}",
  "iceberg.catalog.credential" = "${credential?.credential}",
  "iceberg.catalog.warehouse" = "${warehouse.name}"
  );`;

  const steps: Map<string, WizardStep[]> = new Map<string, WizardStep[]>().set(
    WIZARD_START_KEY,
    [
      {
        title: 'CelerData setup',
        body: <CelerDataSetup />,
      },
      {
        title: 'Create credential',
        disallowContinue: !credential,
        effect: createCredential,
        body: (
          <CreateCredentialStep
            organizationName={organizationName}
            connectionName={'CelerData'}
            credential={credential?.credential}
            errorStatus={errorStatus}
          />
        ),
      },

      {
        title: 'Complete connection',
        body: (
          <Stack sx={{ width: 0.95 }}>
            <Box pt={0}>
              <Typography variant={'body1'}>
                Steps to query from Tabular warehouse:
                <List sx={{ listStyle: 'decimal', pl: 5, pt: 2 }}>
                  <ListItem sx={{ display: 'list-item', p: 0 }}>
                    <Typography variant={'body1'}>
                      Click <b>Open cluster</b>
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item', p: 0 }}>
                    <Typography variant={'body1'}>
                      Login with default credentials set in cloud formation
                      template
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item', p: 0 }}>
                    <Typography variant={'body1'}>
                      Copy and paste query below to complete connection:
                    </Typography>
                  </ListItem>
                </List>
              </Typography>
              <Box
                sx={{
                  mt: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  width: 1,
                }}
              >
                <Grid
                  container
                  sx={{
                    width: 0.95,
                    mt: 1,
                    border: 1,
                    borderColor: 'midnight.two',
                    borderRadius: '8px',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1,
                      borderRadius: '8px',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottom: 1,
                      borderColor: 'midnight.two',
                      backgroundColor: 'dusk.half',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      paddingRight: 2,
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant={'body1'}
                      color={'midnight.nine'}
                      alignSelf={'center'}
                    >
                      Create catalog
                    </Typography>
                    <Tooltip
                      title={
                        isEmpty(copySuccess) ? 'Click to Copy' : copySuccess
                      }
                      placement={`top`}
                      arrow
                    >
                      <IconButton
                        onClick={() => copyToClipBoard(cmdTemplate)}
                        title="Copy to clipboard"
                        size={'small'}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderBottom: 1,
                      borderColor: 'midnight.two',
                    }}
                  >
                    <SyntaxHighlighter
                      language="bash"
                      style={{ ...dracula }}
                      customStyle={{ margin: 0 }}
                      showLineNumbers={false}
                    >
                      {cmdTemplate}
                    </SyntaxHighlighter>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: 1,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Tooltip
                      title={
                        isEmpty(copySuccess) ? 'Click to Copy' : copySuccess
                      }
                      placement={`top`}
                      arrow
                    >
                      <Tabutton
                        onClick={() => copyToClipBoard(cmdTemplate)}
                        // title="Copy to clipboard"
                        size={'small'}
                        sx={{ alignSelf: 'right' }}
                        endIcon={<FileCopyIcon />}
                      >
                        Copy command
                      </Tabutton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Stack>
        ),
      },
      {
        title: 'Test connection',
        effect: pollCredUsage,
        body: (
          <>
            <Box sx={{ width: 0.8 }}>
              {!credentialUsed && (
                <Talert color="neutral">
                  Watching for connection, please configure and test your new
                  catalog in CelerData.
                </Talert>
              )}
              {credentialUsed && (
                <Talert severity="success">
                  Successful connection detected!
                </Talert>
              )}
              <CelerDataSqlTemplate catalogName={catalogName} />
            </Box>
          </>
        ),
      },
    ],
  );

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <WizardContainer
        title={'CelerData'}
        stepsTitle={warehouse.name}
        stepsSubtitle={`Connect CelerData`}
        stepsIcon={
          <CustomIcon
            src={'/assets/img/logos/celerdata.svg'}
            sx={{ width: 48, height: 48 }}
          />
        }
        backButtonClick={() => {}}
        steps={steps}
        closeAction={
          <DialogActions>
            <CloseOutlinedIcon
              onClick={() => modal.hide()}
              sx={{ cursor: 'pointer' }}
            >
              Close
            </CloseOutlinedIcon>
          </DialogActions>
        }
        handleClose={() => modal.hide()}
        sx={{ minHeight: '550px', width: 1, height: '98vh' }}
      />
    </Dialog>
  );
});

export function showCelerDataWizardDialog({
  warehouse,
  organizationName,
  organizationId,
}: {
  warehouse: Warehouse;
  organizationName: string;
  organizationId: string;
}) {
  return show(CelerDataWizardDialog, {
    warehouse,
    organizationName,
    organizationId,
  });
}
