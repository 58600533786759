import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { CircularProgress, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import { isEqual } from 'lodash';

import AuthFormContainer from '../../components/Forms/AuthFormContainer';
import BaseLayout from '../../components/Layouts/BaseLayout';
import PasswordButtonGroup from '../../components/Login/PasswordButtonGroup';
import PasswordValidationHelpers from '../../components/Login/PasswordValidationHelpers';
import {
  LENGTH_ERROR,
  passwordSchema,
  REQUIRED_ERROR,
  resetPasswordContent,
  UNIQUE_ERROR,
} from '../../components/Login/helpers';
import { useAuth } from '../../context/auth-context';
import { changeUserPassword } from '../../graphql/user';

export default function ResetPassword() {
  const { reset } = useAuth();
  const [params] = useSearchParams();
  const resetCode = params.get('code');
  const navigate = useNavigate();
  const [resetPassword] = useMutation(changeUserPassword);
  const [formErrors, setFormErrors] = useState<any>({});
  const [showPassword, setShowPassword] = useState(false);
  const [submitButtonContent, setSubmitButtonContent] = useState<
    string | JSX.Element
  >('Sign In');
  const formInvalid =
    formErrors?.required || formErrors?.charLength || formErrors?.unique;

  const handleSubmit = async (values: any, actions: any) => {
    setSubmitButtonContent(<CircularProgress color="inherit" size={15} />);
    const { data, errors } = await resetPassword({
      variables: {
        resetCode,
        newPassword: values.password,
      },
      errorPolicy: 'all',
    });

    if (errors) {
      navigate('/login', { state: { invalidLink: true } });
    }

    if (data?.changePassword) {
      reset().catch((e: any) => {
        console.log(e);
        actions.setSubmitting(false);
      });
    }
  };

  const validatePassword = (values: any) => {
    setFormErrors({});
    passwordSchema.validate(values, { abortEarly: false }).catch((error) => {
      const errors: { [k: string]: any } = {};
      error.inner.forEach((e: any) => {
        if (isEqual(REQUIRED_ERROR, e.message)) {
          errors.required = e.message;
        }
        if (isEqual(LENGTH_ERROR, e.message)) {
          errors.charLength = e.message;
        }
        if (isEqual(UNIQUE_ERROR, e.message)) {
          errors.unique = e.message;
        }
      });
      setFormErrors(errors);
    });
  };

  return (
    <BaseLayout>
      <AuthFormContainer
        title={`Change your password`}
        subtitle={''}
        leftSideContent={resetPasswordContent}
      >
        <Formik
          initialValues={{ password: '' }}
          validateOnMount
          validateOnBlur
          validateOnChange
          validate={validatePassword}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, touched }) => (
            <Form>
              <Box>
                <PasswordValidationHelpers formErrors={formErrors} />
                <PasswordButtonGroup
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  buttonDisabled={isSubmitting || Boolean(formInvalid)}
                  buttonContent={submitButtonContent}
                  formAutoComplete={`new-password`}
                  inputError={touched.password && Boolean(formInvalid)}
                  helperText={
                    touched.password &&
                    (formErrors.required ||
                      formErrors.charLength ||
                      formErrors.unique)
                  }
                />
              </Box>
            </Form>
          )}
        </Formik>
      </AuthFormContainer>
    </BaseLayout>
  );
}
