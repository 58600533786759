import React, { useMemo } from 'react';

import { Box, useTheme } from '@mui/material';

import StatusPanelSectionLayoutPipeline from './StatusPanelSectionLayoutPipeline';
import StatusPanelSectionLayoutTask from './StatusPanelSectionLayoutTask';
import { StatusPanelSectionContextProvider } from './context/StatusPanelSectionContextProvider';
import { useStatusPanelSectionProps } from './useStatusPanelSectionProps';

import { StatusPanelSectionProps } from './index';

export default function StatusPanelSectionView(
  inProps: StatusPanelSectionProps,
) {
  const processedProps = useStatusPanelSectionProps(inProps);
  const { headerLabel, headerProps, ...props } = processedProps;

  const PanelLayout = useMemo(() => {
    switch (processedProps.variant) {
      case 'pipeline-header':
      case 'pipeline-source':
      case 'pipeline-target':
      case 'pipeline-service':
        return StatusPanelSectionLayoutPipeline;
      case 'task':
        return StatusPanelSectionLayoutTask;
      default:
        return Box;
    }
  }, [processedProps.variant]);

  const panelClasses = useMemo<string[]>(() => {
    switch (processedProps.variant) {
      case 'pipeline-source':
      case 'pipeline-target':
      case 'pipeline-service':
        return ['status-panel-hide-tiny'];
      default:
        return [];
    }
  }, [processedProps.variant]);

  return (
    <StatusPanelSectionContextProvider props={processedProps}>
      <Box
        {...props}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          minHeight: 72,
        }}
      >
        <PanelLayout
          headerProps={{ ...headerProps, className: panelClasses.join(' ') }}
          className={panelClasses
            .join(' ')
            .concat(' section status-panel-mobile')}
          flexGrow={1}
          hideForwardArrow={processedProps.variant == 'pipeline-source'}
          {...processedProps}
        />
      </Box>
    </StatusPanelSectionContextProvider>
  );
}
