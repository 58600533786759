export const getDangerZoneItems = () => {
  return [
    {
      title: 'Drop Organization',
      description:
        'This will delete the organization from Tabular without deleting data or metadata files stored in the organization’s buckets.',
      buttonText: 'Drop Organization',
      confirmModal: 'TypeDeleteDialog',
      modalContent: {
        title: 'Drop Organization',
        confirmText: 'Drop Organization',
        recoveryWarningText:
          'This will delete the organization from Tabular without deleting data or metadata files stored in the organization’s buckets. Once deleted, you will be logged out.',
      },
      mutationName: 'dropOrg',
    },
  ];
};
