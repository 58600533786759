import * as React from 'react';
import { KeyboardEventHandler, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputBase, Paper } from '@mui/material';

export default function SearchBox({
  name,
  onKeyUp,
  onKeyDown,
  placeholder,
  onClearSearchField,
  whChips,
  dbChips,
  ...props
}: {
  name: string;
  onKeyUp: KeyboardEventHandler<HTMLElement>;
  onKeyDown: KeyboardEventHandler<HTMLElement>;
  placeholder?: string;
  onClearSearchField?: () => void;
  whChips?: JSX.Element[];
  dbChips?: JSX.Element[];
}) {
  const [value, setValue] = useState('');

  const handleClear = () => {
    setValue('');
    if (onClearSearchField) {
      onClearSearchField();
    }
  };

  const handleSearch = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderRadius: '24px',
      }}
    >
      <IconButton type="button" aria-label="search">
        <SearchIcon fontSize={`small`} />
      </IconButton>
      {whChips &&
        whChips.length > 0 &&
        whChips?.map((whChip: JSX.Element, i: number) => (
          <Box key={`whChip-${i}`}>{whChip}</Box>
        ))}
      {dbChips &&
        dbChips.length > 0 &&
        dbChips?.map((dbChip: JSX.Element, i: number) => (
          <Box key={`dbChip-${i}`}>{dbChip}</Box>
        ))}
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder ? placeholder : 'Tabular search'}
        inputProps={{
          'aria-label': placeholder ? placeholder : 'search tabular',
        }}
        {...props}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        autoFocus
        value={value}
        onChange={handleSearch}
      />
      <IconButton onClick={handleClear}>
        <CloseIcon fontSize={`small`} />
      </IconButton>
    </Paper>
  );
}
