import { gql } from '@apollo/client';

import { FRAGMENTS } from './fragments';

//queries
export const fetchFullTableQuery = gql`
  query FetchFullTableByName(
    $orgId: String!
    $orgName: String!
    $orgDisplayName: String!
    $warehouseId: String!
    $database: String!
    $tableName: String!
    $daysBack: Int
  ) {
    fetchFullTableByName(
      orgId: $orgId
      orgName: $orgName
      orgDisplayName: $orgDisplayName
      warehouseId: $warehouseId
      database: $database
      tableName: $tableName
      daysBack: $daysBack
    ) {
      ...TableFields
      createdAt
      createdBy
      metadata {
        ...MetadataFields
        default_partition_spec {
          spec_id
          fields {
            ...PartitionFields
          }
        }
        current_schema {
          schema_id
          identifier_field_ids
          fields {
            ...SchemaFields
          }
        }
        default_sort_order {
          order_id
          fields {
            ...SortOrderFields
          }
        }
        refs {
          key
          value {
            snapshot_id
            type
            max_ref_age_ms
          }
        }
      }
      fieldLabels {
        fieldId
        labelId
        name
        description
        properties
      }
      createdByMembership {
        id
        displayName
        properties
      }
      lastModifiedByMembership {
        id
        displayName
        properties
      }
      statistics(daysBack: $daysBack) {
        ...StatisticsFields
      }
      compactionSummary(daysBack: $daysBack) {
        ...CompactionFields
      }
      pipelineReferences {
        pipelineId
        referenceType
        pipelineType
      }
    }
  }
  ${FRAGMENTS.extTable}
  ${FRAGMENTS.table}
  ${FRAGMENTS.tableMetadata}
  ${FRAGMENTS.stats}
  ${FRAGMENTS.compaction}
`;

export const TableWarehouseQuery = gql`
  query WarehouseForTableById($organizationId: String!, $warehouseId: String!) {
    organization(id: $organizationId) {
      id
      warehouse(id: $warehouseId) {
        id
        managed
        storageProfile {
          id
          bucket
          storageType
          region
        }
      }
    }
  }
`;

export const TableSnapshotQuery = gql`
  query fetchSnapshots(
    $warehouseId: String!
    $database: String!
    $table: String!
    $page: Int
    $pageSize: Int
    $branch: String
  ) {
    fetchSnapshots(
      warehouseId: $warehouseId
      database: $database
      table: $table
      page: $page
      pageSize: $pageSize
      branch: $branch
    ) {
      ...MetadataFields
      refs {
        key
        value {
          snapshot_id
          type
          max_ref_age_ms
        }
      }
      numSnapshots
      snapshots {
        ...SnapshotFields
        branches
      }
    }
  }
  ${FRAGMENTS.tableMetadata}
  ${FRAGMENTS.snapshot}
`;

export const TableDataPreviewQuery = gql`
  query fetchTableDataPreview(
    $warehouseId: String!
    $database: String!
    $table: String!
    $branchName: String!
  ) {
    fetchTableDataPreview(
      warehouseId: $warehouseId
      database: $database
      table: $table
      branchName: $branchName
    ) {
      dataPreview {
        snapshotId
        schema {
          schema_id
          identifier_field_ids
          fields {
            id
            name
            required
            type
            doc
          }
        }
        records
      }
      metadata {
        ...MetadataFields
        refs {
          key
          value {
            snapshot_id
            type
            max_ref_age_ms
          }
        }
      }
    }
  }
  ${FRAGMENTS.tableMetadata}
`;

export const TableEventsQuery = gql`
  query SearchEvents(
    $organizationId: String!
    $tableRefId: String!
    $query: [String]
    $eventType: [String]
    $branchName: String
    $sortBy: [SortByInput]
    $searchAfter: [String]
    $maxResults: Int
    $verbosity: Int
  ) {
    searchEvents(
      organizationId: $organizationId
      tableRefId: $tableRefId
      query: $query
      eventType: $eventType
      branchName: $branchName
      sortBy: $sortBy
      searchAfter: $searchAfter
      maxResults: $maxResults
      verbosity: $verbosity
    ) {
      totalHits
      searchAfterKeys
      results {
        eventId
        eventTs
        operation
        engine
        event
        member {
          id
          displayName
          properties
          user {
            id
            email
          }
        }
      }
    }
  }
`;

export const TableCompactionSummaryQuery = gql`
  query TableCompactionSummaryQuery(
    $organizationId: String!
    $warehouseId: String!
    $database: String!
    $table: String!
    $daysBack: Int
  ) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouse(id: $warehouseId) {
        id
        name
        database(name: $database) {
          warehouseId
          name
          table(name: $table) {
            ...TableFields
            metadata {
              ...MetadataFields
              schemas {
                schema_id
                fields {
                  id
                  name
                  required
                  doc
                  type
                }
              }
            }
            compactionSummary(daysBack: $daysBack) {
              compactions
              startingFiles
              rewrittenFiles
              filePercent
              startingSize
              rewrittenSize
              sizeChange
              sizePercent
            }
          }
        }
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.extTable}
  ${FRAGMENTS.tableMetadata}
`;

export const tablePeersQuery = gql`
  query TablePeersQuery(
    $organizationId: String!
    $warehouseId: String!
    $database: String!
  ) {
    organization(id: $organizationId) {
      ...OrgFields
      warehouse(id: $warehouseId) {
        id
        name
        database(name: $database) {
          warehouseId
          name
          tables {
            ...TableFields
          }
        }
      }
    }
  }
  ${FRAGMENTS.org}
  ${FRAGMENTS.extTable}
`;

export const fetchTableQuery = gql`
  query FetchTableByName(
    $warehouseId: String!
    $database: String!
    $tableName: String!
  ) {
    fetchTableByName(
      warehouseId: $warehouseId
      database: $database
      tableName: $tableName
    ) {
      ...TableRefFields
    }
  }
  ${FRAGMENTS.tableRef}
`;

export const fetchTableLabelCount = gql`
  query TableLabelCount(
    $warehouseId: String!
    $databaseName: String!
    $tableName: String!
  ) {
    fetchTableLabelCount(
      warehouseId: $warehouseId
      databaseName: $databaseName
      tableName: $tableName
    )
  }
`;

export const fetchTableLoadStatusQuery = gql`
  query FetchTableLoadStatus($organizationId: String!, $tableLoadId: String!) {
    fetchTableLoadStatus(
      organizationId: $organizationId
      tableLoadId: $tableLoadId
    ) {
      ...TableLoadStatusFragment
    }
  }
  ${FRAGMENTS.tableLoadStatus}
`;

export const fetchMostRecentStatusForTableQuery = gql`
  query FetchMostRecentStatusForTable(
    $organizationId: String!
    $tableRefId: String!
  ) {
    fetchMostRecentStatusForTable(
      organizationId: $organizationId
      tableRefId: $tableRefId
    ) {
      ...TableLoadStatusFragment
    }
  }
  ${FRAGMENTS.tableLoadStatus}
`;

export const fetchTableMetadata = gql`
  query TableMetadataQuery(
    $warehouseId: String!
    $databaseName: String!
    $tableName: String!
  ) {
    fetchTableMetadata(
      warehouseId: $warehouseId
      databaseName: $databaseName
      tableName: $tableName
    ) {
      table_uuid
      current_schema {
        schema_id
        identifier_field_ids
      }
    }
  }
`;

//mutations
export const updateTableProperties = gql`
  mutation UpdateTablePropertiesMutation(
    $warehouseId: String!
    $database: String!
    $table: String!
    $contentType: IcebergContentType!
    $properties: [PropertyInput]!
  ) {
    updateTableProperties(
      warehouseId: $warehouseId
      database: $database
      table: $table
      contentType: $contentType
      properties: $properties
    ) {
      key
      value
    }
  }
`;

export const deleteTableProperties = gql`
  mutation DeleteTablePropertiesMutation(
    $warehouseId: String!
    $database: String!
    $table: String!
    $contentType: IcebergContentType!
    $properties: [String]!
  ) {
    deleteTableProperties(
      warehouseId: $warehouseId
      database: $database
      table: $table
      contentType: $contentType
      properties: $properties
    )
  }
`;

export const triggerMaintenanceAction = gql`
  mutation TriggerMaintenanceActionMutation(
    $warehouseId: String!
    $database: String!
    $table: String!
    $operation: String!
  ) {
    triggerMaintenanceAction(
      warehouseId: $warehouseId
      database: $database
      table: $table
      operation: $operation
    )
  }
`;

export const triggerAnalyzeAction = gql`
  mutation TriggerAnalyzeActionMutation(
    $warehouseId: String!
    $database: String!
    $table: String!
  ) {
    triggerAnalyzeAction(
      warehouseId: $warehouseId
      database: $database
      table: $table
    )
  }
`;

export const dropTable = gql`
  mutation DropTableMutation(
    $warehouseId: String!
    $database: String!
    $table: String!
    $contentType: IcebergContentType!
  ) {
    dropTable(
      warehouseId: $warehouseId
      database: $database
      table: $table
      contentType: $contentType
    )
  }
`;

interface IcebergDataType {
  primitiveType: string;
  description: string;
  requirements?: string;
}
export const IcebergDataTypes: IcebergDataType[] = [
  { primitiveType: 'boolean', description: 'True or false' },
  {
    primitiveType: 'int',
    description: '32-bit signed integers',
    requirements: 'Can promote to long',
  },
  {
    primitiveType: 'long',
    description: '64-bit signed integers',
  },
  {
    primitiveType: 'float',
    description: '32-bit IEEE 754 floating point',
    requirements: 'Can promote to double',
  },
  {
    primitiveType: 'double',
    description: '64-bit IEEE 754 floating point',
  },
  {
    primitiveType: 'decimal',
    description: 'Fixed-point decimal; precision P, scale S',
    requirements: 'Scale is fixed, precision must be 38 or less',
  },
  {
    primitiveType: 'date',
    description: 'Calendar date without timezone or time',
  },
  {
    primitiveType: 'time',
    description: 'Time of day without date, timezone',
    requirements: 'Microsecond precision',
  },
  {
    primitiveType: 'timestamp',
    description: 'Timestamp without timezone',
    requirements: 'Microsecond precision',
  },
  {
    primitiveType: 'timestamptz',
    description: 'Timestamp with timezone',
    requirements: 'Stored as UTC',
  },
  {
    primitiveType: 'string',
    description: 'Arbitrary-length character sequences',
    requirements: 'Encoded with UTF-8',
  },
  {
    primitiveType: 'uuid',
    description: 'Universally unique identifiers',
    requirements: 'Should use 16-byte fixed',
  },
  {
    primitiveType: 'fixed',
    description: 'Fixed-length byte array of length L',
  },
  {
    primitiveType: 'binary',
    description: 'Arbitrary-length byte array',
  },

  {
    primitiveType: 'map',
    description:
      'A map is a collection of key-value pairs with a key type and a value type. Both the key field and value field each have an integer id that is unique in the table schema. Map keys are required and map values can be either optional or required. Both map keys and map values may be any type, including nested types.',
  },

  {
    primitiveType: 'list',
    description:
      'A list is a collection of values with some element type. The element field has an integer id that is unique in the table schema. Elements can be either optional or required. Element types may be any type.',
  },

  {
    primitiveType: 'struct',
    description:
      'A struct is a tuple of typed values. Each field in the tuple is named and has an integer id that is unique in the table schema. Each field can be either optional or required, meaning that values can (or cannot) be null. Fields may be any type. Fields may have an optional comment or doc string. ',
  },
];

export const createTable = gql`
  mutation CreateTableMutation(
    $warehouseId: String!
    $database: String!
    $request: CreateTableRequest!
  ) {
    createTable(
      warehouseId: $warehouseId
      database: $database
      request: $request
    ) {
      id
      name
      warehouseId
      databaseName
    }
  }
`;

export const registerTable = gql`
  mutation RegisterTableMutation(
    $warehouseId: String!
    $database: String!
    $name: String!
    $metadataLocation: String!
  ) {
    registerTable(
      warehouseId: $warehouseId
      database: $database
      name: $name
      metadataLocation: $metadataLocation
    ) {
      id
      name
      warehouseId
      databaseName
    }
  }
`;

export const patchTable = gql`
  mutation PatchTableMutation(
    $warehouseId: String!
    $database: String!
    $tableName: String!
    $request: PatchTableRequest!
    $dryRun: Boolean!
  ) {
    patchTable(
      warehouseId: $warehouseId
      database: $database
      tableName: $tableName
      request: $request
      dryRun: $dryRun
    ) {
      finalSchema {
        schema_id
        identifier_field_ids
        fields {
          ...SchemaFields
        }
      }
      finalSpec {
        spec_id
        fields {
          ...PartitionFields
        }
      }
      finalWriteOrder {
        order_id
        fields {
          ...SortOrderFields
        }
      }
      schemaChanges
      updateTableRequest
    }
  }

  ${FRAGMENTS.table}
`;

export const loadTableData = gql`
  mutation loadTableDataMutation(
    $organizationId: String!
    $warehouseId: String!
    $tableReferenceId: String!
    $request: LoadTableRequest!
  ) {
    loadTableData(
      organizationId: $organizationId
      warehouseId: $warehouseId
      tableReferenceId: $tableReferenceId
      request: $request
    ) {
      taskId
    }
  }
`;

export const cancelTableLoadMutation = gql`
  mutation CancelTableLoad($organizationId: String!, $tableLoadId: String!) {
    cancelTableLoad(
      organizationId: $organizationId
      tableLoadId: $tableLoadId
    ) {
      ...TableLoadStatusFragment
    }
  }
  ${FRAGMENTS.tableLoadStatus}
`;

export const skipAutoLoadDetectionMutation = gql`
  mutation SkipAutoLoadDetection(
    $organizationId: String!
    $tableLoadId: String!
  ) {
    skipAutoLoadDetection(
      organizationId: $organizationId
      tableLoadId: $tableLoadId
    ) {
      ...TableLoadStatusFragment
    }
  }
  ${FRAGMENTS.tableLoadStatus}
`;

export const generateTableMetaDataMutation = gql`
  mutation GenerateSchema(
    $organizationId: String!
    $storageProfileId: String!
    $keyPaths: [String]!
    $warehouseId: String!
    $databaseName: String!
    $stagedTableId: String
    $fileFormat: String!
    $delimiter: String
    $hasHeader: Boolean
    $useMaps: Boolean
    $inferPartitionsFromPath: Boolean
  ) {
    generateMetaData(
      organizationId: $organizationId
      storageProfileId: $storageProfileId
      keyPaths: $keyPaths
      warehouseId: $warehouseId
      databaseName: $databaseName
      stagedTableId: $stagedTableId
      fileFormat: $fileFormat
      delimiter: $delimiter
      hasHeader: $hasHeader
      useMaps: $useMaps
      inferPartitionsFromPath: $inferPartitionsFromPath
    ) {
      metadata {
        table_uuid
        current_schema {
          schema_id
          identifier_field_ids
          fields {
            id
            name
            required
            doc
            type
          }
        }
        default_partition_spec {
          fields {
            field_id
            name
            source_id
            transform
          }
        }
      }
      tableNames
    }
  }
`;

export const readTableMetaDataMutation = gql`
  mutation ReadMetadata(
    $organizationId: String!
    $storageProfileId: String!
    $keyPaths: [String]!
    $warehouseId: String!
    $databaseName: String!
  ) {
    readMetaData(
      organizationId: $organizationId
      storageProfileId: $storageProfileId
      keyPaths: $keyPaths
      warehouseId: $warehouseId
      databaseName: $databaseName
    ) {
      metadata {
        table_uuid
        current_schema {
          schema_id
          identifier_field_ids
          fields {
            ...SchemaFields
          }
        }
        default_partition_spec {
          fields {
            ...PartitionFields
          }
        }
        default_sort_order {
          order_id
          fields {
            ...SortOrderFields
          }
        }
      }
      tableName
      properties {
        key
        value
      }
    }
  }
  ${FRAGMENTS.table}
`;

export const addTableFieldLabels = gql`
  mutation addTableFieldLabels(
    $warehouseId: String!
    $database: String!
    $table: String!
    $fieldLabels: [FieldLabelInput]!
  ) {
    addTableFieldLabels(
      warehouseId: $warehouseId
      database: $database
      table: $table
      fieldLabels: $fieldLabels
    )
  }
`;

export const deleteTableFieldLabels = gql`
  mutation deleteTableFieldLabels(
    $warehouseId: String!
    $database: String!
    $table: String!
    $fieldLabels: [FieldLabelInput]!
  ) {
    deleteTableFieldLabels(
      warehouseId: $warehouseId
      database: $database
      table: $table
      fieldLabels: $fieldLabels
    )
  }
`;

export const renameTable = gql`
  mutation renameTable(
    $warehouseId: String!
    $database: String!
    $table: String!
    $newDatabaseName: String!
    $newTableName: String!
    $contentType: IcebergContentType!
  ) {
    renameTable(
      warehouseId: $warehouseId
      database: $database
      table: $table
      newDatabaseName: $newDatabaseName
      newTableName: $newTableName
      contentType: $contentType
    )
  }
`;

export const createBranch = gql`
  mutation CreateBranchMutation(
    $warehouseId: String!
    $database: String!
    $table: String!
    $name: String!
    $snapshotId: BigInt!
  ) {
    createBranch(
      warehouseId: $warehouseId
      database: $database
      table: $table
      name: $name
      snapshotId: $snapshotId
    )
  }
`;

export const createTag = gql`
  mutation CreateTagMutation(
    $warehouseId: String!
    $database: String!
    $table: String!
    $name: String!
    $snapshotId: BigInt!
    $maxRefAgeMs: BigInt
  ) {
    createTag(
      warehouseId: $warehouseId
      database: $database
      table: $table
      name: $name
      snapshotId: $snapshotId
      maxRefAgeMs: $maxRefAgeMs
    )
  }
`;

export const deleteTag = gql`
  mutation DeleteTagMutation(
    $warehouseId: String!
    $database: String!
    $table: String!
    $name: String!
  ) {
    deleteTag(
      warehouseId: $warehouseId
      database: $database
      table: $table
      name: $name
    )
  }
`;

export const rollbackSnapshot = gql`
  mutation RollbackToMutation(
    $warehouseId: String!
    $database: String!
    $table: String!
    $snapshotId: BigInt!
    $ref: String
  ) {
    rollbackTo(
      warehouseId: $warehouseId
      database: $database
      table: $table
      snapshotId: $snapshotId
      ref: $ref
    )
  }
`;
