import React from 'react';

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const PleaseWait = () => {
  return (
    <>
      <Box display="flex" flexGrow={1} alignItems="center" p={1}>
        Please Wait{' '}
        <CircularProgress color="inherit" size={25} sx={{ ml: 3 }} />
      </Box>
    </>
  );
};
