import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Skeleton } from '@mui/material';
import { includes, some } from 'lodash';

import { MainTabGroup } from '../../context/GlobalPropsContext';

import { toRoles } from '../../RouteTable';
import NotFound from '../../components/Errors/NotFound';
import TabLayout from '../../components/Layouts/TabLayout';
import Inheritance from '../../components/RoleSingle/Inheritance';
import RoleAccessControl from '../../components/RoleSingle/RoleAccessControl';
import RoleAuthorizations from '../../components/RoleSingle/RoleAuthorizations';
import RoleMemberGrid from '../../components/RoleSingle/RoleMemberGrid';
import RoleSecurity from '../../components/RoleSingle/RoleSecurity';
import RoleSettings from '../../components/RoleSingle/RoleSettings';
import { useAuth } from '../../context/auth-context';
import { getAuthorizationDecision } from '../../graphql/authorization';
import { AuthDecisionResponseType } from '../../graphql/gen/graphql';
import { getRoleByName } from '../../graphql/role';

export default function Role() {
  const { role: roleName } = useParams();
  const { user } = useAuth();
  const organizationId = useMemo(
    () => user?.loginSession?.loggedInOrg?.id,
    [user],
  );
  const orgName = useMemo(
    () => user?.loginSession?.loggedInOrg?.name || '',
    [user],
  );
  const orgDisplayName = useMemo(
    () => user?.loginSession?.loggedInOrg?.displayName,
    [user],
  );

  const { data, loading, refetch } = useQuery(getRoleByName, {
    variables: {
      organizationId,
      roleName,
      organizationName: orgName,
      organizationDisplayName: orgDisplayName,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const { data: decisionData, loading: decisionLoading } = useQuery(
    getAuthorizationDecision,
    {
      skip: !data?.fetchRoleByName,
      variables: {
        request: {
          subject: {
            type: 'USER',
            identifier: user.id,
          },
          privileges: ['MODIFY_ROLE'],
          requirement: 'ALL',
          resource: {
            type: 'ROLE',
            identifier: data?.fetchRoleByName?.id,
          },
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );
  // Role === OA : require RoleAdmin or MODIFY_ROLE
  // Role === SA : require OA, SA, RoleAdmin or MODIFY_ROLE
  // all other roles : require SA, RoleAdmin or MODIFY_ROLE
  const canModifyRole = useMemo(
    () =>
      data?.fetchRoleByName?.name === 'ORG_ADMIN'
        ? user.isRoleAdmin(data?.fetchRoleByName) ||
          decisionData?.authDecision === AuthDecisionResponseType.Allow
        : data?.fetchRoleByName?.name === 'SECURITY_ADMIN'
        ? user.isSecurityAdmin(orgName) ||
          user.isOrganizationAdmin(orgName) ||
          decisionData?.authDecision === AuthDecisionResponseType.Allow ||
          user.isRoleAdmin(data?.fetchRoleByName)
        : user.isSecurityAdmin(orgName) ||
          decisionData?.authDecision === AuthDecisionResponseType.Allow ||
          user.isRoleAdmin(data?.fetchRoleByName),
    [user, orgName, data, decisionData],
  );
  const canViewAuthorizations = useMemo(() => {
    if (user.isSecurityAdmin(orgName)) {
      return true;
    }
    return some(
      user?.loginSession?.roles,
      (userRole) => userRole.id === data?.fetchRoleByName?.id,
    );
  }, [user, data, orgName]);
  const isSystemRole = useMemo(
    () =>
      includes(
        ['EVERYONE', 'SECURITY_ADMIN', 'ORG_ADMIN'],
        data?.fetchRoleByName?.name,
      ),
    [data?.fetchRoleByName],
  );
  const currentUserEmail = user?.loginSession?.membership?.email || user?.email;
  const roleDisplayName = useMemo(
    () => data?.fetchRoleByName?.displayName || data?.fetchRoleByName?.name,
    [data],
  );

  const breadcrumbs = [
    { href: toRoles(orgName), text: 'Roles' },
    { text: roleDisplayName },
  ];

  const tabs = useMemo(
    () => [
      {
        id: 'role_membership',
        label: 'Role Membership',
        group: MainTabGroup.ORG,
        content: (
          <RoleMemberGrid
            role={data?.fetchRoleByName}
            canModifyRole={canModifyRole}
            currentUserEmail={currentUserEmail}
            loading={loading || decisionLoading}
            refreshResourceContent={refetch}
            orgName={orgName}
            orgId={organizationId}
            orgDisplayName={orgDisplayName}
          />
        ),
        hide: false,
        href: 'membership',
      },
      {
        id: 'inheritance',
        label: 'Inheritance',
        group: MainTabGroup.ORG,
        content: (
          <Inheritance
            parents={data?.fetchRoleByName?.parents}
            loading={loading}
            refreshResourceContent={refetch}
            orgName={orgName}
            orgDisplayName={orgDisplayName}
            orgId={organizationId}
            roleId={data?.fetchRoleByName?.id}
            roleName={data?.fetchRoleByName?.name}
          />
        ),
        hide: false,
        href: 'inheritance',
      },
      {
        id: 'role_security',
        label: 'Security',
        group: MainTabGroup.ORG,
        content: (
          <RoleSecurity
            role={data?.fetchRoleByName}
            currentUserEmail={currentUserEmail}
            loading={loading}
            refetchRole={refetch}
            orgName={orgName}
            orgId={organizationId}
            canModifyRole={canModifyRole}
          />
        ),
        hide: false,
        href: 'role-security',
      },
      {
        id: 'role_authorizations',
        label: 'Authorizations',
        group: MainTabGroup.ORG,
        content: (
          <RoleAuthorizations
            organizationId={organizationId}
            roleName={roleName || ''}
            orgName={orgName}
            roleId={data?.fetchRoleByName?.id}
          />
        ),
        hide: false,
        disabled: !canViewAuthorizations,
        href: 'authorizations',
      },
      {
        id: 'role_access',
        label: 'Access Control',
        group: MainTabGroup.ORG,
        content: (
          <RoleAccessControl
            role={data?.fetchRoleByName}
            loading={loading}
            refreshResourceContent={refetch}
            orgName={orgName}
            orgId={organizationId}
            orgDisplayName={orgDisplayName}
            canModifyRole={canModifyRole}
          />
        ),
        hide: isSystemRole,
        href: 'role-access',
      },
      {
        id: 'role_settings',
        label: 'Settings',
        group: MainTabGroup.ORG,
        content: (
          <RoleSettings
            role={data?.fetchRoleByName}
            orgName={orgName}
            orgId={organizationId}
            orgDisplayName={orgDisplayName}
            currentUserEmail={user?.email}
            canModifyRole={canModifyRole}
          />
        ),
        hide: isSystemRole,
        href: 'settings',
      },
    ],
    [
      roleDisplayName,
      data,
      loading,
      decisionLoading,
      isSystemRole,
      canModifyRole,
      canViewAuthorizations,
    ],
  );

  if (!loading && !data) {
    return <NotFound />;
  }

  return (
    <>
      {!loading && !decisionLoading && tabs ? (
        <TabLayout
          tabs={tabs}
          breadcrumbs={breadcrumbs}
          resourceType={`Role`}
        />
      ) : (
        <Skeleton />
      )}
    </>
  );
}
