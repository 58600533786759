import React from 'react';

import { Box, Grid, Typography, Link } from '@mui/material';

import { ErrorMessagingType } from './ErrorMessaging';

type Props = {
  message: ErrorMessagingType;
};

export default function ErrorModal({ message }: Props) {
  const imageText = message.img === 'penguin' ? 'Penguin' : 'Bear';
  const penguin = imageText === 'Penguin';
  return (
    <Box
      sx={{
        minWidth: '630px',
        height: '370px',
      }}
    >
      <Grid
        container
        sx={(theme) => ({
          m: 0,
          boxShadow: theme.shadows[6],
          backgroundColor: 'controlBackground.main',
          borderRadius: 1,
        })}
      >
        <Grid
          item
          display={'flex'}
          flexDirection={'column'}
          paddingY={6}
          paddingX={4}
          sx={{
            backgroundColor: 'brandBlue.main',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
          }}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Box
              display="flex"
              flexDirection={'column'}
              paddingX={penguin ? 5 : 2}
              alignItems={'center'}
            >
              <img
                src={`/assets/img/${message.img}.svg`}
                alt={'404'}
                style={{ margin: '0 auto', display: 'flex' }}
              />
              <Box display="flex" maxWidth={'90px'}>
                <Typography
                  variant="tooltip"
                  sx={{
                    color: 'midnight.three',
                    textAlign: 'center',
                    paddingTop: 2,
                  }}
                >
                  {imageText} by Liv Iko from {/* @ts-ignore */}
                  <Link
                    external
                    href={message.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    color={'midnight.three'}
                  >
                    {' '}
                    Noun Project
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Grid
            container
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={message.alignItems || 'center'}
            rowSpacing={8}
            paddingTop={6}
          >
            <Grid item alignItems={'flex-start'} paddingY={8} paddingX={4}>
              <Typography
                variant="h1"
                //@ts-ignore
                sx={{ marginBottom: 1, color: 'brandBlue.main' }}
              >
                {message.header}
              </Typography>
              <Typography variant="subtitle1">{message.subHeader}</Typography>
              {message.description && (
                <Typography variant="body1" maxWidth="375px" paddingTop={3}>
                  {message.description}
                </Typography>
              )}
            </Grid>
            {message.buttons && (
              <Grid item display="flex">
                <Box paddingTop={3} display={'flex'}>
                  {message.buttons}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
