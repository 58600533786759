import React from 'react';

import { Grid } from '@mui/material';

import { RoleSelector } from '../Forms/RoleSelector';
import TextField from '../Forms/TextField';

export interface TableEditCreateHeaderProps {
  descriptionRows: number;
  showDescription?: boolean;
}

export const TableEditCreateHeader = ({
  descriptionRows,
  showDescription = true,
}: TableEditCreateHeaderProps) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="name"
            type="text"
            rows={1}
            label="Table Name"
            id="name"
            variant="outlined"
            margin="normal"
            autoComplete="off"
            required
            autoFocus
          />
        </Grid>
        {showDescription && (
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              multiline
              rows={descriptionRows}
              name="description"
              type="text"
              label="Table description"
              id="description"
              variant="outlined"
              margin="normal"
              autoComplete="off"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
