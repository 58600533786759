import React, { useMemo, useState, useRef, useCallback } from 'react';

import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Box, Card, CardActions } from '@mui/material';

import { useAuth } from '../../../context/auth-context';
import { OrgAuth, OrgAuthProvider } from '../../../graphql/gen/graphql';
import { getOrganizationAuths } from '../../../graphql/organization';
import { Tabutton } from '../../Button/Tabutton';
import { AWSIdentityProviderForm } from '../../Credentials/Forms/AWSIdentityProviderForm';
import AWSIdentityProviders from '../../Credentials/Grids/AWSIdentityProviders';
import { CustomIcon } from '../../Icons/CustomIcon';
import { AthenaError } from './components/AthenaError';

export const AthenaErrorsSecurityAdmin = ({
  organizationId,
  organizationName,
}: {
  organizationId: string;
  organizationName: string;
}) => {
  const { user } = useAuth();
  const [formVisible, setFormVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const {
    data: authData,
    refetch: refetchAuth,
    loading: authLoading,
  } = useQuery(getOrganizationAuths, {
    variables: { organizationId },
  });

  const onIdentityProviderCreated = async () => {
    await refetchAuth();
  };

  const awsIdentity = useMemo(
    () =>
      authData?.organization?.auths?.filter(
        (auth: OrgAuth) => auth?.authType === OrgAuthProvider.IamSso,
      ),
    [authData?.organization?.auths],
  );

  const currentUserEmail = useMemo(
    () => user?.loginSession?.membership?.email || user?.email,
    [user],
  );

  const handleAdd = async () => {
    await setFormVisible(true);
    setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth' }), 0);
  };

  return (
    <Box>
      <AthenaError message={'Add ARN as an AWS IAM identity provider below'} />
      <>
        <Card
          sx={(theme) => ({
            border: `1px solid ${theme.palette.midnight.two}`,
            borderRadius: 2,
            mt: 2,
          })}
        >
          <AWSIdentityProviders
            orgAuthData={awsIdentity}
            onIdentityProviderDelete={() => {}}
            currentUserEmail={currentUserEmail}
            showDelete={false}
            loading={authLoading}
          />

          <CardActions
            sx={(theme) => ({
              borderTop: `1px solid ${theme.palette.midnight.two}`,
              display: 'flex',
              justifyContent: 'end',
              padding: 0,
            })}
          >
            <Tabutton
              onClick={handleAdd}
              endIcon={<AddIcon />}
              size={'small'}
              sx={{ m: 1 }}
            >
              Add identity
            </Tabutton>
          </CardActions>
        </Card>
        {formVisible && (
          <div ref={ref}>
            <AWSIdentityProviderForm
              organizationId={organizationId}
              onIdentityProviderCreated={onIdentityProviderCreated}
              modal={false}
              setFormVisible={() => setFormVisible(false)}
            />
          </div>
        )}
      </>
    </Box>
  );
};
