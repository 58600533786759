// see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export const compactNumberFormatter = new Intl.NumberFormat(undefined, {
  notation: 'compact',
});

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const number = Math.abs(bytes); //Math.log does not accept negative numbers
  const i = Math.floor(Math.log(number) / Math.log(k));
  return parseFloat((number / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
