import React from 'react';

import { Box } from '@mui/material';

export default function SectionHeader({
  children,
  styleProps,
  borderTop,
}: {
  children: any;
  styleProps?: any;
  borderTop?: string;
}) {
  return (
    <Box
      sx={(theme) => ({
        ...styleProps,
        height: '50px',
        backgroundColor: theme.palette.dusk.half,
        borderTop: borderTop ?? `1px solid ${theme.palette.midnight.two}`,
        borderBottom: `1px solid ${theme.palette.midnight.two}`,
        paddingLeft: 2,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      })}
    >
      {children}
    </Box>
  );
}
