import React from 'react';

import { useModal, create, show } from '@ebay/nice-modal-react';
import Dialog from '@mui/material/Dialog';

import CreateLabel from '../Forms/CreateLabel';

interface CreateLabelDialogProps {
  organizationId: string;
  onLabelCreated: (label: any) => void;
  onPartialFail: () => void;
}
const CreateLabelDialog = create(
  ({
    organizationId,
    onLabelCreated,
    onPartialFail,
  }: CreateLabelDialogProps) => {
    const modal = useModal();

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        maxWidth={`xl`}
        sx={{
          '& .MuiDialog-container': {
            '> .MuiPaper-root': {
              width: '705px',
            },
          },
        }}
      >
        <CreateLabel
          organizationId={organizationId}
          onCancel={modal.hide}
          onLabelCreated={(label: any) => {
            modal.hide();
            onLabelCreated && onLabelCreated(label);
          }}
          onPartialFail={() => {
            modal.hide();
            onPartialFail && onPartialFail();
          }}
        />
      </Dialog>
    );
  },
);

export default CreateLabelDialog;

export function showCreateLabelDialog(
  organizationId: string,
  onLabelCreated: (label: any) => void,
  onPartialFail: () => void,
) {
  return show<boolean, any, CreateLabelDialogProps>(CreateLabelDialog, {
    organizationId,
    onLabelCreated,
    onPartialFail,
  });
}
