import React from 'react';

import { Popper, Typography } from '@mui/material';
import fileSize from 'filesize';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';

import { Table } from '../../../graphql/gen/graphql';
import { calculateTextWidth } from '../../../pages/helpers';
import { TableSnapshot } from '../../../schema/events/table.snapshot';
import { DescriptionPopover } from '../../Popovers/DescriptionPopover';
import { Verbosity } from '../TableEventsView';
import UpdateChanges from './UpdateChanges';

type Props = {
  event: TableSnapshot;
  table: Table;
  verbosity?: Verbosity;
  columnSize: number;
};
export default function TableSnapshotView({
  event,
  table,
  verbosity = Verbosity.Summary,
  columnSize,
}: Props) {
  const friendlyString: { [index: string]: string | ((o: any) => string) } = {
    added_data_files: (cnt) => {
      return cnt + ' ' + (cnt > 1 ? 'data files' : 'data file') + ' added';
    },
    added_records: (cnt) => {
      return cnt + ' ' + (cnt > 1 ? 'records' : 'record') + ' added';
    },
    added_file_sizes: (bytes) => {
      return fileSize(bytes) + ' added';
    },
    deleted_data_files: (cnt) => {
      return cnt + ' ' + (cnt > 1 ? 'data files' : 'data file') + ' deleted';
    },
    deleted_records: (cnt) => {
      return cnt + ' ' + (cnt > 1 ? 'records' : 'record') + ' deleted';
    },
    deleted_file_sizes: (bytes) => {
      return fileSize(bytes) + ' removed';
    },
  };
  switch (verbosity) {
    case Verbosity.Summary:
      let changeSummary = '';
      for (let eachKey in event) {
        if (
          (eachKey.startsWith('added_') || eachKey.startsWith('deleted_')) &&
          (event[eachKey] as number) > 0
        ) {
          changeSummary +=
            (typeof friendlyString[eachKey] === 'function'
              ? (friendlyString[eachKey] as (o: any) => string)(event[eachKey])
              : friendlyString[eachKey] || eachKey) + '; ';
        }
      }
      const textWidth = calculateTextWidth(
        changeSummary,
        '18px Source Sans Pro',
        true,
      );

      return (
        <>
          <UpdateChanges key={event.id} operations={[event.operation]} />
          <PopupState variant="popper">
            {(popupState) => (
              <>
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  {...bindHover(popupState)}
                >
                  {changeSummary}
                </Typography>
                {textWidth >= columnSize && (
                  <Popper {...bindPopper(popupState)} placement={`top-start`}>
                    <DescriptionPopover description={changeSummary} />
                  </Popper>
                )}
              </>
            )}
          </PopupState>
        </>
      );
    default:
      return null;
  }
}
