import React from 'react';

import {
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { Table } from '../../../graphql/gen/graphql';
import { Event } from '../../../schema/events/event';
import { SucceededExpireSnapshots } from '../../../schema/events/succeeded.expire-snapshots';
import { TableEventTypes, Verbosity } from '../TableEventsView';

type Props = {
  event: Event;
  table: Table;
  verbosity?: Verbosity;
};
export default function ExpireSnapshotsView({
  event,
  table,
  verbosity = Verbosity.Summary,
}: Props) {
  let expireSnapshotsMessage = (function () {
    switch (event.type) {
      case TableEventTypes.RUN_EXPIRE_SNAPSHOTS:
        return 'Scheduled';
      case TableEventTypes.STARTED_EXPIRE_SNAPSHOTS:
        return 'Started';
      case TableEventTypes.SUCCEEDED_EXPIRE_SNAPSHOTS:
        return 'Succeeded';
      default:
        return 'Failed';
    }
  })();

  switch (verbosity) {
    case Verbosity.Summary:
      return <div>Table Expire Snapshots {expireSnapshotsMessage}</div>;
    case Verbosity.Detailed:
      const succeededExpireSnapshots = event as SucceededExpireSnapshots;

      return (
        <>
          <Grid container margin={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TableContainer component={Paper}>
                <MuiTable aria-label="Listing">
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">Deleted data files</TableCell>
                      <TableCell>
                        {succeededExpireSnapshots.deleted_data_files_count}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">
                        Deleted equality delete files
                      </TableCell>
                      <TableCell>
                        {
                          succeededExpireSnapshots.deleted_equality_delete_files_count
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">
                        Deleted position delete files
                      </TableCell>
                      <TableCell>
                        {
                          succeededExpireSnapshots.deleted_position_delete_files_count
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">
                        Deleted manifest files
                      </TableCell>
                      <TableCell>
                        {succeededExpireSnapshots.deleted_manifest_files_count}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">
                        Deleted manifest lists
                      </TableCell>
                      <TableCell>
                        {succeededExpireSnapshots.deleted_manifest_lists_count}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
}
