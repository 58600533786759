import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  createFilterOptions,
  Stack,
  TextField as MuiTextField,
} from '@mui/material';
import { Field, Form, FormikHelpers, Formik } from 'formik';
import { Autocomplete } from 'formik-mui';
import { debounce, differenceBy } from 'lodash';
import { useSnackbar } from 'notistack';

import { SortByDirection, Label } from '../../graphql/gen/graphql';
import { searchApplyLabelsQuery } from '../../graphql/label';
import { addTableFieldLabels, fetchFullTableQuery } from '../../graphql/table';
import { getUserFriendlyErrorMessage } from '../AccessControl/common';
import LabelSelect from '../Labels/LabelSelect';

export default function ApplyLabel({
  organizationId,
  orgName,
  orgDisplayName,
  warehouseId,
  database,
  tableName,
  fieldId,
  fieldLabels,
  onLabelApplied,
}: {
  organizationId: string;
  orgName: string;
  orgDisplayName: string;
  warehouseId: string;
  database: string;
  tableName: string;
  fieldId: string;
  fieldLabels: any;
  onLabelApplied: (label: Label) => void;
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const [applyTableFieldLabel] = useMutation(addTableFieldLabels);

  const { data, loading, error } = useQuery<any>(searchApplyLabelsQuery, {
    variables: {
      organizationId: organizationId,
      query: searchQuery !== '' ? searchQuery : '*',
      searchAfter: undefined,
      sortBy: [
        {
          fieldName: 'labelName.sort',
          sortDirection: SortByDirection.Asc,
        },
      ],
      maxResults: 100,
      privilegeFilter: ['APPLY_LABEL'],
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const getInitialValues = () => {
    const nofieldLabelsApplied = fieldLabels === undefined;
    if (nofieldLabelsApplied) {
      return { labels: [] };
    }
    const existingLabels =
      !nofieldLabelsApplied &&
      fieldLabels.map((label: any) => ({
        id: label.labelId,
        name: label.name,
        description: label.description,
        properties: label.properties,
      }));

    return { labels: existingLabels };
  };
  const options = useMemo(() => {
    const labels = data?.searchLabels?.results.map((label: any) => ({
      id: label.labelId,
      name: label.labelName,
      description: label.description,
      properties: label.properties,
    }));
    const existingLabels = getInitialValues();
    return differenceBy(labels, existingLabels.labels, 'id');
  }, [data, fieldLabels]);

  const filterOptions = createFilterOptions({
    stringify: (option: any) => `${option.name}`,
  });

  const onSearchChange = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      //@ts-ignore
      const search = e?.target?.value || '';
      setSearchQuery(search);
    },
    [],
  );
  const debouncedSearchChange = debounce(onSearchChange, 500);

  const initialValues = getInitialValues();
  const onSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>,
  ) => {
    const newFieldLabels = {
      fieldId: fieldId,
      labelId: values?.labels?.id,
    };
    await applyTableFieldLabel({
      variables: {
        warehouseId: warehouseId,
        database: database,
        table: tableName,
        fieldLabels: newFieldLabels,
      },
      refetchQueries: [
        {
          query: fetchFullTableQuery,
          variables: {
            orgId: organizationId,
            orgName,
            orgDisplayName,
            warehouseId,
            database,
            tableName,
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then((data) => {
        setSubmitting(false);
        onLabelApplied(data.data.addTableFieldLabels);
      })
      .catch((error) => {
        enqueueSnackbar(getUserFriendlyErrorMessage(error.message), {
          variant: 'error',
        });
      });
  };
  const CustomPopper = (props: any) => {
    const { className, anchorEl, style, ...rest } = props;
    return (
      <Box
        {...rest}
        sx={(theme) => ({
          '& .MuiPaper-root': {
            boxShadow: 'unset',
          },
          '& ::-webkit-scrollbar': {
            width: '4px',
          },
          '& ::-webkit-scrollbar-track': {
            backgroundColor: 'midnight.half',
          },
          '& ::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: 'midnight.two',
          },
          borderTop: `1px solid ${theme.palette.midnight.two}`,
          position: 'absolute',
          zIndex: 9999,
          width: '250px',
          display: 'flex',
          justifyItems: 'flex-start',
          ml: '-13px',
        })}
      />
    );
  };

  return (
    <Stack direction={'column'} alignItems={'center'} height={'300px'}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ setFieldValue, submitForm, values }) => (
          <Form>
            <Field
              component={Autocomplete}
              id={`apply-label-combo-box`}
              open
              name="labels"
              loading={loading}
              value={values?.labels?.name}
              noOptionsText={`No labels`}
              options={options ? options : []}
              isOptionEqualToValue={(option: any, value: any) =>
                option.id === value.id
              }
              PopperComponent={CustomPopper}
              getOptionLabel={(option: any) => option.name}
              renderOption={(props: any, option: any) => (
                <li
                  {...props}
                  style={{
                    padding: '12px 5px',
                  }}
                >
                  <LabelSelect label={option} />
                </li>
              )}
              onChange={(e: Event, newValue: string) => {
                setFieldValue('labels', newValue);
                submitForm();
              }}
              filterOptions={filterOptions}
              renderTags={() => null}
              onInputChange={(event: SyntheticEvent) => {
                //@ts-ignore
                debouncedSearchChange(event);
              }}
              popupIcon={''}
              ListboxProps={{
                style: {
                  maxHeight: '250px',
                  width: '245px',
                },
              }}
              renderInput={(params: any) => (
                <MuiTextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      width: '225px',
                      borderRadius: 24,
                      height: '30px',
                      alignContent: 'center',
                    },
                    startAdornment: (
                      <SearchIcon
                        fontSize={'small'}
                        sx={{ color: 'brandBlue.main' }}
                      />
                    ),
                  }}
                  name="labels"
                  error={error}
                  placeholder="Search"
                  margin="normal"
                  onKeyDown={(event: any) => {
                    if (event.key === 'Backspace') {
                      event.stopPropagation();
                    }
                  }}
                />
              )}
            />
          </Form>
        )}
      </Formik>
    </Stack>
  );
}
