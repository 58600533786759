import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { Skeleton } from '@mui/lab';

import { ROUTES } from '../../RouteTable';
import { Organization } from '../../graphql/gen/graphql';
import { deleteOrg } from '../../graphql/organization';
import StatusBars from '../Feedback/StatusBars';
import DangerZone from '../TableSettings/DangerZone';
import { OrgGeneralSettings } from './OrgGeneralSettings';
import { getDangerZoneItems } from './helpers';

export default function OrgSettings({
  userCanDrop,
  organization,
}: {
  userCanDrop: boolean;
  organization: Organization;
}) {
  const [apiError, setApiError] = useState(false);
  const dangerZoneItems = getDangerZoneItems();
  const navigate = useNavigate();
  const [dropOrg] = useMutation(deleteOrg, {
    variables: {
      organizationId: organization?.id,
    },
    onError: () => setApiError(true),
    onCompleted: () => navigate(ROUTES.logout),
  });

  const handleDangerZoneMutation = async (mutationName: string) => {
    const mutationMapping = {
      dropOrg,
    };
    //@ts-ignore
    await mutationMapping[mutationName]();
  };

  return (
    <>
      {organization ? (
        <OrgGeneralSettings organization={organization} />
      ) : (
        <Skeleton variant="rectangular" height={380} />
      )}
      {userCanDrop && (
        <DangerZone
          dangerZoneItems={dangerZoneItems}
          onConfirm={handleDangerZoneMutation}
        />
      )}
      <StatusBars
        errorDisplay={apiError}
        setErrorDisplay={setApiError}
        errorMessage={`Something went wrong. Please refresh the page and try again.`}
      />
    </>
  );
}
