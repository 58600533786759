import React from 'react';

import { Typography, Box, BoxProps } from '@mui/material';

export interface DashboardItem {
  title: string;
  data: JSX.Element;
}
interface DashboardCardProps extends BoxProps {
  items: DashboardItem[];
}
export default function DashboardCard({ items, ...props }: DashboardCardProps) {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'sky.half',
        borderColor: 'midnight.two',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '4px',
        height: 64,
        boxShadow: theme.shadows[2],
        backdropFilter: 'blur(4px)',
        [theme.breakpoints.down(500)]: {
          flexDirection: `${items.length >= 3 ? 'column' : 'row'}`,
          height: `${items.length >= 3 ? 'auto' : 64}`,
          paddingTop: `${items.length >= 3 ? '8px' : 'none'}`,
          paddingBottom: `${items.length >= 3 ? '8px' : 'none'}`,
        },
      })}
      {...props}
    >
      {items.map((item, index) => {
        return (
          <Box
            key={`dashboard-content-${item.title}-${index}`}
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 135,
              flexGrow: 1,
              borderRight: `1px solid ${theme.palette.midnight.two}`,
              '&:last-of-type': {
                borderRight: `1px solid transparent`,
              },
              [theme.breakpoints.down('sm')]: {
                borderRight: `${items.length >= 3 ? 'none' : 'inherit'}`,
                marginTop: `${items.length >= 3 ? '8px' : 'none'}`,
                marginBottom: `${items.length >= 3 ? '8px' : 'none'}`,
              },
            })}
          >
            <Typography
              sx={{ color: 'midnight.seven', pb: 1 }}
              variant={`overline`}
            >
              {item.title.toUpperCase()}
            </Typography>
            {item.data}
          </Box>
        );
      })}
    </Box>
  );
}
