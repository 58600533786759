import React, { useMemo, useState } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  Box,
  CardContent,
  CardHeader,
  TextField,
  Tooltip,
} from '@mui/material';
import { isEmpty } from 'lodash';

import { StorageProfile } from '../../graphql/gen/graphql';
import { Tacard } from '../Card/Tacard';

export default function GCPDetailsTemplate({
  profile,
}: {
  profile: StorageProfile;
}) {
  const [copySuccess, setCopySuccess] = useState('');
  const poolId = profile?.roleArn?.substring(
    profile?.roleArn?.indexOf('/') + 1,
    profile?.roleArn?.lastIndexOf('/'),
  );
  const providerId = profile?.roleArn?.substring(
    profile?.roleArn?.lastIndexOf('/') + 1,
  );

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  return (
    <Tacard sx={{ mx: 2 }}>
      <CardHeader
        title={`Google cloud storage account`}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.midnight.two}`,
        })}
      />
      <CardContent>
        <Box display={`flex`} marginTop={2}>
          <Box display={`flex`} flexBasis={`50%`}>
            <TextField
              variant={`outlined`}
              label={`Region`}
              defaultValue={profile?.properties?.['gcp.bucket.region']}
              inputProps={{
                size: profile?.properties?.['gcp.bucket.region'].length + 10,
                readOnly: true,
              }}
              sx={{ mr: 2 }}
            />
            <Box
              display={`flex`}
              alignItems={`center`}
              marginRight={2}
              width={`100%`}
            >
              <TextField
                variant={`outlined`}
                label={`Bucket name`}
                defaultValue={profile?.bucket}
                inputProps={{
                  readOnly: true,
                }}
                fullWidth
                sx={{ mr: 1 }}
              />
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <ContentCopyOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    color: 'brandBlue.main',
                  }}
                  onClick={() => {
                    copyToClipBoard(profile?.bucket).then(() =>
                      setTimeout(() => {
                        setCopySuccess('');
                      }, 2000),
                    );
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box display={`flex`} marginTop={4}>
          <Box display={`flex`} flexBasis={`50%`}>
            <Box
              display={`flex`}
              alignItems={`center`}
              marginRight={2}
              width={`100%`}
            >
              <TextField
                variant={`outlined`}
                label={`Project number`}
                defaultValue={profile?.accountId}
                inputProps={{
                  readOnly: true,
                }}
                fullWidth
                sx={{ mr: 1 }}
              />
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <ContentCopyOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    color: 'brandBlue.main',
                  }}
                  onClick={() => {
                    copyToClipBoard(profile?.accountId).then(() =>
                      setTimeout(() => {
                        setCopySuccess('');
                      }, 2000),
                    );
                  }}
                />
              </Tooltip>
            </Box>
            <Box
              display={`flex`}
              alignItems={`center`}
              marginRight={2}
              width={`100%`}
            >
              <TextField
                variant={`outlined`}
                label={`Project ID`}
                defaultValue={profile?.properties?.[`gcp.project.id`]}
                inputProps={{
                  readOnly: true,
                }}
                fullWidth
                sx={{ mr: 1 }}
              />
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <ContentCopyOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    color: 'brandBlue.main',
                  }}
                  onClick={() => {
                    copyToClipBoard(
                      profile?.properties?.[`gcp.project.id`],
                    ).then(() =>
                      setTimeout(() => {
                        setCopySuccess('');
                      }, 2000),
                    );
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          <Box display={`flex`} flexBasis={`50%`}>
            <Box
              display={`flex`}
              alignItems={`center`}
              marginRight={2}
              width={`100%`}
            >
              <TextField
                variant={`outlined`}
                label={`Pool ID`}
                defaultValue={poolId}
                inputProps={{
                  readOnly: true,
                }}
                fullWidth
                sx={{ mr: 1 }}
              />
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <ContentCopyOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    color: 'brandBlue.main',
                  }}
                  onClick={() => {
                    copyToClipBoard(poolId).then(() =>
                      setTimeout(() => {
                        setCopySuccess('');
                      }, 2000),
                    );
                  }}
                />
              </Tooltip>
            </Box>
            <Box display={`flex`} alignItems={`center`} width={`100%`}>
              <TextField
                variant={`outlined`}
                label={`Provider ID`}
                defaultValue={providerId}
                inputProps={{
                  readOnly: true,
                }}
                fullWidth
                sx={{ mr: 1 }}
              />
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <ContentCopyOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    color: 'brandBlue.main',
                  }}
                  onClick={() => {
                    copyToClipBoard(providerId).then(() =>
                      setTimeout(() => {
                        setCopySuccess('');
                      }, 2000),
                    );
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box display={`flex`} marginTop={4}>
          <Box display={`flex`} flexBasis={`50%`}>
            <Box
              display={`flex`}
              alignItems={`center`}
              marginRight={2}
              width={`100%`}
            >
              <TextField
                variant={`outlined`}
                label={`Service account email`}
                defaultValue={
                  profile?.properties?.[`gcp.service-account.email`]
                }
                inputProps={{
                  readOnly: true,
                }}
                fullWidth
                sx={{ mr: 1 }}
              />
              <Tooltip
                title={isEmpty(copySuccess) ? 'Click to Copy' : copySuccess}
                placement={`top`}
                arrow
              >
                <ContentCopyOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    color: 'brandBlue.main',
                  }}
                  onClick={() => {
                    copyToClipBoard(
                      profile?.properties?.[`gcp.service-account.email`],
                    ).then(() =>
                      setTimeout(() => {
                        setCopySuccess('');
                      }, 2000),
                    );
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Tacard>
  );
}
