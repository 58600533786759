import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Skeleton } from '@mui/material';
import { includes, orderBy } from 'lodash';

import CardGridLayout from '../../components/Layouts/CardGridLayout';
import { Organization, Role } from '../../graphql/gen/graphql';
import { getOrgSystemRoles } from '../../graphql/organization';
import SystemRoleDetails from './SystemRoleDetails';

export default function SystemRoleCards({
  organization,
}: {
  organization: Organization;
}) {
  const builtInOrder = ['EVERYONE', 'SECURITY_ADMIN', 'ORG_ADMIN'];
  const { data: systemRolesData } = useQuery(getOrgSystemRoles, {
    variables: { organizationId: organization.id },
  });

  const builtInRoleCards = useMemo(() => {
    //Query for org system roles is returning parent roles where EVERYONE is a child
    const actualSystemRoles = systemRolesData?.orgSystemRoles.filter(
      (role: Role) => includes(builtInOrder, role.name),
    );
    return orderBy(
      actualSystemRoles,
      (a: Role, b: Role) =>
        builtInOrder.indexOf(a?.name) - builtInOrder.indexOf(b?.name),
    ).map((role: Role) => (
      <SystemRoleDetails
        key={role.id}
        organization={organization}
        peopleCount={role?.users?.length || 0}
        nestedRoleCount={role?.children?.length || 0}
        roleName={role.name}
        roleDisplayName={role.displayName || role.name}
      />
    ));
  }, [systemRolesData]);

  return (
    <>
      {builtInRoleCards ? (
        <CardGridLayout cards={builtInRoleCards} noMarginTop />
      ) : (
        <Skeleton variant="rectangular" height={350} />
      )}
    </>
  );
}
