import React, { useEffect, useMemo, useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';

import {
  BucketValidationResponse,
  CheckResponse,
  Maybe,
} from '../../graphql/gen/graphql';
import { Talert } from '../Alert/Talert';
import { Refresh } from '../Button/Refresh';
import { Tabutton } from '../Button/Tabutton';
import { Tabulink } from '../Link/Tabulink';
import { ConnectionStatus } from './ConnectionAssistant';

const BucketValidation = ({
  verifyLoading,
  status,
  serverError,
  onBack,
  onRevalidate,
  finalSubmit,
  createLoading,
  isWarehouse,
  validationResponse,
}: {
  verifyLoading: boolean;
  status?: Maybe<ConnectionStatus>;
  serverError?: Maybe<string>;
  onBack: () => void;
  onRevalidate: () => void;
  finalSubmit: (value: boolean) => void;
  createLoading: boolean;
  isWarehouse: boolean;
  validationResponse?: Maybe<BucketValidationResponse>;
}) => {
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [finished, setFinished] = useState(false);
  const [verifyCount, setVerifyCount] = useState(0);
  const [showDetails, setShowDetails] = useState<boolean | undefined>(
    undefined,
  );

  function resetProgress() {
    setProgress1(0);
    setProgress2(0);
    setProgress3(0);
    setFinished(false);
    setShowDetails(undefined);
    setVerifyCount((prevState) => prevState + 1);
  }

  const finalizedWithErrorAndNoDetailsPref =
    finished &&
    validationResponse &&
    showDetails === undefined &&
    !(
      status == ConnectionStatus.WarehouseEligible ||
      status == ConnectionStatus.ReadOnlyEligible
    );

  const { submitForm, isSubmitting } = useFormikContext();

  useEffect(() => {
    if (finished || createLoading) {
      return;
    }

    let counter = 0;
    let secondCounter = 0;
    let thirdCounter = 0;
    const timer1: ReturnType<typeof setInterval> = setInterval(() => {
      if (counter < 100) {
        counter++;
        setProgress1(counter);
      }
      if (counter === 100) {
        return clearInterval(timer1);
      }
    }, 2);

    const timer2: ReturnType<typeof setInterval> = setInterval(() => {
      if (counter === 100 && secondCounter < 100) {
        secondCounter++;
        setProgress2(secondCounter);
      }
      if (secondCounter === 100) {
        return clearInterval(timer2);
      }
    }, 5);

    const timer3: ReturnType<typeof setInterval> = setInterval(() => {
      if (counter === 100 && secondCounter === 100 && thirdCounter < 100) {
        thirdCounter++;
        setProgress3(thirdCounter);
      }

      if (thirdCounter === 100) {
        setFinished(true);
        return clearInterval(timer3);
      }
    }, 10);
  }, [verifyCount]);

  const getStatusIcon = (progress: number, pass: boolean) => {
    if (progress !== 100 || verifyLoading) {
      return <MoreHorizIcon sx={{ color: 'sky.seven' }} />;
    } else if (pass) {
      return (
        <CheckCircleOutlineIcon
          fontSize={'medium'}
          sx={{ color: 'aurora.seven' }}
        />
      );
    } else if (serverError || !pass) {
      return <ErrorOutlineIcon sx={{ color: 'sunset.seven' }} />;
    }
  };

  const minimumValidationPassed =
    status !== ConnectionStatus.BucketError &&
    status !== ConnectionStatus.AssumeRoleError &&
    status !== ConnectionStatus.Failed;
  const policyPermissionPassed =
    status === ConnectionStatus.WarehouseEligible ||
    status === ConnectionStatus.ReadOnlyEligible;

  const summary = (
    <Box sx={{ backgroundColor: 'sky.one', borderRadius: '8px', padding: 2 }}>
      <Typography variant={'subtitle1'}>Checklist</Typography>
      <Divider sx={{ paddingTop: 2 }} />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingY={2}
      >
        <Typography variant={'body2'}>Bucket validated</Typography>
        {getStatusIcon(progress1, minimumValidationPassed)}
      </Box>
      <Divider />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingY={2}
      >
        <Typography variant={'body2'}>Role access validated</Typography>
        {getStatusIcon(progress2, minimumValidationPassed)}
      </Box>
      <Divider />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingY={2}
      >
        <Typography variant={'body2'}>
          {status === ConnectionStatus.WarehouseEligible
            ? 'Permissions verified for Tabular warehouses'
            : status === ConnectionStatus.ReadOnlyEligible
            ? 'Permissions verified for data import (read-only)'
            : 'Permissions issue'}
        </Typography>
        {getStatusIcon(
          progress3,
          minimumValidationPassed && policyPermissionPassed,
        )}
      </Box>
    </Box>
  );

  const details = useMemo(() => {
    return (
      validationResponse && (
        <>
          <Talert severity={'warning'} sx={{ mb: 2 }}>
            Updates to cloud configuration can take minutes to propagate.
          </Talert>
          <Grid
            container
            spacing={1}
            sx={{
              backgroundColor: 'sky.one',
              borderRadius: '8px',
              padding: 2,
              maxHeight: 450,
              overflow: 'auto',
            }}
          >
            <Grid item xs={12} spacing={1}>
              <Typography variant={'subtitle1'}>Details</Typography>
            </Grid>

            {Object.keys(validationResponse)
              .filter(
                (eachCheck) =>
                  eachCheck !== '__typename' &&
                  // @ts-ignore
                  validationResponse[eachCheck].status !== 'NOT_APPLICABLE' &&
                  // @ts-ignore
                  validationResponse[eachCheck].status !== 'UNCHECKED',
              )
              .map((eachCheck) => {
                const eachValidation: Maybe<CheckResponse> =
                  // @ts-ignore
                  validationResponse[eachCheck];
                return eachValidation;
              })
              .sort((a, b) =>
                a?.checkPassed == b?.checkPassed ? 0 : a?.checkPassed ? -1 : 1,
              )
              .map((eachValidation, idx) => {
                if (!eachValidation) return <></>;
                return (
                  <>
                    {idx !== 0 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}

                    <Grid item xs={4}>
                      {' '}
                      <Typography variant={'body2'}>
                        {eachValidation.type}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {' '}
                      <Typography variant={'body2'}>
                        {eachValidation.status}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      {' '}
                      {getStatusIcon(progress1, eachValidation.checkPassed!)}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'body2'}>
                        {eachValidation.message}
                      </Typography>
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </>
      )
    );
  }, [validationResponse]);

  const verificationBody = useMemo(() => {
    return showDetails || finalizedWithErrorAndNoDetailsPref
      ? details
      : summary;
  }, [showDetails, finalizedWithErrorAndNoDetailsPref, details, summary]);

  return (
    <Card sx={{ paddingX: 5, pt: 1, boxShadow: 'none' }}>
      <Typography variant={'h1'}>Validation</Typography>
      <CardContent sx={{ px: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography id="modal-description" sx={{ mb: 4 }}>
            {verifyLoading || !finished
              ? `One moment, we're checking bucket details`
              : 'See results below'}{' '}
            {finished && !verifyLoading && (
              <Tabulink
                onClick={() =>
                  setShowDetails(
                    showDetails === undefined &&
                      finalizedWithErrorAndNoDetailsPref
                      ? false
                      : !showDetails,
                  )
                }
              >
                {showDetails || finalizedWithErrorAndNoDetailsPref
                  ? 'show summary'
                  : `show${!policyPermissionPassed ? ' error' : ''} details`}
              </Tabulink>
            )}
          </Typography>
          {finished && !verifyLoading && (
            <Refresh
              sx={{ width: 24 }}
              refreshList={() => {
                onRevalidate();
              }}
              iconSize={'small'}
              title={'Re-validate storage profile'}
            />
          )}
        </Box>

        {verificationBody}
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pb: 7,
          pt: 3,
          px: 0,
        }}
      >
        <Tabutton
          disabled={verifyLoading || !finished}
          sx={{ ml: 2 }}
          onClick={() => {
            resetProgress();
            onBack();
          }}
        >
          {'Back'}
        </Tabutton>

        <Tabutton
          variant="contained"
          disabled={verifyLoading || !finished || isSubmitting}
          sx={{ ml: 2 }}
          loadingBtn
          loading={createLoading}
          onClick={() => {
            finalSubmit(true);
            return submitForm();
          }}
        >
          {verifyLoading || !finished || createLoading
            ? `Pending`
            : status === ConnectionStatus.WarehouseEligible
            ? `Create`
            : status === ConnectionStatus.ReadOnlyEligible
            ? `Create read-only profile`
            : `Create unvalidated profile`}
        </Tabutton>
      </CardActions>
    </Card>
  );
};

export default BucketValidation;
